import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import Wave from "react-wavify";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Avatar,
  Button,
  Paper,
  Stack,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  CardContent,
  Card,
} from "@mui/material";

// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import SignUp from "../../components/auth/SignUp";
import { spacing, useTheme } from "@mui/system";
import { WaveBackground } from "../../Svgs";
import "./index.css";
import { StarRounded } from "@mui/icons-material";
import useScreenOrientation from "../components/ScreenOrientation";
import { getRandomInt, isDev } from "../../helper";
import { useLocation, useNavigate } from "react-router-dom";
import ForgotPassword from "../../components/auth/ForgotPassword";

// import Logo from "static/img/brands/tanika_logo.png";
// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 64px;
//   height: 64px;
//   margin-bottom: 32px;
// `;

const Spacer = styled.div(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 102px;
  height: 102px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
  padding: 20px;
`;

function SignIn() {
  const [transform, setTransform] = useState(false);
  const [testimony, setTestimony] = useState(allTestimonies[getRandomInt(2)]);
  // const [showSignUp, setShowSignUp] = useState(true);
  // const showSignUp = isDev();
  const showSignUp = false;
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const primary = theme.palette.primary && theme.palette.primary.main;
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [showForgotPassowrd, setShowForgotPassowrd] = useState(false);

  const screenOrientation = useScreenOrientation();

  useEffect(() => {
    // let { hash, search } = location;
    // console.log(location);
    // if (search && search.includes("exhibition")) setShowSignUp(true);
    // if (hash && hash.includes("product_grouping")) setValue(2);
  }, []);

  const setShowSignUp = () => {
    navigate("/auth/register");
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        // backgroundImage: "url('/static/img/svg/signin_background_mobile.svg')",
        ...(isMobile
          ? {
              background:
                "url(/static/img/svg/signin_mobile_bg.png) no-repeat center center fixed",
              "-webkit-background-size": "cover",
              "-moz-background-size": "cover",
              "-o-background-size": "cover",
              "background-size": "cover",
              height: "100vh",
              overflow: "hidden",
            }
          : {}),
      }}
    >
      {/* <Brand /> */}
      {!isMobile && (
        <Navbar transform={transform} setTransform={setTransform} />
      )}
      <Stack
        direction={"row"}
        sx={{
          height: "500px",
          alignItems: "center",
          mt: 15,
          display: { xs: "none", lg: "block" },
        }}
      >
        <Stack
          direction="row"
          sx={{
            backgroundColor: "primary.main",
            width: "100vw",
            height: "450px",
          }}
          justifyContent="center"
          alignItems={"center"}
        >
          <Stack
            direction="row"
            width={"80vw"}
            minHeight="450px"
            sx={{ position: "relative" }}
          >
            <Box
              sx={{
                width: "50vw",
                position: "absolute",
                left: "0px",
                top: "-25px",
              }}
            >
              <SignInWrapper
                setShowSignUp={setShowSignUp}
                showSignUp={showSignUp}
                showForgotPassowrd={showForgotPassowrd}
                setShowForgotPassowrd={setShowForgotPassowrd}
              />
            </Box>
            <Box sx={{ width: "50vw" }}>{/* <SignInWrapper /> */}</Box>
            <Box sx={{ width: "50vw", margin: "auto" }}>
              <Testimonial
                src={testimony?.src}
                name={testimony?.name}
                testimony={testimony?.testimony}
              />
              {/* {allTestimonies[getRandomInt(2)]}
               */}
            </Box>
          </Stack>
        </Stack>
      </Stack>
      {isMobile && (
        <>
          <Stack
            // direction={"row"}
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              mt: 4,
              textAlign: "center",
            }}
            justifyContent="center"
          >
            {/* <Typography sx={{ color: "#fff" }} variant="h3">
              Jwero
            </Typography>
            <Typography sx={{ color: "#fff", mt: 5 }} variant="body2">
              {showSignUp ? "Create New Account" : "Please login to continue"}
            </Typography> */}
          </Stack>
          {/* <Box sx={{ height: "100vh" }}>
            <svg
              viewBox="0 0 300 100"
              preserveAspectRatio="none"
              style={{
                height: "100vh",
                width: "100vw",
                // marginLeft: "auto",
                // width: "500px",
                // backgroundColor: "blue",
              }}
            >
              <path
                d="M-0.56,34.05 C155.76,-17.26 302.48,68.59 503.38,14.31 L500.00,0.00 L0.00,0.00 Z"
                stroke="none"
                fill={primary}
                // fill={!transform && isIpad ? "#fff" : primary}
              ></path>
            </svg>
          </Box> */}
        </>
      )}
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "center",
          mt: 5,
        }}
      >
        <Box
          sx={
            isMobile
              ? {
                  px: 5,
                  // position: "absolute",
                  // top: "60%",
                  // transform: "translateY(-50%)",
                  borderRadius: "20px",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: "5px",
                  right: "5px",
                }
              : {
                  // position: "absolute",
                  // top: "10%",
                  // left: "50%",
                  // transform: "translateX(-50%)",
                  display: { sm: "block", lg: "none" },
                  // justifyContent: "center",
                }
          }
        >
          <SignInWrapper
            hideTitle={isMobile ? true : false}
            setShowSignUp={setShowSignUp}
            showSignUp={showSignUp}
            showForgotPassowrd={showForgotPassowrd}
            setShowForgotPassowrd={setShowForgotPassowrd}
          />
        </Box>
      </Stack>

      {!isMobile && (
        <Box
          sx={{
            mt: screenOrientation === "portrait-primary" ? 18 : isLg ? 18 : 0,
          }}
        >
          <Footer />
        </Box>
      )}
    </Box>
  );
}

export default SignIn;

const SignInWrapper = ({
  hideTitle,
  setShowSignUp,
  showSignUp,
  showForgotPassowrd,
  setShowForgotPassowrd,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Card
      sx={{
        // py: 8,
        backgroundColor: "#fff",
        minWidth: isMobile ? "auto" : "400px",
        height: isMobile ? "auto" : "500px",
        justifyContent: "center",
        width: isMobile ? "auto" : "400px",
        boxShadow:
          isMobile && false ? "0px" : "0px 0px 4px -1px rgba(0,0,0,0.75)",
        pb: 1,
        ...(isMobile ? {} : {}),
      }}
    >
      <CardContent
        sx={{
          alignItems: "center",
          // backgroundColor: "#fff000",
          margin: "auto",
          padding: isMobile ? "14px" : "20px",
          paddingBottom: "10px",
          "&:last-child": { paddingBottom: isMobile ? "6px" : "24px" },
        }}
      >
        {isMobile && (
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <img
              src={"/static/img/brands/jwero_logo.png"}
              style={{ height: "50px" }}
            />
          </Box>
        )}
        <Helmet title="Sign In" />
        {!hideTitle && !showForgotPassowrd && (
          <>
            <Typography
              // component="h1"
              variant="h2"
              // align="center"
              gutterBottom
            >
              {showSignUp ? "Sign up" : "Login"}
            </Typography>
            <Stack direction="row" alignItems="center" flexWrap={"wrap"}>
              <Typography component="h2" variant="body1">
                {showSignUp
                  ? "Create new account or "
                  : "Sign in to your account to continue or "}
              </Typography>
              <Button
                onClick={() => setShowSignUp(!showSignUp)}
                sx={{
                  whiteSpace: "nowrap",
                  background: "rgba(1, 39, 137, 0.06)",
                }}
              >
                {showSignUp ? "Sign In" : "Create an account"}
              </Button>
            </Stack>
          </>
        )}
        {/* <BigAvatar
                  alt="Lucy"
                  src="/static/img/brands/tanika_icon.png"
                /> */}
        {showSignUp ? (
          <SignUp />
        ) : showForgotPassowrd ? (
          <ForgotPassword onGoToSignIn={() => setShowForgotPassowrd(false)} />
        ) : (
          <SignInComponent
            onForgotPasswordPress={() => setShowForgotPassowrd(true)}
          />
        )}
        {isMobile && (
          <Stack
            direction={isMobile ? "column" : "row"}
            sx={{ mt: 2 }}
            alignItems="center"
          >
            <Typography component="h2" variant="body1">
              {showSignUp
                ? "Create new account or "
                : "Sign in to your account to continue or "}
            </Typography>
            <Button
              onClick={() => setShowSignUp(!showSignUp)}
              sx={{
                whiteSpace: "nowrap",
                background: "rgba(1, 39, 137, 0.06)",
              }}
            >
              {showSignUp ? "Sign In" : "Create an account"}
              {/* {showSignUp ? "Sign In" : "Create an account"} */}
            </Button>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

const SignUpWrapper = ({ hideTitle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Card
      sx={{
        // py: 8,
        backgroundColor: "#fff",
        // minWidth: "400px",
        height: "500px",
        justifyContent: "center",
        width: isMobile ? "100%" : "400px",
        boxShadow: isMobile ? "0px" : "0px 0px 4px -1px rgba(0,0,0,0.75)",
      }}
    >
      <CardContent
        sx={{
          alignItems: "center",
          // backgroundColor: "#fff000",
          margin: "auto",
        }}
      >
        <Helmet title="Sign In" />
        {!hideTitle && (
          <>
            <Typography
              // component="h1"
              variant="h2"
              // align="center"
              gutterBottom
            >
              Login
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography component="h2" variant="body1">
                Sign in to your account to continue or
              </Typography>
              <Button>Sign Up</Button>
            </Stack>
          </>
        )}
        {/* <BigAvatar
                  alt="Lucy"
                  src="/static/img/brands/tanika_icon.png"
                /> */}

        <SignInComponent />
      </CardContent>
    </Card>
  );
};

const Testimonial = ({ src, name, testimony }) => {
  return (
    <Box
      sx={{
        // position: "absolute",
        // top: "50%",
        // transform: "translateY(-50%)",
        paddingRight: "50px",
      }}
    >
      <Stack direction={"row"} alignItems="center">
        <img
          src={src}
          style={{ width: "60px", height: "60px", borderRadius: "50%" }}
        />
        <Box sx={{ ml: 3 }}>
          <Typography variant="h6" sx={{ color: "#fff" }}>
            {name}
          </Typography>
          <Stack direction={"row"} justifyContent="flex-start" mt={0}>
            <StarRounded sx={{ color: "#fff" }} />
            <StarRounded sx={{ color: "#fff" }} />
            <StarRounded sx={{ color: "#fff" }} />
            <StarRounded sx={{ color: "#fff" }} />
            <StarRounded sx={{ color: "#fff" }} />
          </Stack>
        </Box>
      </Stack>
      <Typography
        variant="body2"
        sx={{ color: "#fff", fontSize: "17px", mt: 3 }}
      >
        {testimony}
      </Typography>
    </Box>
  );
};

const Footer = ({}) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 0,
    },
  };
  return (
    <Box sx={{ marginTop: "auto" }}>
      <Grid container spacing={3} mt={3}>
        <Grid item xs />
        <Grid xs={12} sm={10} md={10}>
          <Carousel
            responsive={responsive}
            infinite
            autoPlay
            autoPlaySpeed={3000}
            arrows={false}
          >
            {/* <BrandIcon src="https://jwero.ai/wp-content/uploads/2021/04/talla1.png" /> */}
            <BrandIcon src="https://jwero.ai/wp-content/uploads/2021/04/s1.png" />
            <BrandIcon src="https://jwero.ai/wp-content/uploads/2022/01/kc.png" />
            <BrandIcon src="https://jwero.ai/wp-content/uploads/2021/08/ratnalayajewellers.png" />
            <BrandIcon src="https://jwero.ai/wp-content/uploads/2021/04/images-8-e1646711743728.png" />
            <BrandIcon src="https://jwero.ai/wp-content/uploads/2021/04/atul1.png" />
            <BrandIcon src="https://jwero.ai/wp-content/uploads/2021/04/akshaya1.png" />
            <BrandIcon src="https://jwero.ai/wp-content/uploads/2021/04/13-1.png" />
          </Carousel>
        </Grid>
        <Grid item xs />
      </Grid>
      <Box
        sx={{
          alignItems: "center",
          // backgroundColor: "red",
          height: "100%",
          // flex: 1,
          display: { xs: "none", sm: "block" },
          flexGrow: 1,
        }}
      >
        <Stack sx={{ textAlign: "center", mt: 3 }}>
          <Typography color="text.secondary">
            Join over 150 jewellers using Tanika Tech to grow{" "}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

const Navbar = ({ transform, setTransform }) => {
  return (
    <Stack
      direction="row"
      py={4}
      px={4}
      sx={{ zIndex: 100 }}
      justifyContent={"space-between"}
    >
      <img
        // src={Logo}
        src={"/static/img/brands/jwero_logo.png"}
        alt="Jwero One"
        style={{ height: "50px" }}
      />
      {/* <BigAvatar alt="Lucy" src="/static/img/brands/tanika_logo.png" /> */}
      <Stack direction="row">
        <Button
          onClick={() =>
            window.open("https://tanika.tech/privacy-policy/", "_blank")
          }
        >
          Privacy Policy
        </Button>
        <Button
          onClick={() =>
            window.open("https://tanika.tech/terms-of-service/", "_blank")
          }
        >
          Terms & Conditions
        </Button>
        {/* <Button variant="contained">Sign Up</Button> */}
      </Stack>
    </Stack>
  );
};

const BrandIcon = ({ src }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ textAlign: "center" }}>
      <img src={src} style={{ height: "50px", width: "100px" }} />
    </Box>
  );
};
const CustomerImage = ({ src, transform }) => {
  return (
    <Box sx={{ transform }}>
      <img
        src={src}
        style={{
          height: "90px",
          width: "90px",
          borderRadius: "50%",
          border: "2px solid white",
          padding: "5px",
        }}
      />
    </Box>
  );
};

CustomerImage.defaultProps = {
  transform: "translateX(0px)",
};

const allTestimonies = [
  {
    name: "Sanjay Bohra - J Mittalal Thanga Maligai",
    src: "/static/img/testimonials/jmt.png",
    testimony:
      "I must say the dedication of the team is impeccable. They have a very good understanding of the jewellery trade in our country and also know how to bridge the gap between orthodox and modern business practices.",
  },
  {
    name: "Anish Sha | Akshaya Gold",
    src: "/static/img/testimonials/akshayagold.png",
    testimony:
      "Promises kept on time: whether design, development, maintenance, they are always upto the mark. Complete support available round the clock to hear and help you. Our decision to tie-up was a right click at the right time. Thanks Team Tanika! Keep it UP!",
  },
];
