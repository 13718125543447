/* eslint-disable react-hooks/exhaustive-deps */
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  ConfimPopup,
  isArrayWithValues,
  isObjWithValues,
  settingsEndpoint,
} from "../../../../helper";
import { Label } from "../../../customers/components/Info";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { LoadingButton } from "@mui/lab";
import { Msg91Svg } from "../../../../Svgs";
import { RenderPoints } from ".";
let smsChannels = [
  {
    label: "Exotel",
    value: "exotel",
    icon: (
      <img
        src="/static/img/brands/exotelLogo.png"
        width="18px"
        height="18px"
        alt="exotel"
      />
    ),
  },
  { label: "MSG91", value: "msg91", icon: <Msg91Svg /> },
];

let integrationPoints = [
  `<strong> Select </strong> the <strong> Channel </strong>(Service Provider to be Integrated)`,
  `<strong>Add </strong> the <strong> Auth Key </strong>and  <strong>Sender ID </strong>(How to Find API Key and Sender ID)`,
  `Click <strong> Submit </strong>`,
  `Switch to <strong>Templates Tab </strong> to <strong> Manage </strong> All <strong> DLT approved Templates.</strong>`,
];
const Integration = () => {
  const [integrateChannel, setIntegrateChannel] = useState("msg91");
  const smsIntegration = useSelector((state) => state.settings.smsIntegration);
  useEffect(() => {
    setIntegrateChannel(smsIntegration?.selected_channel || "msg91");

    return () => {};
  }, [smsIntegration]);

  return (
    <Box p={"12px 26px"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          alignItems: "center",
          justifyContent: "space-between",
          width: "55%",
        }}
      >
        <Typography variant="h5">Select Channel to Integrate SMS</Typography>
        <TextField
          select
          disabled={isObjWithValues(
            smsIntegration?.[smsIntegration?.selected_channel]
          )}
          sx={{
            minWidth: "150px",
            ".MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              gap: 1,
            },
          }}
          size="small"
          value={integrateChannel}
          onChange={(e) => setIntegrateChannel(e.target.value)}
        >
          {smsChannels?.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{ display: "flex", alignItems: "center", gap: 3 }}
            >
              {option?.icon} {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {/* <Typography variant="h6" sx={{ display: "flex", alignItems: "center", gap: 2 }}> <Msg91Svg /> MSG91 </Typography> */}
      <Box sx={{ my: 5 }}>
        <Authform
          channel={integrateChannel}
          smsIntegration={smsIntegration}
          integrateChannel={integrateChannel}
        />
      </Box>

      <Box>
        <Typography variant="h6">
          To Integrate Your Services Provider:
        </Typography>
        <RenderPoints points={integrationPoints} />
      </Box>
    </Box>
  );
};

export default Integration;

const Authform = ({ channel, smsIntegration, integrateChannel }) => {
  const [hide, setHide] = useState({
    api_key: true,
    api_token: true,
    auth_key: true,
  });
  const fields =
    channel === "exotel"
      ? [
          {
            label: "Your API Key",
            value: "api_key",
            heplerLabel: (
              <Typography>
                (How to get api keys{" "}
                <a
                  href="https://support.exotel.com/support/solutions/articles/3000023019-how-to-find-my-api-key-api-token-account-sid-and-subdomain-"
                  target="_blank"
                >
                  Click here
                </a>
                )
              </Typography>
            ),
            props: {
              type: hide?.api_key ? "password" : "text",
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setHide((pre) => ({ ...pre, api_key: !hide?.api_key }));
                      }}
                      // onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {
                        // showPassword
                        hide?.api_key ? <VisibilityOff /> : <Visibility />
                      }
                    </IconButton>
                  </InputAdornment>
                ),
              },
            },
          },
          {
            label: "Your API Token",
            value: "api_token",
            props: {
              type: hide?.api_token ? "password" : "text",
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      // onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                      onClick={() => {
                        setHide((pre) => ({
                          ...pre,
                          api_token: !hide?.api_token,
                        }));
                      }}
                    >
                      {hide?.api_token ? ( // showPassword
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            },
          },
          { label: "Your SId", value: "sid" },
          { label: "Sender ID", value: "sender_id" },
          // {
          //     label: "Sub Domain",
          //     value: "subdomain",
          //     props: { select: true },
          //     menuList: [
          //         { label: "Singapore cluster", value: "@api.exotel.com" },
          //         { label: "Mumbai cluster", value: "@api.in.exotel.com" },
          //     ],
          // },
        ]
      : [
          {
            label: "Your Auth key",
            value: "auth_key",
            heplerLabel: (
              <Typography>
                (How to get api keys{" "}
                <a
                  href="https://msg91.com/help/MSG91/how-can-i-integrate-api-provided-in-msg91-panel"
                  target="_blank"
                >
                  Click here
                </a>
                )
              </Typography>
            ),

            props: {
              type: hide?.auth_key ? "password" : "text",
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setHide((pre) => ({
                          ...pre,
                          auth_key: !hide?.auth_key,
                        }));
                      }}
                      // onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {
                        // showPassword
                        hide?.auth_key ? <VisibilityOff /> : <Visibility />
                      }
                    </IconButton>
                  </InputAdornment>
                ),
              },
            },
          },
          { label: "Sender ID", value: "sender_id" },
        ];

  const [formState, setFormState] = useState({ subdomain: "@api.exotel.com" });
  const [hasError, setHasError] = useState({});
  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const dispatch = useDispatch();
  const onEditForm = (payload) => {
    setHasError({});
    setFormState((pre) => ({ ...pre, ...(payload || {}) }));
  };

  useEffect(() => {
    setHasError({});
    if (channel === "exotel") {
      setFormState((pre) => ({ subdomain: "@api.exotel.com" }));
    } else setFormState({});

    return () => {};
  }, [channel]);

  useEffect(() => {
    console.log(smsIntegration);
    // if (isObjWithValues(smsIntegration))
    setFormState(smsIntegration?.[smsIntegration?.selected_channel] || {});

    return () => {};
  }, [smsIntegration, integrateChannel]);

  const onSubmitHandler = async () => {
    let hasError = false;
    for (const defaultObj of fields) {
      if (!formState?.[defaultObj?.value]) {
        hasError = true;
        setHasError((pre) => ({ ...pre, [defaultObj?.value]: true }));
      }

      if (defaultObj?.value === "sender_id") {
        const pattern = /^[A-Z]{6}$/;

        const isValid = pattern.test(formState?.[defaultObj?.value]);
        hasError = !isValid;

        setHasError((pre) => ({ ...pre, [defaultObj?.value]: !isValid }));
      }
    }

    if (hasError) return;
    setLoading(true);
    try {
      let res = await settingsEndpoint({
        endpoint: "sms_integration",
        method: "POST",
        data: {
          selected_channel: channel,
          [channel]: formState,
        },
      });
      if (res) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            smsIntegration: res,
          },
        });
      }
    } catch (error) {}
    setLoading(false);
  };
  const onRevoke = async () => {
    setConfirmLoading(true);
    try {
      await settingsEndpoint({
        endpoint: "sms_integration",
        method: "DELETE",
        params: {
          force_delete: 1,
        },
        data: {
          id: channel,
          // selected_channel: "",
        },
      });
      // if (res) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          smsIntegration: {},
        },
      });
      // }
      setOpenConfirm(false);
    } catch (error) {}
    setConfirmLoading(false);
  };

  return (
    <Box sx={{ width: "55%" }}>
      <Stack sx={{ rowGap: 3 }}>
        {fields?.map((obj) => {
          return (
            <Stack key={obj?.label}>
              <Stack direction={"row"} gap={2}>
                <Label>{obj?.label} </Label>
                {obj?.heplerLabel}
              </Stack>
              <TextField
                size="small"
                value={formState?.[obj?.value] || ""}
                onChange={(e) => {
                  onEditForm({ [obj?.value]: e.target.value });
                }}
                disabled={isObjWithValues(
                  smsIntegration?.[smsIntegration?.selected_channel]
                )}
                {...(obj?.props || {})}
                error={hasError?.[obj?.value]}
                helperText={
                  hasError?.[obj?.value]
                    ? `${obj?.label} should be capital and 6 letters required`
                    : ""
                }
              >
                {isArrayWithValues(obj?.menuList) &&
                  obj?.menuList?.map((o) => {
                    return <MenuItem value={o.value}>{o.label}</MenuItem>;
                  })}
              </TextField>
            </Stack>
          );
        })}
        <Stack direction={"row"} justifyContent={"flex-end"}>
          {isObjWithValues(
            smsIntegration?.[smsIntegration?.selected_channel]
          ) ? (
            <LoadingButton
              loading={loading}
              color="error"
              variant="contained"
              onClick={() => setOpenConfirm(true)}
            >
              Revoke
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={() => onSubmitHandler()}
            >
              Integrate
            </LoadingButton>
          )}
        </Stack>
      </Stack>
      <ConfimPopup
        button={
          <LoadingButton
            loading={confirmLoading}
            variant="contained"
            color="error"
            onClick={() => onRevoke()}
          >
            Revoke
          </LoadingButton>
        }
        heading={`Are you sure you want to Revoke ${channel} SMS Integrations?`}
        subtitle={"This action can't be undone!"}
        open={openConfirm}
        handleClose={() => setOpenConfirm(false)}
      />
    </Box>
  );
};
