import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SubsectionHeader from "../../../components/SubsectionHeader";
import { Cached, MoreHoriz, MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../../components/CustomTable";
import {
  fetchJweroCampaigns,
  fetchJweroTriggers,
} from "../../../../../redux/actions/marketingActions";
import {
  formatDate,
  isArray,
  isArrayWithValues,
  isObjWithValues,
  validateNumber,
} from "jwero-javascript-utils";
import { useDispatch, useSelector } from "react-redux";
import {
  backendApiCall,
  backendApiEndpoints,
  formatServerValue,
} from "../../../../../helper";
import CustomChip from "../../../../components/CustomChip";
import {
  SecondaryLabel,
  SecondaryLabelSmall,
} from "../../../../../helper/utility/Labels";
import {
  deleteFlowInRedux,
  deleteTriggerInRedux,
  updateFlowInRedux,
} from "../../../../../redux/actions/flowsActions";
import { setGlobalToast } from "../../../../../redux/actions/helperActions";
import { ALL_TRIGGERS } from "../../../utils";
import CustomConfirmationDialog from "../../../../../helper/utility/CustomConfirmationDialog";
import { campaignChannelIcon } from "../../../../customers/components/CampaignHistory";
import HorizontalStack from "../../../../../helper/utility/HorizontalStack";

function Trigger() {
  const navigate = useNavigate();
  const [triggers, setTriggers] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [confirmationProps, setConfirmationProps] = React.useState({});

  const confirmationRef = React.useRef();

  const triggersList = useSelector((state) => state?.marketing?.triggers);
  const fetchingJweroTriggers = useSelector(
    (state) => state?.marketing?.fetchingJweroTriggers
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (isArray(triggersList)) setTriggers(triggersList);
  }, [triggersList]);
  useEffect(() => {
    if (!isArrayWithValues(triggersList)) dispatch(fetchJweroTriggers());
  }, []);

  const onCreateCampaign = () => {
    navigate(`/trigger/create`);
  };

  const onEditPress = async (row) => {
    navigate(`/trigger/${row?.id}`);
  };

  const onDelete = async (item) => {
    try {
      setDeleteLoading(true);
      confirmationRef?.current?.setLoading(true);

      let res = await backendApiCall({
        endpoint: `${backendApiEndpoints.flow}/${item?.id}`,
        method: "DELETE",
      });
      if (res?.success) {
        dispatch(deleteTriggerInRedux({ id: item?.id }));
        dispatch(
          setGlobalToast({
            show: true,
            message: "Trigger has been deleted",
            severity: "success",
          })
        );
        onCloseConfirmation();
      } else {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Unable to delete trigger",
            severity: "error",
          })
        );
      }
    } catch (error) {
      console.log(error, "<<<< delete error");
    } finally {
      setDeleteLoading(false);
      confirmationRef?.current?.setLoading(false);
    }
  };

  const onDeletePress = (item) => {
    if (item?.status === "publish") return publishDeleteWarning();
    setConfirmationProps({
      title: `Delete trigger ${item?.title}?`,
      subtitle: "This action cannot be undone",
      submitButtonProps: {
        label: "Delete",
        onClick: async () => await onDelete(item),
        loading: deleteLoading,
        color: "error",
      },
    });
    onOpenConfirmation();
  };

  const publishDeleteWarning = (item) => {
    setConfirmationProps({
      title: `Cannot delete published trigger!`,
      subtitle: "Please make it draft to delete it",
      closeButtonProps: { hide: true },
      submitButtonProps: {
        label: "Got it",
        onClick: () => onCloseConfirmation(),
        // color: "info",
      },
    });
    onOpenConfirmation();
  };

  const onOpenConfirmation = () => {
    confirmationRef?.current?.open();
  };
  const onCloseConfirmation = () => {
    confirmationRef?.current?.close();
  };
  return (
    <Box sx={{ mt: 3 }}>
      <SubsectionHeader
        title={"Triggers List"}
        primaryButtonProps={{
          startIcon: (
            <Cached
              className={fetchingJweroTriggers ? "infinite_rotate" : ""}
            />
          ),
          label: "Refresh",
          variant: "outlined",
          disabled: fetchingJweroTriggers,
          onClick: () =>
            dispatch(
              fetchJweroTriggers({
                onSuccess: () => {
                  dispatch(
                    setGlobalToast({
                      show: true,
                      message: "Triggers refreshed successfully",
                      severity: "success",
                    })
                  );
                },
              })
            ),
        }}
        right={
          <Button
            size="small"
            variant="contained"
            disabled={validateNumber(triggersList?.length) >= 10}
            onClick={onCreateCampaign}
          >
            Create Trigger
          </Button>
        }
      />
      <Box sx={{ mt: 4 }}>
        <CustomTable
          rows={triggersList || []}
          tableHeadCells={triggerHeads}
          tableRow={CustomTableRow}
          tableRowProps={{ onEditPress, onDeletePress }}
        />
      </Box>

      <CustomConfirmationDialog ref={confirmationRef} {...confirmationProps} />
    </Box>
  );
}

export default Trigger;

const typesFromNode = {
  "whatsapp-template": "whatsapp",
  email: "email",
  "push-notification": "push_notification",
  sms: "sms",
};

const CustomTableRow = ({ row, onEditPress, onDeletePress }) => {
  const [statusChecked, setStatusChecked] = React.useState(false);
  const [updatingStatus, setUpdatingStatus] = React.useState(false);
  const [action, setAction] = useState({});
  const [channelsUsed, setChannelsUsed] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setAction(ALL_TRIGGERS?.find((i) => i?.value === row?.event_name));
    let channels = row?.nodes
      ?.map((i) => {
        if (Object.keys(typesFromNode)?.includes(i?.type))
          return typesFromNode[i?.type];
      })
      ?.filter(Boolean);
    channels = [...new Set(channels)];
    setChannelsUsed(channels);
  }, [row]);

  React.useEffect(() => {
    setStatusChecked(row?.status === "publish");
  }, []);

  const onStatusChange = (value) => {
    setStatusChecked(value);
    onStatusUpdate(value);
  };

  const onStatusUpdate = async (checked) => {
    try {
      setUpdatingStatus(true);
      let res = await backendApiCall({
        endpoint: `${backendApiEndpoints.flow}/${row?.id}`,
        method: "PUT",
        data: { status: checked ? "publish" : "draft" },
      });
      if (res?.success && isObjWithValues(res?.data)) {
        dispatch(updateFlowInRedux({ flow: res?.data }));
        dispatch(
          setGlobalToast({
            show: true,
            message: "Status has been updated",
            severity: "success",
          })
        );
      }
    } catch (error) {
    } finally {
      setUpdatingStatus(false);
    }
  };
  return (
    <TableRow
      hover
      // onClick={(event) => handleClick(event, row.id)}
      // role="."
      // aria-checked={isItemSelected}
      tabIndex={-1}
      // key={row.id}
      // selected={isItemSelected}
      sx={{ cursor: "pointer" }}
    >
      {/* <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell> */}
      <TableCell
        component="th"
        // id={labelId}
        scope="row"
        onClick={() => onEditPress(row)}
        sx={{ cursor: "pointer" }}
        // padding="none"
      >
        {`${row?.title}`}
      </TableCell>
      <TableCell>
        <SecondaryLabel>{action?.label}</SecondaryLabel>
      </TableCell>
      <TableCell>
        <HorizontalStack spacing={2}>
          {channelsUsed?.map((i) => {
            return <Box>{campaignChannelIcon[i]}</Box>;
          })}
        </HorizontalStack>
      </TableCell>
      <TableCell>
        <SecondaryLabel>
          {validateNumber(row?.meta_data?.flow_trigger_count) || "N/A"}
        </SecondaryLabel>
      </TableCell>
      <TableCell align="right">
        <SecondaryLabel>
          {formatDate(new Date(row?.created_at), "DD MMM YYYY")}
        </SecondaryLabel>
        <SecondaryLabelSmall>
          {formatDate(new Date(row?.created_at), "hh:mm A")}
        </SecondaryLabelSmall>
      </TableCell>
      <TableCell align="right">
        <SecondaryLabel>
          {formatDate(new Date(row?.updated_at), "DD MMM YYYY")}
        </SecondaryLabel>
        <SecondaryLabelSmall>
          {formatDate(new Date(row?.updated_at), "hh:mm A")}
        </SecondaryLabelSmall>
      </TableCell>

      <TableCell align="right">
        <Switch
          disabled={updatingStatus}
          checked={statusChecked}
          onChange={(e) => onStatusChange(e.target.checked)}
        />
      </TableCell>
      <TableCell align="right">
        <FlowRowActions
          onEdit={() => onEditPress(row)}
          onDelete={() => onDeletePress(row)}
        />
        {/* <IconButton>
          <MoreHoriz />
        </IconButton> */}
      </TableCell>
    </TableRow>
  );
};

function FlowRowActions({ onEdit, onDelete }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditPress = () => {
    onEdit();
    handleClose();
  };
  const onDeletePress = () => {
    onDelete();
    handleClose();
  };

  return (
    <div>
      <IconButton
        // onClick={() => onEditPress(row?.id)}
        size="small"
        // sx={{ backgroundColor: "text.secondary" }}
        onClick={handleClick}
        // onClick={() => onEditPress(row)}
      >
        <MoreHoriz />
      </IconButton>
      {/* <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={onEditPress}>Edit</MenuItem>
        <MenuItem onClick={onDeletePress}>Delete</MenuItem>
      </Menu>
    </div>
  );
}

const triggerHeads = [
  {
    id: "triggers",
    numeric: false,
    disablePadding: true,
    label: "Triggers",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
  {
    id: "channels",
    numeric: false,
    disablePadding: true,
    label: "Channels",
  },
  {
    id: "interactions",
    numeric: false,
    disablePadding: true,
    label: "Interactions",
  },
  {
    id: "created",
    numeric: true,
    disablePadding: false,
    label: "Created on",
  },
  {
    id: "updatd",
    numeric: true,
    disablePadding: false,
    label: "Last updated",
  },
  // {
  //   id: "carbs",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Sent By",
  // },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  // {
  //   id: "Actions",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Sent To",
  // },
  // {
  //   id: "delivered_to",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Delivered To",
  // },
  // {
  //   id: "read_by",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Read By",
  // },
  // {
  //   id: "failed",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Failed",
  // },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];
