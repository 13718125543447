import { Box } from "@mui/material";
import React, { useEffect } from "react";
import MainSidebar from "./components/MainSidebar";
import Header from "./components/Header";
import { getSegmentList } from "../../redux/actions/crmActions";
import { useDispatch, useSelector } from "react-redux";
import { isArrayWithValues, isObjWithValues } from "jwero-javascript-utils";
import {
  getAllTemplates,
  getEmailTemplate,
  getTempletes as getWhatsappTempletes,
} from "../../redux/actions/chatActions";
import { default as OldMarketingAutomation } from "../marketing_automation_old";
import { isDev } from "../../helper";

function MarketingAutomation() {
  const customerSegments = useSelector((state) => state.chats.customerSegments);
  const smsIntegration = useSelector((state) => state.settings.smsIntegration);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isObjWithValues(customerSegments)) dispatch(getSegmentList());
  }, []);
  const reduxWhatsappTemplete = useSelector(
    (state) => state.chats.whatsappTempletes
  );
  const reduxEmailTemplates = useSelector(
    (state) => state.chats.emailTemplates
  );
  const smsTemplates = useSelector((state) => state.chats.smsTemplates);
  const marketing_automation_view = useSelector(
    (state) => state.persist.marketing_automation_view
  );

  useEffect(() => {
    if (!isArrayWithValues(reduxWhatsappTemplete))
      dispatch(getWhatsappTempletes());

    if (!isObjWithValues(reduxEmailTemplates)) dispatch(getEmailTemplate());
  }, []);

  useEffect(() => {
    if (
      !isArrayWithValues(smsTemplates) &&
      smsIntegration?.selected_channel === "msg91"
    )
      dispatch(
        getAllTemplates(
          smsIntegration?.[smsIntegration?.selected_channel]?.template_ids ||
            [],
          smsIntegration?.[smsIntegration?.selected_channel]?.auth_key
        )
      );
  }, [smsIntegration]);

  // if (marketing_automation_view === "old" || !marketing_automation_view)
  // if (isDev()) return <OldMarketingAutomation />;
  // else
  return (
    <Box>
      <Header />
      <MainSidebar />
    </Box>
  );
}

export default MarketingAutomation;
