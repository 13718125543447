import { backendApiCall } from "../../../../../../helper";
import { backendEndpoints } from "../../../../../chat_new/apiFunctions";
// import { BACKEND_URL } from "../../../../utils";
// import { postData } from "../utils/fetchTemplate";

export const saveFlow = async (obj) => {
  await backendApiCall({
    endpoint: `${backendEndpoints.test_bot}`,
    method: "POST",
    data: obj,
  });
  // const url = `${BACKEND_URL}/api/test-chatbot`;
  // await postData(url, obj);
  return true;
};
