import { Add, Delete, Edit, YouTube } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { tutorialData } from "../../App";
// import { isArrayWithValues } from "../../helper";
import {
  dialogStyle,
  getAxiosError,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  // token,
  // website,
} from "../../helper";
import {
  setGlobalToast,
  setGlobalTutorial,
} from "../../redux/actions/helperActions";
import { getDropdownData } from "../../redux/actions/persistActions";
import {
  addShippingsInRedux,
  DeleteShippingsInRedux,
  EDIT_SETTINGS,
  getShippings,
  removeShippingInRedux,
  updateShippingsInRedux,
} from "../../redux/actions/settingActions";
import { FormLabel } from "./Email";
import CustomConfirmationDialog from "../../helper/utility/CustomConfirmationDialog";
const methods = [
  { label: "Flat rate", value: "flat_rate" },
  { label: "Free Shipping", value: "free_shipping" },
  { label: "Local pickup", value: "local_pickup" },
];

const initalForm = {
  name: "",
  loaction: [],
  methods: [],
  postcode: [],
  cost: "",
  hasPostCode: false,
};
const Shipping = () => {
  const [shippings, setShippings] = useState([]);
  const [editId, setEditId] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchingShippings, setFetchingShippings] = useState(false);
  const [shipToCountries, setShipToCountries] = useState([]);
  const [shipCountriesValues, setShipCountriesValues] = useState([]);
  const [addForm, setAddForm] = useState(initalForm);
  const [deleting, setDeleting] = useState(false);

  const editAddForm = (payload) => {
    setAddForm((pre) => ({ ...pre, ...payload }));
  };
  const reduxShippings = useSelector((state) => state.settings.shippings);
  const reduxFetchingShippings = useSelector(
    (state) => state.settings.fetchingShippings
  );
  const reduxShipToCountries = useSelector(
    (state) => state.settings.shipToCountries
  );

  // console.log(reduxShippings);
  const allCountries = useSelector((state) => state.persist.countries) || [];
  const allStates = useSelector((state) => state.persist.states);
  const [allRegoins, setAllRegoins] = useState([]);

  const confirmationRef = React.useRef();
  const [confirmationProps, setConfirmationProps] = React.useState({});
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const onOpenConfirmation = () => {
    confirmationRef?.current?.open();
  };
  const onCloseConfirmation = () => {
    confirmationRef?.current?.close();
  };

  useEffect(() => {
    if (isArrayWithValues(reduxShipToCountries)) {
      setShipToCountries(reduxShipToCountries);
      if (isArrayWithValues(allCountries)) {
        setShipCountriesValues(
          reduxShipToCountries.map((str) =>
            allCountries.find((obj) => obj?.code == str)
          )
        );
      }
    }
  }, [reduxShipToCountries]);
  useEffect(() => {
    if (!isArrayWithValues(allCountries)) dispatch(getDropdownData());
  }, []);
  useEffect(() => {
    setFetchingShippings(reduxFetchingShippings || false);
  }, [reduxFetchingShippings]);

  useEffect(() => {
    let regoinsGroup = [];
    if (
      isArrayWithValues(reduxShipToCountries) &&
      isArrayWithValues(allCountries)
    ) {
      let shipCountries = reduxShipToCountries.map((item) =>
        allCountries.find((obj) => obj.code === item)
      );

      for (const obj of shipCountries) {
        let stateArray = allStates[obj?.code].map((item) => ({
          ...item,
          type: "state",
          countryCode: obj.code,
        }));

        const regionaArray = [{ ...obj, type: "country" }, ...stateArray];
        regoinsGroup = [...regoinsGroup, ...regionaArray];
      }
    }
    setAllRegoins(regoinsGroup);
  }, [reduxShipToCountries, allCountries]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isArrayWithValues(reduxShippings)) setShippings(reduxShippings);
  }, [reduxShippings]);

  useEffect(() => {
    if (!isArrayWithValues(reduxShippings)) {
      dispatch(getShippings());
    }
  }, []);

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  const onDeleteShipping = async (obj) => {
    let website = getWebsite();
    let token = getToken();
    if (!website || !token) return false;
    console.log(obj);
    setDeleteLoading(obj?.id);
    try {
      const res = await axios({
        url: `${website}/wp-json/wc/v3/shipping/zones/${obj?.id}`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "DELETE",
        params: {
          force: true,
        },
      });
      console.log(res);
      dispatch(DeleteShippingsInRedux(obj?.id));
      dispatch(
        setGlobalToast({
          show: true,
          message: `Shipping has been deleted successfully`,
          severity: "success",
        })
      );
    } catch (error) {
      console.log(getAxiosError(error));
    } finally {
      setDeleteLoading(false);
      onCloseConfirmation();
    }
  };

  useEffect(() => {
    console.log(showForm, addForm);
    if (isObjWithValues(showForm)) {
      let payload = {
        name: "",
        loaction: [],
        methods: [],
        postcode: [],
        cost: "",
        hasPostCode: false,
      };
      let costObj = showForm?.methods.find(
        (obj) => obj?.method_id === "flat_rate"
      );
      let hasPostCode = false;
      let postcode = [];
      let setlocation = showForm?.loaction?.map((name) => {
        let obj = allRegoins?.find((item) => item?.label === name);
        console.log(obj, name, allCountries, allRegoins);
        if (!isObjWithValues(obj)) {
          hasPostCode = true;
          postcode.push(name);
          return false;
        } else {
          return obj;
        }
      });

      setlocation = setlocation?.filter(Boolean);
      setAddForm({
        loaction: setlocation,
        hasPostCode,
        postcode,
        methods: showForm?.methods?.map((obj) => ({
          value: obj?.method_id,
          label: obj?.title,
          id: obj?.id,
        })),
        name: showForm?.name,
        cost: costObj?.settings?.cost?.value,
      });
    }
  }, [showForm]);

  const onSubmitForm = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website || !token) return false;
    console.log(addForm);
    setLoading(true);
    try {
      const zoneRes = await axios({
        url: `${website}/wp-json/wc/v3/shipping/zones${
          showForm?.id ? `/${showForm?.id}` : ""
        }`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: showForm?.id ? "PUT" : "POST",
        data: {
          name: addForm?.name,
        },
      });

      let setlocation = [];
      console.log(addForm?.loaction);
      setlocation = addForm?.loaction?.map((obj) => {
        let { type, countryCode, code } = obj;
        let putObj = {
          code: `${countryCode ? `${countryCode}:` : ""}${code}`,
          type: type,
        };
        return putObj;
      });
      if (isArrayWithValues(addForm?.postcode)) {
        let arr = addForm?.postcode.map((code) => ({
          type: "postcode",
          code: code,
        }));
        setlocation = [...setlocation, ...arr];
      }
      const locationRes = await axios({
        url: `${website}/wp-json/wc/v3/shipping/zones/${zoneRes?.data?.id}/locations`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "PUT",
        data: setlocation,
      });
      const { data: currentMethods } = await axios({
        url: `${website}/wp-json/wc/v3/shipping/zones/${zoneRes?.data?.id}/methods`,
        headers: {
          Authorization: `Basic ${token}`,
        },
      });

      // console.log(addForm?.methods, "<<<<< addForm?.methods");
      // console.log(currentMethods, "<<<<< currentMethods");
      let methodRes = [];

      let removedMethods = currentMethods?.filter(
        (i) =>
          // !addForm?.methods?.find((j) => j?.value === i?.method_id) &&
          // !addForm?.methods?.find((j) => j?.value?.id === i?.method_id) &&
          !addForm?.methods?.find((j) => j?.id === i?.id)
      );

      // console.log(removedMethods, "<<<<<<<<<removedMethods");

      for (let method of removedMethods) {
        await axios({
          url: `${website}/wp-json/wc/v3/shipping/zones/${
            zoneRes?.data?.id
          }/methods${method?.id ? `/${method?.id}` : ""}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          params: { force: true },
          method: "DELETE",
        });
      }

      for await (const method_id of addForm?.methods) {
        let postData = { method_id: method_id?.value };

        const methodAssign = await axios({
          url: `${website}/wp-json/wc/v3/shipping/zones/${
            zoneRes?.data?.id
          }/methods${method_id?.id ? `/${method_id?.id}` : ""}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: method_id?.id ? "PUT" : "POST",
          data: postData,
        });
        methodRes = [...methodRes, methodAssign?.data];
        console.log(methodAssign?.data);
      }
      let costRes = {};
      console.log(addForm?.methods);
      if (addForm?.methods?.find((obj) => obj?.value === "flat_rate")) {
        let instance_id = methodRes.find(
          (obj) => obj.method_id === "flat_rate"
        );
        const setCost = await axios({
          url: `${website}/wp-json/wc/v3/shipping/zones/${zoneRes?.data?.id}/methods/${instance_id?.instance_id}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: {
            // data: {
            settings: {
              cost: addForm?.cost,
            },
            // },
          },
        });
        costRes = setCost?.data;
      }

      console.log(zoneRes.data, locationRes.data, methodRes, costRes);
      methodRes =
        methodRes?.map((obj) => (obj?.id === costRes?.id ? costRes : obj)) ||
        methodRes;
      if (
        isObjWithValues(zoneRes.data) &&
        isArrayWithValues(locationRes.data) &&
        isArrayWithValues(methodRes)
      ) {
        dispatch(
          setGlobalToast({
            show: true,
            message: `${
              showForm?.id ? "Updated" : "Create"
            } Shipping successfully`,
            severity: "success",
          })
        );
        let postObject = {
          id: zoneRes.data?.id,
          name: zoneRes.data?.name,
          loaction: [
            ...addForm.loaction.map((obj) => obj.label),
            ...(addForm?.postcode || {}),
          ],
          methods: methodRes,
        };
        console.log(postObject);
        showForm?.id
          ? dispatch(
              updateShippingsInRedux({ ...postObject, id: showForm?.id })
            )
          : dispatch(addShippingsInRedux(postObject));
        setShowForm(false);
      }
      // dispatch(removeShippingInRedux(obj.id));
    } catch (error) {
      console.log(getAxiosError(error));
    }
    setLoading(false);
  };

  const onAddCountry = async (array) => {
    let website = getWebsite();
    let token = getToken();
    if (!website || !token) return false;
    setShipCountriesValues(array);

    const res = await axios({
      url: `${website}/wp-json/store/v1/settings/woocommerce_specific_ship_to_countries`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "POST",
      data: {
        setting: "woocommerce_specific_ship_to_countries",
        data: array.map((obj) => obj.code),
      },
    });
    if (res?.data?.type === "success") {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          shipToCountries: res?.data?.data,
        },
      });
      dispatch(
        setGlobalToast({
          show: true,
          message: "Update Country",
          severity: "success",
        })
      );
    }
    console.log(res);
  };
  console.log(shippings);
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};

  const onDeletePress = (row) => {
    setConfirmationProps({
      title: `Delete shipping ${row?.name}?`,
      subtitle: "This action cannot be undone",
      submitButtonProps: {
        label: "Delete",
        onClick: () => onDeleteShipping(row),
        // loading: deleteLoading,
        color: "error",
      },
    });
    onOpenConfirmation();
  };

  return (
    <Box>
      <Stack
        alignItems={"center"}
        justifyContent="flex-end"
        direction="row"
        // p={2}
        // border="0.5px solid #d1d1d1 "
        // borderRadius={"6px"}
      >
        <IconButton
          onClick={() =>
            dispatch(
              setGlobalTutorial({
                show: true,
                url: tutorialData?.shipping_settings?.link,
                title: tutorialData?.shipping_settings?.title,
              })
            )
          }
        >
          <YouTube />
        </IconButton>
        <Button
          size="small"
          onClick={() => {
            setShowForm(true);
          }}
          startIcon={<Add />}
          sx={{ mr: 2 }}
          variant="contained"
          //   disabled={!isDefaultCurrency}
        >
          Add New Shipping Zone
        </Button>
      </Stack>
      <Card sx={{ mt: 3, padding: "20px" }} className="shadow_card">
        <Stack sx={{ mb: 4 }}>
          <FormLabel>Ship To Specific Countries</FormLabel>
          <Autocomplete
            disablePortal
            size="small"
            id="combo-box-demo"
            options={allCountries || []}
            value={shipCountriesValues || []}
            multiple
            onChange={(e, newValue) => onAddCountry(newValue)}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Countries" />
            )}
          />
        </Stack>

        {false && (
          <Box>
            {isArrayWithValues(shippings) &&
              shippings.map((row, index) => (
                <CardContent key={index}>
                  <Box>
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Typography variant="h6">{row.name}</Typography>
                      <Stack direction="row">
                        {deleting === row?.id ? (
                          <CircularProgress
                            style={{ height: "20px", width: "20px" }}
                          />
                        ) : (
                          <IconButton
                            size="small"
                            onClick={() => {
                              onDeletePress(row);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        )}
                        {/* <IconButton
                          size="small"
                        //    onClick={() => onEditPress(row)}
                        >
                          <Edit />
                        </IconButton> */}
                      </Stack>
                    </Stack>
                    <Grid container columnSpacing={2}>
                      <Grid item xs={12}>
                        <Typography fontSize="14px" mt={2}>
                          Regions
                        </Typography>
                        <Stack
                          direction="row"
                          flexWrap={"wrap"}
                          sx={{
                            border: "0.4px solid #bababa",
                            p: 1,
                            py: 2,
                            mt: 1,
                            borderRadius: "8px",
                          }}
                        >
                          {isArrayWithValues(row.loaction) &&
                            row.loaction.map((loaction, i) => {
                              return (
                                <Typography>
                                  {loaction}
                                  {i !== row.loaction.length - 1 ? `, ` : ""}
                                </Typography>
                              );
                            })}
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography fontSize="14px" mt={2}>
                          Shipping Methods
                        </Typography>
                        <Stack
                          direction="row"
                          flexWrap={"wrap"}
                          sx={{
                            border: "0.4px solid #bababa",
                            p: 1,
                            py: 2,
                            mt: 1,
                            borderRadius: "8px",
                          }}
                        >
                          {isArrayWithValues(row.methods) &&
                            row.methods.map((method, i) => {
                              return (
                                <Typography>
                                  {method}
                                  {i !== row.methods.length - 1 ? `, ` : ""}
                                </Typography>
                              );
                            })}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              ))}
          </Box>
        )}

        {
          <TableContainer
            // component={Paper}
            sx={{
              mt: 3,
              borderRadius: "10px",
              boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>SR. No.</TableCell>
                  <TableCell align="left">Zone Name</TableCell>
                  <TableCell align="left">Regions</TableCell>
                  <TableCell align="left">Shipping Methods</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fetchingShippings && (
                  <>
                    {Array(8)
                      .fill(null)
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">
                            <Skeleton
                              variant="text"
                              width={30}
                              height={30}
                              // sx={{ marginLeft: 5 }}
                            />
                          </TableCell>

                          <TableCell align="left">
                            <Skeleton
                              variant="text"
                              width={"100%"}
                              height={30}
                              // sx={{ marginLeft: 5 }}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <Skeleton
                              variant="text"
                              width={"100%"}
                              height={30}
                              // sx={{ marginLeft: 5 }}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <Skeleton
                              variant="text"
                              width={"100%"}
                              height={30}
                              // sx={{ marginLeft: 5 }}
                            />
                          </TableCell>
                          <TableCell>
                            <Stack direction={"row"} sx={{ gap: "10px" }}>
                              <Skeleton
                                variant="text"
                                width={30}
                                height={30}
                                // sx={{ marginLeft: 5 }}
                              />
                              <Skeleton
                                variant="text"
                                width={30}
                                height={30}
                                // sx={{ marginLeft: 5 }}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                )}
                {isArrayWithValues(shippings) &&
                  shippings.map((row, index) => (
                    <TableRow
                      key={index}
                      hover
                      // onClick={() => onEditPress(row)}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                    >
                      <TableCell align="left">
                        <Typography>{++index}</Typography>
                      </TableCell>
                      <TableCell component="th">
                        <Typography>{row.name}</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Stack>
                          {isArrayWithValues(row.loaction) && (
                            <Stack
                              my={1}
                              direction="row"
                              alignItems={"center"}
                              maxWidth={380}
                            >
                              <Typography
                                sx={{
                                  display: "-webkit-box",
                                  overflow: "hidden",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 3, // Limits text to three lines
                                  textOverflow: "ellipsis", // Adds ellipsis at the end
                                  maxWidth: "100%",
                                }}
                              >
                                {row.loaction?.join(", ")}
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        {isArrayWithValues(row.methods) &&
                          row.methods.map((method) => {
                            return (
                              <Stack
                                my={1}
                                direction="row"
                                alignItems={"center"}
                              >
                                <Typography>{method?.title}</Typography>
                              </Stack>
                            );
                          })}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton onClick={() => setShowForm(row)}>
                          <Edit />
                        </IconButton>
                        {deleting === row?.id ? (
                          <CircularProgress
                            style={{ height: "20px", width: "20px" }}
                          />
                        ) : (
                          <IconButton
                            size="small"
                            onClick={() => {
                              onDeletePress(row);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>

                      {/* <TableCell align="left">{row.protein}</TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Card>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={showForm}
        onClose={() => {
          setShowForm(false);
          setAddForm(initalForm);
        }}
        sx={dialogStyle}
        {...globalPopupStyle}
      >
        <DialogTitle>
          {isObjWithValues(showForm) ? "Update" : "Add"} Shipping Zone
        </DialogTitle>

        <DialogContent>
          {/* <Collapse in={alert.show}>
            <Alert severity={alert.severity}>{alert.message}</Alert>
          </Collapse> */}
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} md={12}>
              <TextField
                variant="standard"
                value={addForm.name}
                onChange={(e) => editAddForm({ name: e.target.value })}
                label="Zone Name"
                autoFocus
                fullWidth
                // disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                openOnFocus
                id="combo-box-demo"
                value={addForm?.loaction}
                getOptionLabel={(option) => option?.label || ""}
                options={allRegoins}
                onChange={(e, newValue) => {
                  editAddForm({ loaction: newValue });
                  //   console.log(newValue);
                  //   console.log({
                  //     type: newValue.type,
                  //     code: `${newValue.countryCode}:${newValue.code}`,
                  //   });
                }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      marginLeft: option.type === "state" ? "15px" : "",
                      fontWeight: option.type === "country" ? "500" : "",
                    }}
                  >
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Zone Regions"
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              <Typography>Limit to specific ZIP/postcodes</Typography>
              <Checkbox
                onChange={(e) => {
                  editAddForm({ hasPostCode: e.target.checked });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Collapse in={addForm.hasPostCode}>
                <Autocomplete
                  multiple
                  options={[]}
                  value={addForm.postcode}
                  onChange={(e, newValue) =>
                    editAddForm({ postcode: newValue })
                  }
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      placeholder="Pin code"
                    />
                  )}
                />
                {/* <TextField
                  variant="standard"
                  size="small"
                  fullWidth
                  type="number"
                  label="PostCode"
                  value={addForm.postcode}
                  onChange={(e) => {
                    editAddForm({ postcode: e.target.value });
                  }}
                /> */}
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="tags-standard"
                options={methods?.filter(
                  (i) => !addForm.methods?.find((j) => j?.value === i?.value)
                )}
                value={addForm.methods}
                onChange={(e, newValue) =>
                  editAddForm({
                    methods: newValue,
                  })
                }
                // getOptionLabel={(option) => option.title}
                // defaultValue={[top100Films[13]]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Shipping Methods"
                  />
                )}
              />
              {/* <Autocomplete
                multiple
                id="tags-filled"
                options={methods}
                // defaultValue={[top100Films[13].title]}
                value={addForm.methods}
                onChange={(e, newValue) => editAddForm({
                  methods: newValue
                })}
                // freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Shipping Methods"

                  />
                )}
              /> */}
              {/* <Select
                variant="standard"
                // label="Shipping Methods"
                multiple
                size="small"
                fullWidth
                value={addForm.methods}
                onChange={(e) => {
                  editAddForm({
                    methods:
                      typeof value === "string"
                        ? e.target.value.split(",")
                        : e.target.value,
                  });
                }}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value="free_shipping">Free Shipping</MenuItem>
                <MenuItem value="flat_rate">Flate Rate</MenuItem>
                <MenuItem value="local_pickup">Local Pickup</MenuItem>
              </Select> */}
            </Grid>
            <Grid item xs={12}>
              <Collapse
                in={addForm.methods.find((obj) => obj.value === "flat_rate")}
              >
                {console.log(addForm)}
                <TextField
                  value={addForm?.cost}
                  onChange={(e) =>
                    editAddForm({
                      cost: e.target.value,
                    })
                  }
                  size="small"
                  fullWidth
                  label="Flat Rate Price"
                  variant="standard"
                />
              </Collapse>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowForm(false);
              setAddForm(initalForm);
            }}
          >
            Cancel
          </Button>
          <LoadingButton loading={loading} onClick={onSubmitForm}>
            {isObjWithValues(showForm) ? "Update" : "Add"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <CustomConfirmationDialog
        ref={confirmationRef}
        {...confirmationProps}
        submitButtonProps={{
          ...confirmationProps?.submitButtonProps,
          loading: deleteLoading,
        }}
      />
    </Box>
  );
};

export default Shipping;
