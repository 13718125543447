export const checkUserInput = ({ nodes, userInput }) => {
    console.log("userInput", userInput)
  let flagFor;
  const flag = nodes.some((node) => {
    if (node?.type === "user") {
      const fieldData = node?.fieldData;
      console.log(fieldData)
      if (fieldData) {
        const responses = fieldData?.userResponse;
        console.log("response",responses)
        const keywords = fieldData?.keywords;
        console.log(keywords)
        if (responses?.includes(userInput)) {
            console.log("VALIDDD")
          flagFor = "responses";
          return true;
        } else if (keywords?.includes(userInput)) {
            console.log(userInput)
          flagFor = "keywords";
          return true;
        }
      }
      return false;
    }
  });

  return { flag, flagFor}
};
