import { onAddDelay } from "../../utils/DelayNode";
import { calculateAxis } from "../calculateAxis";
import { getAxis } from "../getAxis";

export const createDelayNode = ({  dispatch,
  nodes,
  nodeId,
  currentAddHandle,
  handlesObj, }) => {
    const currNode = nodes.find((node) => node.id === nodeId);
    const type = currNode.type;
    const currentType = "delay";
  
    let axis = {
      x: currNode.position.x,
      y: currNode.position.y,
    };
  
    if (handlesObj) {
      const index = handlesObj.findIndex(
        (item) => item?.value == currentAddHandle
      );
      axis = getAxis({
        x: axis.x,
        y: axis.y,
        handleNum: index + 1,
        totalHandles: handlesObj.length,
      });
    } else {
      axis = calculateAxis({ axis, currType: currentType, prevType: type });
    }
  

  onAddDelay({dispatch, axis, nodeId, currentAddHandle});
};
