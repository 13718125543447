import * as actions from "../actions/flowsActions";
import { applyNodeChanges, applyEdgeChanges } from "reactflow";
import { checkIfLastNode } from "../utils/flow/checkIfLastNode";
import { updateCurrentEdge } from "../utils/flow/updateCurrentEdge";
import { updateNodesUserCount } from "../utils/flow/updateNodesUserCount";
import { updateNodePositions } from "../utils/flow/updateNodePositions";
import { removeEdgeAndReturnSource } from "../utils/flow/removeEdgeAndReturnSource";
import { updateUserConnected } from "../utils/flow/updateUserConnected";
import { traverseAndDeleteNodes } from "../utils/flow/traverseAndDeleteNodes";

let initialState = {
  mode: "light",
  webChat: false,
  whatsappChat: false,
  loading: true,
  loaderProgress: 0,
  title: "",
  nodes: [],
  edges: [],
  segments: null,
  goToBlock: [],
  globalAttributes: [],
  whatsappTemplates: [],
  actionsMenu: true,
  isDragging: false,
  nodeDraggedToId: null,
  dragginNodeType: null,
  nodeAddPopover: false,
  messageNodeIndicatorId: "0001",
  chatbotType: null,
  dragginErrorMessage: {
    id: null,
    message: "",
  },
  integration: {
    whatsappIntegrated: false,
    productCatalogIntegrated: false,
  },
  isChatClosed: false,
  isMinimized: true,
  isFetchingEdges: false,
  openStartSidebar: false,
  openUserSidebar: false,
  openBotSidebar: false,
  openUserAttachmentSidebar: false,
  openQuestionSidebar: false,
  openFilterSidebar: false,
  openGoToStepSidebar: false,
  openWhatsappTemplateSidebar: false,
  openProductCatalogSidebar: false,
  openEmailSidebar: false,
  openSmsSidebar: false,
  openNotificationSidebar: false,
  openDelaySidebar: false,
  openCreateTaskSidebar: false,
  openMetalRatesSidebar: false,
  openAssignTeamSidebar: false,
  dialogBox: false,
  userCount: 0,
  currentNodeId: null,
  testBotMessages: [],
  testBotTypingIndicator: false,
  chatbotMessageResponse: null,
  chatbotStyles: {
    botName: "Bot",
    botIcon:
      "https://jwero-bucket.s3.ap-south-1.amazonaws.com/oAeqLavq0AQi/test_bot/jwero-logo.png",
    tagline: "Online",
    headerColor: "#fff",
    chatWindowColor: "#eaeef3",
    widgetButton: "classic",
  },
  chatbotCustomization: null,
  ifLastNode: false,
};

export const flowReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.EDIT_ELEMENT_IN_REDUX:
      return { ...state, ...action.payload };

    case actions.SET_MODE: {
      return {
        ...state,
        mode: action.payload,
      };
    }
    case actions.SET_TITLE: {
      return {
        ...state,
        title: action.payload,
      };
    }
    case actions.SET_NODES: {
      return {
        ...state,
        nodes: action.payload,
      };
    }
    case actions.SET_EDGES: {
      return {
        ...state,
        edges: action.payload,
      };
    }
    case actions.ADD_NODE: {
      const newNode = action.payload.newNode;
      const newNodeType = newNode.type;
      const newNodeId = newNode.id;

      const multiHandlesNodes = [
        "email",
        "whatsapp-template",
        "sms",
        "push-notification",
      ];

      const addFromNodeId = action.payload.nodeId;
      const addFromNode = state.nodes.find((node) => node.id === addFromNodeId);
      const addFromNodeType = addFromNode?.type;
      const addFromNodeUserConnected = addFromNode?.userConnected;
      const handleType = multiHandlesNodes.includes(addFromNodeType)
        ? action.payload.handleType
        : null;

      const lastNodeFlag = checkIfLastNode({
        id: addFromNodeId,
        edges: state.edges,
      });

      if (
        multiHandlesNodes.includes(addFromNodeType)
        //  && lastMultiResponseNode({ addFromNodeId, edges: state.edges, handleType })
      ) {
        return { ...state, nodes: [...state.nodes, newNode] };
      }

      const nodesAllowed = [
        "user",
        "user-attachment",
        "success",
        "failure",
        "question",
        "email",
        "sms",
        "filter",
        "fallback",
        "whatsapp-template",
      ];

      if (!lastNodeFlag || addFromNodeUserConnected > 1) {
        if (nodesAllowed.includes(newNodeType)) {
          return { ...state, nodes: [...state.nodes, newNode] };
        } else {
          const updatedEdges = updateCurrentEdge({
            id: addFromNodeId,
            updateSourceId: newNodeId,
            edges: state.edges,
          });
          let updateNewNode = newNode;
          const getUpdatedNodes = updateNodesUserCount({
            id: addFromNodeId,
            nodes: state.nodes,
            setUserConnected: 0,
          });
          updateNewNode.userConnected =
            addFromNode?.userConnected !== undefined
              ? addFromNode?.userConnected
              : 0;
          const updatedNodes = updateNodePositions({
            nodes: getUpdatedNodes,
            edges: state.edges,
            startId: addFromNodeId,
            updateXBy: 200,
          });
          return {
            ...state,
            nodes: [...updatedNodes, newNode],
            edges: updatedEdges,
          };
        }
      } else {
        const edgeToupdateId = lastNodeFlag.source;
        const updatedEdges = updateCurrentEdge({
          id: edgeToupdateId,
          updateSourceId: newNodeId,
          edges: state.edges,
        });
        const allowedTypes = ["user", "user-attachment", "filter", "fallback"];
        if (!allowedTypes.includes(newNodeType)) {
          let updateNewNode = newNode;
          const getUpdatedNodes = updateNodesUserCount({
            id: addFromNodeId,
            nodes: state.nodes,
            setUserConnected: 0,
          });
          if (addFromNode.userConnected > 0) {
            updateNewNode.userConnected =
              addFromNode.userConnected !== undefined
                ? addFromNode.userConnected
                : 0;
          }
          const updatedNodes = updateNodePositions({
            nodes: getUpdatedNodes,
            edges: state.edges,
            startId: addFromNodeId,
            updateXBy: 200,
          });
          return {
            ...state,
            nodes: [...updatedNodes, updateNewNode],
            edges: updatedEdges,
          };
        }
        const updatedNodes = updateNodePositions({
          nodes: state.nodes,
          edges: state.edges,
          startId: addFromNodeId,
          updateXBy: 200,
        });
        return {
          ...state,
          nodes: [...updatedNodes, newNode],
          edges: updatedEdges,
        };
      }
    }
    case actions.DELETE_NODE: {
      const deleteNodeId = action.payload.id;
      const deleteNodeType = action.payload.type;

      const allowedTypes = ["user", "user-attachment", "filter", "fallback"];
      const findSourceId = state.edges.find(
        (edge) => edge.target === deleteNodeId
      ).source;

      if (allowedTypes.includes(deleteNodeType)) {
        const updatedNodes = updateUserConnected({
          nodes: state.nodes,
          id: findSourceId,
        });
        const removedNodes = updatedNodes.filter(
          (node) => node.id !== deleteNodeId
        );
        return {
          ...state,
          nodes: removedNodes,
        };
      } else {
        const nodesCopy = state.nodes;
        const sourceNode = nodesCopy.find((node) => node?.id === findSourceId);
        const deleteNode = nodesCopy.find((node) => node?.id === deleteNodeId);

        if (deleteNode?.userConnected > 0) {
          const deleteNodeUserCount = deleteNode?.userConnected;
          const updatedNodes = nodesCopy.map((node) => {
            if (node.id === findSourceId) {
              return { ...node, userConnected: deleteNodeUserCount };
            }
            return node;
          });
          const tempNodes = updatedNodes.filter(
            (node) => node.id !== deleteNodeId
          );

          return {
            ...state,
            nodes: tempNodes,
          };
        }
      }

      const tempNodes = state.nodes.filter((node) => node.id !== deleteNodeId);

      return {
        ...state,
        nodes: tempNodes,
      };
    }
    case actions.REMOVE_AND_UPDATE_EDGE: {
      const deleteNodeId = action.payload.id;

      const lastNodeFlag = checkIfLastNode({
        id: deleteNodeId,
        edges: state.edges,
      });

      const { sourceId, updatedEdges } = removeEdgeAndReturnSource({
        edges: state.edges,
        id: deleteNodeId,
      });

      const updateNewEdgeSource = updatedEdges.map((edge) => {
        if (edge.source === deleteNodeId) {
          return { ...edge, source: sourceId };
        }
        return edge;
      });

      if (!lastNodeFlag) {
        return {
          ...state,
          edges: updateNewEdgeSource,
        };
      } else {
        const updatedNodes = updateNodePositions({
          nodes: state.nodes,
          edges: state.edges,
          startId: deleteNodeId,
          updateXBy: -200,
        });

        return {
          ...state,
          nodes: updatedNodes,
          edges: updateNewEdgeSource,
        };
      }
    }
    case actions.DELETE_WITH_CHILDREN: {
      const deleteNodeId = action.payload.id;
      const deleteNodeType = action.payload.type;
      const findStartDeletingId = state.edges.find(
        (edge) => edge.source === deleteNodeId
      )?.target;
      const { sourceId, updatedEdges } = removeEdgeAndReturnSource({
        edges: state.edges,
        id: deleteNodeId,
      });
      const { finalNodes, finalEdges } = traverseAndDeleteNodes({
        nodes: state.nodes,
        edges: updatedEdges,
        id: deleteNodeType ? deleteNodeId : findStartDeletingId,
        sourceId: deleteNodeId,
      });
      return { ...state, nodes: finalNodes, edges: finalEdges };
    }
    case actions.ADD_EDGE:
      return {
        ...state,
        edges: [...state.edges, action.payload],
      };
    case actions.FETCHING_NODES:
      return {
        ...state,
        isFetchingNodes: true,
      };
    case actions.FETCHING_EDGES:
      return {
        ...state,
        isFetchingEdges: true,
      };
    case actions.ONCHANGE_NODES: {
      const changes = action.payload?.changes;
      const id = changes?.[0]?.id;
      const prevNodePostion = action.payload?.prevNodePosition?.position;
      const nodesWithChanges = applyNodeChanges(changes, state.nodes);
      const updatedPosition = nodesWithChanges.find(
        (node) => node.id === id
      )?.position;

      const xAxisDiff = updatedPosition?.x - prevNodePostion?.x;
      const yAxisDiff = updatedPosition?.y - prevNodePostion?.y;

      const updatedNodes = updateNodePositions({
        nodes: nodesWithChanges,
        edges: state.edges,
        startId: id,
        updateXBy: xAxisDiff,
        updateYBy: yAxisDiff,
      });
      return {
        ...state,
        nodes: updatedNodes,
      };
    }
    case actions.ONCHANGE_EDGES:
      return {
        ...state,
        edges: applyEdgeChanges(action.payload, state.edges),
      };
    case actions.FIRST_NODE_ID:
      return {
        ...state,
        firstNodeId: action.payload,
      };
    case actions.SET_DIALOG_BOX:
      return {
        ...state,
        dialogBox: action.payload,
      };
    case actions.OPEN_SIDEBAR_USER:
      return {
        ...state,
        openUserSidebar: true,
      };
    case actions.CLOSE_SIDEBAR_USER:
      return {
        ...state,
        openUserSidebar: false,
      };
    case actions.OPEN_SIDEBAR_BOT:
      return {
        ...state,
        openBotSidebar: true,
      };

    case actions.CLOSE_SIDEBAR_BOT:
      return {
        ...state,
        openBotSidebar: false,
      };
    case actions.SIDEBAR_USER_ATTACHMENT:
      return {
        ...state,
        openUserAttachmentSidebar: action.payload,
      };
    case actions.SIDEBAR_QUESTION:
      return {
        ...state,
        openQuestionSidebar: action.payload,
      };
    case actions.SIDEBAR_FILTER:
      return {
        ...state,
        openFilterSidebar: action.payload,
      };

    case actions.SIDEBAR_GO_TO_STEP:
      return {
        ...state,
        openGoToStepSidebar: action.payload,
      };
    case actions.SIDEBAR_WHATSAPP_TEMPLATE:
      return {
        ...state,
        openWhatsappTemplateSidebar: action.payload,
      };
    case actions.CURRENT_NODE_ID:
      return {
        ...state,
        currentNodeId: action.payload.nodeId,
      };
    case actions.RESET_NODE_ID:
      return {
        ...state,
        currentNodeId: null,
      };
    case actions.UPDATE_USER_CONNECTED:
      return {
        ...state,
        nodes: state.nodes.map((node) => {
          if (node.id === action.payload.id) {
            return {
              ...node,
              userConnected: action.payload.count,
            };
          }
          return node;
        }),
      };
    case actions.UPDATE_NODE_DATA: {
      const newGoToBlock = state.goToBlock.map((node) => {
        if (node.id === action.payload.id) {
          return {
            ...node,
            fieldData: action.payload.data,
          };
        }
        return node;
      });

      return {
        ...state,
        nodes: state.nodes.map((node) => {
          if (node.id === action.payload.id) {
            return {
              ...node,
              fieldData: action.payload.data,
            };
          }
          return node;
        }),
        goToBlock: newGoToBlock,
      };
    }
    case actions.TEST_BOT_ADD_MESSAGES:
      return {
        ...state,
        testBotMessages: [...state.testBotMessages, action.payload],
      };
    case actions.RESET_TEST_BOT_MESSAGES:
      return {
        ...state,
        testBotMessages: [],
      };
    case actions.TEST_BOT_TYPING_INDICATOR:
      return {
        ...state,
        testBotTypingIndicator: action.payload,
      };
    case actions.SET_NODES_EDGES_EMPTY:
      return {
        ...state,
        nodes: [],
        edges: [],
      };
    default:
      return state;
  }
};
export default flowReducer;
// let initialState = {
//   mode: "light",
//   webChat: false,
//   whatsappChat: false,
//   loading: true,
//   loaderProgress: 0,
//   title: "",
//   nodes: [],
//   edges: [],
//   goToBlock: [],
//   globalAttributes: [],
//   whatsappTemplates: [],
//   actionsMenu: false,
//   isDragging: false,
//   nodeDraggedToId: null,
//   dragginNodeType: null,
//   nodeAddPopover: false,
//   messageNodeIndicatorId: "0001",
//   dragginErrorMessage: {
//     id: null,
//     message: "",
//   },
//   integration: {
//     whatsappIntegrated: false,
//     productCatalogIntegrated: false,
//   },
//   isChatClosed: false,
//   isMinimized: true,
//   isFetchingEdges: false,
//   openUserSidebar: false,
//   openBotSidebar: false,
//   openUserAttachmentSidebar: false,
//   openQuestionSidebar: false,
//   openFilterSidebar: false,
//   openGoToStepSidebar: false,
//   openWhatsappTemplateSidebar: false,
//   openProductCatalogSidebar: false,
//   openEmailSidebar: false,
//   openSmsSidebar: false,
//   openNotificationSidebar: false,
//   openDelaySidebar: false,
//   dialogBox: false,
//   userCount: 0,
//   currentNodeId: "",
//   testBotMessages: [],
//   testBotTypingIndicator: false,
//   chatbotMessageResponse: null,
//   chatbotStyles: {
//     botName: "Bot",
//     botIcon:
//       "https://jwero-bucket.s3.ap-south-1.amazonaws.com/oAeqLavq0AQi/test_bot/jwero-logo.png",
//     tagline: "Online",
//     headerColor: "#fff",
//     chatWindowColor: "#eaeef3",
//     widgetButton: "classic",
//   },
//   chatbotCustomization: null,
//   ifLastNode: false,
// };

// const flowReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case actions.EDIT_ELEMENT_IN_REDUX:
//       return { ...state, ...action.payload };

//     case actions.SET_MODE: {
//       return {
//         ...state,
//         mode: action.payload,
//       };
//     }
//     case actions.SET_TITLE: {
//       return {
//         ...state,
//         title: action.payload,
//       };
//     }
//     case actions.SET_NODES: {
//       return {
//         ...state,
//         nodes: action.payload,
//       };
//     }
//     case actions.SET_EDGES: {
//       return {
//         ...state,
//         edges: action.payload,
//       };
//     }
//     case actions.ADD_NODE: {
//       const newNode = action.payload.newNode;
//       const newNodeType = newNode.type;
//       const newNodeId = newNode.id;

//       const addFromNodeId = action.payload.nodeId;
//       const addFromNode = state.nodes.find((node) => node.id === addFromNodeId);
//       const addFromNodeType = addFromNode?.type;
//       const addFromNodeUserConnected = addFromNode?.userConnected;

//       const lastNodeFlag = checkIfLastNode({
//         id: addFromNodeId,
//         edges: state.edges,
//       });

//       if (!lastNodeFlag || addFromNodeUserConnected > 1) {
//         if (
//           newNodeType === "user" ||
//           newNodeType === "user-attachment" ||
//           newNodeType === "success" ||
//           newNodeType === "failure" ||
//           newNodeType === "question"
//         ) {
//           return { ...state, nodes: [...state.nodes, newNode] };
//         } else {
//           const updatedEdges = updateCurrentEdge({
//             id: addFromNodeId,
//             updateSourceId: newNodeId,
//             edges: state.edges,
//           });
//           let updateNewNode = newNode;
//           const getUpdatedNodes = updateNodesUserCount({
//             id: addFromNodeId,
//             nodes: state.nodes,
//             setUserConnected: 0,
//           });
//           updateNewNode.userConnected =
//             addFromNode.userConnected !== undefined
//               ? addFromNode.userConnected
//               : 0;
//           const updatedNodes = updateNodePositions({
//             nodes: getUpdatedNodes,
//             edges: state.edges,
//             startId: addFromNodeId,
//             updateXBy: 200,
//           });
//           return {
//             ...state,
//             nodes: [...updatedNodes, newNode],
//             edges: updatedEdges,
//           };
//         }
//       } else {
//         const edgeToupdateId = lastNodeFlag.source;
//         const updatedEdges = updateCurrentEdge({
//           id: edgeToupdateId,
//           updateSourceId: newNodeId,
//           edges: state.edges,
//         });
//         if (newNodeType !== "user" || newNodeType === "user-attachment") {
//           let updateNewNode = newNode;
//           const getUpdatedNodes = updateNodesUserCount({
//             id: addFromNodeId,
//             nodes: state.nodes,
//             setUserConnected: 0,
//           });
//           if (addFromNode.userConnected > 0) {
//             updateNewNode.userConnected =
//               addFromNode.userConnected !== undefined
//                 ? addFromNode.userConnected
//                 : 0;
//           }
//           const updatedNodes = updateNodePositions({
//             nodes: getUpdatedNodes,
//             edges: state.edges,
//             startId: addFromNodeId,
//             updateXBy: 200,
//           });
//           return {
//             ...state,
//             nodes: [...updatedNodes, updateNewNode],
//             edges: updatedEdges,
//           };
//         }
//         const updatedNodes = updateNodePositions({
//           nodes: state.nodes,
//           edges: state.edges,
//           startId: addFromNodeId,
//           updateXBy: 200,
//         });
//         return {
//           ...state,
//           nodes: [...updatedNodes, newNode],
//           edges: updatedEdges,
//         };
//       }
//     }
//     case actions.DELETE_NODE: {
//       const deleteNodeId = action.payload.id;
//       const deleteNodeType = action.payload.type;

//       const allowedTypes = ["user", "user-attachment"];
//       console.log(deleteNodeId);
//       const findSourceId = state.edges.find(
//         (edge) => edge.target === deleteNodeId
//       ).source;
//       console.log(findSourceId);
//       console.log("DELETE NODE SOURCE", findSourceId);

//       if (allowedTypes.includes(deleteNodeType)) {
//         const updatedNodes = updateUserConnected({
//           nodes: state.nodes,
//           id: findSourceId,
//         });
//         const removedNodes = updatedNodes.filter(
//           (node) => node.id !== deleteNodeId
//         );
//         return {
//           ...state,
//           nodes: removedNodes,
//         };
//       }

//       const tempNodes = state.nodes.filter((node) => node.id !== deleteNodeId);

//       return {
//         ...state,
//         nodes: tempNodes,
//       };
//     }
//     case actions.REMOVE_AND_UPDATE_EDGE: {
//       const deleteNodeId = action.payload.id;
//       console.log(action.payload.id);

//       const lastNodeFlag = checkIfLastNode({
//         id: deleteNodeId,
//         edges: state.edges,
//       });

//       console.log(lastNodeFlag);
//       const { sourceId, updatedEdges } = removeEdgeAndReturnSource({
//         edges: state.edges,
//         id: deleteNodeId,
//       });

//       const updateNewEdgeSource = updatedEdges.map((edge) => {
//         if (edge.source === deleteNodeId) {
//           return { ...edge, source: sourceId };
//         }
//         return edge;
//       });

//       if (!lastNodeFlag) {
//         return {
//           ...state,
//           edges: updateNewEdgeSource,
//         };
//       } else {
//         const updatedNodes = updateNodePositions({
//           nodes: state.nodes,
//           edges: state.edges,
//           startId: deleteNodeId,
//           updateXBy: -200,
//         });

//         return {
//           ...state,
//           nodes: updatedNodes,
//           edges: updateNewEdgeSource,
//         };
//       }
//     }
//     case actions.DELETE_WITH_CHILDREN: {
//       const deleteNodeId = action.payload.id;
//       const findStartDeletingId = state.edges.find(
//         (edge) => edge.source === deleteNodeId
//       )?.target;
//       console.log("SSS", deleteNodeId);
//       const { sourceId, updatedEdges } = removeEdgeAndReturnSource({
//         edges: state.edges,
//         id: deleteNodeId,
//       });
//       console.log(sourceId);
//       const { finalNodes, finalEdges } = traverseAndDeleteNodes({
//         nodes: state.nodes,
//         edges: updatedEdges,
//         id: findStartDeletingId,
//         sourceId: deleteNodeId,
//       });
//       console.log(sourceId, updatedEdges);

//       return { ...state, nodes: finalNodes, edges: finalEdges };
//     }
//     case actions.ADD_NODE_IN_BETWEEN:
//       return {};
//     case actions.ADD_EDGE:
//       return {
//         ...state,
//         edges: [...state.edges, action.payload],
//       };
//     case actions.FETCHING_NODES:
//       return {
//         ...state,
//         isFetchingNodes: true,
//       };
//     case actions.FETCHING_EDGES:
//       return {
//         ...state,
//         isFetchingEdges: true,
//       };
//     case actions.ONCHANGE_NODES:
//       console.log(
//         action.payload,
//         state.nodes,
//         "<<<<<<< action.payload || [], state.nodes || []"
//       );
//       return {
//         ...state,
//         nodes: applyNodeChanges(action.payload || [], state.nodes || []),
//       };
//     case actions.ONCHANGE_EDGES:
//       return {
//         ...state,
//         edges: applyEdgeChanges(action.payload, state.edges),
//       };
//     case actions.FIRST_NODE_ID:
//       return {
//         ...state,
//         firstNodeId: action.payload,
//       };
//     case actions.SET_DIALOG_BOX:
//       return {
//         ...state,
//         dialogBox: action.payload,
//       };
//     case actions.OPEN_SIDEBAR_USER:
//       return {
//         ...state,
//         openUserSidebar: true,
//       };
//     case actions.CLOSE_SIDEBAR_USER:
//       return {
//         ...state,
//         openUserSidebar: false,
//       };
//     case actions.OPEN_SIDEBAR_BOT:
//       return {
//         ...state,
//         openBotSidebar: true,
//       };

//     case actions.CLOSE_SIDEBAR_BOT:
//       return {
//         ...state,
//         openBotSidebar: false,
//       };
//     case actions.SIDEBAR_USER_ATTACHMENT:
//       return {
//         ...state,
//         openUserAttachmentSidebar: action.payload,
//       };
//     case actions.SIDEBAR_QUESTION:
//       return {
//         ...state,
//         openQuestionSidebar: action.payload,
//       };
//     case actions.SIDEBAR_FILTER:
//       return {
//         ...state,
//         openFilterSidebar: action.payload,
//       };

//     case actions.SIDEBAR_GO_TO_STEP:
//       return {
//         ...state,
//         openGoToStepSidebar: action.payload,
//       };
//     case actions.SIDEBAR_WHATSAPP_TEMPLATE:
//       return {
//         ...state,
//         openWhatsappTemplateSidebar: action.payload,
//       };
//     case actions.CURRENT_NODE_ID:
//       return {
//         ...state,
//         currentNodeId: action.payload.nodeId,
//       };
//     case actions.RESET_NODE_ID:
//       return {
//         ...state,
//         currentNodeId: null,
//       };
//     case actions.UPDATE_USER_CONNECTED:
//       return {
//         ...state,
//         nodes: state.nodes.map((node) => {
//           if (node.id === action.payload.id) {
//             return {
//               ...node,
//               userConnected: action.payload.count,
//             };
//           }
//           return node;
//         }),
//       };
//     case actions.UPDATE_NODE_DATA: {
//       const newGoToBlock = state.goToBlock.map((node) => {
//         if (node.id === action.payload.id) {
//           return {
//             ...node,
//             fieldData: action.payload.data,
//           };
//         }
//         return node;
//       });

//       return {
//         ...state,
//         nodes: state.nodes.map((node) => {
//           if (node.id === action.payload.id) {
//             return {
//               ...node,
//               fieldData: action.payload.data,
//             };
//           }
//           return node;
//         }),
//         goToBlock: newGoToBlock,
//       };
//     }
//     case actions.TEST_BOT_ADD_MESSAGES:
//       return {
//         ...state,
//         testBotMessages: [...state.testBotMessages, action.payload],
//       };
//     case actions.RESET_TEST_BOT_MESSAGES:
//       return {
//         ...state,
//         testBotMessages: [],
//       };
//     case actions.TEST_BOT_TYPING_INDICATOR:
//       return {
//         ...state,
//         testBotTypingIndicator: action.payload,
//       };
//     case actions.SET_NODES_EDGES_EMPTY:
//       return {
//         ...state,
//         nodes: [],
//         edges: [],
//       };
//     default:
//       return state;
//   }
// };
// export default flowReducer;

// // import { EDIT_FLOW } from "../actions/flowsActions";
// // import { applyNodeChanges, applyEdgeChanges } from "reactflow";
// // import * as actions from "../actions/flowsActions";

// // const initialState = {
// //   mode: "dark",
// //   webChat: false,
// //   whatsappChat: false,
// //   loading: true,
// //   loaderProgress: 0,
// //   title: "",
// //   nodes: [],
// //   edges: [],
// //   goToBlock: [],
// //   isFetchingNodes: false,
// //   isFetchingEdges: false,
// //   openUserSidebar: false,
// //   openBotSidebar: false,
// //   openUserAttachmentSidebar: false,
// //   openQuestionSidebar: false,
// //   openFilterSidebar: false,
// //   openGoToStepSidebar: false,
// //   openWhatsappTemplateSidebar: false,
// //   dialogBox: false,
// //   userCount: 0,
// //   currentNodeId: "",
// //   testBotMessages: [],
// //   testBotTypingIndicator: false,
// //   chatbotMessageResponse: null,
// //   chatbotStyles: {
// //     headerColor: "#fff",
// //     chatWindowColor: "#eaeef3",
// //     widgetButton: "classic",
// //   },
// // };

// // function findEdgeTargetById(id, edges) {
// //   const matchingEdge = edges.find((edge) => edge.source === id);
// //   return matchingEdge ? matchingEdge.target : null;
// // }

// // function updateEdgeSourcesAndTarget(edges, prevNodeId, newNodeId) {
// //   const updatedEdges = edges.map((edge) => {
// //     if (edge.target == prevNodeId) {
// //       console.log("Matched !!", edge);
// //       edge.source = newNodeId;
// //     }
// //     return edge;
// //   });

// //   return updatedEdges;
// // }

// // // function updateNodePositions(nodes, edges, nodeId, deltaX) {
// // //   const updatePositionNodesId = []
// // //   const nextNodeId = edges.find(edge => edge.source == nodeId)
// // //   updatePositionNodesId.push(nextNodeId.id)
// // //   console.log(updatePositionNodesId)

// // //   for(const id of updatePositionNodesId) {
// // //     console.log(id)
// // //     console.log(nodes)
// // //     const currNode = nodes.find(node => node.id == id)
// // //     console.log(currNode)
// // //     console.log("UPDATED NODES")
// // //     currNode.position.x = currNode.position.x + deltaX
// // //   }
// // // }

// // function updateNodePositions(nodes, edges, nodeId, deltaX) {
// //   const nextEdge = edges.find((edge) => edge.source == nodeId);

// //   if (nextEdge) {
// //     const nextNodeId = nextEdge.id;
// //     const currNode = nodes.find((node) => node.id == nextNodeId);

// //     if (currNode) {
// //       currNode.position.x += deltaX;
// //       updateNodePositions(nodes, edges, nextNodeId, deltaX);
// //     }
// //   }
// // }

// // const reducer = (state = initialState, action) => {
// //   switch (action.type) {
// //     case EDIT_FLOW:
// //       return { ...state, ...action?.payload };
// //     case actions.EDIT_ELEMENT_IN_REDUX:
// //       return { ...state, ...action?.payload };

// //     case actions.SET_MODE: {
// //       return {
// //         ...state,
// //         mode: action.payload,
// //       };
// //     }
// //     case actions.SET_TITLE: {
// //       return {
// //         ...state,
// //         title: action.payload,
// //       };
// //     }
// //     case actions.SET_NODES: {
// //       return {
// //         ...state,
// //         nodes: action.payload,
// //       };
// //     }
// //     case actions.SET_EDGES: {
// //       return {
// //         ...state,
// //         edges: action.payload,
// //       };
// //     }
// //     case actions.ADD_NODE: {
// //       const { nodeId, newNode } = action.payload;
// //       let newGoToBlock = [...state.goToBlock];

// //       if (newNode.type === "user" || newNode.type === "user-attachment") {
// //         return { ...state, nodes: [...state.nodes, newNode] };
// //       }

// //       if (
// //         newNode.type === "bot" ||
// //         newNode.type === "question" ||
// //         newNode.type === "fallback"
// //       ) {
// //         newGoToBlock.push(newNode);
// //       }

// //       const index = state.nodes.findIndex((node) => node.id === nodeId);

// //       const updatedNodes = [...state.nodes];
// //       const tempEdges = [...state.edges];

// //       if (index === updatedNodes.length - 1 || newNode.type === "success") {
// //         console.log("AT END");
// //         updatedNodes.push(newNode);
// //       } else {
// //         console.log("IN BETWEEN");
// //         const tempEdges = [...state.edges];
// //         const prevNodeTargetId = findEdgeTargetById(nodeId, tempEdges);
// //         updateEdgeSourcesAndTarget(tempEdges, prevNodeTargetId, newNode.id);
// //         updateNodePositions(updatedNodes, tempEdges, newNode.id, 200);
// //         updatedNodes.splice(index + 1, 0, newNode);
// //       }

// //       return {
// //         ...state,
// //         nodes: updatedNodes,
// //         edges: tempEdges,
// //         goToBlock: newGoToBlock,
// //       };
// //     }
// //     case actions.DELETE_NODE: {
// //       const tempNodes = state.nodes.filter(
// //         (node) => node.id !== action.payload.id
// //       );
// //       return {
// //         ...state,
// //         nodes: tempNodes,
// //       };
// //     }
// //     case actions.REMOVE_AND_UPDATE_EDGE: {
// //       var prevNode;
// //       const updatedEdges = state.edges.map((edge) => {
// //         if (edge.target === action.payload.id) {
// //           prevNode = edge.source;
// //         }

// //         if (edge.source === action.payload.id) {
// //           let nextNode = edge.target;
// //           const newEdge = {
// //             id: Date.now().toString(),
// //             source: prevNode,
// //             target: nextNode,
// //           };
// //           console.log(newEdge);
// //           return newEdge;
// //         }
// //         return edge;
// //       });
// //       return {
// //         ...state,
// //         edges: updatedEdges,
// //       };
// //     }
// //     case actions.ADD_NODE_IN_BETWEEN:
// //       return {};
// //     case actions.ADD_EDGE:
// //       return {
// //         ...state,
// //         edges: [...state.edges, action.payload],
// //       };
// //     case actions.FETCHING_NODES:
// //       return {
// //         ...state,
// //         isFetchingNodes: true,
// //       };
// //     case actions.FETCHING_EDGES:
// //       return {
// //         ...state,
// //         isFetchingEdges: true,
// //       };
// //     case actions.ONCHANGE_NODES:
// //       return {
// //         ...state,
// //         nodes: applyNodeChanges(action.payload, state.nodes),
// //       };
// //     case actions.ONCHANGE_EDGES:
// //       return {
// //         ...state,
// //         edges: applyEdgeChanges(action.payload, state.edges),
// //       };
// //     case actions.FIRST_NODE_ID:
// //       return {
// //         ...state,
// //         firstNodeId: action.payload,
// //       };
// //     case actions.SET_DIALOG_BOX:
// //       return {
// //         ...state,
// //         dialogBox: action.payload,
// //       };
// //     case actions.OPEN_SIDEBAR_USER:
// //       return {
// //         ...state,
// //         openUserSidebar: true,
// //       };
// //     case actions.CLOSE_SIDEBAR_USER:
// //       return {
// //         ...state,
// //         openUserSidebar: false,
// //       };
// //     case actions.OPEN_SIDEBAR_BOT:
// //       return {
// //         ...state,
// //         openBotSidebar: true,
// //       };

// //     case actions.CLOSE_SIDEBAR_BOT:
// //       return {
// //         ...state,
// //         openBotSidebar: false,
// //       };
// //     case actions.SIDEBAR_USER_ATTACHMENT:
// //       return {
// //         ...state,
// //         openUserAttachmentSidebar: action.payload,
// //       };
// //     case actions.SIDEBAR_QUESTION:
// //       return {
// //         ...state,
// //         openQuestionSidebar: action.payload,
// //       };
// //     case actions.SIDEBAR_FILTER:
// //       return {
// //         ...state,
// //         openFilterSidebar: action.payload,
// //       };

// //     case actions.SIDEBAR_GO_TO_STEP:
// //       return {
// //         ...state,
// //         openGoToStepSidebar: action.payload,
// //       };
// //     case actions.SIDEBAR_WHATSAPP_TEMPLATE:
// //       return {
// //         ...state,
// //         openWhatsappTemplateSidebar: action.payload,
// //       };
// //     case actions.CURRENT_NODE_ID:
// //       return {
// //         ...state,
// //         currentNodeId: action.payload.nodeId,
// //       };
// //     case actions.RESET_NODE_ID:
// //       return {
// //         ...state,
// //         currentNodeId: null,
// //       };
// //     case actions.UPDATE_USER_CONNECTED:
// //       return {
// //         ...state,
// //         nodes: state.nodes.map((node) => {
// //           if (node.id === action.payload.id) {
// //             return {
// //               ...node,
// //               userConnected: action.payload.count,
// //             };
// //           }
// //           return node;
// //         }),
// //       };
// //     case actions.UPDATE_NODE_DATA: {
// //       const newGoToBlock = state.goToBlock.map((node) => {
// //         if (node.id === action.payload.id) {
// //           return {
// //             ...node,
// //             fieldData: action.payload.data,
// //           };
// //         }
// //         return node;
// //       });

// //       return {
// //         ...state,
// //         nodes: state.nodes.map((node) => {
// //           if (node.id === action.payload.id) {
// //             return {
// //               ...node,
// //               fieldData: action.payload.data,
// //             };
// //           }
// //           return node;
// //         }),
// //         goToBlock: newGoToBlock,
// //       };
// //     }
// //     case actions.TEST_BOT_ADD_MESSAGES:
// //       return {
// //         ...state,
// //         testBotMessages: [...state.testBotMessages, action.payload],
// //       };
// //     case actions.RESET_TEST_BOT_MESSAGES:
// //       return {
// //         ...state,
// //         testBotMessages: [],
// //       };
// //     case actions.TEST_BOT_TYPING_INDICATOR:
// //       return {
// //         ...state,
// //         testBotTypingIndicator: action.payload,
// //       };
// //     case actions.SET_NODES_EDGES_EMPTY:
// //       return {
// //         ...state,
// //         nodes: [],
// //         edges: [],
// //       };
// //     default:
// //       return state;
// //   }
// // };

// // // const reducer = (state = initialState, { type, payload }) => {
// // //   switch (type) {
// // //     case EDIT_FLOW:
// // //       return { ...state, ...payload };
// // //     default:
// // //       return state;
// // //   }
// // // };
// // export default reducer;
