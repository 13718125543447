export const initialNodes = {
  preStartNode: {
    id: "0000",
    type: "pre_start",
    data: { label: "Start" },
    position: {
      x: 0,
      y: 0,
    },
    fieldData: [],
    title: "Start Point",
    userConnected: 0,
  },
  startNode: {
    id: "0001",
    type: "start",
    data: { label: "Start" },
    position: {
      x: 0,
      y: 0,
    },
    fieldData: [],
    title: "Start Point",
    userConnected: 0,
  },

  defaultFallbackNode: {
    id: "0002",
    type: "fallback",
    data: { label: "Default Fallback" },
    position: {
      x: 209,
      y: 75,
    },
    fieldData: [],
    title: "Fallback",
  },

  BotNodeFallback: {
    id: "0003",
    type: "bot",
    userConnected: 0,
    data: { label: "Bot" },
    position: {
      x: 400,
      y: 75,
    },
    fieldData: [],
  },

  BotNode: {
    id: "0004",
    type: "bot",
    userConnected: 0,
    data: { label: "Bot defaultfallback" },
    position: {
      x: 200,
      y: -75,
    },
    fieldData: [],
  },
};
