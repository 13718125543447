import { Box, Typography } from "@mui/material";
import NodeOptions from "./NodeOptions";

const NodeTop = ({ title, isHovered, isDragging, nodeId, setIsHovered }) => {
  return (
    <>
      {isHovered &&
      (isDragging === undefined || !isDragging) &&
      nodeId !== "0002" ? (
        <NodeOptions nodeId={nodeId} setIsHovered={setIsHovered} />
      ) : (
        <Box
          sx={{
            textAlign: "center",
            // maxWidth: "80px",
            whiteSpace: "nowrap",
            // overflow: "hidden",
            // textOverflow: "ellipsis",
            fontFamily: "Inter",
            fontWeight: 600,
            color: "#566e8a",
          }}
        >
          <Typography
            variant="span"
            sx={{
              fontSize: "13px",
              fontFamily: "Inter",
              fontWeight: 600,
              color: "#566e8a",
            }}
          >
            {title || "‎"}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default NodeTop;
