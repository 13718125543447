import {
  Apple,
  Close,
  FileDownload,
  FileUpload,
  Laptop,
  PhoneIphone,
  Sync,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  dialogStyle,
  formatDate,
  isObjWithValues,
  formatServerValue,
  validateNumber,
  formatDateDynamic,
  isDev,
} from "../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { UAParser } from "ua-parser-js";
import { Windows } from "../../../Svgs";
import { getJweroSheetSync } from "../../../redux/actions/settingActions";
import { LoadingButton } from "@mui/lab";
import CustomChip from "../../components/CustomChip";
import {
  SecondaryLabel,
  SecondaryLabelExtraSmall,
  SecondaryLabelSmall,
} from "../../../helper/utility/Labels";
import { isArrayWithValues } from "jwero-javascript-utils";

function JweroSyncHistory({ showSync, closeSync, type }) {
  const dispatch = useDispatch();
  const loadingJweroSheetSync = useSelector(
    (state) => state?.settings?.loadingJweroSheetSync
  );
  return (
    <Dialog
      open={showSync}
      onClose={() => closeSync()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
      sx={{ ...dialogStyle }}
      keepMounted={false}
    >
      {/* <DialogTitle    id="alert-dialog-title">CRM Sheet</DialogTitle> */}
      <DialogContent>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          mb={3}
          flexWrap="wrap"
        >
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ my: 2 }}
            spacing={2}
          >
            <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
              {formatServerValue(type)} Sheet
            </Typography>
            <LoadingButton
              loading={loadingJweroSheetSync}
              onClick={() => dispatch(getJweroSheetSync())}
              startIcon={<Sync />}
              size="small"
            >
              Refresh
            </LoadingButton>
          </Stack>

          {/* {!googleSheetIntegrated && ( */}
          <IconButton onClick={closeSync}>
            <Close />
          </IconButton>
          {/* )} */}
        </Stack>
        <HistoryTable type={type} />
        {/* {googleSheetIntegrated && (
          <CRMSheetHistory
            chatsHistory={chatsSpreadsheetHistory}
            heading={"Chats Sheet Sync"}
          />
        )} */}
      </DialogContent>
      {/* <DialogActions>
        <Button variant="outlined" startIcon={<Close />} onClick={closeSync}>
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

export default JweroSyncHistory;

const HistoryTable = ({ type }) => {
  const jweroSheetSync = useSelector(
    (state) => state?.settings?.jweroSheetSync
  );
  const reduxTeamMembers =
    useSelector((state) => state.settings.teamMembers) || [];
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const download_exported_sheet = useSelector(
    (state) => state.user.download_exported_sheet
  );
  let [hasAccessToDownload, setHasAccessToDownload] = useState(false);

  useEffect(() => {
    let has = false;
    if (isAdmin) has = true;
    else if (isArrayWithValues(download_exported_sheet)) {
      has = download_exported_sheet?.find((i) =>
        i?.toLowerCase()?.includes(type?.toLowerCase())
      );
    }
    setHasAccessToDownload(Boolean(has));
  }, [download_exported_sheet, type]);

  useEffect(() => {
    if (isObjWithValues(jweroSheetSync)) {
      let allArray = Object.values(jweroSheetSync)
        ?.filter((o) => o?.type === type)
        ?.sort(
          (a, b) =>
            validateNumber(b?.start_time) - validateNumber(a?.start_time)
        );
      let filteredArray = [];
      let haveFile = false;
      allArray?.forEach((o) => {
        if (haveFile) delete o.file_path;
        if (o.file_path && o.action === "export") haveFile = true;

        filteredArray.push(o);
      });
      filteredArray = filteredArray?.filter((i) =>
        !isDev() ? !i?.is_dev : true
      );
      setRows(filteredArray);
    }

    return () => {};
  }, [jweroSheetSync]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <TableContainer component={Paper}>
        <Table sx={{}} aria-label="custom pagination table">
          <TableHead>
            <TableRow
              sx={{ backgroundColor: "grey" }}
              style={{ backgroundColor: "#e7e7e73b" }}
            >
              {/* <TableCell>Action</TableCell> */}
              <TableCell>Date</TableCell>
              {/* <TableCell>Exported</TableCell>
              <TableCell>Added</TableCell>
              <TableCell>Updated</TableCell> */}
              <TableCell colSpan={3} align="center">
                Summary
              </TableCell>
              <TableCell>Device</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>By</TableCell>
              {hasAccessToDownload && <TableCell>File</TableCell>}

              {/* <TableCell>IP</TableCell> */}
              {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
        <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => {
              let { ua, status, action, file_path } = row;
              let os = new UAParser(ua).getOS();
              let browser = new UAParser(ua).getBrowser();
              let teamMember = reduxTeamMembers?.find((o) => o?.id == row?.by);
              return (
                <TableRow key={row.id}>
                  {/* <TableCell padding="checkbox" align="center">
                    <Typography sx={{ ml: 3, fontWeight: "600" }}>
                      {index + 1}
                    </Typography>
                    
                
                  </TableCell> */}

                  <TableCell component="th" scope="row">
                    <Typography variant="subtitle2">
                      {formatServerValue(action)}
                    </Typography>
                    <SecondaryLabelSmall
                      sx={{ color: "black", opacity: "0.6" }}
                    >
                      {row.start_time
                        ? formatDateDynamic(
                            new Date(row.start_time),
                            "DD MMM, YYYY - hh:mm A"
                          )
                        : ""}
                      {/* {row.start_time
                        ? formatDate(new Date(row.start_time), "dd/mm/yyyy")
                        : ""}{" "}
                      {row.start_time
                        ? formatDate(new Date(row.start_time), "hh:mm am/pm")
                        : ""} */}
                    </SecondaryLabelSmall>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6">{row.exported || 0}</Typography>
                    <Typography variant="caption" color={"text.secondary"}>
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6">
                      {action === "export" ? "-" : row.added || 0}
                    </Typography>
                    <Typography variant="caption" color={"text.secondary"}>
                      Add
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6" color={"text.secondary"}>
                      {action === "export" ? "-" : row.updated || 0}
                    </Typography>
                    <Typography variant="caption">Update</Typography>
                  </TableCell>
                  <TableCell padding="checkbox" sx={{ fontWeight: "600" }}>
                    <Stack direction="row" alignItems={"center"} spacing={2}>
                      {os && os.name === "Mac OS" ? (
                        <Apple style={{ color: "#666666" }} />
                      ) : os.name === "Windows" ? (
                        <Windows style={{ color: "#666666" }} />
                      ) : null}
                      {os && (os.name === "Mac OS" || os.name === "Windows") ? (
                        <Laptop width="20px" height="20px" />
                      ) : (
                        <PhoneIphone />
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <CustomChip label={status} status={status} type={status} />
                  </TableCell>
                  {/* <TableCell>
                    <Typography
                      // variant="h6"
                      fontSize={"11px"}
                      sx={{ mt: 0.5 }}
                    >
                      {isObjWithValues(row.location)
                        ? `${row.location.city || ""} ${
                            row.location.state || row.location.region || ""
                          } ${row.location.country}`
                        : ""}
                    </Typography>
                  </TableCell> */}
                  <TableCell>
                    {row?.isAdmin
                      ? "Admin"
                      : `${teamMember?.first_name || ""} ${
                          teamMember?.last_name || ""
                        }`}
                  </TableCell>
                  {hasAccessToDownload && (
                    <TableCell>
                      {action === "export" && file_path ? (
                        <a href={file_path} download>
                          Download
                        </a>
                      ) : null}
                    </TableCell>
                  )}

                  {/* 
                <TableCell>
                  <Typography
                    // variant="h6"
                    fontSize={"11px"}
                    sx={{ mt: 0.5 }}
                  >
                    {row.ip}
                  </Typography>
                </TableCell> */}
                  {/* <TableCell  align="right">
            {row.fat}
          </TableCell> */}
                </TableRow>
              );
            })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                // colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};
