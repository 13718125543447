import { validateNumber } from "jwero-javascript-utils";

function excelSerialDateToJSDate(serial) {
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400;
  const date_info = new Date(utc_value * 1000);

  const fractional_day = serial - Math.floor(serial) + 0.0000001;
  const total_seconds = Math.floor(86400 * fractional_day);
  const seconds = total_seconds % 60;
  const minutes = Math.floor(total_seconds / 60) % 60;
  const hours = Math.floor(total_seconds / 3600);

  date_info.setHours(hours, minutes, seconds);
  return date_info;
}
function isNDaysBefore(date, n) {
  const currentDate = new Date();
  const targetDate = new Date(date);

  // Calculate the difference in milliseconds between the current date and the target date
  const timeDifference = currentDate - targetDate;

  // Calculate the number of milliseconds in "n" days
  const nDaysInMillis = n * 24 * 60 * 60 * 1000;

  return validateNumber(timeDifference) >= validateNumber(nDaysInMillis);
}
export { excelSerialDateToJSDate, isNDaysBefore };
