export const textBoxStyles = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #fff",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "7px 15px",
    transition: "0.3s ease",
    maxHeight: "100px",
    "&:hover": {
      transition: "0.3s ease",
      border: "1px solid #5f5d5d",
    },
  },
  inputAdornment: {
    color: "#464747",
    cursor: "pointer",
  },
};
