import { Chip } from "@material-ui/core";
import { Box, Select, Stack } from "@mui/material";
import {
  keyColumn,
  textColumn,
  intColumn,
  createTextColumn,
  floatColumn,
  checkboxColumn,
} from "react-datasheet-grid";
import {
  isArray,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  isObjectEqual,
  removeDuplicatesFromArray,
  sleep,
  validateNumber,
} from "../../../../helper";
import { SelectComponent } from "../helper";
import "../../index.css";
import React from "react";
import {
  arePropsEqual,
  removeFalsyValuesFromObject,
} from "../../../../utils/js";
import { getDiamondQualityWithoutType } from "../../../products/AddProduct";
import { gemstoneColors, gemstoneSizes } from "../../../pricing/Gemstone";
import { disabledFields } from "../../utils";
import { allSizeGuides } from "../../../settings_new/product/SizeMasters";

const validateProducts = ({
  products,
  dropdowns,
  columns,
  defaultProductObject,
  sizesDropdown,
  productSettings,
  partiallyUpdate = false,
}) => {
  let invalidProducts = [];
  let index = 0;
  for (let product of products) {
    let filteredProduct = removeFalsyValuesFromObject(product, {
      removeNewLine: true,
      excludeNewLine: ["description"],
    });
    let {
      sku,
      name,
      stock_quantity,
      collections,
      categories,
      stock_status,
      metal_types,
      gold_gross,
      gold_net,
      gold_kt,
      silver_gross,
      silver_net,
      silver_purity,
      platinium_gross,
      platinium_net,
      platinium_purity,
      subcatsetting,
      extra_charge_label,
      extra_charge_value,
      diamond_quality,
      diamond_shape,
      diamond_pieces,
      diamond_rate,
      diamond_cut,
      diamond_from,
      diamond_weight,
      colorstone_from,
      colorstone_pieces,
      colorstone_quality,
      colorstone_rate,
      colorstone_shape,
      colorstone_size,
      colorstone_type,
      colorstone_weight,
      huid,
      diamond_type,
      labourType,
      minimum_making,
      wastage_percent,
      per_gram,
      manual_price,
    } = filteredProduct;
    if (Object.keys(filteredProduct).length === 1) filteredProduct = {};
    let isAutoNetWeight = productSettings?.net_weight === "auto";
    if (
      !isObjectEqual(defaultProductObject, filteredProduct) &&
      isObjWithValues(filteredProduct)
    ) {
      let categoryValue = dropdowns?.categories?.find(
        (i) => i.label === categories
      )?.value;

      if (!sku) {
        let columnIndex = findColumnIndexFromColumns({ columns, key: "sku" });
        invalidProducts.push({
          row: index,
          column: columnIndex,
          error: `No sku found`,
          message: "Please enter a sku for the product",
        });
      }
      if (partiallyUpdate) return invalidProducts;
      if (!name) {
        let columnIndex = findColumnIndexFromColumns({ columns, key: "name" });
        invalidProducts.push({
          row: index,
          column: columnIndex,
          error: `No title found`,
          message: "Please enter a title for the product",
        });
      }
      if (manual_price) return invalidProducts;
      if (!isArrayWithValues(collections)) {
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "collections",
        });
        invalidProducts.push({
          row: index,
          column: columnIndex,
          error: `No collection found`,
          message: "Please enter a collection for the product",
        });
      }
      if (!isArrayWithValues(categories)) {
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "categories",
        });
        invalidProducts.push({
          row: index,
          column: columnIndex,
          error: `No categories found`,
          message: "Please enter a categories for the product",
        });
      }

      if (stock_status === "instock" && !stock_quantity) {
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "stock_quantity",
        });
        invalidProducts.push({
          row: index,
          column: columnIndex,
          error: `Stock quantity required`,
          message: "Please enter stock quantity or change it to make to order",
        });
      }
      if (subcatsetting && categories) {
        let array = sizesDropdown?.[categoryValue];
        if (isArrayWithValues(array)) {
          let setting = array?.find((i) => `${i.value}` === `${subcatsetting}`);
          if (!setting) {
            let columnIndex = findColumnIndexFromColumns({
              columns,
              key: "subcatsetting",
            });
            invalidProducts.push({
              row: index,
              column: columnIndex,
              error: `Invalid setting/size found`,
              message: "Please enter a valid setting/size",
            });
          }
        }
      }
      if (huid) {
        let isInvalid = false;
        let _huid =
          typeof huid === "string"
            ? huid?.split(",")
            : isArrayWithValues(huid)
            ? huid
            : [];
        _huid?.forEach((i) => {
          if (i?.trim()?.length !== 6) {
            isInvalid = true;
          }
        });

        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "huid",
        });
        if (isInvalid)
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `Invalid HUID found`,
            message: "HUID should be 6 digits separated by commas",
          });
      }
      if (!isArrayWithValues(metal_types)) {
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "metal_types",
        });
        invalidProducts.push({
          row: index,
          column: columnIndex,
          error: `No metal found`,
          message: "Please enter metal type",
        });
      }
      if (metal_types?.includes("gold")) {
        if (!gold_kt) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: "gold_kt",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `No gold purity found`,
            message: "Please enter gold purity",
          });
        }
        if (!gold_gross) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: "gold_gross",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `No gold gross weight found`,
            message: "Please enter gold gross weight",
          });
        }
        if (
          gold_gross &&
          gold_net &&
          validateNumber(gold_net) > validateNumber(gold_gross)
        ) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: "gold_net",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `Net weight should be less than gross weight`,
            message: "Please enter net weight less than gross",
          });
        }
        if (!gold_net && !isAutoNetWeight) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: "gold_net",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `No gold net weight found`,
            message: "Please enter gold net weight",
          });
        }
      }
      if (metal_types?.includes("silver")) {
        if (!silver_purity) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: "silver_purity",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `No silver purity found`,
            message: "Please enter silver purity",
          });
        }
        if (!silver_gross) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: "silver_gross",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `No silver gross weight found`,
            message: "Please enter silver gross weight",
          });
        }
        if (!silver_net && !isAutoNetWeight) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: "silver_net",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `No silver net weight found`,
            message: "Please enter silver net weight",
          });
        }
        if (
          silver_gross &&
          silver_net &&
          validateNumber(silver_net) > validateNumber(silver_gross)
        ) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: "silver_net",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `Net weight should be less than gross weight`,
            message: "Please enter net weight less than gross",
          });
        }
      }
      if (metal_types?.includes("platinium")) {
        if (!platinium_purity) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: "platinium_purity",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `No platinium purity found`,
            message: "Please enter platinium purity",
          });
        }
        if (!platinium_net) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: "platinium_net",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `No platinium net weight found`,
            message: "Please enter platinium net weight",
          });
        }
        if (!platinium_gross && !isAutoNetWeight) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: "platinium_gross",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `No platinium gross weight found`,
            message: "Please enter platinium gross weight",
          });
        }
        if (
          platinium_gross &&
          platinium_net &&
          validateNumber(platinium_net) > validateNumber(platinium_gross)
        ) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: "platinium_net",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `Net weight should be less than gross weight`,
            message: "Please enter net weight less than gross",
          });
        }
      }
      if (
        (diamond_quality ||
          diamond_shape ||
          diamond_pieces ||
          diamond_cut ||
          diamond_type) &&
        !diamond_weight
      ) {
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "diamond_weight",
        });
        invalidProducts.push({
          row: index,
          column: columnIndex,
          error: `No diamond weight found`,
          message: "Please enter diamond weight",
        });
      }
      if (collections?.join()?.toLowerCase()?.includes("diamond")) {
        console.log(diamond_from, diamond_weight, diamond_pieces);
        if (!diamond_from) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: "diamond_from",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `No diamond from selected`,
            message: "Please enter diamond from",
          });
        }
        if (
          (diamond_from === "customize" || diamond_from === "individual") &&
          (!diamond_weight || !diamond_pieces || !diamond_rate)
        ) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: !diamond_weight
              ? "diamond_weight"
              : !diamond_pieces
              ? "diamond_pieces"
              : !diamond_rate && "diamond_rate",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `Diamond weight, pieces and rates are required`,
            message: "Please enter diamond weight, pieces and rates",
          });
        }
        if (
          diamond_from === "master" &&
          (!diamond_weight ||
            !diamond_pieces ||
            !diamond_type ||
            !diamond_quality ||
            !diamond_shape)
        ) {
          let min = findColumnIndexFromColumns({
            columns,
            key: "diamond_weight",
          });
          let max = findColumnIndexFromColumns({
            columns,
            key: "diamond_shape",
          });
          if (min < 0) min = 0;
          if (max < 0) max = 0;
          invalidProducts.push({
            min: { row: index, col: min },
            max: { row: index, col: max },
            row: index,
            // column: columnIndex,
            error: `Diamond details required`,
            message: "Please enter all diamond details",
          });
        }

        let diamondLength = [
          `${diamond_weight || ""}`?.split(",").filter(Boolean)?.length,
          `${diamond_pieces || ""}`?.split(",").filter(Boolean)?.length,
        ];
        if (diamond_from === "master") {
          diamondLength = diamondLength.concat([
            `${diamond_quality || ""}`?.split(",").filter(Boolean)?.length,
            // `${diamond_type||""}`?.split(",").filter(Boolean)?.length,
            // `${diamond_cut || ""}`?.split(",").filter(Boolean)?.length,
            `${diamond_shape || ""}`?.split(",").filter(Boolean)?.length,
          ]);
        } else
          diamondLength = diamondLength.concat([
            `${diamond_rate || ""}`?.split(",").filter(Boolean)?.length,
          ]);
        let lastLength = diamondLength[0];
        let isSame = true;

        for (let len of diamondLength) {
          if (lastLength !== len) {
            isSame = false;
            break;
          }
        }
        if (!isSame) {
          let min = findColumnIndexFromColumns({
            columns,
            key: "diamond_weight",
          });
          let max = findColumnIndexFromColumns({
            columns,
            key: diamond_from === "master" ? "diamond_cut" : "diamond_rate",
          });
          if (min < 0) min = 0;
          if (max < 0) max = 0;
          invalidProducts.push({
            min: { row: index, col: min },
            max: { row: index, col: max },
            row: index,
            // column: columnIndex,
            error: `Diamond combination mismatch`,
            message: "Please enter correct diamond combination",
          });
        }
      }

      let gemstoneLength = [
        // `${colorstone_weight}`?.split(",").filter(Boolean)?.length,
        // `${colorstone_pieces}`?.split(",").filter(Boolean)?.length,
        // `${colorstone_quality}`?.split(",").filter(Boolean)?.length,
        // `${colorstone_type}`?.split(",").filter(Boolean)?.length,
        // `${colorstone_shape}`?.split(",").filter(Boolean)?.length,
        // `${colorstone_size}`?.split(",").filter(Boolean)?.length,
      ];

      // let diamondLength = [
      //   `${diamond_weight || ""}`?.split(",").filter(Boolean)?.length,
      //   `${diamond_pieces || ""}`?.split(",").filter(Boolean)?.length,
      // ];
      if (colorstone_from === "master") {
        gemstoneLength = gemstoneLength.concat([
          `${colorstone_weight}`?.split(",").filter(Boolean)?.length,
          `${colorstone_pieces}`?.split(",").filter(Boolean)?.length,
          `${colorstone_quality}`?.split(",").filter(Boolean)?.length,
          `${colorstone_type}`?.split(",").filter(Boolean)?.length,
          // `${colorstone_shape}`?.split(",").filter(Boolean)?.length,
          // `${colorstone_size}`?.split(",").filter(Boolean)?.length,
        ]);
      } else
        gemstoneLength = gemstoneLength.concat([
          `${colorstone_rate || ""}`?.split(",").filter(Boolean)?.length,
        ]);

      let lastColorstoneLength = gemstoneLength[0];
      let isColorstoneSame = true;

      for (let len of gemstoneLength) {
        if (lastColorstoneLength !== len) {
          isColorstoneSame = false;
          break;
        }
      }
      if (!isColorstoneSame) {
        let min = findColumnIndexFromColumns({
          columns,
          key: "colorstone_weight",
        });
        let max = findColumnIndexFromColumns({
          columns,
          key: "colorstone_quality",
        });
        if (min < 0) min = 0;
        if (max < 0) max = 0;
        invalidProducts.push({
          min: { row: index, col: min },
          max: { row: index, col: max },
          row: index,
          // column: columnIndex,
          error: `Gemstone combination mismatch`,
          message: "Please enter correct gemstone combination",
        });
      }
      if (
        (colorstone_pieces ||
          colorstone_quality ||
          colorstone_rate ||
          colorstone_shape ||
          colorstone_size ||
          colorstone_type) &&
        !colorstone_weight
      ) {
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "colorstone_weight",
        });
        invalidProducts.push({
          row: index,
          column: columnIndex,
          error: `No gemstone weight found`,
          message: "Please enter gemstone weight",
        });
      }
      if (
        isArrayWithValues(
          extra_charge_label?.map((i) => i?.trim())?.filter(Boolean)
        )
      ) {
        if (isArrayWithValues(extra_charge_label) && !extra_charge_value) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: "extra_charge_value",
          });
          invalidProducts.push({
            row: index,
            column: columnIndex,
            error: `No extra charges amount found`,
            message: "Please enter extra charges amount",
          });
        }
        if (isArrayWithValues(extra_charge_label) && extra_charge_value) {
          let labelLength = extra_charge_label?.length;
          let valueLength = extra_charge_value?.split(",")?.length;

          if (labelLength !== valueLength) {
            let columnIndex = findColumnIndexFromColumns({
              columns,
              key: "extra_charge_value",
            });
            invalidProducts.push({
              row: index,
              column: columnIndex,
              error: `Extra charges are invalid`,
              message: "Make sure extra charges values match the labels",
            });
          }
        }
      }
      if (
        labourType === "customize" &&
        !minimum_making &&
        !wastage_percent &&
        !per_gram
      ) {
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "per_gram",
        });
        invalidProducts.push({
          row: index,
          column: columnIndex,
          error: `There should be at least one value added for the labour`,
          message: "Please enter one of Minimum making, wastage or per gram",
        });
      }
    }

    index++;
  }
  return invalidProducts;
};
const validateCreatedUpdatedProducts = ({
  products,
  columns,
  defaultProductObject,
  isUpdate,
}) => {
  let invalidProducts = [];
  let index = 0;
  for (let product of products) {
    let { sku, error, code, message, stock_quantity, stock_status } = product;
    if (error?.code === "product_invalid_sku") {
      let columnIndex = findColumnIndexFromColumns({
        columns,
        key: "sku",
      });
      invalidProducts.push({
        row: index,
        column: columnIndex,
        error: `Duplicate or invalid sku found`,
        message: "Please choose another sku for the product",
      });
    } else {
      invalidProducts.push({
        row: 0,
        column: 0,
        error: `Unable to ${isUpdate ? "update" : "create"} the product`,
        message: `Unable to ${isUpdate ? "update" : "create"} the product`,
      });
    }
  }
  return invalidProducts;
};

const findColumnIndexFromColumns = ({ columns = [], key }) => {
  return columns.findIndex((i) => i.id === key);
};

const columns = [
  { label: "SKU", value: "sku", disabled: true, mandatory: true },
  { label: "Barcode", value: "barcode" },
  { label: "Slug", value: "slug" },
  { label: "Title", value: "name" },
  { label: "Status", value: "status", mandatory: true },
  { label: "Description", value: "description" },
  { label: "Stock Status", value: "stock_status", mandatory: true },
  { label: "Quantity", value: "stock_quantity", mandatory: true },
  { label: "Collections", value: "collections" },
  { label: "Category", value: "categories" },
  { label: "Sub Category", value: "subcategory" },
  { label: "Settings/Size", value: "subcatsetting" },
  { label: "Featured", value: "featured" },
  { label: "Backorders", value: "backorders" },
  { label: "Sold individually", value: "sold_individually" },
  { label: "HUID", value: "huid" },
  { label: "Min shipping days", value: "min_shipping_days" },
  { label: "Max shipping days", value: "max_shipping_days" },
  { label: "Metal Type", value: "metal_types" },
  { label: "Metal Color", value: "metal_color" },
  { label: "Gold Purity", value: "gold_kt" },
  { label: "Gold Gross", value: "gold_gross" },
  { label: "Gold Net", value: "gold_net" },
  { label: "Silver Purity", value: "silver_purity" },
  { label: "Silver Gross", value: "silver_gross" },
  { label: "Silver Net", value: "silver_net" },
  { label: "Platinum Purity", value: "platinium_purity" },
  { label: "Platinum Gross", value: "platinium_gross" },
  { label: "Platinum Net", value: "platinium_net" },
  {
    label: "Diamond",
    value: "diamond",
    child: [
      { label: "Diamond From", value: "diamond_from" },
      { label: "Diamond Type", value: "diamond_type" },
      { label: "Diamond Quality", value: "diamond_quality" },
      { label: "Diamond Shapes", value: "diamond_shape" },
      { label: "Diamond Cut", value: "diamond_cut" },
      { label: "Diamond Pieces", value: "diamond_pieces" },
      { label: "Diamond Weight", value: "diamond_weight" },
      { label: "Custom Diamond Rate", value: "diamond_rate" },
      { label: "Diamond Certified", value: "diamond_certified" },
      { label: "Diamond Lab", value: "diamond_lab" },
      { label: "Diamond Certificate ID", value: "diamond_certificate_id" },
    ],
  },
  {
    label: "Gemstone",
    value: "colorstone",
    child: [
      { label: "Gemstone From", value: "colorstone_from" },
      { label: "Gemstone Quality", value: "colorstone_quality" },
      { label: "Gemstone Shape", value: "colorstone_shape" },
      { label: "Gemstone Type", value: "colorstone_type" },
      { label: "Gemstone Pieces", value: "colorstone_pieces" },
      { label: "Gemstone Size", value: "colorstone_size" },
      { label: "Gemstone Weight", value: "colorstone_weight" },
      { label: "Gemstone Color", value: "colorstone_color" },
      { label: "Custom Gemstone Rate", value: "colorstone_rate" },
      // Handling Pearl + Additional Jpearls related info
      { label: "No. of Lines", value: "no_of_lines" },
      // { label: "Length", value: "length" },
      { label: "Closure Type", value: "closure_type" },
      { label: "Pearl Type", value: "pearl_type" },
      { label: "Pearl Setting Type", value: "pearl_setting_type" },
      { label: "Pearl Size", value: "pearl_size" },
      { label: "Pearl Pieces", value: "pearl_pieces" },
      { label: "Pearl Weight", value: "pearl_weight" },
    ],
  },
  ...allSizeGuides.map((i) => ({ label: i?.label, value: i?.size_guide })),
  { label: "Labour Type", value: "labourType" },
  { label: "Labour From", value: "labour_from" },
  { label: "Wastage in (%)", value: "wastage_percent" },
  { label: "Wastage From", value: "custom_wastage_from" },
  { label: "Per Gram", value: "per_gram" },
  { label: "Minimum Making", value: "minimum_making" },
  { label: "Extra Charge Type", value: "extra_charge_label" },
  { label: "Extra Charge Value", value: "extra_charge_value" },
  { label: "SEO Title", value: "seo_title" },
  { label: "SEO Description", value: "seo_description" },
  { label: "SEO Keywords", value: "seo_keywords" },
  { label: "Width", value: "product_width" },
  { label: "Height", value: "product_height" },
  { label: "Thickness", value: "product_thickness" },
  { label: "Tags", value: "tag" },
  { label: "Gender", value: "gender" },
  { label: "Ocassions", value: "ocassions" },
  { label: "Min Shipping Days", value: "min" },
  { label: "Max Shipping Days", value: "max" },
  { label: "Manual Price", value: "manual_price" },
  { label: "Sale Price", value: "sale_price" },
  // { label: "Automatic Price", value: "regular_price" },
  { label: "Disable Customization", value: "disable_customization" },
  { label: "Upsell", value: "upsell_ids" },
  { label: "Cross Sell", value: "cross_sell_ids" },
  // { label: "Discount On", value: "DiscountOn" },
  // { label: "Discount Type", value: "type" },
  // { label: "Discount Value", value: "value" },
  { label: "Images", value: "images" },
  { label: "Video URL", value: "_woodmart_product_video" },
];

export const columnTooltips = {
  products: {
    subcatsetting: "Enter same text, numbers or space, as defined in Master.",
    huid: "6 Alphanumeric characters only",
    diamond_quality:
      "Select the correct option for which you have set the price in Master",
    diamond_shape:
      "Select the correct shape in sync with Dia. Quality >, for which you set the price in Master",
    diamond_cut:
      "Select the correct Cut in sync with Dia Quality > Dia Shape >, for which you set the price in Master",
    diamond_certified: "Check the box if diamonds are certified",
    colorstone_quality:
      "Select the correct option for which you have set the price in Master",
    colorstone_type:
      "Select the correct shape in sync with GS Quality >, for which you set the price in Master",
    colorstone_shape:
      "Select the correct Cut in sync with GS Quality > GS Type >, for which you set the price in Master",
    colorstone_size:
      "Select the correct Cut in sync with GS Quality > GS Type > GS Shape, for which you set the price in Master",
    extra_charge_label:
      "In case of more than one Extra Charge Type selected then respective Amount value should be seperated by comma eg. 100, 60, 150",
    seo_keywords:
      "In case of multiple keywords, separate them by comma eg. Flower Ring, Yellow Ring",
    manual_price:
      "Write Product Price in case if you want to override calculated product price",
    upsell_ids:
      "write SKU number of matching products or other which you want to promote with this product",
    cross_sell_ids:
      "write SKU number of matching products or other which you want to promote with this product",
    min_shipping_days: "provide numeric values from 1 to 100.",
    max_shipping_days: "provide numeric values from 1 to 100.",
    images: "Enter filename of the image without extension",
    _woodmart_product_video: "Enter filename of the video without extension",
  },
};

const products_keys_mapping_static = {
  // date_modified_gmt: {
  //   column_name: "Last Modified",
  //   is_date: true,
  //   type: "date",
  // },
  sku: {
    column_name: "SKU",
    type: "string",
    required: true,
  },
  barcode: {
    column_name: "Barcode",
    type: "string",
  },
  slug: {
    column_name: "Slug",
    type: "string",
  },
  // actions: {
  //   column_name: "Actions",
  //   is_dropdown: true,
  //   type: "dropdown",
  // },
  // ProductError: {
  //   column_name: "Product Error",
  //   type: "string",
  // },
  name: {
    column_name: "Title",
    type: "string",
    required: true,
  },
  status: {
    column_name: "Status",
    type: "dropdown",
    required: true,
  },
  description: {
    column_name: "Product Description",
    type: "string",
  },
  // slug: {
  //   column_name: "Slug",
  //   type: "string",
  // },
  stock_status: {
    column_name: "Stock Status",
    is_dropdown: true,
    type: "dropdown",
    required: true,
  },
  stock_quantity: {
    column_name: "Quantity",
    is_number: true,
    type: "number",
    required: true,
  },
  collections: {
    column_name: "Collections",
    type: "dropdown",
    // type: "string",
    is_multiple_dropdown: true,
    width: 300,
    search: true,
  },

  categories: {
    column_name: "Category",
    type: "dropdown",
    is_multiple_dropdown: true,
    width: 300,
    search: true,
  },
  subcategory: {
    column_name: "Sub Category",
    is_dropdown: true,
    type: "dropdown",
    dropdown_value: "subcategory",
    is_multiple_dropdown: true,
    width: 300,
    search: true,
  },
  subcatsetting: {
    column_name: "Settings/Size",
    type: "dropdown",
    // is_multiple_dropdown: false,
    // toFixed: 2,
  },
  featured: {
    is_boolean: true,
    column_name: "Featured",
    type: "checkbox",
  },
  backorders: {
    column_name: "Backorders allowed",
    type: "checkbox",
  },
  sold_individually: {
    column_name: "Sold Individually",
    type: "checkbox",
  },
  huid: {
    column_name: "HUID",
    type: "string",
  },
  min_shipping_days: {
    column_name: "Min shipping days",
    type: "number",
  },
  max_shipping_days: {
    column_name: "Max shipping days",
    type: "number",
  },
  metal_types: {
    column_name: "Metal Type",
    type: "dropdown",
    is_multiple_dropdown: true,
  },
  metal_color: {
    column_name: "Metal Color",
    is_dropdown: true,
    type: "dropdown",
  },
  gold_kt: {
    column_name: "Gold Purity",
    type: "dropdown",
  },
  gold_gross: {
    column_name: "Gold Gross",
    is_number: true,
    type: "number",
    toFixed: 3,
  },
  gold_net: {
    is_float: true,
    column_name: "Gold Net",
    type: "number",
    toFixed: 3,
  },
  silver_purity: {
    column_name: "Silver Purity",
    is_dropdown: true,
    type: "dropdown",
  },
  silver_gross: {
    column_name: "Silver Gross",
    is_float: true,
    type: "number",
    toFixed: 3,
  },
  silver_net: {
    column_name: "Silver Net",
    is_float: true,
    type: "number",
    toFixed: 3,
  },
  platinium_purity: {
    column_name: "Platinum Purity",
    is_dropdown: true,
    type: "dropdown",
  },
  platinium_gross: {
    column_name: "Platinum Gross",
    is_float: true,
    type: "number",
    toFixed: 3,
  },
  platinium_net: {
    column_name: "Platinum Net",
    is_float: true,
    type: "number",
    toFixed: 3,
  },
  diamond_from: {
    column_name: "Diamond From",
    is_dropdown: true,
    type: "dropdown",
  },

  diamond_weight: {
    is_dropdown: true,
    column_name: "Diamond Weight",
    type: "string",
  },
  diamond_pieces: {
    column_name: "Diamond Pieces",
    type: "string",
  },
  diamond_type: {
    column_name: "Diamond Type",
    type: "dropdown",
    dropdown_value: "diamond_types",
    is_multiple_dropdown: false,
    // duplicate_dropdown: true,
  },
  diamond_quality: {
    column_name: "Diamond Quality",
    type: "dropdown",
    dropdown_value: "diamond_qualities",
    is_multiple_dropdown: true,
    duplicate_dropdown: true,
  },
  diamond_shape: {
    column_name: "Diamond Shapes",
    dropdown_value: "diamond_shapes",
    type: "dropdown",
    is_multiple_dropdown: true,
    duplicate_dropdown: true,
  },
  diamond_cut: {
    column_name: "Diamond Cut",
    type: "dropdown",
    dropdown_value: "diamond_cuts",
    is_multiple_dropdown: true,
    duplicate_dropdown: true,
  },
  diamond_rate: {
    is_float: true,
    column_name: "Custom Diamond Rate",
    type: "string",
  },
  diamond_certified: {
    column_name: "Diamond Certified",
    type: "checkbox",
  },
  diamond_lab: {
    column_name: "Diamond Lab",
    dropdown_value: "diamond_labs",
    type: "dropdown",
  },
  diamond_certificate_id: {
    column_name: "Diamond Certificate ID",
    type: "string",
    width: 230,
  },
  colorstone_from: {
    column_name: "Gemstone From",
    type: "dropdown",
    is_multiple_dropdown: false,
  },
  colorstone_quality: {
    column_name: "Gemstone Quality",
    type: "dropdown",
    dropdown_value: "gemstone_qualities",
    is_multiple_dropdown: true,
    duplicate_dropdown: true,
  },
  colorstone_shape: {
    column_name: "Gemstone Shape",
    type: "dropdown",
    dropdown_value: "gemstone_shapes",
    is_multiple_dropdown: true,
    duplicate_dropdown: true,
  },
  colorstone_type: {
    column_name: "Gemstone Type",
    type: "dropdown",
    dropdown_value: "gemstone_types",
    is_multiple_dropdown: true,
    duplicate_dropdown: true,
  },
  colorstone_size: {
    column_name: "Gemstone Size",
    type: "dropdown",
    is_multiple_dropdown: true,
    dropdown_value: "gemstone_sizes",
    duplicate_dropdown: true,
  },
  colorstone_pieces: {
    column_name: "Gemstone Pieces",
    type: "string",
  },
  colorstone_weight: {
    column_name: "Gemstone Weight",
    type: "string",
  },
  colorstone_color: {
    column_name: "Gemstone Color",
    type: "dropdown",
    is_multiple_dropdown: true,
    dropdown_value: "gemstone_color",
    duplicate_dropdown: true,
  },
  colorstone_rate: {
    column_name: "Custom Gemstone Rate",
    type: "string",
  },
  //
  no_of_lines: {
    column_name: "No. of Lines",
    type: "string",
  },
  length: {
    column_name: "Length",
    type: "string",
  },
  closure_type: {
    column_name: "Closure Type",
    type: "string",
  },
  pearl_type: {
    column_name: "Pearl Type",
    type: "string",
  },
  pearl_setting_type: {
    column_name: "Pearl Setting Type",
    type: "string",
  },
  pearl_size: {
    column_name: "Pearl Size",
    type: "string",
  },
  pearl_pieces: {
    column_name: "Pearl Pieces",
    type: "string",
  },
  pearl_weight: {
    column_name: "Pearl Weight",
    type: "string",
  },
  //
  labourType: {
    column_name: "Labour Type",
    is_dropdown: true,
    type: "dropdown",
  },
  labour_from: {
    column_name: "Labour From",
    is_dropdown: true,
    type: "dropdown",
  },
  per_gram: {
    is_float: true,
    column_name: "Per Gram",
    type: "number",
  },
  custom_wastage_from: {
    column_name: "Wastage From",
    is_dropdown: true,
    type: "dropdown",
  },
  wastage_percent: {
    is_float: true,
    column_name: "Wastage in (%)",
    type: "number",
  },
  minimum_making: {
    column_name: "Minimum Making",
    is_float: true,
    type: "number",
  },
  extra_charge_label: {
    column_name: "Extra Charge Type",
    type: "dropdown",
    is_multiple_dropdown: true,
    dropdown_value: "extra_charges",
  },
  extra_charge_value: {
    column_name: "Extra Charge Value",
    type: "string",
  },
  seo_title: {
    column_name: "SEO Title",
    type: "string",
  },
  seo_description: {
    column_name: "SEO Description",
    type: "string",
  },
  seo_keywords: {
    column_name: "SEO Keywords",
    type: "string",
  },
  product_width: {
    column_name: "Width (in mm)",
    toFixed: 2,
    type: "number",
  },
  product_height: {
    column_name: "Height (in mm)",
    toFixed: 2,
    type: "number",
  },
  product_thickness: {
    column_name: "Thickness (in mm)",
    toFixed: 2,
    type: "number",
  },
  tag: {
    column_name: "Tags",
    type: "dropdown",
    is_multiple_dropdown: true,
    width: 350,
    search: true,
  },
  gender: {
    column_name: "Gender",
    type: "dropdown",
    is_multiple_dropdown: true,
  },
  ocassions: {
    column_name: "Ocassions",
    is_dropdown: true,
    type: "dropdown",
    is_multiple_dropdown: true,
    width: 280,
  },
  // min: {
  //   column_name: "Min Shipping Days",
  //   is_number: true,
  //   type: "number",
  // },
  // max: {
  //   column_name: "Max Shipping Days",
  //   is_number: true,
  //   type: "number",
  // },
  manual_price: {
    column_name: "Manual Product Price (MRP)",
    is_float: true,
    type: "number",
    width: 250,
  },
  sale_price: {
    column_name: "Sale Price",
    is_float: true,
    type: "number",
    width: 250,
  },
  disable_customization: {
    column_name: "Disable Customization",
    type: "checkbox",
  },
  // regular_price: {
  //   column_name: "Automatic Price",
  //   is_float: true,
  //   type: "string",
  //   disabled: true,
  // },
  upsell_ids: {
    column_name: "Upsell",
    type: "string",
  },
  cross_sell_ids: {
    column_name: "Cross Sell",
    type: "string",
  },
  // DiscountOn: {
  //   column_name: "Discount On",
  //   type: "string",
  // },
  // type: {
  //   column_name: "Discount Type",
  //   type: "string",
  // },
  // value: {
  //   is_number: true,
  //   column_name: "Discount Value",
  //   type: "number",
  // },
  images: {
    column_name: "Images",
    type: "string",
  },
  _woodmart_product_video: {
    column_name: "Video URL",
    type: "string",
  },
};

const products_keys_mapping = allSizeGuides.reduce(
  (acc, guide) => {
    acc[guide.size_guide] = {
      column_name: guide.label,
      type: "string",
    };
    return acc;
  },
  { ...products_keys_mapping_static }
);

const convertDataToDatasheetColumn = (props) => {
  let {
    dropdowns,
    selectedColumns = {},
    disabledSKU,
    sizesDropdown,
    productSettings = {},
  } = props || {};
  let array = [];
  let mappingObject = {};
  for (let key in products_keys_mapping)
    if (selectedColumns?.[key]) mappingObject[key] = products_keys_mapping[key];

  for (let i of Object.keys(mappingObject)) {
    let {
      column_name,
      type,
      is_multiple_dropdown,
      dropdown_value,
      width,
      toFixed,
      disabled,
      duplicate_dropdown,
      required,
    } = products_keys_mapping[i];
    let column_key = i;
    if (required) column_name = `${column_name}*`;
    if (
      productSettings?.net_weight === "auto" &&
      (column_key === "gold_net" ||
        column_key === "silver_net" ||
        column_key === "platinium_net")
    ) {
      type = "string";
      // obj.silver_net = "auto";
      // obj.platinium_net = "auto";
    }
    // if (required)
    //   column_name = (
    //     <span
    //       style={{
    //         flex: 1,
    //         minWidth: width || validateNumber(column_name?.length) * 10 + 50,
    //         flexBasis: 1,
    //         flexGrow: 1,
    //       }}
    //     >
    //       {column_name}
    //       <span style={{ color: "red" }}>*</span>
    //     </span>
    //   );
    if (disabledSKU && disabledFields.product?.includes(column_key)) {
      disabled = true;
    }
    if (
      // column_key === "metal_color" ||
      column_key === "gold_kt" ||
      column_key === "gold_gross" ||
      column_key === "gold_net"
    )
      disabled = ({ rowData }) => !rowData?.metal_types?.includes("gold");
    if (
      column_key === "silver_purity" ||
      column_key === "silver_gross" ||
      column_key === "silver_net"
    )
      disabled = ({ rowData }) => !rowData?.metal_types?.includes("silver");
    if (
      column_key === "platinium_purity" ||
      column_key === "platinium_gross" ||
      column_key === "platinium_net"
    )
      disabled = ({ rowData }) => !rowData?.metal_types?.includes("platinium");

    if (
      column_key === "gold_net" ||
      column_key === "silver_net" ||
      column_key === "platinium_net"
    ) {
      disabled = ({ rowData }) => productSettings?.net_weight === "auto";
      if (productSettings?.net_weight === "auto")
        column_name = `${column_name} (Auto)`;
    }
    if (
      column_key === "diamond_from" ||
      column_key === "diamond_type" ||
      column_key === "diamond_quality" ||
      column_key === "diamond_shape" ||
      column_key === "diamond_cut" ||
      column_key === "diamond_pieces" ||
      column_key === "diamond_weight" ||
      column_key === "diamond_rate" ||
      column_key === "diamond_certified" ||
      column_key === "diamond_lab" ||
      column_key === "diamond_certificate_id"
    ) {
      disabled = ({ rowData }) =>
        !rowData?.collections?.find((i) =>
          i?.toLowerCase()?.includes("diamond")
        );
    }

    if (
      column_key === "diamond_certificate_id" ||
      column_key === "diamond_lab"
    ) {
      disabled = ({ rowData }) => !rowData?.diamond_certified;
    }
    if (column_key === "diamond_rate")
      disabled = ({ rowData }) =>
        rowData?.diamond_from === "master" || !rowData?.diamond_from;
    if (column_key === "colorstone_rate")
      disabled = ({ rowData }) =>
        rowData?.colorstone_from === "master" || !rowData?.colorstone_from;

    if (
      column_key === "labour_from" ||
      column_key === "wastage_percent" ||
      column_key === "per_gram" ||
      column_key === "labour" ||
      column_key === "custom_wastage_from"
    ) {
      disabled = ({ rowData }) =>
        rowData?.labourType == "master" || !rowData?.labourType;
    }
    let column = {
      minWidth: width || validateNumber(column_name?.length) * 10 + 50,
    };
    if (type === "dropdown") {
      let options = dropdowns?.[dropdown_value] || dropdowns?.[i];
      column = {
        ...column,
        copyValue: ({ rowData }) => {
          console.log(rowData);
          return is_multiple_dropdown && !duplicate_dropdown && isArray(rowData)
            ? rowData?.join() || ""
            : rowData || "";
        },

        pasteValue: ({ value }) =>
          is_multiple_dropdown && !duplicate_dropdown
            ? value?.split(",")
            : value,
        columnData: { continuousUpdates: true },
        component: React.memo(
          (props) => {
            const { rowData, rowIndex } = props;
            // if (column_key === "subcatsetting") {
            //   options = sizesDropdown?.[rowData?.categories];
            //   console.log(
            //     sizesDropdown,
            //     rowData,
            //     sizesDropdown?.[rowData?.categories],
            //     props,
            //     "size masters"
            //   );
            // }
            return (
              <SelectComponent
                key={i}
                column_key={i}
                column_name={column_name}
                is_multiple_dropdown={is_multiple_dropdown}
                duplicate_dropdown={duplicate_dropdown}
                // selectedValue={rowData?.[i]}
                selectedValue={rowData}
                options={options}
                {...props}
              />
            );
          },
          (p, n) =>
            arePropsEqual(p, n, ["rowData", "disabled", "focus", "active"])
        ),
      };
    } else if (type === "number") {
      if (toFixed || true)
        column = {
          ...column,
          ...floatColumn,
          prePasteValues: async (data) => {
            await sleep(300);
            return data;
          },
          pasteValue: ({ value }) => {
            // value &&
            console.log(value, "<number Value");

            if (value) return validateNumber(value);
          },
          // grow:
          columnData: {
            ...floatColumn.columnData,
            parseUserInput: (e) => {
              return validateNumber(e)
                ? validateNumber(e)?.toFixed(toFixed || 0)
                : "";
            },
            // formatInputOnFocus: (e) => {
            //   console.log(e, "formatInputOnFocus");
            //   return "asdfasdf";
            // },
            formatBlurredInput: (e) => {
              return validateNumber(e)
                ? validateNumber(e)?.toFixed(toFixed || 0)
                : "";
            },
          },

          // cellClassName: (props) => {
          //   let { rowData } = props;
          //   return isNaN(rowData) ? "error-cell" : "";
          // },
        };
      else column = { ...column, ...intColumn };
    } else if (type === "checkbox") {
      column = {
        ...column,
        ...checkboxColumn,
        // cellClassName: (props) => {
        //   let { rowData } = props;
        //   return isNaN(rowData) ? "error-cell" : "";
        // },
      };
    }
    // column = createTextColumn({
    //   ...column,
    // });
    else
      column = {
        ...column,
        ...textColumn,
        // cellClassName: (props) => {
        //   let { rowData } = props;
        //   return isNaN(rowData) || rowData === null ? "" : "";
        // },
        // cellClassName: (props) => {
        //   let { rowData } = props;
        //   return isNaN(rowData) || rowData === null
        //     ? "error-cell"
        //     : "error-cell";
        // },
        // cellRenderer: (props) => (
        //   <div style={{}} className="error-cell">
        //     {props.rowData}
        //   </div>
        // ),
      };
    let columnObject = {
      title: column_name,
      disabled,
    };
    // if (type === "dropdown") columnObject = { ...columnObject, ...column };
    // else
    {
      columnObject = {
        ...keyColumn(i, column),
        ...columnObject,
      };
    }
    array.push(columnObject);
    // if (key === "status") {
    //   return {
    //     ...keyColumn(key, {
    //       ...textColumn,
    //       cellRenderer: (props) => {
    //         const { rowData } = props;
    //         const chipLabel = rowData === "publish" ? "Publish" : "Draft";
    //         const chipColor =
    //           rowData === "publish"
    //             ? "status-cell-publish"
    //             : "status-cell-draft";

    //         return (
    //           <div className="status-cell">
    //             <Stack direction="row" spacing={1}>
    //               <Chip label={chipLabel} color={chipColor} />
    //             </Stack>
    //           </div>
    //         );
    //       },
    //     }),
    //     title: column_name,
    //     minWidth: 200,
    //   };
    // } else if (is_dropdown) {
    //   const columnDefinition = {
    //     component: (props) => {
    //       const { rowData, rowIndex } = props;
    //       const selectedValue = rowData[key];

    //       const handleValueChange = (newValue, columnKey, rowIndex) => {
    //         setData((prevData) => {
    //           const newData = [...prevData];
    //           newData[rowIndex][columnKey] = newValue;
    //           return newData;
    //         });
    //       };

    //       return (
    //         <>
    //           <SelectComponent
    //             column_key={key}
    //             selectedValue={selectedValue}
    //             // onValueChange={handleValueChange}
    //             onValueChange={(newValue) =>
    //               handleValueChange(newValue, key, rowIndex)
    //             }
    //             {...props}
    //           />
    //         </>
    //       );
    //     },
    //     disableKeys: true,
    //     //  deleteValue: () => null,
    //     //  copyValue: ({ rowData }) =>
    //     //    choices.find((choice) => choice.value === rowData)?.label,
    //     //  pasteValue: ({ value }) =>
    //     //    choices.find((choice) => choice.label === value)?.value ?? null,
    //     title: column_name,

    //     minWidth: 200,
    //   };

    //   return columnDefinition;
    // } else {
    //   const columnDefinition = {
    //     ...keyColumn(
    //       key,
    //       is_number
    //         ? {
    //             ...intColumn,

    //             cellClassName: (props) => {
    //               let { rowData } = props;
    //               return isNaN(rowData) || rowData === null
    //                 ? "error-cell"
    //                 : "normal-cell";
    //             },
    //             cellRenderer: (props) => {
    //               let { rowData } = props;
    //               return NaN(rowData) || rowData === null ? (
    //                 <div className="error-cell">
    //                   Error: {rowData}
    //                   <FaExclamationCircle className="error-icon" />
    //                 </div>
    //               ) : (
    //                 <div className="normal-cell">{rowData}</div>
    //               );
    //             },
    //           }
    //         : is_float
    //         ? {
    //             ...floatColumn,

    //             cellClassName: (props) => {
    //               let { rowData } = props;
    //               return isNaN(rowData) || rowData === null
    //                 ? "error-cell"
    //                 : "normal-cell";
    //             },
    //             cellRenderer: (props) => {
    //               let { rowData } = props;
    //               return NaN(rowData) || rowData === null ? (
    //                 <div className="error-cell">
    //                   Error: {rowData}
    //                   <FaExclamationCircle className="error-icon" />
    //                 </div>
    //               ) : (
    //                 <div className="normal-cell">{rowData}</div>
    //               );
    //             },
    //           }
    //         : is_boolean
    //         ? floatColumn
    //         : is_date
    //         ? customDateColumn
    //         : is_decimal
    //         ? floatColumn
    //         : textColumn
    //     ),
    //     title: column_name,
    //     minWidth: 200,
    //   };
    //   return columnDefinition;
    // }
  }
  return array;
};

const getDropdownData = ({
  productSettings = {},
  masterPricing = {},
  taxonomies = {},
  options = {},
  sizesDropdown = {},
}) => {
  let { user_type } = options;
  if (isObjWithValues(productSettings)) {
    let {
      categories,
      collections,
      colors,
      gold_purities,
      silver_purities,
      platinum_purities,
      gemstone_qualities,
      gemstone_shapes,
      gemstone_types,
      diamond_cuts,
      diamond_shapes,
      diamond_types,
      diamond_colors,
      types,
      diamond_labs,
    } = productSettings;
    let selectedTypes = [];
    let goldType = types.find((i) => i.value === "gold");
    let silverType = types.find((i) => i.value === "silver");
    let platiniumType = types.find((i) => i.value === "platinium");

    if (goldType) selectedTypes.push(goldType);
    if (silverType) selectedTypes.push(silverType);
    if (platiniumType) selectedTypes.push(platiniumType);
    let obj = {
      status: [
        { label: "Publish", value: "publish", type: "completed" },
        { label: "Draft", value: "draft", type: "failed" },
      ],
      stock_status: [
        { label: "In Stock", value: "instock", type: "completed" },
        { label: "Make to Order", value: "onbackorder", type: "pending" },
        { label: "Out of Stock", value: "outofstock", type: "failed" },
      ],
      backorders: [
        { label: "Yes", value: "yes", type: "completed" },
        { label: "Notify", value: "notify", type: "pending" },
        { label: "No", value: "no", type: "failed" },
      ],
      labourType: [
        { label: "Master", value: "master" },
        { label: "Customize", value: "customize" },
      ],
      diamond_from: [
        { label: "Master", value: "master" },
        { label: "Customize", value: "customize" },
      ],
      colorstone_from: [
        { label: "Master", value: "master" },
        { label: "Customize", value: "customize" },
      ],
      colorstone_color: gemstoneColors,
      labour_from: [
        { label: "Gross", value: "gross" },
        { label: "Net", value: "net" },
      ],
      custom_wastage_from: [
        { label: "Gross", value: "gross" },
        { label: "Net", value: "net" },
      ],
      diamond_lab: [],
      categories: [],
      collections: [],
      subcategory: [],
      metal_color: [],
      gold_kt: [],
      silver_purity: [],
      platinium_purity: [],
      gemstone_sizes: [],
      gemstone_qualities: isArrayWithValues(gemstone_qualities)
        ? gemstone_qualities.filter(Boolean)
        : [],
      gemstone_shapes: isArrayWithValues(gemstone_shapes)
        ? gemstone_shapes.filter(Boolean)
        : [],
      gemstone_types: isArrayWithValues(gemstone_types)
        ? gemstone_types.filter(Boolean)
        : [],
      // gemstone_sizes: Object(gemstoneSizes)
      diamond_qualities: [],
      diamond_cuts: isArrayWithValues(diamond_cuts)
        ? diamond_cuts.filter(Boolean)
        : [],
      // diamond_colors: isArrayWithValues(diamond_colors)
      //   ? diamond_colors.filter(Boolean)
      //   : [],
      diamond_shapes: isArrayWithValues(diamond_shapes)
        ? diamond_shapes.filter(Boolean)
        : [],
      diamond_types: isArrayWithValues(diamond_types)
        ? diamond_types?.map((i) => ({ ...i, value: i.label })).filter(Boolean)
        : [],
      extra_charges: [],
      metal_type: selectedTypes,
      metal_types: selectedTypes,
    };
    let allGemstoneSizes = Object.values(gemstoneSizes)
      .map((i) => Object.keys(i).map((j) => ({ label: j, value: j })))
      .flat();
    allGemstoneSizes = removeDuplicatesFromArray(allGemstoneSizes, "value");
    obj.gemstone_sizes = allGemstoneSizes;
    let { diamond_pricing, additional_pricing } = masterPricing;
    let { default_currency } = productSettings;
    let diamondQualities = getDiamondQualityWithoutType(
      diamond_pricing?.[default_currency]
    );
    if (isArrayWithValues(diamondQualities))
      obj.diamond_qualities = diamondQualities;
    if (isArrayWithValues(diamond_labs))
      obj.diamond_lab = diamond_labs?.filter(Boolean);
    if (isArrayWithValues(categories)) obj.categories = categories;
    if (isArrayWithValues(collections)) obj.collections = collections;
    if (isArrayWithValues(colors)) obj.metal_color = colors.filter(Boolean);
    if (isArrayWithValues(gold_purities))
      obj.gold_kt = gold_purities.filter(Boolean);
    if (isArrayWithValues(silver_purities))
      obj.silver_purity = silver_purities.filter(Boolean);
    if (isArrayWithValues(platinum_purities))
      obj.platinium_purity = platinum_purities.filter(Boolean);

    if (
      isObjWithValues(masterPricing) &&
      isObjWithValues(masterPricing.additional_pricing)
    ) {
      let { additional_pricing } = masterPricing;
      obj.extra_charges = Object.keys(additional_pricing).map((i) => ({
        label: i,
        value: i,
      }));
    }
    if (isObjWithValues(productSettings)) {
      if (user_type === "supplier") {
        let { collections, categories, ocassions, tags } = productSettings;
        if (isArrayWithValues(collections))
          obj.collections = collections.filter(Boolean);
        if (isArrayWithValues(categories))
          obj.categories = categories.filter(Boolean);
      }
    }
    if (isObjWithValues(taxonomies)) {
      let { collections, categories, ocassions, tags, gender } = taxonomies;
      if (user_type === "retailer" || !user_type) {
        if (isArrayWithValues(collections))
          obj.collections = collections
            ?.map((i) => ({ ...i, value: i.name || i.label }))
            .filter(Boolean);
        if (isArrayWithValues(categories))
          obj.categories = categories.filter(Boolean);
      }
      if (isArrayWithValues(taxonomies?.["sub-categories"]))
        obj.subcategory = taxonomies?.["sub-categories"]
          ?.map((i) => ({ ...i, value: i.name || i.label }))
          .filter(Boolean);
      if (isArrayWithValues(ocassions))
        obj.ocassions = ocassions
          ?.map((i) => ({ ...i, value: i.name || i.label }))
          .filter(Boolean);
      if (isArrayWithValues(gender))
        obj.gender = gender
          ?.map((i) => ({ ...i, value: i.name || i.label }))
          .filter(Boolean);
      if (isArrayWithValues(tags))
        obj.tag = tags
          ?.map((i) => ({ ...i, value: i.name || i.label }))
          .filter(Boolean);
      if (isObjWithValues(sizesDropdown))
        obj.subcatsetting = removeDuplicatesFromArray(
          Object.values(sizesDropdown)?.flat(),
          "value"
        );
    }

    return obj;
  }
};

const getDefaultProductObject = ({
  defaults,
  taxonomies,
  productSettings = {},
}) => {
  let obj = {
    status: "draft",
    diamond_from: "master",
    labourType: "master",
    labour_from: "gross",
    custom_wastage_from: "gross",
  };
  let {
    diamond_lab,
    metal_color,
    gold_kt,
    category,
    collection,
    gender,
    metal_type,
    stock_status,
    product_shipping,
  } = defaults || {};
  if (diamond_lab?.value) obj.diamond_lab = diamond_lab.value;
  if (metal_color?.value) obj.metal_color = metal_color.value;
  if (gold_kt?.value) obj.gold_kt = gold_kt.value;
  if (category?.label) obj.categories = [category.label];
  if (metal_type?.value) obj.metal_types = [metal_type?.value];
  if (collection?.label) {
    if (typeof collection.label === "string")
      obj.collections = [collection.label];
  }
  if (stock_status?.value) {
    obj.stock_status = stock_status.value;
    if (stock_status?.value === "instock") obj.stock_quantity = "1";
  }
  if (product_shipping) {
    obj.min_shipping_days = validateNumber(product_shipping.min) || "";
    obj.max_shipping_days = validateNumber(product_shipping.max) || "";
  }
  if (productSettings?.net_weight === "auto") {
    obj.gold_net = "auto";
    obj.silver_net = "auto";
    obj.platinium_net = "auto";
  }
  // if (gender?.value) obj.gender = gender.value;
  return obj;
};

export {
  products_keys_mapping,
  convertDataToDatasheetColumn,
  getDropdownData,
  columns,
  getDefaultProductObject,
  validateProducts,
  validateCreatedUpdatedProducts,
};
