import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notificationTypes } from "../../components/navbar/NavbarNotificationsDropdown";
import {
  getRelativeTimeDifference,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";

import { getNotificationIconByType } from "../dashboards/Default/NotificationsDashboard";
import ProfilePopup from "../design_bank/components/ProfilePopup";

function Notifications() {
  const navigate = useNavigate();
  const [allNotifications, setAllNotifications] = useState([]);
  const [filterValue, setFilterValue] = useState("notification");
  const [showProfile, setShowProfile] = useState(false);
  const notifications = useSelector((state) => state.settings.notifications);
  const fetchingAllSettings = useSelector(
    (state) => state.settings.fetchingAllSettings
  );
  const onlyChats = useSelector((state) => state.helper.onlyChats);
  const filters = onlyChats
    ? [
        { label: "All Notification", value: "notification" },
        {
          label: "New WhatsApp",
          value: "whatsapp_message,whatsapp_msg_notify",
        },
      ]
    : [
        { label: "All Notification", value: "notification" },
        { label: "New Users", value: "new_user" },
        { label: "Digital gold", value: "new_digigold" },
        {
          label: "New WhatsApp",
          value: "whatsapp_message,whatsapp_msg_notify",
        },
        { label: "Catalog Request", value: "catalog_request" },
      ];
  useEffect(() => {
    if (isArrayWithValues(notifications))
      setAllNotifications([...notifications]);
  }, [notifications]);
  const onClick = (obj) => {
    let { type, id } = obj;

    if (notificationTypes.chat.includes(type)) navigate(`/chats`);
    else if (notificationTypes.crm.includes(type)) navigate(`/crm/${id}#chat`);
    else if (notificationTypes.catalog.includes(type))
      navigate(`/catalogs/${id}`);
    else if (notificationTypes.catalogRequest.includes(type))
      navigate(`/catalogs/requests/${id}`);
    else if (notificationTypes.order.includes(type)) navigate(`/orders/${id}`);
    else if (notificationTypes.digigold.includes(type)) navigate(`/digigold`);
    else if (notificationTypes.review.includes(type))
      navigate(`/frontend#reviews`);
  };
  const onFilterChange = (e) => {
    setFilterValue(e?.target?.value);
    if (isArrayWithValues(notifications)) {
      if (e?.target?.value === "notification")
        setAllNotifications([...notifications]);
      else
        setAllNotifications(
          notifications?.filter((obj) => e?.target?.value?.includes(obj?.type))
        );
    }
  };

  return (
    <Card>
      <CardContent>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <CardHeader title="Notifications" />
          <Select value={filterValue} onChange={onFilterChange} size="small">
            {filters.map((obj) => (
              <MenuItem value={obj?.value}>{obj?.label}</MenuItem>
            ))}
          </Select>
        </Stack>
        <ProfilePopup
          open={showProfile}
          handleClose={() => setShowProfile(false)}
        />

        {fetchingAllSettings && !isArrayWithValues(allNotifications) ? (
          <Stack direction="row" justifyContent={"center"} my={15}>
            <CircularProgress />
          </Stack>
        ) : null}
        {allNotifications.map((obj) => {
          let timeObj = getRelativeTimeDifference(
            Number(obj.time * 1000),
            Number(new Date().getTime())
          );
          return (
            <Card
              sx={{ cursor: obj.id ? "pointer" : "default" }}
              onClick={() => onClick(obj)}
            >
              <CardContent>
                <Stack direction="row" alignItems={"center"} width="100%">
                  {getNotificationIconByType(obj.type)}
                  {/* {obj.type === "whatsapp_msg_notify" ||
                  obj.type === "whatsapp_message" ? (
                    <Whatsapp />
                  ) : (
                    <Person />
                  )} */}
                  <Stack flex={"100%"} ml={2}>
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      justifyContent="space-between"
                    >
                      <Typography variant="h6">{obj.title}</Typography>
                      <Stack alignItems={"flex-end"}>
                        {isObjWithValues(timeObj) && (
                          <Typography variant="caption">
                            {timeObj.value} {timeObj.label} ago
                          </Typography>
                        )}
                        {false && (
                          <Stack
                            direction={"row"}
                            alignItems="center"
                            gap={"15px"}
                          >
                            <Button size="small">Accept</Button>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowProfile(true);
                              }}
                            >
                              View profile
                            </Button>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                    {/* <div dangerouslySetInnerHTML={{__html:obj.message}} /> */}
                    <Stack>
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{
                          __html: obj.message,
                        }}
                        sx={{ margin: 0, padding: 0 }}
                      >
                        {/* {obj.message} */}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          );
        })}
      </CardContent>
    </Card>
  );
}

export default Notifications;
