export const panelStyles = {
  panelBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "white",
    height: "50px",
    // width: "425px",
    borderRadius: "7px",
    padding: "0px 15px 0px 15px",
    top: "0px",
    position: "absolute",
    right: "5px",
    margin: "0px 10px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  pluginIconBox: {
    "&:hover": { cursor: "pointer", backgroundColor: "#fff" },
  },
  pluginIcon: {
    color: "#000",
    marginRight: "5px",
  },
  testBotButton: {
    padding: "4px 5px",
    width: "130px",
    color: "#000",
    marginLeft: "10px",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
    textTransform: "none",
    border: "1.5px solid #000",
    fontSize: "16px",
    fontWeight: 500,
    borderRadius: "7px",
    backgroundColor: "#fff",
    transition: "0.3s ease",
    // height: "50px",
    marginRight: "7px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
      border: "1.5px solid #000",
    },
  },
  saveButton: {
    marginLeft: "10px",
    fontFamily: "Inter, Roboto, sans-serif",
    textTransform: "none",
    fontSize: "17px",
    marginRight: "12px",
  },
  successMessage: {
    position: "relative",
    bottom: "-86px",
    left: "-16px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "16px",
    paddingLeft: "10px",
    transition: "0.3s ease",
  },
};
