import { backendApiCall } from "../../../../../../helper";

export async function uploadDataToAwsBucket({ formData }) {
  const res = await backendApiCall({
    endpoint: "media/?path=upload",
    method: "POST",
    data: formData,
  });

  return res;
}
