export const startStyles = {
  customCard: {
    position: "absolute",
    minWidth: "480px",
    maxWidth: "480px",
    minHeight: "250px",
    maxHeight: "500px",
    overflowY: "auto",
    padding: "20px 0",
    borderRadius: "5px",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    top: "20px",
    right: "20px",
    pointerEvents: "all",
    backgroundColor: "#dcdcdc",
    userSelect: "none"
  },
  topCard: {
    padding: "15px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: "15px",

  },
  responseSection: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "40px",
    paddingRight: "30px",
    // height: "200px",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
  },
  labels: {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: '10px',
    color: "#22272d"
  },
};
