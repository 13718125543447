import { memo, useEffect, useRef, useState } from "react";
import {
  DataSheetGrid,
  checkboxColumn,
  textColumn,
  keyColumn,
  DynamicDataSheetGrid,
} from "react-datasheet-grid";
import * as XLSX from "xlsx";

// Import the style only once in your app!
import "react-datasheet-grid/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  convertDataToDatasheetColumn,
  getDefaultCustomerObject,
  getDropdownData,
  validateCreatedUpdatedProducts,
  validateProducts,
  //   columns as allCRMColumns,
  getColumns,
  validateCustomers,
  getCustomerKeysMapping,
} from "./utils";
import "../index.css";
import {
  ResponsiveStackRow,
  addFileAws3,
  formatDate,
  getAdminName,
  getAuthToken,
  getEmail,
  getId,
  getStoreId,
  getStoreName,
  getTenantId,
  getToken,
  getWebSocketUrl,
  getWebsite,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  isObjectEqual,
  isOurWebsite,
  primaryLabel,
  primaryLabelLarge,
  secondaryLabelSmall,
  validateNumber,
} from "../../../helper";
import { createSpreadSheetData } from "./helper";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { updateCustomersFromSheet } from "./helper/updateData";
import { LoadingButton } from "@mui/lab";
import {
  ErrorOutline,
  History,
  Sync,
  ViewWeekOutlined,
} from "@material-ui/icons";
import UpdateDialog from "../helper/UpdateDialog";
import { fetchTaxonomies } from "../../../redux/actions/productActions";
import ColumnsPopover, {
  defaultSelectedColumns,
} from "../helper/ColumnsPopover";
import ErrorsPopover from "../helper/ErrosPopover";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import { editDataSheet } from "../../../redux/actions/datasheetActions";
import { getIp, sidebarExtraWidth } from "../../../App";
import { arePropsEqual, removeFalsyValuesFromObject } from "../../../utils/js";
import { fetchCustomers } from "../../../redux/actions/crmActions";
import { setPersistData } from "../../../redux/actions/persistActions";
import {
  bulkActionChatUser,
  getChatsUsers,
  settingApi,
} from "../../chat_new/apiFunctions";
import { EDIT_CHATS } from "../../../redux/actions/chatActions";
import {
  ArrowBackIosNew,
  ArrowDownward,
  ArrowUpward,
} from "@mui/icons-material";
import ImportDialog from "../product/components/ImportDialog";
import ExportDialog from "../product/components/ExportDialog";
import axios from "axios";
import { UAParser } from "ua-parser-js";
import JweroSyncHistory from "../../products/components/JweroSyncHistory";
import { EDIT_SETTINGS } from "../../../redux/actions/settingActions";
import {
  originalChatsHeaders,
  validateSheetHeaders,
} from "../helper/validateSheetHeaders";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const ChatsDataSheet = () => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const updateDialogRef = useRef();
  const columnsPopoverRef = useRef();
  const errorsPopoverRef = useRef();
  const inputRef = useRef(null);

  const [loadingText, setLoadingText] = useState("");
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [backupRestored, setBackupRestored] = useState(false);

  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const taxonomies = useSelector((state) => state.product.taxonomies);
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const storePlan = useSelector((state) => state.user.store_plan);
  const storeUsed = useSelector((state) => state.user.store_used);

  const selectedChatsColumns = useSelector(
    (state) => state.persist.selectedChatsColumns
  );
  const [cellhasError, setCellHasError] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    // [`group_name_${getStoreId()}`]: true,
    // ...defaultSelectedColumns.crm,
    ...selectedChatsColumns,
  });
  const selectedEditCustomers = useSelector(
    (state) => state.datasheet.customers_edit
  );
  const products_backup = useSelector(
    (state) => state.datasheet.products_backup
  );
  const create_chats_sheet_backup = useSelector(
    (state) => state.datasheet.create_chats_sheet_backup
  );
  const edit_chats_sheet_backup = useSelector(
    (state) => state.datasheet.edit_chats_sheet_backup
  );
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const [defaultCustomerObject, setDefaultCustomerObject] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const location = useLocation();
  const datasheetRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [remainingProducts, setRemainingProducts] = useState(0);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [CRMCustomFields, setCRMCustomFields] = useState([]);
  const jweroSheetSync = useSelector(
    (state) => state?.settings?.jweroSheetSync
  );
  const [openImport, setOpenImport] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [showSync, setShowSync] = useState(false);
  const [exporting, setExporting] = useState(false);
  const occupationsArray = useSelector((state) => state.persist.occupations);
  const custom_fields = useSelector((state) => state?.settings?.custom_fields);
  const countries = useSelector((state) => state.persist.countries);
  const states = useSelector((state) => state.persist.states);
  let branches = useSelector((state) => state.settings.branch);
  let showErrorTimeout, backupTimeout, savingTimeout;
  const allCustomerGroup = useSelector((state) => state.customers.crmGrouping);
  const broadcast = useSelector((state) => state.chats?.chat_broadcasts);
  const tags = useSelector((state) => state.chats?.chat_tags);

  const productDefaults = useSelector(
    (state) => state.settings.productDefaults
  );
  const [saving, setSaving] = useState(false);
  const closeSync = () => {
    setShowSync(false);
  };
  useEffect(() => {
    if (backupRestored) return;
    if (
      isUpdate &&
      isArrayWithValues(edit_chats_sheet_backup) &&
      !isArrayWithValues(selectedEditCustomers)
    ) {
      setData(edit_chats_sheet_backup);
      setBackupRestored(true);
    } else if (isArrayWithValues(create_chats_sheet_backup)) {
      setData(create_chats_sheet_backup);
      setBackupRestored(true);
    }
  }, [
    location.pathname,
    create_chats_sheet_backup,
    edit_chats_sheet_backup,
    isUpdate,
  ]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDev()) return;
      event.preventDefault();
      event.returnValue = ""; // Chrome requires this
      setIsDataSaved(true);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // window.removeEventListener("popstate", handleBeforeUnload);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  useEffect(() => {
    if (!isObjWithValues(custom_fields?.crm)) return;
    setCRMCustomFields(Object.values(custom_fields?.crm));
  }, [custom_fields]);

  useEffect(() => {
    if (!taxonomies) dispatch(fetchTaxonomies());
    // if (!reduxOrnateProducts) dispatch(fetchOrnateProducts());
    if (isObjWithValues(selectedChatsColumns))
      setSelectedColumns(selectedChatsColumns);
  }, [isObjWithValues(selectedChatsColumns)]);

  useEffect(() => {
    dispatch(setPersistData({ selectedChatsColumns: selectedColumns }));
  }, [selectedColumns]);

  useEffect(() => {
    if (backupTimeout) clearTimeout(backupTimeout);
    backupTimeout = setTimeout(() => {
      let key = isUpdate
        ? "edit_chats_sheet_backup"
        : "create_chats_sheet_backup";
      dispatch(editDataSheet({ [key]: data }));
      setSaving(true);
      if (savingTimeout) clearTimeout(savingTimeout);
      savingTimeout = setTimeout(() => {
        setSaving(false);
      }, 1500);
    }, 5000);
  }, [data]);
  // useEffect(() => {
  //   if (backupTimeout) clearTimeout(backupTimeout);
  //   backupTimeout = setTimeout(() => {
  //     dispatch(editDataSheet({ products_backup: data }));
  //   }, 5000);
  // }, [data]);

  useEffect(() => {
    let { add_products, catalogs } = storePlan || {};
    let { products, catalogs: catalogs_created } = storeUsed || {};
    setRemainingProducts(
      validateNumber(add_products) - validateNumber(products)
    );
    // if (products && add_products) setAddLimitReached(products >= add_products);
    // if (catalogs && catalogs_created)
    //   setCatalogsLimitReached(catalogs_created >= catalogs);
  }, [storePlan, storeUsed]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // if (isDev()) return;
      event.preventDefault();

      event.returnValue = ""; // Chrome requires this
      let key = isUpdate
        ? "edit_chats_sheet_backup"
        : "create_chats_sheet_backup";
      dispatch(editDataSheet({ [key]: null }));
      setIsDataSaved(true);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // window.removeEventListener("popstate", handleBeforeUnload);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!isUpdate) {
      let defaultObj = getDefaultCustomerObject({});
      if (defaultObj) {
        setDefaultCustomerObject(defaultObj);
        if (remainingProducts && !isArrayWithValues(create_chats_sheet_backup))
          setData(new Array(50).fill(defaultObj));
      }
    }
  }, [productDefaults, remainingProducts]);

  useEffect(() => {
    let dropdowns = getDropdownData({
      professions: occupationsArray,
      countries: countries,
      branches,
      allCustomerGroup,
      broadcast: Object.values(broadcast || {})
        ?.filter((obj) => obj?.platform === "whatsapp")
        ?.map((obj) => {
          return {
            label: obj?.title,
            value: obj?.title,
            // value: isUpdate ? obj?.title : obj?.id,
          };
        }),
      tags: Object.values(tags || {})?.map((obj) => {
        return {
          label: obj?.title,
          value: obj?.title,
          // value: isUpdate ? obj?.title : obj?.id,
        };
      }),

      // broadcast tags,
    });
    let columnsData =
      // selectedColumns;
      convertDataToDatasheetColumn({
        dropdowns,
        selectedColumns: selectedColumns,
        custom_fields,
        // getColumns(),
        // disableSKU: isUpdate,
      });
    if (isUpdate)
      columnsData = columnsData?.map((obj) =>
        obj?.id == "whatsapp" || obj?.id == "dialcode_whatsapp"
          ? { ...obj, disabled: true }
          : obj
      );

    setColumns(
      columnsData
      // getColumns()
      // convertDataToDatasheetColumn({
      //   dropdowns,
      //   // selectedColumns: getColumns(),
      //   // disableSKU: isUpdate,
      // })
    );
  }, [
    productSettings,
    masterPricing,
    taxonomies,
    branches,
    allCustomerGroup,
    selectedColumns,
    CRMCustomFields,
  ]);
  useEffect(() => {
    // setIsUpdate(location.pathname?.includes("chats/sheet"));

    if (
      // isArrayWithValues(selectedEditCustomers) &&
      location.pathname?.includes("chats/sheet")
    ) {
      // const getChats = async () => {
      //   let res = await getChatsUsers({
      //     params: { noLimit: true, platform: "whatsapp" },
      //   });
      //   if (res?.success) {
      //     // let setupData = (chats)=>{
      //     // }
      //     let keys = Object.keys(getCustomerKeysMapping() || {});
      //     let setupData = res?.data?.map((obj) => {
      //       let requiredObj = {
      //         id: obj?.id,
      //       };
      //       Object.entries({ ...obj, ...(obj?.meta_data || {}) })?.forEach(
      //         ([key, value]) => {
      //           if (keys?.includes(key)) requiredObj[key] = value;
      //         }
      //       );
      //       if (isArrayWithValues(requiredObj.broadcast))
      //         requiredObj.broadcast = requiredObj.broadcast
      //           ?.map((str) => {
      //             return broadcast?.[str]?.title || false;
      //           })
      //           ?.filter(Boolean);
      //       // delete requiredObj.broadcast;
      //       return requiredObj;
      //     });
      //     console.log(setupData);
      //     setData(setupData || []);
      //   }
      // };
      // getChats();
      // setData(
      //   createSpreadSheetData({
      //     customers: selectedEditCustomers,
      //     states,
      //     allCustomerGroup,
      //   })
      // );
    }
  }, [location, broadcast]);

  const onCloseImport = () => {
    setOpenImport(false);
  };
  const onCloseExport = () => {
    setOpenExport(false);
  };
  s;
  const onUpdate = async () => {
    let filteredData = data?.filter(
      (i) =>
        !isObjectEqual(i, defaultCustomerObject) &&
        isObjWithValues(removeFalsyValuesFromObject(i))
    );
    // if (isUpdate) {
    //   console.log(data);
    //   return;
    // }
    if (!isArrayWithValues(filteredData)) {
      return dispatch(
        setGlobalToast({
          show: true,
          message: `No details added`,
          severity: "error",
        })
      );
    }

    let invalidCustomers = validateCustomers({
      columns,
      customers: filteredData,
      states,
      defaultCustomerObject,
    });

    setErrors(invalidCustomers);

    if (isArrayWithValues(invalidCustomers)) {
      return dispatch(
        setGlobalToast({
          show: true,
          message: `Please resolve issues before ${
            isUpdate ? "updating" : "creating"
          } customers`,
          severity: "error",
        })
      );
    } else {
      let validateUsers = [];
      // isUpdate
      //   ? filteredData
      //       ?.filter((obj) => isObjWithValues(obj) || obj?.id)
      //       ?.map((obj) => {
      //         let chatObject = { id: obj?.id };
      //         let { broadcast: labelArray, ...restData } = obj;
      //         chatObject = { ...chatObject, ...restData };
      //         if (isArrayWithValues(labelArray)) {
      //           let broadcastArray = Object.values(broadcast || {});
      //           chatObject = {
      //             ...chatObject,
      //             meta_data: {
      //               broadcast: labelArray?.map(
      //                 (label) =>
      //                   broadcastArray?.find((o) => o?.title === label)?.id
      //               ),
      //             },
      //           };
      //         }
      //         return chatObject;
      //       })
      //   : filteredData
      //       ?.filter((obj) => isObjWithValues(obj))
      //       ?.map((obj) => {
      //         obj.platform_id = `${obj?.dialcode_whatsapp || ""}${
      //           obj?.whatsapp || ""
      //         }`;
      //         obj.platform = "whatsapp";
      //         let chatObject = { id: obj?.id };
      //         let { broadcast: labelArray, ...restData } = obj;
      //         chatObject = { ...chatObject, ...restData };
      //         if (isArrayWithValues(labelArray)) {
      //           let broadcastArray = Object.values(broadcast || {});
      //           chatObject = {
      //             ...chatObject,
      //             meta_data: {
      //               broadcast: labelArray?.map(
      //                 (label) =>
      //                   broadcastArray?.find((o) => o?.title === label)?.id
      //               ),
      //             },
      //           };
      //         }
      //         return chatObject;
      //       });
      if (true)
        validateUsers = filteredData
          ?.filter((obj) => isObjWithValues(obj))
          ?.map((obj) => {
            obj.platform_id = `${obj?.dialcode_whatsapp || ""}${
              obj?.whatsapp || ""
            }`;
            obj.platform = "whatsapp";
            let chatObject = { id: obj?.id };
            let { broadcast: labelArray, ...restData } = obj;
            chatObject = { ...chatObject, ...restData };
            if (isArrayWithValues(labelArray)) {
              let broadcastArray = Object.values(broadcast || {});
              chatObject = {
                ...chatObject,
                meta_data: {
                  broadcast: labelArray?.map(
                    (label) =>
                      broadcastArray?.find((o) => o?.title === label)?.id
                  ),
                },
              };
            }
            return chatObject;
          });
      let finalData = [];
      let tagsDropdown = Object.values(tags || {});
      validateUsers?.forEach((obj) => {
        let { broadcast, tags: tagsArray, ...restData } = obj;

        if (isArrayWithValues(broadcast))
          restData.meta_data = {
            broadcast,
          };
        if (isArrayWithValues(tagsArray)) {
          restData.meta_data = {
            ...(restData.meta_data || {}),
            tags: tagsArray?.map(
              (label) => tagsDropdown?.find((o) => o?.title === label)?.id
            ),
          };
        }
        if (isArrayWithValues(CRMCustomFields)) {
          CRMCustomFields.forEach((custom) => {
            if (obj?.[custom?.value])
              restData.meta_data = {
                ...(restData.meta_data || {}),
                [custom?.value]: obj?.[custom?.value],
              };
          });
        }
        finalData.push(restData);
      });
      // return;
      // return;
      let responseArray = [];
      setLoading(true);
      // if (false) {
      if (false) {
        setGlobalToast({
          show: true,
          message: `${isUpdate ? "Updating" : "Creating"} chats...`,
          severity: "error",
        });
        let batchLength = Math.ceil(finalData?.length / 100) || 1;
        let index = 0;

        let actionKey = isUpdate ? "update" : "create";
        for await (let i of Array.from(new Array(batchLength).fill(1))) {
          let batchArray = finalData?.slice(index * 100, index * 100 + 100);

          // batchArray = batchArray?.map((obj) => {
          //   let { broadcast, ...restData } = obj;
          //   if (isArrayWithValues(broadcast))
          //     restData.meta_data = {
          //       broadcast,
          //     };

          //   return restData;
          // });
          index++;

          let response = await bulkActionChatUser({
            data: {
              [actionKey]: batchArray,
            },
          });

          if (response?.success) {
            responseArray = [
              ...responseArray,
              ...(isArrayWithValues(response?.data?.[actionKey])
                ? response?.data?.[actionKey]
                : []),
            ];
          }
          /*
           update broadcast settings
            */
        }
      } else {
        let jsonData = finalData?.filter((o) => o?.platform_id);
        setLoading(true);
        let time = formatDate(new Date(), "DD/MM/YYYY HH:mm")?.replaceAll(
          "/",
          "-"
        );
        const jsonString = JSON.stringify(jsonData);
        const blob = new Blob([jsonString], {
          type: "application/json",
          name: `CHATS-SYNC-${time}.json`,
        });
        blob.name = `CHATS-SYNC-${time}.json`;

        let fileRes = await addFileAws3({
          params: {
            path: "jwero_sheets",
          },
          image: blob,
        });
        try {
          let { data: res } = await axios({
            url: `${getWebSocketUrl()}/import/json/chats`,
            headers: {
              Authorization: `Basic ${getToken()}`,
            },
            method: "POST",
            data: {
              json: jsonData,
              website: getWebsite(),
              // should_map_image: should_map,
              ip: await getIp(),
              ua: new UAParser().getUA(),
              isAdmin: isAdmin,
              by: getId(),
              newAuthToken: getAuthToken(),
              email: getEmail(),
              userName: getAdminName(),
              file_path: fileRes?.file_path,
            },
          });
          if (res?.success) {
            dispatch(
              setGlobalToast({
                show: true,
                message: "Sync started Successfully!",
                severity: "success",
              })
            );
            dispatch({
              type: EDIT_SETTINGS,

              payload: {
                jweroSheetSync: {
                  ...(jweroSheetSync || {}),
                  ...(res?.data || {}),
                },
              },
            });
            setData([]);
            dispatch(
              editDataSheet({
                edit_chats_sheet_backup: null,
                create_chats_sheet_backup: null,
              })
            );

            navigate("/chats#open");
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
      // let broadcastData = {};
      // Object.entries(broadcast || {})?.forEach(([key, value]) => {
      //   let newCustomersIds = (isDev() ? finalData : responseArray)
      //     ?.filter((obj) => obj?.meta_data?.broadcast?.includes(key))
      //     ?.map((obj) => obj?.id);

      //   value.contact_ids = [...(value.contact_ids || []), ...newCustomersIds];
      //   broadcastData[key] = value;
      // });

      // let res = await settingApi({
      //   data: broadcastData,
      //   method: "PUT",
      //   endpoint: "chat_broadcasts",
      // });

      // if (res?.success) {
      //   dispatch({
      //     type: EDIT_CHATS,
      //     payload: res?.data,
      //   });
      // }
      // // tags
      // let tagsData = {};
      // Object.entries(tags || {})?.forEach(([key, value]) => {
      //   let newCustomersIds = (isDev() ? finalData : responseArray)
      //     ?.filter((obj) => obj?.meta_data?.tags?.includes(key))
      //     ?.map((obj) => obj?.id);

      //   value.contact_ids = [...(value.contact_ids || []), ...newCustomersIds];
      //   tagsData[key] = value;
      // });
      // let tagsRes = await settingApi({
      //   data: tagsData,
      //   method: "PUT",
      //   endpoint: "chat_tags",
      // });

      setLoading(false);
      if (isArrayWithValues(responseArray)) {
        dispatch(
          setGlobalToast({
            show: true,
            message: `${
              responseArray?.filter((obj) => obj?.id)?.length || ""
            } Chats ${isUpdate ? "Updated" : "Created"} SuccessFully! `,
            severity: "success",
          })
        );

        if (
          responseArray?.filter((obj) => obj?.id)?.length ===
          responseArray?.length
        )
          navigate("/chats");
      }
      navigate("/chats");
    }
  };
  const navigateError = (obj) => {
    datasheetRef.current?.setActiveCell({
      col: obj.column,
      row: obj.row,
    });
    setCellHasError(true);
    errorsPopoverRef.current?.close();
    if (obj.message)
      dispatch(
        setGlobalToast({
          show: true,
          message: obj.message,
          severity: "info",
        })
      );
    if (showErrorTimeout) clearTimeout(showErrorTimeout);
    showErrorTimeout = setTimeout(() => {
      setCellHasError(false);
    }, 2000);
  };
  const onExport = async () => {
    try {
      setExporting(true);
      let { data: res } = await axios({
        url: `${getWebSocketUrl()}/sheets/chats`,
        method: "POST",
        headers: {
          Authorization: `Basic ${getToken()}`,
        },
        data: {
          store_id: getId(),
          website: getWebsite(),
          newAuthToken: getAuthToken(),
          tenantId: getTenantId(),
          email: getEmail(),
          userName: getAdminName(),
          storeName: getStoreName(),
          ip: await getIp(),
          ua: new UAParser().getUA(),
          isAdmin: isAdmin,
          by: getId(),
        },
      });
      if (res?.success) {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Sheet Exporting started, please check your email",
            severity: "success",
          })
        );
        if (isObjWithValues(res?.data))
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              jweroSheetSync: {
                ...(jweroSheetSync || {}),
                ...(res?.data || {}),
              },
            },
          });
        navigate("/chats#open");
      }

      // dispatch(
      //   setGlobalToast({
      //     show: true,
      //     message: "Sheet Exporting started, please check your email",
      //     severity: "success",
      //   })
      // );
    } catch (error) {
      console.log(error);
      dispatch(
        setGlobalToast({
          show: true,
          message: "Sheet Exporting Failed",
          severity: "error",
        })
      );
    } finally {
      setExporting(false);
    }
  };
  const onDownloadTemplate = ({ data, fileName, fileType }) => {
    let header = {};
    columns?.forEach((obj) => {
      header[obj?.label] = "";
    });
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  const downloadCsv = () => {
    let chats_keys_mapping = getCustomerKeysMapping(getStoreId());
    let arr = Object.keys(chats_keys_mapping)?.map((key) => ({
      label: chats_keys_mapping?.[key]?.column_name,
      value: key,
    }));
    if (isArrayWithValues(CRMCustomFields)) {
      arr = [
        ...arr,
        ...CRMCustomFields?.map((o) => ({
          label: o?.title,
          value: o?.value,
        })),
      ];
    }
    onDownloadTemplate({
      data: [
        arr?.map((o) => o?.label)?.join(","),
        arr?.map((o) => "")?.join(","),
      ]?.join("\n"),
      fileName: "Jwero Chats Sheet.csv",
      fileType: "text/csv",
    });
  };
  const onChangeFile = (event) => {
    // const file = event.target.files[0];
    const file = event.target.files[0];
    if (file) {
      setOpenImport(false);
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[firstSheetName];
          const csvArray = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          const firstRow = csvArray[0];
          if (
            !validateSheetHeaders({
              original: originalChatsHeaders,
              sheetHeaders: firstRow,
            })
          ) {
            return window.alert("This is not valid Sheet !");
          }
          dispatch(
            setGlobalToast({
              show: true,
              message: "Importing Data...",
              // severity: "success",
            })
          );
          const excelData = XLSX.utils.sheet_to_json(
            workbook.Sheets[firstSheetName]
          );
          let customerData = [];
          excelData?.forEach((obj) => {
            let customer = { ...obj };
            let customer_keys_mapping = getCustomerKeysMapping(getStoreId());
            let properCustomer = {};
            Object.entries(customer)?.forEach(([key, value]) => {
              let _key = Object.keys(customer_keys_mapping)?.find(
                (k) => customer_keys_mapping[k]?.column_name === key
              );
              if (_key === "dialcode_mobile" || _key === "dialcode_whatsapp")
                properCustomer[_key] = value?.toString();
              if (typeof value === "number")
                properCustomer[_key] = value?.toString();
              else if (_key === "broadcast" && typeof value === "string") {
                properCustomer[_key] = value?.split(",");
              } else if (_key === "tags" && typeof value === "string") {
                properCustomer[_key] = value?.split(",");
              } else properCustomer[_key] = value;
            });
            customerData.push(properCustomer);
          });
          setData(customerData);
          dispatch(
            setGlobalToast({
              show: true,
              message: "Imported file successfully",
              severity: "success",
            })
          );
          // setJsonData(excelData);
        };
        reader.readAsArrayBuffer(file);
      } catch (error) {
        console.log(error);
      }
    }
    inputRef.current.value = "";
  };
  return (
    <Box
      sx={{
        // maxWidth: !isIpad ? `calc(100vw - ${sidebarExtraWidth}px)` : "100%",
        pr: 3,
        pl: 1,
      }}
    >
      <input
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        accept="text/csv"
        onChange={onChangeFile}
      />
      <JweroSyncHistory
        type={"chats"}
        showSync={showSync}
        closeSync={closeSync}
      />
      {/* <Prompt
        when={!isDataSaved}
        message="Are you sure you want to leave? Your changes will be lost."
      /> */}
      {/* <Box sx={{ mx: 2, justifyContent: "space-between",  }}>
      </Box> */}
      {/* <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        mx={2}
        pb={3}
      >
        <Typography sx={{ ...primaryLabelLarge }}>
          {!isUpdate ? "Create" : "Edit"} customers
        </Typography>
        <Stack direction="row" alignItems={"center"} spacing={2}>
          <Collapse in={isArrayWithValues(errors)}>
            <IconButton
              variant="text"
              onClick={(e) => errorsPopoverRef?.current?.open(e)}
              // startIcon={<ViewWeekOutlined />}
              color="error"
              // size="small"
            >
              <Badge badgeContent={4} color="error" variant="dot">
                <ErrorOutline />
              </Badge>
            </IconButton>
          </Collapse>

          <Button
            variant="text"
            onClick={(e) => columnsPopoverRef?.current?.open(e)}
            startIcon={<ViewWeekOutlined />}
          >
            Columns
          </Button>
          <LoadingButton
            sx={{}}
            variant="contained"
            onClick={onUpdate}
            // disabled={loading}
            loading={loading}
            // startIcon={
            //   loading ? (
            //     <CircularProgress style={{ height: "15px", width: "15px" }} />
            //   ) : (
            //     <Sync style={{ height: "17px", width: "17px" }} />
            //   )
            // }
          >
            {isUpdate ? "Update" : "Create"} customers
          </LoadingButton>
        </Stack>
      </Stack> */}
      <ResponsiveStackRow
        firstElement={
          <Stack direction={"row"} alignItems={"center"} gap={3}>
            <IconButton
              onClick={() => {
                navigate("/chats");
              }}
            >
              <ArrowBackIosNew />
            </IconButton>
            <Box>
              <Typography sx={{ ...primaryLabelLarge, fontSize: "20px" }}>
                {/* {!isUpdate ? "Create" : "Edit"} products */}
                Jwero Sheets (Beta)
              </Typography>
              <Typography
                sx={{
                  ...secondaryLabelSmall,
                  mt: 0.2,
                  // textAlign: "right",
                  color: "primary",
                }}
              >
                {/* {!isUpdate ? "Create" : "Edit"} Contacts */}
              </Typography>
            </Box>
            <Button
              sx={{ color: saving ? "primary.main" : "text.secondary" }}
              endIcon={<Sync />}
              disabled={saving}
            >
              {saving ? "Auto Saving" : "Auto Saved"}
            </Button>
          </Stack>
        }
        secondElement={
          <Stack direction="row" alignItems={"center"} spacing={2}>
            {/* <Fade in={isArrayWithValues(errors)}> */}
            <Tooltip title={isArrayWithValues(errors) ? "" : "No errors found"}>
              <IconButton
                variant="text"
                onClick={(e) =>
                  isArrayWithValues(errors)
                    ? errorsPopoverRef?.current?.open(e)
                    : null
                }
                // startIcon={<ViewWeekOutlined />}
                // color={isArrayWithValues(errors) ? "secondary" : "error"}
                sx={{
                  color: !isArrayWithValues(errors)
                    ? "text.secondary"
                    : "error.main",
                }}
                // disabled={!isArrayWithValues(errors)}
                // size="small"
              >
                <Badge
                  badgeContent={isArrayWithValues(errors) ? 1 : 0}
                  color="error"
                  variant="dot"
                >
                  <ErrorOutline />
                </Badge>
              </IconButton>
            </Tooltip>
            {/* </Fade> */}
            {(isOurWebsite() || isDev() || true) && (
              <Tooltip title="Sync History">
                <IconButton onClick={() => setShowSync(true)}>
                  <History />
                </IconButton>
              </Tooltip>
            )}
            <Button
              variant="text"
              onClick={(e) => columnsPopoverRef?.current?.open(e)}
              startIcon={<ViewWeekOutlined />}
              sx={{
                backgroundColor: "#ffffff",
                padding: "6px 18px",
              }}
            >
              Columns
            </Button>
            {(isOurWebsite() || isDev() || true) && (
              <>
                <Button
                  onClick={() => {
                    // inputRef?.current?.click();
                    setOpenImport(true);
                  }}
                  sx={{
                    backgroundColor: "#ffffff",
                    padding: "6px 18px",
                  }}
                  startIcon={<ArrowDownward />}
                >
                  {" "}
                  Import
                </Button>
                <LoadingButton
                  loading={exporting}
                  onClick={() => onExport()}
                  startIcon={<ArrowUpward />}
                  sx={{
                    backgroundColor: "#ffffff",
                    padding: "6px 18px",
                  }}
                >
                  {" "}
                  Export
                </LoadingButton>
              </>
            )}
            <LoadingButton
              sx={{}}
              variant="contained"
              onClick={onUpdate}
              // disabled={loading}
              loading={loading}
              // startIcon={
              //   loading ? (
              //     <CircularProgress style={{ height: "15px", width: "15px" }} />
              //   ) : (
              //     <Sync style={{ height: "17px", width: "17px" }} />
              //   )
              // }
            >
              {/* {loading ? `${Math.round(progress)}%` : "Update products"} */}
              {isOurWebsite() || isDev() || true
                ? "Sync"
                : isUpdate
                ? "Update"
                : "Create"}{" "}
              Contacts
            </LoadingButton>
          </Stack>
        }
        containerStyle={{ padding: "0px" }}
      />
      <DynamicDataSheetGrid
        // <DataSheetGrid
        ref={datasheetRef}
        // rowHeight={(props) => {
        //   return { top: 40 }; // custom function
        //   // return validateNumber(rowData?.length || 0) + 40;
        // }}
        // rowHeight={60}
        height={windowSize.innerHeight - 200}
        value={data}
        onChange={(data, operations) => {
          setData(data);
        }}
        columns={columns}
        style={{
          "--dsg-selection-border-color": cellhasError
            ? "tomato"
            : "rgb(69,128,230)",
        }}
        // disableContextMenu
        addRowsComponent={(props) => (
          <AddRowsComponent remainingRows={remainingProducts} {...props} />
        )}
      />
      <ErrorsPopover
        navigateError={navigateError}
        ref={errorsPopoverRef}
        errors={errors}
      />
      <UpdateDialog
        loadingText={loadingText}
        ref={updateDialogRef}
        progress={progress}
        title="Syncing customers"
      />
      <ColumnsPopover
        selectedColumns={selectedColumns}
        setSelectedColumns={(payload) =>
          setSelectedColumns((state) => ({ ...state, ...payload }))
        }
        ref={columnsPopoverRef}
        allColumns={getColumns(getStoreId())}
        type="chats"
      />
      <ImportDialog
        open={openImport}
        handleClose={onCloseImport}
        onAddFile={() => {
          inputRef?.current?.click();
        }}
        heading={"Import Chats by CSV"}
        downloadCsv={downloadCsv}
      />
      <ExportDialog open={openExport} handleClose={onCloseExport} />
    </Box>
  );
};

export default ChatsDataSheet;

const AddRowsComponent = memo(
  ({ addRows, remaingRows }) => {
    let [temp, setTemp] = useState("");
    return (
      <Box sx={{ bgcolor: "#fafafa", p: 2 }}>
        <Stack spacing={3} direction={"row"} alignItems={"center"}>
          <TextField
            size="small"
            value={temp}
            onChange={(e) => setTemp(e.target.value)}
            sx={{ maxWidth: 100 }}
            type="number"
          />
          <Button
            variant="outlined"
            onClick={() => (!temp ? null : addRows(validateNumber(temp)))}
            disabled={temp > remaingRows || validateNumber(temp) > 20000}
          >
            Add Rows
          </Button>
        </Stack>
        {validateNumber(temp) > 20000 && (
          <ErrorLabelExtraSmall sx={{ mt: 1 }}>
            The limit to add/update CRM is upto 20,000
          </ErrorLabelExtraSmall>
        )}
      </Box>
    );
  },
  (a, b) => arePropsEqual(a, b)
);
