import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const chatUserVariables = [
  { label: "{{First Name}}", value: "first_name" },
  { label: "{{Last Name}}", value: "last_name" },
  { label: "{{Fullname}}", value: "fullname" },
  { label: "{{City}}", value: "city" },
  { label: "{{Address}}", value: "address" },
  //   { label: "{{Title}}", value: "title" },
  //   { label: "{{Order id}}", value: "order_id" },
  //   { label: "{{Payment method}}", value: "payment_method" },
  //   { label: "{{due date}}", value: "due_date" },
  //   { label: "{{Expiry}}", value: "expiry" },
  //   { label: "{{amount}}", value: "amount" },
  //   { label: "{{pieces}}", value: "pieces" },
  //   { label: "{{Brand name}}", value: "brand_name" },
  //   { label: "{{Brand number}}", value: "brand_number" },
  //   { label: "{{Order note}}", value: "order_note" },
  //   { label: "{{Brand url}}", value: "brand_url" },
  //   { label: "{{Reference no}}", value: "reference_no" },
  //   { label: "{{Link}}", value: "link" },
  // { label: "Address", value: "address" },
  //   { label: "Whatsapp", value: "whatsapp" },
  //   { label: "Mobile", value: "mobile" },
  //   { label: "Profession", value: "profession" },
  //   { label: "Language", value: "language" },
  //   { label: "Ethinicity", value: "ethinicity" },
  //   { label: "Religion", value: "religion" },
];
export default function ChatUserVariableSelect({
  buttonComponent: ButtonComponent,
  onSelect,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}
      {ButtonComponent && <ButtonComponent open={handleClick} />}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {chatUserVariables.map((i) => {
          return (
            <Typography
              onClick={() => {
                onSelect && onSelect(i.label);
                handleClose();
              }}
              sx={{ p: 2, cursor: "pointer" }}
            >
              {i.label}
            </Typography>
          );
        })}
      </Popover>
    </div>
  );
}
