import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import Wave from "react-wavify";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Avatar,
  Button,
  Paper,
  Stack,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  CardContent,
  Card,
  TextField,
  Alert,
} from "@mui/material";

// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import SignUp from "../../components/auth/SignUp";
import { spacing, useTheme } from "@mui/system";
import { WaveBackground } from "../../Svgs";
import "./index.css";
import { StarRounded } from "@mui/icons-material";
import useScreenOrientation from "../components/ScreenOrientation";
import {
  Base64,
  backendApiCall,
  getAxiosError,
  getRandomInt,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../hooks/useAuth";
import { fetchCustomer } from "../crm/View";
import { backendEndpoints } from "../chat_new/apiFunctions";

// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 64px;
//   height: 64px;
//   margin-bottom: 32px;
// `;

const Spacer = styled.div(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 102px;
  height: 102px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
  padding: 20px;
`;

function MemberRegister() {
  const [transform, setTransform] = useState(false);
  const [testimony, setTestimony] = useState(allTestimonies[getRandomInt(2)]);
  const [showSignUp, setShowSignUp] = useState(false);
  const theme = useTheme();
  const primary = theme.palette.primary && theme.palette.primary.main;
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const location = useLocation();

  const screenOrientation = useScreenOrientation();

  return (
    <React.Fragment>
      {/* <Brand /> */}
      {!isMobile && (
        <Navbar transform={transform} setTransform={setTransform} />
      )}
      <Stack
        direction={"row"}
        sx={{
          height: "500px",
          alignItems: "center",
          mt: 15,
          display: { xs: "none", lg: "block" },
        }}
      >
        <Stack
          direction="row"
          sx={{
            backgroundColor: "primary.main",
            width: "100vw",
            height: "450px",
          }}
          justifyContent="center"
          alignItems={"center"}
        >
          <Stack
            direction="row"
            width={"80vw"}
            minHeight="450px"
            sx={{ position: "relative" }}
          >
            <Box
              sx={{
                width: "50vw",
                position: "absolute",
                left: "0px",
                top: "-25px",
              }}
            >
              {/* <MemberRegisterWrapper
                setShowSignUp={setShowSignUp}
                showSignUp={showSignUp}
              /> */}
            </Box>
            <Box sx={{ width: "50vw" }}>
              <SignInWrapper />{" "}
            </Box>
            <Box sx={{ width: "50vw", margin: "auto" }}>
              <Testimonial
                src={testimony?.src}
                name={testimony?.name}
                testimony={testimony?.testimony}
              />
              {/* {allTestimonies[getRandomInt(2)]}
               */}
            </Box>
          </Stack>
        </Stack>
      </Stack>
      {isMobile && (
        <>
          <Stack
            // direction={"row"}
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              mt: 4,
              textAlign: "center",
            }}
            justifyContent="center"
          >
            <Typography sx={{ color: "#fff" }} variant="h3">
              Tanika Tech
            </Typography>
            <Typography sx={{ color: "#fff", mt: 5 }} variant="body2">
              Please login to continue
            </Typography>
          </Stack>
          <Box sx={{ height: "35vh" }}>
            <svg
              viewBox="0 0 300 100"
              preserveAspectRatio="none"
              style={{
                height: "100vh",
                width: "100vw",
                // marginLeft: "auto",
                // width: "500px",
                // backgroundColor: "blue",
              }}
            >
              <path
                d="M-0.56,34.05 C155.76,-17.26 302.48,68.59 503.38,14.31 L500.00,0.00 L0.00,0.00 Z"
                stroke="none"
                fill={primary}
                // fill={!transform && isIpad ? "#fff" : primary}
              ></path>
            </svg>
          </Box>
        </>
      )}
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "center",
          mt: 5,
        }}
      >
        <Box
          sx={
            isMobile
              ? {
                  px: 2,
                  // position: "absolute",
                  // top: "60%",
                  // transform: "translateY(-50%)",
                }
              : {
                  // position: "absolute",
                  // top: "10%",
                  // left: "50%",
                  // transform: "translateX(-50%)",
                  display: { xs: "none", sm: "block", lg: "none" },
                  // justifyContent: "center",
                }
          }
        >
          <SignInWrapper
            hideTitle={isMobile ? true : false}
            setShowSignUp={setShowSignUp}
            showSignUp={showSignUp}
          />
        </Box>
      </Stack>
      {!isMobile && (
        <Box
          sx={{
            mt: screenOrientation === "portrait-primary" ? 18 : isLg ? 18 : 0,
          }}
        >
          <Footer />
        </Box>
      )}
    </React.Fragment>
  );
}

export default MemberRegister;

const SignInWrapper = ({ hideTitle, setShowSignUp, showSignUp }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [email, setEmail] = useState("");
  const location = useLocation();
  const [error, setError] = useState("");
  const [info, setInfo] = useState({
    email: "",
    website: "",
    token: "",
    auth_token: "",
  });
  const [form, setForm] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();

  const editForm = (payload) => {
    if (error) setError("");
    setForm((state) => ({ ...state, ...payload }));
  };

  useEffect(() => {
    console.log(location);

    let params_string = location.search;
    // let url = new URL(params_string);
    let e = new URLSearchParams(params_string).get("e");
    let obj = {};
    try {
      obj = JSON.parse(Base64.atob(e));
      if (obj.token)
        obj.token =
          obj.token.substring(0, 3) + obj.token.substring(9, obj.token.length);
      if (obj.auth_token)
        obj.auth_token =
          obj.auth_token.substring(0, 3) +
          obj.auth_token.substring(9, obj.auth_token.length);
    } catch (error) {}
    setInfo(obj);
  }, []);

  const onSubmit = async () => {
    if (!form.new_password) return setError("Please Enter the Password");
    if (!form.confirm_password) return setError("Please Confirm the Password");
    if (form.confirm_password !== form.new_password)
      return setError("Passwords does not Match");
    setLoading(true);
    let user = await createOldAccount();
    console.log(user);
    await createAccount();

    if (isObjWithValues(user)) {
      try {
        let res = await signIn(info.email, form.new_password);
        console.log(res);
        setLoading(false);
        if (res?.unauthenticated) {
          setError(res.message);
        } else {
          if (res === "UNREGISTERED") {
            setTimeout(() => {
              navigate("/auth/register");
            }, 600);
          } else if (res === "UNMIGRATED") {
            setTimeout(() => {
              navigate("/migration");
            }, 600);
          } else {
            return navigate("/dashboard");
          }
        }
      } catch (error) {
        error = getAxiosError(error);
        console.log(error);
        setError("Internal Server Error");
      }
    }
    setLoading(false);
  };

  const createAccount = async () => {
    let usersRes = await backendApiCall({
      endpoint: `${backendEndpoints.users}`,
      params: { search: info.email },
      // method: "POST",
      // data: payload,
    });
    let newUserDetails;
    if (usersRes?.data?.[0] && usersRes?.data?.[0]?.email === info?.email) {
      newUserDetails = usersRes?.data?.[0];
    }
    if (!newUserDetails) {
      let phone = await backendApiCall({
        endpoint: `${backendEndpoints.users}`,
        params: { search: info.phone },
        // method: "POST",
        // data: payload,
      });
      if (phone?.data?.[0] && phone?.data?.[0]?.phone === info?.phone) {
        newUserDetails = phone?.data?.[0];
      }
    }
    if (!newUserDetails) {
      let createRes = await backendApiCall({
        endpoint: `${backendEndpoints.sub_user}`,
        // params: { search: info.email },
        method: "POST",
        data: {
          first_name: info?.first_name,
          last_name: info?.last_name,
          email: info?.email,
          dialcode_phone: info?.dialcode,
          phone: info?.phone,
        },
        // mainUser: true,
        token: info.auth_token,
      });
      if (createRes?.success) {
        newUserDetails = createRes?.data;
      }
    }
    await backendApiCall({
      endpoint: `${backendEndpoints.users}/${newUserDetails?.id}`,
      // params: { search: info.email },
      method: "PUT",
      data: {
        password: form.new_password,
      },
      mainUser: true,
    });
  };

  const createOldAccount = async () => {
    try {
      let username = info.email
        ?.split("@")[0]
        ?.replace(/[^a-zA-Z ]/g, "")
        ?.toLowerCase();
      if (username?.length < 4) username = `${username}2023`;
      let { data } = await axios({
        url: `${info.website}/wp-json/wp/v2/users`,
        headers: { Authorization: `Basic ${info.token}` },
        params: {
          username,
          email: info.email,
          password: form.new_password,
          roles: ["shop_manager", "customer"],
        },
        method: "POST",
      });
      console.log(data);
      return data;
    } catch (error) {
      let e = getAxiosError(error);
      console.log(e);
      if (
        isArrayWithValues(e?.additional_errors) ||
        e?.code === "existing_user_email" ||
        e?.code === "existing_user_login"
      ) {
        if (
          (isArrayWithValues(e.additional_errors) &&
            e.additional_errors.find(
              (i) =>
                i.message === "Sorry, that username already exists!" ||
                i.code === "user_email"
            )) ||
          e?.code === "existing_user_email" ||
          e?.code === "existing_user_login"
        ) {
          let customer = await fetchCustomerByEmail(info.email);
          if (isObjWithValues(customer)) {
            let updatedCustomer = await updateCustomer({
              id: customer?.data,
              password: form.new_password,
              roles: ["shop_manager", "customer"],
            });
            if (updatedCustomer && !updateCustomer.code) {
              await updateStatus();
              return { user: true };
            } else {
              setError("Internal Error! Please try again later");
              return false;
            }
          } else {
            setError("Internal Error! Please try again later");
            return false;
          }
        }
        if (
          e.additional_errors[0]?.message.includes(
            "This email address is already"
          )
        ) {
          setError("User already exist!");
          return { user: true };
        }
      }
      console.log(getAxiosError(error));
      return false;
    }
  };

  const updateStatus = async () => {
    try {
      if (info.website && info.token) {
        let { data } = await axios({
          url: `${info.website}/wp-json/store/v1/settings/team_members`,
          headers: { Authorization: `Basic ${info.token}` },
        });
        if (isArrayWithValues(data.data)) {
          let array = data.data.map((i) =>
            i.email === info.email ? { ...i, status: "complete" } : i
          );

          let { data: updateRes } = await axios({
            url: `${info.website}/wp-json/store/v1/settings/team_members`,
            headers: { Authorization: `Basic ${info.token}` },
            method: "POST",
            data: { type: "team_members", data: array },
          });
        }
        return true;
      }
    } catch (error) {
      console.log(getAxiosError(error), "Couldn't udpate");
    }
  };

  const updateCustomer = async (customer) => {
    try {
      if (info.website && info.token) {
        let { data } = await axios({
          url: `${info.website}/wp-json/wp/v2/users/${customer.id}`,
          method: "POST",
          headers: { Authorization: `Basic ${info.token}` },
          params: {
            ...customer,
          },
        });
        console.log(data);
        return data;
      }
      // if (info.website && info.token) {
      //   let { data } = await axios({
      //     url: `${info.website}/wp-json/wc/v3/customers/${customer.id}`,
      //     data: customer,
      //     method: "PUT",
      //     headers: { Authorization: `Basic ${info.token}` },
      //   });
      //   console.log(data);
      //   return data;
      // }
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  };

  const fetchCustomerByEmail = async (email) => {
    try {
      if (info.website && info.token) {
        let { data } = await axios({
          url: `${info.website}/wp-json/store/v1/customer/create`,
          data: { value: email },
          headers: { Authorization: `Basic ${info.token}` },
          method: "POST",
        });
        if (data.success) return data;
        // console.log(data);
        // if (isArrayWithValues(data)) return data;
      }
      // console.log(info);
      // if (info.website && info.token) {
      //   let { data } = await axios({
      //     url: `${info.website}/wp-json/wc/v3/customers`,
      //     params: { search: email },
      //     headers: { Authorization: `Basic ${info.token}` },
      //   });
      //   console.log(data);
      //   if (isArrayWithValues(data)) return data;
      // }
    } catch (error) {
      return false;
    }
  };

  // useEffect(() => {
  //   console.log(location);
  //   let email = new URLSearchParams(location.search).get("email");
  //   setEmail(email);
  // }, []);

  return (
    <Card
      sx={{
        // py: 8,
        backgroundColor: "#fff",
        // minWidth: "400px",
        height: "500px",
        justifyContent: "center",
        width: isMobile ? "100%" : "400px",
        boxShadow: isMobile ? "0px" : "0px 0px 4px -1px rgba(0,0,0,0.75)",
      }}
    >
      <CardContent
        sx={{
          alignItems: "center",
          // backgroundColor: "#fff000",
          margin: "auto",
          padding: isMobile ? "0px" : "16px",
        }}
      >
        <>
          <Typography
            // component="h1"
            variant="h2"
            // align="center"
            gutterBottom
          >
            Register
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="h6" fontSize="14px" fontWeight={"normal"}>
              Welcome <strong>{info.email}</strong>
            </Typography>
          </Stack>
        </>
        <Box sx={{ height: "25px" }} />
        {error ? (
          <Alert mt={2} mb={3} severity="warning">
            {error}
          </Alert>
        ) : null}
        <Typography variant="h5" mt={2} ml={2}>
          Enter New Password
        </Typography>
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          placeholder="Enter New Password"
          type="password"
          value={form.new_password}
          onChange={(e) => editForm({ new_password: e.target.value })}
        />
        <Typography variant="h5" mt={5} ml={2}>
          Confirm New Password
        </Typography>
        <TextField
          fullWidth
          sx={{ mt: 2 }}
          placeholder="Confirm New Password"
          type="password"
          value={form.confirm_password}
          onChange={(e) => editForm({ confirm_password: e.target.value })}
        />
        <LoadingButton
          loading={loading}
          variant="contained"
          sx={{ mt: 5 }}
          onClick={onSubmit}
          fullWidth
        >
          Create Account
        </LoadingButton>
        {/* <BigAvatar
                  alt="Lucy"
                  src="/static/img/brands/tanika_icon.png"
                /> */}
        {/* {showSignUp ? <SignUp /> : <SignInComponent />} */}
      </CardContent>
    </Card>
  );
};

const Testimonial = ({ src, name, testimony }) => {
  return (
    <Box
      sx={{
        // position: "absolute",
        // top: "50%",
        // transform: "translateY(-50%)",
        paddingRight: "50px",
      }}
    >
      <Stack direction={"row"} alignItems="center">
        <img
          src={src}
          style={{ width: "60px", height: "60px", borderRadius: "50%" }}
        />
        <Box sx={{ ml: 3 }}>
          <Typography variant="h6" sx={{ color: "#fff" }}>
            {name}
          </Typography>
          <Stack direction={"row"} justifyContent="flex-start" mt={0}>
            <StarRounded sx={{ color: "#fff" }} />
            <StarRounded sx={{ color: "#fff" }} />
            <StarRounded sx={{ color: "#fff" }} />
            <StarRounded sx={{ color: "#fff" }} />
            <StarRounded sx={{ color: "#fff" }} />
          </Stack>
        </Box>
      </Stack>
      <Typography
        variant="body2"
        sx={{ color: "#fff", fontSize: "17px", mt: 3 }}
      >
        {testimony}
      </Typography>
    </Box>
  );
};

const Footer = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 0,
    },
  };
  return (
    <Box sx={{ marginTop: "auto" }}>
      <Grid container spacing={3} mt={3}>
        <Grid item xs />
        <Grid xs={12} sm={10} md={10}>
          <Carousel
            responsive={responsive}
            infinite
            autoPlay
            autoPlaySpeed={3000}
            arrows={false}
          >
            {/* <BrandIcon src="https://jwero.ai/wp-content/uploads/2021/04/talla1.png" /> */}
            <BrandIcon src="https://jwero.ai/wp-content/uploads/2021/04/s1.png" />
            <BrandIcon src="https://jwero.ai/wp-content/uploads/2022/01/kc.png" />
            <BrandIcon src="https://jwero.ai/wp-content/uploads/2021/08/ratnalayajewellers.png" />
            <BrandIcon src="https://jwero.ai/wp-content/uploads/2021/04/images-8-e1646711743728.png" />
            <BrandIcon src="https://jwero.ai/wp-content/uploads/2021/04/atul1.png" />
            <BrandIcon src="https://jwero.ai/wp-content/uploads/2021/04/akshaya1.png" />
            <BrandIcon src="https://jwero.ai/wp-content/uploads/2021/04/13-1.png" />
          </Carousel>
        </Grid>
        <Grid item xs />
      </Grid>
      <Box
        sx={{
          alignItems: "center",
          // backgroundColor: "red",
          height: "100%",
          // flex: 1,
          display: { xs: "none", sm: "block" },
          flexGrow: 1,
        }}
      >
        <Stack sx={{ textAlign: "center", mt: 3 }}>
          <Typography color="text.secondary">
            Join over 150 jewellers using Tanika Tech to grow{" "}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

const Navbar = ({ transform, setTransform }) => {
  return (
    <Stack
      direction="row"
      py={4}
      px={4}
      sx={{ zIndex: 100 }}
      justifyContent={"space-between"}
    >
      <img src="/static/img/brands/jwero_logo.png" style={{ height: "40px" }} />
      {/* <BigAvatar alt="Lucy" src="/static/img/brands/tanika_icon.png" /> */}
      <Stack direction="row">
        <Button
          onClick={() =>
            window.open("https://tanika.tech/privacy-policy/", "_blank")
          }
        >
          Privacy Poicy
        </Button>
        <Button
          onClick={() =>
            window.open("https://tanika.tech/terms-of-service/", "_blank")
          }
        >
          Terms & Conditions
        </Button>
        {/* <Button variant="contained">Sign Up</Button> */}
      </Stack>
    </Stack>
  );
};

const BrandIcon = ({ src }) => {
  return (
    <Box sx={{ textAlign: "center" }}>
      <img src={src} style={{ height: "50px", width: "100px" }} />
    </Box>
  );
};
const CustomerImage = ({ src, transform }) => {
  return (
    <Box sx={{ transform }}>
      <img
        src={src}
        style={{
          height: "90px",
          width: "90px",
          borderRadius: "50%",
          border: "2px solid white",
          padding: "5px",
        }}
      />
    </Box>
  );
};

CustomerImage.defaultProps = {
  transform: "translateX(0px)",
};

const allTestimonies = [
  {
    name: "Sanjay Bohra - J Mittalal Thanga Maligai",
    src: "https://retailer.tanika.tech/wp-content/themes/retailer-dashboard/img/testimonials/jmt.png",
    testimony:
      "I must say the dedication of the team is impeccable. They have a very good understanding of the jewellery trade in our country and also know how to bridge the gap between orthodox and modern business practices.",
  },
  {
    name: "Anish Sha | Akshaya Gold",
    src: "https://retailer.tanika.tech/wp-content/themes/retailer-dashboard/img/testimonials/akshayagold.png",
    testimony:
      "Promises kept on time: whether design, development, maintenance, they are always upto the mark. Complete support available round the clock to hear and help you. Our decision to tie-up was a right click at the right time. Thanks Team Tanika! Keep it UP!",
  },
];
