import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { isDev, isOurWebsite, TabPanel } from "../../helper";
import Shipping from "./Shipping";
import ShippingRates from "./ShippingRates";

const ShippingAndDiscountTabs = () => {
  const [value, setValue] = useState("shipping_zones");
  return (
    <Box>
      <Tabs
        value={value}
        onChange={(e, newValue) => setValue(newValue)}
        sx={{
          //  borderBottom: 1,
          //  borderColor: "divider"
          mt: 2,
          mb: 3,
        }}
      >
        <Tab value={"shipping_zones"} label="Shipping Zones" />
        {(isDev() || isOurWebsite()) && (
          <Tab value={"shipping_rates"} label="Shipping Rates" />
        )}
      </Tabs>
      <TabPanel value={value} index={"shipping_zones"}>
        <Shipping />
      </TabPanel>
      <TabPanel value={value} index={"shipping_rates"}>
        <ShippingRates />
      </TabPanel>
    </Box>
  );
};

export default ShippingAndDiscountTabs;
