import { initialNodes } from "../utils/initialNodes";
import { initialEdges } from "../utils/initialEdges";
import * as actions from "../../../../../redux/actions/flowsActions";
import { backendApiCall } from "../../../../../helper";
import {
  isArrayWithValues,
  isObjWithValues,
  validateNumber,
} from "jwero-javascript-utils";
import { DEFAULT_CREATE_TRIGGER_TITLE } from "..";

export const fetchElements = async ({ dispatch, id, triggersList, type }) => {
  const nodes = initialNodes;
  const edges = initialEdges;

  try {
    if (validateNumber(id)) {
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { fetchingFlow: true },
      });
      let flow = {};

      if (isArrayWithValues(triggersList)) {
        flow = triggersList?.find((i) => i?.id + "" === id + "");
      }
      if (!isObjWithValues(flow)) {
        let res = await backendApiCall({ endpoint: `flow/${id}` });
        flow = res?.data || {};
      }

      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: {
          loading: false,
          flow_details: flow,
          flowChannels: flow?.channels,
        },
      });
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { loaderProgress: 100 },
      });
      dispatch({
        type: actions.SET_NODES,
        payload: flow?.nodes,
      });
      dispatch({
        type: actions.SET_EDGES,
        payload: flow?.edges,
      });
      dispatch({
        type: actions.SET_TITLE,
        payload: flow?.title,
      });
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: flow?.theme_mode,
      });
    } else {
      const campaignNodes = [nodes.startNode];
      const triggerNodes = [nodes.startNode];
      const webchatNodes = [
        nodes.startNode,
        nodes.defaultFallbackNode,
        nodes.BotNodeFallback,
        nodes.BotNode,
      ];

      const initialNodes =
        type === "campaign"
          ? campaignNodes
          : type === "trigger"
          ? triggerNodes
          : webchatNodes;
      const initialEdges =
        type === "campaign" || type === "trigger"
          ? []
          : [edges.startEdge, edges.startBot, edges.defaultBot];

      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { nodes: initialNodes },
      });
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { edges: initialEdges },
      });
      dispatch({
        type: actions.SET_TITLE,
        payload: DEFAULT_CREATE_TRIGGER_TITLE,
      });
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { loading: false },
      });
    }
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { fetchingFlow: false },
    });
  }
};
