import React, { forwardRef, useImperativeHandle, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const CustomConfirmationDialog = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [loading, _setLoading] = useState(false);
  const {
    onClose,
    title,
    content,
    children,
    submitButtonProps,
    closeButtonProps,
    subtitle,
    onSubmit,
    closeOnBlur = true,
    ...otherProps
  } = props;

  useImperativeHandle(ref, () => {
    return {
      setLoading: _setLoading,
      _open: setOpen,
      open: function () {
        setOpen(true);
      },
      close: () => {
        setOpen(false);
      },
    };
  });

  const onClosePress = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={closeOnBlur ? onClosePress : null}
        fullWidth
        maxWidth="sm"
        {...otherProps}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{subtitle}</DialogContentText>
          {/* {children} */}
        </DialogContent>
        <DialogActions>
          {!closeButtonProps?.hide && (
            <Button onClick={onClosePress} color="primary">
              Close
            </Button>
          )}
          {!submitButtonProps?.hide && (
            <LoadingButton
              variant="contained"
              color="primary"
              loading={loading || submitButtonProps?.loading}
              onClick={onSubmit}
              {...submitButtonProps}
            >
              {submitButtonProps?.label || "Submit"}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default CustomConfirmationDialog;
