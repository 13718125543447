import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtonTopTabs from "../../../components/ButtonTopTabs";
import Broadcast from "./broadcast";
import Campaign from "./campaign";
import Trigger from "./triggers";
import { isDev } from "../../../../helper";
import { useSelector } from "react-redux";

const allTabs = isDev()
  ? [
      { label: "Broadcast", value: "broadcast" },
      { label: "Campaigns", value: "campaigns" },
      { label: "Triggers", value: "triggers" },
    ]
  : [
      { label: "Broadcast", value: "broadcast" },
      // { label: "Campaigns", value: "campaigns" },
      { label: "Triggers", value: "triggers" },
    ];

function Marketing() {
  let [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(allTabs?.[0]?.value);
  const storePlan = useSelector((state) => state.user.store_plan);

  useEffect(() => {
    let tabs = allTabs;
    if (storePlan?.plan !== "growth")
      tabs = tabs?.filter((i) => i?.value !== "triggers");
    setTabs(tabs);
  }, [allTabs]);

  return (
    <Box>
      <ButtonTopTabs
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {selectedTab === "broadcast" && (
        <Box sx={{ p: 2 }}>
          <Broadcast />
        </Box>
      )}
      {selectedTab === "campaigns" && (
        <Box sx={{ p: 2 }}>
          <Campaign />
        </Box>
      )}
      {selectedTab === "triggers" && (
        <Box sx={{ p: 2 }}>
          <Trigger />
        </Box>
      )}
    </Box>
  );
}
export default Marketing;
