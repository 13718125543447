export const calculateAxis = ({ axis, currType, prevType }) => {
  const bigWidthNodes = [
    "start",
    "assign",
    "product-catalog",
    "bot",
    "fallback",
    "question",
    "success",
    "failure",
    "go-to-step",
    "filter",
    "metal-rates",
    "subscribe",
    "unsubscribe",
    "create-task",
    "delay",
  ];
  const smallWidthNodes = ["user", "user-attachment"];

  const bigHeightNodes = [
    "whatsapp-template",
    "sms",
    "email",
    "push-notification",
  ];

  if (bigWidthNodes.includes(currType)) {
    if (bigWidthNodes.includes(prevType)) {
      return { x: axis.x + 210, y: axis.y };
    } else if (smallWidthNodes.includes(prevType)) {
      return { x: axis.x + 145, y: axis.y };
    } else if (bigHeightNodes.includes(prevType)) {
      return { x: axis.x + 210, y: axis.y - 15 };
    }
  } else if (smallWidthNodes.includes(currType)) {
    if (smallWidthNodes.includes(prevType)) {
      return { x: axis.x + 150, y: axis.y };
    } else if (bigWidthNodes.includes(prevType)) {
      return { x: axis.x + 180, y: axis.y };
    } else if (bigHeightNodes.includes(prevType)) {
      return { x: axis.x + 150, y: axis.y - 15 };
    }
  } else {
    if (bigWidthNodes.includes(prevType)) {
      return { x: axis.x + 200, y: axis.y - 15 };
    } else {
      return { x: axis.x + 105, y: axis.y - 15 };
    }
  }
};
