export const productCatalogStyles = {
    customCard: {
      position: "absolute",
      minWidth: "480px",
      maxWidth: "500px",
      margin: "0 auto",
      borderRadius: "5px",
      fontFamily: "Source Sans Pro, Roboto, sans-serif",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      top: "20px",
      right: "20px",
      pointerEvents: "all",
    },
    topCard: {
      display: "flex",
      flexDirection: "column",
      height: "150px",
      backgroundColor: "#ffffff",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    innerCard: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: "35px",
    },
    userHeader: {
      display: "flex",
      alignItems: "center",
      fontSize: "20px",
      fontFamily: "Source Sans Pro, Roboto, sans-serif",
      fontWeight: 600,
      color: "#59687b",
      justifyContent: "center",
   },
    headerTitle: {
      fontSize: "15px",
      fontWeight: "530",
      marginLeft: "7px",
    },
    headerIconsMainDiv: {
      display: "flex",
      alignContents: "flex-end",
      position: "relative",
      left: "15px",
      bottom: "5px",
    },
    headerIconsDiv: {
      backgroundColor: "#dcdcdc",
      marginRight: "10px",
      borderRadius: "4px",
      height: "30px",
      width: "30px",
    },
    headerIcons: {
      color: "#475e7a",
      fontSize: "20px",
      fontWeight: "600",
    },
    titleTextField: {
      marginTop: "15px",
      marginBottom: "15px",
      borderRadius: "8px",
      fontSize: "50px",
    },
    responseSection: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#dcdcdc",
      paddingLeft: "40px",
      paddingRight: "30px",
      minHeight: "200px",
      maxHeight: "500px",
      overflowY: "auto",
      paddingBottom: "30px",
      fontFamily: "Source Sans Pro, Roboto, sans-serif",
    },
    responseDiv: {
      display: "flex",
      flexDirection: "column",
      marginTop: "30px",
    },
    iconsButton: {
      color: 'black',
      fontWeight: 600,
      position: "relative",
      top: "1px",
    },
    icons: {
      fontSize: "20px",
    },
    labels: {
      fontSize: "16px", 
      fontWeight: 600,
      marginBottom: '10px',
      color: "#22272d"
    },
    optionsInput: {
      backgroundColor: '#fff',
      border: 'none',
      borderRadius: '5px',
      marginBottom: '10px',
      transition: "0.3s ease",
      outline: "none",
      fontFamily: "Source Sans Pro, Roboto, sans-serif",
      fontSize: "15px",
      '&:hover fieldset': {
        borderColor: '#3b82f680!important',
        cursor: "grab!important"
      },
      "&:hover": {
        cursor: "grab!important"
      }
    }
  };
  