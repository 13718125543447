import * as actions from "../../../../../../redux/actions/flowsActions";

export const onAddSms = async ({
  dispatch,
  axis,
  nodeId,
  currentAddHandle,
}) => {
  const newNodeId = Date.now().toString();

  const newNode = {
    id: newNodeId,
    type: "sms",
    data: { label: "Sms" },
    position: axis,
    fieldData: [],
    isUser: true,
  };

  const edge = {
    id: Date.now(),
    source: nodeId,
    target: newNodeId,
    style: {
      strokeWidth: 1.5,
      stroke: "#566e8a",
    },
  };

  await dispatch({
    type: actions.CURRENT_NODE_ID,
    payload: { nodeId: newNodeId },
  });

  const nodePayload = {
    newNode,
    nodeId,
  };

  if (currentAddHandle) {
    (edge.sourceHandle = currentAddHandle),
      (nodePayload.handleType = currentAddHandle);
  }

  dispatch({ type: actions.ADD_NODE, payload: nodePayload });
  dispatch({ type: actions.ADD_EDGE, payload: edge });
  dispatch({
    type: actions.EDIT_ELEMENT_IN_REDUX,
    payload: { openSmsSidebar: true },
  });
};
