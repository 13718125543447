// import { MenuItem, Select } from "@mui/material";
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import Select from "react-select";
import {
  containsText,
  decodeHtml,
  getNetWeight,
  goldPurities,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  setupJweroLegacyProduct,
  validateNumber,
} from "../../../../helper";
import { gemstoneShapes } from "../../../settings/MasterSettings";
import {
  diamondCut,
  diamondShapes,
  diamondTypes,
  gemstoneQualities,
  gemstoneTypes,
} from "../../../settings/MasterSettings";
import StaticDropdown from "../utils/static_dropdowns";
import { arePropsEqual } from "../../../../utils/js";
import {
  Autocomplete,
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  Popover,
  TextField,
  Typography,
  Popper,
  ListItem,
  Card,
  CardContent,
  ListItemButton,
  InputAdornment,
  Tooltip,
  ListItemText,
  Checkbox,
  ListSubheader,
} from "@mui/material";
import CustomChip from "../../../components/CustomChip";
import { ArrowDropDown, Search } from "@material-ui/icons";
import { gemstoneColors } from "../../../pricing/Gemstone";

const chipTypes = {
  stock_status: {
    instock: "completed",
    onbackorder: "pending",
    outofstock: "failed",
  },
  status: {
    publish: "completed",
    draft: "failed",
  },
};

const SelectComponent = (props) => {
  let {
    focus,
    active,
    stopEditing,
    column_key,
    column_name,
    rowData: allRowData,
    setRowData,
    onValueChange,
    selectedValue,
    is_multiple_dropdown,
    options = [],
    duplicate_dropdown,
    search,
  } = props || {};
  // let rowData = allRowData?.[column_key];
  let rowData = allRowData;
  const textinputRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => options?.filter((option) => containsText(option.value, searchText)),
    [searchText]
  );

  const handleClick = (event) => {
    if (!anchorEl && !isArrayWithValues(options)) return;
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const optionClick = (value) => {
    setRowData(rowData ? `${rowData || ""}, ${value}` : value);
    handleClose();
    textinputRef?.current?.focus();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  // const choices = StaticDropdown?.[column_key]?.map((i) => ({ ...i })) || [];

  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  const handleDropdownOpen = () => {
    setDropdownOpen(true);
  };

  const selectedOption =
    is_multiple_dropdown && false
      ? rowData?.map((i) => {
          options?.find((j) => j.value === i || j.slug === i)?.label;
        }) || []
      : options?.find(
          (option) => option.value === rowData || option.label === rowData
        )?.label;

  useEffect(() => {
    if (!focus || !active) {
      textinputRef?.current?.blur();
    }
  }, [focus, active]);

  const onDropdownChange = (value) => {
    // setRowData({ ...allRowData, [column_key]: value });
    setRowData(value);
  };

  if (!is_multiple_dropdown)
    return (
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        // multiple
        fullWidth
        value={selectedOption || ""}
        onChange={(e) => {
          onDropdownChange(e.target.value);
        }}
        disabled={props.disabled}
        MenuProps={{ sx: { maxHeight: "300px" } }}
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": {
            border: "none !important",
          },
          pointerEvents: active ? undefined : "none",
        }}
        input={<OutlinedInput id="select-multiple-chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            <CustomChip
              key={selected}
              label={selected}
              size="small"
              type={chipTypes?.[column_key]?.[rowData] || "default"}
            />
            {/* {selected.map((value) => (
          ))} */}
          </Box>
        )}
      >
        {search && (
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {displayedOptions?.map((obj) => (
          <MenuItem key={obj} value={obj.value}>
            <Checkbox
              checked={Boolean(
                rowData?.includes(obj?.label) || rowData?.includes(obj?.value)
              )}
            />
            <ListItemText primary={decodeHtml(obj.label || "")} />
          </MenuItem>
        ))}
      </Select>
    );
  else if (duplicate_dropdown)
    return (
      <>
        <TextField
          // ref={textinputRef}
          inputRef={textinputRef}
          onClick={props.disabled ? null : handleClick}
          disabled={props.disabled}
          value={rowData}
          onChange={(e) => onDropdownChange(e.target.value)}
          fullWidth
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": {
              border: "none !important",
            },
            pointerEvents: active ? undefined : "none",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDown />
              </InputAdornment>
            ),
          }}
        />
        <Popper id={id} open={open} anchorEl={anchorEl}>
          <Card
            sx={{
              maxHeight: "300px",
              overflow: "scroll",
              p: 0,
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
              // "0rem 0.25rem 0.125rem -0.125rem #1f212414, 0rem 0.75rem 0.125rem -0.125rem #1f212426",
            }}
          >
            <CardContent sx={{ px: 0 }}>
              {options?.map((obj) => (
                <ListItemButton
                  key={obj.value}
                  value={obj.value}
                  onClick={() => optionClick(obj.value)}
                  sx={{ cursor: "pointer", px: 4 }}
                  // style={getStyles(obj, personName, theme)}
                >
                  {obj?.label}
                </ListItemButton>
              ))}
            </CardContent>
          </Card>
        </Popper>
      </>
    );
  // return (
  //   <Autocomplete
  //     id="free-solo-demo"
  //     freeSolo
  //     // value={isArrayWithValues(rowData) ? rowData : []}
  //     options={top100Films.map((option) => option.title)}
  //     renderInput={(params) => <TextField {...params} />}
  //     selectOnFocus
  //     ref={textinputRef}
  //     // inputValue={rowData || ""}
  //     // onInputChange={(e) => {
  //     //   console.log(e.target.value);
  //     // }}
  //     onClose={handleDropdownClose}
  //     onOpen={handleDropdownOpen}
  //     onClick={handleDropdownOpen}
  //     autoSelect
  //     multiple
  //     fullWidth
  //     renderTags={(values) => {
  //       console.log(values, "props");
  //       return values?.map((i) => <div>{i},</div>);
  //     }}
  //     limitTags={2}
  //     sx={{
  //       boxShadow: "none",
  //       ".MuiOutlinedInput-notchedOutline": {
  //         border: "none !important",
  //       },
  //       pointerEvents: active ? undefined : "none",
  //     }}
  //   />
  // );

  return (
    <Tooltip
      title={isArrayWithValues(rowData) && !dropdownOpen ? rowData?.join() : ""}
    >
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        fullWidth
        disabled={props.disabled}
        open={dropdownOpen}
        onOpen={handleDropdownOpen}
        onClose={handleDropdownClose}
        value={isArrayWithValues(rowData) ? rowData : []}
        // value={selectedOption}
        onChange={(e) => {
          let values = e.target.value || [];
          onDropdownChange([...values]);
        }}
        MenuProps={{ sx: { maxHeight: "300px" } }}
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": {
            border: "none !important",
          },
          pointerEvents: active ? undefined : "none",
        }}
        renderValue={(selected) => selected.join(", ")}
        // input={<OutlinedInput id="select-multiple-chip" />}
        // renderValue={(selected) => (
        //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        //     <CustomChip
        //       key={selected}
        //       label={selected}
        //       size="small"
        //       type={chipTypes?.[column_key]?.[rowData] || "default"}
        //     />
        //     {/* {selected.map((value) => (
        //   ))} */}
        //   </Box>
        // )}
      >
        {search && (
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {displayedOptions?.map((obj) => (
          <MenuItem key={obj} value={obj.value}>
            <Checkbox
              checked={Boolean(
                rowData?.includes(obj?.label) || rowData?.includes(obj?.value)
              )}
            />
            <ListItemText primary={decodeHtml(obj.label || "")} />
          </MenuItem>
        ))}
        {/* {options?.map((obj, index) => (
          <MenuItem
            key={obj.value + index}
            value={obj.value}
            
          >
            {obj?.label}
          </MenuItem>
        ))} */}
      </Select>
    </Tooltip>
  );

  return (
    <Autocomplete
      fullWidth
      multiple={is_multiple_dropdown}
      ref={ref}
      options={options}
      value={selectedValue}
      isSearchable
      isMulti={is_multiple_dropdown}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{ borderColor: "transparent" }}
          label=""
          variant="standard"
          inputProps={{
            style: {
              height: "38px",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                border: "none",
              },
            },
          }}
        />
      )}
      onChange={(a, e) => {
        // console.log(e, "<<<<");
      }}
      styles={{
        container: (provided) => ({
          ...provided,
          flex: 1, // full width
          alignSelf: "stretch", // full height
        }),
        control: (provided) => ({
          ...provided,
          height: "100%",
          border: "none",
          boxShadow: "none",
          background: "none",
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          opacity: 0,
        }),
      }}
    />
  );
};

const discountLabelObj = {
  on_total_sale: "Total",
  on_sale_making: "Making",
  on_sale_metal: "Metal",
  on_sale_diamond: "Diamond",
  on_sale_colorstone: "Gemstone",
};

const createSpreadsheetData = ({
  products = [],
  productSettings = {},
  reduxGoogleSpreadsheet = {},
  options = {},
  taxonomies = {},
}) => {
  let { addHeaders = true } = options;
  let { types } = productSettings;
  let excludedColumns = [];
  if (isObjWithValues(reduxGoogleSpreadsheet)) {
    let { excluded_sheet_columns } = reduxGoogleSpreadsheet;
    if (isArrayWithValues(excluded_sheet_columns))
      excludedColumns = excluded_sheet_columns;
  }
  const shouldAddMetalHeaders = types?.find(
    (i) => i.value === "gold" || i.value === "silver" || i.value === "platinium"
  );

  const shouldAddGoldHeaders =
    types?.find((i) => i.value === "gold") &&
    !excludedColumns.includes("metal");
  const shouldAddSilverHeaders =
    types?.find((i) => i.value === "silver") &&
    !excludedColumns.includes("metal");
  const shouldAddPlatiniumHeaders =
    types?.find((i) => i.value === "platinium") &&
    !excludedColumns.includes("metal");
  const shouldAddDiamondHeaders =
    types?.find((i) => i.value === "diamond") &&
    !excludedColumns.includes("diamond");
  const shouldAddGemstoneHeaders =
    types?.find((i) => i.value === "gemstone") &&
    !excludedColumns.includes("gemstone");
  const shouldAddSeoHeaders = !excludedColumns.includes("seo");
  const shouldAddMeasurementsHeaders =
    !excludedColumns.includes("measurements");
  const shouldAddLabourHeaders = !excludedColumns.includes("labour");
  const shouldAddDiscountHeaders = !excludedColumns.includes("discounts");
  const shouldShowMatchingHeaders = !excludedColumns.includes("matching");
  const shouldShowImageHeaders = !excludedColumns.includes("images");
  const shouldShowVideoHeaders = !excludedColumns.includes("video");

  let metalHeaders = [
    { label: "Metal Type", value: "metal_types" },
    { label: "Metal Color", value: "metal_color" },
  ];
  let SEOHeaders = [
    { label: "SEO Title", value: "seo_title" },
    { label: "SEO Descriptoin", value: "seo_description" },
    { label: "SEO Keywords", value: "seo_keywords" },
  ];
  let platinumHeaders = [
    { label: "Platinum Purity", value: "platinium_purity" },
    { label: "Platinum Gross", value: "platinium_gross" },
    { label: "Platinum Net", value: "platinium_net" },
  ];
  let silverHeaders = [
    { label: "Silver Purity", value: "silver_purity" },
    { label: "Silver Gross", value: "silver_gross" },
    { label: "Silver Net", value: "silver_net" },
  ];
  let goldHeaders = [
    { label: "Gold Purity", value: "gold_kt" },
    { label: "Gold Gross", value: "gold_gross" },
    { label: "Gold Net", value: "gold_net" },
  ];
  let measurementsHeaders = [
    { label: "Width", value: "product_width" },
    { label: "Height", value: "product_height" },
    { label: "Thickness", value: "product_thickness" },
  ];
  let labourHeaders = [
    { label: "Labour Type", value: "labourType" },
    { label: "Labour From", value: "labour_from" },
    { label: "Wastage in (%)", value: "wastage_percent" },
    { label: "Customize Wastage From", value: "custom_wastage_from" },
    { label: "Per Gram", value: "per_gram" },
    // { label: "Labour Per gram", value: "labour" },
    { label: "Minimum Labour", value: "minimum_labour" },
  ];
  let discountsHeaders = [
    { label: "Discount On", value: "discount_on" },
    { label: "Discount Type", value: "discount_type" },
    { label: "Discount Value", value: "discount_value" },
  ];
  let matchingHeaders = [
    { label: "Upsell", value: "upsell_ids" },
    { label: "Cross Sell", value: "cross_sell_ids" },
  ];
  let imageHeaders = [{ label: "Images", value: "images" }];
  let videoHeaders = [{ label: "Video URL", value: "_woodmart_product_video" }];

  const diamondHeaders = [
    { label: "Diamond From", value: "diamond_from" },
    { label: "Diamond Type", value: "diamond_type" },
    { label: "Diamond Quality", value: "diamond_quality" },
    { label: "Diamond Shapes", value: "diamond_shape" },
    // { label: "Diamond Sieve", value: "diamond_sieve" },
    { label: "Diamond Cut", value: "diamond_cut" },
    { label: "Diamond Pieces", value: "diamond_pieces" },
    { label: "Diamond Weight", value: "diamond_weight" },
    { label: "Custom Diamond Rate", value: "diamond_rate" },
    // { label: "Diamond Certified", value: "diamond_certified" },
    { label: "Diamond Certificate", value: "" },
    { label: "Diamond Lab", value: "diamond_lab" },
  ];
  const gemstoneHeaders = [
    // { label: "Gemstone From", value: "colorstone_from" },
    { label: "Gemstone Quality", value: "colorstone_quality" },
    { label: "Gemstone Shape", value: "colorstone_shape" },
    { label: "Gemstone Type", value: "colorstone_type" },
    { label: "Gemstone Pieces", value: "colorstone_pieces" },
    { label: "Gemstone Size", value: "colorstone_size" },
    { label: "Gemstone Color", value: "colorstone_color" },
    { label: "Gemstone Weight", value: "colorstone_weight" },
    { label: "Custom Gemstone Rate", value: "colorstone_rate" },
    //
    { label: "No. of Lines", value: "no_of_lines" },
    { label: "Length", value: "length" },
    { label: "Closure Type", value: "closure_type" },
    { label: "Pearl Type", value: "pearl_type" },
    { label: "Pearl Setting Type", value: "pearl_setting_type" },
    { label: "Pearl Size", value: "pearl_size" },
    { label: "Pearl Pieces", value: "pearl_pieces" },
    { label: "Pearl Weight", value: "pearl_weight" },
  ];
  const AllHeaders = [
    { label: "Last Modified", value: "date_modified" },
    { label: "SKU", value: "sku" },
    { label: "Actions", value: "actions" },
    { label: "Product Error", value: "error" },
    { label: "Title", value: "name" },
    { label: "Status", value: "status" },
    { label: "Description", value: "description" },
    { label: "Slug", value: "slug" },
    { label: "Stock Status", value: "stock_status" },
    { label: "Quantity", value: "stock_quantity" },
    { label: "Collections", value: "collections" },
    { label: "Category", value: "categories" },
    { label: "Sub Category", value: "subcategory" },
    { label: "Settings/Size", value: "subcatsetting" },
    { label: "Featured", value: "featured" },
    { label: "HUID", value: "huid" },
    ...(shouldAddMetalHeaders ? metalHeaders : []),
    ...(shouldAddGoldHeaders ? goldHeaders : []),
    ...(shouldAddSilverHeaders ? silverHeaders : []),
    ...(shouldAddPlatiniumHeaders ? platinumHeaders : []),
    ...(shouldAddDiamondHeaders ? diamondHeaders : []),
    ...(shouldAddGemstoneHeaders ? gemstoneHeaders : []),
    ...(shouldAddLabourHeaders ? labourHeaders : []),
    // { label: "Labour Type", value: "labourType" },
    { label: "Extra Charge Type", value: "extra_charge_label" },
    { label: "Extra Charge Value", value: "extra_charge_value" },
    ...(shouldAddSeoHeaders ? SEOHeaders : []),
    ...(shouldAddMeasurementsHeaders ? measurementsHeaders : []),
    // { label: "Upsell", value: "cross_sell_ids" },
    // { label: "Cross Sell", value: "cross_sell_ids" },
    { label: "Tags", value: "tags" },
    { label: "Gender", value: "gender" },
    { label: "Ocassions", value: "ocassions" },
    // { label: "Custom tags", value: "custom_tags" },
    { label: "Min Shipping Days", value: "min_shipping_days" },
    { label: "Max Shipping Days", value: "max_shipping_days" },
    { label: "Manual Price", value: "manual_price" },
    { label: "Sale Price", value: "sale_price" },
    { label: "Automatic Price", value: "price" },
    { label: "Disable Customization", value: "disable_customization" },
    ...(shouldShowMatchingHeaders ? matchingHeaders : []),
    // ...(shouldAddDiscountHeaders ? discountsHeaders : []),
    ...(shouldShowImageHeaders ? imageHeaders : []),
    ...(shouldShowVideoHeaders ? videoHeaders : []),
  ];

  let length = products.length;
  let spreadsheetArray = [];
  let headersArray = [];

  for (let i in AllHeaders) headersArray.push(AllHeaders[i].label);
  if (!addHeaders) spreadsheetArray.push(headersArray);
  for (let i = 0; i < length; i++) {
    let product = products[i];

    let {
      name,
      sku,
      price,
      slug,
      description,
      collections,
      categories,
      subcategory,
      stock_status,
      status,
      stock_quantity,
      images,
      date_modified,
      date_created,
      meta_data,
      attributes,
      upsell_ids,
      cross_sell_ids,
      short_description,
      tags,
      featured,
      backorders,
      sold_individually,
      tag,
      sale_price,
    } = product;
    let productObj = {
      name,
      sku,
      slug,
      description: description || short_description,
      short_description: short_description || description,
      collections,
      // :
      //   typeof collections === "string" ? collections?.split(",") : collections,
      categories,
      subcategory,
      stock_status,
      status,
      stock_quantity,
      images,
      date_created,
      date_modified,
      featured: featured === "no" ? false : Boolean(featured),
      backorders: backorders === "no" ? false : Boolean(backorders),
      sold_individually:
        sold_individually === "no" ? false : Boolean(sold_individually),
      tag,
      upsell_ids: isArrayWithValues(upsell_ids)
        ? upsell_ids.join()
        : upsell_ids || "",
      cross_sell_ids: isArrayWithValues(cross_sell_ids)
        ? cross_sell_ids.join()
        : cross_sell_ids || "",
      // meta_data,
      actions: product.error || product?.reasons ? "Error" : "synced",
    };
    // console.log(
    //   typeof collections === "string" ? collections?.split(",") : collections
    // );
    if (sale_price) productObj.sale_price = sale_price;

    if (isArrayWithValues(tags))
      productObj.tag = tags?.map((i) => i.name || i.label);

    // if (isArrayWithValues(upsell_ids)) {
    //   productObj.upsell_ids = upsell_ids
    //     ?.map((id) => {
    //       let obj = products.find((i) => i.id == id);
    //       if (obj) return obj.sku;
    //     })
    //     ?.filter(Boolean)
    //     ?.join();
    // }
    // if (isArrayWithValues(cross_sell_ids)) {
    //   productObj.cross_sell_ids = cross_sell_ids
    //     ?.map((id) => {
    //       let obj = products.find((i) => i.id == id);
    //       if (obj) return obj.sku;
    //     })
    //     ?.filter(Boolean)
    //     ?.join();
    // }
    let unnecessaryKeys = [
      // "discount_sale_on",
      // "discount_type",
      // "discount_value",
      "extra_charges",
      "price_breakup",
      // "seo",
      "woodmart_sguide_select",
      "wd_page_css_files",
      "_wds_analysis",
      "_wds_readability",
    ];

    if (isArrayWithValues(attributes)) {
      let gender = attributes.find((i) => i.slug === "pa_gender");
      if (gender && isArrayWithValues(gender?.options))
        productObj.gender = gender?.options.map((i) => {
          return taxonomies?.gender?.find(
            (j) => j?.value === i || j?.label === i
          )?.label;
        });

      // productObj.gender = gender?.options.join();
    }
    if (isArrayWithValues(attributes)) {
      let ocassions = attributes.find((i) => i.slug === "pa_ocassions");
      if (ocassions && isArrayWithValues(ocassions?.options)) {
        productObj.ocassions = ocassions?.options.map((i) => {
          return taxonomies?.ocassions?.find(
            (j) => j?.value === i || j?.label === i
          )?.label;
        });
        // productObj.ocassions = ocassions?.options.join();
      }
    }

    if (isArrayWithValues(meta_data))
      for (let i in meta_data) {
        let obj = meta_data[i];
        productObj[obj.key] = obj.value || "";
      }

    productObj.disable_customization =
      productObj?.disable_customization === "no"
        ? false
        : Boolean(productObj?.disable_customization);

    if (!productObj?.minimum_making && productObj?.minimum_labour)
      productObj.minimum_making = productObj?.minimum_labour; // productObj = setupJweroLegacyProduct(productObj);

    if (isObjWithValues(productObj.product_shipping)) {
      productObj.min_shipping_days = productObj.product_shipping.min;
      productObj.max_shipping_days = productObj.product_shipping.max;
      delete productObj.product_shipping;
    }

    if (productSettings.net_weight === "auto") {
      if (productObj.gold_gross)
        productObj.gold_net = getNetWeight(
          validateNumber(productObj.gold_gross),
          productObj
        );
      if (productObj.silver_gross)
        productObj.silver_net = getNetWeight(
          validateNumber(productObj.silver_gross),
          productObj
        );
      if (productObj.platinium_gross)
        productObj.platinium_net = getNetWeight(
          validateNumber(productObj.platinium_gross),
          productObj
        );
    }

    // if (productObj.gold_kt) {
    //   let gold_kt = productObj.gold_kt;
    //   let purity = productObj.gold_kt
    //     .toLowerCase()
    //     .replace("kt", "")
    //     .replace("k", "")
    //     .replace("t", "");
    //   let purityObj = goldPurities.find((i) => i.value == purity);
    //   if (purityObj) gold_kt = purityObj.label + "KT";
    //   // meta_data.push({ key: "gold_kt", value: gold_kt });
    //   if (!gold_kt.toLowerCase().includes("kt")) gold_kt += "KT";
    //   productObj.gold_kt = gold_kt;
    // }
    if (isObjWithValues(productObj.extra_charges)) {
      let { extra_charges } = productObj;
      let labels = "";
      let values = "";
      let array = Object.values(extra_charges);
      array.map((i) => {
        let { extra_charge_label, extra_charge_value } = i;
        if (labels) labels += `,${extra_charge_label}`;
        else labels = extra_charge_label;
        if (values) values += `,${extra_charge_value}`;
        else values = extra_charge_value;
      });
      productObj.extra_charge_label = labels ? labels?.split(",") : [];
      productObj.extra_charge_value = values;
    }
    delete productObj.extra_charges;
    // if (isObjWithValues(productObj.custom_discounts)) {
    //   let discountOnArray = Object.keys(productObj.custom_discounts);
    //   let index = 0;
    //   for (let discountObj of Object.values(productObj.custom_discounts)) {
    //     let { type, value } = discountObj;
    //     if ((type, value)) {
    //       productObj.discount_on = productObj.discount_on
    //         ? `${productObj.discount_on},${
    //             discountLabelObj[discountOnArray[index]]
    //           }`
    //         : discountLabelObj[discountOnArray[index]];
    //       productObj.discount_type = productObj.discount_type
    //         ? `${productObj.discount_type},${type}`
    //         : type;
    //       productObj.discount_value = productObj.discount_value
    //         ? `${productObj.discount_value},${value}`
    //         : value;
    //     }
    //     index++;
    //   }
    // }
    delete productObj.custom_discounts;

    if (isObjWithValues(productObj.seo)) {
      let { seo_title, seo_description, seo_keywords } = productObj.seo;
      productObj.seo_title = seo_title;
      productObj.seo_description = seo_description;
      if (isArrayWithValues(seo_keywords))
        productObj.seo_keywords = seo_keywords.join();
      else if (typeof seo_keywords === "string")
        productObj.seo_keywords = seo_keywords;
    }
    delete productObj.seo;

    if (isArrayWithValues(productObj.subcatsetting))
      productObj.subcatsetting = productObj.subcatsetting.join(",");

    if (isObjWithValues(productObj.colorstone_details)) {
      let { colorstone_details } = productObj;
      let colorstones = Object.values(colorstone_details);
      for (let i in colorstones) {
        let obj = colorstones[i];
        let keys = Object.keys(obj);
        for (let j in keys) {
          let value = "";
          if (keys[j] === "colorstone_shape") {
            let valueObj = gemstoneShapes.find((i) => i.value === obj[keys[j]]);
            if (valueObj) value = valueObj.label;
          }
          if (keys[j] === "colorstone_color") {
            let valueObj = gemstoneColors.find((i) => i.value === obj[keys[j]]);
            if (valueObj) value = valueObj.label;
          }
          if (keys[j] === "colorstone_quality") {
            let valueObj = gemstoneQualities.find(
              (i) => i.value === obj[keys[j]]
            );
            if (valueObj) value = valueObj.label;
          }
          if (keys[j] === "colorstone_type") {
            let valueObj = gemstoneTypes?.find((i) => i.value === obj[keys[j]]);
            if (valueObj) value = valueObj.label;
          }
          value = value || obj[keys[j]];

          productObj[keys[j]] = productObj[keys[j]]
            ? `${productObj[keys[j]]}, ${value}`
            : value;
        }
      }
    }
    delete productObj.colorstone_details;
    if (productObj.diamond) {
      let { diamond } = productObj;

      let diamonds = isObjWithValues(diamond)
        ? Object.values(diamond)
        : isArrayWithValues(diamond)
        ? diamond
        : {};
      for (let i in diamonds) {
        let obj = diamonds[i];
        let keys = Object.keys(obj);
        for (let j in keys) {
          let value = "";
          if (keys[j] === "diamond_type") {
            let valueObj = diamondTypes?.find(
              (type) => type.value === obj[keys[j]]
            );
            if (valueObj) value = valueObj.label;
          }
          if (keys[j] === "diamond_shape") {
            let valueObj = diamondShapes.find(
              (shape) => shape.value === obj[keys[j]]
            );
            if (valueObj) value = valueObj.label;
          }
          if (keys[j] === "diamond_cut") {
            let valueObj = diamondCut.find(
              (shape) => shape.value === obj[keys[j]]
            );
            if (valueObj) value = valueObj.label;
          }
          value = value || obj[keys[j]];

          productObj[keys[j]] = productObj[keys[j]]
            ? `${productObj[keys[j]]}, ${value}`
            : value;
        }
      }
      productObj.diamond_type = diamondTypes?.find(
        (type) => type.value === diamonds?.[0]?.diamond_type
      )?.label;
    }
    delete productObj.diamond;

    if (isArrayWithValues(productObj.categories))
      productObj.categories = productObj.categories.map((i) =>
        typeof i === "string" ? i : i.name || i.label
      );
    // .join();
    if (isArrayWithValues(productObj.subcategory))
      productObj.subcategory = productObj.subcategory.map((i) =>
        typeof i === "string" ? i : i.name || i.label
      );
    // .join();
    if (isArrayWithValues(productObj.collections))
      productObj.collections = productObj.collections.map((i) =>
        typeof i === "string" ? i : i.name || i.label
      );
    // .join();
    if (isArrayWithValues(productObj.huid))
      productObj.huid = productObj.huid.join();
    if (isArrayWithValues(productObj.images)) {
      let { images } = productObj;
      let string = "";
      images.map((i) => {
        let name =
          typeof i.src === "string"
            ? i.src.replace(/^.*[\\\/]/, "").replace(/\.[^/.]+$/, "")
            : "";
        string = string ? `${string},${name}` : name;
      });
      productObj.images = string;
    }
    // productObj.images = productObj.images.map((i) => i.src).join();
    if (isArrayWithValues(productObj.product_groups))
      productObj.product_groups = productObj.product_groups.join();
    else productObj.product_groups = "";
    for (let i in unnecessaryKeys)
      if (productObj.hasOwnProperty(unnecessaryKeys[i]))
        delete productObj[unnecessaryKeys[i]];
    if (productObj.manual_price == "0") productObj.manual_price = "";
    if (!productObj.manual_price)
      productObj.price = product.priceBreakup?.price || price;
    productObj.gold_gross = Number(productObj.gold_gross);
    productObj.gold_net = Number(productObj.gold_net);
    productObj.silver_net = Number(productObj.silver_net);
    productObj.silver_gross = Number(productObj.silver_gross);
    productObj.platinium_gross = Number(productObj.platinium_gross);
    productObj.platinium_net = Number(productObj.platinium_net);
    productObj.product_width = Number(productObj.product_width);
    productObj.product_height = Number(productObj.product_height);
    productObj.product_thickness = Number(productObj.product_thickness);
    // let array = [];
    // for (let i in AllHeaders)
    //   array.push(
    //     typeof productObj[AllHeaders[i].value] !== "object" &&
    //       !Array.isArray(productObj[AllHeaders[i].value])
    //       ? productObj[AllHeaders[i].value]
    //         ? productObj[AllHeaders[i].value]
    //         : ""
    //       : ""
    //   );
    spreadsheetArray.push(productObj);
  }
  return spreadsheetArray;
};

const _SelectComponent = React.memo(
  SelectComponent
  // (a, b) =>
  // arePropsEqual(a, b, [])
);

const top100Films = [
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  {
    title: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  {
    title: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
  { title: "Casablanca", year: 1942 },
  { title: "City Lights", year: 1931 },
  { title: "Psycho", year: 1960 },
  { title: "The Green Mile", year: 1999 },
];

export { _SelectComponent as SelectComponent, createSpreadsheetData };

// return (
//   <Autocomplete
//     fullWidth
//     multiple={false}
//     ref={ref}
//     options={options}
//     value={isArrayWithValues(options) ? rowData : ""}
//     isSearchable
//     renderInput={(params) => (
//       <TextField
//         {...params}
//         style={{ borderColor: "transparent" }}
//         label=""
//         variant="standard"
//         // inputProps={{
//         //   style: {
//         //     height: "38px",
//         //   },
//         // }}
//         // sx={{
//         //   "& .MuiOutlinedInput-root": {
//         //     "& > fieldset": {
//         //       border: "none",
//         //     },
//         //   },
//         // }}
//       />
//     )}
//     onChange={(a, e) => {
//       setRowData(e);
//     }}
//   />
// );
