import { Box, TextField } from "@mui/material";
import React from "react";

import { styled } from "@mui/material/styles";

// Customizing the TextField component
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    border: "none", // Remove borders
    "& fieldset": {
      border: "none", // Remove borders
    },
    "&:hover fieldset": {
      border: "none", // Remove borders on hover
    },
    "&.Mui-focused fieldset": {
      border: "none", // Remove borders when focused
    },
  },
  "& .MuiInputBase-input": {
    color: "text.secondary", // Set text color
    fontSize: "22px", // Slightly 22px text
    fontWeight: "500",
    paddingLeft: "-100px", // Remove left padding
  },
  "& .MuiInputBase-input::placeholder": {
    color: "text.secondary", // Grey placeholder text color
    fontSize: "22px", // Slightly 22px placeholder text
    fontWeight: "500",
  },
  "& .MuiInputBase-root": {
    fontSize: "22px", // Ensure the input text is slightly 22px
    fontWeight: "500",
    padding: "0 !important",
  },
});

export default function CustomTextFieldNoBorder(props) {
  return (
    <CustomTextField
      multiline
      rows={2}
      placeholder="Enter text..."
      variant="outlined"
      fullWidth
      sx={{ mt: 2 }}
      {...props}
    />
  );
}
