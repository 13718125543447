import { Sync, Upload, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getToken,
  getWebsite,
  isDev,
  isObjWithValues,
} from "../../../../helper";
import axios from "axios";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { useTheme } from "styled-components";
import Switch from "rc-switch";
import ProductMediaLibrary from "../../../components/ProductMediaLibrary";
import { GupshupSmsTemplates } from "./components/GupshupSmsTemplates";
import * as XLSX from "xlsx";
import { toSnakeCase } from "./helper";

function Templates() {
  const theme = useTheme();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  const [formData, setFormData] = useState({
    gs_dlt_templates_file: "",
  });
  const [uploadingTemplates, setUploadingTemplates] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleMediaLibrary, setToggleMediaLibrary] = useState(false);

  const gupshupIntegration = useSelector(
    (state) => state.settings.gupshupIntegration
  );
  const globalToast = useSelector((state) => state.helper.globalToast);
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isObjWithValues(gupshupIntegration))
      onFormEdit({ ...gupshupIntegration });
  }, [gupshupIntegration]);

  const onFormEdit = (payload) => {
    setFormData((pre) => ({ ...pre, ...payload }));
  };

  const onUpdateForm = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/gupshup_integration`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "gupshup_integration",
        data: {
          ...(formData || {}),
        },
      },
    });
    if (res.data.success) {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Settings updated successfully",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          gupshupIntegration: res.data.data,
        },
      });
    } else {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Settings Failed! Please try again.",
          severity: "error",
        })
      );
    }
    setLoading(false);
  };

  const setLogo = (ar, id) => {
    if (ar[0]?.source_url) {
      onFormEdit({ gs_dlt_templates_file: ar[0]?.source_url });
    }

    setToggleMediaLibrary(false);
  };

  const replaceTemplateVariables = (template) => {
    let variableIndex = 1;
    return template.replace(
      /@\_\_123\_\_@/g,
      () => `##var${variableIndex++}##`
    );
  };

  const convertToFormattedList = (data) => {
    const [headers, ...rows] = data;
    return rows.map((row) => {
      return headers.reduce((obj, header, index) => {
        if (header === "TEMPLATE CONTENT") {
          // Adjust based on your CSV headers
          obj[toSnakeCase(header)] = replaceTemplateVariables(row[index]);
        } else {
          obj[toSnakeCase(header)] = row[index];
        }
        return obj;
      }, {});
    });
  };

  const onChangeFile = (event) => {
    const file = event.target.files[0];

    if (file) {
      setUploadingTemplates(true);
      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array", raw: true });
          const firstSheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[firstSheetName];

          const csvArray = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          //   const firstRow = csvArray[0];

          const formattedList = convertToFormattedList(csvArray);

          let website = getWebsite();
          let token = getToken();

          let res = await axios({
            url: `${website}/wp-json/store/v1/settings/gupshup_integration`,
            method: "PUT",
            headers: {
              Authorization: `Basic ${token}`,
            },
            data: {
              setting: "gupshup_integration",
              data: {
                sms: {
                  dlt_templates_list: formattedList,
                },
              },
            },
          });

          if (res.data.success) {
            dispatch(
              setGlobalToast({
                ...globalToast,
                show: true,
                message: "File imported successfully",
                severity: "success",
              })
            );
            dispatch({
              type: EDIT_SETTINGS,
              payload: {
                gupshupIntegration: res.data.data,
              },
            });
          } else {
            dispatch(
              setGlobalToast({
                ...globalToast,
                show: true,
                message: "Something went wrong! Please try again.",
                severity: "error",
              })
            );
          }
        };
        reader.readAsArrayBuffer(file);
      } catch (error) {
        console.log(error);
      } finally {
        setUploadingTemplates(false);
      }
    }
    inputRef.current.value = "";
  };

  return (
    <Box p={4} sx={{ pt: 0 }}>
      <input
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        accept="text/csv"
        onChange={onChangeFile}
      />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h6">Templates</Typography>

        <Stack direction={"row"} gap={3} alignItems={"center"}>
          <LoadingButton
            loading={uploadingTemplates}
            variant={"contained"}
            onClick={() => {
              inputRef.current.click();
            }}
            sx={{ mr: 4 }}
          >
            Upload Template File
          </LoadingButton>
        </Stack>
      </Stack>
      <Typography sx={{ fontStyle: "italic" }}>
        Templates can be created via Gupshup DLT services directly. Once Created
        it must be added to your Preferred SMS Service provider and sync here in
        Jwero to utilize all the templates.
      </Typography>
      <GupshupSmsTemplates gupshupIntegration={gupshupIntegration} />
    </Box>
  );

  return (
    <Box sx={{ paddingLeft: "12px", paddingBottom: "25px" }}>
      <Stack spacing={7} sx={{ width: isPhone ? "100%" : "70%" }}>
        <Stack spacing={7} sx={{ width: isPhone ? "100%" : "70%" }}>
          <Card
            onClick={() => setToggleMediaLibrary(true)}
            sx={{
              cursor: "pointer",
              backgroundColor: "rgba(0,0,0,0.1)",
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.1)",
              },
            }}
          >
            <CardContent
              //   fullWidth
              id="outlined"
              style={{
                // borderWidth: 2,
                // borderStyle: "dashed",
                // borderColor: "#bababa",
                alignItems: "center",
                display: "flex",
              }}
              hoverTitle=""
              sx={{ position: "relative" }}
            >
              <Box
                sx={{
                  height: "120px",
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  // backgroundColor: "primary.main",
                  borderRadius: "50%",
                }}
              >
                {formData?.gs_dlt_templates_file ? (
                  <img
                    // src={obj?.bg_image_mobile[0]}
                    src={formData?.gs_dlt_templates_file}
                    style={{
                      maxHeight: "160px",
                      width: "100%",
                      objectFit: "contain",
                      textAlign: "center",
                      borderRadius: "8px",
                    }}
                  />
                ) : (
                  <Stack justifyContent={"center"} alignItems="center">
                    {/* <Typography>1440px X 600px</Typography> */}
                    <Upload style={{}} />
                    <Typography>Gupshup DLT Templates</Typography>
                    <Typography style={{ fontSize: 12 }}>Upload</Typography>
                    <Typography
                      style={{
                        fontSize: 8,
                        // position: "absolute",
                        left: 2,
                        bottom: 2,
                      }}
                    >
                      CSV file
                    </Typography>
                  </Stack>
                )}
              </Box>
            </CardContent>
          </Card>
        </Stack>
        <LoadingButton
          loading={loading}
          variant={"contained"}
          onClick={onUpdateForm}
          sx={{ mr: 4 }}
        >
          Upload
        </LoadingButton>
      </Stack>
      <ProductMediaLibrary
        open={toggleMediaLibrary}
        onChange={() => setToggleMediaLibrary(!toggleMediaLibrary)}
        setParentImages={setLogo}
        multiple={false}
        aspect={{ width: 100, height: 100 }}
        // selectedImages={images}
        // enable_crop={false}
      />
    </Box>
  );
}

export default Templates;
