import { History } from "@material-ui/icons";
import { ArrowForward, Check, Close, Edit, YouTube } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsChip } from ".";
import { getIp, tutorialData } from "../../../App";
import {
  callSocket,
  commerceUpdateApi,
  dialogStyle,
  formatDate,
  getAdminName,
  getEmail,
  getId,
  getMasterPricingHistoryObj,
  getPricingdiff,
  getWebsite,
  isArrayWithValues,
  isDev,
  isEarlyAccess,
  isObjWithValues,
} from "../../../helper";
import { setGlobalTutorial } from "../../../redux/actions/helperActions";

import {
  addUserActivity,
  sendNewRatesPushNotification,
} from "../../../redux/actions/hubActions";
import {
  EDIT_SETTINGS,
  addMetalPricingHistory,
  updateMarketPlaceProducts,
  updateMasterPricings,
} from "../../../redux/actions/settingActions";
import { isValidDate } from "../../../utils/dateFunctions";
import { primaryLabel } from "../../chat/ChatHelper";
import { FullSizeDialogTitle, StackRow } from "../../components/helper";
import { currencySymbols } from "../../pricing/Gold";
import {
  GoldView,
  PlatinumView,
  SilverView,
} from "../../settings/MasterSettings";
import { findDifferencesBetweenObjects } from "../../pricing/helper";
import { UAParser } from "ua-parser-js";

const PricingSetting = () => {
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const default_currency = useSelector(
    (state) => state.settings.productSettings
  )?.default_currency;
  const [pricing, setPricing] = useState({
    gold_pricing: {},
    silver_pricing: {},
    platinum_pricing: {},
  });
  const [changePurity, setChangePurity] = useState({
    gold: [],
    silver: [],
    platinum: [],
  });
  const [loading, setLoading] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  const openForm = () => setFormOpen(true);
  const closeForm = () => setFormOpen(false);

  const dispatch = useDispatch();

  const isAdmin = useSelector((state) => state?.user?.isAdmin);
  const productPriceUpdateHistory = useSelector(
    (state) => state?.settings?.productPriceUpdateHistory
  );
  const oldMasterSetting = useSelector(
    (state) => state?.helper?.oldMasterSetting
  );

  useEffect(() => {
    if (isObjWithValues(masterPricing)) {
      let { gold_pricing, platinum_pricing, silver_pricing, last_updated } =
        masterPricing;
      //   setGoldPrice(gold_pricing);
      //   setSilverPrice(platinum_pricing);
      //   setPlatinumPrice(silver_pricing);
      setPricing({
        gold_pricing,
        platinum_pricing,
        silver_pricing,
        last_updated,
      });
    }
  }, [masterPricing]);

  const onBaseAmountChange = (
    metal,
    purity,
    amount,
    dontChangeOtherPurities
  ) => {
    console.log(metal, purity, amount, dontChangeOtherPurities);
    let type = pricing[`${metal}_pricing`]?.type;
    // console.log(pricing[`${metal}_pricing`][default_currency][type], purity);
    // return;
    let obj = {
      ...pricing[`${metal}_pricing`][default_currency][type][purity],
    };
    obj.base = amount;
    obj.rate = amount;
    if (type === "automatic") {
      if (Number(obj.margin)) {
        obj.rate = Math.round(
          (Number(amount) * Number(obj.margin)) / Number(purity)
        );
      }
      if (!dontChangeOtherPurities) {
        let purities = Object.keys(
          pricing[`${metal}_pricing`][default_currency].enabled_purities
        ).filter((i) => i !== purity);
        for (let i = 0; i < purities.length; i++) {
          let otherPurityAmount = Math.round(
            (Number(amount) * Number(purities[i])) / Number(obj.margin)
          );
          onBaseAmountChange(metal, purities[i], otherPurityAmount, true);
        }
      }
    }

    setPricing((state) => ({
      ...state,
      [`${metal}_pricing`]: {
        ...state[`${metal}_pricing`],
        [default_currency]: {
          ...state[`${metal}_pricing`][default_currency],
          [type]: {
            ...state[`${metal}_pricing`][default_currency][type],
            [purity]: obj,
          },
        },
      },
    }));
  };
  const onPricngChange = (metal, purity) => {
    let oldPurities = changePurity[metal] || [];
    console.log(oldPurities, purity);
    setChangePurity((pre) => ({ ...pre, [metal]: [...oldPurities, purity] }));
  };
  const onUpdate = (label) => {
    setLoading(label);
    console.log(getMasterPricingHistoryObj(pricing, default_currency));
    let goldtype = pricing[`gold_pricing`]?.type;
    let slivertype = pricing[`silver_pricing`]?.type;
    let platinumtype = pricing[`platinum_pricing`]?.type;
    let defaultPurityAmountGold =
      pricing?.gold_pricing[default_currency][goldtype];
    let diffrenceObjGold = getPricingdiff(
      masterPricing?.gold_pricing[default_currency][goldtype],
      defaultPurityAmountGold
    );
    let defaultPurityAmountSilver =
      pricing?.silver_pricing[default_currency][slivertype];
    let diffrenceObjSilver = getPricingdiff(
      masterPricing?.silver_pricing[default_currency][slivertype],
      defaultPurityAmountSilver
    );
    let defaultPurityAmountPlatinum =
      pricing?.platinum_pricing[default_currency][platinumtype];
    let diffrenceObjPlatimum = getPricingdiff(
      masterPricing?.platinum_pricing[default_currency][platinumtype],
      defaultPurityAmountPlatinum
    );

    let event_info_gold = {};
    let event_info_silver = {};
    let event_info_platinum = {};
    for (const purity of changePurity?.gold) {
      let diffrenceObjpurity = diffrenceObjGold[purity] || {};
      event_info_gold = { ...event_info_gold, [purity]: diffrenceObjpurity };
    }
    for (const purity of changePurity?.silver) {
      let diffrenceObjpurity = diffrenceObjSilver[purity] || {};
      event_info_silver = {
        ...event_info_silver,
        [purity]: diffrenceObjpurity,
      };
    }
    for (const purity of changePurity?.platinum) {
      let diffrenceObjpurity = diffrenceObjPlatimum[purity] || {};
      event_info_platinum = {
        ...event_info_platinum,
        [purity]: diffrenceObjpurity,
      };
    }
    if (
      isArrayWithValues(changePurity?.gold) ||
      isArrayWithValues(changePurity?.silver) ||
      isArrayWithValues(changePurity?.platinum)
    ) {
      dispatch(
        addUserActivity({
          event: `pricing_update`,
          currency: default_currency,
          event_info: {
            gold:
              isArrayWithValues(event_info_gold) ||
              isObjWithValues(event_info_gold)
                ? event_info_gold
                : undefined,
            silver:
              isArrayWithValues(event_info_silver) ||
              isObjWithValues(event_info_silver)
                ? event_info_silver
                : undefined,
            platinum:
              isArrayWithValues(event_info_platinum) ||
              isObjWithValues(event_info_platinum)
                ? event_info_platinum
                : undefined,
          },
        })
      );
    }
    commerceUpdateApi();
    dispatch(updateMarketPlaceProducts());
    dispatch(
      updateMasterPricings({
        data: {
          ...pricing,
          last_updated: isDev() ? undefined : Math.floor(Date.now() / 1000),
        },
        afterUpdate: async () => {
          setLoading(false);
          closeForm();
          dispatch(
            addMetalPricingHistory(
              getMasterPricingHistoryObj(pricing, default_currency)
            )
          );
          let {
            gold_pricing: old_gold_pricing,
            silver_pricing: old_silver_pricing,
            platinum_pricing: old_platinum_pricing,
          } = oldMasterSetting || {};

          console.log(old_gold_pricing, event_info_gold, "Empty array");

          let obj = {};
          // let goldDifference = isObjWithValues(old_gold_pricing)
          //   ? findDifferencesBetweenObjects(
          //       old_gold_pricing[default_currency],
          //       event_info_gold[default_currency]
          //     )
          //   : [];
          // let silverDifference = isObjWithValues(old_silver_pricing)
          //   ? findDifferencesBetweenObjects(
          //       old_silver_pricing[default_currency],
          //       event_info_silver[default_currency]
          //     )
          //   : [];
          // let platinumDifference = isObjWithValues(old_platinum_pricing)
          //   ? findDifferencesBetweenObjects(
          //       old_platinum_pricing[default_currency],
          //       event_info_platinum[default_currency]
          //     )
          //   : [];

          // if (isArrayWithValues(goldDifference)) obj.gold = goldDifference;
          // if (isArrayWithValues(silverDifference))
          //   obj.silver = silverDifference;
          // if (isArrayWithValues(platinumDifference))
          //   obj.platinum = platinumDifference;

          if (isEarlyAccess()) {
            let hookRes = await callSocket({
              endpoint: "master_update_hook",
              method: "POST",
              data: {
                website: getWebsite(),
                historyData: {
                  master_update: obj,
                  ip: await getIp(),
                  ua: new UAParser().getUA(),
                  isAdmin: isAdmin,
                  by: getId(),
                  is_dev: isDev(),
                },
                email: getEmail(),
                userName: getAdminName(),
                // pricingInfo: {
                //   old: {
                //     diamond:
                //       isArrayWithValues(diamondDifference) &&
                //       old_diamond_pricing,
                //     gemstone:
                //       isArrayWithValues(gemstoneDifference) &&
                //       old_gemstone_pricing,
                //     labour:
                //       isArrayWithValues(labourDifference) && old_labour_pricing,
                //   },
                //   new: {
                //     diamond:
                //       isArrayWithValues(diamondDifference) && diamond_pricing,
                //     gemstone:
                //       isArrayWithValues(gemstoneDifference) && gemstone_pricing,
                //     labour:
                //       isArrayWithValues(labourDifference) && labour_pricing,
                //   },
                // },
                require_history: true,
              },
            });

            console.log("masterUpdateHook", hookRes);

            if (isObjWithValues(hookRes?.data?.data)) {
              dispatch({
                type: EDIT_SETTINGS,
                payload: {
                  productPriceUpdateHistory: {
                    ...(productPriceUpdateHistory || {}),
                    [hookRes?.data?.data?.id]: hookRes?.data?.data,
                  },
                },
              });
              // setShowSync(true);
            }
          }
          dispatch(sendNewRatesPushNotification());
        },
        updateGoogleProducts: true,
        // afterUpdate,
      })
    );
  };
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <Box sx={{ mb: 5, mt: 2 }}>
      {/* <Typography sx={{ fontSize: "15px", mb: 2, px: 2, fontWeight: "400" }}>
        Master Pricing
      </Typography> */}
      <Card>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          px={2}
          py={2}
        >
          <Typography sx={{ ...primaryLabel }}>
            Today's rates{" "}
            <IconButton
              onClick={() =>
                dispatch(
                  setGlobalTutorial({
                    show: true,
                    url: tutorialData?.update_metal_rates_from_dashboard?.link,
                    title:
                      tutorialData?.update_metal_rates_from_dashboard?.title,
                  })
                )
              }
            >
              <YouTube />
            </IconButton>
          </Typography>

          <Button startIcon={<Edit />} onClick={openForm}>
            Update Pricing
          </Button>
        </Stack>
        <Grid container spacing={isIpad ? 0 : 3} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <DashboardPricing
              label="Gold"
              icon={<GoldView />}
              price={pricing.gold_pricing}
              url="https://www.pngitem.com/pimgs/m/137-1378758_gold-coin-png-circle-transparent-png.png"
              default_currency={default_currency}
              updating={loading}
              onUpdate={onUpdate}
              onBaseAmountChange={onBaseAmountChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <DashboardPricing
              label="Silver"
              icon={<SilverView />}
              price={pricing.silver_pricing}
              onUpdate={onUpdate}
              updating={loading}
              url="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKz1t3Edg4qkbAquEyxufJRy7KLW1chd1dIw&usqp=CAU"
              default_currency={default_currency}
              onBaseAmountChange={onBaseAmountChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            xl={3}
            // sx={
            //   isPhone
            //     ? {
            //         display: "flex",
            //         justifyContent: "space-between",
            //         paddingRight: "14px",
            //         alignItems: "center",
            //       }
            //     : {}
            // }
          >
            <DashboardPricing
              label="Platinum"
              icon={<PlatinumView />}
              price={pricing.platinum_pricing}
              default_currency={default_currency}
              onUpdate={onUpdate}
              updating={loading}
              onBaseAmountChange={onBaseAmountChange}
            />
            {/* {isPhone ? (
              <IconButton onClick={openForm}>
                <Edit />
              </IconButton>
            ) : null} */}
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <Stack
              direction="row"
              alignItems={"center"}
              sx={{
                // margin: "auto 0px",
                pl: 4,
                pt: 1,
                pb: 1,
                backgroundColor: "#fbfbfb",
              }}
            >
              <History
                style={{
                  width: "12px",
                  height: "12px",
                  color: "gba(0,0,0,0.6)",
                }}
              />
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "text.secondary",
                  alignItems: "center",
                  verticalAlign: "center",
                  ml: 1,
                }}
              >
                Last Updated :{" "}
                {isValidDate(new Date(pricing.last_updated))
                  ? formatDate(
                      new Date(pricing.last_updated * 1000),
                      "dd:mm:yyyy hh:mm"
                    )
                  : ""}
              </Typography>
            </Stack>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3} xl={1.5}>
                <Stack direction="row" alignItems={"center"}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "text.secondary",
                      alignItems: "center",
                      verticalAlign: "center",
                      margin: "auto 0px",
                    }}
                  >
                    Last Updated : 12 dec 2022 12:20 pm
                  </Typography>
                </Stack>
              </Grid> */}
        </Grid>
        {/* <Stack
          direction="row"
          alignItems={"center"}
          sx={{
            // margin: "auto 0px",
            pl: 4,
            pt: 1,
            pb: 1,
            backgroundColor: "#fbfbfb",
          }}
        >
          <History
            style={{
              width: "12px",
              height: "12px",
              color: "gba(0,0,0,0.6)",
            }}
          />
          <Typography
            sx={{
              fontSize: "10px",
              color: "text.secondary",
              alignItems: "center",
              verticalAlign: "center",
              ml: 1,
            }}
          >
            Last Updated :{" "}
            {isValidDate(new Date(pricing.last_updated))
              ? formatDate(
                  new Date(pricing.last_updated * 1000),
                  "dd:mm:yyyy hh:mm"
                )
              : ""}
          </Typography>
        </Stack> */}
      </Card>
      {/* <Grid container spacing={3} sx={{ alignItems: "center" }}>
        <Grid item xs={12} sm={10}></Grid>
        {!isPhone ? (
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              endIcon={<ArrowForward />}
              onClick={openForm}
            >
              Update Pricing
            </Button>
          </Grid>
        ) : null}
      </Grid> */}

      <Dialog
        open={formOpen}
        onClose={closeForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...globalPopupStyle}
        sx={{ ...dialogStyle }}
      >
        {/* <DialogTitle id="alert-dialog-title"  >    {"Update Master Pricing"}    <IconButton onClick={() => closeForm()}><Close /></IconButton>   </DialogTitle> */}
        <FullSizeDialogTitle>
          {"Update Master Pricing"}{" "}
          <IconButton onClick={() => closeForm()}>
            <Close />
          </IconButton>
        </FullSizeDialogTitle>

        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <DashboardPricingCard
                label="Gold"
                icon={<GoldView />}
                price={pricing.gold_pricing}
                url="https://www.pngitem.com/pimgs/m/137-1378758_gold-coin-png-circle-transparent-png.png"
                default_currency={default_currency}
                updating={loading}
                onUpdate={onUpdate}
                onBaseAmountChange={onBaseAmountChange}
                onPricngChange={onPricngChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DashboardPricingCard
                label="Silver"
                icon={<SilverView />}
                price={pricing.silver_pricing}
                onUpdate={onUpdate}
                updating={loading}
                url="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKz1t3Edg4qkbAquEyxufJRy7KLW1chd1dIw&usqp=CAU"
                default_currency={default_currency}
                onBaseAmountChange={onBaseAmountChange}
                onPricngChange={onPricngChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DashboardPricingCard
                label="Platinum"
                icon={<PlatinumView />}
                price={pricing.platinum_pricing}
                default_currency={default_currency}
                onUpdate={onUpdate}
                updating={loading}
                onBaseAmountChange={onBaseAmountChange}
                onPricngChange={onPricngChange}
              />
            </Grid>
          </Grid>
          <StackRow>
            <Button onClick={closeForm}>Cancel</Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={onUpdate}
              autoFocus
            >
              Update
            </LoadingButton>
          </StackRow>
          {/* <DialogActions> */}

          {/* </DialogActions> */}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default PricingSetting;

const DashboardPricingCard = ({
  label,
  icon,
  price,
  default_currency,
  onBaseAmountChange,
  updating,
  onUpdate,
  onPricngChange,
}) => {
  let defaultPurity = price?.[default_currency]?.default;
  let defaultPurityAmount =
    price?.[default_currency]?.[price.type]?.[defaultPurity]?.base;
  return (
    <Box sx={{ mt: 2 }}>
      {/* <img
        src={url}
        style={{
          width: "65%",
          objectFit: "contain",
          margin: "10px auto 20px auto",
          alignSelf: "center",
          display: "flex",
        }}
      /> */}
      {/* <Box
        sx={{
          height: "200px",
          width: "50%",
          borderRadius: "14px",
          background: "rgb(250,233,175)",
          margin: "14px auto",
          background:
            "linear-gradient(130deg, rgba(250,233,175,1) 0%, rgba(172,137,60,1) 100%)",
        }}
      /> */}
      <Stack direction={"row"} sx={{ gap: "7px" }}>
        {icon}{" "}
        <Typography variant="h6" sx={{ mb: 2 }}>
          {label} {defaultPurity ? `(${defaultPurity})` : ""}
        </Typography>
      </Stack>
      <TextField
        placeholder={`${label} Rate`}
        size="small"
        sx={{ mt: 3, mb: 2 }}
        fullWidth
        type="number"
        value={defaultPurityAmount}
        onChange={(e) => {
          onBaseAmountChange(
            label.toLowerCase(),
            defaultPurity,
            e.target.value
          );
          onPricngChange(label.toLowerCase(), defaultPurity);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment>
              <Typography sx={{ mr: 2 }}>
                {currencySymbols[default_currency]}
              </Typography>
            </InputAdornment>
          ),
          // endAdornment: (
          //   <InputAdornment>
          //     {updating === label ? (
          //       <CircularProgress size="18px" />
          //     ) : (
          //       <IconButton onClick={() => onUpdate(label)} size="small">
          //         <Check />
          //       </IconButton>
          //     )}
          //   </InputAdornment>
          // ),
        }}
      />
    </Box>
  );
};
const DashboardPricing = ({
  label,
  icon,
  price,
  default_currency,
  onBaseAmountChange,
  updating,
  onUpdate,
}) => {
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  let defaultPurity = price?.[default_currency]?.default;
  let defaultPurityAmount =
    price?.[default_currency]?.[price.type]?.[defaultPurity]?.base;
  return (
    <Box sx={{ padding: "12px 16px" }}>
      {/* <img
        src={url}
        style={{
          width: "65%",
          objectFit: "contain",
          margin: "10px auto 20px auto",
          alignSelf: "center",
          display: "flex",
        }}
      /> */}
      {/* <Box
        sx={{
          height: "200px",
          width: "50%",
          borderRadius: "14px",
          background: "rgb(250,233,175)",
          margin: "14px auto",
          background:
            "linear-gradient(130deg, rgba(250,233,175,1) 0%, rgba(172,137,60,1) 100%)",
        }}
      /> */}
      <Stack direction={"row"} alignItems="center" sx={{ gap: "7px" }}>
        {icon}{" "}
        <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: "500" }}>
          {label} {defaultPurity ? `(${defaultPurity})` : ""}:
        </Typography>
        {/* <AnalyticsChip label={"10%"} type={"success"} /> */}
        <Typography sx={{ mr: 2, fontSize: "15px", fontWeight: "600" }}>
          {currencySymbols[default_currency]} {defaultPurityAmount}
        </Typography>
      </Stack>
      {/* <TextField
        placeholder={`${label} Rate`}
        size="small"
        sx={{ mt: 3, mb: 2 }}
        fullWidth
        type="number"
        value={defaultPurityAmount}
        onChange={(e) =>
          onBaseAmountChange(label.toLowerCase(), defaultPurity, e.target.value)
        }
        InputProps={{
          startAdornment: (
            <InputAdornment>
              <Typography sx={{ mr: 2 }}>
                {currencySymbols[default_currency]}
              </Typography>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment>
              {updating === label ? (
                <CircularProgress size="18px" />
              ) : (
                <IconButton onClick={() => onUpdate(label)} size="small">
                  <Check />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      /> */}
    </Box>
  );
};
