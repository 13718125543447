/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover as MuiPopover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Bell } from "react-feather";
import { getToken, getWebsite, isArrayWithValues } from "../../helper";
import axios from "axios";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

export const notificationTypes = {
  chat: ["whatsapp_msg_notify", "whatsapp_message"],
  crm: [
    "new_user",
    "user_login_success_admin_notify",
    "email_reopened",
    "email_opened",
  ],
  catalog: ["new_catalog"],
  catalogRequest: ["catalog_request"],
  order: ["new_order"],
  digigold: ["new_digigold"],
  review: ["new_review"],
};

function Notification({
  title,
  description,
  customer_id,
  image,
  icon,
  status,
  handleClose,
  isMessage,
  type,
}) {
  const navigate = useNavigate();
  const onClick = () => {
    handleClose();
    if (notificationTypes.chat.includes(type)) navigate(`/chats`);
    else if (notificationTypes.crm.includes(type))
      navigate(`/crm/${customer_id}#chat`);
    else if (notificationTypes.catalog.includes(type))
      navigate(`/catalogs/${customer_id}`);
    else if (notificationTypes.catalogRequest.includes(type))
      navigate(`/catalogs/requests/${customer_id}`);
    else if (notificationTypes.order.includes(type))
      navigate(`/orders/${customer_id}`);
    else if (notificationTypes.digigold.includes(type)) navigate(`/digigold`);
    else if (notificationTypes.review.includes(type))
      navigate(`/frontend#reviews`);
    console.log(notificationTypes.digigold.includes(type), "");
  };

  return (
    <ListItem
      divider
      onClick={onClick}
      sx={{ cursor: "pointer" }}
      // component={Link}
      // to="#"
      disabled={status === 0 ? false : true}
    >
      {icon}
      {/* <ListItemAvatar>
        <Avatar src={image} alt="Avatar" />
      </ListItemAvatar> */}
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={description}
        secondaryTypographyProps={{ fontSize: "11px" }}
      />
    </ListItem>
  );
}

function NavbarNotificationsDropdown(props) {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unread, setUnread] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [show, setShow] = useState(false);
  let timeout;

  useEffect(() => {
    let timeout = setTimeout(() => {
      setShow(true);
    }, 1700);
    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    if (isArrayWithValues(props.notifications)) {
      let array = [];
      let unread = 0;
      let { notifications } = props;
      for (let i = 0; i < notifications.length; i++) {
        if (notifications[i].status === 0) unread += 1;
        array.push(notifications[i]);
      }
      setUnread(unread);
      setNotifications(array);
    }
  }, [props.notifications]);

  const handleOpen = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
    // if (isArrayWithValues(notifications) && unread > 0) {
    //   let array = [...notifications];
    //   array = array.map((i) => ({ ...i, status: 1 }));
    //   // console.log(array);
    //   updateNotifications(array);
    // }
  };
  useMemo(() => {
    if (anchorEl) {
      if (isArrayWithValues(notifications) && unread > 0) {
        let array = [...notifications];
        array = array.map((i) => ({ ...i, status: 1 }));
        // console.log(array);
        updateNotifications(array);
      }
    }
  }, [anchorEl]);

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          // ref={ref}
          onClick={handleOpen}
          size="large"
          className="notification_icon"
        >
          <Indicator badgeContent={unread}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        id={id}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ maxHeight: "300px" }}
        open={open}
      >
        <NotificationHeader p={2} sx={{}}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Typography variant="subtitle1" color="textPrimary">
              {unread} New Notifications
            </Typography>
            <Button
              size="small"
              onClick={() => {
                handleClose();
                navigate("/notifications");
              }}
            >
              View all
            </Button>
          </Stack>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding sx={{}}>
            {isArrayWithValues(notifications) &&
              show &&
              notifications.map((obj) => (
                <Notification
                  key={obj.time}
                  title={obj.title}
                  description={obj.message}
                  customer_id={obj.id}
                  handleClose={handleClose}
                  status={obj.status}
                  isMessage={obj.type === "whatsapp_msg_notify"}
                  type={obj.type}
                  // icon={
                  //   <Box
                  //     sx={{
                  //       width: "50px",
                  //       justifyContent: "center",
                  //       // textAlign: "center",
                  //     }}
                  //   >
                  //     <Whatsapp style={{ margin: "auto" }} />
                  //   </Box>
                  // }
                  // image="/static/img/avatars/avatar-2.jpg"
                />
              ))}
            {/* <Notification
              title="Update complete"
              description="Restart server to complete update."
              Icon={Server}
            />
            <Notification
              title="New connection"
              description="Anna accepted your request."
              Icon={UserPlus}
            />
            <Notification
              title="Lorem ipsum"
              description="Aliquam ex eros, imperdiet vulputate hendrerit et"
              Icon={Bell}
            />
            <Notification
              title="New login"
              description="Login from 192.186.1.1."
              Icon={Home}
            /> */}
          </List>
        </React.Fragment>
        {/* <Box p={1} display="flex" justifyContent="center">
          <Button size="small" component={Link} to="#">
            Show all notifications
          </Button>
        </Box> */}
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;

const updateNotifications = async (notifications, dispatch) => {
  notifications = notifications.reverse();
  let token = getToken();
  let website = getWebsite();
  let headersList = {
    // Accept: "*/*",
    Authorization: `Basic ${token}`,
    "Content-Type": "application/json",
  };
  try {
    // eslint-disable-next-line no-unused-vars
    let { data } = await axios({
      url: `${website}/wp-json/store/v1/settings/jwero_notifications`,
      method: "POST",
      headers: headersList,
      data: { type: "jwero_notifications", data: notifications },
    });
  } catch (error) {}

  // console.log(data, "<<< NOTIFICTAION UPDATE");
  // await fetch(`${website}/wp-json/store/v1/notifications`, {
  //   method: "POST",
  //   headers: headersList,
  //   body: { type: "add", data: notifications },
  // })
  //   .then(function (response) {
  //     console.log(response);
  //     return response.text();
  //   })
  //   .then(function (data) {
  //     console.log(data);
  //     // return data;
  //   })
  //   .catch((e) => console.log(e));
  // if (res.data) return res.data;

  // console.log(token);
  // let res = await axios({
  //   method: "GET",
  //   url: `${website}/wp-json/store/v1/notifications`,
  //   headers: {
  //     Authentication: `Basic ${token}`,
  //     // Accept: "*/*",
  //   },
  // });
  // setLoading(false);
  // if (res.data) return res.data;
};
