import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Popover,
  Box,
  TextField,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { attributePopoverStyles } from "../../sidebar-bot/styles/attributePopoverStyles";
import { preDefinedAttributesTextField } from "../../sidebar-bot/utils/preDefinedAttribuesTextField";

const styles = attributePopoverStyles;

const containsText = (text, searchText) => {
  return text.toLowerCase().includes(searchText.toLowerCase());
};

const Attributes = ({
  attributeAnchorEl,
  setAttributeAnchorEl,
  handleAttributeSelect,
}) => {
  const open = Boolean(attributeAnchorEl);
  const id = open ? "simple-popover" : undefined;
  const [searchText, setSearchText] = useState("");
  const globalAttributes = useSelector(
    (state) => state?.flow?.globalAttributes
  );

  const displayedOptions = useMemo(() => {
    const preDefinedSection = "Pre-defined Attributes";
    const globalSection = "Global Attributes";

    const filteredPreDefined = preDefinedAttributesTextField.filter((option) =>
      containsText(option, searchText)
    );

    const filteredGlobal = globalAttributes.filter((option) =>
      containsText(option, searchText)
    );

    const options = [];

    if (filteredPreDefined.length > 0) {
      options.push(preDefinedSection, ...filteredPreDefined);
    }

    if (filteredGlobal.length > 0) {
      options.push(globalSection, ...filteredGlobal);
    }

    if (options.length === 0) {
      options.push("No options found");
    }

    return options;
  }, [searchText, globalAttributes]);

  const searchLabels = [
    "Pre-defined Attributes",
    "Global Attributes",
    "No options found",
  ];

  const handleClose = () => {
    setAttributeAnchorEl(null);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={attributeAnchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Box sx={styles.outerBox}>
        <Box
          sx={{
            borderBottom: "1px solid #dcdcdc",
            padding: "5px",
          }}
        >
          <TextField
            size="small"
            autoFocus
            variant="standard"
            placeholder="Type to search..."
            fullWidth
            inputProps={{
              style: {
                fontFamily: "Inter",
              },
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment
                  sx={{
                    position: "relative",
                    left: "4px",
                    bottom: "2px",
                    color: "#366aed",
                  }}
                  position="start"
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
        </Box>
        {displayedOptions?.map((option, i) => (
          <MenuItem
            key={i}
            sx={{
              fontFamily: "Inter",
              fontSize: searchLabels.includes(option) ? "16px" : "15px",
              fontWeight: searchLabels.includes(option) ? "600px" : "400px",
              backgroundColor: searchLabels.includes(option)
                ? "#fff"
                : "inherit",
              color: searchLabels.includes(option) ? "#000" : "inherit",
              margin: searchLabels.includes(option) ? "10px 0px 0px 0px" : "0",
              padding: searchLabels.includes(option)
                ? "5px 7px"
                : "5px 15px 5px 15px",
              cursor: searchLabels.includes(option) ? "default" : "pointer",
              "&:hover": {
                backgroundColor: !searchLabels.includes(option)
                  ? "#f0f0f0"
                  : "#fff",
              },
            }}
            onClick={() => {
              if (!searchLabels.includes(option)) {
                handleAttributeSelect({ option });
                handleClose();
              }
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Box>
    </Popover>
  );
};

export default Attributes;
