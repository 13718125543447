import { settingsEndpoint } from "../../../../../../helper";

export const fetchCustomerSegments = async () => {
  // const url =
  //   "https://tiarabytj.com/wp-json/store/v1/settings/customer_segments";

  try {
    let res = await settingsEndpoint({
      endpoint: "customer_segments",
    });
    return res;
    // const response = await fetch(url, {
    //   method: "GET",
    //   headers: {
    //     authority: "tiarabytj.com",
    //     accept: "application/json, text/plain, */*",
    //     "accept-language": "en-GB,en-US;q=0.9,en;q=0.8",
    //     authorization: "Basic dGlhcmFieXRqQGdtYWlsLmNvbTpPY3RvYmVyQEp3ZXJv",
    //     origin: "https://app.jwero.ai",
    //     referer: "https://app.jwero.ai/",
    //     "sec-ch-ua":
    //       '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
    //     "sec-ch-ua-mobile": "?0",
    //     "sec-ch-ua-platform": '"macOS"',
    //     "sec-fetch-dest": "empty",
    //     "sec-fetch-mode": "cors",
    //     "sec-fetch-site": "cross-site",
    //     "user-agent":
    //       "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36",
    //   },
    // });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching customer segments:", error);
    throw error; // Propagate the error to the caller
  }
};
