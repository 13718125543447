import { useState, useEffect, useRef } from "react";
import { Typography, Card, TextField, IconButton, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import FormFields from "./components/FormFields";
import NotificationPreview from "./components/NotificationPreview";
import { checkTitleExists } from "../helper/checkTitleExists";
import { bytesToMegabytes } from "../sidebar-bot/utils/bytesToMegabytes";
import { uploadDataToAwsBucket } from "../sidebar-bot/utils/uploadDataToAwsBucket";
import { pushNotificationStyles } from "./styles/pushNotificationStyles";
import SidebarTopBar from "../components/SidebarTopBar";

const styles = pushNotificationStyles;

const SidebarPushNotification = () => {
  const imageFunctionRef = useRef();
  const inputRefTitle = useRef();
  const inputRefMessage = useRef();
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [titleCheckFlag, setTitleCheckFlag] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [notificationTitleError, setNotificationTitleError] = useState(false);
  const [currentAttributeField, setCurrentAttributeField] = useState(null);
  const [notificationMessageError, setNotificationMessageError] =
    useState(false);
  const [data, setData] = useState({
    title: null,
    message: null,
    image: null,
    launch_url: null,
  });

  const titleErrorMessage =
    title === "" ? "Title is required" : "Title already exists!";

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const closeSidebar = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { openNotificationSidebar: false },
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleTitle = (e) => {
    const value = e.target.value;
    setTitle(e.target.value);
    if (checkTitleExists({ nodes, title: value })) {
      setTitleCheckFlag(true);
      return;
    }
    setTitleCheckFlag(false);
  };

  const responseObj = {
    // app_id: "3938a11f-6742-410b-85df-87f9c868f681",
    // name: "name",
    contents: {},
    headings: {},
    // url: "https://google.com",
    // chrome_web_image:
    //   "https://tiarabytj.com/wp-content/uploads/sites/27/2023/12/XYZ_03-scaled.jpg",
    ios_attachments: {
      // id1: "https://tiarabytj.com/wp-content/uploads/sites/27/2023/12/XYZ_03-scaled.jpg",
    },
    // big_picture:
    //   "https://tiarabytj.com/wp-content/uploads/sites/27/2023/12/XYZ_03-scaled.jpg",
    // global_image:
    //   "https://tiarabytj.com/wp-content/uploads/sites/27/2023/12/XYZ_03-scaled.jpg",
    // chrome_big_picture:
    //   "https://tiarabytj.com/wp-content/uploads/sites/27/2023/12/XYZ_03-scaled.jpg",
    // channel: "",
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title == "" || titleCheckFlag) {
      setTitleCheckFlag(true);
      setTitleError(true);
    }

    if (!data?.title) {
      setNotificationTitleError(true);
    }

    if (!data?.message) {
      setNotificationMessageError(true);
    }

    if (title && !titleCheckFlag && data?.title && data?.message) {
      if (!responseObj.contents) responseObj.contents = {};
      responseObj.contents.en = data?.message;
      if (!responseObj.headings) responseObj.headings = {};
      responseObj.headings.en = data?.title;
      responseObj.url = data?.launch_url;
      responseObj.chrome_web_image = data?.image;
      responseObj.ios_attachments.id1 = data?.image;
      responseObj.big_picture = data?.image;
      responseObj.global_image = data?.image;
      responseObj.global_image = data?.image;
      responseObj.chrome_big_picture = data?.image;

      const submittedUserData = {
        title,
        data,
        template: responseObj,
      };

      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openNotificationSidebar: false },
      });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  const handleImage = async (e) => {
    setUploadingFile(true);
    const uploadedFile = e.target.files[0];
    const imageSize = bytesToMegabytes(uploadedFile?.size);
    if (imageSize > 5) {
      alert("Image size exceeds the 5MB limit.");
      setUploadingFile(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", uploadedFile);
    const res = await uploadDataToAwsBucket({ formData });
    const imageURL = res.data.source_url;
    setUploadingFile(false);
    return imageURL;
  };

  const handleAttributeSelect = ({ option }) => {
    const titleCursor =
      inputRefTitle.current.querySelector("textarea").selectionStart;
    const messageCursor =
      inputRefMessage.current.querySelector("textarea").selectionStart;
    const currCursor =
      currentAttributeField === "title" ? titleCursor : messageCursor;
    const prevValue =
      currentAttributeField === "title"
        ? data?.title || ""
        : data?.message || "";

    const newValue =
      prevValue.slice(0, currCursor) +
      ` {{${option}}} ` +
      prevValue.slice(currCursor);

    setData((prev) => ({
      ...prev,
      [currentAttributeField]: newValue,
    }));
  };

  const handleChange = async ({ e }) => {
    const objKey = e?.target?.name ? e?.target?.name : "image";
    let value = e?.target?.value;

    if (objKey === "image" && e?.target?.files?.[0]) {
      value = await handleImage(e);
    }

    setData((prev) => ({
      ...prev,
      [objKey]: value,
    }));
  };

  useEffect(() => {
    const currentNode = nodes?.find((it) => it.id === userId);

    if (currentNode) {
      const node = currentNode?.fieldData;
      if (node?.title) {
        setTitle(node?.title);
      }
      if (node?.data) {
        setData(node?.data);
      }
    }
  }, [userId, nodes]);

  return (
    <Card style={styles.customCard}>
      <form>
        <SidebarTopBar
          onTitleChange={handleTitle}
          titleError={titleErrorMessage}
          title={title}
          showTitleError={titleCheckFlag}
          handleClose={handleClose}
          handleCheck={handleCheck}
          type="push"
          topBarTitle={"Push Notification"}
          titleLabel="Trigger title"
        />
        {/* <Box sx={styles.topCard}>
          <Box sx={styles.innerCard}>
            <Box sx={styles.userHeader}>
              <NotificationsActiveIcon />
              <Typography variant="span" style={styles.headerTitle}>
                Push Notification
              </Typography>
            </Box>
            <Box sx={styles.headerIconsMainDiv}>
              <IconButton sx={styles.headerIconsDiv} onClick={handleClose}>
                <CloseIcon sx={styles.headerIcons} />
              </IconButton>
              <IconButton sx={styles.headerIconsDiv} onClick={handleCheck}>
                <CheckIcon sx={styles.headerIcons} />
              </IconButton>
            </Box>
          </Box>

          <TextField
            placeholder="Type block title"
            size="small"
            style={styles.titleTextField}
            onChange={handleTitle}
            error={titleError}
            value={title}
            required
          />
          {titleCheckFlag && (
            <Typography
              sx={{
                fontSize: "14px",
                color: "#E63946",
                fontWeight: 600,
                fontFamily: "Inter",
                position: "relative",
                bottom: "10px",
                left: "3px",
              }}
            >
              {titleErrorMessage}
            </Typography>
          )}
        </Box> */}
        <Box sx={styles.responseSection}>
          <Box sx={styles.responseDiv}>
            <FormFields
              data={data}
              handleChange={handleChange}
              uploadingFile={uploadingFile}
              imageFunctionRef={imageFunctionRef}
              notificationTitleError={notificationTitleError}
              notificationMessageError={notificationMessageError}
              handleAttributeSelect={handleAttributeSelect}
              currentAttributeField={currentAttributeField}
              setCurrentAttributeField={setCurrentAttributeField}
              inputRefTitle={inputRefTitle}
              inputRefMessage={inputRefMessage}
            />
            <NotificationPreview data={data} />
          </Box>
        </Box>
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </form>
    </Card>
  );
};

export default SidebarPushNotification;
