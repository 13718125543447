import { isObjWithValues } from "jwero-javascript-utils";

let getSelectedWabaFromIntegration = ({ metaIntegration = {} }) => {
  if (!isObjWithValues(metaIntegration)) return;
  let whatsappObj = Object.values(metaIntegration)?.find((i) =>
    i?.connected?.find((i) => i === "whatsapp")
  );
  if (whatsappObj) {
    let waba = whatsappObj?.whatsapp_info?.find(
      (i) => i?.id === whatsappObj?.selected_whatsapp_phone_id
    )?.waba;
    return waba;
  }
};

export { getSelectedWabaFromIntegration };
