import { Check, Close, Email } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SmsIcon from "@mui/icons-material/Sms";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { SecondaryLabelSmall } from "../../../../../helper/utility/Labels";
import CustomField from "../../../../../helper/utility/CustomField";
import { validateNumber } from "jwero-javascript-utils";

const iconByTyeps = {
  email: Email,
  whatsapp_template: WhatsAppIcon,
  sms: SmsIcon,
  delay: HourglassBottomIcon,
  push: NotificationsActiveIcon,
};

function SidebarTopBar({
  onTitleChange: handleBotTitle,
  title,
  titleError: titleErrorMessage,
  showTitleError: titleCheckFlag,
  handleClose,
  handleCheck,
  topBarTitle,
  type,
  titleToolTip,
  titlePlaceholder,
  titleLabel,
}) {
  let Icon = iconByTyeps[type] || Box;
  return (
    <Box sx={styles.topCard}>
      <Box sx={styles.innerCard}>
        <Box sx={styles.userHeader}>
          <Icon />
          <Typography variant="span" style={styles.headerTitle}>
            {topBarTitle}
          </Typography>
        </Box>
        <Box sx={styles.headerIconsMainDiv}>
          <Button
            size="small"
            variant="text"
            onClick={handleClose}
            sx={{ color: "text.secondary" }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // disabled={!isArrayWithValues(selectedTriggers)}
            onClick={handleCheck}
          >
            Save
          </Button>
          {/* <IconButton sx={styles.headerIconsDiv} onClick={handleClose}>
            <Close sx={styles.headerIcons} />
          </IconButton>
          <IconButton sx={styles.headerIconsDiv} onClick={handleCheck}>
            <Check sx={styles.headerIcons} />
          </IconButton> */}
        </Box>
      </Box>

      <CustomField
        type="text"
        onChange={(e) => handleBotTitle({ target: { value: e } })}
        error={titleCheckFlag}
        value={title}
        textFieldProps={{
          inputProps: {
            maxLength: 60,
          },
          InputProps: {
            endAdornment: (
              <InputAdornment>
                {validateNumber(title?.length)}/60
              </InputAdornment>
            ),
          },
          placeholder:
            titlePlaceholder || "For e.g. Send SMS after Email is failed",
        }}
        sx={{ margin: "15px 0px" }}
        label={titleLabel || "Block Title"}
        tooltip={titleToolTip || "Give it a name that makes sense to you."}
      />
      {/* <SecondaryLabelSmall */}
      {/* <TextField
        placeholder="For e.g. Send SMS after Email is failed"
        size="small"
        style={styles.titleTextField}
        onChange={handleBotTitle}
        error={titleCheckFlag}
        value={title}
        required
        inputProps={{
          maxLength: 30,
        }}
      /> */}
      {titleCheckFlag && (
        <Typography
          sx={{
            fontSize: "14px",
            color: "#E63946",
            fontWeight: 600,
            fontFamily: "Inter",
            position: "relative",
            bottom: "10px",
            left: "3px",
          }}
        >
          {titleErrorMessage}
        </Typography>
      )}
    </Box>
  );
}

export default SidebarTopBar;

const styles = {
  customCard: {
    position: "absolute",
    minWidth: "680px",
    maxWidth: "680px",
    margin: "0 auto",
    borderRadius: "5px",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    top: "20px",
    right: "20px",
    pointerEvents: "all",
    transition: "0.3s ease",
  },
  topCard: {
    display: "flex",
    flexDirection: "column",
    // height: "150px",
    backgroundColor: "#ffffff",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  innerCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "35px",
  },
  userHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
    fontWeight: 600,
    color: "#59687b",
    justifyContent: "center",
  },
  headerTitle: {
    fontSize: "15px",
    fontWeight: "530",
    marginLeft: "7px",
  },
  headerIconsMainDiv: {
    display: "flex",
    alignContents: "flex-end",
    position: "relative",
    left: "15px",
    bottom: "5px",
  },
  headerIconsDiv: {
    backgroundColor: "#dcdcdc",
    marginRight: "10px",
    borderRadius: "4px",
    height: "30px",
    width: "30px",
  },
  headerIcons: {
    color: "#475e7a",
    fontSize: "20px",
    fontWeight: "600",
  },
  titleTextField: {
    marginTop: "15px",
    marginBottom: "15px",
    borderRadius: "8px",
    fontSize: "50px",
  },
  responseSection: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#dcdcdc",
    paddingLeft: "40px",
    paddingRight: "30px",
    minHeight: "300px",
    maxHeight: "500px",
    // height: "200px",
    overflowY: "auto",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
  },
  responseDiv: {
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
  },
  iconsButton: {
    color: "black",
    fontWeight: 600,
    position: "relative",
    top: "1px",
  },
  icons: {
    fontSize: "20px",
  },
  labels: {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "10px",
    color: "#22272d",
  },
  optionsInput: {
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "5px",
    marginBottom: "10px",
    transition: "0.3s ease",
    outline: "none",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
    fontSize: "15px",
    "&:hover fieldset": {
      borderColor: "#3b82f680!important",
      cursor: "grab!important",
    },
    "&:hover": {
      cursor: "grab!important",
    },
  },
};
