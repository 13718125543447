import axios from "axios";
import {
  calculateTotalDiamondWeight,
  calculateTotalGemstoneWeight,
  fetchProducts,
  getAxiosError,
  getNetWeight,
  getTimeInSeconds,
  getToken,
  getWebsite,
  goldPurities,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  unformatServerValue,
  updateJweroBatchProducts,
  validateNumber,
} from "../../../../helper";
import { getAllLabourMasterPricing } from "../../../products/AddProduct";
import {
  diamondCut,
  diamondShapes,
  diamondTypes,
  gemstoneQualities,
  gemstoneShapes,
  gemstoneTypes,
} from "../../../settings/MasterSettings";
import { columns, products_keys_mapping } from "../utils";
import { extractNumbers, trimObjectStrings } from "../../../../utils/js";

const fetchMatchingProductWithIds = async (skus = []) => {
  let batchSize = 100;
  let batchLength = Math.ceil(skus.length);
  let page = 1;
  let skusToGet = [...skus];
  let allProducts = [];
  for await (let i of Array.from(new Array(batchLength).fill(1))) {
    let products = await fetchProducts({
      per_page: batchSize,
      page,
      sku: skusToGet?.splice(0, batchSize)?.join(),
      _fields: "id,sku",
    });
    if (isArrayWithValues(products)) allProducts = allProducts.concat(products);
  }
  return allProducts;
};

const updateProductsFromSheet = async ({
  products = [],
  sheetProducts,
  productSettings,
  masterPricing,
  taxonomies,
  allDiamondGroups,
  setProgress,
  setLoading,
  allProductsWithIds,
  setLoadingText,
  isUpdate = false,
  should_map,
}) => {
  setLoading && setLoading(true);
  let action = isUpdate ? "update" : "create";
  setLoadingText && setLoadingText("Starting syncing process...");
  let productsToUpdate = [];
  let allImages = should_map ? await getAllImages() : null;
  let matchingProductSkus = [];
  products?.forEach((product) => {
    let array = [];
    if (typeof product.upsell_ids === "string")
      array = array.concat(product.upsell_ids?.split(",")?.filter(Boolean));
    if (typeof product.cross_sell_ids === "string")
      array = array.concat(product.cross_sell_ids?.split(",")?.filter(Boolean));
    matchingProductSkus = matchingProductSkus.concat(array);
  });
  matchingProductSkus = [...new Set(matchingProductSkus)];
  let matchingProductWithIds = await fetchMatchingProductWithIds(
    matchingProductSkus
  );
  try {
    products?.forEach((product) => {
      let obj = getProductObjFromSheetRow({
        product,
        sheetProducts,
        productSettings,
        masterPricing,
        taxonomies,
        allDiamondGroups,
        allImages,
        allProducts: matchingProductWithIds,
      });

      if (isUpdate) {
        let _product = allProductsWithIds?.find((i) => i.sku === obj.sku);
        if (!_product) return null;
        obj.id = _product?.id;
      }
      productsToUpdate.push(obj);
    });
  } catch (error) {
    console.log(error);
    return { error: true };
  }
  // console.log(productsToUpdate, "productsToUpdate");
  // return { error: true };
  let batchSize = 100;
  let productsLength = products?.length;
  let totalBatchLength = Math.round(productsLength / batchSize) || 1;
  let updatedProducts = [];

  let progressLength = 100;
  let addProgressAtaTime = progressLength / totalBatchLength;

  for await (let i of Array.from(new Array(totalBatchLength).fill(1))) {
    let productsUpdatingNow = productsToUpdate?.splice(0, batchSize);
    let data = await updateJweroBatchProducts({
      payload: { [action]: productsUpdatingNow },
    });
    let productsResponse = data?.[action];

    productsResponse = productsResponse?.map((product, index) => {
      if (product.error) {
        let sku = productsUpdatingNow?.[index]?.sku;
        if (sku) product.sku = sku;
        return product;
      }

      return product;
    });

    if (isArrayWithValues(productsResponse))
      updatedProducts = updatedProducts.concat(productsResponse);

    setProgress((state) => (state += addProgressAtaTime));
    setLoadingText &&
      setLoadingText(`Updated ${updatedProducts?.length} products...`);
  }
  console.log(totalBatchLength, updatedProducts, "updatedProducts");
  setLoading && setLoading(false);
  let invalidProducts = updatedProducts.filter((i) => i.error);
  return { updatedProducts, invalidProducts };
};

const getAllImages = async () => {
  let website = await getWebsite();
  let token = await getToken();
  let array = [];
  let page = 1;
  let per_page = 1000;
  if (website && token) {
    try {
      let res = await axios({
        url: `${website}/wp-json/store/v1/media`,
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      // console.log(res, "images");
      if (res?.data?.success) {
        array = isArrayWithValues(res?.data?.data) ? res?.data?.data : [];
        // setSyncProgress(28);
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }

    return array;
  }
};

const getProductObjFromSheetRow = ({
  product = {},
  sheetProducts,
  headerRow,
  row,
  productSettings = {},
  allImages = [],
  masterPricing = {},
  taxonomies = {},
  allDiamondGroups = {},
  index,
  allProducts = [],
}) => {
  let { default_currency } = productSettings;
  let { labour_pricing } = masterPricing;
  if ((isArrayWithValues(headerRow) && isArrayWithValues(row)) || true) {
    let obj = {
      ...product,
    };

    if (product?.sale_price) obj.sale_price = product?.sale_price;
    // for (let key of Object.keys(product)?.reverse()) obj[key] = product[key];
    // let length = headerRow.length;
    // for (let i = 0; i < length; i++) {
    //   let keyObj = allSheetHeaders.find((obj) => obj.label === headerRow[i]);
    //   // console.log(keyObj);
    //   if (keyObj && row[i]) {
    //     obj[keyObj.value] = row[i];
    //   }
    // }

    if (!obj?.status) obj.status = "draft";

    for (let key in obj)
      if (typeof obj[key] === "number") obj[key] = `${obj[key]}`;

    obj = trimObjectStrings(obj);

    // set meta keys which only have string value
    let meta_keys = [
      "hallmarked",
      "huid",
      "metal_color",
      "gold_gross",
      "gold_net",
      "platinium_purity",
      "platinium_gross",
      "platinium_net",
      "silver_purity",
      "silver_gross",
      "silver_net",
      "diamond_certified",
      "diamond_from",
      "diamond_lab",
      "colorstone_from",
      "labourType",
      "labour_from",
      "custom_wastage_from",
      "custom_tags",
      "product_width",
      "product_thickness",
      "product_height",
      "shipping_days",
      "_woodmart_product_video",
      "minimum_labour",
      "minimum_making",
      "per_gram",
      "barcode",
      "diamond_certificate_id",
      "disable_customization",
    ];
    let meta_data = [];

    if (obj.minimum_making) obj.minimum_labour = obj.minimum_making;
    if (obj.diamond_from === "individual") obj.diamond_from = "customize";
    obj.backorders =
      typeof obj.backorders === "boolean"
        ? obj.backorders
          ? "yes"
          : "no"
        : obj.backorders;

    if (typeof obj.huid === "string")
      obj.huid = obj.huid?.split(",")?.map((i) => i?.trim());

    if (typeof obj.tags === "string") obj.tag = obj.tags?.split(",");
    if (typeof obj.tag === "string") obj.tag = obj.tag?.split(",");

    if (obj.product_width)
      obj.product_width = extractNumbers(obj.product_width);
    if (obj.product_thickness)
      obj.product_thickness = extractNumbers(obj.product_thickness);
    if (obj.product_height)
      obj.product_height = extractNumbers(obj.product_height);

    for (let i in meta_keys)
      if (obj.hasOwnProperty([meta_keys[i]])) {
        meta_data.push({ key: meta_keys[i], value: obj[meta_keys[i]] || "" });
        delete obj[meta_keys[i]];
      }

    meta_data.push({ key: "last_price_update", value: getTimeInSeconds() });
    if (obj?.metal_types) {
      let array = (
        typeof obj?.metal_types === "string"
          ? obj?.metal_types?.split()
          : obj?.metal_types
      )
        // ?.split(",")
        ?.map((i) => i?.toLowerCase()?.trim());
      if (isArrayWithValues(array)) {
        meta_data.push({ key: "metal_types", value: array });
      }
    }

    // if (obj.upsell_ids) {
    //   let upsell_ids = [];
    //   let skus = isArrayWithValues(obj.upsell_ids)
    //     ? obj.upsell_ids
    //     : obj.upsell_ids?.split(",");
    //   //   ?.split(",");
    //   if (isArrayWithValues(skus)) {
    //     for (let sku of skus) {
    //       let obj = allProducts.find(
    //         (i) => i.sku?.toLowerCase() == sku?.toLowerCase()
    //       );
    //       if (obj?.id) upsell_ids.push(obj.id);
    //     }
    //   }
    //   if (isArrayWithValues(upsell_ids)) obj.upsell_ids = upsell_ids;
    //   else obj.upsell_ids = skus?.join();
    // }
    // if (obj.cross_sell_ids) {
    //   let cross_sell_ids = [];

    //   let skus = isArrayWithValues(obj.cross_sell_ids)
    //     ? obj.cross_sell_ids
    //     : obj.cross_sell_ids?.split(",");
    //   //   ?.split(",");
    //   if (isArrayWithValues(skus)) {
    //     for (let sku of skus) {
    //       let obj = allProducts.find(
    //         (i) => i.sku?.toLowerCase() == sku?.toLowerCase()
    //       );
    //       if (obj?.id) cross_sell_ids.push(obj.id);
    //     }
    //   }
    //   if (isArrayWithValues(cross_sell_ids))
    //     obj.cross_sell_ids = cross_sell_ids;
    //   else obj.cross_sell_ids = skus?.join();
    // }
    if (obj.wastage_percent) {
      meta_data.push({
        key: "wastage_percent",
        value: obj.wastage_percent?.replaceAll("%", ""),
      });
      delete obj.wastage_percent;
    }
    meta_data.push({
      key: "manual_price",
      value: obj.manual_price?.replace(/\D/g, "") || "",
    });
    delete obj.manual_price;
    // if (obj.manual_price) {
    // }

    let seo = {
      seo_title: obj.seo_title || "",
      seo_description: obj.seo_description || "",
      seo_keywords: obj.seo_keywords
        ? obj.seo_keywords.split(",").filter(Boolean)
        : "",
    };
    meta_data.push({ key: "seo", value: seo });

    meta_data.push({
      key: "product_shipping",
      value: {
        min: obj.min_shipping_days || "",
        max: obj.max_shipping_days || "",
      },
    });
    let attributes = [];
    if (obj.gender) {
      let genders = isArrayWithValues(obj.gender)
        ? obj.gender
        : typeof obj.gender === "string"
        ? obj.gender?.split(",")
        : [];
      let allGenders = genders
        ?.map((i) => {
          let obj = taxonomies?.gender?.find(
            (j) => j.label === i || j.value === i || j.slug === i
          );
          return typeof obj === "object"
            ? obj?.value
            : typeof obj == "string"
            ? obj
            : "";
        })
        ?.filter(Boolean);
      attributes.push({
        name: "Gender",
        visible: true,
        options: allGenders,
      });
    }

    // console.log(isArrayWithValues(obj.ocassions), "isOcations");
    if (typeof obj.ocassions === "string" && obj.ocassions) {
      obj.ocassions = obj.ocassions?.split(",")?.map((item) => item?.trim());
    }

    if (isArrayWithValues(obj.ocassions)) {
      let allOcassions = obj.ocassions?.map((i) => {
        return taxonomies?.ocassions?.find(
          (j) => j.label === i || j.value === i || j.slug === i
        )?.value;
      });
      // isDev()
      //   ? attributes.push({
      //       id: 6,
      //       name: "Ocassions",
      //       visible: true,
      //       options: allOcassions,
      //     })
      //   :
      attributes.push({
        // id: 6,
        name: "Ocassions",
        visible: true,
        options: allOcassions,
      });
    }

    obj.attributes = attributes;

    if (obj.discount_on && obj.discount_type && obj.discount_value) {
      let discountsArray = [];
      obj.discount_on
        .split(",")
        .map((i) => discountsArray.push({ discount_on: i }));
      obj.discount_type.split(",").map((value, index) => {
        if (discountsArray[index])
          discountsArray[index] = {
            ...discountsArray[index],
            discount_type: value,
          };
      });
      obj.discount_value.split(",").map((value, index) => {
        if (discountsArray[index])
          discountsArray[index] = {
            ...discountsArray[index],
            discount_value: value,
          };
      });
      if (isArrayWithValues(discountsArray)) {
        let discountsObj = {};
        for (let obj of discountsArray) {
          let { discount_on, discount_type, discount_value } = obj;
          if (discount_on && discount_type && discount_value)
            discountsObj[discount_on] = {
              type: discount_type,
              value: discount_value,
            };
        }
        meta_data.push({ key: "custom_discounts", value: discountsObj });
      }
    }

    if (obj.gold_kt) {
      let gold_kt = obj.gold_kt;
      let purity = obj.gold_kt
        .toLowerCase()
        .replace("kt", "")
        .replace("k", "")
        .replace("t", "");
      let purityObj = goldPurities.find((i) => i.label == purity);
      if (purityObj) gold_kt = purityObj.value;
      meta_data.push({ key: "gold_kt", value: gold_kt });
    }

    if (obj.subcatsetting)
      meta_data.push({
        key: "subcatsetting",
        value: [obj.subcatsetting],
      });

    let diamond = {};
    // converting sheet diamond data to meta diamond obj

    //weight should be on top
    if (typeof obj.diamond_weight === "string" && obj.diamond_weight) {
      let { diamond_weight } = obj;
      let array = diamond_weight
        ?.trim()
        ?.split(",")
        .map((i) => i.trim())
        .filter(Boolean);
      array.map((item, index) => {
        if (!diamond[`${index + 1}`]) diamond[`${index + 1}`] = {};
        diamond[`${index + 1}`].diamond_weight = item;
      });
    }
    if (obj?.diamond_type) {
      let { diamond_type } = obj;
      let array = [];
      if (isArrayWithValues(diamond_type))
        array = diamond_type?.map((i) => i.trim())?.filter(Boolean);
      else if (typeof diamond_type === "string")
        array = diamond_type
          ?.trim()
          ?.toLowerCase()
          ?.split(",")
          ?.map((i) => i.trim())
          ?.filter(Boolean);

      Array.from(new Array(Object.keys(diamond).length)).map((a, i) => {
        if (!array[i] && array[0]) array[i] = array[0];
      });
      array.map((item, index) => {
        if (!diamond[`${index + 1}`]) diamond[`${index + 1}`] = {};
        let value = diamondTypes.find((i) => i.label === item);
        diamond[`${index + 1}`].diamond_type = value ? value.value : item;
      });
    }
    if (typeof obj.diamond_quality === "string" && obj.diamond_quality) {
      let { diamond_quality } = obj;
      let array = diamond_quality
        ?.trim()
        ?.split(",")
        .map((i) => i.trim())
        .filter(Boolean);

      Array.from(new Array(Object.keys(diamond).length)).map((a, i) => {
        if (!array[i] && array[0]) array[i] = array[0];
      });
      array.map((item, index) => {
        if (!diamond[`${index + 1}`]) diamond[`${index + 1}`] = {};
        diamond[`${index + 1}`].diamond_quality = item;
      });
    }
    if (typeof obj.diamond_shape === "string" && obj.diamond_shape) {
      let { diamond_shape } = obj;
      let array = diamond_shape
        ?.trim()
        ?.toLowerCase()
        ?.split(",")
        .map((i) => i.trim())
        .filter(Boolean);

      Array.from(new Array(Object.keys(diamond).length)).map((a, i) => {
        if (!array[i] && array[0]) array[i] = array[0];
      });
      array.map((item, index) => {
        if (!diamond[`${index + 1}`]) diamond[`${index + 1}`] = {};
        let value = diamondShapes.find((i) => i.label === item);
        diamond[`${index + 1}`].diamond_shape = value ? value.value : item;
      });
    }
    // if (typeof obj.diamond_sieve === "string" && obj.diamond_sieve) {
    //   let { diamond_sieve } = obj;
    //   let array = diamond_sieve?.trim()?.split(",").map((i) => i.trim());
    //   array.map((item, index) => {
    //     if (!diamond[`${index + 1}`]) diamond[`${index + 1}`] = {};
    //     diamond[`${index + 1}`].diamond_sieve = item;
    //   });
    // }
    if (typeof obj.diamond_cut === "string" && obj.diamond_cut) {
      let { diamond_cut } = obj;
      let array = diamond_cut
        ?.trim()
        ?.toLowerCase()
        ?.split(",")
        .map((i) => unformatServerValue(i.trim()));
      Array.from(new Array(Object.keys(diamond).length)).map((a, i) => {
        if (!array[i] && array[0]) array[i] = array[0];
      });
      array.map((item, index) => {
        if (!diamond[`${index + 1}`]) diamond[`${index + 1}`] = {};
        let value = diamondCut.find((i) => i.label === item);
        diamond[`${index + 1}`].diamond_cut = value ? value.value : item;
      });
    }
    if (typeof obj.diamond_pieces === "string" && obj.diamond_pieces) {
      let { diamond_pieces, diamond_weight } = obj;
      let array = diamond_pieces
        ?.trim()
        ?.split(",")
        .map((i) => validateNumber(i.trim()));
      let diamondArray = diamond_weight
        ?.trim()
        ?.split(",")
        .map((i) => i.trim())
        .filter(Boolean);
      let totalDiamondWithWeights = diamondArray?.length;
      function removeExtraTotalFromPieces(a, b) {
        if (a?.length > b?.length) {
          let aFirst = a[0];
          let bOther = a.slice(1, a?.length);
          let otherTotal = 0;
          bOther.map((i) => (otherTotal += parseInt(i)));
          if (aFirst === otherTotal) return a?.slice(1, a?.length);
          return a;
        }
        return a;
      }
      if (array.length > totalDiamondWithWeights) {
        array = removeExtraTotalFromPieces(
          array,
          Object.values(diamond)
            .map((i) => validateNumber(i?.diamond_weight))
            ?.filter(Boolean)
        );
      }

      array.map((item, index) => {
        if (!diamond[`${index + 1}`]) diamond[`${index + 1}`] = {};
        diamond[`${index + 1}`].diamond_pieces = item;
      });
    }
    if (typeof obj.diamond_rate === "string" && obj.diamond_rate) {
      let { diamond_rate } = obj;
      let array = diamond_rate
        ?.trim()
        ?.split(",")
        .map((i) => i.trim());
      array.map((item, index) => {
        if (!diamond[`${index + 1}`]) diamond[`${index + 1}`] = {};
        diamond[`${index + 1}`].diamond_rate = item;
      });
    }
    if (isObjWithValues(diamond) && isObjWithValues(allDiamondGroups)) {
      let diamondGroups = Object.values(allDiamondGroups);
      let index = 1;
      for (let diamondObj of Object.values(diamond)) {
        let { diamond_shape, diamond_pieces, diamond_weight } = diamondObj;
        let netWeight = Number(diamond_weight) / Number(diamond_pieces);
        let obj = diamondGroups.find(
          (i) =>
            i.shape === diamond_shape &&
            netWeight > Number(i.from) &&
            netWeight < Number(i.to)
        );
        if (!obj)
          obj = diamondGroups.find((i) => {
            let netWeightFixed2 = Number(netWeight).toFixed(2);
            return (
              i.shape === diamond_shape &&
              netWeightFixed2 >= Number(i.from) &&
              netWeightFixed2 <= Number(i.to)
            );
          });
        if (obj) diamond[index].diamond_sieve = obj.id;
        index++;
      }
    }
    diamond = {
      ...Object.values(diamond)?.filter((i) => i?.diamond_weight),
    };
    meta_data.push({
      key: "diamond",
      value: diamond,
    });
    if (isObjWithValues(meta_data.find((i) => i.key === "diamond"))) {
      let obj = meta_data.find((i) => i.key === "diamond");
      let totalWeight = calculateTotalDiamondWeight(obj);
      if (totalWeight)
        meta_data.push({
          key: "total_diamond_weight",
          value: totalWeight,
        });
    }

    let colorstone_details = {};

    let colorstone_quality_length = obj?.colorstone_quality
      ?.split(",")
      ?.map((i) => i?.trim())?.length;

    let colorstone_shape_length = obj?.colorstone_shape
      ?.split(",")
      ?.map((i) => i?.trim())?.length;

    let colorstone_type_length = obj?.colorstone_type
      ?.split(",")
      ?.map((i) => i?.trim())?.length;

    let colorstone_size_length = obj?.colorstone_size
      ?.split(",")
      ?.map((i) => i?.trim())?.length;

    function findMax(value1, value2, value3, value4) {
      // Filter out null and undefined values
      const values = [value1, value2, value3, value4].filter(
        (val) => val !== null && val !== undefined
      );

      // Check if there are any valid values
      if (values.length === 0) {
        // Handle the case where all values are null or undefined
        console.error("All values are null or undefined.");
        return null; // or undefined, or any other default value as needed
      }

      // Find the maximum value using the spread operator and Math.max
      const max = Math.max(...values);
      return max;
    }

    function createObjectWithEmptyObjects(maxValue) {
      const resultObject = {};

      // Ensure maxValue is a valid number
      if (typeof maxValue !== "number" || isNaN(maxValue)) {
        console.error("Invalid max value.");
        return resultObject;
      }

      // Generate the object with empty objects
      for (let i = 1; i <= maxValue; i++) {
        resultObject[i] = {};
      }

      return resultObject;
    }

    const maxResult = findMax(
      colorstone_quality_length,
      colorstone_shape_length,
      colorstone_type_length,
      colorstone_size_length
    );

    colorstone_details = createObjectWithEmptyObjects(maxResult);

    console.log("colorstone_details", colorstone_details);

    if (typeof obj.colorstone_weight === "string" && obj.colorstone_weight) {
      //weight should be on top
      let { colorstone_weight } = obj;
      let array = colorstone_weight.split(",").map((i) => i.trim());
      array.map((item, index) => {
        if (!colorstone_details[`${index + 1}`])
          colorstone_details[`${index + 1}`] = {};
        colorstone_details[`${index + 1}`].colorstone_weight = item;
      });
    }

    if (typeof obj.colorstone_quality === "string" && obj.colorstone_quality) {
      let { colorstone_quality } = obj;
      let array = colorstone_quality.split(",").map((i) => i.trim());
      Array.from(new Array(Object.keys(colorstone_details).length)).map(
        (a, i) => {
          if (!array[i] && array[0]) array[i] = array[0];
        }
      );
      array.map((item, index) => {
        if (!colorstone_details[`${index + 1}`])
          colorstone_details[`${index + 1}`] = {};
        let value = gemstoneQualities.find((i) => i.label === item);
        colorstone_details[`${index + 1}`].colorstone_quality = value
          ? value.value
          : item;
      });
    }

    if (typeof obj.colorstone_shape === "string" && obj.colorstone_shape) {
      let { colorstone_shape } = obj;
      let array = colorstone_shape.split(",").map((i) => i.trim());
      Array.from(new Array(Object.keys(colorstone_details).length)).map(
        (a, i) => {
          if (!array[i] && array[0]) array[i] = array[0];
        }
      );
      array.map((item, index) => {
        if (!colorstone_details[`${index + 1}`])
          colorstone_details[`${index + 1}`] = {};
        let value = gemstoneShapes.find((i) => i.label === item);
        colorstone_details[`${index + 1}`].colorstone_shape = value
          ? value.value
          : item;
      });
    }

    if (typeof obj.colorstone_type === "string" && obj.colorstone_type) {
      let { colorstone_type } = obj;
      let array = colorstone_type.split(",").map((i) => i.trim());
      Array.from(new Array(Object.keys(colorstone_details).length)).map(
        (a, i) => {
          if (!array[i] && array[0]) array[i] = array[0];
        }
      );
      array.map((item, index) => {
        if (!colorstone_details[`${index + 1}`])
          colorstone_details[`${index + 1}`] = {};
        let value = gemstoneTypes.find((i) => i.label === item);
        colorstone_details[`${index + 1}`].colorstone_type = value
          ? value.value
          : item;
      });
    }

    if (typeof obj.colorstone_pieces === "string" && obj.colorstone_pieces) {
      let { colorstone_pieces } = obj;
      let array = colorstone_pieces.split(",").map((i) => i.trim());
      array.map((item, index) => {
        if (!colorstone_details[`${index + 1}`])
          colorstone_details[`${index + 1}`] = {};
        colorstone_details[`${index + 1}`].colorstone_pieces = item;
      });
    }

    if (typeof obj.colorstone_size === "string" && obj.colorstone_size) {
      let { colorstone_size } = obj;
      let array = colorstone_size.split(",").map((i) => i.trim());
      Array.from(new Array(Object.keys(colorstone_details).length)).map(
        (a, i) => {
          if (!array[i] && array[0]) array[i] = array[0];
        }
      );
      array.map((item, index) => {
        if (!colorstone_details[`${index + 1}`])
          colorstone_details[`${index + 1}`] = {};
        colorstone_details[`${index + 1}`].colorstone_size = item;
      });
    }

    if (typeof obj.colorstone_color === "string" && obj.colorstone_color) {
      let { colorstone_color } = obj;
      let array = colorstone_color.split(",").map((i) => i.trim());
      Array.from(new Array(Object.keys(colorstone_details).length)).map(
        (a, i) => {
          if (!array[i] && array[0]) array[i] = array[0];
        }
      );
      array.map((item, index) => {
        if (!colorstone_details[`${index + 1}`])
          colorstone_details[`${index + 1}`] = {};
        colorstone_details[`${index + 1}`].colorstone_color = item;
      });
    }

    if (typeof obj.colorstone_rate === "string" && obj.colorstone_rate) {
      let { colorstone_rate } = obj;
      let array = colorstone_rate.split(",").map((i) => i.trim());
      array.map((item, index) => {
        if (!colorstone_details[`${index + 1}`])
          colorstone_details[`${index + 1}`] = {};
        colorstone_details[`${index + 1}`].colorstone_rate = item;
      });
    }

    // Additional handling for columns No of Lines, Length, Closure types, Pearls (Type, Size, Pieces, Weight)...
    if (typeof obj.no_of_lines === "string" && obj.no_of_lines) {
      let { no_of_lines } = obj;
      let array = no_of_lines.split(",").map((i) => i.trim());
      array.map((item, index) => {
        if (!colorstone_details[`${index + 1}`])
          colorstone_details[`${index + 1}`] = {};
        colorstone_details[`${index + 1}`].no_of_lines = item;
      });
    }
    if (typeof obj?.["length"] === "string" && obj?.["length"]) {
      let { length } = obj;
      let array = length.split(",").map((i) => i.trim());
      array.map((item, index) => {
        if (!colorstone_details[`${index + 1}`])
          colorstone_details[`${index + 1}`] = {};
        colorstone_details[`${index + 1}`]["length"] = item;
      });
    }
    if (typeof obj.closure_type === "string" && obj.closure_type) {
      let { closure_type } = obj;
      let array = closure_type.split(",").map((i) => i.trim());
      array.map((item, index) => {
        if (!colorstone_details[`${index + 1}`])
          colorstone_details[`${index + 1}`] = {};
        colorstone_details[`${index + 1}`].closure_type = item;
      });
    }

    // Pearl
    const gemstonesCount =
      validateNumber(Object?.keys(colorstone_details)?.length) + 1;
    if (typeof obj.pearl_type === "string" && obj.pearl_type) {
      let { pearl_type } = obj;
      let array = pearl_type.split(",").map((i) => i.trim());
      array.map((item, index) => {
        if (!colorstone_details[`${gemstonesCount + index}`])
          colorstone_details[`${gemstonesCount + index}`] = {};
        colorstone_details[`${gemstonesCount + index}`].pearl_type = item;
        colorstone_details[`${gemstonesCount + index}`].type = "pearl";
      });
    }
    if (typeof obj.pearl_setting_type === "string" && obj.pearl_setting_type) {
      let { pearl_setting_type } = obj;
      let array = pearl_setting_type.split(",").map((i) => i.trim());
      array.map((item, index) => {
        if (!colorstone_details[`${gemstonesCount + index}`])
          colorstone_details[`${gemstonesCount + index}`] = {};
        colorstone_details[`${gemstonesCount + index}`].pearl_setting_type =
          item;
        colorstone_details[`${gemstonesCount + index}`].type = "pearl";
      });
    }
    if (typeof obj.pearl_size === "string" && obj.pearl_size) {
      let { pearl_size } = obj;
      let array = pearl_size.split(",").map((i) => i.trim());
      array.map((item, index) => {
        if (!colorstone_details[`${gemstonesCount + index}`])
          colorstone_details[`${gemstonesCount + index}`] = {};
        colorstone_details[`${gemstonesCount + index}`].pearl_size = item;
        colorstone_details[`${gemstonesCount + index}`].type = "pearl";
      });
    }
    if (typeof obj.pearl_pieces === "string" && obj.pearl_pieces) {
      let { pearl_pieces } = obj;
      let array = pearl_pieces.split(",").map((i) => i.trim());
      array.map((item, index) => {
        if (!colorstone_details[`${gemstonesCount + index}`])
          colorstone_details[`${gemstonesCount + index}`] = {};
        colorstone_details[`${gemstonesCount + index}`].pearl_pieces = item;
        colorstone_details[`${gemstonesCount + index}`].type = "pearl";
      });
    }
    if (typeof obj.pearl_weight === "string" && obj.pearl_weight) {
      let { pearl_weight } = obj;
      let array = pearl_weight.split(",").map((i) => i.trim());
      array.map((item, index) => {
        if (!colorstone_details[`${gemstonesCount + index}`])
          colorstone_details[`${gemstonesCount + index}`] = {};
        colorstone_details[`${gemstonesCount + index}`].pearl_weight = item;
        colorstone_details[`${gemstonesCount + index}`].type = "pearl";
      });
    }

    meta_data.push({
      key: "colorstone_details",
      value: colorstone_details,
    });

    if (
      isObjWithValues(meta_data.find((i) => i.key === "colorstone_details"))
    ) {
      let obj = meta_data.find((i) => i.key === "colorstone_details");
      let totalWeight = calculateTotalGemstoneWeight(obj);
      if (totalWeight)
        meta_data.push({
          key: "total_gemstone_weight",
          value: totalWeight,
        });
    }

    let extra_charges = {};
    if (obj.extra_charge_label || obj.extra_charge_value) {
      let { extra_charge_label, extra_charge_value } = obj;
      let label = isArrayWithValues(extra_charge_label)
        ? extra_charge_label
        : extra_charge_label
        ? extra_charge_label?.split(",")
        : [];
      let value = isArrayWithValues(extra_charge_value)
        ? extra_charge_value
        : extra_charge_value
        ? `${extra_charge_value}`?.split(",")
        : [];

      let array = label?.map((i) => i.trim());
      let valuesArray = value?.map((i) => i.trim());

      let preDefinedValues = [];
      if (!isArrayWithValues(valuesArray)) {
        let { additional_pricing } = masterPricing;
        let index = 0;
        for (let value of array) {
          if (additional_pricing?.[value]?.[default_currency]) {
            preDefinedValues.push(
              additional_pricing?.[value]?.[default_currency]
            );
          }
          index++;
        }
        if (preDefinedValues.length === array.length)
          valuesArray = preDefinedValues;
      }

      array.map((label, index) => {
        extra_charges[`${index + 1}`] = {
          extra_charge_label: label,
          extra_charge_value: valuesArray[index] || 0,
        };
      });
    }
    if (isObjWithValues(extra_charges))
      meta_data.push({
        key: "extra_charges",
        value: extra_charges,
      });

    meta_data.push({ key: "labour", value: "" });
    obj.meta_data = meta_data;
    let { collections, categories } = taxonomies || {};
    obj.collections =
      typeof obj.collections === "string"
        ? obj.collections?.split(",")
        : obj.collections;

    if (isArrayWithValues(collections) && obj.collections) {
      let array = [];
      let productCollections = obj.collections;
      //  && obj.collections.split(",")?.filter(Boolean);
      if (
        isArrayWithValues(collections) &&
        isArrayWithValues(productCollections)
      ) {
        for (let i in productCollections) {
          let obj = collections.find(
            (obj) => obj.label === productCollections[i]
          );
          if (obj && obj.label) array.push(obj.label);
          else array.push(productCollections[i]);
        }
      }
      obj.collections = array;
      // obj.collections = ["Gold Jewellery"];
    }

    if (obj?.categories || obj?.category) {
      let array = [];
      let productCategories = [];
      if (isArrayWithValues(obj?.categories))
        productCategories = obj?.categories;
      else if (typeof obj?.categories === "string")
        productCategories = obj?.categories?.split(",")?.filter(Boolean);
      else if (isArrayWithValues(obj?.category))
        productCategories = obj?.category;
      else if (typeof obj?.category === "string")
        productCategories = obj?.category?.split(",")?.filter(Boolean);

      if (isArrayWithValues(productCategories))
        for (let i in productCategories) {
          let obj = categories.find(
            (j) =>
              j.label === productCategories[i] ||
              j.value === productCategories[i]
          );
          if (obj && obj.label) array.push(obj.label);
          else array.push(productCategories[i]);
        }
      obj.category = array;
      obj.categories = array;

      if (isArrayWithValues(array))
        obj?.attributes?.push({
          // id: 2,
          name: "Categories",
          visible: true,
          options: array,
        });
    }

    if (isArrayWithValues(taxonomies?.["sub-categories"]) && obj?.subcategory) {
      let array = [];
      let productSubCategories =
        typeof obj?.subcategory === "string"
          ? obj?.subcategory?.split(",").filter(Boolean)
          : obj?.subcategory;
      if (isArrayWithValues(productSubCategories))
        for (let i in productSubCategories) {
          let obj = taxonomies?.["sub-categories"].find(
            (j) =>
              j.label === productSubCategories[i] ||
              j.value === productSubCategories[i]
          );
          if (obj && obj.label) array.push(obj.label);
          else array.push(productSubCategories[i]);
        }
      obj.subcategory = array;
    }
    if (
      isArrayWithValues(obj.collections) &&
      isArrayWithValues(obj.category) &&
      default_currency &&
      labour_pricing
    ) {
      let array = getAllLabourMasterPricing(
        labour_pricing[default_currency],
        obj.collections,
        obj.category,
        obj.subcategory
      );
      if (isArrayWithValues(array))
        obj.meta_data.push({
          key: "labour_pricing_title",
          value: array[0].id,
        });
    }
    if (
      isArrayWithValues(allImages) &&
      obj.sku &&
      !obj._woodmart_product_video
    ) {
      for (let image of allImages) {
        let { title, source_url, mime_type } = image;
        if (title?.rendered) {
          let sku = title?.rendered;
          if (sku.includes(".")) sku = sku?.split(".")?.[0];
          if (sku.includes("_")) sku = sku?.split("_")?.[0];
          if (sku.includes("-")) sku = sku?.split("-")?.[0];
          // if (
          //   sku?.toLowerCase()?.includes(obj.sku?.toLowerCase()) ||
          //   obj.sku?.toLowerCase()?.includes(sku?.toLowerCase())
          // )
          if (
            sku?.toLowerCase() == obj.sku?.toLowerCase() &&
            sku &&
            source_url &&
            mime_type?.includes("video")
          ) {
            meta_data.push({
              key: "_woodmart_product_video",
              value: source_url,
            });
            break;
          }
          // allImages.map(i => {
          //   let {}
          // })
          // images.map((name) => {
          //   let imageObj = allImages.find((i) => {
          //     let { source_url } = i;
          //     let imageName =
          //       typeof source_url === "string"
          //         ? source_url.replace(/^.*[\\\/]/, "").replace(/\.[^/.]+$/, "")
          //         : source_url;
          //     return imageName.trim() === name.trim();
          //   });
          //   if (imageObj)
          //     array.push({ src: imageObj.source_url, id: imageObj.id });
          // });
        }
      }
    }

    if (obj?.images && isArrayWithValues(allImages)) {
      let { images } = obj;
      let imageString = `${images}`;
      images = imageString.split(",");
      // images = imageString.split(" ");
      let array = [];
      images.map((name) => {
        let imageObj = allImages.find((i) => {
          let { source_url, mime_type } = i;
          if (!mime_type?.includes("video")) {
            let imageName =
              typeof source_url === "string"
                ? source_url.replace(/^.*[\\\/]/, "").replace(/\.[^/.]+$/, "")
                : source_url;

            if (imageName.includes(".")) imageName = imageName?.split(".")?.[0];
            // if (imageName.includes("_")) imageName = imageName?.split("_")?.[0];
            return imageName.trim() === name.trim();
          }
        });
        if (imageObj) array.push({ src: imageObj.source_url, id: imageObj.id });
      });
      if (isArrayWithValues(array)) obj.images = array;
      // images = images.map((i) => ({ src: i }));
      // obj.images = images;
    }
    if (!obj.images && obj.sku && isArrayWithValues(allImages)) {
      let { sku } = obj;
      let array = [];
      for (let image of allImages) {
        let { id, source_url, title, mime_type } = image;
        let name = title?.rendered;

        name = name?.split("_")?.[0];
        name = name?.split(".")?.[0];
        if (
          sku?.toLowerCase() === name?.toLowerCase() &&
          !mime_type?.includes("video")
        ) {
          array.push({
            id,
            src: source_url,
            name: title?.rendered,
          });
        }
      }
      if (isArrayWithValues(array)) {
        array = array?.sort(
          (a, b) =>
            Number(a?.name?.split("_")?.[1]?.split("-")?.[0]) -
            Number(b?.name?.split("_")?.[1]?.split("-")?.[0])
        );
      }
      if (isArrayWithValues(array)) obj.images = array;
      // if (isArrayWithValues(array) && !obj.status) obj.images = array;
    }
    if (!isArrayWithValues(obj.images)) delete obj.images;
    if (obj.stock_status === "instock") obj.manage_stock = true;
    else obj.manage_stock = false;
    if (obj.stock_status === "instock" && !obj.stock_quantity)
      obj.stock_quantity = 1;

    if (obj.description) obj.short_description = obj.description;
    if (obj.stock_quantity)
      obj.stock_quantity = validateNumber(obj.stock_quantity);

    if (productSettings.net_weight === "auto") {
      let product = sheetProducts?.find((i) => i.sku == obj.sku);
      let productWithMeta = { ...obj };
      let { meta_data } = obj;
      if (isArrayWithValues(meta_data)) {
        for (let meta of meta_data) {
          productWithMeta[meta?.key] = meta.value;
        }
      }
      if (product?.gold_gross)
        obj.gold_net = getNetWeight(
          validateNumber(product.gold_gross),
          productWithMeta
        );
      if (product?.silver_gross)
        obj.silver_net = getNetWeight(
          validateNumber(product.silver_gross),
          productWithMeta
        );
      if (product?.platinium_gross)
        obj.platinium_net = getNetWeight(
          validateNumber(product.platinium_gross),
          productWithMeta
        );
    }

    return obj;
  }
};

function mapData(dataArray, mappingArray) {
  const result = [];

  for (const item of dataArray) {
    const mappedItem = {};
    // console.log(item);
    for (const mapping of mappingArray) {
      const sourceKey = mapping.value;
      const targetKey = mapping.label;
      // console.log(item[targetKey], "<<>>");
      // if (item.hasOwnProperty(sourceKey)) {
      //   mappedItem[targetKey] = item[sourceKey];
      // }
      // console.log(item[targetKey], targetKey);
      if (item[targetKey]) {
        mappedItem[sourceKey] = item[targetKey];
      }
    }

    result.push(mappedItem);
  }

  return result;
}
const onCreateProduct = ({ data }) => {
  // log
  let arr = Object.keys(products_keys_mapping)?.map((key) => ({
    label: products_keys_mapping?.[key]?.column_name,
    value: key,
  }));

  const mappedData = mapData(data, arr);
  return mappedData;
};

export { updateProductsFromSheet, getProductObjFromSheetRow, onCreateProduct };
