import axios from "axios";
import {
  getAxiosError,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  validateNumber,
} from "../../../../helper";

export { getTanikaProductFromOrnate, updateTanikaBulkProducts };

const getImages = async (paths) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/channel/ornate?endpoint=/get_images`,
        headers: { Authorization: `Basic ${token}` },
        data: {
          payload: paths,
        },
        method: "POST",
      });
      console.log(data);
      return data;
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  }
};

const updateTanikaBulkProducts = async (website, token, products) => {
  try {
    let { data } = await axios({
      url: `${website}/wp-json/wc/v3/products/batch`,
      headers: { Authorization: `Basic ${token}` },
      method: "POST",
      data: { update: products },
    });
    return data;
  } catch (error) {
    return false;
  }
};

const getTanikaProductFromOrnate = async (id, ornateProducts = {}, options) => {
  let { LabelDetail, StuddedDetail } = ornateProducts;
  let metalPurity, productGoldKT;
  let ornate_taxonomy = options?.ornate_integration?.ornate_taxonomy;
  let ornate_settings = options?.ornate_integration?.ornate_settings;
  let attributesData = options?.attributes;
  let excludedFields = [
    // "gender",
    // "tags",
    // "categories",
    // "collections",
    // "ocassions",
    // "title",
  ];
  if (ornate_settings && isArrayWithValues(ornate_settings.autosync_disable))
    excludedFields = ornate_settings.autosync_disable;
  let sku_from = "";
  if (isObjWithValues(ornate_taxonomy)) sku_from = ornate_taxonomy.sku_from;
  let productObj = {
    // status: options.status ? "publish" : "draft",
    stock_status: "instock",
    stock_quantity: 1,
    manage_stock: true,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
  };
  let metaObj = {};
  let uniqueLabelToSearch = "LabelNo";
  if (sku_from == "label") uniqueLabelToSearch = "LabelNo";
  if (sku_from == "barcode") uniqueLabelToSearch = "BarcodeNo";
  let labelObj = LabelDetail.find((i) => i[uniqueLabelToSearch] == id);
  if (!labelObj) return false;
  let diamondArray = StuddedDetail.filter(
    (i) =>
      i.UniqueLabelId == labelObj.UniqueLabelID &&
      i.IsBase === "N" &&
      i.MetalName === "DIAMOND"
  );
  let stoneArray = StuddedDetail.filter(
    (i) =>
      i.UniqueLabelId == labelObj.UniqueLabelID &&
      i.IsBase === "N" &&
      i.MetalName === "STONE"
  );
  let goldObj = StuddedDetail.find(
    (i) =>
      i.UniqueLabelId == labelObj.UniqueLabelID &&
      // i.IsBase === "N" &&
      // i.TradOrOtChg === "0" &&
      i.MetalName === "GOLD"
  );
  let silverObj = StuddedDetail.find(
    (i) =>
      i.UniqueLabelId == labelObj.UniqueLabelID &&
      // i.IsBase === "N" &&
      // i.TradOrOtChg === "0" &&
      i.MetalName === "SILVER"
  );
  let platinumObj = StuddedDetail.find(
    (i) =>
      i.UniqueLabelId == labelObj.UniqueLabelID &&
      // i.IsBase === "N" &&
      // i.TradOrOtChg === "0" &&
      i.MetalName === "PLATINUM"
  );
  let metalNames = ["DIAMOND", "STONE", "GOLD", "SILVER", "PLATINUM"];
  let brandObj = StuddedDetail.find(
    (i) =>
      i.UniqueLabelId == labelObj.UniqueLabelID &&
      // i.IsBase === "N" &&
      // i.TradOrOtChg === "0" &&
      // i.MetalName === "BRANDED"
      i.MetalName &&
      !metalNames.includes(i.MetalName)
  );

  if (!goldObj) goldObj = brandObj;
  if (brandObj) metaObj.manual_price = labelObj?.SalesPrice;

  let extra_charges = StuddedDetail.map((i) => {
    if (
      i.UniqueLabelId == labelObj.UniqueLabelID &&
      i.IsBase === "N" &&
      !i.MetalName
    )
      return {
        // extra_charge_label: i.StyleName,
        extra_charge_label: `${i.StyleName} (${i?.NetWt || ""} ${
          i?.SalesRate ? ` x @${i.SalesRate}` : ""
        })`,
        extra_charge_value: i.SalesAmount,
      };
    return false;
  }).filter(Boolean);

  if (isArrayWithValues(extra_charges))
    metaObj.extra_charges = { ...extra_charges };

  if (isObjWithValues(labelObj)) {
    let {
      LabelNo,
      BarcodeNo,
      LabourOn,
      LabourRate,
      SalWastPer,
      LabourAmt,
      ItemName,
      LabourPer,
      SlsWastOnStr,
      HUID,
      LabourGoldRate,
      GoldRate,
    } = labelObj;
    if (HUID) metaObj.huid = [HUID];
    if (
      ornate_taxonomy?.manual_price &&
      labelObj?.[ornate_taxonomy?.manual_price] &&
      labelObj?.[ornate_taxonomy?.manual_price] !== "0"
    ) {
      metaObj.manual_price = labelObj?.[ornate_taxonomy?.manual_price];
    }
    if (
      ornate_taxonomy?.collections &&
      labelObj?.[ornate_taxonomy?.collections] &&
      labelObj?.[ornate_taxonomy?.collections] !== "0" &&
      !excludedFields.includes("collections")
    )
      productObj.collections = [labelObj?.[ornate_taxonomy?.collections]];

    if (
      ornate_taxonomy?.categories &&
      labelObj?.[ornate_taxonomy?.categories] &&
      labelObj?.[ornate_taxonomy?.categories] !== "0" &&
      !excludedFields.includes("categories")
    )
      productObj.category = [labelObj?.[ornate_taxonomy?.categories]];

    if (
      ornate_taxonomy?.["sub-categories"] &&
      labelObj?.[ornate_taxonomy?.["sub-categories"]] &&
      labelObj?.[ornate_taxonomy?.["sub-categories"]] !== "0"
    )
      productObj["subcategory"] = [
        labelObj?.[ornate_taxonomy?.["sub-categories"]],
      ];

    if (
      ornate_taxonomy?.["tags"] &&
      labelObj?.[ornate_taxonomy?.["tags"]] &&
      labelObj?.[ornate_taxonomy?.["tags"]] !== "0"
    )
      productObj["tag"] = [labelObj?.[ornate_taxonomy?.["tags"]]];

    let attributes = [];
    // {
    //   id: 23,
    //   name: 'Ocassions',
    //   slug: 'pa_ocassions',
    //   type: 'select',
    //   order_by: 'menu_order',
    let occasionAttribute = attributesData?.find(
      (i) => i?.slug === "pa_ocassions"
    );
    if (
      ornate_taxonomy?.["gender"] &&
      labelObj?.[ornate_taxonomy?.["gender"]] &&
      labelObj?.[ornate_taxonomy?.["gender"]] !== "0"
    )
      attributes.push({
        name: "Gender",
        visible: true,
        options: [labelObj?.[ornate_taxonomy?.["gender"]]?.toLowerCase()],
      });
    if (
      ornate_taxonomy?.["ocassions"] &&
      labelObj?.[ornate_taxonomy?.["ocassions"]] &&
      labelObj?.[ornate_taxonomy?.["ocassions"]] !== "0"
    )
      attributes.push({
        id: occasionAttribute?.id,
        name: "Ocassions",
        visible: true,
        options: [labelObj?.[ornate_taxonomy?.["ocassions"]]],
      });

    if (isArrayWithValues(attributes)) productObj.attributes = attributes;

    let paths = [];
    for (let i of [1, 2, 3, 4, 5, 6])
      if (labelObj[`ImagePath${i}`]) paths.push(labelObj[`ImagePath${i}`]);
    // let obj =
    // let images = [];
    // if (isArrayWithValues(paths)) images = await getImages(paths);
    if (!excludedFields.includes("title") && !excludedFields.includes("name"))
      productObj.name = ItemName;
    productObj.sku = sku_from === "barcode" ? BarcodeNo : LabelNo;
    metaObj._sku = sku_from === "barcode" ? BarcodeNo : LabelNo;
    metaObj.ornate_unique_id = id;
    metaObj.labourType = "individual";
    metaObj.custom_wastage_from = SlsWastOnStr?.includes("Net")
      ? "net"
      : "gross";

    switch (LabourOn) {
      case `Net Wt %.`:
      case "Net Wt.":
      case `Gross Wt.`:
      case "Gross Wt. %":
        metaObj.labour_from = LabourOn.includes("Net") ? "net" : "gross";
        metaObj.making_from =
          LabourOn === "Net Wt." || LabourOn === "Gross Wt." ? "labour" : "";
        if (LabourPer) {
          if (GoldRate !== LabourGoldRate && LabourGoldRate && LabourRate) {
            metaObj.labour = LabourRate;
            metaObj.per_gram = LabourRate;
            metaObj.wastage_percent = "";
          } else {
            metaObj.wastage_percent = LabourPer;
            metaObj.labour = "";
            metaObj.per_gram = "";
            metaObj.minimum_labour = "";
          }
        } else {
          if (LabourRate) {
            metaObj.labour = LabourRate;
            metaObj.per_gram = LabourRate;
          }

          if (SalWastPer) {
            metaObj.wastage_from = "rate";
            metaObj.wastage_percent = SalWastPer;
          }
          if (!LabourRate || !SalWastPer) {
            metaObj.minimum_labour = LabourAmt;
          }
        }

        break;
      case "Pcs":
        metaObj.labour_from = "gross";
        metaObj.making_from = "";
        metaObj.wastage_from = "";
        metaObj.wastage_percent = "";
        metaObj.labour = "";
        metaObj.per_gram = "";
        metaObj.minimum_labour = LabourAmt;
        break;
      default:
        break;
    }
  }
  let metals = [];

  const goldPurities = {
    23.99976: "999.99",
    23.976: "999",
    23.88: "995",
    // 24: "999",
    22: "916",
    18: "750",
    14: "583",
    9: "375",
  };
  if (isObjWithValues(goldObj)) {
    let { StyleGrossWt, NetWt, Purity } = goldObj;
    metaObj.gold_gross = StyleGrossWt;
    metaObj.gold_net = NetWt;

    let labelObjGoldPurity = labelObj?.[ornate_taxonomy?.gold_kt];
    if (
      labelObjGoldPurity &&
      labelObjGoldPurity !== "0" &&
      [
        "58.30",
        "75",
        "76",
        "91.67",
        "91.60",
        "99.50",
        "99.90",
        "99.99",
        "100",
      ]?.includes(labelObjGoldPurity) &&
      labelObjGoldPurity >= 91.0 &&
      labelObjGoldPurity <= 92.0
    )
      Purity = labelObjGoldPurity;

    if (Purity == "58.30" || validateNumber(Purity) == 60) metaObj.gold_kt = 14;
    else if (Purity == "75" || Purity == "76" || validateNumber(Purity) == 75)
      metaObj.gold_kt = 18;
    else if (
      (Purity >= 91.0 && Purity <= 92.0) ||
      Purity == "91.67" ||
      Purity == "91.60"
    )
      metaObj.gold_kt = 22;
    else if (Purity == "99.50") metaObj.gold_kt = 23.88;
    else if (Purity == "99.90") metaObj.gold_kt = 23.976;
    else if (Purity == "99.99" || Purity == "100") metaObj.gold_kt = 23.99976;

    metaObj.gold_kt = goldPurities[metaObj.gold_kt];
    productGoldKT = metaObj.gold_kt;
    metalPurity = Purity;
    // console.log(Purity, goldPurities[metaObj.gold_kt], "<<<< ");
    metals.push("gold");
  }
  if (isObjWithValues(silverObj)) {
    let { StyleGrossWt, NetWt, Purity } = silverObj;
    metaObj.silver_gross = StyleGrossWt || NetWt;
    metaObj.silver_net = NetWt;

    let labelObjSilverPurity = labelObj?.[ornate_taxonomy?.silver_purity];
    if (labelObjSilverPurity && labelObjSilverPurity !== "0")
      if (labelObjSilverPurity == 100) Purity = 999;

    if (Purity == 100) {
      metaObj.silver_purity = 999;
    } else {
      metaObj.silver_purity = 925;
    }
    metals.push("silver");
  }
  if (isObjWithValues(platinumObj)) {
    let { StyleGrossWt, NetWt, Purity } = platinumObj;
    metaObj.platinium_gross = StyleGrossWt || NetWt;
    metaObj.platinium_net = NetWt;

    let labelObjPlatiniumPurity = labelObj?.[ornate_taxonomy?.platinium_purity];
    if (labelObjPlatiniumPurity && labelObjPlatiniumPurity !== "0")
      if (labelObjPlatiniumPurity == 100) Purity = 950;

    if (Purity == 100) {
      metaObj.platinium_purity = 950;
    } else {
      metaObj.platinium_purity = 900;
    }
    metals.push("platinium");
  }
  if (metals.length > 1) metaObj.metal_type = "multi";
  else if (isArrayWithValues(metals)) {
    metaObj.metal_type = metals[0];
    metaObj.metal_types = metals;
  }

  if (isArrayWithValues(diamondArray)) {
    let diamondMetaObj = {};
    let index = 1;
    for (let diamond of diamondArray) {
      let { NetWt, Pcs, CutShape, Clarity, Colour, SizeID, SalesRate } =
        diamond;
      diamondMetaObj[`${index}`] = {
        diamond_shape: CutShape,
        diamond_size: SizeID,
        diamond_quality: "",
        diamond_type: "natural",
        diamond_weight: NetWt,
        diamond_pieces: Pcs,
        diamond_rate: Math.round(validateNumber(SalesRate)),
      };
      index++;
    }
    metaObj.diamond = diamondMetaObj;
    metaObj.diamond_from = "individual";
  } else {
    metaObj.diamond = {};
    metaObj.diamond_from = "individual";
  }

  if (isArrayWithValues(stoneArray)) {
    let colorstoneMetaObj = {};
    let index = 1;
    for (let colorstone of stoneArray) {
      let { NetWt, Pcs, CutShape, Clarity, Colour, SizeID, SalesAmount } =
        colorstone;
      colorstoneMetaObj[`${index}`] = {
        colorstone_shape: CutShape,
        colorstone_size: SizeID,
        colorstone_quality: "",
        colorstone_weight: NetWt,
        colorstone_pieces: Pcs,
        colorstone_rate: Math.round(validateNumber(SalesAmount)),
      };
      index++;
    }
    metaObj.colorstone_details = colorstoneMetaObj;
    metaObj.colorstone_from = "individual";
  } else {
    metaObj.colorstone_details = {};
    metaObj.colorstone_from = "individual";
  }
  let meta_data = [];
  for (let key of Object.keys(metaObj)) {
    meta_data.push({ key, value: metaObj[key] });
  }
  productObj.meta_data = meta_data;
  let product = { ...productObj };
  return { product, labelObj, productGoldKT, metalPurity };
};
