import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Typography,
  Card,
  TextField,
  InputAdornment,
  Box,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { whatsappTemplateStyles } from "./styles/whatsappTemplateStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import Header from "./components/Header";
import PreviewTemplate from "./components/PreviewTemplate";
import CircularProgress from "@mui/material/CircularProgress";
import { createTemplateForBackend } from "./utils/createTemplateForBackend";
import SearchIcon from "@mui/icons-material/Search";
import { checkIflast } from "../custom-nodes/helper/checkIfLast";
import { formatServerValue } from "../../../../../helper";
import { setGlobalToast } from "../../../../../redux/actions/helperActions";

const styles = whatsappTemplateStyles;

const containsText = (text, searchText) => {
  return text.toLowerCase().includes(searchText.toLowerCase());
};

const SidebarWhatsappTemplate = () => {
  const dispatch = useDispatch();
  const attributesRef = useRef();
  const titleAttributesRef = useRef();
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const nodeId = useSelector((state) => state.flow?.currentNodeId);
  const all_attributes = useSelector((state) => state.flow?.all_attributes);
  const [templateDetails, setTemplateDetails] = useState({
    name: null,
    id: null,
  });
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [previewTemplate, setPreviewTemplate] = useState(false);
  const whatsappTemplates = useSelector(
    (state) => state.flow?.whatsappTemplates
  );
  const [currentTemplateState, setCurrentTemplateState] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);
  const [titleCheckFlag, setTitleCheckFlag] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [templateError, setTemplateError] = useState(false);
  const lastNodeflag = checkIflast({ nodes, edges, nodeId: nodeId });
  const [optionSelected, setOptionSelected] = useState({});
  const [attributesCount, setAttributesCount] = useState(0);
  const [titleAttributesCount, setTitleAttributesCount] = useState(0);
  const [titleAttributes, setTitleAttributes] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [titleAnchorEl, setTitleAnchorEl] = useState(null);
  const [initialSave, setInitialSave] = useState(true);
  const [loading, setLoading] = useState(true);
  const [mediaNotAdded, setMediaNotAdded] = useState(false);

  const handleOptions = (option) => {
    const value = option;
    if (value === "") return;
    setTemplateDetails({
      name: value?.name,
      id: value?.id,
    });
    if (setTemplateDetails) {
      setPreviewTemplate(true);
      setTemplateError(false);
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    // if (!lastNodeflag) {
    //   console.log("ENTERED");
    //   setEditError(true);
    //   return;
    // }
    setOpen(true);
  };

  function hasCount() {
    return Object?.keys(optionSelected).length === attributesCount;
  }

  function hasTitleCount() {
    return Object?.keys(titleAttributes).length === titleAttributesCount;
  }

  const handleCheck = async (e) => {
    e.preventDefault();
    if (title == "" || titleCheckFlag) {
      setTitleCheckFlag(true);
      setTitleError(true);
    }

    if (attributesCount > 0 && !hasCount()) {
      setAnchorEl(attributesRef.current);
      return;
    }

    if (titleAttributesCount > 0 && !hasTitleCount()) {
      setAnchorEl(titleAttributesRef.current);
    }

    if (!previewTemplate) {
      setTemplateError(true);
    }

    if (title && !titleCheckFlag && hasCount() && previewTemplate) {
      const currentNode = nodes?.find((node) => node?.id === nodeId);
      const fieldData = currentNode?.fieldData;

      if (fieldData?.currentSavedState) {
        const currentSavedState = fieldData?.currentSavedState;
        if (currentSavedState?.id === currentTemplateState?.id) {
          console.log("SAMMME TEMPLATE");
        } else {
          const connectedEdges = edges.filter(
            (edge) => edge?.source === nodeId
          );
          const preventDelete = [
            "whatsapp_sent_handle",
            "whatsapp_delivered_handle",
            "whatsapp_read_handle",
            "whatsapp_failed_handle",
          ];
          const edgesToBeDeleted = connectedEdges.filter(
            (edge) => !preventDelete.includes(edge?.sourceHandle)
          );

          await Promise.all(
            edgesToBeDeleted.map(async (edge) => {
              dispatch({
                type: actions.DELETE_WITH_CHILDREN,
                payload: { id: edge?.target, type: "whatsapp-template" },
              });
            })
          );
        }
      }

      const templateData = createTemplateForBackend({
        id: templateDetails.id,
        templates: whatsappTemplates,
        currentTemplate: currentTemplateState,
        all_attributes,
      });

      let file_required = currentTemplateState?.components?.find(
        (i) =>
          i?.type?.toLowerCase() === "header" &&
          i?.format?.toLowerCase() !== "text"
      );
      let file_added = Boolean(
        currentTemplateState?.components?.find(
          (i) =>
            i?.type?.toLowerCase() === "header" &&
            i?.format?.toLowerCase() !== "text"
        )?.file_url?.url
      );
      if (file_required && !file_added) {
        setMediaNotAdded(true);
        return dispatch(
          setGlobalToast({
            show: true,
            message: `Please select an ${formatServerValue(
              file_required?.format
            )} for template`,
            severity: "error",
          })
        );
      }

      const submittedUserData = {
        title,
        fieldsData: templateDetails,
        currentSavedState: currentTemplateState,
        templateData,
        isUser: false,
        initial_save: false,
      };
      console.log(submittedUserData);
      dispatch({ type: actions.SIDEBAR_WHATSAPP_TEMPLATE, payload: false });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: nodeId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  const closeSidebar = () => {
    dispatch({ type: actions.SIDEBAR_WHATSAPP_TEMPLATE, payload: false });
  };

  const displayedOptions = useMemo(() => {
    // setLoading(true)
    const options = whatsappTemplates?.filter((option) =>
      containsText(option?.name, searchText)
    );
    setLoading(false);
    return options;
  }, [searchText, whatsappTemplates]);
  // const loading = displayedOptions.length ? false : true;
  // console.log(displayedOptions, loading);

  useEffect(() => {
    const currentNode = nodes?.find((it) => it.id === nodeId);
    const node = currentNode?.fieldData;

    if (node?.title && initialLoad) {
      setInitialLoad(false);
      setTitle(node.title);
      setTemplateDetails({ id: null, name: node.fieldsData?.name });
      setCurrentTemplateState(node.currentSavedState);
      setInitialSave(node?.initialSave);
      setPreviewTemplate(true);
    } else {
      const currentTemplatePreview = whatsappTemplates?.find(
        (template) => template.id + "" === templateDetails.id + ""
      );
      setCurrentTemplateState(currentTemplatePreview);
    }
  }, [nodeId, templateDetails?.id]);

  return (
    <Card style={styles.customCard}>
      <form>
        <Header
          nodes={nodes}
          handleClose={handleClose}
          handleCheck={handleCheck}
          setTitle={setTitle}
          titleError={titleError}
          title={title}
          titleCheckFlag={titleCheckFlag}
          setTitleCheckFlag={setTitleCheckFlag}
        />

        <Box sx={styles.responseSection}>
          <Box sx={styles.responseDiv}>
            <FormControl fullWidth>
              <Typography
                sx={{
                  marginBottom: "7px",
                  fontFamily: "Inter",
                }}
              >
                Select Whatsapp Template
              </Typography>
              <Select
                sx={{ backgroundColor: "#fff" }}
                size="small"
                MenuProps={{
                  autoFocus: false,
                }}
                id="search-select"
                value={templateDetails?.name}
                onClose={() => setSearchText("")}
                renderValue={() => formatServerValue(templateDetails.name)}
              >
                <Box
                  sx={{
                    minHeight: "200px",
                    maxHeight: "500px",
                    minWidth: "400px",
                    maxWidth: "400px",
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: "1px solid #dcdcdc",
                      padding: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <TextField
                      size="small"
                      autoFocus
                      variant="standard"
                      placeholder="Type to search..."
                      fullWidth
                      inputProps={{
                        style: {
                          fontFamily: "Inter",
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              position: "relative",
                              left: "4px",
                              bottom: "2px",
                              color: "#366aed",
                            }}
                          >
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </Box>
                  {lastNodeflag &&
                  displayedOptions &&
                  displayedOptions.length > 0 ? (
                    <Box>
                      {displayedOptions?.map((option, i) => (
                        <MenuItem
                          onClick={() => handleOptions(option)}
                          key={i}
                          sx={{
                            whiteSpace: "break-spaces",
                            fontFamily: "Inter",
                            transition: "0.3s ease",
                          }}
                          value={option}
                        >
                          <Typography
                            sx={{
                              width: "100%",
                              fontFamily: "Inter",
                              wordWrap: "break-word",
                            }}
                          >
                            {formatServerValue(option?.name)}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        minHeight: "200px",
                        overflowY: "auto",
                        maxHeight: "500px",
                        maxWidth: "450px",
                      }}
                    >
                      <MenuItem
                        sx={{
                          whiteSpace: "break-spaces",
                          fontFamily: "Inter",
                          transition: "0.3s ease",
                          cursor: "not-allowed",
                          marginTop: "10px",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            fontFamily: "Inter",
                            wordWrap: "break-word",
                          }}
                        >
                          No Options found!!
                        </Typography>
                      </MenuItem>
                    </Box>
                  )}
                </Box>
              </Select>
            </FormControl>
            {templateError && (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#E63946",
                  fontWeight: 600,
                  fontFamily: "Inter",
                  marginTop: "5px",
                }}
              >
                Please select template.
              </Typography>
            )}
            {!initialSave && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#E63946",
                    fontWeight: 600,
                    fontFamily: "Inter",
                    marginTop: "5px",
                    textDecoration: "underline",
                  }}
                >
                  Please note:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#E63946",
                    fontWeight: 500,
                    fontFamily: "Inter",
                  }}
                >
                  If you change the template, the nodes connected to the quick
                  reply buttons i.e. (except sent and delivered) will all be
                  deleted.
                </Typography>
              </Box>
            )}
          </Box>

          {previewTemplate && (
            <Typography
              variant="span"
              style={{ ...styles.labels, marginTop: "10px" }}
            >
              Preview
            </Typography>
          )}
          {previewTemplate && (
            <PreviewTemplate
              templateDetails={templateDetails}
              currentTemplateState={currentTemplateState}
              setCurrentTemplateState={setCurrentTemplateState}
              optionSelected={optionSelected}
              setOptionSelected={setOptionSelected}
              attributesCount={attributesCount}
              setAttributesCount={setAttributesCount}
              attributesRef={attributesRef}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              titleAttributesCount={titleAttributesCount}
              setTitleAttributesCount={setTitleAttributesCount}
              titleAttributes={titleAttributes}
              setTitleAttributes={setTitleAttributes}
              titleAttributesRef={titleAttributesRef}
              titleAnchorEl={titleAnchorEl}
              setTitleAnchorEl={setTitleAnchorEl}
              mediaNotAdded={mediaNotAdded}
              setMediaNotAdded={setMediaNotAdded}
            />
          )}
        </Box>
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </form>
    </Card>
  );
};

export default SidebarWhatsappTemplate;
