import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import { ImportExport } from "@mui/icons-material";
import CustomTooltip from "../../../../../flows/pages/addFlow/components/CustomTooltip";
import { capitalizeFirstLetter } from "jwero-javascript-utils";

export default function PlanSwitchWarning({
  onRewardTypeChange,
  currentType = "Custom",
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    handleClose();
    onRewardTypeChange && onRewardTypeChange();
  };

  return (
    <React.Fragment>
      <CustomTooltip
        title={`Swap to ${capitalizeFirstLetter(
          currentType === "fixed" ? "custom" : "fixed"
        )} type`}
      >
        <IconButton size="small" variant="outlined" onClick={handleClickOpen}>
          <ImportExport style={{ rotate: "90deg" }} />
        </IconButton>
      </CustomTooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Change reward type to{" "}
          {`${capitalizeFirstLetter(
            currentType === "fixed" ? "custom" : "fixed"
          )}`}{" "}
          lumpsum reward?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All the rewards created here will be deleted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={onConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
