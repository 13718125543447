import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Card,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import DialogBox from "../components/DialogBox";
import ReactHtmlParser from "react-html-parser";
import { fetchEmailTemplates } from "./helper/fetchEmailTemplates";
import { checkTitleExists } from "../helper/checkTitleExists";
import { emailStyles } from "./styles/emailStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import SidebarTopBar from "../components/SidebarTopBar";

const styles = emailStyles;

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const SidebarEmail = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [loader, setLoader] = useState(true);
  const [emailTemplates, setEmailTemplates] = useState(null);
  const [currentSelectedTemplate, setCurrentSelectedTemplate] = useState({
    key: null,
    templateName: null,
    templateHtml: null,
    templateObj: null,
  });
  const [allOptions, setAllOptions] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [titleCheckFlag, setTitleCheckFlag] = useState(false);
  const titleErrorMessage =
    title === "" ? "Title is required" : "Title already exists!";

  const handleClickPopover = (e) => {
    setOptionsAnchorEl(e.currentTarget);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const closeSidebar = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { openEmailSidebar: false },
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title === "" || titleCheckFlag) {
      setTitleCheckFlag(true);
      setTitleError(true);
    }

    if (title && !titleCheckFlag) {
      const submittedUserData = {
        title,
        template: currentSelectedTemplate,
      };
      console.log(submittedUserData);
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openEmailSidebar: false },
      });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  const getEmailTemplates = async ({ isSelected }) => {
    try {
      if (isSelected) {
        setLoader(true);
      }
      const templates = await fetchEmailTemplates();
      console.log(templates);

      if (templates && typeof templates === "object") {
        setEmailTemplates(templates);
        const getOptions = Object.keys(templates).map((key) => {
          return templates?.[key]?.name;
        });
        setAllOptions(getOptions);
        setLoader(false);
      } else {
        console.error("Invalid or missing templates object:", templates);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const handleTemplateOption = (value) => {
    const selectedKey = Object.keys(emailTemplates).find(
      (key) => emailTemplates[key]?.name === value
    );
    console.log(selectedKey);
    const currTemplateObj = emailTemplates?.[selectedKey];
    const selectKeyHtml = emailTemplates?.[selectedKey]?.html_template;

    setCurrentSelectedTemplate((prev) => ({
      ...prev,
      key: selectedKey,
      templateName: value,
      templateHtml: selectKeyHtml,
      templateObj: currTemplateObj,
    }));
  };

  const displayedOptions = useMemo(() => {
    if (searchText === "") {
      return allOptions;
    } else {
      return allOptions?.filter((option) => containsText(option, searchText));
    }
  }, [searchText, allOptions]);

  const handleBotTitle = (e) => {
    const value = e.target.value;
    setTitle(e.target.value);
    if (checkTitleExists({ nodes, title: value })) {
      setTitleCheckFlag(true);
      return;
    }
    setTitleCheckFlag(false);
  };

  useEffect(() => {
    const currentNode = nodes?.find((it) => it.id === userId);

    if (currentNode) {
      const node = currentNode?.fieldData;
      if (node?.title) {
        setTitle(node?.title);
      }
      if (node?.template) {
        setCurrentSelectedTemplate(node?.template);
        getEmailTemplates({ isSelected: true });
      } else {
        getEmailTemplates({ isSelected: false });
      }
    }
  }, [userId, nodes]);

  return (
    <Card style={styles.customCard}>
      <form>
        <SidebarTopBar
          onTitleChange={handleBotTitle}
          titleError={titleErrorMessage}
          title={title}
          showTitleError={titleCheckFlag}
          handleClose={handleClose}
          handleCheck={handleCheck}
          type="email"
          topBarTitle={"Email"}
          titleLabel="Trigger title"
        />
        {/* <Box sx={styles.topCard}>
          <Box sx={styles.innerCard}>
            <Box sx={styles.userHeader}>
              <EmailIcon />
              <Typography variant="span" style={styles.headerTitle}>
                Email
              </Typography>
            </Box>
            <Box sx={styles.headerIconsMainDiv}>
              <IconButton sx={styles.headerIconsDiv} onClick={handleClose}>
                <CloseIcon sx={styles.headerIcons} />
              </IconButton>
              <IconButton sx={styles.headerIconsDiv} onClick={handleCheck}>
                <CheckIcon sx={styles.headerIcons} />
              </IconButton>
            </Box>
          </Box>

          <TextField
            placeholder="Type block title"
            size="small"
            style={styles.titleTextField}
            onChange={handleBotTitle}
            error={titleError}
            value={title}
            required
            inputProps={{
              maxLength: 30,
            }}
          />
          {titleCheckFlag && (
            <Typography
              sx={{
                fontSize: "14px",
                color: "#E63946",
                fontWeight: 600,
                fontFamily: "Inter",
                position: "relative",
                bottom: "10px",
                left: "3px",
              }}
            >
              {titleErrorMessage}
            </Typography>
          )}
        </Box> */}
        <Box sx={styles.responseSection}>
          <Box sx={styles.responseDiv}>
            {loader ? (
              <CircularProgress />
            ) : (
              <Box>
                <FormControl fullWidth>
                  <Typography
                    sx={{
                      marginBottom: "7px",
                      fontFamily: "Inter",
                    }}
                  >
                    Select Email Template
                  </Typography>
                  <Select
                    sx={{ backgroundColor: "#fff" }}
                    size="small"
                    MenuProps={{
                      autoFocus: false,
                    }}
                    id="search-select"
                    value={currentSelectedTemplate?.templateName}
                    onChange={(e) => handleTemplateOption(e.target.value)}
                    onClose={() => setSearchText("")}
                    renderValue={() => currentSelectedTemplate?.templateName}
                  >
                    <Box
                      sx={{
                        borderBottom: "1px solid #dcdcdc",
                        padding: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <TextField
                        size="small"
                        autoFocus
                        variant="standard"
                        placeholder="Type to search..."
                        fullWidth
                        inputProps={{
                          style: {
                            fontFamily: "Inter",
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{
                                position: "relative",
                                left: "4px",
                                bottom: "2px",
                                color: "#366aed",
                              }}
                            >
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </Box>
                    {displayedOptions?.map((option, i) => (
                      <MenuItem key={i} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>
          <Box sx={{ height: "500px", width: "100%", marginTop: "20px" }}>
            {currentSelectedTemplate?.templateName && (
              <Box>
                {ReactHtmlParser(currentSelectedTemplate?.templateHtml)}
              </Box>
            )}
          </Box>
        </Box>
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </form>
    </Card>
  );
};

export default SidebarEmail;
