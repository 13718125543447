import { Typography, TextField, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { whatsappTemplateStyles } from "../styles/whatsappTemplateStyles";
import { checkTitleExists } from "../../helper/checkTitleExists";
import SidebarTopBar from "../../components/SidebarTopBar";

const styles = whatsappTemplateStyles;

const Header = ({
  nodes,
  handleClose,
  handleCheck,
  setTitle,
  titleError,
  title,
  titleCheckFlag,
  setTitleCheckFlag,
}) => {
  const titleErrorMessage =
    title === "" ? "Title is required" : "Title already exists!";

  const handleBotTitle = (e) => {
    const value = e.target.value;
    setTitle(e.target.value);
    if (checkTitleExists({ nodes, title: value })) {
      setTitleCheckFlag(true);
      return;
    }
    setTitleCheckFlag(false);
  };

  return (
    <SidebarTopBar
      onTitleChange={handleBotTitle}
      titleError={titleError}
      title={title}
      showTitleError={titleCheckFlag}
      handleClose={handleClose}
      handleCheck={handleCheck}
      topBarTitle={"Whatsapp Template"}
      type="whatsapp_template"
      titleLabel="Trigger title"
    />
  );
  return (
    <Box sx={styles.topCard}>
      <Box sx={styles.innerCard}>
        <Box sx={styles.userHeader}>
          <WhatsAppIcon />
          <Typography variant="span" style={styles.headerTitle}>
            Whatsapp Template
          </Typography>
        </Box>
        <Box sx={styles.headerIconsMainDiv}>
          <IconButton sx={styles.headerIconsDiv} onClick={handleClose}>
            <CloseIcon sx={styles.headerIcons} />
          </IconButton>
          <IconButton sx={styles.headerIconsDiv} onClick={handleCheck}>
            <CheckIcon sx={styles.headerIcons} />
          </IconButton>
        </Box>
      </Box>

      <TextField
        placeholder="Type block title"
        size="small"
        style={styles.titleTextField}
        onChange={handleBotTitle}
        error={titleError}
        value={title}
        required
      />
      {titleCheckFlag && (
        <Typography
          sx={{
            fontSize: "14px",
            color: "#E63946",
            fontWeight: 600,
            fontFamily: "Inter",
            position: "relative",
            bottom: "10px",
            left: "3px",
          }}
        >
          {titleErrorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default Header;
