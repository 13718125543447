export const productCatalogStyles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Inter",
  },
  bottomRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    "&:hover": {
      cursor: "pointer",
    },
  },
  nodeBody: {
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    height: "30px",
    minWidth: "110px",
    fontSize: "16px",
    color: "#000",
    backgroundColor: "#00b3fa",
    borderRadius: "20px",
    marginBottom: "30px",
  },
  titleName: {
    marginLeft: "5px",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 550,
    color: "#fff"
  },
  buttonIcon: {
    marginLeft: "7px",
    padding: 0,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#00b3fa",
    },
  },
  titleText: {
    fontSize: "12px",
    fontWeight: 600,
    color: "white",
  },
  addIcon: {
    fontSize: "14px",
    height: "20px",
    width: "20px",
    color: "black",
    borderRadius: "50%",
    backgroundColor: "white",
    marginLeft: "4px",
    transition: "visibility 0s, color 0.2s",
    position: "relative",
    bottom: "13px",
    "&:hover": {
      color: "blue",
    },
  },
  icon: {
    fontSize: "18px",
  },
  leftHandle: {
    background: "#566d89",
    top: "-11px",
    opacity: "0",
    position: "relative",
    left: "10px",
  },
  rightHandle: {
    background: "#566d89",
    opacity: "0",
    top: "4px",
    right: "15px",
    position: "relative",
  },
  editOptionDiv: {
    textAlign: "center",
    maxWidth: "100px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Inter",
    fontWeight: 600,
    color: "#566e8a",
  },
  title: {
    fontSize: "13px",
    fontFamily: "Inter",
    fontWeight: 600,
    color: "#566e8a",
  },
};
