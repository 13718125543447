/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Avatar,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Typography,
  Skeleton,
  Checkbox,
  Drawer,
  Card,
  TextField,
  TablePagination,
  FormGroup,
  FormControlLabel,
  Divider,
  InputAdornment,
  MenuItem,
  Tooltip,
  Menu,
  Badge,
  Slide,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  IconButton,
  Select,
  styled,
  LinearProgress,
  linearProgressClasses,
  Paper,
  TableFooter,
  CircularProgress,
  Collapse,
  Autocomplete,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Box, useTheme } from "@mui/system";
import {
  addMultipleCustomerInRedux,
  appendNextCusromer,
  deleteMultipleCustomerInRedux,
  editCRM,
  fetchCustomers,
  updateCustomerInRedux,
  updateMultipleCustomersInRedux,
} from "../../redux/actions/crmActions";

import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  CachedOutlined,
  Clear,
  Close,
  Favorite,
  Laptop,
  PhoneIphone,
  Search,
} from "@material-ui/icons";
import {
  apiCallFromSocket,
  createCustomCustomer,
  dialogStyle,
  encryptStorage,
  fetchIPInfo,
  formatDate,
  formatServerValue,
  getAxiosError,
  getIsBackendMigrated,
  getMetaValueFromProduct,
  getRandomDarkColor,
  getRandomString,
  getRelativeTimeDifference,
  getStoreId,
  getToken,
  getWebsite,
  isArray,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  isOurWebsite,
  LiveDot,
  primaryLabelSmall,
  reduceLargeText,
  removeDuplicatesFromArray,
  secondaryLabel,
  sleep,
  unformatServerValue,
  updateCustomer,
  validateNumber,
} from "../../helper";
import {
  Call,
  Circle,
  CircleNotifications,
  Delete,
  Description,
  EmailOutlined,
  FilterAltOutlined,
  Language,
  Launch,
  LocalOffer,
  ManageAccountsOutlined,
  NotificationsActiveOutlined,
  Payment,
  Person,
  PersonAddAltOutlined,
  ShoppingCart,
  Sync,
  Widgets,
} from "@mui/icons-material";
import AddCustomer from "./AddCustomer";
import {
  Android,
  Apple,
  CampaignIconSvg,
  CatalogIcon,
  DeleteIllustration,
  DigigoldMenuSvg,
  EmailCampaignSvg,
  Facebook,
  // Facebook,
  GoogleSheet,
  InstagramSvg,
  Messenger,
  NoSheets,
  SalesApp,
  ShopifySvg,
  Whatsapp,
  WhatsappSvg,
  Windows,
  WooCommerceSvg,
} from "../../Svgs";
import {
  DesktopDatePicker,
  LoadingButton,
  LocalizationProvider,
} from "@mui/lab";
import axios from "axios";
import { setGlobalToast } from "../../redux/actions/helperActions";
// import { dialCodes } from "../crm/Add";
import TempleteList from "../components/TempleteList";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { isValidDate } from "../../utils/dateFunctions";
import {
  addScheduleActivity,
  // editSettings,
  EDIT_SETTINGS,
} from "../../redux/actions/settingActions";
import { getIp } from "../../App";
import { UAParser } from "ua-parser-js";
import {
  ethincityArray,
  languageArray,
  religionArray,
} from "./components/Info";
import asyncComponent from "../../components/Async";
import DataNotFound from "../components/DataNotFound";
import {
  addMessageInRedux,
  EDIT_CHATS,
  getCampaignHistory,
  setUpCampaignHistory,
  updateCampaignAnalytics,
  updateCrmIdinSetting,
} from "../../redux/actions/chatActions";
import { addConversation } from "../chat/components/ChatWindow";

import EmailTemplate, { filterSubmitHandler } from "./components/EmailTemplate";
import CampaignHistory from "./components/CampaignHistory";
import { DeleteCrmLabel } from "../crm/CRM";
import SendSms from "./components/SendSms";
import SendNotification from "../components/SendNotification";
import { EncryptStorage } from "encrypt-storage";
import { getExcelColumnHeader } from "../../utils/js";
import { editDataSheet } from "../../redux/actions/datasheetActions";
import { sendMessage } from "../chat_new/apiFunctions";
import Filters from "./components/Filters";
import JweroSyncHistory from "../products/components/JweroSyncHistory";
import { CustomDateRangeFilter } from "../digital_gold/Dashboard";
import {
  PrimaryLabelSmall,
  SecondaryLabelSmall,
} from "../../helper/utility/Labels";
import CustomTooltip from "../flows/pages/addFlow/components/CustomTooltip";
import HorizontalStack from "../../helper/utility/HorizontalStack";
const Loader = () => {
  return (
    <Stack
      sx={{ width: "-webkit-fill-available", height: "70vh" }}
      justifyContent="center"
      alignItems={"center"}
    >
      <CircularProgress color="primary" disableShrink />
    </Stack>
  );
};

export const customerAnalyticCardsStyle = {
  backgroundColor: "#f6f6f6",
};

const CustomerView = asyncComponent(
  () => import("./CustomerView"),
  100,
  <Loader />
);

export const getExotelUsers = async ({ api_key, api_token, sid }) => {
  try {
    let res = await apiCallFromSocket({
      // url: `https://${api_key}:${api_token}${subdomain}/v1/Accounts/${sid}/Calls/connect`,
      // url: `https://api.exotel.com/v1/Accounts/${sid}/Sms/send.json`,
      url: `https://api.exotel.com/v2_beta/Accounts/${sid}/IncomingPhoneNumbers`,
      // url: `https://api.exotel.com/v2_beta/Accounts/jwero1/IncomingPhoneNumbers`,
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(api_key + ":" + api_token)}`,
      },

      // data: qs.stringify(bodyObj),
    });
    if (res?.data?.success) {
      return res?.data?.data;
    }
  } catch (error) {}
};
//Customer relationship component
const Customers = () => {
  const dispatch = useDispatch();

  const campaignHistory = useSelector((state) => state.chats.campaignHistory);
  const exotelIntegration = useSelector(
    (state) => state.settings.exotelIntegration
  );
  const getttingCampaignHistory = useSelector(
    (state) => state.chats.getttingCampaignHistory
  );
  useEffect(async () => {
    if (isObjWithValues(exotelIntegration)) {
      let res = await getExotelUsers(exotelIntegration);

      if (isObjWithValues(res)) {
        dispatch({
          type: EDIT_CHATS,
          payload: {
            exotelNumbers: res?.incoming_phone_numbers?.map(
              (obj) => obj?.phone_number
            ),
          },
        });
      }
    }
  }, [exotelIntegration]);

  // useEffect(() => {
  //   if (!isObjWithValues(campaignHistory) && !getttingCampaignHistory)
  //     dispatch(getCampaignHistory());
  // }, []);

  // useEffect(() => {
  //   if (isObjWithValues(campaignHistory)) {
  //     dispatch(setUpCampaignHistory({ history: campaignHistory || {} }));
  //     dispatch(updateCampaignAnalytics(Object.values(campaignHistory || {})));
  //   }
  // }, [campaignHistory]);

  return (
    <Box sx={{ paddingLeft: 0 }}>
      <GridCustomersList />
    </Box>
  );
};

export default Customers;

const GridCustomersList = () => {
  const allReduxCustomers = useSelector(
    (state) => state.customers.allCustomers
  );
  const [addFormView, setAddFormView] = useState(false);
  const storeUsed = useSelector((state) => state.user.store_used);
  const fetchingCustomers = useSelector(
    (state) => state.customers.fetchingCustomers
  );
  const dispatch = useDispatch();
  const customerListRef = useRef();
  useEffect(() => {
    if (!isArrayWithValues(allReduxCustomers) && !fetchingCustomers) {
      dispatch(
        fetchCustomers({
          page: 1,
          per_page: 100,
          orderby: "registered_date",
          order: "desc",
        })
      );
    }
  }, []);

  // inistal dispatch

  const { id } = useParams();

  const theme = useTheme();
  const laptop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      {!fetchingCustomers &&
      !isArrayWithValues(allReduxCustomers) &&
      storeUsed?.users <= 1 ? (
        <DataNotFound
          illustration={<img src="/gifs/crm.gif" alt="gifs" />}
          subtitle={
            "CRM manages all your company's relationships and interactions with customers and potential customers"
          }
          title={"Let’s create your first customer."}
          createButton={
            <Button
              variant="contained"
              size="large"
              sx={{ mt: 4 }}
              onClick={() => setAddFormView(true)}
            >
              Create customer
            </Button>
          }
        />
      ) : (
        <>
          {laptop && (
            <Grid container sx={{ maxWidth: "100%" }} spacing={3}>
              <Grid item xs={3}>
                <CustomerList
                  ref={customerListRef}
                  allReduxCustomers={allReduxCustomers}
                  fetchingCustomers={fetchingCustomers}
                  addFormView={addFormView}
                  setAddFormView={setAddFormView}
                />
              </Grid>
              <Grid item xs={9}>
                <CustomerView
                  allReduxCustomers={allReduxCustomers}
                  fetchingCustomers={fetchingCustomers}
                />
              </Grid>
            </Grid>
          )}
          {!laptop && !id && (
            <CustomerList
              allReduxCustomers={allReduxCustomers}
              fetchingCustomers={fetchingCustomers}
              addFormView={addFormView}
              setAddFormView={setAddFormView}
            />
          )}
          {!laptop && id && (
            <CustomerView
              allReduxCustomers={allReduxCustomers}
              fetchingCustomers={fetchingCustomers}
            />
          )}
        </>
      )}

      <AddCustomer addFormView={addFormView} setAddFormView={setAddFormView} />
    </>
  );
};

const AddLabelView = ({ onClose, onAdd, setLoading, loading }) => {
  const [customLabel, setCustomLabel] = useState("");
  const [addLabelColor, setAddLabelColor] = useState(getRandomDarkColor());
  const [allCustomerGroup, setAllCustomerGroup] = useState([]);
  const [error, setError] = useState("");
  const [deleteId, setDeleteId] = useState(false);
  const reduxCustomerGroup = useSelector(
    (state) => state.customers.crmGrouping
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (isArrayWithValues(reduxCustomerGroup))
      setAllCustomerGroup(reduxCustomerGroup);
  }, [reduxCustomerGroup]);

  useEffect(() => {
    if (error) setError("");
  }, [customLabel]);
  const onSubmit = async (name) => {
    if (
      allCustomerGroup.find(
        (i) =>
          i.group_name?.toLowerCase()?.trim() ==
          customLabel.toLowerCase().trim()
      )
    )
      return setError(`${customLabel} is already added to the list`);
    let obj = allCustomerGroup.find((i) => i.group_name === name);
    if (allCustomerGroup.length >= 10 && !obj && !isDev())
      return setError("Maximum limit reached");
    setLoading(true);
    if (obj) {
      // console.log(obj);
      onAdd(obj.group_name);
    } else {
      if (!customLabel) return;
      let addObj = {
        color: addLabelColor,
        group_name: customLabel?.trim(),
        group_description: "",
      };
      let array = [...allCustomerGroup];
      if (isArrayWithValues(array)) {
        array.push({ ...addObj });
      } else array = [{ ...addObj }];
      try {
        let res = await addCustomerLabel(array);
        if (res.success && res.data && res.data.crm_grouping_names)
          dispatch({
            type: "SET_CRM_DATA",
            payload: { crmGrouping: res.data.crm_grouping_names },
          });
        onAdd(addObj.group_name);
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };
  const addCustomerLabel = async (data) => {
    let website = await getWebsite();
    let token = await getToken();

    let res = await axios({
      url: `${website}/wp-json/store/v1/settings`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      // params: { options: "customer_labels" },
      data: { crm_grouping_names: data },
      method: "POST",
    });
    return res.data;
  };
  return (
    <>
      {deleteId ? (
        <DeleteCrmLabel
          setDeleteId={setDeleteId}
          deleteId={deleteId}
          allReduxCustomerGroup={allCustomerGroup}
        />
      ) : (
        <>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            pr={3}
          >
            <DialogTitle>Customer Grouping</DialogTitle>
            <IconButton onClick={() => onClose && onClose()}>
              <Close />
            </IconButton>
          </Stack>
          <DialogContent>
            {isArrayWithValues(allCustomerGroup) &&
              allCustomerGroup.map((i) => {
                return (
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    sx={{
                      px: 3,
                      py: 2,
                      "&:hover": {
                        backgroundColor: "rgba(0,0,0,0.02)",
                      },
                      cursor: "pointer",
                    }}
                    onClick={() => onSubmit(i.group_name)}
                  >
                    <LocalOffer
                      style={{
                        height: "16px",
                        width: "16px",
                        color: i.color,
                      }}
                    />
                    <Typography variant="h6" ml={3}>
                      {i.group_name}
                    </Typography>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteId(i);
                      }}
                      style={{ float: "right", marginLeft: "auto" }}
                    >
                      <Delete color="error" fontSize="small" />
                    </IconButton>
                  </Stack>
                );
              })}
          </DialogContent>
          <Box sx={{ px: 3 }}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Custom Label"
              fullWidth
              variant="standard"
              sx={{ mt: 2 }}
              value={customLabel}
              onChange={(e) => setCustomLabel(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ p: 2 }}
                      onClick={() =>
                        setAddLabelColor(
                          getRandomDarkColor()
                          // `#${Math.floor(Math.random() * 16777215).toString(16)}`
                        )
                      }
                    >
                      <LocalOffer
                        style={{
                          height: "16px",
                          width: "16px",
                          color: addLabelColor,
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Collapse in={Boolean(error)}>
            <Typography
              sx={{
                ...secondaryLabel,
                color: "error.main",
                textAlign: "center",
              }}
            >
              {error}
            </Typography>
          </Collapse>
          <DialogActions>
            <Button onClick={() => onClose && onClose()}>Cancel</Button>

            <LoadingButton onClick={onSubmit} loading={loading}>
              Update
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </>
  );
};

export const likeFilters = [
  { label: "First Name", value: "first_name" },
  { label: "Last Name", value: "last_name" },
  // { label: "Nickname", value: "nickname" },
  { label: "City", value: "billing_city" },
  { label: "State", value: "billing_state" },
  { label: "Country", value: "billing_country" },
  { label: "Whatsapp", value: "whatsapp" },
  { label: "Mobile", value: "mobile" },
  { label: "Profession", value: "profession" },
  { label: "Language", value: "language" },
  { label: "Ethinicity", value: "ethinicity" },
  { label: "Annual Income", value: "annual_income" },
  { label: "Gender", value: "gender" },
];
export const rangeFilters = [
  // { label: "Total Purchase", value: "total_purchase_amount" },
  { label: "Customer Since", value: "customer_since" },
  { label: "User Birthday", value: "user_birthday" },
  { label: "User Anniversary", value: "user_anniversary" },
  { label: "First Purchase", value: "first_purchase" },
  { label: "Last Visited Website", value: "last_visit_on_website" },
  { label: "Last Visited Showroom", value: "last_visit_in_showrrom" },
];

const allRangeFilters = [
  // { label: "Total Purchase", value: "total_purchase_amount" },
  { label: "Customer Since", value: "customer_since" },
  // { label: "User Birthday", value: "user_birthday" },
  // { label: "User Anniversary", value: "user_anniversary" },
  { label: "First Purchase", value: "first_purchase" },
  { label: "Last Visited Website", value: "last_visit_on_website" },
  { label: "Last Visited Showroom", value: "last_visit_in_showrrom" },
];

const betweenFilters = [
  { label: "Visits on Showroom", value: "visits_in_showroom" },
  { label: "Total purchase", value: "total_purchase" },
];
export const sorting = [
  {
    label: "Last Active",
    key: "last_active",
    compare_key: "EXISTS",
    value: "",
    compare: "!=",
  },
  {
    label: "Recently Update",
    key: "last_update",
    compare_key: "EXISTS",
    value: "",
    compare: "!=",
  },
  {
    label: "Returning visitors",
    returning: true,
  },
  // { label: "Recent Communication", name: "recent_updated" },
  {
    label: "Recent Purchased",
    key: "paying_customer",
    compare_key: "EXISTS",
    // value: "1",
  },
  // { label: "Digi Gold", name: "digigold" },

  // { label: "Recent Purchased", name: "recent_communication" },
  // { label: "Shopify Customer", name: "shopify_customer" },
  // { label: "Woocommerce Customer", name: "woocommerce_customer" },
];

const actionsFilters = [
  { label: "Abandoned Cart", value: "cart" },
  { label: "WishList", value: "wishlist" },
  { label: "Product Viewed", value: "wishlist" },
  { label: "Birthday", value: "wishlist" },
  { label: "Anniversaries", value: "wishlist" },

  // { label: "Catalogs", value: "catalog" },
  // { label: "Orders", value: "order" },
];

export const defaultFilterForm = {
  sorting: [],
  socials: [],
  cart: false,
  wishlist: false,
  catalog: false,
  payment: false,
  group: "",
  branch: "",
  filterValue: "",
  filterValueLabel: "",
  searchValue: "",
  searchValueLabel: "",
  rangeLabel: "",
  minRange: "",
  maxRange: "",
  filterMethod: "AND",
  user_birthday: "",
  user_birthday_start: "",
  user_birthday_end: "",
  user_anniversary: "",
  user_anniversary_start: "",
  user_anniversary_end: "",
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#00e676" : "#00e676",
  },
}));
const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1ca25e" : "#1ca25e",
  },
}));

const defaultSendingTemplate = {
  loading: false,
  total: 0,
  totalSent: 0,
  percentage: 0,
};

// customerList component
const CustomerList = forwardRef(
  (
    {
      allReduxCustomers,
      isSide,
      fetchingCustomers,
      addFormView,
      setAddFormView,
    },
    ref
  ) => {
    const location = useLocation();
    const { id } = useParams();
    const theme = useTheme();
    const laptop = useMediaQuery(theme.breakpoints.up("md"));
    const [emailOpen, setEmailOpen] = useState(false);
    const [openCampaignHistory, setOpenCampaignHistory] = useState(false);
    const [allCustomers, setAllCustomers] = useState(
      removeDuplicatesFromArray(allReduxCustomers || [], "id")
    );
    const [search, setSearch] = useState("");
    const [showBulkGroupAdd, setShowBulkGroupAdd] = useState(false);
    const [showBulkTemplate, setShowBulkTemplate] = useState(false);
    const navigate = useNavigate();
    const [selected, setSelected] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [addCustomerAnchor, setAddCustomerAnchor] = React.useState(null);
    const [anchorElOption, setAnchorElOption] = React.useState(null);
    const [showBulkDeleteWarning, setShowBulkDeleteWarning] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const bulkActionOpen = Boolean(anchorEl);
    const addCustomerOpen = Boolean(addCustomerAnchor);
    const [labelupdating, setLabelUpdating] = useState(false);
    const [filterForm, setFilterForm] = useState(defaultFilterForm);
    const [page, setPage] = useState(0);
    const [withFilters, setWithFilters] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [smsOpen, setSmsOpen] = useState(false);
    const [pushNotificationOpen, setpushNotificationOpen] = useState(false);
    const [stateArray, setStateArray] = useState([{ key: "", value: "" }]);
    const [sendingBulkTemplates, setSendingBulkTemplates] = useState(
      defaultSendingTemplate
    );
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [impParams, setImpParams] = useState({});
    const [defaultParams] = useState({
      page: 1,
      per_page: 100,
      // sortby: "last_active",
      orderby: "registered_date",
      order: "desc",
      // includes: ""
    });

    const [params, setParams] = useState({ ...defaultParams, ...impParams });
    const [crmSheetLoading, setCRMSheetLoading] = useState(false);
    const [filterView, setFilterView] = useState(false);
    const [showSync, setShowSync] = useState(false);
    const [showJweroSync, setShowJweroSync] = useState(false);
    const [totalFilters, setTotalFilters] = useState(0);

    const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
    const [viewSearch, seViewSearch] = useState(false);
    const [totalCustomer, setTotalCustomers] = useState();
    const [syncProgress, setSyncProgress] = useState(0);
    const [storeId, setStoreId] = useState(null);
    const [openSheetLoading, setOpenSheetLoading] = useState(false);
    const [addLimitReached, setAddLimitReached] = useState(false);
    const [allBranches, setAllBranches] = useState([]);
    const [integratedChannels, setIntegratedChannels] = useState([
      "website",
      "sales_app",
      "catalog",
      "email",
      "digigold",
    ]);
    const [showSections, setShowSections] = useState({
      campaign: false,
    });
    const [rangeFilters, setRangeFilters] = useState(allRangeFilters);
    const [showAllGroups, setShowAllGroups] = useState(false);

    const filterRef = useRef(null);
    const campaignHistoryMaxWidth = useSelector(
      (state) => state?.helper?.campaignHistoryMaxWidth
    );
    const crmAccess = useSelector((state) => state.user?.access?.crm);
    const allCustomerGroup = useSelector(
      (state) => state.customers.crmGrouping
    );
    const reduxGoogleSpreadsheet = useSelector(
      (state) => state.settings.googleSpreadsheet
    );
    const occupationsArray = useSelector((state) => state.persist.occupations);
    const assignedCustomers = useSelector(
      (state) => state.user.assigned_customers
    );
    const customerIncluded = useSelector(
      (state) => state.user.customerIncluded
    );
    const [onlyCRMProduct, setOnlyCRMProduct] = useState(false);
    const globalPopupStyle =
      useSelector((state) => state.helper.globalPopupStyle) || {};
    const appendingCustomers = useSelector(
      (state) => state.customers.appendingCustomers
    );

    const reduxShopifyIntegration = useSelector(
      (state) => state.settings.shopifyIntegration
    );
    const noCustomersFound = useSelector(
      (state) => state.customers.noCustomersFound
    );
    const [isCampaign, setIsCampaign] = useState(true);
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const storePlan = useSelector((state) => state.user.store_plan);
    const storeUsed = useSelector((state) => state.user.store_used);
    const mergeProfiles = useSelector((state) => state.chats.mergeProfiles);
    const user_products = useSelector(
      (state) => state.user?.store_plan?.user_products
    );
    const planName = useSelector((state) => state.user?.store_plan?.plan);
    const totalCustomersFound = useSelector(
      (state) => state.customers?.totalCustomersFound
    );

    const chatsSpreadsheetHistory = useSelector(
      (state) => state.chats.chatsSpreadsheetHistory
    );

    const crmSpreadsheetHistory = useSelector(
      (state) => state.customers.crmSpreadsheetHistory
    );
    const reduxMetaIntegration = useSelector(
      (state) => state.settings.metaIntegration
    );

    const reduxWooCommerceIntegration = useSelector(
      (state) => state.settings.wooCommerceIntegration
    );

    const all_products_access = useSelector(
      (state) => state?.store_plan?.all_products_access
    );
    const custom_fields = useSelector(
      (state) => state?.settings?.custom_fields
    );

    let branches = useSelector((state) => state.settings.branch);
    let googleIntegration = useSelector(
      (state) => state.settings.googleIntegration
    );

    const googleSheetIntegrated = Object.values(
      useSelector((state) => state.settings.googleIntegration) || {}
    )
      .map((o) => (isArrayWithValues(o?.connected) ? o.connected : []))
      ?.flat()
      ?.includes("google_sheets");
    const customerSearchTextfieldRef = useRef();

    useEffect(() => {
      const excludeKeys = [
        "user_birthday_start",
        "user_birthday_end",
        "user_anniversary_start",
        "user_anniversary_end",
        "filterMethod",
      ]; // Add the keys you want to exclude here

      const filterCount = Object.keys(filterForm || {})
        .filter((key) => !excludeKeys.includes(key))
        .map((key) => filterForm[key])
        .filter((value) => {
          if (Array.isArray(value)) {
            return value.length > 0;
          }
          return value;
        }).length;

      setTotalFilters(filterCount);
    }, [filterForm]);

    useImperativeHandle(ref, () => {
      return {
        openAddForm() {
          setAddFormView(true);
        },
      };
    });
    let { hash } = location;
    useEffect(() => {
      if (hash && hash.includes("open")) setShowJweroSync(true);
    }, [hash]);
    useEffect(() => {
      if (!isObjWithValues(custom_fields?.crm)) return;
      let dataCustomFilters = Object.values(custom_fields?.crm)
        ?.map((i) =>
          i.type === "date" ? { label: i.title, value: i.value } : null
        )
        ?.filter(Boolean);

      setRangeFilters(allRangeFilters.concat(dataCustomFilters));
    }, [custom_fields]);

    useEffect(() => {
      let array = [];
      if (reduxShopifyIntegration?.integrated) array.push("shopify");
      if (reduxWooCommerceIntegration?.integrated) array.push("woocommerce");
      if (isObjWithValues(reduxMetaIntegration)) {
        // setIntegratedChannels(
        //   );
        Object.values(reduxMetaIntegration).map(
          (i) => (array = array.concat(i.connected || []))
        );
      }
      setIntegratedChannels((state) => [
        ...new Set([...(array || []), ...state]),
      ]);
    }, [
      reduxMetaIntegration,
      reduxWooCommerceIntegration,
      reduxShopifyIntegration,
    ]);

    useEffect(() => {
      let isCRMProduct =
        user_products?.length === 1 && user_products.includes("crm");
      setOnlyCRMProduct(isCRMProduct);
      setShowSections({
        campaign: all_products_access || isCRMProduct || planName === "growth",
      });
    }, [user_products]);

    useEffect(() => {
      if (!isObjWithValues(branches)) return;
      setAllBranches(
        Object.values(branches)
          .map((i) => ({
            label: i.branchName,
            value: i.branchName,
          }))
          .filter((i) => i.label)
      );
    }, [branches]);

    useEffect(() => {
      if (typeof customerIncluded === "boolean") {
        let obj = {};
        if (isArrayWithValues(assignedCustomers)) {
          if (customerIncluded)
            obj = {
              ...impParams,
              include: assignedCustomers.map((i) => i.value),
            };
          else
            obj = {
              ...impParams,
              exclude: assignedCustomers.map((i) => i.value),
            };
          setImpParams((state) => ({
            ...state,
            ...obj,
          }));
          if (!isArrayWithValues(allReduxCustomers)) {
            dispatch(fetchCustomers({ ...defaultParams }));
          }
          // dispatch(fetchCustomers({ ...defaultParams, ...obj }));
        } else {
          if (!isArrayWithValues(allReduxCustomers) && !fetchingCustomers) {
            dispatch(fetchCustomers(defaultParams));
          }
        }
      } else {
        if (!isArrayWithValues(allReduxCustomers)) {
          dispatch(fetchCustomers(defaultParams));
        }
      }
    }, [customerIncluded, assignedCustomers]);

    useEffect(() => {
      let { crm } = storePlan || {};
      let { users } = storeUsed || {};

      console.log(users, crm);
      if (users && crm) setAddLimitReached(users >= crm);
    }, [storePlan, storeUsed]);

    const openSync = () => {
      setShowSync(true);
    };
    const closeSync = () => {
      setShowSync(false);
    };
    const bulkActionClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const bulkActionClose = () => {
      setAnchorEl(null);
    };
    const addCustomerClick = (event) => {
      setAddCustomerAnchor(event.currentTarget);
    };
    const addCustomerClose = () => {
      setAddCustomerAnchor(null);
    };

    const editFilterForm = (payload) =>
      setFilterForm((state) => ({ ...state, ...payload }));

    const user = useSelector((state) => state.user);
    useEffect(() => {
      if (isObjWithValues(user)) {
        if (user.store_used)
          if (user.store_used.users) setTotalCustomers(user.store_used.users);
      }
    }, [user]);

    useEffect(() => {
      if (
        laptop &&
        isArrayWithValues(allReduxCustomers) &&
        !fetchingCustomers &&
        allReduxCustomers[0].id
      ) {
        if (!id) {
          navigate(`/crm/${allReduxCustomers[0].id}`);
        }
      }
    }, [id, fetchingCustomers, allReduxCustomers]);

    useEffect(() => {
      // let allChats = [...(allReduxCustomers || [])]?.map((obj) => {
      //   let merge_profiles = Object.values(mergeProfiles || {}).find(
      //     (profile) => profile?.crm_id === obj?.id
      //   );

      //   if (merge_profiles) {
      //     console.log(merge_profiles);
      //     obj.merge_profiles = merge_profiles;
      //     obj.profiles = {};
      //     for (const idObj of merge_profiles?.profiles || []) {
      //       obj.profiles[idObj?.platform] = idObj?.id;
      //     }
      //   }

      //   return obj;
      // });
      if (!isArrayWithValues(allReduxCustomers)) {
        setAllCustomers([]);
        return;
      }
      let allCustomers = [...allReduxCustomers];
      let updated = [];
      for (let customer of allCustomers) {
        let { meta_data } = customer;
        let updatedCustomer = { ...customer };
        if (isArrayWithValues(meta_data))
          for (let metaObj of meta_data)
            updatedCustomer[metaObj.key] = metaObj.value;
        updated.push(updatedCustomer);
      }

      setAllCustomers(removeDuplicatesFromArray([...(updated || [])], "id"));
    }, [id, allReduxCustomers, fetchingCustomers, mergeProfiles]);
    useEffect(() => {
      setStoreId(getStoreId());
    }, []);

    const emptyRows = 10;

    const changeRouteHandler = (id) => {
      navigate(`/crm/${id}`);
    };

    const dispatch = useDispatch();
    const searchSubmitHandler = (e) => {
      const { value } = e.target;
      if (e.key === "Enter") {
        onSearch(value);
      }
    };
    const onSearch = (search) => {
      navigate("/crm/");

      // let { filterValueLabel } = filterForm;
      // let keysToSearch = [
      //   "first_name",
      //   "last_name",
      //   "email",
      //   "phone",
      //   "whatsapp",
      // ];
      // if (isArrayWithValues(custom_filter)) {
      //   custom_filter = custom_filter.filter(
      //     (i) => !keysToSearch.includes(i.key)
      //   );
      // } else custom_filter = [];

      // if (keysToSearch.includes(filterValueLabel)) {
      //   setFilterForm((state) => ({
      //     ...state,
      //     filterValueLabel: "",
      //     filterValue: "",
      //   }));
      // }
      // if (search) {
      //   for (let key of keysToSearch) {
      //     custom_filter.push({
      //       key,
      //       value: search,
      //       compare: "LIKE",
      //     });
      //   }
      // }
      // console.log(custom_filter, "IN SEARCH");
      setParams({ ...params, custom_search: search, page: 1 });
      dispatch(
        fetchCustomers({ ...params, custom_search: search, page: 1 }, true)
      );
    };

    const sortingCheckHandler = (item) => {
      // const { name } = e.target;
      // setSortingParams({ ...initialSortState, [`${name}`]: true });
      let { sorting } = filterForm;
      // setFilterForm((state) => ({
      //   ...state,
      //   sorting: [item],
      // }));
      if (sorting.includes(item))
        setFilterForm((state) => ({
          ...state,
          sorting: sorting.filter((i) => i !== item),
        }));
      else
        setFilterForm((state) => ({
          ...state,
          sorting: [item],
        }));
    };
    const socialsCheckHandler = (item) => {
      // const { name } = e.target;
      // setSortingParams({ ...initialSortState, [`${name}`]: true });
      let { socials = [] } = filterForm;
      if (socials?.find((o) => o?.label === item?.label)) {
        socials = socials?.filter((o) => o.label !== item?.label);
      } else {
        socials = [...socials, item];
      }
      setFilterForm((pre) => ({ ...pre, socials }));
      // let { socials } = filterForm;
      // if (socials.includes(item))
      //   setFilterForm((state) => ({
      //     ...state,
      //     socials: socials.filter((i) => i !== item),
      //   }));
      // else
      //   setFilterForm((state) => ({
      //     ...state,
      //     socials: [...socials, item],
      //   }));
    };

    const filterSubmitHandlerOnClick = async ({ reset }) => {
      let filterObj = {
        custom_filter: [],
      };
      let filterByValue = {};
      let groupState = filterRef?.current?.getState() || [];

      // return;
      let paramsObj = { ...params };
      let deletableKeys = Object.keys(paramsObj).filter(
        (i) => i.includes("custom_filter") || i.includes("sortby")
      );
      for (let key of deletableKeys) delete paramsObj[key];
      if (!reset) {
        filterObj = filterSubmitHandler({ ...filterForm });
        groupState?.forEach((obj) => {
          let { key, value } = obj;
          if (key && value)
            filterObj.custom_filter.push({
              key,
              value: value?.value || value,
              compare: "LIKE",
            });
        });
        // let filtersObj = filterForm;
        // let {
        //   sorting,
        //   cart,
        //   wishlist,
        //   group,
        //   filterValue,
        //   filterValueLabel,
        //   searchValue,
        //   searchValueLabel,
        //   minRange,
        //   maxRange,
        //   rangeLabel,
        //   socials,
        //   branch,
        // } = filtersObj;

        // let storeId = await getStoreId();
        // if (isArrayWithValues(sorting)) {
        //   filterObj.sortby = sorting[0];
        // }
        // let channelSocials = ["woocommerce", "shopify"];
        // if (isArrayWithValues(socials)) {
        //   for (let social of socials) {
        //     if (channelSocials.includes(social)) {
        //       filterObj.custom_filter.push({
        //         key: "customer_channel",
        //         value: social,
        //         compare: "!=",
        //       });
        //     } else
        //       filterObj.custom_filter.push({
        //         key: social,
        //         value: "",
        //         compare: "!=",
        //         type: "NUMERIC",
        //       });
        //   }
        // }
        // if (cart) {
        //   filterObj.custom_filter.push({
        //     key: `_woocommerce_persistent_cart_${storeId}`,
        //     value: "product_id",
        //     compare: "LIKE",
        //   });
        // }
        // if (wishlist) {
        //   filterObj.custom_filter.push({
        //     key: `woodmart_wishlist_`,
        //     value: "product_id",
        //     compare: "LIKE",
        //     compare_key: "LIKE",
        //   });
        // }
        // if (group) {
        //   filterObj.custom_filter.push({
        //     key: `group_name_${storeId}`,
        //     value: group,
        //     compare: "LIKE",
        //   });
        // }
        // if (branch) {
        //   filterObj.custom_filter.push({
        //     key: `branch_from`,
        //     value: branch,
        //     compare: "LIKE",
        //   });
        // }
        // if (filterValue && filterValueLabel) {
        //   filterObj.custom_filter.push({
        //     key: filterValueLabel,
        //     value: filterValue,
        //     compare: "LIKE",
        //   });
        // }
        // if (searchValue && searchValueLabel) {
        //   filterObj.custom_filter.push({
        //     key: searchValueLabel,
        //     value: searchValue,
        //     compare: "LIKE",
        //   });
        // }

        // if (
        //   rangeLabel &&
        //   isValidDate(new Date(minRange)) &&
        //   isValidDate(new Date(maxRange))
        // ) {
        //   filterObj.custom_filter.push({
        //     key: rangeLabel,
        //     value: [
        //       formatDate(minRange, "yyyy-mm-dd"),
        //       formatDate(maxRange, "yyyy-mm-dd"),
        //     ],
        //     compare: "BETWEEN",
        //   });
        // }
      }
      // if (isArrayWithValues(socials)) {
      //   for (let social of socials) {
      //     filterObj[`custom_filter[${index}][key]`] = social;
      //     filterObj[`custom_filter[${index}][value]`] = "";
      //     filterObj[`custom_filter[${index}][compare]`] = "LIKE";
      //     filterObj[`custom_filter[${index}][type]`] = "NUMERIC";
      //     index++;
      //   }
      // }
      // if (cart) {
      //   filterObj[
      //     `custom_filter[${index}][key]`
      //   ] = `_woocommerce_persistent_cart_${storeId}`;
      //   filterObj[`custom_filter[${index}][value]`] = "product_id";
      //   filterObj[`custom_filter[${index}][compare]`] = "LIKE";
      //   index++;
      // }
      // if (wishlist) {
      //   filterObj[`custom_filter[${index}][key]`] = `woodmart_wishlist_`;
      //   filterObj[`custom_filter[${index}][compare_key]`] = "LIKE";
      //   filterObj[`custom_filter[${index}][value]`] = "product_id";
      //   filterObj[`custom_filter[${index}][compare]`] = "LIKE";
      //   index++;
      // }
      // if (group) {
      //   filterObj[`custom_filter[${index}][key]`] = `group_name_${storeId}`;
      //   filterObj[`custom_filter[${index}][value]`] = group;
      //   filterObj[`custom_filter[${index}][compare]`] = "LIKE";
      //   index++;
      // }
      // if (filterValue && filterValueLabel) {
      //   filterObj[`custom_filter[${index}][key]`] = filterValueLabel;
      //   filterObj[`custom_filter[${index}][value]`] = filterValue;
      //   filterObj[`custom_filter[${index}][compare]`] = "LIKE";
      //   index++;
      // }
      // if (searchValue && searchValueLabel) {
      //   filterObj[`custom_filter[${index}][key]`] = searchValueLabel;
      //   filterObj[`custom_filter[${index}][value]`] = searchValue;
      //   filterObj[`custom_filter[${index}][compare]`] = "LIKE";
      //   index++;
      // }
      // if (
      //   rangeLabel &&
      //   isValidDate(new Date(minRange)) &&
      //   isValidDate(new Date(maxRange))
      // ) {
      //   filterObj[`custom_filter[${index}][key]`] = rangeLabel;
      //   filterObj[`custom_filter[${index}][value]`] = [
      //     formatDate(minRange, "yyyy-mm-dd"),
      //     formatDate(maxRange, "yyyy-mm-dd"),
      //   ];
      //   filterObj[`custom_filter[${index}][compare]`] = "BETWEEN";
      //   index++;
      // }
      setFilterView(false);
      console.log({ ...paramsObj, ...filterObj });

      dispatch(
        fetchCustomers({
          ...paramsObj,
          ...filterObj,
          operation: filterForm?.filterMethod || "AND",
        })
      );
      setParams({ ...filterObj, ...paramsObj });
      navigate("/crm/");
      // console.log(sortingParams);
      // for (const key in sortingParams) {
      //   if (sortingParams[key]) {
      //     navigate("/crm/");
      //     dispatch(fetchCustomers({ ...params, sortby: key }));
      //     setFilterView(false);
      //   }
      // }
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      if (Array.isArray(allReduxCustomers) && allReduxCustomers.length > 0) {
        let currentPage = newPage + 1;
        if (
          allReduxCustomers.length / rowsPerPage <= currentPage &&
          !appendingCustomers &&
          !noCustomersFound
        ) {
          setParams({ ...params, page: params.page + 1 });
          dispatch(appendNextCusromer({ ...params, page: params.page + 1 }));
        }
      }
      // if (
      //   allReduxCustomers.length >=
      //   page * rowsPerPage + rowsPerPage + rowsPerPage
      // ) {
      //   console.log("fetching>>>");
      //   dispatch(appendNextCusromer({ ...paginationParams }));
      //   setPaginationParams({
      //     ...paginationParams,
      //     page: paginationParams.page++,
      //   });
      // }
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    let backgroundColor = theme.palette.primary && theme.palette.primary.main;
    let isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const onBulkDelete = async () => {
      setIsDeleting(true);
      let array = [...selected];
      let ids = array.map((i) => i.id);
      let length = Math.round(ids.length / 100) || 1;
      let error = false;
      for await (let index of new Array(length)) {
        let res = await bulkDelete(ids);
        // let res = true;
        // await sleep(2000);
        if (!res) {
          error = true;
          break;
        }
      }
      if (error)
        dispatch(
          setGlobalToast({
            show: true,
            message: "Delete operation failed, please try again",
            severity: "error",
          })
        );
      else {
        setSelected([]);
        dispatch(deleteMultipleCustomerInRedux(ids, { navigate }));
        dispatch(
          setGlobalToast({
            show: true,
            message: "Customers has been deleted!",
            severity: "success",
          })
        );
        setShowBulkDeleteWarning(false);
      }
      setIsDeleting(false);
    };

    const bulkDelete = async (ids) => {
      let website = await getWebsite();
      let token = await getToken();
      if (website && token) {
        try {
          let res = await axios({
            url: `${website}/wp-json/wc/v3/customers/batch`,
            headers: {
              Authorization: `Basic ${token}`,
            },
            method: "POST",
            data: { delete: ids },
          });
          return isArrayWithValues(res.data.delete);
        } catch (error) {
          return false;
        }
      }
      return false;
    };

    const onCustomerLabelAdd = async (name) => {
      let update = [];
      let storeId = await getStoreId();
      console.log(selected);
      for (let obj of selected) {
        let { id, meta_data } = obj;
        let updateObj = { id };
        if (isArrayWithValues(meta_data)) {
          let groupObj = meta_data.find(
            (i) => i.key === `group_name_${storeId}`
          );
          if (groupObj && isArrayWithValues(groupObj.value))
            updateObj.meta_data = [
              {
                key: `group_name_${storeId}`,
                value: [...groupObj.value, name],
              },
            ];
          else
            updateObj.meta_data = [
              { key: `group_name_${storeId}`, value: [name] },
            ];
        }
        update.push(updateObj);
      }
      if (isArrayWithValues(update)) {
        let website = await getWebsite();
        let token = await getToken();
        let length = parseInt(update.length / 100) || 1;
        for (let temp of new Array(length)) {
          let { data } = await axios({
            url: `${website}/wp-json/wc/v3/customers/batch`,
            method: "POST",
            headers: { Authorization: `Basic ${token}` },
            data: { update: update.splice(0, 100) },
          });
          console.log(data);
          if (isArrayWithValues(data.update))
            dispatch(updateMultipleCustomersInRedux(data.update));
        }
      }
      setLabelUpdating(false);
      setShowBulkGroupAdd(false);
      dispatch(
        setGlobalToast({
          show: true,
          message: "Tags updated successfully!",
          severity: "success",
        })
      );
      setSelected([]);
    };

    // {
    //   "name": "welcome_quickstart",
    //   "language": {
    //     "code": "en"
    //   },
    //   "components": [
    //     {
    //       "type": "HEADER",
    //       "parameters": [
    //         {
    //           "type": "text",
    //           "text": "{First Name}"
    //         }
    //       ]
    //     },
    //     {
    //       "type": "BODY",
    //       "parameters": [
    //         {
    //           "type": "text",
    //           "text": "{First Name}"
    //         }
    //       ]
    //     }
    //   ]
    // }
    // const variables = [
    //   { label: "{First Name}", value: "first_name" },
    //   { label: "{Last Name}", value: "last_name" },
    //   { label: "{Fullname}", value: "fullname" },
    //   { label: "{City}", value: "city" },
    //   { label: "{Address}", value: "address" },
    // ];
    let getVariableObjFromCustomer = (customer) => {
      if (!isObjWithValues(customer)) return;
      let { first_name, last_name, billing } = customer;
      return {
        "{First Name}": first_name,
        "{Last Name}": last_name,
        "{Fullname}": `${first_name} ${last_name}`,
        "{City}": billing.city,
        "{Address}": `${billing.address_1 ? `${billing.address_1},` : ""} ${
          billing.address_2 ? `${billing.address_2},` : ""
        } ${billing.city ? `${billing.city},` : ""} ${
          billing.state ? `${billing.state},` : ""
        } ${billing.country ? `${billing.country},` : ""} ${
          billing.postcode ? `${billing.postcode},` : ""
        }`,
      };
    };
    const onSendBulkTemplate = async (e, schedule, textTemplate) => {
      let scheduleData = { ...schedule };
      console.log(e, textTemplate);
      // console.log(e.components.map(obj => obj?.parameters.map(obj => obj?.text.includes("{") ? obj?.text = `var_${unformatServerValue(obj?.text.slice(1, -1))}` : obj?.text)))

      if (scheduleData?.enable) {
        let components = [];
        components = e.components.map((obj) => {
          if (isArrayWithValues(obj?.parameters)) {
            obj?.parameters.map((o) =>
              o.text.includes("{")
                ? (o.text = `var_${unformatServerValue(o?.text.slice(1, -1))}`)
                : o
            );
          }
          return { ...obj };
        });
        dispatch(
          addScheduleActivity({
            time: new Date(scheduleData?.time).getTime(),
            patform: "whatsapp",
            type: "template",
            template_name: e?.name,
            schedule_title: scheduleData?.scheduleTitle,
            payload: { ...e, components: components },
            customers: [selected.map((obj) => obj?.id)],
          })
        );
        return;
      }

      setSendingBulkTemplates((state) => ({
        ...state,
        ...defaultSendingTemplate,
      }));
      let bulkPayload = [];
      let customers = {};

      for (let customer of selected) {
        let payload = {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          // to: customer.number,
          type: "template",
          template: e,
        };
        console.log(`1`);
        let variableObj = getVariableObjFromCustomer(customer);
        let { meta_data, billing } = customer;
        let obj = {
          phone: billing ? billing.phone : "",
        };
        if (isArrayWithValues(meta_data))
          meta_data.map((i) => {
            if (
              ["whatsapp", "dialcode_whatsapp", "dialcode_mobile"].includes(
                i.key
              ) &&
              i.value
            )
              obj[i.key] = i.value;
          });
        console.log(`2`);
        if (obj.whatsapp && obj.dialcode_whatsapp) {
          payload.to = `${obj.dialcode_whatsapp}${obj.whatsapp}`
            .replace(/\s/g, "")
            .trim();
        } else if (obj.mobile && obj.dialcode_mobile) {
          payload.to = `${obj.dialcode_mobile}${obj.mobile}`
            .replace(/\s/g, "")
            .trim();
        } else if (obj.phone && obj.dialcode_mobile) {
          payload.to = `${obj.dialcode_mobile}${obj.phone}`
            .replace(/\s/g, "")
            .trim();
        } else if (obj.phone || obj.mobile || obj.whatsapp) {
          let phone = obj.whatsapp || obj.mobile || obj.phone;
          phone = phone?.replace("+", "");
          // let obj = phone(`${!phone?.includes("+") ? "+" : ""}${phone}`);
          // console.log(phone, "<<<<");
          if (validateNumber(phone) && phone?.length > 9) payload.to = phone;
        }
        if (payload.to) customers[payload.to] = customer;
        else continue;

        // console.log(payload);
        let errorFound = false;
        if (isArrayWithValues(payload?.template?.components)) {
          let components = [];
          for (let component of payload?.template.components) {
            if (isArrayWithValues(component.parameters)) {
              let parameters = [];
              for (let parameter of component.parameters) {
                let { text } = parameter || {};
                let variableText = text
                  ? text?.includes("{") && text?.includes("}")
                    ? variableObj?.[text] || " "
                    : text
                  : text;
                if (!variableText) variableText = " ";
                parameters.push({ ...parameter, text: variableText });
              }
              components.push({ ...component, parameters });
            }
          }
          if (isArrayWithValues(components))
            payload = {
              ...payload,
              template: { ...payload.template, components },
            };
          // payload.template.components = components;
        }
        console.log(errorFound);
        if (errorFound) continue;
        // if (isDev()) payload.to = payload.to?.slice(0, 8);
        payload.name = `${customer.first_name || ""} ${
          customer?.last_name || ""
        }`;
        bulkPayload.push(payload);
      }
      console.log(bulkPayload);
      if (!isArrayWithValues(bulkPayload)) return;
      setShowBulkTemplate(false);
      setSendingBulkTemplates((state) => ({
        ...state,
        loading: true,
        total: bulkPayload.length,
      }));
      let index = 0;
      let percentageToAdd = 100 / bulkPayload.length;
      console.log(bulkPayload);
      // if (isDev()) return;
      try {
        for await (let payload of bulkPayload) {
          let res = await sendSingleTemplate(payload, customers, textTemplate);
          console.log(res);

          index++;
          setSendingBulkTemplates((state) => ({
            ...state,
            totalSent: index,
            percentage:
              state.percentage + percentageToAdd < 100
                ? state.percentage + percentageToAdd
                : 100,
          }));
        }
        await sleep(1200);
        dispatch(
          setGlobalToast({
            show: true,
            message: "Bulk messages has been sent to users",
            severity: "success",
          })
        );
        setSelected([]);
        setSendingBulkTemplates((state) => ({ ...state, loading: false }));
      } catch (error) {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Couldn't Send bulk messages! please try again",
            severity: "error",
          })
        );
      }
    };

    const sendSingleTemplate = async (payload, customers, textTemplate) => {
      let website = await getWebsite();
      let token = await getToken();
      if (website && token) {
        try {
          let data;
          if (getIsBackendMigrated("chats")) {
            let res = await sendMessage({
              payload: {
                ...payload,
              },
              meta_data: {
                template: textTemplate,
              },
              platform: "whatsapp",
            });
            data = {
              success: true,
              data: res,
            };
          } else {
            let res = await axios({
              url: `${website}/wp-json/store/v1/social/message`,
              method: "POST",
              data: {
                platform: "whatsapp",
                payload,
              },
            });
            data = res?.data;
          }
          // await sleep(3000);
          // const { data } = getIsBackendMigrated("chats")
          //   ? // await sendMessage({
          //     //   payload : {
          //     //     platform: "whatsapp",
          //     //     payload,
          //     //     meta_data : {
          //     //       template : textTemplate
          //     //     }
          //     //   },platform : whatsapp
          //     // })
          //     await sendMessage({
          //       payload: {
          //         ...payload,
          //         meta_data: {
          //           template: textTemplate,
          //         },
          //       },
          //       platform: "whatsapp",
          //     })
          //   : await axios({
          //       url: `${website}/wp-json/store/v1/social/message`,
          //       method: "POST",
          //       data: {
          //         platform: "whatsapp",
          //         payload,
          //       },
          //     });
          console.log(data);
          // dispatch(addResponseInChat(data?.data, payload.to, { platform: "whatsapp" }))
          dispatch(addMessageInRedux(data, payload?.to));
          console.log(data);
          if (data?.success) {
            let customer = customers[payload?.to];
            if (isObjWithValues(customer)) {
              let whatsapp_id = customer?.meta_data?.find(
                (obj) => obj?.key === "whatsapp_id"
              );
              if (!whatsapp_id) {
                let updateCustomerRes = await updateCustomer(
                  customers[payload?.to]?.id,
                  { meta_data: [{ key: "whatsapp_id", value: payload?.to }] }
                );
                console.log("<<<res", updateCustomerRes);
                let createObject = {};
                if (getIsBackendMigrated("chats")) {
                  let contact_type = getMetaValueFromProduct(
                    customer,
                    "contact_type"
                  );
                  createObject = {
                    platform_id: payload.to,
                    first_name: customer?.first_name,
                    last_name: customer?.last_name,
                    platform: "whatsapp",
                    email: customer?.email,
                    company_name: customer?.billing?.company,
                    contact_type,
                  };
                } else {
                  createObject = {
                    id: payload.to,
                    name: `${customer?.first_name || ""} ${
                      customer?.last_name || ""
                    }`,
                    number: payload.to,
                    status_updated: Date.now(),
                    customer_id: id,
                  };
                }
                // let conversations = await addConversation({
                //   id: payload.to,
                //   name: `${customer?.first_name || ""} ${
                //     customer?.last_name || ""
                //   }`,
                //   number: payload.to,
                //   status_updated: Date.now(),
                //   customer_id: id,
                // });
                let conversations = await addConversation(createObject);
                dispatch(
                  updateCrmIdinSetting(customers[payload?.to]?.id, [
                    {
                      platform: "whatsapp",
                      id: payload.to,
                    },
                  ])
                );
                if (updateCustomerRes)
                  dispatch(updateCustomerInRedux(updateCustomerRes));
              }
            }
          }
        } catch (error) {
          console.log(getAxiosError(error));
        }
      }
    };

    const onSelectAll = (e) => {
      if (selected.length !== allCustomers?.length && isSelectedAll) {
        setSelected(allCustomers);
      } else {
        setIsSelectedAll(e.target.checked);
        if (e.target.checked) setSelected(allCustomers);
        else setSelected([]);
      }
    };

    // console.log(syncProgress, "<<<<< SYNC PROGRESS");
    const openSheet = async () => {
      let { crm_spreadsheet_id } = reduxGoogleSpreadsheet || {};
      if (!crm_spreadsheet_id) {
        setOpenSheetLoading(true);
        crm_spreadsheet_id = await createSpreadsheet();
        setOpenSheetLoading(false);
      }
      window.open(
        `https://docs.google.com/spreadsheets/d/${crm_spreadsheet_id}/edit#gid=0`,
        "_blank"
      );
    };
    const onSyncPress = async () => {
      try {
        setSyncProgress(0);
        setShowSync(false);
        setCRMSheetLoading(true);
        let historyObj = {
          id: getRandomString(8),
          start: Math.floor(Date.now() / 1000),
          ip: await getIp(),
          ua: new UAParser().getUA(),
          // ip:
        };
        let location = await fetchIPInfo(historyObj.ip);
        let crmSheetCustomerErrors = [];
        if (isObjWithValues(location)) {
          let { city, region, postal, country } = location;
          historyObj.location = {
            city,
            state: region,
            postcode: postal,
            country,
          };
        }
        let allCustomers = await getAllCustomers(
          setSyncProgress,
          totalCustomer
        );

        console.log(allCustomers, "<<< all customers ");

        let { crm_spreadsheet_id } = reduxGoogleSpreadsheet;
        if (!crm_spreadsheet_id) {
          crm_spreadsheet_id = await createSpreadsheet();
        }
        let access_token = Object.values(googleIntegration)?.find((i) =>
          i.connected?.includes("google_sheets")
        )?.access_token;

        let spreadsheetData = await getChannelData(
          "google_sheets",
          `/spreadsheets/${crm_spreadsheet_id}/values:batchGet?majorDimension=ROWS`,
          { access_token }
        );
        console.log(spreadsheetData, "<<<< <<<<<<<<<<");
        // return;

        if (spreadsheetData?.error?.status === "UNAUTHENTICATED") {
          setShowSync(false);
          setCRMSheetLoading(false);
          setSyncProgress((state) => 0);
          dispatch(
            setGlobalToast({
              show: true,
              title: "Sync Failed",
              message: "Google has been revoked, Kindly reintegrate again",
              severity: "error",
            })
          );
          return;
        } else if (!spreadsheetData) {
          setShowSync(false);
          setCRMSheetLoading(false);
          setSyncProgress((state) => 0);
          dispatch(
            setGlobalToast({
              show: true,
              title: "Sync Failed",
              message: "Failed to get Google sheet data, please try again",
              severity: "error",
            })
          );
          return;
        }
        if (
          isArrayWithValues(spreadsheetData)
          // isArrayWithValues(spreadsheetData.valueRanges[0].values)
        ) {
          let bulkObj = getBulkCreateUpdateObject(spreadsheetData);
          console.log(bulkObj, "bulkobj");
          if (addLimitReached) bulkObj.create = [];
          console.log("1");
          if (
            isArrayWithValues(bulkObj.create) &&
            isArrayWithValues(allCustomers)
          ) {
            bulkObj.create = bulkObj.create.filter((customer) => {
              let isDuplicate = getCustomerIdFromAllCustomers(
                allCustomers,
                customer
              );
              if (isDuplicate)
                crmSheetCustomerErrors.push({
                  ...customer,
                  action: "create",
                  error_message: "Duplicate Customer",
                });
              return !Boolean(isDuplicate);
            });
            bulkObj.create = bulkObj.create.filter(Boolean);
          }
          console.log("2");
          // adding id to update customers
          if (
            isArrayWithValues(bulkObj.update) &&
            isArrayWithValues(allCustomers)
          ) {
            bulkObj.update = bulkObj.update.map((customer) => {
              let id = getCustomerIdFromAllCustomers(allCustomers, customer);
              if (id) return { ...customer, id };
              else {
                crmSheetCustomerErrors.push({
                  ...customer,
                  action: "update",
                  error_message: "Customer Not Found",
                });
                return false;
              }
            });
            bulkObj.update = bulkObj.update.filter(Boolean);
          }
          console.log("3");

          let bulkResponse = await addAndUpdateBulkProducts(
            bulkObj,
            setSyncProgress
          );
          console.log(bulkResponse, "bulkResponse");
          if (isArrayWithValues(bulkResponse?.create)) {
            dispatch(addMultipleCustomerInRedux(bulkResponse?.create));
            allCustomers = [...(bulkResponse?.create || [])].concat(
              allCustomers
            );
          }
          if (isArrayWithValues(bulkResponse?.update)) {
            dispatch(updateMultipleCustomersInRedux(bulkResponse?.update));
            let updatedCustomers = [...bulkResponse.update];
            // here were assigning
            allCustomers = allCustomers.map((customer) => {
              let obj = bulkResponse.update.find((i) => i.id === customer.id);
              if (obj)
                updatedCustomers = updatedCustomers.filter(
                  (i) => i.id != obj.id
                );
              return obj || customer;
            });
            //remaining updated customers as we wont be able to find new customers in allCustomers
            if (isArrayWithValues(updatedCustomers))
              allCustomers = allCustomers.concat(updatedCustomers);
          }
          console.log(bulkObj, "bulkobj before history");
          if (bulkObj.create) historyObj.create = bulkObj.create.length;
          if (bulkObj.update) historyObj.update = bulkObj.update.length;
        }
        let array = createSpreadSheetData(
          [...allCustomers, ...crmSheetCustomerErrors],
          allCustomerGroup
        );
        console.log(
          array,
          allCustomers,
          crmSheetCustomerErrors,
          "spreadsheet data 1"
        );
        if (isArrayWithValues(allCustomers))
          historyObj.exported = allCustomers.length;
        // if (sheetRowsLength && sheetRowsLength > array?.length) {
        //   let extraRows = sheetRowsLength - array.length;
        //   array = [
        //     ...array,
        //     ...Array.from(
        //       new Array(extraRows).fill(
        //         Array.from(new Array(array?.[0]?.length)).fill("")
        //       )
        //     ),
        //   ];
        // }
        // console.log(array, "spreadsheet data 2");
        setSyncProgress(97);

        await clearSpreadsheet(array, crm_spreadsheet_id);
        await exportSpreadsheet(array, crm_spreadsheet_id, {
          access_token,
        });
        setSyncProgress(98);

        const dropdownData = getDropdownData(occupationsArray);
        let sheetIdObj = await getSheetId(crm_spreadsheet_id);
        console.log(sheetIdObj);
        if (isArrayWithValues(sheetIdObj?.sheets)) {
          let sheetId = sheetIdObj.sheets[0]?.properties?.sheetId;
          console.log(sheetId);
          const dropdownBody = getSpreadsheetDropdowns(
            sheetId,
            array[0],
            dropdownData
          );
          console.log(dropdownData, dropdownBody, "DROPDOWN DATA");
          await setSpreadsheetDropdowns(crm_spreadsheet_id, dropdownBody);
        }
        setSyncProgress(99);
        historyObj.end = Math.floor(Date.now() / 1000);
        console.log(historyObj);
        if (!isDev())
          await addHistroyObj({ [getRandomString(10)]: historyObj });
        setSyncProgress(100);
        await sleep(200);
        setCRMSheetLoading(false);
        setShowSync(true);
        dispatch(
          setGlobalToast({
            show: true,
            message: "CRM Sheet sync complete",
            severity: "success",
          })
        );
      } catch (error) {
        let err = "";
        if (getAxiosError(error)) err = getAxiosError(error);
        console.log(err, "onsyncpress error");
        if (err?.error?.status === "UNAUTHENTICATED") {
          dispatch(
            setGlobalToast({
              show: true,
              title: "Sync Failed",
              message: "Google has been revoked, Kindly reintegrate again",
              severity: "error",
            })
          );
        } else {
          dispatch(
            setGlobalToast({
              show: true,
              title: "Sync Failed",
              message: "Failed to get Google sheet data, please try again",
              severity: "error",
            })
          );
        }
        setShowSync(false);
        setCRMSheetLoading(false);
        setSyncProgress((state) => 0);
      }
    };

    const getCustomerIdFromAllCustomers = (allCustomers, customer) => {
      if (!isArrayWithValues(allCustomers) || !isObjWithValues(customer))
        return;
      let findCustomer = allCustomers.find((i) => {
        let temp = false;
        let { meta_data: old_meta_data = [] } = i;
        let { meta_data = [] } = customer;
        let obj = { ...customer };
        let oldObj = { ...i };
        for (let metaObj of old_meta_data) oldObj[metaObj.key] = metaObj.value;
        for (let metaObj of meta_data) obj[metaObj.key] = metaObj.value;

        if (customer.email)
          if (oldObj.email === customer.email) temp = true;
          else if (oldObj?.billing?.email === customer.email) temp = true;
          else if (oldObj?.username === customer.email) temp = true;
        if (obj?.billing?.phone)
          if (oldObj?.billing?.phone === obj?.billing?.phone) temp = true;
          else if (oldObj?.username === obj?.billing?.phone) temp = true;
        if (obj?.whatsapp)
          if (oldObj?.whatsapp === obj?.whatsapp) temp = true;
          else if (oldObj?.username === obj?.whatsapp) temp = true;
        return temp;
      });
      return findCustomer?.id || false;
    };

    const addAndUpdateBulkProducts = async (
      bulkObj,
      setSyncLoading,
      sheetErrors
    ) => {
      // const WooCommerce = await getWooCommerce();
      const website = getWebsite();
      const token = getToken();
      let length = 0;
      let createIds = [];
      let batchLength = 0;
      let batchLengthFloat = 0;
      if (Array.isArray(bulkObj.update) && Array.isArray(bulkObj.create)) {
        length += bulkObj.update.length;
        length += bulkObj.create.length;
      }
      if (length) {
        batchLength = Math.ceil(length / 25);
        batchLengthFloat = parseFloat(length / 25);
      } else return bulkObj;
      // console.log(length);
      // return;

      if (length / 25 > batchLength) batchLength += 1;

      let currentBatch = 1;
      let tempBulkObj = { ...bulkObj };

      let responseObj = {
        create: [],
        update: [],
      };

      let flag = 0;
      for await (let i of Array(batchLength)) {
        flag++;
        let tempBatchObj = {
          create: [],
          update: [],
        };
        if (tempBulkObj.create.length >= 25) {
          let { create } = tempBulkObj;
          tempBatchObj.create = create.splice(1, 25);
          tempBulkObj.create = [...create];
        } else if (
          tempBulkObj.create.length < 25
          // &&tempBulkObj.create.length > 0
        ) {
          tempBatchObj.create = [...tempBulkObj.create];
          if (tempBulkObj.update.length >= 25) {
            let { update } = tempBulkObj;
            tempBatchObj.update = update.splice(
              0,
              25 - tempBatchObj.create.length
            );
            tempBulkObj.update = [...update];
          } else if (
            tempBulkObj.update.length < 25 &&
            tempBulkObj.update.length > 0
          ) {
            let createLength = tempBatchObj.create.length;
            if (tempBulkObj.update.length > 25 - createLength) {
              let { update } = tempBulkObj;
              tempBatchObj.update = update.splice(0, 25 - createLength);
              tempBulkObj.update = [...update];
            } else {
              let { update } = tempBulkObj;
              tempBatchObj.update = update;
              tempBulkObj.update = [];
            }
            // if (
            //   100 - tempBulkObj.update.length + tempBulkObj.create.length >
            //   100
            // ) {
            //   let { update } = tempBulkObj;
            //   tempBatchObj.update = update.splice(0, 100 - tempBulkObj.update.length)
            // }
          }
        }

        // if (tempBatchObj.create.length >= 25) {
        //   let { create } = tempBatchObj;
        //   let temp = create.splice(25, 100);
        //   tempBatchObj.create = create;
        //   tempBulkObj.create = [...temp, ...tempBulkObj.create];
        // }

        // continue;

        try {
          if (isArrayWithValues(tempBatchObj.create)) {
            let updatedCustomers = [];
            for await (let customer of tempBatchObj.create) {
              let obj = { ...customer };
              let tempObj = {};
              let { billing, meta_data } = customer;
              let metaKeys = [
                "dialcode_mobile",
                "dialcode_whatsapp",
                "whatsapp",
              ];
              if (isArrayWithValues(meta_data)) {
                for (let metaObj of meta_data)
                  if (metaKeys.includes(metaObj.key))
                    tempObj[metaObj.key] = metaObj.value;

                let data = await createCustomCustomer({
                  value:
                    obj?.email ||
                    obj?.billing?.email ||
                    obj?.billing?.phone ||
                    tempObj?.whatsapp,
                });
                if (data.success) {
                  createIds.push(data.data);
                  updatedCustomers.push({ id: data.data, ...customer });
                }
              }
            }
            tempBatchObj.create = [];
            tempBatchObj.update = tempBatchObj.update.concat(updatedCustomers);
          }
        } catch (error) {}
        console.log(tempBatchObj, "BATCH OBJ");

        try {
          let res = await axios({
            url: `${website}/wp-json/wc/v3/customers/batch`,
            headers: {
              Authorization: `Basic ${token}`,
            },
            method: "POST",
            data: { ...tempBatchObj },
          });
          // const res = await WooCommerce.post("products/batch", bulkObj);
          let { data } = res;
          // console.log(data.create, tempBatchObj.create);
          if (
            isArrayWithValues(data.create) &&
            isArrayWithValues(tempBatchObj.create)
          ) {
            let index = 0;
            let createArray = [];
            for (let productRes of data.create) {
              let productObj = tempBatchObj.create[index];
              createArray.push({
                ...productRes,
                index: productObj.index,
                error: productRes.error,
              });
              // if (isObjWithValues(productRes.error)) {
              //   let { message } = productRes.error;
              //   console.log(message, "ERROR MEsSAGE");
              //   sheetErrors = sheetErrors[productObj.index]
              //     ? {
              //         ...sheetErrors,
              //         [productObj.index]: {
              //           ...sheetErrors[productObj.index],
              //           reasons: [
              //             ...(sheetErrors[productObj.index].reasons || []),
              //             message,
              //           ],
              //         },
              //       }
              //     : {
              //         ...sheetErrors,
              //         [productObj.index]: {
              //           sku: productObj.sku,
              //           index: productObj.index,
              //           reasons: [message],
              //         },
              //       };
              //   // setSheetErrors((state) =>

              //   // );
              // }
              index++;
            }
            // responseObj.create = createArray.concat(data.create);
            // responseObj.create = responseObj.create.concat(createArray);
          }
          if (
            isArrayWithValues(data.update) &&
            isArrayWithValues(tempBatchObj.update)
          ) {
            let updateArray = [];
            let createArray = [];
            let index = 0;
            for (let productRes of data.update) {
              let productObj = tempBatchObj.update[index];
              if (createIds.includes(productObj.id))
                createArray.push({
                  ...productRes,
                  index: productObj.index,
                  error: productRes.error,
                });
              else
                updateArray.push({
                  ...productRes,
                  index: productObj.index,
                  error: productRes.error,
                });
              index++;
            }
            // responseObj.update = updateArray.concat(data.update);
            responseObj.create = responseObj.create.concat(createArray);
            responseObj.update = responseObj.update.concat(updateArray);
          }
          // return res.data;
          console.log(data, `BATCH ${flag} is Updated`);
        } catch (error) {
          console.log(getAxiosError(error), "BATCH UPDATE ERROR");
        }
        await new Promise((r) => setTimeout(r, 2000));

        if (batchLength === flag) {
          console.log(responseObj, "RESPONSE OBJ");
          setSyncLoading(90);
          return responseObj;
        } else
          setSyncLoading((state) =>
            state + 60 / batchLengthFloat >= 90
              ? 90
              : state + 60 / batchLengthFloat
          );
      }
      return {};
    };

    const addHistroyObj = async (obj) => {
      let website = await getWebsite();
      let token = await getToken();
      if (website && token) {
        try {
          let { data } = await axios({
            url: `${website}/wp-json/store/v1/settings/crm_spreadsheet_history`,
            headers: { Authorization: `Basic ${token}` },
            method: "PUT",
            data: { type: `crm_spreadsheet_history`, data: obj },
          });
          if (data.success && isObjWithValues(data.data))
            dispatch(editCRM({ crmSpreadsheetHistory: data.data }));
          return true;
        } catch (error) {
          return false;
        }
      }
    };

    const getDropdownData = (professions) => {
      let obj = {
        actions: ["create", "update", "synced"],
        ethincity: ethincityArray,
        language: languageArray,
        religion: religionArray,
      };
      if (isArrayWithValues(professions))
        obj.profession = professions.map((i) => i.label).slice(0, 500);
      return obj;
    };

    const getSpreadsheetDropdowns = (sheetId, headerArray, dropdownData) => {
      if (isArrayWithValues(headerArray) && isObjWithValues(dropdownData)) {
        console.log(headerArray);
        let validations = {
          ethincity: {
            index: headerArray.indexOf("Ethnicity"),
            type: "ONE_OF_LIST",
            values: dropdownData.ethincity,
          },
          language: {
            index: headerArray.indexOf("Language"),
            type: "ONE_OF_LIST",
            values: dropdownData.language,
          },
          religion: {
            index: headerArray.indexOf("Religion"),
            type: "ONE_OF_LIST",
            values: dropdownData.religion,
          },
          actions: {
            index: headerArray.indexOf("Actions"),
            type: "ONE_OF_LIST",
            values: dropdownData.actions,
          },
          profession: {
            index: headerArray.indexOf("Profession"),
            type: "ONE_OF_LIST",
            values: dropdownData.profession,
          },
        };

        const keys = Object.keys(validations);
        const length = Object.keys(validations).length;
        const data = [];
        for (let i = 0; i < length; i++) {
          let value = validations[keys[i]];
          let { values } = value;
          let dropdownData = values.map((i) => [{ userEnteredValue: i }]);
          data.push({
            setDataValidation: {
              range: {
                sheetId,
                startRowIndex: 1,
                startColumnIndex: value.index,
                endColumnIndex: value.index + 1,
              },
              rule: {
                condition: {
                  type: value.type,
                  values: dropdownData,
                },
                showCustomUi: true,
              },
            },
          });
        }
        // console.log(JSON.stringify(data, null, 2));
        return data;
      }
    };

    const getBulkCreateUpdateObject = (sheetData) => {
      let headerRow = sheetData[0];
      let data = { create: [], update: [] };
      let actionIndex = headerRow?.findIndex((i) => i === "Actions");
      for (let productArray of sheetData) {
        let action = productArray?.[actionIndex];
        console.log(action, "<<< action");
        if (!action) continue;
        let product = getProductObjectFromSheetArray(headerRow, productArray);
        if (action === "create") {
          product.password = "password";
        }
        if (action === "create" || action === "update")
          data?.[action]?.push(product);
      }
      return data;
    };

    const getProductObjectFromSheetArray = (headerRow, productArray) => {
      let customerObj = {
        // index,
      };
      let length = headerRow.length;
      let whiteSpacesToRemove = [
        "phone",
        "whatsapp",
        "dialcode_whatsapp",
        "dialcode_mobile",
        "email",
      ];
      for (let i = 0; i < length; i++) {
        let keyObj = sheetHeaders.find((obj) => obj.label === headerRow[i]);
        if (keyObj) {
          if (whiteSpacesToRemove?.includes(keyObj.value))
            customerObj[keyObj.value] = `${productArray?.[i]}`
              .replace(/\s/g, "")
              .trim();
          else customerObj[keyObj.value] = productArray?.[i];
        }
      }
      // if (customerObj.phone)
      //   customerObj.phone = `${customerObj.phone}`?.replace(/\s/g, "").trim();
      // if (customerObj.whatsapp)
      //   customerObj.whatsapp = `${customerObj.whatsapp}`
      //     ?.replace(/\s/g, "")
      //     .trim();
      // if (customerObj.dialcode_whatsapp)
      //   customerObj.dialcode_whatsapp = `${customerObj.dialcode_whatsapp}`
      // if (customerObj.dial)
      //   customerObj.dial = `${customerObj.dial}`
      //     ?.replace(/\s/g, "")
      //     .trim();
      let storeId = getStoreId();
      let meta_keys = [
        "dialcode_mobile",
        "phone",
        "dialcode_whatsapp",
        "whatsapp",
        `group_name_${storeId}`,
        "address_1",
        "address_2",
        "city",
        "postcode",
        "state",
        "country",
        "customer_since",
        "first_purchase",
        "total_purchase",
        "visits_in_showroom",
        "last_visit_in_showrrom",
        "visits_on_website",
        "last_visit_on_website",
        "user_birthday",
        "user_anniversary",
        "profession",
        "annual_income",
        "ethincity",
        "language",
        "religion",
        "Sub-Sect",
        "customer_facebook",
        "customer_instagram",
        "customer_twitter",
        "customer_linkedin",
        "customer_youtube",
        "customer_pinterest",
        "branch_from",
        "facebook_id",
        "whatsapp_id",
        "has_call_logs",
      ];
      let billingKeys = [
        "first_name",
        "last_name",
        "company",
        "address_1",
        "address_2",
        "city",
        "postcode",
        "country",
        "state",
        "email",
        "phone",
      ];
      let showInObject = ["first_name", "last_name", "email", "phone"];

      let meta_data = [];
      let billing = {};
      for (let i of billingKeys) {
        if (customerObj.hasOwnProperty(i)) billing[i] = customerObj[i];
        if (!showInObject.includes(i)) delete customerObj[i];
      }

      if (customerObj[`group_name_${storeId}`])
        customerObj[`group_name_${storeId}`] =
          customerObj[`group_name_${storeId}`].split(",");

      for (let i in meta_keys)
        if (customerObj.hasOwnProperty([meta_keys[i]])) {
          meta_data.push({
            key: meta_keys[i],
            value: customerObj[meta_keys[i]] || "",
          });
          delete customerObj[meta_keys[i]];
        }
      customerObj.meta_data = meta_data;
      customerObj.billing = billing;
      if (!customerObj.email || !customerObj?.billing?.email) {
        delete customerObj?.email;
        delete customerObj?.billing?.email;
      }

      return customerObj;
    };

    const createSpreadSheetData = (customers, allCustomerGroup) => {
      let storeId = getStoreId();
      let spreadsheetArray = [];
      spreadsheetArray.push(sheetHeaders.map((i) => i.label));
      for (let customer of customers) {
        let { meta_data, billing } = customer;
        if (isArrayWithValues(meta_data)) {
          for (let metaObj of meta_data) customer[metaObj.key] = metaObj.value;
        }
        if (customer.error_message) customer = { ...customer };
        else customer = { ...customer, action: "synced" };
        if (isObjWithValues(billing))
          for (let key in billing)
            if (billing[key]) customer[key] = billing[key];
        if (isArrayWithValues(customer[`group_name_${storeId}`])) {
          let labelNameArray =
            allCustomerGroup?.map((obj) => obj?.group_name) || [];
          // console.log(customer[`group_name_${storeId}`]?.filter(str => labelNameArray?.includes(str)), labelNameArray);
          customer[`group_name_${storeId}`] = customer[`group_name_${storeId}`]
            ?.filter((str) => labelNameArray?.includes(str))
            .join();
        }
        spreadsheetArray.push(
          sheetHeaders.map((i) =>
            typeof customer[i.value] === "string" && customer[i.value]
              ? customer[i.value]
              : ""
          )
        );
      }
      return spreadsheetArray;
    };

    const getChannelData = async (app, endpoint, options = {}) => {
      let website = await getWebsite();
      let { access_token } = options;
      let requestObj = {};
      let startRange = 1,
        endRange = 3000;
      let increase_by = 3000;
      if (access_token) {
        requestObj = {
          ...requestObj,
          url: `https://sheets.googleapis.com/v4${endpoint}`,
          method: "GET",
          params: { access_token },
        };
      } else {
        requestObj = {
          ...requestObj,
          url: `${website}/wp-json/store/v1/channel/${app}`,
          method: "GET",
          params: {
            endpoint,
          },
        };
      }
      let rows = [];
      for (let i of Array.from(new Array(6).fill(1))) {
        // requestObj.url = requestObj.url + `&ranges=${startRange}:${endRange}`;
        requestObj.params.ranges = `${startRange}:${endRange}`;
        try {
          let { data } = await axios(requestObj);
          rows = rows.concat(data?.valueRanges[0].values);
          if (!isArrayWithValues(data?.valueRanges[0].values)) break;
          startRange = startRange + increase_by;
          endRange = endRange + increase_by;
        } catch (error) {
          break;
        }
      }
      return rows;
    };
    const createSpreadsheet = async () => {
      let website = await getWebsite();
      let token = await getToken();
      if (website && token) {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/channel/google_sheets`,
          params: { endpoint: "/spreadsheets" },
          method: "POST",
          data: {
            payload: {
              properties: {
                title: "Jwero - CRM Sheet",
              },
            },
          },
        });
        if (data.spreadsheetId) {
          let { data: updateData } = await axios({
            url: `${website}/wp-json/store/v1/settings/google_spreadsheet`,
            headers: {
              Authorization: `Basic ${token}`,
            },
            data: {
              type: "google_spreadsheet",
              data: {
                crm_spreadsheet_id: data.spreadsheetId,
              },
            },
            method: "PUT",
          });
          if (updateData.success) {
            dispatch({
              type: EDIT_SETTINGS,
              payload: {
                googleSpreadsheet: updateData.data,
              },
            });
          }
          return data.spreadsheetId;
        }
      }
    };

    const getAllCustomers = async (setSyncProgress, total) => {
      let fetchBatchLength = 100;
      let batchLength = Math.ceil(total / fetchBatchLength) + 1;
      let getProductsTotalProgress = 30;
      let addToProgress = Number(getProductsTotalProgress / batchLength);

      let page = 1;
      let allCustomers = [];
      for await (let i of Array(5000)) {
        let customers = await getCustomers({
          per_page: fetchBatchLength,
          page,
        });
        allCustomers = allCustomers.concat(customers);
        setSyncProgress((state) =>
          state + addToProgress >= getProductsTotalProgress
            ? getProductsTotalProgress
            : state + addToProgress
        );
        page += 1;
        if (customers.length < fetchBatchLength) break;
      }
      return allCustomers;

      // let website = await getWebsite();
      // let token = await getToken();
      // if (website && token) {
      // }
    };

    const getCustomers = async (params) => {
      let website = await getWebsite();
      let token = await getToken();
      if (website && token) {
        try {
          let { data } = await axios({
            url: `${website}/wp-json/wc/v3/customers`,
            headers: { Authorization: `Basic ${token}` },
            params,
          });
          return data;
        } catch (error) {
          return [];
        }
      }
    };

    let sheetHeaders = [
      { label: "Actions", value: "action" },
      { label: "Errors", value: "error_message" },
      { label: "Registered Date", value: "date_created" },
      { label: "First Name", value: "first_name" },
      { label: "Last Name", value: "last_name" },
      { label: "Email", value: "email" },
      // { label: "Nickname", value: "nickname" },
      { label: "Dial Code 1", value: "dialcode_mobile" },
      { label: "Phone", value: "phone" },
      { label: "Dial Code 2", value: "dialcode_whatsapp" },
      { label: "Whatsapp", value: "whatsapp" },
      { label: "Groupings", value: `group_name_${storeId}` },
      { label: "Address Line 1", value: "address_1" },
      { label: "Address Line 2", value: "address_2" },
      { label: "City", value: "city" },
      { label: "Postcode", value: "postcode" },
      { label: "State", value: "state" },
      { label: "Country", value: "country" },
      { label: "Branch", value: "branch_from" },
      { label: "Customer Since", value: "customer_since" },
      { label: "First Purchase", value: "first_purchase" },
      { label: "Total Purchase", value: "total_purchase" },
      { label: "Visits In Showroom", value: "visits_in_showroom" },
      { label: "Last Visit In Showroom", value: "last_visit_in_showrrom" },
      { label: "Visits On Website", value: "visits_on_website" },
      { label: "Last Visit On Website", value: "last_visit_on_website" },
      { label: "Birthday", value: "user_birthday" },
      { label: "Anniversary", value: "user_anniversary" },
      { label: "Profession", value: "profession" },
      { label: "Annual Income", value: "annual_income" },
      { label: "Ethnicity", value: "ethincity" },
      { label: "Language", value: "language" },
      { label: "Religion", value: "religion" },
      { label: "Sub-Sect", value: "Sub-Sect" },
      { label: "Facebook", value: "customer_facebook" },
      { label: "Instagram", value: "customer_instagram" },
      { label: "Twitter", value: "customer_twitter" },
      { label: "Linkedin", value: "customer_linkedin" },
      { label: "YouTube", value: "customer_youtube" },
      { label: "Pinterest", value: "customer_pinterest" },
    ];

    // const getSpreadsheetId = async () => {
    //   let website = await getWebsite();
    //   let token = await getToken();
    //   let { data } = await axios({
    //     url: `${website}/wp-json/store/v1/settings/google_spreadsheet`,
    //     headers: {
    //       Authorization: `Basic ${token}`,
    //     },
    //     data: {
    //       type: "google_spreadsheet",
    //       data: {
    //         spreadSheet_product_id: createSpreadSheet.data.spreadsheetId,
    //       },
    //     },
    //     method: "PUT",
    //   });
    // };

    const onFilterReset = () => {
      setFilterForm(defaultFilterForm);
      setStateArray([{ key: "", value: "" }]);
      filterSubmitHandlerOnClick({ reset: true });
    };

    const onBulkEdit = () => {
      dispatch(
        editDataSheet({
          customers_edit: selected,
        })
      );
      navigate("/crm_bulk_edit");
    };
    const closeShowJweroSync = () => {
      setShowJweroSync(false);
    };

    return (
      <Box
        sx={{
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <JweroSyncHistory
          type={"customer"}
          showSync={showJweroSync}
          closeSync={closeShowJweroSync}
        />
        {smsOpen && (
          <SendSms
            handleClose={() => setSmsOpen(false)}
            selected={selected}
            sendWithCampagin={true}
            open={smsOpen}
          />
        )}
        <>
          <TableContainer
            sx={{
              width: "100%",
              height: "calc(100vh - 95px)",
              backgroundColor: "#fff",
              // overflow:",
              pt: 2,
            }}
            className="scrollbar-hidden"
          >
            <Table
              aria-label="simple table"
              sx={{ display: "block", position: "relative" }}
            >
              <TableHead sx={{ width: "100%", display: "block" }}>
                <TableRow sx={{ width: "100%", display: "block" }}>
                  <TableCell
                    sx={{ padding: 0, pb: 2, width: "100%", display: "block" }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      px={2}
                      mt={2}
                      sx={{ minHeight: "51.5px", paddingRight: "10px" }}
                      gap={"10px"}
                    >
                      <TextField
                        type={"search"}
                        onKeyDown={searchSubmitHandler}
                        sx={{
                          // transform: viewSearch ? "scaleY(1)" : "scaleY(0)",
                          width: viewSearch ? "100%" : "0%",
                          // height: viewSearch ? "100%" : "0%",
                          overflow: "hidden",
                          // display: viewSearch ? "block" : "none",
                          transition: `all ${viewSearch ? "0.3s" : "0s"}`,
                        }}
                        // autoFocus
                        size="small"
                        ref={customerSearchTextfieldRef}
                        placeholder="Search"
                        variant="standard"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment sx={{ mb: 1 }} position="start">
                              <Search color="action" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ cursor: "pointer", mb: 1 }}
                            >
                              <Close
                                color="action"
                                onClick={() => {
                                  seViewSearch(false);
                                  if (params.custom_search) {
                                    setParams({
                                      ...params,
                                      search: "",
                                      custom_search: "",
                                    });
                                    setSearch("");
                                    onSearch("");
                                  }
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        onSubmit={searchSubmitHandler}
                        fullWidth
                      />

                      {/* <Menu
                        anchorEl={anchorElOption}
                        id="account-menu"
                        open={optionOpen}
                        onClose={optionClose}
                        onClick={optionClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        {(isDev() || isOurWebsite()) && false ? (
                          <MenuItem
                            onClick={() => setOpenCampaignHistory(true)}
                          >
                            Campaign
                          </MenuItem>
                        ) : null}
                      </Menu> */}
                      <Menu
                        anchorEl={addCustomerAnchor}
                        id="add-customer"
                        open={addCustomerOpen}
                        onClose={addCustomerClose}
                        onClick={addCustomerClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem onClick={() => setAddFormView(true)}>
                          Add Customer
                        </MenuItem>
                        <MenuItem onClick={() => navigate("/crm_bulk_add")}>
                          Add Bulk Customers
                        </MenuItem>
                      </Menu>
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={bulkActionOpen}
                        onClose={bulkActionClose}
                        onClick={bulkActionClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem onClick={() => setShowBulkGroupAdd(true)}>
                          Add to Group
                        </MenuItem>
                        <MenuItem onClick={() => setShowBulkTemplate(true)}>
                          Whatsapp Message
                        </MenuItem>
                        {/* {isDev() || isOurWebsite() ? (
                        ) : null} */}
                        <MenuItem
                          onClick={() => {
                            setIsCampaign(false);
                            setEmailOpen(true);
                          }}
                        >
                          Send Email
                        </MenuItem>
                        <MenuItem
                          onClick={() => setShowBulkDeleteWarning(true)}
                        >
                          Bulk Delete
                        </MenuItem>
                        <MenuItem onClick={() => setSmsOpen(true)}>
                          Send SMS
                        </MenuItem>
                        {isDev() && (
                          <MenuItem
                            onClick={() => setpushNotificationOpen(true)}
                          >
                            Send Push Notification
                          </MenuItem>
                        )}
                        {/* {(isDev() || isOurWebsite()) && (
                          )} */}
                        <MenuItem onClick={onBulkEdit}>Bulk Edit</MenuItem>
                      </Menu>
                      {pushNotificationOpen && (
                        <Dialog
                          fullWidth
                          maxWidth="md"
                          sx={dialogStyle}
                          // fullScreen={fullScreen}
                          open={pushNotificationOpen}
                          onClose={() => setpushNotificationOpen(false)}
                          aria-labelledby="responsive-dialog-title"
                          {...globalPopupStyle}
                        >
                          <DialogTitle id="responsive-dialog-title">
                            Push notifications
                          </DialogTitle>
                          <DialogContent>
                            <SendNotification
                              buttonLabel={"Send Notification"}
                              sendBulk={true}
                              selected={selected}
                              onClose={() => setpushNotificationOpen(false)}
                            />
                          </DialogContent>
                        </Dialog>
                      )}
                      {!viewSearch && (
                        <Stack
                          direction={"row"}
                          sx={{ width: "100%" }}
                          justifyContent="space-around"
                        >
                          <Stack
                            justifyContent={"center"}
                            alignItems="center"
                            sx={{ cursor: "pointer" }}
                            rowGap={"8px"}
                            onClick={() => {
                              seViewSearch(true);
                              customerSearchTextfieldRef?.current?.focus();
                            }}
                          >
                            <Search
                              color="action"
                              style={{ cursor: "pointer" }}
                            />
                            <Typography color={"text.secondary"}>
                              Search
                            </Typography>
                          </Stack>

                          <Stack
                            justifyContent={"center"}
                            alignItems="center"
                            rowGap={"8px"}
                            onClick={
                              () =>
                                // isOurWebsite()
                                // ?
                                setShowJweroSync(true)
                              // : openSync()
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            <CachedOutlined
                              color="action"
                              style={{ cursor: "pointer" }}
                            />
                            <Typography color={"text.secondary"}>
                              Sync
                            </Typography>
                          </Stack>
                          <Stack
                            justifyContent={"center"}
                            alignItems="center"
                            rowGap={"8px"}
                            sx={{ cursor: "pointer" }}
                            onClick={(e) => {
                              console.log(
                                crmAccess,
                                isAdmin,
                                addLimitReached,
                                isArray(crmAccess) && crmAccess.includes("add")
                              );
                              ((isArray(crmAccess) &&
                                crmAccess.includes("add")) ||
                                isAdmin) &&
                              !addLimitReached
                                ? addCustomerClick(e)
                                : // ? setAddFormView(true)
                                  null;
                            }}
                          >
                            <PersonAddAltOutlined
                              color="action"
                              disabled={
                                (isArray(crmAccess) &&
                                  !isAdmin &&
                                  !crmAccess.includes("add")) ||
                                addLimitReached
                              }
                              style={{ cursor: "pointer" }}
                            />
                            <Typography color={"text.secondary"}>
                              Add
                            </Typography>
                          </Stack>
                          <Stack
                            justifyContent={"center"}
                            alignItems="center"
                            rowGap={"8px"}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              setFilterView(true);
                            }}
                          >
                            <Badge
                              color="secondary"
                              // variant="dot"
                              badgeContent={totalFilters}
                              invisible={!Boolean(totalFilters)}
                            >
                              <FilterAltOutlined
                                color="action"
                                style={{ cursor: "pointer" }}
                              />
                            </Badge>
                            <Typography color={"text.secondary"}>
                              Filter
                            </Typography>
                          </Stack>
                          {showSections.campaign &&
                          (isDev() || isOurWebsite()) &&
                          false ? (
                            <Stack
                              justifyContent={"center"}
                              alignItems="center"
                              rowGap={"8px"}
                              sx={{ cursor: "pointer" }}
                              onClick={() => setOpenCampaignHistory(true)}

                              // onClick={optionClick}
                            >
                              <CampaignIconSvg
                                color="action"
                                style={{ cursor: "pointer" }}
                              />
                              <Typography color={"text.secondary"}>
                                Campaign
                              </Typography>
                            </Stack>
                          ) : null}
                        </Stack>
                      )}
                      <Stack
                        style={{
                          cursor: "pointer",
                          // display: "none",
                          display: selected.length ? "flex" : "none",
                          "&:active": {
                            animation: "scale-display .3s",
                            display: "inline-flex",
                          },
                          "&:out": {
                            animation: " scale-display--reversed .3s",
                            animationFillMode: "forwards",
                            display: " inline-flex",
                          },

                          // maxWidth: selected.length ? "100%" : "0%",
                          // visibility: selected.length ? "visible" : "hidden",
                          // transform: selected.length ? "scale(1)" : "scale(0)",
                          // opacity: selected.length ? "1" : "0",
                          // overflow: "hidden",
                          transition: "all 0.4s",
                        }}
                        justifyContent="center"
                        alignItems={"center"}
                        onClick={bulkActionClick}
                      >
                        <Badge
                          badgeContent={selected.length}
                          sx={{
                            display: selected.length ? "block" : "none",
                          }}
                          color="primary"
                        >
                          <ManageAccountsOutlined color="action" />
                        </Badge>
                        {!viewSearch && (
                          <Typography color={"text.secondary"}>
                            Actions
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{
                    // position: "absolute",
                    // left: 0,
                    // right: 0,
                    // bottom: 0,
                    // zIndex: 10,
                    // color: 'blue'
                    width: "100%",
                    display: "block",
                    flex: 1,
                  }}
                >
                  <TableCell
                    colSpan={10}
                    component="th"
                    sx={{
                      width: "100%",
                      p: 0,
                      flex: 1,
                      display: "flex",
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      justifyContent="space-between"
                      pl={4}
                      sx={{
                        // maxHeight: selected.length ? "38px" : "0px",
                        // transition: "all 0.3s",
                        overflow: "hidden",
                        width: "100%",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={isSelectedAll}
                            onChange={onSelectAll}
                          />
                        }
                        label="Select All"
                      />
                      <Typography sx={{ ...primaryLabelSmall, mr: 2 }}>
                        Total: {totalCustomersFound}
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                className="scrollbar-hidden"
                style={{
                  height: selected.length
                    ? "calc(100vh - 210px)"
                    : "calc(100vh - 172px)",
                  overflow: "scroll",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  transition: "all 0.3s",
                }}
              >
                {!fetchingCustomers && !isArrayWithValues(allCustomers) && (
                  <Box sx={{ textAlign: "center", marginTop: "25vh" }}>
                    <Person
                      style={{ width: 50, height: 50, fill: "#d1d1d1" }}
                    />
                    <Typography
                      sx={{
                        ...secondaryLabel,
                        mt: 3,
                        fill: "#92929299",
                        fontWeight: "600",
                      }}
                    >
                      No Customers
                    </Typography>
                  </Box>
                )}
                {fetchingCustomers ? (
                  <>
                    {/* <TableRow
                  style={{
                    height: (true ? 33 : 53) * emptyRows,
                  }}
                > */}
                    {new Array(emptyRows).fill(1).map((i, index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow key={index} sx={{ display: "contents" }}>
                            <TableCell colSpan={1}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                // sx={{ padding: "5px 10px" }}
                              >
                                <Skeleton
                                  variant="circular"
                                  width={50}
                                  height={40}
                                />
                                <Box sx={{ ml: 2, width: "100%" }}>
                                  <Skeleton
                                    variant="text"
                                    width={"90%"}
                                    height={25}
                                  />
                                  <Skeleton
                                    variant="text"
                                    width={"75%"}
                                    height={20}
                                    sx={{ mt: 0.5 }}
                                  />
                                </Box>
                              </Stack>
                            </TableCell>
                          </TableRow>
                          {/* <div style={{ alignItems: "center" }}>
                          {customerPlaceholder()}
                        </div> */}
                        </React.Fragment>
                      );
                    })}
                    {/* </TableRow> */}
                  </>
                ) : (
                  allCustomers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <React.Fragment key={row.id}>
                        <EnhancedTableRow
                          row={row}
                          isSide={isSide}
                          onClick={() => {
                            changeRouteHandler(row.id);
                            // isIpad && setDrawerView(false);
                          }}
                          setSelected={setSelected}
                          selected={selected}
                        />
                      </React.Fragment>
                    ))
                )}
                {!fetchingCustomers && isArrayWithValues(allCustomers) ? (
                  <TableRow
                    sx={{
                      display: "block",
                      position: "sticky",
                      bottom: "0px",
                      // zIndex: 4
                      // position: "absolute",
                      // left: 0,
                      // right: 0,
                      // bottom: 0,
                      // zIndex: 10,
                      // color: 'blue'
                    }}
                  >
                    <Paper>
                      <TableCell
                        sx={{ padding: 0, display: "block", width: "100%" }}
                      >
                        {/* <Stack
                  direction="row"
                  sx={{ py: 3, px: 2 }}
                  justifyContent="flex-end"
                  // justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "text.secondary",
                      mr: 2,
                      fontWeight: "600",
                    }}
                  >
                    1 - {allCustomers?.length} of 240
                  </Typography>
                  <IconButton size="small">
                    <ArrowBackIos style={{ height: "18px", width: "18px" }} />
                  </IconButton>
                  <IconButton size="small">
                    <ArrowForwardIos
                      style={{ height: "18px", width: "18px" }}
                    />
                  </IconButton>
                </Stack> */}
                        <Divider />
                        <TablePagination
                          sx={{
                            // maxWidth: "fit-content",
                            ".MuiTablePagination-selectLabel": {
                              display: "none",
                            },
                            ".MuiTablePagination-toolbar": {
                              padding: 0,
                            },
                          }}
                          className="scrollbar-hidden"
                          component="div"
                          count={allReduxCustomers.length}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPageOptions={[5, 10, 20]}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableCell>
                    </Paper>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </>
        {emailOpen && (
          <EmailTemplate
            setEmailOpen={setEmailOpen}
            emailOpen={emailOpen}
            selected={selected}
            setSelected={setSelected}
            withFilters={withFilters}
            setWithFilters={setWithFilters}
            openCampaignHistory={openCampaignHistory}
            setOpenCampaignHistory={setOpenCampaignHistory}
            isPhone={isPhone}
            isCampaign={isCampaign}
          />
        )}
        <TempleteList
          open={showBulkTemplate}
          handleClose={() => setShowBulkTemplate(false)}
          setParentTemplate={onSendBulkTemplate}
          hideRejected
        />
        <Dialog
          open={showSync}
          onClose={() => setShowSync(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
          sx={{ ...dialogStyle }}
        >
          {/* <DialogTitle    id="alert-dialog-title">CRM Sheet</DialogTitle> */}
          <DialogContent>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              mb={3}
              flexWrap="wrap"
            >
              <Stack
                direction={"row"}
                alignItems="center"
                sx={{ my: 2 }}
                spacing={2}
              >
                <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
                  CRM Sheet
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems={"center"}
                justifyContent={"flex-end"}
                spacing={1}
                pr={1}
                flex={1}
              >
                <Tooltip
                  title={
                    !googleSheetIntegrated ? "Google Sheet not Integrated" : ""
                  }
                >
                  <div>
                    <LoadingButton
                      loading={crmSheetLoading}
                      endIcon={<Sync />}
                      size="small"
                      variant="contained"
                      onClick={() => onSyncPress()}
                      sx={{ whiteSpace: "nowrap" }}
                      disabled={!googleSheetIntegrated}
                    >
                      Sync CRM
                    </LoadingButton>
                  </div>
                </Tooltip>

                <Tooltip
                  title={
                    !googleSheetIntegrated ? "Google Sheet not Integrated" : ""
                  }
                >
                  <div>
                    <LoadingButton
                      loading={openSheetLoading}
                      endIcon={<Launch />}
                      size="small"
                      variant="outlined"
                      onClick={openSheet}
                      sx={{ whiteSpace: "nowrap" }}
                      disabled={!googleSheetIntegrated}
                    >
                      Open Sheet
                    </LoadingButton>
                  </div>
                </Tooltip>
              </Stack>
            </Stack>
            {googleSheetIntegrated ? (
              isObjWithValues(crmSpreadsheetHistory) ? (
                <>
                  <CRMSheetHistory />
                </>
              ) : (
                <DataNotFound
                  illustration={
                    <Description
                      style={{
                        width: "100px",
                        height: "100px",
                        color: "#d7d7d7",
                      }}
                    />
                  }
                  subtitle={"No history created yet"}
                />
              )
            ) : (
              <>
                <DataNotFound
                  illustration={
                    <NoSheets
                      width="130px"
                      height="130px"
                      // className="animated_background_color"
                    />
                  }
                  subtitle={
                    "Oops. Seems your Google Sheets is not integrated, Lets go to integrations and do it right!"
                    // "To access chats dashboard you need to integrate atleast one social media and select page"
                    // "Google sheets integration not found"
                  }
                  title={""}
                  createButton={
                    <Button
                      variant="contained"
                      onClick={() => navigate("/integrations#Google")}
                      // size={"small"}
                      sx={{ mt: 3 }}
                    >
                      Integrate Google Sheets
                    </Button>
                  }
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              startIcon={<Close />}
              onClick={closeSync}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openCampaignHistory}
          onClose={() => {
            setOpenCampaignHistory(false);
            setEmailOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={campaignHistoryMaxWidth || "md"}
          fullWidth
          fullScreen={true}
          // sx={
          //   isPhone
          //     ? {}
          //     : {
          //         ...dialogStyle,
          //         ".MuiDialog-paper": {
          //           padding: "9px 7px",
          //           borderRadius: "30px",
          //           minHeight: "85vh",
          //         },
          //       }
          // }
        >
          {/* <CampaignHistory
            isPhone={isPhone}
            setOpenEmailCampaign={(val) => {
              setEmailOpen(val);
              setWithFilters(val);
            }}
            onClose={() => {
              setOpenCampaignHistory(false);
              setEmailOpen(false);
            }}
            // setCreateTemplate={setCreateTemplate}
          /> */}
        </Dialog>

        <Dialog
          open={sendingBulkTemplates.loading}
          // onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {"Sending Bulk Template Messages"}
          </DialogTitle>
          <DialogContent>
            <Stack
              direction={"row"}
              justifyContent="center"
              sx={{ margin: "10px auto", alignSelf: "center" }}
            >
              <Whatsapp
                width="130px"
                height="130px"
                // className="animated_background_color"
              />
            </Stack>
            <Box sx={{ mt: 6 }}>
              <BorderLinearProgress
                variant="determinate"
                value={sendingBulkTemplates.percentage}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "right",
                  mr: 2,
                  mt: 2,
                  fontWeight: "600",
                }}
              >
                {sendingBulkTemplates.totalSent} / {sendingBulkTemplates.total}
              </Typography>
            </Box>
            {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
          </DialogContent>
        </Dialog>

        <Dialog
          open={showBulkGroupAdd}
          onClose={() => setShowBulkGroupAdd(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
          TransitionComponent={Slide}
          sx={{
            // textAlign: "center",
            height: "70vh",
            margin: "auto 0px",
            marginTop: 20,

            ...dialogStyle,
            ".MuiDialog-paper": {
              padding: "9px 7px",
              borderRadius: "30px",
              marginTop: !isPhone && "150px",
            },
          }}
          // {...globalPopupStyle}
        >
          <AddLabelView
            onAdd={onCustomerLabelAdd}
            setLoading={setLabelUpdating}
            loading={labelupdating}
            onClose={() => setShowBulkGroupAdd(false)}
          />
        </Dialog>
        <Dialog
          open={showBulkDeleteWarning}
          onClose={() => setShowBulkDeleteWarning(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
          TransitionComponent={Slide}
          sx={{ textAlign: "center", ...dialogStyle }}
        >
          <DeleteIllustration
            size={isMobile ? 300 : 350}
            style={{ margin: "auto" }}
            primary={backgroundColor}
          />
          <DialogTitle id="alert-dialog-title">
            Delete following {selected.length} customer(s)?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You won't be able to recover them!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowBulkDeleteWarning(false)}>
              Cancel
            </Button>
            <LoadingButton
              loading={isDeleting}
              color="error"
              onClick={onBulkDelete}
              autoFocus
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {/* add popup */}
        <Drawer
          sx={{
            "	.MuiDrawer-paperAnchorBottom": {
              borderTopLeftRadius: 9,
              borderTopRightRadius: 9,
            },
            "	.MuiDrawer-paperAnchorRight": {
              height: "calc(100vh - 20px)",
              margin: "10px",
              borderRadius: 4,
            },
          }}
          anchor={isPhone ? "bottom" : "right"}
          open={filterView}
          onClose={() => {
            setFilterView(false);
          }}
        >
          <Card
            className="scrollbar-hidden"
            sx={{
              width: isPhone ? "100%" : "350px",
              height: isPhone ? "70vh" : "100%",
              padding: "20px",
              paddingBottom: isPhone ? 0 : "20px",
              overflow: "hidden",
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">CRM Filters</Typography>
              <Close
                fontSize="small"
                onClick={() => {
                  setFilterView(false);
                }}
              />
            </Stack>
            <Box sx={{ height: "10px" }} />
            <Divider />
            <Stack
              sx={{
                height: isPhone ? "calc(70vh - 93px)" : "calc(90vh - 53px)",
                overflow: "scroll",
              }}
              className="scrollbar-hidden"
            >
              <Stack
                direction={"row"}
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                mt={4}
              >
                <Typography
                  style={{
                    fontSize: "15px",
                    fontWeight: 500,
                  }}
                  variant="h6"
                >
                  Filter Method
                </Typography>
                <Select
                  value={filterForm.filterMethod}
                  onChange={(e) =>
                    editFilterForm({
                      filterMethod:
                        filterForm.filterMethod === e.target.value
                          ? ""
                          : e.target.value,
                    })
                  }
                  size="small"
                >
                  {[
                    { label: "AND", value: "AND" },
                    { label: "OR", value: "OR" },
                  ].map((i) => {
                    return <MenuItem value={i.value}>{i.label}</MenuItem>;
                  })}
                </Select>
              </Stack>
              <Box sx={{ height: "10px" }} />
              <Divider sx={{ mt: 4 }} />
              {!onlyCRMProduct && (
                <>
                  <Stack mt={4}>
                    <FilterLabel variant="h6">Sorting</FilterLabel>
                    <FormGroup>
                      <Grid container>
                        {sorting.map((item) => (
                          <Grid item xs={6}>
                            <FormControlLabel
                              key={item.label}
                              control={
                                <Checkbox
                                  // size="small"
                                  sx={{ padding: "5px", paddingLeft: "10px" }}
                                  // name={item.name}

                                  checked={
                                    filterForm?.sorting?.[0]?.label ==
                                    item.label
                                  }
                                  onChange={() => sortingCheckHandler(item)}
                                />
                              }
                              style={{
                                whiteSpace: "nowrap",
                              }}
                              // label={item.label}
                              label={<FilterLabelView label={item?.label} />}
                              // label={
                              //   <CustomTooltip title={item?.label}>
                              //     <PrimaryLabelSmall
                              //       style={{
                              //         whiteSpace: "nowrap",
                              //       }}
                              //     >
                              //       {item?.label}
                              //     </PrimaryLabelSmall>
                              //   </CustomTooltip>
                              // }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Stack>
                  <Box sx={{ height: "10px" }} />
                  <Divider />
                </>
              )}
              <Stack mt={4}>
                <FilterLabel variant="h6">Filter by Product</FilterLabel>
                <FormGroup>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ padding: "5px", paddingLeft: "10px" }}
                            // size="small"
                            name="Abandoned Cart"
                            checked={filterForm.cart}
                            onChange={(e) =>
                              editFilterForm({ cart: e.target.checked })
                            }
                          />
                        }
                        // label="Abandoned Cart"
                        label={<FilterLabelView label={"Abandoned Cart"} />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ padding: "5px", paddingLeft: "10px" }}
                            // size="small"
                            name="Wishlist"
                            checked={filterForm.wishlist}
                            onChange={(e) =>
                              editFilterForm({ wishlist: e.target.checked })
                            }
                          />
                        }
                        // label="WishList"
                        label={<FilterLabelView label={"WishList"} />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ padding: "5px", paddingLeft: "10px" }}
                            // size="small"
                            name="Orders"
                            checked={filterForm.order}
                            onChange={(e) =>
                              editFilterForm({ order: e.target.checked })
                            }
                          />
                        }
                        // label="Orders"
                        label={<FilterLabelView label={"Orders"} />}
                      />
                    </Grid>
                    {/* <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ padding: "5px", paddingLeft: "10px" }}
                        // size="small"
                        name="Catalogs"
                        checked={filterForm.catalog}
                        onChange={(e) =>
                          editFilterForm({ catalog: e.target.checked })
                        }
                      />
                    }
                    label="Catalogs"
                  /> */}
                  </Grid>
                </FormGroup>
              </Stack>
              <Box sx={{ height: "10px" }} />
              <Divider />

              <Stack mt={4}>
                <FilterLabel variant="h6">Filter by Group</FilterLabel>
                <Grid container>
                  {isArrayWithValues(allCustomerGroup) &&
                    allCustomerGroup
                      ?.slice(0, showAllGroups ? allCustomerGroup?.length : 12)
                      .map((i) => {
                        return (
                          // <MenuItem value={i.group_name}>
                          //   {}
                          // </MenuItem>
                          <Grid item xs={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{ padding: "5px", paddingLeft: "10px" }}
                                  // size="small"
                                  // name="Orders"
                                  checked={filterForm.group?.includes(
                                    i?.group_name
                                  )}
                                  onChange={(e) =>
                                    editFilterForm({
                                      group: e.target.checked
                                        ? [...filterForm.group, i?.group_name]
                                        : filterForm.group?.filter(
                                            (j) => j !== i?.group_name
                                          ),
                                    })
                                  }
                                />
                              }
                              // label="Orders"
                              label={
                                <FilterLabelView
                                  label={formatServerValue(i.group_name)}
                                />
                              }
                            />
                          </Grid>
                        );
                      })}
                </Grid>
                {/* <Select
                  value={filterForm.group}
                  onChange={(e) =>
                    editFilterForm({
                      group:
                        filterForm.group === e.target.value
                          ? ""
                          : e.target.value,
                    })
                  }
                  fullWidth
                  size="small"
                  MenuProps={{
                    sx: { maxHeight: "300px" },
                  }}
                  endAdornment={
                    <IconButton
                      sx={{
                        display: filterForm.group ? "block" : "none",
                        mr: 1.8,
                      }}
                      onClick={() =>
                        editFilterForm({
                          group: "",
                        })
                      }
                      size={"small"}
                    >
                      <Clear style={{ height: "18px", width: "18px" }} />
                    </IconButton>
                  }
                >
                  {isArrayWithValues(allCustomerGroup) &&
                    allCustomerGroup.map((i) => {
                      return (
                        <MenuItem value={i.group_name}>
                          {formatServerValue(i.group_name)}
                        </MenuItem>
                      );
                    })}
                </Select> */}
              </Stack>
              <HorizontalStack justifyContent="flex-end">
                <Button onClick={() => setShowAllGroups((state) => !state)}>
                  {!showAllGroups ? "Show More Groups" : "Show Less Groups"}
                </Button>
              </HorizontalStack>
              <Box sx={{ height: "10px" }} />
              <Divider />
              {isArrayWithValues(
                getSocialFilters()?.filter((i) =>
                  integratedChannels?.includes(i.value)
                )
              ) && (
                <>
                  <Stack mt={4}>
                    <FilterLabel variant="h6">Filter By Channels</FilterLabel>
                    <FormGroup>
                      <Grid container>
                        {getSocialFilters()
                          ?.filter((i) =>
                            integratedChannels?.includes(i.value || i.key)
                          )
                          .map((item) => (
                            <Grid item xs={6} spacing={1}>
                              <FormControlLabel
                                key={item.label}
                                control={
                                  <Checkbox
                                    // size=""
                                    sx={{ padding: "5px", paddingLeft: "10px" }}
                                    // name={item.name}

                                    checked={filterForm?.socials?.find(
                                      (o) => o?.label === item.label
                                    )}
                                    onChange={() => socialsCheckHandler(item)}
                                  />
                                }
                                label={
                                  <FilterLabelView
                                    // label={formatServerValue(item?.label)}
                                    view={
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        spacing={2.5}
                                      >
                                        {item.icon}
                                        <Typography>{item?.label}</Typography>
                                      </Stack>
                                    }
                                  />
                                }
                                // label={
                                //   <Stack
                                //     direction={"row"}
                                //     alignItems={"center"}
                                //     spacing={2.5}
                                //   >
                                //     {item.icon}
                                //     <Typography>{item?.label}</Typography>
                                //   </Stack>
                                // }
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </FormGroup>
                  </Stack>
                  <Box sx={{ height: "10px" }} />
                  <Divider />
                </>
              )}
              {false && (
                <>
                  <Stack mt={4}>
                    <FilterLabel variant="h6">Filter by Religion</FilterLabel>
                    <Autocomplete
                      size="small"
                      id="combo-box-demo"
                      options={religionArray}
                      value={filterForm.religion ? filterForm.religion : ""}
                      onChange={(event, newInputValue) => {
                        editFilterForm({ religion: newInputValue });
                      }}
                      fullWidth
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                  <Stack mt={4}>
                    <FilterLabel variant="h6">Filter by Ethinicity</FilterLabel>
                    <Autocomplete
                      size="small"
                      id="combo-box-demo"
                      options={ethincityArray}
                      value={filterForm.ethincity ? filterForm.ethincity : ""}
                      onChange={(event, newInputValue) => {
                        editFilterForm({ ethincity: newInputValue });
                      }}
                      fullWidth
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                  <Divider sx={{ my: 3 }} />
                  <Stack mt={4}>
                    <FilterLabel variant="h6">Filter by Branch</FilterLabel>

                    <Select
                      value={filterForm.branch}
                      onChange={(e) =>
                        editFilterForm({
                          branch:
                            filterForm.branch === e.target.value
                              ? ""
                              : e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                      MenuProps={{
                        sx: { maxHeight: "300px" },
                      }}
                      endAdornment={
                        <IconButton
                          sx={{
                            display: filterForm.branch ? "block" : "none",
                            mr: 1.8,
                          }}
                          onClick={() =>
                            editFilterForm({
                              branch: "",
                            })
                          }
                          size={"small"}
                        >
                          <Clear style={{ height: "18px", width: "18px" }} />
                        </IconButton>
                      }
                    >
                      {isArrayWithValues(allBranches) &&
                        allBranches.map((i) => {
                          return (
                            <MenuItem value={i.value}>
                              {formatServerValue(i.label)}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    {/* <FormGroup>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={likeFilters}
                  sx={{ marginTop: 2.5 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select value" size="small" />
                  )}
                />
              </FormGroup> */}
                  </Stack>
                </>
              )}
              <Stack mt={4}>
                <FilterLabel variant="h6">Search by Range</FilterLabel>
                <FormGroup sx={{}}>
                  <Grid container spacing={2} rowSpacing={4}>
                    <Grid item xs={12}>
                      <Select
                        value={filterForm.betweenFilters}
                        onChange={(e) =>
                          editFilterForm({
                            betweenFilters:
                              filterForm.betweenFilters === e.target.value
                                ? ""
                                : e.target.value,
                          })
                        }
                        fullWidth
                        size="small"
                        endAdornment={
                          <IconButton
                            sx={{
                              display: filterForm.betweenFilters
                                ? "block"
                                : "none",
                              mr: 1.8,
                            }}
                            onClick={() =>
                              editFilterForm({
                                betweenFilters: "",
                              })
                            }
                            size={"small"}
                          >
                            <Clear style={{ height: "18px", width: "18px" }} />
                          </IconButton>
                        }
                      >
                        {betweenFilters.map((i) => {
                          return <MenuItem value={i.value}>{i.label}</MenuItem>;
                        })}
                      </Select>
                    </Grid>
                    <Grid item xs={12}>
                      <Collapse in={filterForm.betweenFilters}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                // label="Anniversary"
                                value={
                                  filterForm.minRange
                                    ? filterForm.minRange
                                    : null
                                }
                                onChange={(newValue) => {
                                  editFilterForm({ minRange: newValue });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    size="small"
                                  />
                                )}
                              />
                            </LocalizationProvider> */}
                            <TextField
                              fullWidth
                              size="small"
                              value={filterForm.minBetween}
                              type="number"
                              onChange={(e) => {
                                editFilterForm({ minBetween: e.target.value });
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                // label="Anniversary"
                                value={
                                  filterForm.maxRange
                                    ? filterForm.maxRange
                                    : null
                                }
                                onChange={(newValue) => {
                                  editFilterForm({ maxRange: newValue });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    size="small"
                                  />
                                )}
                              />
                            </LocalizationProvider> */}
                            <TextField
                              fullWidth
                              size="small"
                              value={filterForm.maxBetween}
                              type="number"
                              onChange={(e) => {
                                editFilterForm({ maxBetween: e.target.value });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Collapse>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Stack>
              <Stack mt={4}>
                <FilterLabel variant="h6">Search by Date</FilterLabel>
                <FormGroup sx={{}}>
                  <Grid container spacing={2} rowSpacing={4}>
                    <Grid item xs={12}>
                      <Select
                        value={filterForm.rangeLabel}
                        onChange={(e) =>
                          editFilterForm({
                            rangeLabel:
                              filterForm.rangeLabel === e.target.value
                                ? ""
                                : e.target.value,
                          })
                        }
                        fullWidth
                        size="small"
                        endAdornment={
                          <IconButton
                            sx={{
                              display: filterForm.rangeLabel ? "block" : "none",
                              mr: 1.8,
                            }}
                            onClick={() =>
                              editFilterForm({
                                rangeLabel: "",
                              })
                            }
                            size={"small"}
                          >
                            <Clear style={{ height: "18px", width: "18px" }} />
                          </IconButton>
                        }
                      >
                        {rangeFilters.map((i) => {
                          return <MenuItem value={i.value}>{i.label}</MenuItem>;
                        })}
                      </Select>
                    </Grid>
                    <Grid item xs={12}>
                      <Collapse in={filterForm.rangeLabel}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                // label="Anniversary"
                                value={
                                  filterForm.minRange
                                    ? filterForm.minRange
                                    : null
                                }
                                onChange={(newValue) => {
                                  editFilterForm({ minRange: newValue });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    size="small"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>

                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                // label="Anniversary"
                                value={
                                  filterForm.maxRange
                                    ? filterForm.maxRange
                                    : null
                                }
                                onChange={(newValue) => {
                                  editFilterForm({ maxRange: newValue });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    size="small"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Collapse>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Stack>
              <Stack mt={4}>
                <FilterLabel variant="h6">User Birthday</FilterLabel>
                <CustomDateRangeFilter
                  selectedValue={filterForm.user_birthday}
                  onSelectedValueChange={(e) =>
                    editFilterForm({ user_birthday: e })
                  }
                  dateRange={filterForm}
                  startDateLabel={"user_birthday_start"}
                  endDateLabel={"user_birthday_end"}
                  setDateRange={(dateRange) => {
                    console.log(dateRange, "<<<<<<< dateRange");
                    editFilterForm({
                      // ...defaultParams, ...params,
                      ...dateRange,
                    });
                  }}
                  mobileMode
                  setByDefault={""}
                  allowed_filters={[
                    "today",
                    "this_week",
                    "this_month",
                    "custom",
                  ]}
                  horizontal={false}
                  show_clear_icon={Boolean(filterForm.user_birthday)}
                  onClearPress={() =>
                    editFilterForm({
                      user_birthday: "",
                      user_birthday_start: "",
                      user_birthday_end: "",
                    })
                  }
                />
              </Stack>
              {/* <Divider sx={{ my: 3 }} /> */}
              <Stack mt={4}>
                <FilterLabel variant="h6">User Anniversary</FilterLabel>
                <CustomDateRangeFilter
                  selectedValue={filterForm.user_anniversary}
                  onSelectedValueChange={(e) =>
                    editFilterForm({ user_anniversary: e })
                  }
                  dateRange={filterForm}
                  startDateLabel={"user_anniversary_start"}
                  endDateLabel={"user_anniversary_end"}
                  setDateRange={(dateRange) =>
                    editFilterForm({
                      // ...defaultParams, ...params,
                      ...dateRange,
                    })
                  }
                  mobileMode
                  setByDefault={""}
                  allowed_filters={[
                    "today",
                    "this_week",
                    "this_month",
                    "custom",
                  ]}
                  horizontal={false}
                  show_clear_icon={Boolean(filterForm.user_anniversary)}
                  onClearPress={() =>
                    editFilterForm({
                      user_anniversary: "",
                      user_anniversary_start: "",
                      user_anniversary_end: "",
                    })
                  }
                />
              </Stack>
              <Divider sx={{ my: 3 }} />
              {/* <Stack mt={4} mb={6}>
                <FilterLabel variant="h6">Filter by Value</FilterLabel>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Select
                      value={filterForm.filterValueLabel}
                      onChange={(e) =>
                        editFilterForm({
                          filterValueLabel:
                            filterForm.filterValueLabel === e.target.value
                              ? ""
                              : e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                      endAdornment={
                        <IconButton
                          sx={{
                            display: filterForm.filterValueLabel
                              ? "block"
                              : "none",
                            mr: 1.8,
                          }}
                          onClick={() =>
                            editFilterForm({
                              filterValueLabel: "",
                            })
                          }
                          size={"small"}
                        >
                          <Clear style={{ height: "18px", width: "18px" }} />
                        </IconButton>
                      }
                    >
                      {likeFilters.map((i) => {
                        return <MenuItem value={i.value}>{i.label}</MenuItem>;
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      fullWidth
                      value={filterForm.filterValue}
                      onChange={(e) =>
                        editFilterForm({ filterValue: e.target.value })
                      }
                    />
                  </Grid>
                </Grid>
              </Stack> */}
              <Filters
                ref={filterRef}
                editFilterForm={editFilterForm}
                filterForm={filterForm}
                stateArray={stateArray}
                setStateArray={setStateArray}
              />
            </Stack>

            <Button
              variant="contained"
              sx={{ padding: "3px 16px" }}
              onClick={filterSubmitHandlerOnClick}
              // loading={true}
              size="large"
            >
              Filter
            </Button>
            <Button size="large" onClick={onFilterReset}>
              Reset
            </Button>
          </Card>
        </Drawer>
        <Dialog
          open={crmSheetLoading}
          // onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <CRMSheetLoading open={crmSheetLoading} syncProgress={syncProgress} />
        </Dialog>
      </Box>
    );
  }
);

const FilterLabelView = ({ label, view }) => {
  return (
    <CustomTooltip title={label || ""}>
      <div>
        {Boolean(label) && (
          <PrimaryLabelSmall
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {reduceLargeText(label, 14)}
          </PrimaryLabelSmall>
        )}
        {view}
      </div>
    </CustomTooltip>
  );
};

export const CRMSheetLoading = ({ open, syncProgress, heading }) => {
  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {heading || "CRM Sheet Sync"}{" "}
      </DialogTitle>
      <DialogContent>
        <Stack
          direction={"row"}
          justifyContent="center"
          sx={{ margin: "10px auto", alignSelf: "center" }}
        >
          <GoogleSheet
            width="130px"
            height="130px"
            // className="animated_background_color"
          />
        </Stack>
        <Box sx={{ mt: 6 }}>
          <BorderLinearProgress2
            variant="determinate"
            value={Math.round(syncProgress)}
          />
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "right",
              mr: 2,
              mt: 2,
              fontWeight: "600",
            }}
          >
            {Math.round(syncProgress)}%
          </Typography>
        </Box>
        {/* <DialogContentText id="alert-dialog-description">
      Let Google help apps determine location. This means sending
      anonymous location data to Google, even when no apps are running.
    </DialogContentText> */}
      </DialogContent>
    </>
  );
};

export const CRMSheetHistory = ({ chatsHistory }) => {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const crmSpreadsheetHistory = useSelector(
    (state) => state.customers.crmSpreadsheetHistory
  );
  const chatsSpreadsheetHistory = useSelector(
    (state) => state.chats.chatsSpreadsheetHistory
  );
  useEffect(() => {
    if (chatsHistory) {
      setRows(Object.values(chatsSpreadsheetHistory || {}).reverse() || []);
    } else {
      setRows(
        Object.values(crmSpreadsheetHistory || {})
          ?.filter(Boolean)
          .reverse() || []
      );
    }
  }, [crmSpreadsheetHistory, chatsHistory, chatsSpreadsheetHistory]);
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log(rows, "row");
  return (
    <Box sx={{ mt: 3 }}>
      {/* {isArrayWithValues(rows) ? (
        <DataNotFound
          illustration={
            <GoogleSheet
              width="100px"
              height="100px"
              // className="animated_background_color"
            />
          }
          title="CRM Spreadsheet not Integrated"
          createButton={
            <Button variant="contained" sx={{ mt: 3 }} endIcon={<ArrowRight />}>
              Integrate Google Sheet
            </Button>
          }
        />
      ) : ( */}
      <TableContainer component={Paper}>
        <Table sx={{}} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>Sr.</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Exported</TableCell>
              <TableCell>Added</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>Device</TableCell>
              <TableCell>Location</TableCell>
              {/* <TableCell>IP</TableCell> */}
              {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
          <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => {
              let { ua } = row;
              let os = new UAParser(ua).getOS();
              let browser = new UAParser(ua).getBrowser();
              return (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox" align="left">
                    <Typography sx={{ ml: 3, fontWeight: "600" }}>
                      {index + 1}
                    </Typography>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "13px", whiteSpace: "nowrap" }}
                    >
                      {row.start
                        ? formatDate(
                            new Date(row.start * 1000),
                            "dd:mm:yyyy hh:mm am/pm"
                          )
                        : ""}
                    </Typography>
                  </TableCell>
                  <TableCell>{row.exported}</TableCell>
                  <TableCell>{row.create || 0}</TableCell>
                  <TableCell>{row.update || 0}</TableCell>
                  <TableCell padding="checkbox" sx={{ fontWeight: "600" }}>
                    <Stack direction="row" alignItems={"center"} spacing={2}>
                      {os && os.name === "Mac OS" ? (
                        <Apple style={{ color: "#666666" }} />
                      ) : os.name === "Windows" ? (
                        <Windows style={{ color: "#666666" }} />
                      ) : null}
                      {os && (os.name === "Mac OS" || os.name === "Windows") ? (
                        <Laptop width="20px" height="20px" />
                      ) : (
                        <PhoneIphone />
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Typography
                      // variant="h6"
                      fontSize={"11px"}
                      sx={{ mt: 0.5 }}
                    >
                      {isObjWithValues(row.location)
                        ? `${row.location.city || ""} ${
                            row.location.state || row.location.region || ""
                          } ${row.location.country}`
                        : ""}
                    </Typography>
                  </TableCell>
                  {/* 
                  <TableCell>
                    <Typography
                      // variant="h6"
                      fontSize={"11px"}
                      sx={{ mt: 0.5 }}
                    >
                      {row.ip}
                    </Typography>
                  </TableCell> */}
                  {/* <TableCell  align="right">
              {row.fat}
            </TableCell> */}
                </TableRow>
              );
            })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                // colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

// table Row
const EnhancedTableRow = ({ row, onClick, selected, setSelected }) => {
  const { id } = useParams();
  const [isPaid, setIspaid] = useState(false);
  const [selectedArr, setSelectedArr] = useState([]);
  const [profile_url, setProfile_url] = useState("");
  // const timeObj = row.whatsapp_chat.find((chat) => chat);
  const [lastSeenobj, setLastSeenObj] = useState(null);
  const [hasEmail, setHasEmail] = useState({});
  const live_visitors = useSelector((state) => state.visitor?.live_catalogs);
  const crmEmailHistory = useSelector((state) => state.chats.crmEmailHistory);

  const [socialConnected, setSocialConnect] = useState({
    instagram_id: false,
    whatsapp_id: false,
    facebook_id: false,
  });

  useEffect(() => {
    if (isArrayWithValues(crmEmailHistory?.[row?.id]))
      setHasEmail(
        Boolean(crmEmailHistory?.[row?.id]?.find((i) => i.sent === "success"))
      );
    else setHasEmail(false);
  }, [crmEmailHistory]);

  useEffect(() => {
    // let {} =
    if (row.whatsapp_chat && row.whatsapp_chat.length !== 0) {
      let timeObj = row.whatsapp_chat.find((item) => item.sender);
      if (timeObj) {
        let obj = getRelativeTimeDifference(timeObj.time);
        setLastSeenObj(obj);
      } else {
        setLastSeenObj(null);
      }
    } else {
      setLastSeenObj(null);
    }
    if (isArrayWithValues(row.meta_data)) {
      let { meta_data } = row;
      let socialKeys = ["instagram_id", "facebook_id", "whatsapp_id"];
      let socialObj = {};
      for (let obj of meta_data) {
        if (socialKeys.includes(obj.key) && obj.value)
          socialObj[obj.key] = obj.value;
      }
      // for (const idObj of row?.merge_profiles?.profiles || []) {
      //   socialObj[`${idObj?.platform}_id`] = idObj?.id;
      // }

      setSocialConnect(socialObj);
      let keys = [
        "profile_url",
        "shopify_customer",
        "woocommerce_customer",
        "customer_channel",
      ];
      for (let obj of meta_data) {
        if (keys.includes(obj.key) && obj.value) {
          setProfile_url(obj.value);
        }
        if (obj?.key && obj?.value) {
          row[obj.key] = obj?.value;
        }
      }
    }
  }, [row]);

  useEffect(() => {
    setSelectedArr(selected);
  }, [selected]);

  const onSelect = (obj) => {
    setSelected((state) =>
      state.find((i) => i.id == obj.id)
        ? state.filter((i) => i.id !== obj.id)
        : [...state, obj]
    );
  };
  return (
    <>
      <TableRow
        // hover
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          cursor: "pointer",
          padding: 0,
          position: "relative",
        }}
        onClick={onClick}
        selected={Number(row.id) === Number(id)}
      >
        <TableCell
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            overflow: "hidden",

            // padding: "10px 12px",
          }}
          spacing={4}
        >
          <Checkbox
            checked={Boolean(selectedArr.find((i) => i.id == row.id))}
            sx={{ height: "16px", width: "16px", mr: 1.5 }}
            onClick={(e) => {
              e.stopPropagation();
              onSelect(row);
            }}
            // onChange={(e) => {
            //   console.log(e.target.checked);
            // }}
            size="small"
          />
          {
            // isSide || isIpad ?
            <>
              {
                // row.avatar_url &&
                //   !row.avatar_url.includes("secure.gravatar.com") &&
                //   !row.avatar_url.includes("/gravatar/")
                profile_url ? (
                  <Avatar
                    // sizes="small"
                    alt={`${row.first_name}  ${row.last_name}`}
                    src={profile_url}
                    sx={{
                      marginRight: 2,
                      height: "38px",
                      width: "38px",
                      // bgcolor: "#b6bed4",
                      fontSize: "14px",
                      // background: "#b6bed4",
                      background: isPaid
                        ? "linear-gradient(90deg,rgb(237 217 71) 0%,rgb(186 144 54) 100%)"
                        : "linear-gradient(90deg, rgb(168 190 245) 0%, rgb(89 97 132) 100%)",
                    }}
                  >
                    {reduceLargeText(
                      `${
                        row.first_name ? row.first_name[0].toUpperCase() : ""
                      }${row.last_name ? row.last_name[0].toUpperCase() : ""}`,
                      30
                    )}
                  </Avatar>
                ) : (
                  <Avatar
                    // sizes="small"
                    alt={`${row.first_name}  ${row.last_name}`}
                    sx={{
                      marginRight: 2,
                      height: "38px",
                      width: "38px",
                      // bgcolor: "#b6bed4",
                      fontSize: "14px",
                      // background: "#b6bed4",
                      background: isPaid
                        ? "linear-gradient(90deg,rgb(237 217 71) 0%,rgb(186 144 54) 100%)"
                        : "linear-gradient(90deg, rgb(168 190 245) 0%, rgb(89 97 132) 100%)",
                    }}
                  >
                    {reduceLargeText(
                      `${
                        row.first_name ? row.first_name[0].toUpperCase() : ""
                      }${row.last_name ? row.last_name[0].toUpperCase() : ""}`,
                      30
                    )}
                  </Avatar>
                )
              }
              <Stack>
                <Stack direction="row" alignItems={"center"} spacing={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      paddingLeft: 0,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {reduceLargeText(
                      `${
                        `${row.first_name}  ${row.last_name}`.trim() ||
                        row.username
                      }`,
                      35
                    )}
                  </Typography>
                  {live_visitors?.find((i) =>
                    i.customers?.find(
                      (j) =>
                        j.user_id == row.id || j.user_id == `user_${row.id}`
                    )
                  ) && (
                    // <Box className="example">
                    //   <Box className="dot"></Box>
                    // </Box>
                    <i class="pulse green"></i>
                  )}
                </Stack>
                {/* <Typography variant="caption" style={{ overflowX: "hidden" }}>
                  {row.billing.email}
                </Typography> */}
                {/* <Typography>{row.billing.phone}</Typography> */}
                <Typography
                  variant="caption"
                  sx={{ fontSize: "10px", padding: "0" }}
                >
                  {lastSeenobj
                    ? `${lastSeenobj.value} ${lastSeenobj.label} ago`
                    : ""}
                </Typography>
                <Stack direction={"row"} sx={{ mt: 1 }} alignItems={"center"}>
                  {isObjWithValues(row.cart) && (
                    <Tooltip title="Cart">
                      <Box sx={{ pr: 1.5 }}>
                        <ShoppingCart
                          style={{
                            height: "14px",
                            width: "14px",
                            color: "#979797",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  )}
                  {row.push_notify && (
                    <Tooltip title="Push Notification">
                      <Box sx={{ pr: 1.5 }}>
                        <NotificationsActiveOutlined
                          style={{
                            height: "14px",
                            width: "14px",
                            color: "#979797",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  )}
                  {isObjWithValues(row.wishlist) &&
                    isArrayWithValues(row.wishlist.products) && (
                      <Tooltip title="Wishlist">
                        <Box sx={{ pr: 1.5 }}>
                          <Favorite
                            style={{
                              height: "14px",
                              width: "14px",
                              color: "#979797",
                            }}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  {/* {isObjWithValues(row?.[`digigold_uid_${getStoreId()}`]) && ( */}
                  {row?.[`digigold`] && (
                    <Tooltip title="Digital Gold">
                      <Box sx={{ pr: 1.5 }}>
                        <Circle
                          style={{
                            height: "14px",
                            width: "14px",
                            color: "#c9ab33",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  )}

                  {isArrayWithValues(row.catalogs) && (
                    <Tooltip title="Catalogs">
                      <Box sx={{ pr: 1.5 }}>
                        <Widgets
                          style={{
                            height: "14px",
                            width: "14px",
                            color: "#979797",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  )}
                  {isArrayWithValues(row.payment_links) && (
                    <Tooltip title="Payments">
                      <Box sx={{ pr: 1.5 }}>
                        <Payment
                          style={{
                            height: "14px",
                            width: "14px",
                            color: "#979797",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  )}
                  {socialConnected.facebook_id && (
                    <Tooltip title="Facebook">
                      <Box sx={{ pr: 1.5 }}>
                        <Messenger
                          style={{
                            height: "14px",
                            width: "14px",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  )}
                  {socialConnected.instagram_id && (
                    <Tooltip title="Instagram">
                      <Box sx={{ pr: 1.5 }}>
                        <InstagramSvg
                          style={{
                            height: "14px",
                            width: "14px",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  )}
                  {socialConnected.whatsapp_id && (
                    <Tooltip title="Whatsapp">
                      <Box sx={{ pr: 1.5 }}>
                        <WhatsappSvg
                          style={{
                            height: "14px",
                            width: "14px",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  )}
                  {row?.shopify_customer ||
                  row?.customer_channel?.name === "shopify" ? (
                    <Tooltip title="Shopify">
                      <Box sx={{ pr: 1.5 }}>
                        <ShopifySvg
                          style={{
                            height: "14px",
                            width: "14px",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  ) : null}
                  {row?.woocommerce_customer ||
                  row?.customer_channel?.name === "woocommerce" ? (
                    <Tooltip title="Woocommerce">
                      <Box sx={{ pr: 1.5 }}>
                        <WooCommerceSvg
                          style={{
                            height: "14px",
                            width: "14px",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  ) : null}
                  {hasEmail ? (
                    <Tooltip title="Email">
                      <Box sx={{ pr: 1.5 }}>
                        <EmailOutlined
                          color="action"
                          style={{
                            fontSize: "14px",
                            // height: "14px",
                            // width: "14px",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  ) : null}
                  {row?.has_call_logs ? (
                    <Tooltip title="Email">
                      <Box sx={{ pr: 1.5 }}>
                        <Call
                          color="action"
                          style={{
                            fontSize: "14px",
                            // height: "14px",
                            // width: "14px",
                          }}
                        />
                      </Box>
                    </Tooltip>
                  ) : null}
                </Stack>
              </Stack>
            </>
          }
          {/* <Checkbox
            icon={<CheckCircleOutline />}
            checkedIcon={<CheckCircle />}
          /> */}
        </TableCell>
        {/* {isSide || isIpad ? null : (
          <>
            <TableCell>{row.billing.email}</TableCell>
            <TableCell>{row.billing.phone}</TableCell>
            <TableCell>""</TableCell>
          </>
        )} */}
      </TableRow>
    </>
  );
};
// loading rows component
const customerPlaceholder = () => (
  <Stack spacing={1}>
    <div style={{ display: "flex", padding: "10px 0px 10px 20px" }}>
      <Skeleton variant="circular" width={50} height={50} />
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginLeft: 12 }}>
          {/* <Skeleton variant="text" style={{ width: "40vw" }} />
            <Skeleton variant="text" style={{ width: "45vw" }} /> */}
          <Skeleton
            variant="text"
            width="100%"
            height={28}
            style={{
              // width: "12vw",
              // "12vw"
              // height: 28,
              borderRadius: 8,
            }}
          />
        </div>
        {/* <div style={{ marginLeft: 15 }}>
            <Skeleton variant="circular" style={{ width: 20, height: 20 }} />
          </div>
          <div style={{ marginLeft: 35 }}>
            <Skeleton
              variant="rectangle"
              style={{ width: 40, height: 20, borderRadius: 8 }}
            />
          </div> */}
        {/* <div style={{ marginLeft: 12 }}>
            <Skeleton variant="text" style={{ width: "3vw" }} />
          </div> */}
      </div>
    </div>
    {/* <Skeleton variant="rectangular" width={210} height={118} /> */}
  </Stack>
);

// grid view Customer

export const FilterLabel = styled(Typography)`
          font-size: 15px;
          font-weight: 500,
          margin-top: 7px;
          margin-bottom: 7px;
`;
export const exportSpreadsheet = async (array, spreadsheetId, options = {}) => {
  let website = await getWebsite();
  let token = await getToken();

  if (website && token) {
    try {
      let { access_token, totalProgress, setSyncProgress } = options;

      let batchLength = 500;
      let totalData = array?.length;
      let totalBatch = Math.ceil(totalData / batchLength) || 1;
      let dataToUpdate = [...array];
      let columnName = getExcelColumnHeader(array?.[0]?.length);
      let start = 1;
      let end = batchLength;

      console.log();
      let progressToAdd = validateNumber(
        validateNumber(totalProgress) / totalBatch
      );
      for await (let i of Array.from(new Array(totalBatch))) {
        try {
          let requestObj = {
            // url: `${website}/wp-json/store/v1/channel/google_sheets`,
            // url: `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values:batchUpdate`,
            params: {},
            headers: {
              Authorization: `Basic ${token}`,
            },
            method: "POST",
            data: {},
          };
          let payload = {
            valueInputOption: "RAW",
            data: [
              {
                values: dataToUpdate?.splice(0, batchLength),
                majorDimension: "ROWS",
                range: `A${start}:${columnName}${end}`,
              },
            ],
            // data: [{ values: array, majorDimension: "ROWS", range: "A1" }],
          };
          if (access_token) {
            requestObj.url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values:batchUpdate`;
            requestObj.params.access_token = access_token;
            requestObj.data = payload;
          } else {
            requestObj.url = `${website}/wp-json/store/v1/channel/google_sheets`;
            requestObj.params.endpoint = `/spreadsheets/${spreadsheetId}/values:batchUpdate`;
            requestObj.data.payload = payload;
          }
          console.log(requestObj, "requestObj");
          let { data } = await axios(requestObj);
          console.log(data);

          if (progressToAdd && setSyncProgress)
            setSyncProgress((state) => (state += progressToAdd));
          // return data;
        } catch (error) {
          console.log(getAxiosError(error));
          // return false;
        }
        start += batchLength;
        end += batchLength;
      }
    } catch (error) {
      console.log(error);
    }
  }
};
export const clearSpreadsheet = async (array, spreadsheetId) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/channel/google_sheets`,
        // url: `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values:batchUpdate`,
        params: {
          endpoint: `/spreadsheets/${spreadsheetId}:batchUpdate`,
        },
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "POST",
        data: {
          payload: {
            requests: [
              {
                updateCells: {
                  range: {
                    sheetId: 0,
                  },
                  fields: "*",
                },
              },
            ],

            // requests: [
            //   {
            //     updateCells: {
            //       range: {
            //         sheetId: 0,
            //       },
            //       fields: "*",
            //     },
            //   },
            // ],
          },
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
};

export const setSpreadsheetDropdowns = async (spreadsheetId, data) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    try {
      let res = await axios({
        url: `${website}/wp-json/store/v1/channel/google_sheets`,
        params: { endpoint: `/spreadsheets/${spreadsheetId}:batchUpdate` },

        data: { payload: { requests: data } },
        method: "POST",
        headers: {
          Authorization: `Basic ${token}`,
          // "Content-Type": "application/json",
        },
      });
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
};

export const getSheetId = async (spreadsheetId) => {
  try {
    let website = await getWebsite();
    let token = await getToken();
    let { data } = await axios({
      url: `${website}/wp-json/store/v1/channel/google_sheets`,
      params: {
        endpoint: `/spreadsheets/${spreadsheetId}/?&fields=sheets.properties`,
      },
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.log(getAxiosError(error));
    return false;
  }
};

export const socials = [
  // { label: "Whatsapp", name: "whatsapp_id", value: "whatsapp" },
  // { label: "Facebook", name: "facebook_id", value: "facebook" },
  // { label: "Instagram", name: "instagram_id", value: "instagram" },
  // { label: "Shopify", name: "shopify", value: "shopify" },
  {
    label: "Website",
    icon: <Language color="primary" style={{ height: 20, width: 20 }} />,
    key: "website",
  },
  {
    label: "Catalog",
    icon: <CatalogIcon height={20} width={20} />,
    key: "catalog",
  },
  {
    label: "Sales App",
    icon: <SalesApp height={20} width={20} />,
    key: "sales_app",
  },
  // {
  //   label: "Digital Gold",
  //   value: "digigold",
  //   key: `digigold_uid_${getStoreId && getStoreId()}`,
  //   filter_value: "",
  //   // compare_key: "LIKE",
  //   compare: "LIKE",
  //   icon: <DigigoldMenuSvg height={20} width={20} />,
  // },
  {
    label: "Email",
    value: "email",
    filter_value: "",
    key: "billing_email",
    // compare_key: "LIKE",
    compare: "!=",
    icon: <EmailCampaignSvg height={20} width={20} />,
  },
  {
    label: "iOS App",
    key: "session_tokens",
    value: "iPhone",
    compare: "LIKE",
    icon: <Apple height={20} width={20} />,
  },
  {
    label: "Android App",
    key: "session_tokens",
    value: "Android",
    compare: "LIKE",
    icon: <Android height={20} width={20} />,
  },

  // {
  //   label: "Quotation",
  //   icon: <QuotationMenuSvg height={20} width={20} />
  // },
  {
    label: "Whatsapp",
    key: "whatsapp_id",
    compare_key: "EXISTS",
    value: "whatsapp",
    compare: "!=",
    icon: <WhatsappSvg height={20} width={20} />,
  },
  {
    label: "Facebook",
    key: "facebook_id",
    compare_key: "EXISTS",
    value: "facebook",
    compare: "!=",
    icon: <Facebook height={20} width={20} />,
  },
  {
    label: "Instagram",
    key: "instagram_id",
    compare_key: "EXISTS",
    value: "instagram",
    compare: "!=",
    icon: <InstagramSvg height={20} width={20} />,
  },

  {
    label: "Shopify",
    key: "customer_channel",
    value: "shopify",
    compare: "LIKE",
    icon: <ShopifySvg height={20} width={20} />,
  },
  {
    label: "Woocommerce",
    key: "customer_channel",
    value: "woocommerce",
    compare: "LIKE",
    icon: <WooCommerceSvg height={20} width={20} />,
  },
  // { label: "Shopify Customer", name: "shopify_customer" },
  // { label: "Digi Gold", name: "digigold" },
  // { label: "Recently Update", name: "recent_communication" },
];
export const getSocialFilters = () => {
  let user = encryptStorage?.getItem("user");
  let store_id = "";
  if (user && user.store_details && user.store_id) store_id = user.store_id;
  let filters = [
    // { label: "Whatsapp", name: "whatsapp_id", value: "whatsapp" },
    // { label: "Facebook", name: "facebook_id", value: "facebook" },
    // { label: "Instagram", name: "instagram_id", value: "instagram" },
    // { label: "Shopify", name: "shopify", value: "shopify" },
    {
      label: "Website",
      icon: <Language color="primary" style={{ height: 20, width: 20 }} />,
      key: "website",
    },
    {
      label: "Catalog",
      icon: <CatalogIcon height={20} width={20} />,
      key: "catalog",
    },
    {
      label: "Sales App",
      icon: <SalesApp height={20} width={20} />,
      key: "sales_app",
    },

    {
      label: "Email",
      value: "email",
      filter_value: "",
      key: "billing_email",
      // compare_key: "LIKE",
      compare: "!=",
      icon: <EmailCampaignSvg height={20} width={20} />,
    },
    {
      label: "iOS App",
      key: "session_tokens",
      value: "iPhone",
      compare: "LIKE",
      icon: <Apple height={20} width={20} />,
    },
    {
      label: "Android App",
      key: "session_tokens",
      value: "Android",
      compare: "LIKE",
      icon: <Android height={20} width={20} />,
    },

    // {
    //   label: "Quotation",
    //   icon: <QuotationMenuSvg height={20} width={20} />
    // },
    {
      label: "Whatsapp",
      key: "whatsapp_id",
      compare_key: "EXISTS",
      value: "whatsapp",
      compare: "!=",
      icon: <WhatsappSvg height={20} width={20} />,
    },
    {
      label: "Facebook",
      key: "facebook_id",
      compare_key: "EXISTS",
      value: "facebook",
      compare: "!=",
      icon: <Facebook height={20} width={20} />,
    },
    {
      label: "Instagram",
      key: "instagram_id",
      compare_key: "EXISTS",
      value: "instagram",
      compare: "!=",
      icon: <InstagramSvg height={20} width={20} />,
    },

    {
      label: "Shopify",
      key: "customer_channel",
      value: "shopify",
      compare: "LIKE",
      icon: <ShopifySvg height={20} width={20} />,
    },
    {
      label: "Woocommerce",
      key: "customer_channel",
      value: "woocommerce",
      compare: "LIKE",
      icon: <WooCommerceSvg height={20} width={20} />,
    },
    // { label: "Shopify Customer", name: "shopify_customer" },
    // { label: "Digi Gold", name: "digigold" },
    // { label: "Recently Update", name: "recent_communication" },
  ];

  if (store_id)
    filters.push({
      label: "Digital Gold",
      value: "digigold",
      // key: `digigold_uid_${getStoreId && getStoreId()}`,
      key: `digigold`,
      filter_value: "",
      // compare_key: "LIKE",
      compare: "!=",
      icon: <DigigoldMenuSvg height={20} width={20} />,
    });
  return filters;
};
