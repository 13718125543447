import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import Switch from "rc-switch";
import {
  callSocket,
  getInvalidKeys,
  getWebsite,
  isObjWithValues,
  settingsEndpoint,
} from "../../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "styled-components";
import { useEffect } from "react";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";

function Settings() {
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  const unicommerce_integration = useSelector(
    (state) => state.settings.unicommerce_integration
  );

  useEffect(() => {
    if (isObjWithValues(unicommerce_integration)) {
      editForm(unicommerce_integration);
    }
  }, [unicommerce_integration]);

  const editForm = (state) => {
    setFormState((ps) => ({ ...ps, ...state }));
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      // let integration = await settingsEndpoint({
      //   method: "PUT",
      //   endpoint: "unicommerce_integration",
      //   data: {
      //     ...formState,
      //   },
      // });

      let integration = await callSocket({
        endpoint: "unicommerce/update_settings",
        method: "POST",
        data: {
          website: getWebsite(),
          data: {
            ...formState,
          },
        },
      });

      if (integration?.data?.success) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            unicommerce_integration: integration?.data?.data,
          },
        });

        dispatch(
          setGlobalToast({
            show: true,
            message: "Settings updated",
            severity: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Can't update settings",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ paddingLeft: "12px", paddingBottom: "25px" }}>
      <Stack spacing={7} sx={{ width: isPhone ? "100%" : "70%" }}>
        <Stack>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            sx={{ gap: "14px" }}
          >
            <Typography variant="h6">Auto Sync</Typography>
            <Switch
              onChange={(value) =>
                editForm({ is_auto_sync: value ? "on" : "off" })
              }
              checked={formState.is_auto_sync === "on"}
              style={
                formState.is_auto_sync === "on"
                  ? {
                      border: `1px solid ${backgroundColor}`,
                      backgroundColor,
                    }
                  : {}
              }
            />
          </Stack>
          <FormHelperText>
            Do you want all your Jwero orders to AutoSync into UniCommerce?
          </FormHelperText>
        </Stack>
        <Stack>
          <Typography variant="h6">Order Prefix</Typography>
          <FormControl sx={{ m: 1, width: "20ch" }} variant="outlined">
            <OutlinedInput
              value={formState.prefix}
              onChange={(e) => editForm({ prefix: e.target.value })}
              id="outlined-adornment-weight"
              placeholder="JWERO"
              size="small"
              endAdornment={
                <InputAdornment position="end">_ORDERID</InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
          <FormHelperText>
            Unicommerce OrderID will be generated in this format when synced
            from Jwero (for example, JWERO_766863)
          </FormHelperText>
        </Stack>
        <Stack flexDirection={"flex-end"} alignItems="flex-end">
          <LoadingButton
            loading={loading}
            variant={"contained"}
            onClick={onSubmit}
            sx={{ mr: 4 }}
          >
            Update Settings
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Settings;
