import { capitalizeFirstLetter } from "jwero-javascript-utils";

const formatServerValue = (str) =>
  str &&
  typeof str === "string" &&
  str
    .replaceAll("_", " ")
    .replaceAll("-", " ")
    .split(" ")
    .map((i) => capitalizeFirstLetter(i))
    .join(" ");

export function updateNodesWithRef(nodes, edges) {
  const nodeRefs = {};
  edges.forEach((edge) => {
    const sourceNodeId = edge.source;
    const targetNodeId = edge.target;

    if (!nodeRefs[sourceNodeId]) {
      nodeRefs[sourceNodeId] = [];
    }
    let payload = { id: targetNodeId };
    if (edge?.sourceHandle) {
      if (edge?.sourceHandle === "whatsapp_sent_handle") {
        payload.type = "sent";
      } else if (edge?.sourceHandle === "whatsapp_failed_handle") {
        payload.type = "failed";
      } else payload.type = "whatsapp_quick_reply";
      payload.label = getLabelFromSourceHandle({
        source_handle: edge?.sourceHandle,
      });
      // {
      //   id: "23423849",
      //   type: "whatsapp_quick_reply",
      //   label: "Interested"
      // }
    }
    nodeRefs[sourceNodeId].push(payload);

    // ("Sent on Whatsapp");
    // ("whatsapp_sent_on_whatsapp_handle");
  });

  nodes.forEach((node) => {
    const nodeId = node.id;
    if (nodeRefs[nodeId]) {
      node.ref = nodeRefs[nodeId];
    }
  });

  return nodes;
}

const getLabelFromSourceHandle = ({ source_handle }) => {
  let result = source_handle;
  let channels = ["whatsapp", "sms", "push_notification", "email"];

  // Remove 'whatsapp_' from the beginning if it exists
  for (let channel of channels) {
    if (result.startsWith(`${channel}_`)) {
      result = result.substring(`${channel}_`.length);
    }
  }

  // Remove '_handle' from the end if it exists
  if (result.endsWith("_handle")) {
    result = result.substring(0, result.length - "_handle".length);
  }

  return formatServerValue(result);
};
