const ALL_TRIGGERS = [
  {
    label: "Customer Birthday",
    type: "customer",
    value: "JWERO_CUSTOMER_BIRTHDAY",
  },
  {
    label: "Customer Aniversary",
    type: "customer",
    value: "JWERO_CUSTOMER_ANIVERSARY",
  },
  {
    label: "Customer Register",
    type: "customer",
    value: "JWERO_CUSTOMER_REGISTER",
  },
  {
    label: "Customer Login",
    type: "customer",
    value: "JWERO_CUSTOMER_LOGIN",
  },
  {
    label: "Website visit",
    type: "website",
    value: "JWERO_WEBSITE_VISIT",
  },
  { label: "Order Create", type: "order", value: "JWERO_ORDER_CREATE" },
  { label: "Order Shipped", type: "order", value: "JWERO_ORDER_SHIPPED" },
  { label: "Order Cancelled", type: "order", value: "JWERO_ORDER_CANCELLED" },
  { label: "Order Complete", type: "order", value: "JWERO_ORDER_COMPLETED" },
  { label: "Order Refunded", type: "order", value: "JWERO_ORDER_REFUNDED" },
  {
    label: "Order Payment Failed",
    type: "order",
    value: "JWERO_ORDER_PAYMENT_FAILED",
  },
  { label: "Abandon cart", type: "product", value: "JWERO_ABANDON_CART" },
  {
    label: "Whatsapp Request Welcome",
    type: "whatsapp",
    value: "JWERO_WHATSAPP_REQUEST_WELCOME",
    tooltip:
      "The Whatsapp Request Welcome event triggers when a new user contacts your WhatsApp for the first time",
  },
  {
    label: "Digigold Payment Reminder",
    type: "digital_gold",
    value: "DIGIGOLD_PAYMENT_REMINDER",
  },
  {
    label: "Digigold Payment Done",
    type: "digital_gold",
    value: "DIGIGOLD_PAYMENT_DONE",
  },
  {
    label: "Digigold Payment Missed",
    type: "digital_gold",
    value: "DIGIGOLD_PAYMENT_MISSED",
  },
];
export { ALL_TRIGGERS };
