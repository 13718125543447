/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Add,
  ArrowBack,
  CampaignOutlined,
  Clear,
  Close,
  Delete,
  DoneOutlined,
  Edit,
  Language,
  MarkEmailReadOutlined,
  NotificationsActiveOutlined,
  People,
  PeopleAlt,
  RemoveRedEyeOutlined,
  Search,
  Send,
  SmsOutlined,
} from "@mui/icons-material";
import {
  DatePicker,
  DesktopDatePicker,
  LoadingButton,
  LocalizationProvider,
} from "@mui/lab";
import PropTypes from "prop-types";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Select,
  Skeleton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { UAParser } from "ua-parser-js";
import { getIp } from "../../../App";
import {
  dialogStyle,
  campaignEmailLength,
  EmptyState,
  fetchIPInfo,
  formatDate,
  formatServerValue,
  getAdminName,
  getCustomerTotal,
  getRandomString,
  getTodaysEmailSent,
  getStoreId,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isEmailIsIntegrated,
  isObjWithValues,
  nodeWebsite,
  primaryLabel,
  secondaryLabel,
  secondaryLabelSmall,
  TabPanel,
  textFeildRadius,
  validateNumber,
  getFullName,
  getCustomerWidthFilter,
  StyledTableRow,
  getIntegrationObj,
  getAuthToken,
  isNumber,
  websocket_doamin,
} from "../../../helper";
import {
  EDIT_CHATS,
  getCampaignHistory,
  getEmailTemplate,
  setCountOnSegment,
} from "../../../redux/actions/chatActions";
import { getSegmentList } from "../../../redux/actions/crmActions";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import {
  Android,
  Apple,
  CatalogIcon,
  DigigoldMenuSvg,
  Facebook,
  InstagramSvg,
  NewMessageIcon,
  OneSignalSvg,
  QuotationMenuSvg,
  SalesApp,
  ShopifySvg,
  WhatsappSvg,
  WooCommerceSvg,
} from "../../../Svgs";
import { isValidDate } from "../../../utils/dateFunctions";

import EmailBuilder, {
  VariablePopover,
} from "../../campaign/components/EmailBuilder";

import ViewCustomerList from "../../components/ViewCustomerList";
import {
  EmailTemplateList,
  PredefinedEmailTemplate,
} from "../../email/EmailList";
import {
  FilterLabel,
  likeFilters,
  rangeFilters,
  socials,
  sorting,
} from "../Customers";
import { Label } from "./Info";
import { styled } from "@mui/system";
import SendSms from "./SendSms";
import TempleteList from "../../components/TempleteList";
import PhoneMessagePreview from "./PhoneMessagePreview";
import SendNotification from "../../components/SendNotification";
import { campaignChannelIcon } from "./CampaignHistory";
import { CustomDateRangeFilter } from "../../digital_gold/Dashboard";
import { JewelleryTypeCard } from "../../settings/MasterSettings";
import HorizontalStack from "../../../helper/utility/HorizontalStack";
export const templateListPage = "templateList";
export const openTempalte = "openTempalte";
export const confirmPage = "confirmPage";
export const createSegment = "createSegment";
export const exitPage = "exitPage";

let nameError = "Please Enter Campaign Name";
let categoryError = "Please Select Campaign Category";
let recipientsError = "Please Select Recipients";
let templateError = "Please Select Template";

////
// toast message

let sentCampaign = (name) =>
  `Campaign ${name ? ` ${name}` : ""} has been initiated successfully`;
let darfCampaign = (name) =>
  `Save as draft campaign ${name} template successfully!`;
let createSegmentToast = (name) => `Created ${name} segment successfully!`;
let updateSegmentToast = (name) => `Updated ${name} segment successfully!`;
let deleteSegmentToast = (name) => `Deleted ${name} segment successfully!`;
const EmailTemplate = ({
  setEmailOpen,
  emailOpen,
  selected,
  setOpenCampaignHistory,
  openCampaignHistory,
  withFilters,
  setSelected,
  setWithFilters,
  isPhone,
  isCampaign,
  wrapperTag: Wrapper = Dialog,
}) => {
  const [selectedPage, setSelectedPage] = useState("");
  const [fetchingList, setFetchingList] = useState(false);
  const [customerCount, setCustomerCount] = useState(0);
  /////
  const [onSelectedTemplated, setOnSelectedtemplated] = useState(false);

  // const [createPage, setCreatePage] = useState(0);
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  // const [deleteTemplateTab, setDeleteTemplateTab] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [createCampaignState, setCreateCampaignState] = useState({});
  const [remainingEmails, setRemainingEmails] = useState(0);
  const [campaginCustomersStr, setCampaginCustomersStr] = useState(``);

  const smtpMail = useSelector((state) => state.settings.smtpMail);
  const customerSegments = useSelector((state) => state.chats.customerSegments);
  const campaignHistory = useSelector((state) => state.chats.campaignHistory);

  useEffect(() => {
    if (isObjWithValues(campaignHistory)) {
      let emailSentToday = getTodaysEmailSent(campaignHistory);
      setRemainingEmails(campaignEmailLength - emailSentToday);
    } else setRemainingEmails(campaignEmailLength);
  }, [campaignHistory]);

  // useEffect(() => {
  //   if (!isObjWithValues(campaignHistory)) dispatch(getCampaignHistory());
  // }, []);
  useEffect(async () => {
    if (selectedPage === confirmPage && isCampaign) {
      setCampaginCustomersStr("Loading ...");
      let paramsObj = returnOptOutParams(
        customerSegments?.[createCampaignState?.segment_id?.[0]] || {}
      );

      let res = await getCustomerWidthFilter({
        params: { ...paramsObj, per_page: 10 },
      });
      let resOnlyName = res?.data
        ?.map((customer) => getFullName(customer) || "")
        ?.filter((customer) => customer?.trim())
        ?.slice(0, 2);
      setCampaginCustomersStr(
        `${resOnlyName?.join(",")} ${
          customerCount - validateNumber(resOnlyName?.length)
            ? `+${customerCount - validateNumber(resOnlyName?.length)} others`
            : ""
        } `
      );
    }
  }, [selectedPage]);

  const dispatch = useDispatch();
  const onEditForm = (payload) => {
    setError({});
    setCreateCampaignState((pre) => ({ ...pre, ...payload }));
  };

  const setDefaultState = () => {
    setOnSelectedtemplated(false);
    if (isCampaign) setSelectedPage("");
    else setSelectedPage(templateListPage);
    // setSelectedPage("");
    // setCreateTemplate(false);
    // setCreatePage(0);
    setLoading(false);
    setIsEdit(false);
    setPreview(false);
    setCreateCampaignState({});
    // setDeleteTemplateTab(false);
    setWithFilters && setWithFilters(false);
  };

  useEffect(() => {
    if (!isCampaign) {
      setSelectedPage(templateListPage);
    }
  }, [isCampaign]);

  useEffect(() => {
    if (!isObjWithValues(customerSegments))
      dispatch(getSegmentList({ loading: setFetchingList }));
    return () => {
      setCreateCampaignState({});
    };
  }, []);

  useMemo(() => {
    setError({});
  }, [onSelectedTemplated]);

  let sendEmailTemplate = async ({ draft }) => {
    let website = getWebsite();
    let token = getToken();
    if (!createCampaignState?.name && isCampaign)
      return setError({
        nameError,
      });
    delete createCampaignState.templateString;
    delete createCampaignState.sender_id;
    if (website && token) {
      setLoading(true);
      let time = Date.now();
      let id = `${time}_${getRandomString(5)}`;
      let historyObj = {
        id,
        campaign_info: {
          // send_to_all: false,
          // send_to_segments: isObjWithValues(segmentSelected),
          // segment_id: segmentSelected?.id || "",
          // params: withFilters ? filterSubmitHandler(filterForm) : [],

          selected_ids: selected?.map((obj) => ({ id: obj.id })) || [],
          history_obj_id: id,
          template_id: onSelectedTemplated?.id,

          template_name: onSelectedTemplated?.name,
          campaign_type: withFilters ? withFilters : "email",
          ...createCampaignState,
          segment_id: createCampaignState?.segment_id,
        },
        is_campaign: isCampaign,
        start_time: time,
        ip: await getIp(),
        ua: new UAParser().getUA(),
      };
      if (withFilters === "whatsapp") {
        historyObj.campaign_info = {
          ...historyObj.campaign_info,
          template: onSelectedTemplated,
        };
      }
      if (withFilters === "push_notification") {
        historyObj.campaign_info = {
          ...historyObj.campaign_info,
          channel: "onesignal",
        };
      }
      if (!historyObj.name)
        historyObj.name = formatDate(new Date(time), "dd:mm:yyyy hh:mm am/pm");
      let location = await fetchIPInfo(historyObj.ip);
      historyObj.location = location;

      // return;
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings/campaign_history`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "PUT",
        data: {
          setting: "campaign_history",
          data: {
            [id]: historyObj,
          },
        },
      });
      if (res?.data?.success) {
        dispatch({
          type: EDIT_CHATS,
          payload: {
            campaignHistory: res?.data?.data,
          },
        });
        dispatch(
          setGlobalToast({
            show: true,
            severity: "success",
            message: draft
              ? darfCampaign(createCampaignState?.name)
              : sentCampaign(createCampaignState?.name),
          })
        );
        setLoading(false);
        setDefaultState && setDefaultState();
        if (isCampaign) setOpenCampaignHistory && setOpenCampaignHistory(true);
        setSelectedPage && setSelectedPage(templateListPage);
        setEmailOpen && setEmailOpen(false);
        if (!draft) {
          await startCampaignFromSocket({ id, website });
        }

        // let socketRes = await axios({
        //   // url: `http://192.168.1.12:8080/campaign`,
        //   url: `${nodeWebsite}/campaign`,
        //   method: "POST",
        //   headers: {
        //     Authorization: `Basic ${token}`,
        //   },
        //   data: {
        //     history_obj_id: id,
        //     website,
        //   },
        // });
        // onClose();
        if (isCampaign) setOpenCampaignHistory && setOpenCampaignHistory(true);
        else {
          setSelectedPage(templateListPage);
          setEmailOpen(false);
        }
        setLoading(false);
        setDefaultState();
      }
    }
    setDefaultState();
    setLoading(false);
  };

  return (
    <Wrapper
      fullWidth={true}
      // maxWidth={createPage || preview ? "lg" : "sm"}
      maxWidth={
        selectedPage === openTempalte
          ? "lg"
          : selectedPage === confirmPage || selectedPage === exitPage
          ? "sm"
          : "md"
      }
      open={emailOpen}
      // open={"email"}
      onClose={() => {
        setEmailOpen(false);
        setDefaultState();
      }}
      fullScreen={isPhone}
      sx={
        isPhone
          ? {}
          : {
              ...dialogStyle,

              ".MuiDialog-paper": {
                padding: "9px 7px",
                borderRadius: "30px",
                minHeight:
                  selectedPage === confirmPage || selectedPage === exitPage
                    ? "auto"
                    : "85vh",
              },
            }
      }
      keepMounted={false}
    >
      {
        <>
          <TabPanel
            value={""}
            index={
              isCampaign
                ? selectedPage === confirmPage
                  ? ""
                  : selectedPage
                : selectedPage
            }
          >
            <CreateCampaign
              error={error}
              emailOpen={emailOpen}
              setEmailOpen={setEmailOpen}
              setError={setError}
              setSelectedPage={setSelectedPage}
              customerSegments={customerSegments}
              fetchingList={fetchingList}
              createCampaignState={createCampaignState}
              setCreateCampaignState={setCreateCampaignState}
              onEditForm={onEditForm}
              setSelected={setSelected}
              smtpMail={smtpMail}
              selected={selected}
              customerCount={customerCount}
              setCustomerCount={setCustomerCount}
              onSelectedTemplated={onSelectedTemplated}
              sendEmailTemplate={sendEmailTemplate}
              loading={loading}
              isPhone={isPhone}
              setPreview={setPreview}
              preview={preview}
              setOnSelectedtemplated={setOnSelectedtemplated}
              setIsEdit={setIsEdit}
            />
          </TabPanel>
          <TabPanel
            value={templateListPage}
            index={selectedPage}
            className={"scrollbar-hidden"}
          >
            <EmailTemplateList
              // action={true}
              setSelectedPage={setSelectedPage}
              setOnSelectedtemplated={setOnSelectedtemplated}
              setIsEdit={setIsEdit}
              setPreview={setPreview}
              preview={preview}
              hidePredefined={isArrayWithValues(selected)}
              // campaignView={true}
              // setCreateTemplate={setCreateTemplate}
              setCreateTemplate={() => setSelectedPage(openTempalte)}
              isCampaign={isCampaign}
            />
          </TabPanel>
          <TabPanel value={openTempalte} index={selectedPage}>
            {/* {selectedPage === openTempalte && ( */}
            <EmailBuilder
              setSelectedPage={setSelectedPage}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              setPreview={setPreview}
            />
            {/* )} */}
          </TabPanel>
          <TabPanel value={"predefined"} index={selectedPage}>
            <PredefinedEmailTemplate
              setSelectedPage={setSelectedPage}
              setPreview={setPreview}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              isCampaign={isCampaign}
            />
          </TabPanel>

          <TabPanel value={createSegment} index={selectedPage}>
            <CreateSegment setSelectedPage={setSelectedPage} />
          </TabPanel>
          <TabPanel value={confirmPage} index={selectedPage}>
            <DialogWrapper wrapperTag={isCampaign ? Dialog : React.Fragment}>
              <CustomeDialogTitle
                dialogTitle={
                  <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <NewMessageIcon style={{ height: "25px", width: "25px" }} />
                    <Typography variant="h6">
                      {isCampaign
                        ? `Send ${formatServerValue(withFilters) || ""}  ${
                            createCampaignState?.name || ""
                          }  - to ${customerCount} Contacts`
                        : `Send ${
                            formatServerValue(withFilters) || ""
                          } Bulk template`}
                      {/* Send {withFilters} template */}
                    </Typography>{" "}
                  </Stack>
                }
                backButton={
                  <IconButton
                    onClick={() => {
                      isCampaign
                        ? setSelectedPage("")
                        : setSelectedPage(templateListPage);
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                }
              />
              <DialogContent>
                <Grid container spacing={2} rowSpacing={6}>
                  <Grid item sx={6} md={3}>
                    <Typography>To</Typography>
                  </Grid>
                  <Grid item sx={6} md={9}>
                    <Typography variant="h6" sx={{ width: "80%" }}>
                      {isCampaign ? (
                        <>{campaginCustomersStr}</>
                      ) : (
                        <>
                          {selected
                            ?.slice(0, 2)
                            ?.map((customer) => getFullName(customer) || "")
                            ?.join(",")}

                          {selected?.slice(2, selected?.length)?.length
                            ? " +" +
                              selected?.slice(2, selected?.length)?.length
                            : ""}
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item sx={6} md={3}>
                    <Typography>Template</Typography>
                  </Grid>
                  <Grid item sx={6} md={9}>
                    <Typography variant="h6">
                      {onSelectedTemplated?.name ||
                        createCampaignState?.template_name ||
                        ""}
                    </Typography>
                  </Grid>
                </Grid>
                {/* <DialogContentText>
                  <Typography variant="h6">
                    Send{" "}
                    <Typography
                      variant="h6"
                      display={"inline"}
                      color={"text.primary"}
                      fontWeight={700}
                    >
                      {onSelectedTemplated?.name || ""}
                    </Typography>{" "}
                    template to {isCampaign ? customerCount : selected?.length}
                    {"  "}
                  
                    contacts?
                  </Typography>
                  <Alert variant="outlined" severity="warning" sx={{ mt: 3 }}>
                 
                    Email sent today{" "}
                    {campaignEmailLength - validateNumber(remainingEmails)}/
                    {campaignEmailLength}
                   
                  </Alert>
                </DialogContentText> */}
              </DialogContent>
              <DialogActions
                sx={{
                  mt: 2,
                  borderTop: 1.5,
                  borderTopColor: "divider",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  px: 4,
                }}
              >
                <Box>
                  {withFilters === "email" && (
                    <Typography>
                      Eamil sent Today{" "}
                      {campaignEmailLength - validateNumber(remainingEmails)}/
                      {campaignEmailLength}
                    </Typography>
                  )}
                  {/* <Typography>
                    {typeof withFilters === "string" ? formatServerValue(withFilters) : "Email"} sent today{" "}
                    {campaignEmailLength - validateNumber(remainingEmails)}/
                    {campaignEmailLength}
                  </Typography> */}
                </Box>
                {false && (
                  <LoadingButton
                    loading={loading}
                    variant="outlined"
                    onClick={() => {
                      sendEmailTemplate({
                        draft: true,
                      });

                      // goBack && goBack();
                      // setCreateTemplate && setCreateTemplate(false);
                      // setOnSelectedtemplated && setOnSelectedtemplated(false);
                    }}
                  >
                    Save As Draft
                  </LoadingButton>
                )}
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  onClick={() =>
                    sendEmailTemplate({
                      draft: false,
                    })
                  }
                  disabled={!remainingEmails && withFilters === "email"}
                  // onClick={() => {
                  //     console.log(filterSubmitHandler(filterForm), filterForm)
                  // }}
                >
                  Send{" "}
                  {typeof withFilters === "string"
                    ? "Campaign"
                    : // formatServerValue(withFilters)
                      "Email"}
                </LoadingButton>
              </DialogActions>
            </DialogWrapper>
          </TabPanel>
          <TabPanel value={exitPage} index={selectedPage}>
            <>
              <CustomeDialogTitle
                dialogTitle={
                  <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <NewMessageIcon style={{ height: "25px", width: "25px" }} />
                    <Typography variant="h6">Cancel campaign</Typography>{" "}
                  </Stack>
                }
                // goBack={
                //   <IconButton onClick={() => goBack()}>
                //     <ArrowBack />
                //   </IconButton>
                // }
              />
              <DialogContent>
                <DialogContentText>
                  <Typography variant="h6">
                    Are you sure you want to cancel campaign ?
                    {/* you can save your
                    campaign as draft */}
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ mt: 2 }}>
                {false && (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      sendEmailTemplate({
                        draft: true,
                      });
                      // goBack && goBack();
                      // setCreateTemplate && setCreateTemplate(false);
                      // setOnSelectedtemplated && setOnSelectedtemplated(false);
                    }}
                  >
                    Save As Draft
                  </Button>
                )}
                <LoadingButton
                  // loading={loading}
                  color="error"
                  variant="contained"
                  onClick={() => {
                    setEmailOpen(false);
                    setDefaultState();
                  }}
                  // onClick={() => {
                  //     console.log(filterSubmitHandler(filterForm), filterForm)
                  // }}
                >
                  Cancel Campaign
                </LoadingButton>
              </DialogActions>
            </>
          </TabPanel>
        </>
      }
    </Wrapper>
  );
};

export default EmailTemplate;

const DialogWrapper = ({ wrapperTag: Wrapper = React.Fragment, children }) => {
  return (
    <Wrapper
      open={true}
      {...(Wrapper
        ? {
            fullWidth: true,
            maxWidth: "sm",
          }
        : {})}
      sx={{ ...dialogStyle }}
    >
      {children}
    </Wrapper>
  );
};

export const startCampaignFromSocket = async ({ id, website }) => {
  let token = getToken();
  if (!website) website = getWebsite();
  if (token) {
    await axios({
      // url: `http://192.168.1.12:8080/campaign`,
      url: `${websocket_doamin}/campaign`,
      // url: `${nodeWebsite}/campaign`,
      method: "POST",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        history_obj_id: id,
        website,
        store_id: getStoreId(),
        newToken: getAuthToken(),
      },
    });
  }
};
function removeKey(obj, key) {
  const { [key]: _, ...newObj } = obj;
  return newObj;
}
export const CreateSegment = ({ setSelectedPage, isEdit }) => {
  const reduxMetaIntegration = useSelector(
    (state) => state.settings.metaIntegration
  );

  const reduxWooCommerceIntegration = useSelector(
    (state) => state.settings.wooCommerceIntegration
  );

  const [errors, setErrors] = useState({});
  const [filterForm, setFilterForm] = useState({});
  const [gettingCount, setGettingCount] = useState(false);
  const [fetchingCount, setFetchingCount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateAnchorEl, setDateAnchorEl] = React.useState(null);
  const [integratedChannels, setIntegratedChannels] = useState([
    "website",
    "sales_app",
    "catalog",
    "digigold",
    "iPhone",
    "Android",
  ]);
  const reduxCustomerGroup = useSelector(
    (state) => state.customers.crmGrouping
  );
  const reduxShopifyIntegration = useSelector(
    (state) => state.settings.shopifyIntegration
  );
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  let branches = useSelector((state) => state.settings.branch);
  let timeout;
  const dateOpen = Boolean(dateAnchorEl);
  const dateId = dateOpen ? "simple-popover" : undefined;
  const dispatch = useDispatch();
  const editFilterForm = (payload) => {
    setFilterForm((state) => ({ ...state, ...payload }));
    setErrors({});
  };
  const sortingCheckHandler = (item) => {
    // const { name } = e.target;
    // setSortingParams({ ...initialSortState, [`${name}`]: true });
    let { sorting } = filterForm;
    if (sorting?.includes(item))
      setFilterForm((state) => ({
        ...state,
        sorting: [item],
      }));
    else
      setFilterForm((state) => ({
        ...state,
        sorting: [item],
      }));
  };

  const onSelectChannel = (obj) => {
    let { socials = [] } = filterForm;
    if (socials?.find((o) => o?.label === obj?.label)) {
      socials = socials?.filter((o) => o.label !== obj?.label);
    } else {
      socials = [...socials, obj];
    }
    editFilterForm({ socials });
  };

  useEffect(() => {
    // let timeout;
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      if (
        isObjWithValues(removeEmptyValues({ ...filterForm, segment_name: "" }))
      ) {
        setGettingCount(true);
        setFetchingCount(true);
        console.log(
          filterSubmitHandler({
            ...filterForm,
            sorting: (filterForm?.sorting || [])?.map((o) => {
              let { label, icon, value, ...other } = o || {};

              return other;
            }),
            socials: (filterForm?.socials || [])?.map((o) => {
              let { label, icon, ...other } = o || {};
              // if (value) return value;
              return other;
            }),
          }),
          "parma<<"
        );
        // console.log("getting number");
        let count = await getCustomerTotal({
          params: filterSubmitHandler({
            ...filterForm,
            sorting: (filterForm?.sorting || [])?.map((o) => {
              let { label, icon, value, ...other } = o || {};

              return other;
            }),
            socials: (filterForm?.socials || [])?.map((o) => {
              let { label, icon, ...other } = o || {};
              // if (value) return value;
              return other;
            }),
          }),
        });

        setGettingCount(count);
        setFetchingCount(false);
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [filterForm]);
  useEffect(() => {
    let array = [];
    if (reduxShopifyIntegration?.integrated) array.push("shopify");
    if (reduxWooCommerceIntegration?.integrated) array.push("woocommerce");
    if (isObjWithValues(reduxMetaIntegration)) {
      // setIntegratedChannels(
      //   );
      Object.values(reduxMetaIntegration).map(
        (i) => (array = array.concat(i.connected || []))
      );
    }
    setIntegratedChannels((state) => [
      ...new Set([...(array || []), ...state]),
    ]);
  }, [
    reduxMetaIntegration,
    reduxWooCommerceIntegration,
    reduxShopifyIntegration,
  ]);

  useEffect(() => {
    if (isObjWithValues(isEdit?.filterForm)) {
      setFilterForm(isEdit?.filterForm);
    }
  }, [isEdit]);
  function removeEmptyValues(obj) {
    let newObj = { ...obj };
    for (let key in obj) {
      const value = obj[key];
      if (
        value === "" ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === "object" && isObjWithValues(value))
      ) {
        if (obj[key]) removeKey(obj, key);
      } else if (typeof value === "object") {
        removeEmptyValues(value);
      }
    }
    return obj;
  }
  const handleClose = () => {
    setDateAnchorEl(null);
  };
  const onDateSubmit = (params) => {
    // if (params.after) params.after = formatDate(params.after, "yyyy-mm-dd");
    // if (params.before) params.before = formatDate(params.before, "yyyy-mm-dd");
    if (params.after) params.after = new Date(params.after);
    if (params.before) params.before = new Date(params.before);
    setFilterForm({ ...params });
    // dispatch(getAllOrders({ ...params, ...defaultParams }, true));
  };

  const putSegmentData = async () => {
    let website = getWebsite();
    let token = getToken();
    let id = `${Date.now()}_${getRandomString(3)}`;

    if (!filterForm.segment_name) {
      return setErrors((pre) => ({ ...pre, segment_name: true }));
    }
    // return
    let makeFiterObj = {
      ...filterForm,
      socials: filterForm?.social?.map((o) => o.name),
    };
    if (filterForm?.product) {
      for (const productObj of filterForm?.product || []) {
        makeFiterObj[productObj?.value] = true;
      }
    }
    if (website && token) {
      setLoading(true);
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings/customer_segments`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "PUT",
        data: {
          setting: "customer_segments",
          data: {
            [isEdit?.id ? isEdit?.id : id]: {
              id: isEdit?.id ? isEdit?.id : id,
              params: filterSubmitHandler(makeFiterObj),
              filterForm,
            },
          },
        },
      });
      if (res?.data?.success) {
        dispatch({
          type: EDIT_CHATS,
          payload: {
            customerSegments: res?.data?.data,
          },
        });
        dispatch(
          setGlobalToast({
            show: true,
            severity: "success",
            message: isObjWithValues(isEdit)
              ? updateSegmentToast(filterForm.segment_name)
              : createSegmentToast(filterForm.segment_name),
          })
        );
        setSelectedPage("");
        setLoading(false);
        // setCreate(false);
      }
    }
    setSelectedPage("");
    setLoading(false);
  };
  return (
    <>
      <CustomeDialogTitle
        dialogTitle={
          isObjWithValues(isEdit) ? "Update Segment" : "Create Segment"
        }
        backButton={
          <IconButton
            onClick={() => {
              setSelectedPage("");
            }}
          >
            <ArrowBack />
          </IconButton>
        }
        buttons={
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={5}
            sx={isPhone && { justifyContent: "space-between", width: "100%" }}
          >
            {false && (
              <Typography
                color={"text.secondary"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  whiteSpace: "nowrap",
                }}
              >
                {isPhone ? "Total contacts" : "Total contacts selected :"}
                {fetchingCount && (
                  <CircularProgress
                    color="primary"
                    style={{ height: "17px", width: "17px" }}
                  />
                )}
                <Typography display={"inline"} variant="h6">
                  {typeof gettingCount === Boolean && gettingCount
                    ? "loading"
                    : gettingCount}
                </Typography>
              </Typography>
            )}
            <LoadingButton
              loading={loading}
              variant="contained"
              sx={{ whiteSpace: "nowrap" }}
              onClick={() => {
                putSegmentData();
                // setCreate(true)
              }}
            >
              {isPhone
                ? isObjWithValues(isEdit)
                  ? "Update"
                  : "Create"
                : isObjWithValues(isEdit)
                ? "Update"
                : "Create "}
            </LoadingButton>
          </Stack>
        }
      />

      <DialogContent>
        <Paper sx={{ borderRadius: "12px", padding: "35px" }}>
          <Box>
            <Stack justifyContent={"end"} direction={"row"} sx={{ mb: 3 }}>
              <Typography
                color={"text.secondary"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  whiteSpace: "nowrap",
                }}
              >
                {isPhone ? "Total contacts" : "Total contacts selected :"}
                {fetchingCount && (
                  <CircularProgress
                    color="primary"
                    style={{ height: "17px", width: "17px" }}
                  />
                )}
                <Typography display={"inline"} variant="h6">
                  {typeof gettingCount === Boolean && gettingCount
                    ? "loading"
                    : gettingCount}
                </Typography>
              </Typography>
            </Stack>
            <Grid container>
              <Grid item sx={12} md={12}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{ width: "100%" }}
                >
                  <Stack>
                    <FilterLabel variant="h6">Segment Name*</FilterLabel>
                    <TextField
                      onError={errors?.segment_name}
                      size="small"
                      sx={{ minWidth: "300px" }}
                      value={filterForm?.segment_name || ""}
                      onChange={(e) =>
                        editFilterForm({ segment_name: e.target.value })
                      }
                    />
                    <Typography
                      style={{
                        ...primaryLabel,
                        marginTop: 4,
                        textAlign: "center",
                      }}
                      color="error.main"
                    >
                      {errors?.segment_name && "Please enter name for segment"}
                    </Typography>
                  </Stack>
                  {false && (
                    <Stack justifyContent={"end"}>
                      <FilterLabel
                        variant="h6"
                        textAlign={"end"}
                        sx={{ mr: "15px" }}
                      >
                        Select Date
                      </FilterLabel>
                      <CustomDateRangeFilter
                        dateRange={filterForm}
                        startDateLabel={"after"}
                        endDateLabel={"before"}
                        setDateRange={(dateRange) =>
                          editFilterForm({ ...filterForm, ...dateRange })
                        }
                        mobileMode

                        // containerSx={{ justifyContent: "center", py: 0 }}
                      />
                      <Popover
                        id={dateId}
                        open={dateOpen}
                        anchorEl={dateAnchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <DatePicker
                          dateRanges={filterForm}
                          setDateRanges={onDateSubmit}
                          onClose={handleClose}
                        />
                      </Popover>
                    </Stack>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={6} mt={4} rowSpacing={6}>
              <Grid item xs={12} md={12}>
                {/* <Typography variant="h6">Select contact Groups</Typography> */}
                <FilterLabel variant="h6">Channels</FilterLabel>
                {/* <Label sx={{ mt: 2 }}>Channels</Label> */}
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  {socials
                    ?.filter(
                      (i) =>
                        integratedChannels?.includes(i.value) ||
                        integratedChannels?.includes(i.key)
                    )
                    ?.map((o) => (
                      <JewelleryTypeCard
                        key={o?.label}
                        obj={o}
                        onClick={() => onSelectChannel(o)}
                        isSelected={filterForm?.socials?.find(
                          (obj) => obj?.label === o?.label
                        )}
                        isMobile
                        labelProps={{ fontWeight: 600 }}
                      />
                    ))}
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Label>Sorting</Label>
                <TextField
                  size="small"
                  select
                  fullWidth
                  value={filterForm?.sorting?.[0] || ""}
                  onChange={(e) => sortingCheckHandler(e.target.value)}
                  InputProps={{
                    endAdornment: filterForm?.sorting?.[0] ? (
                      <InputAdornment position="start">
                        <IconButton
                          size={"small"}
                          onClick={() => {
                            sortingCheckHandler("");
                          }}
                        >
                          <Close style={{ height: "18px", width: "18px" }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                >
                  {sorting?.map((obj) => (
                    <MenuItem value={obj}>{obj?.label}</MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={4}>
                <Label>Filter by Action</Label>
                <Autocomplete
                  multiple
                  size="small"
                  fullWidth
                  id="tags-standard"
                  options={[
                    { label: "Abandoned Cart", value: "cart" },
                    { label: "WishList", value: "wishlist" },
                    { label: "Product Viewed", value: "wishlist" },
                    { label: "Birthday", value: "wishlist" },
                    { label: "Anniversaries", value: "wishlist" },

                    // { label: "Catalogs", value: "catalog" },
                    // { label: "Orders", value: "order" },
                  ]}
                  getOptionLabel={(option) => option?.label || ""}
                  value={filterForm?.product || []}
                  onChange={(e, newValue) => {
                    console.log(newValue);
                    editFilterForm({ product: newValue });
                  }}
                  // value={Object.keys(filterForm ||{})?.filter(key => )}
                  // defaultValue={[top100Films[13]]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // variant="standard"
                      // label="Multiple values"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={6} md={4}>
                <Label>Filter by social</Label>
                <Autocomplete
                  multiple
                  size="small"
                  fullWidth
                  id="tags-standard"
                  options={socials}
                  getOptionLabel={(option) => option?.label || ""}
                  value={filterForm?.socials || []}
                  onChange={(e, newValue) => {
                    editFilterForm({ socials: newValue });
                  }}
                  // value={Object.keys(filterForm ||{})?.filter(key => )}
                  // defaultValue={[top100Films[13]]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // variant="standard"
                      // label="Multiple values"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </Grid> */}
              <Grid item xs={6} md={4}>
                <Label>Filter by Group</Label>
                <Select
                  value={filterForm?.group || ""}
                  onChange={(e) =>
                    editFilterForm({
                      group:
                        filterForm?.group === e.target.value
                          ? ""
                          : e.target.value,
                    })
                  }
                  fullWidth
                  size="small"
                  MenuProps={{
                    sx: { maxHeight: "300px" },
                  }}
                  endAdornment={
                    <IconButton
                      sx={{
                        display: filterForm?.group ? "block" : "none",
                        mr: 1.8,
                      }}
                      onClick={() =>
                        editFilterForm({
                          group: "",
                        })
                      }
                      size={"small"}
                    >
                      <Clear style={{ height: "18px", width: "18px" }} />
                    </IconButton>
                  }
                >
                  {isArrayWithValues(reduxCustomerGroup) &&
                    reduxCustomerGroup?.map((i) => {
                      return (
                        <MenuItem value={i.group_name}>
                          {formatServerValue(i.group_name)}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
              <Grid item xs={12} md={4}>
                <Label>Filter by Store/Branch</Label>
                <Select
                  value={filterForm?.branch || ""}
                  onChange={(e) =>
                    editFilterForm({
                      branch:
                        filterForm?.branch === e.target.value
                          ? ""
                          : e.target.value,
                    })
                  }
                  fullWidth
                  size="small"
                  MenuProps={{
                    sx: { maxHeight: "300px" },
                  }}
                  endAdornment={
                    <IconButton
                      sx={{
                        display: filterForm.branch ? "block" : "none",
                        mr: 1.8,
                      }}
                      onClick={() =>
                        editFilterForm({
                          branch: "",
                        })
                      }
                      size={"small"}
                    >
                      <Clear style={{ height: "18px", width: "18px" }} />
                    </IconButton>
                  }
                >
                  {isObjWithValues(branches) &&
                    Object.values(branches || {})?.map((i) => {
                      return (
                        <MenuItem value={i.branchName}>
                          {formatServerValue(i.branchName)}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
              <Grid item xs={12} md={4}>
                <Label>Filter by value</Label>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Select
                      value={filterForm?.filterValueLabel || ""}
                      onChange={(e) =>
                        editFilterForm({
                          filterValueLabel:
                            filterForm?.filterValueLabel === e.target.value
                              ? ""
                              : e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                      endAdornment={
                        <IconButton
                          sx={{
                            display: filterForm.filterValueLabel
                              ? "block"
                              : "none",
                            mr: 1.8,
                          }}
                          onClick={() =>
                            editFilterForm({
                              filterValueLabel: "",
                            })
                          }
                          size={"small"}
                        >
                          <Clear style={{ height: "18px", width: "18px" }} />
                        </IconButton>
                      }
                    >
                      {likeFilters.map((i) => {
                        return <MenuItem value={i.value}>{i.label}</MenuItem>;
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      fullWidth
                      value={filterForm?.filterValue || ""}
                      onChange={(e) =>
                        editFilterForm({ filterValue: e.target.value })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Label>Search by date</Label>
                <Grid container spacing={2} rowSpacing={4}>
                  <Grid item xs={12}>
                    <Select
                      value={filterForm?.rangeLabel || ""}
                      onChange={(e) =>
                        editFilterForm({
                          rangeLabel:
                            filterForm?.rangeLabel === e.target.value
                              ? ""
                              : e.target.value,
                        })
                      }
                      fullWidth
                      size="small"
                      endAdornment={
                        <IconButton
                          sx={{
                            display: filterForm.rangeLabel ? "block" : "none",
                            mr: 1.8,
                          }}
                          onClick={() =>
                            editFilterForm({
                              rangeLabel: "",
                            })
                          }
                          size={"small"}
                        >
                          <Clear style={{ height: "18px", width: "18px" }} />
                        </IconButton>
                      }
                    >
                      {rangeFilters.map((i) => {
                        return <MenuItem value={i.value}>{i.label}</MenuItem>;
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        // label="Anniversary"
                        value={
                          filterForm?.minRange ? filterForm?.minRange : null
                        }
                        onChange={(newValue) => {
                          editFilterForm({ minRange: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        // label="Anniversary"
                        value={
                          filterForm?.maxRange ? filterForm?.maxRange : null
                        }
                        onChange={(newValue) => {
                          editFilterForm({ maxRange: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </DialogContent>
    </>
  );
};

export const filterSubmitHandler = (filterForm, CRMCustomFields) => {
  let filterObj = {
    custom_filter: [],
  };
  let paramsObj = {};
  let deletableKeys = Object.keys(paramsObj).filter(
    (i) => i.includes("custom_filter") || i.includes("sortby")
  );
  for (let key of deletableKeys) delete paramsObj[key];
  // if (!reset) {
  let filtersObj = filterForm;
  let {
    sorting,
    cart,
    wishlist,
    group,
    filterValue,
    filterValueLabel,
    searchValue,
    searchValueLabel,
    minRange,
    maxRange,
    rangeLabel,
    socials,
    branch,
    product,
    religion,
    ethincity,
    betweenFilters,
    minBetween,
    maxBetween,
    catalog,
    sales_app,
    order,
    user_birthday_start,
    user_birthday_end,
    user_anniversary,
    user_anniversary_start,
    user_anniversary_end,
  } = filtersObj || {};

  let storeId = getStoreId();
  if (sorting?.[0]?.returning) {
    filterObj = { ...filterObj, returning: true };
  }
  if (isArrayWithValues(sorting) && !sorting?.[0]?.returning) {
    // filterObj.sortby = sorting[0];
    filterObj.custom_filter.push(sorting[0]);
  }
  if (isArrayWithValues(socials)) {
    // console.log(socials);
    for (let social of socials) {
      if (isObjWithValues(social)) {
        let { label, icon, filter_value, ...other } = social || {};
        filterObj.custom_filter.push({
          ...other,
          value: social?.hasOwnProperty("filter_value")
            ? filter_value
            : other?.value,
        });
      } else
        filterObj.custom_filter.push({
          key: social,
          value: "",
          compare: "LIKE",
          type: "NUMERIC",
        });
    }
  }
  if (isArrayWithValues(product)) {
    for (let filter of product) {
      if (isObjWithValues(filter)) {
        let { label, ...other } = filter || {};
        filterObj.custom_filter.push({
          ...other,
        });
      }
    }
  }
  if (religion) {
    filterObj.custom_filter.push({
      key: `religion`,
      value: religion,
      compare: "LIKE",
    });
  }
  if (cart) {
    filterObj.custom_filter.push({
      key: `_woocommerce_persistent_cart_${storeId}`,
      value: "product_id",
      compare: "LIKE",
    });
  }
  if (ethincity) {
    filterObj.custom_filter.push({
      key: `ethincity`,
      value: ethincity,
      compare: "LIKE",
    });
  }
  // if (product) {
  //   filterObj.custom_filter.concat(product);
  // }
  if (wishlist) {
    filterObj.custom_filter.push({
      key: `woodmart_wishlist_`,
      value: "product_id",
      compare: "LIKE",
      compare_key: "LIKE",
    });
  }
  if (group) {
    if (isArrayWithValues(group)) {
      for (let gr of group) {
        filterObj.custom_filter.push({
          key: `group_name_${storeId}`,
          value: gr,
          compare: "LIKE",
        });
      }
    } else
      filterObj.custom_filter.push({
        key: `group_name_${storeId}`,
        value: group,
        compare: "LIKE",
      });
  }
  if (branch) {
    filterObj.custom_filter.push({
      key: `branch_from`,
      value: branch,
      compare: "LIKE",
    });
  }
  if (filterValue && filterValueLabel) {
    filterObj.custom_filter.push({
      key: filterValueLabel,
      value: filterValue,
      compare: "LIKE",
    });
  }
  if (searchValue && searchValueLabel) {
    filterObj.custom_filter.push({
      key: searchValueLabel,
      value: searchValue,
      compare: "LIKE",
    });
  }
  if (order) {
    filterObj.custom_filter.push({
      key: "order",
    });
  }
  if (catalog) {
    filterObj.custom_filter.push({
      key: "catalog",
    });
  }

  if (
    rangeLabel &&
    isValidDate(new Date(minRange)) &&
    isValidDate(new Date(maxRange))
  ) {
    filterObj.custom_filter.push({
      key: rangeLabel,
      value: [
        formatDate(minRange, "yyyy-mm-dd"),
        formatDate(maxRange, "yyyy-mm-dd"),
      ],
      compare: "BETWEEN",
    });
  }
  if (
    isValidDate(new Date(user_birthday_start)) &&
    isValidDate(new Date(user_birthday_end))
  ) {
    filterObj.custom_filter.push({
      key: "user_birthday",
      value: [
        formatDate(user_birthday_start, "yyyy-mm-dd"),
        formatDate(user_birthday_end, "yyyy-mm-dd"),
      ],
      compare: "BETWEEN",
    });
  }
  if (
    isValidDate(new Date(user_anniversary_start)) &&
    isValidDate(new Date(user_anniversary_end))
  ) {
    filterObj.custom_filter.push({
      key: "user_anniversary",
      value: [
        formatDate(user_anniversary_start, "yyyy-mm-dd"),
        formatDate(user_anniversary_end, "yyyy-mm-dd"),
      ],
      compare: "BETWEEN",
    });
  }
  if (betweenFilters && isNumber(minBetween) && isNumber(maxBetween)) {
    filterObj.custom_filter.push({
      key: betweenFilters,
      value: [validateNumber(minBetween), validateNumber(maxBetween)],
      compare: "BETWEEN",
    });
  }
  // }
  return filterObj;
};

const EmailIntegratePopup = ({ onClose, channel }) => {
  const navigate = useNavigate();

  const getLogo = (channel) => {
    switch (channel) {
      case "email":
        return (
          <MarkEmailReadOutlined
            style={{ height: "70px", width: "70px", opacity: "0.8" }}
            color="primary"
          />
        );

      case "whatsapp":
        return (
          <WhatsappSvg
            height={60}
            width={60}
            // style={{ height: "70px", width: "70px", opacity: "0.8" }}
            // color="primary"
          />
        );

      case "sms":
        return (
          <SmsOutlined
            style={{ height: "70px", width: "70px", opacity: "0.8" }}
            color="primary"
          />
        );
      case "push_notification":
        return (
          <NotificationsActiveOutlined
            style={{ height: "70px", width: "70px", opacity: "0.8" }}
            color="primary"
          />
        );

      default:
        return (
          <MarkEmailReadOutlined
            style={{ height: "70px", width: "70px", opacity: "0.8" }}
            color="primary"
          />
        );

        break;
    }
  };

  let navigateString = (channel) => {
    switch (channel) {
      case "email":
        return `/settings#email`;

        break;
      case "whatsapp":
        return `/integrations#Meta`;
        break;
      case "sms":
        return `/integrations#SMS`;
        break;
      case "push_notification":
        return `/integrations#onesignal`;
        break;

      default:
        return `/settings#email`;

        break;
    }
  };
  return (
    <>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography></Typography>
        <IconButton onClick={() => onClose()}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <EmptyState
          icon={getLogo(channel)}
          text={
            `Please ${formatServerValue(
              channel
            )} integration  to create a campaign`
            // <>
            //     <Typography></Typography>
            // </>
          }
          button={
            <Button
              onClick={() => navigate(navigateString(channel))}
              variant="outlined"
            >
              Integrate {formatServerValue(channel)}
            </Button>
          }
        />
      </DialogContent>
    </>
  );
};

export const returnOptOutParams = (params) => {
  let paramsObj = {
    ...(params?.params || {}),
    custom_filter: params?.params?.custom_filter || [],

    // ...params,
    _fields: "first_name,last_name",
    // per_page: 10,
  };
  paramsObj.custom_filter = [
    ...(isArrayWithValues(paramsObj.custom_filter)
      ? paramsObj.custom_filter
      : []),
    {
      compare_key: "email_opt_out",
      compare: "NOT_EXISTS",
    },
  ];
  return paramsObj;
};

const SegmentListItem = ({
  obj,
  setsegmentSelected,
  setDeleteSegemt,
  hideDelete,
  onEditForm,
  handleClick,
  createCampaignState,
  hideSelect,
  tableView,
  index,
  setViewCustomer,
  onEditSegment,
}) => {
  // useEffect
  const [fetchingNumber, setFetchingNumber] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!obj?.count) {
      dispatch(setCountOnSegment(obj, setFetchingNumber));
    }
  }, []);
  const segmentInfo = useSelector(
    (state) => state?.chats?.campaignAnalytics
  )?.segmentInfo;

  const viewClick = (ob) => {
    setViewCustomer &&
      // setViewCustomer((obj?.params))
      setViewCustomer(returnOptOutParams(ob));
  };

  return (
    <>
      {tableView && (
        <>
          <TableRow
            // key={row.name}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell padding="checkbox" align="left">
              <Typography sx={{ ml: 3, fontWeight: "600" }}>
                {index + 1}
              </Typography>
            </TableCell>
            {!hideSelect && (
              <TableCell component="th" scope="row">
                <Checkbox
                  edge="start"
                  checked={Boolean(
                    createCampaignState?.segment_id?.includes(obj?.id)
                  )}
                  disableRipple
                />
              </TableCell>
            )}
            <TableCell>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {obj?.filterForm?.segment_name}
              </Typography>
            </TableCell>
            <TableCell>
              {formatDate(
                new Date(validateNumber(obj?.id?.split("_")?.[0])),
                "dd/mm/yyyy"
              )}
            </TableCell>
            {/* <TableCell>
              <HorizontalStack spacing={2}>
                {fetchingNumber && (
                  <CircularProgress style={{ height: "11px", width: "11px" }} />
                )}
                <Typography
                  variant="h6"
                  onClick={(e) => {
                    e.stopPropagation();
                    viewClick(obj);
                    // setViewCustomer &&
                    //   setViewCustomer(returnOptOutParams(obj?.params));
                  }}
                  // fontSize={"1rem"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    cursor: "pointer",
                  }}
                >
                  {obj?.count}
                  <People fontSize="small" color="action" />
                </Typography>
              </HorizontalStack>
            </TableCell> */}
            <TableCell>
              <Tooltip
                title={(segmentInfo?.[obj?.id]?.campaignNames || [])?.join(",")}
              >
                <Typography
                  variant="h6"
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  <CampaignOutlined /> {segmentInfo?.[obj?.id]?.totalSent || 0}
                </Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="right">
              <Stack direction={"row"} justifyContent={"flex-end"} gap={2}>
                {onEditSegment && (
                  <>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onEditSegment(obj);
                        // handleClick(e, obj);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    {/* <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    // handleClick(e, obj);
                  }}
                >
                  <Delete />
                </IconButton> */}
                  </>
                )}
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClick(e, obj);
                  }}
                  color={"error"}
                >
                  <Delete />
                </IconButton>
              </Stack>
            </TableCell>
          </TableRow>
          {/* <TableContainer  component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Dessert (100g serving)</TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> */}
        </>
      )}
      {!tableView && (
        <>
          <ListItem>
            <ListItemButton
              onClick={(e) => {
                let idArrays = [...(createCampaignState?.segment_id || [])];

                !hideSelect &&
                  onEditForm({
                    segment_id: idArrays?.includes(obj?.id)
                      ? idArrays?.filter((str) => str != obj?.id)
                      : [...(idArrays || []), obj?.id],
                  });
              }}
              // onClick={() => setsegmentSelected(obj)}
            >
              {/* <ListItemIcon> */}
              {!hideSelect && (
                <Checkbox
                  edge="start"
                  checked={Boolean(
                    createCampaignState?.segment_id?.includes(obj?.id)
                  )}
                  disableRipple
                />
              )}
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ width: "100%" }}
              >
                <Stack sx={{ flexGrow: 1 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {obj?.filterForm?.segment_name}
                  </Typography>
                  <Typography sx={{ ...secondaryLabelSmall }}>
                    Created on :{" "}
                    {formatDate(
                      new Date(validateNumber(obj?.id?.split("_")?.[0])),
                      "dd/mm/yyyy"
                    )}
                  </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} gap={2}>
                  <Typography
                    variant="h6"
                    onClick={(e) => {
                      e.stopPropagation();

                      viewClick(obj);
                      // setViewCustomer &&
                      //   // setViewCustomer((obj?.params))
                      //   setViewCustomer(returnOptOutParams(obj));
                    }}
                    // fontSize={"1rem"}
                    sx={{ display: "flex", alignItems: "center", gap: 2 }}
                  >
                    {obj?.count}
                    <People fontSize="small" color="action" />
                  </Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClick(e, obj);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              </Stack>

              {/* </ListItemIcon> */}
              {/* <Grid container>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <Typography variant="body2" fontSize={"1rem"}>
                {obj?.filterForm?.segment_name}
              </Typography>
              <Typography sx={{ ...secondaryLabel }}>
                Created on :{" "}

              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack>
                  <Typography
                    variant="h6"
                    fontSize={"1rem"}
                    sx={{ display: "flex", alignItems: "center", gap: 2, }}
                  >
                    {obj?.count}
                    <People fontSize="small" color="action" />
                  </Typography>
                  <Typography sx={{ ...secondaryLabel }}>{formatDate(
                    new Date(validateNumber(obj?.id?.split("_")?.[0])),
                    "dd/mm/yyyy"
                  )}</Typography>
                </Stack>
                <IconButton
                  onClick={(e) => {
                    handleClick(e, obj);
                  }}
                >
                  <Delete />
                </IconButton>
              </Stack>
            </Grid>
          </Grid> */}
              {/* <ListItemText
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
                <Delete />
              </IconButton>
            }
            primary={
              <>
                {obj?.filterForm?.segment_name}{" "}
                {fetchingNumber ? "Loading" : `(${obj?.count || ""})`}
              </>
            }
            secondary={
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Created on
                  </Typography>
                  <Typography>
                    | {"  "}
                    {formatDate(
                      new Date(validateNumber(obj?.id?.split("_")?.[0])),
                      "dd:mm:yyyy hh:mm"
                    )}
                  </Typography>
                </Stack>
                {hideDelete ? null : (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteSegemt(obj);
                    }}
                  >
                    <Delete color="error" />
                  </IconButton>
                )}
              </Stack>
            }
          /> */}
            </ListItemButton>
          </ListItem>
        </>
      )}
    </>
  );
};

export const CustomeDialogTitle = ({
  dialogTitle,
  buttons,
  backButton,
  tileIcon,
  asBox,
  breadcrumbs,
  sx = {},
}) => {
  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // boxShadow: asBox ? "" : "rgba(0, 0, 0, 0.04) 0px 3px 5px;",
          padding: isIpad ? 0 : "10px 24px",
          mb: 4,
          ...sx,
          flexWrap: "wrap",
        }}
      >
        <Stack>
          <Typography
            variant={asBox ? "h3" : "h6"}
            sx={
              asBox
                ? {
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }
                : {
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    fontSize: "1.2rem",
                    fontWeight: 700,
                    color: "text.secondary",
                  }
            }
          >
            {backButton}
            {dialogTitle}
            {tileIcon}
          </Typography>
          {breadcrumbs && (
            <Box>
              <Breadcrumbs aria-label="Breadcrumb" mt={1}>
                <Link component={NavLink} to="/">
                  Dashboard
                </Link>
                {breadcrumbs?.map((obj) => {
                  if (obj?.link)
                    return (
                      <Link component={NavLink} to={obj?.link}>
                        {obj?.title}
                      </Link>
                    );
                  return <Typography>{obj.title}</Typography>;
                })}
              </Breadcrumbs>
            </Box>
          )}
        </Stack>

        {buttons}
      </DialogTitle>
    </>
  );
};
const categories = [
  { label: "Marketing", value: "marketing" },
  { label: "Promotional", value: "promotional" },
  // {label: "Transactional", value: "transactional" },
  { label: "Greetings ", value: "greetings" },
];

const push_notification = "push_notification";
const sms = "sms";
const email = "email";
const whatsapp = "whatsapp";

const CreateCampaign = ({
  setEmailOpen,
  error,
  setError,
  customerSegments,
  setSelectedPage,
  fetchingList,
  createCampaignState,
  setCreateCampaignState,
  setSelected,
  onEditForm,
  smtpMail,
  selected,
  emailOpen,
  onSelectedTemplated,
  customerCount,
  setCustomerCount,
  sendEmailTemplate,
  loading,
  // isPhone,
  setPreview,
  preview,
  setOnSelectedtemplated,
  setIsEdit,
}) => {
  const metaIntegration = useSelector(
    (state) => state?.settings?.metaIntegration
  );
  const smsIntegration = useSelector(
    (state) => state?.settings?.smsIntegration
  );
  const reduxOnesignalIntegration = useSelector(
    (state) => state.settings.onesignalIntegration
  );
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [openSms, setOpenSms] = useState(false);
  const [emailIntegrated, setEmailIntegrated] = useState(true);

  const getWhatsappNumber = (metaIntegration) => {
    let whatsappObj = getIntegrationObj(metaIntegration, "whatsapp")?.obj || {};

    let selectObj = whatsappObj?.whatsapp_info?.find(
      (o) => o?.id === whatsappObj?.selected_whatsapp_phone_id
    );
    return selectObj?.display_phone_number;
  };
  useEffect(() => {
    if (emailOpen === "email")
      setEmailIntegrated(isEmailIsIntegrated(smtpMail));
    if (emailOpen === "whatsapp")
      setEmailIntegrated(Boolean(getWhatsappNumber(metaIntegration)));
    if (emailOpen === "sms")
      setEmailIntegrated(Boolean(smsIntegration?.selected_channel));

    if (emailOpen === "oneSignal")
      setEmailIntegrated(
        Boolean(isObjWithValues(reduxOnesignalIntegration?.selectedAccount))
      );

    // // setDefaultState();
    // return () => {
    //   setWithFilters && setWithFilters(false);
    // };
  }, [smtpMail, metaIntegration, emailOpen]);
  let segmentCount =
    typeof emailOpen === "boolean"
      ? selected?.length
      : Object.values(customerSegments || {})
          ?.filter((obj) => createCampaignState?.segment_id?.includes(obj?.id))
          ?.map((obj) => obj?.count)
          ?.reduce(
            (accumulator, currentValue) =>
              validateNumber(accumulator) + validateNumber(currentValue),
            0
          );
  const reduxEmailTemplates = useSelector(
    (state) => state.chats.emailTemplates
  );
  const [activeSteps, setActiveSteps] = useState([
    { active: true, completed: false, icon: "1" },
    { active: false, completed: false, icon: "2" },
    { active: false, completed: false, icon: "3" },
  ]);
  useEffect(() => {
    let segmentCount =
      typeof emailOpen === "boolean"
        ? selected?.length
        : Object.values(customerSegments || {})
            ?.filter((obj) =>
              createCampaignState?.segment_id?.includes(obj?.id)
            )
            ?.map((obj) => obj?.count)
            ?.reduce(
              (accumulator, currentValue) =>
                validateNumber(accumulator) + validateNumber(currentValue),
              0
            );
    setCustomerCount(segmentCount);

    return () => {};
  }, [createCampaignState, selected]);
  useEffect(async () => {
    if (createCampaignState?.category && createCampaignState?.name) {
      setActiveSteps([
        { active: true, completed: true, icon: <DoneOutlined /> },
        { active: true, completed: false, icon: 2 },
        { active: false, completed: false, icon: "3" },
      ]);
    }
    if (createCampaignState?.segment_id) {
      setActiveSteps([
        { active: true, completed: true, icon: <DoneOutlined /> },
        { active: true, completed: true, icon: <DoneOutlined /> },
        { active: true, completed: false, icon: "3" },
      ]);
    }

    if (onSelectedTemplated?.id) {
      setActiveSteps([
        { active: true, completed: true, icon: <DoneOutlined /> },
        { active: true, completed: true, icon: <DoneOutlined /> },
        { active: true, completed: true, icon: <DoneOutlined /> },
      ]);
    }
    return () => {};
  }, [JSON.stringify(createCampaignState), onSelectedTemplated]);

  const ongoToCreate = () => {
    if (!createCampaignState?.name)
      return setError({
        nameError,
      });
    if (!createCampaignState?.category)
      return setError({
        categoryError,
      });
    if (emailOpen !== "push_notification") {
      if (onSelectedTemplated?.id || createCampaignState?.template_name) {
      } else
        return setError({
          templateError,
        });
    }

    if (!createCampaignState?.segment_id)
      return setError({
        recipientsError,
      });
    if (typeof emailOpen !== "boolean") {
      if (!isArrayWithValues(createCampaignState?.segment_id))
        return setError({
          recipientsError,
        });
    }
    // return;
    setSelectedPage(confirmPage);
  };
  const onDraftClick = () => {
    if (!createCampaignState?.name)
      return setError({
        nameError,
      });
    if (!createCampaignState?.category)
      return setError({
        categoryError,
      });
    if (!onSelectedTemplated?.id)
      return setError({
        templateError,
      });

    // if (
    //   !createCampaignState?.createCampaignState?.segment_id
    // )
    //   return setError({
    //     recipientsError,
    //   });
    if (!isArrayWithValues(createCampaignState?.segment_id))
      return setError({
        recipientsError,
      });
    sendEmailTemplate({ draft: true });
  };

  const onBack = () => {
    if (
      !createCampaignState?.name &&
      !createCampaignState?.category &&
      !onSelectedTemplated?.id &&
      !isArrayWithValues(createCampaignState?.segment_id)
    )
      return setEmailOpen(false);
    // if (!createCampaignState?.category) return setEmailOpen(false);
    // if (!onSelectedTemplated?.id) return setEmailOpen(false);
    // if (!isArrayWithValues(createCampaignState?.segment_id))
    //   return setEmailOpen(false);
    // return;
    setSelectedPage(exitPage);
  };

  const onSelectWhatsapp = (template, obj, extra) => {
    setOnSelectedtemplated(template);
    onEditForm({
      templateString:
        extra?.components?.find((o) => o.type === "BODY")?.text || "",
      sender_id: extra?.components.find((o) => o.type === "HEADER")?.text || "",
      template_id: extra?.id,
      template_name: extra?.name,
    });
    // setOpenSms(false)
  };
  let getsenderName = (channel) => {
    switch (channel) {
      case "whatsapp":
        return getWhatsappNumber(metaIntegration) || "";
        break;
      case "email":
        return smtpMail?.username || "";
        break;
      case "sms":
        return (
          smsIntegration?.[smsIntegration?.selected_channel]?.sender_id || ""
        );
      case "push_notification":
        return reduxOnesignalIntegration?.selectedAccount?.name || "";
        break;

      default:
        break;
    }
  };

  //////
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //////////

  if (!emailIntegrated)
    return (
      <EmailIntegratePopup
        channel={emailOpen}
        onClose={() => setEmailOpen(false)}
      />
    );

  return (
    <>
      <CustomeDialogTitle
        dialogTitle={
          <>
            Create {formatServerValue(emailOpen)}{" "}
            {/* {campaignChannelIcon?.[emailOpen]} */}
            Campaign
          </>
        }
        // tileIcon={<CampaignOutlined color="action" />}
        tileIcon={campaignChannelIcon?.[emailOpen]}
        backButton={
          <IconButton
            onClick={() => {
              onBack();
            }}
          >
            <ArrowBack />
          </IconButton>
        }
        buttons={
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: 3,
              width: isPhone ? "100%" : "auto",
              justifyContent: isPhone ? "space-between" : "initial",
              rowGap: isPhone ? 2 : 0,
            }}
          >
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <Typography sx={{ ...secondaryLabel, whiteSpace: "nowrap" }}>
                {isPhone ? "Total contacts" : "Total contacts selected :"}
              </Typography>
              <Typography>{segmentCount || 0}</Typography>
            </Stack>
            {false && (
              <LoadingButton
                loading={loading}
                variant="outlined"
                onClick={() => onDraftClick()}
                sx={{ whiteSpace: "nowrap" }}
              >
                Save as Draft
              </LoadingButton>
            )}
            <Button
              endIcon={<Send fontSize={"small"} />}
              variant="contained"
              onClick={() => {
                ongoToCreate();
              }}
              size="large"
              sx={{ padding: "8px 30px" }}
            >
              Send Campaign
            </Button>
          </Stack>
        }
      />
      <DialogContent sx={isPhone ? { padding: 0 } : {}}>
        <Box
        // sx={{ borderRadius: "20px", padding: "35px" }}
        >
          <Collapse in={Boolean(Object.values(error || {})?.[0])}>
            <Alert sx={{ padding: "0  16px", mb: 2 }} severity="error">
              {Object.values(error || {})?.[0]}
            </Alert>
          </Collapse>
          <Box>
            <Grid container spacing={10}>
              <Grid item xs={12} md={4}>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ my: 2 }}
                >
                  <Stepper {...(activeSteps?.[0] || {})}>
                    <Step
                      key={"label"}
                      sx={{ flexDirection: "column", rowGap: 2 }}
                    >
                      <StepLabel
                        sx={{ flexDirection: "column", rowGap: 2 }}
                        StepIconComponent={ColorlibStepIcon}
                        icon={activeSteps?.[0]?.icon}
                        StepIconProps={activeSteps[0]}
                        // onClick={() => isDev() && setActiveTab(index)}
                        // sx={{ cursor: "pointer" }}
                      >
                        Campaign Info
                        {/* {label} */}
                      </StepLabel>
                    </Step>
                  </Stepper>
                </Stack>
                <Card
                  sx={{
                    borderRadius: "15px",
                    height: isPhone ? "auto" : "60vh",
                    opacity: activeSteps[0].active ? 1 : 0.6,
                  }}
                >
                  <CardContent>
                    <Typography variant="h6">Campaign details</Typography>

                    <Grid
                      container
                      spacing={4}
                      rowSpacing={4}
                      sx={{ mt: 2, mr: 3 }}
                    >
                      <Grid item xs={6} md={12}>
                        <Label>Campaign Name</Label>
                        <TextField
                          sx={{ ...textFeildRadius }}
                          fullWidth
                          size="small"
                          value={createCampaignState?.name}
                          onChange={(e) => onEditForm({ name: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={6} md={12}>
                        <Label>Category</Label>
                        <TextField
                          sx={{ ...textFeildRadius }}
                          select
                          fullWidth
                          size="small"
                          value={createCampaignState?.category}
                          onChange={(e) =>
                            onEditForm({ category: e.target.value })
                          }
                        >
                          {categories?.map((obj) => (
                            <MenuItem value={obj?.value}>{obj?.label}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} md={12}>
                        <Label sx={{ opacity: "0.6" }}>whatsapp Limit</Label>
                        <TextField
                          sx={{ ...textFeildRadius }}
                          fullWidth
                          size="small"
                          type="number"
                          value={createCampaignState?.whatsapp_limit || ""}
                          onChange={(e) =>
                            onEditForm({ whatsapp_limit: e.target.value })
                          }
                        />
                      </Grid>
                      <Grid item xs={6} md={12}>
                        <Label sx={{ opacity: "0.6" }}>Sender</Label>
                        <TextField
                          sx={{ ...textFeildRadius }}
                          fullWidth
                          size="small"
                          value={getsenderName(emailOpen) || ""}
                          // value={smtpMail?.username || ""}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} md={12}>
                        <Label sx={{ opacity: "0.6" }}>Sender Name</Label>
                        <TextField
                          sx={{ ...textFeildRadius }}
                          fullWidth
                          size="small"
                          value={getAdminName()}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                // sx={{ borderLeft: 2, borderColor: "divider" }}
              >
                <Grid container spacing={10}>
                  <Grid item xs={12} md={6}>
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{ my: 2 }}
                    >
                      <Stepper>
                        <Step key={"label"}>
                          <StepLabel
                            sx={{ flexDirection: "column", rowGap: 2 }}
                            StepIconComponent={ColorlibStepIcon}
                            StepIconProps={{ ...activeSteps[1] }}
                            icon={activeSteps?.[1]?.icon}

                            // onClick={() => isDev() && setActiveTab(index)}
                            // sx={{ cursor: "pointer" }}
                          >
                            Select Customer
                            {/* {label} */}
                          </StepLabel>
                        </Step>
                      </Stepper>
                    </Stack>
                    <Card
                      sx={{
                        borderRadius: "15px",
                        height: "60vh",
                        opacity: activeSteps[1].active ? 1 : 0.6,
                        pointerEvents: activeSteps[1].active
                          ? "initial"
                          : "none",
                      }}
                    >
                      <CardContent>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="h6"
                            className={
                              Object.keys(error || {})?.includes(
                                "recipientsError"
                              )
                                ? "btn--jump"
                                : ""
                            }
                          >
                            {selected && typeof emailOpen === "boolean" ? (
                              <>Recipients</>
                            ) : (
                              <>
                                Select Segment (Recipients){" "}
                                {customerSegments
                                  ? `(${Object.keys(customerSegments)?.length})`
                                  : null}
                              </>
                            )}
                          </Typography>
                          {selected && typeof emailOpen === "boolean" ? null : (
                            <Button
                              startIcon={<Add />}
                              onClick={() => {
                                setSelectedPage(createSegment);
                              }}
                              size="small"
                              variant="outlined"
                            >
                              {/* Create new Segment */}
                              Create
                            </Button>
                          )}
                        </Stack>
                        <Box
                          sx={{
                            // height: "240px",
                            overflow: "auto",
                          }}
                        >
                          {selected && typeof emailOpen === "boolean" ? (
                            <>
                              <List sx={{ height: "240px", overflow: "auto" }}>
                                {selected?.map((obj) => {
                                  return (
                                    <ListItem
                                      secondaryAction={
                                        // <IconButton edge="end" aria-label="delete">
                                        //   <DeleteIcon />
                                        // </IconButton>
                                        <Button
                                          color={"error"}
                                          onClick={() => {
                                            setSelected &&
                                              setSelected((pre) =>
                                                pre?.filter(
                                                  (o) => o.id !== obj?.id
                                                )
                                              );
                                          }}
                                        >
                                          Remove{" "}
                                        </Button>
                                      }
                                    >
                                      <ListItemAvatar>
                                        <Avatar></Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={
                                          <Typography>
                                            {" "}
                                            {`${obj.first_name}  ${obj.last_name}`.trim() ||
                                              obj.username}
                                          </Typography>
                                        }
                                        // secondary={secondary ? 'Secondary text' : null}
                                      />
                                    </ListItem>
                                  );
                                })}
                              </List>
                            </>
                          ) : (
                            // <List  >

                            <SelectSemgment
                              customerSegments={customerSegments}
                              fetchingList={fetchingList}
                              onEditForm={onEditForm}
                              createCampaignState={createCampaignState}
                            />
                            // </List>
                          )}

                          {/* <CustomerSegment hideDelete={true} /> */}
                        </Box>
                        {/* <Stack
                direction={"row"}
                alignItems={"center"}
                gap={3}
                flexWrap={"wrap"}
              >
                {Object.values(customerSegments || {}).map((obj) => {
                  return <SagmentCard obj={obj} />;
                })}
              </Stack> */}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{ my: 2 }}
                    >
                      <Stepper {...(activeSteps?.[2] || {})}>
                        <Step key={"label"}>
                          <StepLabel
                            sx={{ flexDirection: "column", rowGap: 2 }}
                            StepIconComponent={ColorlibStepIcon}
                            StepIconProps={activeSteps?.[2]}
                            // onClick={() => isDev() && setActiveTab(index)}
                            // sx={{ cursor: "pointer" }}
                          >
                            Select Template
                            {/* {label} */}
                          </StepLabel>
                        </Step>
                      </Stepper>
                    </Stack>
                    <Card
                      sx={{
                        borderRadius: "15px",
                        height: "60vh",
                        opacity: activeSteps[2].active ? 1 : 0.6,

                        pointerEvents: activeSteps[2].active
                          ? "initial"
                          : "none",
                      }}
                    >
                      <CardContent>
                        {emailOpen === "email" ? (
                          <>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                variant="h6"
                                className={
                                  Object.keys(error || {})?.includes(
                                    "templateError"
                                  )
                                    ? "btn--jump"
                                    : ""
                                }
                              >
                                Email Template{" "}
                                {isObjWithValues(reduxEmailTemplates)
                                  ? `(${
                                      Object.keys(reduxEmailTemplates)?.length
                                    })`
                                  : null}
                              </Typography>
                              <Button
                                startIcon={<Add />}
                                onClick={() => setSelectedPage(openTempalte)}
                                variant="outlined"
                                size="small"
                              >
                                Create
                              </Button>
                              {/* <Button onClick={() => setSelectedPage(templateListPage)}>
                  Show all templates
                </Button> */}
                            </Stack>
                            <SelectTemplate
                              setSelectPreview={setPreview}
                              setTemplateListPage={setSelectedPage}
                              setOnSelectedtemplated={setOnSelectedtemplated}
                              onSelectedTemplated={onSelectedTemplated}
                            />
                          </>
                        ) : emailOpen === "sms" ? (
                          <Stack
                            sx={{ height: "100%", width: "100%" }}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            {openSms && (
                              <SendSms
                                heading={`Send SMS ${
                                  createCampaignState?.name || ""
                                } to 
                                  ${
                                    //   createCampaignState?.segment_id?.map(
                                    //   (str_id) => customerSegments?.[str_id] || ""
                                    // )
                                    ""
                                  }
                                    -  ${segmentCount || 0} Contacts`}
                                open={openSms}
                                handleClose={() => setOpenSms(false)}
                                returnPayload={true}
                                onEditForm={onEditForm}
                              />
                            )}
                            {/* {emailOpen} */}
                            {createCampaignState?.templateString ? (
                              <>
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                  sx={{ width: "100%" }}
                                >
                                  <Typography variant="h6">
                                    Select Template
                                  </Typography>
                                  <IconButton
                                    onClick={() => {
                                      setCreateCampaignState((pre) => {
                                        let tempPreState = { ...pre };
                                        delete tempPreState.template_id;
                                        delete tempPreState.template_name;
                                        delete tempPreState.channel;
                                        delete tempPreState.campaign_type;
                                        delete tempPreState.template;
                                        delete tempPreState.templateString;

                                        return tempPreState;
                                      });
                                    }}
                                  >
                                    <Close />
                                  </IconButton>
                                </Stack>
                                <PhoneMessagePreview
                                  templateString={
                                    createCampaignState?.templateString
                                  }
                                  heading={
                                    <>
                                      <SmsOutlined
                                        color="action"
                                        style={{
                                          height: "18px",
                                          width: "18px",
                                        }}
                                      />
                                      {`JW-${createCampaignState?.sender_id}`}
                                    </>
                                  }
                                />
                              </>
                            ) : (
                              <EmptyState
                                icon={
                                  <SmsOutlined
                                    style={{ height: "40px", width: "40px" }}
                                  />
                                }
                                text={"Select SMS Template"}
                                button={
                                  <Button
                                    startIcon={<Add />}
                                    variant="outlined"
                                    onClick={() => setOpenSms(true)}
                                  >
                                    Select
                                    {/* Select SMS Template */}
                                  </Button>
                                }
                              />
                            )}
                          </Stack>
                        ) : emailOpen === "whatsapp" ? (
                          <Stack
                            sx={{ height: "100%", width: "100%" }}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <TempleteList
                              open={openSms}
                              handleClose={() => setOpenSms(false)}
                              setParentTemplate={onSelectWhatsapp}
                              hideRejected={true}
                            />
                            {/* {emailOpen} */}
                            {onSelectedTemplated ? (
                              <>
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                  sx={{ width: "100%" }}
                                >
                                  <Typography variant="h6">
                                    Select Template
                                  </Typography>
                                  <IconButton
                                    onClick={() => {
                                      setOnSelectedtemplated(false);

                                      setCreateCampaignState((pre) => {
                                        let tempPreState = { ...pre };
                                        delete tempPreState.template_id;
                                        delete tempPreState.template_name;

                                        delete tempPreState.templateString;

                                        return tempPreState;
                                      });
                                    }}
                                  >
                                    <Close />
                                  </IconButton>
                                </Stack>
                                <PhoneMessagePreview
                                  templateString={
                                    createCampaignState?.templateString
                                  }
                                  heading={
                                    <>
                                      <WhatsappSvg height={18} width={18} />{" "}
                                      {createCampaignState?.sender_id}
                                    </>
                                  }
                                />
                              </>
                            ) : (
                              <EmptyState
                                icon={
                                  <WhatsappSvg
                                    style={{ height: "40px", width: "40px" }}
                                  />
                                }
                                text={"Select Whatsapp Template"}
                                button={
                                  <Button
                                    startIcon={<Add />}
                                    variant="outlined"
                                    onClick={() => setOpenSms(true)}
                                  >
                                    Select
                                    {/* Select SMS Template */}
                                  </Button>
                                }
                              />
                            )}
                          </Stack>
                        ) : emailOpen === push_notification ? (
                          <>
                            <Stack
                              sx={{ height: "100%", width: "100%" }}
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              {openSms && (
                                <>
                                  <VariablePopover
                                    heading={`You can copy and paste variables in the Text Feild`}
                                    open={open}
                                    anchorEl={anchorEl}
                                    handleClose={handleClose}
                                  />
                                  <Dialog
                                    fullWidth
                                    maxWidth="md"
                                    sx={dialogStyle}
                                    // fullScreen={fullScreen}
                                    open={openSms}
                                    onClose={() => setOpenSms(false)}
                                  >
                                    <DialogTitle
                                      id="responsive-dialog-title"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      Push notifications
                                      {/* <Button variant="contained" onClick={handleClick}>
                                        Variables
                                      </Button> */}
                                    </DialogTitle>
                                    <DialogContent>
                                      <SendNotification
                                        buttonLabel={"Submit"}
                                        returnPayload={true}
                                        getPayload={(payload) => {
                                          setCreateCampaignState((pre) => ({
                                            ...pre,
                                            payload: payload,
                                          }));
                                        }}
                                        onClose={() => setOpenSms(false)}
                                      />
                                    </DialogContent>
                                  </Dialog>
                                </>
                              )}

                              {/* {emailOpen} */}
                              {createCampaignState?.payload?.contents?.en ? (
                                <>
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    sx={{ width: "100%" }}
                                  >
                                    <Typography variant="h6">
                                      Select Template
                                    </Typography>
                                    <IconButton
                                      onClick={() => {
                                        setCreateCampaignState((pre) => {
                                          let tempPreState = { ...pre };
                                          delete tempPreState.payload;

                                          return tempPreState;
                                        });
                                      }}
                                    >
                                      <Close />
                                    </IconButton>
                                  </Stack>
                                  <PhoneMessagePreview
                                    cardContent={
                                      <>
                                        <Stack
                                          direction={"row"}
                                          justifyContent="space-between"
                                        >
                                          <Typography>
                                            {createCampaignState?.payload.title}
                                          </Typography>
                                          <Typography>now</Typography>
                                        </Stack>
                                        <Stack>
                                          {/* <Typography>{createCampaignState?.paylo}</Typography> */}
                                          <Typography>
                                            {
                                              createCampaignState?.payload
                                                ?.contents?.en
                                            }
                                          </Typography>
                                        </Stack>
                                        {createCampaignState?.payload
                                          .chrome_web_image ? (
                                          <img
                                            alt="notification "
                                            src={
                                              createCampaignState?.payload
                                                .chrome_web_image
                                            }
                                            style={{
                                              width: "100%",
                                              borderRadius: "7px",
                                            }}
                                          />
                                        ) : null}
                                      </>
                                    }
                                    // templateString={
                                    //   createCampaignState?.payload?.contents?.en
                                    // }
                                    // heading={
                                    //   <>
                                    //     <SmsOutlined
                                    //       color="action"
                                    //       style={{
                                    //         height: "18px",
                                    //         width: "18px",
                                    //       }}
                                    //     />
                                    //     {
                                    //       createCampaignState?.payload?.headings
                                    //         ?.en
                                    //     }
                                    //   </>
                                    // }
                                  />
                                </>
                              ) : (
                                <EmptyState
                                  icon={
                                    <OneSignalSvg
                                      style={{ height: "40px", width: "40px" }}
                                    />
                                  }
                                  text={"Make Push Notification"}
                                  button={
                                    <Button
                                      startIcon={<Add />}
                                      variant="outlined"
                                      onClick={() => setOpenSms(true)}
                                    >
                                      Create
                                      {/* Select SMS Template */}
                                    </Button>
                                  }
                                />
                              )}
                            </Stack>
                          </>
                        ) : null}

                        {/* <EmailTemplateList
                // action={true}
                setSelectedPage={setSelectedPage}
                setOnSelectedtemplated={setOnSelectedtemplated}
                setIsEdit={setIsEdit}
                setPreview={setPreview}
                preview={preview}
                hidePredefined={isArrayWithValues(selected)}
                campaignView={true}
                // setCreateTemplate={setCreateTemplate}
                setCreateTemplate={() => setSelectedPage(openTempalte)}
                isCampaign={true}
              /> */}

                        {/* {isObjWithValues(onSelectedTemplated) && (
                <Box
                  sx={{
                    width: "180px",
                    height: "120px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "divider",
                    borderColor: "divider",
                    borderWidth: "divider",
                    backgroundColor: "#eee",
                    borderRadius: 3,
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h6">
                    {onSelectedTemplated?.name}
                  </Typography>
                </Box>
              )}
              {isObjWithValues(onSelectedTemplated) ? null : (
                <Button
                  startIcon={<Add />}
                  variant="outlined"
                  onClick={() => setSelectedPage(openTempalte)}
                  sx={{
                    width: "180px",
                    height: "120px",
                    flexDirection: "column",
                  }}
                >
                  Create Template
                </Button>
              )} */}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>{" "}
      </DialogContent>
    </>
  );
};

const SelectTemplate = ({
  setSelectPreview,
  setTemplateListPage,
  setOnSelectedtemplated,
  onSelectedTemplated,
}) => {
  const reduxEmailTemplates = useSelector(
    (state) => state.chats.emailTemplates
  );
  const getttingEamilTemplate = useSelector(
    (state) => state.chats.getttingEamilTemplate
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isObjWithValues(reduxEmailTemplates)) dispatch(getEmailTemplate());
  }, []);
  return (
    <List sx={{ maxHeight: "50vh", overflow: "auto" }}>
      {getttingEamilTemplate ? (
        <Box
          sx={{
            my: "10vh",
            width: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
      {Object.values(reduxEmailTemplates || {})?.map((obj) => {
        return (
          <>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  setOnSelectedtemplated(obj);
                }}
                // onClick={(e) => {
                //   let idArrays = [...(createCampaignState?.segment_id || [])];

                //   !hideSelect &&
                //     onEditForm({
                //       segment_id: idArrays?.includes(obj?.id)
                //         ? idArrays?.filter((str) => str != obj?.id)
                //         : [...(idArrays || []), obj?.id],
                //     });
                // }}
                // onClick={() => setsegmentSelected(obj)}
              >
                {/* <ListItemIcon> */}
                {/* {!hideSelect && ( */}
                <Checkbox
                  edge="start"
                  checked={onSelectedTemplated?.id === obj?.id}
                  // checked={Boolean(
                  //   createCampaignState?.segment_id?.includes(obj?.id)
                  // )}
                  disableRipple
                />
                {/* )} */}
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{ width: "100%" }}
                >
                  <Stack sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {obj?.name}
                    </Typography>
                    <Typography sx={{ ...secondaryLabelSmall }}>
                      Created on :{" "}
                      {formatDate(
                        new Date(validateNumber(obj?.created)),
                        "dd/mm/yyyy"
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setTemplateListPage &&
                          setTemplateListPage(templateListPage);
                        setSelectPreview && setSelectPreview(obj);
                        // handleClick(e, obj);
                      }}
                    >
                      <RemoveRedEyeOutlined />
                    </IconButton>
                  </Stack>
                </Stack>
              </ListItemButton>
            </ListItem>
          </>
        );
      })}
    </List>
  );
};

export const SelectSemgment = ({
  customerSegments,
  fetchingList,
  onEditForm,
  createCampaignState,
  hideSelect,
  tableView,
  // actions,
  showCreate,
  onEditSegment,
  onCreatePress,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteObject, setDeleteObject] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewCustomer, setViewCustomer] = useState(false);
  const handleClick = (event, obj) => {
    setAnchorEl(event.currentTarget);
    setDeleteObject(obj);
  };
  useEffect(() => {
    setRows(
      Object.values(customerSegments || {})?.sort(
        (a, b) =>
          validateNumber(b?.id?.split("_")?.[0]) -
          validateNumber(a?.id?.split("_")?.[0])
      )
    );
  }, [customerSegments]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value) {
      setRows(
        Object.values(customerSegments || {})?.filter((obj) =>
          obj?.filterForm?.segment_name
            ?.toLowerCase()
            ?.includes(e.target.value?.toLowerCase())
        )
      );
    } else {
      setRows(Object.values(customerSegments || {}));
    }
  };

  return (
    <>
      <TemplateDeletePopover
        anchorEl={anchorEl}
        deleteTemplateObj={deleteObject}
        handleClose={handleClose}
      />

      <ViewCustomerList
        params={viewCustomer}
        open={isObjWithValues(viewCustomer)}
        handleClose={() => setViewCustomer(false)}
      />
      {!isObjWithValues(customerSegments) ? (
        <>
          {fetchingList ? (
            new Array(5).fill("").map((str) => (
              <ListItem style={{}}>
                <Card
                  sx={{
                    padding: 3,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    pr: { xs: 0, sm: 3 },
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1.3rem", width: "100%" }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem", width: "80%" }}
                    />
                  </Box>
                </Card>
              </ListItem>
            ))
          ) : (
            <EmptyState
              icon={
                <PeopleAlt
                  style={{
                    height: "160px",
                    width: "160px",
                    // color: "text.secondary",
                    fill: "#b6b6b6",
                  }}
                />
              }
              text={"No Customer Segment yet"}
              // button={
              //   <Button onClick={() => setCreate(true)}>
              //     Create Segment
              //   </Button>
              // }
            />
          )}
        </>
      ) : (
        <>
          {tableView && (
            <>
              <Paper sx={{ borderRadius: "20px" }}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography variant="h5">
                    Segments {rows?.length ? `(${rows?.length})` : null}
                  </Typography>
                  <HorizontalStack spacing={2}>
                    {Boolean(showCreate) && (
                      <Button variant="contained" onClick={onCreatePress}>
                        Create
                      </Button>
                    )}
                    <TextField
                      onChange={onSearch}
                      value={searchTerm}
                      placeholder="Search segment"
                      sx={{
                        ".MuiInputBase-root": {
                          borderRadius: "12px",
                        },
                      }}
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </HorizontalStack>
                </Stack>
                {isArrayWithValues(rows) ? (
                  <>
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                      <Table>
                        <TableHead>
                          <StyledTableRow>
                            <TableCell>Sr.</TableCell>
                            {!hideSelect && <TableCell></TableCell>}
                            <TableCell>Name</TableCell>
                            <TableCell>Created On</TableCell>
                            {/* <TableCell>Customers</TableCell> */}
                            <TableCell>Campagin Sent</TableCell>
                            <TableCell align="right">Action</TableCell>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {rows
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .sort(
                              (a, b) =>
                                validateNumber(b?.id?.split("_")?.[0]) -
                                validateNumber(a?.id?.split("_")?.[0])
                            )
                            ?.map((obj, index) => {
                              return (
                                <SegmentListItem
                                  tableView={tableView}
                                  hideSelect={hideSelect}
                                  onEditForm={onEditForm}
                                  createCampaignState={createCampaignState}
                                  handleClick={handleClick}
                                  index={index}
                                  setViewCustomer={setViewCustomer}
                                  // hideDelete={hideDelete}
                                  obj={obj}
                                  // setsegmentSelected={setsegmentSelected}
                                  key={obj?.id}
                                  onEditSegment={onEditSegment}
                                  // setDeleteSegemt={setDeleteSegemt}
                                />
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={Object.values(customerSegments || {}).length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                ) : (
                  <EmptyState
                    icon={
                      <PeopleAlt
                        style={{
                          height: "160px",
                          width: "160px",
                          // color: "text.secondary",
                          fill: "#b6b6b6",
                        }}
                      />
                    }
                    text={"No Customer Segment yet"}
                    // button={
                    //   <Button onClick={() => setCreate(true)}>
                    //     Create Segment
                    //   </Button>
                    // }
                  />
                )}
              </Paper>
            </>
          )}
          {!tableView && (
            <List sx={{ maxHeight: "50vh", overflow: "auto" }}>
              {rows
                ?.sort(
                  (a, b) =>
                    validateNumber(b?.id?.split("_")?.[0]) -
                    validateNumber(a?.id?.split("_")?.[0])
                )
                ?.map((obj, index) => {
                  return (
                    <SegmentListItem
                      tableView={tableView}
                      hideSelect={hideSelect}
                      onEditForm={onEditForm}
                      createCampaignState={createCampaignState}
                      handleClick={handleClick}
                      setViewCustomer={setViewCustomer}
                      // hideDelete={hideDelete}
                      obj={obj}
                      index={index}
                      // setsegmentSelected={setsegmentSelected}
                      key={obj?.id}
                      onEditSegment={onEditSegment}
                      // setDeleteSegemt={setDeleteSegemt}
                    />
                  );
                })}
            </List>
          )}
        </>
      )}
    </>
  );
};

const TemplateDeletePopover = ({
  anchorEl,
  handleClose,
  deleteTemplateObj,
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      // onClose={handleClose}

      PaperProps={{
        elevation: 0,

        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          borderRadius: "16px",
          width: 300,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
            borderRadius: 8,
          },
          // "&:before": {
          //     content: '""',
          //     display: "block",
          //     position: "absolute",
          //     top: 0,
          //     right: 14,
          //     width: 10,
          //     height: 10,
          //     bgcolor: "background.paper",
          //     transform: "translateY(-50%) rotate(45deg)",
          //     zIndex: 0,
          // },
        },
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
    >
      <DeleteConfirm
        deleteTemplateObj={deleteTemplateObj}
        setDeleteId={handleClose}
      />
    </Popover>
  );
};

const DeleteConfirm = ({ deleteTemplateObj, setDeleteId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const deleteSegment = async (id) => {
    let website = getWebsite();
    let token = getToken();

    if (website && token) {
      // setDeleteId(id);
      setLoading(true);
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings/customer_segments`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "DELETE",
        data: {
          id: id,
        },
      });
      if (res?.data?.success) {
        setDeleteId(false);
        dispatch(
          setGlobalToast({
            show: true,
            severity: "success",
            message: deleteSegmentToast(deleteTemplateObj.segment_name),
          })
        );
        dispatch({
          type: EDIT_CHATS,
          payload: {
            customerSegments: res?.data?.data,
          },
        });
      }
    }
    setDeleteId(false);
    setLoading(false);
  };
  return (
    <>
      <DialogContent>
        <Typography variant="h6">
          Are you sure you want to delete Segment{" "}
          {deleteTemplateObj?.filterForm?.segment_name}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteId(false)}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          color="error"
          onClick={() => {
            deleteSegment(deleteTemplateObj?.id);
          }}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </>
  );
};
ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};
const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 36,
  height: 36,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(66, 93, 194) 0%, rgb(24, 50, 148) 50%, rgb(13, 26, 74) 100%)",
    backgroundColor: "#5cb85c",
    // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(66, 93, 194) 0%, rgb(24, 50, 148) 50%, rgb(13, 26, 74) 100%)",
    backgroundColor: "#5cb85c",
  }),
}));
function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {props.icon}
      {/* {icons[String(props.icon)]} */}
    </ColorlibStepIconRoot>
  );
}
