import React, { useEffect } from "react";

const Chatbot = () => {
  useEffect(() => {
    const token = "rOLFPhUhW0LL6rTokp63rQ==";
    // Create a new div element
    const chatbotContainer = document.createElement("div");
    // Assign the id 'chatbot-container' to the new div
    chatbotContainer.id = "chatbot-container";
    // Append the new div to the body element
    document.body.appendChild(chatbotContainer);
    // Now you can initialize your ChatWidget or any other scripts that target this div
    if (window.ChatWidget && window.ChatWidget.default) {
      window.ChatWidget.default.init("chatbot-container", {
        token,
        showDefaultIcon: false,
      });
    }
  }, []);

  return null;
};

export default Chatbot;
