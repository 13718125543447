import {
  Add,
  ArrowBack,
  ArticleOutlined,
  ArticleTwoTone,
  BusinessCenter,
  Campaign,
  CheckCircle,
  Close,
  Code,
  FormatBold,
  FormatItalic,
  ImageOutlined,
  Info,
  KeyOutlined,
  PlayCircleOutlined,
  Search,
  StrikethroughS,
} from "@mui/icons-material";
import axios from "axios";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  Radio,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import {
  backendApiCall,
  containsEmoji,
  dialCodes,
  dialCodesObj,
  dialogStyle,
  formatServerValue,
  getAxiosError,
  getToken,
  getValueFormIntegrations,
  getWebsite,
  includesEmoji,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  secondaryLabelSmall,
  settingsEndpoint,
  unformatServerValue,
} from "../../helper";
import "../../App.css";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_CHATS, getTempletes } from "../../redux/actions/chatActions";
import { LoadingButton } from "@mui/lab";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { commonDialCodes } from "../crm/Add";
import { StackRow } from "./helper";
import { FormLabel } from "../settings/Email";
import phone from "phone";
import ReactQuill from "react-quill";
import { backendEndpoints } from "../chat_new/apiFunctions";

const CheckButton = ({ isSelected, onClick, label, icon, tooltip }) => {
  return (
    <Card
      sx={{
        border: `0.5px solid`,
        borderColor: !isSelected ? "grey.500" : "priamry.main",
        margin: "1px 6px",
        overflow: "visible",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => onClick && onClick(label)}
    >
      <CardContent
        sx={{
          textAlign: "center",
          padding: "6px",
          "&:last-child": { paddingBottom: "6px" },
        }}
      >
        {isSelected ? (
          <CheckCircle
            width={9}
            height={9}
            style={{
              position: "absolute",
              right: "-4px",
              top: "-4px",
              width: "16px",
              height: "16px",
            }}
          />
        ) : null}
        <Stack direction="row" alignItems={"center"}>
          {icon}
          <Typography ml={1} variant="h6">
            {label}
          </Typography>
          {!!tooltip && <InfoTooltip title={tooltip} />}
        </Stack>
      </CardContent>
    </Card>
  );
};

const InfoTooltip = ({ title }) => {
  return (
    <Tooltip
      sx={{ fontSize: "18px" }}
      style={{ fontSize: "18px" }}
      title={title}
    >
      <IconButton size="small">
        <Info
          style={{
            height: "16px",
            width: "16px",
            // ".MuiTooltip-touch": {
            //   fontSize: "15px"
            // }
          }}
        />
      </IconButton>
    </Tooltip>
  );
};
const variables = [
  { label: "{First Name}", value: "first_name" },
  { label: "{Last Name}", value: "last_name" },
  { label: "{Fullname}", value: "fullname" },
  { label: "{City}", value: "city" },
  { label: "{Address}", value: "address" },
  { label: "{Title}", value: "title" },
  { label: "{Order id}", value: "order_id" },
  { label: "{Payment method}", value: "payment_method" },
  { label: "{due date}", value: "due_date" },
  { label: "{Expiry}", value: "expiry" },
  { label: "{amount}", value: "amount" },
  { label: "{pieces}", value: "pieces" },
  { label: "{Brand name}", value: "brand_name" },
  { label: "{Brand number}", value: "brand_number" },
  { label: "{Order note}", value: "order_note" },
  { label: "{Brand url}", value: "brand_url" },
  { label: "{Reference no}", value: "reference_no" },
  { label: "{Link}", value: "link" },
  // { label: "Address", value: "address" },
  //   { label: "Whatsapp", value: "whatsapp" },
  //   { label: "Mobile", value: "mobile" },
  //   { label: "Profession", value: "profession" },
  //   { label: "Language", value: "language" },
  //   { label: "Ethinicity", value: "ethinicity" },
  //   { label: "Religion", value: "religion" },
];

const languages = [
  { label: "Afrikaans", value: "af" },
  { label: "Albanian", value: "sq" },
  { label: "Arabic", value: "ar" },
  { label: "Azerbaijani", value: "az" },
  { label: "Bengali", value: "bn" },
  { label: "Bulgarian", value: "bg" },
  { label: "Catalan", value: "ca" },
  { label: "Chinese (CHN)", value: "zh_CN" },
  { label: "Chinese (HKG)", value: "zh_HK" },
  { label: "Chinese (TAI)", value: "zh_TW" },
  { label: "Croatian", value: "hr" },
  { label: "Czech", value: "cs" },
  { label: "Danish", value: "da" },
  { label: "Dutch", value: "nl" },
  { label: "English", value: "en" },
  { label: "English (UK)", value: "en_GB" },
  { label: "English (US)", value: "en_US" },
  { label: "Estonian", value: "et" },
  { label: "Filipino", value: "fil" },
  { label: "Finnish", value: "fi" },
  { label: "French", value: "fr" },
  { label: "Georgian", value: "ka" },
  { label: "German", value: "de" },
  { label: "Greek", value: "el" },
  { label: "Gujarati", value: "gu" },
  { label: "Hausa", value: "ha" },
  { label: "Hebrew", value: "he" },
  { label: "Hindi", value: "hi" },
  { label: "Hungarian", value: "hu" },
  { label: "Indonesian", value: "id" },
  { label: "Irish", value: "ga" },
  { label: "Italian", value: "it" },
  { label: "Japanese", value: "ja" },
  { label: "Kannada", value: "kn" },
  { label: "Kazakh", value: "kk" },
  { label: "Kinyarwanda", value: "rw_RW" },
  { label: "Korean", value: "ko" },
  { label: "Kyrgyz (Kyrgyzstan)", value: "ky_KG" },
  { label: "Lao", value: "lo" },
  { label: "Latvian", value: "lv" },
  { label: "Lithuanian", value: "lt" },
  { label: "Macedonian", value: "mk" },
  { label: "Malay", value: "ms" },
  { label: "Malayalam", value: "ml" },
  { label: "Marathi", value: "mr" },
  { label: "Norwegian", value: "nb" },
  { label: "Persian", value: "fa" },
  { label: "Polish", value: "pl" },
  { label: "Portuguese (BR)", value: "pt_BR" },
  { label: "Portuguese (POR)", value: "pt_PT" },
  { label: "Punjabi", value: "pa" },
  { label: "Romanian", value: "ro" },
  { label: "Russian", value: "ru" },
  { label: "Serbian", value: "sr" },
  { label: "Slovak", value: "sk" },
  { label: "Slovenian", value: "sl" },
  { label: "Spanish", value: "es" },
  { label: "Spanish (ARG)", value: "es_AR" },
  { label: "Spanish (SPA)", value: "es_ES" },
  { label: "Spanish (MEX)", value: "es_MX" },
  { label: "Swahili", value: "sw" },
  { label: "Swedish", value: "sv" },
  { label: "Tamil", value: "ta" },
  { label: "Telugu", value: "te" },
  { label: "Thai", value: "th" },
  { label: "Turkish", value: "tr" },
  { label: "Ukrainian ", value: "uk" },
  { label: "Urdu", value: "ur" },
  { label: "Uzbek", value: "uz" },
  { label: "Vietnamese", value: "vi" },
  { label: "Zulu", value: "zu" },
];

const getExampleValue = (value) => {
  let valueObj = variables.find((o) => o.label === value);
  return valueObj?.value ? valueObj?.value : value;
};
const setExampleValue = (value) => {
  let valueObj = variables.find((o) => o.value === value);
  return valueObj?.label ? valueObj?.value : value;
};
const CreateTemplete = ({ open, close }) => {
  let steps = ["Select", "Create", "Done"];
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const inputRef = useRef();
  const [currentStep, setCurrentStep] = useState(0);
  // const [editTemplate, setEditTemplate] = useState(false);
  const [uploadingDoc, setUploadingDoc] = useState(false);
  const defaultTempltete = {
    name: "",
    category: "MARKETING",
    language: { label: "English (UK)", value: "en_GB" },
    components: [],
  };
  let obj = {
    type: "PHONE_NUMBER",
    phone_text: "",
    url_text: "",
    phone_number: "",
    country: {
      label: "🇮🇳 India (+91)",
      value: "91",
    },
    linkType: "static",
    url: "",
  };
  const [header, setHeader] = useState("none");
  const [headerText, setHeaderText] = useState("");
  const [button, setbutton] = useState("none");
  const [firstPageError, setFirstPageError] = useState({
    nameError: false,
    languagesError: false,
    category: false,
  });
  const [creatingTemplete, setCreatingTemplete] = useState(false);
  const [drafting, setDrafting] = useState(false);
  const [formValue, setFormValue] = useState(defaultTempltete);
  const [textButton, setTextButton] = useState([""]);
  const [actionArray, setActionArray] = useState([obj]);
  const [body, setBody] = useState("");
  const [footer, setFooter] = useState("");
  const [headerVariable, setHeaderVariable] = useState([]);
  const [bodyVariable, setBodyVariable] = useState([]);
  const [urlVariable, setUrlVariable] = useState("");
  const [whatsappTempletes, setWhatsappTempletes] = useState([]);
  const [nameHasError, setNameHasError] = useState("");
  const [hasError, setHasError] = useState({});
  const [headerMedia, setHeaderMedia] = useState("");
  const [actonsButtonText, setActionButtonText] = useState([]);
  const [uploadingSample, setUploadingSample] = useState(false);
  const [uploadFileURL, setUploadFileURL] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const [bodySelectionStart, setBodySelectionStart] = useState(-1);
  const [bodySelectionEnd, setBodySelectionEnd] = useState(-1);
  const [selectedWaba, setSelectedWaba] = useState("");
  const bodyRef = useRef(null);
  const reduxWhatsappTemplete = useSelector(
    (state) => state.chats.whatsappTempletes
  );
  const metaIntegration = useSelector(
    (state) => state.settings.metaIntegration
  );
  const globalToast = useSelector((state) => state.helper.globalToast);
  const nameRef = useRef(null);
  const languageRef = useRef(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isArrayWithValues(reduxWhatsappTemplete)) {
      setWhatsappTempletes(reduxWhatsappTemplete);
    }
  }, [reduxWhatsappTemplete]);

  useEffect(() => {
    // if (!isObjWithValues(metaIntegration)) return;
    // let whatsappObj = Object.values(metaIntegration)?.find((i) =>
    //   i?.connected?.find((i) => i === "whatsapp")
    // );
    // if (whatsappObj) {
    //   let waba = whatsappObj?.whatsapp_info?.find(
    //     (i) => i?.id === whatsappObj?.selected_whatsapp_phone_id
    //   );
    //   setWha;
    // }
  }, [metaIntegration]);

  useEffect(() => {
    if (!isArrayWithValues(reduxWhatsappTemplete)) {
      console.log("calling in create");
      dispatch(getTempletes());
    }
  }, []);
  const onClose = () => {
    setBody("");
    setHeaderVariable([]);
    setBodyVariable([]);
    setHeaderText("");
    setFooter("");
    setUploadFileURL("");
    setImgSrc("");
    setFormValue(defaultTempltete);
    close();
    setActionArray([obj]);
    setHeader("none");
    setbutton("none");
    setCurrentStep(0);
  };

  const wrapText = (variable) => {
    const input = bodyRef.current;

    if (bodySelectionStart >= 0 && bodySelectionEnd >= 0) {
      const value =
        body.substring(0, bodySelectionStart) +
        variable +
        body.substring(bodySelectionStart, bodySelectionEnd) +
        variable +
        body.substring(bodySelectionEnd);
      // setBody(value);
      input.setSelectionRange(bodySelectionEnd + 4, bodySelectionEnd + 4);
      setBodySelectionStart(-1);
      setBodySelectionEnd(-1);
    }
    // if (input) {
    //   const selectionStart = input.selectionStart;
    //   const selectionEnd = input.selectionEnd;
    //   const wrappedText = `${variable}${body.slice(
    //     selectionStart,
    //     selectionEnd
    //   )}${variable}`;
    //   const newInputValue = `${body.slice(
    //     0,
    //     selectionStart
    //   )}${wrappedText}${body.slice(selectionEnd)}`;
    //   setBody(newInputValue);
    //   input.setSelectionRange(
    //     selectionStart + variable.length,
    //     selectionEnd + variable.length
    //   );
    //   input.focus();
    // }
  };

  useEffect(() => {
    if (isObjWithValues(open)) {
      setFormValue({ ...open });
      setCurrentStep(1);
    }
    if (isArrayWithValues(open?.components)) {
      console.log(open);
      let body = open?.components.find((obj) => obj.type === "BODY");
      let header = open?.components.find((obj) => obj.type === "HEADER");
      let footer = open?.components.find((obj) => obj.type === "FOOTER");
      let button = open?.components.find((obj) => obj.type === "BUTTONS");
      if (header) {
        console.log(header?.example?.header_text?.[0]);
        setHeaderVariable(
          header?.example?.header_text?.[0]
            ? [header?.example?.header_text?.[0]].map((str) =>
                setExampleValue(str)
              )
            : []
        );
      }
      // if (header?.format) setHeader(header?.format);
      else setHeader("none");
      if (header?.format === "TEXT") {
        setHeaderText(header.text);
        setHeader("TEXT");
      } else {
        if (header?.format) {
          setHeader("MEDIA");
          setHeaderMedia(header?.format || "");
        }
        let arr = header?.example?.header_handle;
        if (isArrayWithValues(arr)) setUploadFileURL(arr[0]);
      }
      if (footer) setFooter(footer.text);
      if (body) {
        setBody(body?.text);
        let len = body?.text?.match(/{{/gi)?.length;
        console.log(body?.example?.body_text?.[0]);
        setBodyVariable(
          isArrayWithValues(body?.example?.body_text?.[0])
            ? body?.example?.body_text?.[0].map((str) => setExampleValue(str))
            : []
        );
        // console.log(len, new Array(len).fill(""));
        // setBodyVariable(len ? new Array(len).fill("") : []);
      }
      if (button) {
        if (isArrayWithValues(button?.buttons)) {
          if (button?.buttons.find((obj) => obj.type === "QUICK_REPLY")) {
            setbutton("reply");
            setTextButton(button?.buttons.map((obj) => obj.text));
          } else {
            setbutton("action");
            let obj = {
              type: "PHONE_NUMBER",
              phone_text: "",
              url_text: "",
              phone_number: "",
              country: {
                label: "🇮🇳 India (+91)",
                value: "91",
              },
              linkType: "static",
              url: "",

              // phone_text: numberObj?.text,
            };
            console.log(button, "<<<<buttons >>>>");
            setActionArray(
              button?.buttons.map((buttonData) => {
                let phone_text = buttonData.text;
                let url_text = buttonData?.text;
                let linkType = buttonData.url?.includes("{{")
                  ? "dynamic"
                  : "static";
                if (buttonData?.example?.[0]) {
                  setUrlVariable(buttonData?.example?.[0]);
                }
                if (buttonData?.phone_number) {
                  let { phoneNumber, countryCode } =
                    phone(buttonData?.phone_number) || {};
                  let phone_number = buttonData?.phone_number;
                  console.log(buttonData?.phone_number);
                  // const [phoneNumberWithoutCountryCode, guessedCountry] = phone(phoneNumberString);
                  buttonData.phone_number =
                    phoneNumber?.replace(countryCode, "") ||
                    phone_number?.replace("+", "");
                  buttonData.country = [
                    ...(commonDialCodes || []),
                    ...(dialCodes || []),
                  ]?.find((o) => o?.value == countryCode?.replace("+", "")) || {
                    label: "🇮🇳 India (+91)",
                    value: "91",
                  };
                }
                console.log({
                  ...obj,
                  ...buttonData,
                  phone_text,
                  url_text,
                  linkType,
                });
                return {
                  ...obj,
                  ...buttonData,
                  phone_text,
                  url_text,
                  linkType,
                };
              })
            );
          }
        }
      }
    }
  }, [open]);
  const replaceVariable = (currentText, replace, whole_text) => {
    let firstIndex = whole_text.indexOf(replace);
    let firstString = whole_text.substring(0, firstIndex);
    whole_text = whole_text.replace(replace, "");
    let lastString = whole_text.substring(firstIndex, whole_text.length);
    return `${firstString} ${currentText} ${lastString}`;
  };
  const onAddAction = () => {
    let o = obj;
    if (actionArray.find((obj) => obj.type === "PHONE_NUMBER")) {
      o.type = "URL";
      setActionArray((pre) => [...pre, o]);
    } else {
      setActionArray((pre) => [...pre, obj]);
    }
  };
  const onBodyChange = (e) => {
    // setBody(e?.target?.value);
    let len = e?.target?.value?.match(/{{/gi)?.length;
    // console.log(formatTextWithCustomMarkers(e));
    // let len = body?.match(/{{/gi)?.length;
    setBodyVariable((pre) =>
      pre.length === len ? pre : len ? new Array(len).fill("") : []
    );
    const input = bodyRef.current;
    // replaceVariable

    setBody(e.target.value);
    const selectionStart = input.selectionStart;
    const selectionEnd = input.selectionEnd;
    if (selectionStart >= 0 && selectionEnd >= 0) {
      if (selectionStart < selectionEnd) {
        setBodySelectionStart(selectionStart);
        setBodySelectionEnd(selectionEnd);
      } else {
        setBodySelectionStart(-1);
        setBodySelectionEnd(-1);
      }
    }
  };

  // console.log(metaIntegration, "<<<<<metaIntegration");

  const onNextPage = () => {
    if (!!!formValue.name) {
      nameRef.current?.focus();
      setFirstPageError((pre) => ({ ...pre, nameError: true }));
      return;
    }
    if (!!!formValue.language) {
      languageRef.current?.focus();
      setFirstPageError((pre) => ({ ...pre, languagesError: true }));
      return;
    }
    if (!!!formValue.category) {
      setFirstPageError((pre) => ({ ...pre, categoryError: true }));
      return;
    }
    // if (!!formValue.category && !!formValue.name )
    setCurrentStep(1);
  };
  const createTemplete = async (draft) => {
    let website = getWebsite();
    let token = getToken();
    let components = [];
    if (!!!body) {
      console.log("body is empty required");
      dispatch(
        setGlobalToast({
          show: true,
          message: "body is empty required",
          severity: "error",
        })
      );

      return;
    }
    let bodyObj = {
      type: "BODY",
      text: body,
    };
    if (isArrayWithValues(bodyVariable)) {
      if (bodyVariable.includes("")) {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Exmaple variable needed",
            severity: "error",
          })
        );
        return;
      }
      bodyObj.example = {
        body_text: [bodyVariable.map((str) => getExampleValue(str))],
      };
    }
    components.push(bodyObj);
    if (header !== "none" && header !== "TEXT") {
      if (!uploadFileURL) {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Exmaple image needed",
            severity: "error",
          })
        );
        return;
      }
      let obj = {
        type: "HEADER",
        format: headerMedia,
        example: {
          header_handle: [uploadFileURL],
        },
      };

      // return

      components.push(obj);
    }
    if (header === "TEXT") {
      let textObj = {};
      textObj.format = "TEXT";
      textObj.type = "HEADER";
      textObj.text = headerText;
      if (isArrayWithValues(headerVariable))
        textObj.example = {
          header_text: [...headerVariable].map((str) => getExampleValue(str)),
        };
      if (headerText.includes("{{") && headerVariable.includes("")) {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Exmaple variable needed",
            severity: "error",
          })
        );
        return;
      }

      components.push(textObj);
    }

    console.log(components);
    // return
    if (!!footer) {
      let obj = {
        type: "FOOTER",
        text: footer,
      };
      components.push(obj);
    }

    if (button === "reply") {
      let obj = {
        type: "BUTTONS",
        buttons: textButton.map((val) => ({ type: "QUICK_REPLY", text: val })),
      };
      if (textButton.includes("")) {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Button value needed",
            severity: "error",
          })
        );
        return;
      }
      components.push(obj);
    }
    if (button === "action") {
      let buttonPayload = { buttons: [], type: "BUTTONS" };
      // buttonPayload.buttons = [];

      let phoneObj = actionArray.find((o) => o.type === "PHONE_NUMBER");
      let urlObj = actionArray.find((o) => o.type === "URL");
      console.log(phoneObj, urlObj);
      if (phoneObj) {
        // buttonPayload.type = "BUTTONS";
        buttonPayload.buttons = [];
        let pay = {
          type: "PHONE_NUMBER",
          text: phoneObj.phone_text,
          phone_number: `${phoneObj?.country?.value}${phoneObj?.phone_number}`,
        };
        buttonPayload.buttons.push(pay);
        // components.push(buttonPayload);
      }
      let linkPayload = {};
      if (urlObj) {
        let urlPayload = {};
        // linkPayload.type = "BUTTONS";
        urlPayload.type = "URL";
        urlPayload.text = urlObj.url_text;
        urlPayload.url = urlObj.url;
        if (urlObj.linkType === "dynamic") {
          urlPayload.url = urlObj.url + "{{1}}";
          urlPayload.example = [`${urlObj.url}${urlVariable}`];
        }

        buttonPayload.buttons.push(urlPayload);
      }
      components.push(buttonPayload);
    }
    console.log({
      payload: {
        name: formValue.name,
        category: formValue.category,
        languages: formValue.language.value,
        components,
      },
    });

    let data = {
      payload: {
        name: formValue.name,
        category: formValue.category,
        language: formValue.language.value,
        components,
      },
    };

    if (isObjWithValues(open)) {
      console.log(open);
      data = {
        ...data,
        channel: "whatsapp",
      };
    }
    if (open?.id) {
      data = { template_id: open?.id };
    }
    if (open.language) {
      data = {
        ...data,
        payload: {
          name: formValue.name,
          category: formValue.category,
          language: formValue.language.value,
          components,
          language: open.language,
        },
      };
    }
    console.log("payload", data);
    // if (isDev()) return;
    if (draft) {
      setDrafting(true);
      let putRes = await settingsEndpoint({
        endpoint: "draft_whatsapp_templates",
        method: "PUT",
        data: {
          [formValue.name]: data?.payload,
          //  {
          //   ...(data?.payload || {})
          // }
        },
      });

      if (putRes) {
        dispatch({
          type: EDIT_CHATS,
          payload: {
            draftWhatsappTemplates: putRes,
          },
        });
      }
      setDrafting(false);
    } else {
      setCreatingTemplete(true);
      try {
        // let res;
        // let res = await backendApiCall({
        //   endpoint: message_templates,
        // });
        // const res = await axios({
        //   url: `${website}/wp-json/store/v1/social/whatsapp/templates`,
        //   method: "POST",
        //   headers: {
        //     Authorization: `Basic ${token}`,
        //   },
        //   data: {
        //     ...data,
        //   },
        // });
        // console.log(res);

        let res = await createWhatsappTemplate({
          data: data?.payload,
          integrations: metaIntegration,
        });
        // console.log(tempRes, "<metaIntegration");
        // if (res?.data?.success) {
        //   dispatch(
        //     setGlobalToast({
        //       ...globalToast,
        //       show: true,
        //       message: "Whatsapp templeted submit succesfully",
        //       severity: "success",
        //     })
        //   );
        // }
        if (res?.id) {
          dispatch(getTempletes());
          dispatch(
            setGlobalToast({
              ...globalToast,
              show: true,
              message: "Whatsapp template submit successfully",
              severity: "success",
            })
          );
          // close();
          setBody("");
          setHeaderText("");
          setFooter("");
          setUploadFileURL("");
          setImgSrc("");
          setHeaderVariable([]);
          setActionArray([]);
          onClose();
          setFormValue(defaultTempltete);
        }
        let errorMessage =
          res?.error?.error?.error_user_msg ||
          res?.error?.error?.error_user_title ||
          res?.error?.error?.message;
        if (errorMessage) {
          dispatch(
            setGlobalToast({
              ...globalToast,
              show: true,
              message: `${errorMessage}`,
              severity: "error",
            })
          );
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
      setCreatingTemplete(false);
    }
  };

  const onNameChange = (e) => {
    setFormValue((pre) => ({
      ...pre,
      name: unformatServerValue(e.target.value),
    }));
    if (
      whatsappTempletes.find(
        (o) => o.name === unformatServerValue(e.target.value)
      )
    ) {
      setFirstPageError((pre) => ({ ...pre, nameError: true }));
      setNameHasError("Name already in used");
    } else {
      setFirstPageError((pre) => ({ ...pre, nameError: false }));
      setNameHasError("");
    }
  };

  useEffect(() => {
    let urlobj = actionArray.find((obj) => obj?.url_text !== "");
    let phoneobj = actionArray.find((obj) => obj?.phone_text !== "");
    // if(urlobj) {

    setActionButtonText([
      urlobj?.url_text ? urlobj?.url_text : "",
      phoneobj?.phone_text ? phoneobj?.phone_text : "",
    ]);

    if (actionArray.length === 1 && actionArray[0]?.type === "URL") {
      setActionButtonText([urlobj?.url_text ? urlobj?.url_text : ""]);
    }
    if (actionArray.length === 1 && actionArray[0]?.type === "PHONE_NUMBER") {
      setActionButtonText([phoneobj?.phone_text ? phoneobj?.phone_text : ""]);
    }
    // }
    // if(urlobj) {
    //   setActionButtonText([urlobj?.url_text])
    // }
  }, [actionArray]);

  const onUploadDocs = async (obj) => {
    let website = getWebsite();
    let token = getToken();
    console.log(obj);
    setImgSrc(URL.createObjectURL(obj));
    const form_data = new FormData();
    form_data.append("file", obj);
    setUploadingSample(true);
    try {
      const res = await axios({
        url: `${website}/wp-json/store/v1/social/whatsapp/templates`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: form_data,
        method: "POST",
      });
      if (res.data.success) {
        setUploadFileURL(res.data.data);
      }
      console.log(res.data);
    } catch (error) {
      console.log(getAxiosError(error));
    }

    setUploadingSample(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={
        isIpad
          ? {}
          : { ...dialogStyle, padding: "15px", transition: "all 0.1s" }
      }
      fullWidth
      fullScreen={isIpad}
      maxWidth={currentStep === 0 ? "md" : "lg"}
      className="scrollbar-hidden"
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography
            variant="h5"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            {currentStep === 1 && (
              <>
                {!isArrayWithValues(open?.components) && (
                  <IconButton
                    onClick={() => {
                      setCurrentStep(0);
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                )}
              </>
            )}
            {isArrayWithValues(open?.components) ? "Update" : "Create"} Template{" "}
            {currentStep === 1 && (
              <Typography variant="h5" sx={{ ml: 2, color: "primary.main" }}>
                - {formatServerValue(formValue.name)}
              </Typography>
            )}
          </Typography>
          {/* {currentStep === 1 && (
            <>
              {!isArrayWithValues(open?.components) && (
                <Button
                  onClick={() => {
                    setCurrentStep(0);
                  }}
                  size="small"
                >
                  Back
                </Button>
              )}
            </>
          )} */}
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stepper activeStep={currentStep} alternativeLabel sx={{ mb: 2 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {currentStep === 0 && (
          <>
            <Card sx={{ padding: "10px 20px" }}>
              <Typography
                variant="h6"
                sx={firstPageError.categoryError && { color: "#FF0000" }}
              >
                Category <InfoTooltip title="Choose a message template." />
              </Typography>
              {/* <Typography >Choose a message template.</Typography> */}
              <Stack direction={"row"}>
                <CheckButton
                  label={"Utility"}
                  //   tooltip={
                  //     "Send account updates, order updates, alerts and more to share important information."
                  //   }
                  icon={<BusinessCenter />}
                  isSelected={formValue.category === "UTILITY"}
                  onClick={() => {
                    setFormValue((pre) => ({
                      ...pre,
                      category: "UTILITY",
                    }));
                    setFirstPageError((pre) => ({
                      ...pre,
                      categoryError: false,
                    }));
                  }}
                />
                {/* <CheckButton
                  label={"Transactional"}
                  //   tooltip={
                  //     "Send account updates, order updates, alerts and more to share important information."
                  //   }
                  icon={<BusinessCenter />}
                  isSelected={formValue.category === "TRANSACTIONAL"}
                  onClick={() => {
                    setFormValue((pre) => ({
                      ...pre,
                      category: "TRANSACTIONAL",
                    }));
                    setFirstPageError((pre) => ({
                      ...pre,
                      categoryError: false,
                    }));
                  }}
                /> */}

                <CheckButton
                  label="Marketing"
                  icon={<Campaign />}
                  //   tooltip="Send promotional offers, product announcements and more to increase awareness and engagement."
                  isSelected={formValue.category === "MARKETING"}
                  onClick={() => {
                    setFormValue((pre) => ({ ...pre, category: "MARKETING" }));
                    setFirstPageError((pre) => ({ ...pre, category: false }));
                  }}
                />
                {/* <CheckButton label="One-time passwords" icon={<KeyOutlined />} isSelected={formValue.category === "OTP"} onClick={() => { setFormValue(pre => ({ ...pre, category: "OTP" })) }} /> */}
              </Stack>
            </Card>

            <Card sx={{ padding: "10px 20px", paddingRight: "30px", mt: 3 }}>
              <Typography variant="h6">
                Name
                <InfoTooltip title="Name your message template." />
              </Typography>
              <TextField
                ref={nameRef}
                error={firstPageError.nameError}
                placeholder="Enter message template name..."
                value={formatServerValue(formValue.name)}
                onChange={(e) => {
                  onNameChange(e);
                }}
                size="small"
                fullWidth
                sx={{ mt: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formValue.name.length}/{"512"}
                    </InputAdornment>
                  ),
                }}
                helperText={nameHasError}
              />
            </Card>
            <Card sx={{ padding: "10px 20px", paddingRight: "30px", mt: 3 }}>
              <Typography variant="h6">
                Languages
                <InfoTooltip title="Choose languages for your message template. You can delete or add more languages later." />
              </Typography>
              <Autocomplete
                ref={languageRef}
                // disablePortal
                id="combo-box-demo"
                options={languages}
                value={formValue.language}
                onChange={(e, newValue) => {
                  setFormValue((pre) => ({ ...pre, language: newValue }));
                  setFirstPageError((pre) => ({
                    ...pre,
                    languagesError: false,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={firstPageError.languagesError}
                    fullWidth
                    sx={{ mt: 2 }}
                    placeholder="Language"
                    startAdornment={
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    }
                  />
                )}
              />
            </Card>
          </>
        )}
        {currentStep === 1 && (
          <Grid container spacing={4} columnSpacing={5}>
            <Grid item xs={12} lg={8}>
              <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center", gap: 1.5 }}
                >
                  Header{" "}
                  <InfoTooltip title="Add a title or choose which type of media you'll use for this header." />
                  {uploadFileURL && header === "MEDIA" ? (
                    <Typography sx={{ ...secondaryLabelSmall }}>
                      Exmaple is Added
                    </Typography>
                  ) : null}
                </Typography>
              </Stack>
              {/* <Typography>Add a title or choose which type of media you'll use for this header.</Typography> */}
              <Stack
                direction={"row"}
                sx={{ gap: "15px" }}
                alignItems="flex-start"
              >
                <Select
                  size="small"
                  value={header || "none"}
                  onChange={(e) => setHeader(e.target.value)}
                  sx={{ height: "36px" }}
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="TEXT">Text</MenuItem>
                  <MenuItem value="MEDIA">Media</MenuItem>
                </Select>
                {header === "TEXT" && (
                  <TextField
                    placeholder="Enter text in English"
                    value={headerText}
                    onChange={(e) => {
                      setHeaderText(e.target.value);
                      if (containsEmoji(e.target.value)) {
                        setHasError((pre) => ({ ...pre, header: true }));
                      } else {
                        setHasError((pre) => ({ ...pre, header: false }));
                      }
                    }}
                    helperText={
                      hasError?.header && "Emoji not accepted in Header"
                    }
                    error={hasError?.header}
                    size="small"
                    fullWidth
                  />
                )}
                {header === "MEDIA" && (
                  <Stack
                    direction={"row"}
                    sx={{ height: "36px" }}
                    alignItems="center"
                  >
                    <CheckButton
                      label="Image"
                      icon={<ImageOutlined fontSize="small" />}
                      isSelected={headerMedia === "IMAGE"}
                      onClick={() => {
                        setHeaderMedia("IMAGE");
                        setUploadFileURL("");
                      }}
                    />
                    <CheckButton
                      label="Video"
                      icon={<PlayCircleOutlined fontSize="small" />}
                      isSelected={headerMedia === "VIDEO"}
                      onClick={() => {
                        setHeaderMedia("VIDEO");
                        setUploadFileURL("");
                      }}
                    />
                    <CheckButton
                      label="Document"
                      icon={<ArticleOutlined fontSize="small" />}
                      isSelected={headerMedia === "DOCUMENT"}
                      onClick={() => {
                        setHeaderMedia("DOCUMENT");
                        setUploadFileURL("");
                      }}
                    />

                    <LoadingButton
                      loading={uploadingSample}
                      sx={{ height: "32px" }}
                      onClick={() => inputRef.current.click()}
                      variant="contained"
                    >
                      Upload Sample Example
                    </LoadingButton>
                  </Stack>
                )}
              </Stack>
              {header === "TEXT" && (
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  sx={{ mt: 1 }}
                >
                  <Box>
                    {isArrayWithValues(headerVariable) && (
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        size="small"
                        sx={{ minWidth: "180px" }}
                        inputValue={headerVariable[0] || ""}
                        onInputChange={(e, value) => {
                          // console.log(value);
                          // return
                          setHeaderVariable([`${value}`]);
                        }}
                        options={variables.map((option) => option.label)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="freeSolo"
                            placeholder="Enter content for {{1}}"
                            // error={bodyError}
                          />
                        )}
                      />
                      // <TextField
                      //   size="small"
                      //   value={headerVariable[0] || ""}
                      //   onChange={(e) => {
                      //     setHeaderVariable([`${e.target.value}`]);
                      //   }}
                      //   placeholder="Enter content for {{1}}"
                      // />
                    )}
                  </Box>
                  <Button
                    startIcon={<Add />}
                    disabled={headerText.includes("{{")}
                    onClick={() => {
                      setHeaderVariable([""]);
                      setHeaderText(`${headerText}{{1}}`);
                    }}
                  >
                    Add variable
                  </Button>
                </Stack>
              )}
              <input
                type={"file"}
                style={{ display: "none" }}
                ref={inputRef}
                onChange={(e) => onUploadDocs(e.target.files[0])}
              />

              <Typography variant="h6" sx={{ mt: 4, mb: 1 }}>
                Body
                <InfoTooltip title="Enter the text for your message in the language that you've selected." />
              </Typography>
              <TextField
                ref={bodyRef}
                size="small"
                fullWidth
                multiline
                rows={4}
                value={body}
                onChange={onBodyChange}
                placeholder="Type something.."
              />
              {/* <ReactQuill
                theme="snow"
                //  value={value} onChange={setValue}
                // ref={bodyRef}
                // size="small"
                // fullWidth
                // multiline
                // rows={4}

                value={body}
                onChange={onBodyChange}
                // value={body}
                // onChange={setBody}
                modules={{ toolbar: [["bold", "italic", "strike", "code"]] }}
                formats={["bold", "italic", "strike", "code"]}
                placeholder="Type something.."
              /> */}

              <Stack
                direction={isIpad ? "column-reverse" : "row"}
                justifyContent="space-between"
                alignItems={"flex-start"}
              >
                <Grid
                  container
                  spacing={2}
                  rowSpacing={2}
                  sx={{ mt: 1, width: isIpad ? "100%" : "55%" }}
                >
                  {isArrayWithValues(bodyVariable) && (
                    <>
                      {bodyVariable.map((v, index) => {
                        return (
                          <Grid item xs={5}>
                            <Autocomplete
                              id="free-solo-demo"
                              freeSolo
                              size="small"
                              fullWidth
                              inputValue={v || ""}
                              onInputChange={(e, value) => {
                                console.log(value);
                                setBodyVariable(
                                  bodyVariable.map((str, i) =>
                                    i === index ? value : str
                                  )
                                );
                              }}
                              options={variables}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // label="freeSolo"
                                  placeholder={`Enter content for {{${
                                    index + 1
                                  }}}`}
                                  // error={bodyError}
                                />
                              )}
                            />
                            {/* <TextField
                              fullWidth
                              size="small"
                              value={v}
                              placeholder={`Enter content for {{${index + 1}}}`}
                              onChange={(e) => {
                                setBodyVariable(
                                  bodyVariable.map((str, i) =>
                                    i === index ? e.target.value : str
                                  )
                                );
                              }}
                            /> */}
                            <FormHelperText>
                              Example content for variable {index + 1}
                            </FormHelperText>
                          </Grid>
                        );
                      })}
                    </>
                  )}
                </Grid>
                <Stack direction={"row"} justifySelf="end">
                  {true && (
                    <>
                      <Tooltip title="Bold">
                        <IconButton size="samll">
                          <FormatBold
                            fontSize="small"
                            onClick={() => {
                              // setBody(`${body}**`);
                              wrapText("*");
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Italic">
                        <IconButton size="samll">
                          <FormatItalic
                            fontSize="small"
                            onClick={() => {
                              setBody(`${body}__`);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Strike-through">
                        <IconButton size="samll">
                          <StrikethroughS
                            fontSize="small"
                            onClick={() => {
                              setBody(`${body}~~`);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Monospace">
                        <IconButton size="samll">
                          <Code
                            fontSize="small"
                            onClick={() => {
                              setBody(`${body}${"``````"}`);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  <Button
                    sx={{ whiteSpace: "nowrap" }}
                    startIcon={<Add />}
                    onClick={() => {
                      setBodyVariable((pre) => [...pre, ""]);
                      setBody(`${body} {{${bodyVariable.length + 1}}}`);
                      // setBody((pre) => {
                      //   return pre + `{{${bodyVariable.length + 1}}`;
                      // });
                    }}
                  >
                    Add variable
                  </Button>
                </Stack>
              </Stack>
              <Typography variant="h6" sx={{ mt: 4, mb: 1 }}>
                Footer{" "}
                <InfoTooltip title="Add a short line of text to the bottom of your message template." />
              </Typography>
              {/* <Typography >Add a short line of text to the bottom of your message template.</Typography> */}
              <TextField
                placeholder="Enter text in English"
                size="small"
                fullWidth
                value={footer}
                onChange={(e) => {
                  setFooter(e.target.value);
                  if (containsEmoji(e.target.value)) {
                    setHasError((pre) => ({ ...pre, footer: true }));
                  } else {
                    setHasError((pre) => ({ ...pre, footer: false }));
                  }
                }}
                helperText={hasError?.footer && "Emoji not accepted in Footer"}
                error={hasError?.footer}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">0/{"60"}</InputAdornment>
                  ),
                }}
              />
              <Typography variant="h6" sx={{ mt: 4, mb: 1 }}>
                Buttons{" "}
                <InfoTooltip
                  title={"Max 2 CTA and max 3 Quick Replies can be selected"}
                />
              </Typography>
              {/* <Typography >Add a short line of text to the bottom of your message template.</Typography> */}
              {/* <TextField placeholder='Enter text in English' size='small' fullWidth sx={{ mt: 2 }} select /> */}
              <Select
                size="small"
                value={button}
                onChange={(e) => setbutton(e.target.value)}
              >
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="action">Call to action</MenuItem>
                <MenuItem value="reply">Quick reply</MenuItem>
              </Select>
              {button === "action" && (
                <>
                  {actionArray.map((val, index) => {
                    return (
                      <>
                        <Card
                          sx={
                            isIpad
                              ? {
                                  padding: "5px",
                                  position: "relative",
                                  marginBottom: "20px",
                                }
                              : { padding: "5px", position: "relative" }
                          }
                        >
                          {actionArray.length > 1 && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                right: "7px",
                                top: "-3px",
                              }}
                              onClick={() => {
                                let newArr = actionArray.filter(
                                  (val, i) => i !== index
                                );
                                setActionArray(newArr);
                              }}
                            >
                              <Close />
                            </IconButton>
                          )}
                          <Grid container spacing={1}>
                            <Grid item md={3} xs={6}>
                              <Typography>Type of action</Typography>
                              <Select
                                size="small"
                                value={val.type}
                                onChange={(e) => {
                                  let obj = val;
                                  obj.type = e.target.value;
                                  setActionArray(
                                    actionArray.map((o, i) =>
                                      i === index ? obj : o
                                    )
                                  );
                                }}
                                fullWidth
                              >
                                <MenuItem
                                  value="PHONE_NUMBER"
                                  disabled={val.type === "PHONE_NUMBER"}
                                >
                                  Number
                                </MenuItem>
                                <MenuItem
                                  value="URL"
                                  disabled={val.type === "URL"}
                                >
                                  Website
                                </MenuItem>
                              </Select>
                            </Grid>
                            <Grid item md={3} xs={6}>
                              <Typography>Button text</Typography>
                              <TextField
                                fullWidth
                                value={
                                  val.type === "PHONE_NUMBER"
                                    ? val.phone_text
                                    : val.url_text
                                }
                                onChange={(e) => {
                                  let obj = val;
                                  if (val.type === "PHONE_NUMBER")
                                    obj.phone_text = e.target.value;
                                  if (val.type === "URL")
                                    obj.url_text = e.target.value;
                                  let newValueArray = actionArray.map((o, i) =>
                                    index === i ? obj : o
                                  );
                                  setActionArray(newValueArray);
                                }}
                                size="small"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {`${
                                        val.type === "PHONE_NUMBER"
                                          ? val.phone_text.length
                                          : val.url_text.length
                                      }/25`}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>

                            {val.type === "PHONE_NUMBER" && (
                              <>
                                {" "}
                                <Grid item md={1.5} xs={4}>
                                  <Typography>Country</Typography>
                                  <Select
                                    fullWidth
                                    size="small"
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      obj = val;
                                      if (val.type === "PHONE_NUMBER")
                                        obj.country = e.target.value;
                                      let newValueArray = actionArray.map(
                                        (o, i) => (index === i ? obj : o)
                                      );
                                      setActionArray(newValueArray);
                                    }}
                                    // value={customerInfo.dialcode_mobile || ""}
                                    value={val?.country || ""}
                                    MenuProps={{ sx: { maxHeight: "400px" } }}
                                    renderValue={(value) => `+${value.value}`}
                                    // renderValue={(e) => `+${e.target.value}`}
                                  >
                                    <ListSubheader>
                                      Common Dial Codes
                                    </ListSubheader>
                                    {commonDialCodes.map((i) => {
                                      return (
                                        <MenuItem key={i.value} value={i}>
                                          {i.label}
                                        </MenuItem>
                                      );
                                    })}
                                    <ListSubheader>Dial Codes</ListSubheader>
                                    {dialCodes.map((obj) => (
                                      <MenuItem key={obj.value} value={obj}>
                                        {obj.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {/* <Autocomplete
                                    fullWidth
                                    id="combo-box-demo"
                                    options={dialCodes}
                                    value={val.country || ""}
                                    onChange={(e, newValue) => {
                                      obj = val;
                                      if (val.type === "PHONE_NUMBER")
                                        obj.country = newValue;
                                      let newValueArray = actionArray.map(
                                        (o, i) => (index === i ? obj : o)
                                      );
                                      setActionArray(newValueArray);
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} size="small" />
                                    )}
                                  /> */}
                                </Grid>
                                <Grid item md={4.5} xs={8}>
                                  <Typography>Phone Number</Typography>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    value={val.phone_number || ""}
                                    onChange={(e) => {
                                      let obj = val;
                                      obj.phone_number = e.target.value;
                                      let newValueArray = actionArray.map(
                                        (o, i) => (index === i ? obj : o)
                                      );
                                      setActionArray(newValueArray);
                                    }}
                                  />
                                </Grid>
                              </>
                            )}
                            {val.type === "URL" && (
                              <>
                                <Grid item md={1.5} xs={4}>
                                  <Typography>Type</Typography>
                                  <Select
                                    size="small"
                                    fullWidth
                                    value={val.linkType}
                                    onChange={(e) => {
                                      let obj = val;
                                      obj.linkType = e.target.value;
                                      let newValueArray = actionArray.map(
                                        (o, i) => (index === i ? obj : o)
                                      );
                                      setActionArray(newValueArray);
                                    }}
                                  >
                                    <MenuItem value="static">Static</MenuItem>
                                    <MenuItem value="dynamic">Dynamic</MenuItem>
                                  </Select>
                                </Grid>
                                <Grid item md={4.5} xs={8}>
                                  <Typography>URL</Typography>
                                  <Stack direction={"row"} alignItems="center">
                                    <TextField
                                      size="small"
                                      fullWidth
                                      value={val.url || ""}
                                      onChange={(e) => {
                                        let obj = val;
                                        obj.url = e.target.value;
                                        let newValueArray = actionArray.map(
                                          (o, i) => (index === i ? obj : o)
                                        );
                                        setActionArray(newValueArray);
                                      }}
                                      placeholder="http://www.example.com"
                                    />
                                    {val.linkType === "dynamic" && (
                                      <Typography>{`{{1}}`}</Typography>
                                    )}
                                  </Stack>
                                  {val.linkType === "dynamic" && (
                                    <Stack
                                      direction={"row"}
                                      justifyContent="end"
                                      sx={{ mt: 2, mr: 5 }}
                                    >
                                      <TextField
                                        sx={{ justifySelf: "end" }}
                                        value={urlVariable}
                                        onChange={(e) =>
                                          setUrlVariable(e.target.value)
                                        }
                                        size="small"
                                        placeholder="Enter value for {{1}}"
                                      />
                                    </Stack>
                                  )}
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Card>
                      </>
                    );
                  })}
                  <Stack direction={"row"} justifyContent={"end"}>
                    <Button
                      startIcon={<Add />}
                      onClick={onAddAction}
                      disabled={actionArray.length > 1}
                    >
                      Add another button
                    </Button>
                  </Stack>
                </>
              )}

              {button === "reply" && (
                <>
                  {textButton.map((value, index) => (
                    <>
                      <Typography sx={{ fontWeight: "700", mt: 2 }}>
                        Text Button
                      </Typography>
                      <Stack direction={"row"} alignItems="center">
                        <TextField
                          size="small"
                          value={value}
                          onChange={(e) => {
                            let newValue = textButton.map((val, i) =>
                              index === i ? e.target.value : val
                            );
                            setTextButton(newValue);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {`${textButton[index].length} / 25 `}
                              </InputAdornment>
                            ),
                          }}
                        />
                        {textButton.length !== 1 && (
                          <IconButton
                            onClick={() => {
                              let newArr = textButton.filter(
                                (val, i) => i !== index
                              );
                              setTextButton(newArr);
                            }}
                          >
                            <Close />
                          </IconButton>
                        )}
                      </Stack>
                    </>
                  ))}
                  <Button
                    startIcon={<Add />}
                    disabled={textButton.length > 2}
                    onClick={() => {
                      setTextButton((pre) => [...pre, ""]);
                    }}
                  >
                    Add another button
                  </Button>
                </>
              )}
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box
                sx={{
                  backgroundImage: "url(/static/img/whatsappBackground.png)",
                  height: "100%",
                  width: "100%",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  sx={{ color: "#808080", fontWeight: "600" }}
                  variant="subtitle1"
                >
                  Preview
                </Typography>
                <Box sx={{ height: "10px" }}></Box>
                <Card
                  sx={{
                    width: "90%",
                    padding: "7px",
                    backgroundColor:
                      header === "none" && !body && !footer
                        ? "transparent"
                        : "background.paper",
                  }}
                >
                  {header !== "none" && header !== "TEXT" && (
                    <Box
                      sx={{
                        backgroundColor: "#CCD0D5",
                        borderRadius: "3px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {headerMedia === "DOCUMENT" && (
                        <ArticleOutlined
                          style={{ height: "150px", width: "150px" }}
                        />
                      )}
                      {headerMedia === "IMAGE" && (
                        <ImageOutlined
                          style={{ height: "150px", width: "150px" }}
                        />
                      )}
                      {headerMedia === "VIDEO" && (
                        <PlayCircleOutlined
                          style={{ height: "150px", width: "150px" }}
                        />
                      )}

                      {/* <img src={imgSrc} style={{ marginBottom: "5px" }} /> */}
                    </Box>
                  )}
                  {header === "TEXT" && (
                    <Typography variant="h6">{headerText}</Typography>
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: body.replaceAll("\n", "<br />"),
                    }}
                    style={{ fontSize: "14px" }}
                  />
                  {footer && (
                    <Typography variant="caption" sx={{ color: "#A9A9A9" }}>
                      {footer}
                    </Typography>
                  )}
                </Card>
                {isArrayWithValues(textButton) && button == "reply" && (
                  <Grid
                    container
                    sx={{ width: "90%", mt: 0.5 }}
                    spacing={textButton.length === 1 ? 0 : 1}
                  >
                    {textButton.map((b, index) => (
                      <Grid
                        item
                        xs={
                          textButton.length === 1
                            ? 12
                            : textButton.length === 2
                            ? 6
                            : textButton.length === 3
                            ? index !== 2
                              ? 6
                              : 12
                            : null
                        }
                      >
                        {b ? (
                          <Card
                            sx={{
                              padding: 1,
                              // backgroundColor: !button
                              //   ? "transparent"
                              //   : "Background.paper",
                            }}
                          >
                            <Typography sx={{ textAlign: "center" }}>
                              {b}
                            </Typography>
                          </Card>
                        ) : null}
                      </Grid>
                    ))}
                  </Grid>
                )}
                {isArrayWithValues(actonsButtonText) && button == "action" && (
                  <Grid
                    container
                    sx={{ width: "90%", mt: 0.5 }}
                    spacing={actonsButtonText.length === 1 ? 0 : 1}
                  >
                    {actonsButtonText.map((b, index) => (
                      <Grid
                        item
                        xs={
                          actonsButtonText.filter((v) => v !== "").length === 1
                            ? 12
                            : actonsButtonText.filter((v) => v !== "")
                                .length === 2
                            ? 6
                            : null
                        }
                      >
                        {b ? (
                          <Card
                            sx={{
                              padding: 1,
                              // backgroundColor: !button
                              //   ? "transparent"
                              //   : "Background.paper",
                            }}
                          >
                            <Typography sx={{ textAlign: "center" }}>
                              {b}
                            </Typography>
                          </Card>
                        ) : null}
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
        {isIpad ? (
          <StackRow>
            {/* because close button in Title */}

            {false && <Button onClick={close}>Cancel</Button>}
            {currentStep === 0 && (
              <Button onClick={onNextPage} variant="contained">
                Next
              </Button>
            )}
            {currentStep === 1 && (
              <LoadingButton
                loading={creatingTemplete}
                onClick={() => createTemplete()}
              >
                {open?.id ? "Update" : "Create"}
              </LoadingButton>
            )}
          </StackRow>
        ) : null}
      </DialogContent>
      {!isIpad ? (
        <DialogActions>
          {false && <Button onClick={onClose}>Cancel</Button>}
          {currentStep === 0 && (
            <Button onClick={onNextPage} variant="contained">
              Next
            </Button>
          )}
          {currentStep === 1 && (
            <>
              {isDev() && !isArrayWithValues(open?.components) && (
                <LoadingButton
                  variant="outlined"
                  loading={drafting}
                  onClick={() => createTemplete(true)}
                >
                  Save as Draft
                </LoadingButton>
              )}
              <LoadingButton
                variant="contained"
                loading={creatingTemplete}
                onClick={() => createTemplete()}
              >
                {isArrayWithValues(open?.components) ? "Update" : "Create"}
              </LoadingButton>
            </>
          )}
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

export default CreateTemplete;

function formatTextWithCustomMarkers(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Helper function to recursively format text within a node.
  function formatNodeText(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      let text = node.textContent;

      // Replace bold markers with <strong> tags.
      text = text.replace(/\*([^*]+)\*/g, "<strong>$1</strong>");

      // Replace italic markers with <em> tags.
      text = text.replace(/~~([^~]+)~~/g, "<em>$1</em>");

      // Replace monospace markers with <code> tags.
      text = text.replace(/```([^`]+)```/g, "<code>$1</code>");

      node.textContent = text;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (const childNode of node.childNodes) {
        formatNodeText(childNode);
      }
    }
  }

  // Start formatting from the root element.
  formatNodeText(doc.body);

  // Serialize the modified HTML document back to a string.
  const formattedHtml = new XMLSerializer().serializeToString(doc);

  return formattedHtml;
}

const createWhatsappTemplate = async ({ integrations, data, isEdit }) => {
  let whatsapp_info = getValueFormIntegrations({
    integrations,
    key: "whatsapp_info",
    platform: "whatsapp",
  });
  let selected_whatsapp_phone_id = getValueFormIntegrations({
    integrations,
    key: "selected_whatsapp_phone_id",
    platform: "whatsapp",
  });
  let selectedWhatsappObj = whatsapp_info?.find(
    (i) => i?.id === selected_whatsapp_phone_id
  );
  let waba = selectedWhatsappObj?.waba;
  let endpoint = isEdit ? `/${data?.id}` : `/${waba}/message_templates`;
  if (isEdit) {
    delete data?.id;
    delete data?.status;
  }
  console.log(JSON.stringify(data, null, 2), isEdit);
  try {
    let response = await backendApiCall({
      endpoint: `${backendEndpoints.social}/whatsapp`,
      method: "POST",
      params: {
        endpoint,
      },
      data,
    });
    console.log(response);
    if (!response?.id) return response;
    return { id: response?.id };
  } catch (error) {
    console.log("Template error", getAxiosError(error));
    return getAxiosError(error);
  }
};
