import axios from "axios";
import {
  filterCondition,
  formatDate,
  formatServerValue,
  getAxiosError,
  getStoreId,
  getSupplierProduct,
  getToken,
  getWebsite,
  goldName,
  isArrayWithValues,
  isObjWithValues,
  updateSupplierProduct,
} from "../../helper";
import { setGlobalToast } from "./helperActions";
import { SET_PRODUCT_DATA } from "./productActions";

export const SUPPLIER_EDIT = "SUPPLIER_EDIT";

export const getSuppliers = () => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    dispatch({
      type: SUPPLIER_EDIT,
      payload: {
        fetchingSuppliers: true,
      },
    });
    try {
      let res;
      // res = await axios({
      //   url: `${website}/wp-json/store/v1/suppliers`,
      //   // url: `${website}/wp-json/store/v1/settings/crm_invites`,
      //   headers: {
      //     Authorization: `Basic ${token}`,
      //   },
      // });
      if (isArrayWithValues(res?.data)) {
        dispatch({
          type: SUPPLIER_EDIT,
          payload: {
            fetchingSuppliers: false,
            allSuppliers: res?.data,
          },
        });
      }
      if (isObjWithValues(res?.data?.data)) {
        dispatch({
          type: SUPPLIER_EDIT,
          payload: {
            fetchingSuppliers: false,
            allSuppliers: Object.values(res.data?.data),
          },
        });
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
};

// const updateSupplierProduct = (product, data) => {
//     return async (dispatch, getState) => {
//         let state = getState()
//         let suppliers = state.supplier.allSuppliers;
//         let supplier = suppliers.find((obj) => obj?.id === product?.supplier_id)
//         try {
//             let res = await axios({
//                 url: `${supplier?.url}/wp-json/wc/v3/products/${product.id}`,
//                 headers: {
//                     Authorization: `Basic ${Base64.btoa(`${supplier?.key}:${supplier?.secret}`)}`,
//                 },
//                 method: 'PUT',
//                 data: data,
//             });
//             console.log(res);
//             // if (res.data) return res.data;
//         } catch (error) {
//             console.log(getAxiosError(error), 'Update supplier product failed');
//             return false;
//         }
//     }
// }

export const likeProduct = (product) => {
  return async (dispatch, getState) => {
    let state = getState();
    let suppliers = state.supplier.allSuppliers;
    let supplier = suppliers.find((obj) => obj?.id === product?.supplier_id);
    let retailer_id = getStoreId();
    let products = state.product?.designbankProducts || [];
    dispatch({
      type: SET_PRODUCT_DATA,
      payload: {
        likingDesignbankProduct: true,
      },
    });
    try {
      let liveProduct = await getSupplierProduct(supplier, product?.id);
      let meta_data = liveProduct?.meta_data || [];
      let likes = meta_data.find((obj) => obj?.key === "retailer_likes");
      if (isObjWithValues(likes)) {
        let likeIdArr = likes?.value || [];
        if (likeIdArr.includes(retailer_id))
          likes.value = likeIdArr.filter((str) => str != retailer_id);
        else likes.value = [...likeIdArr, retailer_id];
      } else likes = { key: "retailer_likes", value: [retailer_id] };
      // let prelikeObj =
      let res = await updateSupplierProduct(supplier, product?.id, {
        meta_data: [likes],
      });

      console.log(res);
      if (res?.id) {
        dispatch({
          type: SET_PRODUCT_DATA,
          payload: {
            designbankProducts: products.map((obj) =>
              obj?.id === product?.id
                ? {
                    ...res,
                    supplier_id: supplier?.id,
                    supplier_name: supplier?.name,
                  }
                : obj
            ),
            likingDesignbankProduct: false,
          },
        });
        dispatch(
          setGlobalToast({
            show: true,
            message: `${
              likes?.value.includes(retailer_id) ? "Liked" : "Unliked"
            } a product  ${product?.name}`,
            severity: "success",
          })
        );
      } else {
        dispatch({
          type: SET_PRODUCT_DATA,
          payload: {
            designbankProducts: products.map((obj) =>
              obj?.id === product?.id
                ? {
                    ...product,
                    supplier_id: supplier?.id,
                    supplier_name: supplier?.name,
                  }
                : obj
            ),
            likingDesignbankProduct: false,
          },
        });
      }
      // if (res.data) return res.data;
    } catch (error) {
      console.log(getAxiosError(error), "Update supplier product failed");
      return false;
    }
  };
};

const setupDesignBankAcitivities = (acitivities, suppliers) => {
  return (dispatch, getState) => {
    let state = getState();
    let designBankActivities = state.supplier.designBankActivities;
    let allSupplers = state.supplier.allSuppliers;
    if (!acitivities) acitivities = designBankActivities;
    if (!suppliers) suppliers = allSupplers;

    let analyticsObj = {
      totalProductsPulledByCollection: {},
      totalProductsPulledBySuppliers: {},
      pulledHistory: {},
    };
    let historyDateObj = {};
    if (isObjWithValues(acitivities)) {
      for (let activity of Object.values(acitivities)) {
        let { pulled, time } = activity;
        if (!historyDateObj[formatDate(new Date(time), "mm-dd-yyyy")])
          historyDateObj[formatDate(new Date(time), "mm-dd-yyyy")] = 0;
        historyDateObj[formatDate(new Date(time), "mm-dd-yyyy")] += 1;
        if (!isObjWithValues(pulled)) continue;

        for (let supplierId in pulled) {
          if (supplierId && isArrayWithValues(pulled[supplierId?.toString()])) {
            analyticsObj.totalProductsPulledBySuppliers[
              supplierId?.toString()
            ] = pulled[supplierId?.toString()]
              .map((i) => i.source)
              .filter(Boolean);
          }
        }
      }
    }
    analyticsObj.pulledHistory = historyDateObj;

    if (isObjWithValues(acitivities) && isArrayWithValues(suppliers)) {
      for (let activity of Object.values(acitivities)) {
        let { pulled } = activity;
        if (!isObjWithValues(pulled)) continue;
        for (let supplierId in pulled) {
          if (supplierId && isArrayWithValues(pulled[supplierId])) {
            let supplierInfo = suppliers?.find((i) => i.id == supplierId);
            if (!supplierInfo) continue;
            for (let product of pulled[supplierId]) {
              let productInfo = supplierInfo?.filters?.[product?.source];
              if (!productInfo) continue;
              let collectionArray = productInfo?.collections?.split(",");
              if (isArrayWithValues(collectionArray)) {
                for (let collection of collectionArray) {
                  if (!analyticsObj.totalProductsPulledByCollection[collection])
                    analyticsObj.totalProductsPulledByCollection[
                      collection
                    ] = 0;
                  analyticsObj.totalProductsPulledByCollection[collection] += 1;
                }
              }
            }
          }
        }
      }
    }
    dispatch({
      type: SUPPLIER_EDIT,
      payload: {
        designbankProductsAnalytics: {
          ...(state?.supplier?.designbankProductsAnalytics || {}),
          ...analyticsObj,
        },
      },
    });
  };
};

export const getAllBusinesses = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: SUPPLIER_EDIT,
      payload: { gettingAllBusinesses: true },
    });

    let website = getWebsite();
    let token = getToken();
    try {
      let allBusinesses;
      // let allBusinesses = await axios({
      //   url: `${website}/wp-json/store/v1/businesses`,
      //   headers: {
      //     Authorization: `Basic ${token}`,
      //   },
      // });
      if (allBusinesses?.data?.success) {
        let allSuppliers = allBusinesses?.data?.data?.filter(
          (obj) => obj?.type === "supplier"
        );
        dispatch({
          type: SUPPLIER_EDIT,
          payload: {
            allSuppliers: allSuppliers,
            // ?.filter((obj) => obj?.type === "supplier")
            // ?.map((i) => (i.id == 27 ? { ...i, status: "connected" } : i))
            allRetailer: allBusinesses?.data?.data?.filter(
              (obj) => obj?.type === "retailer"
            ),
          },
        });
        dispatch(setupDesignBankAcitivities(false, allSuppliers));
        let filters = {
          suppliers: [],
          collections: [],
          categories: [],
          gold_kt: [],
          gold_gross: [],
          diamond_quality: [],
        };
        let connectedSupplier = allSuppliers?.filter(
          (obj) => obj?.status === "connected"
        );
        let products = [];
        let productsData = {
          total: 0,
          readyStock: 0,
          makeToOrder: 0,
          stockAvailable: {
            diamondProducts: 0,
            goldProducts: 0,
          },
          totalProductsByCollection: {},
          goldJewellery: 0,
          diamondJewelley: 0,
          platinumJwewlley: 0,
          silverJewellery: 0,
        };
        // console.log(connectedSupplier, "<<< connectedSupplier");
        for (const obj of connectedSupplier) {
          for (const product of Object.values(obj?.filters || {})) {
            let { collections } = product;
            if (collections) {
              let collectionArray = collections.split(",");
              if (isArrayWithValues(collectionArray)) {
                for (let collection of collectionArray) {
                  if (!productsData.totalProductsByCollection[collection])
                    productsData.totalProductsByCollection[collection] = 0;
                  productsData.totalProductsByCollection[collection] += 1;
                }
              }
            }
            productsData.total += 1;
            if (product?.collections?.includes("diamond"))
              productsData.diamondJewelley += 1;
            if (product?.collections?.includes("gold"))
              productsData.goldJewellery += 1;
            if (product?.collections?.includes("platinum"))
              productsData.platinumJwewlley += 1;
            if (product?.collections?.includes("silver"))
              productsData.silverJewellery += 1;
            if (product?.stock_status === "instock") {
              productsData.readyStock += 1;
              if (product?.gold_kt)
                productsData.stockAvailable.goldProducts += 1;
              if (product?.diamond_quality)
                productsData.stockAvailable.diamondProducts += 1;
            }
            if (product?.stock_status === "onbackorder")
              productsData.readyStock += 1;

            if (filterCondition(filters.collections, product?.collections))
              filters.collections = [
                ...filters.collections,
                {
                  value: product?.collections,
                  label: formatServerValue(product?.collections),
                },
              ];
            if (filterCondition(filters.categories, product?.categories))
              filters.categories = [
                ...filters.categories,
                {
                  value: product?.categories,
                  label: formatServerValue(product?.categories),
                },
              ];
            if (filterCondition(filters.gold_kt, product?.gold_kt))
              filters.gold_kt = [
                ...filters.gold_kt,
                {
                  value: product?.gold_kt,
                  label: goldName[product?.gold_kt],
                },
              ];
            if (product?.diamond_quality) {
              // console.log(filters?.diamond_quality);
              let diamondArr = product?.diamond_quality?.split(",") || [];
              for (let index = 0; index < diamondArr.length; index++) {
                const element = diamondArr[index];
                if (filterCondition(filters.diamond_quality, element))
                  filters.diamond_quality = [
                    ...filters.diamond_quality,
                    {
                      value: element,
                      label: formatServerValue(element)?.toUpperCase(),
                    },
                  ];
              }
            }
            products = [
              ...products,
              { ...product, supplier_id: obj?.id, suppliers: obj?.name },
            ];
          }
          if (isObjWithValues(obj?.filters))
            filters.suppliers.push({ label: obj?.name, value: obj?.name });
        }
        // console.log(products, ">> products  ");
        dispatch({
          type: SUPPLIER_EDIT,
          payload: {
            supplierProductsFilters: filters,
            filterObjectProducts: products,
            designbankProductsAnalytics: {
              ...(getState()?.supplier?.designbankProductsAnalytics || {}),
              ...productsData,
            },
          },
        });
      }
      // console.log(allBusinesses?.data);
    } catch (error) {
      console.log(getAxiosError(error));
    }
    dispatch({
      type: SUPPLIER_EDIT,
      payload: { gettingAllBusinesses: false },
    });
  };
};

export const addDesignBankActivity = (data) => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    try {
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings/design_bank_activities`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "PUT",
        data: {
          setting: "design_bank_activities",
          data: { ...data },
        },
      });
      console.log(res);
      dispatch({
        type: SUPPLIER_EDIT,
        payload: {
          designBankActivities: res?.data?.data,
        },
      });
      dispatch(setupDesignBankAcitivities(res?.data?.data));
    } catch (error) {}
  };
};

export const getDesignBankActivities = () => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/design_bank_activities`,
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    if (res?.data?.type === "success") {
      dispatch({
        type: SUPPLIER_EDIT,
        payload: {
          designBankActivities: res?.data?.data,
        },
      });
    }
    dispatch(setupDesignBankAcitivities(res?.data?.data));

    console.log(res);
  };
};
