import { isArrayWithValues, validateNumber } from "jwero-javascript-utils";
import { removeHtmlTags } from "../../../../../helper";

const validatePrimaryPlanDetails = ({ form }) => {
  let errors = {};
  if (!form?.name) errors.name = "Plan name is required";
  return errors;
};
const validatePlanContentsDetails = ({ form }) => {
  let errors = {};
  let terms_and_conditions = removeHtmlTags(form?.terms_and_conditions || "");
  if (!form?.terms_and_conditions || !terms_and_conditions)
    errors.terms_and_conditions = "Terms and Conditions are required";
  return errors;
};
function convertToDays(number, unit) {
  let days;
  switch (unit.toLowerCase()) {
    case "days":
      days = number;
      break;
    case "months":
      days = number * 30; // Assuming an average month has 30 days
      break;
    case "years":
      days = number * 365; // Assuming a non-leap year for simplicity
      break;
    default:
      return "Invalid unit";
  }
  return days;
}
const validateCustomRewardForm = ({ form, addForm, mainForm }) => {
  let errors = {};
  if (!form?.from) errors.from = "Starts from is required";
  if (!form?.to) errors.to = "Ends on is required";
  if (validateNumber(form.from) >= validateNumber(form.to))
    errors.from = "Starts from cannot be greater than Ends on";
  if (validateNumber(form.from) === validateNumber(form.to))
    errors.from = "Starts from cannot be equal to Ends on";

  let total_days = convertToDays(
    validateNumber(mainForm?.digi_plan_duration),
    mainForm?.duration_period
  );

  let total_ends_on_days = convertToDays(
    validateNumber(form?.to),
    form?.time_period_type
  );
  let total_starts_from_days = convertToDays(
    validateNumber(form?.from),
    form?.time_period_type
  );
  if (total_ends_on_days > total_days)
    errors.to = "Ends on should not be greater than plan duration";
  if (total_starts_from_days > total_days)
    errors.from = "Starts from should not be greater than plan duration";

  // validate overlap
  if (isArrayWithValues(addForm?.reward_breakup)) {
    let checkForOverlap = addForm?.reward_breakup;
    if (form?.id) {
      checkForOverlap = checkForOverlap?.filter(
        (i) => i?.id + "" !== form?.id + ""
      );
    }
    let overlapping = doesOverlap(form, checkForOverlap);
    if (overlapping)
      errors.overlapping = `Interval duration overlapping with ${overlapping?.title} (${overlapping?.from} - ${overlapping?.to} ${overlapping?.time_period_type})`;
  }

  return errors;
};

const normalizePeriodToDays = (value, period) => {
  const periodInDays = {
    days: 1,
    months: 30, // Approximation
    years: 365, // Approximation
  };

  return value * periodInDays[period];
};

const doesOverlap = (newRow, existingRows) => {
  const newRowStartInDays = normalizePeriodToDays(
    parseInt(validateNumber(newRow.from), 10),
    newRow.time_period_type
  );
  const newRowEndInDays = normalizePeriodToDays(
    parseInt(validateNumber(newRow.to), 10),
    newRow.time_period_type
  );

  for (let row of existingRows) {
    const rowStartInDays = normalizePeriodToDays(
      parseInt(validateNumber(row.from), 10),
      row.time_period_type
    );
    const rowEndInDays = normalizePeriodToDays(
      parseInt(validateNumber(row.to), 10),
      row.time_period_type
    );
    const isOverlapping =
      newRowStartInDays <= rowEndInDays && newRowEndInDays >= rowStartInDays;
    if (isOverlapping) {
      return row;
    }
  }
  return false;
};

export {
  validatePrimaryPlanDetails,
  validateCustomRewardForm,
  validatePlanContentsDetails,
};
