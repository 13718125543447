import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  DefaultLabel,
  SecondaryLabelExtraSmall,
  SecondaryLabelSmall,
} from "../../../helper/utility/Labels";
import AddCustomField from "./components/AddCustomField";
import { useDispatch, useSelector } from "react-redux";
import {
  EmptyState,
  TabPanel,
  a11yProps,
  formatDate,
  formatServerValue,
  getTenantId,
  isDev,
  isObjWithValues,
  isOurWebsite,
  validateNumber,
} from "../../../helper";
import { EmptyGraphSvg, NotFound } from "../../../Svgs";
import { Check, DeleteOutlined, EditOutlined } from "@mui/icons-material";
import HorizontalStack from "../../../helper/utility/HorizontalStack";
import CustomConfirmationDialog from "../../../helper/utility/CustomConfirmationDialog";
import { deleteCustomField } from "./helpers";
import {
  setGlobalSnackbar,
  setGlobalToast,
} from "../../../redux/actions/helperActions";
import { editChats } from "../../../redux/actions/chatActions";
import { editSettings } from "../../../redux/actions/settingActions";
const DROPDOWN_TYPES = ["checkbox", "dropdown", "radio"];

function CustomFields() {
  const addRef = useRef(null);
  const deleteRef = useRef(null);
  const custom_fields = useSelector((state) => state?.settings?.custom_fields);
  const [customFields, setCustomFields] = useState({});
  const [selectedTab, setSelectedTab] = useState("all");
  const [deleteField, setDeleteField] = useState({});
  const [maxCustomFields, setMaxCustomFields] = useState(0);
  const fetchingMainSettings = useSelector(
    (state) => state.settings.fetchingMainSettings
  );
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const storePlan = useSelector((state) => state.user.store_plan);

  const dispatch = useDispatch();

  useEffect(() => {
    let max = 0;
    if (
      isDev() ||
      getTenantId() === "M7n1wenu7QYT" ||
      getTenantId() === "oAeqLavq0AQi" ||
      isOurWebsite()
    )
      max = 500;
    else if (storePlan?.plan === "growth" || user_products?.includes("crm"))
      max = 25;
    else max = 3;
    setMaxCustomFields(max);
  }, [user_products]);

  useEffect(() => {
    if (!isObjWithValues(custom_fields)) return;
    let all = {};
    Object.entries(custom_fields)?.map(([key, value]) => {
      all = { ...all, ...value };
    });

    setCustomFields({ all, ...custom_fields });
  }, [custom_fields]);
  const onAddPress = () => {
    addRef?.current?.open();
  };

  const onTabChange = useCallback((a, e) => {
    setSelectedTab(e);
  }, []);

  const onEditPress = useCallback((field) => {
    addRef?.current?.open();
    addRef?.current?.setupForm(field);
  }, []);
  const onDeletePress = (field) => {
    setDeleteField(field);
    deleteRef?.current?._open(true);
    // deleteRef?.current?.setupForm(field);
  };
  const onDelete = async () => {
    deleteRef?.current?.setLoading(true);
    let res = await deleteCustomField({ id: deleteField?.id });
    if (res?.success) {
      dispatch(editSettings({ custom_fields: res?.data }));
      dispatch(
        setGlobalToast({
          show: true,
          message: "Custom Field deleted successfully",
          severity: "success",
        })
      );
      deleteRef?.current?.close();
    } else
      dispatch(
        setGlobalToast({
          show: true,
          message: "Unable to delete custom field",
          severity: "error",
        })
      );
    deleteRef?.current?.setLoading(false);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>
              <Typography sx={{}} variant="h5">
                {Object.values(customFields?.all || {})?.length}/
                <Typography
                  variant="span"
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  {maxCustomFields}
                </Typography>{" "}
                fields created
              </Typography>
              <SecondaryLabelSmall>
                Enhance your contact records with personalized custom fields
                tailored to your specific business needs
              </SecondaryLabelSmall>
            </Box>
            <Button
              variant="contained"
              disabled={
                fetchingMainSettings ||
                Object.values(customFields.all || {})?.length >= maxCustomFields
              }
              onClick={onAddPress}
            >
              Create field
            </Button>
          </Stack>
          {!fetchingMainSettings && !isObjWithValues(customFields) ? (
            <EmptyState
              icon={<NotFound height={270} width={270} />}
              heading={"No custom fields found"}
              subtitle={
                "Start by creating custom fields to tailor your experience."
              }
            />
          ) : (
            <>
              <TabsView selectedTab={selectedTab} onTabChange={onTabChange} />

              <TableView
                fields={Object.values(customFields?.[selectedTab] || {}) || []}
                onEditPress={onEditPress}
                onDeletePress={onDeletePress}
              />
              {/* <TabPanel value={selectedTab} index={"all"}>
              </TabPanel>
              <TabPanel value={selectedTab} index={"crm"}>
                Item Two
              </TabPanel> */}
            </>
          )}
        </CardContent>
      </Card>
      <AddCustomField ref={addRef} />
      <CustomConfirmationDialog
        ref={deleteRef}
        title={"Delete Custom Field?"}
        subtitle={
          "Deleting this field will permanently remove it from the CRM."
        }
        onSubmit={onDelete}
        submitButtonProps={{
          label: "Yes, Delete Custom Field",
          color: "error",
          size: "small",
        }}
      />
    </>
  );
}

export default CustomFields;

const TabsView = ({ selectedTab, onTabChange }) => {
  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={onTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="All" value="all" />
          <Tab label="CRM" value={"crm"} />
        </Tabs>
      </Box>
    </Box>
  );
};

const TableView = ({ fields, onEditPress, onDeletePress }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Options</TableCell>
              <TableCell>Module</TableCell>
              <TableCell>Mandatory</TableCell>
              <TableCell>Access to</TableCell>
              <TableCell>Created by</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields?.map((row) => {
              return (
                <Row
                  key={row.id}
                  row={row}
                  onEditPress={onEditPress}
                  onDeletePress={onDeletePress}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const Row = ({ row, onEditPress, onDeletePress }) => {
  const theme = useTheme();
  const teamMembers = useSelector((state) => state.settings.teamMembers);
  const [creatorName, setCreatorName] = useState("");
  useEffect(() => {
    if (!isObjWithValues(row)) return;
    let { created_by, is_admin } = row;
    let creator = "";
    if (!is_admin) {
      let member = teamMembers?.find((i) => i.id === created_by);
      if (member) creator = `${member?.first_name} ${member?.last_name}`;
    } else creator = `Admin`;
    setCreatorName(creator);
  }, [row]);
  return (
    <TableRow
      key={row.value}
      sx={{
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        "&:last-child td, &:last-child th": { border: 0 },
        p: 0,
      }}
    >
      <TableCell component="th" scope="row">
        {row.title}
      </TableCell>
      <TableCell>{formatServerValue(row?.type)}</TableCell>
      <TableCell>{row?.options?.length || ""}</TableCell>
      <TableCell>{formatServerValue(row.module)}</TableCell>
      <TableCell>{row?.mandatory && <Check color="success" />}</TableCell>
      <TableCell>{validateNumber(row?.team_members?.length)}</TableCell>
      <TableCell>
        <Typography>{creatorName}</Typography>
        <SecondaryLabelExtraSmall>
          {formatDate(new Date(row.created_on), "dd:mm:yyyy hh:mm am/pm")}
        </SecondaryLabelExtraSmall>
      </TableCell>
      <TableCell>
        <HorizontalStack>
          <IconButton onClick={() => onEditPress(row)}>
            <EditOutlined />
          </IconButton>
          <IconButton onClick={() => onDeletePress(row)}>
            <DeleteOutlined />
          </IconButton>
        </HorizontalStack>
      </TableCell>
    </TableRow>
  );
};
