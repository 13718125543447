import { CheckCircle } from "@material-ui/icons";
import {
  Add,
  DeleteOutline,
  Edit,
  FlashAuto,
  Info,
  YouTube,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sidebarExtraWidth, tutorialData } from "../../../App";
import {
  EmptyState,
  dialogStyle,
  formatServerValue,
  isArrayWithValues,
  isObjWithValues,
  settingsEndpoint,
} from "../../../helper";
import {
  setGlobalSnackbar,
  setGlobalToast,
  setGlobalTutorial,
} from "../../../redux/actions/helperActions";
import { fetchTaxonomies } from "../../../redux/actions/productActions";
import {
  createSizeMaster,
  editSettings,
} from "../../../redux/actions/settingActions";
import { currencySymbols } from "../../pricing/Gold";
import { getRandomString } from "jwero-javascript-utils";
import CustomTooltip from "../../flows/pages/addFlow/components/CustomTooltip";
import CustomConfirmationDialog from "../../../helper/utility/CustomConfirmationDialog";
import HorizontalStack from "../../../helper/utility/HorizontalStack";
import CustomChip from "../../components/CustomChip";
import { NotFound } from "../../../Svgs";
import { PrimaryLabelExtraSmall } from "../../../helper/utility/Labels";
const chainSize = [
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
];
const ringSize = [
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
];
const bangel_bracelet_Size = [
  "2.02",
  "2.04",
  "2.06",
  "2.08",
  "2.10",
  "2.12",
  "2.14",
  "2.16",
];
export const allSizeGuides = [
  // { label: "Rings", size_guide: "rings-size-guide" },
  // { label: "bangles", size_guide: "bangles-size-guide" },
  // { label: "Chain", size_guide: "chain-size-guide" },
  { label: "Rings Size", size_guide: "ring_size" },
  { label: "Bangles and Bracelet Size", size_guide: "bangel_bracelet_size" },
  // { label: "Chain", size_guide: "chain" },
  { label: "No. of Lines", size_guide: "no_of_lines" },
  { label: "Product Length", size_guide: "product_length" },
];

const SizeMasters = ({ productSettings }) => {
  const [viewAddForm, setAddViewForm] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [formSizes, setFormSizes] = useState([]);
  const defaultState = {
    label: [],
    size_guide: "",
    sizes: [],
    difference_by: "amount",
    increase_difference: "",
    decrease_difference: "",
    size_guides: [],
  };
  const [addSizeMasterForm, setAddSizeMasterForm] = useState(defaultState);
  const [allTabs, setAllTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedSizeGuideForTab, setSelectedSizeGuideForTab] = useState("");
  const [allSizeMasters, setAllSizeMasters] = useState({});
  const textFieldRef = createRef();
  const [increaseError, setIncreaseError] = useState("");
  const [decreaseError, setDecreaseError] = useState("");
  const [increaseErrors, setIncreaseErrors] = useState({});
  const [decreaseErrors, setDecreaseErrors] = useState({});
  const sizeMasters = useSelector((state) => state.settings.sizeMastersNew);
  const [confirmationProps, setConfirmationProps] = useState({});
  const [helperText, setHelperText] = useState({});
  const fetchingMastersSetting = useSelector(
    (state) => state.settings.fetchingAllSettings
  );
  const creatingSizeMaster = useSelector(
    (state) => state.settings.creatingSizeMaster
  );
  const global_size_masters_guide = useSelector(
    (state) => state.settings.global_size_masters_guide
  );
  // const sizeMasters = useSelector((state) => state.settings.sizeMasters);
  const dispatch = useDispatch();
  const taxonomies = useSelector((state) => state.product.taxonomies);
  const confirmationRef = useRef();

  useEffect(() => {
    if (isObjWithValues(sizeMasters)) {
      setAllSizeMasters(sizeMasters);
      let tabs = [];
      for (const key in sizeMasters) {
        let obj = { label: sizeMasters[key].label, value: key };
        tabs.push(obj);
      }

      setAllTabs(tabs);
      setSelectedTab(tabs[0].value);
    } else {
      // dispatch(getSizeMastersNew());
    }
  }, [sizeMasters]);

  useEffect(() => {
    setFilteredCategories(
      addSizeMasterForm?.id
        ? categories?.filter(
            (i) =>
              !Object.values(allSizeMasters)
                ?.filter((j) => j?.id !== addSizeMasterForm?.id)
                ?.map((i) => i?.label)
                ?.flat()
                ?.find((j) => j?.value === i?.value)
          )
        : categories?.filter(
            (i) =>
              !Object.values(allSizeMasters)
                ?.map((i) => i?.label)
                ?.flat()
                ?.find((j) => j?.value === i?.value)
          )
    );
  }, [addSizeMasterForm?.id, categories, allSizeMasters]);

  useEffect(() => {
    if (allSizeMasters?.[selectedTab]?.size_guides)
      setSelectedSizeGuideForTab(
        allSizeMasters?.[selectedTab]?.size_guides?.[0]?.id
      );
  }, [selectedTab, allSizeMasters]);

  useEffect(() => {
    // if (addSizeMasterForm.size_guide === "rings-size-guide") {
    //   setFormSizes(ringSize);
    //   setAddSizeMasterForm((pre) => ({ ...pre, sizes: ringSize }));
    // }
    // if (addSizeMasterForm.size_guide === "bangles-size-guide") {
    //   setFormSizes(bangel_bracelet_Size);
    //   setAddSizeMasterForm((pre) => ({ ...pre, sizes: bangel_bracelet_Size }));
    // }
    // if (addSizeMasterForm.size_guide === "chain-size-guide") {
    // setFormSizes(chainSize);
    // setAddSizeMasterForm((pre) => ({ ...pre, sizes: chainSize }));
    // }
    let options =
      global_size_masters_guide?.[addSizeMasterForm.size_guide]?.options || [];
    setFormSizes(options);
    setAddSizeMasterForm((pre) => ({ ...pre, sizes: options }));
  }, [addSizeMasterForm.size_guide, global_size_masters_guide]);

  useEffect(() => {
    setHelperText({
      increase_difference: `Increasing ${allSizeMasters?.[selectedTab]?.difference_by} by size`,
    });
  }, [allSizeMasters?.[selectedTab]?.difference_by]);

  useEffect(() => {
    if (isObjWithValues(taxonomies)) {
      let { categories } = taxonomies;
      if (isArrayWithValues(categories)) {
        setCategories(
          categories.filter((obj) => !allTabs.find((i) => i.label == obj.label))
        );
      }
    } else {
      dispatch(fetchTaxonomies());
    }
  }, [taxonomies, allTabs]);

  const onAddSizeMaters = async () => {
    let id = addSizeMasterForm?.id || getRandomString(10);
    dispatch(
      createSizeMaster(
        {
          [id]: {
            id,
            ...addSizeMasterForm,
            sizes: addSizeMasterForm.sizes.map((i) => ({
              // label: i,
              ...i,
              enable: true,
            })),
            deleted: false,
          },
        },
        null,
        () => {
          setAddViewForm(false);
          setAddSizeMasterForm(defaultState);
        }
      )
    );
    // if (!fetchingMastersSetting) setAddViewForm(false);
    // await setAddViewForm(fetchingMastersSetting);
  };
  useEffect(() => {
    if (!fetchingMastersSetting) {
      setAddViewForm(fetchingMastersSetting);
      setAddSizeMasterForm(defaultState);
    }
  }, [fetchingMastersSetting]);

  const onSizeChange = (tab, obj) => {
    let sizesArr = allSizeMasters[tab].sizes.map((i) =>
      i.label === obj.label ? { ...i, enable: !obj.enable } : i
    );
    setAllSizeMasters((pre) => ({
      ...pre,
      [tab]: { ...allSizeMasters[tab], sizes: sizesArr },
    }));
  };

  const onLabelChange = (value) => {
    console.log(value, "<<<<<<<<<value");
    setAddSizeMasterForm((pre) => ({
      ...pre,
      label: value,
    }));
    // let { size_guide } = addSizeMasterForm;
    // value = value?.value;
    // if (value === "bangles" && size_guide !== "bangles-size-guide")
    //   setAddSizeMasterForm((state) => ({
    //     ...state,
    //     size_guide: "bangles-size-guide",
    //   }));
    // if (
    //   value.includes("ring") &&
    //   !value.includes("earrings") &&
    //   size_guide !== "rings-size-guide"
    // )
    //   setAddSizeMasterForm((state) => ({
    //     ...state,
    //     size_guide: "rings-size-guide",
    //   }));
    // if (value.includes("chain") && size_guide !== "chain-size-guide")
    //   setAddSizeMasterForm((state) => ({
    //     ...state,
    //     size_guide: "chain-size-guide",
    //   }));
  };

  const onEditAllSizeMasters = (category, payload) => {
    setAllSizeMasters((state) => ({
      ...state,
      [category]: { ...state[category], ...payload },
    }));
  };

  const onUpdate = () => {
    dispatch(
      createSizeMaster(
        {
          [selectedTab]: allSizeMasters[selectedTab],
        },
        true
      )
    );
  };

  const onEditAddForm = (payload) => {
    return setAddSizeMasterForm((state) => ({ ...state, ...payload }));
  };

  const onDifferenceValueChange = (label, value) => {
    setAddSizeMasterForm((state) => ({
      ...state,
      size_guides: state.size_guides?.map((i) =>
        i?.id === state.selected_size_guide ? { ...i, [label]: value } : i
      ),
    }));
  };
  // const onDifferenceValueChange = (label, value) => {
  //   setAddSizeMasterForm((state) => ({ ...state, [label]: value }));
  //   if (addSizeMasterForm.difference_by === "weight") {
  //     if (Math.floor(value) > 0) {
  //       if (label === "increase_difference")
  //         return setIncreaseError("Weight cannot be more than 1");
  //       if (label === "decrease_difference")
  //         return setDecreaseError("Weight cannot be more than 1");
  //     } else if (value.length > 4) {
  //       if (label === "increase_difference")
  //         return setIncreaseError("Length cannot be more than 4");
  //       if (label === "decrease_difference")
  //         return setDecreaseError("Length cannot be more than 4");
  //     } else {
  //       setDecreaseError("");
  //       setIncreaseError("");
  //     }
  //   } else {
  //     setDecreaseError("");
  //     setIncreaseError("");
  //   }
  // };
  const onAllSizeDifferenceValueChange = (category, label, value) => {
    onEditAllSizeMasters(category, { [label]: value });
    if (label === "difference_by" && value === "weight") {
      let { decrease_difference, increase_difference } =
        allSizeMasters[category];
      if (Math.floor(decrease_difference) > 0)
        setDecreaseErrors({
          [category]: "Weight cannot be more than 1",
        });
      if (Math.floor(increase_difference) > 0)
        setIncreaseErrors({
          [category]: "Weight cannot be more than 1",
        });
      if (decrease_difference.length > 4)
        setDecreaseErrors({
          [category]: "Length cannot be more than 4",
        });
      if (increase_difference.length > 4)
        setIncreaseErrors({
          [category]: "Length cannot be more than 4",
        });
    } else {
      setDecreaseErrors({ [category]: "" });
      setIncreaseErrors({ [category]: "" });
    }
    if (allSizeMasters[selectedTab]?.difference_by === "weight") {
      if (Math.floor(value) > 0) {
        if (label === "increase_difference")
          return setIncreaseErrors({
            [category]: "Weight cannot be more than 1",
          });
        if (label === "decrease_difference")
          return setDecreaseError({
            [category]: "Weight cannot be more than 1",
          });
      } else if (value.length > 4) {
        if (label === "increase_difference")
          return setIncreaseErrors({
            [category]: "Length cannot be more than 4",
          });
        if (label === "decrease_difference")
          return setDecreaseError({
            [category]: "Length cannot be more than 4",
          });
      }
      setDecreaseError({ [category]: "" });
      setIncreaseErrors({ [category]: "" });
    } else {
      setDecreaseError({ [category]: "" });
      setIncreaseErrors({ [category]: "" });
    }
  };

  const deleteSizeMaster = async () => {
    try {
      confirmationRef?.current?.setLoading(true);
      let deleteRes = await settingsEndpoint({
        endpoint: "size_masters_info",
        method: "DELETE",
        data: { id: selectedTab },
      });
      console.log(deleteRes, "<<<<<<<<<<< deleteRes");
      dispatch(
        editSettings({
          sizeMastersNew: deleteRes,
        })
      );
      dispatch(
        setGlobalToast({
          show: true,
          message: "Size master deleted successfully!",
          severity: "success",
        })
      );
      confirmationRef?.current?.close();
    } catch (error) {
      console.log(error);
      dispatch(
        setGlobalToast({
          show: true,
          message: "Unable to delete size master!",
          severity: "error",
        })
      );
    } finally {
      confirmationRef?.current?.setLoading(false);
    }
  };

  const onDeletePress = () => {
    setConfirmationProps({
      title: "Delete size master?",
      subtitle: "This action cannot be undone",
      submitButtonProps: { color: "error" },
      onSubmit: () => deleteSizeMaster(),
    });
    confirmationRef?.current?.open();
  };

  const onEditPress = () => {
    setAddSizeMasterForm({ ...allSizeMasters[selectedTab] });
    setAddViewForm(true);
  };

  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};

  const onSizeGuideSelect = ({ size_guide }) => {
    let id = getRandomString(8);
    if (isArrayWithValues(addSizeMasterForm?.size_guides)) {
      setAddSizeMasterForm((pre) => ({
        ...pre,
        size_guides: [
          ...pre.size_guides,
          {
            id,
            size_guide,
            sizes: global_size_masters_guide?.[size_guide]?.options,
          },
        ],
        selected_size_guide: id,
      }));
    } else
      setAddSizeMasterForm((pre) => ({
        ...pre,
        size_guides: [
          {
            id,
            size_guide,
            sizes: global_size_masters_guide?.[size_guide]?.options,
          },
        ],
        selected_size_guide: id,
      }));
  };

  const onSizeGuideDeleteFromAddForm = (id) => {
    setAddSizeMasterForm((pre) => ({
      ...pre,
      size_guides: pre?.size_guides?.filter((i) => i?.id !== id),
    }));
  };
  const onSizeGuideSelectTab = (id) => {
    setAddSizeMasterForm((pre) => ({
      ...pre,
      selected_size_guide: id,
    }));
  };

  const onSizeGuideValueSelect = (values) => {
    setAddSizeMasterForm((pre) => ({
      ...pre,
      size_guides: pre?.size_guides?.map((i) =>
        i?.id === pre.selected_size_guide ? { ...i, sizes: values } : i
      ),
    }));
  };

  return (
    <Box>
      <Card sx={{ padding: 4 }}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="h6">
            Size Master{" "}
            <IconButton
              onClick={() =>
                dispatch(
                  setGlobalTutorial({
                    show: true,
                    url: tutorialData?.size_Masters?.link,
                    title: tutorialData?.size_Masters?.title,
                  })
                )
              }
            >
              <YouTube />
            </IconButton>
          </Typography>
          <Stack direction={"row"} sx={{ gap: "1rem" }}>
            <Button
              variant="outlined"
              onClick={() => {
                setAddSizeMasterForm(defaultState);
                setAddViewForm(true);
              }}
              startIcon={<Add />}
            >
              Add New
            </Button>
            {/* <LoadingButton
              loading={fetchingMastersSetting || creatingSizeMaster}
              variant="contained"
              onClick={onUpdate}
            >
              Save Changes
            </LoadingButton> */}
          </Stack>
        </Stack>
        {fetchingMastersSetting && (
          <Stack
            justifyContent={"center"}
            alignItems="center"
            sx={{ height: "50vh" }}
          >
            <Typography
              variant="h6"
              textAlign={"center"}
              sx={{ marginBottom: 5 }}
            >
              Fetching Sizes...
            </Typography>
            <LinearProgress sx={{ width: "50%" }} />
          </Stack>
        )}
        {!fetchingMastersSetting && (
          <Card sx={{ mt: 4, overflowY: "scroll" }}>
            <Stack
              direction="row"
              sx={{
                overflow: "auto",
                // maxWidth: `calc(100% - ${sidebarExtraWidth}px)`,
                flex: 1,
              }}
              spacing={2}
            >
              {allTabs.map((i) => {
                const isSelected = i.value === selectedTab;
                return (
                  <CustomTooltip
                    title={
                      isArrayWithValues(i?.label)
                        ? i?.label?.map((i) => i?.label)?.join()
                        : i?.label
                    }
                  >
                    <Button
                      //   variant={isSelected ? "contained" : "text"}
                      disableRipple
                      sx={{
                        // mx: 2,
                        backgroundColor: isSelected ? "#eee" : "transparent",
                        color: isSelected ? "primary.main" : "text.secondary",
                        // whiteSpace: "nowrap",
                        textTransform: "capitalize",
                      }}
                      onClick={() => setSelectedTab(i.value)}
                    >
                      {i.label &&
                        `${i.label?.[0]?.label} ${
                          i.label?.length > 1 ? ` +${i.label?.length - 1}` : ""
                        }`}
                    </Button>
                  </CustomTooltip>
                );
              })}
            </Stack>
            <CardContent sx={{}}>
              {Boolean(allSizeMasters?.[selectedTab]?.id) && (
                <Stack
                  direction={"row"}
                  justifyContent="flex-end"
                  spacing={2}
                  mt={3}
                  mb={2}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<Edit />}
                    onClick={() => {
                      onEditPress();
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<DeleteOutline />}
                    color="error"
                    onClick={() => onDeletePress()}
                  >
                    Delete
                  </Button>
                  {/* <Box sx={{ minWidth: 120, float: "right" }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Default
                    </InputLabel>
                    {allSizeMasters[selectedTab] && (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={allSizeMasters[selectedTab].default || ""}
                        label="Default"
                        onChange={(e) => {
                          setAllSizeMasters({
                            ...allSizeMasters,
                            [selectedTab]: {
                              ...allSizeMasters[selectedTab],
                              default: e.target.value,
                            },
                          });
                        }}
                      >
                        {allSizeMasters[selectedTab] &&
                          allSizeMasters[selectedTab].sizes.map((i) =>
                            i.enable ? (
                              <MenuItem value={i.value}>
                                {i.label?.[0]?.label}
                              </MenuItem>
                            ) : null
                          )}
                      </Select>
                    )}
                  </FormControl>
                </Box> */}
                </Stack>
              )}
              <Stack direction="row" spacing={2} flexWrap={"wrap"}>
                {allSizeMasters[selectedTab] &&
                  allSizeMasters[selectedTab]?.size_guides?.map(
                    (i) => (
                      <CustomChip
                        // onDelete={() => onSizeGuideDeleteFromAddForm(i?.id)}
                        label={formatServerValue(i?.size_guide)}
                        onClick={() => setSelectedSizeGuideForTab(i?.id)}
                        type={
                          selectedSizeGuideForTab !== i?.id
                            ? "outlined"
                            : "default"
                        }
                        showBorder
                      />
                    )
                    // <SizeCard
                    //   obj={i}
                    //   onClick={() => {}}
                    //   title={selectedTab}
                    //   isSelected={i.enable}
                    //   // isSelected={
                    //   //   selected[selectedTab] &&
                    //   //   selected[selectedTab].find((size) => size.value === i.value)
                    //   // }
                    // />
                  )}
              </Stack>
              <Stack direction="row" flexWrap={"wrap"} sx={{ mt: 2 }}>
                {allSizeMasters[selectedTab] &&
                  allSizeMasters[selectedTab]?.size_guides
                    ?.find((i) => selectedSizeGuideForTab === i?.id)
                    ?.sizes.map((i) => (
                      <SizeCard
                        obj={i}
                        onClick={() => {}}
                        title={selectedTab}
                        isSelected={true}
                        // isSelected={
                        //   selected[selectedTab] &&
                        //   selected[selectedTab].find((size) => size.value === i.value)
                        // }
                      />
                    ))}
              </Stack>
              <Box>
                {/* {allSizeMasters[selectedTab]&&isArrayWithValues(allSizeMasters[selectedTab].sizes) &&
                allSizeMasters[selectedTab].sizes.map((obj) => {
                  return (
                  );
                })} */}
                {true && (
                  <>
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent={"space-between"}
                      mt={3}
                    >
                      <Stack direction={"row"} alignItems="center">
                        <Typography variant="h5" fontSize="15px">
                          Size Difference
                        </Typography>
                        <Tooltip title="Add difference to increase/decrease on size change">
                          <IconButton>
                            <Info style={{ height: "16px", width: "16px" }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      {/* <ButtonGroup
                          aria-label="outlined button group"
                          size="small"
                        >
                          <Button
                            sx={{ fontSize: "10px" }}
                            variant={
                              allSizeMasters[selectedTab].difference_by ===
                              "amount"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              // onEditAllSizeMasters(selectedTab, {
                              //   difference_by: "amount",
                              // });
                              onAllSizeDifferenceValueChange(
                                selectedTab,
                                "difference_by",
                                "amount"
                              );
                            }}
                          >
                            By Amount
                          </Button>
                          <Button
                            sx={{ fontSize: "10px" }}
                            variant={
                              allSizeMasters[selectedTab].difference_by ===
                              "weight"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              // onEditAllSizeMasters(selectedTab, {
                              //   difference_by: "weight",
                              // });
                              onAllSizeDifferenceValueChange(
                                selectedTab,
                                "difference_by",
                                "weight"
                              );
                            }}
                          >
                            By Weight
                          </Button>
                        </ButtonGroup> */}
                    </Stack>
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent={"space-between"}
                      mt={3}
                      spacing={3}
                    >
                      <TextField
                        fullWidth
                        disabled
                        value={
                          allSizeMasters[selectedTab]?.size_guides?.find(
                            (i) => selectedSizeGuideForTab === i?.id
                          )?.decrease_difference
                        }
                        // onChange={(e) => {
                        //   onAllSizeDifferenceValueChange(
                        //     selectedTab,
                        //     "decrease_difference",
                        //     e.target.value
                        //   );
                        //   // onEditAllSizeMasters(selectedTab, {
                        //   //   decrease_difference: e.target.value,
                        //   // });
                        // }}
                        label={`Enter ${allSizeMasters[selectedTab]?.difference_by}`}
                        helperText={
                          decreaseErrors[selectedTab] ||
                          `Decreasing ${allSizeMasters[selectedTab]?.difference_by} by size`
                        }
                        error={Boolean(decreaseErrors[selectedTab])}
                        InputProps={
                          allSizeMasters[selectedTab]?.difference_by ===
                          "amount"
                            ? {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {productSettings.default_currency &&
                                      currencySymbols[
                                        productSettings.default_currency
                                      ]}
                                  </InputAdornment>
                                ),
                              }
                            : {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    /gram
                                  </InputAdornment>
                                ),
                              }
                        }
                      />
                      <TextField
                        fullWidth
                        disabled
                        value={
                          allSizeMasters[selectedTab]?.size_guides?.find(
                            (i) => selectedSizeGuideForTab === i?.id
                          )?.increase_difference
                        }
                        // onChange={(e) => {
                        //   onAllSizeDifferenceValueChange(
                        //     selectedTab,
                        //     "increase_difference",
                        //     e.target.value
                        //   );
                        //   // onEditAllSizeMasters(selectedTab, {
                        //   //   increase_difference: e.target.value,
                        //   // });
                        // }}
                        label={`Enter ${allSizeMasters[selectedTab]?.difference_by}`}
                        helperText={
                          increaseErrors[selectedTab] ||
                          // helperText.increase_difference
                          `Increasing ${allSizeMasters[selectedTab]?.difference_by} by size`
                        }
                        error={Boolean(increaseErrors[selectedTab])}
                        InputProps={
                          allSizeMasters[selectedTab]?.difference_by ===
                          "amount"
                            ? {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {productSettings.default_currency &&
                                      currencySymbols[
                                        productSettings.default_currency
                                      ]}
                                  </InputAdornment>
                                ),
                              }
                            : {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    /gram
                                  </InputAdornment>
                                ),
                              }
                        }
                      />
                    </Stack>
                  </>
                )}
              </Box>
            </CardContent>
          </Card>
        )}

        <Dialog
          fullWidth
          maxWidth="sm"
          open={viewAddForm}
          onClose={() => {
            setAddViewForm(false);
          }}
          sx={dialogStyle}
          {...globalPopupStyle}
        >
          <DialogTitle>
            {addSizeMasterForm.id
              ? "Update size master"
              : "Add New Category Size"}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  options={filteredCategories}
                  value={addSizeMasterForm.label}
                  onChange={(e, newValue) => onLabelChange(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Category" />
                  )}
                  sx={{ mt: 1.5 }}
                />
              </Grid>
              <Grid item xs={12}>
                <HorizontalStack spacing={2}>
                  {isArrayWithValues(addSizeMasterForm?.size_guides) &&
                    addSizeMasterForm?.size_guides?.map((i) => {
                      return (
                        <CustomChip
                          onDelete={() => onSizeGuideDeleteFromAddForm(i?.id)}
                          label={formatServerValue(i?.size_guide)}
                          onClick={() => onSizeGuideSelectTab(i?.id)}
                          type={
                            addSizeMasterForm?.selected_size_guide !== i?.id
                              ? "outlined"
                              : "default"
                          }
                          showBorder
                        />
                      );
                    })}
                  {isArrayWithValues(addSizeMasterForm?.size_guides) && (
                    <AddSizeGuideButtonChip
                      size_guides={allSizeGuides?.filter(
                        (i) =>
                          !addSizeMasterForm?.size_guides?.find(
                            (j) => j?.size_guide === i?.size_guide
                          )
                      )}
                      onSizeGuideSelect={onSizeGuideSelect}
                    />
                  )}
                </HorizontalStack>
                {/* <HorizontalStack>
                  <CustomChip label={"Yes"} />
                </HorizontalStack> */}
              </Grid>
              {!isArrayWithValues(addSizeMasterForm?.size_guides) && (
                <Grid item xs={12}>
                  <EmptyState
                    icon={<NotFound />}
                    subtitle={"Please select size guide"}
                    button={
                      <AddSizeGuideButtonChip
                        size_guides={allSizeGuides?.filter(
                          (i) =>
                            !addSizeMasterForm?.size_guides?.find(
                              (j) => j?.size_guide === i?.size_guide
                            )
                        )}
                        onSizeGuideSelect={onSizeGuideSelect}
                      />
                    }
                  />
                </Grid>
              )}

              {/* <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Size Guide
                  </InputLabel>
                  <Select
                    label="Select Size Guide"
                    fullWidth
                    value={addSizeMasterForm.size_guide}
                    onChange={(e) => {
                      setAddSizeMasterForm((pre) => ({
                        ...pre,
                        size_guide: e.target.value,
                      }));
                    }}
                  >
                    {allSizeGuides.map((i) => (
                      <MenuItem value={i.size_guide}>{i.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allSizeGuides}
                value={addSizeMasterForm.size_guide}
                // getOptionLabel={(option) => option.label}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  setAddSizeMasterForm((pre) => ({
                    ...pre,
                    size_guide: newValue.size_guide,
                  }));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Size Guide" />
                )}
              /> */}
              {isArrayWithValues(addSizeMasterForm?.size_guides) && (
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    multiple
                    freeSolo
                    value={
                      addSizeMasterForm.size_guides?.find(
                        (i) => i?.id === addSizeMasterForm?.selected_size_guide
                      )?.sizes || []
                    }
                    // options={formSizes}
                    options={
                      global_size_masters_guide?.[
                        addSizeMasterForm.size_guides?.find(
                          (i) =>
                            i?.id === addSizeMasterForm?.selected_size_guide
                        )?.size_guide
                      ]?.options || []
                    }
                    onChange={(e, newValue) => {
                      console.log(newValue);
                      onSizeGuideValueSelect(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Sizes" />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            {isArrayWithValues(addSizeMasterForm?.size_guides) && (
              <>
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  mt={3}
                >
                  <Stack direction={"row"} alignItems="center">
                    <Typography variant="h5" fontSize="15px">
                      Size Difference
                    </Typography>
                    <Tooltip title="Add difference to increase/decrease price on size change">
                      <IconButton>
                        <Info style={{ height: "16px", width: "16px" }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>

                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  mt={3}
                  spacing={3}
                >
                  <TextField
                    fullWidth
                    // value={addSizeMasterForm.decrease_difference}
                    value={
                      addSizeMasterForm.size_guides?.find(
                        (i) => i?.id === addSizeMasterForm?.selected_size_guide
                      )?.decrease_difference || []
                    }
                    onChange={(e) => {
                      onDifferenceValueChange(
                        "decrease_difference",
                        e.target.value
                      );
                    }}
                    label={`Enter ${addSizeMasterForm.difference_by}`}
                    helperText={
                      decreaseError ||
                      `Decreasing ${addSizeMasterForm.difference_by} by size`
                    }
                    error={Boolean(decreaseError)}
                    InputProps={
                      addSizeMasterForm.difference_by === "amount"
                        ? {
                            startAdornment: (
                              <InputAdornment position="start">
                                {productSettings.default_currency &&
                                  currencySymbols[
                                    productSettings.default_currency
                                  ]}
                              </InputAdornment>
                            ),
                          }
                        : {
                            endAdornment: (
                              <InputAdornment position="end">
                                /gram
                              </InputAdornment>
                            ),
                          }
                    }
                  />
                  <TextField
                    fullWidth
                    // value={addSizeMasterForm.increase_difference}
                    value={
                      addSizeMasterForm.size_guides?.find(
                        (i) => i?.id === addSizeMasterForm?.selected_size_guide
                      )?.increase_difference || []
                    }
                    onChange={(e) => {
                      onDifferenceValueChange(
                        "increase_difference",
                        e.target.value
                      );
                    }}
                    label={`Enter ${addSizeMasterForm.difference_by}`}
                    helperText={
                      increaseError ||
                      `Increasing ${addSizeMasterForm.difference_by} by size`
                    }
                    error={Boolean(increaseError)}
                    InputProps={
                      addSizeMasterForm.difference_by === "amount"
                        ? {
                            startAdornment: (
                              <InputAdornment position="start">
                                {productSettings.default_currency &&
                                  currencySymbols[
                                    productSettings.default_currency
                                  ]}
                              </InputAdornment>
                            ),
                          }
                        : {
                            endAdornment: (
                              <InputAdornment position="end">
                                /gram
                              </InputAdornment>
                            ),
                          }
                    }
                  />
                </Stack>
              </>
            )}

            {/* <ButtonGroup aria-label="outlined button group" size="small">
                <Button
                  sx={{ fontSize: "10px" }}
                  variant={
                    addSizeMasterForm.difference_by === "amount"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    onEditAddForm({ difference_by: "amount" });
                  }}
                >
                  By Amount
                </Button>
                <Button
                  sx={{ fontSize: "10px" }}
                  variant={
                    addSizeMasterForm.difference_by === "weight"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    onEditAddForm({ difference_by: "weight" });
                  }}
                >
                  By Weight
                </Button>
              </ButtonGroup> */}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setAddViewForm(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={fetchingMastersSetting || creatingSizeMaster}
              onClick={onAddSizeMaters}
              disabled={
                // Boolean(decreaseError) ||
                // Boolean(increaseError) ||
                // !addSizeMasterForm.decrease_difference ||
                // !addSizeMasterForm.increase_difference ||
                !isArrayWithValues(addSizeMasterForm.label) ||
                // !addSizeMasterForm.size_guide ||
                !isArrayWithValues(addSizeMasterForm.size_guides)
              }
            >
              Save
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Card>
      <CustomConfirmationDialog ref={confirmationRef} {...confirmationProps} />
    </Box>
  );
};

export default SizeMasters;

function AddSizeGuideButtonChip({ size_guides, onSizeGuideSelect }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <HorizontalStack
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        spacing={2}
        sx={{
          border: "1px dotted #000",
          px: 2,
          py: 1,
          borderRadius: "13px",
          cursor: "pointer",
        }}
      >
        <PrimaryLabelExtraSmall>Add Size Guide</PrimaryLabelExtraSmall>
        <Add style={{ height: "13px", width: "13px" }} />
      </HorizontalStack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {size_guides?.map((i) => (
          <MenuItem
            onClick={() => {
              onSizeGuideSelect({ size_guide: i?.size_guide });
              handleClose();
            }}
          >
            {i?.label}
          </MenuItem>
        ))}
        {/* <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </div>
  );
}

function AddSizeGuideButtonChip2() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {/* <Button  variant="contained" >
        Open Popover
      </Button> */}
      <HorizontalStack
        aria-describedby={id}
        onClick={handleClick}
        spacing={2}
        sx={{
          border: "1px dotted #000",
          px: 2,
          py: 1,
          borderRadius: "6px",
          cursor: "pointer",
        }}
      >
        <PrimaryLabelExtraSmall>Add Size Guide</PrimaryLabelExtraSmall>
        <Add style={{ height: "13px", width: "13px" }} />
      </HorizontalStack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
      </Popover>
    </div>
  );
}

const SizeCard = ({ obj, isSelected, onClick, isMobile, title }) => {
  const padding = isMobile ? "9px" : "12px";
  return (
    <Card
      sx={{
        border: isSelected ? "1px solid" : `0.5px solid`,
        color: !isSelected ? "grey.500" : "priamry.main",
        borderColor: !isSelected ? "grey.500" : "priamry.main",
        margin: "6px 6px",
        overflow: "visible",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => onClick(title, obj)}
    >
      <CardContent
        sx={{
          textAlign: "center",
          padding,
          "&:last-child": { paddingBottom: padding },
          //   display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            minWidth: "37px",
            // height: "17px",
            borderRadius: "14px",
            textAlign: "center",
            // background: colorsBackground[obj.value],
            // background: "linear-gradient(to right, #E1C16E,#edd595 )",
          }}
        />
        {isSelected ? (
          <CheckCircle
            style={{
              position: "absolute",
              right: "-4px",
              top: "-4px",
              width: "16px",
              height: "16px",
            }}
            width={9}
            height={9}
          />
        ) : null}
        <Typography variant={isMobile ? "body2" : "h6"}>{obj.label}</Typography>
      </CardContent>
    </Card>
  );
};
