import { useState, useEffect } from "react";
import {
  Typography,
  Card,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Autocomplete,
  Chip,
} from "@mui/material";
import SwapCallsSharpIcon from "@mui/icons-material/SwapCallsSharp";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { goToStepStyles } from "./styles/goToStepStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import BlockOptions from "./components/BlockOptions";
import { checkTitleExists } from "../helper/checkTitleExists";
import { fetchUsers } from "../sidebar-assign-team/helper/fetchUsers";
import { isArrayWithValues } from "jwero-javascript-utils";

const styles = goToStepStyles;

const SidebarCreateTask = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [block, setBlock] = useState(null);
  const [form, setForm] = useState({
    title: "",
    description: "",
    due_after: "",
    assign_to: [],
  });
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [data, setData] = useState(null);
  const [titleCheckFlag, setTitleCheckFlag] = useState(false);
  const [users, setUsers] = useState([]);
  const titleErrorMessage =
    title === "" ? "Block title is required" : "Title already exists!";

  useEffect(() => {
    const currentNode = nodes?.find((it) => it.id === userId);

    if (currentNode) {
      const node = currentNode?.fieldData;

      if (node?.title) {
        setTitle(node?.title);
      }
      if (node?.task)
        editForm({
          ...(node?.task || {}),
        });
    }
  }, [userId, nodes]);
  useEffect(() => {
    getUsersData();
  }, []);

  const getUsersData = async () => {
    const res = await fetchUsers();
    if (isArrayWithValues(res))
      setUsers(
        res?.map((i) => ({
          first_name: i.first_name,
          last_name: i.last_name,
          name: `${i?.first_name || ""} ${i?.last_name || ""}`?.trim(),
          label: `${i?.first_name || ""} ${i?.last_name || ""}`?.trim(),
          value: i?.id,
          is_admin: i?.is_admin,
        }))
      );
  };

  const handleClickPopover = (e) => {
    setOptionsAnchorEl(e.currentTarget);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const editForm = (payload) => setForm((state) => ({ ...state, ...payload }));

  const closeSidebar = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { openCreateTaskSidebar: false },
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title == "") {
      setTitleCheckFlag(true);
      setTitleError(true);
    }

    if (title) {
      let task = { ...form };
      if (isArrayWithValues(task?.assign_to)) {
        let array = task?.assign_to
          ?.map((i) => {
            let user = users?.find((j) => j?.value === i?.value);
            return user ? { id: user?.value, ...user } : false;
          })
          ?.filter(Boolean);
        task.assign_to = array;
      }
      const submittedUserData = {
        title,
        // redirect: data,
        // optionValue: block,
        task,
      };
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openCreateTaskSidebar: false },
      });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  const handleTitle = (e) => {
    const value = e.target.value;
    setTitle(e.target.value);
    if (checkTitleExists({ nodes, title: value })) {
      setTitleCheckFlag(true);
      return;
    }
    setTitleCheckFlag(false);
  };

  return (
    <Card style={styles.customCard}>
      <form>
        <Box sx={styles.topCard}>
          <Box sx={styles.innerCard}>
            <Box sx={styles.userHeader}>
              <AddTaskIcon />
              <Typography variant="span" style={styles.headerTitle}>
                Create Task
              </Typography>
            </Box>
            <Box sx={styles.headerIconsMainDiv}>
              <IconButton sx={styles.headerIconsDiv} onClick={handleClose}>
                <CloseIcon sx={styles.headerIcons} />
              </IconButton>
              <IconButton sx={styles.headerIconsDiv} onClick={handleCheck}>
                <CheckIcon sx={styles.headerIcons} />
              </IconButton>
            </Box>
          </Box>

          <TextField
            placeholder="Type block title"
            size="small"
            style={styles.titleTextField}
            onChange={handleTitle}
            error={titleError}
            value={title}
            required
          />
          {titleCheckFlag && (
            <Typography
              sx={{
                fontSize: "14px",
                color: "#E63946",
                fontWeight: 500,
                fontFamily: "Inter",
                position: "relative",
                bottom: "10px",
                left: "3px",
              }}
            >
              {titleErrorMessage}
            </Typography>
          )}
        </Box>
        <Box sx={styles.responseSection}>
          <Box sx={styles.responseDiv}>
            <Typography variant="span" style={styles.labels}>
              Task Title
            </Typography>
            <TextField
              size="small"
              sx={styles.optionsInput}
              onChange={(e) => editForm({ title: e.target.value })}
              placeholder="Enter task title"
              value={form.title}
              fullWidth
              // disabled={true}
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <SearchOutlinedIcon />
              //     </InputAdornment>
              //   ),
              // }}
            />
            <Typography
              variant="span"
              style={{ ...styles.labels, marginTop: "10px" }}
            >
              Task Description
            </Typography>
            <TextField
              size="small"
              sx={styles.optionsInput}
              onChange={(e) => editForm({ description: e.target.value })}
              placeholder="Enter task description"
              value={form.description}
              fullWidth
              // disabled={true}
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <SearchOutlinedIcon />
              //     </InputAdornment>
              //   ),
              // }}
            />
            <Typography
              variant="span"
              style={{ ...styles.labels, marginTop: "10px" }}
            >
              Due after
            </Typography>
            <TextField
              size="small"
              sx={styles.optionsInput}
              onChange={(e) => editForm({ due_after: e.target.value })}
              placeholder="Enter due after"
              value={form.due_after}
              fullWidth
              type="number"
              // disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Days</InputAdornment>
                ),
              }}
            />
            <Typography
              variant="span"
              style={{ ...styles.labels, marginTop: "10px" }}
            >
              Assign To
            </Typography>
            <Autocomplete
              multiple
              placeholder="Select..."
              id="tags-filled"
              options={isArrayWithValues(users) ? users : []}
              value={form.assign_to || []}
              onChange={(e, newValue) => editForm({ assign_to: newValue })}
              renderTags={(value, getTagProps) =>
                value.map((selectedTeam, index) => (
                  <Chip
                    key={index}
                    sx={{ backgroundColor: "#dcdcdc", fontFamily: "Inter" }}
                    variant="outlined"
                    label={selectedTeam?.label}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select..."
                  variant="outlined"
                  sx={{
                    backgroundColor: "#fff",
                  }}
                />
              )}
            />
          </Box>
        </Box>
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </form>
    </Card>
  );
};

export default SidebarCreateTask;
