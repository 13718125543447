import {
  Add,
  ArrowBack,
  ArrowBackIosNew,
  ArrowCircleRightOutlined,
  ArrowDropDown,
  ArrowForwardIos,
  ChevronRight,
  // CheckBox,
  // CheckBoxOutlineBlank,
  Close,
  ErrorOutline,
  Favorite,
  FavoriteBorder,
  FilterAlt,
  Inventory2Outlined,
  Person,
  RemoveRedEyeOutlined,
  Search,
  ShareOutlined,
  ThumbUpOutlined,
} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  // Checkbox,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  InputAdornment,
  Link,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Select,
  Skeleton,
  Slide,
  Stack,
  Tab,
  Table,
  TableContainer,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  Pagination as MuiPagination,
  DialogContentText,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputLabel,
  Avatar,
  Rating,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { createRef } from "react";
import { CornerDownLeft } from "react-feather";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  addSuppliersettings,
  dialogStyle,
  filterCondition,
  formatServerValue,
  getAdminName,
  getAxiosError,
  getId,
  getInitials,
  getProductPrice,
  getRandomString,
  getStoreId,
  getSupplierInfo,
  getSupplierProductBatch,
  getToken,
  getWebsite,
  goldName,
  isArray,
  isArrayWithValues,
  isObjWithValues,
  isProductIncomplete,
  primaryLabel,
  reduceLargeText,
  secondaryLabel,
  TabPanel,
  unformatServerValue,
  updateSupplierProductBatch,
  validateNumber,
} from "../../helper";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { addUserActivity } from "../../redux/actions/hubActions";
import {
  fetchAllProducts,
  fetchAdminTaxonomies,
  SET_PRODUCT_SETTINGS,
  getDesignBankProduct,
  onSelectDesignBankProduct,
} from "../../redux/actions/productActions";
import {
  addDiamondGroup,
  getAllTaxes,
  updateMasterPricings,
} from "../../redux/actions/settingActions";
import {
  getAllBusinesses,
  getDesignBankActivities,
  getSuppliers,
  likeProduct,
} from "../../redux/actions/supplierActions";
import {
  DesignBankIllustration,
  Readystockoutline,
  UnderConstruction,
} from "../../Svgs";
import {
  getTotalDiamondPieces,
  getTotalDiamondWeight,
  getTotalGemstonePieces,
  getTotalGemstoneWeight,
  getTotalMetalGross,
  getTotalMetalNet,
} from "../../utils/productFunctions";
import { allDiamondWeights } from "../pricing/DiamondGroup";
import { currencySymbols } from "../pricing/Gold";
import {
  getAllLabourMasterPricing,
  getDiamondRate,
  getDiamondSizes,
  getGemstoneRate,
} from "../products/AddProduct";
import { NoBorderSelect, SliderComponent } from "../products/AllProducts";
import CompanyCard from "./components/CompanyCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { sidebarExtraWidth } from "../../App";
import { FormLabel } from "../integrations/Integrations";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const allSortBy = [
  { label: "Price: low to high", value: "price_low_to_high" },
  { label: "Price: high to low", value: "price_high_to_low" },
  { label: "Date", value: "date" },
  { label: "Popularity", value: "popularity" },
  // { label: "Avarage rating", value: "rating" },
];
const getDiamondSieve = (weight, pieces, selectedShape, allDiamondGroups) => {
  allDiamondGroups = allDiamondGroups || {};
  let netWeight = Number(Number(weight) / Number(pieces));
  let diamondGroup = Object.values(allDiamondGroups);
  // console.log(diamondGroup);
  let obj = diamondGroup.find((i) => {
    if (i.shape === selectedShape) {
    }
    return (
      i.shape === selectedShape &&
      netWeight >= Number(i.from) &&
      netWeight <= Number(i.to)
    );
  });
  if (!obj)
    obj = diamondGroup.find((i) => {
      let netWeightFixed2 = Number(netWeight).toFixed(2);
      return (
        i.shape === selectedShape &&
        netWeightFixed2 >= Number(i.from) &&
        netWeightFixed2 <= Number(i.to)
      );
    });
  return obj?.id;
};

const verifyProduct = (
  product,
  masterPricing,
  businessSettings,
  allDiamondGroups
) => {
  let returnObj = {
    labour: {
      valid: true,
      value: "labour",
    },
    diamond: {
      valid: true,
      value: "diamond",
    },
    gemstone: {
      value: "gemstone",
      valid: true,
    },
  };
  let { collections, categories, subcategory } = product || {};
  try {
    let {
      labour_pricing,
      diamond_pricing: diamondPricings,
      gemstone_pricing: gemstonePricing,
    } = masterPricing || {};
    let { default_currency: defaultCurrency } = businessSettings || {};
    // console.log();

    // console.log(product?.labourType);
    if (
      product?.labourType === "master" &&
      Array.isArray(collections) &&
      Array.isArray(categories) &&
      isObjWithValues(labour_pricing)
    ) {
      let temp_collections = collections.map((i) => i.name);
      let temp_categories = categories.map((i) => i.name);
      let temp_subcategories = isArrayWithValues(subcategory)
        ? subcategory.map((i) => i.name)
        : [];
      let array = getAllLabourMasterPricing(
        labour_pricing[defaultCurrency],
        temp_collections?.map((str) => formatServerValue(str)),
        temp_categories?.map((str) => formatServerValue(str)),
        temp_subcategories?.map((str) => formatServerValue(str))
      );
      if (!isArrayWithValues(array)) {
        returnObj.labour.valid = false;
        returnObj.labour.combination = [
          {
            collections: collections || [],
            categories: categories || [],
            subcategory: subcategory || [],
          },
        ];
        // console.log({ collections, categories, subcategory });
        returnObj.labour.collections = collections || [];
        returnObj.labour.categories = categories || [];
        returnObj.labour.subcategory = subcategory || [];
        // returnObj.labour.combination = [
        //   {
        //     collections: collections.map((obj) => obj?.slug),
        //     categories: categories.map((obj) => obj?.slug),
        //     subcategory: subcategory.map((obj) => obj?.slug),
        //   },
        // ];
      }
    }
    if (
      product?.diamond_from === "master" &&
      isObjWithValues(product?.diamond)
    ) {
      // let { diamond } = product;
      // let diamondValid = true;
      let diamond = product?.diamond || {};
      diamond = Object.values(diamond);

      let totalWeight = 0;
      let totalPieces = 0;
      if (!isArrayWithValues(diamond)) return;
      diamond = diamond.map((i) => {
        let {
          diamond_quality,
          diamond_shape,
          diamond_sieve,
          diamond_cut,
          diamond_weight,
          diamond_pieces,
          diamond_type,
        } = i;
        totalWeight += Number(diamond_weight);
        totalPieces += Number(diamond_pieces);
        diamond_sieve = getDiamondSieve(
          Number(diamond_weight),
          Number(diamond_pieces),
          diamond_shape,
          allDiamondGroups
        );
        // console.log(diamond_sieve, product?.name);
        // console.log(diamondPricings[defaultCurrency],
        //   diamond_type,
        //   diamond_quality,
        //   diamond_shape,
        //   diamond_sieve,
        //   diamond_cut);
        let rate = getDiamondRate(
          diamondPricings[defaultCurrency],
          diamond_type,
          diamond_quality,
          diamond_shape,
          diamond_sieve,
          diamond_cut
        );
        if (!!rate) return {};
        else {
          // diamondValid = false
          // delete i.diamond_weight
          // delete i.diamond_pieces
          returnObj.diamond.valid = false;
          return i;
        }
      });
      returnObj.diamond.combination = diamond;
    }
    if (
      // product?.colorstone_from === "master" &&
      isObjWithValues(product?.colorstone_details)
    ) {
      let { colorstone_details } = product || {};
      colorstone_details = Object.values(colorstone_details) || [];
      let colorStoneCombination = colorstone_details.map((obj) => {
        let {
          colorstone_quality,
          colorstone_type,
          colorstone_shape,
          colorstone_size,
        } = obj || {};
        let rate = getGemstoneRate(
          gemstonePricing[defaultCurrency],
          colorstone_quality,
          colorstone_type,
          colorstone_shape,
          colorstone_size
        );
        if (rate) return { rate: rate };
        else {
          returnObj.gemstone.valid = false;
          return { ...obj };
        }
      });
      returnObj.gemstone.combination = colorStoneCombination;
    }
  } catch (error) {
    console.log(error);
  }

  return returnObj;
};

function DesignbankProducts({ isUnderContruction }) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const [products, setProducts] = useState([]);
  const [params, setParams] = useState({
    search: "",
    page: 1,
    per_page: 100,
  });
  const [productDetail, setProductDetail] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [allSuppliers, setAllSupliers] = useState([]);
  // const [allProducts, setAllProducts] = useState([]);
  const [filterView, setFilterView] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [paramFilters, setParamFilters] = useState({});
  const [showAddDetailsPopup, setShowAddDetailsPopup] = useState({});
  const [productMasterAdd, setProductMasterAdd] = useState({});
  const [creating, setCreating] = useState(false);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const reduxSupplier = useSelector((state) => state.supplier.allSuppliers);
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const allTaxes = useSelector((state) => state.settings.allTaxes) || [];
  const reduxFetchingSuppliers = useSelector(
    (state) => state.supplier.fetchingSuppliers
  );
  const reduxAllProducts = useSelector(
    (state) => state.product?.designbankProducts
  );
  const allProducts = useSelector(
    (state) => state.supplier.filterObjectProducts
  );
  const totalProductsPulledBySuppliers = useSelector(
    (state) =>
      state.supplier.designbankProductsAnalytics?.totalProductsPulledBySuppliers
  );
  const reduxFetchingDesignbankProduct = useSelector(
    (state) => state.product?.fetchingDesignbankProduct
  );
  const reduxDesignbankProductsLength = useSelector(
    (state) => state.product?.designbankProductsLength
  );
  const reduxdesignbankfilters =
    useSelector((state) => state.product?.designbankfilters) || {};
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  const allDiamondGroups = useSelector(
    (state) => state.settings.allDiamondGroups
  );
  const designBankActivities = useSelector(
    (state) => state.supplier.designBankActivities
  );
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (!isArrayWithValues(allTaxes)) dispatch(getAllTaxes());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isObjWithValues(reduxdesignbankfilters)) {
      setParamFilters(reduxdesignbankfilters);
    }
  }, [reduxdesignbankfilters]);

  useEffect(() => {
    if (!isArrayWithValues(reduxSupplier)) dispatch(getAllBusinesses());
    if (!isObjWithValues(designBankActivities))
      dispatch(getDesignBankActivities());
    if (!isArrayWithValues(reduxSupplier)) dispatch(getSuppliers());
  }, []);
  // useEffect(() => {
  //   if (!isArrayWithValues(allSuppliers)) dispatch(getAllBusinesses());
  //   if (!isObjWithValues(designBankActivities)) dispatch(getDesignBankActivities())
  // }, []);
  // const filterCondition = (oldArray, value) => {
  //   if (!oldArray.find((obj) => obj?.value === value) && value) return true;
  //   else return false;
  // };
  // useEffect(() => {
  //   if (!isArrayWithValues(reduxSupplier)) return;
  //   setAllSupliers(reduxSupplier);
  //   let filteredSupplier = reduxSupplier

  //   let filters = {
  //     suppliers: [],
  //     collections: [],
  //     categories: [],
  //     gold_kt: [],
  //     gold_gross: [],
  //     diamond_quality: [],
  //   };

  //   let products = [];
  //   for (const obj of filteredSupplier) {
  //     for (const product of Object.values(obj?.filters || [])) {
  //       if (filterCondition(filters.collections, product?.collections))
  //         filters.collections = [
  //           ...filters.collections,
  //           {
  //             value: product?.collections,
  //             label: formatServerValue(product?.collections),
  //           },
  //         ];
  //       if (filterCondition(filters.categories, product?.categories))
  //         filters.categories = [
  //           ...filters.categories,
  //           {
  //             value: product?.categories,
  //             label: formatServerValue(product?.categories),
  //           },
  //         ];
  //       if (filterCondition(filters.gold_kt, product?.gold_kt))
  //         filters.gold_kt = [
  //           ...filters.gold_kt,
  //           {
  //             value: product?.gold_kt,
  //             label: goldName[product?.gold_kt],
  //           },
  //         ];
  //       if (product?.diamond_quality) {
  //         // console.log(filters?.diamond_quality);
  //         let diamondArr = product?.diamond_quality?.split(",") || [];
  //         for (let index = 0; index < diamondArr.length; index++) {
  //           const element = diamondArr[index];
  //           if (filterCondition(filters.diamond_quality, element))
  //             filters.diamond_quality = [
  //               ...filters.diamond_quality,
  //               {
  //                 value: element,
  //                 label: formatServerValue(element),
  //               },
  //             ];
  //         }
  //       }
  //       products = [
  //         ...products,
  //         { ...product, supplier_id: obj?.id, suppliers: obj?.name },
  //       ];
  //     }
  //     filters.suppliers.push({ label: obj?.name, value: obj?.name });
  //   }
  //   console.log(filters, "<,,filters  ");
  //   // return
  //   setFilterObj(filters);
  //   setAllProducts(products);
  // }, [reduxSupplier]);
  useEffect(() => {
    if (isArrayWithValues(allProducts) && !isArrayWithValues(reduxAllProducts))
      dispatch(
        getDesignBankProduct({
          ...params,
          products: allProducts,
        })
      );
  }, [allProducts]);
  // console.log(reduxAllProducts);
  // console.log(reduxSupplier);
  useEffect(() => {
    if (Array.isArray(reduxAllProducts)) {
      let meta_keys = [
        "retailer_likes",
        "labourType",
        "colorstone_from",
        "diamond_from",
        "colorstone_from",
        "diamond",
        "colorstone_details",
        "retailer_pull",
        "gold_kt",
      ];
      let productArr = [];
      for (let index = 0; index < reduxAllProducts.length; index++) {
        let productObj = reduxAllProducts[index];
        let { meta_data } = productObj || {};
        for (let index = 0; index < meta_keys.length; index++) {
          const element = meta_keys[index];
          let obj = meta_data.find((obj) => obj?.key === element);
          if (isObjWithValues(obj)) {
            productObj = { ...productObj, [obj?.key]: obj?.value };
          }
        }

        productArr.push(productObj);
      }
      setProducts([...productArr]);
    }
  }, [reduxAllProducts]);

  const closeProductDetail = () => {
    setProductDetail({});
  };

  // const onAddInventory = async () => {
  //   let website = getWebsite();
  //   let token = getToken();
  //   let storeId = getStoreId();
  //   if (!website || !token) return;

  //   // let
  //   setCreating(true);
  //   let removeKeys = [
  //     "date_on_sale_from",
  //     "date_on_sale_from_gmt",
  //     "date_on_sale_to",
  //     "date_on_sale_to_gmt",
  //     "id",
  //     "_links",
  //     "retailer_pull",
  //   ];
  //   let supplierObj = {};
  //   let createProdutsArr = [];
  //   let userActivitieArr = [];
  //   //making  batch for create prodcut and object for update supplier
  //   for (let index = 0; index < selectedProducts.length; index++) {
  //     const product = selectedProducts[index];
  //     let supplier = getSupplierInfo(reduxSupplier, product?.supplier_id);
  //     let productObj = { ...product };
  //     let oldArr = supplierObj[product?.supplier_id] || [];
  //     supplierObj[product?.supplier_id] = [...oldArr, product];
  //     productObj.meta_data.push({
  //       key: "pull_source",
  //       value: `${productObj?.supplier_id}_${productObj?.id}`,
  //     });
  //     setProducts((pre) =>
  //       pre.map((obj) =>
  //         obj.id === product?.id ? { ...obj, retailer_pull: [storeId] } : obj
  //       )
  //     );
  //     userActivitieArr.push({
  //       product_name: product?.name,
  //       supplier_id: product?.supplier_id,
  //       supplier_name: supplier?.name,
  //     });
  //     productObj.images = productObj.images.map((obj) => {
  //       delete obj.id;
  //       return obj;
  //     });
  //     for (let keysindex = 0; keysindex < removeKeys.length; keysindex++) {
  //       const key = removeKeys[keysindex];
  //       delete productObj[key];
  //     }
  //     createProdutsArr.push(productObj);
  //   }
  //   //save retailer basic info in supplier products - (in supplier)
  //   for await (const supplier_id of Object.keys(supplierObj)) {
  //     //geting product
  //     let supplier = getSupplierInfo(reduxSupplier, supplier_id);
  //     let getproducts = await getSupplierProductBatch(
  //       supplier,
  //       supplierObj[supplier_id].map((obj) => obj?.id)
  //     );
  //     console.log(getproducts);
  //     let liveProduct = getproducts?.data || [];
  //     //find and upadate key
  //     let updateDataMatadata = liveProduct.map((pro) => {
  //       let { meta_data } = pro || {};
  //       let retailer_pull = meta_data.find(
  //         (obj) => obj.key === "retailer_pull"
  //       );
  //       if (isObjWithValues(retailer_pull)) {
  //         retailer_pull?.value?.push(storeId);
  //         return { id: pro?.id, meta_data: [retailer_pull] };
  //       } else {
  //         return {
  //           id: pro?.id,
  //           meta_data: [{ key: "retailer_pull", value: [storeId] }],
  //         };
  //       }
  //     });
  //     // console.log(updateDataMatadata);
  //     let updateSupplierProducts = await updateSupplierProductBatch(
  //       supplier,
  //       updateDataMatadata
  //     );
  //     // if()

  //     let updateSupplierActivity = await addSuppliersettings(supplier, {
  //       event: "pull_product",
  //       products: liveProduct.map((obj) => obj.id),
  //     });
  //     console.log(updateSupplierProducts, updateSupplierActivity);
  //   }
  //   console.log(supplierObj);
  //   console.log(createProdutsArr);
  //   //creating product

  //   try {
  //     let res = await axios({
  //       url: `${website}/wp-json/wc/v3/products/batch`,
  //       method: "POST",
  //       headers: {
  //         Authorization: `Basic ${token}`,
  //       },
  //       data: {
  //         create: createProdutsArr,
  //       },
  //     });
  //     console.log(res);
  //     if (isArrayWithValues(res?.data?.create)) {
  //       dispatch(
  //         setGlobalToast({
  //           show: true,
  //           message: `Pulled products  ${res?.data?.create
  //             .filter((obj) => obj.name)
  //             .map((obj) => obj?.name)
  //             .join(",")}  successfully`,
  //           severity: "success",
  //         })
  //       );
  //     }
  //   } catch (error) {
  //     console.log(getAxiosError(error));
  //   }
  //   dispatch(
  //     addUserActivity({
  //       event: "pull_products",
  //       event_info: {
  //         products: userActivitieArr,
  //         // supplier_name :
  //         // name: itemToDelete?.title,
  //       },
  //     })
  //   );
  //   setCreating(false);
  //   setSelectedProducts([]);
  // };
  const onPreviosProduct = () => {
    if (!isObjWithValues(productDetail)) return;
    let index = products.findIndex((obj) => obj?.id === productDetail?.id);
    if (index === 0) return;
    setProductDetail(products[index - 1]);
  };
  const onNextProduct = () => {
    if (!isObjWithValues(productDetail)) return;
    let index = products.findIndex((obj) => obj?.id === productDetail?.id);
    if (index === products.length - 1) return;
    setProductDetail(products[index + 1]);
  };
  const onPageChange = (event, page) => {
    setParams((pre) => ({ ...pre, page: page }));
    dispatch(
      getDesignBankProduct({
        ...params,
        page: page,
        filter: paramFilters,
        products: allProducts,
      })
    );
  };

  const closeAddPopup = () => {
    setShowAddDetailsPopup({});
  };

  return (
    <Box padding={isIpad ? 0 : "25px"}>
      {/* <Collapse in={isArrayWithValues(selectedProducts)}>
        <Stack direction={"row"} justifyContent="flex-end">
          <LoadingButton
            loading={creating}
            variant="contained"
            onClick={onAddInventory}
          >
            Add to Inventory{" "}
            {selectedProducts?.length ? selectedProducts?.length : ""}
          </LoadingButton>
        </Stack>
      </Collapse> */}
      {/* <Typography variant="h3" gutterBottom display="inline">
        Design Bank
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Design Bank</Typography>
      </Breadcrumbs> */}
      {isArrayWithValues(reduxSupplier) &&
      !isArrayWithValues(
        reduxSupplier?.filter((obj) => obj?.status === "connected")
      ) ? (
        <>
          {/* {!reduxFetchingSuppliers && !reduxFetchingDesignbankProduct ? ( */}
          <>
            <Box
              sx={{
                maxWidth: "400px",
                margin: "auto",
                marginTop: "4vh",
                marginBottom: "10vh",
                textAlign: "center",
              }}
            >
              <DesignBankIllustration />
              <Stack justifyContent={"center"} alignItems="center" rowGap={3}>
                <Typography variant="h6" color="text.secondary">
                  You are connected to 0 suppliers
                </Typography>
                <Typography variant="h6">
                  Invite suppliers to start using design bank
                </Typography>
                <Button startIcon={<Add />} variant="outlined" mt={4}>
                  send invite request
                </Button>
              </Stack>
            </Box>
          </>
          {/* ) : null} */}
        </>
      ) : (
        <>
          <AddDetails
            open={isObjWithValues(showAddDetailsPopup)}
            handleClose={closeAddPopup}
            data={showAddDetailsPopup}
            productMasterAdd={productMasterAdd}
          />

          <SearchRow
          // isIpad={isIpad}
          // allProducts={allProducts}
          // setParams={setParams}
          // params={params}
          // paramFilters={paramFilters}
          // setFilterView={setFilterView}
          // reduxdesignbankfilters={reduxdesignbankfilters}
          // handleDelete={handleDelete}
          />

          <Typography variant="h3" my={3}>
            Products
          </Typography>
          <Typography sx={{ ...secondaryLabel }}>
            Showing {reduxDesignbankProductsLength} produts
          </Typography>
          {reduxFetchingDesignbankProduct || reduxFetchingSuppliers ? (
            <Grid container mt={3} spacing={4}>
              {new Array(12).fill("").map((i) => {
                return (
                  <Grid item xs={6} sm={4} md={4} lg={2.4}>
                    <ProductLoadingCard />
                  </Grid>
                );
              })}
            </Grid>
          ) : null}
          <Grid container mt={3} spacing={5}>
            {!reduxFetchingSuppliers && !reduxFetchingDesignbankProduct
              ? products.map((i) => {
                  // if (isArrayWithValues(isProductIncomplete(products))) return
                  if (isArrayWithValues(i.images))
                    return (
                      <Grid item xs={6} sm={4} md={4} lg={2.4}>
                        <ProductCard
                          key={i.id}
                          setShowAddDetailsPopup={setShowAddDetailsPopup}
                          setProductMasterAdd={setProductMasterAdd}
                          masterPricing={masterPricing}
                          productSettings={productSettings}
                          selectedProducts={selectedProducts}
                          allDiamondGroups={allDiamondGroups}
                          totalProductsPulledBySuppliers={
                            totalProductsPulledBySuppliers
                          }
                          product={i}
                          setProductDetail={setProductDetail}
                          productDetail={productDetail}
                          allTaxes={allTaxes}
                        />
                      </Grid>
                    );
                })
              : null}
          </Grid>
          {/* <RecommendedSupplier /> */}
          <Stack alignItems={"end"}>
            {reduxDesignbankProductsLength &&
            reduxDesignbankProductsLength > 100 ? (
              <MuiPagination
                sx={{ mt: 3, mr: 5 }}
                count={
                  reduxDesignbankProductsLength
                    ? (reduxDesignbankProductsLength / 100).toFixed()
                    : 1
                }
                page={params?.page}
                onChange={onPageChange}
              />
            ) : null}
          </Stack>
          <Stack
            justifyContent={"center"}
            alignItems="center"
            my={5}
            rowGap={"10px"}
          >
            <Typography variant="h4">Do you like using Design Bank</Typography>
            <Typography variant="h6">Rate us</Typography>
            <Rating name="size-large" defaultValue={2} size="large" />
          </Stack>
        </>
      )}

      <Dialog
        open={isObjWithValues(productDetail)}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={closeProductDetail}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
        sx={dialogStyle}
        {...globalPopupStyle}
        // keepMounted
      >
        <DialogContent>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            // sx={{ padding: "10px 20px" }}
          >
            <Typography variant="h3">Product Details</Typography>{" "}
            <Stack direction={"row"} alignItems="center" gap="10px">
              <Stack direction={"row"} alignItems="center">
                <IconButton onClick={onPreviosProduct}>
                  <ArrowBackIosNew />
                </IconButton>
                <IconButton onClick={onNextProduct}>
                  <ArrowForwardIos />
                </IconButton>
              </Stack>
              <IconButton onClick={closeProductDetail}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
          <ProductDialog
            productDetail={productDetail}
            // onSelectPress={onSelectPress}
            selectedProducts={selectedProducts}
            productSettings={productSettings}
            allDiamondGroups={allDiamondGroups}
            onPreviosProduct={onPreviosProduct}
            onNextProduct={onNextProduct}
            masterPricing={masterPricing}
            allTaxes={allTaxes}
            totalProductsPulledBySuppliers={totalProductsPulledBySuppliers}
          />

          <DialogActions></DialogActions>
        </DialogContent>
      </Dialog>
      <Drawer
        sx={{
          "	.MuiDrawer-paperAnchorBottom": {
            borderTopLeftRadius: 9,
            borderTopRightRadius: 9,
          },
          "	.MuiDrawer-paperAnchorRight": {
            height: "calc(100vh - 20px)",
            margin: "10px",
            borderRadius: 4,
          },
        }}
        anchor={isPhone ? "bottom" : "right"}
        open={filterView}
        onClose={() => {
          setFilterView(false);
        }}
      >
        <FiltersOptions
          filterObj={filterObj}
          setFilterView={setFilterView}
          paramFilters={paramFilters}
          setParamFilters={setParamFilters}
          allProducts={allProducts}
          params={params}
          setParams={setParams}
          reduxFetchingDesignbankProduct={reduxFetchingDesignbankProduct}
        />
      </Drawer>
    </Box>
  );
}
const FiltersOptions = ({
  filterObj,
  setFilterView,
  setParamFilters,
  paramFilters,
  allProducts,
  reduxFetchingDesignbankProduct,
  params,
  setParams,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();
  console.log(filterObj);
  // const paramFilters = useSelector(state => state?.supplier?.supplierProductsFilters)
  const onFilterChange = (key, value) => {
    let arr = paramFilters?.[key] || [];
    if (arr.includes(value)) arr = arr?.filter((str) => str !== value);
    else arr = [...arr, value];
    setParamFilters((pre) => ({ ...pre, [key]: arr }));
  };

  const onFilterSubmit = () => {
    if (isArrayWithValues(allProducts))
      setParams((pre) => ({ ...pre, page: 1 }));
    dispatch(
      getDesignBankProduct({
        ...params,
        page: 1,
        filter: paramFilters,
        products: allProducts,
      })
    );
    console.log({
      ...params,
      page: 1,
      filter: paramFilters,
      products: allProducts,
    });
    setFilterView(false);
  };
  const onRest = () => {
    if (isArrayWithValues(allProducts))
      dispatch(
        getDesignBankProduct({
          ...params,
          filter: {},
          products: allProducts,
          // products: allProducts.filter(obj => Object.keys(paramFilters).includes()).slice(
          //   params?.page * params?.per_page,
          //   params?.page * params?.per_page + params?.per_page
          // ),
        })
      );
    setParamFilters({});
    setFilterView(false);
  };
  // const
  return (
    <Card
      className="scrollbar-hidden"
      sx={{
        width: isPhone ? "100%" : "350px",
        height: isPhone ? "70vh" : "100%",
        padding: "20px",
        paddingBottom: isPhone ? 0 : "20px",
        overflow: "hidden",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">Products Filters</Typography>
        <Close
          fontSize="small"
          onClick={() => {
            setFilterView(false);
          }}
        />
      </Stack>
      <Box sx={{ height: "10px" }} />
      <Divider />
      <Stack
        sx={{
          height: isPhone ? "calc(70vh - 93px)" : "calc(90vh - 53px)",
          overflow: "scroll",
        }}
        className="scrollbar-hidden"
      >
        {Object.keys(filterObj || {}).map((key) => {
          if (!isArrayWithValues(filterObj?.[key])) return;
          if (id && key === "suppliers") return;
          let filterArr = paramFilters[key] || [];
          return (
            <Stack mt={4} key={key}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {formatServerValue(key)}
              </Typography>
              <FormGroup>
                {filterObj?.[key].map((obj) => {
                  if (!obj?.label) return;
                  return (
                    <FormControlLabel
                      key={obj.label}
                      control={
                        <Checkbox
                          size="small"
                          sx={{ padding: "5px", paddingLeft: "10px" }}
                          // name={item.name}
                          checked={filterArr.includes(obj?.value)}
                          onChange={() => onFilterChange(key, obj?.value)}
                        />
                      }
                      label={obj?.label || ""}
                    />
                  );
                })}
              </FormGroup>
              <Divider sx={{ mt: 5, mb: 2 }} />
            </Stack>
          );
        })}
      </Stack>

      <LoadingButton
        variant="contained"
        sx={{ padding: "3px 16px" }}
        onClick={onFilterSubmit}
        // loading={true}
        loading={reduxFetchingDesignbankProduct}
        loadingIndicator="Fetching"
      >
        Filter
      </LoadingButton>
      <Button onClick={onRest}>Reset</Button>
    </Card>
  );
};
export const ProductDialog = ({
  productDetail,
  onSelectPress,
  selectedProducts,
  productSettings,
  allDiamondGroups,
  masterPricing,
  // productSettings,
  totalProductsPulledBySuppliers,
  onPreviosProduct,
  onNextProduct,
  allTaxes,
}) => {
  const dispatch = useDispatch();
  const currency = useSelector(
    (state) => state.settings?.productSettings?.default_currency
  );
  const designBankSelectedProducts = useSelector(
    (state) => state.productDetail?.designBankSelectedProducts
  );
  const [selectedImage, setSelectedImage] = useState("");
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [tabValue, setTabValue] = useState("metal_details");
  const [likeObj, setLikeObj] = useState([]);
  const [priceBreakup, setPriceBreakup] = useState({});
  const [tabsData, setTabsData] = useState({});
  const { images, name } = productDetail;
  const totalMetalGross = getTotalMetalGross(productDetail);
  const [priceBreakupArray, setPriceBreakupArray] = useState([]);
  const totalMetalNet = getTotalMetalNet(productDetail);
  const totalDiamondWeight = getTotalDiamondWeight(productDetail);
  const totalDiamondPieces = getTotalDiamondPieces(productDetail);
  const totalGemstoneWeight = getTotalGemstoneWeight(productDetail);
  const totalGemstonePieces = getTotalGemstonePieces(productDetail);

  const allProducts = useSelector((state) => state.product?.designbankProducts);
  let isSelected = designBankSelectedProducts?.find(
    (obj) => obj?.id === productDetail?.id
  );
  let productValid = verifyProduct(
    productDetail,
    masterPricing,
    productSettings,
    allDiamondGroups
  );
  console.log(productValid, productDetail);

  useEffect(() => {
    if (Array.isArray(productDetail?.retailer_likes))
      setLikeObj(productDetail?.retailer_likes);
    if (totalGemstoneWeight && totalGemstonePieces)
      setTabValue("gemastone_details");
    if (totalDiamondWeight && totalDiamondPieces)
      setTabValue("diamonad_details");
    if (totalMetalGross || totalMetalNet) setTabValue("metal_details");
    // let meta_data =
    for (const metaObj of productDetail?.meta_data || []) {
      productDetail[metaObj?.key] = metaObj?.value;
    }
  }, [productDetail?.id, allProducts]);

  useEffect(() => {
    let product = productDetail;
    if (
      isObjWithValues(product) &&
      isObjWithValues(masterPricing) &&
      isObjWithValues(productSettings) &&
      isObjWithValues(allDiamondGroups)
    ) {
      let priceObj =
        getProductPrice(
          product || {},
          masterPricing || {},
          productSettings || {},
          allDiamondGroups || {},
          Math.max(...allTaxes?.map((o) => validateNumber(o.rate) || []))
          // currentTaxRate
        ) || {};
      let arr = [];
      setPriceBreakup(priceObj);
      console.log(priceObj);
      let BreakupObj = {
        metal: priceObj?.master?.metal,
        gemstone: priceObj?.master?.colorstone,

        diamond: priceObj?.master?.diamond,
        labour: priceObj?.master?.labour,
        total: validateNumber(
          priceObj?.master?.metal +
            priceObj?.master?.diamond +
            priceObj?.master?.labour +
            priceObj?.master?.colorstone
        ),
        extra_charges: priceObj?.master?.extraCharges,
        tax: priceObj?.tax,
        total_Price: priceObj?.totalPrice,
      };
      arr = [
        ...Object.keys(BreakupObj)
          .filter((key) => BreakupObj[key])
          .map((key) => {
            let label = formatServerValue(key);
            return [
              label,
              `${currencySymbols[currency] || ""}${BreakupObj[key] || ""} `,
            ];
          }),
      ];

      setPriceBreakupArray(arr);
    }
  }, [
    productDetail,
    masterPricing,
    productSettings,
    allDiamondGroups,
    allTaxes,
  ]);
  let retailer_likes = productDetail?.meta_data?.map(
    (obj) => obj?.key === "retailer_likes"
  )?.value;
  // console.log(Object.values(productValid));
  let validProduct =
    productValid?.diamond?.valid &&
    productValid?.gemstone?.valid &&
    productValid?.labour?.valid;

  // console.log(productDetail);
  const onLikeProduct = () => {
    // if (likingDesignbankProduct) return;
    // if (likeObj.includes(store_id))
    //   setLikeObj(likeObj.filter((id) => id != store_id));
    // else setLikeObj((pre) => [...pre, store_id]);
    dispatch(likeProduct(productDetail));
  };

  useEffect(() => {
    let gold_pricing = masterPricing?.gold_pricing?.[currency];
    let metal_details = [];
    if (productDetail?.metal_color)
      metal_details.push([
        "Metal Color",
        `${formatServerValue(productDetail?.metal_color || "")}`,
      ]);
    if (productDetail?.gold_kt)
      metal_details.push([
        "Gold purity",
        // <Stack direction={"row"} > </Stack>
        `${goldName[productDetail?.gold_kt] || ""} - ( ${
          currencySymbols[currency] || ""
        } ${
          gold_pricing?.[masterPricing?.gold_pricing?.type]?.[
            productDetail?.gold_kt
          ]?.rate || ""
        }/gm on ${formatServerValue(masterPricing?.gold_pricing?.from)} wt)`,
        // `Gold rate of ${goldName[productDetail?.gold_kt] || ""}: ${gold_pricing?.[masterPricing?.gold_pricing?.type]?.[productDetail?.gold_kt]?.rate || ""}`
        // `${gold_pricing?.[masterPricing?.gold_pricing?.type]?.[productDetail?.gold_kt]?.rate || ""} /${goldName[productDetail?.gold_kt] || ""} `,
      ]);
    if (productDetail?.silver_purity)
      metal_details.push([
        "Silver purity",
        `${productDetail?.silver_purity || ""} `,
      ]);
    if (productDetail?.platinium_purity)
      metal_details.push([
        "Platinum purity",
        `${productDetail?.platinium_purity || ""}  `,
      ]);
    let details = [
      "gold_gross",
      "gold_net",
      "silver_gross",
      "silver_net",
      "platinium_gross",
      "platinium_net",
    ];
    for (const metaObj of productDetail?.meta_data || []) {
      if (details?.includes(metaObj?.key) && validateNumber(metaObj?.value)) {
        metal_details.push([
          `${formatServerValue(metaObj?.key || "")
            ?.replace("Gross", "G Wt.")
            ?.replace("Net", "N Wt.")}`,
          `${validateNumber(metaObj?.value)?.toFixed(3)} gms.`,
        ]);
      }
    }
    // metal_details = [...metal_details, ["Metal Gross", `${totalMetalGross.toFixed(2)} gms.`],
    // ["Metal Net", `${totalMetalNet.toFixed(2)} gms.`]]

    setTabsData({ metal_details: metal_details });
  }, [productDetail]);

  return (
    <Box>
      <Grid container spacing={8}>
        <Grid item xs={12} md={5}>
          {/* <Stack direction={"row"} alignItems="center">
                  <Person />
                  <Typography mx={1}>Supplier: </Typography>
                  <Typography fontWeight={"bold"}>
                    {productDetail?.supplier_name}
                  </Typography>
                </Stack> */}
          <Swiper
            style={{
              "--swiper-navigation-color": "#808080",
              "--swiper-pagination-color": "#808080",
              position: "relative",
            }}
            pagination={{
              type: "fraction",
            }}
            thumbs={{ swiper: thumbsSwiper }}
            navigation={true}
            modules={[Pagination, Navigation, Thumbs]}
            // className="mySwiper"
          >
            {isArrayWithValues(productDetail?.images)
              ? productDetail?.images.map((obj) => (
                  <SwiperSlide key={obj?.id}>
                    <img
                      src={
                        obj?.src || "/static/img/woocommerce_placeholder.png"
                      }
                      style={{ width: "100% ", objectFit: "contain" }}
                    />
                  </SwiperSlide>
                ))
              : null}
            {productDetail?.stock_status === "instock" ? (
              <Tooltip title={"In Stock"}>
                <Readystockoutline
                  style={{
                    position: "absolute",
                    right: "10px",
                    bottom: "10px",

                    zIndex: 30,
                    height: "30px",
                    width: "30px",
                  }}
                />
              </Tooltip>
            ) : null}
          </Swiper>
          <Swiper
            slidesPerView={3}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            onSwiper={setThumbsSwiper}
            modules={[Pagination]}
            // className="mySwiper"
          >
            {isArrayWithValues(productDetail?.images)
              ? productDetail?.images.map((item) => (
                  <SwiperSlide key={item.img} style={{}}>
                    <img
                      // className="shadow_card"

                      onClick={() => setSelectedImage(item?.src)}
                      src={
                        item?.src || "/static/img/woocommerce_placeholder.png"
                      }
                      style={{
                        maxWidth: "70px",
                        objectFit: "contain",
                        boxShadow:
                          "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                      }}
                    />
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack
            direction={"row"}
            // alignItems="center"
            justifyContent={"space-between"}
            gap={8}
          >
            <Stack rowGap={"8px"}>
              <Typography variant="h5">{name}</Typography>

              <Stack direction={"row"} alignItems="center" gap={2}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "text.secondary", fontWeight: "400" }}
                >
                  {formatServerValue(
                    productDetail?.collections?.[0]?.name || " "
                  )}
                </Typography>
                <ChevronRight style={{ color: "#ccc" }} />
                <Typography sx={{ color: "text.secondary", fontWeight: "400" }}>
                  {formatServerValue(
                    productDetail?.categories?.[0]?.name || ""
                  )}
                </Typography>
                {productDetail?.subcategory?.[0]?.name ? (
                  <>
                    <ChevronRight style={{ color: "#ccc" }} />
                    <Typography
                      sx={{ color: "text.secondary", fontWeight: "400" }}
                    >
                      {formatServerValue(
                        productDetail?.subcategory?.[0]?.name || ""
                      )}
                    </Typography>
                  </>
                ) : null}
              </Stack>
            </Stack>
            {priceBreakup?.price ? (
              <Typography variant="h6" color="text.secondary">
                {currencySymbols[currency]}
                {priceBreakup?.price}
              </Typography>
            ) : null}
            <Stack>
              <Stack onClick={onLikeProduct}>
                <ThumbUpOutlined />
                {isArrayWithValues(likeObj) ? likeObj.length : ""}
                {/* {retailer_likes?.length} */}
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Tabs
              // sx={{ borderBottom: "0.5px solid #808080" }}
              onChange={(e, value) => setTabValue(value)}
              value={tabValue}
            >
              {totalMetalGross && totalMetalNet ? (
                <Tab label="Metal Details" value="metal_details" />
              ) : null}
              {totalDiamondWeight && totalDiamondPieces ? (
                <Tab label="Diamond  Details" value="diamonad_details" />
              ) : null}
              {totalGemstoneWeight && totalGemstonePieces ? (
                <Tab label="Gemstone Details" value="gemastone_details" />
              ) : null}
              {<Tab label="Price Breakup" value={"price_breakup"} />}
            </Tabs>
            <Divider />
            <TabPanel value={tabValue} index={"metal_details"}>
              <ProductDetailBoxNew
                title={
                  <Stack direction={"row"} gap={"8px"}>
                    {" "}
                    Metal Details{" "}
                    {priceBreakup?.master?.metal ? (
                      <Typography sx={{ color: "text.secondary" }}>
                        {" "}
                        ({currencySymbols[currency]}{" "}
                        {priceBreakup?.master?.metal})
                      </Typography>
                    ) : null}{" "}
                  </Stack>
                }
                data={tabsData?.metal_details || []}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={"diamonad_details"}>
              <ProductDetailBoxNew
                title={
                  <Stack direction={"row"} gap={"8px"}>
                    {" "}
                    Diamond Details{" "}
                    {priceBreakup?.master?.diamond ? (
                      <Typography sx={{ color: "text.secondary" }}>
                        {" "}
                        ({currencySymbols[currency]}{" "}
                        {priceBreakup?.master?.diamond})
                      </Typography>
                    ) : null}{" "}
                  </Stack>
                }
                data={[
                  [
                    `D Wt. ${
                      priceBreakup?.master?.diamond && totalDiamondWeight
                        ? `(Avg. )`
                        : null
                    }`,
                    `${totalDiamondWeight.toFixed(2)} cts.`,
                  ],
                  ["D P.", `${totalDiamondPieces.toFixed()}`],
                ]}
              />
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>Quality</TableCell>
                      <TableCell>Shape</TableCell>
                      <TableCell>DW</TableCell>
                      <TableCell>SS group</TableCell>
                      <TableCell>Rate /ct</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(productDetail?.diamond || {})?.map(
                      (row, index) => {
                        let {
                          diamond_quality,
                          diamond_shape,
                          diamond_sieve,
                          diamond_cut,
                          diamond_weight,
                          diamond_pieces,
                          diamond_type,
                        } = row;
                        //  totalWeight += Number(diamond_weight);
                        //   totalPieces += Number(diamond_pieces);
                        diamond_sieve = getDiamondSieve(
                          Number(diamond_weight),
                          Number(diamond_pieces),
                          diamond_shape,
                          allDiamondGroups
                        );
                        let rate = getDiamondRate(
                          masterPricing?.diamond_pricing?.[currency],
                          diamond_type,
                          diamond_quality,
                          diamond_shape,
                          diamond_sieve,
                          diamond_cut
                        );
                        let diamond_sieve_group = Object.values(
                          allDiamondGroups || {}
                        ).find((obj) => obj?.id === diamond_sieve)?.title;
                        console.log(rate);
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {formatServerValue(row.diamond_type || "")}
                            </TableCell>
                            <TableCell>
                              {formatServerValue(row.diamond_quality)}
                            </TableCell>
                            <TableCell>
                              {formatServerValue(row.diamond_shape)}
                            </TableCell>
                            <TableCell>
                              {validateNumber(row?.diamond_weight).toFixed(2)}
                              {/* {validateNumber(
                                (
                                  Number(row?.diamond_weight || 0) /
                                  Number(row?.diamond_pieces || 0)
                                )
                                  .toFixed(3)
                                  .toString()
                              )} */}
                            </TableCell>
                            <TableCell>{diamond_sieve_group || ""}</TableCell>
                            <TableCell>
                              {rate ? (
                                rate
                              ) : (
                                <Typography color={"error.main"}>
                                  No rate
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value={tabValue} index={"gemastone_details"}>
              <ProductDetailBoxNew
                title={
                  <Stack direction={"row"} gap={"8px"}>
                    {" "}
                    Gemstone Details{" "}
                    {priceBreakup?.master?.colorstone ? (
                      <Typography sx={{ color: "text.secondary" }}>
                        {" "}
                        ({currencySymbols[currency]}{" "}
                        {priceBreakup?.master?.colorstone})
                      </Typography>
                    ) : null}{" "}
                  </Stack>
                }
                data={[
                  ["Gemstone Weight", `${totalGemstoneWeight.toFixed(3)} ct.`],
                  ["Gemstone Pieces", `${totalGemstonePieces} pcs.`],
                ]}
              />
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Quality</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Shape</TableCell>
                      <TableCell>Pieces</TableCell>
                      <TableCell>size</TableCell>
                      <TableCell>Rate</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(
                      productDetail?.colorstone_details || {}
                    )?.map((row, index) => {
                      let {
                        colorstone_quality,
                        colorstone_shape,
                        // colorstone_sieve,
                        // colorstone_cut,
                        colorstone_weight,
                        colorstone_pieces,
                        colorstone_type,
                        colorstone_size,
                      } = row;
                      //  totalWeight += Number(colorstone_weight);
                      //   totalPieces += Number(colorstone_pieces);

                      let rate = getGemstoneRate(
                        masterPricing?.gemstone_pricing?.[currency],
                        colorstone_quality,
                        colorstone_type,
                        colorstone_shape,
                        colorstone_size
                      );
                      console.log(rate);
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {formatServerValue(row.colorstone_quality || "")}
                          </TableCell>
                          <TableCell>
                            {formatServerValue(row.colorstone_type)}
                          </TableCell>
                          <TableCell>
                            {formatServerValue(row.colorstone_shape)}
                          </TableCell>
                          <TableCell>{colorstone_pieces}</TableCell>
                          <TableCell>{colorstone_size}</TableCell>
                          <TableCell>{rate}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value={tabValue} index={"price_breakup"}>
              <ProductDetailBoxNew
                title="Price Breakup"
                data={priceBreakupArray}
              />
            </TabPanel>
          </Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Box>
              {validProduct && (
                <Button
                  // size="small"
                  variant="outlined"
                  onClick={() =>
                    dispatch(onSelectDesignBankProduct(productDetail))
                  }
                  color={isSelected ? "error" : "primary"}
                  disabled={totalProductsPulledBySuppliers?.[
                    productDetail?.supplier_id
                  ]?.includes(productDetail?.id?.toString())}
                >
                  {totalProductsPulledBySuppliers?.[
                    productDetail?.supplier_id
                  ]?.includes(productDetail?.id?.toString())
                    ? "Already Pulled"
                    : "Add to My product line"}
                </Button>
              )}
            </Box>

            {/* <Stack direction={"row"} alignItems="center">
              <IconButton onClick={onPreviosProduct}>
                <ArrowBackIosNew />
              </IconButton>
              <IconButton onClick={onNextProduct}>
                <ArrowForwardIos />
              </IconButton>
            </Stack> */}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
const ProductDetailBox = ({ data, title }) => {
  return (
    <Stack
      sx={{
        backgroundColor: "rgba(0,0,0,0.1)",
        py: 3,
        px: 2,
        borderRadius: "6px",
        mt: 3,
      }}
    >
      <Typography variant="h5">{title}</Typography>
      <Stack direction={"row"} mt={3} justifyContent="space-between">
        {isArrayWithValues(data) &&
          data.map((i, index) => (
            <Box
              key={index}
              sx={{
                flex: 1,
                justifyContent: "flex-start",
              }}
            >
              <Typography variant="h6" fontSize={"14px"} fontWeight={"normal"}>
                {i[0]}
              </Typography>
              <Typography mt={1} variant="h6">
                {i[1]}
              </Typography>
            </Box>
          ))}
      </Stack>
    </Stack>
  );
};
const ProductDetailBoxNew = ({ data, title }) => {
  return (
    <Stack
      sx={{
        // backgroundColor: "rgba(0,0,0,0.1)",
        py: 3,
        px: 2,
        borderRadius: "6px",
        // mt: 3,
      }}
    >
      <Typography variant="h5">{title}</Typography>
      {/* <Grid mt={3}>
        {isArrayWithValues(data) &&
          data.map((d) => (
            <Grid container>
              {d.map((i) => (
                <Grid item xs={12 / data?.length}>
                  {i}
                </Grid>
              ))}
            </Grid>
          ))}
      </Grid> */}
      <Stack
        direction={"column"}
        mt={3}
        justifyContent="space-between"
        spacing={2}
      >
        {isArrayWithValues(data) &&
          data.map((i) => (
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Typography variant="h6" fontSize={"14px"}>
                {i[0]}
              </Typography>
              <Typography
                mt={1}
                variant="h6"
                fontSize={"14px"}
                fontWeight={"normal"}
              >
                {i[1]}
              </Typography>
              {/* {<Typography mt={1} variant="h6">
                {i[2]}
              </Typography>} */}
            </Stack>
            // </Box>
          ))}
      </Stack>
    </Stack>
  );
};

const ProductRow = ({ product, setProductDetail, productDetail }) => {
  let { images, name, sku } = product;
  const imageUrl = isArrayWithValues(images) ? images[0].src : "";
  const [showViewMore, setShowViewMore] = useState(false);
  const onSeeDetails = () => {
    setTimeout(() => {
      setProductDetail(product);
    }, 300);
  };
  return (
    <>
      <Card
        className="shadow_card"
        onMouseEnter={() => setShowViewMore(true)}
        onMouseLeave={() => setShowViewMore(false)}
        // onMouseOver={() => setShowViewMore(false)}
      >
        <CardContent
          sx={{
            p: 0,
            "&:last-child": { paddingBottom: "0px" },
            position: "relative",
          }}
        >
          <Box>
            <img
              src={imageUrl || "/static/img/woocommerce_placeholder.png"}
              style={{ width: "100% ", objectFit: "contain" }}
            />
          </Box>
          <Stack
            alignItems={"center"}
            component={Button}
            onClick={onSeeDetails}
            justifyContent="center"
            sx={{
              position: "absolute",
              height: "40px",
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgb(69,90,124)",
              background:
                "linear-gradient(356deg, rgba(69,90,124,1) 0%, rgba(118,133,148,1) 100%)",
              //   transform: showViewMore ? "translateY(0px)" : "translateY(40px)",
              //   transition: "transform 0.3s",
              //   transform: !showViewMore ? "scale(0, 0)" : "scale(1, 1)",
              //   transform: showViewMore
              //     ? "scaleY(1) translateY(40px)"
              //     : "scaleY(0) translateY(0px)",

              //   height: !showViewMore ? "0px" : "40px",
              // transition: "height 0.4s",
              //   display: !showViewMore ? "none" : "flex",
              opacity: !showViewMore ? 0 : 1,
              transition: "opacity 0.4s",

              //   transition: "all 0.4s",

              //   overflow: "hidden",
              color: "#fff",
            }}
          >
            <Typography
              variant="h6"
              fontSize={"14px"}
              textAlign={"center"}
              sx={{ verticalAlign: "center" }}
              margin="auto"
            >
              See Details
            </Typography>
          </Stack>
        </CardContent>
      </Card>
      <Box
        sx={{
          height: "17.5px",
          overflow: "hidden",
          mx: 2,
          mt: 3,
        }}
      >
        <Typography variant="h6" fontSize="14px" textAlign={"center"}>
          {name}
        </Typography>
      </Box>
    </>
  );
};

export const ProductCard = ({
  product,
  setProductDetail,
  productDetail,
  selectedProducts,
  onSelectPress,
  masterPricing,
  productSettings,
  allDiamondGroups,
  setShowAddDetailsPopup,
  setProductMasterAdd,
  totalProductsPulledBySuppliers,
  allTaxes,
}) => {
  let { images, name, sku, stock_status } = product;
  const store_id = getStoreId();
  let collection = isArrayWithValues(product?.collections)
    ? product?.collections[0]?.name
    : "";
  const imageUrl = isArrayWithValues(images) ? images[0].src : "";
  const dispatch = useDispatch();
  // const [showViewMore, setShowViewMore] = useState(false);
  const [likeObj, setLikeObj] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [priceBreakup, setPriceBreakup] = useState({});
  const [productValid, setProductValid] = useState({});
  const [validProduct, setValidProduct] = useState(false);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const likingDesignbankProduct = useSelector(
    (state) => state.product?.likingDesignbankProduct
  );
  const designBankSelectedProducts = useSelector(
    (state) => state.product?.designBankSelectedProducts
  );
  const currency = useSelector(
    (state) => state.settings?.productSettings?.default_currency
  );

  useEffect(() => {
    if (Array.isArray(product?.retailer_likes))
      setLikeObj(product?.retailer_likes);
  }, [product?.retailer_likes]);

  // const [isSelected, setIsSelected] = useState(false);
  const onSeeDetails = (e) => {
    e.stopPropagation();
    setTimeout(() => {
      setProductDetail && setProductDetail(product);
    }, 300);
  };

  const onLikeProduct = () => {
    if (likingDesignbankProduct) return;
    if (likeObj.includes(store_id))
      setLikeObj(likeObj.filter((id) => id != store_id));
    else setLikeObj((pre) => [...pre, store_id]);
    dispatch(likeProduct(product));
  };
  let isSelected = designBankSelectedProducts?.find(
    (obj) => obj?.id === product?.id
  );
  // let productValid = verifyProduct(
  //   product,
  //   masterPricing,
  //   productSettings,
  //   allDiamondGroups
  // );
  // let productValid = {}
  // console.log(Object.values(productValid));
  // let validProduct =
  //   productValid?.diamond?.valid &&
  //   productValid?.gemstone?.valid &&
  //   productValid?.labour?.valid;

  useEffect(() => {
    if (
      isObjWithValues(product) &&
      isObjWithValues(masterPricing) &&
      isObjWithValues(productSettings) &&
      isObjWithValues(allDiamondGroups)
    ) {
      setPriceBreakup(
        getProductPrice(
          product || {},
          masterPricing || {},
          productSettings || {},
          allDiamondGroups || {},
          Math.max(...allTaxes?.map((o) => validateNumber(o.rate) || []))
          // currentTaxRate
        ) || {}
      );

      setProductValid(
        verifyProduct(product, masterPricing, productSettings, allDiamondGroups)
      );
    }
  }, [product, masterPricing, productSettings, allDiamondGroups, allTaxes]);

  useEffect(() => {
    setValidProduct(
      productValid?.diamond?.valid &&
        productValid?.gemstone?.valid &&
        productValid?.labour?.valid
    );
  }, [
    productValid?.diamond?.valid,
    productValid?.gemstone?.valid,
    productValid?.labour?.valid,
  ]);

  // let priceBreakup = getProductPrice(
  //   product,
  //   masterPricing,
  //   productSettings,
  //   allDiamondGroups,
  //   Math.max(...allTaxes?.map((o) => validateNumber(o.rate) || []))
  //   // currentTaxRate
  // ) || {};
  // let priceBreakup = {};

  const open = Boolean(anchorEl);
  const onSelect = (e) => {
    e.stopPropagation();
    if (!validProduct) {
      setShowAddDetailsPopup(productValid);
      setProductMasterAdd && setProductMasterAdd(product);
      return;
    }

    dispatch(onSelectDesignBankProduct(product));
    // onSelectPress(product)
  };

  // console.log(totalProductsPulledBySuppliers, product);

  return (
    <Box sx={{ height: "100%" }}>
      <Card
        onClick={onSeeDetails}
        sx={{
          position: "relative",
          border: `1px solid ${"secondary"}`,
          height: "100%",
          borderRadius: "15px",
        }}
        // sx={{ height: "100%" }}
      >
        <Tooltip
          title={
            totalProductsPulledBySuppliers?.[product?.supplier_id]?.includes(
              product?.id?.toString()
            )
              ? "Already pulled"
              : ""
          }
        >
          <div>
            <Checkbox
              style={{ color: !validProduct && "#FF9494" }}
              // disabled={ product?.retailer_pull?.includes(getStoreId())}
              disabled={totalProductsPulledBySuppliers?.[
                product?.supplier_id
              ]?.includes(product?.id?.toString())}
              onClick={onSelect}
              checked={isSelected || false}
              sx={{
                position: "absolute",
                right: "4px",
                zIndex: 10,
                "&.Mui-checked": {
                  backgroundColor: "#fff",
                },
              }}
            />
          </div>
        </Tooltip>
        <Swiper
          pagination={true}
          modules={[Pagination]}
          // className="mySwiper"
          onClick={(e) => e?.stopPropagation && e?.stopPropagation()}
          style={{ maxWidth: "240px", position: "relative" }}
        >
          {images?.map((obj) => (
            <SwiperSlide>
              <LazyLoadImage
                src={obj?.src || "/static/img/woocommerce_placeholder.png"}
                width="100%"
                height={"240px"}
                // style={{
                //   width: "100%",
                //   minHeight: "240px",
                // }}

                effect="opacity"
              />
            </SwiperSlide>
          ))}
          {stock_status === "instock" ? (
            <Tooltip title={"In Stock"}>
              <Readystockoutline
                style={{
                  position: "absolute",
                  right: "10px",
                  bottom: "10px",
                  zIndex: 30,
                }}
              />
            </Tooltip>
          ) : null}
        </Swiper>
        {/* <CardMedia
          component="img"
          // height="140"
          width="100%"
          image={imageUrl || "/static/img/woocommerce_placeholder.png"}
        // alt="green iguana"
        /> */}
        <CardContent>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} gap="5px" alignItems={"center"}>
              <ThumbUpOutlined style={{ height: "18px", width: "18px" }} />
              <Typography>
                {isArrayWithValues(likeObj) ? likeObj.length : ""}
              </Typography>
            </Stack>
            <Stack direction={"row"} gap="5px" alignItems={"center"}>
              <ShareOutlined style={{ height: "18px", width: "18px" }} />
              {/* <Typography>{0}</Typography> */}
            </Stack>
            <Stack direction={"row"} gap="5px" alignItems={"center"}>
              <RemoveRedEyeOutlined style={{ height: "18px", width: "18px" }} />
              {/* <Typography>{0}</Typography> */}
            </Stack>
          </Stack>
          <Stack sx={{ mt: 4 }}>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              gap={"8px"}
            >
              <Typography variant="subtitle1" sx={{ ...secondaryLabel }}>
                {reduceLargeText(name, 23)}
              </Typography>
              <Typography variant="subtitle1" sx={{ ...secondaryLabel }}>
                {goldName[product?.gold_kt]}
              </Typography>
              {/* <Stack direction={"row"} alignItems="center"> */}
              {/* <IconButton
                  onClick={() => onLikeProduct()}
                  disabled={likingDesignbankProduct}
                >
                  {likeObj?.includes(store_id) ? (
                    <Favorite style={{ color: "#F7414F" }} />
                  ) : (
                    <FavoriteBorder />
                  )}
                </IconButton>
                <Typography>
                  {isArrayWithValues(likeObj) ? likeObj.length : ""}
                </Typography> */}
              {/* </Stack>   */}
            </Stack>
            {collection ? (
              <Typography variant="subtitle2" sx={{ ...secondaryLabel }}>
                {formatServerValue(collection)}
              </Typography>
            ) : null}
          </Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Box>
              {validProduct ? (
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  {currencySymbols[currency]}
                  {priceBreakup?.price}
                  {/* {product?.price} */}
                </Typography>
              ) : null}
            </Box>
            <Tooltip title={product?.supplier_name || ""}>
              <Avatar
                src={product?.logo || "" || ""}
                alt={product?.supplier_name || ""}
                style={{
                  width: 27,
                  height: 27,
                  // margin: "14px auto",
                  // marginTop: "8px",
                  objectFit: "contain",
                }}
                // sx={{ height: "27px", width: "27px" }}
              >
                {getInitials(`${product?.supplier_name || ""}`)}
              </Avatar>
              {/* <Avatar src={product?.logo || ""} sizes="small" sx={{ height: "27px", width: "27px" }} /> */}
            </Tooltip>
          </Stack>
        </CardContent>
        {/* <CardActions>
          {product?.retailer_pull?.includes(getStoreId()) ? (
            <Button
              size="small"
              // onClick={() => onSelectPress(product)}
              // color={isSelected ? "error" : "primary"}
              disabled={product?.retailer_pull?.includes(getStoreId())}
            >
              Already Pulled
            </Button>
          ) : null}
          {validProduct && !product?.retailer_pull?.includes(getStoreId()) ? (
            <Button
              size="small"
              onClick={() => onSelectPress(product)}
              color={isSelected ? "error" : "primary"}
              disabled={product?.retailer_pull?.includes(getStoreId())}
            >
              {isSelected ? "Unselect" : "Select"}
            </Button>
          ) : null}
          {!validProduct && !product?.retailer_pull?.includes(getStoreId()) ? (
            <Button
              size="small"
              // onClick={() => onSelectPress(product)}
              color={"error"}
              // variant={'contained'}
              // disabled
              endIcon={<ErrorOutline />}
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onClick={() => setShowAddDetailsPopup(productValid)}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              Select
            </Button>
          ) : null}

          <Button size="small" onClick={onSeeDetails}>
            View More
          </Button>
        </CardActions> */}
      </Card>
    </Box>
  );
};
export const ProductLoadingCard = () => {
  return (
    <Box sx={{ height: "100%" }}>
      <Card
        // onClick={() => onSelectPress(product)}
        className={"shadow_card"}
        sx={{
          position: "relative",
          border: `1px solid ${"secondary"}`,
          height: "100%",
          borderRadius: "20px",
        }}
        // sx={{ height: "100%" }}
      >
        <Box>
          {/* <img
            src={imageUrl || "/static/img/woocommerce_placeholder.png"}
            style={{ width: "100% ", objectFit: "contain" }}
          /> */}
          <Skeleton variant="rounded" width={"100%"} height={236} />
        </Box>
        <CardContent sx={{ height: "100%" }}>
          <Stack sx={{ mt: 2 }} spacing={2}>
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "80%" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "50%" }} />
          </Stack>
          {/* <Stack direction={"row"} justifyContent="flex-end"> */}
          <Button
            sx={{ position: "absolute", bottom: 0, right: 0 }}
            // onClick={onSeeDetails}
          >
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "80%" }} />
          </Button>
          {/* </Stack> */}
        </CardContent>
      </Card>
    </Box>
  );
};
const giveLabourStatment = (labour) => {
  let { categories, collections, subcategory } = labour || {};
  if (labour?.valid) return "";
  if (
    isArrayWithValues(categories) &&
    isArrayWithValues(collections) &&
    isArrayWithValues(subcategory)
  )
    return `Labour Pricing is not created for this Jewellery Category , Product Category and Product Sub Category`;
  if (isArrayWithValues(categories) && isArrayWithValues(collections))
    return `Labour Pricing is not created for this Jewellery Category and Product Category.`;
};
export const AddDetails = ({ open, handleClose, data, productMasterAdd }) => {
  const dispatch = useDispatch();
  const allDiamondGroups = useSelector(
    (state) => state.settings.allDiamondGroups
  );
  let masters = useSelector((state) => state.settings.masterPricing);
  const default_currency = useSelector(
    (state) => state.settings.productSettings
  )?.default_currency;
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );
  let labour_pricing = masters?.labour_pricing?.[default_currency];
  const [labourObj, setLabourObj] = useState({});
  const [diamondObj, setDiamondObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [labourError, setLabourError] = useState({});
  const [showDiamondGroup, setShowDiamondGroup] = useState(false);
  const [diamondError, setDiamondError] = useState({});
  const [gemstoneCombinations, setGemstoneCombinations] = useState({});
  // let { diamond_pricing } = masters || {};
  let details = data;
  console.log(details);
  let diamondCombination = details?.diamond?.combination || [];
  // useEffect(() => {
  //   console.log(diamondCombination);
  //   for (let index = 0; index < diamondCombination.length; index++) {
  //     const element = diamondCombination[index];
  //   }
  // }, [diamondCombination]);
  useEffect(() => {
    setLabourObj({
      collections: isArrayWithValues(details?.labour?.collections)
        ? details?.labour?.collections?.map((obj) => ({
            value: obj?.slug,
            label: obj?.name,
          }))
        : [],
      categories: isArrayWithValues(details?.labour?.categories)
        ? details?.labour?.categories.map((obj) => ({
            value: obj?.slug,
            label: obj?.name,
          }))
        : [],
      subcategories: isArrayWithValues(details?.labour?.subcategories)
        ? details?.labour?.subcategories.map((obj) => ({
            value: obj?.slug,
            label: obj?.name,
          }))
        : [],
      title: `Labour Combination #${
        labour_pricing?.length + 1
      } (Via Design Bank)`,
      wastage_from: "weight",
      labour_from: "gross",
    });
  }, [details?.labour]);

  let gemstoneCombination = details?.gemstone?.combination || [];
  const onEditLabour = (payload) => {
    setLabourError({});
    setLabourObj((pre) => ({ ...pre, ...payload }));
  };
  const addMasters = () => {
    // console.log(diamondObj);
    let diamond_pricing = masters?.diamond_pricing?.[default_currency];
    let labour_pricing = masters?.labour_pricing?.[default_currency];
    let gemstone_pricing = masters?.gemstone_pricing?.[default_currency];
    console.log();
    if (!details?.gemstone?.valid) {
      let gemstones =
        details?.gemstone?.combination?.map((obj, index) => {
          return {
            // [index]: {
            id: `${Date.now()}_${getRandomString(5)}`,
            title: `Gemstone Combination #${
              gemstone_pricing?.length + 1 + index
            }`,
            qualities: [
              {
                label: formatServerValue(obj?.colorstone_quality),
                value: obj?.colorstone_quality,
              },
            ],
            types: [
              {
                label: formatServerValue(obj?.colorstone_type),
                value: obj?.colorstone_type,
              },
            ],
            shapes: [
              {
                label: formatServerValue(obj?.colorstone_shape),
                value: obj?.colorstone_shape,
              },
            ],
            sizes: [
              {
                label: formatServerValue(obj?.colorstone_size),
                value: obj?.colorstone_size,
              },
            ],
            rate: gemstoneCombinations?.[index]?.rate,
            // last_updated : Date.now(),
            // updated_by : getId(),
            // updated_by :  getAdminName()
          };
          // };
        }) || [];
      if (
        gemstones?.find((obj) => validateNumber(obj?.rate) <= 0 && !obj?.rate)
      ) {
        console.log("error <<");
        setLabourError({ price: true });
        return;
      } else {
        if (isArray(gemstone_pricing)) gemstone_pricing.push(...gemstones);
      }

      console.log(gemstones, gemstone_pricing);
      // return
    }
    if (!details?.diamond?.valid) {
      if (isArray(diamond_pricing)) {
        for (let index = 0; index < Object.values(diamondObj).length; index++) {
          const element = Object.values(diamondObj)[index];
          if (!element) return;
        }
        diamond_pricing.push(
          ...Object.values(diamondObj).map((detail, index) => ({
            ...detail,
            // last_updated: Date.now(),
            // updated_by: getId(),
            // updated_by: getAdminName(),

            title: `Diamond Combination #${
              diamond_pricing.length + index + 1
            }  (Via Design Bank)`,
            id: `${Math.round(Date.now() / 1000 + index)}_${getRandomString(
              6
            )}`,
          }))
        );
      }
      for (const key in diamondObj) {
        console.log(diamondObj[key]);
        if (!diamondObj[key]?.sieves?.[0]?.value) {
          setDiamondError((pre) => ({ ...pre, [key]: { sieve: true } }));
          return;
        }
        if (!diamondObj[key]?.rate) {
          setDiamondError((pre) => ({ ...pre, [key]: { rate: true } }));
          return;
        }
      }
    }

    // return;
    if (!details?.labour?.valid) {
      if (!labourObj?.title) {
        setLabourError({ title: true });
        return;
      }

      if (
        validateNumber(labourObj?.wastage) <= 0 &&
        validateNumber(labourObj?.wastage) > 100 &&
        validateNumber(labourObj?.per_gram) <= 0 &&
        validateNumber(labourObj?.minimum_making) <= 0
      ) {
        setLabourError({ price: true });
        return;
      }

      if (isArray(labour_pricing)) {
        labour_pricing.push({
          ...labourObj,
          // last_updated: Date.now(),
          // updated_by: getId(),
          // updated_by: getAdminName(),

          id: `${Math.round(Date.now() / 1000)}_${getRandomString(6)}`,
        });
      }
    }

    if (isObjWithValues(masters)) {
      masters.diamond_pricing[default_currency] = diamond_pricing;
      masters.labour_pricing[default_currency] = labour_pricing;
      masters.labour_pricing[default_currency] = labour_pricing;

      console.log(
        {
          ...masters,
        },
        "<<< "
      );

      // return;

      dispatch(
        updateMasterPricings({
          data: {
            ...masters,
            // gold_pricing,
            // silver_pricing,
            // platinum_pricing,
            // labour_pricing,
            // diamond_pricing,
            // gemstone_pricing,
            // additional_pricing,
            last_updated: Math.floor(Date.now() / 1000),
          },
          setLoading,
          afterUpdate: async () => {
            handleClose();
          },
        })
      );
      dispatch(onSelectDesignBankProduct(productMasterAdd));
    }

    console.log(diamond_pricing, labour_pricing);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={showDiamondGroup ? "sm" : "md"}
      sx={{ ...dialogStyle }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", alignItems: "center", gap: "6px" }}
      >
        {showDiamondGroup && (
          <IconButton onClick={() => setShowDiamondGroup(false)}>
            <ArrowBack />
          </IconButton>
        )}{" "}
        {showDiamondGroup
          ? "Add Diamond group in masters"
          : "Add Details in masters"}
      </DialogTitle>
      {!showDiamondGroup ? (
        <DialogContent>
          {productMasterAdd?.id ? (
            <>
              <Stack direction={"row"} gap={"15px"} alignItems="center">
                <img
                  src={productMasterAdd?.images?.[0]?.src}
                  height={"85px"}
                  width={"85px"}
                />
                <Stack rowGap={"10px"}>
                  <Typography variant="h6">{productMasterAdd?.name}</Typography>
                  <Typography>Sku: {productMasterAdd?.sku}</Typography>
                </Stack>
              </Stack>
            </>
          ) : null}
          {!details?.diamond?.valid ? (
            <Box>
              <Typography
                sx={{ ...primaryLabel, mt: 3, mb: 1 }}
                color={"error.main"}
              >
                This Diamond combination is not created in your Pricing Master
              </Typography>
              <Stack spacing={3}>
                {diamondCombination.map((obj, index) => {
                  // let arr = Object.values(obj) || []
                  return (
                    <AddDiamond
                      key={index}
                      setShowDiamondGroup={setShowDiamondGroup}
                      setDiamondObj={setDiamondObj}
                      obj={obj}
                      allDiamondGroups={allDiamondGroups}
                      index={index}
                      diamondError={diamondError}
                      setDiamondError={setDiamondError}
                    />
                  );
                })}
              </Stack>
            </Box>
          ) : null}
          {!details?.gemstone?.valid ? (
            <Box sx={{ my: 5 }}>
              {!details?.diamond?.valid && <Divider />}
              <Typography
                sx={{ ...primaryLabel, mt: 3, mb: 2 }}
                color={"error.main"}
              >
                Gemstone rate for this combination is not created in your
                Pricing Master
              </Typography>
              <Box>
                <Stack spacing={3} rowGap={3}>
                  {gemstoneCombination.map((obj, index) => {
                    let arr = Object.values(obj) || [];
                    let showObj = {
                      Quality: obj?.colorstone_quality,
                      Type: obj?.colorstone_type,
                      Shape: obj?.colorstone_shape,
                      Size: obj?.colorstone_size,
                    };

                    return (
                      <Stack
                        direction={"row"}
                        gap="10px"
                        key={index}
                        alignItems="end"
                      >
                        {index + 1}
                        {Object.keys(showObj || {})?.map((key) => {
                          return (
                            <Stack key={key}>
                              <Typography
                                sx={{ ...secondaryLabel, textAlign: "center" }}
                              >
                                {key}
                              </Typography>
                              <Chip
                                label={formatServerValue(showObj[key] || "")}
                              />
                            </Stack>
                          );
                        })}
                        <TextField
                          size="small"
                          placeholder="Rate"
                          type={"number"}
                          onChange={(e) => {
                            setGemstoneCombinations((pre) => ({
                              ...pre,
                              [index]: { rate: e.target.value },
                            }));
                          }}
                        />
                      </Stack>
                    );
                  })}
                </Stack>
              </Box>
            </Box>
          ) : null}
          {!details?.labour?.valid ? (
            <>
              {!details?.gemstone?.valid || !details?.diamond?.valid ? (
                <Divider />
              ) : null}
              <Typography
                sx={{ ...primaryLabel, mt: 3, mb: 3 }}
                color={"error.main"}
              >
                {giveLabourStatment(details?.labour)}
              </Typography>
              <Stack rowGap={6}>
                <Stack direction={"row"} alignItems="center" gap={"10px"}>
                  {isArrayWithValues(details?.labour?.collections) ? (
                    <Chip
                      label={details?.labour?.collections?.map((obj) =>
                        formatServerValue(obj?.name || "")
                      )}
                    />
                  ) : null}
                  {isArrayWithValues(details?.labour?.categories) ? (
                    <Chip
                      label={details?.labour?.categories?.map((obj) =>
                        formatServerValue(obj?.name || "")
                      )}
                    />
                  ) : null}
                  {isArrayWithValues(details?.labour?.subcategory) ? (
                    <Chip
                      label={details?.labour?.subcategory?.map((obj) =>
                        formatServerValue(obj?.name || "")
                      )}
                    />
                  ) : null}
                </Stack>
                <Stack direction={"row"} alignItems="center" gap={"10px"}>
                  <TextField
                    size="small"
                    label="Title*"
                    value={labourObj?.title || ""}
                    onChange={(e) => onEditLabour({ title: e.target.value })}
                    error={labourError?.title}
                    helperText={labourError?.title ? "Title is required" : ""}
                  />
                  <TextField
                    sx={{ minWidth: "100px" }}
                    size="small"
                    value={labourObj?.labour_from || ""}
                    select
                    // placeholder="From"
                    label="From"
                    onChange={(e) =>
                      onEditLabour({ labour_from: e.target.value })
                    }
                  >
                    <MenuItem value="gross">Gross</MenuItem>
                    <MenuItem value="net">Net</MenuItem>
                  </TextField>
                  <TextField
                    size="small"
                    type={"number"}
                    // placeholder="Wastage"
                    label="Wastage"
                    value={labourObj?.wastage || ""}
                    onChange={(e) => onEditLabour({ wastage: e.target.value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          On Weight
                        </InputAdornment>
                      ),
                    }}
                    error={labourError?.wastage}
                    helperText={
                      labourError?.wastage ? "Wastage is required" : ""
                    }
                  />
                  <TextField
                    size="small"
                    type={"number"}
                    // placeholder="Per gram"
                    label="Per gram*"
                    value={labourObj?.per_gram || ""}
                    onChange={(e) => onEditLabour({ per_gram: e.target.value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {currencySymbols[default_currency]}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          On Labour
                        </InputAdornment>
                      ),
                    }}
                    error={labourError?.per_gram}
                    helperText={
                      labourError?.per_gram ? "Per gram is required" : ""
                    }
                  />
                  <TextField
                    size="small"
                    type={"number"}
                    value={labourObj?.minimum_making || ""}
                    // placeholder="Min Making"
                    label="Min Making"
                    onChange={(e) =>
                      onEditLabour({ minimum_making: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {currencySymbols[default_currency]}
                        </InputAdornment>
                      ),
                    }}
                    error={labourError?.minimum_making}
                    helperText={
                      labourError?.minimum_making
                        ? "minimum_making is required"
                        : ""
                    }
                  />
                </Stack>
                {labourError?.price ? (
                  <Typography color={"error.main"} textAlign="center">
                    {" "}
                    In Labour you must add ...{" "}
                  </Typography>
                ) : null}
              </Stack>
            </>
          ) : null}
        </DialogContent>
      ) : (
        <DialogContent>
          <AddDiamondGroup
            setShowDiamondGroup={setShowDiamondGroup}
            productSettings={productSettings}
            dispatch={dispatch}
            showDiamondGroup={showDiamondGroup}
            allGroups={allDiamondGroups}
          />
        </DialogContent>
      )}
      {!showDiamondGroup && (
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={loading} onClick={addMasters} autoFocus>
            Add
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

const AddDiamondGroup = ({
  setShowDiamondGroup,
  productSettings,
  showDiamondGroup,
  dispatch,
  allGroups,
}) => {
  const [addForm, setAddForm] = useState({ shape: showDiamondGroup?.shape });
  const [allShapes, setAllShapes] = useState([]);
  const [weightsDropdown, setWeightsDropdown] = useState([]);
  const [toWeightsDropdown, setToWeightsDropdown] = useState([]);
  const [optionsHasError, setOptionsHasError] = useState(false);
  const editAddForm = (payload) => {
    setAddForm((pre) => ({ ...pre, ...payload }));
  };
  console.log(showDiamondGroup, productSettings.diamond_shapes);
  useEffect(() => {
    if (isArrayWithValues(productSettings.diamond_shapes))
      setAllShapes(productSettings.diamond_shapes);
  }, [productSettings]);
  useEffect(() => {
    if (showDiamondGroup?.shape)
      setAddForm((pre) => ({ ...pre, shape: showDiamondGroup?.shape }));
  }, [showDiamondGroup?.shape, allShapes]);
  console.log(allGroups);
  useEffect(() => {
    if (addForm.shape) {
      let allWeights = [...(allDiamondWeights[addForm.shape] || [])];
      if (!isArrayWithValues(allWeights) && !isArrayWithValues(allGroups))
        return setWeightsDropdown([]);
      if (!addForm.id) {
        for (let group of Object.values(allGroups)) {
          let { shape, from, to } = group;
          if (shape !== addForm.shape) continue;
          let array = [...allWeights];
          let startIndex = array.findIndex((i) => {
            return Number(i.wt) == from;
          });
          let endIndex = array.findIndex((i) => Number(i.wt) == to);
          console.log(startIndex, endIndex);
          if (startIndex >= 0 && endIndex >= 0) {
            let deleted = allWeights.splice(startIndex, endIndex - startIndex);
            console.log(deleted);
          }
        }
      }
      if (addForm.from) {
        const startIndex = 0;
        let endIndex = allWeights.findIndex((i) => {
          return Number(i.wt) == addForm.from;
        });
        let tempWeights = [...allWeights];
        tempWeights.splice(startIndex, endIndex - startIndex + 1);
        setToWeightsDropdown(tempWeights);
      }
      setWeightsDropdown(allWeights);
    } else setWeightsDropdown([]);
  }, [addForm?.shape, addForm?.from, allGroups]);

  const onAddDimaond = () => {
    // setLoading(true);
    let obj = {};
    let isUpdate = addForm.id;
    if (isUpdate) {
      let array = allDiamondWeights[addForm.shape];
      let fromObj = array.find((i) => i.wt == addForm.from);
      let toObj = array.find((i) => i.wt == addForm.to);
      obj = { [addForm.id]: { ...addForm, fromObj, toObj } };
    } else {
      let array = allDiamondWeights[addForm.shape];
      let fromObj = array.find((i) => i.wt == addForm.from);
      let toObj = array.find((i) => i.wt == addForm.to);
      let time = Math.floor(Date.now() / 1000);
      let id = `${time}_${getRandomString(5)}`;
      console.log(
        validateNumber(addForm.from),
        validateNumber(addForm.to),
        validateNumber(showDiamondGroup?.ct)
      );
      if (
        validateNumber(addForm.from) >= validateNumber(showDiamondGroup?.ct) &&
        validateNumber(addForm.to) > validateNumber(showDiamondGroup?.ct)
      ) {
        console.log("error");
        setOptionsHasError(true);
        return;
      }
      console.log("success");
      return;
      obj = {
        [id]: {
          id,
          fromObj,
          toObj,
          ...addForm,
        },
      };
    }

    // console.log(obj);
    // return;
    dispatch(
      addDiamondGroup(
        obj,
        () => {
          // setShowAdd(false);
          dispatch(
            setGlobalToast({
              show: true,
              severity: "success",
              message: `Diamond group ${
                !isUpdate ? "added" : "updated"
              } successfully`,
            })
          );
          setShowDiamondGroup(false);
          // setAddForm(defaultState);
          // setLoading(false);
        },
        () => {
          dispatch(
            setGlobalToast({
              show: true,
              severity: "error",
              message: `Couldn't ${
                !isUpdate ? "add" : "update"
              } Diamond group!`,
            })
          );
        }
      )
    );
  };

  return (
    <>
      <Stack direction={"row"} gap={"10px"}>
        {showDiamondGroup?.diamond?.map((str) =>
          str ? <Chip key={str} label={formatServerValue(str || "")} /> : ""
        )}
      </Stack>
      <Grid container spacing={4} mt={2}>
        <Grid sx={{ mb: 2 }} item xs={12} md={12}>
          <TextField
            variant="standard"
            value={addForm.title}
            onChange={(e) => editAddForm({ title: e.target.value })}
            label="Title"
            size="small"
            autoFocus
            fullWidth
            // disabled={!isDefaultCurrency}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Shapes</InputLabel>
            <Select
              // disabled
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Shapes"
              value={addForm?.shape}
              onChange={(e) => editAddForm({ shape: e.target.value })}
            >
              {isArrayWithValues(allShapes) &&
                allShapes.map((i) => {
                  return <MenuItem value={i.value}>{`${i.label}`}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">From</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="From"
              // size="small"
              value={addForm.from}
              onChange={(e) => {
                setOptionsHasError(false);
                editAddForm({ from: e.target.value });
              }}
              MenuProps={{ sx: { maxHeight: "300px" }, autoFocus: false }}
            >
              {/* <ListSubheader>
                    <TextField
                      size="small"
                      // Autofocus on textfield
                      autoFocus
                      placeholder="Type to search..."
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setFromSearch(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          // Prevents autoselecting item while typing (default Select behaviour)
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader> */}
              {/* {isArrayWithValues(allDiamondWeights[addForm.shape]) &&
                    allDiamondWeights[addForm.shape].map((i) => { */}
              {isArrayWithValues(weightsDropdown) &&
                weightsDropdown.map((i) => {
                  return (
                    <MenuItem value={i.wt}>{`${i.wt} ct. ${
                      i.sieve ? `(${i.sieve}) ` : ""
                    } ${i.size ? `(${i.size} mm) ` : ""}`}</MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">To</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="To"
              value={addForm.to}
              onChange={(e) => {
                setOptionsHasError(false);
                editAddForm({ to: e.target.value });
              }}
              MenuProps={{ sx: { maxHeight: "300px" }, autoFocus: false }}
            >
              {isArrayWithValues(toWeightsDropdown) &&
                toWeightsDropdown.map((i) => {
                  return (
                    <MenuItem value={i.wt}>{`${i.wt} ct. ${
                      i.sieve ? `(${i.sieve}) ` : ""
                    } ${i.size ? `(${i.size} mm) ` : ""}`}</MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        {optionsHasError ? (
          <Grid item xs={12}>
            <Typography textAlign={"center"} color={"error.main"}>
              Diamond Group must contain{" "}
              {validateNumber(showDiamondGroup?.ct?.toFixed(3))} ct{" "}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <DialogActions>
        <Button onClick={() => setShowDiamondGroup(false)}>Cancel</Button>
        <LoadingButton onClick={onAddDimaond} autoFocus>
          Add
        </LoadingButton>
      </DialogActions>
    </>
  );
};
const AddDiamond = ({
  obj,
  index,
  allDiamondGroups,
  setDiamondObj,
  setShowDiamondGroup,
  setDiamondError,
  diamondError,
}) => {
  const [diamondSizes, setDiamondSizes] = useState([]);
  // const
  const [diamond, setDiamond] = useState({
    clarities: [],
    colors: [],
    sieves: [],
    types: [],
    rate: "",
  });

  let {
    diamond_type,
    diamond_quality,
    diamond_shape,
    diamond_pieces,
    diamond_weight,
    diamond_cut,
  } = obj || {};
  useEffect(() => {
    console.log(
      getDiamondGroup(
        allDiamondGroups,
        diamond_weight,
        diamond_pieces,
        diamond_shape
      )
    );
  }, [obj, allDiamondGroups]);
  let diamondGroup = getDiamondGroup(
    allDiamondGroups,
    diamond_weight,
    diamond_pieces,
    diamond_shape
  );
  let showObj = {
    Type: obj?.diamond_type,
    Quality: obj?.diamond_quality,
    Shape: obj?.diamond_shape,

    Ct:
      validateNumber(
        (Number(diamond_weight || 0) / Number(diamond_pieces || 0))
          .toFixed(3)
          .toString()
      ) + "ct",
    "SS Group": diamondGroup?.title,
  };
  let arr = Object.keys(showObj) || [];

  useEffect(() => {
    if (isObjWithValues(obj)) {
      let claritiesObj = {
        label: diamond_quality?.split("-")?.[0],
        value: diamond_quality?.split("-")?.[0],
      };
      console.log(diamond_quality);
      let colorsObj = {
        label: diamond_quality?.split("-")?.[1] || "",
        value: diamond_quality?.split("-")?.[1] || "",
      };
      let dimondObj = {
        clarities: [claritiesObj],
        colors: [colorsObj],
        sieves: [{ label: diamondGroup?.title, value: diamondGroup?.id }],
        types: [
          { label: formatServerValue(diamond_type || ""), value: diamond_type },
        ],
        rate: "",
        shapes: [
          {
            label: formatServerValue(diamond_shape || ""),
            value: diamond_shape,
          },
        ],
      };
      if (diamond_cut) {
        let str = diamond_cut || "";
        let strArr = str.split(",");
        dimondObj.cuts = strArr.map((s) => ({
          label: formatServerValue(s),
          value: s,
        }));
      }
      setDiamond(dimondObj);
      setDiamondObj((pre) => ({ ...pre, [index]: dimondObj }));
    }
  }, [obj]);

  const onEditDiamond = (e, key) => {
    let { name } = e.target;
    // console.log(name);

    setDiamond((pre) => ({ ...pre, [name]: e.target.value }));
    let obj = { ...diamond, [name]: e.target.value };
    setDiamondObj((pre) => ({ ...pre, [index]: obj }));
    setDiamondError({});
  };
  return (
    <Stack sx={{ my: 3 }} rowGap={"8px"}>
      {!isObjWithValues(diamondGroup) && false && (
        <Stack direction={"row"} gap={"10px"} alignItems="center">
          <Typography variant="h6"> {index + 1}</Typography>

          <TextField label="Title" size="small" />
          {/* <Grid item> */}
          <TextField
            select
            label="From"
            size="small"
            sx={{ minWidth: "200px" }}
          ></TextField>
          {/* </Grid> */}
          {/* <Grid item> */}
          <TextField
            select
            label="To"
            size="small"
            sx={{ minWidth: "200px" }}
          ></TextField>
          {/* </Grid> */}
        </Stack>
      )}
      <Stack direction={"row"} alignItems="end" gap="10px">
        {" "}
        {index + 1}
        {arr.map((str) =>
          showObj[str] ? (
            <Stack alignItems={"center"}>
              <Typography sx={{ ...secondaryLabel }}>
                {str}
                {/* {formatServerValue(str || "")} */}
              </Typography>
              <Chip key={str} label={formatServerValue(showObj[str] || "")} />{" "}
            </Stack>
          ) : (
            ""
          )
        )}
        {!isObjWithValues(diamondGroup) && (
          <Chip
            icon={<Add />}
            label={"Add Diamond Group"}
            color={diamondError[index]?.sieve ? "error" : "primary"}
            onClick={() =>
              setShowDiamondGroup({
                diamond: Object.values(showObj || {}),
                shape: diamond_shape,
                ct:
                  Number(diamond_weight || 0) /
                  Number(diamond_pieces || 0).toFixed(3),
              })
            }
          />
        )}
        <TextField
          size="small"
          placeholder="Rate"
          name="rate"
          label="Rate per ct.*"
          // value={}
          onChange={(e) => onEditDiamond(e)}
          error={diamondError[index]?.rate}
          helperText={diamondError[index]?.rate ? "Rate is required" : ""}
        />
      </Stack>
    </Stack>
  );
};

const getDiamondGroup = (allDiamondGroups, weight, pieces, selectedShape) => {
  if (isObjWithValues(allDiamondGroups)) {
    if (weight && pieces) {
      // console.log(, allDiamondGroups);
      let netWeight = Number(Number(weight) / Number(pieces));
      let diamondGroup = Object.values(allDiamondGroups);
      // console.log(diamondGroup);
      let obj = diamondGroup.find((i) => {
        // if (i.shape === selectedShape) {
        //   console.log(i.from, netWeight, i.to);
        //   console.log(
        //     i.title,
        //     i.shape === selectedShape,
        //     netWeight >= Number(i.from),
        //     netWeight <= Number(i.to)
        //   );
        // }
        return (
          i.shape === selectedShape &&
          netWeight >= Number(i.from) &&
          netWeight <= Number(i.to)
        );
      });
      if (!obj)
        obj = diamondGroup.find((i) => {
          let netWeightFixed2 = Number(netWeight).toFixed(2);
          return (
            i.shape === selectedShape &&
            netWeightFixed2 >= Number(i.from) &&
            netWeightFixed2 <= Number(i.to)
          );
        });
      console.log(obj, "<<< AUTO SIEVE OBJ");
      if (obj) {
        return obj;
        // onSizeChange({ target: { value: obj.id } });
        // if (selectedCut) setSelectedCut({ target: { value: selectedCut } });
      } else {
        return {};
        // setSelectedSize("");
        // setSelectedCut("");
        // setRate("");
        // setDiamondCuts([]);
      }
    }
  }
};

export const SearchRow = ({ supplierProducts }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();
  const [searchBy, setSearchBy] = useState("search");
  const [paramFilters, setParamFilters] = useState({});
  const [filterView, setFilterView] = useState(false);
  const designbankSearch = useSelector(
    (state) => state.product?.designbankSearch
  );

  // const [allProducts, setAllProducts] = useState(allProducts)
  const allProducts = useSelector(
    (state) => state.supplier?.filterObjectProducts
  );
  let supplierProductsFilters = useSelector(
    (state) => state.supplier?.supplierProductsFilters
  );
  const reduxdesignbankfilters =
    useSelector((state) => state.product?.designbankfilters) || {};
  const [params, setParams] = useState({
    search: "",
    page: 1,
    per_page: 100,
  });
  // useEffect(() => {
  //   if (id && supplierProducts) {
  //     setAllProducts(supplierProducts)
  //   } else setAllProducts(supplierProductsFilters)
  // }, [reduxAllProducts, supplierProducts, id])

  useEffect(() => {
    if (id) {
      let filters = {
        suppliers: [],
        collections: [],
        categories: [],
        gold_kt: [],
        gold_gross: [],
        diamond_quality: [],
      };
      for (const product of Object.values(supplierProducts || {})) {
        if (filterCondition(filters.collections, product?.collections))
          filters.collections = [
            ...filters.collections,
            {
              value: product?.collections,
              label: formatServerValue(product?.collections),
            },
          ];
        if (filterCondition(filters.categories, product?.categories))
          filters.categories = [
            ...filters.categories,
            {
              value: product?.categories,
              label: formatServerValue(product?.categories),
            },
          ];
        if (filterCondition(filters.gold_kt, product?.gold_kt))
          filters.gold_kt = [
            ...filters.gold_kt,
            {
              value: product?.gold_kt,
              label: goldName[product?.gold_kt],
            },
          ];
        if (product?.diamond_quality) {
          // console.log(filters?.diamond_quality);
          let diamondArr = product?.diamond_quality?.split(",") || [];
          for (let index = 0; index < diamondArr.length; index++) {
            const element = diamondArr[index];
            if (filterCondition(filters.diamond_quality, element))
              filters.diamond_quality = [
                ...filters.diamond_quality,
                {
                  value: element,
                  label: formatServerValue(element)?.toUpperCase(),
                },
              ];
          }
        }
      }
      supplierProductsFilters = filters;
    }
  }, [supplierProducts, supplierProductsFilters]);

  useEffect(() => {
    if (isObjWithValues(designbankSearch)) {
      setSearchBy(designbankSearch?.searchBy || "search");
      setParams((pre) => ({ ...pre, search: designbankSearch?.search || "" }));
    }
  }, [designbankSearch]);

  const fetchProducts = () => {
    console.log({
      ...params,
      searchBy,
      products: allProducts,
      filter: paramFilters || {},
    });
    // if (e.key === "Enter") {
    dispatch(
      getDesignBankProduct({
        ...params,
        searchBy,
        products: allProducts,
        filter: paramFilters || {},
      })
    );
    // }
  };
  useEffect(() => {
    if (id && isArrayWithValues(allProducts)) {
      console.log(id);
      dispatch(
        getDesignBankProduct({
          ...params,
          searchBy,
          products: allProducts,
          filter: paramFilters || {},
          id: id,
        })
      );
      setParams((pre) => ({ ...pre, id: id }));
    }
  }, [id, allProducts, supplierProductsFilters]);

  const handleDelete = (str) => {
    console.log(str);
    let filters = { ...paramFilters };
    console.log(Object.keys(paramFilters));
    let keysArr = Object.keys(paramFilters);
    for (let index = 0; index < keysArr.length; index++) {
      const element = keysArr[index];
      if (filters[element].includes(str)) {
        filters[element] = filters[element].filter((s) => s !== str);
      }
      if (!isArrayWithValues(filters[element])) {
        // console.log("hi");
        delete filters[element];
      }
    }
    setParamFilters(filters);
    console.log(filters);
    dispatch(
      getDesignBankProduct({
        ...params,
        // page: page,
        filter: filters,
        products: allProducts,
      })
    );
  };
  return (
    <Paper sx={{ mt: 3, px: 3, py: 5, borderRadius: "8px" }}>
      <Grid container spacing={2}>
        <Grid item xs={10} md={2}>
          <TextField
            fullWidth
            select
            // size="small"
            id="demo-simple-select"
            // variant="standard"
            value={searchBy || ""}
            onChange={(e) => {
              setSearchBy(e.target.value);
              if (params?.search) fetchProducts();
            }}
            size="medium"
            InputProps={{
              sx: { borderRadius: "10px", maxHeight: "44px" },
            }}
            displayEmpty
          >
            <MenuItem value={"search"} defaultChecked>
              <ListItemText primary={`Title`} />
            </MenuItem>
            <MenuItem value={"sku"}>
              <ListItemText primary={`Sku`} />
            </MenuItem>
          </TextField>
          {/* <TextField
            fullWidth
            select
            size="medium"
            placeholder="Sort by"
            InputProps={{
              sx: { borderRadius: "10px", maxHeight: "44px" },
            }}
          >
            {allSortBy.map((obj) => {
              return (
                <MenuItem
                  key={obj.value}
                  value={obj.value}
                  sx={{
                    borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                  }}
                >
                  <Checkbox
                  // checked={sortBy.indexOf(obj.value) > -1}
                  />
                  <ListItemText primary={`Sort by: ${obj.label}`} />
                </MenuItem>
              );
            })}
          </TextField> */}
        </Grid>
        {isIpad ? (
          <Grid item xs={2}>
            <IconButton
              onClick={() => setFilterView(true)}
              sx={{
                borderRadius: "5px",
                border: "1px solid",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
              className=""
            >
              <FilterAlt />
            </IconButton>
          </Grid>
        ) : null}
        <Grid item xs md>
          <TextField
            value={params?.search || ""}
            onChange={(e) =>
              setParams((pre) => ({ ...pre, search: e.target.value }))
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                fetchProducts();
              }
            }}
            variant="outlined"
            placeholder={`Search ${
              searchBy === "search" ? "Title" : searchBy === "sku" ? "Sku" : ""
            }`}
            InputProps={{
              sx: { borderRadius: "10px" },

              startAdornment: (
                <InputAdornment position="start">
                  {/* <FormControl size="small" fullWidth sx={{ border: 0 }}> */}

                  {/* </FormControl> */}
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <CornerDownLeft size="18px" />
                </InputAdornment>
              ),
            }}
            //   onKeyDown={onKeyDown}
            inputProps={{
              sx: {
                padding: "12.5px 14px",
                paddingLeft: 0,
              },
            }}
            // sx={{ borderRadius: "10px", border: "0px" }}
            // style={{ borderWidth: 0 }}
            inputMode="search"
            fullWidth
          />
        </Grid>
        {!isIpad && (
          <Grid item xs={0.5}>
            <IconButton
              onClick={() => setFilterView(true)}
              sx={{
                borderRadius: "5px",
                border: "1px solid",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              }}
              className=""
            >
              <FilterAlt />
            </IconButton>

            {/* <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
            ></Select>
          </FormControl> */}
          </Grid>
        )}
      </Grid>
      {/* <FilterRow products={products} params={params} setParams={setParams} /> */}
      {isObjWithValues(reduxdesignbankfilters) && (
        // <TableContainer>
        <Box>
          <Stack
            gap={"10px"}
            direction="row"
            mt={3}
            overflow={"auto"}
            sx={{ width: `calc(100vw - ${sidebarExtraWidth + 115}px )` }}
            className="scrollbar-hidden"
          >
            {Object.values(reduxdesignbankfilters || {})
              .flat()
              .map((str) => (
                <Chip
                  label={formatServerValue(str)}
                  // size="small"
                  onDelete={() => handleDelete(str)}
                />
              ))}
          </Stack>
        </Box>
        // </TableContainer>
      )}
      <Drawer
        sx={{
          "	.MuiDrawer-paperAnchorBottom": {
            borderTopLeftRadius: 9,
            borderTopRightRadius: 9,
          },
          "	.MuiDrawer-paperAnchorRight": {
            height: "calc(100vh - 20px)",
            margin: "10px",
            borderRadius: 4,
          },
        }}
        anchor={isPhone ? "bottom" : "right"}
        open={filterView}
        onClose={() => {
          setFilterView(false);
        }}
      >
        <FiltersOptions
          filterObj={supplierProductsFilters}
          setFilterView={setFilterView}
          paramFilters={paramFilters}
          setParamFilters={setParamFilters}
          allProducts={allProducts}
          params={params}
          setParams={setParams}
          // reduxFetchingDesignbankProduct={reduxFetchingDesignbankProduct}
        />
      </Drawer>
    </Paper>
  );
};
const Actions = () => {
  return (
    <Stack direction="row" justifyContent="flex-end">
      <Select
        value={""}
        // onChange={(e) => setCustomAction(e.target.value)}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        size="small"
        sx={{ mr: 2, minWidth: "170px" }}
        // disabled={numSelected === 0 || isMobile}
      >
        <MenuItem disabled value="">
          <em>Custom actions</em>
        </MenuItem>
        <MenuItem value={"bulk_edit"}>Bulk edit</MenuItem>
        <MenuItem value={"add_to_catalog"}>Add to catalog</MenuItem>
        <MenuItem value={"create_quotation"}>Create Quotation</MenuItem>
        <MenuItem value={"bulk_delete"}>Bulk delete</MenuItem>
      </Select>
      <LoadingButton
        // loading={customActionLoading}
        variant="outlined"
        // disabled={
        //   customAction ? false : true || numSelected === 0 || isMobile
        // }
        // size=""
        sx={{ p: 0, m: 0 }}
        // onClick={() => onCustomActionApply()}
      >
        Apply
      </LoadingButton>
    </Stack>
  );
};
const defaultParams = {
  per_page: 30,
  search: "",
  page: 1,
};
const FilterRow = ({
  products,
  appendProducts,
  params,
  setParams,
  productAccess,
}) => {
  const [mounted, setMounted] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [taxonomies, setTaxonomies] = useState({});
  const [allCollections, setCollections] = useState([]);
  const [allCategories, setCategories] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [allGenders, setAllGenders] = useState([]);
  const [allOcassions, setAllOcassions] = useState([]);
  const [allDiamondWeights, setAllDiamondWeights] = useState([]);
  const [allGoldWeights, setAllGoldWeights] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    collections: [],
    categories: [],
    tags: [],
    genders: [],
    ocassions: [],
    diamond_wt: [],
    gold_wt: [],
  });
  // const [productSettings, setProductSettings] = useState({});
  //himanshu added
  const [productSettings, setProductSettings] = useState({});
  const [goldWtAnchor, setGoldWtAnchor] = React.useState(null);
  const [goldWeightValues, setGoldWeightValues] = useState([0, 200]);
  const [diamondWeightValues, setDiamondWeightValues] = useState([0, 200]);
  const [diamondWtAnchor, setDiamondWtAnchor] = React.useState(null);
  const [diamondQualities, setDiamondQualities] = useState([]);
  const [diamondLabs, setDiamondLabs] = useState([]);
  const [stockStatusOptions, setStockStatusOptions] = useState([
    { label: "In Stock", value: "instock" },
    { label: "Out of Stock", value: "outofstock" },
    { label: "Make to Order", value: "onbackorder" },
  ]);
  const [statusOptions, setStatusOptions] = useState([
    { label: "Publish", value: "publish" },
    { label: "Draft", value: "draft" },
  ]);
  const channeltypes = [
    { label: "Google Shopping", value: "allow_for_google_merchants" },
    { label: "Facebook Shop", value: "_wc_facebook_sync_enabled" },
    { label: "Ornate NX", value: "ornate_unique_id" },
    { label: "Virtual Tryon", value: "try_on_image_id" },
  ];
  const dispatch = useDispatch();
  let { gold_purities } = productSettings;
  const [allFilters, setAllFilters] = useState([]);
  const rows = products;
  const reduxTaxonomies = useSelector(
    (state) => state.product.admin_taxonomies
  );
  const noProductsFound = useSelector((state) => state.product.noProductsFound);
  const fetchedAllProducts = useSelector(
    (state) => state.product.fetchedAllProducts
  );
  const fetchingProducts = useSelector(
    (state) => state.product.fetchingProducts
  );
  const reduxProductSettings = useSelector(
    (state) => state.settings.productSettings
  );

  useEffect(async () => {
    setTimeout(() => {
      setMounted(true);
    }, 400);

    // if (!isObjWithValues(reduxProductSettings))
    //   dispatch(fetchAdminTaxonomies());
  }, []);

  // useEffect(() => {
  //   if (mounted) allFilterChange();
  // }, [allFilters]);

  useEffect(() => {
    if (isObjWithValues(reduxProductSettings))
      setProductSettings({ ...reduxProductSettings });

    if (
      reduxProductSettings &&
      isArrayWithValues(reduxProductSettings.collections)
    )
      setCollections([...reduxProductSettings.collections]);
    if (
      reduxProductSettings &&
      isArrayWithValues(reduxProductSettings.categories)
    )
      setCategories([...reduxProductSettings.categories]);
  }, [reduxProductSettings]);

  useEffect(() => {
    if (reduxTaxonomies && Object.keys(reduxTaxonomies).length > 0)
      setTaxonomies(reduxTaxonomies);
  }, [reduxTaxonomies]);
  useEffect(() => {
    if (mounted) allFilterChange();
  }, [allFilters]);
  useEffect(() => {
    if (isObjWithValues(taxonomies)) {
      let {
        collections,
        categories,
        tags,
        gender,
        ocassions,
        diamond_wt,
        gold_wt,
      } = taxonomies;
      if (
        isArrayWithValues(collections) &&
        (!productSettings ||
          (productSettings && !isObjWithValues(productSettings.collections)))
      )
        setCollections(collections);
      if (
        isArrayWithValues(categories) &&
        (!productSettings ||
          (productSettings && !isObjWithValues(productSettings.categories)))
      )
        setCategories(categories);
      if (isArrayWithValues(tags)) setAllTags(tags);
      if (isArrayWithValues(gender)) setAllGenders(gender);
      if (isArrayWithValues(ocassions)) setAllOcassions(ocassions);
      if (isArrayWithValues(diamond_wt)) setAllDiamondWeights(diamond_wt);
      if (isArrayWithValues(gold_wt)) setAllGoldWeights(gold_wt);
    }
    //eslint-disable-next-line
  }, [taxonomies]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const getProductSettings = async () => {
  //   const website = getWebsite();
  //   const token = getToken();
  //   if (website && token) {
  //     let res = await axios({
  //       url: `${website}/wp-json/store/v1/settings/business_settings`,
  //       headers: {
  //         Authorization: `Basic ${token}`,
  //       },
  //     });
  //     let { data } = res;
  //     if (
  //       data.success &&
  //       isObjWithValues(data.data) &&
  //       isObjWithValues(data.data.business_settings)
  //     )
  //       dispatch({
  //         type: SET_PRODUCT_SETTINGS,
  //         payload: { ...data.databusiness_settings },
  //       });
  //   }
  // };

  const handleClick = (event, id) => {
    const selectedIndex = selected?.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (Array.isArray(products) && products.length > 0) {
      let currentPage = newPage + 1;
      if (products.length / rowsPerPage === currentPage) {
        appendProducts();
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onFilterChange = (label, value) => {
    if (Array.isArray(selectedFilters[label]) && Array.isArray(value)) {
      let isAdding = selectedFilters[label].length < value.length;
      setSelectedFilters({ ...selectedFilters, [label]: value });
      if (isAdding) {
        let obj = getObjectByLabel(label, value[value.length - 1]);
        if (obj) setAllFilters(allFilters.concat({ ...obj, key: label }));
      } else {
        let oldArray = selectedFilters[label];
        let removed = "";
        for (let i = 0; i < oldArray.length; i++) {
          if (value.indexOf(oldArray[i]) === -1) {
            removed = oldArray[i];
            break;
          }
        }
        if (removed) {
          let updatedAllFilters = allFilters.filter(
            (i) => i.key === label && i.label !== removed
          );
          setAllFilters(updatedAllFilters);
        }
      }
    }
  };

  const removeFromAllFilter = (obj) => {
    let updatedAllFilters = allFilters.filter((i) =>
      i.key === obj.key && i.label === obj.label ? false : true
    );
    setAllFilters(updatedAllFilters);
    if (isArrayWithValues(selectedFilters[obj.key])) {
      let updatedFilter = selectedFilters[obj.key].filter(
        (i) => i !== obj.label
      );
      setSelectedFilters({ ...selectedFilters, [obj.key]: updatedFilter });
    }
  };

  const getObjectByLabel = (key, label) => {
    let data = [];
    data =
      key === "collections"
        ? allCollections
        : key === "categories"
        ? allCategories
        : key === "tags"
        ? allTags
        : key === "genders"
        ? allGenders
        : key === "ocassions"
        ? allOcassions
        : key === "diamond_wt"
        ? allDiamondWeights
        : key === "gold_wt"
        ? allGoldWeights
        : key === "gold_kt"
        ? gold_purities
        : key === "diamond"
        ? diamondQualities
        : key === "diamond_lab"
        ? diamondLabs
        : key === "_stock_status"
        ? stockStatusOptions
        : key === "status"
        ? statusOptions
        : key === "channel_type"
        ? channeltypes
        : [];
    if (isArrayWithValues(data)) {
      return data.find((i) => i.label === label) || false;
    }
  };

  const allFilterChange = () => {
    let websiteTaxonomies = { ...reduxTaxonomies };
    let paramValues = [
      // { param_value: "stock_status", label: "" },
      { param_value: "status", label: "status" },
      { param_value: "collections", label: "collections" },
      { param_value: "category", label: "categories" },
      { param_value: "subcategory", label: "sub-categories" },
      { param_value: "pa_gender", label: "genders" },
      { param_value: "pa_by-diamond-wt", label: "diamond_wt" },
      { param_value: "pa_by-weight", label: "gold_wt" },
      { param_value: "pa_ocassions", label: "ocassions" },
      { param_value: "tags", label: "tags" },
      { param_value: "diamond", label: "" },
      { param_value: "gold_kt", label: "" },
      { param_value: "diamond_lab", label: "" },
      { param_value: "gold_gross", label: "" },
      { param_value: "total_diamond_weight", label: "" },
      { param_value: "channel_type", label: "" },
    ];
    const filtersForAttribute = [
      // "collections",
      // "subcategory",
      "pa_gender",
      "pa_by-diamond-wt",
      "pa_by-weight",
      "pa_ocassions",
    ];
    let filterParams = {
      // attribute_term: "collections,pa_gender,pa_ocassions",
      // attributes: `205,250, 165,151`,
      // attributes: [],
      // attribute_term: [],
      attributes: "",
      attribute_term: "",
      // search: params.search,
      custom_filter: [],
      ...defaultParams,
    };
    let { attribute_term, attributes } = filterParams;
    let customFilterFlag = 0;

    let getFilterId = (taxonomies, key, slug) => {
      if (!isArrayWithValues(taxonomies[key])) return;
      let obj = taxonomies[key].find(
        (i) => i.value === slug || i.slug === slug
      );
      if (obj) return obj.id;
      return false;
    };

    // console.log(allFilters, websiteTaxonomies, "all filter");
    for (let i = 0; i < allFilters.length; i++) {
      let obj = allFilters[i];

      let id = getFilterId(websiteTaxonomies, obj.key, obj.value || obj.slug);
      if (id) obj._id = id;

      let paramValueObj = paramValues.find((i) => i.label === obj.key);
      if (paramValueObj && paramValueObj.param_value) {
        let { param_value } = paramValueObj;
        if (filtersForAttribute.includes(param_value)) {
          if (!attributes.includes(param_value))
            attributes = attributes
              ? attributes.concat(`, ${param_value}`)
              : param_value;
          attribute_term = attribute_term
            ? attribute_term.concat(`, ${obj._id || obj.value}`)
            : `${obj._id || obj.value}`;
          filterParams = {
            attributes,
            attribute_term,
          };
          // attribute_term = `${attribute_term} ${attribute_term ? "" : ","} ${
          //   obj._id || obj.value
          // }`;
        } else {
          let str = filterParams[param_value];
          str = str
            ? str.concat(`, ${obj._id || obj.value}`)
            : `${obj._id || obj.value}`;
          filterParams[param_value] = str;
          // if (isArrayWithValues(filterParams[param_value]))
          // else filterParams[param_value] = [obj.id || obj.value];
        }
      } else {
        let comparekeys = ["gold_gross", "total_diamond_weight"];
        if (obj.key === "channel_type") {
          if (obj.value === "allow_for_google_merchants") {
            filterParams[`custom_filter[${customFilterFlag}][key]`] = obj.value;
            filterParams[`custom_filter[${customFilterFlag}][value]`] = "1";
          } else if (obj.value === "_wc_facebook_sync_enabled") {
            filterParams[`custom_filter[${customFilterFlag}][key]`] = obj.value;
            filterParams[`custom_filter[${customFilterFlag}][value]`] = "yes";
          } else if (obj.value === "ornate_unique_id") {
            filterParams[`custom_filter[${customFilterFlag}][key]`] = obj.value;
            filterParams[`custom_filter[${customFilterFlag}][value]`] = "";
            filterParams[`custom_filter[${customFilterFlag}][compare]`] =
              "LIKE";
          } else if (obj.value === "try_on_image_id") {
            filterParams[`custom_filter[${customFilterFlag}][key]`] = obj.value;
            filterParams[`custom_filter[${customFilterFlag}][value]`] = "";
            filterParams[`custom_filter[${customFilterFlag}][compare]`] =
              "LIKE";
          }
        } else {
          filterParams[`custom_filter[${customFilterFlag}][key]`] = obj.key;
          filterParams[`custom_filter[${customFilterFlag}][value]`] = obj.value;
          filterParams[`custom_filter[${customFilterFlag}][compare]`] =
            comparekeys.includes(obj.key) ? "BETWEEN" : "LIKE";
        }
        customFilterFlag++;
        // if (obj.key === "diamond") {
        //   filterParams.custom_filter.push({
        //     key: "diamond",
        //     value: obj.value,
        //     compare: "LIKE",
        //   });
        // }
      }
    }
    if (isObjWithValues(params)) {
      let tempObj = { ...params, ...defaultParams };
      for (let i = 0; i < paramValues.length; i++) {
        if (paramValues[i].param_value)
          delete tempObj[paramValues[i].param_value];
      }
      for (let key in tempObj)
        if (key.includes("custom_filter")) delete tempObj[key];

      filterParams = { ...filterParams, ...tempObj };

      setParams({ search: params?.search, ...filterParams });
    }
    // return;
    // console.log(filterParams);
    // if (isArrayWithValues(filterParams.custom_filter)) {
    //   let { custom_filter } = filterParams;
    //   let obj = {};
    //   for (let i = 0; i < custom_filter.length; i++) {
    //     let { key, value, compare } = custom_filter[i];
    //     obj[`custom_filter[${i}][key]`] = key;
    //     obj[`custom_filter[${i}][value]`] = value;
    //     if (compare) obj[`custom_filter[${i}][compare]`] = compare;
    //   }
    //   filterParams = { ...obj, ...filterParams };
    // }
    // delete filterParams.custom_filter;
    if (allFilters.length === 0) {
      let withoutFilterParams = { ...defaultParams };
      if (params?.order)
        withoutFilterParams = { ...withoutFilterParams, order: params?.order };
      if (params?.orderBy)
        withoutFilterParams = {
          ...withoutFilterParams,
          orderBy: params.orderBy,
        };
      dispatch(
        fetchAllProducts(
          { ...withoutFilterParams, search: params?.search },
          true,
          true
        )
      );
    } else {
      if (!filterParams.attribute_term) delete filterParams.attribute_term;
      if (!filterParams.attributes) delete filterParams.attributes;
      dispatch(fetchAllProducts(filterParams, true));
    }
  };
  const goldWtopen = Boolean(goldWtAnchor);
  const id = goldWtopen ? "simple-popover" : undefined;
  const diamondWtopen = Boolean(diamondWtAnchor);
  const diamondId = diamondWtopen ? "simple-popover" : undefined;
  const goldWtAnchorClose = () => {
    setGoldWtAnchor(null);
  };
  const diamondWtAnchorClose = () => {
    setDiamondWtAnchor(null);
  };

  const addGoldWeightInFilter = () => {
    let value = goldWeightValues;
    if (!isArrayWithValues(value)) return;
    let obj = {
      key: "gold_gross",
      label: `${value[0]} - ${value[1]} grams`,
      value,
    };
    let filters = [...allFilters];
    if (filters.find((i) => i.key === "gold_gross")) {
      let index = filters.findIndex((i) => i.key === "gold_gross");
      filters[index] = obj;
      setAllFilters(filters);
    } else setAllFilters(filters.concat(obj));
    goldWtAnchorClose();
  };
  const addDiamondWeightInFilter = () => {
    let value = diamondWeightValues;
    if (!isArrayWithValues(value)) return;
    let obj = {
      key: "total_diamond_weight",
      label: `${value[0]} - ${value[1]} carats`,
      value,
    };
    let filters = [...allFilters];
    if (filters.find((i) => i.key === "total_diamond_weight")) {
      let index = filters.findIndex((i) => i.key === "total_diamond_weight");
      filters[index] = obj;
      setAllFilters(filters);
    } else setAllFilters(filters.concat(obj));
    diamondWtAnchorClose();
  };

  return (
    <div>
      <Paper>
        <div
          style={{
            display: "flex",
            // flexWrap: "nowrap",
            overflow: "auto",
            marginLeft: "10px",
            alignItems: "center",
          }}
          className="scrollbar-hidden"
        >
          {/* <div
            style={{
              display: "flex",
              // overflow: "auto",

              marginLeft: "10px",
              whiteSpace: "nowrap",
            }}
          > */}
          {/* {isArrayWithValues(allCollections) && allCollections.length > 1 && (
              )} */}
          <FilterDropdown
            data={allCollections}
            minWidth={"100px"}
            label="Collections"
            value={selectedFilters.collections}
            onChange={(e) => onFilterChange("collections", e.target.value)}
          />
          {isArrayWithValues(allCategories) && allCategories.length > 1 && (
            <FilterDropdown
              data={allCategories}
              label="Categories"
              minWidth={"100px"}
              value={selectedFilters.categories}
              onChange={(e) => onFilterChange("categories", e.target.value)}
            />
          )}
          {/* <FilterDropdown
              data={allTags}
              label="Tags"
              minWidth={"70px"}
              value={selectedFilters.tags}
              onChange={(e) => onFilterChange("tags", e.target.value)}
            />
            <FilterDropdown
              data={allGenders}
              label="Gender"
              minWidth={"85px"}
              value={selectedFilters.genders}
              onChange={(e) => onFilterChange("genders", e.target.value)}
            />
            <FilterDropdown
              data={allOcassions}
              label="Ocassions"
              minWidth={"100px"}
              value={selectedFilters.ocassions}
              onChange={(e) => onFilterChange("ocassions", e.target.value)}
            /> */}
          {/* <FilterDropdown
              data={allDiamondWeights}
              label="Diamond Weights"
              minWidth={"100px"}
              value={selectedFilters.diamond_wt}
              onChange={(e) => onFilterChange("diamond_wt", e.target.value)}
            />
            <FilterDropdown
              data={allGoldWeights}
              label="Gold Weights"
              minWidth={"100px"}
              value={selectedFilters.gold_wt}
              onChange={(e) => onFilterChange("gold_wt", e.target.value)}
            /> */}
          <FilterDropdown
            data={stockStatusOptions || []}
            label="Stock Status"
            minWidth={"100px"}
            value={
              isArrayWithValues(selectedFilters._stock_status)
                ? selectedFilters._stock_status[0]
                : ""
            }
            // onChange={(e) => console.log(e.target.value)}
            onChange={(e) => {
              console.log(e.target.value, e.target.value.length);
              onFilterChange(
                "_stock_status",
                // e.target.value.length > 1
                //   ? [e.target.value[e.target.value.length - 1]]
                //   :
                [e.target.value],
                false
              );
            }}
            multiple={false}
          />
          <FilterDropdown
            data={statusOptions || []}
            label="Product Status"
            minWidth={"100px"}
            value={
              isArrayWithValues(selectedFilters.status)
                ? selectedFilters.status[0]
                : ""
            }
            // onChange={(e) => console.log(e.target.value)}
            onChange={(e) => {
              console.log(e.target.value, e.target.value.length);
              onFilterChange(
                "status",
                // e.target.value.length > 1
                //   ? [e.target.value[e.target.value.length - 1]]
                //   :
                [e.target.value],
                false
              );
            }}
            multiple={false}
          />
          <FilterDropdown
            data={channeltypes}
            label="Channel Types"
            minWidth={"100px"}
            value={selectedFilters.channel_type || []}
            onChange={(e) => onFilterChange("channel_type", e.target.value)}
          />
          <FilterDropdown
            data={gold_purities || []}
            label="Gold Purity"
            minWidth={"100px"}
            value={selectedFilters.gold_kt || []}
            onChange={(e) => onFilterChange("gold_kt", e.target.value)}
          />
          <FilterDropdown
            data={diamondQualities || []}
            label="Diamond Purity"
            minWidth={"100px"}
            value={selectedFilters.diamond || []}
            onChange={(e) => onFilterChange("diamond", e.target.value)}
          />
          <FilterDropdown
            data={diamondLabs || []}
            label="Diamond Labs"
            minWidth={"100px"}
            value={selectedFilters.diamond_lab || []}
            onChange={(e) => onFilterChange("diamond_lab", e.target.value)}
          />
          <Button
            endIcon={<ArrowDropDown />}
            sx={{
              padding: "3px 2px",
              borderRadius: "8px",
              marginLeft: "2px",
              color: "text.secondary",
              whiteSpace: "nowrap",
            }}
            onClick={(e) => setGoldWtAnchor(e.currentTarget)}
          >
            Gold wt
          </Button>
          <Button
            endIcon={<ArrowDropDown />}
            sx={{
              padding: "3px 2px",
              borderRadius: "8px",
              marginLeft: "2px",
              color: "text.secondary",
              whiteSpace: "nowrap",
            }}
            onClick={(e) => setDiamondWtAnchor(e.currentTarget)}
          >
            Diamond wt
          </Button>
          <Popover
            id={id}
            open={goldWtopen}
            anchorEl={goldWtAnchor}
            onClose={goldWtAnchorClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            PaperProps={{
              sx: {
                boxShadow: "0.4px 0.4px 3px 0px rgba(0,0,0,0.25)",
                padding: "14px",
              },
            }}
            style={{}}
          >
            <Box sx={{ p: 6, width: "280px", flex: 1 }}>
              <Typography variant="h6" sx={{ m: 0 }}>
                Gold Weight (in grams)
              </Typography>
              <SliderComponent
                getAriaLabel={() => "Gold Weight"}
                max={200}
                onChangeCommitted={(e) => {
                  setGoldWeightValues(e);
                }}
                step={0.5}
                min={0}
              />
            </Box>
            <Button
              variant="contained"
              size="small"
              sx={{ float: "right", m: 3, mt: -2 }}
              onClick={addGoldWeightInFilter}
            >
              Submit
            </Button>
          </Popover>
          <Popover
            id={diamondId}
            open={diamondWtopen}
            anchorEl={diamondWtAnchor}
            onClose={diamondWtAnchorClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            PaperProps={{
              sx: {
                boxShadow: "0.4px 0.4px 3px 0px rgba(0,0,0,0.25)",
                padding: "14px",
              },
            }}
            style={{}}
          >
            <Box sx={{ p: 6, width: "280px", flex: 1 }}>
              <Typography variant="h6" sx={{ m: 0 }}>
                Diamond Weight (in carat)
              </Typography>
              <SliderComponent
                getAriaLabel={() => "Diamond Weight"}
                max={15}
                onChangeCommitted={(e) => {
                  setDiamondWeightValues(e);
                }}
                step={0.1}
                min={0}
              />
            </Box>
            <Button
              variant="contained"
              size="small"
              sx={{ float: "right", m: 3, mt: -2 }}
              onClick={addDiamondWeightInFilter}
            >
              Submit
            </Button>
          </Popover>
          {/* <Dropdown label="Categories" /> */}
        </div>
        {/* <div style={{ display: "flex", flexWrap: "wrap", margin: "6px 8px" }}> */}

        <div
          style={{
            display: "flex",
            overflow: "auto",
            marginLeft: "10px",
            whiteSpace: "nowrap",
          }}
        >
          {allFilters.map((filter) => {
            // console.log(allFilters, "<<< all filters");
            return (
              <Chip
                key={filter?.label}
                label={filter?.label}
                onDelete={() => removeFromAllFilter(filter)}
                style={{ marginLeft: "6px", marginTop: "6px" }}
              />
            );
          })}
        </div>
        {/* <div style={{ display: "flex", flexWrap: "wrap", margin: "6px 8px" }}> */}
        <div
          style={{
            display: "flex",
            overflow: "auto",
            marginLeft: "10px",
            whiteSpace: "nowrap",
          }}
        >
          {allFilters.map((filter) => {
            return (
              <Chip
                key={filter?.label}
                label={filter?.label}
                onDelete={() => removeFromAllFilter(filter)}
                style={{ marginLeft: "6px", marginTop: "6px" }}
              />
            );
          })}
        </div>
      </Paper>
    </div>
  );
};

export const FilterDropdown = ({
  data,
  label,
  value,
  onChange,
  minWidth,
  multiple = true,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <FormControl
      variant="standard"
      sx={{
        "&:hover": {
          // border: "0px solid transparent",
          backgroundColor: "rgba(0,0,0,0.05)",
          borderRadius: "10px",
        },
        "&:focus": {
          backgroundColor: "transparent",
        },
        minWidth: minWidth || "120px",
      }}
      size="small"
    >
      {/* <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel> */}
      <NoBorderSelect
        disableUnderline
        // labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple={multiple}
        open={open}
        // input={<OutlinedInput label={label} />}
        renderValue={(selected) => label}
        value={value}
        onChange={(e) => {
          handleClose();
          onChange(e);
        }}
        MenuProps={{
          sx: { maxHeight: "300px" },
          className: "scrollbar-hidden",
        }}
        style={{ backgroundColor: "transparent" }}
        onClose={handleClose}
        onOpen={handleOpen}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        SelectDisplayProps={{
          style: {
            backgroundColor: "transparent",
            padding: "0px 24px 0px 0px",
          },
        }}
        sx={{
          padding: "0px",
          margin: "4px 6px",
          "&:focus": {
            backgroundColor: "transparent",
          },
        }}
        size="small"
      >
        {isArrayWithValues(data) &&
          data.length > 0 &&
          data.map((obj) => {
            return (
              <MenuItem
                key={obj.id}
                value={obj.label}
                sx={{
                  borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                }}
              >
                {multiple && (
                  <Checkbox checked={value?.indexOf(obj.label) > -1} />
                )}
                <ListItemText primary={obj.label} />
              </MenuItem>
            );
          })}
      </NoBorderSelect>
    </FormControl>
  );
};
// const FilterDropdown = ({ data, label, value, onChange, minWidth }) => {
//   const [open, setOpen] = React.useState(false);

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleOpen = () => {
//     setOpen(true);
//   };
//   return (
//     <FormControl
//       variant="standard"
//       sx={{
//         "&:hover": {
//           border: "0px solid transparent",
//           backgroundColor: "rgba(0,0,0,0.05)",
//           borderRadius: "10px",
//         },
//         "&:focus": {
//           backgroundColor: "transparent",
//         },
//         minWidth: minWidth || "120px",
//       }}
//       size="small"
//     >
//       {/* <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel> */}
//       <Select
//         disableUnderline
//         // labelId="demo-multiple-checkbox-label"
//         id="demo-multiple-checkbox"
//         multiple
//         open={open}
//         // input={<OutlinedInput label={label} />}
//         renderValue={(selected) => label}
//         value={value || []}
//         onChange={(e) => {
//           handleClose();
//           onChange(e);
//         }}
//         onClose={handleClose}
//         onOpen={handleOpen}
//         displayEmpty
//         inputProps={{ "aria-label": "Without label" }}
//         style={{ backgroundColor: "transparent" }}
//         SelectDisplayProps={{ style: { backgroundColor: "transparent" } }}
//         sx={{
//           margin: "4px 6px",
//           "&:focus": {
//             backgroundColor: "transparent",
//           },
//         }}
//         size="small"
//       >
//         {isArrayWithValues(data) &&
//           data.length > 0 &&
//           data.map((obj) => {
//             return (
//               <MenuItem
//                 key={obj.id}
//                 value={obj.label}
//                 sx={{
//                   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
//                 }}
//               >
//                 {/* <Checkbox checked={value?.indexOf(obj?.label) > -1} /> */}
//                 <ListItemText primary={obj.label} />
//               </MenuItem>
//             );
//           })}
//       </Select>
//     </FormControl>
//   );
// };

export default DesignbankProducts;

export const UnderConstructionComponent = () => {
  return (
    <Box
      sx={{
        width: "300px",
        margin: "auto",
        marginTop: "10vh",
        marginBottom: "10vh",
        textAlign: "center",
      }}
    >
      <UnderConstruction />
      <Typography variant="h6" color="text.secondary" mt={5}>
        This section is under construction
      </Typography>
    </Box>
  );
};

const RecommendedSupplier = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 0,
    },
  };
  return (
    <Box my={5}>
      <Typography variant="h4" my={4}>
        Recommended Suppliers
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <Carousel
            responsive={responsive}
            infinite
            autoPlay={true}
            autoPlaySpeed={3000}
            arrows={false}
          >
            <CompanyCard />
            <CompanyCard />
            <CompanyCard />
            {/* {new Array(4).fill("").map(obj => <CompanyCard />)} */}
          </Carousel>
        </Grid>
      </Grid>

      {/* <div className="containerScroll">
        <CompanyCard />
        <CompanyCard />
        <CompanyCard />
        <CompanyCard />
        <CompanyCard />
      </div> */}
    </Box>
  );
};
