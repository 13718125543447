export const catalogBox = {
  container: {
    marginTop: "15px",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "15px",
    minWidth: "260px",
  },
  textField: {
    backgroundColor: "white",
    borderRadius: "10px",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
    color: "#fff",
    fontSize: "10px",
    // minHeight: "100px",
    border: "none",
    "& fieldset": { border: "none" },
  },
  button: {
    borderRadius: 0,
    // backgroundColor: "#007bff",
    // backgroundColor: "#6c757d",
    textTransform: "none",
    borderTop: "0.5px solid #626e7a",
    fontWeight: 600,
  },
};
