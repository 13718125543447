import {
  CameraAltOutlined,
  HeadphonesOutlined,
  InsertDriveFileOutlined,
  ShoppingCartOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import React from "react";

function MessageTypeIcon({ type, icon_color }) {
  return (
    <div>
      {type === "image" || type === "story_mention" || type === "share" ? (
        <CameraAltOutlined
          color={icon_color}
          style={{ height: "15px", width: "15px" }}
        />
      ) : type === "video" ? (
        <VideocamOutlined
          color={icon_color}
          style={{ height: "15px", width: "15px" }}
        />
      ) : type === "audio" ? (
        <HeadphonesOutlined
          color={icon_color}
          style={{ height: "15px", width: "15px" }}
        />
      ) : type === "order" ? (
        <ShoppingCartOutlined
          color={icon_color}
          style={{ height: "15px", width: "15px" }}
        />
      ) : type === "document" || type === "contacts" ? (
        <InsertDriveFileOutlined
          color={icon_color}
          style={{ height: "15px", width: "15px" }}
        />
      ) : null}
    </div>
  );
}

export default MessageTypeIcon;
