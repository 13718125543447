import {
  Apple,
  ArrowForwardIos,
  Close,
  FileDownload,
  FileUpload,
  Laptop,
  PhoneIphone,
  Sync,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import {
  dialogStyle,
  formatDate,
  isObjWithValues,
  formatServerValue,
  validateNumber,
  formatDateDynamic,
  goldName,
  silverName,
  platinumName,
  isDev,
  settingsEndpoint,
  getWebsite,
  websocketApiCall,
  getToken,
  getEmail,
  getAdminName,
} from "../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { UAParser } from "ua-parser-js";
import { Windows } from "../../../Svgs";
import {
  getJweroSheetSync,
  getUpdateProductHistory,
  getUpdateProductHistoryRef,
  modifyUpdateProductHistoryRef,
} from "../../../redux/actions/settingActions";
import { LoadingButton } from "@mui/lab";
import CustomChip from "../../components/CustomChip";
import {
  SecondaryLabel,
  SecondaryLabelExtraSmall,
  SecondaryLabelSmall,
} from "../../../helper/utility/Labels";
import Loader from "../../../components/Loader";

function ProductUpdateHistory({ showSync, closeSync }) {
  const dispatch = useDispatch();
  const fetchingUpdateHistory = useSelector(
    (state) => state?.settings?.fetchingUpdateHistory
  );
  const productPriceUpdateHistory = useSelector(
    (state) => state?.settings?.productPriceUpdateHistory
  );
  useEffect(() => {
    if (!isObjWithValues(productPriceUpdateHistory))
      dispatch(getUpdateProductHistory());

    dispatch(getUpdateProductHistoryRef());

    return () => {};
  }, []);

  return (
    <Dialog
      open={showSync}
      onClose={() => closeSync()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
      sx={{ ...dialogStyle }}
      keepMounted={false}
    >
      {/* <DialogTitle    id="alert-dialog-title">CRM Sheet</DialogTitle> */}
      <DialogContent>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          mb={3}
          flexWrap="wrap"
        >
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ my: 2 }}
            spacing={2}
          >
            <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
              Master Update history
            </Typography>
            <LoadingButton
              loading={fetchingUpdateHistory}
              onClick={() => dispatch(getUpdateProductHistory())}
              startIcon={<Sync />}
              size="small"
            >
              Refresh
            </LoadingButton>
          </Stack>

          {/* {!googleSheetIntegrated && ( */}
          <IconButton onClick={closeSync}>
            <Close />
          </IconButton>
          {/* )} */}
        </Stack>
        <HistoryTable />
        {/* {googleSheetIntegrated && (
            <CRMSheetHistory
              chatsHistory={chatsSpreadsheetHistory}
              heading={"Chats Sheet Sync"}
            />
          )} */}
      </DialogContent>
      {/* <DialogActions>
          <Button variant="outlined" startIcon={<Close />} onClick={closeSync}>
            Close
          </Button>
        </DialogActions> */}
    </Dialog>
  );
}

export default ProductUpdateHistory;

const HistoryTable = () => {
  const dispatch = useDispatch();
  const productPriceUpdateHistory = useSelector(
    (state) => state?.settings?.productPriceUpdateHistory
  );
  const productPriceUpdateHistoryRef = useSelector(
    (state) => state?.settings?.productPriceUpdateHistoryRef
  );
  const reduxTeamMembers =
    useSelector((state) => state.settings.teamMembers) || [];
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (isObjWithValues(productPriceUpdateHistoryRef)) {
      setExpanded(productPriceUpdateHistoryRef?.id);
    }

    return () => {};
  }, [productPriceUpdateHistoryRef]);

  useEffect(() => {
    if (isObjWithValues(productPriceUpdateHistory)) {
      // let filteredArray = Object.values(productPriceUpdateHistory || {})?.sort(
      //   (a, b) => validateNumber(b?.start_time) - validateNumber(a?.start_time)
      // );

      let filteredArray = Object.keys(productPriceUpdateHistory || {})?.sort(
        (a, b) =>
          validateNumber(productPriceUpdateHistory?.[b]?.start_time) -
          validateNumber(productPriceUpdateHistory?.[a]?.start_time)
      );

      setRows(
        filteredArray
          ?.map((id) => ({
            id,
            ...(productPriceUpdateHistory?.[id] || {}),
          }))
          ?.filter((i) => (isDev() ? true : !i?.is_dev))
      );
    }
    //   if (isObjWithValues(jweroSheetSync)) {
    // let allArray = Object.values(jweroSheetSync)
    //   ?.filter((o) => o?.type === type)
    //   ?.sort(
    //     (a, b) =>
    //       validateNumber(b?.start_time) - validateNumber(a?.start_time)
    //   );
    // let filteredArray = [];
    // let haveFile = false;
    // allArray?.forEach((o) => {
    //   if (haveFile) delete o.file_path;
    //   if (o.file_path && o.action === "export") haveFile = true;

    //   filteredArray.push(o);
    // });
    // setRows(filteredArray);
    //   }

    return () => {};
  }, [productPriceUpdateHistory]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onPressRetryFailedProducts = async () => {
    try {
      const res = await websocketApiCall({
        method: "POST",
        endpoint: "master_update_hook_retry",
        token: getToken(),
        data: {
          website: getWebsite(),
          ...productPriceUpdateHistoryRef,
        },
      });

      if (res?.success) {
        dispatch(
          modifyUpdateProductHistoryRef({
            data: res?.data,
          })
        );
      } else {
        console.log(res?.message);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onRetry = async (obj) => {
    try {
      const res = await websocketApiCall({
        method: "POST",
        endpoint: "master_update_hook",
        token: getToken(),
        data: {
          website: getWebsite(),
          email: getEmail(),
          userName: getAdminName(),
          require_history: true,
          history_id: obj?.id,
          retrying: true,
          is_dev: isDev(),
          // ...productPriceUpdateHistoryRef,
        },
      });

      // if (res?.success) {
      //   dispatch(
      //     modifyUpdateProductHistoryRef({
      //       data: res?.data,
      //     })
      //   );
      // } else {
      //   console.log(res?.message);
      // }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <TableContainer component={Paper}>
        <Table sx={{}} aria-label="custom pagination table">
          <TableHead>
            <TableRow
              sx={{ backgroundColor: "grey" }}
              style={{ backgroundColor: "#e7e7e73b" }}
            >
              <TableCell>Date</TableCell>

              <TableCell align="center">Updated</TableCell>
              <TableCell>Device</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>By</TableCell>
              {isDev() && <TableCell>Retry</TableCell>}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => {
              let { ua, status, action, activity, id } = row;
              let os = new UAParser(ua).getOS();
              let browser = new UAParser(ua).getBrowser();
              let teamMember = reduxTeamMembers?.find((o) => o?.id == row?.by);
              let summaryObj = getStrFromActivity(activity);

              let lastHistory =
                row?.id === productPriceUpdateHistoryRef?.id &&
                productPriceUpdateHistoryRef?.failed?.length > 0
                  ? productPriceUpdateHistoryRef
                  : null;

              return (
                <Fragment key={row.id}>
                  <TableRow key={row.id}>
                    {/* <TableCell padding="checkbox" align="center">
                      <Typography sx={{ ml: 3, fontWeight: "600" }}>
                        {index + 1}
                      </Typography>
                      
                  
                    </TableCell> */}

                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle2">
                        {formatServerValue(action)}
                      </Typography>
                      <SecondaryLabelSmall
                        sx={{ color: "black", opacity: "0.6" }}
                      >
                        {row.start_time
                          ? formatDateDynamic(
                              new Date(row.start_time),
                              "DD MMM, YYYY - hh:mm A"
                            )
                          : ""}
                        {/* {row.start_time
                          ? formatDate(new Date(row.start_time), "dd/mm/yyyy")
                          : ""}{" "}
                        {row.start_time
                          ? formatDate(new Date(row.start_time), "hh:mm am/pm")
                          : ""} */}
                      </SecondaryLabelSmall>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="h6">{row.updated || 0}</Typography>
                    </TableCell>

                    <TableCell padding="checkbox" sx={{ fontWeight: "600" }}>
                      <Stack direction="row" alignItems={"center"} spacing={2}>
                        {os && os.name === "Mac OS" ? (
                          <Apple style={{ color: "#666666" }} />
                        ) : os.name === "Windows" ? (
                          <Windows style={{ color: "#666666" }} />
                        ) : null}
                        {os &&
                        (os.name === "Mac OS" || os.name === "Windows") ? (
                          <Laptop width="20px" height="20px" />
                        ) : (
                          <PhoneIphone />
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <CustomChip
                        label={status}
                        status={status}
                        type={status}
                      />
                    </TableCell>
                    {/* <TableCell>
                      <Typography
                        // variant="h6"
                        fontSize={"11px"}
                        sx={{ mt: 0.5 }}
                      >
                        {isObjWithValues(row.location)
                          ? `${row.location.city || ""} ${
                              row.location.state || row.location.region || ""
                            } ${row.location.country}`
                          : ""}
                      </Typography>
                    </TableCell> */}
                    <TableCell>
                      {row?.isAdmin
                        ? "Admin"
                        : `${teamMember?.first_name || ""} ${
                            teamMember?.last_name || ""
                          }`}
                    </TableCell>
                    {isDev() && (
                      <TableCell>
                        <Button onClick={() => onRetry(row)}>Retry</Button>
                      </TableCell>
                    )}
                    <TableCell
                      align="center"
                      style={{ padding: 0, margin: 0 }}
                      width="fit-content"
                    >
                      <IconButton
                        size="small"
                        style={{ padding: 0 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          // toggleQuickEdit(!showQuickEdit);
                          console.log(expanded);
                          setExpanded(row?.id === expanded ? false : row?.id);
                          // setExpanded((pre) => (pre == id ? false : id));
                        }}
                      >
                        <ArrowForwardIos
                          style={{
                            transform: expanded == id ? "rotate(90deg)" : "",
                            transition: "transform 150ms ease",
                            padding: 0,
                          }}
                        />
                      </IconButton>
                    </TableCell>

                    {/* 
                  <TableCell>
                    <Typography
                      // variant="h6"
                      fontSize={"11px"}
                      sx={{ mt: 0.5 }}
                    >
                      {row.ip}
                    </Typography>
                  </TableCell> */}
                    {/* <TableCell  align="right">
              {row.fat}
            </TableCell> */}
                  </TableRow>
                  <TableRow style={{ maxWidth: "100%" }}>
                    <TableCell
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        borderBottom: expanded !== id && 0,
                      }}
                      colSpan={6}
                    >
                      <Collapse
                        in={expanded == id}
                        timeout="auto"
                        unmountOnExit
                        style={{ paddingBottom: 8, paddingTop: 8 }}
                      >
                        <Grid container>
                          <Grid item md={8} xs={12}>
                            <Stack>
                              {Object.keys(summaryObj || {})?.map((key) => {
                                return (
                                  <Fragment key={key}>
                                    <Typography
                                      variant="subtitle2"
                                      style={{ marginBottom: 0, marginTop: 8 }}
                                    >
                                      • {formatServerValue(key || "")}
                                    </Typography>
                                    <Stack>
                                      {summaryObj[key]?.map((str) => {
                                        return <Typography>{str}</Typography>;
                                      })}
                                    </Stack>
                                  </Fragment>
                                );
                              })}
                            </Stack>
                          </Grid>
                          {(isDev() || true) && (
                            <Grid item md={4} xs={12}>
                              {lastHistory && (
                                <Box alignContent={"center"}>
                                  <SecondaryLabelSmall sx={{ color: "black" }}>
                                    Products failed (
                                    {lastHistory?.failed?.length})
                                  </SecondaryLabelSmall>
                                  <IconButton
                                    onClick={() => {
                                      if (!lastHistory?.retrying) {
                                        onPressRetryFailedProducts();
                                      }
                                    }}
                                  >
                                    {lastHistory?.retrying ? (
                                      <Grid container>
                                        <Grid item xs>
                                          <Loader />
                                        </Grid>
                                        <Grid item xs>
                                          <Typography>Retrying</Typography>
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      <CustomChip
                                        label={`Click to retry`}
                                        status={"failed"}
                                        type={"failed"}
                                      />
                                    )}
                                  </IconButton>
                                  <Typography
                                    sx={{ color: "black", opacity: "0.6" }}
                                  >
                                    Last try on{" "}
                                    {lastHistory.last_updated
                                      ? formatDateDynamic(
                                          new Date(lastHistory.last_updated),
                                          "DD MMM, YYYY - hh:mm A"
                                        )
                                      : ""}
                                  </Typography>
                                </Box>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                // colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const getStrFromActivity = (inputObj, currency) => {
  const resultObj = {};
  let names = {
    gold: goldName, // Assuming goldName is defined elsewhere and maps purities to names
    silver: silverName, // Assuming silverName is defined elsewhere
    platinum: platinumName, // Assuming platinumName is defined elsewhere
  };

  for (const metal in inputObj) {
    const metalChanges = inputObj[metal];
    resultObj[metal] = [];

    for (const changeKey in metalChanges) {
      const { new: newPrice, old: oldPrice } = metalChanges[changeKey];
      let changeDescription = "";

      if (newPrice !== undefined && oldPrice !== undefined) {
        // Modification
        changeDescription = `${formatServerValue(metal)} price change of ${
          currency || ""
        } ${names?.[metal]?.[changeKey] || changeKey} from ${oldPrice} to ${
          currency || ""
        } ${newPrice}`;
      } else if (newPrice !== undefined) {
        // Addition
        changeDescription = `${formatServerValue(
          metal
        )} added with a price of ${currency || ""} ${newPrice} for ${
          names?.[metal]?.[changeKey] || changeKey
        }`;
      } else if (oldPrice !== undefined) {
        // Deletion
        changeDescription = `${formatServerValue(
          metal
        )} removed with a last known price of ${
          currency || ""
        } ${oldPrice} for ${names?.[metal]?.[changeKey] || changeKey}`;
      }

      if (changeDescription) {
        resultObj[metal].push(changeDescription);
      }
    }
  }
  return resultObj;

  // const resultObj = {};
  // let names = {
  //   gold: goldName,
  //   silver: silverName,
  //   platinum: platinumName,
  // };

  // for (const metal in inputObj) {
  //   const metalPrices = inputObj[metal];
  //   resultObj[metal] = [];

  //   for (const purity in metalPrices) {
  //     const newPrice = metalPrices?.[purity]?.new;
  //     const oldPrice = metalPrices?.[purity]?.old;
  //     resultObj[metal].push(
  //       `${formatServerValue(metal)} price change of ${currency || ""} ${
  //         names?.[metal]?.[purity] || purity
  //       } from ${oldPrice} to ${currency || ""} ${newPrice}`
  //     );
  //   }
  // }
  // return resultObj;
};
