import {
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Stack,
  Typography,
} from "@mui/material";
import { display } from "@mui/system";
import React from "react";
import { formatDate, getMediaType, validateNumber } from "../../helper";

const Thumbnail = ({ data, setViewImage, onSelect, selected }) => {
  let { date, file_type } = data || {};
  let link = data?.source_url;
  let name = data?.file_name;
  let size = validateNumber(data?.size);
  let type = getMediaType(data);
  let previewLink = link;
  switch (type) {
    case "image":
      previewLink = link;
      break;
    case "video":
      previewLink =
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-vCLTuAiQsXzSXVUrh-CcrJELuFsiSBnCzQ&usqp=CAU";
      break;
    case "document":
      previewLink =
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0iHXcwKuDP2PpsQvqkETZ7Q40pZpKUmOl6HFvRUtVLnqeBB60feXVfzz-9s6k_uvYKek&usqp=CAU";
      break;

    default:
      previewLink =
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRH-rFxLV6Tl1tfiqrM-vXnC2Il3VTORXmHU70t6p-rZIPlBJ8RQFs2LEOljagz1vb0s5U&usqp=CAU";
      break;
  }

  return (
    <Card
      sx={{
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;",
        position: "relative",
        height: "310px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Checkbox
        sx={{
          position: "absolute",
          top: 0.5,
          left: 0.5,
          zIndex: 1,
          ".MuiSvgIcon-root": {
            backgroundColor: "#fff",
            borderRadius: "4px",
          },
        }}
        checked={selected?.some((item) => item?.id === data?.id)}
        onChange={() => onSelect && onSelect(data)}
      />
      <CardMedia component="img" image={previewLink} />
      <CardContent
        onClick={() => setViewImage(data)}
        sx={{
          cursor: "pointer",
          px: 2,
          ":last-child": {
            pb: 4,
          },
        }}
      >
        <Typography noWrap>{name}</Typography>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography color={"text.secondary"}>
            {" "}
            {Number(parseFloat(size / 1024 / 1024).toFixed(2))} mb
          </Typography>
          <Typography color={"text.secondary"}>
            {" "}
            {formatDate(new Date(date), "dd:mm:yyyy hh:mm")}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Thumbnail;
