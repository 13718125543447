import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../../../redux/actions/flowsActions";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Typography,
  InputLabel,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { textTransform } from "@mui/system";

const EditTitleDialog = ({ openTitleDialog, setOpenTitleDialog, title }) => {
  const dispatch = useDispatch();
  const tempTitle = title;
  const [currTitle, setCurrTitle] = useState(title);
  const [error, setError] = useState(false);

  useEffect(() => {
    setCurrTitle(title);
  }, [title]);

  const handleCloseDialog = () => {
    setOpenTitleDialog(false);
  };

  const handleCancel = () => {
    dispatch({ type: actions.SET_TITLE, payload: tempTitle });
    setOpenTitleDialog(false);
  };

  const handleTitle = (e) => {
    setCurrTitle(e.target.value);
  };

  const handleSave = () => {
    if (currTitle === "") {
      return;
    }
    dispatch({ type: actions.SET_TITLE, payload: currTitle });
    handleCloseDialog();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={openTitleDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          fontWeight: 600,
          fontFamily: "Inter",
          borderBottom: "1px solid #dbe1e8",
          textAlign: "center",
          fontSize: "18px",
        }}
        id="alert-dialog-title"
      >
        Flow Title
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: "center",
          // flex: "display",
          // alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          id="title"
          value={currTitle}
          onChange={handleTitle}
          label="Title"
          type="title"
          fullWidth
          size="small"
          inputProps={{
            maxLength: 30,
            style: {
              fontFamily: "Inter",
            },
          }}
          sx={{ marginBottom: "10px", marginTop: "40px", width: "90%" }}
          variant="outlined"
        />
        {currTitle === "" && (
          <Alert severity="error" sx={{ width: "80%", marginLeft: "20px" }}>
            Title can't be empty!
          </Alert>
        )}
        {/* <DialogContentText
        id="alert-dialog-description"
        sx={{
          fontFamily: "Inter",
          color: "#516173",
          marginTop: "20px"
        }}
      >
        Are you sure you want to leave without saving the changes?
      </DialogContentText> */}
      </DialogContent>
      <DialogActions
        sx={{
          marginBottom: "15px",
          flex: "display",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Button
          sx={{
            fontFamily: "Inter",
            backgroundColor: "#fff",
            border: "1px solid #dbe1e8",
            textTransform: "none",
            color: "#516173",
            "&:hover": {
              border: "1px solid #006cff",
              backgroundColor: "#fff",
              transition: "all .3s",
            },
          }}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button> */}
        <Button
          sx={{
            width: "40%",
            fontFamily: "Inter",
            backgroundColor: "#006cff",
            border: "1px solid #006cff",
            textTransform: "none",
            color: "#fff",
            marginRight: "20px",
            "&:hover": {
              // border: "1px #006cff",
              backgroundColor: "#6295f5",
              transition: "all .3s",
              color: "#fff",
            },
          }}
          onClick={handleSave}
          autoFocus
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTitleDialog;

{
  /* <Dialog
open={openTitleDialog}
onClose={handleCloseDialog}
maxWidth="sm"
sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
>
<DialogTitle
  sx={{
    textAlign: "center",
    padding: "20px 130px 0px 130px",
    borderBottom: "1.5px solid #969696",
  }}
>
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "20px",
    }}
  >
    <EditIcon />
    <Typography
      variant="body1"
      sx={{ marginLeft: "5px", fontFamily: "Inter", fontWeight: 600 }}
    >
      Edit Flow Title
    </Typography>
  </Box>
</DialogTitle>
<DialogContent>
  {/* <Typography
    sx={{ marginTop: "20px", fontWeight: 600, fontFamily: "Inter" }}
  >
    Title
  </Typography> */
}
//   <TextField
//     autoFocus
//     margin="dense"
//     id="title"
//     value={currTitle}
//     onChange={handleTitle}
//     label="Title"
//     type="title"
//     fullWidth
//     size="small"
//     inputProps={{
//       maxLength: 30,
//       style: {
//         fontFamily: "Inter",
//       },
//     }}
//     sx={{ marginBottom: "10px", marginTop: "40px" }}
//     variant="outlined"
//   />
// </DialogContent>
// <DialogActions
//   sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "25px" }}
// >
//   <Button variant="contained" sx={{ textTransform: "none" }} onClick={handleCancel}>Cancel</Button>
//   <Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSave}>Save</Button>
// </DialogActions>
// </Dialog> */}
