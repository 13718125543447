import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Handle, useNodeId } from "reactflow";
import {
  Box,
  IconButton,
  Typography,
  Tooltip,
  Fade,
  Stack,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import NodePopover from "../helper/NodePopover";
import * as actions from "../../../../../../redux/actions/flowsActions";
import ShowChatIndicator from "../helper/ShowChatIndicator";
import AddNodesButton from "../helper/AddNodesButton";
import { startNodeStyles } from "../styles/startNodeStyles";
import { nodeCanBeAddedErrorMessage } from "../helper/nodeCanBeAdded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import "../styles/node.css";
import { isArrayWithValues } from "jwero-javascript-utils";
import {
  PrimaryLabel,
  PrimaryLabelSmall,
} from "../../../../../../helper/utility/Labels";
import HorizontalStack from "../../../../../../helper/utility/HorizontalStack";
import CustomTooltip from "../../components/CustomTooltip";
import { InfoOutlined } from "@material-ui/icons";

const styles = startNodeStyles;

const CustomStart = () => {
  const nodeId = useNodeId();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  let [selectedTriggers, setSelectedTriggers] = useState([]);
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const reduxSelectedTriggers = useSelector(
    (state) => state.flow?.selected_triggers
  );
  const nodesUpdated = useSelector((state) => state.flow?.nodesUpdated);
  const segments = nodes?.find((it) => it?.id == nodeId)?.fieldData
    ?.selected_segments;
  // const selectedTriggers = nodes?.find((it) => it?.id == nodeId)?.fieldData
  //   ?.selected_triggers;
  const title = nodes?.find((it) => it?.id == nodeId)?.title;
  const messageNodeIndicatorId = useSelector(
    (state) => state.flow?.messageNodeIndicatorId
  );
  const chatbotType = useSelector((state) => state.flow?.chatbotType);
  const isMinimized = useSelector((state) => state.flow?.isMinimized);
  const [isHovered, setIsHovered] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openNodePopover, setOpenNodePopover] = useState(false);
  const isDragging = useSelector((state) => state.flow?.isDragging);
  const dragginNodeType = useSelector((state) => state.flow?.dragginNodeType);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setSelectedTriggers(
      reduxSelectedTriggers
        ? reduxSelectedTriggers
        : nodes?.find((it) => it?.id == nodeId)?.fieldData?.selected_triggers
    );
  }, [nodesUpdated, nodes, reduxSelectedTriggers]);

  const handleMain = () => {
    if (chatbotType?.type === "campaign" || chatbotType?.type === "trigger") {
      dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openStartSidebar: true },
      });
    }

    setOpenTooltip(!openTooltip);
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
  };

  const handleClick = (event) => {
    setOpenNodePopover(true);
    setAnchorEl(event.currentTarget);
    setIsHovered(false);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { nodeAddPopover: true },
    });
  };

  const handleClose = () => {
    setOpenNodePopover(false);
    setAnchorEl(null);

    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: {
        nodeAddPopover: false,
        actionsMenu: false,
      },
    });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { nodeDraggedToId: nodeId },
    });

    if (isDragging) {
      const currentNode = nodes?.find((node) => node.id === nodeId);
      const flag = nodeCanBeAddedErrorMessage({
        nodes,
        edges,
        currentNode,
        nodeAddedType: dragginNodeType,
      });

      if (flag !== "allowed") {
        setErrorMessage(flag);
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setErrorMessage(null);
    setOpenTooltip(false);
  };

  return (
    // <Tooltip
    //   arrow
    //   title="It is the start point of the flow/story. It can't be edited."
    //   open={openTooltip}
    //   placement={chatbotType?.type === "campaign" ? "top" : "left"}
    //   componentsProps={{
    //     tooltip: {
    //       sx: {
    //         width: "180px",
    //         backgroundColor: "#797b8c",
    //         color: "#fff",
    //         fontSize: "14px",
    //         fontFamily: "Inter",
    //         padding: "8px",
    //         fontWeight: 550,
    //       },
    //     },
    //     arrow: {
    //       sx: {
    //         color: "#797b8c",
    //       },
    //     },
    //   }}
    // >
    <Box
      className="nodeContainer"
      sx={{
        ...styles.mainContainer,
        cursor:
          chatbotType?.type === "campaign" || chatbotType?.type === "trigger"
            ? "allowed"
            : "not-allowed",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          textAlign: "center",
          position: "relative",
          left: "-55px",
          top: "4px",
        }}
      >
        {chatbotType?.type === "campaign" && segments && segments.length > 0 && (
          <Box
            sx={{
              width: "200px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              padding: "10px 0px",
              position: "absolute",
              left: "-26px",
              top: "54px",
              color: "#566e8a",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignSelf: "flex-start",
                paddingLeft: "10px",
                fontSize: "13px",
                fontWeight: 600,
              }}
            >
              Selected Segments -
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "flex-start",
                gap: "4px",
              }}
            >
              {segments.map((segment, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <FiberManualRecordIcon
                    sx={{
                      display: "flex",
                      alignSelf: "flex-start",
                      fontSize: "8px",
                      paddingTop: "5px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      // display: "flex",
                      // alignSelf: "flex-start",
                      textAlign: "start",
                      maxWidth: "150px",
                      overflow: "hidden",
                    }}
                  >
                    {segment}
                  </Typography>
                </Box>
              ))}
              {/* </ul> */}
            </Box>
          </Box>
        )}
        <HorizontalStack justifyContent="space-between" sx={{ gap: 2 }}>
          <Typography
            variant="span"
            sx={{
              fontSize: "13px",
              alignItems: "center",
              fontFamily: "Inter",
              fontWeight: 600,
              color: "#566e8a",
            }}
          >
            {title}
          </Typography>
          <CustomTooltip
            title={
              "Please choose an action that you'd like to set a trigger for"
            }
          >
            <InfoOutlined style={{ height: "14px", width: "14px" }} />
          </CustomTooltip>
        </HorizontalStack>
      </div>
      {/* <div
        style={{
          textAlign: "center",
          position: "relative",
          // left: "-110px",
          top: "4px",
        }}
      >
        {chatbotType?.type === "trigger" &&
          isArrayWithValues(selectedTriggers) && (
            <Box
              sx={{
                // width: "200px",
                // display: "flex",
                // alignItems: "center",
                // flexDirection: "column",
                padding: "10px 0px",
                position: "absolute",
                left: "-110px",
                top: "34px",
                color: "#566e8a",
                // backgroundColor: "blue",
              }}
            >
              <Typography
                sx={{
                  // display: "flex",
                  // alignSelf: "flex-start",
                  // paddingLeft: "10px",
                  fontSize: "13px",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                Selected trigger -{" "}
              </Typography>
              <Box
                sx={
                  {
                    // display: "flex",
                    // flexDirection: "column",
                    // alignSelf: "flex-start",
                    // gap: "4px",
                  }
                }
              >
                {selectedTriggers.map((segment, index) => (
                  <Box
                    key={index}
                    sx={
                      {
                        // display: "flex",
                        // flexDirection: "row",
                        // alignItems: "center",
                        // gap: "4px",
                      }
                    }
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        // display: "flex",
                        // alignSelf: "flex-start",
                        // textAlign: "start",
                        // maxWidth: "150px",
                        // overflow: "hidden",
                      }}
                    >
                      <PrimaryLabelSmall>{segment?.label}</PrimaryLabelSmall>
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
      </div> */}
      <Box sx={styles.bottomRow}>
        {isHovered && isDragging && errorMessage !== null ? (
          <Tooltip
            arrow
            title={errorMessage}
            placement="top"
            open={true}
            componentsProps={{
              tooltip: {
                sx: {
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  padding: "10px",
                },
              },
            }}
          >
            <Box
              sx={styles.nodeBody}
              onClick={handleMain}
              className="startButton"
            >
              <IconButton sx={styles.iconButton}>
                <HomeIcon sx={styles.icon} />
              </IconButton>
              <Typography variant="span" style={styles.titleName}>
                Start point
              </Typography>
              <Handle
                type="source"
                position="right"
                style={styles.rightHandle}
              />
            </Box>
          </Tooltip>
        ) : (
          <Box
            sx={styles.nodeBody}
            onClick={handleMain}
            className="startButton"
          >
            <IconButton sx={styles.iconButton}>
              <HomeIcon sx={styles.icon} />
            </IconButton>
            <Typography variant="span" style={styles.titleName}>
              {chatbotType?.type === "trigger"
                ? selectedTriggers?.[0]?.label
                  ? `${selectedTriggers?.[0]?.label}`
                  : "Select a Action"
                : "Start point"}
            </Typography>
            <Handle type="source" position="right" style={styles.rightHandle} />
          </Box>
        )}

        {messageNodeIndicatorId === nodeId && !isMinimized && (
          <ShowChatIndicator />
        )}
        <Fade in={openNodePopover} timeout={3000}>
          <div>
            <NodePopover
              nodeId={nodeId}
              handleClick={handleClick}
              handleClose={handleClose}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
          </div>
        </Fade>
        <AddNodesButton
          handleClick={handleClick}
          isHovered={isHovered}
          nodeId={nodeId}
          errorMessage={errorMessage}
        />
      </Box>
    </Box>
    // </Tooltip>
  );
};

export default CustomStart;
