import { useState } from "react";
import { Popover, Box, Typography, Button, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { conditionValueStyles } from "../styles/conditionValueStyles";
import { conditionButtons } from "../utils/conditionButtons";

const styles = conditionValueStyles;

const ConditionValuePopover = ({
  condValAnchorEl,
  setCondValAnchorEl,
  fltrObj,
}) => {
  const open = condValAnchorEl.openedPopoverId === fltrObj?.id;
  const [selectedButtonId, setSelectedButtonId] = useState(
    fltrObj?.selectedButtonId
  );
  const [condition, setCondition] = useState(fltrObj?.selectedButtonId);
  const [inputValue, setInputValue] = useState(fltrObj?.value);

  const handleClose = () => {
    setCondValAnchorEl({ openedPopoverId: null, anchorEl: null });
  };

  const handleSubmit = () => {
    fltrObj.value = inputValue;
    fltrObj.condition = condition;
    fltrObj.selectedButtonId = selectedButtonId;
    handleClose();
  };

  const handleButton = (e, type, buttonId) => {
    setSelectedButtonId(buttonId);
    setCondition(type);
  };

  const handleTextfield = (e) => {
    setInputValue(e.target.value);
  };

  const id = open ? "simple-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={condValAnchorEl.anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
    >
      <Box sx={styles.outerBox}>
        <Box sx={styles.header}>
          <SendIcon sx={styles.icon} />
          <Typography variant="span" sx={styles.labelText}>
            Message
          </Typography>
        </Box>
        <Typography variant="span" sx={styles.labelText}>
          Condition
        </Typography>
        <Box sx={styles.conditionDiv}>
          {conditionButtons.map((button) => (
            <Button
              key={button.id}
              id={button.id}
              onClick={(e) => handleButton(e, button?.value, button?.id)}
              variant="outlined"
              sx={
                selectedButtonId === button.id
                  ? styles.selectedButton
                  : styles.button
              }
            >
              {button.label}
            </Button>
          ))}
        </Box>
        <Typography sx={styles.labelText}>Value</Typography>
        <TextField
          value={inputValue}
          variant="outlined"
          size="small"
          placeholder="value"
          fullWidth
          sx={styles.textfield}
          onChange={handleTextfield}
        />
        <Button fullWidth sx={styles.submitButton} onClick={handleSubmit}>
          Apply filter
        </Button>
      </Box>
    </Popover>
  );
};

export default ConditionValuePopover;
