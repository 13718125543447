import { useState } from "react";
import { Box, Typography } from "@mui/material";
import TextBox from "../helper/TextBox";
import { pushNotificationStyles } from "../styles/pushNotificationStyles";
import CircularProgress from "@mui/material/CircularProgress";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DataObjectIcon from "@mui/icons-material/DataObject";
import Attributes from "../helper/Attributes";

const styles = pushNotificationStyles;

const FormFields = ({
  data,
  handleChange,
  uploadingFile,
  imageFunctionRef,
  notificationTitleError,
  notificationMessageError,
  handleAttributeSelect,
  currentAttributeField,
  setCurrentAttributeField,
  inputRefMessage,
  inputRefTitle,
}) => {
  const [attributeAnchorEl, setAttributeAnchorEl] = useState(false);

  const handleAttributes = ({ e, fieldType }) => {
    setAttributeAnchorEl(e?.currentTarget);
    setCurrentAttributeField(fieldType);
  };

  return (
    <Box sx={styles.formFieldsBox}>
      {/* <Box sx={styles.textFieldBox}>
        <Typography style={styles.labels}>Name :</Typography>
        <TextBox
          value={data?.name || ""}
          onChangeFunction={handleChange}
          placeholderValue="Enter name..."
          fieldType={"name"}
          maxLengthValue={30}
        />
      </Box> */}
      <Box sx={styles.textFieldBox}>
        <Typography style={styles.labels}>Notification Title :</Typography>
        <TextBox
          value={data?.title || ""}
          onChangeFunction={handleChange}
          fieldType={"title"}
          placeholderValue="Enter title..."
          maxLengthValue={30}
          inputRef={inputRefTitle}
          inputAdornment={<DataObjectIcon />}
          handleClick={handleAttributes}
        />
        {notificationTitleError && !data?.title && (
          <Typography
            sx={{
              fontSize: "14px",
              color: "#E63946",
              fontWeight: 600,
              fontFamily: "Inter",
            }}
          >
            Title is required.
          </Typography>
        )}
      </Box>
      <Box sx={styles.textFieldBox}>
        <Typography style={styles.labels}>Message :</Typography>
        <TextBox
          value={data?.message || ""}
          onChangeFunction={handleChange}
          inputRef={inputRefMessage}
          fieldType={"message"}
          placeholderValue="Enter Message..."
          maxLengthValue={500}
          rows={3}
          inputAdornment={<DataObjectIcon />}
          handleClick={handleAttributes}
        />
        {notificationMessageError && !data?.message && (
          <Typography
            sx={{
              fontSize: "14px",
              color: "#E63946",
              fontWeight: 600,
              fontFamily: "Inter",
            }}
          >
            Message is required.
          </Typography>
        )}
      </Box>
      <Box sx={styles.textFieldBox}>
        <Typography style={styles.labels}>Image :</Typography>
        {!uploadingFile ? (
          <TextBox
            value={data?.image || ""}
            onChangeFunction={handleChange}
            placeholderValue="Enter image url or select..."
            fieldType={"image"}
            maxLengthValue={200}
            rows={2}
            inputAdornment={<AddPhotoAlternateIcon />}
            handleClick={() => imageFunctionRef.current.click()}
          />
        ) : (
          <CircularProgress />
        )}
        <input
          type="file"
          accept="image/jpeg, image/jpg, image/png"
          style={{
            position: "absolute",
            display: "none",
          }}
          ref={imageFunctionRef}
          name="image"
          onChange={(e) => handleChange({ e })}
        />
      </Box>
      <Box sx={{ ...styles.textFieldBox, position: "relative" }}>
        <Typography style={styles.labels}>Launch URL :</Typography>
        <TextBox
          value={data?.launch_url || ""}
          onChangeFunction={handleChange}
          fieldType={"launch_url"}
          placeholderValue="For e.g. https://google.com"
          maxLengthValue={500}
        />
      </Box>
      <Attributes
        attributeAnchorEl={attributeAnchorEl}
        setAttributeAnchorEl={setAttributeAnchorEl}
        handleAttributeSelect={handleAttributeSelect}
      />
    </Box>
  );
};

export default FormFields;
