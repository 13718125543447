import {
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  Close,
  KeyboardReturn,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Fab,
  FormControl,
  IconButton,
  InputAdornment,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  isObjWithValues,
  isArrayWithValues,
  fetchProducts,
  formatServerValue,
  getUserType,
  secondaryLabel,
  ResponsiveStackRow,
  allStockStatus,
  removeDuplicatesFromArray,
  validateNumber,
} from "../../helper";
import { fetchTaxonomies } from "../../redux/actions/productActions";
import { getDiamondQualityWithoutType } from "../products/AddProduct";
import CustomChip from "./CustomChip";

const SelectProducts = forwardRef(
  (
    {
      onClose,
      onSelect,
      facebookProduct,
      extraParams = {},
      onlyImage,
      title,
      disabledProducts = [],
    },
    ref
  ) => {
    const [productSettings, setProductSettings] = useState({});
    const [allCollections, setAllCollections] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [allGoldPurities, setAllGoldPurities] = useState([]);
    const [allDiamondQualities, setAllDiamondQualities] = useState([]);
    const [allDiamondLabs, setAllDiamondLabs] = useState([]);
    const [products, setProducts] = useState([]);
    const [defaultParams, setDefaultParams] = useState({
      per_page: 20,
      page: 1,
      ...extraParams,
    });
    const [search, setSearch] = useState("");
    const [params, setParams] = useState(defaultParams);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [filtersToShow, setFiltersToShow] = useState([]);
    const [productLoading, setProductsLoading] = useState(false);
    const masterPricing = useSelector((state) => state.settings.masterPricing);
    const reduxProductSettings = useSelector(
      (state) => state.settings.productSettings
    );
    const [isMounted, setIsMounted] = useState(false);
    const [noMoreProducts, setNoMoreProducts] = useState(false);
    const reduxTaxonomies = useSelector((state) => state.product.taxonomies);
    const [selectedFilters, setSelectedFilters] = useState({
      collections: [],
      category: [],
      gold_kt: [],
      diamond: [],
      diamond_lab: [],
      ["subcategory"]: [],
    });
    const [allSubCategories, setAllSubCategories] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [predefinedProducts, setPredefinedProducts] = useState([]);
    const scrollRef = useRef();
    const storePlan = useSelector((state) => state.user.store_plan);

    const [maxProductLimit, setMaxProductLimit] = useState(50);

    const dispatch = useDispatch();
    const theme = useTheme();

    const isIpad = useMediaQuery(theme.breakpoints.down("md"));

    useImperativeHandle(ref, () => {
      return {
        setPreSelectedProducts: (products) => {
          setSelectedProducts(products);
          setPredefinedProducts(products);
        },
      };
    });

    useEffect(() => {
      setMaxProductLimit(validateNumber(storePlan?.catalogs_products) || 50);
    }, [storePlan]);
    useEffect(() => {
      if (
        isArrayWithValues(products) &&
        isArrayWithValues(predefinedProducts)
      ) {
        setProducts((state) =>
          removeDuplicatesFromArray([...predefinedProducts, ...state], "id")
        );
        setPredefinedProducts([]);
      }
    }, [predefinedProducts, products]);

    useEffect(() => {
      setAllSelected(
        products?.every((i) => selectedProducts?.find((j) => j.id === i.id)) &&
          isArrayWithValues(products)
      );
    }, [selectedProducts, products]);

    useEffect(() => {
      let index = 0;
      let object = {
        [`custom_filter[${index}][key]`]: "_stock_status",
        [`custom_filter[${index}][value]`]: "outofstock",
        [`custom_filter[${index}][compare]`]: "NOT IN",
      };
      index++;
      object = {
        ...object,
        [`custom_filter[${index}][key]`]: "digi_plan_type",
        [`custom_filter[${index}][compare]`]: "NOT EXISTS",
        [`custom_filter[${index}][value]`]: "",
      };
      index++;
      if (facebookProduct && !onlyImage) {
        object = {
          // ...object,
          [`custom_filter[${index}][key]`]: "_wc_facebook_sync_enabled",
          [`custom_filter[${index}][value]`]: "yes",
        };
        index++;
      }
      if (onlyImage && !facebookProduct) {
        object = {
          ...object,
          [`custom_filter[${index}][key]`]: "_thumbnail_id",
          [`custom_filter[${index}][value]`]: "",
          [`custom_filter[${index}][compare]`]: "!=",
        };
        index++;
      }

      if (onlyImage && facebookProduct) {
        object = {
          ...object,
          [`custom_filter[${index}][key]`]: "_wc_facebook_sync_enabled",
          [`custom_filter[${index}][value]`]: "yes",
          [`custom_filter[${index + 1}][key]`]: "_thumbnail_id",
          [`custom_filter[${index + 1}][value]`]: "",
          [`custom_filter[${index + 1}][compare]`]: "!=",
        };
        index++;
      }
      setDefaultParams((state) => ({ ...state, ...object }));
      setParams((state) => ({ ...state, ...object }));
      if (!isArrayWithValues(products))
        fetchProducts(
          { ...defaultParams, ...object, ...extraParams },
          setProducts,
          setProductsLoading,
          false
        );
      setIsMounted(true);
    }, [facebookProduct, onlyImage]);
    useEffect(() => {
      // if (!isObjWithValues(reduxProductSettings)) dispatch(getProductSettings());
      // if (!isObjWithValues(masterPricing)) dispatch(getMasterPricings());
      if (!isObjWithValues(reduxTaxonomies)) dispatch(fetchTaxonomies());
    }, []);

    useEffect(() => {
      if (getUserType() === "supplier") {
        if (isObjWithValues(reduxProductSettings)) {
          if (isArrayWithValues(reduxProductSettings.collections))
            setAllCollections([...reduxProductSettings.collections]);

          if (isArrayWithValues(reduxProductSettings.categories))
            setAllCategories([...reduxProductSettings.categories]);
          if (isArrayWithValues(reduxTaxonomies?.["sub-categories"]))
            setAllSubCategories(reduxTaxonomies?.["sub-categories"]);
        }
      } else {
        if (isObjWithValues(reduxTaxonomies)) {
          setProductSettings({ ...reduxTaxonomies });

          if (isArrayWithValues(reduxTaxonomies.collections))
            setAllCollections([...reduxTaxonomies.collections]);

          if (isArrayWithValues(reduxTaxonomies.categories))
            setAllCategories([...reduxTaxonomies.categories]);
          if (isArrayWithValues(reduxTaxonomies?.["sub-categories"]))
            setAllSubCategories(reduxTaxonomies?.["sub-categories"]);
          // if (isArrayWithValues(reduxTaxonomies.gold_purities))
          //   setAllGoldPurities([...reduxTaxonomies.gold_purities]);
        }
      }
      if (isObjWithValues(reduxProductSettings)) {
        setProductSettings({ ...reduxProductSettings });
        if (isArrayWithValues(reduxProductSettings.gold_purities))
          setAllGoldPurities([...reduxProductSettings.gold_purities]);
        if (isArrayWithValues(reduxProductSettings.diamond_labs)) {
          // console.log();
          setAllDiamondLabs([...reduxProductSettings.diamond_labs]);
        }
        // setAllGoldPurities([...reduxProductSettings.gold_purities]);
      }
    }, [reduxProductSettings, reduxTaxonomies]);

    useEffect(() => {
      if (
        isObjWithValues(masterPricing) &&
        isObjWithValues(masterPricing?.diamond_pricing) &&
        productSettings &&
        productSettings?.default_currency
      ) {
        let diamondQualities = getDiamondQualityWithoutType(
          masterPricing.diamond_pricing[productSettings.default_currency]
        );
        if (diamondQualities) setAllDiamondQualities(diamondQualities);
      }
      // if (
      //   isObjWithValues(productSettings) &&
      //   isArrayWithValues(productSettings.diamond_labs)
      // )
    }, [masterPricing, productSettings]);

    useEffect(() => {
      if (isMounted) onFiltersChange();
    }, [selectedFilters]);

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleScroll = (e, f) => {
      const scrollTop = scrollRef.current.scrollTop;
      const totalHeight = scrollRef.current.scrollHeight;
      const height = scrollRef.current.offsetHeight;
      if (
        totalHeight - height - scrollTop < 10 &&
        !productLoading &&
        !noMoreProducts
      ) {
        setParams((state) => ({ ...state, page: state.page + 1 }));
        fetchProducts(
          { ...params, page: params.page + 1 },
          (products) => setProducts((state) => state.concat(products)),
          setProductsLoading,
          setNoMoreProducts
        );
      }
      // if (
      //   window.innerHeight + document.documentElement.scrollTop !==
      //   document.documentElement.offsetHeight
      // )
      //   return;
      // console.log("Fetch more list items!");
    };

    const onFilterValuePress = (key, value) =>
      setSelectedFilters((state) => ({ ...state, [key]: value }));

    const onFiltersChange = () => {
      let filters = { ...selectedFilters };
      let taxonomies = {
        ...reduxTaxonomies,
        category: reduxTaxonomies?.categories,
        subcategory: reduxTaxonomies?.["sub-categories"],
      };
      console.log(filters);
      let filtersToShow = [];
      for (let key in filters) {
        if (isArrayWithValues(filters[key]))
          for (let label of filters[key]) {
            console.log(key, filters, key, label);
            if (key === "gold_kt") {
              let obj = allGoldPurities.find((i) => i.value === label);
              filtersToShow.push({ key, label, _label: obj.label });
            } else filtersToShow.push({ key, label });
          }
      }
      setFiltersToShow(filtersToShow);

      let updatedParams = { ...params, page: 1 };

      let impParams = ["per_page", "page", "serach", "sku"];
      for (let i in updatedParams)
        if (!impParams.includes(i)) delete updatedParams[i];

      let customFilterFlag = 0;
      if (onlyImage) customFilterFlag++;
      if (facebookProduct) customFilterFlag++;
      for (let key in filters)
        if (isArrayWithValues(filters[key]))
          for (let value of filters[key]) {
            if (
              key === "diamond_lab" ||
              key === "diamond" ||
              key === "gold_kt"
            ) {
              updatedParams[`custom_filter[${customFilterFlag}][key]`] = key;
              updatedParams[`custom_filter[${customFilterFlag}][value]`] =
                value;
              updatedParams[`custom_filter[${customFilterFlag}][compare]`] =
                "LIKE";
              customFilterFlag++;
            } else {
              if (isArrayWithValues(taxonomies[key])) {
                let obj = taxonomies[key].find(
                  (i) => i.slug === value || i.value === value
                );
                if (obj && obj.id)
                  updatedParams[key] = updatedParams[key]
                    ? `${updatedParams[key]}, ${obj.id}`
                    : `${obj.id}`;
              }
            }
          }
      console.log(
        updatedParams,
        taxonomies,
        allSubCategories,
        filters?.subcategory
      );
      // if(filters?.subcategory) updatedParams.subcategory = allSubCategories?.find(o => o?.value = )
      setParams({ ...updatedParams, ...extraParams });
      if (!productLoading)
        fetchProducts(
          { ...updatedParams, ...extraParams },
          setProducts,
          setProductsLoading,
          false
        );
    };

    const deleteFilter = (obj) =>
      setSelectedFilters((state) => ({
        ...state,
        [obj.key]: state[obj.key].filter((i) => i !== obj.label),
      }));

    const onProductClick = (product) => {
      if (selectedProducts.find((i) => i.id === product.id))
        setSelectedProducts((state) =>
          state.filter((i) => i.id !== product.id)
        );
      else
        setSelectedProducts((state) =>
          state.length >= maxProductLimit
            ? [...state]
            : [...state, { ...product }]
        );
    };

    const onSearch = () => {
      setParams((state) => ({ ...state, search }));
      fetchProducts(
        { ...params, search },
        setProducts,
        setProductsLoading,
        false
      );
    };
    const onSearchChange = (e) => {
      if (search.length > e.target.value.length && e.target.value.length === 0)
        fetchProducts(
          { ...params, search: "" },
          setProducts,
          setProductsLoading,
          false
        );

      setSearch(e.target.value);
    };

    const onKeyDown = (e) => {
      if (e.key === "Enter") onSearch();
    };

    const onSelectAll = () => {
      if (allSelected) {
        setSelectedProducts([]);
        setAllSelected(false);
      } else {
        let addedProducts = [...selectedProducts];
        for (let product of products) {
          if (!addedProducts?.find((i) => i.id == product?.id))
            addedProducts.push(product);
        }

        setAllSelected(true);
        setSelectedProducts(
          addedProducts
            ?.slice(0, maxProductLimit)
            ?.filter((i) => !disabledProducts?.includes(i.id))
        );
      }
    };

    return (
      <Box sx={{ p: 3, overflow: "hidden", position: "relative" }}>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Typography variant="h4" sx={{ display: "flex" }}>
            {title ? title : "Select products"}{" "}
            {!isIpad && (
              <Typography
                variant="h4"
                color={"text.secondary"}
                sx={{ display: "inline", marginLeft: 3 }}
              >
                ({selectedProducts.length} Selected)
              </Typography>
            )}
          </Typography>
          <Button
            startIcon={<Close />}
            // variant="outlined"
            onClick={() => onClose && onClose()}
          >
            Close
          </Button>
          {/* <IconButton onClick={() => onClose && onClose()}>
          <Close />
        </IconButton> */}
        </Stack>
        {isIpad && (
          <Typography
            variant="h5"
            color={"text.secondary"}
            sx={{ display: "inline" }}
          >
            ({selectedProducts.length} Selected)
          </Typography>
        )}

        <Fab
          size="medium"
          color="secondary"
          aria-label="add"
          sx={{
            position: "absolute",
            bottom: 20,
            // left: 10,
            right: 20,
            zIndex: 10,
          }}
          onClick={() => onSelect && onSelect(selectedProducts)}
        >
          <Check />
        </Fab>

        <TextField
          value={search}
          onChange={onSearchChange}
          sx={{ mb: 2, mt: 3 }}
          variant="outlined"
          fullWidth
          size="small"
          type="search"
          placeholder="Search products"
          InputProps={{
            sx: { borderRadius: "10px" },
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                {productLoading ? (
                  <CircularProgress size="18px" />
                ) : (
                  <KeyboardReturn size="18px" />
                )}
              </InputAdornment>
            ),
          }}
          onKeyDown={onKeyDown}
          // onSubmit={() => console.log("submit")}
        />

        <Stack
          direction="row"
          mt={2}
          alignItems={"center"}
          sx={{ overflowX: "scroll" }}
          className="scrollbar-hidden"
        >
          <FilterDropdown
            data={allCollections}
            value={selectedFilters.collections}
            label="Collections"
            minWidth="100px"
            onChange={(e) => onFilterValuePress("collections", e.target.value)}
          />
          <FilterDropdown
            data={allCategories}
            value={selectedFilters.category}
            onChange={(e) => onFilterValuePress("category", e.target.value)}
            label="Categories"
            minWidth="100px"
          />
          <FilterDropdown
            data={allSubCategories}
            value={selectedFilters?.["subcategory"]}
            onChange={(e) => onFilterValuePress("subcategory", e.target.value)}
            label="Sub-Categories"
            minWidth="100px"
          />
          <FilterDropdown
            data={allGoldPurities}
            value={selectedFilters.gold_kt}
            onChange={(e) => onFilterValuePress("gold_kt", e.target.value)}
            label="Gold Purity"
            minWidth="100px"
          />
          <FilterDropdown
            data={allDiamondQualities}
            value={selectedFilters.diamond}
            onChange={(e) => onFilterValuePress("diamond", e.target.value)}
            label="Diamond Purity"
            minWidth="100px"
          />
          <FilterDropdown
            data={allDiamondLabs}
            value={selectedFilters.diamond_lab}
            onChange={(e) => onFilterValuePress("diamond_lab", e.target.value)}
            label="Diamond Labs"
            minWidth="100px"
          />
        </Stack>

        <Stack
          direction="row"
          my={2}
          alignItems={"center"}
          className="scrollbar-hidden"
          sx={{ overflowX: "scroll" }}
          spacing={2}
        >
          {filtersToShow.map((i) => {
            return (
              <Chip
                label={i._label || formatServerValue(i.label) || i.label}
                onDelete={() => deleteFilter(i)}
              />
            );
          })}
        </Stack>

        <Stack
          direction="row"
          my={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          className="scrollbar-hidden"
          sx={{ overflowX: "scroll", cursor: "pointer" }}
          spacing={2}
          onClick={onSelectAll}
        >
          <Stack direction="row" alignItems={"center"} spacing={2}>
            {allSelected ? (
              <CheckBox style={{ height: "22px", width: "22px" }} />
            ) : (
              <CheckBoxOutlineBlank style={{ height: "22px", width: "22px" }} />
            )}
            <Typography>Select all</Typography>
          </Stack>
          {selectedProducts.length >= maxProductLimit && (
            <Typography sx={{ ...secondaryLabel, color: "error.main" }}>
              Maximum product limit is {maxProductLimit}
            </Typography>
          )}
        </Stack>

        <Box
          sx={{
            overflowY: "scroll",
            height: "100vh",
            position: "relative",
            pb: 6,
          }}
          className="scrollbar-hidden"
          onScroll={handleScroll}
          ref={scrollRef}
        >
          {productLoading && !isArrayWithValues(products) && (
            <Box
              sx={{
                top: "30%",
                position: "absolute",
                left: "50%",
                transform: "translate(-50%, -50%)",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ textAlign: "center" }} />
              {/* <Typography mt={2} sx={{ textAlign: "center" }}>
              Loading...
            </Typography> */}
            </Box>
          )}

          {products.map((i) => {
            let { images, stock_status, name, sku, id } = i;
            let image = isArrayWithValues(images)
              ? images[0].src
              : "/static/img/woocommerce_placeholder.png";
            let selected = selectedProducts.find((i) => i.id == id);
            let statusChip = allStockStatus?.find(
              (i) => i.value === stock_status
            );
            let isDisabled = disabledProducts?.find((i) => i == id);
            return (
              <Stack
                direction="row"
                py={3}
                alignItems={"center"}
                sx={{
                  cursor: isDisabled ? "default" : "pointer",
                  opacity: isDisabled ? 0.6 : 1,
                }}
                onClick={() => (isDisabled ? null : onProductClick(i))}
              >
                {selected ? (
                  <CheckBox style={{ height: "22px", width: "22px" }} />
                ) : (
                  <CheckBoxOutlineBlank
                    style={{ height: "22px", width: "22px" }}
                  />
                )}
                <img
                  src={image}
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                    margin: "0px 8px",
                  }}
                  onError={() => (image = "")}
                />
                <Box>
                  <Typography variant="h6" fontSize="14px">
                    {name}
                  </Typography>
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <CustomChip
                      status={statusChip?.label}
                      type={statusChip?.type}
                      size="small"
                    />
                    <Typography mt={0.5} color="text.secondary">
                      {sku}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            );
          })}

          {productLoading && isArrayWithValues(products) ? (
            <Typography mt={3} sx={{ textAlign: "center" }} alignItems="center">
              <CircularProgress
                style={{
                  height: "13px",
                  width: "13px",
                  marginRight: "7px",
                  marginTop: "3px",
                }}
              />
              Fetching More Products...
            </Typography>
          ) : (
            <Box sx={{ height: "100px" }} />
          )}
          <Box sx={{ height: "340px" }} />
        </Box>
      </Box>
    );
  }
);

export default SelectProducts;

const FilterDropdown = ({ data, label, value, onChange, minWidth }) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <FormControl
      variant="standard"
      sx={{
        "&:hover": {
          border: "0px solid transparent",
          backgroundColor: "rgba(0,0,0,0.05)",
          borderRadius: "10px",
        },
        "&:focus": {
          backgroundColor: "transparent",
        },
        minWidth: minWidth || "120px",
      }}
      size="small"
    >
      {/* <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel> */}
      <Select
        disableUnderline
        id="demo-multiple-checkbox"
        multiple
        open={open}
        // input={<OutlinedInput label={label} />}
        renderValue={(selected) => label}
        value={value}
        onChange={(e) => {
          handleClose();
          onChange(e);
        }}
        onClose={handleClose}
        onOpen={handleOpen}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        style={{ backgroundColor: "transparent" }}
        SelectDisplayProps={{ style: { backgroundColor: "transparent" } }}
        sx={{
          margin: "4px 6px",
          maxHeight: "300px",
          "&:focus": {
            backgroundColor: "transparent",
          },
        }}
        MenuProps={{
          sx: {
            maxHeight: "400px",
          },
        }}
        size="small"
      >
        {isArrayWithValues(data) &&
          data.length > 0 &&
          data.map((obj) => {
            return (
              <MenuItem
                key={obj.id}
                value={obj.value}
                sx={{
                  borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                }}
              >
                <Checkbox checked={value.indexOf(obj.value) > -1} />
                <ListItemText primary={obj.label} />
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};
