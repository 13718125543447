import { Box, Button, Grid, useTheme } from "@mui/material";
import React from "react";
import { DigigoldFixedPlan } from "../../../../../../Svgs";
import {
  PrimaryLabel,
  SecondaryLabel,
  SecondaryLabelSmall,
} from "../../../../../../helper/utility/Labels";

function RewardTypeSelection({ form, editForm }) {
  return (
    <Box>
      <PrimaryLabel>
        Setup rewards & penalties for your plan{" "}
        <SecondaryLabel component="span">(Optional)</SecondaryLabel>
      </PrimaryLabel>
      <Box
        sx={{
          mt: 5,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          minHeight: "100%",
        }}
      >
        <Box
          sx={{
            borderRadius: "16px",
            boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.22)",
            overflow: "hidden",
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <TypeView
                value="custom"
                title={"Setup custom interval based rewards & penalties"}
                subtitle="Select this option if you want to setup rewards & penalties based on intervals, along with gifts & other settings for each interval"
                form={form}
                editForm={editForm}
                mainButtonProps={{ label: "Customize" }}
                containerStyle={{
                  borderRight: 0.5,
                  borderColor: "#e8e8e8",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TypeView
                value="fixed"
                title={"Setup fixed lumpsum reward"}
                subtitle="Choose this option if you wish to offer you customers a single lumpsum reward at the end of the plan"
                form={form}
                editForm={editForm}
                mainButtonProps={{ label: "Add fixed reward" }}
                containerStyle={{
                  borderLeft: 0.5,
                  borderColor: "#e8e8e8",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default RewardTypeSelection;

const TypeView = ({
  title = "Flexible Plan",
  value,
  form,
  subtitle,
  editForm,
  mainButtonProps = {},
  containerStyle = {},
}) => {
  const selected = form?.reward_type === value;
  const theme = useTheme();
  return (
    <Box
      sx={{
        // border: 2,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        // flex: 1,
        // backgroundColor: selected ? "#E9EFFF" : "transparent",
        minHeight: "320px",
        // height: "300pd",
        // borderColor: selected ? "primary.main" : "transparent",

        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f8faff",
        },
        ...containerStyle,
      }}
    >
      <Box sx={{ textAlign: "center", maxWidth: "80%" }}>
        <img
          src={`/static/img/digital_gold/${value}_reward_icon.png`}
          width="60px"
          height="60px"
        />
        <PrimaryLabel sx={{ mt: 2 }}>{title}</PrimaryLabel>
        <SecondaryLabelSmall sx={{ mt: 2 }}>{subtitle}</SecondaryLabelSmall>
        <Button
          variant="contained"
          onClick={() =>
            editForm({
              reward_type: value,
            })
          }
          sx={{ mt: 4 }}
        >
          {mainButtonProps?.label}
        </Button>
      </Box>
    </Box>
  );
};
