export const pluginPopoverStyles = {
  popover: {
    marginTop: "30px",
    borderRadius: "7px"
  },
  outerBox: {
    width: "200px",
    display: "flex",
    flexDirection: "column",
    margin: "15px",
    background: "#fff",
  },
  innerBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "Inter",
    marginBottom: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  optionText: {
    fontSize: "15px",
    fontWeight: 600,
  },
  icon: {
    "&:hover": {
        cursor: "pointer",
      },
  }
};
