import { isArrayWithValues } from "jwero-javascript-utils";
import { seperateCountryCode } from "../../../helper";
import { getJweroCustomerPhoneNumber } from "../../customers/utils";
import { extractNumbers } from "../../../utils/js";

function replaceTemplateVariables(template, example) {
  const components = template?.components || [];

  for (let i = 0; i < components.length; i++) {
    if (components[i].type === "BODY") {
      let bodyText = components[i].text;
      let exampleText =
        example?.components?.[i]?.parameters?.map((param) => param.text) || [];

      for (let j = 0; j < exampleText.length; j++) {
        const placeholder = "{{" + (j + 1) + "}}";
        bodyText = bodyText?.replace(placeholder, exampleText[j]);
      }

      components[i].text = bodyText;
    } else if (components[i].type === "HEADER") {
      if (components[i].format === "IMAGE") {
        const headerHandle =
          example?.components?.[i]?.parameters?.[0]?.image?.link;
        if (!components[i].example) components[i].example = {};
        if (!components[i].example.header_handle)
          components[i].example.header_handle = [];
        components[i].example.header_handle[0] = headerHandle;
      }
      if (components[i].format === "TEXT") {
        let bodyText = components[i].text;
        let exampleText =
          example?.components?.[i]?.parameters?.map((param) => param.text) ||
          [];

        for (let j = 0; j < exampleText.length; j++) {
          const placeholder = "{{" + (j + 1) + "}}";
          bodyText = bodyText?.replace(placeholder, exampleText[j]);
        }
        components[i].text = bodyText;
      }
    }
  }

  return template;
}
function getMessageAndType(platform, obj) {
  if (!platform || !obj) {
    return {};
  }

  let messageContent = null,
    messageType = null,
    templateAttachmentType = null;
  messageType = obj?.message?.type || obj?.type || "text";
  if (!messageType && obj?.message?.attachments?.[0]?.type)
    messageType = obj?.message?.attachments?.[0]?.type;
  if (platform === "whatsapp") {
    if (messageType === "text")
      messageContent = obj?.message?.text ? obj?.message?.text?.body : null;
    else if (messageType === "image")
      messageContent = obj.message.image ? obj?.message?.image?.link : null;
    else if (messageType === "document")
      messageContent = obj.message.document
        ? obj?.message?.document?.link
        : null;
    else if (messageType === "button")
      messageContent = obj.message.button
        ? obj?.message?.button?.payload || obj?.message?.button?.text
        : null;
    else if (messageType === "contacts")
      messageContent = obj.message.contacts
        ? `${obj.message.contacts?.length} Contacts`
        : null;
    else if (messageType === "interactive") {
      let interactiveType = obj?.message?.interactive?.type;
      messageContent =
        obj?.message?.interactive || obj?.interactive
          ? `${
              obj?.message?.interactive?.header?.text
                ? `${obj?.message?.interactive?.header?.text}\n`
                : ""
            } ${obj?.message?.interactive?.body?.text || ""} ${
              obj?.interactive?.body?.text || ""
            }`?.trim()
          : null;
      if (!messageContent) {
        messageContent = `${
          obj?.message?.interactive?.[interactiveType]?.title || ""
        }`;
      }
    } else if (messageType === "template") {
      let templateWithReplacedVariables = replaceTemplateVariables(
        obj?.meta_data?.template || {},
        obj?.message?.template || {}
      );
      let components =
        templateWithReplacedVariables?.components ||
        obj?.meta_data?.template?.components ||
        obj?.message?.template?.components ||
        [];
      let headerText = components?.find((i) => i.type === "HEADER")?.text;
      let bodyText = components?.find((i) => i.type === "BODY")?.text;
      templateAttachmentType = components
        ?.find((i) => i.type === "HEADER")
        ?.format?.toLowerCase();
      messageContent = headerText || bodyText || "";
      // messageContent = obj.message.template?.header
      //   ? obj.message.template?.header
      //   : obj.message.template?.body
      //   ? obj.message.template?.body
      //   : null;
      if (typeof messageContent !== "string" || !messageContent)
        messageContent = headerText;
    } else if (
      messageType === "unsupported" ||
      messageType === "unsupported_type"
    ) {
      messageContent = "Unsupported message type";
    } else if (messageType === "order") {
      messageContent = "Order";
    }
  } else if (platform === "instagram" || platform === "facebook") {
    if (obj?.message?.text) {
      messageType = "text";
      messageContent = obj?.message?.text?.body || obj?.message?.text;
    } else if (messageType === "share") messageContent = "Share";
    else if (
      messageType === "unsupported" ||
      messageType === "unsupported_type"
    ) {
      messageContent = "Unsupported message type";
    } else if (obj?.message?.is_unsupported) {
      messageContent = "Unsupported message type";
    } else if (
      obj?.message?.attachments &&
      obj?.message?.attachments?.length > 0
    ) {
      const attachment = obj?.message?.attachments[0];
      if (
        // attachment?.type === "image" &&
        attachment?.payload &&
        attachment?.payload.url
      ) {
        messageType = attachment?.type;
        messageContent = attachment?.payload?.url;
      }
    }
  } else if (platform === "webchat") {
    if (obj?.message?.text) {
      messageType = "text";
      messageContent = obj?.message?.text;
    }
  }
  return {
    type: messageType,
    message: messageContent,
    template_attachment_type: templateAttachmentType,
  };
}

const addUserDataInQuickReply = ({ quick_reply = {}, user = {} }) => {
  if (!quick_reply?.content?.text) return quick_reply;
  let personalizedText = quick_reply.content.text;

  personalizedText = personalizedText.replace(
    /{{first_name}}/g,
    user?.first_name || ""
  );
  personalizedText = personalizedText.replace(
    /{{First Name}}/g,
    user?.first_name || ""
  );
  personalizedText = personalizedText.replace(
    /{{last_name}}/g,
    user?.last_name || ""
  );
  personalizedText = personalizedText.replace(
    /{{Last Name}}/g,
    user?.last_name || ""
  );
  personalizedText = personalizedText.replace(/{{email}}/g, user?.email || "");
  personalizedText = personalizedText.replace(/{{Email}}/g, user?.email || "");
  personalizedText = personalizedText.replace(
    /{{Gender}}/g,
    user?.gender || ""
  );
  personalizedText = personalizedText.replace(
    /{{contact_type}}/g,
    user?.contact_type || ""
  );
  personalizedText = personalizedText.replace(
    /{{Contact Type}}/g,
    user?.contact_type || ""
  );
  personalizedText = personalizedText.replace(
    /{{channel}}/g,
    user?.platform || ""
  );
  personalizedText = personalizedText.replace(
    /{{Channel}}/g,
    user?.platform || ""
  );
  personalizedText = personalizedText.replace(
    /{{birthday}}/g,
    user?.meta_data?.birthday ? formatTaskDate(user?.meta_data?.birthday) : ""
  );
  personalizedText = personalizedText.replace(
    /{{Birthday}}/g,
    user?.meta_data?.birthday ? formatTaskDate(user?.meta_data?.birthday) : ""
  );
  personalizedText = personalizedText.replace(
    /{{anniversary}}/g,
    user?.meta_data?.anniversary
      ? formatTaskDate(user?.meta_data?.anniversary)
      : ""
  );
  personalizedText = personalizedText.replace(
    /{{city}}/g,
    user?.meta_data?.city || ""
  );
  personalizedText = personalizedText.replace(
    /{{state}}/g,
    user?.meta_data?.state || ""
  );
  personalizedText = personalizedText.replace(
    /{{country}}/g,
    user?.meta_data?.country || ""
  );
  personalizedText = personalizedText.replace(
    /{{City}}/g,
    user?.meta_data?.city || ""
  );
  personalizedText = personalizedText.replace(
    /{{State}}/g,
    user?.meta_data?.state || ""
  );
  personalizedText = personalizedText.replace(
    /{{Country}}/g,
    user?.meta_data?.country || ""
  );
  personalizedText = personalizedText.replace(
    /{{Address}}/g,
    `${user?.meta_data?.city ? `${user?.meta_data?.city},` : ""} ${
      user?.meta_data?.state ? `${user?.meta_data?.state},` : ""
    } ${user?.meta_data?.country ? `${user?.meta_data?.country},` : ""}`
  );
  quick_reply.content.text = personalizedText;
  return quick_reply;
};

function getErrorMessage(data) {
  // Check if the 'statuses' object exists and has a 'failed' status
  if (data?.statuses && data?.statuses?.failed) {
    const failedStatus = data.statuses.failed;

    // Check if there are errors in the 'failed' status
    if (failedStatus.errors && failedStatus.errors.length > 0) {
      // Assuming we want to show the first error message
      return getErrorContentFromWhatsapp({
        error: failedStatus.errors[0],
      });
      // const firstError = failedStatus.errors[0];

      // // Constructing the error message to show to the user
      // let errorMessage;
      // //  = firstError.message || "An error occurred.";

      // // Adding details if available
      // if (firstError.error_data && firstError.error_data.details) {
      //   errorMessage = `${firstError.error_data.details}`;
      // } else if (firstError?.title) {
      //   errorMessage = `${firstError.title}`;
      // }

      // return {
      //   success: true,
      //   errorMessage: errorMessage,
      //   errorCode: firstError.code || null,
      //   errorLink: firstError.href || null,
      // };
    }
  }
  // If no errors were found or the structure was unexpected
  return {
    success: false,
    // message: "No errors found or the data structure was unexpected.",
  };
}

const getErrorContentFromWhatsapp = ({ error: firstError = {} }) => {
  // Constructing the error message to show to the user
  let errorMessage;
  //  = firstError.message || "An error occurred.";

  // Adding details if available
  if (firstError.error_data && firstError.error_data.details) {
    errorMessage = `${firstError.error_data.details}`;
  } else if (firstError?.title) {
    errorMessage = `${firstError.title}`;
  }

  return {
    success: true,
    errorMessage: errorMessage,
    errorCode: firstError.code || null,
    errorLink: firstError.href || null,
  };
};

const getPhoneNumberFromChatUser = ({ user }) => {
  let { whatsapp, number, dialcode_mobile, dialcode_whatsapp, platform_id } =
    user;
  let response = {};
  if (number && dialcode_mobile) {
    response.dialcode = dialcode_mobile;
    response.phone = number;
  } else if (whatsapp && dialcode_whatsapp) {
    response.dialcode = dialcode_whatsapp;
    response.phone = whatsapp;
  } else if (platform_id) {
    let { dial_code, phone_number } = seperateCountryCode(`${platform_id}`);
    response.dialcode = dial_code;
    response.phone = phone_number;
  }
  response.data = `${response.dialcode + "" + response.phone + ""}`;
  if (response.data) response.success = true;
  return response;
};

const createChatUserPayloadFromCRM = ({ crm_user, platform = "whatsapp" }) => {
  try {
    let { first_name, last_name, billing, meta_data, email } = crm_user;
    if (isArrayWithValues(meta_data))
      for (let metaObj of meta_data) {
        if (!crm_user[metaObj.key]) crm_user[metaObj.key] = metaObj.value;
      }

    let phoneNumberRes = getJweroCustomerPhoneNumber({ customer: crm_user });
    let phone = crm_user?.digits_phone_no || billing?.phone;
    let dialcode_mobile =
      crm_user?.digt_countrycode || crm_user?.dialcode_mobile;
    let dialcode_whatsapp = crm_user?.dialcode_whatsapp;

    let payload = {
      first_name: first_name || billing?.first_name,
      last_name: last_name || billing?.last_name,
      platform,
      email: email || billing?.email,
      number: phone,
      dialcode_mobile,
      dialcode_whatsapp,
      whatsapp: crm_user?.whatsapp,
      meta_data: {},
    };

    if (!payload.number) payload.number = phoneNumberRes?.phone;
    if (!payload.whatsapp) payload.whatsapp = phoneNumberRes?.phone;
    if (!payload.dialcode_mobile)
      payload.dialcode_mobile = phoneNumberRes?.dialcode;
    if (!payload.dialcode_whatsapp)
      payload.dialcode_whatsapp = phoneNumberRes?.dialcode;

    if (payload.dialcode_mobile)
      payload.dialcode_mobile = payload.dialcode_mobile?.replace("+", "");
    if (payload.dialcode_whatsapp)
      payload.dialcode_whatsapp = payload.dialcode_whatsapp?.replace("+", "");

    if (platform === "whatsapp") {
      if (
        (payload?.dialcode_mobile || payload?.dialcode_whatsapp) &&
        (payload?.number || payload?.whatsapp)
      )
        payload.platform_id = `${
          payload?.dialcode_whatsapp || payload?.dialcode_mobile
        }${payload?.whatsapp || payload?.number}`;
      payload.platform_id = extractNumbers(payload.platform_id);
    }
    if (platform === "sms") {
      if (
        (payload?.dialcode_mobile || payload?.dialcode_whatsapp) &&
        (payload?.number || payload?.whatsapp)
      )
        payload.platform_id = `${
          payload?.dialcode_mobile || payload?.dialcode_whatsapp
        }${payload?.number || payload?.whatsapp}`;
      payload.platform_id = extractNumbers(payload.platform_id);
    } else if (platform === "email") {
      if (payload?.email) payload.platform_id = payload?.email;
    } else if (platform === "push") {
      payload.platform_id = `push_${crm_user?.id}`;
      payload.meta_data.push_tokens = crm_user.push_notify?.map((i) => ({
        token: i,
      }));
    }

    return payload;
  } catch (error) {
    console.log(error);
  }
};

export {
  getMessageAndType,
  addUserDataInQuickReply,
  getErrorContentFromWhatsapp,
  getErrorMessage,
  getPhoneNumberFromChatUser,
  createChatUserPayloadFromCRM,
};
