import { Cached, Cancel, CheckCircle } from "@material-ui/icons";
import axios from "axios";

import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/system";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAxiosError,
  getPricingdiff,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isObjWithValues,
} from "../../helper";
// import { getMasterSettings } from "../../../redux/actions/settingsAction";
import {
  EDIT_SETTINGS,
  fetchAllSettings,
} from "../../redux/actions/settingActions";
import {
  setGlobalToast,
  setGlobalTutorial,
} from "../../redux/actions/helperActions";
import { YouTube } from "@mui/icons-material";
import { tutorialData } from "../../App";
import { fetchAdminTaxonomies } from "../../redux/actions/productActions";
import InfoTooltip from "../components/InfoTooltip";
export const GoldView = ({ style }) => (
  <div
    style={{
      width: "20px",
      height: "20px",
      borderRadius: "14px",
      background: "linear-gradient(to right, #FFD700,#F0E68C)",
      ...style,
    }}
  />
);

export const SilverView = ({ style }) => (
  <div
    style={{
      width: "20px",
      height: "20px",
      borderRadius: "14px",
      background: "linear-gradient(to right, #C0C0C0,#cfcfcf)",
      ...style,
    }}
  />
);

export const PlatinumView = ({ style }) => (
  <div
    style={{
      width: "20px",
      height: "20px",
      borderRadius: "14px",
      background: "linear-gradient(to right, #E5E4E2,#edeceb)",
      ...style,
    }}
  />
);
export const CZView = ({ style }) => (
  <div
    style={{
      width: "20px",
      height: "20px",
      borderRadius: "14px",
      background: "linear-gradient(to right, #ADD8E6, #b8d8e3)",
      ...style,
    }}
  />
);
export const AntiqueView = ({ style }) => (
  <div
    style={{
      width: "20px",
      height: "20px",
      borderRadius: "14px",
      background: "linear-gradient(to right, #C2B280, #b5a982)",
      ...style,
    }}
  />
);

const DiamondView = () => (
  <div
    style={{
      width: "20px",
      height: "20px",
      borderRadius: "14px",
      background: "linear-gradient(to right, #b9f2ff,#ccf6ff )",
    }}
  />
);
// const DiamondView = () => (
//   <Diamond
//     sx={{
//       width: "20px",
//       height: "20px",
//       // borderRadius: "14px",
//       color: "#b9f2ff",
//     }}
//   />
// );
// const GemstoneView = () => (
//   <Gemstone
//     fill="#79ad96"
//     style={{
//       width: "20px",
//       height: "20px",
//       // borderRadius: "14px",
//       // background: "linear-gradient(to right, #79ad96,#91baa8 )",
//     }}
//   />
// );
// const BrassView = () => (
//   <div
//     style={{
//       width: "20px",
//       height: "20px",
//       borderRadius: "14px",
//       background: "linear-gradient(to right, #E1C16E,#edd595 )",
//     }}
//   />
// );
const GridComponent = (props) => {
  const { label, children, titleTooltip } = props;
  return (
    <Card
      sx={{ marginTop: "10px", maxWidth: "100%", overflow: "hidden" }}
      className="light_shadow_card"
    >
      <CardContent>
        <Stack direction={"row"} gutterBottom alignItems="center" spacing={1}>
          <Typography variant="h5" display="inline">
            {label}
          </Typography>
          {titleTooltip && <InfoTooltip title={titleTooltip} />}
        </Stack>
        {children}
        {/* <Grid container spacing={2}>
            <Grid item sx={12}>
            </Grid>
            <Grid item sx={12}>
            </Grid>
          </Grid> */}
      </CardContent>
    </Card>
  );
};
// function getObjectDiff(oldObj, newObj) {
//   const diff = {};

//   // Check for added, removed, or changed properties in newObj
//   for (let prop in newObj) {
//     if (typeof newObj[prop] === 'object' && newObj[prop] !== null) {
//       if (typeof oldObj[prop] !== 'object' || oldObj[prop] === null) {
//         // Property changed from non-object to object
//         diff[prop] = { new: newObj[prop] };
//       } else {
//         // Recursively check nested objects and arrays
//         const nestedDiff = getObjectDiff(oldObj[prop], newObj[prop]);
//         if (Object.keys(nestedDiff).length > 0) {
//           diff[prop] = nestedDiff;
//         }
//       }
//     } else if (!oldObj.hasOwnProperty(prop) || oldObj[prop] !== newObj[prop]) {
//       diff[prop] = { old: oldObj[prop], new: newObj[prop] };
//     }
//   }

//   // Check for removed properties in oldObj
//   for (let prop in oldObj) {
//     if (!newObj.hasOwnProperty(prop)) {
//       diff[prop] = { old: oldObj[prop], new: undefined };
//     }
//   }

//   // Check for changes in string properties
//   if (typeof oldObj === 'string' && typeof newObj === 'string' && oldObj !== newObj) {
//     diff[''] = { old: oldObj, new: newObj };
//   }

//   // Check for changes in array lengths
//   if (Array.isArray(oldObj) && Array.isArray(newObj) && oldObj.length !== newObj.length) {
//     diff[''] = { old: oldObj, new: newObj };
//   }

//   return diff;
// }

const defaultViewState = {
  selectCurrency: true,
  selectJewelleryType: true,
  selectMetalTypes: true,
  selectMetalPurity: true,
  selectMetalColors: true,
  netWeight: true,
  diamond: true,
  gemstone: true,
  collections: true,
};
const MasterSetting = forwardRef(
  ({ hideUpdate = false, updateLoading }, ref) => {
    const updateSettingsInRedux = (settings) => {
      dispatch({
        type: EDIT_SETTINGS,
        payload: { productSettings: { ...settings } },
      });
    };
    const reduxMasterSetting = useSelector(
      (state) => state.settings.productSettings
    );
    const fetchingMasterSettings = useSelector(
      (state) => state.settings.fectchingProductSettings
    );
    const fetchingAllSettings = useSelector(
      (state) => state.settings.fetchingAllSettings
    );
    const allSettingsFetched = useSelector(
      (state) => state.settings.allSettingsFetched
    );
    const admin_taxonomies = useSelector(
      (state) => state.product?.admin_taxonomies
    );
    const user_products = useSelector(
      (state) => state.user?.store_plan?.user_products
    );
    const dispatch = useDispatch();
    useEffect(() => {
      // if (!isObjWithValues(reduxMasterSetting)) dispatch(getProductSettings());
      if (!isObjWithValues(admin_taxonomies)) dispatch(fetchAdminTaxonomies());
    }, []);

    const [fetchingSettings, setFetchingSettings] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [allCollections, setAllCollections] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [showCardState, setShowCardState] = useState(defaultViewState);

    useEffect(() => {
      if (!allSettingsFetched) dispatch(fetchAllSettings());
    }, []);

    useImperativeHandle(ref, () => ({
      onSave(afterUpdate, website, token) {
        onUpdate(afterUpdate, website, token);
      },
      // getAlert() {
      //   alert("getAlert from Child");
      // }
    }));

    useEffect(() => {
      setFetchingSettings(fetchingMasterSettings);
    }, [fetchingMasterSettings]);

    const [settings, setSettings] = useState({
      types: [],
      gold_purities: [],
      silver_purities: [],
      platinum_purities: [],
      colors: [],
      net_weight: "",
      auto_diamond_sieve: "",
      collections: [],
      categories: [],
      diamond_types: [],
      diamond_clarities: [],
      diamond_cuts: [],
      diamond_shapes: [],
      diamond_colors: [],
      diamond_sizes: [],
      currency: [],
      default_currency: "",
      gemstone_qualities: [],
      gemstone_shapes: [],
      gemstone_types: [],
      diamond_labs: [],
      hide_pricing: false,
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const user_type = useSelector((state) => state.user.user_type);
    useEffect(() => {
      if (isObjWithValues(reduxMasterSetting)) setSettings(reduxMasterSetting);
    }, [reduxMasterSetting]);

    useEffect(() => {
      if (admin_taxonomies && Object.keys(admin_taxonomies).length > 0) {
        let { collections, categories } = admin_taxonomies;
        if (Array.isArray(collections) && collections.length > 0)
          setAllCollections(collections);
        if (Array.isArray(categories) && categories.length > 0)
          setAllCategories(categories);
      }
      //eslint-disable-next-line
    }, [admin_taxonomies]);

    useEffect(() => {
      let onlyChats =
        user_products?.length === 1 && user_products?.includes("chats");
      if (onlyChats) {
        let showTabs = [
          "selectCurrency",
          "selectMetalTypes",
          "selectMetalPurity",
        ];
        let showObj = {};
        for (const key of showTabs) {
          showObj[key] = true;
        }
        console.log(showObj);
        setShowCardState(showObj);
      }
    }, [user_products]);

    const onSettingsChange = (label, obj) => {
      let data = settings[label];
      if (obj.icon) delete obj.icon;
      if (isArrayWithValues(data))
        if (data.find((i) => i.value === obj.value))
          data = data.filter((i) => i.value !== obj.value);
        else data.push(obj);
      else data = [obj];
      setSettings((pre) => ({ ...pre, [label]: data }));
      // let data = [obj];
      // console.log(settings.default_currency, data);
      // setSettings((state) => ({
      //   ...state,
      //   currency: data,
      //   default_currency: data[0].value,
      // }));
      // onDropdownChange(label, data);
    };
    const onCurrencyChange = (label, obj) => {
      // let data = settings[label];
      if (obj.icon) delete obj.icon;
      // if (isArrayWithValues(data))
      //   if (data.find((i) => i.value === obj.value))
      //     data = data.filter((i) => i.value !== obj.value);
      //   else data.push(obj);
      // else data = [obj];
      let data = [obj];
      setSettings((state) => ({
        ...state,
        currency: data,
        default_currency: data[0].value,
      }));
      // onDropdownChange(label, data);
    };
    const onSettingsToggle = (label, value) => {
      let data = settings[label];
      if (data === value) data = "";
      else data = value;
      setSettings({ ...settings, [label]: value });
    };
    const onUpdate = async (afterUpdate, website, token) => {
      if (!website) website = getWebsite();
      if (!token) token = getToken();
      // if (!website || !token) return false;

      // return;
      setUpdating(true);
      updateLoading && updateLoading(true);
      try {
        let res = await axios({
          url: `${website}/wp-json/store/v1/settings/business_settings`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "POST",
          data: {
            type: "business_settings",
            data: { ...settings },
          },
        });
        try {
          await axios({
            url: `${website}/wp-json/store/v1/settings/woocommerce_currency`,
            headers: {
              Authorization: `Basic ${token}`,
            },
            method: "POST",
            data: {
              type: "woocommerce_currency",
              data: settings.default_currency,
            },
          });
        } catch (error) {}
        if (typeof afterUpdate === "function") await afterUpdate();
        if (res.data && isObjWithValues(res.data.data))
          updateSettingsInRedux({ ...res.data.data });
        dispatch(
          setGlobalToast({
            show: true,
            message: "Settings updated successfully!",
            severity: "success",
          })
        );
      } catch (error) {
        console.log(getAxiosError(error));
      }
      setUpdating(false);
      updateLoading && updateLoading(false);
      // dispatch(
      //   getProductSettings({
      //     method: "POST",
      //     data: { type: "add", data: settings },
      //   })
      // );
    };

    const onDropdownChange = (label, value) => {
      setSettings({ ...settings, [label]: value });
    };

    const onRemoveDropdown = (label, value) => {
      let data = settings[label];
      console.log(data, label, value);
      if (isArrayWithValues(data)) data = data.filter((i) => i.label !== value);
      console.log(data, label, value);
      onDropdownChange(label, data);
    };
    return (
      <Box sx={{ maxWidth: "100%", overflow: "hidden" }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems={"center"}
          sx={{
            display: hideUpdate ? "none" : "flex",
          }}
        >
          <IconButton
            onClick={() =>
              dispatch(
                setGlobalTutorial({
                  show: true,
                  url: tutorialData?.master_pricing?.link,
                  title: tutorialData?.master_pricing?.title,
                })
              )
            }
          >
            <YouTube />
          </IconButton>
          <div style={{ alignItems: "center", display: "flex" }}>
            <div>
              {fetchingSettings ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size="13px" />
                    <Typography variant="body1" ml={2} mr={2}>
                      Fetching settings
                    </Typography>
                  </div>
                </>
              ) : (
                <LoadingButton
                  loading={fetchingMasterSettings || fetchingAllSettings}
                  onClick={() => {
                    dispatch(fetchAllSettings());
                  }}
                  startIcon={<Cached />}
                >
                  Refresh
                </LoadingButton>
              )}
            </div>
            {
              //   !hideUpdateButton
              true && (
                <div>
                  <LoadingButton
                    loading={updating}
                    disabled={fetchingSettings || fetchingAllSettings}
                    variant="contained"
                    onClick={onUpdate}
                  >
                    Save Changes
                  </LoadingButton>
                </div>
              )
            }
          </div>
        </Stack>
        <Card
          sx={{ marginTop: "10px", pt: 1, display: isDev() ? "block" : "none" }}
          className="light_shadow_card"
        >
          <CardContent>
            <Stack>
              <Stack
                direction={"row"}
                sx={{ gap: "30px" }}
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Typography variant="h5">Use pricing?</Typography>

                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button
                    variant={!settings.hide_pricing ? "contained" : "outlined"}
                    onClick={() => onSettingsToggle("hide_pricing", false)}
                  >
                    Yes
                  </Button>
                  <Button
                    variant={settings.hide_pricing ? "contained" : "outlined"}
                    onClick={() => onSettingsToggle("hide_pricing", true)}
                  >
                    NO
                  </Button>
                </ButtonGroup>
              </Stack>
              <FormHelperText>
                Only prices that are set manually will be shown, while prices
                that are automatically generated will be kept hidden.
              </FormHelperText>
            </Stack>
          </CardContent>
        </Card>

        {showCardState?.selectCurrency && (
          <Card sx={{ marginTop: "10px" }} className="light_shadow_card">
            <CardContent>
              <Stack
                direction="row"
                alignItems={"center"}
                // justifyContent={"space-between"}
                spacing={1}
              >
                <Typography variant="h5">Select Currency</Typography>
                {/* <FormControl size="small" sx={{ minWidth: "100px" }}>
              <InputLabel id="demo-simple-select-label">default</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={settings.default_currency || []}
                label="default"
                unselectable
                onChange={(e) =>
                  setSettings((state) => ({
                    ...state,
                    default_currency: e.target.value,
                  }))
                }
              >
                {isArrayWithValues(settings.currency) &&
                  settings.currency.map((i) => (
                    <MenuItem
                      value={i.value}
                    >{`${i.label} (${i.symbol})`}</MenuItem>
                  ))}
              </Select>
            </FormControl> */}
                <InfoTooltip title="Select any one or more currency (based on subscription plan) for all your product pricing" />
              </Stack>
              <div
                style={{
                  display: "flex",
                  marginTop: "12px",
                  flexWrap: "wrap",
                }}
              >
                {allCurrency.map((i) => (
                  <CurrencyCard
                    obj={i}
                    icon={i.symbol}
                    isSelected={
                      settings.currency &&
                      settings.currency.find((obj) => obj.value === i.value)
                    }
                    onClick={(value) => {
                      onCurrencyChange("currency", value);
                    }}
                    isMobile={isMobile}
                  />
                ))}
                {/* <JewelleryTypeCard label="Silver" icon={<SilverView />} />
          <JewelleryTypeCard label="Platinum" icon={<PlatinumView />} /> */}
              </div>
            </CardContent>
          </Card>
        )}
        {showCardState?.selectJewelleryType && (
          <GridComponent
            label="Select Jewellery type"
            titleTooltip="Select your preferred Jewellery type in which you are dealing"
          >
            <div
              style={{
                display: "flex",
                marginTop: "12px",
                flexWrap: "wrap",
              }}
            >
              {jewelleryTypes.map((i) => (
                <JewelleryTypeCard
                  obj={i}
                  icon={i.icon}
                  isSelected={
                    isArrayWithValues(settings.types) &&
                    settings.types.find((obj) => obj.value === i.value)
                  }
                  onClick={(value) => onSettingsChange("types", value)}
                  isMobile={isMobile}
                />
              ))}
              {/* <JewelleryTypeCard label="Silver" icon={<SilverView />} />
              <JewelleryTypeCard label="Platinum" icon={<PlatinumView />} /> */}
            </div>
          </GridComponent>
        )}
        {showCardState?.selectMetalTypes && (
          <GridComponent
            label="Select Metal types"
            titleTooltip="Select your preferred Metal types in which you are dealing"
          >
            <div
              style={{
                display: "flex",
                marginTop: "12px",
                flexWrap: "wrap",
              }}
            >
              {metalTypes.map((i) => (
                <JewelleryTypeCard
                  obj={i}
                  icon={i.icon}
                  isSelected={
                    isArrayWithValues(settings.types) &&
                    settings.types.find((obj) => obj.value === i.value)
                  }
                  onClick={(value) => onSettingsChange("types", value)}
                  isMobile={isMobile}
                />
              ))}
              {/* <JewelleryTypeCard label="Silver" icon={<SilverView />} />
              <JewelleryTypeCard label="Platinum" icon={<PlatinumView />} /> */}
            </div>
          </GridComponent>
        )}

        {showCardState?.selectMetalPurity && (
          <GridComponent
            label="Select Metal Purity"
            titleTooltip="Select your preferred metal purities in which you are dealing"
          >
            {isArrayWithValues(settings.types) &&
            settings.types.find((i) => i.value === "gold") ? (
              <>
                <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
                  Gold
                </Typography>
                <div
                  style={{
                    display: "flex",
                    marginTop: "4px",
                    flexWrap: "wrap",
                  }}
                >
                  {purities.map((i) => (
                    <PurityCard
                      obj={i}
                      isSelected={settings.gold_purities.find(
                        (obj) => obj.value === i.value
                      )}
                      onClick={(value) => {
                        onSettingsChange("gold_purities", value);
                      }}
                      isMobile={isMobile}
                    />
                  ))}
                </div>
              </>
            ) : null}
            {isArrayWithValues(settings.types) &&
            settings.types.find((i) => i.value === "silver") ? (
              <>
                <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
                  Silver
                </Typography>
                <div
                  style={{
                    display: "flex",
                    marginTop: "4px",
                    flexWrap: "wrap",
                  }}
                >
                  {silverPurities.map((i) => (
                    <PurityCard
                      obj={i}
                      isSelected={settings.silver_purities.find(
                        (obj) => obj.value === i.value
                      )}
                      onClick={(value) =>
                        onSettingsChange("silver_purities", value)
                      }
                      isMobile={isMobile}
                    />
                  ))}
                </div>
              </>
            ) : null}
            {isArrayWithValues(settings.types) &&
            settings.types.find((i) => i.value === "platinium") ? (
              <>
                <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
                  Platinum
                </Typography>
                <div
                  style={{
                    display: "flex",
                    marginTop: "4px",
                    flexWrap: "wrap",
                  }}
                >
                  {platinumPurities.map((i) => (
                    <PurityCard
                      obj={i}
                      isSelected={settings.platinum_purities.find(
                        (obj) => obj.value === i.value
                      )}
                      onClick={(value) =>
                        onSettingsChange("platinum_purities", value)
                      }
                      isMobile={isMobile}
                    />
                  ))}
                </div>
              </>
            ) : null}
          </GridComponent>
        )}
        {!settings.hide_pricing ? (
          <>
            {showCardState?.selectMetalColors && (
              <GridComponent
                label="Select Metal Colors"
                titleTooltip="Select preferred metal color in which you are dealing"
              >
                <div
                  style={{
                    display: "flex",
                    marginTop: "12px",
                    flexWrap: "wrap",
                  }}
                >
                  {colors.map((i) => (
                    <ColorCard
                      obj={i}
                      isSelected={
                        settings.colors &&
                        settings.colors.find((obj) => obj.value === i.value)
                      }
                      onClick={(value) => onSettingsChange("colors", value)}
                      isMobile={isMobile}
                    />
                  ))}
                </div>
              </GridComponent>
            )}
            {showCardState?.netWeight && (
              <GridComponent
                label="Net weight"
                titleTooltip="If you want system to calculate metal net wt automatically, select YES"
              >
                <div
                  style={{
                    display: "flex",
                    marginTop: "12px",
                    flexWrap: "wrap",
                  }}
                >
                  {netWeights.map((i) => (
                    <WeightCard
                      obj={i}
                      isSelected={settings.net_weight === i.value}
                      onClick={(value) => onSettingsToggle("net_weight", value)}
                      isMobile={isMobile}
                    />
                  ))}
                </div>
              </GridComponent>
            )}
            {showCardState?.diamond && (
              <Collapse
                in={
                  // true
                  isArrayWithValues(settings.types) &&
                  settings.types.find((i) => i.value === "diamond")
                }
              >
                <GridComponent
                  label="Diamond"
                  titleTooltip="Select the parameters appropriate for your business"
                >
                  {/* <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
                Auto Diamond Sieve
              </Typography>
              <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
                {netWeights.map((i) => (
                  <WeightCard
                    obj={i}
                    isSelected={settings.auto_diamond_sieve === i.value}
                    onClick={(value) =>
                      onSettingsToggle("auto_diamond_sieve", value)
                    }
                    isMobile={isMobile}
                  />
                ))}
              </div> */}
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    mt={3}
                    spacing={1}
                  >
                    <Typography variant="body1" fontWeight="bold" ml={2}>
                      Diamond Type
                    </Typography>
                    <InfoTooltip title="Select Diamond type in which you deals in" />
                  </Stack>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "12px",
                      flexWrap: "wrap",
                    }}
                  >
                    {diamond_types.map((i) => (
                      <PurityCard
                        obj={i}
                        // icon={i.icon}
                        isSelected={
                          settings.diamond_types &&
                          settings.diamond_types.find(
                            (obj) => obj.value === i.value
                          )
                        }
                        onClick={(value) =>
                          onSettingsChange("diamond_types", value)
                        }
                        isMobile={isMobile}
                      />
                    ))}
                  </div>

                  <Stack
                    direction={"row"}
                    alignItems="center"
                    mt={3}
                    spacing={1}
                  >
                    <Typography variant="body1" fontWeight="bold" ml={2}>
                      Diamond Clarity
                    </Typography>
                    <InfoTooltip title="Select preferred Diamond qualities" />
                  </Stack>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "12px",
                      flexWrap: "wrap",
                    }}
                  >
                    {diamondClarity.map((i) => (
                      <PurityCard
                        obj={i}
                        // icon={i.icon}
                        isSelected={
                          settings.diamond_clarities &&
                          settings.diamond_clarities.find(
                            (obj) => obj.value === i.value
                          )
                        }
                        onClick={(value) =>
                          onSettingsChange("diamond_clarities", value)
                        }
                        isMobile={isMobile}
                      />
                    ))}
                  </div>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    mt={3}
                    spacing={1}
                  >
                    <Typography variant="body1" fontWeight="bold" ml={2}>
                      Diamond Colors
                    </Typography>
                    <InfoTooltip
                      title={[
                        "Select  Diamond color.  Based on the selection, the system will create groups.  Eg. if you select G, H and I",
                        "These groups will gets created G GH GHI, H, HI, I",
                      ]}
                    />
                  </Stack>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "12px",
                      flexWrap: "wrap",
                    }}
                  >
                    {diamondColors.map((i) => (
                      <PurityCard
                        obj={i}
                        // icon={i.icon}
                        isSelected={
                          settings.diamond_colors &&
                          settings.diamond_colors.find(
                            (obj) => obj.value === i.value
                          )
                        }
                        onClick={(value) =>
                          onSettingsChange("diamond_colors", value)
                        }
                        isMobile={isMobile}
                      />
                    ))}
                  </div>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    mt={3}
                    spacing={1}
                  >
                    <Typography variant="body1" fontWeight="bold" ml={2}>
                      Diamond Shapes
                    </Typography>
                    <InfoTooltip
                      title={[
                        "Select shapes in which you you makes the jewellery",
                      ]}
                    />
                  </Stack>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "12px",
                      flexWrap: "wrap",
                    }}
                  >
                    {diamondShapes.map((i) => (
                      <PurityCard
                        obj={i}
                        // icon={i.icon}
                        isSelected={
                          settings.diamond_shapes &&
                          settings.diamond_shapes.find(
                            (obj) => obj.value === i.value
                          )
                        }
                        onClick={(value) =>
                          onSettingsChange("diamond_shapes", value)
                        }
                        isMobile={isMobile}
                      />
                    ))}
                  </div>
                  <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
                    Diamond Cut
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "12px",
                      flexWrap: "wrap",
                    }}
                  >
                    {diamondCut.map((i) => (
                      <PurityCard
                        obj={i}
                        // icon={i.icon}
                        isSelected={
                          settings.diamond_cuts &&
                          settings.diamond_cuts.find(
                            (obj) => obj.value === i.value
                          )
                        }
                        onClick={(value) =>
                          onSettingsChange("diamond_cuts", value)
                        }
                        isMobile={isMobile}
                      />
                    ))}
                  </div>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    mt={3}
                    spacing={1}
                  >
                    <Typography variant="body1" fontWeight="bold" ml={2}>
                      Diamond Labs
                    </Typography>
                    <InfoTooltip
                      title={[
                        "Select the diamond laboratories where you certify your diamond jewellery",
                      ]}
                    />
                  </Stack>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "12px",
                      flexWrap: "wrap",
                    }}
                  >
                    {diamondLabs.map((i) => (
                      <PurityCard
                        obj={i}
                        // icon={i.icon}
                        isSelected={
                          settings.diamond_labs &&
                          settings.diamond_labs.find(
                            (obj) => obj.value === i.value
                          )
                        }
                        onClick={(value) =>
                          onSettingsChange("diamond_labs", value)
                        }
                        isMobile={isMobile}
                      />
                    ))}
                  </div>
                </GridComponent>
              </Collapse>
            )}
            {showCardState?.gemstone && (
              <Collapse
                in={
                  // true
                  isArrayWithValues(settings.types) &&
                  settings.types.find((i) => i.value === "gemstone")
                }
              >
                <GridComponent label="Gemstone">
                  <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
                    Gemstone Qualities
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "12px",
                      flexWrap: "wrap",
                    }}
                  >
                    {gemstoneQualities.map((i) => (
                      <PurityCard
                        obj={i}
                        // icon={i.icon}
                        isSelected={
                          settings.gemstone_qualities &&
                          settings.gemstone_qualities.find(
                            (obj) => obj.value === i.value
                          )
                        }
                        onClick={(value) =>
                          onSettingsChange("gemstone_qualities", value)
                        }
                        isMobile={isMobile}
                      />
                    ))}
                  </div>
                  <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
                    Gemstone Types
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "12px",
                      flexWrap: "wrap",
                    }}
                  >
                    {gemstoneTypes.map((i) => (
                      <PurityCard
                        obj={i}
                        // icon={i.icon}
                        isSelected={
                          settings.gemstone_types &&
                          settings.gemstone_types.find(
                            (obj) => obj.value === i.value
                          )
                        }
                        onClick={(value) =>
                          onSettingsChange("gemstone_types", value)
                        }
                        isMobile={isMobile}
                      />
                    ))}
                  </div>
                  <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
                    Gemstone Shapes
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "12px",
                      flexWrap: "wrap",
                    }}
                  >
                    {gemstoneShapes.map((i) => (
                      <PurityCard
                        obj={i}
                        // icon={i.icon}
                        isSelected={
                          settings.gemstone_shapes &&
                          settings.gemstone_shapes.find(
                            (obj) => obj.value === i.value
                          )
                        }
                        onClick={(value) =>
                          onSettingsChange("gemstone_shapes", value)
                        }
                        isMobile={isMobile}
                      />
                    ))}
                  </div>
                </GridComponent>
              </Collapse>
            )}
            {showCardState?.collections && (
              <>
                {user_type === "supplier" ? (
                  <>
                    <Card sx={{ marginTop: "10px" }}>
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h5"
                            gutterBottom
                            display="inline"
                          >
                            Collections
                          </Typography>
                          <Dropdown
                            data={allCollections}
                            value={settings.collections}
                            onChange={(e, value) =>
                              onDropdownChange("collections", e.target.value)
                            }
                            label="Select collections"
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "12px",
                            flexWrap: "wrap",
                          }}
                        >
                          {isArrayWithValues(settings.collections)
                            ? settings.collections.map((i) => (
                                <CollectionCard
                                  label={i?.label}
                                  isMobile={isMobile}
                                  onRemove={(value) =>
                                    onRemoveDropdown("collections", value)
                                  }
                                />
                              ))
                            : null}
                          {/* <CollectionCard label="Antique" />
                <CollectionCard label="Gemstone" /> */}
                        </div>
                      </CardContent>
                    </Card>
                    <Card sx={{ marginTop: "10px" }}>
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h5"
                            gutterBottom
                            display="inline"
                          >
                            Categories
                          </Typography>
                          <Dropdown
                            label="Select Categories"
                            data={allCategories}
                            value={settings.categories}
                            onChange={(e, value) =>
                              onDropdownChange("categories", e.target.value)
                            }
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "12px",
                            flexWrap: "wrap",
                          }}
                        >
                          {isArrayWithValues(settings.categories)
                            ? settings.categories.map((i) => (
                                <CollectionCard
                                  isMobile={isMobile}
                                  label={i?.label}
                                  onRemove={(value) =>
                                    onRemoveDropdown("categories", value)
                                  }
                                />
                              ))
                            : null}
                        </div>
                        {/* <MyComponent /> */}
                      </CardContent>
                    </Card>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : null}
      </Box>
    );
  }
);

export default MasterSetting;
const PurityCard = ({ obj, isSelected, onClick, isMobile }) => {
  const padding = isMobile ? "9px" : "12px";
  return (
    <Card
      sx={{
        border: `0.5px solid`,
        borderColor: !isSelected ? "grey.500" : "priamry.main",
        margin: "6px 6px",
        overflow: "visible",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => onClick && onClick(obj)}
    >
      <CardContent
        sx={{
          textAlign: "center",
          padding,
          "&:last-child": { paddingBottom: padding },
        }}
      >
        {isSelected ? (
          <CheckCircle
            width={9}
            height={9}
            style={{
              position: "absolute",
              right: "-4px",
              top: "-4px",
              width: "16px",
              height: "16px",
            }}
          />
        ) : null}
        <Typography variant={isMobile ? "body2" : "h6"}>{obj.label}</Typography>
      </CardContent>
    </Card>
  );
};
const ColorCard = ({ obj, isSelected, onClick, isMobile }) => {
  const padding = isMobile ? "9px" : "12px";
  return (
    <Card
      sx={{
        border: `0.5px solid`,
        borderColor: !isSelected ? "grey.500" : "priamry.main",
        margin: "6px 6px",
        overflow: "visible",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => onClick(obj)}
    >
      <CardContent
        sx={{
          textAlign: "center",
          padding,
          "&:last-child": { paddingBottom: padding },
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "17px",
            height: "17px",
            borderRadius: "14px",
            background: colorsBackground[obj.value],
            // background: "linear-gradient(to right, #E1C16E,#edd595 )",
          }}
        />
        {isSelected ? (
          <CheckCircle
            style={{
              position: "absolute",
              right: "-4px",
              top: "-4px",
              width: "16px",
              height: "16px",
            }}
            width={9}
            height={9}
          />
        ) : null}
        <Typography variant={isMobile ? "body2" : "h6"} ml={1}>
          {obj.label}
        </Typography>
      </CardContent>
    </Card>
  );
};
const WeightCard = ({ obj, isSelected, onClick, isMobile }) => {
  const padding = isMobile ? "9px" : "12px";
  return (
    <Tooltip title={obj.info}>
      <div>
        <Card
          sx={{
            border: `0.5px solid`,
            borderColor: !isSelected ? "grey.500" : "priamry.main",
            margin: "6px 6px",
            overflow: "visible",
            position: "relative",
            cursor: "pointer",
          }}
          onClick={() => onClick(obj.value)}
        >
          <CardContent
            sx={{
              textAlign: "center",
              padding,
              "&:last-child": { paddingBottom: padding },
            }}
          >
            {isSelected ? (
              <CheckCircle
                style={{
                  position: "absolute",
                  right: "-4px",
                  top: "-4px",
                  width: "16px",
                  height: "16px",
                }}
                width={9}
                height={9}
              />
            ) : null}
            <Typography variant={isMobile ? "body2" : "h6"}>
              {obj.label}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </Tooltip>
  );
};
const CurrencyCard = ({ obj, isSelected, onClick, isMobile }) => {
  const padding = isMobile ? "9px" : "12px";
  return (
    <Card
      sx={{
        border: `0.5px solid`,
        borderColor: !isSelected ? "grey.500" : "priamry.main",
        margin: "6px 6px",
        overflow: "visible",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => onClick && onClick(obj)}
    >
      <CardContent
        sx={{
          textAlign: "center",
          padding,
          "&:last-child": { paddingBottom: padding },
        }}
      >
        {isSelected ? (
          <CheckCircle
            width={9}
            height={9}
            style={{
              position: "absolute",
              right: "-4px",
              top: "-4px",
              width: "16px",
              height: "16px",
            }}
          />
        ) : null}
        <Stack direction="row">
          <Typography mr={1} variant={isMobile ? "body2" : "h6"}>
            {obj.label}
          </Typography>
          <Typography variant="h6">({obj.symbol && obj.symbol})</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export const JewelleryTypeCard = (props) => {
  const { obj, isSelected, onClick, isMobile, labelProps = {} } = props;
  const padding = isMobile ? "9px" : "12px";
  return (
    <Card
      sx={{
        border: `1px solid `,
        borderColor: !isSelected ? "grey.500" : "priamry.main",
        cursor: "pointer",
        margin: "6px 6px",
        overflow: "visible",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => onClick(obj)}
    >
      <CardContent
        sx={{
          textAlign: "center",
          padding,
          "&:last-child": { paddingBottom: padding },
        }}
      >
        {isSelected ? (
          <CheckCircle
            style={{
              position: "absolute",
              right: "-4px",
              top: "-4px",
              width: "16px",
              height: "16px",
            }}
            width={9}
            height={9}
          />
        ) : null}
        <div style={{ display: "flex" }}>
          {jewelleryTypeIcon[obj.value] || obj.icon}
          <Typography
            variant={isMobile ? "body2" : "h6"}
            ml={2}
            {...labelProps}
            // color="GrayText"
          >
            {obj.label}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};
const GemstoneView = () => (
  <div
    fill="#79ad96"
    style={{
      width: "20px",
      height: "20px",
      borderRadius: "14px",
      background: "linear-gradient(to right, #79ad96,#91baa8 )",
    }}
  />
);
const BrassView = () => (
  <div
    style={{
      width: "20px",
      height: "20px",
      borderRadius: "14px",
      background: "linear-gradient(to right, #E1C16E,#edd595 )",
    }}
  />
);
export const allCurrency = [
  {
    label: "INR",
    value: "INR",
    symbol: "₹",
    name: "Indian Rupee",
    country: "India",
  },
  {
    label: "USD",
    value: "USD",
    symbol: "$",
    name: "United States Dollar",
    country: "United States",
  },
  {
    label: "EUR",
    value: "EUR",
    symbol: "€",
    name: "Euro",
    country: "European Union",
  },
  {
    label: "GBP",
    value: "GBP",
    symbol: "£",
    name: "British Pound Sterling",
    country: "United Kingdom",
  },
  {
    label: "AUD",
    value: "AUD",
    symbol: "$",
    name: "Australian Dollar",
    country: "Australia",
  },
  {
    label: "AED",
    value: "AED",
    symbol: "د.إ",
    name: "United Arab Emirates Dirham",
    country: "United Arab Emirates",
  },
  {
    label: "SGD",
    value: "SGD",
    symbol: "$",
    name: "Singapore Dollar",
    country: "Singapore",
  },
  {
    label: "SAR",
    value: "SAR",
    symbol: "SR",
    name: "Saudi Riyal",
    country: "Saudi Arabia",
  },
  {
    label: "ZAR",
    value: "ZAR",
    symbol: "R",
    name: "South African Rand",
    country: "South Africa",
  },
  {
    label: "CAD",
    value: "CAD",
    symbol: "$",
    name: "Canadian Dollar",
    country: "Canada",
  },
  {
    label: "JPY",
    value: "JPY",
    symbol: "¥",
    name: "Japanese Yen",
    country: "Japan",
  },
  {
    label: "CNY",
    value: "CNY",
    symbol: "¥",
    name: "Chinese Yuan",
    country: "China",
  },
  {
    label: "VND",
    value: "VND",
    symbol: "₫",
    name: "Vietnamese Dong",
    country: "Vietnam",
  },
  {
    label: "THB",
    value: "THB",
    symbol: "฿",
    name: "Thai Baht",
    country: "Thailand",
  },
  {
    label: "KWD",
    value: "KWD",
    symbol: "د.ك",
    name: "Kuwaiti Dinar",
    country: "Kuwait",
  },
];

export const jewelleryTypes = [
  { label: "Gold Jewellery", value: "gold", icon: <GoldView /> },
  { label: "Silver Jewellery", value: "silver", icon: <SilverView /> },
  { label: "Platinum Jewellery", value: "platinium", icon: <PlatinumView /> },
  { label: "Diamond Jewellery", value: "diamond", icon: <DiamondView /> },
  { label: "Gemstone Jewellery", value: "gemstone", icon: <PlatinumView /> },
  { label: "CZ Jewellery", value: "cz", icon: <CZView /> },
  {
    label: "Antique / Jadau Jewellery",
    value: "antique",
    icon: <AntiqueView />,
  },
  // { label: "Brass", value: "brass", icon: <PlatinumView /> },
];
export const metalTypes = [
  {
    label: "Gold",
    value: "gold",
    icon: <GoldView style={{ height: "16px", width: "16px" }} />,
  },
  {
    label: "Silver",
    value: "silver",
    icon: <SilverView style={{ height: "16px", width: "16px" }} />,
  },
  {
    label: "Platinum",
    value: "platinium",
    icon: <PlatinumView style={{ height: "16px", width: "16px" }} />,
  },
  // { label: "Brass", value: "brass", icon: <PlatinumView /> },
];
const jewelleryTypeIcon = {
  gold: <GoldView />,
  silver: <SilverView />,
  platinum: <PlatinumView />,
  diamond: <DiamondView />,
  gemstone: <GemstoneView />,
  brass: <BrassView />,
};
export const diamond_types = [
  { label: "Natural", value: "natural" },
  { label: "Lab Grown", value: "lab_grown" },
];

const diamondClarity = [
  { label: "IF", value: "IF" },
  { label: "VVS", value: "VVS" },
  { label: "VVS1", value: "VVS1" },
  { label: "VVS2", value: "VVS2" },
  { label: "VS", value: "VS" },
  { label: "VS1", value: "VS1" },
  { label: "VS2", value: "VS2" },
  { label: "SI", value: "SI" },
  { label: "SI1", value: "SI1" },
  { label: "SI2", value: "SI2" },
  { label: "SI3", value: "SI3" },
  { label: "I", value: "I" },
  { label: "I1", value: "I1" },
  { label: "2I", value: "I2" },

  { label: "IF/VVS", value: "IF/VVS" },
  { label: "VVS/VS", value: "VVS/VS" },
  { label: "VS/SI", value: "VS/SI" },
  { label: "SI/I", value: "SI/I" },
  { label: "SI/I1", value: "SI/I1" },
  { label: "DIAMOND", value: "DIAMOND" },
];
const diamondColors = [
  { label: "D", value: "D" },
  { label: "E", value: "E" },
  { label: "F", value: "F" },
  { label: "G", value: "G" },
  { label: "H", value: "H" },
  { label: "I", value: "I" },
  { label: "J", value: "J" },
  { label: "K", value: "K" },
  { label: "L", value: "L" },
  { label: "M", value: "M" },
  { label: "PINK", value: "PINK" },
  { label: "YELLOW", value: "YELLOW" },
  { label: "BLUE", value: "BLUE" },
];

// export const diamondShapes = [
//   { label: "Round", value: "round" },
//   { label: "Pear", value: "pear" },
//   { label: "Princess", value: "princess" },
//   { label: "Marquise", value: "marquise" },
//   { label: "Oval", value: "oval" },
//   // { label: "Radiant", value: "radiant" },
//   { label: "Emerald", value: "emerald" },
//   { label: "Heart", value: "heart" },
//   // { label: "Triangle", value: "triangle" },
//   // { label: "Cushion", value: "cushion" },
//   // { label: "Asscher", value: "asscher" },
//   { label: "Tapper Baguette", value: "tapper_baguette" },
// ];

export const diamondShapes = [
  { label: "Round", value: "round" },
  { label: "Princess", value: "princess" },
  { label: "Marquise", value: "marquise" },
  { label: "Pear", value: "pear" },
  { label: "Oval", value: "oval" },
  { label: "Taper Baguette", value: "tapered_baguette" },
  { label: "Bullet Baguette", value: "bullet_baguette" },
  //label: "Tapper Bugget", { value: "tapperbugget",  },
  { label: "Baguette", value: "baguette" },
  { label: "Heart", value: "heart" },
  { label: "Cushion", value: "cushion" },
  { label: "Radiant", value: "radiant" },
  { label: "Asscher", value: "asscher" },
  { label: "Trillion", value: "trillion" },
  { label: "Lozenge", value: "lozenge" },
  { label: "Emerald", value: "emerald" },
  { label: "Half Moon", value: "halfmoon" },
  { label: "Triangle", value: "triangle" },
  { label: "Fancy", value: "fancy" },
  { label: "Trapezoid", value: "trapezoid" },
];

export const diamondCut = [
  { label: "Excellent", value: "excellent" },
  { label: "Very Good", value: "very_good" },
  { label: "Good", value: "good" },
  { label: "Fair", value: "fair" },
  { label: "Poor", value: "poor" },
];

const diamondSizes = [
  { label: "-7 MQ", value: "-7 MQ" },
  { label: "-3 TB", value: "-3 TB" },
  { label: "+3-4 TB", value: "+3-4 TB" },
  { label: "+0-2", value: "+0-2" },
  { label: "+2-6.5", value: "+2-6.5" },
  { label: "+0-6", value: "+0-6" },
  { label: "+6.5-9.5", value: "+6.5-9.5" },
  { label: "+6.5-11", value: "+6.5-11" },
  { label: "+0-9.5", value: "+0-9.5" },
  { label: "+9.5-11", value: "+9.5-11" },
  { label: "+0-11", value: "+0-11" },
  { label: "1/10", value: "1/10" },
  { label: "-7 PRN", value: "-7 PRN" },
  { label: "-7 PE", value: "-7 PE" },
  { label: "+7 PRN", value: "+7 PRN" },
  { label: "+7 MQ", value: "+7 MQ" },
  { label: "-7 OV", value: "-7 OV" },
  { label: "1", value: "1" },
  { label: "1.25", value: "1.25" },
  { label: "4/5", value: "4/5" },
];
export const purities = [
  { label: "24KT (999.99)", value: "999.99" },
  { label: "24KT (999)", value: "999" },
  { label: "24KT (995)", value: "995" },
  { label: "22KT (916)", value: "916" },
  { label: "18KT (750)", value: "750" },
  { label: "14KT (583)", value: "583" },
  { label: "9KT (375)", value: "375" },
];
export const silverPurities = [
  { label: "650", value: "650" },
  { label: "750", value: "750" },
  { label: "850", value: "850" },
  { label: "Sterling (925)", value: "925" },
  { label: "Fine (995)", value: "995" },
  { label: "Fine (999)", value: "999" },
];

export const platinumPurities = [
  { label: "850", value: "850" },
  { label: "900", value: "900" },
  { label: "950", value: "950" },
];

const colors = [
  { label: "White", value: "white" },
  { label: "Yellow", value: "yellow" },
  { label: "Rose", value: "rose" },
  { label: "Green", value: "green" },
  { label: "Two Tone", value: "two-tone" },
];

const diamondLabs = [
  { label: "AGS", value: "AGS" },
  { label: "DGLA", value: "DGLA" },
  { label: "DGSL", value: "DGSL" },
  { label: "DHC", value: "DHC" },
  { label: "EGL", value: "EGL" },
  { label: "Forevermark", value: "Forevermark" },
  { label: "GIA", value: "GIA" },
  { label: "GII", value: "GII" },
  { label: "GSI", value: "GSI" },
  { label: "HRD", value: "HRD" },
  { label: "IDT", value: "IDT" },
  { label: "IIDGR", value: "IIDGR" },
  { label: "IGI", value: "IGI" },
  { label: "IIG", value: "IIG" },
  { label: "SGL", value: "SGL" },
  { label: "PTR", value: "PTR" },
];

// [
//   { label: "IGI", value: "IGI" },
//   { label: "SGL", value: "SGL" },
//   { label: "DGLA", value: "DGLA" },
//   { label: "IIG", value: "IIG" },
//   { label: "DHC", value: "DHC" },
//   { label: "GIA", value: "GIA" },
//   { label: "HRD", value: "HRD" },
//   { label: "DGSL", value: "DGSL" },
// ];
export const gemstoneQualities = [
  { label: "Precious", value: "precious" },
  { label: "Semi precious", value: "semi-precious" },
  { label: "Polki", value: "polki" },
  { label: "Kundan", value: "kundan" },
  { label: "Uncut", value: "uncut" },
  { label: "Hydro", value: "hydro" },
  { label: "Doublet", value: "doublet" },
  // Below values added based on JPearls Product Sheet
  { label: "925 Sterling Silver", value: "925-sterling-silver" },
  { label: "Fashion", value: "fashion" },
  { label: "Gold", value: "gold" },
  { label: "Pearl", value: "pearl" },
  { label: "Silver", value: "silver" },
  { label: "Southsea", value: "southsea" },
  { label: "Pearls", value: "pearls" }, // Optional, based on whether it represents a different category than "Pearl"
  { label: "Gemstone", value: "gemstone" },
];
export const gemstoneTypes = [
  { label: "Ruby", value: "ruby" },
  { label: "Emerald", value: "emerald" },
  { label: "Pearl", value: "pearl" },
  { label: "Sapphire", value: "sapphire" },
  { label: "Amethyst", value: "amethyst" },
  { label: "Beads", value: "beads" },
  { label: "Black beeds", value: "black-beeds" },
  { label: "Black diamond", value: "black-diamond" },
  { label: "Blue sapphire", value: "blue-sapphire" },
  { label: "Blue sapphire-beads", value: "blue-sapphire-beads" },
  { label: "Real coral", value: "real-coral" },
  { label: "Claw", value: "claw" },
  { label: "Cubic zirconia", value: "cubic-zirconia" },
  { label: "Cubic zircon", value: "cubic-zircon" },
  { label: "Real emerald", value: "real-emerald" },
  { label: "Garnet", value: "garnet" },
  { label: "Gempu ruby", value: "gempu-ruby" },
  { label: "Multicolor mix", value: "multicolor-mix" },
  {
    label: "Navratna set with cubic zirconia",
    value: "navratna-set-with-cubic-zirconia",
  },
  { label: "Navratna set with diamond", value: "navratna-set-with-diamond" },
  { label: "Ruby beads", value: "ruby-beads" },
  { label: "Ruby emerald", value: "ruby-emerald" },
  { label: "Real ruby", value: "real-ruby" },
  { label: "Southsea cream-pearl", value: "southsea-cream-pearl" },
  { label: "Spinnel ruby red", value: "spinnel-ruby-red" },
  { label: "Synthetic", value: "synthetic" },
  { label: "Synthetic beads", value: "synthetic-beads" },
  { label: "Turquoise", value: "turquoise" },
  { label: "Watch", value: "watch" },
  { label: "Wax", value: "wax" },
  { label: "Yellow topaz", value: "yellow-topaz" },
  { label: "Topaz", value: "topaz" },
  { label: "Colour Stones", value: "colour-stones" },
  { label: "Crystal", value: "crystal" },
  { label: "Mother of Pearl", value: "mother-of-pearl" },
  { label: "Multi Stones", value: "multi-stones" },
  { label: "Semi Precious", value: "semi-precious" },
  { label: "Freshwater Pearls", value: "freshwater-pearls" },
  { label: "UnCut Diamond", value: "uncut-diamond" },
  { label: "Victorian Balls", value: "victorian-balls" },
  { label: "South Sea Pearls", value: "south-sea-pearls" },
  { label: "South sea Pearls", value: "south-sea-pearls" },
  { label: "Jade", value: "jade" },
  { label: "SouthSea Pearls", value: "southsea_pearls" },
  { label: "Tahitian Pearl", value: "tahitian_pearl" },
  { label: "Wiring", value: "wiring" },
  { label: "Invisible", value: "invisible" },
  { label: "Fresh Water Pearls", value: "fresh_water_pearls" },
  { label: "Basara Pearls", value: "basara-pearls" },
  { label: "Beryl Emerald", value: "beryl-emerald" },
  { label: "Black Beads", value: "black-beads" },
  { label: "Black Diamonds", value: "black-diamonds" },
  { label: "Cats Eye", value: "cats-eye" },
  { label: "Color stones", value: "color-stones" },
  { label: "Coral", value: "coral" },
  { label: "CZ", value: "cz" },
  { label: "emeralds", value: "emeralds" },
  { label: "Fluorite", value: "fluorite" },
  { label: "Gomed", value: "gomed" },
  { label: "Hessonite Garnet", value: "hessonite-garnet" },
  { label: "Lapis", value: "lapis" },
  { label: "Moon Stone", value: "moon-stone" },
  { label: "Morganite", value: "morganite" },
  { label: "Navratan", value: "navratan" },
  { label: "Peridot", value: "peridot" },
  { label: "Precious Stone", value: "precious-stone" },
  { label: "R+E+P", value: "r+e+p" },
  { label: "Rubies", value: "rubies" },
  { label: "Shell Pearl", value: "shell-pearl" },
  { label: "Tahitian Pearls", value: "tahitian-pearls" },
  { label: "Tarmoline", value: "tarmoline" },
  { label: "Yellow Sapphire", value: "yellow-sapphire" },
  { label: "Ruby Lab Created", value: "ruby-lab-created" },
  { label: "Emerald Lab Created", value: "emerald-lab-created" },
  { label: "Blue Sapphire Lab Created", value: "blue-sapphire-lab-created" },
  { label: "Undrilled", value: "undrilled" },
];

export const gemstoneShapes = [
  { label: "Round", value: "round" },
  { label: "Square", value: "square" },
  { label: "Oval", value: "oval" },
  { label: "Emerald", value: "emerald" },
  { label: "Pear", value: "pear" },
  { label: "Marquise", value: "marquise" },
  // { label: "Marquee", value: "marquee" },
  // { label: "Tapper bugget", value: "tapper-bugget" },
  // { label: "Heart", value: "heart" },
  // { label: "Triangle", value: "triangle" },
  // { label: "Cushion", value: "cushion" },
  // { label: "Beads", value: "beads" },

  // Below values added based on JPearls Product Sheet
  { label: "Button", value: "button" },
  { label: "Potato", value: "potato" },
  { label: "Seed", value: "seed" },
  { label: "Drop", value: "drop" },
  { label: "Flat", value: "flat" },
  { label: "Triangle", value: "triangle" },
  { label: "Tumble", value: "tumble" },
  { label: "Uneven Shape", value: "uneven-shape" },
  { label: "Rice", value: "rice" },

  // { label: 'Button', value: 'button' },
  // { label: 'Oval', value: 'oval' },
  // { label: 'Pear', value: 'pear' },
  // { label: 'Potato', value: 'potato' },
  // { label: 'Rice', value: 'rice' },
  // { label: 'Round', value: 'round' },
  // { label: 'Square', value: 'square' },
  // { label: 'Triangle', value: 'triangle' },
  { label: "Keshi", value: "keshi" },
  { label: "Tumble/Uneven Shape", value: "tumble/uneven_shape" },
  { label: "Beads", value: "beads" },
  { label: "Drum", value: "drum" },
  { label: "Pumpkin Beads/Kharbuja", value: "pumpkin_beads/kharbuja" },
  // { label: 'Drop', value: 'drop' },
  // { label: 'Emerald', value: 'emerald' },
  // { label: 'Seed', value: 'seed' },
  // { label: 'Flat', value: 'flat' }
];
const colorsBackground = {
  white: "#EBEBEB",
  yellow: "#EAC97D",
  green: "#14903F",
  rose: "#FFCBB2",
  "two-tone": "linear-gradient(to right, #EBEBEB 40%, #FFCBB2) 50%",
};

const netWeights = [
  {
    label: "Auto",
    value: "auto",
    info: "System will convert Gems, Diamond wt from carat to gram and minus it from gross wt",
  },
  {
    label: "Manual",
    value: "manual",
    info: "You will enter the weight manually",
  },
];
export const diamondTypes = [
  { label: "Natural", value: "natural" },
  { label: "Lab Grown", value: "lab_grown" },
  // { label: "Moissanite", value: "moissanite" },
];

// import {
//   Alert,
//   Button,
//   Card,
//   CardContent,
//   Checkbox,
//   CircularProgress,
//   Collapse,
//   FormControl,
//   Grid,
//   IconButton,
//   InputLabel,
//   ListItemText,
//   MenuItem,
//   Select,
//   Slide,
//   Snackbar,
//   Stack,
//   Typography,
//   useMediaQuery,
// } from "@mui/material";
// import { Box, useTheme } from "@mui/system";
// import React, {
//   forwardRef,
//   useEffect,
//   useImperativeHandle,
//   useRef,
//   useState,
// } from "react";
// import { Helmet } from "react-helmet-async";
// import {
//   CheckCircle,
//   Cancel as CancelIcon,
//   Diamond,
//   Cached,
// } from "@mui/icons-material";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getAxiosError,
//   getToken,
//   getWebsite,
//   isArrayWithValues,
//   isObjWithValues,
// } from "../../helper";
// import {
//   fetchAdminTaxonomies,
//   setProductData,
//   SET_PRODUCT_SETTINGS,
// } from "../../redux/actions/productActions";
// import { LoadingButton } from "@mui/lab";
// import axios from "axios";
// import { Gemstone } from "../../Svgs";
// import { EDIT_SETTINGS } from "../../redux/actions/settingActions";

// const GoldView = () => (
//   <div
//     style={{
//       width: "20px",
//       height: "20px",
//       borderRadius: "14px",
//       background: "linear-gradient(to right, #FFD700,#F0E68C)",
//     }}
//   />
// );

// const SilverView = () => (
//   <div
//     style={{
//       width: "20px",
//       height: "20px",
//       borderRadius: "14px",
//       background: "linear-gradient(to right, #C0C0C0,#cfcfcf)",
//     }}
//   />
// );

// const PlatinumView = () => (
//   <div
//     style={{
//       width: "20px",
//       height: "20px",
//       borderRadius: "14px",
//       background: "linear-gradient(to right, #E5E4E2,#edeceb)",
//     }}
//   />
// );

// // const DiamondView = () => (
// //   <div
// //     style={{
// //       width: "20px",
// //       height: "20px",
// //       borderRadius: "14px",
// //       background: "linear-gradient(to right, #b9f2ff,#ccf6ff )",
// //     }}
// //   />
// // );
// const DiamondView = () => (
//   <Diamond
//     sx={{
//       width: "20px",
//       height: "20px",
//       // borderRadius: "14px",
//       color: "#b9f2ff",
//     }}
//   />
// );
// const GemstoneView = () => (
//   <Gemstone
//     fill="#79ad96"
//     style={{
//       width: "20px",
//       height: "20px",
//       // borderRadius: "14px",
//       // background: "linear-gradient(to right, #79ad96,#91baa8 )",
//     }}
//   />
// );
// const BrassView = () => (
//   <div
//     style={{
//       width: "20px",
//       height: "20px",
//       borderRadius: "14px",
//       background: "linear-gradient(to right, #E1C16E,#edd595 )",
//     }}
//   />
// );

// const MasterSettings = forwardRef(({ hideUpdateButton }, ref) => {
//   const [taxonomies, setTaxonomies] = useState({});
//   const [toast, setToast] = useState({ show: false });
//   const [settings, setSettings] = useState({
//     types: [],
//     gold_purities: [],
//     silver_purities: [],
//     platinum_purities: [],
//     colors: [],
//     net_weight: "",
//     collections: [],
//     categories: [],
//     diamond_types: [],
//     diamond_clarities: [],
//     diamond_cuts: [],
//     diamond_shapes: [],
//     diamond_colors: [],
//     diamond_sizes: [],
//     currency: [],
//     default_currency: "",
//     gemstone_qualities: [],
//     gemstone_shapes: [],
//     gemstone_types: [],
//     diamond_labs: [],
//   });
//   const [allCollections, setAllCollections] = useState([]);
//   const [allCategories, setAllCategories] = useState([]);
//   const [updating, setUpdating] = useState(false);
//   const [fetchingSettings, setFetchingSettings] = useState(false);
//   const reduxTaxonomies = useSelector(
//     (state) => state.product.admin_taxonomies
//   );
//   const reduxProductSettings = useSelector(
//     (state) => state.settings.productSettings
//   );
//   const user = useSelector((state) => state.user);
//   const [reduxSettings, setReduxSettings] = useState({});
//   const topRef = useRef(null);
//   const dispatch = useDispatch();
//   const theme = useTheme();

//   useImperativeHandle(ref, () => ({
//     submitSettings(website, token, callback) {
//       onUpdate(website, token, callback);
//     },
//   }));

//   useEffect(() => {
//     let isMounted = true;
//     dispatch(fetchAdminTaxonomies());

//     if (!isObjWithValues(reduxProductSettings)) getProductSettings(isMounted);
//     else setSettingsFromRedux({ ...reduxProductSettings });

//     setTimeout(() => scrollToTop(), 100);
//     return () => (isMounted = false);
//   }, [user]);
//   useEffect(() => {
//     if (isObjWithValues(reduxProductSettings))
//       setReduxSettings({ ...reduxProductSettings });
//     // setSettingsFromRedux({ ...reduxProductSettings });
//     else getProductSettings();
//   }, [reduxProductSettings]);
//   useEffect(() => {
//     if (isObjWithValues(reduxSettings)) setReduxSettings(reduxSettings);
//   }, [reduxSettings]);
//   useEffect(() => {
//     if (reduxTaxonomies && Object.keys(reduxTaxonomies).length > 0)
//       setTaxonomies(reduxTaxonomies);
//   }, [reduxTaxonomies]);
//   useEffect(() => {
//     if (taxonomies && Object.keys(taxonomies).length > 0) {
//       let { collections, categories } = taxonomies;
//       if (Array.isArray(collections) && collections.length > 0)
//         setAllCollections(collections);
//       if (Array.isArray(categories) && categories.length > 0)
//         setAllCategories(categories);
//     }
//     //eslint-disable-next-line
//   }, [taxonomies]);

//   const getProductSettings = async (isMounted) => {
//     const website = getWebsite();
//     const token = getToken();
//     if (website && token) {
//       setFetchingSettings(true);
//       const controller = new AbortController();
//       const signal = controller.signal;
//       let res = await axios({
//         url: `${website}/wp-json/store/v1/settings/business_settings`,
//         headers: {
//           Authorization: `Basic ${token}`,
//           // signal,
//         },
//       });
//       if (true) {
//         setFetchingSettings(false);
//         let { data } = res;
//         if (data.success && isObjWithValues(data.data)) {
//           setSettingsFromRedux({ ...data.data });
//           updateSettingsInRedux({ ...data.data });
//         }
//       }
//     }
//   };

//   const setSettingsFromRedux = (settings) => {
//     let updatedSettings = { ...settings };
//     let { collections, categories, currency, default_currency } =
//       updatedSettings;

//     if (isArrayWithValues(collections)) {
//       let arr = [];
//       for (let i = 0; i < collections.length; i++)
//         arr.push(collections[i].label);
//       updatedSettings.collections = arr;
//     }
//     if (isArrayWithValues(categories)) {
//       let arr = [];
//       for (let i = 0; i < categories.length; i++) arr.push(categories[i].label);
//       updatedSettings.categories = arr;
//     }
//     if (!isArrayWithValues(currency)) {
//       updatedSettings.currency = [
//         { ...allCurrency.find((i) => i.value === "INR") },
//       ];
//     }
//     if (!default_currency) updatedSettings.default_currency = "INR";
//     setSettings(updatedSettings);
//   };

//   // const updateSettingsInRedux = (settings) => {
//   //   dispatch({ type: SET_PRODUCT_SETTINGS, payload: { ...settings } });
//   // };
//   const updateSettingsInRedux = (settings) => {
//     dispatch({
//       type: EDIT_SETTINGS,
//       payload: { productSettings: { ...settings } },
//     });
//   };

//   const onDropdownChange = (label, value) => {
//     setSettings({ ...settings, [label]: value });
//   };
//   const onSettingsToggle = (label, value) => {
//     let data = settings[label];
//     if (data === value) data = "";
//     else data = value;
//     setSettings({ ...settings, [label]: value });
//   };

//   const onSettingsChange = (label, obj) => {
//     let data = settings[label];
//     if (obj.icon) delete obj.icon;
//     if (isArrayWithValues(data))
//       if (data.find((i) => i.value === obj.value))
//         data = data.filter((i) => i.value !== obj.value);
//       else data.push(obj);
//     else data = [obj];
//     // let data = [obj];
//     // console.log(settings.default_currency, data);
//     // setSettings((state) => ({
//     //   ...state,
//     //   currency: data,
//     //   default_currency: data[0].value,
//     // }));
//     onDropdownChange(label, data);
//   };
//   const onCurrencyChange = (label, obj) => {
//     // let data = settings[label];
//     if (obj.icon) delete obj.icon;
//     // if (isArrayWithValues(data))
//     //   if (data.find((i) => i.value === obj.value))
//     //     data = data.filter((i) => i.value !== obj.value);
//     //   else data.push(obj);
//     // else data = [obj];
//     let data = [obj];
//     setSettings((state) => ({
//       ...state,
//       currency: data,
//       default_currency: data[0].value,
//     }));
//     // onDropdownChange(label, data);
//   };

//   const onRemoveDropdown = (label, value) => {
//     let data = settings[label];
//     if (isArrayWithValues(data)) data = data.filter((i) => i !== value);
//     onDropdownChange(label, data);
//   };

//   const onUpdate = (website, token, callback) => {
//     try {
//       setUpdating(true);
//       let updatedSettings = { ...settings };
//       if (isArrayWithValues(updatedSettings.collections)) {
//         let arr = [];
//         let { collections } = updatedSettings;
//         for (let i = 0; i < collections.length; i++) {
//           let collection = allCollections.find(
//             (obj) => obj.label === collections[i]
//           );
//           if (collection) arr.push(collection);
//         }
//         updatedSettings.collections = arr;
//       }
//       if (isArrayWithValues(updatedSettings.categories)) {
//         let arr = [];
//         let { categories } = updatedSettings;

//         for (let i = 0; i < categories.length; i++) {
//           let category = allCategories.find(
//             (obj) => obj.label === categories[i]
//           );
//           if (category) arr.push(category);
//         }
//         updatedSettings.categories = arr;
//       }

//       let { diamond_clarities } = updatedSettings;
//       if (isArrayWithValues(diamond_clarities)) {
//         let array = [];
//         diamondClarity.map((i) => {
//           let obj = diamond_clarities.find((obj) => obj.value === i.value);
//           if (obj) array.push(obj);
//         });
//         updatedSettings.diamond_clarities = array;
//       }
//       // return;

//       if (!website || typeof website !== "string") website = getWebsite();
//       if (!token || typeof token !== "string") token = getToken();
//       if (website && token)
//         axios({
//           url: `${website}/wp-json/store/v1/settings/business_settings`,
//           headers: {
//             Authorization: `Basic ${token}`,
//           },
//           method: "POST",
//           data: {
//             type: "add",
//             data: { ...updatedSettings },
//           },
//         })
//           .then((res) => {
//             if (res.data && isObjWithValues(res.data.data))
//               updateSettingsInRedux({ ...res.data.data });
//             setToast({
//               show: true,
//               severity: "success",
//               message: "Settings has been updated",
//             });
//           })
//           .catch((e) => {
//             console.log(getAxiosError(e));

//             setToast({
//               show: true,
//               severity: "error",
//               message: "Failed to update settings, Please try again!",
//             });
//           })
//           .finally(() => {
//             setUpdating(false);
//             callback && callback();
//           });
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const scrollToTop = () => {
//     const domNode = topRef.current;
//     if (domNode) {
//       domNode.scrollTop = 0;
//     }
//   };
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   // console.log(settings.currency);
//   return (
//     <div style={{}} ref={topRef}>
//       <Snackbar
//         open={toast.show}
//         autoHideDuration={3000}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//         TransitionComponent={Slide}
//         style={{ marginRight: "2vw" }}
//         onClose={() => setToast({ ...toast, show: false })}
//       >
//         <Alert severity={toast.severity} sx={{ width: "100%" }}>
//           {toast.message}
//         </Alert>
//       </Snackbar>
//       <Helmet title="Products" />
//       <Stack
//         direction="row"
//         justifyContent="space-between"
//         alignItems={"center"}
//       >
//         <Box />
//         {/* <Typography
//           variant="h3"
//           gutterBottom
//           display="inline"
//           sx={{ marginBottom: "20px" }}
//         >
//           Settings
//         </Typography> */}
//         <div style={{ alignItems: "center", display: "flex" }}>
//           <div>
//             {fetchingSettings ? (
//               <>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <CircularProgress size="13px" />
//                   <Typography variant="body1" ml={2} mr={2}>
//                     Fetching settings
//                   </Typography>
//                 </div>
//               </>
//             ) : (
//               <Button onClick={getProductSettings} startIcon={<Cached />}>
//                 Refresh
//               </Button>
//             )}
//           </div>
//           {!hideUpdateButton && (
//             <div>
//               <LoadingButton
//                 loading={updating}
//                 disabled={fetchingSettings}
//                 variant="contained"
//                 onClick={onUpdate}
//               >
//                 Update
//               </LoadingButton>
//             </div>
//           )}
//         </div>
//       </Stack>
//       <div style={{ height: "10px" }} />
//       <Card sx={{ marginTop: "10px" }}>
//         <CardContent>
//           <Stack
//             direction="row"
//             alignItems={"center"}
//             justifyContent={"space-between"}
//           >
//             <Typography variant="h5">Currency</Typography>
//             <FormControl size="small" sx={{ minWidth: "100px" }}>
//               <InputLabel id="demo-simple-select-label">default</InputLabel>
//               <Select
//                 labelId="demo-simple-select-label"
//                 id="demo-simple-select"
//                 value={settings.default_currency || []}
//                 label="default"
//                 unselectable
//                 onChange={(e) =>
//                   setSettings((state) => ({
//                     ...state,
//                     default_currency: e.target.value,
//                   }))
//                 }
//               >
//                 {isArrayWithValues(settings.currency) &&
//                   settings.currency.map((i) => (
//                     <MenuItem
//                       value={i.value}
//                     >{`${i.label} (${i.symbol})`}</MenuItem>
//                   ))}
//                 {/* <MenuItem value={10}>Ten</MenuItem>
//                 <MenuItem value={30}>Thirty</MenuItem> */}
//               </Select>
//             </FormControl>
//           </Stack>
//           <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//             {allCurrency.map((i) => (
//               <CurrencyCard
//                 obj={i}
//                 icon={i.symbol}
//                 isSelected={
//                   settings.currency &&
//                   settings.currency.find((obj) => obj.value === i.value)
//                 }
//                 onClick={(value) => {
//                   onCurrencyChange("currency", value);
//                 }}
//                 isMobile={isMobile}
//               />
//             ))}
//             {/* <JewelleryTypeCard label="Silver" icon={<SilverView />} />
//           <JewelleryTypeCard label="Platinum" icon={<PlatinumView />} /> */}
//           </div>
//         </CardContent>
//       </Card>
//       <GridComponent label="Jewellery type">
//         <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//           {jewelleryTypes.map((i) => (
//             <JewelleryTypeCard
//               obj={i}
//               icon={i.icon}
//               isSelected={
//                 isArrayWithValues(settings.types) &&
//                 settings.types.find((obj) => obj.value === i.value)
//               }
//               onClick={(value) => onSettingsChange("types", value)}
//               isMobile={isMobile}
//             />
//           ))}
//           {/* <JewelleryTypeCard label="Silver" icon={<SilverView />} />
//           <JewelleryTypeCard label="Platinum" icon={<PlatinumView />} /> */}
//         </div>
//       </GridComponent>

//       {/* {settings.types&&settings.types.find(
//         (i) =>
//           i.value === "gold" || i.value === "silver" || i.value === "platinium"
//       ) && <></>} */}
//       <Collapse
//         in={
//           isArrayWithValues(settings.types) &&
//           settings.types.find(
//             (i) =>
//               i.value === "gold" ||
//               i.value === "silver" ||
//               i.value === "platinium"
//           )
//         }
//       >
//         <GridComponent label="Purity">
//           {isArrayWithValues(settings.types) &&
//           settings.types.find((i) => i.value === "gold") ? (
//             <>
//               <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
//                 Gold
//               </Typography>
//               <div
//                 style={{
//                   display: "flex",
//                   marginTop: "4px",
//                   flexWrap: "wrap",
//                 }}
//               >
//                 {purities.map((i) => (
//                   <PurityCard
//                     obj={i}
//                     isSelected={settings.gold_purities.find(
//                       (obj) => obj.value === i.value
//                     )}
//                     onClick={(value) =>
//                       onSettingsChange("gold_purities", value)
//                     }
//                     isMobile={isMobile}
//                   />
//                 ))}
//               </div>
//             </>
//           ) : null}
//           {isArrayWithValues(settings.types) &&
//           settings.types.find((i) => i.value === "silver") ? (
//             <>
//               <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
//                 Silver
//               </Typography>
//               <div
//                 style={{
//                   display: "flex",
//                   marginTop: "4px",
//                   flexWrap: "wrap",
//                 }}
//               >
//                 {silverPurities.map((i) => (
//                   <PurityCard
//                     obj={i}
//                     isSelected={settings.silver_purities.find(
//                       (obj) => obj.value === i.value
//                     )}
//                     onClick={(value) =>
//                       onSettingsChange("silver_purities", value)
//                     }
//                     isMobile={isMobile}
//                   />
//                 ))}
//               </div>
//             </>
//           ) : null}
//           {isArrayWithValues(settings.types) &&
//           settings.types.find((i) => i.value === "platinium") ? (
//             <>
//               <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
//                 Platinum
//               </Typography>
//               <div
//                 style={{
//                   display: "flex",
//                   marginTop: "4px",
//                   flexWrap: "wrap",
//                 }}
//               >
//                 {platinumPurities.map((i) => (
//                   <PurityCard
//                     obj={i}
//                     isSelected={settings.platinum_purities.find(
//                       (obj) => obj.value === i.value
//                     )}
//                     onClick={(value) =>
//                       onSettingsChange("platinum_purities", value)
//                     }
//                     isMobile={isMobile}
//                   />
//                 ))}
//               </div>
//             </>
//           ) : null}
//         </GridComponent>
//         <GridComponent label="Colors">
//           <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//             {colors.map((i) => (
//               <ColorCard
//                 obj={i}
//                 isSelected={
//                   settings.colors &&
//                   settings.colors.find((obj) => obj.value === i.value)
//                 }
//                 onClick={(value) => onSettingsChange("colors", value)}
//                 isMobile={isMobile}
//               />
//             ))}
//             {/* <ColorCard label="Yellow" />
//           <ColorCard label="White" />
//           <ColorCard label="Two Tone" isSelected={true} />
//           <ColorCard label="Rose" /> */}
//           </div>
//         </GridComponent>
//         <GridComponent label="Net weight">
//           <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//             {netWeights.map((i) => (
//               <WeightCard
//                 obj={i}
//                 isSelected={settings.net_weight === i.value}
//                 onClick={(value) => onSettingsToggle("net_weight", value)}
//                 isMobile={isMobile}
//               />
//             ))}
//           </div>
//         </GridComponent>
//       </Collapse>
//       <Collapse
//         in={
//           isArrayWithValues(settings.types) &&
//           settings.types.find((i) => i.value === "diamond")
//         }
//       >
//         <GridComponent label="Diamond">
//           <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
//             Diamond Types
//           </Typography>
//           <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//             {diamondTypes.map((i) => (
//               <PurityCard
//                 obj={i}
//                 // icon={i.icon}
//                 isSelected={
//                   settings.diamond_types &&
//                   settings.diamond_types.find((obj) => obj.value === i.value)
//                 }
//                 onClick={(value) => onSettingsChange("diamond_types", value)}
//                 isMobile={isMobile}
//               />
//             ))}
//           </div>
//           <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
//             Diamond Clarity
//           </Typography>
//           <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//             {diamondClarity.map((i) => (
//               <PurityCard
//                 obj={i}
//                 // icon={i.icon}
//                 isSelected={
//                   settings.diamond_clarities &&
//                   settings.diamond_clarities.find(
//                     (obj) => obj.value === i.value
//                   )
//                 }
//                 onClick={(value) =>
//                   onSettingsChange("diamond_clarities", value)
//                 }
//                 isMobile={isMobile}
//               />
//             ))}
//           </div>
//           <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
//             Diamond Colors
//           </Typography>
//           <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//             {diamondColors.map((i) => (
//               <PurityCard
//                 obj={i}
//                 // icon={i.icon}
//                 isSelected={
//                   settings.diamond_colors &&
//                   settings.diamond_colors.find((obj) => obj.value === i.value)
//                 }
//                 onClick={(value) => onSettingsChange("diamond_colors", value)}
//                 isMobile={isMobile}
//               />
//             ))}
//           </div>
//           <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
//             Diamond Shapes
//           </Typography>
//           <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//             {diamondShapes.map((i) => (
//               <PurityCard
//                 obj={i}
//                 // icon={i.icon}
//                 isSelected={
//                   settings.diamond_shapes &&
//                   settings.diamond_shapes.find((obj) => obj.value === i.value)
//                 }
//                 onClick={(value) => onSettingsChange("diamond_shapes", value)}
//                 isMobile={isMobile}
//               />
//             ))}
//           </div>
//           <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
//             Diamond Cut
//           </Typography>
//           <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//             {diamondCut.map((i) => (
//               <PurityCard
//                 obj={i}
//                 // icon={i.icon}
//                 isSelected={
//                   settings.diamond_cuts &&
//                   settings.diamond_cuts.find((obj) => obj.value === i.value)
//                 }
//                 onClick={(value) => onSettingsChange("diamond_cuts", value)}
//                 isMobile={isMobile}
//               />
//             ))}
//           </div>
//           <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
//             Diamond Labs
//           </Typography>
//           <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//             {diamondLabs.map((i) => (
//               <PurityCard
//                 obj={i}
//                 // icon={i.icon}
//                 isSelected={
//                   settings.diamond_labs &&
//                   settings.diamond_labs.find((obj) => obj.value === i.value)
//                 }
//                 onClick={(value) => onSettingsChange("diamond_labs", value)}
//                 isMobile={isMobile}
//               />
//             ))}
//           </div>
//           {/* <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
//           Diamond Sizes
//         </Typography>
//         <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//           {diamondSizes.map((i) => (
//             <PurityCard
//               obj={i}
//               // icon={i.icon}
//               isSelected={
//                 settings.diamond_sizes &&
//                 settings.diamond_sizes.find((obj) => obj.value === i.value)
//               }
//               onClick={(value) => onSettingsChange("diamond_sizes", value)}
//               isMobile={isMobile}
//             />
//           ))}
//         </div> */}
//         </GridComponent>
//       </Collapse>
//       <Collapse
//         in={
//           isArrayWithValues(settings.types) &&
//           settings.types.find((i) => i.value === "gemstone")
//         }
//       >
//         <GridComponent label="Gemstone">
//           <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
//             Gemstone Qualities
//           </Typography>
//           <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//             {gemstoneQualities.map((i) => (
//               <PurityCard
//                 obj={i}
//                 // icon={i.icon}
//                 isSelected={
//                   settings.gemstone_qualities &&
//                   settings.gemstone_qualities.find(
//                     (obj) => obj.value === i.value
//                   )
//                 }
//                 onClick={(value) =>
//                   onSettingsChange("gemstone_qualities", value)
//                 }
//                 isMobile={isMobile}
//               />
//             ))}
//           </div>
//           <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
//             Gemstone Qualities
//           </Typography>
//           <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//             {gemstoneTypes.map((i) => (
//               <PurityCard
//                 obj={i}
//                 // icon={i.icon}
//                 isSelected={
//                   settings.gemstone_types &&
//                   settings.gemstone_types.find((obj) => obj.value === i.value)
//                 }
//                 onClick={(value) => onSettingsChange("gemstone_types", value)}
//                 isMobile={isMobile}
//               />
//             ))}
//           </div>
//           <Typography variant="body1" fontWeight="bold" mt={3} ml={2}>
//             Gemstone Qualities
//           </Typography>
//           <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//             {gemstoneShapes.map((i) => (
//               <PurityCard
//                 obj={i}
//                 // icon={i.icon}
//                 isSelected={
//                   settings.gemstone_shapes &&
//                   settings.gemstone_shapes.find((obj) => obj.value === i.value)
//                 }
//                 onClick={(value) => onSettingsChange("gemstone_shapes", value)}
//                 isMobile={isMobile}
//               />
//             ))}
//           </div>
//         </GridComponent>
//       </Collapse>
//       <Card sx={{ marginTop: "10px" }}>
//         <CardContent>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <Typography variant="h5" gutterBottom display="inline">
//               Collections
//             </Typography>
//             <Dropdown
//               data={allCollections}
//               value={settings.collections}
//               onChange={(e, value) =>
//                 onDropdownChange("collections", e.target.value)
//               }
//               label="Select collections"
//             />
//           </div>
//           <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//             {isArrayWithValues(settings.collections)
//               ? settings.collections.map((i) => (
//                   <CollectionCard
//                     label={i}
//                     isMobile={isMobile}
//                     onRemove={(value) => onRemoveDropdown("collections", value)}
//                   />
//                 ))
//               : null}
//             {/* <CollectionCard label="Antique" />
//             <CollectionCard label="Gemstone" /> */}
//           </div>
//         </CardContent>
//       </Card>
//       <Card sx={{ marginTop: "10px" }}>
//         <CardContent>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <Typography variant="h5" gutterBottom display="inline">
//               Categories
//             </Typography>
//             <Dropdown
//               label="Select Categories"
//               data={allCategories}
//               value={settings.categories}
//               onChange={(e, value) =>
//                 onDropdownChange("categories", e.target.value)
//               }
//             />
//           </div>
//           <div style={{ display: "flex", marginTop: "12px", flexWrap: "wrap" }}>
//             {isArrayWithValues(settings.categories)
//               ? settings.categories.map((i) => (
//                   <CollectionCard
//                     isMobile={isMobile}
//                     label={i}
//                     onRemove={(value) => onRemoveDropdown("categories", value)}
//                   />
//                 ))
//               : null}
//           </div>
//         </CardContent>
//       </Card>
//     </div>
//   );
// });

// export default MasterSettings;

// const jewelleryTypes = [
//   { label: "Gold", value: "gold", icon: <GoldView /> },
//   { label: "Silver", value: "silver", icon: <SilverView /> },
//   { label: "Platinum", value: "platinium", icon: <PlatinumView /> },
//   { label: "Diamond", value: "diamond", icon: <DiamondView /> },
//   { label: "Gemstone", value: "gemstone", icon: <PlatinumView /> },
//   { label: "Brass", value: "brass", icon: <PlatinumView /> },
// ];
// const diamondClarity = [
//   { label: "IF", value: "IF" },
//   { label: "VVS", value: "VVS" },
//   { label: "VVS1", value: "VVS1" },
//   { label: "VVS2", value: "VVS2" },
//   { label: "VS", value: "VS" },
//   { label: "VS1", value: "VS1" },
//   { label: "VS2", value: "VS2" },
//   { label: "SI", value: "SI" },
//   { label: "SI1", value: "SI1" },
//   { label: "SI2", value: "SI2" },
//   { label: "SI3", value: "SI3" },
//   { label: "I", value: "I" },
//   { label: "I1", value: "I1" },
//   { label: "2I", value: "I2" },
// ];
// const diamondColors = [
//   { label: "D", value: "D" },
//   { label: "E", value: "E" },
//   { label: "F", value: "F" },
//   { label: "G", value: "G" },
//   { label: "H", value: "H" },
//   { label: "I", value: "I" },
//   { label: "J", value: "J" },
//   { label: "K", value: "K" },
//   { label: "L", value: "L" },
//   { label: "M", value: "M" },
// ];

// export const diamondShapes = [
//   { label: "Round", value: "round" },
//   { label: "Pear", value: "pear" },
//   { label: "Princess", value: "princess" },
//   { label: "Marquise", value: "marquise" },
//   { label: "Oval", value: "oval" },
//   // { label: "Radiant", value: "radiant" },
//   { label: "Emerald", value: "emerald" },
//   { label: "Heart", value: "heart" },
//   // { label: "Cushion", value: "cushion" },
//   // { label: "Asscher", value: "asscher" },
//   { label: "Tapper Baguette", value: "tapper_baguette" },
// ];

// export const diamondCut = [
//   { label: "Excellent", value: "excellent" },
//   { label: "Very Good", value: "very_good" },
//   { label: "Good", value: "good" },
//   { label: "Fair", value: "fair" },
//   { label: "Poor", value: "poor" },
// ];

// export const diamondTypes = [
//   { label: "Natural", value: "natural" },
//   { label: "Lab Grown", value: "lab_grown" },
//   // { label: "Moissanite", value: "moissanite" },
// ];

// const diamondSizes = [
//   { label: "-7 MQ", value: "-7 MQ" },
//   { label: "-3 TB", value: "-3 TB" },
//   { label: "+3-4 TB", value: "+3-4 TB" },
//   { label: "+0-2", value: "+0-2" },
//   { label: "+2-6.5", value: "+2-6.5" },
//   { label: "+0-6", value: "+0-6" },
//   { label: "+6.5-9.5", value: "+6.5-9.5" },
//   { label: "+6.5-11", value: "+6.5-11" },
//   { label: "+0-9.5", value: "+0-9.5" },
//   { label: "+9.5-11", value: "+9.5-11" },
//   { label: "+0-11", value: "+0-11" },
//   { label: "1/10", value: "1/10" },
//   { label: "-7 PRN", value: "-7 PRN" },
//   { label: "-7 PE", value: "-7 PE" },
//   { label: "+7 PRN", value: "+7 PRN" },
//   { label: "+7 MQ", value: "+7 MQ" },
//   { label: "-7 OV", value: "-7 OV" },
//   { label: "1", value: "1" },
//   { label: "1.25", value: "1.25" },
//   { label: "4/5", value: "4/5" },
// ];

// const jewelleryTypeIcon = {
//   gold: <GoldView />,
//   silver: <SilverView />,
//   platinum: <PlatinumView />,
//   diamond: <DiamondView />,
//   gemstone: <GemstoneView />,
//   brass: <BrassView />,
// };
// const purities = [
//   { label: "24KT (999.99)", value: "999.99", icon: <PlatinumView /> },
//   { label: "24KT (999)", value: "999", icon: <GoldView /> },
//   { label: "24KT (995)", value: "995", icon: <SilverView /> },
//   { label: "22KT (916)", value: "916", icon: <PlatinumView /> },
//   { label: "18KT (750)", value: "750", icon: <PlatinumView /> },
//   { label: "14KT (583)", value: "583", icon: <PlatinumView /> },
//   { label: "9KT (375)", value: "375", icon: <PlatinumView /> },
// ];
// const silverPurities = [
//   { label: "650", value: "650" },
//   { label: "Sterling (925)", value: "925" },
//   { label: "Fine (995)", value: "995" },
//   { label: "Fine (999)", value: "999" },
// ];
// const platinumPurities = [
//   { label: "850", value: "850" },
//   { label: "900", value: "900" },
//   { label: "950", value: "950" },
// ];
// const colors = [
//   { label: "White", value: "white" },
//   { label: "Yellow", value: "yellow" },
//   { label: "Rose", value: "rose" },
//   { label: "Green", value: "green" },
//   { label: "Two Tone", value: "two-tone" },
// ];

// const diamondLabs = [
//   { label: "IGI", value: "IGI" },
//   { label: "SGL", value: "SGL" },
//   { label: "DGLA", value: "DGLA" },
//   { label: "IIG", value: "IIG" },
//   { label: "DHC", value: "DHC" },
//   { label: "GIA", value: "GIA" },
//   { label: "HRD", value: "HRD" },
// ];
// export const gemstoneQualities = [
//   { label: "Precious", value: "precious" },
//   { label: "Semi precious", value: "semi-precious" },
//   { label: "Polki", value: "polki" },
//   { label: "Kundan", value: "kundan" },
//   { label: "Uncut", value: "uncut" },
//   { label: "Hydro", value: "hydro" },
//   { label: "Doublet", value: "doublet" },
// ];
// export const gemstoneTypes = [
//   { label: "Ruby", value: "ruby" },
//   { label: "Emerald", value: "emerald" },
//   { label: "Pearl", value: "pearl" },
//   { label: "Sapphire", value: "sapphire" },
//   { label: "Amethyst", value: "amethyst" },
//   { label: "Beads", value: "beads" },
//   { label: "Black beeds", value: "black-beeds" },
//   { label: "Black diamond", value: "black-diamond" },
//   { label: "Blue sapphire", value: "blue-sapphire" },
//   { label: "Blue sapphire-beads", value: "blue-sapphire-beads" },
//   { label: "Real coral", value: "real-coral" },
//   { label: "Claw", value: "claw" },
//   { label: "Cubic zirconia", value: "cubic-zirconia" },
//   { label: "Real emerald", value: "real-emerald" },
//   { label: "Garnet", value: "garnet" },
//   { label: "Gempu ruby", value: "gempu-ruby" },
//   { label: "Multicolor mix", value: "multicolor-mix" },
//   {
//     label: "Navratna set with cubic zirconia",
//     value: "navratna-set-with-cubic-zirconia",
//   },
//   {
//     label: "Navratna set with diamond",
//     value: "navratna-set-with-diamond",
//   },
//   { label: "Ruby beads", value: "ruby-beads" },
//   { label: "Ruby emerald", value: "ruby-emerald" },
//   { label: "Real ruby", value: "real-ruby" },
//   { label: "Southsea cream-pearl", value: "southsea-cream-pearl" },
//   { label: "Spinnel ruby red", value: "spinnel-ruby-red" },
//   { label: "Synthetic", value: "synthetic" },
//   { label: "Synthetic beads", value: "synthetic-beads" },
//   { label: "Turquoise", value: "turquoise" },
//   { label: "Watch", value: "watch" },
//   { label: "Wax", value: "wax" },
//   { label: "Yellow topaz", value: "yellow-topaz" },
//   { label: "Topaz", value: "topaz" },
// ];

// export const gemstoneShapes = [
//   { label: "Round", value: "round" },
//   { label: "Square", value: "square" },
//   { label: "Oval", value: "oval" },
//   { label: "Emerald", value: "emerald" },
//   { label: "Pear", value: "pear" },
//   { label: "Marquee", value: "marquee" },
//   // { label: "Tapper bugget", value: "tapper-bugget" },
//   // { label: "Heart", value: "heart" },
//   // { label: "Triangle", value: "triangle" },
//   // { label: "Cushion", value: "cushion" },
//   // { label: "Beads", value: "beads" },
// ];

// const allCurrency = [
//   { label: "INR", value: "INR", symbol: "₹", name: "Indian Rupee" },
//   {
//     label: "USD",
//     value: "USD",
//     symbol: "$",
//     name: "United States Dollar",
//   },
//   { label: "EUR", value: "EUR", symbol: "€", name: "Euro" },
//   {
//     label: "GBP",
//     value: "GBP",
//     symbol: "£",
//     name: "British Pound Sterling",
//   },
//   {
//     label: "AUD",
//     value: "AUD",
//     symbol: "$",
//     name: "Australian Dollar",
//   },
//   {
//     label: "AED",
//     value: "AED",
//     symbol: "د.إ",
//     name: "United Arab Emirates Dirham",
//   },
//   { label: "SGD", value: "SGD", symbol: "$", name: "Singapore Dollar" },
//   { label: "SAR", value: "SAR", symbol: "SR", name: "Saudi Riyal" },
//   {
//     label: "ZAR",
//     value: "ZAR",
//     symbol: "R",
//     name: "South African Rand",
//   },
//   { label: "CAD", value: "CAD", symbol: "$", name: "Canadian Dollar" },
//   { label: "JPY", value: "JPY", symbol: "¥", name: "Japanese Yen" },
//   { label: "CNY", value: "CNY", symbol: "¥", name: "Chinese Yuan" },
//   { label: "VND", value: "VND", symbol: "₫", name: "Vietnamese Dong" },
//   { label: "THB", value: "THB", symbol: "฿", name: "Thai Baht" },
// ];

// const colorsBackground = {
//   white: "#EBEBEB",
//   yellow: "#EAC97D",
//   green: "#14903F",
//   rose: "#FFCBB2",
//   "two-tone": "linear-gradient(to right, #EBEBEB 40%, #FFCBB2) 50%",
// };

// const netWeights = [
//   { label: "Auto", value: "auto" },
//   { label: "Manual", value: "manual" },
// ];

// const GridComponent = (props) => {
//   const { label, children } = props;
//   return (
//     <Card sx={{ marginTop: "10px" }}>
//       <CardContent>
//         <Typography variant="h5" gutterBottom display="inline">
//           {label}
//         </Typography>
//         {children}
//         {/* <Grid container spacing={2}>
//           <Grid item sx={12}>
//           </Grid>
//           <Grid item sx={12}>
//           </Grid>
//         </Grid> */}
//       </CardContent>
//     </Card>
//   );
// };

// const JewelleryTypeCard = (props) => {
//   const { obj, isSelected, onClick, isMobile } = props;
//   const padding = isMobile ? "9px" : "12px";
//   return (
//     <Card
//       sx={{
//         border: `1px solid `,
//         borderColor: !isSelected ? "grey.500" : "priamry.main",
//         cursor: "pointer",
//         margin: "6px 6px",
//         overflow: "visible",
//         position: "relative",
//         cursor: "pointer",
//       }}
//       onClick={() => onClick(obj)}
//     >
//       <CardContent
//         sx={{
//           textAlign: "center",
//           padding,
//           "&:last-child": { paddingBottom: padding },
//         }}
//       >
//         {isSelected ? (
//           <CheckCircle
//             style={{
//               position: "absolute",
//               right: "-4px",
//               top: "-4px",
//               width: "16px",
//               height: "16px",
//             }}
//             width={9}
//             height={9}
//           />
//         ) : null}
//         <div style={{ display: "flex" }}>
//           {jewelleryTypeIcon[obj.value]}
//           <Typography
//             variant={isMobile ? "body2" : "h6"}
//             ml={2}
//             // color="GrayText"
//           >
//             {obj.label}
//           </Typography>
//         </div>
//       </CardContent>
//     </Card>
//   );
// };

// const PurityCard = ({ obj, isSelected, onClick, isMobile }) => {
//   const padding = isMobile ? "9px" : "12px";
//   return (
//     <Card
//       sx={{
//         border: `0.5px solid`,
//         borderColor: !isSelected ? "grey.500" : "priamry.main",
//         margin: "6px 6px",
//         overflow: "visible",
//         position: "relative",
//         cursor: "pointer",
//       }}
//       onClick={() => onClick && onClick(obj)}
//     >
//       <CardContent
//         sx={{
//           textAlign: "center",
//           padding,
//           "&:last-child": { paddingBottom: padding },
//         }}
//       >
//         {isSelected ? (
//           <CheckCircle
//             width={9}
//             height={9}
//             style={{
//               position: "absolute",
//               right: "-4px",
//               top: "-4px",
//               width: "16px",
//               height: "16px",
//             }}
//           />
//         ) : null}
//         <Typography variant={isMobile ? "body2" : "h6"}>{obj.label}</Typography>
//       </CardContent>
//     </Card>
//   );
// };
// const CurrencyCard = ({ obj, isSelected, onClick, isMobile }) => {
//   const padding = isMobile ? "9px" : "12px";
//   return (
//     <Card
//       sx={{
//         border: `0.5px solid`,
//         borderColor: !isSelected ? "grey.500" : "priamry.main",
//         margin: "6px 6px",
//         overflow: "visible",
//         position: "relative",
//         cursor: "pointer",
//       }}
//       onClick={() => onClick && onClick(obj)}
//     >
//       <CardContent
//         sx={{
//           textAlign: "center",
//           padding,
//           "&:last-child": { paddingBottom: padding },
//         }}
//       >
//         {isSelected ? (
//           <CheckCircle
//             width={9}
//             height={9}
//             style={{
//               position: "absolute",
//               right: "-4px",
//               top: "-4px",
//               width: "16px",
//               height: "16px",
//             }}
//           />
//         ) : null}
//         <Stack direction="row">
//           <Typography mr={1} variant={isMobile ? "body2" : "h6"}>
//             {obj.label}
//           </Typography>
//           <Typography variant="h6">({obj.symbol && obj.symbol})</Typography>
//         </Stack>
//       </CardContent>
//     </Card>
//   );
// };

// const ColorCard = ({ obj, isSelected, onClick, isMobile }) => {
//   const padding = isMobile ? "9px" : "12px";
//   return (
//     <Card
//       sx={{
//         border: `0.5px solid`,
//         borderColor: !isSelected ? "grey.500" : "priamry.main",
//         margin: "6px 6px",
//         overflow: "visible",
//         position: "relative",
//         cursor: "pointer",
//       }}
//       onClick={() => onClick(obj)}
//     >
//       <CardContent
//         sx={{
//           textAlign: "center",
//           padding,
//           "&:last-child": { paddingBottom: padding },
//           display: "flex",
//           alignItems: "center",
//         }}
//       >
//         <div
//           style={{
//             width: "17px",
//             height: "17px",
//             borderRadius: "14px",
//             background: colorsBackground[obj.value],
//             // background: "linear-gradient(to right, #E1C16E,#edd595 )",
//           }}
//         />
//         {isSelected ? (
//           <CheckCircle
//             style={{
//               position: "absolute",
//               right: "-4px",
//               top: "-4px",
//               width: "16px",
//               height: "16px",
//             }}
//             width={9}
//             height={9}
//           />
//         ) : null}
//         <Typography variant={isMobile ? "body2" : "h6"} ml={1}>
//           {obj.label}
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// };
// const WeightCard = ({ obj, isSelected, onClick, isMobile }) => {
//   const padding = isMobile ? "9px" : "12px";
//   return (
//     <Card
//       sx={{
//         border: `0.5px solid`,
//         borderColor: !isSelected ? "grey.500" : "priamry.main",
//         margin: "6px 6px",
//         overflow: "visible",
//         position: "relative",
//         cursor: "pointer",
//       }}
//       onClick={() => onClick(obj.value)}
//     >
//       <CardContent
//         sx={{
//           textAlign: "center",
//           padding,
//           "&:last-child": { paddingBottom: padding },
//         }}
//       >
//         {isSelected ? (
//           <CheckCircle
//             style={{
//               position: "absolute",
//               right: "-4px",
//               top: "-4px",
//               width: "16px",
//               height: "16px",
//             }}
//             width={9}
//             height={9}
//           />
//         ) : null}
//         <Typography variant={isMobile ? "body2" : "h6"}>{obj.label}</Typography>
//       </CardContent>
//     </Card>
//   );
// };

const CollectionCard = ({ label, isSelected, onRemove, isMobile }) => {
  const padding = isMobile ? "9px" : "12px";
  return (
    <Card
      sx={{
        border: `1px solid #8f8989`,
        margin: "6px 6px",
        overflow: "visible",
        position: "relative",
      }}
    >
      <CardContent
        sx={{
          textAlign: "center",
          padding,
          "&:last-child": { paddingBottom: padding },
        }}
      >
        <IconButton
          aria-label="delete"
          style={{
            position: "absolute",
            right: "-4px",
            top: "-4px",
            width: "16px",
            height: "16px",
          }}
          onClick={() => onRemove(label)}
          color="error"
        >
          <Cancel style={{ width: "16px", height: "16px" }} />
        </IconButton>
        <Typography variant="h6">{label}</Typography>
      </CardContent>
    </Card>
  );
};

const options = [
  { value: "apple", label: "Apple" },
  { value: "banana", label: "Banana" },
  { value: "orange", label: "Orange" },
];

function MyComponent() {
  const [selectedOption, setSelectedOption] = useState([]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Select
      multiple
      value={selectedOption}
      onChange={handleSelectChange}
      renderValue={(selected) =>
        selected.map((option) => option.label).join(", ")
      }
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

const Dropdown = ({ data, label, value, onChange }) => {
  // console.log(data);
  return (
    <FormControl size="small">
      {/* <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel> */}
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        // input={<OutlinedInput label={label} />}

        renderValue={(selected) => label}
        value={value || []}
        onChange={onChange}
        // displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        style={{
          margin: "4px 6px",
        }}
        size="small"
        sx={{}}
      >
        {isArrayWithValues(data) &&
          data.length > 0 &&
          data.map((obj) => {
            return (
              <MenuItem
                key={obj.id}
                value={obj}
                sx={{
                  borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                }}
              >
                {/* <Checkbox checked={value && value.indexOf(obj.label) > -1} /> */}
                <Checkbox
                  checked={value.some(
                    (selected) => selected.label === obj.label
                  )}
                />
                <ListItemText primary={obj.label} />
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};
