import { Box, Grid } from "@mui/material";
import React from "react";
import {
  PrimaryLabel,
  PrimaryLabelSmall,
  SecondaryLabel,
  SecondaryLabelSmall,
} from "../../../../../helper/utility/Labels";
import CustomIntervalReward from "./PlanRewards/CustomIntervalReward";
import HorizontalStack from "../../../../../helper/utility/HorizontalStack";
import { Check } from "@mui/icons-material";
import { currencySymbols } from "../../../../pricing/Gold";
import CustomChip from "../../../../components/CustomChip";
import { generateAdvancedPenaltySettingsSentence } from "../utils";
import { formatServerValue } from "../../../../../helper";

function PlanReview({ form, editForm }) {
  return (
    <Box>
      <PrimaryLabel>Review your plan</PrimaryLabel>
      <Grid container spacing={5} rowSpacing={5} sx={{ mt: 2 }}>
        {Boolean(form?.image?.source_url) && (
          <Grid item xs={12} md={6}>
            <img
              src={form?.image?.source_url}
              style={{
                maxHeight: "240px",
                width: "100%",
                objectFit: "contain",
                textAlign: "center",
                borderRadius: "7px",
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <PrimaryLabel sx={{ fontSize: "24px" }}>
            {form?.name || "Gold Saving Plan"}
          </PrimaryLabel>
          <PrimaryLabelSmall sx={{ mt: 3 }}>Plan details</PrimaryLabelSmall>
          <CustomChip
            sx={{ mt: 1 }}
            label={`Plan type: ${formatServerValue(form?.plan_type)}`}
          />
          <Grid container spacing={3} sx={{ mt: 0 }} rowSpacing={3}>
            <Grid item xs={6} rowSpacing={3}>
              <SecondaryLabelSmall>Total plan limit</SecondaryLabelSmall>
              <PrimaryLabel>
                {form?.plan_amount_type !== "range"
                  ? `${currencySymbols[form.currency]} ${
                      form?.plan_amount || ""
                    }`
                  : `${currencySymbols[form.currency]} ${
                      form?.min_plan_amount || ""
                    } - ${currencySymbols[form.currency]} ${
                      form?.max_plan_amount || ""
                    }`}
              </PrimaryLabel>
            </Grid>
            <Grid item xs={6} rowSpacing={3}>
              <SecondaryLabelSmall>Total plan duration</SecondaryLabelSmall>
              <PrimaryLabel>
                {form?.digi_plan_duration || "0"} {form?.duration_period || ""}
              </PrimaryLabel>
            </Grid>
          </Grid>
          <SecondaryLabelSmall sx={{ mt: 3 }}>
            Per transaction limit
          </SecondaryLabelSmall>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <PrimaryLabel>
                {form?.plan_minimum
                  ? `${currencySymbols[form.currency]} ${form?.plan_minimum}`
                  : "N/A"}
              </PrimaryLabel>
              <SecondaryLabelSmall>Min</SecondaryLabelSmall>
            </Grid>
            <Grid item xs={6}>
              <PrimaryLabel>
                {form?.plan_maximum
                  ? `${currencySymbols[form.currency]} ${form?.plan_maximum}`
                  : "N/A"}
              </PrimaryLabel>
              <SecondaryLabelSmall>Max</SecondaryLabelSmall>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PrimaryLabel sx={{ mt: 5 }}>Rewards</PrimaryLabel>
      {form?.reward_type === "custom" && (
        <CustomIntervalReward
          form={form}
          editForm={editForm}
          showHeader={false}
          showActions={false}
        />
      )}
      {form?.reward_type === "fixed" && (
        <Box>
          <SecondaryLabelSmall sx={{ mt: 3 }}>
            Total fixed reward
          </SecondaryLabelSmall>
          <PrimaryLabel sx={{ mt: 2 }}>
            {currencySymbols[form.currency]} {form?.lumpsum_reward_value || "0"}
          </PrimaryLabel>
        </Box>
      )}
      {Boolean(form?.advance_penalty) && (
        <>
          <PrimaryLabel sx={{ mt: 5 }}>Advanced Penalty Settings</PrimaryLabel>
          <PrimaryLabelSmall sx={{ mt: 2 }}>
            {generateAdvancedPenaltySettingsSentence(form)}
          </PrimaryLabelSmall>
        </>
      )}
      <PrimaryLabel sx={{ mt: 5 }}>Settings</PrimaryLabel>
      <SecondaryLabelSmall sx={{ mt: 3 }}>Basic Settings</SecondaryLabelSmall>
      {Boolean(form?.change_gold_rate) && (
        <HorizontalStack spacing={2} sx={{ mt: 2 }}>
          <Check color="success" style={{ height: "14px", width: "14px" }} />
          <PrimaryLabelSmall sx={{ mt: 2 }}>
            Allow admin to update gold rates
          </PrimaryLabelSmall>
        </HorizontalStack>
      )}
      <SecondaryLabelSmall sx={{ mt: 3 }}>Enabled Options</SecondaryLabelSmall>
      {Boolean(form?.amount_on_grams) && (
        <HorizontalStack spacing={2} sx={{ mt: 2 }}>
          <Check color="success" style={{ height: "14px", width: "14px" }} />
          <PrimaryLabelSmall sx={{ mt: 2 }}>
            Show accumulated amount to customers
          </PrimaryLabelSmall>
        </HorizontalStack>
      )}
      {Boolean(form?.grams_on_amount) && (
        <HorizontalStack spacing={2} sx={{ mt: 2 }}>
          <Check color="success" style={{ height: "14px", width: "14px" }} />
          <PrimaryLabelSmall sx={{ mt: 2 }}>
            Show accumulated gold weight to customers
          </PrimaryLabelSmall>
        </HorizontalStack>
      )}
    </Box>
  );
}

export default PlanReview;
