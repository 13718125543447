import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import Switch from "rc-switch";
import React from "react";

function CustomLabelSwitch({
  label,
  subtitle,
  checked,
  labelProps,
  onChange = () => {},
  containerStyle = {},
}) {
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  return (
    <Box sx={{ mt: 5, ...containerStyle }}>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5" fontSize="15px" {...labelProps}>
          {label}
        </Typography>
        <Switch
          onChange={(e) => {
            onChange(e);
          }}
          checked={checked}
          style={{
            border: `1px solid ${checked ? backgroundColor : "#e8e8e8"}`,
            backgroundColor: checked ? backgroundColor : "#e8e8e8",
          }}
        />
      </Stack>

      <Typography
        variant="body2"
        sx={{ fontSize: "12px", color: "text.secondary", mt: 1 }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
}

export default CustomLabelSwitch;
