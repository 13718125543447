import { useState, useEffect } from "react";
import { Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import { fetchProductCatalog } from "./utils/fetchProductCatalog";
import { productCatalogStyles } from "./styles/productCatalogStyles";
import Header from "./components/Header";
import MainSection from "./components/MainSection";
import { checkTitleExists } from "../helper/checkTitleExists";
import { getRandomId } from "../utils";

const styles = productCatalogStyles;

const SidebarProductCatalog = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const bodId = useSelector((state) => state.flow?.currentNodeId);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [catalogs, setCatalogs] = useState({});
  const [selectedCatalog, setSelectedCatalog] = useState([]);
  const [titleCheckFlag, setTitleCheckFlag] = useState(false);

  const handleCatalog = (catalog) => {
    const existingIndex = selectedCatalog.findIndex(
      (item) => item.label === catalog
    );

    if (existingIndex !== -1) {
      const updatedCatalogs = [...selectedCatalog];
      updatedCatalogs.splice(existingIndex, 1);
      setSelectedCatalog(updatedCatalogs);
    } else {
      setSelectedCatalog((prev) => [
        ...prev,
        {
          id: getRandomId(),
          isUser: false,
          label: catalog,
          value: catalog,
          title: "",
          button_type: "product-catalog",
        },
      ]);
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title == "" || titleCheckFlag) {
      setTitleCheckFlag(true);
      setTitleError(true);
    }

    if (title && !titleCheckFlag) {
      const submittedUserData = {
        title,
        fieldsData: selectedCatalog,
      };
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openProductCatalogSidebar: false },
      });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: bodId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  const closeSidebar = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { openProductCatalogSidebar: false },
    });
  };

  const getProductCatalogs = async () => {
    const res = await fetchProductCatalog();
    setCatalogs(res);
  };

  const handleTitle = (e) => {
    const value = e.target.value;
    setTitle(e.target.value);
    if (checkTitleExists({ nodes, title: value })) {
      setTitleCheckFlag(true);
      return;
    }
    setTitleCheckFlag(false);
  };

  useEffect(() => {
    getProductCatalogs();
  }, []);

  useEffect(() => {
    const currentNode = nodes?.find((it) => it.id === bodId);

    if (
      currentNode &&
      currentNode?.fieldData &&
      currentNode?.fieldData?.fieldsData
    ) {
      const data = currentNode?.fieldData;
      setTitle(data?.title);
      setSelectedCatalog(data?.fieldsData);
    }
  }, []);

  return (
    <Card style={styles.customCard}>
      <form>
        <Header
          handleClose={handleClose}
          handleCheck={handleCheck}
          titleError={titleError}
          title={title}
          setTitle={setTitle}
          handleTitle={handleTitle}
          titleCheckFlag={titleCheckFlag}
        />
        <MainSection
          catalogs={catalogs}
          selectedCatalog={selectedCatalog}
          setSelectedCatalog={setSelectedCatalog}
          handleCatalog={handleCatalog}
        />
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </form>
    </Card>
  );
};

export default SidebarProductCatalog;
