export const chatbotStyles = {
  outerBox: {
    display: "flex",
    flexDirection: "column",
    width: "375px",
    backgroundColor: "white",
    marginTop: "80px",
    marginRight: "6px",
    borderRadius: "15px",
    // position: "absolute",
    // top: "60px",
    // right: "20px"
    // position: "fixed",
    // bottom: "10px",
    // right: "10px",
  },
};
