import {
  Article,
  Clear,
  ContentCopy,
  DeleteOutline,
  Edit,
  EditOutlined,
  FacebookTwoTone,
  ShoppingCart,
  Verified,
  YouTube,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useMemo, useRef } from "react";
import { useState } from "react";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { Facebook, InstagramSvg, MetaSvg, WhatsappSvg } from "../../../../Svgs";
import axios from "axios";
import {
  backendApiCall,
  dialogStyle,
  formatServerValue,
  getAxiosError,
  getChannelData,
  getIntegrationObj,
  getIsBackendMigrated,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  primaryLabel,
  putTourHistory,
  saveDatainDataBase,
  secondaryLabel,
  secondaryLabelSmall,
  settingsEndpoint,
} from "../../../../helper";
import { fetchFacebookInfo, fetchFacebookPages } from "../../../hub/facebook";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { setHubData } from "../../../../redux/actions/hubActions";

import {
  client_id_global,
  client_secret_global,
  redirect_url_global,
} from "../../Integrations";
import MetaOverview from "./MetaOverview";
import {
  setGlobalToast,
  setGlobalTutorial,
  setHelperData,
  SET_HELPER_DATA,
} from "../../../../redux/actions/helperActions";
import TempleteList from "../../../components/TempleteList";
import MetaAnalytics from "./MetaAnalytics";

import { AssignmentTurnedIn, WhatsApp } from "@material-ui/icons";
import styled from "styled-components";
import { useTour } from "@reactour/tour";
import {
  metaIntegrationSteps2,
  metaIntegrationsByProduct,
  tutorialData,
} from "../../../../App";
import { addTourHistory } from "../../../../redux/actions/userActions";
import { getTempletes } from "../../../../redux/actions/chatActions";

import { Label } from "../../../customers/components/Info";
import MetaIntegrationView from "./components/MetaIntegrationView";
import HorizontalStack from "../../../../helper/utility/HorizontalStack";
export const allScope = {
  whatsapp: [
    "whatsapp_business_management",
    "whatsapp_business_messaging",
    // "pages_show_list"
  ],
  facebook: [
    "pages_read_engagement",
    "read_insights",
    "pages_show_list",
    "publish_to_groups",
    "pages_manage_posts",
    "email",
    "public_profile",
    "pages_manage_engagement",
    "pages_read_user_content",
    "pages_manage_metadata",
    "pages_messaging",
  ],
  // "pages_read_engagement,read_insights,pages_show_list,publish_to_groups,pages_manage_posts,email,pages_manage_engagement, pages_read_user_content, pages_manage_metadata"
  instagram: [
    "instagram_content_publish",
    "instagram_manage_insights",
    "instagram_basic",
    "instagram_manage_comments",
    "instagram_manage_messages",
    "pages_manage_metadata",
    // "pages_read_engagement",
    // "pages_show_list"
    // "instagram_graph_user_media",
    // "instagram_graph_user_profile"
  ],
  commerce: ["catalog_management"],
  comman: ["public_profile", "business_management"],
};
const Meta = () => {
  const [selectedTab, setSelectedTab] = useState("overview");
  const defaultScope = {
    facebook: false,
    instagram: false,
    whatsapp: false,
    commerce: false,
  };
  const navigate = useNavigate();
  const theme = useTheme();
  // createTheme({
  //   components: {
  //     Paper: {
  //       styleOverrides: {
  //         root: { boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px" }
  //       }
  //     }
  //   }
  // })
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedScope, setSelectedScope] = useState(defaultScope);
  const [metaIntegartion, setMetaIntegration] = useState({});
  const [integratedApp, setIntegartedApp] = useState([]);
  const [revokeApp, setRevokeApp] = useState(false);
  const [revoking, setRevoking] = useState(false);
  const [updatingToken, setUpdatingToken] = useState(false);
  const [whatsappTempletes, setWhatsappTempletes] = useState([]);
  const [showWhatsappTemplates, setShowWhatsappTemplate] = useState(false);
  const [showWhatsappBotOptions, setShowWhatsappBotOptions] = useState(false);

  const [whatsappForm, setWhatsappForm] = useState({});
  const [showAssignTemplate, showShowAssignTemplate] = useState(false);
  const [businessesAccountMeta, setBusinessesAccountMeta] = useState([]);
  const [selectBusinessesAccountMeta, setSelectBusinessesAccountMeta] =
    useState("");
  const [whatsappFormError, setWhatsappFormError] = useState({});
  const [updatingBusinessAccount, setUpdatingBusinessAcount] = useState(false);

  const reduxMetaIntegration = useSelector(
    (state) => state.settings.metaIntegration
  );
  const fetchingMetaIntegration = useSelector(
    (state) => state.settings.fetchingAllSettings
  );
  const reduxWhatsappTemplete = useSelector(
    (state) => state.chats.whatsappTempletes
  );
  const onlyChats = useSelector((state) => state.helper.onlyChats);
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const storePlan = useSelector((state) => state.user.store_plan);

  const [filterIntegration, setFilterIntegration] = useState([]);

  useEffect(() => {
    if (isArrayWithValues(user_products)) {
      let array = [];
      for (let product of user_products) {
        if (metaIntegrationsByProduct[product])
          array = array.concat(metaIntegrationsByProduct[product]);
      }
      // if (user_products.includes("catalog"))
      //   array = array.concat([
      //     "google_sheets",
      //     "google_analytics",
      //     "tag_manager",
      //   ]);
      // if (user_products.includes("chats"))
      //   array = array.concat(["google_calendar", "tag_manager"]);
      setFilterIntegration([...new Set(array)]);
    } else setFilterIntegration([]);
  }, [user_products]);
  const currentTour = useSelector((state) => state.helper.currentTour);
  const { setIsOpen, setSteps } = useTour();
  const { isOpen, setCurrentStep } = useTour();
  useEffect(() => {
    let hideRejected = true;
    if (isArrayWithValues(reduxWhatsappTemplete)) {
      if (hideRejected)
        setWhatsappTempletes(
          reduxWhatsappTemplete.filter((i) => i.status === "APPROVED")
        );
      else setWhatsappTempletes(reduxWhatsappTemplete);
    }
  }, [reduxWhatsappTemplete]);
  useEffect(() => {
    if (reduxMetaIntegration) {
      setMetaIntegration(reduxMetaIntegration);
    }
  }, [reduxMetaIntegration]);

  useEffect(() => {
    if (!isObjWithValues(metaIntegartion)) return;
    // let integrationApp = []
    for (const key in metaIntegartion) {
      if (!isArrayWithValues(metaIntegartion[key].connected)) return;
      let app = metaIntegartion[key].connected;
      if (app.includes("facebook")) {
        let obj = metaIntegartion[key];
        if (obj.facebook_pages_info) {
          setSelectedPage(obj.facebook_pages_info.id);
        }
      }
      // integrationApp = [...integrationApp, ...app]
      // if (isArrayWithValues(integratedApp)) {
      //     for (const item of integrationApp) {
      //         setSelectedScope(pre => ({ ...pre, [item]: true }))
      //     }
      // } else {
      //     setSelectedScope(defaultScope)
      // }
    }
    // setIntegartedApp(integrationApp)
  }, [metaIntegartion, fetchingMetaIntegration]);

  useEffect(() => {
    if (!isObjWithValues(metaIntegartion) && fetchingMetaIntegration) return;
    let integrationApp = [];
    for (const key in metaIntegartion) {
      let app = metaIntegartion[key].connected;
      if (!isArrayWithValues(app)) return;
      integrationApp = [...integrationApp, ...app];
    }
    setIntegartedApp(integrationApp);
  }, [metaIntegartion, fetchingMetaIntegration]);

  useEffect(() => {
    if (!isObjWithValues(metaIntegartion) && fetchingMetaIntegration) return;
    for (const key in metaIntegartion) {
      let app = metaIntegartion[key]?.connected || [];
      if (app.includes("commerce")) {
        let account = metaIntegartion[key]?.commerce_catalogs || [];
        setBusinessesAccountMeta(account);
        let selected = metaIntegartion[key]?.select_commerce_catalogs || "";
        setSelectBusinessesAccountMeta(selected);
      }
    }
  }, [metaIntegartion, fetchingMetaIntegration]);

  const onEditWhatsappForm = (payload) => {
    setWhatsappForm((pre) => ({ ...pre, ...payload }));
    setWhatsappFormError({});
  };
  const onIntegrateWhatsapp = () => {
    if (!whatsappForm?.whatsappAccessToken?.trim()) {
      setWhatsappFormError((pre) => ({ ...pre, whatsappAccessToken: true }));
      return;
    }
    if (!whatsappForm?.wabaId?.trim()) {
      setWhatsappFormError((pre) => ({ ...pre, wabaId: true }));
      return;
    }
    updateWhatsappToken(
      whatsappForm?.whatsappAccessToken,
      whatsappForm?.wabaId
    );
  };

  const getFacebookBusinessAccount = async (basic_info_id, access_token) => {
    try {
      let res = await axios({
        url: `https://graph.facebook.com/v15.0/${basic_info_id}/businesses`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      return res?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getCatalog = async (business_id, access_token) => {
    try {
      let res = await axios({
        url: `https://graph.facebook.com/v15.0/${business_id}/owned_product_catalogs`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      return res?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };
  const fetchWhatsappData = async (access_token) => {
    try {
      let res = await axios({
        url: `https://graph.facebook.com/v18.0/debug_token`,
        params: {
          input_token: access_token,
        },
        headers: {
          Authorization: `Bearer ${client_id_global}|${client_secret_global}`,
        },
      });
      console.log(res.data.data);
      return res.data.data;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };

  useMemo(() => {
    if (isOpen && currentTour !== "meta_integration") {
      isArrayWithValues(selectedScope)
        ? dispatch({
            type: SET_HELPER_DATA,
            payload: {
              // setHelperData({
              restrictedTour: [0, 1, 2, 4],
              // })
            },
          })
        : dispatch({
            type: SET_HELPER_DATA,
            payload: {
              // setHelperData({
              restrictedTour: [0, 1, 2, 3, 4],
              // })
            },
          });
    }
  }, [selectedScope]);

  const onEmbedPress = ({ platforms, config_id, is_embed }) => {
    console.log(window?.FB?.login, "<<<<<<<<<<<<<<< window?.FB?.login");
    window?.FB?.login?.(
      (response) => {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          getAccessTokenFromCode(
            response.authResponse?.code,
            platforms || ["whatsapp"],
            { is_embed, shortAccessToken: accessToken }
          );
          // Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: config_id || "347940381097656", // configuration ID obtained in the previous step goes here
        ...(is_embed
          ? {
              response_type: "code",
              override_default_response_type: true,
            }
          : {}),
        // extras: {
        //   feature: "whatsapp_embedded_signup",
        //   // Display original flow to user
        //   version: 1,
        // },
      }
    );
  };

  const FacebookConfiguration = () => {
    if (fetchingMetaIntegration) return;
    return onEmbedPress({
      platforms: Object.keys(selectedScope)
        .map((key) => selectedScope[key] && key)
        ?.filter(Boolean),
      // platforms: ["facebook", "instagram"],
      // config_id: "1873242969744650",
      config_id: "711491777154588",
    });

    if (isOpen) {
      putTourHistory({ meta_integration: true });
      dispatch(addTourHistory({ meta_integration: true }));
    }

    let allowScope = Object.keys(selectedScope)
      .filter(
        (key) =>
          selectedScope[key] === true &&
          !integratedApp.includes(selectedScope[key])
      )
      .map((i) => [allScope[i], allScope.comman])
      .join();

    const scope = allowScope;
    // const redirect_url = `https://retailer.tanika.tech/integrations/?app=facebook`;
    let apps = Object.keys(selectedScope).filter(
      (key) =>
        selectedScope[key] === true &&
        !integratedApp.includes(selectedScope[key])
    );

    const redirect_url = `${redirect_url_global}/integrations/?app=facebook&response_type=code&scope=${scope}&state=${apps.join()}`;
    // console.log(redirect_url);

    let authUrl = "https://www.facebook.com/dialog/oauth";
    let link = `${authUrl}?client_id=${client_id_global}&redirect_uri=${redirect_url}`;
    // let link = `${authUrl}?client_id=${clientId}&redirect_uri=${redirect_url}&response_type=code&scope=${scope}`;
    console.log(link);
    window.open(link, "_self");
  };

  useEffect(async () => {
    let params_string = location.search;
    // let url = new URL(params_string);
    let code = new URLSearchParams(params_string).get("code");
    let app = new URLSearchParams(params_string).get("state");
    let error = new URLSearchParams(params_string).get("error");
    // console.log(app.slice(0,-10).split(","));
    console.log(app);
    if (!app) return;
    let appArray = app.split(",");
    if (code) getAccessTokenFromCode(code, appArray);

    setSelectedTab("integration");
    console.log(code);
    // for (const key of appArray) {
    //     setSelectedScope(pre => ({ ...pre, [key]: true }))
    // }
    let { hash } = location;
    // if (app)
    navigate("/integrations");
    // if (error) navigate("/integrations");
  }, []);

  const getWhatsappDebug = async (access_token) => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    try {
      // let res = await axios({

      //   url: `https://graph.facebook.com/v14.0/debug_token`,
      //   params: {
      //     input_token: access_token,
      //   },
      //   headers: {
      //     Authorization: `Bearer ${client_id_global}|${client_secret_global}`,
      //   },
      // });
      if (getIsBackendMigrated("chats")) {
        let res = await backendApiCall({
          endpoint: "social/facebook",
          headers: {
            Authorization: `Basic ${token}`,
          },
          params: {
            endpoint: `/debug_token${
              access_token ? `?input_token=${access_token}` : ""
            }`,
            input_token: access_token,
            // endpoint: `/debug_token${access_token ? `?bypass_access_token=${access_token}` : ""
            //   }`,
            // params: {
            //   input_token: access_token,
            // }
          },
          extractDataFromResponse: true,
        });
        console.log(res);
        return res;
      } else {
        let res = await axios({
          url: `${website}/wp-json/store/v1/channel/${"facebook"}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          params: {
            endpoint: `/debug_token${
              access_token ? `?input_token=${access_token}` : ""
            }`,
            input_token: access_token,
            // endpoint: `/debug_token${access_token ? `?bypass_access_token=${access_token}` : ""
            //   }`,
            // params: {

            //   input_token: access_token,
            // }
          },
        });
        console.log(res.data.data);
        return res.data.data;
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };

  const getWhatsappDetails = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    let data;
    let obj;
    let whatsappData = await getWhatsappDebug();
    let targetObj = whatsappData.granular_scopes.find(
      (i) => i.scope === "whatsapp_business_management"
    );
    console.log(targetObj, targetObj.target_ids);
    // obj.whatsapp_info = targetObj.target_ids
    let batch = targetObj?.target_ids.map((i) => ({
      method: "GET",
      relative_url: `/${i}/phone_numbers`,
    }));
    const res = await axios({
      url: `${website}/wp-json/store/v1/channel/${"facebook"}`,
      method: "POST",
      params: {
        include_headers: false,

        endpoint: "/me",
      },
      data: {
        batch,
      },
    });
    if (isArrayWithValues(res.data)) {
      let whatsapp_info = res.data.map((i, index) => {
        let body = JSON.parse(i.body);
        let bodyData = body.data;
        let obj = {};
        if (isArrayWithValues(bodyData)) {
          obj = bodyData[0];
        }
        obj.waba = targetObj.target_ids[index];
        return obj;
      });
      obj = { ...obj, whatsapp_info };
      console.log(whatsapp_info);
      // obj.whatsapp_info = [...whatsapp_info].map(i => i[0])
    }
    // let time = Math.round(Date.now() / 1000);
    // let metaIntegartionData = await axios({
    //   url: `${website}/wp-json/store/v1/settings/meta_integration`,
    //   headers: {
    //     Authorization: `Basic ${token}`,
    //   },
    //   data: {
    //     type: "meta_integration",
    //     data: {
    //       [time]: { ...obj },
    //     },
    //   },
    //   method: "PUT",
    // });
  };

  const getAccessTokenFromCode = async (code, appArray, options) => {
    let { is_embed, shortAccessToken } = options || {};
    dispatch(
      setGlobalToast({
        show: true,
        message: `${appArray.join()} Apps are integrating please wait`,
        severity: "success",
      })
    );
    // return
    let allowScope = appArray.map((i) => [allScope[i], allScope.comman]).join();
    const scope = allowScope;

    // // const redirect_url = `https://retailer.tanika.tech/integrations/?app=facebook`;
    // // const redirect_url = `http://localhost/integrations`;
    // const redirect_url = `http://localhost:3000/integrations?app=facebook&response_type=code&scope=${scope}`;
    // let authUrl = "https://www.facebook.com/v8.0/dialog/oauth";
    // let data = {
    //   code,
    // };
    // const redirect_uri = `http://192.168.0.31:3000/integrations?app=${appArray.join()}`
    // const redirect_uri = `http://localhost:3000/integrations?app=${appArray.join()}`

    let temp =
      "instagram_content_publish,instagram_manage_insights,instagram_basic,instagram_manage_comments,instagram_manage_messages, pages_manage_metadata,whatsapp_business_management,whatsapp_business_messaging,pages_read_engagement,read_insights,pages_show_list,publish_to_groups,pages_manage_posts,email,public_profile,instagram_content_publish,instagram_manage_insights,instagram_basic,instagram_manage_comments,pages_read_user_content,pages_manage_engagement,catalog_management,instagram_manage_messages";
    // const redirect_uri = `${redirect_url_global}/integrations/?app=facebook&response_type=code&scope=${scope}`;
    const redirect_uri = `${redirect_url_global}/integrations/?app=facebook`;
    // const redirect_uri = `https://3867-116-73-246-36.ngrok-free.app/integrations`;

    // &response_type=code&scope=${scope};
    // const redirect_url = `http://localhost:3000/integrations?app=${apps.join()}&response_type=code&scope=${scope}`;
    // let auth = `https://www.facebook.com/dialog/oauth/access_token`;
    let auth = `https://graph.facebook.com/v18.0/oauth/access_token`;
    try {
      let res;
      if (shortAccessToken) {
        res = await axios({
          // url: `${auth}?client_id=${client_id_global}&redirect_uri=${redirect_uri}&client_secret=${client_secret_global}&code=${code}`,
          url: `${auth}?client_id=${client_id_global}&client_secret=${client_secret_global}&grant_type=fb_exchange_token&fb_exchange_token=${shortAccessToken}`,
          method: "GET",
        });
      } else
        res = await axios({
          url: is_embed
            ? `${auth}?client_id=${client_id_global}&client_secret=${client_secret_global}&code=${code}`
            : `${auth}?client_id=${client_id_global}&redirect_uri=${redirect_uri}&client_secret=${client_secret_global}&code=${code}`,
          // url: `${auth}?client_id=${client_id_global}&client_secret=${client_secret_global}&redirect_uri=${}&code=${code}`,
          method: "GET",
        });

      let { data } = res;
      if (data.access_token) {
        let website = await getWebsite();
        let token = await getToken();
        // const info = await fetchFacebookInfo(data.access_token);
        let page = {};
        let obj = {
          connected: appArray,
          scope: allowScope,
          access_token: data.access_token,
          is_whatsapp_embed_signup: is_embed,
          // basic_info: info
        };
        try {
          const info = await fetchFacebookInfo(data.access_token);
          obj.basic_info = info;
          if (appArray.includes("commerce")) {
            let commerceAccount = await getFacebookBusinessAccount(
              info?.id,
              data.access_token
            );
            let business_catalogs = [];
            for await (const account of commerceAccount) {
              let catalogs = await getCatalog(account.id, data.access_token);
              console.log(catalogs);
              let resCatalogs = catalogs || [];
              business_catalogs = [...business_catalogs, ...resCatalogs];
            }
            obj.commerce_catalogs = business_catalogs;
          }
          if (appArray.includes("whatsapp")) {
            let whatsappData = await fetchWhatsappData(data.access_token);
            let targetObj = whatsappData.granular_scopes.find(
              (i) => i.scope === "whatsapp_business_management"
            );
            if (!targetObj)
              targetObj = whatsappData.granular_scopes.find(
                (i) => i.scope === "business_management"
              );
            // obj.whatsapp_info = targetObj.target_ids
            let batch = targetObj?.target_ids?.map((i) => ({
              method: "GET",
              relative_url: `/${i}/phone_numbers`,
            }));
            const res = await axios({
              url: `https://graph.facebook.com/v15.0/me`,
              method: "POST",
              headers: {
                Authorization: `Bearer ${data.access_token}`,
              },
              params: {
                include_headers: false,
              },
              data: {
                batch,
              },
            });
            if (isArrayWithValues(res.data)) {
              let whatsapp_info = res.data.map((i, index) => {
                let body = JSON.parse(i.body);
                let bodyData = body.data;
                let obj = {};
                if (isArrayWithValues(bodyData)) {
                  obj = bodyData[0];
                }
                obj.waba = targetObj.target_ids[index];
                return obj;
              });
              obj = { ...obj, whatsapp_info };
              let phone_number_id = whatsapp_info?.[0]?.id;
              // obj.whatsapp_info = [...whatsapp_info].map(i => i[0])
            }
          }
          let time = Math.round(Date.now() / 1000);
          let metaIntegartionData;
          metaIntegartionData = await settingsEndpoint({
            endpoint: `meta_integration`,
            data: {
              // type: "meta_integration",
              // data: {
              [time]: { ...obj },
              // },
            },
            method: "PUT",
          });
          // let metaIntegartionData = await axios({
          //   url: `${website}/wp-json/store/v1/settings/meta_integration`,
          //   headers: {
          //     Authorization: `Basic ${token}`,
          //   },
          //   data: {
          //     type: "meta_integration",
          //     data: {
          //       [time]: { ...obj },
          //     },
          //   },
          //   method: "PUT",
          // });
          try {
            if (
              appArray.includes("instagram") ||
              appArray.includes("instagram")
            ) {
              let postRes = saveDatainDataBase(
                "instagram_access_token",
                data.access_token
              );
              console.log(postRes);
            }
          } catch (error) {
            console.log(error);
          }
          if (isObjWithValues(metaIntegartionData)) {
            dispatch({
              type: EDIT_SETTINGS,
              payload: {
                metaIntegration: metaIntegartionData,
              },
            });
            dispatch(
              setGlobalToast({
                show: true,
                message: `${appArray.join()} integrated successfully!`,
                severity: "success",
              })
            );
            let steps = [...metaIntegrationSteps2];
            if (!appArray?.includes("whatsapp"))
              steps = steps.filter((i) => !i.selector?.includes("whatsapp"));
            if (!appArray?.includes("facebook"))
              steps = steps.filter((i) => !i.selector?.includes("facebook"));
            if (steps.length >= 2) {
              if (!isIpad) {
                dispatch(
                  setHelperData({
                    restrictedTour: [0],
                  })
                );
                setSteps(steps);
                setIsOpen(true);
              }
            }
          }
        } catch (e) {
          console.log(e);
          console.log(getAxiosError(e));
          dispatch(
            setGlobalToast({
              show: true,
              message: `Couldn't integrate ${appArray.join()}, Try again later!`,
              severity: "error",
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(
      setGlobalToast({
        show: false,
        message: `${appArray.join()} Apps are integrating please wait`,
        severity: "success",
      })
    );
  };
  const revokeMeta = async (app) => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token && !getIsBackendMigrated("chats")) return false;
    setRevoking(true);
    // try {
    let id = "";
    let access_token = "";
    let key = "";
    for (const value in metaIntegartion) {
      if (metaIntegartion[value]?.connected?.includes(app)) {
        console.log(key);
        key = value;
        id = metaIntegartion?.[value]?.basic_info?.id;
        access_token = metaIntegartion?.[value]?.access_token;
      }
    }

    // return
    if (metaIntegartion[key].connected.length === 1) {
      let permissionsdeleteArray = [];
      for (let index = 0; index < allScope[revokeApp].length; index++) {
        let obj = {
          method: "DELETE",
          relative_url: `/${id}/permissions/${allScope[revokeApp][index]}`,
        };
        permissionsdeleteArray.push(obj);
      }
      try {
        try {
          if (!isDev())
            await axios({
              url: `https://graph.facebook.com/v15.0/me`,
              method: "POST",
              params: {
                include_headers: false,
                access_token,
              },
              data: {
                batch: permissionsdeleteArray,
              },
            });
        } catch (error) {}

        // console.log(fbDelteRes);
        // if (getIsBackendMigrated("chats")) {
        //   let meta_integration = await settingsEndpoint({
        //     endpoint: "meta_integration",
        //     method: "DELETE",
        //     data: {
        //       id: key,
        //     },
        //   });
        //   if (meta_integration) {
        //     dispatch({
        //       type: EDIT_SETTINGS,
        //       payload: {
        //         metaIntegration: meta_integration,
        //       },
        //     });
        //     dispatch(
        //       setGlobalToast({
        //         show: true,
        //         message: `${revokeApp} Revoked successfully!`,
        //         severity: "success",
        //       })
        //     );
        //     setRevokeApp(false);
        //   }
        // } else {
        let meta_integrationRes = await axios({
          url: `${website}/wp-json/store/v1/settings/meta_integration`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "DELETE",
          data: {
            id: key,
          },
        });
        // setRevoking(false)

        console.log(meta_integrationRes);
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            metaIntegration: meta_integrationRes.data.data,
          },
        });
        dispatch(
          setGlobalToast({
            show: true,
            message: `${revokeApp} Revoked successfully!`,
            severity: "success",
          })
        );
        setRevokeApp(false);
        // }
        // console.log(res.data);
      } catch (error) {
        console.log(getAxiosError(error));
      } finally {
        setRevokeApp(false);
      }
    } else {
      console.log("going oin else");
      let permissionsdeleteArray = [];
      for (let index = 0; index < allScope[revokeApp].length; index++) {
        let obj = {
          method: "DELETE",
          relative_url: `/${id}/permissions/${allScope[revokeApp][index]}`,
        };
        permissionsdeleteArray.push(obj);
      }
      try {
        try {
          if (!isDev())
            await axios({
              url: `https://graph.facebook.com/v15.0/me`,
              method: "POST",
              params: {
                include_headers: false,
                access_token,
              },
              data: {
                batch: permissionsdeleteArray,
              },
            });
        } catch (error) {}
        let obj = {
          ...metaIntegartion[key],
        };
        obj.connected = obj.connected.filter((i) => i !== revokeApp);
        obj.scope = obj.connected
          .map((i) => [allScope[i], allScope.comman])
          .join();
        let meta_integrationRes = await axios({
          url: `${website}/wp-json/store/v1/settings/meta_integration`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            type: "meta_integration",
            data: {
              [key]: obj,
            },
          },
          method: "PUT",
        });
        let { data } = meta_integrationRes;
        console.log(data);
        if (isObjWithValues(data)) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              metaIntegration: data.data,
            },
          });
        }

        dispatch(
          setGlobalToast({
            show: true,
            message: `${revokeApp} Revoked successfully!`,
            severity: "success",
          })
        );
      } catch (e) {
        console.log(getAxiosError(e));
      }
    }
    console.log(revoking, "roveking");
    setRevokeApp(false);
    setRevoking(false);
  };
  const [pages, setPages] = useState([]);
  const [gettingPages, setGettingPages] = useState(false);
  const [whatsappIds, setWhatsappIds] = useState([]);
  const [selectedWhatsapp, setSelectedWhatsapp] = useState({});
  const [selectedPage, setSelectedPage] = useState("");
  const [updatingWhatsapp, setUpdatingWhatsapp] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const facebookPages = useSelector((state) => state.hub.facebookPages);

  const [showPageIntegrationWarning, setShowPageIntegrationWarning] =
    useState(false);

  useEffect(() => {
    if (!isObjWithValues(metaIntegartion)) return;
    let whatsappObj = Object.values(metaIntegartion).find((i) =>
      i?.connected?.includes("whatsapp")
    );
    if (!isObjWithValues(whatsappObj)) return;
    if (isArrayWithValues(whatsappObj.whatsapp_info))
      setWhatsappIds(
        whatsappObj.whatsapp_info.filter(
          (i) =>
            isObjWithValues(i) && i?.display_phone_number && i.verified_name
        )
      );
  }, [metaIntegartion]);
  useEffect(() => {
    if (!isObjWithValues(metaIntegartion)) return;
    let whatsappObj = Object.values(metaIntegartion).find((i) =>
      i?.connected?.includes("whatsapp")
    );
    if (!isObjWithValues(whatsappObj)) return;
    if (whatsappObj?.selected_whatsapp_phone_id)
      setSelectedWhatsapp(
        whatsappObj?.whatsapp_info?.find(
          (o) => o.id === whatsappObj?.selected_whatsapp_phone_id
        )
      );
  }, [metaIntegartion]);

  useEffect(() => {
    let fetch = async () => {
      if (!isObjWithValues(metaIntegartion)) return;
      if (Array.isArray(facebookPages)) setPages(facebookPages);
      else {
        for (const value in metaIntegartion) {
          if (!isArrayWithValues(metaIntegartion[value]?.connected)) return;

          if (
            metaIntegartion[value].connected.includes("facebook") ||
            metaIntegartion[value].connected.includes("instagram")
          ) {
            console.log(metaIntegartion[value]);
            // console.log(metaIntegartion[value].basic_info.id, metaIntegartion[value].access_token);
            // return
            if (isObjWithValues(metaIntegartion[value])) {
              let id = metaIntegartion[value].basic_info?.id;
              console.log(id);
              // let access_token = metaIntegartion[value]?.access_token;
              try {
                if (id) {
                  setGettingPages(true);
                  let pages = await fetchFacebookPages(id, {
                    useDepricate: true,
                  });
                  setGettingPages(false);
                  console.log(pages.data);
                  if (pages && isArrayWithValues(pages.data)) {
                    dispatch(setHubData({ facebookPages: pages.data }));
                  }
                }
              } catch (error) {
                console.log(error);
              }
            }
          }
        }
      }
    };
    fetch();
  }, [facebookPages, metaIntegartion]);
  const [pageChangeLoading, setPAgeChangeLoading] = useState(false);
  const [openCreateTemplete, setopenCreateTemplete] = useState(false);
  const onBusinessAccountUpdate = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setUpdatingBusinessAcount(true);
    let whatsappObj = Object.values(metaIntegartion || {}).find((i) =>
      i.connected?.includes("whatsapp")
    );
    let selectedWhatsappPhone = Object.values(metaIntegartion)
      ?.find((i) => i?.connected?.includes("whatsapp"))
      ?.whatsapp_info?.find(
        (i) => i.id === whatsappObj.selected_whatsapp_phone_id
      );
    let catalogSettings = await backendApiCall({
      endpoint: "social/whatsapp",
      method: "POST",
      params: {
        endpoint: `/${selectedWhatsappPhone?.waba}/product_catalogs?catalog_id=${selectBusinessesAccountMeta}`,
      },
    });
    if (
      catalogSettings?.error?.error?.error_user_title?.includes(
        "Product Catalog Already Linked"
      )
    ) {
      return dispatch(
        setGlobalToast({
          show: true,
          message: `Product Catalog Already Linked to WhatsApp Business Account`,
          severity: "error",
        })
      );
    }
    for (const value in metaIntegartion) {
      let apps = metaIntegartion[value]?.connected || [];
      if (apps.includes("commerce")) {
        let obj = metaIntegartion[value];
        obj.select_commerce_catalogs = selectBusinessesAccountMeta;
        const res = await axios({
          url: `${website}/wp-json/store/v1/settings/meta_integration`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            type: "meta_integration",
            data: {
              [value]: obj,
            },
          },
          method: "PUT",
        });
        let { data } = res;

        if (isObjWithValues(data)) {
          dispatch(
            setGlobalToast({
              show: true,
              message: ` Update account successfully!`,
              severity: "success",
            })
          );
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              metaIntegration: data.data,
            },
          });
        }
      }
    }

    await backendApiCall({
      endpoint: "social/whatsapp",
      method: "POST",
      params: {
        endpoint: `/${whatsappObj.selected_whatsapp_phone_id}/whatsapp_commerce_settings?is_cart_enabled=true&is_catalog_visible=true`,
      },
    });
    setUpdatingBusinessAcount(false);
  };

  // const getAppIntegrationObj = (app) => {
  //   let obj = {};
  //   let key = "";
  //   for (const value in metaIntegartion) {
  //     console.log(metaIntegartion[value].connected);
  //     if (metaIntegartion[value].connected.includes(app)) {
  //       key = value;
  //       obj = { ...metaIntegartion[value] };
  //       console.log();
  //     }
  //     return {
  //       obj,
  //       key,
  //     };
  //   }
  // };
  const onFacebookPageChange = async (id) => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    if (!isObjWithValues(metaIntegartion)) return;
    if (!isArrayWithValues(pages)) return;

    let putData = {};
    let facebookData = getIntegrationObj(metaIntegartion, "facebook");
    let instagramData = getIntegrationObj(metaIntegartion, "instagram");
    console.log(facebookData, instagramData);
    // let subscribedAppRes = await getChannelData(
    //   "facebook",
    //   `/${id}/subscribed_apps?subscribed_fields=messages`,
    //   "POST"
    // );
    // return
    console.log();
    setPAgeChangeLoading(true);

    let facebookObj = {};

    if (isObjWithValues(facebookData?.obj)) {
      // [facebookData?.key].facebook_pages_info = pages.find((page) => page.id === id);
      putData = {
        ...putData,
        [facebookData?.key]: {
          ...facebookData?.obj,
          facebook_pages_info: pages.find((page) => page.id === id),
        },
      };
      // facebookObj = {
      //   [facebookData?.key]: { ...facebookData?.obj, facebook_pages_info: pages.find((page) => page.id === id) }
      // }

      // console.log("in facebook", putData);
    }
    let instagramobj = {};
    let instagramId = await getChannelData(
      "facebook",
      `/${id}?fields=instagram_business_account`
    );
    try {
      let response = await saveDatainDataBase(
        "instagram_account_id",
        instagramId?.instagram_business_account?.id
      );

      // let instagram_id = await saveDatainDataBase(`instagram_account_data_${instagramId?.instagram_business_account?.id}`, {

      // }
      // )
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    if (isObjWithValues(instagramData?.obj)) {
      try {
        let oldData = putData[instagramData?.key] || instagramData?.obj;
        putData = {
          ...putData,
          [instagramData?.key]: {
            ...oldData,
            instagram_business_id: instagramId?.instagram_business_account?.id,
          },
        };

        // console.log("in insta", putData);
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
    console.log(putData);

    // return
    if (!isObjWithValues(putData)) {
      setPAgeChangeLoading(false);
      return;
    }
    try {
      const res = await axios({
        url: `${website}/wp-json/store/v1/settings/meta_integration`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: {
          type: "meta_integration",
          data: {
            ...putData,
            // ...instagramobj,
          },
        },
        method: "PUT",
      });
      let { data } = res;
      console.log(data);
      if (data?.success) {
        setPAgeChangeLoading(true);
        try {
          //messages,message_echoes
          let subscribedAppRes = await getChannelData(
            "facebook",
            `/${id}/subscribed_apps?subscribed_fields=messages,message_echoes,feed,mention,message_reactions`,
            "POST"
          );
          if (isObjWithValues(instagramData?.obj)) {
            let instagramId = await getChannelData(
              "facebook",
              `/${id}?fields=instagram_business_account`
            );
            try {
              let response = await saveDatainDataBase(
                "instagram_account_id",
                instagramId?.instagram_business_account?.id
              );
              console.log(response);
            } catch (error) {
              console.log(error);
            }

            let instagramObj = {};
            let instagramKey = "";
            let integrationData = data?.data || {};
            for (const key in integrationData) {
              if (integrationData?.[key]?.connected?.includes("instagram")) {
                instagramObj = integrationData?.[key] || {};
                instagramKey = key;
                break;
              }
            }

            let instagramData = {
              [instagramKey]: {
                ...instagramObj,
                instagram_business_id:
                  instagramId?.instagram_business_account?.id,
              },
            };

            const resInstagram = await axios({
              url: `${website}/wp-json/store/v1/settings/meta_integration`,
              headers: {
                Authorization: `Basic ${token}`,
              },
              data: {
                type: "meta_integration",
                data: {
                  ...instagramData,
                  // ...instagramobj,
                },
              },
              method: "PUT",
            });
            data = resInstagram?.data;
          }

          // let { data } = res;

          // if (isObjWithValues(instagramData?.obj)) {
          //   try {

          //     let oldData = putData[instagramData?.key] || instagramData?.obj;
          //     putData = {
          //       ...putData,
          //       [instagramData?.key]: {
          //         ...oldData,
          //         instagram_business_id: instagramId?.instagram_business_account?.id,
          //       },
          //     };

          //     // console.log("in insta", putData);
          //   } catch (error) {
          //     console.log(getAxiosError(error));
          //   }
          // }

          console.log(subscribedAppRes);
        } catch (error) {
          console.log(error);
        }
        dispatch(
          setGlobalToast({
            show: true,
            message: ` Page Update successfully!`,
            severity: "success",
          })
        );
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            metaIntegration: data.data,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
    setPAgeChangeLoading(false);
  };

  const updateWhataAppId = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setUpdatingWhatsapp(true);
    let key = "";
    let obj = {};
    for (const value in metaIntegartion) {
      if (metaIntegartion[value].connected.includes("whatsapp")) {
        key = value;
        obj = metaIntegartion[value];
      }
    }
    obj.selected_whatsapp_phone_id = selectedWhatsapp.id;
    if (obj?.is_whatsapp_embed_signup && !obj.is_pin_regsiter) {
      try {
        await backendApiCall({
          endpoint: "social/whatsapp",
          method: "POST",
          params: {
            endpoint: `/${selectedWhatsapp.id}/register`,
          },
          data: {
            messaging_product: "whatsapp",
            pin: "231197",
          },
        });

        // await axios({
        //   url: `https://graph.facebook.com/${selectedWhatsapp.id}/register`,
        //   method: "POST",
        //   data: {
        //     messaging_product: "whatsapp",
        //     pin: "231197",
        //   },
        //   params: { access_token: obj?.access_token },
        // });
        obj.is_pin_regsiter = true;
      } catch (error) {}
    }
    try {
      await backendApiCall({
        endpoint: "social/whatsapp",
        method: "POST",
        params: {
          endpoint: `/${selectedWhatsapp?.waba}/subscribed_apps`,
        },
        // data: {
        //   messaging_product: "whatsapp",
        //   pin: "231197",
        // },
      });
      // let subscribedAppRes = await getChannelData(
      //   "facebook",
      //   `/${selectedWhatsapp?.waba}/subscribed_apps`,
      //   "POST"
      // );
      console.log(subscribedAppRes);
    } catch (error) {
      console.log(error);
    }
    try {
      if (getIsBackendMigrated("chats")) {
        let res = await settingsEndpoint({
          endpoint: "meta_integration",
          method: "PUT",
          data: {
            [key]: obj,
          },
        });
        if (res) {
          dispatch(
            setGlobalToast({
              show: true,
              message: `Whatsapp Seleted successfully!`,
              severity: "success",
            })
          );
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              metaIntegration: res,
            },
          });
        }
      } else {
        const res = await axios({
          url: `${website}/wp-json/store/v1/settings/meta_integration`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            type: "meta_integration",
            data: {
              [key]: obj,
            },
          },
          method: "PUT",
        });
        if (res?.data?.data) {
          dispatch(
            setGlobalToast({
              show: true,
              message: `Whatsapp Seleted successfully!`,
              severity: "success",
            })
          );
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              metaIntegration: res.data.data,
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    setUpdatingWhatsapp(false);
  };

  const updateWhatsappToken = async (access_token, wabaId) => {
    setUpdatingToken(true);
    try {
      let obj = getIntegrationObj(reduxMetaIntegration, "whatsapp")?.obj || {};
      if (!isObjWithValues(obj)) {
        const info = await fetchFacebookInfo(access_token);
        obj = {
          connected: ["whatsapp"],
          scope:
            "whatsapp_business_management,whatsapp_business_messaging,public_profile,business_management",
        };
        obj.basic_info = info;
      }
      let key =
        getIntegrationObj(reduxMetaIntegration, "whatsapp")?.key ||
        Math.round(Date.now() / 1000);
      let whatsappData = await getWhatsappDebug(access_token);

      if (whatsappData?.granular_scopes || wabaId) {
        let targetObj = whatsappData?.granular_scopes?.find(
          (i) => i?.scope === "whatsapp_business_management"
        );
        targetObj = isArrayWithValues(targetObj?.target_ids)
          ? targetObj
          : {
              scope: "whatsapp_business_management",
              target_ids: [wabaId],
            };
        // obj.whatsapp_info = targetObj.target_ids
        let batch = targetObj?.target_ids?.map((i) => ({
          method: "GET",
          relative_url: `/${i}/phone_numbers`,
        }));
        try {
          let res = await axios({
            url: `https://graph.facebook.com/v15.0/me`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
            params: {
              include_headers: false,
            },
            data: {
              batch,
            },
          });
          console.log(res);
          res = {
            ...res,
            data: res?.data?.filter((o) => !JSON.parse(o.body)?.error) || [],
          };
          if (isArrayWithValues(res.data)) {
            let whatsapp_info = res.data.map((i, index) => {
              let body = JSON.parse(i.body);
              let bodyData = body.data;
              let obj = {};
              if (isArrayWithValues(bodyData)) {
                obj = bodyData[0];
              }
              obj.waba = targetObj?.target_ids[index];
              return obj;
            });
            obj = { ...obj, whatsapp_info };
            // obj.whatsapp_info = [...whatsapp_info].map(i => i[0])
            console.log(obj);
            delete obj.selected_whatsapp_phone_id;
            // if()
            let meta_integration = await settingsEndpoint({
              endpoint: "meta_integration",
              method: "PUT",
              data: {
                [key]: {
                  ...obj,

                  via_access_token: true,
                  access_token,
                },
              },
            });
            if (meta_integration) {
              dispatch(
                setGlobalToast({
                  show: true,
                  message: "Whatsapp Access Token Added Successfully!",
                  severity: "success",
                })
              );
              dispatch({
                type: EDIT_SETTINGS,
                payload: {
                  metaIntegration: meta_integration,
                },
              });
            } else {
              dispatch(
                setGlobalToast({
                  show: true,
                  message: "Invalid Access Token!",
                  severity: "error",
                })
              );
            }
          }
        } catch (error) {
          console.log(getAxiosError(error));
          dispatch(
            setGlobalToast({
              show: true,
              message: "Invalid Access Token!",
              severity: "error",
            })
          );
        }
      } else {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Invalid Access Token!",
            severity: "error",
          })
        );
      }
    } catch (error) {
      console.log(error);
    }

    setUpdatingToken(false);
  };
  //   const getEmailPhoneFromIntegration  = (platform) => {
  //     let emailPhone = "";
  //     if(isObjWithValues(metaIntegartion)){
  //         for(let obj of Object.values(metaIntegartion)){
  //             if(platform === 'facebook' || platform === 'instagram')
  //             if(obj.basic_info && obj.basic_info.email) return obj.basic_info.email;
  //             else
  //         }
  //     }
  //   }
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <Box sx={{ height: "100%", paddingRight: "15px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
          paddingBottom: 0,
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <MetaSvg /> Meta
          </Typography>
          {fetchingMetaIntegration && (
            <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
              <CircularProgress size={"15px"} />{" "}
              <Typography>Fetching Integrations...</Typography>
            </Stack>
          )}
        </Stack>
        {/* <Divider sx={{ mt: 4 }} /> */}
        <Tabs
          onChange={(e, newValue) => {
            if (isOpen) setCurrentStep((state) => state + 1);
            setSelectedTab(newValue);
          }}
          variant="scrollable"
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          {/* <Tab label="How to do?" value="how_to_do" />
          <Tab label="Details" value="details" /> */}
          <Tab
            label="Integration"
            value="integration"
            className="Meta-integration-tab"
          />
          {isArrayWithValues(integratedApp) ? (
            <Tab
              label="Settings"
              value="settings"
              className="Meta-settings-tab"
            />
          ) : null}
          {/* {isArrayWithValues(integratedApp) ? (
            <Tab label="Analytics" value="analytics" />
          ) : null} */}
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"overview"}>
        <MetaOverview />
      </TabPanel>
      <TabPanel value={selectedTab} index={"how_to_do"}>
        <Typography>How to do</Typography>
      </TabPanel>
      <TabPanel value={selectedTab} index={"details"}>
        <Typography>Details</Typography>
      </TabPanel>
      <TabPanel value={selectedTab} index={"analytics"}>
        <MetaAnalytics />
      </TabPanel>

      <TabPanel
        value={selectedTab}
        index={"integration"}
        className="Meta-integrations-list"
      >
        {true ? (
          <MetaIntegrationView
            filterIntegration={filterIntegration}
            integratedApp={integratedApp}
            setRevokeApp={setRevokeApp}
            selectedScope={selectedScope}
            setSelectedScope={setSelectedScope}
            fetchingMetaIntegration={fetchingMetaIntegration}
            onEmbedPress={onEmbedPress}
            FacebookConfiguration={FacebookConfiguration}
          />
        ) : (
          <>
            <div>
              {(
                isArrayWithValues(filterIntegration)
                  ? filterIntegration.includes("facebook")
                  : true
              ) ? (
                <>
                  <Stack
                    direction="row"
                    // justifyContent={""}
                    alignItems="center"
                    mt={6}
                    sx={{ gap: "30px" }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                    >
                      <Facebook />
                      Facebook
                    </Typography>
                    <Switch
                      onChange={(e) => {
                        setSelectedScope((pre) => ({ ...pre, facebook: e }));
                      }}
                      checked={
                        integratedApp.includes("facebook") ||
                        selectedScope.facebook
                      }
                      disabled={
                        fetchingMetaIntegration ||
                        integratedApp.includes("facebook")
                      }
                      style={
                        selectedScope.facebook
                          ? {
                              border: `1px solid ${backgroundColor}`,
                              backgroundColor,
                            }
                          : {}
                      }
                    />
                    {integratedApp.includes("facebook") && (
                      <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() => {
                          setRevokeApp("facebook");
                        }}
                      >
                        Revoke
                      </Button>
                    )}
                  </Stack>
                  <Typography sx={{ mt: 1, marginBottom: "30px" }}>
                    Enables your jewellery business to communicate with your
                    customers through posts, messaging, etc 🌐
                  </Typography>
                  <Divider />
                </>
              ) : null}
              {(
                isArrayWithValues(filterIntegration)
                  ? filterIntegration.includes("instagram")
                  : true
              ) ? (
                <>
                  <Stack
                    direction="row"
                    // justifyContent={"space-between"}
                    sx={{ gap: "30px" }}
                    alignItems="center"
                    mt={6}
                  >
                    <Typography
                      variant="h6"
                      sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                    >
                      <InstagramSvg /> Instagram{" "}
                    </Typography>
                    <Switch
                      onChange={(e) => {
                        setSelectedScope((pre) => ({ ...pre, instagram: e }));
                      }}
                      checked={
                        integratedApp.includes("instagram") ||
                        selectedScope.instagram
                      }
                      disabled={
                        fetchingMetaIntegration ||
                        integratedApp.includes("instagram")
                      }
                      style={
                        selectedScope.instagram
                          ? {
                              border: `1px solid ${backgroundColor}`,
                              backgroundColor,
                            }
                          : {}
                      }
                    />
                    {integratedApp.includes("instagram") && (
                      <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() => {
                          setRevokeApp("instagram");
                        }}
                      >
                        Revoke
                      </Button>
                    )}
                  </Stack>
                  <Typography sx={{ mt: 1, marginBottom: "30px" }}>
                    Instantly Connect your Instagram and automate your Instagram
                    Management from one place 💭
                  </Typography>
                  <Divider />
                </>
              ) : null}
              {(
                isArrayWithValues(filterIntegration)
                  ? filterIntegration.includes("whatsapp")
                  : true
              ) ? (
                <>
                  <Stack
                    direction="row"
                    // justifyContent={"space-between"}
                    alignItems="center"
                    mt={6}
                    sx={{ gap: "30px" }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                    >
                      <WhatsappSvg /> Whatsapp
                    </Typography>
                    <Switch
                      onChange={(e) => {
                        setSelectedScope((pre) => ({ ...pre, whatsapp: e }));
                      }}
                      checked={
                        integratedApp.includes("whatsapp") ||
                        selectedScope.whatsapp
                      }
                      disabled={
                        fetchingMetaIntegration ||
                        integratedApp.includes("whatsapp")
                      }
                      style={
                        selectedScope.whatsapp
                          ? {
                              border: `1px solid ${backgroundColor}`,
                              backgroundColor,
                            }
                          : {}
                      }
                    />
                    {integratedApp.includes("whatsapp") && (
                      <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() => {
                          setRevokeApp("whatsapp");
                        }}
                      >
                        Revoke
                      </Button>
                    )}
                  </Stack>
                  <Typography sx={{ mt: 1 }}>
                    Increase your customer retention by keeping your customers
                    engaged with your jewellery brand 📨
                  </Typography>
                  <Button
                    startIcon={<YouTube />}
                    // sx={{ marginBottom: "20px" }}
                    onClick={() => {
                      dispatch(
                        setGlobalTutorial({
                          show: true,
                          url: tutorialData?.config_whatsapp?.link,
                          title: tutorialData?.config_whatsapp?.title,
                        })
                      );
                    }}
                  >
                    Watch Video
                  </Button>

                  <Divider />
                </>
              ) : null}
            </div>
            {(
              isArrayWithValues(filterIntegration)
                ? filterIntegration.includes("commerce")
                : true
            ) ? (
              <>
                <Stack
                  direction="row"
                  // justifyContent={"space-between"}
                  sx={{ gap: "30px" }}
                  alignItems="center"
                  mt={6}
                >
                  <Typography
                    variant="h6"
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <ShoppingCart
                      style={{
                        height: "22px",
                        width: "22px",
                        color: "#808080",
                      }}
                    />{" "}
                    Commerce
                  </Typography>
                  <Switch
                    onChange={(e) => {
                      setSelectedScope((pre) => ({ ...pre, commerce: e }));
                    }}
                    checked={
                      integratedApp.includes("commerce") ||
                      selectedScope.commerce
                    }
                    disabled={
                      fetchingMetaIntegration ||
                      integratedApp.includes("commerce")
                    }
                    style={
                      selectedScope.commerce
                        ? {
                            border: `1px solid ${backgroundColor}`,
                            backgroundColor,
                          }
                        : {}
                    }
                  />
                  {integratedApp.includes("commerce") && (
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => {
                        setRevokeApp("commerce");
                      }}
                    >
                      Revoke
                    </Button>
                  )}
                </Stack>
                <Typography sx={{ mt: 1, marginBottom: "30px" }}>
                  Market your items on Facebook. Attract potential customers to
                  your products 🛒
                </Typography>
                <Divider sx={{ mb: 3 }} />
              </>
            ) : null}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                position: "sticky",
                bottom: "12px",
                zIndex: 20,
              }}
            >
              <Button
                size="large"
                onClick={FacebookConfiguration}
                sx={{
                  borderRadius: "20px",
                  backgroundColor: "#1877F2",
                  ":hover": { backgroundColor: "#1877F2" },
                  mt: 3,
                }}
                startIcon={<FacebookTwoTone />}
                variant="contained"
                className={
                  Object.values(selectedScope).some(Boolean)
                    ? "Meta-integrations-button btn--jump"
                    : "Meta-integrations-button"
                }
              >
                Continue with Meta
              </Button>
            </Box>
          </>
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={"settings"}>
        {integratedApp.includes("facebook") ||
        integratedApp.includes("instagram") ? (
          <>
            <Stack
              sx={{ margin: "20px", maxWidth: "500px", mr: 0 }}
              className="facebook_page_select"
            >
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Typography variant="h4" sx={{ mb: 1 }}>
                  Facebook
                </Typography>
                <Stack direction={"row"} alignItems="center">
                  {gettingPages ? (
                    <Stack direction={"row"} gap={"7px"} alignItems="center">
                      {" "}
                      <CircularProgress size={"15px"} />{" "}
                      <Typography>Fetching Pages...</Typography>{" "}
                    </Stack>
                  ) : null}
                  {/* <Button>Refresh</Button> */}
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                alignItems="center"
                sx={{ marginTop: "10px" }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedPage}
                  // label="Pages"
                  size="small"
                  fullWidth
                  onFocus={() => {
                    setIsOpen(false);
                  }}
                  // error={

                  // }
                  sx={{
                    // minWidth: "300px",
                    ".MuiSelect-nativeInput": { borderRadius: "15px" },
                    // marginRight: "10px",
                  }}
                  // disabled={pagesDisabled}
                  onChange={(e) => setSelectedPage(e.target.value)}
                >
                  {pages.map((i) => {
                    return <MenuItem value={i.id}>{i.name}</MenuItem>;
                  })}
                </Select>
              </Stack>
              {integratedApp?.includes("instagram") &&
              Object.values(metaIntegartion || {})?.find((o) =>
                isObjWithValues(o.facebook_pages_info)
              ) &&
              isArrayWithValues(pages) &&
              !Object.values(metaIntegartion || {})?.find(
                (data) => data?.instagram_business_id
              ) ? (
                <Typography color={"error.main"} sx={{ ml: 2 }}>
                  Instagram is integrated but not connected with a Facebook Page
                </Typography>
              ) : null}
              <Stack
                sx={{ mt: 3 }}
                direction="row"
                justifyContent={"space-between"}
              >
                <Box>
                  {!!selectedPage && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        mt: 1.3,
                        ml: 1,
                      }}
                    >
                      Category:{" "}
                      {pages.find((page) => page.id === selectedPage)?.category}
                    </Typography>
                  )}
                </Box>
                <LoadingButton
                  size="small"
                  loading={pageChangeLoading}
                  onClick={() => onFacebookPageChange(selectedPage)}
                  variant="contained"
                >
                  Update
                </LoadingButton>
              </Stack>
            </Stack>
            <Divider />
          </>
        ) : null}

        {integratedApp.includes("whatsapp") && (
          <>
            <Stack
              sx={{ margin: "20px", marginBottom: 0, maxWidth: "500px", mr: 0 }}
              className="whatsapp_page_select"
            >
              <Stack
                justifyContent={"space-between"}
                direction="row"
                alignItems={"center"}
                sx={{
                  mb: 3,
                }}
              >
                <Typography variant="h4" sx={{}}>
                  Whatsapp
                </Typography>
              </Stack>
              <TempleteList
                open={showWhatsappTemplates}
                handleClose={() => setShowWhatsappTemplate(false)}
                // setParentTemplate={onSendBulkTemplate}
                // hideRejected
                viewOnly
              />
              <Dialog
                sx={dialogStyle}
                open={showWhatsappBotOptions}
                maxWidth="sm"
                fullWidth
                onClose={() => setShowWhatsappBotOptions(false)}
                {...globalPopupStyle}
              >
                <DialogContent>
                  <WhastappBotOptions
                    handleClose={() => setShowWhatsappBotOptions(false)}
                  />
                </DialogContent>
              </Dialog>
              <Dialog
                fullWidth
                maxWidth="md"
                sx={dialogStyle}
                open={showAssignTemplate}
                onClose={() => showShowAssignTemplate(false)}
                {...globalPopupStyle}
              >
                <AssignTemplate
                  whatsappTempletes={whatsappTempletes}
                  handleClose={() => showShowAssignTemplate(false)}
                />
              </Dialog>

              <Stack direction={"row"} justifyContent="space-between">
                <Typography
                  variant="h6"
                  sx={{ fontSize: "13px", color: "text.secondary" }}
                >
                  Business Number
                </Typography>
                {/* <Button onClick={getWhatsappDetails}>Refresh</Button> */}
              </Stack>
              <Stack
                direction={"row"}
                alignItems="center"
                sx={{ marginTop: "6px" }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedWhatsapp}
                  //   autoWidth
                  fullWidth
                  // label="Pages"
                  size="small"
                  sx={{
                    // maxWidth: "370px",
                    ".MuiSelect-nativeInput": { borderRadius: "15px" },
                    // marginRight: "10px",
                  }}
                  onFocus={() => {
                    setIsOpen(false);
                  }}
                  // disabled={pagesDisabled}
                  onChange={(e) => setSelectedWhatsapp(e.target.value)}
                >
                  {whatsappIds.map((i) => {
                    return (
                      <MenuItem
                        value={i}
                      >{`${i?.display_phone_number} ${i?.verified_name}`}</MenuItem>
                    );
                  })}
                </Select>

                {/* {!!selectedWhatsapp &&
                    selectedWhatsapp.code_verification_status ===
                    "NOT_VERIFIED" && (
                      <Button
                        variant="contained"
                        onClick={() => setShowVerify(true)}
                      >
                        Verify
                      </Button>
                    )} */}
              </Stack>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
                sx={{ mt: 2 }}
              >
                <Box>
                  {!!selectedWhatsapp &&
                    selectedWhatsapp.code_verification_status ===
                      "VERIFIED" && (
                      <Stack sx={{ marginTop: "10px", ml: 1 }}>
                        <Stack direction={"row"} alignItems="center">
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: "500" }}
                          >
                            {selectedWhatsapp.verified_name}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "12px",
                              color: "text.secondary",
                              ml: 2,
                            }}
                          >
                            <Verified
                              color="#00FF00"
                              sx={{
                                maxHeight: "14px !important",
                                maxWidth: "14px !important",
                                color: "#00FF00",
                                marginRight: "5px",
                              }}
                            />
                            Business Verified
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems="center">
                          <Typography
                            sx={{ fontSize: "11px", alignItems: "center" }}
                          >
                            <Typography
                              variant="caption"
                              sx={{ p: 0, fontWeight: "500" }}
                            >
                              Phone no:
                            </Typography>{" "}
                            {selectedWhatsapp?.display_phone_number}
                          </Typography>
                          <IconButton
                            sx={{ p: 0, ml: 1 }}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                selectedWhatsapp?.display_phone_number
                              );
                              dispatch(
                                setGlobalToast({
                                  show: true,
                                  message: "Phone number copied to clipboard",
                                  severity: "success",
                                })
                              );
                            }}
                          >
                            <ContentCopy
                              sx={{
                                height: "15px !important",
                                width: "15px !important",
                              }}
                            />
                          </IconButton>
                        </Stack>

                        {/* <Typography>Id : {selectedWhatsapp.id}</Typography> */}
                      </Stack>
                    )}
                </Box>
                <LoadingButton
                  loading={updatingWhatsapp}
                  onClick={updateWhataAppId}
                  variant="contained"
                  size="small"
                >
                  Update
                </LoadingButton>
              </Stack>
            </Stack>
            <Stack sx={{ maxWidth: "470px" }}>
              <Stack sx={{ mt: 5, mb: 2, marginLeft: "20px", mr: 0 }}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle2">
                    WhatsApp Templates
                  </Typography>
                  <Button
                    variant="outlined"
                    // sx={{
                    //   fontSize: "13px",
                    //   color: "text.secondary",
                    //   p: 0,
                    //   // cursor: "default",
                    // }}
                    disabled={!isObjWithValues(selectedWhatsapp)}
                    endIcon={<Article />}
                    size="small"
                    onClick={() => setShowWhatsappTemplate(true)}
                  >
                    Whatsapp Templates
                  </Button>
                </Stack>
                <Subtitle sx={{ color: "text.secondary" }}>
                  Whatsapp template messages are pre-written and pre-approved
                  messages created in advance which can be sent repeatedly to
                  your customers by your business.
                </Subtitle>
              </Stack>
              {/* <Stack spacing={2} sx={{ mt: 5, mb: 2, marginLeft: "20px" }}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle2">
                    WhatsApp Bot Setting
                  </Typography>
                  <Button
                    variant="outlined"
                    // sx={{
                    //   fontSize: "13px",
                    //   color: "text.secondary",
                    //   p: 0,
                    //   // cursor: "default",
                    // }}
                    disabled={!isObjWithValues(selectedWhatsapp)}
                    endIcon={<WhatsApp />}
                    size="small"
                    onClick={() => setShowWhatsappBotOptions(true)}
                  >
                    Whatsapp Bot Setting
                  </Button>
                </Stack>
                <Subtitle sx={{ color: "text.secondary" }}>
                  Whatsapp Bot allows you to Manage and Automate your daily
                  communications with your customers with the help of pre-set
                  options.
                </Subtitle>
              </Stack> */}

              {(user_products?.includes("chats_pro") ||
                storePlan?.plan === "growth") && (
                <Stack sx={{ mt: 5, mb: 2, marginLeft: "20px" }}>
                  <Stack
                    justifyContent="space-between"
                    direction={"row"}
                    alignItems={"center"}
                  >
                    <Typography variant="subtitle2">
                      Automatic Templates
                    </Typography>{" "}
                    <Button
                      variant="outlined"
                      // sx={{
                      //   fontSize: "13px",
                      //   color: "text.secondary",
                      //   p: 0,
                      //   // cursor: "default",
                      // }}
                      disabled={!isObjWithValues(selectedWhatsapp)}
                      endIcon={<AssignmentTurnedIn />}
                      size="small"
                      onClick={() => showShowAssignTemplate(true)}
                    >
                      Assign Templates
                    </Button>
                  </Stack>
                  <Subtitle sx={{ color: "text.secondary" }}>
                    Assign an existing template or a template that is customised
                    for each event so that you may provide your consumers a
                    personalised experience.
                  </Subtitle>
                </Stack>
              )}
            </Stack>
            <Divider sx={{ my: 3 }} />
          </>
        )}
        {(user_products?.includes("chats_pro") ||
          storePlan?.plan === "growth") && (
          <>
            {integratedApp.includes("commerce") && (
              <>
                <Box sx={{ maxWidth: "500px", margin: "20px" }}>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ gap: "18px", minHeight: "30px" }}
                  >
                    <Typography variant="h6">Commerce</Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems="center">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectBusinessesAccountMeta}
                      fullWidth
                      // label="Pages"
                      size="small"
                      sx={{
                        // minWidth: "300px",
                        ".MuiSelect-nativeInput": { borderRadius: "15px" },
                        // marginRight: "10px",
                      }}
                      // disabled={pagesDisabled}
                      onChange={(e) =>
                        setSelectBusinessesAccountMeta(e.target.value)
                      }
                    >
                      {businessesAccountMeta.map((i) => {
                        return (
                          <MenuItem value={i.id}>
                            {i.name} -{" "}
                            <Typography
                              variant="subtitle2"
                              sx={{ display: "inline" }}
                            >
                              {i.id}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                      {!isArrayWithValues(businessesAccountMeta) ? (
                        <MenuItem disabled>
                          <em>No account found</em>
                        </MenuItem>
                      ) : null}
                    </Select>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"flex-end"}
                    sx={{ mt: 3 }}
                  >
                    {/* <LoadingButton
                    size="small"
                    loading={refreshingApp === "firebase"}
                    onClick={() => getFirebaseAccount()}
                    startIcon={<Refresh />}
                  >
                    Refresh Account
                  </LoadingButton> */}
                    <LoadingButton
                      size="small"
                      loading={updatingBusinessAccount}
                      onClick={onBusinessAccountUpdate}
                      variant="contained"
                    >
                      Update
                    </LoadingButton>
                  </Stack>
                </Box>
              </>
            )}
          </>
        )}
      </TabPanel>

      <Dialog
        open={showPageIntegrationWarning}
        onClose={() => setShowPageIntegrationWarning(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPageIntegrationWarning(false)}>
            Disagree
          </Button>
          <Button
            onClick={() => setShowPageIntegrationWarning(false)}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {/* <CreateTemplete
        open={openCreateTemplete}
        close={() => setopenCreateTemplete(false)}
      /> */}
      {/* <Box sx={{ mt: 5 }}>{integrationView}</Box> */}
      {
        // showSwitch
        false && (
          <Box
            sx={{
              mt: 6,
              border: "0.7px dotted #fa675c",
              py: 3,
              px: 3,
              borderRadius: "6px",
            }}
          >
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Typography fontWeight={"600"}>Revoke Meta</Typography>
              <Button
                // onClick={() => revokePress()}
                color="error"
                size="small"
                variant="outlined"
              >
                Revoke
              </Button>
            </Stack>
          </Box>
        )
      }
      <Dialog
        open={Boolean(revokeApp)}
        onClose={() => setRevokeApp(false)}
        sx={dialogStyle}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to revoke?
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setRevokeApp(false)} >Cancel</Button> */}
          <LoadingButton
            loading={revoking}
            autoFocus
            onClick={() => {
              revokeMeta(revokeApp);
            }}
            variant="contained"
            color="error"
          >
            Revoke
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <VerifyPopUp
        selectedWhatsapp={selectedWhatsapp}
        showVerify={showVerify}
        setShowVerify={setShowVerify}
        metaIntegartion={metaIntegartion}
      />
    </Box>
  );
};

export default Meta;

const Subtitle = styled(Typography)`
  font-size: 11px;
  font-weight: 400;
  max-width: 450px;
  margin-top: 8px;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

const VerifyPopUp = ({
  selectedWhatsapp,
  showVerify,
  setShowVerify,
  metaIntegartion,
}) => {
  const [otp, setOtp] = useState("");
  const [ShowOtp, setShowOtp] = useState(false);
  const [viaOtp, setViaOtp] = useState("");
  let access_token = "";
  const sentOtp = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setShowOtp(true);
    for (const value in metaIntegartion) {
      if (metaIntegartion[value].connected.includes("whatsapp")) {
        access_token = metaIntegartion[value].access_token;
      }
    }
    try {
      const res = await axios({
        url: `https://graph.facebook.com/v14.0/${selectedWhatsapp.id}/request_code`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: {
          code_method: viaOtp,
          locale: "en_US",
          language: "en_US",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const verifyOtp = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    try {
      const res = await axios({
        url: `https://graph.facebook.com/v14.0/${selectedWhatsapp.id}/request_code`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        data: {
          code: otp,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={Boolean(showVerify)}
      onClose={() => setShowVerify(false)}
      sx={dialogStyle}
    >
      <DialogTitle id="alert-dialog-title">
        Verify whatsapp ?{" "}
        <Typography>{selectedWhatsapp?.display_phone_number}</Typography>
      </DialogTitle>
      <DialogContent sx={{ marginTop: "5px" }}>
        {!ShowOtp && (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue="SMS"
            name="radio-buttons-group"
            row
            value={viaOtp}
            onChange={(e) => {
              setViaOtp(e.target.value);
            }}
          >
            <FormControlLabel value="SMS" control={<Radio />} label="Via SMS" />
            <FormControlLabel
              value="VOICE"
              control={<Radio />}
              label="Via Voice"
            />
          </RadioGroup>
        )}
        {ShowOtp && (
          <TextField
            label="OTP"
            onChange={(e) => {
              setOtp(e.target.value);
            }}
          />
        )}

        {/* <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowVerify(false)}>Cancel</Button>
        {!ShowOtp && (
          <Button variant="contained" onClick={sentOtp}>
            Send OTP
          </Button>
        )}
        {ShowOtp && (
          <Button variant="contained" onClick={verifyOtp}>
            Verify
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const WhastappBotOptions = ({ handleClose }) => {
  const onlyChats = useSelector((state) => state.helper.onlyChats);
  let defaultOptions = [
    {
      label: "Today's Gold Rate",
      value: "todays_rate",
      description:
        "Use thisIf the option is chosen from the menu, the bot will instantly share Today’s Gold Rate.",
    },
    {
      label: "Explore Our Collection",
      value: "explore_collections",
      description:
        "If the option is chosen from the menu, the bot will instantly share the catalog link.",
    },
    {
      label: "Get Personalized Catalog",
      value: "get_catalogs",
      description:
        "Customers can create their own catalogue in the chat itself if this option is enabled.",
    },
    // {
    //   label: "Find similar design from Image ",
    //   value: "image_search",
    //   description:
    //     "Automatically send related products based on the customer-shared product image.",
    //   disabled: true,
    // },
    // {
    //   label: "Show me Offers",
    //   value: "show_offers",
    //   description:
    //     "When Selected, Customers will receive Pre-set Personalised Offers automatically.",
    // },
  ];

  const [options, setOptions] = useState(defaultOptions);
  const [botState, setBotState] = useState(false);
  const [seletedOption, setSelectedOption] = useState([]);
  const [startMessage, setStartMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  const reduxBotSettings = useSelector(
    (state) => state.settings.whatsappBotSettings
  );

  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  useEffect(() => {
    if (isObjWithValues(reduxBotSettings)) {
      setBotState(reduxBotSettings?.enable);
      let arr = reduxBotSettings?.options || [];
      setSelectedOption([...new Set(arr)]);
      setStartMessage(reduxBotSettings?.start_message || []);
    }
  }, [reduxBotSettings]);

  const onCheckboxChanage = (val) => {
    if (!botState) return;
    if (val === "image_search") return;
    if (seletedOption.includes(val)) {
      let arr = seletedOption.filter((v) => v !== val);

      setSelectedOption([...new Set(arr)]);
    } else {
      setSelectedOption((pre) => [...new Set([...pre, val])]);
    }
  };
  useEffect(() => {
    let options = defaultOptions;
    if (isArrayWithValues(user_products)) {
      if (onlyChats) {
        options = defaultOptions.filter((obj) => obj?.value === "todays_rate");
      }

      if (user_products?.length > 1) {
        if (user_products?.includes("catalog")) {
          options = defaultOptions.filter(
            (obj) => obj?.value !== "show_offers"
          );
        }
        if (user_products?.includes("e-commerce")) {
          options = defaultOptions.filter(
            (obj) => obj?.value !== "get_catalogs"
          );
        }
      }
    }
    setOptions(options);
  }, [user_products]);

  const onSubmit = async () => {
    let website = getWebsite();
    let token = getToken();

    if (!website && !token) return false;
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/whatsapp_botsettings`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "PUT",
      data: {
        settings: "whatsapp_botsettings",
        data: {
          enable: botState,
          options: [...new Set(seletedOption)],
          start_message: startMessage,
        },
      },
    });

    if (res?.data?.success) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Submit succesfully",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          whatsappBotSettings: res?.data?.data,
        },
      });
      handleClose();
    }
    setLoading(false);
  };
  return (
    <>
      <Stack>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h6">Enable WhatsApp Bot</Typography>
          <Switch
            onChange={(e) => {
              setBotState(e);
            }}
            checked={botState}
            style={
              botState
                ? {
                    border: `1px solid ${backgroundColor}`,
                    backgroundColor,
                  }
                : {}
            }
          />
        </Stack>
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
            mt: 2,
          }}
        >
          {options.map((obj) => {
            const labelId = `checkbox-list-label-${obj.value}`;

            return (
              <ListItem key={obj.value}>
                <Stack
                  direction={"row"}
                  sx={{ gap: "0px", cursor: "pointer" }}
                  alignItems="center"
                  onClick={() => onCheckboxChanage(obj.value)}
                >
                  <Checkbox
                    disabled={!botState}
                    edge="start"
                    checked={seletedOption.includes(obj.value)}
                    onChange={() => onCheckboxChanage(obj.value)}
                    // tabIndex={-1}
                    disableRipple
                    label={obj.label}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                  <Stack>
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Typography>{obj.label}</Typography>
                      {obj?.disabled ? (
                        <Chip size="small" label={"Coming soon"} />
                      ) : null}
                    </Stack>
                    <FormHelperText>{obj.description}</FormHelperText>
                  </Stack>
                </Stack>
                {/* <ListItemIcon>
                    <Checkbox
                      disabled={!botState}
                      edge="start"
                      checked={seletedOption === obj.value}
                      onChange={() => setSelectedOption(obj.value)}
                      // tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${obj.label}`} /> */}
              </ListItem>
            );
          })}
        </List>
        <Box>
          <Typography variant="subtitle2">Start Message</Typography>
          <Autocomplete
            multiple
            size="small"
            id="tags-filled"
            options={[].map((option) => option.title)}
            // defaultValue={[top100Films[13].title]}
            value={startMessage}
            freeSolo
            onChange={(e, newValue) => setStartMessage(newValue)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                // variant="filled"
                // label="freeSolo"
                placeholder="Start Message"
                helperText={"Hit Enter to submit"}
              />
            )}
          />
        </Box>
      </Stack>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={loading} onClick={onSubmit} variant="contained">
          Submit
        </LoadingButton>
      </DialogActions>
    </>
  );
};

const infoTooltip = {
  birthday: [
    "Customers will receive the assigned template message on their Birthday automatically required their Birthdate is added in CRM.",
    "Assign any pre-approved Birthday Template once or update it as required 🎉",
  ],
  anniversary: [
    "Customers will receive the assigned template message on their Anniversary automatically required their Anniversary date is added in CRM.",
    " Assign any pre-approved Anniversary Template once or update it as required 🎊",
  ],
  abandoned_cart: [
    "To motivate customers who have added a product to their cart but haven't made a purchase on your website, you can automatically send them template messages encouraging them to buy the product on your website or app 🛒",
  ],
  abandoned_wishlist: [
    "You can use Automatic Templates to send automated messages to customers who add products to their wishlist but do not purchase.",
    "These messages can encourage customers to buy, improving sales and engagement with your brand 💗",
  ],
  order_update: [
    "Upon placing an order on your website, the customer will immediately receive automatic templates containing their order information on whatsapp🛍️",
  ],
  order_note: [
    "If Jeweller adds a note about a specific order, the customer will get a message on WhatsApp that has the note automatically.💼",
  ],
  registration: [
    "By assigning a template message, customers who register on your website can receive an automated message as soon as they register 🤳",
  ],
  digital_gold_reminder: [
    "Automated reminders will be sent to customers via WhatsApp prior to their due dates, based on the assigned template.⌚",
  ],
  digital_monthly_ledger: [
    "Customers who have enrolled in your digi gold plan will receive a Monthly Ledger Template Message that you have assigned, automatically.🧾",
  ],
  catalog_sent: [
    "Once you create a private catalog, Jwero will automatically send the assigned catalog message with the link to your Contact.",
  ],
  catalog_remainder: [
    "Send a reminder to your contact to checkout the products in the catalog sent to before it expires.",
  ],
};

const AssignTemplate = ({ handleClose, whatsappTempletes }) => {
  const allTemplates = [
    { label: "Birthday", value: "birthday" },
    { label: "Anniversary", value: "anniversary" },
    // { label: "Catalog", value: "catalog_sent" },
    // { label: "Catalog Reminder", value: "catalog_reminder" },
    // { label: "Abandoned cart", value: "abandoned_cart" },
    // { label: "Abandoned wishlist", value: "abandoned_wishlist" },
    { label: "Order update", value: "order_update" },

    { label: "Order note", value: "order_note" },
    { label: "Registration", value: "registration" },
    { label: "Digital Gold Reminder", value: "digital_gold_reminder" },
    // { label: "Digital Monthly Ledger", value: "digital_monthly_ledger" },
  ];
  const chatTemplates = ["birthday", "anniversary"];
  const dispatch = useDispatch();
  const whatsappTemplateSelectRef = useRef();
  const whatsappAssignedTemplates = useSelector(
    (state) => state.settings.whatsappAssignedTemplates
  );
  const user_products = useSelector((state) => state.user.user_products);

  const reduxWhatsappTemplete = useSelector(
    (state) => state.chats.whatsappTempletes
  );
  const [arr, setArr] = useState([]);

  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showWhatsappTemplates, setShowWhatsappTempletes] = useState(false);
  const [selectedTemplateValue, setSelectedTemplateValue] = useState("");

  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    if (!isArrayWithValues(reduxWhatsappTemplete)) dispatch(getTempletes());
  }, []);
  useEffect(() => {
    if (isArrayWithValues(whatsappTempletes)) {
      let templateArray = [];
      for (let temp of whatsappTempletes || []) {
        let headerObj = temp?.components?.find((com) => com?.type === "HEADER");
        if (isObjWithValues(headerObj) && headerObj?.format !== "TEXT")
          continue;
        templateArray.push(temp);
      }
      // let filtered = whatsappTempletes?.filter(obj => obj?.components?.find(com => com?.type === "HEADER")?.format !== )
      setTemplates(templateArray);
    }
  }, [whatsappTempletes]);
  useEffect(() => {
    if (isObjWithValues(whatsappAssignedTemplates))
      setValues({ ...whatsappAssignedTemplates });
  }, [whatsappAssignedTemplates]);

  useEffect(() => {
    if (
      isArrayWithValues(user_products) &&
      user_products.length === 1 &&
      user_products.includes("chats")
    )
      setArr(allTemplates.filter((i) => chatTemplates.includes(i.value)));
    else setArr(allTemplates);
  }, [user_products]);

  const [values, setValues] = useState({
    birthday: "",
    anniversary: "",
    abandoned_cart: "",
    abandoned_wishlist: "",
    order_update: "",
    order_note: "",
    registration: "",
    digital_gold_reminder: "",
    digital_monthly_ledger: "",
  });
  const onFormEdit = (payload) => {
    setValues((pre) => ({ ...pre, ...payload }));
  };
  const onSubmit = async () => {
    console.log(values);
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/whatsapp_assigned_templates`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "PUT",
      data: {
        settings: "whatsapp_assigned_templates",
        data: {
          ...values,
        },
      },
    });
    console.log(res);
    if (res?.data?.success) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Submit succesfully",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          whatsappAssignedTemplates: res?.data?.data,
        },
      });
    }
    setLoading(false);
  };

  const onWhatsappTemplatesClose = () => {
    setShowWhatsappTempletes(false);
  };

  const onShowTemplate = (value) => {
    setSelectedTemplateValue(value);
    setShowWhatsappTempletes(true);
  };

  const onTemplateSelect = (template) => {
    console.log(template, "<<<<<onTemplateSelect");
    setValues((state) => ({
      ...state,
      [selectedTemplateValue]: {
        ...(typeof state[selectedTemplateValue] === "object"
          ? state[selectedTemplateValue]
          : {}),
        template: template,
      },
    }));
    onWhatsappTemplatesClose();
  };

  const onDeleteTemplate = (value) => {
    setValues((state) => ({
      ...state,
      [value]: { ...state[value], template: false },
    }));
  };

  return (
    <Box>
      <DialogTitle>Assign automatic templates</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          {arr.map((v) => {
            return (
              <Card sx={{ padding: 1 }} key={v?.value}>
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      // gap: 3,
                      ...primaryLabel,
                    }}
                  >
                    {v.label}

                    {/* <PopperTooltip points={infoTooltip?.[v.value] || []} /> */}
                  </Typography>
                  <HorizontalStack spacing={2}>
                    <HorizontalStack spacing={3}>
                      {/* <Button size="small">Edit</Button>
                      <Button size="small" color="error">
                        Delete
                      </Button> */}
                      {values?.[v?.value]?.template?.name && (
                        <>
                          <IconButton
                            onClick={() => onShowTemplate(v?.value)}
                            size="small"
                            color="primary"
                          >
                            <EditOutlined />
                          </IconButton>
                          <IconButton
                            onClick={() => onDeleteTemplate(v?.value)}
                            size="small"
                            color="error"
                          >
                            <DeleteOutline />
                          </IconButton>
                        </>
                      )}
                    </HorizontalStack>
                    {values?.[v?.value]?.template?.name ? (
                      <Button
                        variant="outlined"
                        onClick={() => onShowTemplate(v?.value)}
                        disabled
                      >
                        {formatServerValue(values?.[v?.value]?.template?.name)}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={() => onShowTemplate(v?.value)}
                      >
                        Select Whatsapp Template
                      </Button>
                    )}
                  </HorizontalStack>
                  {/* <Select
                    sx={{ minWidth: isIpad ? 200 : 250 }}
                    size="small"
                    value={values[v.value]}
                    onChange={(e) => {
                      onFormEdit({ [v.value]: e.target.value });
                    }}
                    endAdornment={
                      <IconButton
                        sx={{
                          display: values[v.value] ? "block" : "none",
                          mr: 2,
                          mt: "5px",
                        }}
                        onClick={() => onFormEdit({ [v.value]: "" })}
                        size={"small"}
                      >
                        <Clear style={{ height: "18px", width: "18px" }} />
                      </IconButton>
                    }
                    MenuProps={{ sx: { maxHeight: 240 } }}
                  >
                    {templates?.map((o) => (
                      <MenuItem value={o.name}>{o.name}</MenuItem>
                    ))}
                  </Select> */}
                </Stack>

                <Typography
                  sx={{
                    ...(isIpad ? secondaryLabelSmall : secondaryLabel),
                    mt: 1.5,
                    maxWidth: isIpad ? "100%" : "67%",
                  }}
                >
                  {infoTooltip?.[v.value]}
                </Typography>
              </Card>
            );
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton onClick={onSubmit} loading={loading}>
          Assign
        </LoadingButton>
      </DialogActions>
      <TempleteList
        open={showWhatsappTemplates}
        handleClose={onWhatsappTemplatesClose}
        setParentTemplate={onTemplateSelect}
        hideRejected
        // asBox={true}
        ref={whatsappTemplateSelectRef}
        // setShowTemplateButton={setShowTemplateButton}
        newMediaLibrary={true}
        showAllVariables
      />
    </Box>
  );
};
