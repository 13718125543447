import { Tooltip } from "@mui/material";

const CustomTooltip = ({ children, title, position, fontBold }) => {
  return (
    <Tooltip
      arrow
      title={title}
      placement={position}
      componentsProps={{
        tooltip: {
          sx: {
            // backgroundColor: "#fff",
            // color: "#000",
            backgroundColor: "rgba(0,0,0,0.7)",
            color: "#fff",
            fontSize: "12px",
            fontFamily: "Inter",
            padding: "8px",
            fontWeight: fontBold,
          },
        },
        arrow: {
          sx: {
            color: "rgba(0,0,0,0.7)",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
