import { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  DataSheetGrid,
  checkboxColumn,
  textColumn,
  keyColumn,
  DynamicDataSheetGrid,
} from "react-datasheet-grid";
import * as XLSX from "xlsx";
// Import the style only once in your app!
import "react-datasheet-grid/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  convertDataToDatasheetColumn,
  getDefaultProductObject,
  getDropdownData,
  validateCreatedUpdatedProducts,
  validateProducts,
  columns as allColumns,
  products_keys_mapping,
} from "./utils";
import "../index.css";
import {
  ResponsiveStackRow,
  addFileAws3,
  formatDate,
  getAdminName,
  getAuthToken,
  getEmail,
  getId,
  getStoreId,
  getStoreName,
  getTenantId,
  getToken,
  getWebSocketUrl,
  getWebsite,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  isObjectEqual,
  isOurWebsite,
  primaryLabel,
  primaryLabelLarge,
  secondaryLabel,
  secondaryLabelSmall,
  validateNumber,
} from "../../../helper";
import { createSpreadsheetData } from "./helper";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Fade,
  FormControlLabel,
  IconButton,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  getProductObjFromSheetRow,
  onCreateProduct,
  updateProductsFromSheet,
} from "./helper/updateData";
import { LoadingButton } from "@mui/lab";
import { ErrorOutline, Sync, ViewWeekOutlined } from "@material-ui/icons";
import UpdateDialog from "../helper/UpdateDialog";
import {
  fetchAllProducts,
  fetchTaxonomies,
} from "../../../redux/actions/productActions";
import ColumnsPopover, {
  defaultSelectedColumns,
} from "../helper/ColumnsPopover";
import ErrorsPopover from "../helper/ErrosPopover";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import { editDataSheet } from "../../../redux/actions/datasheetActions";
import { getIp, sidebarExtraWidth } from "../../../App";
import MapMediaDialog from "./components/MapMediaDialog";
import {
  arePropsEqual,
  deepStringifyValues,
  removeFalsyValuesFromObject,
} from "../../../utils/js";
import { singleSizeMasters } from "../../products/AddProduct";
import { productDefaultParams } from "../../products/AllProducts";
import { setPersistData } from "../../../redux/actions/persistActions";
import axios from "axios";
import {
  ArrowBackIosNew,
  ArrowDownward,
  ArrowUpward,
  History,
  HistoryEduRounded,
  North,
} from "@mui/icons-material";
import JweroSyncHistory from "../../products/components/JweroSyncHistory";
import ImportDialog from "./components/ImportDialog";
import ExportDialog from "./components/ExportDialog";
import { UAParser } from "ua-parser-js";
import {
  ErrorLabelExtraSmall,
  SecondaryLabelSmall,
} from "../../../helper/utility/Labels";
import { EDIT_SETTINGS } from "../../../redux/actions/settingActions";
import {
  originalProductsHeader,
  validateSheetHeaders,
} from "../helper/validateSheetHeaders";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const ProductDataSheet = () => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const updateDialogRef = useRef();
  const columnsPopoverRef = useRef();
  const mapMediaRef = useRef();
  const errorsPopoverRef = useRef();
  const [loadingText, setLoadingText] = useState("");
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const theme = useTheme();
  const buttonColor = "#cdcccc";

  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [openImport, setOpenImport] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const sizeMasters = useSelector((state) => state.settings.sizeMastersNew);
  const taxonomies = useSelector((state) => state.product.taxonomies);
  const selectedProductColumns = useSelector(
    (state) => state.persist.selectedProductColumns
  );
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );

  const allDiamondGroups = useSelector(
    (state) => state.settings.allDiamondGroups
  );
  const storePlan = useSelector((state) => state.user.store_plan);
  const storeUsed = useSelector((state) => state.user.store_used);
  const jweroSheetSync = useSelector(
    (state) => state?.settings?.jweroSheetSync
  );
  const [cellhasError, setCellHasError] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    // ...defaultSelectedColumns.products,
    ...(selectedProductColumns || {}),
  });
  const showProductSheetInstructions = useSelector(
    (state) => state.persist.showProductSheetInstructions
  );
  const bulkProducts = useSelector((state) => state.datasheet.products);
  const products_backup = useSelector(
    (state) => state.datasheet.products_backup
  );
  const edit_products_sheet_backup = useSelector(
    (state) => state.datasheet.edit_products_sheet_backup
  );
  const create_products_sheet_backup = useSelector(
    (state) => state.datasheet.create_products_sheet_backup
  );
  const partiallyUpdate = useSelector((state) => state.persist.partiallyUpdate);
  const [defaultProductObject, setDefaultProductObject] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const location = useLocation();
  const datasheetRef = useRef();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  let { hash } = location;

  const [errors, setErrors] = useState([]);
  const [remainingProducts, setRemainingProducts] = useState(0);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [sizesDropdown, setSizesDropdown] = useState({});
  const [showSync, setShowSync] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editProductsMounted, setEditProductsMounted] = useState(false);
  let showErrorTimeout, backupTimeout, savingTimeout;
  const productDefaults = useSelector(
    (state) => state.settings.productDefaults
  );
  const [exporting, setExporting] = useState(false);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const [allDropdowns, setAllDropdowns] = useState({});

  const inputRef = useRef(null);
  const closeSync = () => {
    setShowSync(false);
  };

  useEffect(() => {
    if (hash?.includes("openImport")) {
      setOpenImport(true);
      navigate("/products/bulk_add");
    }

    return () => {};
  }, [hash]);

  useEffect(() => {
    if (!taxonomies) dispatch(fetchTaxonomies());
  }, []);

  useEffect(() => {
    let sizesDropdown = { ...singleSizeMasters };
    if (isObjWithValues(sizeMasters)) {
      for (let size in sizeMasters) {
        let array = [];
        if (isArrayWithValues(sizeMasters[size]?.sizes))
          array = sizeMasters[size]?.sizes?.map((i) => ({
            ...i,
            value: i.label,
          }));
        sizesDropdown[size] = array;
      }
    }
    setSizesDropdown(sizesDropdown);
  }, [sizeMasters]);

  useEffect(() => {
    if (location.pathname.includes("products")) {
      // if (
      //   isUpdate &&
      //   isArrayWithValues(edit_products_sheet_backup) &&
      //   !isArrayWithValues(bulkProducts)
      // )
      //   setData(edit_products_sheet_backup);
      // else

      if (
        isArrayWithValues(create_products_sheet_backup)
        // && !isArrayWithValues(bulkProducts)
      )
        setData(create_products_sheet_backup);
    }
  }, [location.pathname, isUpdate, create_products_sheet_backup]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // if (isDev()) return;
      event.preventDefault();
      event.returnValue = ""; // Chrome requires this
      dispatch(editDataSheet({ create_products_sheet_backup: null }));
      console.log("called");
      setIsDataSaved(true);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // window.removeEventListener("popstate", handleBeforeUnload);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (isUpdate) return;
    if (backupTimeout) clearTimeout(backupTimeout);
    backupTimeout = setTimeout(() => {
      let key = isUpdate
        ? "edit_products_sheet_backup"
        : "create_products_sheet_backup";
      // dispatch(editDataSheet({ [key]: data }));
      if (isArrayWithValues(data))
        dispatch(editDataSheet({ create_products_sheet_backup: data }));
      setSaving(true);
      if (savingTimeout) clearTimeout(savingTimeout);
      savingTimeout = setTimeout(() => {
        setSaving(false);
      }, 1000);
    }, 3000);
    return () => {
      if (savingTimeout) clearTimeout(savingTimeout);
      if (backupTimeout) clearTimeout(backupTimeout);
    };
  }, [data]);

  useEffect(() => {
    let { add_products, catalogs } = storePlan || {};
    let { products, catalogs: catalogs_created } = storeUsed || {};
    setRemainingProducts(
      validateNumber(add_products) - validateNumber(products)
    );
    // if (products && add_products) setAddLimitReached(products >= add_products);
    // if (catalogs && catalogs_created)
    //   setCatalogsLimitReached(catalogs_created >= catalogs);
  }, [storePlan, storeUsed]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    // if (!reduxOrnateProducts) dispatch(fetchOrnateProducts());
    if (isObjWithValues(selectedProductColumns))
      setSelectedColumns(selectedProductColumns);
  }, [isObjWithValues(selectedProductColumns)]);

  useEffect(() => {
    dispatch(setPersistData({ selectedProductColumns: selectedColumns }));
  }, [selectedColumns]);

  useEffect(() => {
    if (
      location.pathname?.includes("products/bulk_add") &&
      isObjWithValues(productDefaults) &&
      isObjWithValues(taxonomies)
    ) {
      let defaultObj = getDefaultProductObject({
        defaults: productDefaults,
        taxonomies,
      });
      if (defaultObj) {
        setDefaultProductObject(defaultObj);
        if (
          remainingProducts &&
          !isArrayWithValues(create_products_sheet_backup)
        )
          setData(new Array(50).fill(defaultObj));
      }
    }
  }, [productDefaults, taxonomies, location.pathname, remainingProducts]);
  useEffect(() => {
    let dropdowns = getDropdownData({
      productSettings,
      masterPricing,
      taxonomies,
      sizeMasters,
      sizesDropdown,
    });
    setAllDropdowns(dropdowns);

    setColumns(
      convertDataToDatasheetColumn({
        dropdowns,
        selectedColumns,
        disableSKU: isUpdate,
        sizesDropdown,
        productSettings,
      })
    );
    // if (location.pathname?.includes("products/bulk_edit")) {
    // }
  }, [productSettings, masterPricing, taxonomies, selectedColumns]);

  useEffect(() => {
    setIsUpdate(location.pathname?.includes("products/bulk_edit"));
    if (
      isArrayWithValues(bulkProducts) &&
      location.pathname?.includes("products/bulk_edit") &&
      !editProductsMounted
    ) {
      setTimeout(() => {
        setData(
          createSpreadsheetData({
            products: bulkProducts,
            taxonomies,
            productSettings,
          })
        );
        setEditProductsMounted(true);
      }, 700);
    }
  }, [bulkProducts, allDropdowns, productSettings, taxonomies]);

  useEffect(() => {
    if (!partiallyUpdate) {
      setSelectedColumns({
        ...selectedProductColumns,
        sku: true,
        stock_status: true,
      });
    }
  }, [partiallyUpdate]);

  const onCloseImport = () => {
    setOpenImport(false);
  };
  const onCloseExport = () => {
    setOpenExport(false);
  };

  const onUpdate = () => {
    let filteredData = data?.filter(
      (i) =>
        !isObjectEqual(i, defaultProductObject) &&
        isObjWithValues(removeFalsyValuesFromObject(i))
    );

    if (!isArrayWithValues(filteredData)) {
      return dispatch(
        setGlobalToast({
          show: true,
          message: `No details added`,
          severity: "error",
        })
      );
    }

    // console.log(
    //   filteredData
    //     ?.map((o) => o?.sku)
    //     ?.splice(0, 100)
    //     ?.join()
    // );
    let invalidProducts;
    if (!partiallyUpdate) {
      invalidProducts = validateProducts({
        columns,
        products: data,
        defaultProductObject,
        sizesDropdown,
        dropdowns: allDropdowns,
        productSettings,
        partiallyUpdate,
      });
      setErrors(invalidProducts);
    }
    // if (!isDev())
    if (isArrayWithValues(invalidProducts))
      return dispatch(
        setGlobalToast({
          show: true,
          message: `Please resolve issues before ${
            isUpdate ? "updating" : "creating"
          } products`,
          severity: "error",
        })
      );
    // }
    if (!isArrayWithValues(filteredData))
      return dispatch(
        setGlobalToast({
          show: true,
          message: "No products to create",
          severity: "error",
        })
      );
    mapMediaRef.current?.open();
  };

  const onMappingConfirm = async (should_map) => {
    let filteredData = data?.filter(
      (i) =>
        !isObjectEqual(i, defaultProductObject) &&
        isObjWithValues(removeFalsyValuesFromObject(i))
    );
    // let invalidProducts = validateProducts({
    //   columns,
    //   products: data,
    //   defaultProductObject,
    //   productSettings: productSettings,
    // });
    // setErrors(invalidProducts);
    // if (isArrayWithValues(invalidProducts))
    //   return dispatch(
    //     setGlobalToast({
    //       show: true,
    //       message: `Please resolve issues before ${
    //         isUpdate ? "updating" : "creating"
    //       } products`,
    //       severity: "error",
    //     })
    //   );

    console.log(filteredData, columns, selectedColumns);

    if (partiallyUpdate)
      filteredData = filteredData?.map((i) => {
        let obj = {};
        for (let column in selectedColumns)
          if (selectedColumns[column]) obj[column] = i[column];
        return obj;
      });
    // console.log(filteredData, "<<<<<<<<<");
    // return;
    if (!isArrayWithValues(filteredData))
      return dispatch(
        setGlobalToast({
          show: true,
          message: "No products to create",
          severity: "error",
        })
      );
    if (isOurWebsite() || isDev() || true) {
      setLoading(true);
      let time = formatDate(new Date(), "DD/MM/YYYY HH:mm")?.replaceAll(
        "/",
        "-"
      );
      const jsonString = JSON.stringify(filteredData);
      const blob = new Blob([jsonString], {
        type: "application/json",
        filename: `PRODUCT-SYNC-${time}.json`,
      });
      blob.name = `PRODUCT-SYNC-${time}.json`;
      let fileRes = await addFileAws3({
        params: {
          path: "jwero_sheets",
        },
        image: blob,
      });

      try {
        let { data: res } = await axios({
          url: `${getWebSocketUrl()}/import/json/products`,
          headers: {
            Authorization: `Basic ${getToken()}`,
          },
          method: "POST",
          data: {
            // json: filteredData,
            website: getWebsite(),
            file_path: fileRes?.file_path,
            should_map_image: should_map,
            ip: await getIp(),
            ua: new UAParser().getUA(),
            isAdmin: isAdmin,
            by: getId(),
            partiallyUpdate,
            is_dev: isDev(),
          },
        });
        if (res?.success) {
          dispatch(
            setGlobalToast({
              show: true,
              message: "Sheet Syncing Started!",
              severity: "success",
            })
          );
          // dispatch(
          //   setGlobalToast({
          //     show: true,
          //     message: "Sheet Syncing Started!",
          //     severity: "success",
          //   })
          // );
          dispatch({
            type: EDIT_SETTINGS,

            payload: {
              jweroSheetSync: {
                ...(jweroSheetSync || {}),
                ...(res?.data || {}),
              },
            },
          });
          setData([]);
          dispatch(editDataSheet({ create_products_sheet_backup: null }));
          navigate("/products#open");
        }
      } catch (error) {
        console.log(error);
        dispatch(
          setGlobalToast({
            show: true,
            message: "Sheet Syncing Failed!",
            severity: "error",
          })
        );
      } finally {
        setLoading(false);
      }

      return;
    }
    updateDialogRef.current?.open();
    let {
      updatedProducts,
      error,
      invalidProducts: productsWithErrors,
    } = await updateProductsFromSheet({
      products: filteredData,
      sheetProducts: productSettings,
      masterPricing,
      taxonomies,
      setLoading,
      setProgress,
      allProductsWithIds: bulkProducts,
      setLoadingText,
      isUpdate,
      should_map,
      allDiamondGroups,
    });
    updateDialogRef.current?.close();
    if (error) {
      setLoading(false);
      return dispatch(
        setGlobalToast({
          show: true,
          message: `Unable to ${isUpdate ? "update" : "create"} products`,
          severity: "error",
        })
      );
    }
    if (isArrayWithValues(productsWithErrors)) {
      let invalidProductsData = data?.filter((i) =>
        productsWithErrors?.find((j) => j.sku === i.sku)
      );
      setData(invalidProductsData);
      let errors = validateCreatedUpdatedProducts({
        products: productsWithErrors,
        columns,
        isUpdate,
      });
      if (errors) setErrors(errors);
    } else {
      dispatch(
        setGlobalToast({
          show: true,
          message: `Products has been ${
            isUpdate ? "updated" : "created"
          } successfully`,
          severity: "success",
        })
      );
      let key = isUpdate
        ? "edit_products_sheet_backup"
        : "create_products_sheet_backup";
      dispatch(editDataSheet({ [key]: null }));
      dispatch(fetchAllProducts(productDefaultParams, true, true));
      navigate("/products");
    }
  };

  const navigateError = (obj) => {
    if (obj.hasOwnProperty("min") && obj.hasOwnProperty("max"))
      datasheetRef.current?.setSelection({
        min: obj.min,
        max: obj.max,
      });
    else
      datasheetRef.current?.setActiveCell({
        col: obj.column,
        row: obj.row,
      });
    setCellHasError(true);
    errorsPopoverRef.current?.close();
    if (obj.message)
      dispatch(
        setGlobalToast({
          show: true,
          message: obj.message,
          severity: "info",
        })
      );
    if (showErrorTimeout) clearTimeout(showErrorTimeout);
    showErrorTimeout = setTimeout(() => {
      setCellHasError(false);
    }, 2000);
  };

  const onInstructionsRead = () => {
    dispatch(
      setPersistData({
        showProductSheetInstructions: true,
      })
    );
  };

  const onChangeFile = (event) => {
    const file = event.target.files[0];

    if (file) {
      setOpenImport(false);
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array", raw: true });
          const firstSheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[firstSheetName];

          const csvArray = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          const firstRow = csvArray[0];
          if (
            !validateSheetHeaders({
              original: originalProductsHeader,
              sheetHeaders: firstRow,
            })
          ) {
            return window.alert("This is not valid Sheet !");
          }
          // if (csvArray.length > 0) {
          //   const firstRow = csvArray[0];
          //   console.log(firstRow);
          //   let validHeaderString = [
          //     "SKU",
          //     "Barcode",
          //     "Slug",
          //     "Title",
          //     "Status",
          //     "Product Description",
          //     "Stock Status",
          //     "Quantity",
          //     "Collections",
          //     "Category",
          //     "Sub Category",
          //     "Settings/Size",
          //     "Featured",
          //     "Backorders allowed",
          //     "Sold Individually",
          //     "HUID",
          //     "Min shipping days",
          //     "Max shipping days",
          //     "Metal Type",
          //     "Metal Color",
          //     "Gold Purity",
          //     "Gold Gross",
          //     "Gold Net",
          //     "Silver Purity",
          //     "Silver Gross",
          //     "Silver Net",
          //     "Platinum Purity",
          //     "Platinum Gross",
          //     "Platinum Net",
          //     "Diamond From",
          //     "Diamond Weight",
          //     "Diamond Pieces",
          //     "Diamond Type",
          //     "Diamond Quality",
          //     "Diamond Shapes",
          //     "Diamond Cut",
          //     "Custom Diamond Rate",
          //     "Diamond Certified",
          //     "Diamond Lab",
          //     "Diamond Certificate ID",
          //     "Gemstone From",
          //     "Gemstone Quality",
          //     "Gemstone Shape",
          //     "Gemstone Type",
          //     "Gemstone Size",
          //     "Gemstone Pieces",
          //     "Gemstone Weight",
          //     "Custom Gemstone Rate",
          //     "Labour Type",
          //     "Labour From",
          //     "Per Gram",
          //     "Wastage From",
          //     "Wastage in (%)",
          //     "Minimum Making",
          //     "Extra Charge Type",
          //     "Extra Charge Value",
          //     "SEO Title",
          //     "SEO Description",
          //     "SEO Keywords",
          //     "Width (in mm)",
          //     "Height (in mm)",
          //     "Thickness (in mm)",
          //     "Tags",
          //     "Gender",
          //     "Ocassions",
          //     "Manual Product Price (MRP)",
          //     "Upsell",
          //     "Cross Sell",
          //     "Images",
          //     "Video URL",
          //   ]?.join();

          //   let headerString = firstRow?.join();
          //   if (validHeaderString !== headerString) {
          //     return window.alert("This is not valid Sheet !");
          //   }
          //   console.log(firstRow, "<<");

          //   console.log(headerString, "<<headerString");
          // } else {
          //   return window.alert("This is not valid Sheet !");
          // }
          dispatch(
            setGlobalToast({
              show: true,
              message: "Importing Data...",
              // severity: "success",
            })
          );
          let excelData = XLSX.utils.sheet_to_json(
            workbook.Sheets[firstSheetName]
          );
          // excelData = deepStringifyValues(excelData);
          let dataArray = onCreateProduct({ data: excelData });
          let setUpProduct = [];
          try {
            dataArray?.forEach((product) => {
              let obj = getProductObjFromSheetRow({
                product: product,
                // sheetProducts: productSettings,
                productSettings,
                masterPricing,
                taxonomies,
                allDiamondGroups,
                // allImages,
                // allProducts: matchingProductWithIds,
              });
              console.log(obj, "<<<< 2");
              if (typeof product.images === "string")
                obj.images = product.images;

              // console.log(obj);
              setUpProduct.push(obj);
            });
          } catch (error) {
            console.log(error);
          }
          // console.log(
          //   data,
          //   excelData,
          //   dataArray,
          //   setUpProduct,
          //   createSpreadsheetData({
          //     products: setUpProduct,
          //     taxonomies,
          //     productSettings,
          //   }),
          //   "<<<<<<"
          // );
          let defaultObj = getDefaultProductObject({
            defaults: productDefaults,
            taxonomies,
          });
          let productsData = createSpreadsheetData({
            products: setUpProduct,
            taxonomies,
            productSettings,
          });
          let length = productsData?.length;
          // let remainingRows =  - length;
          // let newArrTotal = customerData.concat(
          //   new Array(length)?.fill(defaultObj)
          // );
          let newArrTotal = [];
          if (length <= 50 && false) {
            let remainingRows = 50 - length;
            newArrTotal = productsData.concat(
              new Array(remainingRows)?.fill(defaultObj)
            );
          } else {
            newArrTotal = productsData;
          }
          setData(newArrTotal);
          // setData(
          //   createSpreadsheetData({
          //     products: setUpProduct,
          //     taxonomies,
          //     productSettings,
          //   })
          // );
          dispatch(
            setGlobalToast({
              show: true,
              message: "Imported file successfully",
              severity: "success",
            })
          );
          // setJsonData(excelData);
        };
        reader.readAsArrayBuffer(file);
      } catch (error) {
        console.log(error);
      }
    }
    inputRef.current.value = "";
  };

  const onExport = async () => {
    try {
      setExporting(true);

      let { data: res } = await axios({
        url: `${getWebSocketUrl()}/sheets/products`,
        method: "POST",
        headers: {
          Authorization: `Basic ${getToken()}`,
        },
        data: {
          store_id: getStoreId(),
          website: getWebsite(),
          newAuthToken: getAuthToken(),
          tenantId: getTenantId(),
          email: getEmail(),
          userName: getAdminName(),
          storeName: getStoreName(),
          ip: await getIp(),
          ua: new UAParser().getUA(),
          isAdmin: isAdmin,
          by: getId(),
        },
      });
      if (res?.success) {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Sheet Exporting started, please check your email",
            severity: "success",
          })
        );
        if (isObjWithValues(res?.data))
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              jweroSheetSync: {
                ...(jweroSheetSync || {}),
                ...(res?.data || {}),
              },
            },
          });
        navigate("/products#open");
      }
    } catch (error) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Sheet Exporting Failed",
          severity: "error",
        })
      );
    } finally {
      setExporting(false);
    }
  };

  const onDownloadTemplate = ({ data, fileName, fileType }) => {
    let header = {};
    columns?.forEach((obj) => {
      header[obj?.label] = "";
    });
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  const downloadCsv = () => {
    let arr = Object.keys(products_keys_mapping)?.map((key) => ({
      label: products_keys_mapping?.[key]?.column_name,
      value: key,
    }));
    onDownloadTemplate({
      data: [
        arr?.map((o) => o?.label)?.join(","),
        arr?.map((o) => "")?.join(","),
      ]?.join("\n"),
      fileName: "Jwero Products Sheet.csv",
      fileType: "text/csv",
    });
  };

  return (
    <Box
      sx={{
        maxWidth: !isIpad ? `calc(100vw - ${sidebarExtraWidth}px)` : "100%",
        pr: 3,
        pl: 1,
      }}
    >
      <input
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        accept="text/csv"
        onChange={onChangeFile}
      />
      {/* <Prompt
        when={!isDataSaved}
        message="Are you sure you want to leave? Your changes will be lost."
      /> */}
      {/* <Box sx={{ mx: 2, justifyContent: "space-between",  }}>
      </Box> */}
      {/* <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        mx={2}
        pb={3}
      > */}
      <ResponsiveStackRow
        firstElement={
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            {/* <Button
              startIcon={<ArrowBackIosNew />}
              onClick={() => {
                const doesAnyHistoryEntryExist = location.key !== "default";
                if (doesAnyHistoryEntryExist) {
                  navigate(-1);
                } else {
                  // no entry exists, we landed on the page via direct URL, there is no previous history so fallback to x
                  navigate("/products");
                }
              }}
            >
              Back
            </Button> */}
            <IconButton
              onClick={() => {
                const doesAnyHistoryEntryExist = location.key !== "default";
                if (doesAnyHistoryEntryExist) {
                  // navigate(-1);
                  navigate("/products");
                } else {
                  // no entry exists, we landed on the page via direct URL, there is no previous history so fallback to x
                  navigate("/products");
                }
              }}
            >
              <ArrowBackIosNew />
            </IconButton>
            <Box>
              <Typography sx={{ ...primaryLabelLarge, fontSize: "20px" }}>
                {/* {!isUpdate ? "Create" : "Edit"} products */}
                Jwero Sheets
                {/* (Beta) */}
              </Typography>
              {/* <Typography
                sx={{
                  ...secondaryLabelSmall,
                  mt: 0.2,
                  // textAlign: "right",
                  color: "primary",
                }}
              >
                {!isUpdate ? "Create" : "Edit"} products
              </Typography> */}
            </Box>
            {!isUpdate && (
              <Button
                sx={{ color: saving ? "primary.main" : "text.secondary" }}
                endIcon={<Sync />}
                disabled={saving}
              >
                {saving ? "Auto Saving" : "Auto Saved"}
              </Button>
            )}
          </Stack>
        }
        secondElement={
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <Tooltip title={isArrayWithValues(errors) ? "" : "No errors found"}>
              <IconButton
                variant="text"
                onClick={(e) =>
                  isArrayWithValues(errors)
                    ? errorsPopoverRef?.current?.open(e)
                    : null
                }
                // startIcon={<ViewWeekOutlined />}
                // color={isArrayWithValues(errors) ? "secondary" : "error"}
                sx={{
                  color: !isArrayWithValues(errors)
                    ? "text.secondary"
                    : "error.main",
                }}
                // disabled={!isArrayWithValues(errors)}
                // size="small"
              >
                <Badge
                  badgeContent={isArrayWithValues(errors) ? 1 : 0}
                  color="error"
                  variant="dot"
                >
                  <ErrorOutline />
                </Badge>
              </IconButton>
            </Tooltip>

            {isOurWebsite() || isDev() || true ? (
              <Tooltip title="Sync History">
                <IconButton onClick={() => setShowSync(true)}>
                  <History />
                </IconButton>
              </Tooltip>
            ) : null}
            {(isOurWebsite() || isDev()) && (
              <Tooltip title="Update specific columns of existing products as needed">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={partiallyUpdate}
                      onChange={(e) =>
                        dispatch(
                          setPersistData({ partiallyUpdate: e.target.checked })
                        )
                      }
                    />
                  }
                  label="Partially Update"
                />
              </Tooltip>
            )}
            <Button
              variant="text"
              sx={{
                backgroundColor: "#ffffff",
                padding: "6px 18px",
              }}
              onClick={(e) => columnsPopoverRef?.current?.open(e)}
              startIcon={<ViewWeekOutlined />}
            >
              Columns
            </Button>
            {(isOurWebsite() || isDev() || true) && (
              <Button
                sx={{
                  backgroundColor: "#ffffff",
                  padding: "6px 18px",
                }}
                // variant="contained"
                onClick={() => {
                  // inputRef?.current?.click();
                  setOpenImport(true);
                }}
                startIcon={<ArrowDownward />}
                // startIcon={<ArrowUpward />}
              >
                Import
              </Button>
            )}
            {(isOurWebsite() || isDev() || true) && (
              <LoadingButton
                sx={{
                  backgroundColor: "#ffffff",
                  padding: "6px 18px",
                }}
                loading={exporting}
                onClick={() => onExport()}
                startIcon={<ArrowUpward />}
                // startIcon={<ArrowDownward />}
              >
                Export
              </LoadingButton>
            )}
            {/* {isDev() && (
              <Button
                sx={{
                  // backgroundColor: "text.secondary",
                  backgroundColor: buttonColor,
                }}
                onClick={() => {
                  let arr = Object.keys(products_keys_mapping)?.map((key) => ({
                    label: products_keys_mapping?.[key]?.column_name,
                    value: key,
                  }));
                  onDownloadTemplate({
                    data: [
                      arr?.map((o) => o?.label)?.join(","),
                      arr?.map((o) => "")?.join(","),
                    ]?.join("\n"),
                    fileName: "Jwero Products Sheet.csv",
                    fileType: "text/csv",
                  });
                }}
              >
                Download
              </Button>
            )} */}
            {/* <Fade in={isArrayWithValues(errors)}> */}
            {/* </Fade> */}
            <LoadingButton
              sx={{}}
              variant="contained"
              onClick={onUpdate}
              // disabled={loading}
              loading={loading}
              // startIcon={
              //   loading ? (
              //     <CircularProgress style={{ height: "15px", width: "15px" }} />
              //   ) : (
              //     <Sync style={{ height: "17px", width: "17px" }} />
              //   )
              // }
            >
              {/* {loading ? `${Math.round(progress)}%` : "Update products"} */}
              {/* {isUpdate ? "Update" : "Create"} */}
              Sync products
            </LoadingButton>
          </Stack>
        }
        containerStyle={{ padding: "0px" }}
      />
      <JweroSyncHistory
        type={"products"}
        showSync={showSync}
        closeSync={closeSync}
      />
      {!showProductSheetInstructions ? (
        <Alert
          severity="info"
          sx={{ my: 2 }}
          action={
            <Button color="inherit" onClick={onInstructionsRead} size="small">
              Got it
            </Button>
          }
        >
          <AlertTitle>Instructions</AlertTitle>
          <Stack direction={"row"} spacing={2}>
            <Box
              style={{
                height: 6,
                width: 6,
                backgroundColor: "#000",
                borderRadius: "50%",
                marginTop: "6px",
              }}
            />
            <Typography>
              {`The predefined value shown in respective column are based on the value
        you have defined in Settings > Product > Product Shortcuts`}
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Box
              style={{
                height: 6,
                width: 6,
                backgroundColor: "#000",
                borderRadius: "50%",
                marginTop: "6px",
              }}
            />
            <Typography>
              {`Write or select the appropriate value (specially for Diamond, Gemstone)to get the product entry correct. Eg. for Diamond / Gemstone, select the same combination for which you have already created the Master Price Settings`}
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Box
              style={{
                height: 6,
                width: 6,
                backgroundColor: "#000",
                borderRadius: "50%",
                marginTop: "6px",
              }}
            />
            <Typography>
              {`Before adding any field, make sure its added in Master`}
            </Typography>
          </Stack>
        </Alert>
      ) : null}

      <DynamicDataSheetGrid
        // <DataSheetGrid
        ref={datasheetRef}
        // rowHeight={(props) => {
        //   return { top: 40 }; // custom function
        //   // return validateNumber(rowData?.length || 0) + 40;
        // }}
        // rowHeight={60}
        // stickyRightColumn={}

        height={windowSize.innerHeight - 200}
        value={data}
        onChange={(data, operations) => {
          setData(data);
        }}
        columns={columns}
        style={{
          "--dsg-selection-border-color": cellhasError
            ? "tomato"
            : "rgb(69,128,230)",
          maxWidth: !isIpad ? `calc(100vw - ${sidebarExtraWidth}px)` : "100%",
        }}
        addRowsComponent={(props) => (
          <AddRowsComponent
            remainingRows={remainingProducts}
            currentRowsLength={data?.length}
            {...props}
          />
        )}
      />
      <ErrorsPopover
        navigateError={navigateError}
        ref={errorsPopoverRef}
        errors={errors}
      />
      <UpdateDialog
        loadingText={loadingText}
        ref={updateDialogRef}
        progress={progress}
      />
      <ColumnsPopover
        selectedColumns={selectedColumns}
        setSelectedColumns={(payload) => {
          setSelectedColumns((state) => ({ ...state, ...payload }));
        }}
        partiallyUpdate={partiallyUpdate}
        ref={columnsPopoverRef}
        allColumns={allColumns}
      />
      <MapMediaDialog onSubmit={onMappingConfirm} ref={mapMediaRef} />

      <ImportDialog
        open={openImport}
        handleClose={onCloseImport}
        onAddFile={() => {
          console.log("clicked ");
          inputRef.current.click();
          // inputRef?.current?.click();
        }}
        heading={"Import Products by CSV"}
        downloadCsv={downloadCsv}
      />
      <ExportDialog open={openExport} handleClose={onCloseExport} />
    </Box>
  );
};

export default ProductDataSheet;

const AddRowsComponent = memo(
  ({ addRows, remaingRows, currentRowsLength }) => {
    let [temp, setTemp] = useState("");
    let [maxReached, setMaxReached] = useState(false);
    useEffect(() => {
      let max = 10000 - currentRowsLength;
      setMaxReached(validateNumber(temp) > max);
    }, [currentRowsLength, temp]);
    return (
      <Box sx={{ bgcolor: "#fafafa", p: 2 }}>
        <Stack spacing={3} direction={"row"} alignItems={"center"}>
          <TextField
            size="small"
            value={temp}
            onChange={(e) => setTemp(e.target.value)}
            sx={{ maxWidth: 100 }}
            type="number"
          />
          <Button
            variant="outlined"
            onClick={() => (!temp ? null : addRows(validateNumber(temp)))}
            disabled={temp > remaingRows || maxReached}
          >
            Add Rows
          </Button>
        </Stack>
        {maxReached && (
          <ErrorLabelExtraSmall sx={{ mt: 1 }}>
            The limit to add/update products is upto 10,000
          </ErrorLabelExtraSmall>
        )}
      </Box>
    );
  },
  (a, b) => arePropsEqual(a, b)
);
