import { Add, Delete, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allEmptyCurrency,
  deepObjectComparison,
  dialogStyle,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";
import { addUserActivity } from "../../redux/actions/hubActions";
import { currencySymbols } from "./Gold";
import { setHelperData } from "../../redux/actions/helperActions";

const defaultAddForm = {
  title: "",
  qualities: [],
  types: [],
  shapes: [],
  sizes: [],
  colors: [],
  rate: "",
};

const Gemstone = forwardRef((props, ref) => {
  let { isDefaultCurrency, defaultCurrency, selectedCurrency } = props;
  const dispatch = useDispatch();
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [gemstonePricing, setGemstonePricing] = useState(allEmptyCurrency);
  const [loading, setLoading] = useState(false);
  const [allQualties, setAllQualities] = useState([]);
  const [allTypes, setAllTypes] = useState([]);
  const [allShapes, setAllShapes] = useState([]);
  const [allSizes, setAllSizes] = useState([]);
  const [editId, setEditId] = useState(false);
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  // const [selectedCurrency, setSelectedCurrency] = useState("");
  const [allCurrency, setAllCurrency] = useState([]);
  const [addForm, setAddForm] = useState(defaultAddForm);
  const [showDelete, setShowDelete] = useState(false);

  const closeDelete = () => setShowDelete(false);
  const openDelete = (id) => {
    setShowDelete(id);
  };

  const onDeleteCombination = () => {
    let id = showDelete;
    let updatedGemstonePricing = {};
    if (selectedCurrency === defaultCurrency) {
      for (let i = 0; i < allCurrency.length; i++) {
        if (allCurrency[i].value === defaultCurrency)
          updatedGemstonePricing[allCurrency[i].value] = gemstonePricing[
            `${allCurrency[i].value}`
          ].filter((obj) => obj.id !== id);
        else
          updatedGemstonePricing[allCurrency[i].value] = gemstonePricing[
            `${allCurrency[i].value}`
          ].filter((obj) => obj.id !== id);
      }
    } else
      updatedGemstonePricing = {
        ...gemstonePricing,
        [selectedCurrency]: gemstonePricing[selectedCurrency].filter(
          (i) => i.id === id
        ),
      };
    // //send this if want to remove unselected currency
    let filteredObj = {};
    for (let i = 0; i < Object.keys(updatedGemstonePricing).length; i++)
      if (
        allCurrency.find(
          (j) => j.value === Object.keys(updatedGemstonePricing)[i]
        )
      )
        filteredObj[Object.keys(updatedGemstonePricing)[i]] =
          updatedGemstonePricing[Object.keys(updatedGemstonePricing)[i]];
    // let updatedGemstonePricing = [...gemstonePricing];
    // updatedGemstonePricing = updatedGemstonePricing.map((i) =>
    //   i.id === editId ? addForm : i
    // );
    setShowDelete(false);
    let { onMasterUpdate } = props;
    onMasterUpdate({
      gemstone_pricing: filteredObj,
      setLoading,
      callback: () => setAddFormOpen(false),
      skipMasterUpdateHook: true,
    });
  };
  useEffect(() => {
    if (isObjWithValues(props.productSettings))
      if (isArrayWithValues(props.productSettings.currency)) {
        let { currency } = props.productSettings;
        // setSelectedCurrency(currency[0].value);
        setAllCurrency(props.productSettings.currency);
      }
  }, [props.productSettings]);

  useImperativeHandle(ref, () => ({
    getGemstonePricing() {
      return gemstonePricing;
    },
  }));

  const editAddForm = (payload) =>
    setAddForm((state) => ({ ...state, ...payload }));

  useEffect(() => {
    if (
      isObjWithValues(masterPricing) &&
      isObjWithValues(masterPricing.gemstone_pricing)
    )
      setGemstonePricing((state) => ({
        ...state,
        ...masterPricing.gemstone_pricing,
      }));
  }, [masterPricing]);

  useEffect(() => {
    if (isObjWithValues(props.productSettings)) {
      let { productSettings } = props;
      if (isArrayWithValues(productSettings.gemstone_qualities))
        setAllQualities(productSettings.gemstone_qualities);
      if (isArrayWithValues(productSettings.gemstone_types))
        setAllTypes(productSettings.gemstone_types);
      if (isArrayWithValues(productSettings.gemstone_shapes))
        setAllShapes(productSettings.gemstone_shapes);
    }
  }, [props.productSettings]);

  useEffect(() => {
    if (isArrayWithValues(addForm.shapes)) {
      let { shapes } = addForm;
      let sizes = [];
      console.log(gemstoneSizes, shapes);
      for (let i = 0; i < shapes.length; i++) {
        if (gemstoneSizes[shapes[i].value])
          sizes = sizes.concat(Object.keys(gemstoneSizes[shapes[i].value]));
      }
      sizes = sizes.concat(Object.keys(gemstoneSizes.common_stones));
      setAllSizes(sizes.map((i) => ({ label: i, value: i })));
    }
  }, [addForm.shapes]);

  const openAddForm = () => {
    let title = "";
    if (isArrayWithValues(allCurrency) && isObjWithValues(gemstonePricing))
      if (isArrayWithValues(gemstonePricing[allCurrency[0].value]))
        title = `Gemstone Combination #${
          gemstonePricing[allCurrency[0].value].length + 1
        }`;
      else title = "Gemstone Combination #1";
    setAddForm({ ...defaultAddForm, title });
    setAddFormOpen(true);
  };
  const closeAddForm = () => {
    setEditId(null);
    setAddForm(defaultAddForm);
    setAddFormOpen(false);
  };

  const onAddCombination = () => {
    let obj = {};
    for (let i = 0; i < allCurrency.length; i++) {
      let array = [...gemstonePricing[allCurrency[i].value]];
      array.push({
        id: Math.floor(Date.now() / 1000),
        ...addForm,
        rate: defaultCurrency === allCurrency[i].value ? addForm.rate : "",
      });
      obj[allCurrency[i].value] = array;
    }
    let { onMasterUpdate } = props;
    dispatch(
      setHelperData({
        changeInCombination: true,
      })
    );
    dispatch(
      addUserActivity({
        event: `gemstone_pricing_add`,
        event_info: {
          name: addForm?.title,
          rate: addForm?.rate,
        },
      })
    );
    onMasterUpdate({
      gemstone_pricing: obj,
      setLoading,
      callback: () => setAddFormOpen(false),
      skipMasterUpdateHook: true,
    });
  };

  const onUpdateCombination = () => {
    let updatedGemstonePricing = {};
    let previousObj = gemstonePricing[selectedCurrency]?.find(
      (o) => o?.id == editId
    );
    if (selectedCurrency === defaultCurrency) {
      for (let i = 0; i < allCurrency.length; i++) {
        if (allCurrency[i].value === defaultCurrency)
          updatedGemstonePricing[allCurrency[i].value] = gemstonePricing[
            `${allCurrency[i].value}`
          ].map((obj) => (obj.id === editId ? addForm : obj));
        else
          updatedGemstonePricing[allCurrency[i].value] = gemstonePricing[
            `${allCurrency[i].value}`
          ].map((obj) =>
            obj.id === editId ? { ...addForm, rate: obj.rate } : obj
          );
      }
    } else
      updatedGemstonePricing = {
        ...gemstonePricing,
        [selectedCurrency]: gemstonePricing[selectedCurrency].map((i) =>
          i.id === editId ? { ...i, rate: addForm.rate } : i
        ),
      };
    // //send this if want to remove unselected currency
    let filteredObj = {};
    for (let i = 0; i < Object.keys(updatedGemstonePricing).length; i++)
      if (
        allCurrency.find(
          (j) => j.value === Object.keys(updatedGemstonePricing)[i]
        )
      )
        filteredObj[Object.keys(updatedGemstonePricing)[i]] =
          updatedGemstonePricing[Object.keys(updatedGemstonePricing)[i]];

    // let updatedGemstonePricing = [...gemstonePricing];
    // updatedGemstonePricing = updatedGemstonePricing.map((i) =>
    //   i.id === editId ? addForm : i
    // );
    // let updatedObj = {
    //   ...gemstonePricing,
    //   [selectedCurrency]: [...updatedGemstonePricing],
    // };
    if (!deepObjectComparison(addForm, previousObj))
      dispatch(
        setHelperData({
          changeInCombination: true,
        })
      );
    dispatch(
      addUserActivity({
        event: `gemstone_pricing_update`,
        event_info: {
          name: addForm?.title,
          rate: addForm?.rate,
        },
      })
    );
    let { onMasterUpdate } = props;
    onMasterUpdate({
      gemstone_pricing: filteredObj,
      setLoading,
      callback: () => setAddFormOpen(false),
      skipMasterUpdateHook: true,
    });
  };

  const onEditPress = (obj) => {
    setEditId(obj.id);
    setAddForm({ ...obj });
    setAddFormOpen(true);
  };

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const globalPopupStyle = useSelector(
    (state) => state.helper.globalPopupStyle
  );

  return (
    <Box>
      <Stack alignItems={"center"} justifyContent="flex-end" direction="row">
        <Button
          size="small"
          onClick={isDefaultCurrency ? openAddForm : null}
          startIcon={<Add />}
          sx={{ mr: 2 }}
          disabled={!isDefaultCurrency}
        >
          Add New Combination
        </Button>
        {/* <FormControl size="small">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCurrency}
            // label="Age"
            onChange={(e) => setSelectedCurrency(e.target.value)}
          >
            {allCurrency.map((i) => {
              return (
                <MenuItem
                  value={i.value}
                >{`${i.label} (${i.symbol})`}</MenuItem>
              );
            })}
          </Select>
        </FormControl> */}
      </Stack>
      {isIpad && (
        <Box>
          {gemstonePricing[selectedCurrency] &&
            gemstonePricing[selectedCurrency].map((row) => {
              return (
                <Card key={row.id}>
                  <CardContent>
                    <Box>
                      <Stack
                        direction={"row"}
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <Typography variant="h6">{row.title}</Typography>
                        <IconButton
                          size="small"
                          onClick={() => onEditPress(row)}
                        >
                          <Edit />
                        </IconButton>
                      </Stack>
                      <Typography fontSize="14px" mt={2}>
                        Qualities
                      </Typography>
                      <Stack
                        direction="row"
                        flexWrap={"wrap"}
                        sx={{
                          border: "0.4px solid #bababa",
                          p: 1,
                          py: 2,
                          mt: 1,
                          borderRadius: "8px",
                        }}
                      >
                        {isArrayWithValues(row.qualities) &&
                          row.qualities.map((quality, i) => {
                            return (
                              <Typography>
                                {quality.label}
                                {i !== row.qualities.length - 1 ? `, ` : ""}
                              </Typography>
                            );
                          })}
                      </Stack>
                      <Typography fontSize="14px" mt={2}>
                        Shapes
                      </Typography>
                      <Stack
                        direction="row"
                        flexWrap={"wrap"}
                        sx={{
                          border: "0.4px solid #bababa",
                          p: 1,
                          py: 2,
                          mt: 1,
                          borderRadius: "8px",
                        }}
                      >
                        {isArrayWithValues(row.shapes) &&
                          row.shapes.map((shape, i) => {
                            return (
                              <Typography>
                                {shape.label}
                                {i !== row.shapes.length - 1 ? `, ` : ""}
                              </Typography>
                            );
                          })}
                      </Stack>
                      <Typography fontSize="14px" mt={2}>
                        Types
                      </Typography>
                      <Stack
                        direction="row"
                        flexWrap={"wrap"}
                        sx={{
                          border: "0.4px solid #bababa",
                          p: 1,
                          py: 2,
                          mt: 1,
                          borderRadius: "8px",
                        }}
                      >
                        {isArrayWithValues(row.types) &&
                          row.types.map((type, i) => {
                            return (
                              <Typography>
                                {type.label}
                                {i !== row.types.length - 1 ? `, ` : ""}
                              </Typography>
                            );
                          })}
                      </Stack>
                      <Typography fontSize="14px" mt={2}>
                        Sizes
                      </Typography>
                      <Stack
                        direction="row"
                        flexWrap={"wrap"}
                        sx={{
                          border: "0.4px solid #bababa",
                          p: 1,
                          py: 2,
                          mt: 1,
                          borderRadius: "8px",
                        }}
                      >
                        {isArrayWithValues(row.sizes) &&
                          row.sizes.map((size, i) => {
                            return (
                              <Typography>
                                {size.label}
                                {i !== row.sizes.length - 1 ? `, ` : ""}
                              </Typography>
                            );
                          })}
                      </Stack>
                      <Typography
                        variant="h6"
                        textAlign={"right"}
                        fontSize="14px"
                        mt={3}
                      >
                        Rate:{" "}
                        {`${currencySymbols[selectedCurrency]} ${row.rate}`}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              );
            })}
        </Box>
      )}
      {!isIpad && (
        <TableContainer
          component={Paper}
          sx={{
            mt: 3,
            borderRadius: "10px",
            boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="left">Qualities</TableCell>
                <TableCell align="left">Types</TableCell>
                <TableCell align="left">Shapes</TableCell>
                <TableCell align="left">Sizes</TableCell>
                <TableCell align="left">Rate</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gemstonePricing[selectedCurrency] &&
                gemstonePricing[selectedCurrency].map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    onClick={() => onEditPress(row)}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell align="left">
                      <Typography>{row.title}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {isArrayWithValues(row.qualities) &&
                        row.qualities.map((quality) => {
                          return (
                            <Stack my={1} direction="row" alignItems={"center"}>
                              <Typography>{quality.label}</Typography>
                            </Stack>
                          );
                        })}
                    </TableCell>
                    <TableCell align="left">
                      <Stack>
                        {isArrayWithValues(row.types) &&
                          row.types.map((type) => {
                            return (
                              <Stack
                                my={1}
                                direction="row"
                                alignItems={"center"}
                              >
                                <Typography>{type.label}</Typography>
                              </Stack>
                            );
                          })}
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                      {isArrayWithValues(row.shapes) &&
                        row.shapes.map((shape) => {
                          return (
                            <Stack my={1} direction="row" alignItems={"center"}>
                              <Typography>{shape.label}</Typography>
                            </Stack>
                          );
                        })}
                    </TableCell>
                    <TableCell align="left">
                      {isArrayWithValues(row.sizes) &&
                        row.sizes.map((size) => {
                          return (
                            <Stack my={1} direction="row" alignItems={"center"}>
                              <Typography>{size.label}</Typography>
                            </Stack>
                          );
                        })}
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {currencySymbols[selectedCurrency]} {row.rate}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <IconButton
                        size="small"
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          openDelete(row.id);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                    {/* <TableCell align="left">{row.protein}</TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog
        open={showDelete}
        onClose={closeDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        {...globalPopupStyle}
      >
        <DialogTitle id="alert-dialog-title">
          Delete Gemstone combination?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDelete}>Cancel</Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            size="small"
            color="error"
            onClick={onDeleteCombination}
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth
        open={addFormOpen}
        onClose={closeAddForm}
        sx={dialogStyle}
        {...globalPopupStyle}
      >
        <DialogTitle>{editId ? "Update" : "Add"} Combination</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                variant="standard"
                value={addForm.title}
                onChange={(e) =>
                  !isDefaultCurrency
                    ? null
                    : editAddForm({ title: e.target.value })
                }
                label="Title"
                autoFocus
                fullWidth
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.qualities}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allQualties}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Qualities" variant="standard" />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ qualities: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal

                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.types}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allTypes}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Types" variant="standard" />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ types: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.shapes}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allShapes}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Shapes" variant="standard" />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ shapes: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal

                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.sizes}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={allSizes}
                noOptionsText="Please select shapes"
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Sizes" variant="standard" />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ sizes: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                // disablePortal
                openOnFocus
                disableCloseOnSelect
                filterSelectedOptions
                value={addForm.colors || []}
                id="combo-box-demo"
                getOptionLabel={(option) => option.label || ""}
                options={gemstoneColors}
                // sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Colors" variant="standard" />
                )}
                onChange={(e, value) =>
                  !isDefaultCurrency ? null : editAddForm({ colors: value })
                }
                disabled={!isDefaultCurrency}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="standard"
                value={addForm.rate || ""}
                onChange={(e) => editAddForm({ rate: e.target.value })}
                label="Rate"
                fullWidth
                type={"number"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {currencySymbols[selectedCurrency]}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={closeAddForm}>Cancel</Button>
            <LoadingButton
              loading={loading}
              onClick={editId ? onUpdateCombination : onAddCombination}
            >
              {editId ? "Update" : "Add"}
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
});

export default Gemstone;

export const gemstoneSizes = {
  round: {
    1.6: 0.02,
    2: 0.03,
    2.5: 0.06,
    3: 0.1,
    3.5: 0.16,
    4: 0.25,
    4.5: 0.33,
    5: 0.5,
    5.5: 0.65,
    6: 0.75,
    6.5: 1,
    7: 1.25,
    8: 2,
    9: 2.75,
    10: 3.5,
    11: 4.6,
    12: 6,
    13: 7.75,
    14: 9.65,
    15: 11.85,
    16: 14.35,
    17: 17.25,
    18: 20.5,
    19: 24,
    20: 28,
    21: 34.8,
    22: 41.8,
    23: 49.8,
    24: 57.9,
    25: 68.7,
  },
  square: {
    5: 0.75,
    6: 1.3,
    7: 2.05,
    8: 2.9,
    9: 4.4,
    10: 6,
    11: 8,
    12: 10.35,
    13: 13.2,
    14: 16.5,
    15: 20.25,
    16: 24.6,
    "20 x 15": 20.3,
    "22 x 15": 23.5,
    "23 x 17": 32.2,
    "25 x 18": 43,
    "27 x 20": 58.5,
    "30 x 22": 79.6,
  },
  oval: {
    "5 x 3": 0.25,
    "6 x 4": 0.5,
    "7 x 5": 1,
    "8 x 6": 1.5,
    "9 x 7": 2.5,
    "10 x 8": 3,
    "11 x 9": 4,
    "12 x 10": 5,
    "14 x 10": 6,
    "14 x 12": 8,
    "16 x 12": 10.5,
    "18 x 13": 14.2,
  },
  emerald: {
    "6 x 4": 0.5,
    "7 x 5": 1,
    "8 x 6": 1.5,
    "9 x 7": 2.5,
    "10 x 8": 3,
    "11 x 9": 4,
    "12 x 10": 5,
    "14 x 10": 8,
    "16 x 12": 10.5,
    "18 x 13": 14.2,
    "20 x 15": 20.3,
    "25 x 18": 30.6,
    "30 x 22": 54.5,
  },
  pear: {
    "6 x 5": 0.7,
    "7 x 5": 0.75,
    "8 x 5": 1,
    "9 x 6": 1.5,
    "10 x 7": 2,
    "11 x 7": 2.25,
    "12 x 8": 3,
    "13 x 9": 3.5,
    "15 x 10": 5.5,
    "16 x 12": 8.6,
    "18 x 13": 12,
    "20 x 15": 17.3,
    "22 x 15": 18.7,
  },
  marquis: {
    "4 x 2": 0.1,
    "5 x 2.5": 0.25,
    "6 x 3": 0.3,
    "8 x 4": 0.75,
    "10 x 5": 1,
    "12 x 6": 2,
    "14 x 7": 3,
    "16 x 8": 4,
    "18 x 9": 6,
    "20 x 10": 8.5,
  },
  common_stones: {
    A: "A",
    AA: "AA",
    AAA: "AAA",
    "1 to 2 mm": "1 to 2 mm",
    "2 to 3 mm": "2 to 3 mm",
    "3 to 4 mm": "3 to 4 mm",
    "1-3 MM": "1-3 MM",
    "2-3 MM": "2-3 MM",
    "2-4 MM": "2-4 MM",
    "3-4 MM": "3-4 MM",
    "4-5 MM": "4-5 MM",
    "5-6 MM": "5-6 MM",
    "4-6 MM": "4-6 MM",
    "6MM": "6MM",
    "3-5 MM": "3-5 MM",
    "8-11 MM": "8-11 MM",
    "4-7 MM": "4-7 MM",
    "6-7 MM": "6-7 MM",
    "7-8 MM": "7-8 MM",
    "8-9 MM": "8-9 MM",
    "9-10 MM": "9-10 MM",
    "10-11 MM": "10-11 MM",
    "10-12 MM": "10-12 MM",
    "11-12 MM": "11-12 MM",
    "12-13 MM": "12-13 MM",
    Small: "small",
    Medium: "medium",
    Large: "large",
  },
};

export const gemstoneColors = [
  { label: "Ash Grey", value: "ash-grey" },
  { label: "Black", value: "black" },
  { label: "Off-White", value: "off-white" },
  { label: "White", value: "white" },
  { label: "Blue", value: "blue" },
  { label: "Brown", value: "brown" },
  { label: "Golden", value: "golden" },
  { label: "Green", value: "green" },
  { label: "Multi Colour", value: "multi-colour" },
  { label: "Peach", value: "peach" },
  { label: "Pink", value: "pink" },
  { label: "Red", value: "red" },
  { label: "Yellow", value: "yellow" },
  { label: "Grey", value: "grey" },
  { label: "Colour Stones", value: "colour-stones" },
];
