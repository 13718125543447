// function generateAdvancedPenaltySettingsSentence(form = {}) {
//   // Initialize the base sentence
//   let sentence = "If payments are missed ";

import {
  PrimaryLabel,
  SecondaryColorLabel,
  SecondaryLabel,
} from "../../../../../helper/utility/Labels";

//   // Append the condition for applying the penalty
//   if (form.advance_penalty_on === "all") {
//     sentence += "consecutively ";
//   } else if (form.advance_penalty_on === "one") {
//     sentence += "on any one ";
//   }

//   // Append the selected phases, if any
//   if (form.penalty_on_phases && form.penalty_on_phases.length > 0) {
//     const phaseDescriptions = form.penalty_on_phases
//       .map((phase) => `Phase ${phase.label}`)
//       .join(", ");
//     sentence += `during ${phaseDescriptions}, `;
//   }

//   // Append the action taken when payments are missed
//   if (form.advance_penalty_action === "no_rewards") {
//     sentence += "then no rewards will be given.";
//   } else if (form.advance_penalty_action === "deduct") {
//     sentence += "then a deduction will be made.";
//   } else if (form.advance_penalty_action === "plan_close") {
//     sentence += "then the plan will be closed.";
//   }

//   // If deduction, specify the type and value
//   if (form.advance_penalty_action === "deduct") {
//     if (form.advance_penalty_deduction_type === "amount") {
//       sentence += ` The deduction will be an amount of ${form.advance_penalty_deduction_value}.`;
//     } else if (form.advance_penalty_deduction_type === "percentage") {
//       sentence += ` The deduction will be ${form.advance_penalty_deduction_value}%`;

//       // Specify what the percentage is applied to
//       if (form.advance_penalty_deduction_on === "total_plan_amount") {
//         sentence += " of the total plan value.";
//       } else if (
//         form.advance_penalty_deduction_on === "current_interval_amount"
//       ) {
//         sentence += " of the total accumulated value.";
//       }
//     }
//   }

//   return sentence;
// }

function generateAdvancedPenaltySettingsSentence(form) {
  // Define components for primary and secondary labels with specific styles
  // const PrimaryLabel = ({ children }) => (
  //   <span style={{ fontWeight: "bold" }}>{children}</span>
  // );
  const Highlight = ({ children }) => (
    <SecondaryColorLabel component="span">{children}</SecondaryColorLabel>
  );

  // Start building the sentence as an array of elements
  let sentence = [
    "If payments are missed ",
    // <PrimaryLabel key="base" component="span">
    // </PrimaryLabel>,
  ];

  // Append the condition for applying the penalty with highlighting
  if (form.advance_penalty_on === "all") {
    sentence.push(<Highlight key="penalty_on">consecutively </Highlight>);
  } else if (form.advance_penalty_on === "one") {
    sentence.push(<Highlight key="penalty_on">on any one </Highlight>);
  }

  // Append the selected phases, if any, with highlighting
  if (form.penalty_on_phases && form.penalty_on_phases.length > 0) {
    const phaseDescriptions = form.penalty_on_phases
      .map((phase) => `Phase ${phase.label}`)
      .join(", ");
    sentence.push(
      <Highlight key="phases">during {phaseDescriptions}</Highlight>,
      " "
    );
  }

  // Append the action taken when payments are missed with highlighting
  switch (form.advance_penalty_action) {
    case "no_rewards":
      sentence.push(
        "then ",
        <Highlight key="action">no rewards will be given</Highlight>,
        "."
      );
      break;
    case "deduct":
      sentence.push(
        "then ",
        <Highlight key="action">a deduction will be made</Highlight>,
        "."
      );
      break;
    case "plan_close":
      sentence.push(
        "then ",
        <Highlight key="action">the plan will be closed</Highlight>,
        "."
      );
      break;
  }

  // If deduction, specify the type and value with highlighting
  if (form.advance_penalty_action === "deduct") {
    let deductionDetail =
      form.advance_penalty_deduction_type === "amount"
        ? `an amount of ${form.advance_penalty_deduction_value}`
        : `${form.advance_penalty_deduction_value}%`;

    sentence.push(
      " The deduction will be ",
      <Highlight key="deduction">{deductionDetail}</Highlight>,
      "."
    );

    // Specify what the percentage is applied to with highlighting
    if (form.advance_penalty_deduction_type === "percentage") {
      let deductionBase =
        form.advance_penalty_deduction_on === "total_plan_amount"
          ? "of the total plan value."
          : "of the total accumulated value.";

      sentence.push(
        " ",
        <Highlight key="deduction_on">{deductionBase}</Highlight>
      );
    }
  }

  // Combine all elements into a single fragment or container
  return <div>{sentence}</div>;
}
export { generateAdvancedPenaltySettingsSentence };
