import { useState, useEffect } from "react";
import {
  Typography,
  Card,
  TextField,
  Autocomplete,
  Chip,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { userStyles } from "./styles/userStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import DataObjectIcon from "@mui/icons-material/DataObject";
import UserInputAttributes from "./helper/UserInputAttributes";
import CustomTooltip from "../components/CustomTooltip";
import { checkUserInput } from "../custom-nodes/utils/checkUserInput";
import { MuiChipsInput } from "mui-chips-input";
import { checkTitleExists } from "../helper/checkTitleExists";
// import UserInputEmoji from "./helper/UserInputEmoji";

const styles = userStyles;

const Sidebar = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [textFields, setTextFields] = useState([""]);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [userResponsesError, setUserResponsesError] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [duplicateResError, setDuplicateResError] = useState([false]);
  const [keywordExists, setKeywordExists] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [attributeAnchorEl, setAttributeAnchorEl] = useState(null);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);
  const [titleCheckFlag, setTitleCheckFlag] = useState(false);
  const errorMessage =
    title === "" ? "Block title is required" : "Title already exists!";

  const handleTextFieldChange = (index, value) => {
    const { flag, flagFor } = checkUserInput({ nodes, userInput: value });

    const updatedTextFields = [...textFields];
    updatedTextFields[index] = value;
    if (value.trim() === "" && index < textFields.length - 1) {
      updatedTextFields.splice(index + 1, 1);
    }

    if (
      index === textFields.length - 1 &&
      textFields.length < 5 &&
      value.trim() !== ""
    ) {
      updatedTextFields.push("");
    }

    setTextFields(updatedTextFields);

    if (flag && flagFor === "responses") {
      const updatedDuplicateError = [...duplicateResError];
      updatedDuplicateError[index] = true;
      setDuplicateResError(updatedDuplicateError);
      return;
    } else {
      const updatedDuplicateError = [...duplicateResError];
      updatedDuplicateError[index] = false;
      setDuplicateResError(updatedDuplicateError);
    }
  };

  const handleAttributeSelect = (index, value) => {
    let updatedTextFields = [...textFields];
    let fieldValue = updatedTextFields[index];
    updatedTextFields[index] = fieldValue + `{{${value}}}`;
    setTextFields(updatedTextFields);
    setAttributeAnchorEl(null);
  };

  const handleEmojiPicker = (e) => {
    setEmojiAnchorEl(e.currentTarget);
  };

  const handleEmojiSelect = (index, emoji) => {
    let updatedTextFields = [...textFields];
    let fieldValue = updatedTextFields[index];
    const emojiText = emoji.emoji;
    updatedTextFields[index] = fieldValue + emojiText;
    setTextFields(updatedTextFields);
    setEmojiAnchorEl(false);
  };

  const handleAttributePopover = (e) => {
    setAttributeAnchorEl(e.currentTarget);
  };

  const handleDeleteTextField = (index) => {
    if (index > 0) {
      const updatedTextFields = [...textFields];
      updatedTextFields.splice(index, 1);
      setTextFields(updatedTextFields);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (checkUserInput({ nodes, userInput: value })) {
      return;
    }
    setUserInput(value);
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title === "" || titleCheckFlag) {
      setTitleCheckFlag(true);
      setTitleError(true);
    }

    if (textFields[0] == "") {
      setUserResponsesError(true);
    }

    if (textFields[0] != "" && title && !titleCheckFlag) {
      const submittedUserData = {
        title,
        userResponse: textFields,
        keywords: selectedValues,
      };
      dispatch({ type: actions.CLOSE_SIDEBAR_USER });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    dispatch({ type: actions.CLOSE_SIDEBAR_USER });
  };

  const handleChipsChange = (newChips) => {
    const value = newChips.slice(-1)[0];
    const { flag, flagFor } = checkUserInput({ nodes, userInput: value });
    if (flag && flagFor === "keywords") {
      setKeywordExists(true);

      setTimeout(() => {
        setKeywordExists(false);
      }, [2000]);
      return;
    }
    setSelectedValues(newChips);
  };

  const handleBotTitle = (e) => {
    const value = e.target.value;
    setTitle(e.target.value);
    if (checkTitleExists({ nodes, title: value })) {
      setTitleCheckFlag(true);
      return;
    }
    setTitleCheckFlag(false);
  };

  useEffect(() => {
    const currentNode = nodes?.find((it) => it.id === userId);
    const node = currentNode?.fieldData;
    if (node?.title) {
      setTitle(node?.title);
      setTextFields(node?.userResponse);
      setSelectedValues(node?.keywords);
    }
  }, [userId]);

  return (
    <Card style={styles.customCard}>
      <form>
        <div style={styles.topCard}>
          <div style={styles.innerCard}>
            <div style={styles.userHeader}>
              <PersonIcon />
              <Typography variant="span" style={styles.headerTitle}>
                USER INPUT
              </Typography>
            </div>
            <div style={styles.headerIconsMainDiv}>
              <IconButton style={styles.headerIconsDiv} onClick={handleClose}>
                <CloseIcon style={styles.headerIcons} />
              </IconButton>
              <IconButton style={styles.headerIconsDiv} onClick={handleCheck}>
                <CheckIcon style={styles.headerIcons} />
              </IconButton>
            </div>
          </div>

          <TextField
            placeholder="Type block title"
            size="small"
            style={styles.titleTextField}
            onChange={handleBotTitle}
            error={titleError}
            value={title}
            inputProps={{
              maxLength: 40,
            }}
            required
          />
          {titleCheckFlag && (
            <Typography
              sx={{
                fontSize: "14px",
                color: "#E63946",
                fontWeight: 600,
                fontFamily: "Inter",
                position: "relative",
                bottom: "10px",
                left: "3px",
              }}
            >
              {errorMessage}
            </Typography>
          )}
        </div>
        <div style={styles.responseSection}>
          <div style={styles.responseDiv}>
            <IconButton style={styles.iconsButton}>
              <CustomTooltip
                title="Keyword is a matching system in bot. It works great when you want a unique phrase or word to trigger a bot response."
                position="left"
                fontBold={500}
              >
                <HelpOutlineIcon style={styles.icons} />
              </CustomTooltip>
            </IconButton>
            <Typography variant="span" style={styles.labels}>
              Keywords
            </Typography>
          </div>
          <div>
            <MuiChipsInput
              value={selectedValues}
              onChange={handleChipsChange}
              fullWidth
            />
            {keywordExists && (
              <Typography
                sx={{
                  marginTop: "10px",
                  fontSize: "14px",
                  color: "#E63946",
                  fontWeight: 600,
                  fontFamily: "Inter",
                }}
              >
                Keyword already exists!
              </Typography>
            )}

            {/* <Autocomplete
              multiple
              fullWidth
              id="custom-autocomplete"
              options={[]}
              value={selectedValues}
              onChange={(_, newValue) => setSelectedValues(newValue) || []}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="+ Add Keyword"
                  placeholder="Enter Keyword and press Enter"
                  value={userInput}
                  onChange={handleInputChange}
                  onKeyDown={handleEnterKeyPress}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    label={option}
                    f
                    onDelete={() => handleDeleteValue(option)}
                    deleteIcon={<CloseIcon />}
                    {...getTagProps({ index })}
                  />
                ))
              }
            /> */}
          </div>
          <div style={styles.responseDiv}>
            <IconButton style={styles.iconsButton}>
              <CustomTooltip
                title="User's says matching system in bot. This system analyzes the full user query to pair it with the bot response that matches the best."
                position="left"
                fontBold={500}
              >
                <HelpOutlineIcon style={styles.icons} />
              </CustomTooltip>
            </IconButton>
            <Typography variant="span" style={styles.labels}>
              User Says
            </Typography>
          </div>

          {textFields &&
            textFields.length > 0 &&
            textFields.map((value, index) => (
              <div
                key={index}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div style={{ display: "flex" }}>
                  <TextField
                    variant="outlined"
                    placeholder="Enter user message"
                    style={{
                      marginBottom: "20px",
                      borderRadius: "8px",
                      fontSize: "50px",
                      backgroundColor: "#fff",
                      fontFamily: "Source Sans Pro, Roboto, sans-serif",
                    }}
                    inputProps={{
                      maxLength: 256,
                    }}
                    key={index}
                    fullWidth
                    value={value}
                    onChange={(e) =>
                      handleTextFieldChange(index, e.target.value)
                    }
                    error={userResponsesError}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       <IconButton
                    //         sx={inputFieldStyles.iconButton}
                    //         onClick={handleAttributePopover}
                    //       >
                    //         <DataObjectIcon sx={inputFieldStyles.icon} />
                    //       </IconButton>
                    //       <UserInputAttributes
                    //         attributeAnchorEl={attributeAnchorEl}
                    //         setAttributeAnchorEl={setAttributeAnchorEl}
                    //         handleAttributeSelect={handleAttributeSelect}
                    //         arrIndex={index}
                    //       />

                    //       <IconButton
                    //         sx={inputFieldStyles.iconButton}
                    //         onClick={handleEmojiPicker}
                    //       >
                    //         <InsertEmoticonIcon sx={inputFieldStyles.icon} />
                    //       </IconButton>
                    //       <UserInputEmoji
                    //         emojiAnchorEl={emojiAnchorEl}
                    //         setEmojiAnchorEl={setEmojiAnchorEl}
                    //         handleEmojiSelect={handleEmojiSelect}
                    //         arrIndex={index}
                    //       />
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                  {index > 0 && (
                    <IconButton
                      aria-label="Delete"
                      onClick={() => handleDeleteTextField(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
                {duplicateResError[index] && (
                  <Typography
                    sx={{
                      position: "relative",
                      bottom: "16px",
                      // marginTop: "20px",
                      fontSize: "14px",
                      color: "#E63946",
                      fontWeight: 600,
                      fontFamily: "Inter",
                    }}
                  >
                    responses already exists!
                  </Typography>
                )}
              </div>
            ))}
        </div>
      </form>
    </Card>
  );
};

export default Sidebar;
