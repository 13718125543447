import { Typography, TextField, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import TelegramIcon from "@mui/icons-material/Telegram";
import { botStyles } from "../styles/botStyles";
import PropTypes from "prop-types";
import { checkTitleExists } from "../../helper/checkTitleExists";

const styles = botStyles;

const SidebarBotHeader = ({
  nodes,
  handleCheck,
  handleClose,
  botTitle,
  setBotTitle,
  titleError,
  titleCheckFlag,
  setTitleCheckFlag,
}) => {
  const errorMessage =
    botTitle === "" ? "Block title is required" : "Title already exists!";

  const handleBotTitle = (e) => {
    const value = e.target.value;
    setBotTitle(e.target.value);
    if (checkTitleExists({ nodes, title: value })) {
      setTitleCheckFlag(true);
      return;
    }
    setTitleCheckFlag(false);
  };
  return (
    <div style={styles.topCard}>
      <div style={styles.customCardDiv}>
        <div style={styles.customCardHeader}>
          <Typography variant="span" style={styles.customCardBotIcon}>
            <TelegramIcon
              style={{ fontSize: "19px", position: "relative", top: "3px" }}
            />
          </Typography>
          <Typography
            variant="span"
            style={{ fontSize: "16px", fontWeight: 600 }}
          >
            BOT RESPONSE
          </Typography>
        </div>
        <div style={styles.customCardHeaderIconsDiv}>
          <IconButton
            style={styles.customCardHeaderIcons}
            onClick={handleClose}
          >
            <CloseIcon style={{ fontSize: "20px" }} />
          </IconButton>
          <IconButton
            style={styles.customCardHeaderIcons}
            onClick={handleCheck}
          >
            <CheckIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </div>
      </div>
      <TextField
        size="small"
        placeholder="Type block title"
        value={botTitle}
        style={styles.customCardTitleField}
        onChange={handleBotTitle}
        inputProps={{
          maxLength: 40,
        }}
        error={titleError}
        required
      />
      {titleCheckFlag && (
        <Typography
          sx={{
            fontSize: "14px",
            color: "#E63946",
            fontWeight: 500,
            position: "relatve",
            marginTop: "20px",
            fontFamily: "Inter",
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

SidebarBotHeader.propTypes = {
  handleCheck: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  botTitle: PropTypes.string.isRequired,
  titleCheckFlag: PropTypes.bool.isRequired,
  setBotTitle: PropTypes.func.isRequired,
  setTitleCheckFlag: PropTypes.func.isRequired,
  titleError: PropTypes.bool.isRequired,
};

export default SidebarBotHeader;
