import { Typography, IconButton, Box } from "@mui/material";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { botFieldStyles } from "../styles/botFieldStyles";

const styles = botFieldStyles;

const AddBotField = ({ handleOnClick }) => {
  const handleOnDrag = (e, widgetType) => {
    e.dataTransfer.setData("WidgetType", widgetType);
  };

  return (
    <Box style={styles.mainDiv}>
      <Typography style={styles.title}>&nbsp; &nbsp;Responses</Typography>
      <div style={styles.cardComponents}>
        <div
          style={styles.coverComponent}
          draggable
          onDragStart={(e) => {
            handleOnDrag(e, "Text");
          }}
          onClick={() => handleOnClick("Text")}
        >
          <IconButton sx={styles.iconCompnentsDiv}>
            <TextFieldsIcon style={styles.iconComponent} />
          </IconButton>
          <Typography variant="span" style={styles.iconText}>
            Text
          </Typography>
        </div>

        <div
          style={styles.coverComponent}
          draggable
          onDragStart={(e) => {
            handleOnDrag(e, "Image");
          }}
          onClick={() => handleOnClick("Image")}
        >
          <IconButton sx={styles.iconCompnentsDiv}>
            <AddPhotoAlternateOutlinedIcon style={styles.iconComponent} />
          </IconButton>
          <Typography variant="span" style={styles.iconText}>
            Image
          </Typography>
        </div>

        {/* <div
          style={styles.coverComponent}
          draggable
          onDragStart={(e) => {
            handleOnDrag(e, "Gallery");
          }}
          onClick={() => handleOnClick("Gallery")}
        >
          <IconButton sx={styles.iconCompnentsDiv}>
            <CollectionsOutlinedIcon style={styles.iconComponent} />
          </IconButton>
          <Typography variant="span" style={styles.iconText}>
            Gallery
          </Typography>
        </div> */}

        <div
          style={styles.coverComponent}
          draggable
          onDragStart={(e) => {
            handleOnDrag(e, "Button");
          }}
          onClick={() => handleOnClick("Button")}
        >
          <IconButton sx={styles.iconCompnentsDiv}>
            <InboxOutlinedIcon style={styles.iconComponent} />
          </IconButton>
          <Typography variant="span" style={styles.iconText}>
            Button
          </Typography>
        </div>

        <div
          style={styles.coverComponent}
          draggable
          onDragStart={(e) => {
            handleOnDrag(e, "QuickReply");
          }}
          onClick={() => handleOnClick("QuickReply")}
        >
          <IconButton sx={styles.iconCompnentsDiv}>
            <SmartButtonIcon style={styles.iconComponent} />
          </IconButton>
          <Typography variant="span" style={styles.iconText}>
            Quick Reply
          </Typography>
        </div>

        <div
          style={styles.coverComponent}
          draggable
          onDragStart={(e) => {
            handleOnDrag(e, "Video");
          }}
          onClick={() => handleOnClick("Video")}
        >
          <IconButton sx={styles.iconCompnentsDiv}>
            <SlideshowIcon style={styles.iconComponent} />
          </IconButton>
          <Typography variant="span" style={styles.iconText}>
            Video
          </Typography>
        </div>

        <div
          style={styles.coverComponent}
          draggable
          onDragStart={(e) => {
            handleOnDrag(e, "Document");
          }}
          onClick={() => handleOnClick("Document")}
        >
          <IconButton sx={styles.iconCompnentsDiv}>
            <AttachFileIcon style={styles.iconComponent} />
          </IconButton>
          <Typography variant="span" style={styles.iconText}>
            Documents
          </Typography>
        </div>
      </div>
    </Box>
  );
};

export default AddBotField;
