import {
  Cancel,
  Computer,
  DragIndicator,
  Smartphone,
  TabletAndroid,
} from "@material-ui/icons";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { InsertLink, Upload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  arrayMove,
  getAxiosError,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  isValidImageUrl,
} from "../../../helper";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import {
  deleteBannerFromRedux,
  getAllSilder,
  replaceSlidinRedux,
} from "../../../redux/actions/pagesActions";
import ProductMediaLibrary from "../../components/ProductMediaLibrary";
import Switch from "rc-switch";

const Banners = () => {
  const navArray = [
    { label: "Desktop" },
    { label: "Tablet" },
    { label: "Mobile" },
  ];
  const dispatch = useDispatch();
  const reduxAllSilder = useSelector((state) => state.pages.allSlider) || [];
  const reduxFetchingSilder = useSelector(
    (state) => state.pages.fetchingSlider
  );
  useEffect(() => {
    if (!isArrayWithValues(reduxAllSilder)) dispatch(getAllSilder());
  }, []);

  const [items, setItems] = React.useState([]);
  const [allSlider, setAllSlider] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (isArrayWithValues(reduxAllSilder)) {
      let filterObj = reduxAllSilder
        .sort((a, b) => a.menu_order - b.menu_order)
        ?.map((obj, index) => {
          let { id, title, menu_order, status, featured_media, metadata } = obj;
          let { bg_image_desktop, bg_image_tablet, bg_image_mobile } = metadata;
          let link = isArrayWithValues(metadata?.link) ? metadata?.link[0] : "";
          let newObj = {
            id,
            title: title?.rendered,
            menu_order,
            status,
            featured_media,
            metadata,
            bg_image_desktop,
            bg_image_tablet,
            bg_image_mobile,
            link,
          };
          return newObj;
        });
      let newObj = {};
      let index = 0;
      for (const iterator of filterObj) {
        newObj[index] = iterator;
        index = index + 1;
      }
      setAllSlider(newObj);
    }
  }, [reduxAllSilder]);

  useEffect(() => {
    setItems(
      Object.values(allSlider).map((obj, index) => (
        <BannerCard
          obj={{ ...obj, title: `Slider ${index + 1}` }}
          editSlider={editSlider}
          removeBanner={removeBanner}
        />
      ))
    );
  }, [allSlider]);

  const onSortEnd = (oldIndex, newIndex) => {
    let newArr = arrayMove(items, oldIndex, newIndex);
    console.log(newArr);
    setItems(
      newArr.map((obj, index) => {
        editSlider({ [index]: { ...obj?.props?.obj, menu_order: index } });
        return (
          <BannerCard
            obj={{
              ...obj?.props?.obj,
              title: `Slider ${index + 1}`,
              menu_order: index,
            }}
            editSlider={editSlider}
            removeBanner={removeBanner}
          />
        );
      })
    );
  };

  const editSlider = (payload) => {
    setAllSlider((pre) => ({ ...pre, ...payload }));
  };

  const onAddBanner = () => {
    console.log(items.length, items);
    let newObj = {
      title: `Slider ${items.length + 1}`,
      menu_order: items.length,
      status: `publish`,
      featured_media: ``,
      metadata: {},
      bg_image_desktop: [],
      bg_image_tablet: [],
      bg_image_mobile: [],
      link: "",
    };
    editSlider({ [items.length]: newObj });
  };

  const isEverySliderHasImage = () => {
    // for (const item of Object.values(allSlider)) {
    //   console.log(item?.bg_image_desktop, "item?.bg_image_desktop");

    //   let bg_image_desktop = item?.bg_image_desktop[0]?.url
    //     ? extractImageUrl(item?.bg_image_desktop[0]?.url)
    //     : false;
    //   let bg_image_tablet = item?.bg_image_tablet[0]?.url
    //     ? extractImageUrl(item?.bg_image_tablet[0]?.url)
    //     : false;
    //   let bg_image_mobile = item?.bg_image_mobile[0]?.url
    //     ? extractImageUrl(item?.bg_image_mobile[0]?.url)
    //     : false;

    //   let imageDesktop =
    //     isArrayWithValues(item?.bg_image_desktop) &&
    //     isValidImageUrl(bg_image_desktop)
    //       ? bg_image_desktop
    //       : "";

    //   let imageTablet =
    //     isArrayWithValues(item?.bg_image_tablet) &&
    //     isValidImageUrl(bg_image_tablet)
    //       ? bg_image_tablet
    //       : "";

    //   let imageMobile =
    //     isArrayWithValues(item?.bg_image_mobile) &&
    //     isValidImageUrl(bg_image_mobile)
    //       ? bg_image_mobile
    //       : "";

    //   console.log({ imageDesktop, imageTablet, imageMobile });

    //   if (!imageDesktop && !imageTablet && !imageMobile) {
    //     return false;
    //   }
    // }
    return true;
  };

  const removeBanner = (keyName) => {
    let payload = allSlider;
    delete payload[keyName];
    console.log(payload);
    setAllSlider({ ...payload });
  };

  const onSave = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;

    if (!isEverySliderHasImage()) return false;

    setSaving(true);
    let res;

    for await (const item of Object.values(allSlider)) {
      let bg_image_desktop = item?.bg_image_desktop[0]?.url
        ? extractImageUrl(item?.bg_image_desktop[0]?.url)
        : false;
      let bg_image_tablet = item?.bg_image_tablet[0]?.url
        ? extractImageUrl(item?.bg_image_tablet[0]?.url)
        : false;
      let bg_image_mobile = item?.bg_image_mobile[0]?.url
        ? extractImageUrl(item?.bg_image_mobile[0]?.url)
        : false;

      let imageDesktop =
        isArrayWithValues(item?.bg_image_desktop) &&
        isValidImageUrl(bg_image_desktop)
          ? bg_image_desktop
          : "";

      let imageTablet =
        isArrayWithValues(item?.bg_image_tablet) &&
        isValidImageUrl(bg_image_tablet)
          ? bg_image_tablet
          : "";

      let imageMobile =
        isArrayWithValues(item?.bg_image_mobile) &&
        isValidImageUrl(bg_image_mobile)
          ? bg_image_mobile
          : "";

      let postObj = {
        ...item,
        metadata: {
          bg_image_desktop: { url: imageDesktop },
          bg_image_tablet: { url: imageTablet },
          bg_image_mobile: { url: imageMobile },
          link: item?.link,
        },
      };
      delete postObj.bg_image_desktop;
      delete postObj.bg_image_tablet;
      delete postObj.bg_image_mobile;
      delete postObj.link;

      res = await axios({
        url: `${website}/wp-json/wp/v2/slider${item?.id ? `/${item?.id}` : ""}`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: {
          abc: 13,
        },
        method: "POST",
        data: {
          ...postObj,
          woodmart_slider: "Retail Slider",
        },
      });
      // setSaving(false);
      console.log(res);

      if (res?.data?.id) {
        dispatch(replaceSlidinRedux(res.data));
      } else {
        setGlobalToast({
          show: true,
          message: "Updated Failed",
          severity: "error",
        });
        return;
      }
    }
    setSaving(false);
    dispatch(
      setGlobalToast({
        show: true,
        message: "Banners Updated",
        severity: "success",
      })
    );
  };

  return (
    <Box sx={{ margin: "10px", marginBottom: "60px" }}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        sx={{ mb: 4 }}
      >
        <Typography variant="h3">Banner Settings</Typography>
        <Tooltip
          title={
            !isEverySliderHasImage()
              ? "Every slider needs to have atleast one image (or delete the empty sliders which doesn't have any image)"
              : ""
          }
        >
          <LoadingButton
            // disabled={!isEverySliderHasImage()}
            variant="contained"
            loading={saving}
            onClick={onSave}
          >
            Save
          </LoadingButton>
        </Tooltip>
      </Stack>
      {reduxFetchingSilder ? (
        <Stack
          sx={{ height: "calc(100vh - 200px)", width: "100%" }}
          justifyContent="center"
          alignItems={"center"}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Fetching Banners ...
          </Typography>
          <LinearProgress sx={{ width: "50%" }} />
        </Stack>
      ) : null}
      <Stack spacing={6}>
        <SortableList
          onSortEnd={onSortEnd}
          className="list"
          draggedItemClassName="dragged"
        >
          {items.map((item) => (
            <SortableItem key={item}>
              <div className="item" style={{ margin: "10px" }}>
                {item}
              </div>
            </SortableItem>
          ))}
        </SortableList>
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        sx={{ mt: 4, mr: 5 }}
      >
        {!reduxFetchingSilder && (
          <Button variant="contained" onClick={onAddBanner}>
            Add Banner
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default Banners;

const BannerCard = ({ obj, editSlider, removeBanner }) => {
  const dispatch = useDispatch();
  const iconStyle = {
    height: "40px",
    width: "40px",
    // backgroundColor: "rgba(0,0,0,0.3)",
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
  };

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mediaLibraryOpen, toggleMediaLibrary] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  let successColor = theme.palette.success && theme.palette.success.main;

  const calculateAspectRatio = (width, height) => {
    return width / height;
  };

  const compareAspectRatios = (
    baseWidth,
    baseHeight,
    otherWidth,
    otherHeight
  ) => {
    const baseRatio = calculateAspectRatio(baseWidth, baseHeight);
    const otherRatio = calculateAspectRatio(otherWidth, otherHeight);
    // Round the aspect ratios to 2 decimal places for comparison
    return baseRatio.toFixed(2) === otherRatio.toFixed(2);
  };

  const setBannerImages = (ar, id) => {
    const expectedAspect = giveAspect(id);
    const isValid = compareAspectRatios(
      expectedAspect?.width,
      expectedAspect?.height,
      ar[0]?.media_details?.width,
      ar[0]?.media_details?.height
    );

    if (!isValid) {
      dispatch(
        setGlobalToast({
          show: true,
          message:
            "Dimensions doesn't match, you can still use this image but the appearance might differ",
          severity: "error",
          timeout: 8000,
        })
      );
      // return;
    }

    if (id === "bg_image_desktop")
      editSlider({
        [obj.menu_order]: {
          ...obj,
          featured_media: ar[0]?.id,
          [id]: [{ url: ar[0]?.source_url }],
        },
      });
    else {
      editSlider({
        [obj.menu_order]: { ...obj, [id]: [{ url: ar[0]?.source_url }] },
      });
    }
    console.log(ar);
    toggleMediaLibrary(false);
  };
  const giveAspect = (id) => {
    switch (id) {
      case "bg_image_desktop":
        // code block
        return { width: 1440, height: 600 };

      case "bg_image_tablet":
        // code block
        return { width: 1440, height: 600 };
        break;
      case "bg_image_mobile":
        // code block
        return { width: 865, height: 1280 };
        break;
      default:
      // code block
    }
  };

  const onChangeStatus = async (e) => {
    try {
      let status = e ? "publish" : "draft";
      let website = getWebsite();
      let token = getToken();
      if (!website && !token) return false;
      setUpdating(true);

      const res = await axios({
        url: `${website}/wp-json/wp/v2/slider/${obj?.id}`,
        method: "PUT",
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: {
          status,
        },
      });

      if (res.data?.id) {
        dispatch(replaceSlidinRedux(res.data));
        dispatch(
          setGlobalToast({
            show: true,
            message: "Status Updated",
            severity: "success",
          })
        );
      }
      console.log(res);
    } catch (e) {
      console.log(e);
    } finally {
      setUpdating(false);
    }
  };

  const deleteBanner = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setDeleting(true);
    console.log({ id: obj?.id });
    try {
      const res = await axios({
        url: `${website}/wp-json/wp/v2/slider/${obj?.id}`,
        method: "DELETE",
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: {
          force: true,
        },
      });
      console.log(res);
      if (res.data?.deleted) {
        dispatch(deleteBannerFromRedux(obj?.id));
        dispatch(
          setGlobalToast({
            show: true,
            message: "Banner Deleted!",
            severity: "success",
          })
        );
      }
      console.log(res);
    } catch (error) {
      console.log(getAxiosError(error));
    }
    setDeleting(false);
  };
  const onDeleteBanner = () => {
    if (obj?.id) deleteBanner();
    else {
      removeBanner(obj?.menu_order);
      dispatch(
        setGlobalToast({
          show: true,
          message: "Banner Deleted!",
          severity: "success",
        })
      );
    }
  };

  console.log(obj.menu_order, "obj.menu_order");

  const onRemoveImage = (id) => {
    console.log({ [obj.menu_order]: { ...obj, [id]: [] } }, "deleting...");
    editSlider({ [obj.menu_order]: { ...obj, [id]: [] } });
  };

  const showDeletePrompt = ({ title, id, message, type }) => {
    setShowDeleteConfirmation({ title, id, message, type });
  };

  const onConfirmDelete = () => {
    if (showDeleteConfirmation?.type === "banner")
      onRemoveImage(showDeleteConfirmation?.id);
    if (showDeleteConfirmation?.type === "slider") onDeleteBanner();
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <Card className="shadow_card">
        <Box sx={{ my: 3, margin: "20px", cursor: "pointer" }}>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            sx={{ mb: 2, mt: 2 }}
          >
            <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
              <DragIndicator />
              <Typography variant="h6">
                {/* {title} */}
                {obj?.title}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ gap: "10px" }}>
              <LoadingButton
                color="error"
                loading={deleting}
                onClick={() =>
                  showDeletePrompt({
                    type: "slider",
                    title: "Remove Banner?",
                    message: "Are you sure you want to delete this banner?",
                  })
                }
              >
                Delete
              </LoadingButton>
            </Stack>
          </Stack>
          <Stack
            direction={isMobile ? "column" : "row"}
            justifyContent="space-between"
            sx={{ gap: "20px" }}
          >
            <Box sx={{ width: isMobile ? "100%" : "30%" }}>
              {isArrayWithValues(obj?.bg_image_desktop) &&
              obj?.bg_image_desktop[0]?.url !== "" &&
              isValidImageUrl(
                extractImageUrl(obj?.bg_image_desktop[0]?.url)
              ) ? (
                <Box sx={{ position: "relative" }}>
                  <img
                    src={extractImageUrl(obj?.bg_image_desktop[0]?.url)}
                    style={{
                      maxHeight: "160px",
                      width: "100%",
                      objectFit: "contain",
                      textAlign: "center",
                      borderRadius: "8px",
                    }}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: "-12px",
                      top: "-12px",
                      zIndex: 2,
                      overflow: "hidden",
                    }}
                    onClick={() =>
                      showDeletePrompt({
                        type: "banner",
                        title: "Remove Image?",
                        id: "bg_image_desktop",
                        message: "Are you sure you want to delete this image?",
                      })
                    }
                    aria-label="delete"
                  >
                    <Cancel
                      size="small"
                      color="primary"
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                      }}
                    />
                  </IconButton>
                </Box>
              ) : (
                <Card
                  onClick={() => toggleMediaLibrary("bg_image_desktop")}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    "&:hover": {
                      backgroundColor: "rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <CardContent
                    fullWidth
                    id="outlined"
                    style={{
                      // borderWidth: 2,
                      // borderStyle: "dashed",
                      // borderColor: "#bababa",
                      alignItems: "center",
                      display: "flex",
                    }}
                    hoverTitle=""
                    sx={{ position: "relative" }}
                  >
                    <Box
                      sx={{
                        height: "120px",
                        width: " 100%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        // backgroundColor: "primary.main",
                        borderRadius: "50%",
                      }}
                    >
                      <Stack justifyContent={"center"} alignItems="center">
                        <Typography>1440px X 600px</Typography>
                        <Computer color="rgba(0,0,0,0.3)" style={iconStyle} />
                        <Typography>JPEG, PNG, WEBP</Typography>
                        <Typography style={{ fontSize: 12 }}>
                          under 5MB
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 8,
                            // position: "absolute",
                            left: 2,
                            bottom: 2,
                          }}
                        >
                          GIF/Videos not supported
                        </Typography>
                      </Stack>
                    </Box>
                    <Upload
                      style={{
                        position: "absolute",
                        top: 2,
                        right: 2,
                      }}
                    />
                  </CardContent>
                </Card>
              )}
              <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                {/* {obj?.label} */}
                Desktop{" "}
                {isArrayWithValues(obj?.bg_image_desktop) &&
                obj?.bg_image_desktop[0]?.url !== "" &&
                isValidImageUrl(extractImageUrl(obj?.bg_image_desktop[0]?.url))
                  ? "(1440px X 600px)"
                  : ""}
              </Typography>
            </Box>
            <Box sx={{ width: isMobile ? "100%" : "30%" }}>
              {isArrayWithValues(obj?.bg_image_tablet) &&
              obj?.bg_image_tablet[0]?.url !== "" &&
              isValidImageUrl(extractImageUrl(obj?.bg_image_tablet[0]?.url)) ? (
                <Box sx={{ position: "relative" }}>
                  <img
                    src={extractImageUrl(obj?.bg_image_tablet[0]?.url)}
                    style={{
                      maxHeight: "160px",
                      width: "100%",
                      objectFit: "contain",
                      textAlign: "center",
                      borderRadius: "8px",
                    }}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: "-12px",
                      top: "-12px",
                      zIndex: 2,
                      overflow: "hidden",
                    }}
                    onClick={() =>
                      showDeletePrompt({
                        type: "banner",
                        title: "Remove Image?",
                        id: "bg_image_tablet",
                        message: "Are you sure you want to delete this image?",
                      })
                    }
                    aria-label="delete"
                  >
                    <Cancel
                      size="small"
                      color="primary"
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                      }}
                    />
                  </IconButton>
                </Box>
              ) : (
                <Card
                  onClick={() => toggleMediaLibrary("bg_image_tablet")}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    "&:hover": {
                      backgroundColor: "rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <CardContent
                    fullWidth
                    id="outlined"
                    style={{
                      // borderWidth: 2,
                      // borderStyle: "dashed",
                      // borderColor: "#bababa",
                      alignItems: "center",
                      display: "flex",
                    }}
                    hoverTitle=""
                    sx={{ position: "relative" }}
                  >
                    <Box
                      sx={{
                        height: "120px",
                        width: " 100%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        // backgroundColor: "primary.main",
                        borderRadius: "50%",

                        // position: "relative",
                      }}
                    >
                      <Stack justifyContent={"center"} alignItems="center">
                        <Typography>1440px X 600px</Typography>
                        <TabletAndroid
                          color="rgba(0,0,0,0.3)"
                          style={iconStyle}
                        />
                        <Typography>JPEG, PNG, WEBP</Typography>
                        <Typography style={{ fontSize: 12 }}>
                          under 5MB
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 8,
                            // position: "absolute",
                            left: 2,
                            bottom: 2,
                          }}
                        >
                          GIF/Videos not supported
                        </Typography>
                      </Stack>
                    </Box>
                    <Upload
                      style={{
                        position: "absolute",
                        top: 2,
                        right: 2,
                      }}
                    />
                  </CardContent>
                </Card>
              )}
              <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                {/* {obj?.label} */}
                Tablet{" "}
                {isArrayWithValues(obj?.bg_image_tablet) &&
                obj?.bg_image_tablet[0]?.url !== "" &&
                isValidImageUrl(extractImageUrl(obj?.bg_image_tablet[0]?.url))
                  ? "(1440px X 600px)"
                  : ""}
              </Typography>
            </Box>
            <Box sx={{ width: isMobile ? "100%" : "30%" }}>
              {isArrayWithValues(obj?.bg_image_mobile) &&
              obj?.bg_image_mobile[0]?.url !== "" &&
              isValidImageUrl(extractImageUrl(obj?.bg_image_mobile[0]?.url)) ? (
                <Box sx={{ position: "relative" }}>
                  <img
                    // src={obj?.bg_image_mobile[0]?.url}
                    src={extractImageUrl(obj?.bg_image_mobile[0]?.url)}
                    style={{
                      maxHeight: "160px",
                      width: "100%",
                      objectFit: "contain",
                      textAlign: "center",
                      borderRadius: "8px",
                    }}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: "-12px",
                      top: "-12px",
                      zIndex: 2,
                      overflow: "hidden",
                    }}
                    onClick={() =>
                      showDeletePrompt({
                        type: "banner",
                        title: "Remove Image?",
                        id: "bg_image_mobile",
                        message: "Are you sure you want to delete this image?",
                      })
                    }
                    aria-label="delete"
                  >
                    <Cancel
                      size="small"
                      color="primary"
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                      }}
                    />
                  </IconButton>
                </Box>
              ) : (
                <Card
                  onClick={() => toggleMediaLibrary("bg_image_mobile")}
                  sx={{
                    // display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    "&:hover": {
                      backgroundColor: "rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <CardContent
                    fullWidth
                    id="outlined"
                    style={
                      {
                        // borderWidth: 2,
                        // borderStyle: "dashed",
                        // borderColor: "#bababa",
                      }
                    }
                    hoverTitle=""
                    sx={{ position: "relative" }}
                  >
                    <Box
                      sx={{
                        height: "120px",
                        width: "100%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        // backgroundColor: "primary.main",
                        borderRadius: "50%",

                        // position: "relative",
                      }}
                    >
                      <Stack justifyContent={"center"} alignItems="center">
                        <Typography>865px X 1280px</Typography>
                        <Smartphone color="rgba(0,0,0,0.3)" style={iconStyle} />
                        <Typography>JPEG, PNG, WEBP</Typography>
                        <Typography style={{ fontSize: 12 }}>
                          under 5MB
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 8,
                            // position: "absolute",
                            left: 2,
                            bottom: 2,
                          }}
                        >
                          GIF/Videos not supported
                        </Typography>
                      </Stack>
                    </Box>
                    <Upload
                      style={{
                        position: "absolute",
                        top: 2,
                        right: 2,
                      }}
                    />
                  </CardContent>
                </Card>
              )}
              <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                {/* {obj?.label} */}
                Mobile{" "}
                {isArrayWithValues(obj?.bg_image_mobile) &&
                obj?.bg_image_mobile[0]?.url !== "" &&
                isValidImageUrl(extractImageUrl(obj?.bg_image_mobile[0]?.url))
                  ? "(865px X 1280px)"
                  : ""}
              </Typography>
            </Box>
          </Stack>
          <Stack
            flexDirection={"row"}
            alignItems={"flex-end"}
            justifyContent={"space-between"}
          >
            <Stack>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                marginBottom={1}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "14px" }}
                  marginRight={1}
                >
                  Add Link
                </Typography>
                <Tooltip title="Redirect link">
                  <InfoOutlinedIcon fontSize="small" sx={{ fontSize: 12 }} />
                </Tooltip>
              </Stack>
              <TextField
                placeholder="https://example.com/"
                size="small"
                sx={{ width: isMobile ? "100%" : "100%" }}
                style={{ width: "100%" }}
                onChange={(e) =>
                  editSlider({
                    [obj.menu_order]: {
                      ...obj,
                      link: e.target.value,
                    },
                  })
                }
                value={obj?.link || ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <InsertLink />
                    </InputAdornment>
                  ),
                }}
              />
              <Typography
                sx={{ fontSize: "12px", color: "grey", marginTop: 2 }}
              >
                ⚬ Upon clicking this banner the customer will be redirected to
                the added link.
              </Typography>
            </Stack>
            <Stack>
              <Stack direction={"row"} alignItems="center">
                {updating && (
                  <CircularProgress
                    color="inherit"
                    size={18}
                    style={{ marginRight: 4 }}
                  />
                )}
                <Typography
                  variant="h2"
                  style={{
                    fontSize: 12,
                    marginRight: 4,
                    color: "grey",
                  }}
                >
                  Publish
                </Typography>
                <Switch
                  onChange={onChangeStatus}
                  checked={obj?.status === "publish"}
                  style={
                    obj?.status === "publish"
                      ? {
                          border: `1px solid ${successColor}`,
                          // border: `1px solid #00ff00`,
                          backgroundColor: successColor,
                          // backgroundColor: "#00ff00",
                        }
                      : {}
                  }
                />
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <ProductMediaLibrary
          open={mediaLibraryOpen}
          onChange={toggleMediaLibrary}
          setParentImages={setBannerImages}
          id={mediaLibraryOpen}
          multiple={false}
          aspect={giveAspect(mediaLibraryOpen)}
          // selectedImages={images}
          enable_crop={false}
        />
        <Dialog
          open={isObjWithValues(showDeleteConfirmation)}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => setShowDeleteConfirmation(false)}
          aria-describedby="alert-dialog-slide-description"
          sx={{ py: 3 }}
        >
          <DialogTitle>
            <Typography variant="subtitle2">
              {showDeleteConfirmation?.title}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              {showDeleteConfirmation?.message ||
                "Are you sure you want to delete this banner"}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ mx: 2 }}>
            <Button
              onClick={() => {
                setShowDeleteConfirmation(false);
              }}
            >
              Close
            </Button>
            <LoadingButton
              loading={false}
              color="error"
              variant="contained"
              onClick={onConfirmDelete}
              // disabled={!productAccess.includes("delete") && !isAdmin}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Card>
    </>
  );
};

function extractImageUrl(urlString) {
  try {
    // if (urlString.startsWith("s:")) {
    //   try {
    //     // Extract the URL part from the serialized string
    //     const match = urlString.match(/\"url\";s:\d+:\"(http[^\"]+)\"/);
    //     if (match && match[1]) {
    //       return match[1];
    //     }
    //   } catch (error) {
    //     console.error("Error parsing serialized URL", error);
    //     return urlString; // Return empty string in case of error
    //   }
    // } else if (!urlString.startsWith("https://")) {
    //   try {
    //     let extractedUrl = urlString
    //       ?.split("https://")?.[1]
    //       ?.split(".jpeg")?.[0];
    //     return "https://" + extractedUrl + ".jpeg";
    //   } catch (e) {
    //     console.error("Error parsing serialized URL", e);
    //     return urlString; // Return empty string in case of error
    //   }
    // }

    // If it's a regular URL, return as is
    return urlString;
  } catch (e) {
    console.log(e);
    return urlString;
  }
}
