import { getAxiosError } from "jwero-javascript-utils";
import { backendApiCall } from "../../helper";
import { backendEndpoints } from "../../pages/chat_new/apiFunctions";
import { mysqlDateToJS } from "../../utils/dateFunctions";

const createUserActivity = async ({ payload }) => {
  try {
    let res = await backendApiCall({
      endpoint: `${backendEndpoints.activities}`,
      method: "POST",
      data: payload,
    });
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};
const getActivitiesUser = async ({ params, id }) => {
  try {
    const controller = new AbortController();
    const signal = controller.signal;

    let requestObj = {
      endpoint: `${backendEndpoints?.activities}`,
      // params: { includes: id, inverse_chats: 1 },
      signal,
    };
    if (params) requestObj.params = params;

    let res = await backendApiCall(requestObj);
    if (res.success) return { success: true, data: res?.data };
  } catch (error) {
    console.log(getAxiosError(error), "get chats Error");
  }
};

const getChatUserActivities = async ({ user }) => {
  const res = await getActivitiesUser({
    params: {
      type: "live_agent,task,contact",
      reference_id: user?.id,
      reference_type: "contact",
    },
  });

  if (res?.success) {
    return res?.data?.map((item) => ({
      ...item,
      message_type: "activity",
      time: new Date(mysqlDateToJS(item?.created_at))?.getTime(),
    }));
  }
  return [];
};

export { createUserActivity, getChatUserActivities };
