import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import SubsectionHeader from "../../../components/SubsectionHeader";
import { Cached } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../../components/CustomTable";
import { fetchJweroCampaigns } from "../../../../../redux/actions/marketingActions";
import { isArray } from "jwero-javascript-utils";
import { useDispatch, useSelector } from "react-redux";

function Campaign() {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const campaingsList = useSelector((state) => state?.marketing?.campaigns);
  const fetchingJweroCampaigns = useSelector(
    (state) => state?.marketing?.fetchingJweroCampaigns
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (isArray(campaingsList)) setCampaigns(campaingsList);
  }, [campaingsList]);
  useEffect(() => {
    dispatch(fetchJweroCampaigns());
  }, []);

  const onCreateCampaign = () => {
    navigate(`/campaigns/create`);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <SubsectionHeader
        title={"Campaigns List"}
        primaryButtonProps={{
          startIcon: <Cached />,
          label: "Refresh",
          variant: "outlined",
          // disabled: fetchingJweroBroadcasts,
          // onClick: () => dispatch(fetchJweroBroadcasts()),
        }}
        right={
          <Button size="small" variant="contained" onClick={onCreateCampaign}>
            Create Campaign
          </Button>
        }
      />
      <Box sx={{ mt: 4 }}>
        {/* <CustomTable
          rows={broadcasts}
          tableHeadCells={BroadcastHeads}
          tableRow={TableRow}
        /> */}
      </Box>
    </Box>
  );
}

export default Campaign;

const TableRow = () => {
  return <Box>Hello</Box>;
};
