import { Handle, useNodeId } from "reactflow";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography } from "@mui/material";
import { questionReplies } from "../styles/questionReplies";
import * as actions from "../../../../../../redux/actions/flowsActions";
import AddNodesButton from "../helper/AddNodesButton";
import DoneIcon from "@mui/icons-material/Done";
import NodePopover from "../helper/NodePopover";
import { nodeCanBeAddedErrorMessage } from "../helper/nodeCanBeAdded";
import ShowChatIndicator from "../helper/ShowChatIndicator";
import "../styles/node.css";
import "../styles/node-title.css";

const styles = questionReplies;

const Success = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const nodeId = useNodeId();
  const nodes = useSelector((state) => state.flow?.nodes);
  const title = nodes.find((it) => it.id == nodeId)?.title;
  const edges = useSelector((state) => state.flow?.edges);
  const isDragging = useSelector((state) => state.flow?.isDragging);
  const dragginNodeType = useSelector((state) => state.flow?.dragginNodeType);
  const messageNodeIndicatorId = useSelector(
    (state) => state.flow?.messageNodeIndicatorId
  );
  const isMinimized = useSelector((state) => state.flow?.isMinimized);
  const [isHovered, setIsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsHovered(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { nodeDraggedToId: nodeId },
    });

    if (isDragging) {
      const currentNode = nodes?.find((node) => node.id === nodeId);
      const flag = nodeCanBeAddedErrorMessage({
        nodes,
        edges,
        currentNode,
        nodeAddedType: dragginNodeType,
      });

      if (flag !== "allowed") {
        setErrorMessage(flag);
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setErrorMessage(null);
  };

  const handleMain = () => {
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
  };

  return (
    <Box
      className="nodeContainer"
      sx={styles.mainContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        <div style={{ textAlign: "center" }}>
          <Typography
            variant="span"
            sx={{
              fontSize: "13px",
              alignItems: "center",
              fontFamily: "Inter",
              fontWeight: 600,
              color: "#566e8a",
            }}
          >
            {title || "‎"}
          </Typography>
        </div>
      </div>
      <Box sx={styles.bottomRow}>
        <Handle type="target" position="left" style={styles.leftHandle} />
        <Box sx={styles.nodeBody} onClick={handleMain} className="successNode">
          <IconButton sx={{ ...styles.iconButton, backgroundColor: "#33bd00" }}>
            <DoneIcon sx={styles.nodeIcon} />
          </IconButton>
          <Typography variant="span" sx={styles.titleName}>
            Success
          </Typography>
          <Handle type="source" position="right" style={styles.rightHandle} />
        </Box>
        {messageNodeIndicatorId === nodeId && !isMinimized && (
          <ShowChatIndicator />
        )}
        <NodePopover
          nodeId={nodeId}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
        <AddNodesButton
          handleClick={handleClick}
          isHovered={isHovered}
          nodeId={nodeId}
          errorMessage={errorMessage}
        />
      </Box>
    </Box>
  );
};

export default Success;
