export const filterStyles = {
  customCard: {
    position: "absolute",
    minWidth: "500px",
    maxWidth: "500px",
    maxHeight: "1200px",
    // margin: "0 auto",
    borderRadius: "5px",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    top: "20px",
    right: "20px",
    pointerEvents: "all",
  },
  topCard: {
    display: "flex",
    flexDirection: "column",
    // height: "150px",
    backgroundColor: "#ffffff",
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: "10px",
  },
  innerCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "35px",
  },
  userHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
    fontWeight: 600,
    color: "#59687b",
    justifyContent: "center",
  },
  headerTitle: {
    fontSize: "15px",
    fontWeight: "530",
    marginLeft: "7px",
  },
  headerIconsMainDiv: {
    display: "flex",
    alignContents: "flex-end",
    position: "relative",
    left: "15px",
    bottom: "5px",
  },
  headerIconsDiv: {
    backgroundColor: "#dcdcdc",
    marginRight: "10px",
    borderRadius: "4px",
    height: "30px",
    width: "30px",
  },
  headerIcons: {
    color: "#475e7a",
    fontSize: "20px",
    fontWeight: "600",
  },
  titleTextField: {
    marginTop: "15px",
    marginBottom: "15px",
    borderRadius: "8px",
    fontSize: "50px",
  },
  responseSection: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#dcdcdc",
    overflowY: "auto",
    paddingTop: "30px",
    paddingBottom: "30px",
    maxHeight: "500px",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
  },
  responseDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
    marginRight: "30px",
    marginBottom: "20px",
  },
  iconsButton: {
    color: "black",
    fontWeight: 600,
    position: "relative",
    top: "1px",
  },
  icons: {
    fontSize: "20px",
  },
  innerBox: {
    width: "370px",
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "20px",
  },
  conditionValueBox: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
  },
  conditionButton: {
    backgroundColor: "#f5f5f5",
    color: "black",
    width: "250px",
    marginRight: "8px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
    "&:hover": {
      backgroundColor: "#006cff",
      color: "#fff",
    },
  },
  addFilterTextfield: {
    border: "1px solid black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    height: "40px",
    "&:hover": {
      border: "1px solid #006cff",
      cursor: "pointer",
    },
  },
  addFilterIcon: {
    fontSize: "24px",
    marginLeft: "15px",
    color: "#006cff",
    fontWeight: 600,
  },
  addFilterText: {
    marginLeft: "5px",
    width: "300px",
    textTransform: "none",
    color: "#006cff",
    fontWeight: 600,
  },
  questionTextfield: {
    border: "none",
  },
  optionsInput: {
    marginTop: "10px",
  },
  footerTextBox: {
    fontSize: "15px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    position: "relative",
    "&:hover": {
      cursor: "pointer",
    },
  },
  footerIcon: {
    borderRadius: "50%",
    fontSize: "22px",
    marginRight: "8px",
    color: "#008aff",
    backgroundColor: "#fff",
    position: "relative",
    transition: "0.3 ease",
    top: "6px",
    "&:hover": {
      backgroundColor: "#008aff",
      color: "#fff",
    },
  },
  footerText: {
    backgroundColor: "#dcdcdc",
    position: "relative",
    top: "8px",
    padding: "0 10px",
    color: "#475e7a",
    fontSize: "14px",
    fontWeight: 600,
  },
  conditionalOperator: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px"
  },
  selectBox: {
    width: "75px",
    backgroundColor: "#fff"
  },
  deleteIcon: {
    position: "relative",
    left: "5px",
    top: "5px",
    fontSize: "18px",
    cursor: "pointer",
    color: "#008aff",
    backgroundColor: "#fff",
    borderRadius: "50%",
    padding: "3px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
    "&:hover": {
      backgroundColor: "#008aff",
      color: "#fff",
    },
  },
};
