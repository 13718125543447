export const updateNodePositions = ({
  nodes,
  edges,
  startId,
  updateXBy = 0,
  updateYBy = 0,
}) => {
  // console.log("UPDATING POSITION");
  // console.log(nodes, edges, startId, updateXBy, updateYBy);
  const initialStartEdge = edges?.find(
    (edge) => edge.source === startId
  )?.source;
  if (!initialStartEdge) {
    return nodes;
  }
  const edgesToTraverse = [initialStartEdge];
  const nodesToUpdateId = [];

  while (edgesToTraverse.length > 0) {
    const currentNodeId = edgesToTraverse.shift();
    const edgesToUpdate = edges.filter((edge) => edge.source === currentNodeId);
    const edgesToUpdateTarget = edgesToUpdate.map((edge) => edge.target);
    nodesToUpdateId.push(...edgesToUpdateTarget);
    edgesToTraverse.push(...edgesToUpdateTarget);
  }

  const updateNodes = nodes.map((node) => {
    if (nodesToUpdateId.includes(node.id)) {
      // const axisValIncrement = {
      //   x: updateXBy ? node.position.x + 200 : node.pos.x,
      //   y: updateXBy ? node.position.y : node.position.y,
      // };

      return {
        ...node,
        // position: axisValIncrement,
        position: {
          ...node.position,
          y: node.position.y + updateYBy,
          x: node.position.x + updateXBy,
        },
      };
    }
    return node;
  });

  return updateNodes;
};
