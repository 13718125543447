export const botMessageStyles = {
    chatHeader: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "7px",
        marginBottom: "-1px",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    chatHeaderLogo: {
        width: 12,
        height: 12,
        marginRight: "5px",
        backgroundBlendMode: "normal",
    },
    chatHeaderText: {
        fontSize: "13px",

    },
    chatFooter: {
        display: "flex",
          flexDirection: "row",
    },
    chatFooterTime: {
        fontFamily: "Source Sans Pro, Roboto, sans-serif",
        fontSize: "13px",
        marginTop: "3px",
        marginLeft: "8px",
        marginBottom: "25px",
    }
}