import axios from "axios";
import { callSocket, getToken, getWebsite, isDev } from "../../../helper";
import { isArrayWithValues } from "jwero-javascript-utils";
// import { callSocket, getWebsite } from "../../helper";

const updateProductPricing = async (
  ids,
  { defaultSettings, defaultAllTaxes }
) => {
  await callSocket({
    endpoint: "master_update_hook",
    method: "POST",
    data: {
      website: getWebsite(),
      notMaster: true,
      ids,
      defaultSettings,
      defaultAllTaxes,
      is_dev: isDev(),
    },
  });
};

const fetchProductAttributes = async () => {
  let website = await getWebsite();
  let token = await getToken();

  if (website && token) {
    let { data } = await axios({
      url: `${website}/wp-json/wc/v3/products/attributes`,
      headers: { Authorization: `Basic ${token}` },
    });
    if (isArrayWithValues(data)) return data;
  }
};

export { fetchProductAttributes, updateProductPricing };
