import { DoneAllRounded, DoneRounded, InfoOutlined } from "@mui/icons-material";
import { isObjWithValues } from "jwero-javascript-utils";
import React, { useEffect, useState } from "react";
import { getErrorContentFromWhatsapp, getErrorMessage } from "../../utils";
import { Tooltip } from "@mui/material";

function MessageStatusIcon({ message }) {
  let [status, setStatus] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    if (!isObjWithValues(message)) return;
    let status = "";
    let { statuses } = message;
    status = statuses?.read
      ? "read"
      : statuses?.delivered
      ? "delivered"
      : statuses?.sent
      ? "sent"
      : statuses?.failed
      ? "failed"
      : null;
    setStatus(status);
  }, [message]);

  useEffect(() => {
    let error = getErrorMessage(message);
    if (error?.success) {
      setError(error.errorMessage);
    }
  }, [status]);

  return (
    <span>
      {status === "sent" ? (
        <DoneRounded
          fill="#8caea5"
          style={{
            marginLeft: "5px",
            color: "#8caea5",
            height: "18px",
            width: "18px",
          }}
        />
      ) : status === "read" || status === "delivered" ? (
        <DoneAllRounded
          fill={status === "read" ? "#53bdeb" : "#8caea5"}
          style={{
            marginLeft: "5px",
            color: status === "read" ? "#53bdeb" : "#8caea5",
            height: "18px",
            width: "18px",
          }}
        />
      ) : status === "failed" ? (
        <Tooltip title={error}>
          <InfoOutlined
            fill="rgb(236 89 89)"
            style={{
              marginLeft: "5px",
              color: "rgb(236 89 89)",
              height: "15px",
              width: "15px",
            }}
          />
        </Tooltip>
      ) : null}
    </span>
  );
}

export default MessageStatusIcon;
