import { Handle, useNodeId } from "reactflow";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Tooltip } from "@mui/material";
import { pushNotificationStyles } from "../styles/pushNotificationStyles";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NodePopover from "../helper/NodePopover";
import ShowChatIndicator from "../helper/ShowChatIndicator";
import * as actions from "../../../../../../redux/actions/flowsActions";
import { nodeCanBeAddedErrorMessage } from "../helper/nodeCanBeAdded";
import AddIcon from "@mui/icons-material/Add";
import { nodeAddedOnHandle } from "../utils/nodeAddedOnHandle";
import NodeTop from "../helper/NodeTop";
import "../styles/node.css";
import "../styles/node-title.css";

const styles = pushNotificationStyles;

const handlesObj = [
  { label: "Displayed", value: "displayed_handle" },
  { label: "Dismissed", value: "dismissed_handle" },
  // { label: "Failed", value: "notification_failed_handle" },
  // { label: "Reopened", value: "notification_reopened_handle" },
  // { label: "Opened", value: "notification_opened_handle" },
  // { label: "Failed", value: "notification_failed_handle" },
];

const PushNotification = () => {
  const dispatch = useDispatch();
  const nodeId = useNodeId();
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const isDragging = useSelector((state) => state.flow?.isDragging);
  const dragginNodeType = useSelector((state) => state.flow?.dragginNodeType);
  const messageNodeIndicatorId = useSelector(
    (state) => state.flow?.messageNodeIndicatorId
  );
  const isMinimized = useSelector((state) => state.flow?.isMinimized);
  const title = nodes.find((node) => node.id === nodeId)?.fieldData?.title;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentAddHandle, setCurrentAddhandle] = useState(null);

  const handleClick = (e, handlePosition) => {
    console.log(handlePosition);
    e.stopPropagation();
    const flag = nodeAddedOnHandle({
      edges: edges,
      currentNodeId: nodeId,
      handleId: handlePosition,
    });

    if (flag) {
      return;
    }

    setCurrentAddhandle(handlePosition);
    setAnchorEl(e.currentTarget);
    setIsHovered(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { nodeDraggedToId: nodeId },
    });

    if (isDragging) {
      const currentNode = nodes?.find((node) => node.id === nodeId);
      const flag = nodeCanBeAddedErrorMessage({
        nodes,
        edges,
        currentNode,
        nodeAddedType: dragginNodeType,
      });

      if (flag !== "allowed") {
        setErrorMessage(flag);
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setErrorMessage(null);
  };

  const handleMain = () => {
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { openNotificationSidebar: true },
    });
  };

  return (
    <Box
      className="nodeContainer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{ paddingBottom: "30px", paddingLeft: "30px" }}
    >
      <Box
        sx={{
          height: "20px",
          width: "180px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
          fontFamily: "Inter",
          position: "relative",
          top: "6px",
        }}
      >
        <NodeTop
          title={title}
          isHovered={isHovered}
          isDragging={isDragging}
          nodeId={nodeId}
        />
      </Box>
      <Box sx={styles.nodeBox}>
        <Handle type="target" position="left" style={styles.leftHandle} />

        <Box sx={{ ...styles.nodeBody, pl: 4 }} onClick={handleMain}>
          <Box sx={styles.nodeInnerBox}>
            <NotificationsActiveIcon sx={styles.nodeIcon} />
            <Typography
              variant="span"
              style={{ ...styles.titleName, fontSize: "7px" }}
            >
              Push Notification
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ ...styles.handleBox }}>
            {handlesObj.map((handle) => (
              <Box key={handle?.value} sx={styles.handleInnerBox}>
                <Typography variant="span" sx={styles.handleText}>
                  {handle?.label}
                </Typography>
                <AddIcon
                  style={{ height: "10px", width: "10px" }}
                  sx={styles.addIcon}
                  onClick={(e) => handleClick(e, handle?.value)}
                />
                <Handle
                  type="source"
                  position="right"
                  id={handle?.value}
                  style={styles.rightHandle}
                />
              </Box>
            ))}
          </Box>
          {/* <Box sx={{ position: "absolute", top: "17px", right: "-20px"}}>
          <BounceLoader color="#3185FC" speedMultiplier="0.8" size="80px" />
        </Box> */}
        </Box>
        {messageNodeIndicatorId === nodeId && !isMinimized && (
          <ShowChatIndicator />
        )}
        <NodePopover
          nodeId={nodeId}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          currentAddHandle={currentAddHandle}
          handlesObj={handlesObj}
        />
      </Box>
    </Box>
  );
};

export default PushNotification;
