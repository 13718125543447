export const droppableNodeObj = [
  {
    id: "01",
    value: "bot",
  },
  {
    id: "02",
    value: "user",
  },
  {
    id: "03",
    value: "whatsapp-template",
  },
  {
    id: "04",
    value: "product-catalog",
  },
  {
    id: "05",
    value: "question",
  },
  {
    id: "06",
    value: "filter",
  },
  {
    id: "07",
    value: "go-to-step",
  },
  {
    id: "08",
    value: "live-agent",
  },
  {
    id: "09",
    value: "subscribe",
  },
  {
    id: "10",
    value: "unsubscribe",
  },
  {
    id: "11",
    value: "email",
  },
  {
    id: "12",
    value: "sms",
  },
  {
    id: "13",
    value: "push-notification",
  },
  {
    id: "14",
    value: "delay"
  },
  {
    id: "15",
    value: "metal-rates"
  },
  {
    id: "16",
    value: "create-task"
  },
  {
    id: "17",
    value: "assign"
  },
  {
    id: "18",
    value: "user-attachment"
  },
  {
    id: "19",
    value: "fallback"
  }
];
