import { Box, Grid, useTheme } from "@mui/material";
import React from "react";
import HorizontalStack from "../../../../../../helper/utility/HorizontalStack";
import PlanSwitchWarning from "./PlanSwitchWarning";
import {
  PrimaryLabel,
  SecondaryLabel,
} from "../../../../../../helper/utility/Labels";
import CustomField from "../../../../../../helper/utility/CustomField";
import CustomTooltip from "../../../../../flows/pages/addFlow/components/CustomTooltip";
import { ADD_PLAN_TOOLTIPS } from "../../../../constants";
import { InfoOutlined } from "@mui/icons-material";

function FixedPlanReward({ form, editForm }) {
  const theme = useTheme();
  let mainBackgroundColor = theme.palette.background?.paper;

  const onRewardTypeChange = () => {
    editForm({ reward_type: "custom" });
  };

  return (
    <Box>
      <HorizontalStack sx={{ justifyContent: "space-between" }}>
        <HorizontalStack spacing={2}>
          <PrimaryLabel>
            Fixed Plan Reward (Lumpsum){" "}
            <SecondaryLabel component="span">(Optional)</SecondaryLabel>
          </PrimaryLabel>
          <CustomTooltip title={ADD_PLAN_TOOLTIPS.add_fixed_reward}>
            <InfoOutlined style={{ height: "14px", width: "14px" }} />
          </CustomTooltip>
        </HorizontalStack>
        <HorizontalStack spacing={2}>
          <PlanSwitchWarning
            editForm={editForm}
            onRewardTypeChange={onRewardTypeChange}
            currentType="fixed"
          />
          {/* <Button
            variant="outlined"
            startIcon={<Add />}
            size="small"
            onClick={() => setShowAdd(true)}
          >
            Add Phase
          </Button> */}
        </HorizontalStack>
      </HorizontalStack>

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={6}>
          <CustomField
            label={"Add a fixed reward"}
            value={form?.lumpsum_reward_value}
            onChange={(e) => editForm({ lumpsum_reward_value: e })}
            backgroundColor={mainBackgroundColor}
            helperText={
              "Fixed amount as bonus or incentives to be rewarded to the customer at the end of the plan"
            }
            textFieldProps={{
              type: "number",
              onInput: (e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 7);
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default FixedPlanReward;
