import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nodesOptionsStyles } from "../styles/nodesOptionStyles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, Box, Typography, Popover } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";
import * as actions from "../../../../../../redux/actions/flowsActions";
import "../styles/node-title.css";
import "../styles/node.css";
import { checkIfValid } from "../utils/checkIfValid";
import { checkIflast } from "./checkIfLast";
import CustomConfirmationDialog from "../../../../../../helper/utility/CustomConfirmationDialog";

const styles = nodesOptionsStyles;

const NodeOptions = ({ nodeId, setIsHovered }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const nodeType = nodes.find((it) => it.id == nodeId)?.type;
  const [canBeDeleted, setCanBeDeleted] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const chatbotType = useSelector((state) => state.flow?.chatbotType);
  const confirmationRef = useRef();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    // setIsHovered(false)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const SidebarType = (type) => {
    if (type === "bot") {
      dispatch({ type: actions.OPEN_SIDEBAR_BOT });
    } else if (type === "user") {
      dispatch({ type: actions.OPEN_SIDEBAR_USER });
    } else if (type === "user-attachment") {
      dispatch({
        type: actions.SIDEBAR_USER_ATTACHMENT,
        payload: { openUserAttachmentSidebar: true },
      });
    } else if (type === "filter") {
      dispatch({
        type: actions.SIDEBAR_FILTER,
        payload: { openFilterSidebar: true },
      });
    } else if (type === "question") {
      dispatch({
        type: actions.SIDEBAR_QUESTION,
        payload: { openQuestionSidebar: true },
      });
    } else if (type === "go-to-step") {
      dispatch({
        type: actions.SIDEBAR_GO_TO_STEP,
        payload: { openGoToStepSidebar: true },
      });
    } else if (type === "whatsapp-template") {
      dispatch({
        type: actions.SIDEBAR_WHATSAPP_TEMPLATE,
        payload: { openWhatsappTemplateSidebar: true },
      });
    } else if (type === "delay") {
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openDelaySidebar: true },
      });
    } else if (type === "email") {
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openEmailSidebar: true },
      });
    } else if (type === "sms") {
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openSmsSidebar: true },
      });
    } else if (type === "push-notification") {
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openNotificationSidebar: true },
      });
    }
  };

  const invalidDeleteSingleNodes = [
    "question",
    "whatsapp-template",
    "sms",
    "email",
    "push-notification",
  ];

  const notDeleteChild = ["success", "failure"];
  const notEditable = ["success", "failure", "fallback"];

  const allowedEditable = () => {
    if (notEditable.includes(nodeType)) {
      return false;
    }
    return true;
  };

  const lastAndDeletable = ["user", "filter", "fallback", "user-attachment"];

  const checkIfSingleAttached = () => {
    const node = nodes.find((node) => node?.id == nodeId);
    if (lastAndDeletable.includes(nodeType)) {
      const edge = edges?.find((edge) => edge?.target === nodeId);
      console.log("edge", edge);
      const sourceId = edge.source;
      const sourceNode = nodes.find((node) => node?.id === sourceId);
      console.log("sourceNode", sourceNode);
      if (sourceNode?.userConnected > 1) {
        return false;
      }
    }
    return true;
  };

  // if filter -> other node -> filter if other node is deleted don't allow
  const checkPrevAndNextNode = () => {
    if (!isLast) {
      console.log("returned");
      return true;
    }
    const edge = edges?.find((edge) => edge?.target === nodeId);
    const nextNodeEdge = edges?.find((edge) => edge?.source === nodeId);
    const sourceId = edge?.source;
    const sourceNode = nodes.find((node) => node?.id === sourceId);
    const nextNode = nodes.find((node) => node?.id === nextNodeEdge);

    const allowedPrevAndNext = ["bot"];
    if (
      sourceNode?.type === nextNode?.type &&
      !allowedPrevAndNext.includes(sourceNode?.type)
    ) {
      return false;
    }

    return true;
  };

  const allowedDeleteSingle = () => {
    if (!isLast && !invalidDeleteSingleNodes.includes(nodeType)) {
      return true;
    }
    if (
      invalidDeleteSingleNodes.includes(nodeType) ||
      nodeId === "0004" ||
      !checkIfSingleAttached() ||
      !checkPrevAndNextNode()
    ) {
      return false;
    }
    return true;
  };

  const allowedDeleteWithChild = () => {
    if (
      notDeleteChild.includes(nodeType) ||
      nodeId === "0004" ||
      (!isLast && lastAndDeletable.includes(nodeType))
    ) {
      return false;
    }
    return true;
  };

  const handleEditBlock = (e) => {
    e.stopPropagation();
    handleClose();
    if (!allowedEditable()) {
      return;
    }
    setTimeout(() => {
      dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
      SidebarType(nodeType);
    }, 100);
  };

  const handleDeleteSingleBlock = () => {
    handleClose();
    if (!allowedDeleteSingle()) {
      return;
    }
    dispatch({
      type: actions.DELETE_NODE,
      payload: { id: nodeId, type: nodeType },
    });
    dispatch({ type: actions.REMOVE_AND_UPDATE_EDGE, payload: { id: nodeId } });

    console.log(nodeId, "<<<<<< nodeId");
  };

  const handleDeleteWithChild = () => {
    handleClose();
    if (!allowedDeleteWithChild()) {
      return;
    }
    dispatch({
      type: actions.DELETE_NODE,
      payload: { id: nodeId, type: nodeType },
    });
    dispatch({ type: actions.DELETE_WITH_CHILDREN, payload: { id: nodeId } });
  };

  useEffect(() => {
    const flag = checkIflast({ nodes, edges, nodeId });
    setIsLast(!flag);
  }, [nodes, edges, nodeId]);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleClose();
      }
      const canBeDeleted = checkIfValid({
        nodes: nodes,
        edges: edges,
        id: nodeId,
      });
      setCanBeDeleted(canBeDeleted);
    };
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [anchorEl]);

  return (
    <Box className="nodeOptionsContainer" style={styles.mainDiv}>
      <Box style={styles.titleDiv}>
        <Typography variant="span" style={styles.titleField}>
          Edit Settings
        </Typography>
      </Box>
      <Box style={styles.optionsIcon} onClick={handleClick}>
        <IconButton disableRipple>
          <MoreHorizIcon style={styles.icon} />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <Box sx={styles.container}>
            <Box
              onClick={handleEditBlock}
              sx={{
                ...styles.editBox,
                "&:hover": {
                  backgroundColor: "#e3e3e3",
                  cursor: allowedEditable() ? "pointer" : "not-allowed",
                },
              }}
            >
              <ModeEditOutlineOutlinedIcon sx={styles.editIcon} />
              <Typography variant="span" sx={{ fontSize: "14px" }}>
                Edit
              </Typography>
            </Box>
            {chatbotType?.type === "trigger" && (
              <Box
                onClick={handleDeleteSingleBlock}
                sx={{
                  ...styles.editBox,
                  "&:hover": {
                    backgroundColor: "#e3e3e3",
                    cursor: allowedDeleteSingle() ? "pointer" : "not-allowed",
                  },
                }}
              >
                <DeleteOutlineOutlinedIcon
                  sx={{ ...styles.editIcon, color: "red" }}
                />
                <Typography variant="span" sx={{ fontSize: "14px" }}>
                  Delete single block
                </Typography>
              </Box>
            )}
            <Box
              // onClick={handleDeleteWithChild}
              onClick={() => confirmationRef?.current?.open()}
              sx={{
                ...styles.editBox,
                "&:hover": {
                  backgroundColor: "#e3e3e3",
                  cursor: allowedDeleteWithChild() ? "pointer" : "not-allowed",
                },
              }}
            >
              <DeleteOutlineOutlinedIcon
                sx={{ ...styles.editIcon, color: "red" }}
              />
              <Typography variant="span" sx={{ fontSize: "14px" }}>
                Delete sub blocks
              </Typography>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                "&:hover": { cursor: "pointer" },
              }}
            >
              <CloseFullscreenOutlinedIcon
                sx={{
                  fontSize: "20px",
                  marginRight: "10px",
                }}
              />
              <Typography variant="span" sx={{ fontSize: "14px" }}>
                Collapse
              </Typography>
            </Box> */}
          </Box>
        </Popover>
      </Box>
      <CustomConfirmationDialog
        ref={confirmationRef}
        onClose={() => confirmationRef?.current?.close()}
        title={"Delete all sub blocks?"}
        // content={"This action cannot be undone"}
        subtitle={"This action cannot be undone"}
        onSubmit={handleDeleteWithChild}
        submitButtonProps={{ color: "error", label: "Delete" }}
      />
    </Box>
  );
};

export default NodeOptions;
