import { checkIfLastNode } from "../../../../../../redux/utils/flow/checkIfLastNode";

export const checkIfValid = ({ nodes, edges, id }) => {
  const nodeAllowedTypes = ["user", "user-attachment"];
  const deleteFromNode = nodes.find((node) => node.id === id)?.type;
  if (nodeAllowedTypes.includes(deleteFromNode)) {
    if (!checkIfLastNode({ id, edges })) {
      return true;
    }
    const findSourceId = edges?.find((edge) => edge.target === id).source;
    const sourceNode = nodes.find((node) => node.id === findSourceId);
    if (sourceNode.userConnected > 1) {
      return false;
    }
  }

  return true;
};
