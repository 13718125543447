export const typingIndicatorStyles = {
  mainDiv: {
    display: "flex",
    flexDirection: "row",
    margin: "4px",
  },
  typingTextField: {
    padding: "15px",
    background: "rgb(255, 255, 255)",
    borderTopRightRadius: "15px",
    borderTopLeftRadius: "15px",
    borderBottomRightRadius: "15px",
    borderBottomLeftRadius: "4px",
    display: "inline-block",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
  },
};
