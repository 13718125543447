import { useState, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FilterOptionsPopover from "../helper/FilterOptionsPopover";
import FilterAdd from "./FilterAdd";
import { filterStyles } from "../styles/filterStyles";
import ConditionValuePopover from "../helper/ConditionValuePopover";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { getRandomId } from "../../utils";
import { conditionButtons } from "../utils/conditionButtons";
import DeleteAndSwap from "../../components/DeleteAndSwap";

const styles = filterStyles;

const getConditionById = (conditionId) => {
  const currentButton = conditionButtons.find(
    (button) => button.id === conditionId
  );
  return currentButton?.boxLabel;
};
const FilterSection = ({
  filters,
  setFilters,
  onAddFilter,
  mainCondition,
  setMainCondition,
}) => {
  const targetDivRef = useRef(null);

  const [optionsAnchorEl, setOptionsAnchorEl] = useState({
    openedPopoverId: null,
    anchorEl: null,
  });
  const [condValAnchorEl, setCondValAnchorEl] = useState({
    openedPopoverId: null,
    anchorEl: null,
  });

  const handleChangeOperator = (e) => {
    const value = e.target.value;
    setMainCondition(value);
  };

  const inputOptions = ["and", "or"];

  const handleClickPopover = (e, index) => {
    const defaultFilter = {
      id: getRandomId(),
      attribute: "",
      condition: "",
      value: "",
      selectedButtonId: "BUTTON_1",
      filterType: "or",
    };

    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      if (index >= 0 && index < newFilters.length) {
        newFilters[index] = [...newFilters[index], defaultFilter];
      }
      return newFilters;
    });
    setOptionsAnchorEl({
      openedPopoverId: defaultFilter?.id,
      anchorEl: e.currentTarget,
    });
  };

  const handleClosePopover = () => {
    setOptionsAnchorEl({ openedPopoverId: null, anchorEl: null });
  };

  const handleBotFieldDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(filters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFilters(items);
  };

  const handleFilterObjDel = (index, id) => {
    setFilters((prevFilters) =>
      prevFilters.map((subArray, i) => {
        if (i === index) {
          return subArray.filter((obj) => obj.id !== id);
        } else {
          return subArray;
        }
      })
    );
  };

  const handleFilterTypeChange = (e, index, id) => {
    const value = e.target.value;
    setFilters((prevFilters) =>
      prevFilters.map((subArray, i) => {
        if (i === index) {
          return subArray.map((obj) => {
            if (obj.id === id) {
              const updatedObj = { ...obj, filterType: value };
              return updatedObj;
            } else {
              return obj;
            }
          });
        } else {
          return subArray;
        }
      })
    );
  };
  return (
    <DragDropContext onDragEnd={handleBotFieldDragEnd}>
      <Droppable droppableId="botFields">
        {(provided) => (
          <Box
            sx={styles.responseSection}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {filters &&
              filters.length > 0 &&
              filters.map((filter, index) => (
                <Draggable
                  key={index}
                  draggableId={index.toString()}
                  index={index}
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          bottom: "50px",
                        }}
                      >
                        <DeleteAndSwap setBotData={setFilters} index={index} />
                      </Box>
                      <Box sx={styles.responseDiv}>
                        <Box sx={styles.innerBox}>
                          {filter &&
                            filter.length > 0 &&
                            filter?.map((fltrObj) => (
                              <>
                                {fltrObj?.attribute !== "" && (
                                  <Box
                                    sx={styles.conditionValueBox}
                                    key={fltrObj?.id}
                                  >
                                    <Button
                                      sx={styles.conditionButton}
                                      size="small"
                                      onClick={(e) =>
                                        setCondValAnchorEl({
                                          openedPopoverId: fltrObj?.id,
                                          anchorEl: e?.currentTarget,
                                        })
                                      }
                                    >
                                      {fltrObj?.attribute + " "}
                                      {getConditionById(
                                        fltrObj?.selectedButtonId
                                      ) + " "}{" "}
                                      {fltrObj?.value}
                                    </Button>
                                    <Select
                                      value={fltrObj?.filterType}
                                      onChange={(e) =>
                                        handleFilterTypeChange(
                                          e,
                                          index,
                                          fltrObj?.id
                                        )
                                      }
                                      sx={styles.selectBox}
                                    >
                                      {inputOptions &&
                                        inputOptions.map((option, index) => (
                                          <MenuItem key={index} value={option}>
                                            {option}
                                          </MenuItem>
                                        ))}
                                    </Select>

                                    <DeleteOutlineIcon
                                      sx={styles.deleteIcon}
                                      onClick={() =>
                                        handleFilterObjDel(index, fltrObj?.id)
                                      }
                                    />
                                  </Box>
                                )}
                                <FilterOptionsPopover
                                  optionsAnchorEl={optionsAnchorEl}
                                  setOptionsAnchorEl={setOptionsAnchorEl}
                                  setFilter={setFilters}
                                  fltrObj={fltrObj}
                                  handleClosePopover={handleClosePopover}
                                  setCondValAnchorEl={setCondValAnchorEl}
                                  targetDivRef={targetDivRef}
                                  index={index}
                                />
                                <ConditionValuePopover
                                  condValAnchorEl={condValAnchorEl}
                                  setCondValAnchorEl={setCondValAnchorEl}
                                  fltrObj={fltrObj}
                                />
                              </>
                            ))}

                          <Box
                            sx={styles.addFilterTextfield}
                            ref={targetDivRef}
                            onClick={(e) => handleClickPopover(e, index)}
                          >
                            <AddOutlinedIcon sx={styles.addFilterIcon} />
                            <Typography
                              variant="span"
                              sx={styles.addFilterText}
                            >
                              Add new filter
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={styles.conditionalOperator}>
                          <Select
                            value={mainCondition}
                            onChange={handleChangeOperator}
                            sx={styles.selectBox}
                          >
                            {inputOptions &&
                              inputOptions.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                          </Select>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
            <FilterAdd onAddFilter={onAddFilter} />
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default FilterSection;
