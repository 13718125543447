import FileViewer from "react-file-viewer";

const ShowDocuments = ({ fileURL }) => {
  return (
    <FileViewer
      fileType="pdf"
      filePath={fileURL}
      onError={(e) => console.log("Error:", e)}
    />
  );
};

export default ShowDocuments;
