import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Popover,
  Box,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import DataObjectIcon from "@mui/icons-material/DataObject";
import SmsAttributes from "./SmsAttributes";
import AttributesSelect from "../../sidebar-whatsapp-template/helper/AttributesSelect";

const SelectAttributes = ({
  selectAttributeAnchor,
  setSelectAttributeAnchor,
  attributeField,
  handleVariable,
}) => {
  const open = Boolean(selectAttributeAnchor);
  const id = open ? "simple-popover" : undefined;
  const [attributeAnchorEl, setAttributeAnchorEl] = useState({
    id: null,
    openAnchor: null,
  });

  const handleAttributePopover = (e, index) => {
    setAttributeAnchorEl({
      id: index,
      openAnchor: e.currentTarget,
    });
  };

  const handleClose = () => {
    setSelectAttributeAnchor(null);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={selectAttributeAnchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
    >
      <Box
        sx={{
          minWidth: "310px",
          padding: "20px 20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Typography
          variant="span"
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            marginLeft: "5px",
            color: "#22272d",
          }}
        >
          Select attributes
        </Typography>
        {attributeField &&
          attributeField.length > 0 &&
          attributeField.map((attr, index) => (
            <AttributesSelect
              value={attr?.value}
              onChange={(e) => handleVariable(e, attr?.index)}
              placeholder={`Select value for {{${index + 1}}}`}
            />
            // <Box
            //   key={index}
            //   sx={{
            //     width: "85%",
            //     borderRadius: "10px",
            //     color: "rgb(89, 104, 123)",
            //     border: "1px solid #a7a7a7",
            //     padding: "5px 10px",
            //     display: "flex",
            //     alignItems: "center",
            //     backgroundColor: "#fff",
            //   }}
            // >
            //   <TextField
            //     fullWidth
            //     placeholder={`Select value for {{${index + 1}}}`}
            //     value={attr?.value || ""}
            //     variant="standard"
            //     onChange={(e) => handleVariable(e.target.value, attr?.index)}
            //     inputProps={{
            //       maxLength: 30,
            //     }}
            //     InputProps={{
            //       disableUnderline: true,
            //       endAdornment: (
            //         <InputAdornment position="end">
            //           <IconButton
            //             onClick={(e) => handleAttributePopover(e, index)}
            //           >
            //             <DataObjectIcon />
            //           </IconButton>
            //         </InputAdornment>
            //       ),
            //     }}
            //   />
            // </Box>
          ))}
        {/* {attributeField &&
          attributeField.length > 0 &&
          attributeField.map((attr, index) => (
            <Box
              key={index}
              sx={{
                width: "85%",
                borderRadius: "10px",
                color: "rgb(89, 104, 123)",
                border: "1px solid #a7a7a7",
                padding: "5px 10px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fff",
              }}
            >
              <TextField
                fullWidth
                placeholder={`Select value for {{${index + 1}}}`}
                value={attr?.value || ""}
                variant="standard"
                onChange={(e) => handleVariable(e.target.value, attr?.index)}
                inputProps={{
                  maxLength: 30,
                }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => handleAttributePopover(e, index)}
                      >
                        <DataObjectIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          ))}
        <SmsAttributes
          attributeAnchorEl={attributeAnchorEl}
          setAttributeAnchorEl={setAttributeAnchorEl}
          handleVariable={handleVariable}
        /> */}
      </Box>
    </Popover>
  );
};

export default SelectAttributes;
