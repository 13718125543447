export const askUntilStyles = {
  text: {
    fontSize: "16px",
    fontFamily: "Inter",
    color: "#516173",
    fontWeight: 500,
  },
  span: {
    textDecoration: "underline",
    cursor: "pointer",
    marginLeft: "4px",
    fontWeight: 600,
    color: "#008aff",
  },
  outerBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "5px",
    borderRadius: "4px",
  },
  button: {
    fontFamily: "Inter",
    textTransform: "none",
    backgroundColor: "#fff",
    fontSize: "14px",
    color: "#516173",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#008aff",
    },
    margin: "5px",
    padding: 0
  },
};
