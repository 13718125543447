import { Box } from "@mui/material";
import React, { useState } from "react";
import ButtonTopTabs from "../../../components/ButtonTopTabs";
import TempleteList from "../../../components/TempleteList";
import { EmailTemplateList } from "../../../email/EmailList";
import { default as SMSTemplates } from "../../../integrations/components/sms/Templates";
import EmailBuilder from "../../../campaign/components/EmailBuilder";

const allTabs = [
  { label: "Whatsapp", value: "whatsapp" },
  { label: "Email", value: "email" },
  { label: "SMS", value: "sms" },
];

function Templates() {
  const [selectedTab, setSelectedTab] = useState("whatsapp");
  const [isEdit, setIsEdit] = useState({});
  const [preview, setPreview] = useState({});
  const [showEmailEdit, setShowEmailEdit] = useState(false);
  return (
    <Box>
      <ButtonTopTabs
        tabs={allTabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {selectedTab === "whatsapp" ? (
        <TempleteList asBox />
      ) : selectedTab === "email" ? (
        <>
          {showEmailEdit ? (
            <EmailBuilder
              setSelectedPage={(e) => {
                if (e === "templateList") setShowEmailEdit(false);
                else setShowEmailEdit(e);
              }}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              setPreview={setPreview}
            />
          ) : (
            <EmailTemplateList
              action={true}
              hideHeading
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              setSelectedPage={(e) => {
                setShowEmailEdit(e);
              }}
              hidePredefined
              setCreateTemplate={(e) => {
                setIsEdit({ create: true });
                setShowEmailEdit(e);
              }}
            />
          )}
        </>
      ) : selectedTab === "sms" ? (
        <SMSTemplates />
      ) : null}
    </Box>
  );
}
export default Templates;
