import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { useSelector } from "react-redux";
import { Popover, Box, Typography, Switch } from "@mui/material";
import { pluginPopoverStyles } from "../styles/pluginPopoverStyles";
import { editElementInRedux } from "../../../../../redux/actions/flowsActions";
import { useDispatch } from "react-redux";

const styles = pluginPopoverStyles;

const PluginPopover = forwardRef(({ anchorEl, setAnchorEl }, ref) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => {
    return {
      setPreForm(form) {
        editForm(form);
      },
    };
  });

  // const webchat = useSelector(state => state?.flow?.webChat)
  // const whatsappChat = useSelector(state => state?.flow?.whatsappChat)

  // const [checkedWebchat, setCheckedWebchat] = useState(false)
  // const [checkedWhatsappChat, setCheckedWhatsappChat] = useState(false);

  const [form, setForm] = useState({
    webchat: true,
    whatsapp: true,
  });

  useEffect(() => {
    dispatch(editElementInRedux({ flowChannels: form }));
  }, [form]);

  const editForm = (payload) => {
    setForm((state) => ({ ...state, ...payload }));
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onChange = (label, value) => editForm({ [label]: value });
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      sx={styles.popover}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box sx={styles.outerBox}>
        <Box sx={styles.innerBox}>
          <Typography sx={styles.optionText}>Web Chat</Typography>
          <Switch
            checked={form?.webchat}
            onChange={(e) => onChange("webchat", e.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
        <Box sx={styles.innerBox}>
          <Typography sx={styles.optionText}>Whatsapp Chat</Typography>
          <Switch
            checked={form?.whatsapp}
            onChange={(e) => onChange("whatsapp", e.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
      </Box>
    </Popover>
  );
});

export default PluginPopover;
