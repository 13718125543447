import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  linearProgressClasses,
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MasterSettings, {
  diamondCut,
  diamondShapes,
  diamondTypes,
  gemstoneQualities,
  gemstoneShapes,
  gemstoneTypes,
} from "../settings/MasterSettings";
import Pricing from "../pricing";
import { SaveOutlined, TaskAlt, TaskAltOutlined } from "@mui/icons-material";
import { ArrowForward } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import { StoreSVG } from "../../Svgs";
import { useDispatch, useSelector } from "react-redux";
import {
  getAxiosError,
  getId,
  getStoreId,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isNumber,
  isObjWithValues,
  sleep,
} from "../../helper";
import axios from "axios";
import { getNewProductObj, updateMigration } from "../../migrationHelper";
import { fetchCustomer } from "../crm/View";
import {
  getDropdownData,
  getSpreadsheetDropdowns,
} from "../products/AllProducts";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { useNavigate } from "react-router-dom";

const steps = [
  `Business Settings`,
  "Master Pricing",
  "Update Products",
  "Migration",
];

function Migration() {
  const [activeTab, setActiveTab] = useState(0);
  const [saveLoading, setSaveLoading] = useState(false);
  const [adminCustomer, setAdminCustomer] = useState({});
  const [diamondGroup, setDiamondGroup] = useState([]);
  const [migrationInfo, setMigrationInfo] = useState({
    business_settings: false,
    master_pricing: false,
    products: false,
    final: false,
  });
  const masterPricingRef = useRef();
  const masterSettingsRef = useRef();
  const finalMigrationRef = useRef();

  const allDiamondGroups = useSelector(
    (state) => state.settings.allDiamondGroups
  );
  const fetchingAllSettings = useSelector(
    (state) => state.settings.fetchingAllSettings
  );

  useEffect(() => {
    if (isObjWithValues(allDiamondGroups))
      setDiamondGroup(Object.values(allDiamondGroups));
  }, [allDiamondGroups]);

  useEffect(() => {
    fetchAdminCustomer();
  }, []);
  const fetchAdminCustomer = async () => {
    let customerId = getId();
    let customer = await fetchCustomer(customerId);
    console.log(customer);
    if (customer) setAdminCustomer(customer);
  };
  const onSavePress = () => {
    if (activeTab === 0)
      masterSettingsRef.current.onSave(async () => {
        console.log("business settings updating");
        await updateMigration({ business_settings: true });
        setActiveTab(1);
      });
    if (activeTab === 1)
      masterPricingRef.current.onSave(async () => {
        console.log("master pricing updating");
        await updateMigration({ master_pricing: true });
        setActiveTab(2);
      });
  };

  const onNextPress = () => {
    if (activeTab !== steps.length - 1) setActiveTab((state) => state + 1);
  };

  const onProductsDone = () => {
    setActiveTab(3);
    finalMigrationRef.current.onStart();
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        pt: 5,
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <Stepper
        alternativeLabel
        activeStep={activeTab}
        sx={{ mb: 5 }}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                onClick={() => setActiveTab(index)}
                sx={{ cursor: "pointer" }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Grid container>
        <Grid item xs></Grid>
        <Grid item xs={12} sm={10} md={9} xl={8}>
          <Card>
            <CardContent>
              <SaveUpdate
                onSave={onSavePress}
                saveLoading={saveLoading || fetchingAllSettings}
                onNext={onNextPress}
                activeTab={activeTab}
                hide={[2, 3]}
              />
              <Box
                sx={{
                  display: activeTab === 0 ? "block" : "none",
                }}
              >
                <MasterSettings
                  ref={masterSettingsRef}
                  hideUpdate
                  updateLoading={setSaveLoading}
                />
              </Box>
              <Box
                sx={{
                  display: activeTab === 1 ? "block" : "none",
                }}
              >
                <Pricing
                  hideUpdate
                  ref={masterPricingRef}
                  updateLoading={setSaveLoading}
                />
              </Box>
              <Box
                sx={{
                  display: activeTab === 2 ? "block" : "none",
                }}
              >
                <UpdateProducts
                  updateLoading={setSaveLoading}
                  adminCustomer={adminCustomer}
                  diamondGroup={diamondGroup}
                  onProductsDone={onProductsDone}
                />
              </Box>
              <Box
                sx={{
                  display: activeTab === 3 ? "block" : "none",
                }}
              >
                <FinalMirgration
                  hideUpdate
                  ref={finalMigrationRef}
                  updateLoading={setSaveLoading}
                  adminCustomer={adminCustomer}
                  diamondGroup={diamondGroup}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs></Grid>
        {/* <Box sx={{ maxWidth }}></Box> */}
      </Grid>
    </Box>
  );
}

export default Migration;

const temp = [
  {
    label: "Update Size Masters",
    done: false,
  },
  {
    label: "Ornate Integration",
    done: false,
  },
];

const FinalMirgration = forwardRef((props, ref) => {
  let [tasks, setTasks] = useState(temp);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useImperativeHandle(ref, () => ({
    onStart() {
      onStartMigration();
    },
  }));

  const onStartMigration = async () => {
    setLoading(true);
    await setSizeMasters();
    await setOranateIntegration();
    await updateMigration({ final: true });
    setLoading(false);
    navigate("/dashboard");
    dispatch(
      setGlobalToast({
        show: true,
        message: "Migration completed successfully",
        severity: "success",
      })
    );
  };

  const setOranateIntegration = async () => {
    let oldIntegration = await getOldOrnateIntegration();
    let newIntegration = await getNewOrnateIntegration();
    console.log(oldIntegration);
    if (
      isObjWithValues(oldIntegration) &&
      oldIntegration?.ornate_auth_data?.ip_port &&
      !newIntegration?.ornate_auth
    ) {
      let {
        ftp_ornate_data,
        ornate_auth_data,
        ornate_settings,
        ornate_taxonomy_map,
        ornate_sync_time,
      } = oldIntegration;
      let mainObj = {};
      mainObj.ornate_auth = {
        ...ornate_auth_data,
        ...ftp_ornate_data,
      };
      mainObj.ornate_settings = {
        ...ornate_settings,
        maketoorder: Boolean(ornate_settings.maketoorder == "1"),
        hallmarked: Boolean(ornate_settings.hallmarked == "1"),
        sync_time: ornate_sync_time?.sync_time,
      };
      mainObj.ornate_taxonomy = {
        ...ornate_taxonomy_map,
      };

      let response = await updateNewOrnateIntegration(mainObj);
      console.log(mainObj, response);
    }
  };

  const getOldOrnateIntegration = async () => {
    let website = await getWebsite();
    const token = await getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings`,
          headers: { Authorization: `Basic ${token}` },
          params: {
            options:
              "ornate_auth_data,ftp_ornate_data,ornate_settings,ornate_taxonomy_map,ornate_sync_time",
          },
        });
        return data.data;
      } catch (error) {
        console.log(getAxiosError(error));
        return false;
      }
    }
  };

  const getNewOrnateIntegration = async () => {
    let website = await getWebsite();
    const token = await getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/ornate_integration`,
          headers: { Authorization: `Basic ${token}` },
        });
        return data.data;
      } catch (error) {
        console.log(getAxiosError(error));
        return false;
      }
    }
  };
  const updateNewOrnateIntegration = async (data) => {
    let website = await getWebsite();
    const token = await getToken();
    if (website && token) {
      try {
        let { data: response } = await axios({
          url: `${website}/wp-json/store/v1/settings/ornate_integration`,
          headers: { Authorization: `Basic ${token}` },
          // params: {
          //   options: "ornate_integration",
          // },
          data: { type: "ornate_integration", data },
          method: "POST",
        });
        return response.data;
      } catch (error) {
        console.log(getAxiosError(error));
        return false;
      }
    }
  };

  const setSizeMasters = async () => {
    let sizeTabs = await getSizeTabs();
    let sizeGuides = await getSizeGuide();
    if (isArrayWithValues(sizeTabs) && isObjWithValues(sizeGuides)) {
      let array = [];
      for (let obj of sizeTabs) {
        let { tab, size_guide } = obj;
        let sizes = [];
        for (let key in sizeGuides) {
          if (key.includes(tab) && sizeGuides[key].size) {
            sizes.push({
              enable: Boolean(sizeGuides[key].enable == "on"),
              label: sizeGuides[key].size,
            });
          }
        }
        array.push({
          deleted: false,
          size_guide,
          default:
            sizeGuides[sizeGuides[tab]?.default_size] &&
            sizeGuides[sizeGuides[tab]?.default_size].size,
          sizes,
        });
      }
      console.log(array);
    }
  };

  const getSizeTabs = async () => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/setting_size_tab`,
          headers: { Authorization: `Basic ${token}` },
        });
        return data?.data;
      } catch (error) {
        return false;
      }
    }
  };
  const getSizeGuide = async () => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/size_guide`,
          headers: { Authorization: `Basic ${token}` },
        });
        return data?.data;
      } catch (error) {
        return false;
      }
    }
  };
  return (
    <Box>
      {/* <Typography sx={{ fontSize: "24px", fontWeight: "500", mb: 5 }}>
        Final Migration
      </Typography> */}
      {/* {tasks.map((i) => {
        return (
          <Stack direction="row" alignItems={"center"} sx={{ mt: 2, ml: 2 }}>
            <TaskAlt color={i.done ? "success" : "disabled"} />
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                ml: 3,
                color: i.done ? "text.success" : "text.secondary",
              }}
            >
              {i.label}
            </Typography>
          </Stack>
        );
      })} */}
      {loading ? (
        <Box sx={{ width: { xl: "70%", sm: "90%" }, margin: "10vh auto" }}>
          <LinearProgress />
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "500",
              textAlign: "center",
              mt: 3,
            }}
          >
            Finishing up the process...
          </Typography>
        </Box>
      ) : null}
      {/* <Stack direction="row" justifyContent={"flex-end"}>
        <Button variant="contained" sx={{mt: 4}} >Start Migration</Button>
      </Stack> */}
    </Box>
  );
});

const UpdateProducts = ({ adminCustomer, diamondGroup, onProductsDone }) => {
  let [progress, setProgress] = useState(0);
  let [totalProducts, setTotalProducts] = useState(0);
  const [productsLoading, setProductsLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const [progressMessage, setProgressMessage] = useState(
    "Lets update your products"
  );

  useEffect(() => {
    if (isObjWithValues(user) && user.store_used)
      if (user.store_used.products) setTotalProducts(user.store_used.products);
  }, [user]);

  const onStart = async () => {
    setProductsLoading(true);
    setProgressMessage("Fetching all products... (1/3)");
    let products = await fetchAllProducts(setProgress);
    console.log(
      products
      // products.filter((i) => i.id == 2227 || i.id == 2212)
    );
    // if (isDev()) return;
    // await sleep(100000);
    setProgressMessage("Updating all products... (2/3)");
    if (isArrayWithValues(products)) {
      const updatedProducts = await updateAllProducts(products, setProgress);
      if (isDev()) return;
      setProgressMessage("Syncing your products... (3/3)");
      if (isArrayWithValues(updatedProducts))
        await syncAllProducts(adminCustomer, updatedProducts);
    }
    // return;
    await updateMigration({ products: true });
    setProductsLoading(false);
    setProgressMessage("Products has been updated");
    onProductsDone && onProductsDone();
  };

  const syncAllProducts = async (customer = {}, products) => {
    setProgress(0);
    await sleep(1000);
    let access_token, spreadsheet_id, refresh_token;
    if (isObjWithValues(customer)) {
      setProgress(20);
      let { meta_data } = customer;
      if (isArrayWithValues(meta_data)) {
        let accessTokenObj = meta_data.find(
          (i) => i.key === "google_access_token"
        );
        if (accessTokenObj) access_token = accessTokenObj.value;
        let spreadSheetObj = meta_data.find(
          (i) => i.key === "google_spreadsheetId"
        );
        let refreshToken = meta_data.find(
          (i) => i.key === "google_refresh_token_token"
        );
        if (refreshToken) refresh_token = refreshToken.value;
        if (spreadSheetObj) spreadsheet_id = spreadSheetObj.value;
      }
    }
    if (refresh_token) {
      let updatedToken = await refreshGoogleToken(refresh_token);
      console.log(updatedToken);
      if (updatedToken.access_token) access_token = updatedToken.access_token;
    }
    let dropdownData = getDropdownData(productSettings, masterPricing);

    let spreadsheetProducts = createSpreadsheetData(products, productSettings);
    const exportedSheetRes = await exportSpreadsheet(
      spreadsheetProducts,
      spreadsheet_id,
      access_token
    );
    setProgress(40);

    let sheetIdResData = await getSheetId(access_token, spreadsheet_id);
    console.log(sheetIdResData, "SHEET ID DATA");
    setProgress(50);
    if (isArrayWithValues(sheetIdResData?.sheets)) {
      let obj = sheetIdResData.sheets[0];
      let id = obj.properties && obj.properties.sheetId;
      if (isNumber(id)) {
        try {
          let dropdownArray = getSpreadsheetDropdowns(
            id,
            spreadsheetProducts[0],
            dropdownData,
            { ...masterPricing }
          );
          console.log(dropdownArray);
          let setSpreadsheetDropdownData = await setSpreadsheetDropdowns(
            access_token,
            spreadsheet_id,
            dropdownArray
          );
          console.log(setSpreadsheetDropdownData);
          setProgress(70);
        } catch (error) {
          console.log(getAxiosError(error));
        }
      }
    }
    if (access_token && spreadsheet_id) {
      setProgress(95);
      await updateSpreadSheetId(spreadsheet_id);
    }
    setProgress(100);
  };

  const updateSpreadSheetId = async (id) => {
    try {
      let website = getWebsite();
      let token = getToken();
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/settings/google_spreadsheet`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: {
          type: "google_spreadsheet",
          data: {
            spreadSheet_product_id: id,
          },
        },
        method: "PUT",
      });
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };

  const refreshGoogleToken = async (refresh_token) => {
    try {
      let { data } = await axios({
        method: "POST",
        url: `https://accounts.google.com/o/oauth2/token`,
        params: {
          refresh_token,
          client_id:
            "448848454597-rbba0c3q0kbcn0f8l893813l98rgra7p.apps.googleusercontent.com",
          client_secret: "28QrjjfHZljyTaBGE2sNHzZG",
          grant_type: "refresh_token",
        },
      });
      return data;
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  };

  const updateAllProducts = async (products, setProgress) => {
    if (!isArrayWithValues(products)) return;

    setProgress(0);
    let fetchBatchLength = 50;
    let batchLength = parseInt(products.length / fetchBatchLength) + 1;
    let addToProgress = Math.round(100 / batchLength);
    console.log(batchLength, addToProgress);
    let page = 1;
    let allProducts = [];
    let productsToUpdate = [...products];
    for await (let i of Array(batchLength)) {
      let products = await updateBatchProducts(
        productsToUpdate.splice(0, fetchBatchLength)
      );
      // console.log(products, "RES");
      allProducts = allProducts.concat(products);
      setProgress((state) =>
        state + addToProgress >= 100 ? 100 : state + addToProgress
      );
      page += 1;
      await sleep(750);
      // if (products.length < fetchBatchLength) break;
    }
    return allProducts;
  };

  const fetchAllProducts = async (setProgress) => {
    let fetchBatchLength = 400;
    let batchLength = parseInt(totalProducts / fetchBatchLength) + 1;
    let addToProgress = Math.round(100 / batchLength);
    let page = 1;
    let allProducts = [];
    for await (let i of Array(100)) {
      let products = await getProducts({ per_page: fetchBatchLength, page });
      // console.log(products, "RES");
      allProducts = allProducts.concat(products);
      setProgress((state) =>
        state + addToProgress >= 100 ? 100 : state + addToProgress
      );
      await sleep(750);
      page += 1;
      if (products.length < fetchBatchLength) break;
    }
    // let index = 0;
    // for (let i of Array(100)) {
    //   console.log(
    //     getNewProductObj(
    //       allProducts[index],
    //       productSettings,
    //       masterPricing,
    //       diamondGroup
    //     )
    //   );
    //   index++;
    // }
    setProgress(0);
    console.log(allProducts, "<<<< old products");
    return allProducts
      .map(
        (i) => getNewProductObj(i, productSettings, masterPricing, diamondGroup)
        // ({ id: i.id })
      )
      .filter(Boolean);
  };

  const updateBatchProducts = async (products) => {
    try {
      let website = getWebsite();
      let token = getToken();
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/products/batch`,
        headers: { Authorization: `Basic ${token}` },
        data: { update: products },
        method: "POST",
      });
      return isArrayWithValues(data.update) ? data.update : [];
    } catch (error) {
      console.log(getAxiosError(error));
      return [];
    }
  };
  const getProducts = async (params) => {
    try {
      let website = getWebsite();
      let token = getToken();
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/products`,
        headers: { Authorization: `Basic ${token}` },
        params,
      });
      return isArrayWithValues(data) ? data : [];
    } catch (error) {
      console.log(getAxiosError(error));
      return [];
    }
  };
  return (
    <Box>
      <Stack direction="row" sx={{ mt: 3 }} justifyContent={"center"}>
        <StoreSVG />
      </Stack>
      <Typography
        sx={{
          mt: 4,
          fontSize: "17px",
          fontWeight: "500",
          textAlign: "center",
        }}
      >
        {progressMessage}
        {/* Lets update your products */}
      </Typography>
      {productsLoading && (
        <Grid container>
          <Grid item xs />
          <Grid item xs={12} sm={10}>
            <Box sx={{ mt: 6 }}>
              <BorderLinearProgress variant="determinate" value={progress} />
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "right",
                  mr: 2,
                  mt: 2,
                  fontWeight: "600",
                }}
              >
                {progress}%
              </Typography>
            </Box>
          </Grid>
          <Grid item xs />
        </Grid>
      )}
      {!productsLoading && (
        <Stack direction="row" mt={4} justifyContent={"center"}>
          <Button
            variant="contained"
            sx={{ alignSelf: "center" }}
            size="large"
            onClick={onStart}
            endIcon={<TaskAltOutlined />}
          >
            Start Update
          </Button>
        </Stack>
      )}
      <Box sx={{ height: "20px" }} />
    </Box>
  );
};

const SaveUpdate = ({ onSave, saveLoading, onNext, hide = [], activeTab }) => {
  return (
    <Box sx={{ visibility: hide.includes(activeTab) ? "hidden" : "visible" }}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        justifyContent={"flex-end"}
      >
        <LoadingButton
          loading={saveLoading}
          onClick={() => onSave && onSave()}
          startIcon={<SaveOutlined />}
        >
          Save
        </LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={onNext}
          // endIcon={<ArrowForward />}
        >
          Next{" "}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(66, 93, 194) 0%,rgb(24, 50, 148) 50%,rgb(13, 26, 74) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(66, 93, 194) 0%,rgb(24, 50, 148) 50%,rgb(13, 26, 74) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));
function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  //   icon: PropTypes.node,
};

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 36,
  height: 36,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(66, 93, 194) 0%, rgb(24, 50, 148) 50%, rgb(13, 26, 74) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(66, 93, 194) 0%, rgb(24, 50, 148) 50%, rgb(13, 26, 74) 100%)",
  }),
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "rgb(24, 156, 86)",
    // backgroundColor: theme.palette.mode === "light" ? "#00e676" : "#00e676",
  },
}));

const createSpreadsheetData = (products, productSettings) => {
  let { types } = productSettings;
  let excludedColumns = [];
  const shouldAddMetalHeaders = types?.find(
    (i) => i.value === "gold" || i.value === "silver" || i.value === "platinium"
  );

  const shouldAddGoldHeaders =
    types?.find((i) => i.value === "gold") &&
    !excludedColumns.includes("metal");
  const shouldAddSilverHeaders =
    types?.find((i) => i.value === "silver") &&
    !excludedColumns.includes("metal");
  const shouldAddPlatiniumHeaders =
    types?.find((i) => i.value === "platinium") &&
    !excludedColumns.includes("metal");
  const shouldAddDiamondHeaders =
    types?.find((i) => i.value === "diamond") &&
    !excludedColumns.includes("diamond");
  const shouldAddGemstoneHeaders =
    types?.find((i) => i.value === "gemstone") &&
    !excludedColumns.includes("gemstone");
  const shouldAddSeoHeaders = !excludedColumns.includes("seo");
  const shouldAddMeasurementsHeaders =
    !excludedColumns.includes("measurements");
  const shouldAddLabourHeaders = !excludedColumns.includes("labour");
  const shouldAddDiscountHeaders = !excludedColumns.includes("discounts");
  const shouldShowMatchingHeaders = !excludedColumns.includes("matching");
  const shouldShowImageHeaders = !excludedColumns.includes("images");
  const shouldShowVideoHeaders = !excludedColumns.includes("video");

  let metalHeaders = [
    { label: "Metal Type", value: "metal_type" },
    { label: "Metal Color", value: "metal_color" },
  ];
  let SEOHeaders = [
    { label: "SEO Title", value: "seo_title" },
    { label: "SEO Descriptoin", value: "seo_description" },
    { label: "SEO Keywords", value: "seo_keywords" },
  ];
  let platinumHeaders = [
    { label: "Platinum Purity", value: "platinium_purity" },
    { label: "Platinum Gross", value: "platinium_gross" },
    { label: "Platinum Net", value: "platinium_net" },
  ];
  let silverHeaders = [
    { label: "Silver Purity", value: "silver_purity" },
    { label: "Silver Gross", value: "silver_gross" },
    { label: "Silver Net", value: "silver_net" },
  ];
  let goldHeaders = [
    { label: "Gold Purity", value: "gold_kt" },
    { label: "Gold Gross", value: "gold_gross" },
    { label: "Gold Net", value: "gold_net" },
  ];
  let measurementsHeaders = [
    { label: "Width", value: "product_width" },
    { label: "Height", value: "product_height" },
    { label: "Thickness", value: "product_thickness" },
  ];
  let labourHeaders = [
    { label: "Labour From", value: "labour_from" },
    { label: "Wastage in (%)", value: "wastage_percent" },
    { label: "Per Gram", value: "per_gram" },
    { label: "Labour Per gram", value: "labour" },
    { label: "Minimum Labour", value: "minimum_labour" },
  ];
  let discountsHeaders = [
    { label: "Discount On", value: "discount_on" },
    { label: "Discount Type", value: "discount_type" },
    { label: "Discount Value", value: "discount_value" },
  ];
  let matchingHeaders = [
    { label: "Upsell", value: "upsell_ids" },
    { label: "Cross Sell", value: "cross_sell_ids" },
  ];
  let imageHeaders = [{ label: "Images", value: "images" }];
  let videoHeaders = [{ label: "Video URL", value: "_woodmart_product_video" }];

  const goldPurities = [
    { label: "24", value: "999" },
    { label: "22", value: "916" },
    { label: "18", value: "750" },
    { label: "14", value: "583" },
    { label: "9", value: "375" },
  ];

  const discountLabelObj = {
    on_total_sale: "Total",
    on_sale_making: "Making",
    on_sale_metal: "Metal",
    on_sale_diamond: "Diamond",
    on_sale_colorstone: "Gemstone",
  };

  const diamondHeaders = [
    // { label: "Diamond From", value: "diamond_from" },
    { label: "Diamond Type", value: "diamond_type" },
    { label: "Diamond Quality", value: "diamond_quality" },
    { label: "Diamond Shapes", value: "diamond_shape" },
    // { label: "Diamond Sieve", value: "diamond_sieve" },
    { label: "Diamond Cut", value: "diamond_cut" },
    { label: "Diamond Pieces", value: "diamond_pieces" },
    { label: "Diamond Weight", value: "diamond_weight" },
    { label: "Custom Diamond Rate", value: "diamond_rate" },
    // { label: "Diamond Certified", value: "diamond_certified" },
    { label: "Diamond Certificate", value: "" },
    { label: "Diamond Lab", value: "diamond_lab" },
  ];
  const gemstoneHeaders = [
    // { label: "Gemstone From", value: "colorstone_from" },
    { label: "Gemstone Quality", value: "colorstone_quality" },
    { label: "Gemstone Shape", value: "colorstone_shape" },
    { label: "Gemstone Type", value: "colorstone_type" },
    { label: "Gemstone Pieces", value: "colorstone_pieces" },
    { label: "Gemstone Size", value: "colorstone_size" },
    { label: "Gemstone Weight", value: "colorstone_weight" },
    { label: "Custom Gemstone Rate", value: "colorstone_rate" },
  ];
  const AllHeaders = [
    { label: "Last Modified", value: "date_modified" },
    { label: "SKU", value: "sku" },
    { label: "Actions", value: "actions" },
    { label: "Title", value: "name" },
    { label: "Status", value: "status" },
    { label: "Description", value: "description" },
    { label: "Slug", value: "slug" },
    { label: "Stock Status", value: "stock_status" },
    { label: "Quantity", value: "stock_quantity" },
    { label: "Collections", value: "collections" },
    { label: "Category", value: "categories" },
    { label: "Sub Category", value: "subcategory" },
    { label: "Settings/Size", value: "subcatsetting" },
    { label: "Featured", value: "featured" },
    { label: "HUID", value: "huid" },
    ...(shouldAddMetalHeaders ? metalHeaders : []),
    ...(shouldAddGoldHeaders ? goldHeaders : []),
    ...(shouldAddSilverHeaders ? silverHeaders : []),
    ...(shouldAddPlatiniumHeaders ? platinumHeaders : []),
    ...(shouldAddDiamondHeaders ? diamondHeaders : []),
    ...(shouldAddGemstoneHeaders ? gemstoneHeaders : []),
    ...(shouldAddLabourHeaders ? labourHeaders : []),
    // { label: "Labour Type", value: "labourType" },
    { label: "Extra Charge Type", value: "extra_charge_label" },
    { label: "Extra Charge Value", value: "extra_charge_value" },
    ...(shouldAddSeoHeaders ? SEOHeaders : []),
    ...(shouldAddMeasurementsHeaders ? measurementsHeaders : []),
    { label: "Upsell", value: "cross_sell_ids" },
    { label: "Cross Sell", value: "cross_sell_ids" },
    { label: "Tags", value: "tags" },
    { label: "Gender", value: "gender" },
    { label: "Ocassions", value: "ocassions" },
    // { label: "Custom tags", value: "custom_tags" },
    { label: "Min Shipping Days", value: "min_shipping_days" },
    { label: "Max Shipping Days", value: "max_shipping_days" },
    { label: "Manual Price", value: "" },
    ...(shouldShowMatchingHeaders ? matchingHeaders : []),
    ...(shouldAddDiscountHeaders ? discountsHeaders : []),
    ...(shouldShowImageHeaders ? imageHeaders : []),
    ...(shouldShowVideoHeaders ? videoHeaders : []),
    { label: "Product Error", value: "error" },
  ];

  let length = products.length;
  let spreadsheetArray = [];
  let headersArray = [];

  for (let i in AllHeaders) headersArray.push(AllHeaders[i].label);

  spreadsheetArray.push(headersArray);
  console.log(products, "allProducts in create spreadsheet");
  for (let i = 0; i < length; i++) {
    let product = products[i];
    let {
      name,
      sku,
      description,
      collections,
      categories,
      subcategory,
      stock_status,
      status,
      stock_quantity,
      images,
      date_modified,
      meta_data,
      attributes,
      upsell_ids,
      cross_sell_ids,
    } = product;
    let productObj = {
      name,
      sku,
      description,
      collections,
      categories,
      subcategory,
      stock_status,
      status,
      stock_quantity,
      images,
      date_modified,
      // upsell_ids: isArrayWithValues(upsell_ids) ? upsell_ids.join() : "",
      // cross_sell_ids: isArrayWithValues(cross_sell_ids)
      //   ? cross_sell_ids.join()
      //   : "",
      // meta_data,
      actions: product.error ? "Error" : "synced",
    };
    if (isArrayWithValues(upsell_ids)) {
      productObj.upsell_ids = upsell_ids
        ?.map((id) => {
          let obj = products.find((i) => i.id == id);
          if (obj) return obj.sku;
        })
        ?.filter(Boolean)
        ?.join();
    }
    if (isArrayWithValues(cross_sell_ids)) {
      productObj.cross_sell_ids = cross_sell_ids
        ?.map((id) => {
          let obj = products.find((i) => i.id == id);
          if (obj) return obj.sku;
        })
        ?.filter(Boolean)
        ?.join();
    }
    let unnecessaryKeys = [
      // "discount_sale_on",
      // "discount_type",
      // "discount_value",
      "extra_charges",
      "price_breakup",
      // "seo",
      "woodmart_sguide_select",
      "wd_page_css_files",
      "_wds_analysis",
      "_wds_readability",
    ];

    if (isArrayWithValues(attributes)) {
      let gender = attributes.find((i) => i.slug === "pa_gender");
      if (gender && isArrayWithValues(gender?.options))
        productObj.gender = gender?.options.join();
    }
    if (isArrayWithValues(attributes)) {
      let ocassions = attributes.find((i) => i.slug === "pa_ocassions");
      if (ocassions && isArrayWithValues(ocassions?.options))
        productObj.ocassions = ocassions?.options.join();
    }

    if (isArrayWithValues(meta_data))
      for (let i in meta_data) {
        let obj = meta_data[i];
        productObj[obj.key] = obj.value || "";
      }

    if (isObjWithValues(productObj.product_shipping)) {
      productObj.min_shipping_days = productObj.product_shipping.min;
      productObj.max_shipping_days = productObj.product_shipping.max;
      delete productObj.product_shipping;
    }

    if (productObj.gold_kt) {
      let gold_kt = productObj.gold_kt;
      let purity = productObj.gold_kt
        .toLowerCase()
        .replace("kt", "")
        .replace("k", "")
        .replace("t", "");
      let purityObj = goldPurities.find((i) => i.value == purity);
      if (purityObj) gold_kt = purityObj.label + "KT";
      // meta_data.push({ key: "gold_kt", value: gold_kt });
      if (!gold_kt.toLowerCase().includes("kt")) gold_kt += "KT";
      productObj.gold_kt = gold_kt;
    }
    if (isObjWithValues(productObj.extra_charges)) {
      let { extra_charges } = productObj;
      let labels = "";
      let values = "";
      let array = Object.values(extra_charges);
      array.map((i) => {
        let { extra_charge_label, extra_charge_value } = i;
        if (labels) labels += `,${extra_charge_label}`;
        else labels = extra_charge_label;
        if (values) values += `,${extra_charge_value}`;
        else values = extra_charge_value;
      });
      productObj.extra_charge_label = labels;
      productObj.extra_charge_value = values;
    }
    delete productObj.extra_charges;
    if (isObjWithValues(productObj.custom_discounts)) {
      let discountOnArray = Object.keys(productObj.custom_discounts);
      let index = 0;
      for (let discountObj of Object.values(productObj.custom_discounts)) {
        let { type, value } = discountObj;
        if ((type, value)) {
          productObj.discount_on = productObj.discount_on
            ? `${productObj.discount_on},${
                discountLabelObj[discountOnArray[index]]
              }`
            : discountLabelObj[discountOnArray[index]];
          productObj.discount_type = productObj.discount_type
            ? `${productObj.discount_type},${type}`
            : type;
          productObj.discount_value = productObj.discount_value
            ? `${productObj.discount_value},${value}`
            : value;
        }
        index++;
      }
    }
    delete productObj.custom_discounts;

    if (isObjWithValues(productObj.seo)) {
      let { seo_title, seo_description, seo_keywords } = productObj.seo;
      productObj.seo_title = seo_title;
      productObj.seo_description = seo_description;
      if (isArrayWithValues(seo_keywords))
        productObj.seo_keywords = seo_keywords.join();
      else if (typeof seo_keywords === "string")
        productObj.seo_keywords = seo_keywords;
    }
    delete productObj.seo;

    if (isArrayWithValues(productObj.subcatsetting))
      productObj.subcatsetting = productObj.subcatsetting.join(",");

    if (isObjWithValues(productObj.colorstone_details)) {
      let { colorstone_details } = productObj;
      let colorstones = Object.values(colorstone_details);
      for (let i in colorstones) {
        let obj = colorstones[i];
        let keys = Object.keys(obj);
        for (let j in keys) {
          let value = "";
          if (keys[j] === "colorstone_shape") {
            let valueObj = gemstoneShapes.find((i) => i.value === obj[keys[j]]);
            if (valueObj) value = valueObj.label;
          }
          if (keys[j] === "colorstone_quality") {
            let valueObj = gemstoneQualities.find(
              (i) => i.value === obj[keys[j]]
            );
            if (valueObj) value = valueObj.label;
          }
          if (keys[j] === "colorstone_type") {
            let valueObj = gemstoneTypes.find((i) => i.value === obj[keys[j]]);
            if (valueObj) value = valueObj.label;
          }
          value = value || obj[keys[j]];

          productObj[keys[j]] = productObj[keys[j]]
            ? `${productObj[keys[j]]}, ${value}`
            : value;
        }
      }
    }
    delete productObj.colorstone_details;
    if (isObjWithValues(productObj.diamond)) {
      let { diamond } = productObj;
      let diamonds = Object.values(diamond);
      for (let i in diamonds) {
        let obj = diamonds[i];
        let keys = Object.keys(obj);
        for (let j in keys) {
          let value = "";
          if (keys[j] === "diamond_type") {
            let valueObj = diamondTypes.find(
              (type) => type.value === obj[keys[j]]
            );
            if (valueObj) value = valueObj.label;
          }
          if (keys[j] === "diamond_shape") {
            let valueObj = diamondShapes.find(
              (shape) => shape.value === obj[keys[j]]
            );
            if (valueObj) value = valueObj.label;
          }
          if (keys[j] === "diamond_cut") {
            let valueObj = diamondCut.find(
              (shape) => shape.value === obj[keys[j]]
            );
            if (valueObj) value = valueObj.label;
          }
          value = value || obj[keys[j]];

          productObj[keys[j]] = productObj[keys[j]]
            ? `${productObj[keys[j]]}, ${value}`
            : value;
          // console.log(productObj[keys[j]], "2---", obj[keys[j]]);
        }
      }
    }
    delete productObj.diamond;

    if (isArrayWithValues(productObj.categories))
      productObj.categories = productObj.categories.map((i) => i.name).join();
    if (isArrayWithValues(productObj.subcategory))
      productObj.subcategory = productObj.subcategory.map((i) => i.name).join();
    if (isArrayWithValues(productObj.collections))
      productObj.collections = productObj.collections.map((i) => i.name).join();
    if (isArrayWithValues(productObj.huid))
      productObj.huid = productObj.huid.join();
    if (isArrayWithValues(productObj.images)) {
      let { images } = productObj;
      let string = "";
      images.map((i) => {
        let name =
          typeof i.src === "string"
            ? i.src.replace(/^.*[\\\/]/, "").replace(/\.[^/.]+$/, "")
            : "";
        string = string ? `${string},${name}` : name;
      });
      productObj.images = string;
    }
    // productObj.images = productObj.images.map((i) => i.src).join();
    if (isArrayWithValues(productObj.product_groups))
      productObj.product_groups = productObj.product_groups.join();
    else productObj.product_groups = "";
    for (let i in unnecessaryKeys)
      if (productObj.hasOwnProperty(unnecessaryKeys[i]))
        delete productObj[unnecessaryKeys[i]];
    let array = [];
    for (let i in AllHeaders)
      array.push(
        typeof productObj[AllHeaders[i].value] !== "object" ||
          !Array.isArray(productObj[AllHeaders[i].value])
          ? productObj[AllHeaders[i].value]
            ? productObj[AllHeaders[i].value]
            : ""
          : ""
      );
    spreadsheetArray.push(array);
    // let product = products[i];
    // let {
    //   name,
    //   sku,
    //   description,
    //   collections,
    //   categories,
    //   subcategory,
    //   stock_status,
    //   stock_quantity,
    //   images,
    //   date_modified,
    //   meta_data,
    //   attributes,
    // } = product;
    // let productObj = {
    //   name,
    //   sku,
    //   description,
    //   collections,
    //   categories,
    //   subcategory,
    //   stock_status,
    //   stock_quantity,
    //   images,
    //   date_modified,
    //   // meta_data,
    //   actions: product.error ? "Error" : "synced",
    // };
    // let unnecessaryKeys = [
    //   // "discount_sale_on",
    //   // "discount_type",
    //   // "discount_value",
    //   "extra_charges",
    //   "price_breakup",
    //   // "seo",
    //   "woodmart_sguide_select",
    //   "wd_page_css_files",
    //   "_wds_analysis",
    //   "_wds_readability",
    // ];

    // if (isArrayWithValues(attributes)) {
    //   let gender = attributes.find((i) => i.slug === "pa_gender");
    //   if (gender && isArrayWithValues(gender?.options))
    //     productObj.gender = gender?.options.join();
    // }
    // if (isArrayWithValues(attributes)) {
    //   let ocassions = attributes.find((i) => i.slug === "pa_ocassions");
    //   if (ocassions && isArrayWithValues(ocassions?.options))
    //     productObj.ocassions = ocassions?.options.join();
    // }

    // if (isArrayWithValues(meta_data))
    //   for (let i in meta_data) {
    //     let obj = meta_data[i];
    //     productObj[obj.key] = obj.value || "";
    //   }

    // if (isObjWithValues(productObj.product_shipping)) {
    //   productObj.min_shipping_days = productObj.product_shipping.min;
    //   productObj.max_shipping_days = productObj.product_shipping.max;
    //   delete productObj.product_shipping;
    // }

    // if (productObj.gold_kt) {
    //   let gold_kt = productObj.gold_kt;
    //   let purity = productObj.gold_kt
    //     .toLowerCase()
    //     .replace("kt", "")
    //     .replace("k", "")
    //     .replace("t", "");
    //   let purityObj = goldPurities.find((i) => i.value == purity);
    //   if (purityObj) gold_kt = purityObj.label + "KT";
    //   // meta_data.push({ key: "gold_kt", value: gold_kt });
    //   if (!gold_kt.toLowerCase().includes("kt")) gold_kt += "KT";
    //   productObj.gold_kt = gold_kt;
    // }
    // if (isObjWithValues(productObj.extra_charges)) {
    //   let { extra_charges } = productObj;
    //   let labels = "";
    //   let values = "";
    //   let array = Object.values(extra_charges);
    //   array.map((i) => {
    //     let { extra_charge_label, extra_charge_value } = i;
    //     if (labels) labels += `,${extra_charge_label}`;
    //     else labels = extra_charge_label;
    //     if (values) values += `,${extra_charge_value}`;
    //     else values = extra_charge_value;
    //   });
    //   productObj.extra_charge_label = labels;
    //   productObj.extra_charge_value = values;
    // }
    // delete productObj.extra_charges;
    // if (isObjWithValues(productObj.custom_discounts)) {
    //   let discountOnArray = Object.keys(productObj.custom_discounts);
    //   let index = 0;
    //   for (let discountObj of Object.values(productObj.custom_discounts)) {
    //     let { type, value } = discountObj;
    //     if ((type, value)) {
    //       productObj.discount_on = productObj.discount_on
    //         ? `${productObj.discount_on},${
    //             discountLabelObj[discountOnArray[index]]
    //           }`
    //         : discountLabelObj[discountOnArray[index]];
    //       productObj.discount_type = productObj.discount_type
    //         ? `${productObj.discount_type},${type}`
    //         : type;
    //       productObj.discount_value = productObj.discount_value
    //         ? `${productObj.discount_value},${value}`
    //         : value;
    //     }
    //     index++;
    //   }
    // }
    // delete productObj.custom_discounts;

    // if (isObjWithValues(productObj.seo)) {
    //   let { seo_title, seo_description, seo_keywords } = productObj.seo;
    //   productObj.seo_title = seo_title;
    //   productObj.seo_description = seo_description;
    //   if (isArrayWithValues(seo_keywords))
    //     productObj.seo_keywords = seo_keywords.join();
    //   else if (typeof seo_keywords === "string")
    //     productObj.seo_keywords = seo_keywords;
    // }
    // delete productObj.seo;

    // if (isArrayWithValues(productObj.subcatsetting))
    //   productObj.subcatsetting = productObj.subcatsetting.join(",");

    // if (isObjWithValues(productObj.colorstone_details)) {
    //   let { colorstone_details } = productObj;
    //   let colorstones = Object.values(colorstone_details);
    //   for (let i in colorstones) {
    //     let obj = colorstones[i];
    //     let keys = Object.keys(obj);
    //     for (let j in keys) {
    //       let value = "";
    //       if (keys[j] === "colorstone_shape") {
    //         let valueObj = gemstoneShapes.find((i) => i.value === obj[keys[j]]);
    //         if (valueObj) value = valueObj.label;
    //       }
    //       if (keys[j] === "colorstone_quality") {
    //         let valueObj = gemstoneQualities.find(
    //           (i) => i.value === obj[keys[j]]
    //         );
    //         if (valueObj) value = valueObj.label;
    //       }
    //       if (keys[j] === "colorstone_type") {
    //         let valueObj = gemstoneTypes.find((i) => i.value === obj[keys[j]]);
    //         if (valueObj) value = valueObj.label;
    //       }
    //       value = value || obj[keys[j]];

    //       productObj[keys[j]] = productObj[keys[j]]
    //         ? `${productObj[keys[j]]}, ${value}`
    //         : value;
    //     }
    //   }
    // }
    // delete productObj.colorstone_details;
    // if (isObjWithValues(productObj.diamond)) {
    //   let { diamond } = productObj;
    //   let diamonds = Object.values(diamond);
    //   for (let i in diamonds) {
    //     let obj = diamonds[i];
    //     let keys = Object.keys(obj);
    //     for (let j in keys) {
    //       let value = "";
    //       if (keys[j] === "diamond_type") {
    //         let valueObj = diamondTypes.find(
    //           (type) => type.value === obj[keys[j]]
    //         );
    //         if (valueObj) value = valueObj.label;
    //       }
    //       if (keys[j] === "diamond_shape") {
    //         let valueObj = diamondShapes.find(
    //           (shape) => shape.value === obj[keys[j]]
    //         );
    //         if (valueObj) value = valueObj.label;
    //       }
    //       if (keys[j] === "diamond_cut") {
    //         let valueObj = diamondCut.find(
    //           (shape) => shape.value === obj[keys[j]]
    //         );
    //         if (valueObj) value = valueObj.label;
    //       }
    //       value = value || obj[keys[j]];

    //       productObj[keys[j]] = productObj[keys[j]]
    //         ? `${productObj[keys[j]]}, ${value}`
    //         : value;
    //       // console.log(productObj[keys[j]], "2---", obj[keys[j]]);
    //     }
    //   }
    // }
    // delete productObj.diamond;

    // if (isArrayWithValues(productObj.categories))
    //   productObj.categories = productObj.categories.map((i) => i.name).join();
    // if (isArrayWithValues(productObj.subcategory))
    //   productObj.subcategory = productObj.subcategory.map((i) => i.name).join();
    // if (isArrayWithValues(productObj.collections))
    //   productObj.collections = productObj.collections.map((i) => i.name).join();
    // if (isArrayWithValues(productObj.huid))
    //   productObj.huid = productObj.huid.join();
    // if (isArrayWithValues(productObj.images)) {
    //   let { images } = productObj;
    //   let string = "";
    //   images.map((i) => {
    //     let name =
    //       typeof i.src === "string"
    //         ? i.src.replace(/^.*[\\\/]/, "").replace(/\.[^/.]+$/, "")
    //         : "";
    //     string = string ? `${string},${name}` : name;
    //   });
    //   productObj.images = string;
    // }
    // // productObj.images = productObj.images.map((i) => i.src).join();
    // if (isArrayWithValues(productObj.product_groups))
    //   productObj.product_groups = productObj.product_groups.join();
    // else productObj.product_groups = "";
    // for (let i in unnecessaryKeys)
    //   if (productObj.hasOwnProperty(unnecessaryKeys[i]))
    //     delete productObj[unnecessaryKeys[i]];
    // let array = [];
    // for (let i in AllHeaders)
    //   array.push(
    //     typeof productObj[AllHeaders[i].value] !== "object" ||
    //       !Array.isArray(productObj[AllHeaders[i].value])
    //       ? productObj[AllHeaders[i].value]
    //         ? productObj[AllHeaders[i].value]
    //         : ""
    //       : ""
    //   );
    // spreadsheetArray.push(array);
  }
  return spreadsheetArray;
};

const getSheetId = async (access_token, spreadsheetId) => {
  try {
    let { data } = await axios({
      url: `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/?&fields=sheets.properties`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return data;
  } catch (error) {
    console.log(getAxiosError(error));
    return false;
  }
};

const exportSpreadsheet = async (array, spreadsheetId, access_token) => {
  // let access_token = integrations.google_access_token;
  // let spreadsheetId =
  //   integrations.productSheet && integrations.productSheet.google_spreadsheetId;
  try {
    let res = await axios({
      url: `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values:batchUpdate`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      method: "POST",
      data: {
        valueInputOption: "RAW",
        data: [{ values: array, majorDimension: "ROWS", range: "A1" }],
      },
    });
    return res;
  } catch (error) {
    console.log(getAxiosError(error));
    return false;
  }
};

const setSpreadsheetDropdowns = async (access_token, spreadsheetId, data) => {
  // console.log(JSON.stringify(data, null, 2));
  try {
    let { data: response } = await axios({
      url: `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
      data: { requests: data },
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        // "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.log(getAxiosError(error), "SET DROPDOWN ERROR");
    return false;
  }
};
