export const actionStyles = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "300px",
    maxHeight: "80%",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "30px 15px 25px 15px",
    position: "absolute",
    right: "30px",
    top: "85px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    userSelect: "none",
  },
  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  headerIcon: {
    position: "absolute",
    right: "15px",
    top: "5px",
  },
  labelBox: {
    alignSelf: "start",
    margin: "0px 0px 5px 10px",
  },
  label: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "18px",
    // marginLeft: "20px",
  },
  nodeBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "10px 0px 20px 0px",
    flexWrap: "wrap",
  },
  node: {
    margin: "1%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "15px",
    borderRadius: "10px",
    padding: "5px 0 5px  5px",
    cursor: "grab",
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ECECEC",
    },
  },
  nodeIcon: {
    backgroundColor: "#dcdcdc",
    padding: "7px",
    fontSize: "22px",
    borderRadius: "25%",
  },
  nodeLabel: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 13,
    marginLeft: "10px",
    wordBreak: "break-word",
    width: "85px",
  },
};
