import { isObjWithValues, validateNumber } from "jwero-javascript-utils";

const getBonusInfoOnPlanRedeem = ({ plans, user_plan_info }) => {
  let current_plan = plans?.find(
    (i) => i?.id + "" === user_plan_info?.product + ""
  );
  let rewardObj = current_plan?.meta_data?.find((i) => i?.key === "reward");
  rewardObj = rewardObj?.value;
  if (isObjWithValues(rewardObj) && isObjWithValues(user_plan_info)) {
    return calculateTotalRewardWithBreakup(rewardObj, user_plan_info);
  }
};
function calculateTotalRewardWithBreakup(rewardDetails, userLedger) {
  let totalReward = 0;
  const monthsPaid = userLedger.ledger.length; // The length of the ledger array as the months paid
  let rewardBreakup = [];

  // Calculate total rewards based on months paid
  for (let key in rewardDetails) {
    if (rewardDetails.hasOwnProperty(key)) {
      const rewardMonth = parseInt(rewardDetails[key].reward_month, 10);
      const rewardAmount = parseInt(rewardDetails[key].reward_amount, 10);
      if (monthsPaid >= rewardMonth) {
        totalReward += rewardAmount;
        rewardBreakup.push({
          month: rewardMonth,
          amount: rewardAmount,
          cumulativeReward: totalReward,
        });
      }
    }
  }

  return {
    total_reward: totalReward,
    reward_breakup: rewardBreakup,
  };
}

function determinePlanStatus({ planInfo = {}, allClosedPlansObj = {} }) {
  const totalPayments = validateNumber(planInfo.duration);
  const paymentsMade = planInfo.ledger.length;
  const isPlanCancelled = planInfo.hasOwnProperty("is_cancelled");
  //  &&
  // planInfo.is_cancelled in allClosedPlansObj;

  // Check if the plan is cancelled and in allClosedPlansObj
  if (isPlanCancelled) {
    // Determine if it's Partially Closed or Closed based on payments made
    if (paymentsMade < totalPayments) {
      return "partially_closed";
    } else {
      return "closed";
    }
  } else {
    // If not cancelled, check if all payments are made
    if (paymentsMade < totalPayments) {
      // Check for any overdue payments by comparing dates
      const lastPaymentDate = new Date(
        planInfo.ledger[planInfo.ledger.length - 1].date_paid
      );
      const currentDate = new Date();
      if (
        currentDate > lastPaymentDate.setMonth(lastPaymentDate.getMonth() + 1)
      ) {
        return "overdue";
      }
      return "active";
    } else {
      // All payments made, but not in allClosedPlansObj
      return "completion_pending";
    }
  }
}

export { getBonusInfoOnPlanRedeem, determinePlanStatus };
