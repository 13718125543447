import { useState } from "react";
import { useSelector } from "react-redux";
import {
  FormControlLabel,
  Checkbox,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Collapse,
  Button,
  CircularProgress,
  Stack,
  Box,
} from "@mui/material";

import DataTimePickerComponent from "../helper/DataTimePickerComponent";
import { updateNodesWithRef } from "../utils/addReference";
import { useParams } from "react-router-dom";
import { backendApiCall } from "../../../../../helper";

const CampaignSave = ({ open, setOpen }) => {
  const { id } = useParams();
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const title = useSelector((state) => state.flow?.title);
  const segments = useSelector((state) => state.flow?.segments);
  const flowChannels = useSelector((state) => state.flow?.flowChannels);
  const flow_details = useSelector((state) => state.flow?.flow_details);

  const handleCloseDialog = () => {
    if (loading) {
      return;
    }
    setOpen(false);
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleDateTimeChange = (newDateTime) => {
    setSelectedDateTime(newDateTime);
  };

  const handleSaveFlow = async () => {
    setLoading(true);
    const payload = {
      nodes,
      edges,
      title,
      meta_data: {
        schedule: checked,
        schedule_date: checked ? selectedDateTime : null,
        segments: segments,
      },
    };

    const data = {
      ...(id ? flow_details : {}),
      title: title,
      nodes: nodes,
      edges: edges,
      channels: flowChannels,
      type: "chatbot",
      // theme_mode: mode,
    };
    data.nodes = updateNodesWithRef(nodes, edges);
    console.log("API CALL", data);

    await backendApiCall({
      endpoint: `flow/${id}`,
      method: "PUT",
      data: data,
    });

    console.log("API CALL", data);

    // await backendApiCall({
    //   endpoint: "campaigns/",
    //   method: "POST",
    //   data: payload,
    // });

    setLoading(false);
    handleCloseDialog();
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          fontWeight: 600,
          fontFamily: "Inter",
          borderBottom: "1px solid #dbe1e8",
          textAlign: "center",
          fontSize: "18px",
          marginBottom: "30px",
        }}
        id="alert-dialog-title"
      >
        Select Campaign
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheckboxChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Schedule for later"
        />
        <Collapse in={checked}>
          <DataTimePickerComponent
            selectedDateTime={selectedDateTime}
            setSelectedDateTime={setSelectedDateTime}
            handleDateTimeChange={handleDateTimeChange}
          />
        </Collapse>
      </DialogContent>
      <DialogActions
        sx={{
          marginBottom: "15px",
          maxHeight: "50px",
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-between",
        }}
      >
        <Button
          sx={{
            fontFamily: "Inter",
            backgroundColor: "#fff",
            border: "1px solid #dbe1e8",
            textTransform: "none",
            color: "#516173",
            cursor: loading ? "not-allowed" : "pointer",
            "&:hover": {
              border: "1px solid #006cff",
              backgroundColor: "#fff",
              transition: "all .3s",
            },
          }}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Box>
          {!loading ? (
            <Button
              sx={{
                fontFamily: "Inter",
                backgroundColor: "#006cff",
                border: "1px solid #006cff",
                textTransform: "none",
                color: "#fff",

                "&:hover": {
                  border: "1px #006cff",
                  backgroundColor: "#6295f5",
                },
              }}
              onClick={handleSaveFlow}
              autoFocus
            >
              {checked ? "Schedule" : "Start campaign"}
            </Button>
          ) : (
            <Stack
              direction="row"
              sx={{ height: "30px", marginRight: "5px", alignItems: "center" }}
            >
              <CircularProgress sx={{ height: "20px" }} />
            </Stack>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignSave;
