import React from "react";
import HorizontalStack from "../../../../../helper/utility/HorizontalStack";
import { Box, Grid, useTheme } from "@mui/material";
import {
  PrimaryLabel,
  SecondaryLabel,
} from "../../../../../helper/utility/Labels";
import { DigigoldFixedPlan } from "../../../../../Svgs";

function PlanTypeSelection({ form, editForm }) {
  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        minHeight: "100%",
      }}
    >
      <Box sx={{}}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TypeView
              value={"fixed"}
              title="Fixed Plan"
              subtitle="Create plans with interval based payments, fixed amount, duration , rewards & gifts"
              form={form}
              editForm={editForm}
            />
          </Grid>
          <Grid item xs={6}>
            <TypeView
              value="flexi"
              title="Flexible Plan"
              subtitle="Create customizable plans that allow adjustments to plans limits, rewards, intervals & gifts at certain interval times"
              form={form}
              editForm={editForm}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default PlanTypeSelection;

const TypeView = ({ title, value, subtitle, form, editForm }) => {
  const selected = form?.digi_plan_type === value;
  const theme = useTheme();
  return (
    <Box
      sx={{
        border: 2,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        // flex: 1,
        backgroundColor: selected ? "#E9EFFF" : "transparent",
        minHeight: "370px",
        // height: "300pd",
        borderColor: selected ? "primary.main" : "transparent",
        borderRadius: "16px",
        boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.22)",
        cursor: "pointer",
        "&:hover": !selected
          ? {
              backgroundColor: "#f8faff",
            }
          : "",
      }}
      onClick={() =>
        editForm({
          digi_plan_type: value,
        })
      }
    >
      <Box sx={{ textAlign: "center", maxWidth: "80%" }}>
        <img
          src={`/static/img/digital_gold/${value}_plan_icon.png`}
          width="60px"
          height="60px"
        />

        <PrimaryLabel sx={{ mt: 2 }}>{title}</PrimaryLabel>
        <SecondaryLabel sx={{ mt: 2 }}>{subtitle}</SecondaryLabel>
      </Box>
    </Box>
  );
};
