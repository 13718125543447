// export function notUserNext(matchingEdges, nodes) {
//   const id = matchingEdges?.target;
//   const node = nodes.find((node) => node.id === id);
//   if (node?.type === "user" || node?.type === "user-attachment") {
//     return true;
//   }
//   return false;
// }

export function checkIflast({ nodes, edges, nodeId }) {
  const matchingEdges = edges.find((edge) => edge.source === nodeId);

  if (matchingEdges) {
    const currentNode = nodes?.find((node) => node?.id == matchingEdges?.source);
    const isValid = ["whatsapp-template", "sms", "push-notification", "email"];
    if (isValid.includes(currentNode?.type)) {
      return true;
    }
  }
  // if (matchingEdges) {
  //   if (notUserNext(matchingEdges, nodes)) {
  //     return false;
  //   }
  //   return true;
  // }
  // return false;
  return matchingEdges ? false : true;
}
