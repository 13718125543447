import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography, Tooltip, Icon } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { actionStyles } from "./styles/actionsStyles";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CategoryIcon from "@mui/icons-material/Category";
import SwapCallsSharpIcon from "@mui/icons-material/SwapCallsSharp";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import TollIcon from "@mui/icons-material/Toll";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import * as actions from "../../../../../redux/actions/flowsActions";
import { Draggable } from "react-beautiful-dnd";
import { checkIflast } from "../custom-nodes/helper/checkIfLast";
import TelegramIcon from "@mui/icons-material/Telegram";
import PersonIcon from "@mui/icons-material/Person";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import AddTaskIcon from "@mui/icons-material/AddTask";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import "./styles/draggable-node-box.css";
import { getAllowedNodes } from "../custom-nodes/utils/getAllowedNodes";

const styles = actionStyles;

const webchatNodes = [
  "bot",
  "user",
  // "user-attachment",
  "question",
  "filter",
  "fallback",
  "go-to-step",
  "whatsapp-template",
  "live-agent",
  "product-catalog",
  "subscribe",
  "unsubscribe",
  "create-task",
  "metal-rates",
  "assign",
];

const campaignNodes = [
  "filter",
  "fallback",
  "go-to-step",
  "whatsapp-template",
  "email",
  "sms",
  "push-notification",
  "delay",
  "subscribe",
  "unsubscribe",
  "create-task",
  "assign",
];
export const triggerNodes = [
  // "filter",
  // "fallback",
  // "go-to-step",
  "whatsapp-template",
  "email",
  "sms",
  "push-notification",
  "delay",
  // "subscribe",
  // "unsubscribe",
  // "create-task",
  // "assign",
];

const ActionsBox = () => {
  const dispatch = useDispatch();
  // const nodes = useSelector((state) => state.flow?.nodes);
  // const edges = useSelector((state) => state.flow?.edges);
  // const currentNodeId = useSelector((state) => state.flow?.currentNodeId);
  // const currentNode = nodes?.find((node) => node?.id === currentNodeId);
  const chatbotType = useSelector((state) => state.flow?.chatbotType);
  const whatsappIntegrated = useSelector(
    (state) => state.flow?.integration
  )?.whatsappIntegrated;
  const isDragging = useSelector((state) => state.flow?.isDragging);

  const handleClose = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { actionsMenu: false },
    });
  };
  const currentFilterNodes =
    // chatbotType.type === "campaign" ? webchatNodes : campaignNodes;
    chatbotType?.type === "campaign"
      ? campaignNodes
      : chatbotType?.type === "trigger"
      ? triggerNodes
      : webchatNodes;

  const interactionsNode = [
    {
      id: "01",
      label: "Bot Response",
      value: "bot",
      icon: (
        <TelegramIcon sx={{ ...styles.nodeIcon, backgroundColor: "#f0f0f0" }} />
      ),
      bgColor: "#dcdcdc",
    },
    {
      id: "02",
      label: "User Input",
      value: "user",
      icon: (
        <PersonIcon
          sx={{ ...styles.nodeIcon, backgroundColor: "#446DF6", color: "#fff" }}
        />
      ),
    },
    {
      id: "05",
      label: "Question",
      value: "question",
      icon: (
        <QuestionMarkIcon
          sx={{ ...styles.nodeIcon, backgroundColor: "#ffad5c" }}
        />
      ),
    },
    // {
    //   id: "18",
    //   label: "User attachment",
    //   value: "user-attachment",
    //   icon: (
    //     <Icon
    //       sx={{
    //         ...styles.nodeIcon,
    //         backgroundColor: "#ebebeb",
    //         color: "#000",
    //       }}
    //     >
    //       <AttachFileOutlinedIcon sx={{ transform: "rotate(90deg)" }} />
    //     </Icon>
    //   ),
    // },
    {
      id: "08",
      label: "Live Agent",
      value: "live-agent",
      icon: (
        <SupportAgentIcon
          sx={{
            ...styles.nodeIcon,
            backgroundColor: "#008aff",
            color: "#F0F0F0",
          }}
        />
      ),
    },
    {
      id: "15",
      label: "Metal Rates",
      value: "metal-rates",
      icon: (
        <TollIcon
          sx={{ ...styles.nodeIcon, backgroundColor: "#FFD700", color: "#000" }}
        />
      ),
    },
  ];
  const filteredInteractionsNodes = interactionsNode.filter((node) =>
    // console.log(node?.value)
    currentFilterNodes.includes(node?.value)
  );

  // tasks,
  const actionsNode = [
    {
      id: "06",
      label: "Filter",
      value: "filter",
      icon: (
        <FilterAltOutlinedIcon
          sx={{
            ...styles.nodeIcon,
            backgroundColor: "#686ad3",
            color: "#fff",
          }}
        />
      ),
    },
    {
      id: "19",
      label: "Fallback",
      value: "fallback",
      icon: (
        <Icon
          sx={{
            ...styles.nodeIcon,
            backgroundColor: "#797b8c",
            color: "#fff",
            padding: 0,
          }}
        >
          <ReplyAllIcon style={{ height: "1rem", width: "1rem" }} />
        </Icon>
      ),
    },
    {
      id: "07",
      label: "Go to step",
      value: "go-to-step",
      icon: (
        <Icon
          sx={{
            ...styles.nodeIcon,
            backgroundColor: "#ffdc66",
            color: "#000",
            padding: 0,
          }}
        >
          <SwapCallsSharpIcon sx={{ transform: "rotate(90deg)" }} />
        </Icon>
      ),
    },
    {
      id: "09",
      label: "Subscribe",
      value: "subscribe",
      icon: (
        <BookmarkAddIcon
          sx={{ ...styles.nodeIcon, backgroundColor: "#9dca80" }}
        />
      ),
    },
    {
      id: "10",
      label: "Unsubscribe",
      value: "unsubscribe",
      icon: (
        <BookmarkRemoveIcon
          sx={{
            ...styles.nodeIcon,
            backgroundColor: "#FB4B54",
            color: "#ffffff",
          }}
        />
      ),
    },
    {
      id: "14",
      label: "Delay",
      value: "delay",
      icon: (
        <HourglassBottomIcon
          sx={{ ...styles.nodeIcon, backgroundColor: "#bae8e8" }}
        />
      ),
    },
    {
      id: "16",
      label: "Create task",
      value: "create-task",
      icon: (
        <AddTaskIcon sx={{ ...styles.nodeIcon, backgroundColor: "#8884FF" }} />
      ),
    },
    {
      id: "17",
      label: "Assign Team",
      value: "assign",
      icon: (
        <GroupAddIcon sx={{ ...styles.nodeIcon, backgroundColor: "#BBE1C3" }} />
      ),
    },
  ];

  const filteredActionsNodes = actionsNode.filter((node) =>
    currentFilterNodes.includes(node?.value)
  );

  const channelsNode = [
    {
      id: "03",
      label: "Whatsapp Template",
      value: "whatsapp-template",
      icon: (
        <WhatsAppIcon
          sx={{
            ...styles.nodeIcon,
            color: "#fff",
            backgroundColor: "#25D366",
          }}
        />
      ),
    },
    {
      id: "04",
      label: "Product Catalog",
      value: "product-catalog",
      icon: (
        <CategoryIcon
          sx={{
            ...styles.nodeIcon,
            backgroundColor: "#00b3fa",
            color: "#fff",
          }}
        />
      ),
    },
    {
      id: "11",
      label: "Email",
      value: "email",
      icon: (
        <EmailIcon
          sx={{
            ...styles.nodeIcon,
            backgroundColor: "#20A4F3",
            color: "#f0f0f0",
          }}
        />
      ),
    },
    {
      id: "12",
      label: "SMS",
      value: "sms",
      icon: (
        <SmsIcon
          sx={{
            ...styles.nodeIcon,
            backgroundColor: "#98E4FF",
            color: "#0F0808",
          }}
        />
      ),
    },
    {
      id: "13",
      label: "Push notification",
      value: "push-notification",
      icon: (
        <NotificationsActiveIcon
          sx={{ ...styles.nodeIcon, backgroundColor: "#86CB92" }}
        />
      ),
    },
  ];

  const filteredChannelNodes = channelsNode.filter((node) =>
    currentFilterNodes.includes(node?.value)
  );

  return (
    <Box sx={styles.mainContainer} className="main-box">
      <IconButton sx={styles.headerIcon} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      {filteredInteractionsNodes.length > 0 && (
        <Box sx={styles.labelBox}>
          <Typography sx={styles.label} variant="span">
            Interactions
          </Typography>
        </Box>
      )}

      {filteredInteractionsNodes.length > 0 && (
        <Box sx={styles.nodeBox}>
          {filteredInteractionsNodes &&
            filteredInteractionsNodes.map((action, index) => (
              <Draggable key={action.id} draggableId={action.id} index={index}>
                {(provided, snapshot) => (
                  <Box
                    key={action.id}
                    sx={{
                      ...styles.node,
                      visibility:
                        !isDragging || snapshot.isDragging
                          ? "visible"
                          : "hidden",
                    }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {action?.icon}
                    <Typography sx={styles.nodeLabel} variant="span">
                      {action.label}
                    </Typography>
                  </Box>
                )}
              </Draggable>
            ))}
        </Box>
      )}

      <Box sx={styles.labelBox}>
        <Typography sx={styles.label} variant="span">
          Actions
        </Typography>
      </Box>

      <Box sx={styles.nodeBox}>
        {filteredActionsNodes &&
          filteredActionsNodes.map((action, index) => (
            <Draggable key={action.id} draggableId={action.id} index={index}>
              {(provided, snapshot) => (
                <Box
                  key={action.id}
                  sx={{
                    ...styles.node,
                    visibility:
                      !isDragging || snapshot.isDragging ? "visible" : "hidden",
                  }}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {action?.icon}
                  <Typography sx={styles.nodeLabel} variant="span">
                    {action.label}
                  </Typography>
                </Box>
              )}
            </Draggable>
          ))}
      </Box>

      <Box sx={styles.labelBox}>
        <Typography sx={styles.label} variant="span">
          Channels
        </Typography>
      </Box>

      <Box sx={styles.nodeBox}>
        {filteredChannelNodes &&
          filteredChannelNodes.map((action, index) => (
            <Draggable key={action.id} draggableId={action.id} index={index}>
              {(provided, snapshot) =>
                !whatsappIntegrated && action.id === "03" ? (
                  <Tooltip
                    title="WhatsApp not integrated!"
                    placement="bottom"
                    key={action.id}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          paddingTop: "7px",
                          backgroundColor: "#ff4545",
                          color: "white",
                          height: "20px",
                          fontSize: "14px",
                          textAlign: "center",
                        },
                      },
                    }}
                  >
                    <Box
                      key={action.id}
                      sx={{
                        ...styles.node,
                        visibility:
                          !isDragging || snapshot.isDragging
                            ? "visible"
                            : "hidden",
                        opacity:
                          action.id === "03" && !whatsappIntegrated ? 0.3 : 1,
                        "&:hover": {
                          cursor:
                            action.id === "03" && !whatsappIntegrated
                              ? "not-allowed"
                              : "pointer",
                        },
                      }}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {action?.icon}
                      <Typography sx={styles.nodeLabel} variant="span">
                        {action.label}
                      </Typography>
                    </Box>
                  </Tooltip>
                ) : (
                  <Box
                    key={action.id}
                    sx={{
                      ...styles.node,
                      visibility:
                        !isDragging || snapshot.isDragging
                          ? "visible"
                          : "hidden",
                    }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {action?.icon}
                    <Typography sx={styles.nodeLabel} variant="span">
                      {action.label}
                    </Typography>
                  </Box>
                )
              }
            </Draggable>
          ))}
      </Box>
    </Box>
  );
};

export default ActionsBox;
