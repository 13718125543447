import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { TabPanel } from "../../../../helper";
import Integration from "./integration";
import { isObjWithValues } from "jwero-javascript-utils";
import { useSelector } from "react-redux";
import Settings from "./settings";
import { ListItem } from "../meta/MetaOverview";
import Activities from "./Activities";
import Templates from "./Templates";
// import Templates from "./Templates";

function GupShup() {
  const [selectedTab, setSelectedTab] = useState("overview");

  const gupshupIntegration = useSelector(
    (state) => state.settings.gupshupIntegration
  );

  return (
    <Box sx={{ height: "100%", paddingRight: "15px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <img
              src="/static/img/brands/gupshup.png"
              width="25px"
              height="22px"
              alt="GupShup"
            />
            GupShup
          </Typography>
        </Stack>
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          <Tab label="Integration" value="integration" />
          {/* <Tab label="Activities" value="activities" /> */}
          {gupshupIntegration?.enabled === "yes" && (
            <Tab label="Templates" value="templates" />
          )}
          {/* {isObjWithValues(gupshupIntegration) && (
            <Tab label="Settings" value="settings" />
          )} */}
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"overview"}>
        <Overview />
      </TabPanel>
      <TabPanel value={selectedTab} index={"integration"}>
        <Integration />
      </TabPanel>
      {/* <TabPanel value={selectedTab} index={"activities"}>
        <Activities />
      </TabPanel> */}
      <TabPanel value={selectedTab} index={"templates"}>
        <Templates />
      </TabPanel>

      {/* {isObjWithValues(gupshupIntegration) && (
        <TabPanel value={selectedTab} index={"settings"}>
          <Settings />
        </TabPanel>
      )} */}
    </Box>
  );
}

export default GupShup;

const benefits = [
  "✅ Quickly Integrate with Jwero.",
  "✅ Set up a points assigning system and sync with Jwero to ensure customers receive loyalty points on spends.",
  "✅ Customers will be able to redeem points upon checkout.",
  "✅ Increase Customer Conversion and encourage Repeat Business.",
];

function Overview() {
  return (
    <Box sx={{ padding: "16px" }}>
      <Typography variant="subtitle1" sx={{ fontSize: "15px" }}>
        What's Gupshup?
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "15px", mt: 3 }}>
        Gupshup enables businesses to add loyalty program to your jewellery
        store, a system that rewards your customers and helps increase your
        sales.
      </Typography>
      <ListItem arr={benefits} />
    </Box>
  );
}
