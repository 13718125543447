import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { MySegment } from "../../customers/components/CampaignHistory";
import Flows from "../../flows";
import Marketing from "../pages/marketing";
import Templates from "../pages/templates";
import { CreateSegment } from "../../customers/components/EmailTemplate";
import { isObjWithValues } from "jwero-javascript-utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ flex: 1 }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const tabs = [
  { label: "Marketing", value: "marketing" },
  { label: "Chatbots", value: "chatbots" },
  { label: "Templates", value: "templates" },
  { label: "Segments", value: "segments" },
];

export default function MainSidebar() {
  const [value, setValue] = React.useState("marketing");
  const [isEditSegment, setIsEditSegment] = React.useState({});
  const [showCreateSegment, setShowCreateSegment] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        flex: 1,
        bgcolor: "background.paper",
        display: "flex",
        // height: 224,
        mt: 3,
        minHeigh: "calc(100vh - 161px)",
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        {tabs?.map((i) => {
          return (
            <Tab
              key={i.value}
              label={i.label}
              value={i.value}
              sx={{ px: 6, fontSize: "14px" }}
            />
          );
        })}
        {/* <Tab label="Item One" {...a11yProps(0)} />
        <Tab label="Item Three" {...a11yProps(2)} />
        <Tab label="Item Four" {...a11yProps(3)} />
        <Tab label="Item Five" {...a11yProps(4)} />
        <Tab label="Item Six" {...a11yProps(5)} />
        <Tab label="Item Seven" {...a11yProps(6)} /> */}
      </Tabs>
      <TabPanel value={value} index={"marketing"}>
        <Marketing />
      </TabPanel>
      <TabPanel value={value} index={"chatbots"}>
        <Flows />
      </TabPanel>
      <TabPanel value={value} index={"segments"}>
        {!isObjWithValues(isEditSegment) && !showCreateSegment ? (
          <MySegment
            showCreate
            onEditSegment={setIsEditSegment}
            onCreatePress={() => {
              console.log("CREATE PRESS");
              setShowCreateSegment(true);
            }}
          />
        ) : (
          <CreateSegment
            setSelectedPage={() => {
              setIsEditSegment({});
              setShowCreateSegment(false);
            }}
            isEdit={isEditSegment}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={"templates"}>
        <Templates />
      </TabPanel>
    </Box>
  );
}
