import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Background,
  Controls,
  MiniMap,
  Panel,
  ReactFlow,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import FlowCard from "./customNode/FlowCard";
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  // Buttonedge,

  EmailNode,
  InitialNode,
  PushNotificationNode,
  SmsNode,
  WhatsappNode,
} from "./customNode/nodeComponents";

import SideBar from "./SideBar";
import {
  ConfimPopup,
  apiCallFromSocket,
  convertToMilliseconds,
  formatDate,
  formatServerValue,
  getAuthToken,
  getRandomString,
  getStoreId,
  getTimeInMilliSeconds,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  nodeWebsite,
  settingsEndpoint,
  sleep,
  unformatServerValue,
  validateNumber,
} from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { CampaignIconSvg, CampaignLaunchSvg, Confetti } from "../../Svgs";
import { Edit, VisibilityOutlined } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  EDIT_CAMPAIGN,
  getFlows,
  getFlowsHistories,
} from "../../redux/actions/campaignActions";
import { Label } from "../customers/components/Info";
import { ArrowBackIosNew, PeopleOutlineRounded } from "@mui/icons-material";
import axios from "axios";
import { extractPrefix } from "./hepler";
import { CustomEdge } from "./customNode/edgesComponents";
import { campaignChannelIcon } from "../customers/components/CampaignHistory";
import RocketLaunchButton from "./RocketLauch";

export const yellowJwero = "#f6b11b";

export const FlowStateContext = createContext();
const nodeTypes = {
  initial: InitialNode,
  WhatsappNode: WhatsappNode,
  SmsNode: SmsNode,
  EmailNode: EmailNode,
  PushNotificationNode: PushNotificationNode,

  textUpdater: FlowCard,
};
const reportNodeTypes = {
  initial: InitialNode,
  WhatsappNode: WhatsappNode,
  SmsNode: SmsNode,
  EmailNode: EmailNode,
  PushNotificationNode: PushNotificationNode,
};
const edgesTypes = {
  buttonedge: CustomEdge,
};

let subHeight = 160;

const CampaignFlowBuilder = () => {
  const reactFlowWrapper = useRef(null);
  const { id: flowId } = useParams();
  const navigate = useNavigate();
  const campaignFlow = useSelector((state) => state.campaign.campaignFlow);
  const campaignFlowHistory = useSelector(
    (state) => state.campaign.campaignFlowHistory
  );

  const [selectedNode, setSelectedNode] = useState(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [openFlowNameDialog, setOpenFlowNameDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const [campaignState, setCampaignState] = useState({});
  const [hasError, setHasError] = useState({});
  const [flowObject, setFlowObject] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(null);
  const [isEditable, setIsEditable] = useState(true);
  const [openDelay, setOpenDelay] = useState(false);
  const [launching, setLaunching] = useState(false);
  const [selectedEdge, setSelectedEdge] = useState(false);
  const [historyObject, setHistoryObject] = useState({});
  const [analytics, setAnalytics] = useState({});
  const [isReport, setIsReport] = useState(false);
  const [isExploding, setIsExploding] = React.useState(false);
  const [openCongrats, setOpenCongrats] = React.useState(false);
  let location = useLocation();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);

  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onInit = (reactFlowInstance) => setReactFlowInstance(reactFlowInstance);

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => {
        let updatedParams = { ...params, type: "buttonedge" };
        updatedParams.action = unformatServerValue(
          params?.sourceHandle?.replace(params?.source, "")
        );
        updatedParams.channel = extractPrefix(params?.target);
        // console.log(updatedParams);
        return addEdge(updatedParams, eds);
      });
    },
    [setEdges]
  );

  const dispatch = useDispatch();

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  useEffect(() => {
    setIsReport(location?.pathname?.includes("report"));
    if (
      location?.pathname?.includes("report") &&
      !isObjWithValues(campaignFlowHistory)
    ) {
      dispatch(getFlowsHistories());
      setIsEditable(false);
    }
    return () => {};
  }, [location]);
  useEffect(() => {
    setHistoryObject(campaignFlowHistory?.[flowId]?.sent_info || {});
    let audience = [
      ...new Set(
        Object.values(campaignFlowHistory?.[flowId]?.sent_info || {})
          ?.map((o) => Object.values(o || {}))
          ?.flat(4)
          ?.map((o) => o?.id?.toString())
      ),
    ]?.length;

    setAnalytics((pre) => ({
      ...pre,
      audience: audience,
      time: validateNumber(campaignFlowHistory?.[flowId]?.launch_time),
    }));

    return () => {};
  }, [campaignFlowHistory, flowId]);

  useEffect(() => {
    if (!isArrayWithValues(campaignFlow)) dispatch(getFlows());
    if (!isObjWithValues(campaignFlowHistory)) dispatch(getFlowsHistories());

    return () => {};
  }, []);

  useEffect(() => {
    if (!flowId) {
      setOpenFlowNameDialog(true);
      return;
    }
    if (isArrayWithValues(campaignFlow) && flowId) {
      let flow = campaignFlow?.find((o) => o?.id === flowId);
      setFlowObject(flow);

      setNodes(flow?.nodes || []);
      setEdges(flow?.connection || []);
      setCampaignState(flow?.target_info || {});
      //   fitView();
    }
    // else {

    //     let flow
    // }
  }, [flowId, campaignFlow]);

  useEffect(() => {
    const node = nodes.filter((node) => {
      if (node.selected) return true;
      return false;
    });
    if (node[0]) {
      setSelectedNode(node[0]);
      setSelectedEdge(false);
      // setIsSelected(true);
    } else {
      setSelectedNode("");
      // setIsSelected(false);
    }
  }, [nodes]);
  useEffect(() => {
    const edge = edges.filter((node) => {
      if (node.selected) return true;
      return false;
    });
    if (edge[0]) {
      setSelectedEdge(edge[0]);

      // setIsSelected(true);
    } else {
      setSelectedNode("");
      // setIsSelected(false);
    }
  }, [edges]);
  const onEditForm = ({ payload, key }) => {
    setCampaignState((pre) => {
      let oldObj = pre?.[key] || {};
      // let { campaign_info } = pre || {};
      // let
      pre = { ...pre, [key]: { ...oldObj, ...payload } };
      return pre;
    });
  };
  const handleEdgeClick = (event, edge) => {
    setSelectedEdge(edge);
  };

  const contextValue = {
    campaignState,
    setCampaignState,
    onEditForm,
    edges,
    setDeleteOpen,
    selectedNode,
    setSelectedNode,
    isEditable,
    hasError,
    setEdges,
    setSelectedEdge,
    selectedEdge,
    isReport,
    historyObject,
    analytics,
    flowObject,
    setFlowObject,
  };

  const onDeleteNode = () => {
    dispatch(
      setGlobalToast({
        show: true,
        message: `${
          campaignState?.[deleteOpen]?.name ||
          formatServerValue((deleteOpen || "")?.split("_")?.[0] || "")
        } Deleted Successfully!`,
        severity: "success",
      })
    );
    setCampaignState((pre) => {
      delete pre[deleteOpen];
      return pre;
    });
    setNodes((pre) => pre?.filter((o) => o?.id !== deleteOpen));
    setEdges((pre) => pre?.filter((o) => o?.target !== deleteOpen));
    setDeleteOpen(false);
  };

  const onLaunch = async () => {
    const token = getToken();
    const website = getWebsite();
    setLaunching(true);
    let socketRes = await axios({
      // url: `http://192.168.1.15:8081/campaign_flow`,
      url: `${nodeWebsite}/campaign_flow`,
      method: "POST",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        flow_id: flowId,
        website,
        store_id: getStoreId(),
        newToken: getAuthToken(),
      },
    });
    await sleep(1000);
    setLaunching(false);
    dispatch(
      setGlobalToast({
        show: true,
        message: "Launch successfully!",
        severity: "success",
      })
    );
    setOpenCongrats(true);
    await sleep(500);
    setIsExploding(true);
    await sleep(1000);
    setIsExploding(false);
  };
  const onSave = async () => {
    setSaving(true);

    // let id = `${getTimeInMilliSeconds()}_${getRandomString(5)}`
    let data = {
      [flowId]: {
        ...flowObject,
        connection: edges,
        nodes: nodes?.map((obj) => {
          delete obj.icon;
          delete obj.data;
          return obj;
        }),
        target_info: campaignState,
      },
    };
    console.log(data);
    let campaignFlow = await settingsEndpoint({
      endpoint: "campaign_flow",
      method: "PUT",
      data,
    });
    if (isObjWithValues(campaignFlow)) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Saved successfully!",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_CAMPAIGN,
        payload: {
          campaignFlow: Object.values(campaignFlow || []),
        },
      });
      // handleClose()
      // navigate(`/campaign-flow/${id}`)
    } else {
      dispatch(
        setGlobalToast({
          show: true,
          message: `Error`,
          severity: "error",
        })
      );
    }
    setSaving(false);
  };
  useEffect(() => {
    if (
      !flowId &&
      !isObjWithValues(flowObject) &&
      !isArrayWithValues(edges) &&
      !isArrayWithValues(nodes)
    )
      return;

    let timeoutId = setTimeout(async () => {
      let data = {
        [flowId]: {
          ...flowObject,
          connection: edges,
          nodes: nodes?.map((obj) => {
            delete obj.icon;
            delete obj.data;
            return obj;
          }),
          target_info: campaignState,
        },
      };
      console.log(data);
      let campaignFlow = await settingsEndpoint({
        endpoint: "campaign_flow",
        method: "PUT",
        data,
      });
      // Code to run after the delay
      // await onSave();
      // dispatch(
      //     setGlobalToast({
      //         show: true,
      //         message: "Saved successfully!",
      //         severity: "success",
      //     })
      // );
    }, 5000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [campaignState, nodes?.length]);
  useEffect(() => {
    let allTargets = [
      ...new Set([...(edges?.map((o) => o?.target) || []), "campaign_info"]),
    ];

    let conditions = {
      campaign: (key) => !isArrayWithValues(campaignState?.[key]?.segment_id),
      whatsapp: (key) => !isObjWithValues(campaignState?.[key]?.template),
      sms: (key) => !isObjWithValues(campaignState?.[key]?.template_id),
      email: (key) => !isObjWithValues(campaignState?.[key]?.template),
      push_notification: (key) =>
        !isObjWithValues(campaignState?.[key]?.payload),
    };
    let tempObj = {};
    for (const nodeId of allTargets) {
      tempObj = {
        ...tempObj,
        [nodeId]: conditions?.[extractPrefix(nodeId)]
          ? conditions?.[extractPrefix(nodeId)](nodeId)
          : false,
      };
      setHasError((pre) => ({
        ...pre,
        [nodeId]: conditions?.[extractPrefix(nodeId)]
          ? conditions?.[extractPrefix(nodeId)](nodeId)
          : false,
      }));
    }
    console.log(tempObj);
    return () => {};
  }, [campaignState, nodes?.length, edges?.length]);

  let flowHeight = `calc(100vh - ${subHeight}px)`;

  return (
    <>
      <RocketLaunchButton />
      <ConfimPopup
        dialogProps={{
          TransitionComponent: Transition,
        }}
        // heading={<>Congratulations <Confetti /> </>}
        content={
          <>
            <CampaignLaunchSvg width={382} height={382} />
            <Typography
              textAlign={"center"}
              variant="h5"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                justifyContent: "center",
              }}
            >
              <Confetti /> Campaign Launch Successfully! 🚀
            </Typography>
            <Typography textAlign={"center"} sx={{ mt: 2 }}>
              Congratulations your campaign has been <br /> set in motion.
            </Typography>
            {/* {isExploding && <ConfettiExplosion zIndex={1000} />}
            {`Congratulations on the successful launch of your ${campaignState?.[deleteOpen]?.name} campaign! This is an exciting milestone and a testament to your hard work, creativity, and strategic thinking.`} */}
          </>
        }
        // button={
        //   <Button
        //     color="error"
        //     variant="contained"
        //     onClick={() => {
        //       onDeleteNode();
        //     }}
        //   >
        //     Delete
        //   </Button>
        // }
        open={Boolean(openCongrats)}
        closeLabel={"Close"}
        handleClose={() => setOpenCongrats(false)}
      />
      <FlowStateContext.Provider value={contextValue}>
        <FlowNameSave
          open={openFlowNameDialog}
          handleClose={() => {
            setOpenFlowNameDialog(false);
            navigate("/campaign#flows");
          }}
        />

        <ConfimPopup
          heading={"Confirmation"}
          content={
            <>
              <TextField size="small" />
            </>
          }
          button={
            <Button
              variant="contained"
              onClick={() => {
                // onDeleteNode();
              }}
            >
              Add
            </Button>
          }
          open={Boolean(openDelay)}
          handleClose={() => setOpenDelay(false)}
        />
        <ConfimPopup
          heading={"Confirmation"}
          content={`Are you sure you want to delete flow action ${
            campaignState?.[deleteOpen]?.name ||
            formatServerValue(
              formatServerValue((deleteOpen || "")?.split("_")?.[0] || "") || ""
            )
          }?`}
          button={
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                onDeleteNode();
              }}
            >
              Delete
            </Button>
          }
          open={Boolean(deleteOpen)}
          handleClose={() => setDeleteOpen(false)}
        />

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            borderBottom: 1,
            borderBottomColor: "divider",
            mb: 1,
            // height: "7%",
            padding: "16px",
          }}
          component={Paper}
          // sx={{  }}
        >
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <IconButton
              onClick={() => {
                navigate(`/campaign#flows`);
              }}
            >
              <ArrowBackIosNew />{" "}
            </IconButton>
            <Typography variant="h6">
              {flowObject?.name || "Campaign Flow"}
            </Typography>
          </Stack>
          <Stack direction={"row"} gap={3}>
            <IconButton onClick={() => setIsEditable((pre) => !pre)}>
              {isEditable ? (
                <Tooltip title={"Go to view Mode"}>
                  <VisibilityOutlined />
                </Tooltip>
              ) : (
                <Tooltip title={"Go to edit Mode"}>
                  <Edit />
                </Tooltip>
              )}
            </IconButton>
            <LoadingButton
              variant="outlined"
              size="small"
              loading={saving}
              // disabled={Object.values(campaignState || {})?.find(
              //     (o) => o.hasError
              // )}

              onClick={onSave}
            >
              {/* Publish */}
              Save as Draft
            </LoadingButton>
            <LoadingButton
              variant="contained"
              size="small"
              loading={launching}
              disabled={
                analytics?.time ||
                isArrayWithValues(
                  Object.values(hasError || {})?.filter(Boolean)
                )
              }
              // disabled={!isDev() || Object.values(campaignState || {})?.find(
              //     (o) => o.hasError
              // )}
              onClick={() => {
                onLaunch();

                // console.log({
                //     connection: edges,
                //     nodes: nodes?.map((obj) => {
                //         delete obj.icon;
                //         delete obj.data;
                //         return obj;
                //     }),
                //     target_info: campaignState,
                // });
              }}
            >
              {/* Publish */}
              {/* {isExploding && <ConfettiExplosion />} */}
              Launch Campaign
            </LoadingButton>
            {/* <Button
                            variant="contained"
                            loading={saving}
                            size="small"
                            onClick={onSave}
                        >
                            Save
                        </Button> */}
          </Stack>
        </Stack>
        <Grid
          container
          sx={{ height: flowHeight, overflow: "hidden" }}
          spacing={3}
        >
          <Grid
            item
            xs={
              isObjWithValues(selectedNode) || isObjWithValues(selectedEdge)
                ? 9
                : 12
            }
            sx={{ height: "100%", transition: "all 0.5s" }}
          >
            <Paper sx={{ height: "100%" }} ref={reactFlowWrapper}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={isReport || !isEditable ? null : onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                edgeTypes={edgesTypes}
                onDragOver={onDragOver}
                onInit={onInit}
                nodesDraggable={isEditable}
                nodesConnectable={isEditable}
                onEdgeClick={handleEdgeClick}
                deleteKeyCode={null}
                disableKeyboardA11y={isReport}
                proOptions={{ hideAttribution: true }}
              >
                <Controls />
                <MiniMap />
                <Background variant="lines" gap={12} size={1} />
                {isReport && (
                  <Panel>
                    <Card className="shadow_card">
                      <CardContent>
                        <Stack gap={5}>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={5}
                          >
                            <Typography variant="h6">
                              Total Audience :
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                              }}
                            >
                              <PeopleOutlineRounded color={"action"} />
                              {analytics?.audience}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={4}
                          >
                            <Typography variant="h6">Channels :</Typography>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              gap={3}
                            >
                              {[...new Set(edges?.map((o) => o?.channel))]?.map(
                                (str) => campaignChannelIcon?.[str]
                              )}
                            </Stack>
                          </Stack>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={2}
                          >
                            <Typography variant="h6">Date :</Typography>
                            {analytics?.time && (
                              <Typography variant="h6">
                                {formatDate(
                                  new Date(
                                    convertToMilliseconds(analytics?.time)
                                  ),
                                  "dd/mm/yyyy"
                                )}{" "}
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Panel>
                )}
              </ReactFlow>
            </Paper>
          </Grid>
          <Grid
            item
            xs={
              isObjWithValues(selectedNode) || isObjWithValues(selectedEdge)
                ? 3
                : 0
            }
            sx={{
              display:
                isObjWithValues(selectedNode) || isObjWithValues(selectedEdge)
                  ? "initial"
                  : "none",
              transition: "all 0.5s",
              height: "100%",
            }}
          >
            <Card
              sx={{
                height: "100%",
                borderLeft: "2px",
                borderLeftColor: "divider",
              }}
            >
              <CardContent sx={{ padding: 0 }}>
                <SideBar
                  selectedNode={selectedNode}
                  selectedEdge={selectedEdge}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </FlowStateContext.Provider>
    </>
  );
};

export default CampaignFlowBuilder;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const FlowNameSave = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [saving, setSaving] = useState();
  const [flowState, setFlowState] = useState({});
  const onEditForm = (payload) => {
    setFlowState((pre) => ({ ...pre, ...payload }));
  };
  const onSave = async () => {
    setSaving(true);
    let time = getTimeInMilliSeconds();
    let id = `${time}_${getRandomString(5)}`;
    let campaignFlow = await settingsEndpoint({
      endpoint: "campaign_flow",
      method: "PUT",
      data: {
        [id]: {
          connection: [],
          id,
          nodes: [
            {
              id: "campaign_info",
              label: "campaign_info",
              type: "initial",
              heading: "Campaign Info",
              sourcePosition: "right",
              position: { x: 10, y: 90 },
              selectable: true,
            },
          ],
          target_info: {},
          created_on: time,

          ...flowState,
        },
      },
    });
    if (isObjWithValues(campaignFlow)) {
      dispatch(
        setGlobalToast({
          show: true,
          message: `${flowState?.name} Added Successfully!`,
          severity: "success",
        })
      );

      dispatch({
        type: EDIT_CAMPAIGN,
        payload: {
          campaignFlow: Object.values(campaignFlow || {}),
        },
      });
      handleClose();
      navigate(`/campaign-flow/${id}`);
    } else {
      dispatch(
        setGlobalToast({
          show: true,
          message: `Error`,
          severity: "error",
        })
      );
    }
    setSaving(false);
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"xs"}>
        <DialogTitle>Create Campaign</DialogTitle>
        <DialogContent>
          <Label>Campaign Name</Label>
          <TextField
            autoFocus
            // margin="dense"
            size="small"
            fullWidth
            value={flowState?.name}
            onChange={(e) => onEditForm({ name: e.target.value })}
            // variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={saving} onClick={onSave} variant="contained">
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
