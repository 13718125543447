export const liveAgentStyles = {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      fontFamily: "Inter",
    },
    nodeInitial: {
      position: "relative",
      top: "20px",
      left: "10px",
      color: "#797b8c",
      textAlign: "center",
      paddingRight: "35px",
    },
    bottomRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      "&:hover": {
        cursor: "pointer",
      },
    },
    nodeBody: {
      margin: 0,
      padding: "0px 15px 0px 5px",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      height: "30px",
      minWidth: "90px",
      fontSize: "16px",
      color: "#fff",
      backgroundColor: "#008aff",
      borderRadius: "20px",
      marginBottom: "30px",
    },
    titleName: {
      fontSize: "12px",
      fontFamily: "Inter",
      fontWeight: 525,
    },
    iconButton: {
      height: "20px",
      width: "20px",
      marginLeft: "5px",
      marginRight: '4px',
      "&:hover": {
        backgroundColor: "#008aff"
      }
    },
    nodeIcon: {
      fontSize: "19px",
      color: "#fff",
      fontWeight: 600,
    },
    addIcon: {
      fontSize: "14px",
      height: "20px",
      width: "20px",
      color: "#22304a",
      borderRadius: "50%",
      backgroundColor: "white",
      marginLeft: "4px",
      transition: "visibility 0s, color 0.2s",
      position: "relative",
      bottom: "13px",
      "&:hover": {
        color: "blue",
      },
    },
    leftHandle: {
      background: "#566d89",
      opacity: "0",
      top: "-11px",
      left: "10px",
      position: "relative",
    },
    rightHandle: {
      background: "#566d89",
      opacity: "0",
      top: "3px",
      right: "10px",
      position: "relative",
    },
  };
  