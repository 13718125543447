export const userAttachmentNodeStyles = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: "Inter",
  },
  bottomRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    "&:hover": {
      cursor: "pointer",
    },
  },
  nodeTitleBox: {
    color: "#797b8c",
    textAlign: "center",
  },
  nodeTitle: {
    fontWeight: 600,
    fontSize: "12px",
  },
  nodeBody: {
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: "30px",
    minWidth: "30px",
    fontSize: "16px",
    backgroundColor: "#7DE2D1",
    borderRadius: "4px",
    transform: "rotate(45deg)",
    border: "0.7px solid #e2e5e9",
    marginBottom: "30px",
  },
  attachedIcon: {
    fontSize: "20px",
    transform: "rotate(360deg)",
    marginLeft: "5px",
    color: "#000",
    position: "relative",
    right: "3px",
    top: "-1px",
    "&:hover": {
      backgroundColor: "#7DE2D1",

    }
  },
  addIcon: {
    height: "20px",
    width: "20px",
    color: "black",
    borderRadius: "50%",
    backgroundColor: "fff",
    marginLeft: "4px",
    transition: "visibility 0s, color 0.2s",
    position: "relative",
    bottom: "11px",
    left: "0px",
    "&:hover": {
      color: "blue",
    },
  },
  leftHandle: {
    background: "#566d89",
    opacity: "0",
    position: "relative",
    // left: "-2px",
    bottom: "11px",
  },
  rightHandle: {
    background: "#566d89",
    opacity: "0",
    position: "relative",
    // right: "-2px",
    bottom: "11px",
  },
};
