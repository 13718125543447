import { CircleNotifications, Sync } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dialogStyle,
  formatDate,
  isArrayWithValues,
  isObjWithValues,
} from "../../../helper";
import { getOnesignalNotifications } from "../../../redux/actions/hubActions";
import { PhoneSvg } from "../../../Svgs";
import AnalyticsCard from "../../components/AnalyticsCard";
import SendNotification from "../../components/SendNotification";
import { validateNumber } from "jwero-javascript-utils";

const OnesignalHub = () => {
  const [rows, setRows] = useState([]);
  const [app_id, setApp_id] = useState("");
  const [showSendNotification, setShowNotification] = useState(false);
  const [showNotificationAnalytics, setShowNotificationAnalytics] =
    useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const reduxOnesignalIntegration = useSelector(
    (state) => state.settings.onesignalIntegration
  );
  const reduxOnesignalNotifications = useSelector(
    (state) => state.hub.onesignalNotifications
  );
  const fetchingOnesignalNotifications = useSelector(
    (state) => state.hub.fetchingNotifications
  );
  console.log(reduxOnesignalNotifications);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isArrayWithValues(reduxOnesignalNotifications))
      setRows(reduxOnesignalNotifications);
  }, [reduxOnesignalNotifications]);

  useEffect(() => {
    let appId = "";
    if (!isObjWithValues(reduxOnesignalIntegration)) return;
    if (isObjWithValues(reduxOnesignalIntegration?.selectedAccount)) {
      appId = reduxOnesignalIntegration?.selectedAccount?.id;
      setApp_id(reduxOnesignalIntegration?.selectedAccount?.id);
    }
    if (!isArrayWithValues(reduxOnesignalNotifications)) {
      dispatch(getOnesignalNotifications(appId));
    }
  }, [reduxOnesignalIntegration]);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        sx={{ mt: 2, mb: 2, gap: "10px" }}
      >
        <IconButton>
          <Sync onClick={() => dispatch(getOnesignalNotifications(app_id))} />
        </IconButton>{" "}
        <Button onClick={() => setShowNotification(true)} variant="contained">
          Create New Push
        </Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Content</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Sent At</TableCell>
              <TableCell align="center">Sent</TableCell>
              <TableCell align="center">Failed</TableCell>
              <TableCell align="right">Clicked %</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchingOnesignalNotifications && (
              <>
                {Array(10)
                  .fill(null)
                  .map((item, index) => (
                    <TableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        py: 2,
                        cursor: "pointer",
                      }}
                    >
                      {/* <TableCell padding="checkbox">
                                               
                                                <IconButton>
                                                    <Skeleton
                                                        variant="rectangular"
                                                        sx={{
                                                            width: "18px",
                                                            height: "18px",
                                                            borderRadius: "3px",
                                                            ml: 1,
                                                        }}
                                                    />
                                                </IconButton>
                                            </TableCell> */}
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem", width: "100%" }}
                        />
                      </TableCell>

                      {/* <TableCell >
                                                <Skeleton
                                                    variant="text"
                                                    sx={{
                                                        fontSize: "1rem",
                                                        width: "35%",
                                                        // marginLeft: "auto",
                                                    }}
                                                />
                                            </TableCell> */}
                      <TableCell>
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "1rem",
                            width: "35%",
                            // marginLeft: "auto",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "1rem",
                            width: "35%",
                            // marginLeft: "auto",
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "1rem",
                            width: "35%",
                            // marginLeft: "auto",
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "1rem",
                            width: "35%",
                            margin: "auto",
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "1rem",
                            width: "35%",
                            margin: "auto",
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "1rem",
                            width: "35%",
                            marginLeft: "auto",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </>
            )}

            {!fetchingOnesignalNotifications && (
              <>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    let name = row?.name;
                    // let status = row?.fcap_status;
                    let time = row?.queued_at;
                    let sent = row?.successful;
                    let errored = row?.errored;
                    let failed = row?.failed;
                    let remaining = row?.remaining;
                    let converted = row?.converted;
                    // let clicked = ((remaining / (sent + failed)) * 100).toFixed(
                    //   2
                    // );
                    let clicked = Number((converted / sent) * 100).toFixed(2);
                    let content = row?.contents?.en;
                    let headings = row?.headings?.en;
                    let image = row?.global_image;
                    return (
                      <TableRow
                        key={index}
                        hover
                        role="checkbox"
                        onClick={() => setShowNotificationAnalytics(row)}
                        // tabIndex={-1}
                        // sx={{
                        //     "&:last-child td, &:last-child th": { border: 0 },
                        //     py: 2,
                        //     cursor: "pointer",
                        // }}
                      >
                        <TableCell>
                          <Typography fontSize="15px" fontWeight={"600"}>
                            {name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            maxWidth: 350,
                          }}
                        >
                          <Stack>
                            <Typography fontSize="14px" fontWeight={"300"}>
                              {headings}
                            </Typography>
                            <Typography fontSize="12px">{content}</Typography>
                            {image ? (
                              <img
                                src={image}
                                style={{
                                  width: "50px",
                                  margin: "auto",
                                  borderRadius: "2px",
                                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                }}
                              />
                            ) : null}
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={!remaining ? "Delivered" : "Sending"}
                            // color="warning"
                            sx={{
                              color: !remaining ? "#1aae6f" : "#5570bc",
                              backgroundColor: !remaining
                                ? "#e5eae8"
                                : "#EAECFB",
                              border: 0,
                              // border: isDarkTheme ? "0.7px solid" : "0px solid",
                              // borderColor: statusFontColor(row.status),
                              // backgroundColor: isDarkTheme
                              //     ? "transparent"
                              //     : "#e5eae8",
                            }}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {" "}
                            {formatDate(new Date(time * 1000), "relative_date")}
                          </Typography>
                          {/* {formatDate(new Date(time), "relative_date")} */}
                        </TableCell>
                        <TableCell align="center">
                          <Typography fontSize="15px">
                            {sent}/{sent + remaining + failed}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography fontSize="15px">{failed}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <PercentageLiner value={clicked} />
                          <Typography
                            sx={{
                              color:
                                sent > failed ? "success.main" : "error.main",
                            }}
                          >
                            {/* {100 - clicked}% */}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
            )}
          </TableBody>
          <TablePagination
            count={rows.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[5, 10, 20]}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>
      <Dialog
        fullWidth
        maxWidth="md"
        sx={dialogStyle}
        // fullScreen={fullScreen}
        open={showSendNotification}
        onClose={() => setShowNotification(false)}
        aria-labelledby="responsive-dialog-title"
        {...globalPopupStyle}
      >
        <DialogTitle id="responsive-dialog-title">
          Push notifications
        </DialogTitle>
        <DialogContent>
          <SendNotification onClose={() => setShowNotification(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        // fullWidth
        // maxWidth="md"
        sx={dialogStyle}
        // fullScreen={fullScreen}
        open={showNotificationAnalytics}
        onClose={() => setShowNotificationAnalytics(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <Stack
          direction={"row"}
          justifyContent="space-evenly"
          sx={{ mb: 2, mt: 2 }}
        >
          <AnalyticsCard
            title="Sent"
            value={
              showNotificationAnalytics?.failed +
              showNotificationAnalytics?.successful
            }
            sx={{ width: "30%" }}
          />
          <AnalyticsCard
            title={"Successful"}
            value={showNotificationAnalytics?.successful}
            sx={{ width: "30%" }}
          />
          <AnalyticsCard
            title="Failed"
            value={showNotificationAnalytics?.failed}
            sx={{ width: "30%" }}
          />
          {/* <Card
                        sx={{
                            border: "0.5px solid #e8e8e8",
                            height: "100%",
                            backgroundColor: "#ecedf1",
                            padding: "5px",
                            minWidth: "25%",
                        }}
                    >
                        <Typography>{showNotificationAnalytics?.failed}</Typography>
                        <Typography>Failed</Typography>
                    </Card> */}
        </Stack>
        <PhoneSvg height="50vh" />
        <Card
          sx={{
            position: "absolute",
            top: showNotificationAnalytics?.global_image ? "53%" : "60%",
            width: "81%",
            right: "40px",
            padding: "10px",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            borderRadius: "18px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack direction={"row"} justifyContent="space-between">
            <Stack direction={"row"} alignItems="center" sx={{ gap: "4px" }}>
              <CircleNotifications />
              <Typography variant="subtitle2">
                {showNotificationAnalytics?.headings?.en}
              </Typography>
            </Stack>
            <Typography>now</Typography>
          </Stack>
          <Stack>
            {/* <Typography>{showNotificationAnalytics?.global_image}</Typography> */}
            <Typography>{showNotificationAnalytics?.contents?.en}</Typography>
          </Stack>
          {showNotificationAnalytics?.global_image ? (
            <img
              src={showNotificationAnalytics?.global_image}
              style={{
                height: "120px",
                borderRadius: "7px",
                overflow: "hidden",
                margin: "auto",
              }}
            />
          ) : null}
        </Card>
        <DialogActions>
          <Button onClick={() => setShowNotificationAnalytics(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OnesignalHub;

export const PercentageLiner = ({ value }) => {
  return (
    <div style={{ position: "relative", maxWidth: "85px" }}>
      <Typography
        variant="subtitle2"
        style={{
          position: "absolute",
          top: "50%",
          right: "50%",
          transform: "translate(-50%,-50%)",
          width: "10px",
          zIndex: 1,
        }}
      >
        {value}%
      </Typography>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          height: "20px",
          backgroundColor: "rgb(0 0 0 / 10%)",
          borderRadius: "4px",
          ".MuiLinearProgress-barColorPrimary": {
            backgroundColor: value < 50 ? "#F5C0C4" : "#8CE4A2",
          },
        }}
      />
    </div>
  );
};
