import { isArrayWithValues } from "jwero-javascript-utils";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HorizontalStack from "../../../../helper/utility/HorizontalStack";
import { Avatar, AvatarGroup, Tooltip, useTheme } from "@mui/material";

export const MultipleTeamMembersView = memo(({ ids = [], iconSize }) => {
  const reduxTeamMembers = useSelector((state) => state.user?.team_members);
  const [teamMembers, setTeamMembers] = useState([]);
  const [tooltip, setTooltip] = useState("");
  const theme = useTheme();

  useEffect(() => {
    setTooltip(
      teamMembers
        ?.map((i) => i?.first_name || i?.email)
        ?.filter(Boolean)
        ?.join(", ")
    );
  }, [teamMembers]);

  useEffect(() => {
    if (isArrayWithValues(ids) && isArrayWithValues(reduxTeamMembers))
      setTeamMembers(reduxTeamMembers?.filter((i) => ids?.includes(i.id)));
    else setTeamMembers([]);
  }, [ids, reduxTeamMembers]);

  return (
    <Tooltip title={tooltip}>
      <AvatarGroup
        // renderSurplus={(surplus) => (
        //   <span
        //     style={{
        //       height: "16px",
        //       width: "16px",
        //       fontSize: "9px",
        //       // marginLeft: "-4px",
        //       // zIndex: -index,
        //       whiteSpace: "nowrap",
        //     }}
        //     // containerStyle={{  }}
        //     // labelStyle={{ fontSize: 8 }}
        //   >
        //     {surplus}
        //   </span>
        // )}
        // total={ids?.length}
        max={10}
      >
        {teamMembers?.map((i, index) => {
          return (
            <Avatar
              key={i.id}
              style={{
                height: "16px",
                width: "16px",
                fontSize: "9px",
                marginLeft: "-4px",
                // zIndex: -index,
                whiteSpace: "nowrap",
                // backgroundColor: "primary.light",
                color: theme.palette.primary.main,
              }}
              sx={{
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
              }}
              // containerStyle={{  }}
              // labelStyle={{ fontSize: 8 }}
            >
              {`${i?.first_name?.[0]?.toUpperCase() || ""}${
                i?.last_name?.[0]?.toUpperCase() || ""
              }`}
            </Avatar>
          );
        })}
      </AvatarGroup>
    </Tooltip>
  );
});
