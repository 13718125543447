import { backendApiCall } from "../../../../../../helper";
import * as actions from "../../../../../../redux/actions/flowsActions";

export const fetchWhatsappTemplates = async (dispatch) => {
  const whatsappTemplateResponse = await backendApiCall({
    endpoint: `social/templates?channel=whatsapp`,
  });
  const whatsappTemplates = whatsappTemplateResponse?.data;
  const approvedTemplates = whatsappTemplates?.filter(
    (templates) => templates.status === "APPROVED"
  );
  dispatch({
    type: actions.EDIT_ELEMENT_IN_REDUX,
    payload: { whatsappTemplates: approvedTemplates },
  });
  return approvedTemplates;
};
