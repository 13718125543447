import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Box } from "@mui/material";
import DialogBox from "../components/DialogBox";
import SidebarBotHeader from "./components/SidebarBotHeader";
import SidebarBotTextField from "./components/BotTextField";
import SidebarBotImageField from "./components/BotImageField";
import BotButtonField from "./components/BotButtonField";
import BotQuickReplyField from "./components/BotQuickReplyField";
import BotVideoField from "./components/BotVideoField";
import BotGalleryField from "./components/BotGalleryField";
import BotDocumentField from "./components/BotDocumentField";
import AddBotField from "./components/AddBotField";
import PropTypes from "prop-types";
import * as actions from "../../../../../redux/actions/flowsActions";
import { botStyles } from "./styles/botStyles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "reactflow/dist/style.css";
import { getRandomId } from "../utils";

const styles = botStyles;

const SidebarBot = ({ botData, setBotData }) => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const botId = useSelector((state) => state.flow?.currentNodeId);
  const fileInputRefs = useRef({});
  const [open, setOpen] = useState(false);
  const [botTitle, setBotTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [titleCheckFlag, setTitleCheckFlag] = useState(false);
  const [fieldError, setFieldError] = useState("");

  const handleClose = () => {
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const closeSidebar = () => {
    setOpen(false);
    dispatch({ type: actions.CLOSE_SIDEBAR_BOT });
    dispatch({ type: actions.RESET_NODE_ID });
    setBotData([]);
  };

  const handleButtonClick = (id) => {
    if (fileInputRefs.current[id]) {
      fileInputRefs.current[id].current.click();
    }
  };

  const handleCheck = (e) => {
    e.preventDefault();

    if (botTitle === "" || titleCheckFlag) {
      setTitleCheckFlag(true);
      setTitleError(true);
    }

    if (botData.length == 0) {
      setFieldError("No items added. Add to save it.");
    }

    if (botTitle !== "" && !titleCheckFlag && botData.length > 0) {
      const submittedData = { title: botTitle, fieldsData: botData };
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: botId, data: submittedData },
      });
      dispatch({ type: actions.CLOSE_SIDEBAR_BOT });
      setBotData([]);
      dispatch({ type: actions.RESET_NODE_ID });
    }
  };

  const textField = {
    id: getRandomId(),
    type: "text",
    response: "",
    isUser: false,
  };

  const imageField = {
    id: Date.now(),
    type: "image",
    isUser: false,
  };

  const buttonField = {
    id: getRandomId(),
    type: "buttons",
    description: "",
    buttons: [
      {
        id: getRandomId(),
        title: "Button",
        type: "send_message",
        url: "",
        number: "",
        block: "",
      },
    ],
    isUser: false,
  };

  const galleryField = {
    id: getRandomId(),
    type: "gallery",
    cards: [
      {
        file: null,
        id: getRandomId(),
        description: "",
        title: "",
        buttons: [
          {
            id: getRandomId(),
            title: "Button",
            type: "send_message",
            url: "",
            number: "",
            block: "",
          },
        ],
      },
    ],
    isUser: false,
  };
  const quickReplyField = {
    id: getRandomId(),
    type: "quick-reply",
    description: "",
    buttons: [
      {
        id: getRandomId(),
        title: "Button",
        type: "send_message",
        url: "",
        number: "",
        block: "",
      },
    ],
    isUser: false,
  };

  const videoField = {
    id: getRandomId(),
    type: "video",
    isUser: false,
  };

  const documentField = {
    id: getRandomId(),
    type: "document",
    isUser: false,
  };

  const handleOnDrop = (e) => {
    e.preventDefault();
    const widgetType = e.dataTransfer.getData("widgetType");

    setBotData((prevDropFields) => {
      if (widgetType === "Text") {
        const updatedFields = [...prevDropFields, textField];
        setFieldError("");
        return updatedFields;
      }

      if (widgetType === "Image") {
        fileInputRefs.current[imageField.id] = React.createRef();
        const updatedFields = [...prevDropFields, imageField];
        setFieldError("");
        return updatedFields;
      }

      if (widgetType === "Button") {
        const updatedFields = [...prevDropFields, buttonField];
        setFieldError("");
        return updatedFields;
      }

      if (widgetType === "Gallery") {
        fileInputRefs.current[galleryField.cards[0].id] = React.createRef();
        const updatedFields = [...prevDropFields, galleryField];
        setFieldError("");
        return updatedFields;
      }

      if (widgetType === "QuickReply") {
        const updatedFields = [...prevDropFields, quickReplyField];
        setFieldError("");
        return updatedFields;
      }

      if (widgetType === "Video") {
        fileInputRefs.current[videoField.id] = React.createRef();
        const updatedFields = [...prevDropFields, videoField];
        setFieldError("");
        return updatedFields;
      }

      if (widgetType === "Document") {
        fileInputRefs.current[documentField.id] = React.createRef();
        const updatedFields = [...prevDropFields, documentField];
        setFieldError("");
        return updatedFields;
      }
    });
  };

  const handleOnClick = (widgetType) => {
    setBotData((prevDropFields) => {
      if (widgetType === "Text") {
        const updatedFields = [...prevDropFields, textField];
        setFieldError("");
        return updatedFields;
      }

      if (widgetType === "Image") {
        fileInputRefs.current[imageField.id] = React.createRef();
        const updatedFields = [...prevDropFields, imageField];
        setFieldError("");
        return updatedFields;
      }

      if (widgetType === "Button") {
        const updatedFields = [...prevDropFields, buttonField];
        setFieldError("");
        return updatedFields;
      }

      if (widgetType === "Gallery") {
        fileInputRefs.current[galleryField.cards[0].id] = React.createRef();
        const updatedFields = [...prevDropFields, galleryField];
        setFieldError("");
        return updatedFields;
      }

      if (widgetType === "QuickReply") {
        const updatedFields = [...prevDropFields, quickReplyField];
        setFieldError("");
        return updatedFields;
      }

      if (widgetType === "Video") {
        fileInputRefs.current[videoField.id] = React.createRef();
        const updatedFields = [...prevDropFields, videoField];
        setFieldError("");
        return updatedFields;
      }

      if (widgetType === "Document") {
        fileInputRefs.current[documentField.id] = React.createRef();
        const updatedFields = [...prevDropFields, documentField];
        setFieldError("");
        return updatedFields;
      }
    });
  };

  const renderField = (data, index) => {
    switch (data.type) {
      case "text":
        return (
          <SidebarBotTextField
            key={data.id}
            botData={botData}
            setBotData={setBotData}
            data={data}
            index={index}
          />
        );

      case "image":
        return (
          <SidebarBotImageField
            botData={botData}
            setBotData={setBotData}
            key={data.id}
            data={data}
            handleButtonClick={handleButtonClick}
            index={index}
            fileInputRefs={fileInputRefs}
          />
        );

      case "buttons":
        return (
          <BotButtonField
            key={data.id}
            data={data}
            index={index}
            botData={botData}
            setBotData={setBotData}
          />
        );

      case "gallery":
        return (
          <BotGalleryField
            key={data.id}
            botData={botData}
            setBotData={setBotData}
            data={data}
            fileInputRefs={fileInputRefs}
            index={index}
            handleButtonClick={handleButtonClick}
          />
        );

      case "quick-reply":
        return (
          <BotQuickReplyField
            key={data.id}
            botData={botData}
            setBotData={setBotData}
            data={data}
            fileInputRefs={fileInputRefs}
            index={index}
            handleButtonClick={handleButtonClick}
          />
        );

      case "video":
        return (
          <BotVideoField
            key={data.id}
            botData={botData}
            setBotData={setBotData}
            data={data}
            handleButtonClick={handleButtonClick}
            fileInputRefs={fileInputRefs}
            index={index}
          />
        );

      case "document":
        return (
          <BotDocumentField
            key={data.id}
            botData={botData}
            setBotData={setBotData}
            data={data}
            handleButtonClick={handleButtonClick}
            fileInputRefs={fileInputRefs}
            index={index}
          />
        );

      default:
        return null;
    }
  };

  const handleBotFieldDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(botData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setBotData(items);
  };

  useEffect(() => {
    const currentNode = nodes?.find((it) => it?.id === botId);

    if (
      currentNode &&
      currentNode?.fieldData &&
      currentNode?.fieldData?.fieldsData
    ) {
      const fields = currentNode?.fieldData?.fieldsData;
      setBotData(fields);
      setBotTitle(currentNode?.fieldData?.title);
    }
  }, [botId, nodes, setBotData]);

  return (
    <div style={styles.mainContainer}>
      <Card style={styles.customCard}>
        {/* <form> */}
        <SidebarBotHeader
          nodes={nodes}
          handleCheck={handleCheck}
          handleClose={handleClose}
          botTitle={botTitle}
          setBotTitle={setBotTitle}
          titleError={titleError}
          titleCheckFlag={titleCheckFlag}
          setTitleCheckFlag={setTitleCheckFlag}
        />
        <DragDropContext onDragEnd={handleBotFieldDragEnd}>
          <Droppable droppableId="botFields">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={styles.innerCard}
                onDragEnter={(e) => {
                  e.preventDefault();
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleOnDrop}
              >
                {fieldError && (
                  <h3 style={styles.emptyFieldError}>{fieldError}</h3>
                )}

                {botData.length > 0 &&
                  botData.map((data, index) => (
                    <Draggable
                      key={data?.id}
                      draggableId={data?.id?.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {renderField(data, index)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided && provided?.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* </form> */}
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </Card>
      <AddBotField setBotData={handleOnClick} handleOnClick={handleOnClick} />
    </div>
  );
};

SidebarBot.propTypes = {
  botData: PropTypes.array.isRequired,
  setBotData: PropTypes.func.isRequired,
};

export default SidebarBot;
