import {
  ArrowBackIosNew,
  CancelOutlined,
  CheckCircleOutline,
  DoDisturb,
  Person,
} from "@mui/icons-material";
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  backendApiCall,
  dialogStyle,
  formatDate,
  formatDateDynamic,
  formatServerValue,
  getAxiosError,
  getComparator,
  getCustomer,
  getId,
  getInitials,
  getIsBackendMigrated,
  getRandomString,
  getStoreId,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  reduceLargeText,
  sleep,
  stableSort,
  validateNumber,
} from "../../helper";
import {
  getDigigoldCustomer,
  SET_CRM_DATA,
  updateCustomerInRedux,
  updateDigigoldUserInRedux,
} from "../../redux/actions/crmActions";
import DigitalGold, {
  updateCustomerDigiUniqueId,
} from "../customers/components/DigitalGold";
import { getStatusBackgroundColor, statusFontColor } from "../orders/Orders";
import PropTypes from "prop-types";
import { SectionLabel, StyledTableRow } from "./Dashboard";
import { visuallyHidden } from "@mui/utils";
import { getCustomerOrders } from "../../redux/actions/orderActions";
import { Add, Close, Delete } from "@material-ui/icons";
import { currencySymbols } from "../pricing/Gold";
import styled from "styled-components";
import { DatePicker } from "@mui/x-date-pickers";
import {
  DesktopDateTimePicker,
  LoadingButton,
  LocalizationProvider,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import axios from "axios";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { getTempletes } from "../../redux/actions/chatActions";
import {
  addDigigoldGift,
  addDigigoldPlanCancel,
} from "../../redux/actions/settingActions";
import TempleteList from "../components/TempleteList";
import AnalyticsCard from "../components/AnalyticsCard";
import { isValidDate } from "../../utils/dateFunctions";
import PerfectScrollbar from "react-perfect-scrollbar";

import "react-perfect-scrollbar/dist/css/styles.css";
import { addUserActivity } from "../../redux/actions/hubActions";
import { sendMessage } from "../chat_new/apiFunctions";
import {
  PrimaryLabelSmall,
  SecondaryLabel,
  SecondaryLabelSmall,
} from "../../helper/utility/Labels";
import HorizontalStack from "../../helper/utility/HorizontalStack";
import { onSendSMS } from "../customers/helpers/sms";
import { getTimeInMilliSeconds, isArray } from "jwero-javascript-utils";
import { getFirstPlanDate } from "../../utils/digigold";
import { LEDGER_TYPES, mergeRewardsIntoLedger } from "./utils/ledger";
import CustomChip from "../components/CustomChip";

function User({
  userView,
  digiPlans,
  setUserView,
  currency,
  paymentsMethods,
  branches,
  teamMembers,
  todayGoldRate,
  // digiCancelledPlans,
}) {
  const [customer, setCustomer] = useState({});
  const location = useLocation();
  const dispatch = useDispatch();
  const [manualFormOpen, setManualFormOpen] = useState(false);
  const [viewLedger, setViewLedger] = useState({});
  const [cancelPlanOpen, setCancelPlanOpen] = React.useState(false);
  const digiCancelledPlans = useSelector(
    (state) => state.settings.digigoldCancelledPlans
  );
  const closeCancelPlan = (props = {}) => {
    setCancelPlanOpen(false);
    let { cancelled_plan } = props;
    let digigold_details = userView?.digigold_details;

    if (isObjWithValues(digigold_details?.plans)) {
      let plans = digigold_details?.plans;
      let updatedPlans = {};
      let viewLedger;
      for (let plan_id in plans) {
        let plan = plans[plan_id];
        if (cancelled_plan?.plan_id + "" === plan?.product + "") {
          viewLedger = { ...plan, is_cancelled: cancelled_plan?.id };
          updatedPlans[plan_id] = viewLedger;
        } else updatedPlans[plan_id] = plan;
      }
      console.log(cancelled_plan, updatedPlans, userView, {
        ...userView,
        digigold_details: {
          ...userView.digigold_details,
          plans: updatedPlans,
        },
      });
      let user = {
        ...userView,
        digigold_details: {
          ...userView.digigold_details,
          plans: updatedPlans,
        },
        plans: updatedPlans,
        ordersFetched: true,
      };
      dispatch(updateDigigoldUserInRedux({ user }));
      setUserView(user);
      setCustomer({
        ...customer,
        digigold_details: {
          ...customer.digigold_details,
          plans: updatedPlans,
        },
        plans: updatedPlans,
        ordersFetched: true,
      });
      if (viewLedger) setViewLedger(viewLedger);
    }
    // console.log({ cancelled_plan, customer, userView });
  };

  const openCancelPlan = (text) => {
    setCancelPlanOpen(text);
  };

  const openManualForm = (v, id) => {
    setManualFormOpen(v);
  };
  const closeManualForm = () => setManualFormOpen(false);

  let digigold_user_view = useSelector(
    (state) => state.customers.digigold_user_view
  );

  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  const navigate = useNavigate();

  const reduxDigiCustomers = useSelector(
    (state) => state.customers.allDigiCustomers
  );
  const fetchingDigiCustomer = useSelector(
    (state) => state.customers.fetchingDigiCustomer
  );
  const digigoldCancelledPlans = useSelector(
    (state) => state.settings.digigoldCancelledPlans
  );
  const digigoldGifts = useSelector((state) => state.settings.digigoldGifts);

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const topDivRef = useRef(null);

  useEffect(() => {
    if (isObjWithValues(userView)) {
      setupCustomer(userView);
      if (!userView.ordersFetched) dispatch(getCustomerOrders(userView.id));
    }
  }, [userView]);
  useEffect(() => {
    setTimeout(() => {
      // window.scrollTo(0, 0);
      // topDivRef?.current?.scrollTo({ top: 0 });
      if (topDivRef?.current) topDivRef.current.scrollTop = -20;

      // window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  }, []);

  const setupCustomer = (customer) => {
    if (!isObjWithValues(customer)) return;
    let rows = [];
    let obj = { ...customer };
    let { meta_data } = customer;
    if (isArrayWithValues(meta_data)) {
      for (let metaObj of meta_data) obj[metaObj.key] = metaObj.value;
    }
    setCustomer(obj);
  };

  // useEffect(() => {
  //   if (digigold_user_view) setCustomer(digigold_user_view);
  // }, [digigold_user_view]);
  // useEffect(() => {
  //   // if (order.dateCreated) return;
  //   let pieces = location.pathname.split("/");
  //   let customerId = pieces[pieces.length - 1];
  //   // let customerObj =
  //   //   isArrayWithValues(reduxDigiCustomers) &&
  //   //   reduxDigiCustomers.find((i) => i.id == customerId);
  //   // if (customerObj) {
  //   //   setCustomer(customerObj);
  //   //   //   fetchNotes(customerObj.id);
  //   // } else if (customerId) {
  //   //   dispatch(getDigigoldCustomer(customerId));
  //   // }
  //   if (customerId) dispatch(getDigigoldCustomer(customerId));
  // }, []);
  const onPlanPress = (obj) => {
    if (isObjWithValues(obj)) {
      let { ledger } = obj;
      let weight = 0;
      let paid = 0;
      let paid_count = 0;
      if (isArrayWithValues(ledger)) {
        ledger.map((i) => {
          paid += validateNumber(Number(i.total));
          let { gold } = i;
          if (gold) gold = gold?.match(/[+-]?\d+(\.\d+)?/g, "")?.[0];
          else gold = 0;
          weight += validateNumber(Number(gold));
        });
        paid_count = ledger.length;
      }
      obj.weight = weight;
      obj.paid = paid;
      obj.paid_count = paid_count;
    }
    setViewLedger(obj);
  };
  if (fetchingDigiCustomer)
    return (
      <Box
        sx={{
          width: "300px",
          margin: "auto",
          marginTop: "40vh",
          textAlign: "center",
        }}
      >
        <LinearProgress />
        <Typography variant="h6" color="text.secondary" mt={3}>
          Fetching customer details
        </Typography>
      </Box>
    );
  return (
    <PerfectScrollbar>
      <div ref={topDivRef}>
        <Button
          variant="text"
          startIcon={<ArrowBackIosNew />}
          onClick={() => setUserView(false)}
          // onClick={() => navigate("/digigold/users")}
        >
          All Customers
        </Button>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <CustomerInfoCard customer={customer} />
          </Grid>
          <Grid item xs={12} md={8}>
            {/* <DigitalGold
            customerInfo={customer}
            setCustomerInfo={setCustomer}
            redirect={false}
            fromDigigold
          /> */}
            <UserPlans
              plans={userView?.plans && Object.values(userView.plans)}
              allPlans={digiPlans}
              paymentsMethods={paymentsMethods}
              branches={branches}
              teamMembers={teamMembers}
              userView={userView}
              setUserView={setUserView}
              todayGoldRate={todayGoldRate}
              openManualForm={(e) => openManualForm(e)}
              closeManualForm={closeManualForm}
              onPlanPress={onPlanPress}
              digigoldCancelledPlans={digigoldCancelledPlans}
              digigoldGifts={digigoldGifts}
              currency={currency}
            />
          </Grid>
        </Grid>
        <KYCdetails customer={customer} />
        <CustomerOrders orders={userView.ordersList} currency={currency} />
        <Ledger
          customerInfo={userView}
          setCustomerInfo={setUserView}
          viewLedger={viewLedger}
          setViewLedger={setViewLedger}
          email={userView?.email}
          openManualDialog={(e, id) => openManualForm(e, id)}
          openCancelPlan={openCancelPlan}
          currency={currencySymbols[currency] || ""}
          digigoldCancelledPlans={digigoldCancelledPlans}
          digigoldGifts={digigoldGifts}
        />

        <Dialog
          open={Boolean(manualFormOpen)}
          onClose={closeManualForm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={isIpad ? {} : dialogStyle}
        >
          <AddManualForm
            closeManualForm={closeManualForm}
            dialogTitle={manualFormOpen}
            allPlans={digiPlans}
            paymentMethods={paymentsMethods}
            branches={branches}
            teamMembers={teamMembers}
            userView={userView}
            setUserView={setUserView}
            todayGoldRate={todayGoldRate}
            selectedPlanId={viewLedger.product}
            setViewLedger={setViewLedger}
          />
        </Dialog>
        <Dialog
          open={Boolean(cancelPlanOpen)}
          onClose={closeCancelPlan}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
          sx={isIpad ? {} : dialogStyle}
          {...globalPopupStyle}
          // sx={(theme) => (theme.breakpoints.down("md") ? {} : dialogStyle)}
          // PaperProps={{
          //   sx: {
          //     height: "80vh"
          //   }
          // }}
        >
          <CancelForm
            closeCancelPlan={closeCancelPlan}
            ledger={viewLedger}
            currency={currencySymbols[currency]}
            digigoldCancelledPlans={digiCancelledPlans}
            customerInfo={userView}
          />
        </Dialog>
      </div>
    </PerfectScrollbar>
  );
}

export default User;

const Ledger = ({
  customerInfo,
  setCustomerInfo,
  viewLedger,
  setViewLedger,
  email,
  currency,
  openManualDialog,
  openCancelPlan,
  digigoldCancelledPlans,
  digigoldGifts,
  userView,
}) => {
  let { duration, plan_name, type, joining_date, gifts } = viewLedger;
  // let gifts = {
  //   1: { title: "Diwali Gift", gift: "Bag", id: "LKASFJ", month: "1" },
  //   2: { title: "Navratri Gift", gift: "Watch", id: "KDSJFL", month: "2" },
  // };
  const dispatch = useDispatch();
  const [analytics, setAnalytics] = useState({});
  const [sendingRemainder, setSendingRemainder] = useState(false);
  const [sendingLedger, setSendingLedger] = useState(false);
  const [selectedTempleteRemainder, setSelectedTemplateRemainder] = useState(
    {}
  );
  const [selectedTempleteLedger, setSelectedTemplateLedger] = useState({});
  const [showWhatsappTemplete, setShowWhatsappTemplate] = useState(false);
  const [uniqueIdUpdating, setUniqueIdUpdating] = useState(false);
  const [planUniqueId, setPlanUniqueId] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [giftForm, setGiftForm] = useState({
    date: new Date(),
    received_by: "",
    given_by: "",
    title: "",
    gift: "",
  });
  const [giftFormOpen, setGiftFormOpen] = useState(false);
  const editGiftForm = (payload) =>
    setGiftForm((state) => ({ ...state, ...payload }));
  const [addedGifts, setAddedGifts] = useState({});
  const [isPlanCancelled, setIsPlanCancelled] = useState(false);
  const [cancelPlanObject, setCancelPlanObject] = useState({});
  const [allPaymentsMade, setIsAllPaymentsMade] = useState(false);
  const assignTemplate = useSelector(
    (state) => state?.settings?.whatsappAssignedTemplates
  );
  const reduxWhatsappTemplete = useSelector(
    (state) => state.chats.whatsappTempletes
  );
  const digigold_user_rewards = useSelector(
    (state) => state.settings.digigold_user_rewards
  );
  const delete_ledger_entry = useSelector(
    (state) => state.user.delete_ledger_entry
  );
  let store_id = getStoreId();
  const isAdmin = useSelector((state) => state.user.isAdmin);

  const closeDelete = () => setShowDelete(false);
  const openDelete = () => setShowDelete(true);

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";
  const [allLedgers, setAllLedgers] = useState([]);

  useEffect(() => {
    let ledger = [];
    let total_penalty = 0,
      total_reward = 0;
    if (isArrayWithValues(viewLedger.ledger)) {
      ledger = [...viewLedger.ledger];
      let rewards =
        digigold_user_rewards?.[`${customerInfo?.id}_${viewLedger?.product}`];

      if (isArrayWithValues(rewards)) {
        ledger = mergeRewardsIntoLedger(
          digigold_user_rewards?.[`${customerInfo?.id}_${viewLedger?.product}`],
          ledger
        );
        rewards.forEach((reward) => {
          if (reward?.type === "reward")
            total_reward += validateNumber(reward?.reward_or_penalty_to_pay);
          if (reward?.type === "penalty")
            total_penalty += validateNumber(reward?.reward_or_penalty_to_pay);
        });
      }
      ledger.sort((a, b) => {
        const dateA = new Date(a.date_paid).getTime();
        const dateB = new Date(b.date_paid).getTime();
        return dateA - dateB;
      });
    }
    setAnalytics((state) => ({ ...state, total_penalty, total_reward }));
    setAllLedgers(ledger);
  }, [viewLedger.ledger, digigold_user_rewards]);
  // useEffect(() => {
  //   if (!isArrayWithValues(viewLedger.ledger)) return;
  //   setAllLedgers(viewLedger.ledger.sort((a, b) => b?.order_id - a?.order_id));
  // }, [viewLedger?.ledger]);

  useEffect(() => {
    if (!isArrayWithValues(reduxWhatsappTemplete)) dispatch(getTempletes());
  }, []);

  useEffect(() => {
    if (isObjWithValues(digigoldCancelledPlans)) {
      let obj =
        viewLedger?.is_cancelled &&
        digigoldCancelledPlans[`${viewLedger.is_cancelled}`];
      setIsPlanCancelled(Boolean(obj));
      if (obj) setCancelPlanObject(obj);
      else setCancelPlanObject({});
    }
    // let uniqueObj = customerInfo?.meta_data?.find((i) => i.key == `unique_ids`);
    let uniqueObj = customerInfo?.[`digigold_uid_${store_id}`];
    if (uniqueObj && uniqueObj?.[viewLedger?.product])
      setPlanUniqueId(uniqueObj?.[viewLedger?.product]);
    else setPlanUniqueId("");

    setIsAllPaymentsMade(
      validateNumber(viewLedger?.duration) <=
        validateNumber(viewLedger?.ledger?.length) && viewLedger?.duration
    );
  }, [digigoldCancelledPlans, viewLedger, customerInfo]);

  useEffect(() => {
    if (
      digigoldGifts &&
      digigoldGifts[`${viewLedger.product}_${customerInfo.id}`]
    )
      setAddedGifts(digigoldGifts[`${viewLedger.product}_${customerInfo.id}`]);
    else setAddedGifts({});
  }, [digigoldGifts, viewLedger, customerInfo]);

  useEffect(() => {
    if (!isArrayWithValues(reduxWhatsappTemplete)) return;

    let remainder = reduxWhatsappTemplete.find(
      (obj) => obj?.name === assignTemplate?.digital_gold_remainder
    );
    let ledger = reduxWhatsappTemplete.find(
      (obj) => obj?.name === assignTemplate?.digital_monthly_ledger
    );
    if (isObjWithValues(remainder)) setSelectedTemplateRemainder(remainder);
    if (isObjWithValues(ledger)) setSelectedTemplateLedger(ledger);
  }, [reduxWhatsappTemplete]);

  // const remainderButton =
  //   customerInfo?.whatsapp_id && assignTemplate?.digital_gold_remainder ? (
  //     <LoadingButton
  //       size="small"
  //       loading={sendingRemainder}
  //       variant="outlined"
  //       disabled={!isObjWithValues(selectedTempleteRemainder)}
  //       onClick={async () => {
  //         setSendingRemainder(true);
  //         setShowWhatsappTemplate(selectedTempleteRemainder);
  //         setSendingRemainder(false);
  //       }}
  //     >
  //       Send Reminder
  //     </LoadingButton>
  //   ) : null;
  const remainderButton = (
    <Tooltip
      title={
        !customerInfo?.whatsapp_id
          ? "No whatsapp number found"
          : !isObjWithValues(selectedTempleteRemainder)
          ? "Digigold template is not assigned"
          : ""
      }
    >
      <LoadingButton
        size="small"
        loading={sendingRemainder}
        variant="outlined"
        // disabled={!isObjWithValues(selectedTempleteRemainder)}
        onClick={async () => {
          setSendingRemainder(true);
          setShowWhatsappTemplate(selectedTempleteRemainder);
          setSendingRemainder(false);
        }}
        disabled={
          !customerInfo?.whatsapp_id ||
          !isObjWithValues(selectedTempleteRemainder)
        }
      >
        Send Reminder
      </LoadingButton>
    </Tooltip>
  );

  const monthlyLedgerButton =
    customerInfo?.whatsapp_id && assignTemplate?.digital_monthly_ledger ? (
      <LoadingButton
        size="small"
        loading={sendingLedger}
        variant="outlined"
        disabled={!isObjWithValues(selectedTempleteLedger)}
        onClick={async () => {
          setSendingLedger(true);
          setShowWhatsappTemplate(selectedTempleteLedger);
          setSendingLedger(true);
        }}
      >
        Send Monthly Ledger
      </LoadingButton>
    ) : null;

  const onSendTemplate = async (obj) => {
    let template = obj;
    // let template = replaceTemplateVariable(obj, customerInfo)
    setShowWhatsappTemplate(false);
    let website = getWebsite();
    let token = getToken();
    // if (!website && !token) return false
    // const res = await axios({
    //   url: `${website}/wp-json/store/v1/social/message`,
    //   headers: {
    //     Authorization: `Basic ${token}`,
    //   },
    //   method: "POST",
    //   data: {
    //     platform: "whatsapp",
    //     payload: {
    //       messaging_product: "whatsapp",
    //       recipient_type: "individual",
    //       to: customerInfo?.whatsapp_id,
    //       type: "template",
    //       template,
    //     },
    //   },
    // });
    let res = {};
    if (getIsBackendMigrated("chats")) {
      res.data = await sendMessage({
        payload: {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: number,
          type: "template",
          template,
        },
        platform: "whatsapp",
      });
    } else {
      res = await axios({
        url: `${website}/wp-json/store/v1/social/message`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "POST",
        data: {
          platform: "whatsapp",
          payload: {
            messaging_product: "whatsapp",
            recipient_type: "individual",
            to: number,
            type: "template",
            template,
            name: `${userView.first_name || ""} ${userView?.last_name || ""}`,
          },
        },
      });
    }

    if (res.data.success) {
      // dispatch(addResponseInChat(res.data.data, customer.id));
      dispatch(
        setGlobalToast({
          show: true,
          message: "Sent Successfully",
          severity: "success",
        })
      );
    }
    if (isObjWithValues(res.data?.data?.error)) {
      dispatch(
        setGlobalToast({
          show: true,
          message:
            res.data?.data?.error?.error_data?.details ||
            res.data?.data?.error?.message,
          severity: "error",
        })
      );
    }
  };
  const onAddDigigoldGift = (obj) => {
    let giftObj = isObjWithValues(gifts)
      ? Object.values(gifts).find((i) => i.id === obj.id)
      : false;
    if (giftObj) obj = { ...obj, ...giftObj };
    dispatch(
      addDigigoldGift(
        viewLedger.product,
        customerInfo.id,
        {
          ...digigoldGifts[`${viewLedger.product}_${customerInfo.id}`],
          [obj.id]: { ...obj, date: new Date(obj.date).getTime() },
        },
        {
          onSuccess: () => {
            setGiftFormOpen(false);
            dispatch(
              setGlobalToast({
                show: true,
                severity: "success",
                message: "Gift Entry added",
              })
            );
          },
        }
      )
    );
  };

  const onUniqueIdUpdate = async () => {
    setUniqueIdUpdating(true);
    let customer = await updateCustomerDigiUniqueId(
      customerInfo,
      viewLedger.product,
      planUniqueId
    );
    if (isArrayWithValues(customer?.meta_data)) {
      let uniqueObj = customer?.meta_data?.find(
        (i) => i.key == `digigold_uid_${store_id}`
      );
      setCustomerInfo({
        ...customerInfo,
        [`digigold_uid_${store_id}`]: uniqueObj?.value,
      });
    }
    setUniqueIdUpdating(false);
    dispatch(
      setGlobalToast({
        message: "Unique ID updated!",
        show: true,
        severity: "success",
      })
    );
  };
  const deleteOrder = async (id) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        await axios({
          url: `${website}/wp-json/wc/v3/orders/${id}`,
          headers: { Authorization: `Basic ${token}` },
          method: "DELETE",
          params: { force: true },
        });
        let { data: digigold_details } = await axios({
          // url: `${website}/wp-json/wc/v3/customers/${customerInfo.id}?asd`,
          url: `${website}/wp-json/store/v1/customer/${customerInfo.id}/digigold`,
          headers: { Authorization: `Basic ${token}` },
        });
        if (digigold_details) {
          dispatch(
            updateCustomerInRedux({
              ...customerInfo,
              digigold_details: digigold_details.data,
            })
          );
          // if(isObjWithValues(digigold_details.digigold_details?.plans)){
          //   let updatedPlans = {};
          //   for(let id in digigold_details.digigold_details?.plans){

          //   }
          // }
          setCustomerInfo({
            ...customerInfo,
            ...digigold_details?.data,
          });
        }
      } catch (error) {
        console.log(error, getAxiosError(error));
      }
    }
  };

  const onOrderDeletePress = (details) => {
    setDeleteId(details);
    openDelete();
  };
  const onOrderDelete = async () => {
    setDeleting(true);
    let data = await deleteOrder(deleteId.order_id || deleteId.id);
    if (data)
      dispatch(
        setGlobalToast({
          message: "Payment history deleted!",
          show: true,
          severity: "success",
        })
      );

    dispatch(
      addUserActivity({
        event: "delete_ledger_digigold",
        event_info: {
          customer_id: customerInfo?.id,
          order_id: deleteId.order_id || deleteId.id,
          ledger_info: deleteId,
        },
      })
    );
    closeDelete();
    if (viewLedger?.ledger?.length == 1) setViewLedger(false);
    setDeleting(false);
  };

  return (
    <>
      <TempleteList
        open={showWhatsappTemplete}
        handleClose={() => setShowWhatsappTemplate(false)}
        setParentTemplate={onSendTemplate}
        // hideRejected
        // viewOnly
      />
      <Drawer
        anchor={!isIpad ? "right" : "bottom"}
        open={Boolean(isObjWithValues(viewLedger))}
        onClose={() => {
          setViewLedger(false);
        }}
        transitionDuration={200}
        sx={{
          "	.MuiDrawer-paperAnchorRight": {
            height: isIpad ? "calc(60vh - 20px)" : "calc(100vh - 20px)",
            // margin: "10px",
            borderRadius: "10px",
            p: 4,
            margin: "10px",
            width: isIpad ? "calc(100vw - 20px)" : "55vw",
            maxWidth: "100%",
          },
        }}
      >
        <Box sx={{ px: 2, py: 2 }}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Typography variant="h4" sx={{ fontSize: "15px" }}>
              {plan_name}'s history
            </Typography>
            <Stack direction={"row"} sx={{ gap: "7px" }} alignItems="center">
              {remainderButton} {monthlyLedgerButton}
              <IconButton onClick={() => setViewLedger(false)}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography
              mt={1}
              variant="h6"
              mb={2}
              sx={{ fontSize: "12px", color: "text.secondary" }}
            >
              {email}
            </Typography>
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={6} md={4} lg={3.5}>
              <LabelValue label="Duration" value={`${duration} months`} />
            </Grid>
            <Grid item xs={6} md={4} lg={3.5}>
              <LabelValue
                label="Type"
                value={`${type ? formatServerValue(type) : ""}`}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={5}>
              <LabelValue
                label="Joining Date"
                value={`${formatDate(
                  new Date(joining_date),
                  "dd:mm:yyyy hh:mm am/pm"
                )}`}
              />
            </Grid>
          </Grid>
        </Box>
        <Stack direction={"row"} justifyContent="flex-end" spacing={2} mt={1}>
          <TextField
            value={planUniqueId}
            onChange={(e) => setPlanUniqueId(e.target.value.toUpperCase())}
            // fullWidth
            // placeholder="Enter name"
            label="Unique ID"
            size="small"
            // disabled
            helperText={
              planUniqueId?.includes(" ")
                ? "Spaces not allowed in unique IDs"
                : ""
            }
            error={planUniqueId?.includes(" ")}
          />
          <LoadingButton
            loading={uniqueIdUpdating}
            variant="outlined"
            size="small"
            onClick={onUniqueIdUpdate}
            disabled={planUniqueId?.includes(" ")}
          >
            Update
          </LoadingButton>
        </Stack>
        {/* <Card sx={{ border: "0.5px solid #e8e8e8", borderRadius: "8px", mt: 3 }}>
        <CardContent>
        </CardContent>
      </Card> */}
        {isObjWithValues(cancelPlanObject) && (
          <Box>
            <Typography
              sx={{ fontSize: "13px", fontWeight: "600", color: "error.main" }}
            >
              This plan has been closed on{" "}
              {cancelPlanObject?.time
                ? formatDate(
                    new Date(cancelPlanObject.time),
                    "dd:mm:yyyy hh:mm am/pm"
                  )
                : ""}
              , By {cancelPlanObject?.close_by} with settlement of {currency}{" "}
              {cancelPlanObject?.final_paid}.
            </Typography>
            {cancelPlanObject?.is_otp_verified ? (
              <HorizontalStack spacing={1} sx={{ mt: 1 }}>
                <CheckCircleOutline
                  style={{ height: 20, width: 20 }}
                  sx={{ color: "success.main" }}
                />
                <PrimaryLabelSmall sx={{ color: "success.main" }}>
                  Verfied with OTP
                </PrimaryLabelSmall>
              </HorizontalStack>
            ) : (
              <HorizontalStack spacing={1} sx={{ mt: 1 }}>
                <CancelOutlined
                  style={{ height: 20, width: 20 }}
                  sx={{ color: "error.main" }}
                />
                <PrimaryLabelSmall sx={{ color: "error.main" }}>
                  Not Verfied with OTP
                </PrimaryLabelSmall>
              </HorizontalStack>
            )}
          </Box>
        )}
        {!isIpad ? (
          <Grid my={2} container spacing={3}>
            <Grid item xs={6} md={3} lg={2.4}>
              <AnalyticsCard
                title="Amount Received"
                value={viewLedger.paid ? `${currency} ${viewLedger.paid}` : "0"}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={2.4}>
              <AnalyticsCard
                title="Total Payments"
                value={viewLedger.paid_count || "0"}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={2.4}>
              <AnalyticsCard
                title="Equivalent Gold"
                value={`${validateNumber(
                  Number(viewLedger.weight).toFixed(3)
                )}`}
                suffix={"g"}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={2.4}>
              <AnalyticsCard
                title="Total Rewards"
                value={`${currency} ${analytics.total_reward}`}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={2.4}>
              <AnalyticsCard
                title="Total Penalty"
                value={`${currency} ${analytics.total_penalty}`}
              />
            </Grid>
          </Grid>
        ) : null}
        {/* <Box sx={{padding}}> */}
        <Stack
          direction="row"
          mx={1}
          alignItems={"center"}
          mt={3}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">Gifts Received</Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems={"center"}
          flexWrap="wrap"
          spacing={3}
          mt={3}
        >
          {isObjWithValues(gifts) &&
            Object.values(gifts).map((i) => {
              return (
                <Chip
                  size="small"
                  mr={1}
                  mb={1}
                  label={`${i.title}: ${i.gift}`}
                  // color="warning"
                  onClick={() => setGiftFormOpen(true)}
                  sx={{
                    color: addedGifts[i.id] ? "#1aae6f" : "#910905",
                    border: isDarkTheme ? "0.7px solid" : "0px solid",
                    // borderColor: statusFontColor(row.status),
                    cursor: "pointer",
                    backgroundColor: isDarkTheme
                      ? "transparent"
                      : addedGifts[i.id]
                      ? "#e0f6ed"
                      : "#f2e5e8",
                  }}
                  variant="outlined"
                />
              );
              // return <Chip label={`${i.title}: ${i.gift}`} size="small" color="success" />;
            })}
        </Stack>
        <Stack
          direction="row"
          mx={1}
          alignItems={"center"}
          mt={3}
          justifyContent={"space-between"}
          flexWrap="wrap"
          gap={"10px"}
        >
          <Stack direction="row" alignItems={"center"}>
            <Typography variant="h6">Payment history</Typography>

            {/* <Button
            // variant="contained"
            size="small"
            startIcon={<Add style={{ width: "15px", height: "15px" }} />}
            onClick={() => openManualDialog("Add Manual Payment")}
            sx={{ ml: 1 }}
            color="error"
          >
            Cancel Plan
          </Button> */}
          </Stack>
          <Stack direction="row" spacing={3} alignItems={"center"}>
            {!isPlanCancelled && (
              <Button
                variant="outlined"
                onClick={openCancelPlan}
                size="small"
                color="error"
              >
                Close Plan
              </Button>
            )}
            {/* <IconButton
              size="small"
              color="error"
              sx={{ mr: 1 }}
              onClick={openCancelPlan}
            >
              <Delete style={{ height: "20px", width: "20px" }} />
            </IconButton> */}

            <Tooltip
              title={
                allPaymentsMade
                  ? "All payments has been made"
                  : isPlanCancelled
                  ? "Plan has been cancelled"
                  : ""
              }
            >
              <div>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<Add style={{ width: "15px", height: "15px" }} />}
                  onClick={() =>
                    openManualDialog("Add Manual Payment", viewLedger.product)
                  }
                  disabled={isPlanCancelled || allPaymentsMade}
                >
                  Add Manual Payment
                </Button>
              </div>
            </Tooltip>
          </Stack>
        </Stack>
        {isArrayWithValues(viewLedger.ledger) && (
          <TableContainer
            sx={{
              border: "0.5px solid #e8e8e8",
              borderRadius: "8px",
              mt: 3,
              // overflowY: "visible !important",
              // overflowX: "auto !important",
              maxWidth: "100%",
            }}
            // component={Paper}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date of Payment</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Payment Ref.</TableCell>
                  <TableCell>Mode</TableCell>
                  <TableCell>Amount Paid</TableCell>
                  <TableCell>Penalty</TableCell>
                  <TableCell>Gold Credited</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allLedgers?.map((detail) => (
                  <TableRow
                    key={detail.url}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {formatDateDynamic(
                        new Date(detail.date_paid),
                        "DD MMM, YYYY hh:ss A"
                      )}
                    </TableCell>
                    <TableCell>
                      <CustomChip
                        label={
                          detail.status === "reward"
                            ? "Reward"
                            : detail.status === "penalty"
                            ? "Penalty"
                            : "Payment"
                        }
                        type={LEDGER_TYPES[detail.status] || "processing"}
                      />
                    </TableCell>
                    <TableCell>{detail.ref}</TableCell>
                    <TableCell>{detail.mode}</TableCell>
                    <TableCell>
                      {detail?.status !== "penalty" && (
                        <>
                          <Typography>
                            {currency} {detail.total}
                          </Typography>
                          <SecondaryLabel sx={{ fontSize: "11px" }}>
                            {detail.manual ? "Manual" : "Automatic"}
                          </SecondaryLabel>
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {detail?.status === "penalty" && (
                        <>
                          <Typography>
                            {currency} {detail.total}
                          </Typography>
                          <SecondaryLabel sx={{ fontSize: "11px" }}>
                            {detail.manual ? "Manual" : "Automatic"}
                          </SecondaryLabel>
                        </>
                      )}
                    </TableCell>
                    <TableCell>{detail.gold}</TableCell>
                    <TableCell>
                      <Tooltip
                        title={
                          !detail.manual
                            ? "Cannot delete automatic payment"
                            : !delete_ledger_entry && !isAdmin
                            ? "You don't have access to delete payment entries"
                            : ""
                        }
                      >
                        <div>
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => onOrderDeletePress(detail)}
                            disabled={
                              !detail.manual ||
                              (!delete_ledger_entry && !isAdmin)
                            }
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ fontWeight: "600" }}>Total</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell sx={{ fontWeight: "600" }}>
                    {currency}{" "}
                    {validateNumber(viewLedger.paid) +
                      validateNumber(analytics.total_reward)}
                  </TableCell>
                  <TableCell>
                    {currency} {validateNumber(analytics.total_penalty)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "600" }}>
                    {viewLedger?.weight?.toFixed(3)} grams
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {/* </Box> */}
        <GiftForm
          form={giftForm}
          setForm={editGiftForm}
          open={giftFormOpen}
          onClose={() => setGiftFormOpen(false)}
          gifts={gifts}
          onAddDigigoldGift={onAddDigigoldGift}
          addedGifts={addedGifts}
        />{" "}
        <Dialog
          open={showDelete}
          onClose={closeDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
          sx={{ ...dialogStyle }}
        >
          <DialogTitle id="alert-dialog-title">
            Delete this payment history?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The order will also be deleted
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDelete}>Cancel</Button>
            <LoadingButton
              loading={deleting}
              variant="contained"
              size="small"
              color="error"
              onClick={onOrderDelete}
              autoFocus
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Drawer>
    </>
  );
};

const CancelForm = ({ closeCancelPlan, ledger, currency, customerInfo }) => {
  const [addForm, setAddForm] = useState({
    tax: "",
    penalty: "",
    bank_charges: "",
    additional_charges: "",
    final_paid: "",
    close_request_by: "",
    close_by: "",
  });
  const [phoneNumber, setPhoneNumber] = useState({
    number: "",
    dialcode: "",
  });
  const dispatch = useDispatch();
  const addingDigigoldCancel = useSelector(
    (state) => state.settings.addingDigigoldCancel
  );
  const [subusers, setSubusers] = useState([]);
  const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
  const editForm = (e) => setAddForm((state) => ({ ...state, ...e }));
  const smsIntegration = useSelector((state) => state.settings.smsIntegration);

  useEffect(() => {
    let { dialcode_mobile, dialcode_whatsapp, whatsapp, billing } =
      customerInfo;
    let dialcode, number;
    if (dialcode_mobile && billing?.phone) {
      dialcode = dialcode_mobile;
      number = billing?.phone;
    } else if (dialcode_whatsapp && whatsapp) {
      dialcode = dialcode_whatsapp;
      number = whatsapp;
    }
    setPhoneNumber({ number, dialcode });
  }, [customerInfo]);

  useEffect(() => {
    if (isArrayWithValues(reduxTeamMembers)) {
      setSubusers(
        [...reduxTeamMembers].map((i) => {
          return {
            label: `${i.first_name} ${i.last_name}`,
            value: `${i.first_name} ${i.last_name}`,
          };
        })
      );
    }
  }, [reduxTeamMembers]);

  let { paid_count, duration, paid, joining_date } = ledger;

  const onSubmit = () => {
    let obj = { ...addForm, plan_id: ledger.product };
    if (!obj?.final_paid) {
      obj.final_paid = Math.round(
        validateNumber(paid) -
          (validateNumber(addForm.tax) +
            validateNumber(addForm.additional_charges) +
            validateNumber(addForm.bank_charges) +
            validateNumber(addForm.penalty)) +
          validateNumber(addForm.additional_benefit)
      );
    }
    obj = {
      id: `${ledger.product}_${customerInfo.id}_${getTimeInMilliSeconds()}`,
      time: new Date().getTime(),
      ...obj,
    };
    dispatch(
      addDigigoldPlanCancel(ledger.product, customerInfo.id, obj, {
        onSuccess: () => {
          dispatch(
            setGlobalToast({
              show: true,
              message: "Plan cancelled successfully",
              severity: "success",
            })
          );
          closeCancelPlan({ cancelled_plan: obj });
        },
      })
    );
  };

  const onSendOTP = async () => {
    try {
      editForm({
        sending_otp: true,
      });
      // let res = await backendApiCall({
      //   endpoint: "sms/otp/generate",
      //   method: "POST",
      //   data: {
      //     to: `${phoneNumber.dialcode || ""}${phoneNumber?.number || ""}`,
      //   },
      // });
      let res = await onSendSMS({
        smsIntegration: smsIntegration,
        customer: customerInfo,
        type: "digital_gold_template",
        phone_number: `${phoneNumber.dialcode || ""}${
          phoneNumber?.number || ""
        }`,
      });
      if (res?.success) {
        editForm({ is_otp_sent: true, otp_sent: res?.otp_sent });
        dispatch(
          setGlobalToast({
            show: true,
            message: "OTP has been sent successfully",
            severity: "success",
          })
        );
      }
      editForm({
        sending_otp: false,
      });
    } catch (error) {
      editForm({
        otp_error: "Unable to send OTP",
      });
    }
  };
  const onVerifyOTP = async () => {
    try {
      editForm({
        verifying_otp: true,
      });
      await sleep(1500);
      let otpRes = {};
      if (addForm.otp + "" === addForm.otp_sent + "") {
        otpRes.success = true;
      } else otpRes.not_match = true;

      // let otpRes = await backendApiCall({
      //   endpoint: "sms/otp/verify",
      //   method: "POST",
      //   data: {
      //     to: `${phoneNumber.dialcode || ""}${phoneNumber?.number || ""}`,
      //     otp: addForm.otp,
      //   },
      // });

      if (!otpRes?.success)
        if (
          otpRes?.message?.toLowerCase()?.includes("match") ||
          otpRes.not_match
        )
          editForm({
            otp_error: "Entered OTP is incorrect",
          });
        else
          editForm({
            otp_error: "Unable to verify OTP",
          });
      if (otpRes?.success) {
        editForm({ is_otp_verified: true });
        editForm({
          otp_error: "",
        });
      }
    } catch (error) {
      console.log(error);
      editForm({
        otp_error: "Unable to verify OTP",
      });
    } finally {
      editForm({
        verifying_otp: false,
      });
    }
  };

  return (
    <>
      <DialogContent>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h6" id="alert-dialog-title">
            Close Plan
          </Typography>
          <IconButton onClick={closeCancelPlan}>
            <Close />
          </IconButton>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Label>Plan Date - </Label>
              <Label sx={{ ml: 1 }}>
                {isValidDate(new Date(joining_date))
                  ? formatDate(new Date(joining_date), "relative_date")
                  : ""}
                {" - "}
                {isValidDate(new Date(joining_date))
                  ? formatDate(
                      new Date(
                        new Date(joining_date).setMonth(
                          new Date(joining_date).getMonth() + Number(duration)
                        )
                      ),
                      "relative_date"
                    )
                  : ""}
              </Label>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="flex-start"
            >
              <Label>Paid Installments - </Label>
              <Label sx={{ ml: 1 }}>
                {paid_count}/{duration}
              </Label>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Label>Total Paid Amount - </Label>
              <Label sx={{ ml: 1 }}>
                {currency} {paid}
              </Label>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Label sx={{ color: "success.main" }}>Amount Payable - </Label>
              <Label
                sx={{
                  ml: 1,
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "success.main",
                }}
              >
                {currency} {paid}
              </Label>
            </Stack>
          </Grid>
          {/* <Grid item xs={6}>
          <Box>
            <Label>Additional Benefit</Label>
            <TextField
              // variant="outlined"
              size="small"
              // disabled
              // label="Duration"
              // value={addForm.postcode}
              // onChange={postcodeChange}
              // fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currency}</InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid> */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Label sx={{ fontSize: "15px" }}>Deduction: </Label>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Label sx={{ color: "error.main" }}>Tax -</Label>
              <TextField
                // variant="outlined"
                size="small"
                // disabled
                // label="Duration"
                value={addForm.tax}
                onChange={(e) => editForm({ tax: e.target.value })}
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Label sx={{ color: "error.main" }}>Penalty -</Label>
              <TextField
                // variant="outlined"
                size="small"
                // disabled
                // label="Duration"
                value={addForm.penalty}
                onChange={(e) => editForm({ penalty: e.target.value })}
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Label sx={{ color: "error.main" }}>Bank Charges -</Label>
              <TextField
                // variant="outlined"
                size="small"
                // disabled
                // label="Duration"

                value={addForm.bank_charges}
                onChange={(e) => editForm({ bank_charges: e.target.value })}
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Label sx={{ color: "error.main" }}>Additional Charges -</Label>
              <TextField
                // variant="outlined"
                size="small"
                // disabled
                // label="Duration"
                value={addForm.additional_charges}
                onChange={(e) =>
                  editForm({ additional_charges: e.target.value })
                }
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Label sx={{ color: "success.main" }}>Additional Benefit +</Label>
              <TextField
                // variant="outlined"
                size="small"
                // disabled
                // label="Duration"

                value={addForm.additional_benefit}
                onChange={(e) =>
                  editForm({ additional_benefit: e.target.value })
                }
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ alignSelf: "center" }}>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"flex-end"}
            >
              <Label>Closing Balance: </Label>
              <Label sx={{ ml: 2 }}>
                {currency}{" "}
                {Math.round(
                  validateNumber(paid) -
                    (validateNumber(addForm.tax) +
                      validateNumber(addForm.additional_charges) +
                      validateNumber(addForm.bank_charges) +
                      validateNumber(addForm.penalty)) +
                    validateNumber(addForm.additional_benefit)
                )}
              </Label>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              mt={1}
            >
              <Label>Final Paid: </Label>
              <TextField
                // variant="outlined"
                size="small"
                // disabled
                // label="Duration"

                value={addForm.final_paid}
                onChange={(e) => editForm({ final_paid: e.target.value })}
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <Box>
              <Label>Close Request By</Label>
              <TextField
                // variant="outlined"
                size="small"
                placeholder="Enter name"
                // disabled
                // label="Duration"

                value={addForm.close_request_by}
                onChange={(e) => editForm({ close_request_by: e.target.value })}
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person style={{ height: "20px", width: "20px" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Label>Close By</Label>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                options={subusers.map((option) => option.label)}
                inputValue={addForm.close_by}
                onInputChange={(a, e) => editForm({ close_by: e })}
                renderInput={(params) => <TextField {...params} />}
                size="small"
                openOnFocus
              />
              {/* <TextField
                // variant="outlined"
                size="small"
                placeholder="Enter name"
                // disabled
                // label="Duration"
                value={addForm.close_by}
                onChange={(e) => editForm({ close_by: e.target.value })}
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person style={{ height: "20px", width: "20px" }} />
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Box>
          </Grid>
        </Grid>
        {!addForm.is_otp_verified && (
          <FormControlLabel
            control={
              <Checkbox
                checked={addForm.otp_verified}
                onChange={(e) => editForm({ otp_verified: e.target.checked })}
              />
            }
            label="Verify with OTP"
          />
        )}
        {addForm.otp_verified &&
          !addForm.is_otp_verified &&
          addForm?.is_otp_sent && (
            <Box>
              <Label sx={{ color: "success.main" }}>
                Enter OTP (Phone:{" "}
                {`${phoneNumber.dialcode || ""} ${phoneNumber?.number || ""}`}):{" "}
              </Label>
              <TextField
                // variant="outlined"
                size="small"
                // disabled
                // label="Duration"

                value={addForm.otp}
                onChange={(e) => editForm({ otp: e.target.value })}
                // fullWidth
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">{currency}</InputAdornment>
                //   ),
                // }}
              />
            </Box>
          )}
      </DialogContent>
      {addForm.is_otp_verified && (
        <SecondaryLabelSmall
          sx={{ color: "success.main", textAlign: "center" }}
        >
          OTP has been verified
        </SecondaryLabelSmall>
      )}
      {addForm.otp_error && (
        <SecondaryLabelSmall sx={{ color: "error.main", textAlign: "center" }}>
          {addForm?.otp_error}
        </SecondaryLabelSmall>
      )}
      <DialogActions>
        <Button onClick={closeCancelPlan}>Close</Button>
        {addForm?.otp_verified && !addForm.is_otp_verified ? (
          !addForm?.is_otp_sent ? (
            <LoadingButton
              variant="contained"
              // color="error"
              size="small"
              onClick={onSendOTP}
              loading={addForm.sending_otp}
              autoFocus
            >
              Send OTP
            </LoadingButton>
          ) : (
            <LoadingButton
              variant="contained"
              // color="error"
              size="small"
              onClick={onVerifyOTP}
              loading={addForm.verifying_otp}
            >
              Verify OTP
            </LoadingButton>
          )
        ) : (
          <LoadingButton
            variant="contained"
            color="error"
            size="small"
            onClick={onSubmit}
            loading={addingDigigoldCancel}
            autoFocus
          >
            Cancel Plan
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

const GiftForm = ({
  form,
  setForm,
  open,
  onClose,
  gifts,
  onAddDigigoldGift,
  addedGifts,
}) => {
  const [giftsDropdown, setGiftsDrodpown] = useState([]);
  const addingDigigoldGift = useSelector(
    (state) => state.settings.addingDigigoldGift
  );

  useEffect(() => {
    if (isArrayWithValues(gifts)) {
      setGiftsDrodpown(gifts.filter((i) => !addedGifts?.[i.id]));
    }
  }, [addedGifts, gifts]);
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <Box>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
        {...globalPopupStyle}
      >
        <DialogTitle id="alert-dialog-title">{"Add Gift Entry"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Label>Received Date</Label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDateTimePicker
                  // label="Birthday"
                  // name="birthdate"
                  renderInput={(params) => (
                    <TextField fullWidth label="Enter Date" {...params} />
                  )}
                  fullWidth
                  onChange={(newValue) => {
                    setForm({ date: formatDate(newValue) });
                  }}
                  value={form.date ? form.date : null}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label>Gift</Label>
              <Select
                fullWidth
                value={form.id}
                onChange={(e) => setForm({ id: e.target.value })}
              >
                {isObjWithValues(giftsDropdown) &&
                  Object.values(giftsDropdown).map((i) => {
                    return (
                      <MenuItem
                        value={i.id}
                      >{`${i.title}: ${i.gift}`}</MenuItem>
                    );
                    // return <Chip label={`${i.title}: ${i.gift}`} size="small" color="success" />;
                  })}
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label>Received By</Label>
              <TextField
                value={form.received_by}
                onChange={(e) => setForm({ received_by: e.target.value })}
                fullWidth
                placeholder="Enter name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label>Given By</Label>
              <TextField
                value={form.given_by}
                onChange={(e) => setForm({ given_by: e.target.value })}
                fullWidth
                placeholder="Enter name"
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <LoadingButton
              loading={addingDigigoldGift}
              onClick={() => onAddDigigoldGift(form)}
              autoFocus
            >
              Add
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const UserPlans = ({
  allPlans,
  currency,
  customers,
  paymentsMethods,
  branches,
  teamMembers,
  userView,
  setUserView,
  todayGoldRate,
  openManualForm,
  closeManualForm,
  onPlanPress,
  digigoldCancelledPlans,
  digigoldGifts,
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  let store_id = getStoreId();

  useEffect(() => {
    if (!isObjWithValues(userView)) return;
    if (!userView.plans || !isObjWithValues(userView.plans)) return setRows([]);
    let plans = Object.values(userView.plans);
    let rows = [];
    for (let plan of plans) {
      let obj = {
        ...plan,
        digi_refer_obj: userView.digi_refer_obj || {},
        // ...userView,
        totalExpected: 0,
        totalPaid: 0,
      };
      let { ledger } = plan;
      if (isArrayWithValues(ledger))
        for (let ledgerObj of ledger)
          obj.totalPaid += validateNumber(ledgerObj.total);
      if (isArrayWithValues(allPlans)) {
        let currentPlan = allPlans.find((i) => i.id == obj.product);
        let currentPlanMetaObj = {};
        if (currentPlan) {
          let { meta_data } = currentPlan;
          if (isArrayWithValues(meta_data))
            for (let metaObj of meta_data)
              currentPlanMetaObj[metaObj.key] = metaObj.value;
          obj.totalExpected = currentPlan?.totalExpectedPerPlan || 0;
        }
        obj.totalCollectedPercentage = validateNumber(
          (validateNumber(obj.totalPaid) / validateNumber(obj.totalExpected)) *
            100
        ).toFixed(3);
        if (isObjWithValues(currentPlanMetaObj.gifts))
          obj.gifts = Object.values(currentPlanMetaObj.gifts);
      }
      rows.push(obj);
    }
    setRows(rows);
    // for (let plan of plans) {
    //   let {
    //     totalUsers,
    //     totalPaid,
    //     totalPayments,
    //     totalExpected,
    //     name,
    //     date_created,
    //     meta_data,
    //     totalMissed,
    //     price,
    //     totalPlanContribution,
    //   } = plan;
    //   let metaObj = {};
    //   if (isArrayWithValues(meta_data)) {
    //     for (let obj of meta_data) {
    //       let { key, value } = obj;
    //       if (value) metaObj[key] = value;
    //     }
    //   }
    //   let obj = {
    //     totalUsers,
    //     totalPaid,
    //     totalPayments,
    //     name,
    //     totalExpected,
    //     duration: metaObj.digi_plan_duration,
    //     ...metaObj,
    //     price,
    //     totalMissed,
    //     totalPlanContribution,
    //   };
    //   if (date_created)
    //     obj.date_created = formatDate(new Date(date_created), "dd/mm/yyyy");
    //   rows.push(obj);
    // }
    // setRows(rows);
  }, [userView, digigoldCancelledPlans, allPlans]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Card sx={{ mt: 3, height: "100%" }}>
      <CardContent>
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontSize: "15px" }}>Plans subscribed</Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                size="small"
                variant="outlined"
                onClick={() => openManualForm("Assign a plan")}
                startIcon={<Add />}
              >
                Assign a plan
              </Button>
            </Stack>
          </Stack>
        </Box>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
              {/* {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <StyledTableRow
                      // hover
                      onClick={(event) => onPlanPress(row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      {/* <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell> */}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        // padding="none"
                        sx={{ fontWeight: "500" }}
                      >
                        <Stack direction="row" sx={{ alignItems: "center" }}>
                          <Typography
                            variant="h5"
                            fontSize="14px"
                            sx={{ mr: 1 }}
                          >
                            {row.plan_name}
                          </Typography>

                          {isObjWithValues(digigoldCancelledPlans) &&
                          digigoldCancelledPlans[`${row.is_cancelled}`] &&
                          row?.is_cancelled ? (
                            <Tooltip title="Plan cancelled">
                              <DoDisturb
                                style={{ height: "15px", width: "15px" }}
                                sx={{ color: "error.main" }}
                              />
                            </Tooltip>
                          ) : null}
                        </Stack>
                        {Boolean(
                          userView?.[`digigold_uid_${store_id}`]?.[row?.product]
                        ) && (
                          <Typography
                            // variant="caption"
                            sx={{
                              color: "#808080",
                              fontWeight: "600",
                              fontSize: "14px",
                              letterSpacing: "0.6px",
                            }}
                          >
                            ID:{" "}
                            {
                              userView?.[`digigold_uid_${store_id}`]?.[
                                row?.product
                              ]
                            }
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontWeight: "600", whiteSpace: "nowrap" }}
                      >
                        {row.joining_date
                          ? formatDate(new Date(row.joining_date), "mm-dd-yyyy")
                          : ""}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip
                          title={
                            isArrayWithValues(row?.gifts)
                              ? (row?.gifts).map((i) => (
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                    }}
                                  >
                                    {i.gift}
                                  </Typography>
                                ))
                              : // .join("\n")
                                "-"
                          }
                        >
                          <div>
                            {isArrayWithValues(row?.gifts)
                              ? row?.gifts?.map((i) => (
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      color: digigoldGifts?.[
                                        `${row.product}_${userView.id}`
                                      ]?.[i.id]
                                        ? "success.main"
                                        : "error.main",
                                    }}
                                  >
                                    {i.gift}
                                  </Typography>
                                ))
                              : ""}
                          </div>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="right">
                        <Typography sx={{ fontSize: "14px" }}>
                          {currencySymbols[currency] || ""} {row.totalExpected}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography sx={{ fontSize: "14px" }}>
                          {currencySymbols[currency] || ""} {row.totalPaid}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "12px", color: "text.secondary" }}
                        >
                          {row.totalCollectedPercentage}%
                        </Typography>
                      </TableCell>
                      <TableCell align="right">-</TableCell>
                      {/* <TableCell align="right">-</TableCell>
                      <TableCell align="right">-</TableCell> */}
                    </StyledTableRow>
                  );
                }
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />s */}
      </CardContent>
    </Card>
  );
};

export const AddManualForm = ({
  closeManualForm,
  paymentMethods,
  allPlans,
  currency,
  disableAllPlans,
  dialogTitle,
  branches,
  teamMembers,
  userView,
  setUserView,
  todayGoldRate,
  selectedPlanId,
  setViewLedger,
}) => {
  let [addForm, setAddForm] = useState({
    id: "",
    plan: "",
    duration: "",
    amount: "",
    weight: "",
    payment_ref: "",
    remarks: "",
    mode: "",
    rate: "",
    date_paid: new Date(),
    type: "",
    branch: "",
    change_gold_rate: false,
    digigold_uid: "",
  });
  const [goldPricing, setGoldPricing] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const editForm = (payload) => {
    setAddForm((state) => ({ ...state, ...payload }));
  };
  const [plans, setPlans] = useState([]);
  const [isPlanAssign, setIsPlanAssign] = useState(false);

  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );

  const goldPurities = [
    { label: "24KT (999.99)", value: "999.99" },
    { label: "24KT (999)", value: "999" },
    { label: "24KT (995)", value: "995" },
    { label: "22KT (916)", value: "916" },
    { label: "18KT (750)", value: "750" },
    { label: "14KT (583)", value: "583" },
    { label: "9KT (375)", value: "375" },
  ];

  useEffect(() => {
    if (dialogTitle && typeof dialogTitle === "string")
      setIsPlanAssign(dialogTitle?.includes("Assign"));
  }, [dialogTitle]);

  useEffect(() => {
    if (!isArrayWithValues(allPlans)) return;
    if (selectedPlanId) return setPlans(allPlans);
    if (!isObjWithValues(userView)) return;
    let { plans: _plans } = userView;
    let plans = allPlans?.filter(
      (i) => !Object.keys(_plans).includes(`${i.id}`)
    );
    setPlans(plans);
  }, [userView, allPlans, selectedPlanId]);

  useEffect(() => {
    if (todayGoldRate) editForm({ rate: todayGoldRate });
  }, [todayGoldRate]);
  useEffect(() => {
    editForm({ plan: selectedPlanId });
  }, [selectedPlanId]);

  useEffect(() => {
    let updatedAddForm = {};
    let { plan } = addForm;
    if (!plan) return;
    let selectedPlan = allPlans.find((i) => i.id == plan);
    if (!isObjWithValues(selectedPlan)) return;
    let { meta_data } = selectedPlan;
    if (isArrayWithValues(meta_data))
      for (let i of meta_data) selectedPlan[i.key] = i.value;
    let { digi_plan_type, digi_plan_duration } = selectedPlan;
    if (digi_plan_type === "amount") {
      updatedAddForm.amount = selectedPlan.price;
      updatedAddForm.weight = 0;
      if (addForm.rate)
        updatedAddForm.weight = Number(
          updatedAddForm.amount / Number(addForm.rate)
        ).toFixed(3);
      updatedAddForm.type = "amount";
    }
    if (digi_plan_type === "flexi") {
      updatedAddForm.amount = Math.round(
        Math.round(selectedPlan.price / Number(digi_plan_duration))
      );
      updatedAddForm.weight = 0;
      if (addForm.rate)
        updatedAddForm.weight = Number(
          updatedAddForm.amount / Number(addForm.rate)
        ).toFixed(3);
      updatedAddForm.type = "flexi";
    }
    if (digi_plan_type === "grams") {
      let gramsPerMonth =
        Number(selectedPlan.plan_total_grams) /
        Number(selectedPlan.digi_plan_duration);
      if (gramsPerMonth && addForm.rate)
        updatedAddForm.amount = Math.round(
          Number(addForm.rate) * gramsPerMonth
        );
      updatedAddForm.weight = Number(gramsPerMonth).toFixed(3);
      updatedAddForm.type = "grams";
    }
    updatedAddForm.change_gold_rate = selectedPlan.change_gold_rate;
    console.log(updatedAddForm, selectedPlan);
    updatedAddForm.duration = digi_plan_duration;
    editForm(updatedAddForm);
  }, [addForm.plan, addForm.rate, selectedPlanId]);

  // useEffect(() => {
  //   let { plan, type, amount, weight } = addForm;
  //   if(type === 'flexi'){

  //   }

  // }, [addForm.amount, addForm.weight]);

  useEffect(() => {
    let currency = "";
    if (isObjWithValues(productSettings))
      currency = productSettings.default_currency;
    if (isObjWithValues(masterPricing)) {
      let { gold_pricing } = masterPricing;
      if (gold_pricing[currency]) {
        let { type } = gold_pricing;
        let { enabled_purities } = gold_pricing[currency];
        if (type) {
          let purityObjects = gold_pricing[currency][type];
          let array = [];
          for (let purity in enabled_purities) {
            if (enabled_purities[purity]) {
              let obj = goldPurities.find((i) => i.value == purity);
              if (!obj) continue;
              array.push({ ...obj, rate: purityObjects[purity].rate });
            }
          }
          setGoldPricing(array);
        }
      }
    }
  }, [masterPricing, productSettings]);

  const onAmountChange = (value) => {
    let obj = { amount: value };
    if (addForm.type === "flexi") {
      let { rate } = addForm;
      if (rate)
        obj.weight = validateNumber(
          (validateNumber(value) / validateNumber(rate)).toFixed(3)
        );
    }
    editForm({ ...obj });
  };
  const onGramChange = (value) => {
    let obj = { weight: value };
    if (addForm.type === "flexi") {
      let { rate } = addForm;
      if (rate)
        obj.amount = validateNumber(
          Math.round(validateNumber(rate) * validateNumber(value))
        );
    }
    editForm({ ...obj });
  };

  const onSubmit = async () => {
    let { billing, shipping } = userView;
    let added_by = "";
    let added_by_id = "";
    if (isAdmin) {
      added_by = "admin";
    } else {
      added_by = `${user?.first_name} ${user?.last_name}`;
    }
    added_by_id = getId();
    let planObj =
      isArrayWithValues(allPlans) && allPlans.find((i) => i.id == addForm.plan);
    let obj = {
      customer_id: userView.id,
      billing,
      shipping,
      payment_method: addForm.mode,
      payment_method_title: "",
      // date_paid: getDate(addForm.date_paid),
      status: "completed",
      line_items: [
        {
          product_id: planObj?.id,
          quantity: 1,
          total: `${addForm.amount}`,
          tax_class: "zero-rate",
        },
      ],
    };
    if (!obj.billing.email) delete obj.billing.email;
    if (isArrayWithValues(paymentMethods)) {
      let paymentObj = paymentMethods.find((i) => i.value == addForm.mode);
      if (paymentObj) obj.payment_method_title = paymentObj.label;
    }
    let metaKeys = ["payment_ref", "remarks", "branch"];
    let meta_data = [
      {
        key: "virtual_order",
        value: "digigold",
      },
      {
        key: "manual_payment",
        value: "yes",
      },
      {
        key: "gold_gross",
        value: addForm.weight,
      },
      {
        key: "added_by",
        value: added_by,
      },
      {
        key: "added_by_id",
        value: added_by_id,
      },
      {
        key: "collected_by",
        value: addForm.collected_by,
      },
      {
        key: "manual_order_date",
        value: new Date(addForm.date_paid).getTime() / 1000,
      },
    ];
    for (let key of metaKeys)
      if (addForm[key]) meta_data.push({ key, value: addForm[key] });
    meta_data.push({
      key: "branch_name",
      value: addForm.branch,
    });
    obj.meta_data = meta_data;

    setSubmitting(true);
    if (isPlanAssign)
      await updateCustomerDigiUniqueId(
        userView,
        addForm.plan,
        `${planObj.sku || getInitials(planObj.name)}-${userView.id}`
      );

    let res = await createOrder(obj);
    if (res) {
      let ledgerItem = {
        id: res?.id,
        // url: "https://tiarabytj.com/checkout/order-pay/70369/?pay_for_order=true&key=wc_order_r4gQTzb7DqSYE",
        status: "completed",
        date_paid: formatDateDynamic(
          new Date(addForm.date_paid),
          "MMMM DD, YYYY, HH:mm:ss"
        ),
        mode: addForm.mode,
        transaction_id: "",
        total: addForm.amount,
        ref: addForm.payment_ref,
        gold: `${addForm.weight} grams`,
        manual: true,
        branch: addForm.branch,
      };
      let digigold_details = userView.digigold_details;

      if (isObjWithValues(digigold_details?.plans)) {
        let plans = digigold_details?.plans;
        let updatedPlans = {};
        let viewLedger;
        for (let plan_id in plans) {
          let plan = plans[plan_id];
          if (
            planObj?.id + "" === plan?.product + "" &&
            isArray(plan?.ledger)
          ) {
            plan.ledger = [...plan?.ledger, ledgerItem];
            let totalPaid = 0;
            plan.ledger?.forEach(
              (i) => (totalPaid += validateNumber(i?.total))
            );
            viewLedger = {
              ...plan,
              paid_count: plan?.ledger?.length,
              paid: totalPaid,
            };
            updatedPlans[plan_id] = viewLedger;
          } else updatedPlans[plan_id] = plan;
        }
        setUserView({
          ...userView,
          digigold_details: {
            ...userView.digigold_details,
            plans: updatedPlans,
          },
          plans: updatedPlans,
        });
        if (viewLedger) setViewLedger(viewLedger);
      }
      // let customers = await getDigiCustomers();
      // if (isObjWithValues(customers)) {
      //   dispatch({
      //     type: SET_CRM_DATA,
      //     payload: {
      //       allDigiCustomers: customers,
      //     },
      //   });
      //   setUserView(customers[userView.id]);
      // }
      dispatch(
        setGlobalToast({
          show: true,
          message: "Manual Payment added successfully!",
          severity: "success",
        })
      );
    }
    setSubmitting(false);

    closeManualForm();
    // closeManualDialog();
  };
  console.log(userView, "<<<<<<<<< userView");
  const getDigiCustomers = async () => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/digigold`,
          // url: `${url}/customers`,
          // params,
          headers: {
            Authorization: `Basic ${token}`,
          },
        });
        return data;
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };
  return (
    <Box className="scrollbar-hidden">
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <Grid container mt={0.5} spacing={4}>
          <Grid item xs={8}>
            <Label>Plan Name</Label>
            <FormControl disabled={selectedPlanId} size="small" fullWidth>
              {/* <InputLabel id="demo-simple-select-label">Plan Name</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={addForm.plan}
                placeholder="Plan Name"
                onChange={(e) => editForm({ plan: e.target.value })}
                // disabled
              >
                {isArrayWithValues(plans) &&
                  plans.map((i) => {
                    return <MenuItem value={i.id}>{i.name}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Label>Duration</Label>
            <TextField
              size="small"
              disabled
              // label="Duration"
              value={addForm.duration}
              onChange={(e) => editForm({ duration: e.target.value })}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Months</InputAdornment>
                ),
              }}
              type="number"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: addForm.change_gold_rate ? "block" : "none" }}
          >
            <Label>Gold Rates</Label>
            <TextField
              size="small"
              // disabled
              // label="Amount"
              value={addForm.rate}
              onChange={(e) => editForm({ rate: e.target.value })}
              // onChange={(e) => onRateChange(e.target.value)}
              fullWidth
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currency}</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment>
                    <FormControl
                      variant="standard"
                      sx={{
                        "&:hover": {
                          border: "0px solid transparent",
                          backgroundColor: "transparent",
                          borderRadius: "10px",
                        },
                        "&:focus": {
                          backgroundColor: "transparent",
                        },
                        // minWidth: minWidth || "120px",
                      }}
                      size="small"
                    >
                      {/* <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel> */}
                      <Select
                        disableUnderline
                        id="demo-multiple-checkbox"
                        // multiple
                        // open={open}
                        // input={<OutlinedInput  label={""} />}
                        // renderValue={(selected) => selected}
                        value={addForm.rate}
                        onChange={(e) => {
                          // handleClose();
                          // onChange(e);
                          // onRateChange(e.target.value);
                          editForm({ rate: e.target.value });
                        }}
                        // onClose={handleClose}
                        // onOpen={handleOpen}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        style={{ backgroundColor: "transparent" }}
                        SelectDisplayProps={{
                          style: { backgroundColor: "transparent" },
                        }}
                        sx={{
                          // margin: "4px 6px",
                          "&:focus": {
                            backgroundColor: "transparent",
                          },
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        size="small"
                      >
                        {isArrayWithValues(goldPricing) &&
                          goldPricing.map((obj) => {
                            return (
                              <MenuItem key={obj.value} value={obj.rate}>
                                {obj.label}
                                {/* <ListItemText primary={obj.label} /> */}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Label>Amount</Label>
            <TextField
              size="small"
              disabled={
                // true
                addForm.type !== "flexi"
              }
              // label="Amount"
              value={addForm.amount}
              // onChange={(e) => editForm({ amount: e.target.value })}
              onChange={(e) => onAmountChange(e.target.value)}
              fullWidth
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currency}</InputAdornment>
                ),
              }}
            />
            {addForm.rate ? (
              <Typography
                sx={{
                  fontSize: "11px",
                  color: "text.secondary",
                  mt: 0.2,
                  ml: 0.2,
                }}
              >
                As per gold rate
                <strong style={{ marginLeft: "3px" }}>{addForm.rate}</strong>
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <Label>Gold Credit</Label>
            <TextField
              size="small"
              disabled={addForm.type !== "flexi"}
              // disabled={true}
              // label="Gold Credit"
              value={addForm.weight}
              // onChange={(e) => editForm({ weight: e.target.value })}
              onChange={(e) => onGramChange(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Grams</InputAdornment>
                ),
              }}
              type="number"
            />
          </Grid>
          {isPlanAssign && false && (
            <Grid item xs={6}>
              <Label>Unique id</Label>
              <TextField
                size="small"
                // label="Duration"
                value={addForm.digigold_uid}
                onChange={(e) =>
                  editForm({ digigold_uid: e.target.value?.toUpperCase() })
                }
                fullWidth
              />
              <Typography
                sx={{
                  fontSize: "11px",
                  color: "text.secondary",
                  mt: 0.2,
                  ml: 0.2,
                }}
              >
                For e.g. Plan1-01
              </Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Label>Payment made on</Label>
              <DatePicker
                // label="Payment made on"
                style={{ width: "100%" }}
                value={addForm.date_paid}
                toolbarFormat="ddd DD MMMM"
                onChange={(newValue) => {
                  editForm({ date_paid: newValue });
                }}
                renderInput={(params) => (
                  <TextField size="small" fullWidth {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <Label>Mode of payment</Label>
            <FormControl size="small" fullWidth>
              {/* <InputLabel id="demo-simple-select-label">
                  Mode of Payment
                </InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={addForm.mode}
                // label="Mode of Payment"
                onChange={(e) => editForm({ mode: e.target.value })}
              >
                {isArrayWithValues(paymentMethods) &&
                  paymentMethods.map((i) => {
                    return (
                      <MenuItem value={i.value}>
                        {i.label === "Cash on delivery" ? "Cash" : i.label}
                      </MenuItem>
                    );
                  })}
                {/* <MenuItem value={"cheque"}>Cheque</MenuItem>
                  <MenuItem value={"demand_draft"}>Demand Draft</MenuItem>
                  <MenuItem value={"online_payment"}>Online Payment</MenuItem>
                  <MenuItem value={"ornate_nx"}>Ornate NX</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Label>Payment Reference</Label>
            <TextField
              size="small"
              // label="Payment Reference"
              value={addForm.payment_ref}
              onChange={(e) => editForm({ payment_ref: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Label>Branch</Label>
            <FormControl size="small" fullWidth>
              {/* <InputLabel id="demo-simple-select-label">
                  Mode of Payment
                </InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={addForm.branch}
                // label="branch of Payment"
                onChange={(e) => editForm({ branch: e.target.value })}
              >
                {isArrayWithValues(branches) &&
                  branches.map((i) => {
                    return (
                      <MenuItem value={i.branchName}>{i.branchName}</MenuItem>
                    );
                  })}
                {/* <MenuItem value={"cheque"}>Cheque</MenuItem>
                  <MenuItem value={"demand_draft"}>Demand Draft</MenuItem>
                  <MenuItem value={"online_payment"}>Online Payment</MenuItem>
                  <MenuItem value={"ornate_nx"}>Ornate NX</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={isPlanAssign || true ? 12 : 6}>
            <Label>Collected by</Label>
            <TextField
              size="small"
              // label="collected By"
              value={addForm.collected_by}
              // rows={2}
              // multiline
              helperText="Name of the person collected"
              onChange={(e) => editForm({ collected_by: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Label>Remarks</Label>
            <TextField
              size="small"
              // label="Remarks"
              value={addForm.remarks}
              rows={2}
              multiline
              onChange={(e) => editForm({ remarks: e.target.value })}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeManualForm}>Cancel</Button>
        <LoadingButton loading={submitting} onClick={onSubmit} autoFocus>
          Add
        </LoadingButton>
      </DialogActions>
    </Box>
  );
};

const CustomerInfoCard = ({ customer }) => {
  let [info, setInfo] = useState({});
  useEffect(() => {
    if (isObjWithValues(customer)) {
      let { billing, meta_data } = customer;
      let obj = { ...billing, ...customer };
      if (isArrayWithValues(meta_data))
        for (let metaObj of meta_data) obj[metaObj.key] = metaObj.value;
      setInfo({
        ...obj,
        id: customer.id,
        joining_date: getFirstPlanDate(customer?.plans || {}),
      });
    }
  }, [customer]);

  return (
    <Card sx={{ mt: 3, boxShadow: 6, height: "100%" }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                overflow: "hidden",
                mb: 3,
              }}
            >
              <img
                src={
                  info.profile_url ||
                  info.avatar_url ||
                  "/static/img/woocommerce_placeholder.png"
                }
                // className="shadow_card"
                style={{
                  height: "100%",
                  width: "100%",
                  maxHeight: "70px",
                  maxWidth: "70px",
                  borderRadius: "50%",
                  objectFit: "contain",
                  margin: "10px",
                  textAlign: "center",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ margin: "auto 10px" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
              {info.first_name} {info.last_name}
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "text.secondary" }}>
              {info.email}
            </Typography>
            <Typography
              sx={{ fontSize: "12px", mt: 1, color: "text.secondary" }}
            >
              <strong>Customer ID:</strong> {info.id}
            </Typography>
            {/* <Typography
              sx={{ fontSize: "12px", mt: 1, color: "text.secondary" }}
            >
              <strong>Mobile:</strong> {info.phone || info.whatsapp}
            </Typography> */}
            {/* <Typography
              sx={{ fontSize: "12px", mt: 1, color: "text.secondary" }}
            >
              <strong>Address:</strong>{" "}
              {info.address_1 ? `${info.address_1},` : ""}{" "}
              {info.address_2 ? `${info.address_2},` : ""}{" "}
              {info.city ? `${info.city},` : ""}{" "}
              {info.state ? `${info.state},` : ""}{" "}
              {info.country ? `${info.country},` : ""}
            </Typography> */}
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={4}
            md={12}
            xl={12}
            sx={{ textAlign: "center", margin: "auto 10px" }}
          >
            <Typography
              sx={{ fontSize: "12px", mt: 1, color: "text.secondary" }}
            >
              <strong>Nominee Name:</strong> {info.nominee_name}
            </Typography>
            <Typography
              sx={{ fontSize: "12px", mt: 1, color: "text.secondary" }}
            >
              <strong>Nominee Relation:</strong> {info.nominee_relation}
            </Typography>
            <Typography
              sx={{ fontSize: "12px", mt: 1, color: "text.secondary" }}
            >
              <strong>Proof Type:</strong> {info.digigold_proof_type}
            </Typography>
            <Typography
              sx={{ fontSize: "12px", mt: 1, color: "text.secondary" }}
            >
              <strong>Proof ID:</strong> {info.digigold_proof_id}
            </Typography>
          </Grid> */}
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Address"
              value={`${info.address_1 ? `${info.address_1},` : ""} ${
                info.address_2 ? `${info.address_2},` : ""
              } ${info.city ? `${info.city},` : ""} ${
                info.state ? `${info.state},` : ""
              } ${info.country ? `${info.country},` : ""}`}
              fullWidth
              // disabled
              rows={2}
              multiline
              sx={{ mt: 3 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile"
              value={`${info.phone || info.whatsapp || ""}`}
              fullWidth
              sx={{ mt: 3 }}
              // disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Family name"
              value={`${customer?.digi_refer_obj?.first_name || ""} ${
                customer?.digi_refer_obj?.last_name || ""
              }`}
              fullWidth
              sx={{ mt: 3 }}
              // disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Joining Date"
              // value={`${""}`}/
              fullWidth
              sx={{ mt: 3 }}
              value={`${formatDate(
                new Date(info?.joining_date),
                "dd:mm:yyyy hh:mm am/pm"
              )}`}
              // disabled
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const KYCdetails = ({ customer }) => {
  return (
    <Card sx={{ mt: 6 }}>
      <CardContent>
        <Typography variant="h5" sx={{ fontSize: "16px", fontWeight: "500" }}>
          KYC Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              label="Nominee Name"
              value={`${customer.nominee_name || ""}`}
              fullWidth
              sx={{ mt: 3 }}
              // disabled
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              label="Relation with nominee"
              value={`${customer.nominee_relation || ""}`}
              fullWidth
              sx={{ mt: 3 }}
              // disabled
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              label="Documents submitted"
              value={`${customer.digigold_proof_type || ""}`}
              fullWidth
              sx={{ mt: 3 }}
              // disabled
            />
          </Grid>
        </Grid>
        <Typography
          variant="h5"
          sx={{ fontSize: "16px", fontWeight: "500", mt: 3 }}
        >
          Other Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              label="Referred to"
              value={`${""}`}
              fullWidth
              sx={{ mt: 3 }}
              // disabled
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              label="Cashback earned"
              value={`${""}`}
              fullWidth
              sx={{ mt: 3 }}
              // disabled
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              label="Cashback redeemed"
              value={`${""}`}
              fullWidth
              sx={{ mt: 3 }}
              // disabled
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Label = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
`;
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const getDate = (date) => {
  var sun = new Date();
  sun.setDate(sun.getDate() + (7 - sun.getDay()));
  sun.setHours(9);
  sun.setMinutes(0);
  sun.setSeconds(0);
  return sun.toISOString().split(".")[0];
};

const createOrder = async (obj) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/orders`,
        method: "POST",
        data: obj,
        headers: { Authorization: `Basic ${token}` },
      });
      return data;
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  }
};
const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Plan Name",
  },

  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  // {
  //   id: "referby",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Refered by",
  // },
  {
    id: "gifts_rewards",
    numeric: false,
    disablePadding: false,
    label: "Gifts/rewards",
  },
  {
    id: "expected_collections",
    numeric: true,
    disablePadding: false,
    label: "Expected collection",
  },
  {
    id: "received_collections",
    numeric: true,
    disablePadding: false,
    label: "Received collection",
  },
  {
    id: "misssed_collections",
    numeric: true,
    disablePadding: false,
    label: "Missed",
  },
];

export const CustomerOrders = ({ orders, currency }) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  const fetchingDigiCustomerOrders = useSelector(
    (state) => state.customers.fetchingDigiCustomerOrders
  );

  const theme = useTheme();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";

  useEffect(() => {
    if (!isArrayWithValues(orders)) return;
    let rows = [];
    for (let order of orders) {
      let obj = { ...order };
      let { meta_data } = order;
      if (isArrayWithValues(meta_data)) {
        for (let metaObj of meta_data) obj[metaObj.key] = metaObj.value;
      }
      rows.push(obj);
    }
    setRows(rows);
    // if (!isArrayWithValues(plans)) return;
    // let rows = [];
    // for (let plan of plans) {
    //   let {
    //     totalUsers,
    //     totalPaid,
    //     totalPayments,
    //     name,
    //     date_created,
    //     meta_data,
    //   } = plan;
    //   let metaObj = {};
    //   if (isArrayWithValues(meta_data)) {
    //     for (let obj of meta_data) {
    //       let { key, value } = obj;
    //       if (value) metaObj[key] = value;
    //     }
    //   }
    //   let obj = {
    //     totalUsers,
    //     totalPaid,
    //     totalPayments,
    //     name,
    //     duration: metaObj.digi_plan_duration,
    //   };
    //   if (date_created)
    //     obj.date_created = formatDate(
    //       new Date(date_created),
    //       "dd:mm:yyyy hh:mm"
    //     );
    //   rows.push(obj);
    // }
    // setRows(rows);
  }, [orders]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(selected.length > 0 && {
                  bgcolor: (theme) =>
                    alpha(
                      theme.palette.primary.main,
                      theme.palette.action.activatedOpacity
                    ),
                }),
              }}
            >
              {selected.length > 0 ? (
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {selected.length} selected
                </Typography>
              ) : (
                <SectionLabel
                  sx={{ flex: "1 1 100%" }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Customer Payments
                </SectionLabel>
              )}

              {/* {selected.length > 0 ? (
                <Tooltip title="Delete">
                  <IconButton>
                    <Delete />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Filter list">
                  <IconButton>
                    <FilterList />
                  </IconButton>
                </Tooltip>
              )} */}
            </Toolbar>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <DigiOrdersTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {fetchingDigiCustomerOrders && !isArrayWithValues(rows) && (
                    <>
                      {Array(5)
                        .fill(null)
                        .map((item, index) => (
                          <TableRow
                            key={index}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              py: 2,
                              cursor: "pointer",
                            }}
                          >
                            {/* <TableCell padding="checkbox">
                              <IconButton>
                                <Skeleton
                                  variant="rectangular"
                                  sx={{
                                    width: "18px",
                                    height: "18px",
                                    borderRadius: "3px",
                                    ml: 1,
                                  }}
                                />
                              </IconButton>
                            </TableCell> */}
                            <TableCell component="th" scope="row">
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem", width: "100%" }}
                              />
                            </TableCell>

                            <TableCell align="right">
                              <Skeleton
                                variant="text"
                                sx={{
                                  fontSize: "1rem",
                                  width: "35%",
                                  marginLeft: "auto",
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Skeleton
                                variant="text"
                                sx={{
                                  fontSize: "1rem",
                                  width: "35%",
                                  marginLeft: "auto",
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Skeleton
                                variant="text"
                                sx={{
                                  fontSize: "1rem",
                                  width: "35%",
                                  marginLeft: "auto",
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Skeleton
                                variant="text"
                                sx={{
                                  fontSize: "1rem",
                                  width: "35%",
                                  marginLeft: "auto",
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Skeleton
                                variant="text"
                                sx={{
                                  fontSize: "1rem",
                                  width: "35%",
                                  marginLeft: "auto",
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Skeleton
                                variant="text"
                                sx={{
                                  fontSize: "1rem",
                                  width: "35%",
                                  marginLeft: "auto",
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          //   <TableRow key={index}>
                          //     <TableCell>
                          //       <Skeleton
                          //         variant="text"
                          //         sx={{ fontSize: "1rem", width: "50%" }}
                          //       />
                          //     </TableCell>
                          //     <TableCell>
                          //       <Skeleton
                          //         variant="text"
                          //         sx={{ fontSize: "1rem", width: "50%" }}
                          //       />
                          //     </TableCell>
                          //     <TableCell>
                          //       <Skeleton
                          //         variant="text"
                          //         sx={{ fontSize: "1rem", width: "50%" }}
                          //       />
                          //     </TableCell>
                          //     <TableCell>
                          //       <Skeleton
                          //         variant="text"
                          //         sx={{ fontSize: "1rem", width: "50%" }}
                          //       />
                          //     </TableCell>
                          //     <TableCell>
                          //       <Skeleton
                          //         variant="text"
                          //         sx={{ fontSize: "1rem", width: "50%" }}
                          //       />
                          //     </TableCell>
                          //   </TableRow>
                        ))}
                    </>
                  )}
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                  {/* {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                  {stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <StyledTableRow
                          // hover
                          // onClick={(event) => handleClick(event, row.name)}
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell> */}
                          {/* <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            <Typography
                              sx={{ fontWeight: "600", fontWeight: "15px" }}
                            >
                              {row?.billing?.first_name || ""}{" "}
                              {row?.billing?.last_name || ""}
                            </Typography>
                          </TableCell>
                          <TableCell align="right"></TableCell>
                          <TableCell align="right">
                            {row?.billing?.city || ""}
                          </TableCell> */}
                          <TableCell component="th" id={labelId} scope="row">
                            <Tooltip title={row?.line_items?.[0]?.name || ""}>
                              <Stack
                                direction="row"
                                alignItems={"center"}
                                // justifyContent="flex-end"
                              >
                                {row?.line_items?.[0]?.name
                                  ? reduceLargeText(
                                      row?.line_items?.[0]?.name,
                                      18
                                    )
                                  : "-"}
                              </Stack>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="left">
                            {row.date_created
                              ? formatDate(
                                  new Date(
                                    validateNumber(row.manual_order_date) * 1000
                                  ),
                                  "relative_date"
                                )
                              : ""}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ fontSize: "14px", fontWeight: "500" }}
                          >{`${row?.currency_symbol || ""} ${
                            row?.total || ""
                          }`}</TableCell>
                          <TableCell align="left">
                            <Typography
                              sx={{ fontWeight: "600", fontWeight: "15px" }}
                            >
                              {/* {row?.billing?.first_name || ""}{" "}
                              {row?.billing?.last_name || ""} */}
                              <Typography sx={{ fontSize: "13px" }}>
                                {row.manual_payment === "yes"
                                  ? "Manual"
                                  : "Automatic"}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  color: "text.secondary",
                                  mt: 0.5,
                                }}
                              >
                                Ref. No: {row.id}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  color: "text.secondary",
                                  mt: 0.5,
                                }}
                              >
                                {row.payment_method_title}
                              </Typography>
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label={row.status}
                              // color="warning"
                              sx={{
                                color: statusFontColor(row.status),
                                border: isDarkTheme
                                  ? "0.7px solid"
                                  : "0px solid",
                                // borderColor: statusFontColor(row.status),
                                backgroundColor: isDarkTheme
                                  ? "transparent"
                                  : getStatusBackgroundColor(row.status),
                              }}
                              variant="outlined"
                            />
                          </TableCell>{" "}
                          <TableCell align="left">
                            {row.remarks || ""}
                          </TableCell>{" "}
                          <TableCell align="right">-</TableCell>
                        </StyledTableRow>
                      );
                    }
                  )}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />s */}
          </Paper>
        </Box>
      </CardContent>
    </Card>
  );
};

const DigiOrdersTableHeadCells = [
  // {
  //   id: "name",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Customer Name",
  // },
  // {
  //   id: "family_name",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Family Name",
  // },
  // {
  //   id: "location",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Location",
  // },
  {
    id: "plan_name",
    numeric: false,
    disablePadding: false,
    label: "Plan Name",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "amt_paid",
    numeric: true,
    disablePadding: false,
    label: "Amt Paid",
  },
  {
    id: "payment_mode",
    numeric: false,
    disablePadding: false,
    label: "Payment mode",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "remarks",
    numeric: false,
    disablePadding: false,
    label: "Remarks",
  },
  {
    id: "next_due",
    numeric: true,
    disablePadding: false,
    label: "Next due on",
  },
];

function DigiOrdersTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {DigiOrdersTableHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

DigiOrdersTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const LabelValue = ({ label, value }) => {
  return (
    <Stack direction="row">
      <Typography variant="body1">{label}: </Typography>
      <Typography variant="body1" ml={1} fontWeight={"500"}>
        {value}
      </Typography>
    </Stack>
  );
};
