const ADD_REWARD_OPTIONS = [{ label: "% Percentage", value: "percentage" }];

const ADD_PLAN_TOOLTIPS = {
  plan_code:
    "Adding a plan code will allow the system to generate and assign a unique code to each customer for their specific plan, ensuring they have a unique identifier based on each plan.",
  plan_type:
    "Upon selecting the amount option, jewellers can configure plan's installments based on amount, whereas opting for the grams option enables the setup of plans based on weight.",
  plan_duration:
    "The duration of the plan can be set to notify customers about how long the plan will be active.",
  plan_amount:
    "The total plan amount limit can be specified. If set to fixed, the total plan limit will be set to  specific amount (X) that customers must reach by the end. If opting for Unlimited option, customers will be aware that there is no maximum limit for the plan.",
  transection_limit:
    "Setting a limit per transaction is necessary when choosing to establish rewards based on customized intervals. Ex. Minimum amount - INR 100 and maximum amount INR 1,99,999.",
  plan_thumbnail:
    "This thumbnail will be displayed on the website when a customer views the plan page.",
  settings_show_accumulated_amount:
    "If Checked , Customers will be able to view accumulated amount within their account on website or app.",
  settings_show_accumulated_weight:
    "If Enabled customers will be able to view Gold Weight for each transaction within their Account on Website or app.",
  add_fixed_reward:
    "If the plan includes a single fixed lump-sum reward, you can specify it here, and the customer will receive this lump-sum amount as a bonus at the end of the plan.",
};

export { ADD_REWARD_OPTIONS, ADD_PLAN_TOOLTIPS };
