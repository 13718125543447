import { useState } from "react";
import {
  Box,
  Dialog,
  Typography,
  DialogActions,
  IconButton,
  DialogContent,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { chatbotSettingStyles
 } from "../../styles/chatbotSettingStyles";
import VerticalTabs from "./components/VerticalTabs";
const styles = chatbotSettingStyles;

const ChatbotSetting = ({ openSetting, setOpenSetting }) => {
  const handleClose = () => {
    setOpenSetting(false);
  };

  return (
    <Dialog
      open={openSetting}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          minWidth: "700px",
          minHeight: "600px",
        }
      }}
    >
      <Box sx={styles.header}>
        <Typography span="h6" sx={styles.headerTitle} id="alert-dialog-title">Setting</Typography>

        <DialogActions>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <IconButton onClick={handleClose}>
            <DoneIcon />
          </IconButton>
        </DialogActions>
      </Box>
      <VerticalTabs />
    </Dialog>
  );
};

export default ChatbotSetting;
