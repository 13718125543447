import { useState, useEffect } from "react";
import {
  Typography,
  Card,
  TextField,
  IconButton,
  Box,
  InputAdornment,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import AskUntil from "./components/AskUntil";
import AddQuestion from "./components/AddQuestion";
import ValidateResponsePopover from "./components/ValidateResponsePopover";
import SaveResponsePopover from "./components/SaveResponsePopover";
import Header from "./components/Header";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { questionStyles } from "./styles/questionStyles";
import "../public/css/scrollbar.css";
import DeleteAndSwap from "../components/DeleteAndSwap";

const styles = questionStyles;

const SidebarQuestion = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [loading, setLoading] = useState(true);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState({
    openedPopoverId: null,
    anchorEl: null,
  });
  const [saveOptionsAnchorEl, setSaveOptionsAnchorEl] = useState({
    openedPopoverId: null,
    anchorEl: null,
  });
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [titleCheckFlag, setTitleCheckFlag] = useState(false);
  const newQuestionObj = {
    id: Date.now(),
    questionVariants: [
      {
        id: Date.now() + "2",
        response: "",
        isUser: false,
        type: "text",
      },
    ],
    variantIndex: 0,
    validateEntity: null,
    saveAttribute: "",
    actionOnFailure: null,
    askUntil: -1,
  };
  const [questions, setQuestions] = useState([]);
  const handleClickPopover = (e, id) => {
    e.stopPropagation();
    setOptionsAnchorEl({ openedPopoverId: id, anchorEl: e.currentTarget });
  };

  const handleSaveClickPopover = (e, id) => {
    e.stopPropagation();
    setSaveOptionsAnchorEl({ openedPopoverId: id, anchorEl: e.currentTarget });
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title == "" || titleCheckFlag) {
      setTitleCheckFlag(true);
      setTitleError(true);
    }

    if (title && !titleCheckFlag) {
      const submittedUserData = {
        title,
        fieldsData: questions,
      };
      dispatch({ type: actions.SIDEBAR_QUESTION, payload: false });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  const closeSidebar = () => {
    dispatch({ type: actions.SIDEBAR_QUESTION, payload: false });
  };

  const handleToggleVariant = (quesId, varIndex) => {
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question.id === quesId) {
          const updatedQuestion = { ...question };
          updatedQuestion.variantIndex = varIndex;
          return updatedQuestion;
        }
        return question;
      });
    });
  };

  const addVariants = (quesId) => {
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question.id === quesId) {
          const updatedQuestion = { ...question };
          updatedQuestion.questionVariants.push({
            id: Date.now() + "2",
            response: "",
            isUser: false,
            type: "text",
          });
          updatedQuestion.variantIndex =
            updatedQuestion.questionVariants.length - 1;
          return updatedQuestion;
        }
        return question;
      });
    });
  };

  const handleInput = (e, quesId, varIndex) => {
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question.id === quesId) {
          const updatedQuestion = { ...question };
          updatedQuestion.questionVariants[varIndex].response = e.target.value;
          return updatedQuestion;
        }
        return question;
      });
    });
  };

  const handleDeleteVariant = (quesId, varIndex) => {
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question.id === quesId) {
          const updatedQuestion = { ...question };
          updatedQuestion.questionVariants.splice(varIndex, 1);
          if (
            updatedQuestion.variantIndex >=
            updatedQuestion.questionVariants.length
          ) {
            updatedQuestion.variantIndex =
              updatedQuestion.questionVariants.length - 1;
          }
          return updatedQuestion;
        }
        return question;
      });
    });
  };

  const handleAddQuestion = () => {
    setQuestions((prev) => {
      if (Array.isArray(prev)) {
        return [...prev, newQuestionObj];
      } else {
        return [newQuestionObj];
      }
    });
  };

  const handleBotFieldDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(questions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setQuestions(items);
  };

  useEffect(() => {
    const currentNode = nodes.filter((it) => it.id === userId);
    if (currentNode[0]?.fieldData?.fieldsData) {
      const fields = currentNode?.[0]?.fieldData?.fieldsData;
      setQuestions(fields);
    } else {
      setQuestions([newQuestionObj]);
    }
    if (currentNode?.[0]?.fieldData?.title) {
      setTitle(currentNode?.[0]?.fieldData?.title);
    }
    setLoading(false);
  }, [userId, nodes]);

  return (
    <Card style={styles.customCard}>
      {!loading && (
        <form>
          <Header
            nodes={nodes}
            handleClose={handleClose}
            handleCheck={handleCheck}
            title={title}
            setTitle={setTitle}
            titleError={titleError}
            titleCheckFlag={titleCheckFlag}
            setTitleCheckFlag={setTitleCheckFlag}
          />
          <DragDropContext onDragEnd={handleBotFieldDragEnd}>
            <Droppable droppableId="botFields">
              {(provided) => (
                <>
                  <Box
                    sx={styles.responseSection}
                    className="response"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <Box sx={styles.responseDiv}>
                      {questions.length > 0 &&
                        questions.map((question, index) => (
                          <Draggable
                            key={questions.id}
                            draggableId={question.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                key={question.key}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Box
                                  sx={{ position: "relative", right: "15px" }}
                                >
                                  <DeleteAndSwap
                                    setBotData={setQuestions}
                                    index={index}
                                  />
                                </Box>
                                <Box sx={styles.innerBox} key={question.id}>
                                  <Box sx={styles.labelBox}>
                                    <Typography
                                      variant="span"
                                      sx={{
                                        ...styles.labels,
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      Question
                                    </Typography>
                                    {question?.questionVariants &&
                                      question.questionVariants.map(
                                        (variant, index) => (
                                          <IconButton
                                            key={index}
                                            sx={{
                                              ...styles.labelIcon,
                                              backgroundColor:
                                                question.variantIndex === index
                                                  ? "#006cff"
                                                  : "#dcdcdc",
                                            }}
                                            onClick={() =>
                                              handleToggleVariant(
                                                question.id,
                                                index
                                              )
                                            }
                                          >
                                            {index + 1}
                                          </IconButton>
                                        )
                                      )}
                                    {question?.questionVariants &&
                                      question?.questionVariants.length < 5 && (
                                        <IconButton
                                          onClick={() =>
                                            addVariants(question.id)
                                          }
                                          sx={{
                                            ...styles.labelIcon,
                                            backgroundColor: "#006cff",
                                            fontSize: "20px",
                                          }}
                                        >
                                          +
                                        </IconButton>
                                      )}
                                  </Box>
                                  <Box sx={styles.textFieldBox}>
                                    <TextField
                                      fullWidth
                                      value={
                                        question?.questionVariants?.[
                                          question?.variantIndex
                                        ]?.response
                                      }
                                      variant="standard"
                                      sx={styles.questionTextfield}
                                      onChange={(e) =>
                                        handleInput(
                                          e,
                                          question.id,
                                          question.variantIndex
                                        )
                                      }
                                      placeholder="Enter your message..."
                                      inputProps={{
                                        maxLength: 256,
                                      }}
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                    />
                                    {question?.questionVariants &&
                                      question?.questionVariants.length !==
                                        1 && (
                                        <IconButton sx={styles.variantDelete}>
                                          <DeleteOutlineOutlinedIcon
                                            onClick={() =>
                                              handleDeleteVariant(
                                                question.id,
                                                question.variantIndex
                                              )
                                            }
                                            sx={{
                                              color: "#008aff",
                                              "&:hover": {
                                                color: "#fff",
                                              },
                                            }}
                                          />
                                        </IconButton>
                                      )}
                                  </Box>
                                  <Box sx={styles.innerBox}>
                                    <Typography
                                      variant="span"
                                      sx={styles.labels}
                                    >
                                      Validate response with entity
                                    </Typography>

                                    <TextField
                                      size="small"
                                      sx={styles.optionsInput}
                                      onClick={(e) =>
                                        handleClickPopover(e, question.id)
                                      }
                                      placeholder="Select reply type"
                                      value={question.validateEntity}
                                      fullWidth
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <ArrowDropDownIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                    <ValidateResponsePopover
                                      optionsAnchorEl={optionsAnchorEl}
                                      questionId={question.id}
                                      questions={questions}
                                      setQuestions={setQuestions}
                                      setOptionsAnchorEl={setOptionsAnchorEl}
                                      handleClickPopover={handleClickPopover}
                                      handleClosePopover={handleClickPopover}
                                    />
                                  </Box>
                                  <Box sx={styles.innerBox}>
                                    <Typography
                                      variant="span"
                                      sx={styles.labels}
                                    >
                                      Save response to attribute
                                    </Typography>
                                    <TextField
                                      size="small"
                                      sx={styles.optionsInput}
                                      onClick={(e) =>
                                        handleSaveClickPopover(e, question.id)
                                      }
                                      placeholder="Choose attribute type"
                                      value={question.saveAttribute}
                                      fullWidth
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <ArrowDropDownIcon />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                    <SaveResponsePopover
                                      saveOptionsAnchorEl={saveOptionsAnchorEl}
                                      questionId={question.id}
                                      questions={questions}
                                      setQuestions={setQuestions}
                                      setSaveOptionsAnchorEl={
                                        setSaveOptionsAnchorEl
                                      }
                                    />
                                  </Box>
                                  <AskUntil
                                    questionId={question.id}
                                    questions={questions}
                                    setQuestions={setQuestions}
                                    value={question.askUntil}
                                  />
                                </Box>
                              </Box>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </Box>
                    {questions.length < 11 && (
                      <AddQuestion handleAddQuestion={handleAddQuestion} />
                    )}
                  </Box>
                  <DialogBox
                    setOpen={setOpen}
                    open={open}
                    handleCloseDialog={handleCloseDialog}
                    closeSidebar={closeSidebar}
                  />
                </>
              )}
            </Droppable>
          </DragDropContext>
        </form>
      )}
    </Card>
  );
};

export default SidebarQuestion;
