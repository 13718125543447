import { Box, Grid, InputAdornment, TextField, useTheme } from "@mui/material";
import React, { forwardRef, useImperativeHandle } from "react";
import {
  PrimaryLabel,
  PrimaryLabelSmall,
} from "../../../../../helper/utility/Labels";
import CustomTextFieldNoBorder from "../../../../../components/CustomTextFieldNoBorder";
import CustomField from "../../../../../helper/utility/CustomField";
import { ADD_PLAN_TOOLTIPS } from "../../../constants";
import { useSelector } from "react-redux";
import { currencySymbols } from "../../../../pricing/Gold";
import { validatePrimaryPlanDetails } from "../helper/validations";

const PrimaryPlanDetails = forwardRef(({ form, editForm, errors }, ref) => {
  const theme = useTheme();
  let mainBackgroundColor = theme.palette.background?.paper;
  const default_currency = useSelector(
    (state) => state.settings.productSettings
  )?.default_currency;

  useImperativeHandle(ref, () => {
    return {
      validateForm() {
        let errors = validatePrimaryPlanDetails({ form });
        return errors;
      },
    };
  });

  return (
    <Box>
      <PrimaryLabel>Plan Details</PrimaryLabel>
      <CustomTextFieldNoBorder
        value={form?.name}
        onChange={(e) => editForm({ name: e?.target?.value })}
        sx={{ mt: 3 }}
        placeholder={"Plan name..."}
        helperText={errors?.name}
        error={Boolean(errors?.name)}
      />

      <Grid container spacing={5} rowSpacing={5}>
        <Grid item xs={12} md={3}>
          <CustomField
            value={form?.sku}
            onChange={(e) => editForm({ sku: e })}
            label="Plan code"
            textFieldProps={{
              placeholder: "Enter code",
              helperText: errors?.sku,
              error: Boolean(errors?.sku),
            }}
            type="text"
            backgroundColor={mainBackgroundColor}
            tooltip={ADD_PLAN_TOOLTIPS.plan_code}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomField
            value={form?.digi_plan_duration}
            onChange={(e) => editForm({ digi_plan_duration: e })}
            label="Total plan duration"
            textFieldProps={{
              placeholder: `Enter ${form?.duration_period || "value"}`,
              helperText: errors?.title,
              error: Boolean(errors?.title),
              type: "number",
              onInput: (e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 3);
              },
            }}
            type="text_dropdown"
            backgroundColor={mainBackgroundColor}
            suffixProps={{
              value: form.duration_period,
              onChange: (e) => editForm({ duration_period: e }),
              options: [
                { label: "Days", value: "days" },
                { label: "Months", value: "months" },
                { label: "Years", value: "years" },
              ],
            }}
            tooltip={ADD_PLAN_TOOLTIPS.plan_duration}
          />
        </Grid>

        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={3}>
          <CustomField
            value={form?.plan_type}
            onChange={(e) => editForm({ plan_type: e })}
            label="Plan Type"
            type="button_group"
            options={[
              { label: "Amount", value: "amount" },
              { label: "Grams", value: "grams" },
            ]}
            backgroundColor={mainBackgroundColor}
            tooltip={ADD_PLAN_TOOLTIPS.plan_type}
          />
        </Grid>
        {/* <Grid item xs={12} md={6} /> */}
        <Grid item xs={12} md={6}>
          {/* <CustomField
            // value={form?.plan_amount}
            // onChange={(e) => editForm({ plan_amount: e })}
            label="Total plan amount"
            type="dropdown"
            value={form.plan_amount_type}
            onChange={(e) => editForm({ plan_amount_type: e })}
            backgroundColor={mainBackgroundColor}
            prefixProps={{}}
            options={[
              { label: "Fixed", value: "fixed" },
              { label: "Unlimited", value: "unlimited" },
              { label: "Range", value: "range" },
            ]}

            // tooltip={"Specify a name for the field"}
          /> */}
          {form?.plan_type === "grams" ? (
            <CustomField
              value={form?.gold_weight}
              onChange={(e) => editForm({ gold_weight: e?.slice(0, 7) })}
              label="Total gold weight"
              textFieldProps={{
                type: "number",
                label:
                  form.plan_amount_type === "unlimited"
                    ? "Enter maximum weight"
                    : "Enter weight",
                // placeholder: "Enter amount",
                helperText: errors?.title,
                error: Boolean(errors?.title),
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">grams</InputAdornment>
                  ),
                },
              }}
              type="text_dropdown"
              backgroundColor={mainBackgroundColor}
              prefixProps={{
                value: form.plan_amount_type,
                onChange: (e) => editForm({ plan_amount_type: e }),
                options: [
                  { label: "Fixed", value: "fixed" },
                  { label: "Unlimited", value: "unlimited" },
                  // { label: "Range", value: "range" },
                ],
              }}
              tooltip={ADD_PLAN_TOOLTIPS.plan_amount}
              // tooltip={"Specify a name for the field"}
            />
          ) : (
            <CustomField
              value={form?.plan_amount}
              onChange={(e) => editForm({ plan_amount: e })}
              label="Total plan amount"
              textFieldProps={{
                label:
                  form.plan_amount_type === "unlimited"
                    ? "Enter maximum amount"
                    : "Enter amount",
                // placeholder: "Enter amount",
                helperText: errors?.plan_amount,
                error: Boolean(errors?.plan_amount),
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      {currencySymbols[default_currency]}
                    </InputAdornment>
                  ),
                },
              }}
              type="text_dropdown"
              backgroundColor={mainBackgroundColor}
              prefixProps={{
                value: form.plan_amount_type,
                onChange: (e) => editForm({ plan_amount_type: e }),
                options: [
                  { label: "Fixed", value: "fixed" },
                  { label: "Unlimited", value: "unlimited" },
                  // { label: "Range", value: "range" },
                ],
              }}
              tooltip={ADD_PLAN_TOOLTIPS.plan_amount}
              // tooltip={"Specify a name for the field"}
            />
          )}
        </Grid>
        {/* {form?.plan_amount_type !== "range" && (
          <Grid item xs={12} md={3} sx={{ mt: "auto" }}>
            <CustomField
              value={form?.plan_amount}
              onChange={(e) => editForm({ plan_amount: e })}
              label="Total plan amount"
              backgroundColor={mainBackgroundColor}
              textFieldProps={{
                type: "number",
                onInput: (e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 9);
                },
              }}
            />
          </Grid>
        )}
        {form?.plan_amount_type === "range" && (
          <Grid item xs={12} md={6} sx={{ mt: "auto" }}>
            <CustomField
              label=""
              type="text_range"
              backgroundColor={mainBackgroundColor}
              prefixProps={{
                value: form.min_plan_amount,
                onChange: (e) => editForm({ min_plan_amount: e }),
                textFieldProps: {
                  type: "number",
                  placeholder: "Enter min amount",
                  onInput: (e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 9);
                  },
                },
              }}
              suffixProps={{
                value: form.max_plan_amount,
                onChange: (e) => editForm({ max_plan_amount: e }),
                textFieldProps: {
                  type: "number",
                  placeholder: "Enter max amount",
                  onInput: (e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 9);
                  },
                },
              }}
              // tooltip={"Specify a name for the field"}
            />
          </Grid>
        )} */}
        {/* <Grid item xs={12} md={6} /> */}
        <Grid item xs={12} md={6}>
          <CustomField
            label="Limit per transactions"
            textFieldProps={{
              helperText: errors?.transaction_limit,
              error: Boolean(errors?.transaction_limit),
            }}
            type="text_range"
            backgroundColor={mainBackgroundColor}
            prefixProps={{
              value: form.plan_minimum,
              onChange: (e) => editForm({ plan_minimum: e }),
              textFieldProps: {
                type: "number",
                // label: "Enter min amount",

                onInput: (e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 9);
                },
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      {currencySymbols[default_currency]}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">Min</InputAdornment>
                  ),
                },
              },
            }}
            suffixProps={{
              value: form.plan_maximum,
              onChange: (e) => editForm({ plan_maximum: e }),
              textFieldProps: {
                type: "number",
                // label: "Enter max amount",
                onInput: (e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 9);
                },
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      {currencySymbols[default_currency]}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">Max</InputAdornment>
                  ),
                },
              },
            }}
            tooltip={ADD_PLAN_TOOLTIPS.transaction_limit}
            // tooltip={"Specify a name for the field"}
          />
        </Grid>
      </Grid>
    </Box>
  );
});

export default PrimaryPlanDetails;
