const BUSINESS_SETTINGS = {
  types: [
    true,
    true,
    {
      label: "Gold",
      value: "gold",
    },
    {
      label: "Diamond Jewellery",
      value: "diamond",
    },
  ],
  gold_purities: [
    {
      label: "24KT (999.99)",
      value: "999.99",
    },
    {
      label: "24KT (999)",
      value: "999",
    },
    {
      label: "24KT (995)",
      value: "995",
    },
  ],
  silver_purities: [],
  platinum_purities: [],
  colors: [
    {
      label: "White",
      value: "white",
    },
    {
      label: "Yellow",
      value: "yellow",
    },
    {
      label: "Rose",
      value: "rose",
    },
  ],
  net_weight: "auto",
  auto_diamond_sieve: "",
  collections: [],
  categories: [],
  diamond_types: [
    {
      label: "Natural",
      value: "natural",
    },
  ],
  diamond_clarities: [
    {
      label: "VVS",
      value: "VVS",
    },
    {
      label: "VS",
      value: "VS",
    },
    {
      label: "SI",
      value: "SI",
    },
  ],
  diamond_cuts: [
    {
      label: "Very Good",
      value: "very_good",
    },
  ],
  diamond_shapes: [
    {
      label: "Round",
      value: "round",
    },
    {
      label: "Pear",
      value: "pear",
    },
  ],
  diamond_colors: [
    {
      label: "F",
      value: "F",
    },
    {
      label: "G",
      value: "G",
    },
    {
      label: "I",
      value: "I",
    },
  ],
  diamond_sizes: [],
  currency: [
    {
      label: "INR",
      value: "INR",
      symbol: "₹",
      name: "Indian Rupee",
    },
  ],
  default_currency: "INR",
  gemstone_qualities: [],
  gemstone_shapes: [],
  gemstone_types: [],
  diamond_labs: [
    {
      label: "IGI",
      value: "IGI",
    },
  ],
};

const DIMAOND_GROUP = {
  "1671627657_LhurM": {
    id: "1671627657_LhurM",
    fromObj: {
      wt: "0.01",
      size: "1.30",
      sieve: "+2.5-3",
    },
    toObj: {
      wt: "0.20",
      size: "3.70",
      sieve: "15.75-16.50",
    },
    title: "1 cent to 0.20 cents - Round",
    shape: "round",
    from: "0.01",
    to: "0.20",
  },
  "1671627672_0ytnJ": {
    id: "1671627672_0ytnJ",
    fromObj: {
      wt: "0.01",
    },
    toObj: {
      wt: "8.05",
      size: "",
    },
    title: "Princess",
    shape: "princess",
    from: "0.01",
    to: "8.05",
  },
  "1671627691_SXhR2": {
    id: "1671627691_SXhR2",
    fromObj: {
      wt: "0.01",
    },
    toObj: {
      wt: "8.05",
    },
    title: "Marquise",
    shape: "marquise",
    from: "0.01",
    to: "8.05",
  },
  "1671627705_C8cbl": {
    id: "1671627705_C8cbl",
    fromObj: {
      wt: "0.01",
    },
    toObj: {
      wt: 0.25,
    },
    title: "1 cent to 0.25 cents - Pear",
    shape: "pear",
    from: "0.01",
    to: "0.25",
  },
  "1671627718_50lvN": {
    id: "1671627718_50lvN",
    fromObj: {
      wt: "0.01",
    },
    toObj: {
      wt: "8.05",
    },
    title: "Oval",
    shape: "oval",
    from: "0.01",
    to: "8.05",
  },
};

const MASTER_PRICING = {
  gold_pricing: {
    INR: {
      default: "999",
      automatic: {
        375: {
          base: 2065,
          margin: "375",
          rate: 2065,
        },
        583: {
          base: 3210,
          margin: "583",
          rate: 3210,
        },
        750: {
          base: 4130,
          margin: "750",
          rate: 4130,
        },
        916: {
          base: 5044,
          margin: "916",
          rate: 5044,
        },
        995: {
          base: 5479,
          margin: "995",
          rate: 5479,
        },
        999: {
          base: "5501",
          margin: 999,
          rate: 5501,
          default: true,
        },
        999.99: {
          base: 5506,
          margin: "999.99",
          rate: 5506,
        },
      },
      manual: {
        375: {
          base: "",
          rate: "",
        },
        583: {
          base: "",
          rate: "",
        },
        750: {
          base: "",
          rate: "",
        },
        916: {
          base: "",
          rate: "",
        },
        995: {
          base: "",
          rate: "",
        },
        999: {
          base: "",
          rate: "",
        },
        999.99: {
          base: "",
          rate: "",
        },
      },
      enabled_purities: {
        995: true,
        999: true,
        999.99: true,
      },
    },
    from: "gross",
    type: "automatic",
  },
  silver_pricing: {
    INR: {
      default: "999",
      automatic: {
        650: {
          base: "",
          margin: 650,
          rate: "",
        },
        925: {
          base: 66,
          margin: 925,
          rate: 66,
        },
        995: {
          base: 71,
          margin: 995,
          rate: 71,
        },
        999: {
          base: "70.90",
          margin: 999,
          rate: 71,
          default: true,
        },
      },
      manual: {
        650: {
          base: "",
          rate: "",
        },
        925: {
          base: "",
          rate: "",
        },
        995: {
          base: "",
          rate: "",
        },
        999: {
          base: "",
          rate: "",
        },
      },
      enabled_purities: [],
    },
    from: "gross",
    type: "automatic",
  },
  platinum_pricing: {
    INR: {
      default: "950",
      automatic: {
        850: {
          base: 3092,
          margin: 850,
          rate: 3092,
        },
        900: {
          base: 3274,
          margin: 965,
          rate: 3510,
        },
        950: {
          base: "3456",
          margin: 950,
          rate: 3456,
          default: true,
        },
      },
      manual: {
        850: {
          base: "",
          rate: "",
        },
        900: {
          base: "",
          rate: "",
        },
        950: {
          base: "",
          rate: "",
        },
      },
      enabled_purities: [],
    },
    from: "gross",
    type: "automatic",
  },
  labour_pricing: {
    INR: [
      {
        id: 1671626787,
        collections: [
          {
            id: 205,
            label: "Antique Jewellery",
            value: "antique-jewellery",
            count: 0,
          },
          {
            id: 12,
            label: "Diamond Jewellery",
            value: "diamond-jewellery",
            count: 4570,
          },
          {
            id: 15,
            label: "Gemstone Jewellery",
            value: "gemstone-jewellery",
            count: 0,
          },
          {
            id: 11,
            label: "Gold Jewellery",
            value: "gold-jewellery",
            count: 0,
          },
          {
            id: 406,
            label: "Ruby Collections",
            value: "ruby-collections",
            count: 0,
          },
        ],
        categories: [
          {
            id: 230,
            label: "Bangles",
            value: "bangles",
            count: 80,
          },
          {
            id: 434,
            label: "Mangalsutra",
            value: "mangalsutra",
            count: 166,
          },
          {
            id: 98,
            label: "Pendants",
            value: "pendants",
            count: 1064,
          },
          {
            id: 5,
            label: "Necklaces",
            value: "necklaces",
            count: 24,
          },
          {
            id: 100,
            label: "Nose Pins",
            value: "nose-pins",
            count: 309,
          },
          {
            id: 10,
            label: "Gents Ring",
            value: "gents-ring",
            count: 408,
          },
          {
            id: 9,
            label: "Ladies Ring",
            value: "ladies-ring",
            count: 995,
          },
          {
            id: 8,
            label: "Bracelet",
            value: "bracelet",
            count: 112,
          },
          {
            id: 2,
            label: "Earrings",
            value: "earrings",
            count: 1149,
          },
        ],
        subcategories: [],
        labour_from: "gross",
        wastage: "5",
        wastage_from: "weight",
        per_gram: "700",
        making_from: "labour",
        minimum_making: "1500",
        title: "For All",
      },
    ],
  },
  diamond_pricing: {
    INR: [
      {
        id: 1671627798,
        clarities: [
          {
            label: "VVS",
            value: "VVS",
          },
          {
            label: "VS",
            value: "VS",
          },
          {
            label: "SI",
            value: "SI",
          },
        ],
        colors: [
          {
            label: "DE",
            value: "DE",
          },
          {
            label: "EF",
            value: "EF",
          },
          {
            label: "FG",
            value: "FG",
          },
        ],
        shapes: [
          {
            value: "round",
            label: "Round",
          },
          {
            value: "princess",
            label: "Princess",
          },
          {
            value: "marquise",
            label: "Marquise",
          },
          {
            value: "pear",
            label: "Pear",
          },
          {
            value: "oval",
            label: "Oval",
          },
        ],
        sieves: [
          {
            label: "Round",
            value: "1671627657_LhurM",
          },
          {
            label: "Princess",
            value: "1671627672_0ytnJ",
          },
          {
            label: "Marquise",
            value: "1671627691_SXhR2",
          },
          {
            label: "Pear",
            value: "1671627705_C8cbl",
          },
          {
            label: "Oval",
            value: "1671627718_50lvN",
          },
        ],
        sieve_from: "",
        sieve_to: "",
        sieve_type: "",
        cuts: [
          {
            label: "Excellent",
            value: "excellent",
          },
        ],
        types: [
          {
            label: "Natural",
            value: "natural",
          },
          {
            label: "Lab Grown",
            value: "lab_grown",
          },
        ],
        rate: "80000",
        title: "Diamond Combination #1",
      },
    ],
  },
  gemstone_pricing: {
    INR: [
      {
        id: 1670237641,
        title: "Gemstone Combination #1",
        qualities: [
          {
            label: "Precious",
            value: "precious",
          },
          {
            label: "Semi precious",
            value: "semi-precious",
          },
          {
            label: "Polki",
            value: "polki",
          },
          {
            label: "Kundan",
            value: "kundan",
          },
          {
            label: "Uncut",
            value: "uncut",
          },
          {
            label: "Hydro",
            value: "hydro",
          },
        ],
        types: [
          {
            label: "Sapphire",
            value: "sapphire",
          },
          {
            label: "Pearl",
            value: "pearl",
          },
          {
            label: "Ruby",
            value: "ruby",
          },
          {
            label: "Blue sapphire",
            value: "blue-sapphire",
          },
          {
            label: "Amethyst",
            value: "amethyst",
          },
          {
            label: "Beads",
            value: "beads",
          },
          {
            label: "Black beeds",
            value: "black-beeds",
          },
          {
            label: "Black diamond",
            value: "black-diamond",
          },
          {
            label: "Blue sapphire-beads",
            value: "blue-sapphire-beads",
          },
          {
            label: "Emerald",
            value: "emerald",
          },
          {
            label: "Real emerald",
            value: "real-emerald",
          },
        ],
        shapes: [
          {
            label: "Round",
            value: "round",
          },
          {
            label: "Square",
            value: "square",
          },
          {
            label: "Oval",
            value: "oval",
          },
        ],
        sizes: [
          {
            label: "11",
            value: "11",
          },
          {
            label: "9",
            value: "9",
          },
          {
            label: "4",
            value: "4",
          },
        ],
        rate: "10000",
      },
    ],
    USD: [],
    EUR: [],
    GBP: [],
    AUD: [],
    AED: [],
    SGD: [],
    SAR: [],
    ZAR: [],
    CAD: [],
    JPY: [],
    CNY: [],
    VND: [],
    THB: [],
    KWD: [],
  },
  additional_pricing: {
    hallmark: [],
    certificate: [],
    rhodium: [],
  },
  last_updated: 1681488922,
  margin_pricing: {
    INR: [],
    USD: [],
    EUR: [],
    GBP: [],
    AUD: [],
    AED: [],
    SGD: [],
    SAR: [],
    ZAR: [],
    CAD: [],
    JPY: [],
    CNY: [],
    VND: [],
    THB: [],
    KWD: [],
  },
};

const SIZE_MASTERS_NEW = {
  "gents-ring": {
    label: "gents-ring",
    size_guide: "rings-size-guide",
    sizes: [
      {
        label: "7",
        enable: true,
      },
      {
        label: "8",
        enable: true,
      },
      {
        label: "9",
        enable: true,
      },
      {
        label: "10",
        enable: true,
      },
      {
        label: "11",
        enable: true,
      },
      {
        label: "12",
        enable: true,
      },
      {
        label: "13",
        enable: true,
      },
      {
        label: "14",
        enable: true,
      },
      {
        label: "15",
        enable: true,
      },
      {
        label: "16",
        enable: true,
      },
      {
        label: "17",
        enable: true,
      },
      {
        label: "18",
        enable: true,
      },
      {
        label: "19",
        enable: true,
      },
      {
        label: "20",
        enable: true,
      },
      {
        label: "21",
        enable: true,
      },
      {
        label: "22",
        enable: true,
      },
      {
        label: "23",
        enable: true,
      },
      {
        label: "24",
        enable: true,
      },
      {
        label: "25",
        enable: true,
      },
      {
        label: "26",
        enable: true,
      },
      {
        label: "27",
        enable: true,
      },
      {
        label: "28",
        enable: true,
      },
      {
        label: "29",
        enable: true,
      },
      {
        label: "30",
        enable: true,
      },
    ],
    difference_by: "amount",
    increase_difference: "0",
    decrease_difference: "0",
    deleted: false,
  },
  "ladies-ring": {
    label: "ladies-ring",
    size_guide: "rings-size-guide",
    sizes: [
      {
        label: "5",
        enable: true,
      },
      {
        label: "6",
        enable: true,
      },
      {
        label: "7",
        enable: true,
      },
      {
        label: "8",
        enable: true,
      },
      {
        label: "9",
        enable: true,
      },
      {
        label: "10",
        enable: true,
      },
      {
        label: "11",
        enable: true,
      },
      {
        label: "12",
        enable: true,
      },
      {
        label: "13",
        enable: true,
      },
      {
        label: "14",
        enable: true,
      },
      {
        label: "15",
        enable: true,
      },
      {
        label: "16",
        enable: true,
      },
      {
        label: "17",
        enable: true,
      },
      {
        label: "18",
        enable: true,
      },
      {
        label: "19",
        enable: true,
      },
      {
        label: "20",
        enable: true,
      },
    ],
    difference_by: "amount",
    increase_difference: "0",
    decrease_difference: "0",
    deleted: false,
  },
  bangles: {
    label: "bangles",
    size_guide: "bangles-size-guide",
    sizes: [
      {
        label: "2.02",
        enable: true,
      },
      {
        label: "2.04",
        enable: true,
      },
      {
        label: "2.06",
        enable: true,
      },
      {
        label: "2.08",
        enable: true,
      },
      {
        label: "2.10",
        enable: true,
      },
      {
        label: "2.12",
        enable: true,
      },
      {
        label: "2.14",
        enable: true,
      },
      {
        label: "2.16",
        enable: true,
      },
    ],
    difference_by: "amount",
    increase_difference: "0",
    decrease_difference: "0",
    deleted: false,
  },
  bracelet: {
    label: "bracelet",
    size_guide: "bangles-size-guide",
    sizes: [
      {
        label: "2.02",
        enable: true,
      },
      {
        label: "2.04",
        enable: true,
      },
      {
        label: "2.06",
        enable: true,
      },
      {
        label: "2.08",
        enable: true,
      },
      {
        label: "2.10",
        enable: true,
      },
      {
        label: "2.12",
        enable: true,
      },
      {
        label: "2.14",
        enable: true,
      },
      {
        label: "2.16",
        enable: true,
      },
    ],
    difference_by: "amount",
    increase_difference: "0",
    decrease_difference: "0",
    deleted: false,
  },
};

export { BUSINESS_SETTINGS, DIMAOND_GROUP, MASTER_PRICING, SIZE_MASTERS_NEW };
