import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography, Box, InputLabel, Select, MenuItem } from "@mui/material";
import * as actions from "../../../../../redux/actions/flowsActions";
import InfoIcon from "@mui/icons-material/Info";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const options = [
  { value: "webchat", label: "Webchat" },
  { value: "triggers", label: "Triggers" },
];

const SelectDialog = ({ openSelectDialog, setOpenSelectDialog }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options?.[0]?.label);
  const dispatch = useDispatch();
  const handleCloseDialog = () => {
    setOpenSelectDialog(false);
  };

  const handleOptionChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
  };

  const handleShowInfo = () => {
    setShowInfo(!showInfo);
  };

  const handleContinue = (value) => {
    if (value) {
      handleCloseDialog();
    }
    const payloadData = { intialLoad: false, type: value };
    dispatch({ type: actions.EDIT_ELEMENT_IN_REDUX, payload: payloadData });
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openSelectDialog}
        onClose={handleCloseDialog}
        disableBackdropClick
      >
        <DialogTitle
          sx={{
            fontWeight: 600,
            fontFamily: "Inter",
            borderBottom: "1px solid #dbe1e8",
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          Select the type of the flow
        </DialogTitle>
        <DialogContent>
          <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
            <InputLabel>Select the type of flow</InputLabel>
            <Select
              size="small"
              sx={{ width: "60%", margin: "10px 0px 10px 0px" }}
              onChange={handleOptionChange}
            >
              {options &&
                options.map((option, index) => (
                  <MenuItem key={index} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
            </Select>
          </Box>
          <Box
            onClick={handleShowInfo}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {!showInfo ? (
              <>
                <InfoIcon fontSize="small" />
                <Typography
                  sx={{ font: "Inter", fontSize: "13px", marginLeft: "3px" }}
                >
                  Show info
                </Typography>
              </>
            ) : (
              <>
                <ExpandLessIcon fontSize="small" />
                <Typography
                  sx={{ font: "Inter", fontSize: "13px", marginLeft: "3px" }}
                >
                  Show less
                </Typography>
              </>
            )}
          </Box>
          {showInfo && (
            <>
              <DialogContentText
                id="alert-dialog-description"
                sx={{
                  fontFamily: "Inter",
                  color: "#516173",
                  marginTop: "20px",
                }}
              >
                Are you sure you want to leave without saving the changes?
              </DialogContentText>
              <DialogContentText
                id="alert-dialog-description"
                sx={{
                  fontFamily: "Inter",
                  color: "#516173",
                  marginTop: "20px",
                }}
              >
                Are you sure you want to leave without saving the changes?
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            marginLeft: "17px",
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            // justifyContent: "center",
            marginBottom: "15px",
          }}
        >
          {/* <Button
            sx={{
              fontFamily: "Inter",
              backgroundColor: "#fff",
              border: "1px solid #dbe1e8",
              textTransform: "none",
              color: "#516173",
              "&:hover": {
                border: "1px solid #006cff",
                backgroundColor: "#fff",
                transition: "all .3s",
              },
            }}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button> */}
          <Button
            sx={{
              width: "20%",
              fontFamily: "Inter",
              backgroundColor: "#006cff",
              border: "1px solid #006cff",
              textTransform: "none",
              color: "#fff",
              "&:hover": {
                border: "1px solid #006cff",
                backgroundColor: "#6295f5",
                transition: "0.3s ease",
              },
            }}
            onClick={handleContinue}
            autoFocus
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectDialog;
