import { useRef } from "react";
import { Box } from "@mui/material";
import UploadFile from "../helper/const UploadFile";
import FileDisplayCard from "../helper/FileDisplayCard";
import { uploadDataToAwsBucket } from "../../sidebar-bot/utils/uploadDataToAwsBucket";
import BodyField from "../helper/BodyField";
import ButtonsField from "../helper/ButtonsField";
import TextComponent from "../helper/TextComponent";

const PreviewTemplate = ({
  templateDetails,
  currentTemplateState,
  setCurrentTemplateState,
  optionSelected,
  setOptionSelected,
  attributesCount,
  setAttributesCount,
  anchorEl,
  setAnchorEl,
  attributesRef,
  titleAttributesCount,
  setTitleAttributesCount,
  titleAttributes,
  setTitleAttributes,
  titleAttributesRef,
  titleAnchorEl,
  setTitleAnchorEl,
  mediaNotAdded,
  setMediaNotAdded,
}) => {
  const fileInputRef = useRef(null);
  const handleIconClick = () => {
    fileInputRef.current.click();
  };
  const handleFileSelect = async (e, index, fileType) => {
    const uploadedFile = e.target.files[0];
    const formData = new FormData();
    formData.append("file", uploadedFile);
    const res = await uploadDataToAwsBucket({ formData });
    const fileURL = res.data.source_url;
    const updatedComponents = [...currentTemplateState.components];
    updatedComponents[index] = {
      ...updatedComponents[index],
      file_url: {
        type: fileType,
        url: fileURL,
      },
    };

    setCurrentTemplateState((prevData) => ({
      ...prevData,
      components: updatedComponents,
    }));
    setMediaNotAdded(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: "20px",
        paddingBottom: "20px",
        width: "75%",
      }}
    >
      {currentTemplateState &&
        currentTemplateState?.components?.length > 0 &&
        currentTemplateState?.components?.map((component, index) => (
          <>
            {component?.type === "HEADER" &&
              (component?.format === "TEXT" ? (
                <TextComponent
                  key={`text-${index}`}
                  component={component}
                  index={index}
                  currentTemplateState={currentTemplateState}
                  setCurrentTemplateState={setCurrentTemplateState}
                  titleAttributesCount={titleAttributesCount}
                  setTitleAttributesCount={setTitleAttributesCount}
                  titleAttributes={titleAttributes}
                  setTitleAttributes={setTitleAttributes}
                  titleAttributesRef={titleAttributesRef}
                  titleAnchorEl={titleAnchorEl}
                  setTitleAnchorEl={setTitleAnchorEl}
                />
              ) : !component?.file_url ? (
                <UploadFile
                  componentType={component.format}
                  handleIconClick={handleIconClick}
                  handleFileSelect={handleFileSelect}
                  fileInputRef={fileInputRef}
                  component={component}
                  index={index}
                  mediaNotAdded={mediaNotAdded}
                />
              ) : (
                <FileDisplayCard
                  componentType={component.format}
                  handleIconClick={handleIconClick}
                  handleFileSelect={handleFileSelect}
                  fileInputRef={fileInputRef}
                  component={component}
                  index={index}
                />
              ))}

            {component?.type === "BODY" && (
              <BodyField
                key={`body-${index}`}
                component={component}
                index={index}
                currentTemplateState={currentTemplateState}
                setCurrentTemplateState={setCurrentTemplateState}
                optionSelected={optionSelected}
                setOptionSelected={setOptionSelected}
                attributesCount={attributesCount}
                setAttributesCount={setAttributesCount}
                attributesRef={attributesRef}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
              />
            )}

            {component?.type === "FOOTER" && (
              <span
                style={{
                  padding: "5px 0px 15px 8px",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontWeight: "600",
                  color: "grey",
                  borderBottom: "1px solid #000",
                  marginBottom: "10px",
                }}
              >
                {component.text}
              </span>
            )}

            {component?.type === "BUTTONS" &&
              component?.buttons.map((button, buttonIndex) => (
                <ButtonsField
                  component={component}
                  index={index}
                  buttonIndex={buttonIndex}
                  key={buttonIndex}
                  button={button}
                  currentTemplateState={currentTemplateState}
                  setCurrentTemplateState={setCurrentTemplateState}
                />
              ))}
          </>
        ))}
    </Box>
  );
};

export default PreviewTemplate;
