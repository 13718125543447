import { Box, Typography, CardMedia } from "@mui/material";
import phoneImg from "../../sidebar-sms/assets/mobile-svg.svg";
import "../styles/multiline-ellipsis.css";
const NotificationPreview = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        flex: 1,
        marginTop: "7px",
      }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 600,
          marginLeft: "60px",
          color: "#22272d",
          textDecoration: "underline",
        }}
      >
        Preview -
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "80px",
          backgroundImage: `url(${phoneImg})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          flex: 1,
        }}
      >
        <Box
          sx={{
            borderRadius: "15px",
            backgroundColor: "#fff",
            // maxWidth: "60%",
            width: "150px",
            padding: "0px 10px",
            "&:hover": {
              cursor: "pointer",
              userSelect: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "4px",
              padding: "10px 5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  maxWidth: "122px",
                  wordBreak: "break-word",
                }}
              >
                {data?.title}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  alignSelf: "flex-start",
                  fontSize: "10px",
                }}
              >
                now
              </Typography>
            </Box>
            <Typography
              className="message-field"
              sx={{
                fontSize: "11px",
                wordWrap: "break-word",
                maxWidth: "120px",
              }}
            >
              {data?.message}
            </Typography>
            {Boolean(data?.image) && (
              <CardMedia
                component="img"
                sx={{
                  height: "100px",
                  objectFit: "contain",
                }}
                image={data?.image}
                alt="Selected Image"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationPreview;
