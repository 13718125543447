import { useState, useEffect, useCallback, useRef } from "react";
import ReactFlow, { Controls, Background, applyNodeChanges } from "reactflow";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { types } from "./helper/nodeTypes";
import { styles } from "./helper/flowStyles";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Fade, Typography, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PanelComponent from "./components/PanelComponent";
import SidebarBot from "./sidebar-bot/index";
import SidebarUser from "./sidebar-user/index";
import SidebarUserAttachment from "./sidebar-user-attachment/index";
import SidebarQuestion from "./sidebar-questions";
import SidebarFilter from "./sidebar-filter";
import SidebarGoToStep from "./sidebar-go-to-step";
import SidebarWhatsappTemplate from "./sidebar-whatsapp-template";
// import Loader from "../../components/Loader";
// import LoadingScreenTemplate from "../../components/LoadingScreenTemplate";
import { fetchGlobalAttributes } from "./helper/fetchGlobalAttributes";
import SidebarProductCatalog from "./sidebar-product-catalog";
import SidebarEmail from "./sidebar-email";
import SidebarPushNotification from "./sidebar-push-notification";
import SidebarSms from "./sidebar-sms";
import ActionsBox from "./actions-sidebar";
import EditTitleDialog from "./components/EditTitleDialog";
import { setIntegrations } from "./helper/setIntegrations";
import { fetchElements } from "./helper/FetchElements";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { droppableNodeObj } from "./helper/droppableNodeObj";
import { addNode } from "./custom-nodes/helper/addNode";
import * as actions from "../../../../redux/actions/flowsActions";
import SidebarDelay from "./sidebar-delay";
import { register } from "swiper/element/bundle";
import {
  nodeCanBeAdded,
  nodeCanBeAddedErrorMessage,
} from "./custom-nodes/helper/nodeCanBeAdded";
import "reactflow/dist/style.css";
import "./public/css/main.css";
import SelectDialog from "./components/SelectDialog";
import SidebarCreateTask from "./sidebar-create-task";
import SidebarMetalRates from "./sidebar-metal-rates";
import SidebarAssignTeam from "./sidebar-assign-team";
import SidebarStart from "./sidebar-start";
import { fetchSingleSettings } from "../../../../redux/actions/settingActions";
import { updateNodePositions } from "../../../../redux/utils/flow/updateNodePositions";
import { EdgesensorHigh } from "@mui/icons-material";
import SidebarStartTrigger from "./sidebar-start-trigger";
import { fetchJweroTriggers } from "../../../../redux/actions/marketingActions";
import { isArrayWithValues, validateNumber } from "jwero-javascript-utils";
import { preDefinedAttributesTextField } from "./sidebar-bot/utils/preDefinedAttribuesTextField";
import Draggable from "react-draggable";
import { ALL_CUSTOMER_PREDEFINED_ATTRIBUTES } from "../../../../utils/crm";
import CustomConfirmationDialog from "../../../../helper/utility/CustomConfirmationDialog";

register();

export const DEFAULT_CREATE_TRIGGER_TITLE = "";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Flow = () => {
  const defaultViewport = { x: 200, y: 400, zoom: 1 };
  const nodeTypes = types;
  const { id } = useParams();
  const dispatch = useDispatch();
  const actionsMenu = useSelector((state) => state.flow?.actionsMenu);
  const isDragging = useSelector((state) => state.flow?.isDragging);
  const nodeDraggedToId = useSelector((state) => state.flow?.nodeDraggedToId);
  const sidebarOpenUser = useSelector((state) => state.flow?.openUserSidebar);
  const sidebarOpenBot = useSelector((state) => state.flow?.openBotSidebar);
  const chatbotType = useSelector((state) => state.flow?.chatbotType);
  const [viewport, setViewport] = useState(defaultViewport);

  const sidebarOpenUserAttachment = useSelector(
    (state) => state.flow?.openUserAttachmentSidebar
  );
  const sidebarOpenStart = useSelector((state) => state.flow?.openStartSidebar);
  const sidebarOpenQuestion = useSelector(
    (state) => state.flow?.openQuestionSidebar
  );
  const sidebarOpenFilter = useSelector(
    (state) => state.flow?.openFilterSidebar
  );
  const sidebarOpenGoToStep = useSelector(
    (state) => state.flow?.openGoToStepSidebar
  );
  const sidebarOpenWhatsappTemplate = useSelector(
    (state) => state.flow?.openWhatsappTemplateSidebar
  );
  const sidebarOpenProductCatalog = useSelector(
    (state) => state.flow?.openProductCatalogSidebar
  );
  const sidebarOpenEmail = useSelector((state) => state.flow?.openEmailSidebar);
  const sidebarOpenSms = useSelector((state) => state.flow?.openSmsSidebar);
  const sidebarOpenNotification = useSelector(
    (state) => state.flow?.openNotificationSidebar
  );
  const sidebarOpenDelay = useSelector((state) => state.flow?.openDelaySidebar);
  const sidebarOpenCreateTask = useSelector(
    (state) => state.flow?.openCreateTaskSidebar
  );
  const sidebarOpenMetalRates = useSelector(
    (state) => state.flow?.openMetalRatesSidebar
  );
  const sidebarOpenAssignTeam = useSelector(
    (state) => state.flow?.openAssignTeamSidebar
  );
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const title = useSelector((state) => state.flow?.title);
  const loading = useSelector((state) => state.flow?.loading);
  const mode = useSelector((state) => state.flow?.mode);
  const nodeAddPopover = useSelector((state) => state.flow?.nodeAddPopover);
  // nodeAddPopover;
  const [botData, setBotData] = useState([]);
  const [dragCancel, setDragCancel] = useState(false);
  const [openTitleDialog, setOpenTitleDialog] = useState(false);
  const [openSelectDialog, setOpenSelectDialog] = useState(null);
  const [checkSidebarOpen, setSidebarOpen] = useState(false);
  const flowSettings = useSelector((state) => state.settings?.flowSettings);
  const flowStyles = {
    ...styles,
    backgroundColor: mode === "dark" ? "#22304a" : "#e2e7ed",
  };
  const location = useLocation();
  const [flowType, setFlowType] = useState("");
  const triggersList = useSelector((state) => state?.marketing?.triggers);

  const globalAttributes = useSelector(
    (state) => state?.flow?.globalAttributes
  );
  const global_attributes = useSelector(
    (state) => state?.flow?.global_attributes
  );
  const all_attributes = useSelector((state) => state?.flow?.all_attributes);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState({});
  const confirmationDialogRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (triggersList?.length >= 10 && !validateNumber(id)) {
      setConfirmationDialogProps({
        title: "Max triggers limit reached",
        closeButtonProps: { hide: true },
        submitButtonProps: { label: "Go back" },
        onSubmit: () => navigate("/marketing_automation"),
        closeOnBlur: false,
      });
      confirmationDialogRef?.current?.open();
    } else {
      // confirmationDialogRef?.current?.open();
    }
  }, [triggersList, id]);

  useEffect(() => {
    const options = [];

    if (isArrayWithValues(ALL_CUSTOMER_PREDEFINED_ATTRIBUTES)) {
      options.push(...ALL_CUSTOMER_PREDEFINED_ATTRIBUTES);
    }

    if (isArrayWithValues(global_attributes)) {
      options.push(
        ...global_attributes?.map((i) => ({ ...i, type: "custom" }))
      );
    }

    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { all_attributes: options },
    });
    // return options;
    // return options?.map((i) => ({ label: i, value: `{{${i}}}` }));
  }, [globalAttributes]);

  useEffect(() => {
    if (!isArrayWithValues(triggersList)) dispatch(fetchJweroTriggers());
  }, []);
  useEffect(() => {
    setFlowType(
      location?.pathname?.includes("campaigns")
        ? "campaign"
        : location?.pathname?.includes("trigger")
        ? "trigger"
        : "webchat"
    );
    if (location?.pathname?.includes("trigger/create"))
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openStartSidebar: true },
      });
  }, [location.pathname]);

  useEffect(() => {
    if (!flowSettings)
      dispatch(
        fetchSingleSettings({
          setting_name: "flow_settings",
          reduxKey: "flowSettings",
          loadingKey: "fetchingFlowKeywords",
        })
      );
  }, [flowSettings]);

  const handleClickOpen = () => {
    setOpenTitleDialog(true);
  };

  const bgColor =
    mode === "dark" ? "rgba(220, 220, 220, 1)" : "rgba(0,0,0, 0.7)";

  const onNodesChange = useCallback(
    (changes) => {
      const prevNodePosition = nodes.find(
        (node) => node?.id === changes?.[0].id
      );
      // const changes = changes;
      const id = changes?.[0]?.id;
      const prevNodePostion = prevNodePosition?.position;
      const nodesWithChanges = applyNodeChanges(changes, nodes);
      const updatedPosition = nodesWithChanges.find(
        (node) => node.id === id
      )?.position;

      const xAxisDiff = updatedPosition?.x - prevNodePostion?.x;
      const yAxisDiff = updatedPosition?.y - prevNodePostion?.y;

      const updatedNodes = updateNodePositions({
        nodes: nodesWithChanges,
        edges: edges,
        startId: id,
        updateXBy: xAxisDiff,
        updateYBy: yAxisDiff,
      });
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { nodes: updatedNodes },
      });
      // dispatch({
      //   type: actions.ONCHANGE_NODES,
      //   payload: { changes, prevNodePosition },
      // });
    },
    [dispatch, nodes]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      if (changes.type !== "remove") {
        dispatch({ type: actions.ONCHANGE_EDGES, payload: changes });
      }
    },
    [dispatch]
  );

  const onConnect = (params) => {
    console.log(params);
  };

  const resetHandleState = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: {
        isDragging: false,
        dragginNodeId: null,
        dragginNodeType: null,
        dragginErrorMessage: null,
        nodeDraggedToId: null,
        actionsMenu: false,
      },
    });
  };

  const handleOnDragEnd = (result) => {
    const id = result?.draggableId;
    const nodeAdded = droppableNodeObj.find((node) => node.id === id);
    const nodeDraggedTo = nodes.find((node) => node.id === nodeDraggedToId);
    const nodeType = nodeAdded?.value;

    if (!nodeAdded || !nodeDraggedTo || !id || !nodeType) {
      resetHandleState();
      return;
    }

    if (dragCancel) {
      resetHandleState();
      return;
    }

    const flag = nodeCanBeAdded({
      nodes,
      edges,
      currentNode: nodeDraggedTo,
      nodeAddedType: nodeType,
    });
    if (flag) {
      addNode({
        dispatch: dispatch,
        nodes,
        currentNode: nodeDraggedTo,
        addNodeType: nodeType,
      });
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { actionsMenu: false },
      });
    }

    resetHandleState();
    return;
  };

  const handleOnDragStart = (result) => {
    const id = result?.draggableId;
    const nodeAdded = droppableNodeObj.find((node) => node.id === id);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: {
        isDragging: true,
        dragginNodeType: nodeAdded?.value,
      },
    });
  };

  // const handleOnDragUpdate = (result) => {
  //   console.log("HANDLE ON DRAF")
  //   if (!result.destination) return;

  //   const id = result?.draggableId;
  //   const nodeAdded = droppableNodeObj.find((node) => node.id === id);
  //   const nodeDraggedTo = nodes.find((node) => node.id === nodeDraggedToId);
  //   const nodeType = nodeAdded.value;
  //   console.log(nodeAdded,nodeDraggedTo,nodeDraggedToId);
  //   const flag = nodeCanBeAddedErrorMessage({
  //     nodes,
  //     edges,
  //     currentNode: nodeDraggedTo,
  //     nodeAddedType: nodeType,
  //   });

  //   if (flag !== "allowed") {
  //     dispatch({
  //       type: actions.EDIT_ELEMENT_IN_REDUX,
  //       payload: {
  //         dragginErrorMessage: {
  //           id: nodeDraggedTo?.id,
  //           message: flag,
  //         },
  //       },
  //     });
  //   }
  // };

  const handleDragCancel = () => {
    setDragCancel(true);
  };

  useEffect(() => {
    const initialLoad = false;
    if (initialLoad) {
      setOpenSelectDialog(true);
    } else {
      let payloadData = { initialLoad: false, type: flowType };
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { chatbotType: payloadData },
      });
    }
    // const type = location?.pathname?.includes("campaigns")
    //   ? "campaign"
    //   : "webchat";
    const actionMenuFlag = flowType === "webchat";
    // type === "campaign" ? false : true;
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { actionsMenu: actionMenuFlag },
    });
    // dispatch({ type: actions.EDIT_ELEMENT_IN_REDUX, loaderProgress: 0 });
    fetchElements({ dispatch, id, triggersList, type: flowType });
    fetchGlobalAttributes(dispatch);
    setIntegrations(dispatch);
  }, [dispatch, triggersList, id, flowType]);

  const ifSidebarOpen = useCallback(() => {
    return (
      sidebarOpenStart ||
      sidebarOpenUser ||
      sidebarOpenBot ||
      sidebarOpenUserAttachment ||
      sidebarOpenQuestion ||
      sidebarOpenFilter ||
      sidebarOpenGoToStep ||
      sidebarOpenWhatsappTemplate ||
      sidebarOpenProductCatalog ||
      sidebarOpenEmail ||
      sidebarOpenSms ||
      sidebarOpenNotification ||
      sidebarOpenDelay ||
      sidebarOpenCreateTask ||
      sidebarOpenMetalRates ||
      sidebarOpenAssignTeam
    );
  }, [
    sidebarOpenStart,
    sidebarOpenUser,
    sidebarOpenBot,
    sidebarOpenUserAttachment,
    sidebarOpenQuestion,
    sidebarOpenFilter,
    sidebarOpenGoToStep,
    sidebarOpenWhatsappTemplate,
    sidebarOpenProductCatalog,
    sidebarOpenEmail,
    sidebarOpenSms,
    sidebarOpenNotification,
    sidebarOpenDelay,
    sidebarOpenCreateTask,
    sidebarOpenMetalRates,
    sidebarOpenAssignTeam,
  ]);

  useEffect(() => {
    setSidebarOpen(ifSidebarOpen());
  }, [ifSidebarOpen]);

  return (
    <>
      {/* <Loader /> */}
      <CustomConfirmationDialog
        ref={confirmationDialogRef}
        {...confirmationDialogProps}
      />
      <SelectDialog
        openSelectDialog={openSelectDialog}
        setOpenSelectDialog={setOpenSelectDialog}
      />
      {/* {loading && <LoadingScreenTemplate />} */}
      {/* {(!loading || true) && (
      )} */}
      <DragDropContext
        onDragEnd={handleOnDragEnd}
        onDragStart={handleOnDragStart}
        // onDragUpdate={handleOnDragUpdate}
      >
        <Droppable droppableId="characters">
          {(provided) => (
            <Box
              className="main-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <Box
                onClick={handleClickOpen}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Inter",
                  position: "fixed",
                  zIndex: 1,
                  left: "30px",
                  top: "84px",
                  padding: "5px 10px 5px 10px",
                  borderRadius: "5px",
                  transition: "0.3s ease",
                  backgroundColor: "#fff",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 600,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "200px",
                    overflow: "hidden",
                  }}
                >
                  {title || "Untitled Flow "}
                </Typography>
              </Box>
              <EditTitleDialog
                openTitleDialog={openTitleDialog}
                setOpenTitleDialog={setOpenTitleDialog}
                title={title}
              />

              <Box
                className={`${checkSidebarOpen ? "blur-content" : ""}`}
                style={flowStyles}
              >
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  nodeTypes={nodeTypes}
                  defaultViewport={viewport}
                  nodesDraggable={true}
                  maxZoom={1.2}
                  deleteKeyCode={null}
                  fitView
                  proOptions={{ hideAttribution: true }}
                >
                  <Background
                    color={bgColor}
                    size="2"
                    style={{ opacity: 0.1 }}
                    variant="dots"
                  />
                  <Controls />
                  <PanelComponent id={id} />
                </ReactFlow>
              </Box>
              {actionsMenu && !checkSidebarOpen && !nodeAddPopover && (
                <Fade in={actionsMenu}>
                  <Box
                    style={{ visibility: isDragging ? "hidden" : "visible" }}
                  >
                    <ActionsBox />
                  </Box>
                </Fade>
              )}
              {isDragging && (
                <IconButton
                  onMouseEnter={handleDragCancel}
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "43%",
                    height: "100px",
                    width: "100px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#ebebeb",
                    paddingBottom: "10px 10px 15px 10px",
                    color: "#333333",
                    "&:hover": {
                      transition: "0.3s ease",
                      backgroundColor: "#fc7474",
                      height: "130px",
                      width: "130px",
                      color: "#ebebeb",
                    },
                  }}
                >
                  <CloseIcon sx={{ fontSize: "60px" }} />
                  <Typography
                    variant="span"
                    sx={{
                      fontFamily: "Inter",
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#000",
                    }}
                  >
                    Cancel
                  </Typography>
                </IconButton>
              )}
              {/* <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
              > */}
              <Box>
                {sidebarOpenStart && (
                  <Fade in={sidebarOpenStart}>
                    <Box className="sidebar-container">
                      {chatbotType?.type === "trigger" ? (
                        <SidebarStartTrigger />
                      ) : (
                        <SidebarStart />
                      )}
                    </Box>
                  </Fade>
                )}
                {sidebarOpenUser && (
                  <Fade in={sidebarOpenUser}>
                    <Box className="sidebar-container">
                      <SidebarUser />
                    </Box>
                  </Fade>
                )}
                {sidebarOpenBot && (
                  <>
                    {/* <Loader /> */}
                    <Fade in={sidebarOpenBot}>
                      <Box className="sidebar-bot">
                        <SidebarBot botData={botData} setBotData={setBotData} />
                      </Box>
                    </Fade>
                  </>
                )}
                {sidebarOpenUserAttachment && (
                  <Fade in={sidebarOpenUserAttachment}>
                    <Box className="sidebar-container">
                      <SidebarUserAttachment
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </Box>
                  </Fade>
                )}
                {sidebarOpenQuestion && (
                  <Fade in={sidebarOpenQuestion}>
                    <Box className="sidebar-container">
                      <SidebarQuestion
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </Box>
                  </Fade>
                )}
                {sidebarOpenFilter && (
                  <Fade in={sidebarOpenFilter}>
                    <Box className="sidebar-container">
                      <SidebarFilter
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </Box>
                  </Fade>
                )}
                {sidebarOpenGoToStep && (
                  <Fade in={sidebarOpenGoToStep}>
                    <Box className="sidebar-container">
                      <SidebarGoToStep
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </Box>
                  </Fade>
                )}
                {sidebarOpenWhatsappTemplate && (
                  <Fade in={sidebarOpenWhatsappTemplate}>
                    <Box className="sidebar-container">
                      <SidebarWhatsappTemplate
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </Box>
                  </Fade>
                )}
                {sidebarOpenProductCatalog && (
                  <Fade in={sidebarOpenProductCatalog}>
                    <Box className="sidebar-container">
                      <SidebarProductCatalog
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </Box>
                  </Fade>
                )}
                {sidebarOpenEmail && (
                  <Fade in={sidebarOpenEmail}>
                    <Box className="sidebar-container">
                      <SidebarEmail botData={botData} setBotData={setBotData} />
                    </Box>
                  </Fade>
                )}
                {sidebarOpenSms && (
                  <Fade in={sidebarOpenSms}>
                    <Box className="sidebar-container">
                      <SidebarSms botData={botData} setBotData={setBotData} />
                    </Box>
                  </Fade>
                )}
                {sidebarOpenNotification && (
                  <Fade in={sidebarOpenNotification}>
                    <Box className="sidebar-container">
                      <SidebarPushNotification
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </Box>
                  </Fade>
                )}
                {sidebarOpenDelay && (
                  <Fade in={sidebarOpenDelay}>
                    <Box className="sidebar-container">
                      <SidebarDelay botData={botData} setBotData={setBotData} />
                    </Box>
                  </Fade>
                )}
                {sidebarOpenCreateTask && (
                  <Fade in={sidebarOpenCreateTask}>
                    <Box className="sidebar-container">
                      <SidebarCreateTask
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </Box>
                  </Fade>
                )}
                {sidebarOpenMetalRates && (
                  <Fade in={sidebarOpenMetalRates}>
                    <Box className="sidebar-container">
                      <SidebarMetalRates
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </Box>
                  </Fade>
                )}
                {sidebarOpenAssignTeam && (
                  <Fade in={sidebarOpenAssignTeam}>
                    <Box className="sidebar-container">
                      <SidebarAssignTeam
                        botData={botData}
                        setBotData={setBotData}
                      />
                    </Box>
                  </Fade>
                )}
              </Box>
              {/* </Draggable> */}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default Flow;
