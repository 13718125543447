import React from "react";
import HorizontalStack from "../../../helper/utility/HorizontalStack";
import { PrimaryLabel } from "../../../helper/utility/Labels";
import { Button } from "@mui/material";

function SubsectionHeader({ primaryButtonProps, right, title }) {
  return (
    <HorizontalStack style={{ justifyContent: "space-between" }}>
      <PrimaryLabel>{title}</PrimaryLabel>

      <HorizontalStack style={{ justifyContent: "flex-end" }} spacing={2}>
        {primaryButtonProps?.label && (
          <Button size="small" variant="contained" {...primaryButtonProps}>
            {primaryButtonProps?.label}
          </Button>
        )}
        {right && right}
      </HorizontalStack>
    </HorizontalStack>
  );
}

export default SubsectionHeader;
