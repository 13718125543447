import { ArrowBack, Cancel, Delete, Edit } from "@material-ui/icons";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  LinearProgress,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import {
  dialogStyle,
  formatDate,
  getAxiosError,
  getMedia,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  isValidImageUrl,
} from "../../../helper";
import {
  addBloginRedux,
  addCategoryinRedux,
  deleteBlogCategory,
  deleteBlogOnRedux,
  getBlogs,
  getBlogsCategories,
  replaceBloginRedux,
} from "../../../redux/actions/pagesActions";
import styled from "styled-components";
import ProductMediaLibrary from "../../components/ProductMediaLibrary";
import { FileUpload, ImageNotSupportedOutlined } from "@mui/icons-material";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import { LoadingButton } from "@mui/lab";
import {
  Chip,
  DialogContentText,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import { sidebarExtraWidth } from "../../../App";
import { isArray } from "jwero-javascript-utils";

const SEO_TITLE_LIMIT = 80;
const SEO_DESC_LIMIT = 300;

const Blogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allReduxBlogs = useSelector((state) => state.pages.allBlogs);
  const fetchingReduxBlogs = useSelector((state) => state.pages.fetchingBlogs);
  const totalBlogs = useSelector((state) => state.pages.totalBlogs);
  const fetchingMoreReduxBlogs = useSelector(
    (state) => state.pages.fetchingMoreBlogs
  );
  const blogsCategories =
    useSelector((state) => state.pages.blogsCategories) || [];
  const location = useLocation();
  const [allBlogs, setAllBlogs] = useState([]);
  const [openCategoeryForm, setOpenCategoeryForm] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
  });

  useEffect(() => {
    if (!isArrayWithValues(allReduxBlogs)) dispatch(getBlogs());
    if (!isArrayWithValues(blogsCategories)) dispatch(getBlogsCategories());
  }, []);
  useEffect(() => {
    if (isArray(allReduxBlogs)) setAllBlogs(allReduxBlogs);
  }, [allReduxBlogs]);
  let { hash } = location;

  const onLoadMoreBlogs = () => {
    dispatch(
      getBlogs({
        params: {
          ...params,
          page: params.page + 1,
        },
        onSuccess: () => {
          setParams((state) => ({ ...state, page: state?.page + 1 }));
        },
      })
    );
  };

  return (
    <Box
      sx={
        {
          // maxWidth: `calc(100% - ${sidebarExtraWidth}px)`,
        }
      }
    >
      <Stack
        direction={"row"}
        justifyContent="space-between"
        sx={{ margin: "10px", mr: 5 }}
        alignItems="center"
      >
        <Typography variant="h3">Blogs</Typography>
        {hash === "" || hash?.includes("blogs") ? (
          <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => navigate(`/frontend#add`)}
            >
              Add New Blog
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setOpenCategoeryForm(true)}
            >
              Add New Category
            </Button>
          </Stack>
        ) : null}
      </Stack>
      {!fetchingReduxBlogs ? (
        <Box overflow={"visible"}>
          {hash.includes("edit") || hash.includes("add") ? (
            <BlogForm blogsCategories={blogsCategories} />
          ) : (
            <AllBlogs
              allBlogs={allBlogs}
              fetchingReduxBlogs={fetchingReduxBlogs}
              onLoadMore={onLoadMoreBlogs}
              fetchingMore={fetchingMoreReduxBlogs}
              totalBlogs={totalBlogs}
            />
          )}
        </Box>
      ) : (
        <Stack
          sx={{ height: "calc(100vh - 200px)", width: "100%" }}
          justifyContent="center"
          alignItems={"center"}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Fetching blogs ...
          </Typography>
          <LinearProgress sx={{ width: "50%" }} />
        </Stack>
      )}
      <CreateCategoryPopup
        open={openCategoeryForm}
        handleClose={() => setOpenCategoeryForm(false)}
      />
    </Box>
  );
};

export default Blogs;

const AllBlogs = ({
  allBlogs,
  fetchingReduxBlogs,
  onLoadMore,
  fetchingMore,
  totalBlogs,
}) => {
  const blogsCategories =
    useSelector((state) => state.pages.blogsCategories) || [];
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    if (!isArrayWithValues(blogsCategories)) dispatch(getBlogsCategories());
  }, []);
  useEffect(() => {
    if (isArrayWithValues(allBlogs)) setBlogs(allBlogs);
  }, [allBlogs]);

  const [selectedTab, setSelectedTab] = useState("all");
  const onChangeCategoery = (id) => {
    if (!isArrayWithValues(allBlogs)) return;
    if (id === "all") setBlogs(allBlogs);
    else {
      let filterAll = allBlogs.filter((obj) => obj?.categories?.includes(id));
      setBlogs(filterAll);
    }
    setSelectedTab(id);
  };
  return (
    <Box
      sx={{
        padding: "20px",
        // maxWidth: `calc(100% - ${sidebarExtraWidth}px)`,
      }}
    >
      {!fetchingReduxBlogs && (
        <Stack>
          {/* <Divider /> */}
          {/* <Stack
            direction={"row"}
            style={{ alignItems: "flex-end", justifyContent: "flex-end" }}
          >
            <Stack
              direction={"row"}
              style={{ alignItems: "flex-end", justifyContent: "flex-end" }}
            >
              <Typography>Draft: </Typography>
              <Typography marginLeft={1} fontWeight={"500"} marginRight={6}>
                {allBlogs?.filter((item) => item?.status === "draft")?.length}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              style={{ alignItems: "flex-end", justifyContent: "flex-end" }}
            >
              <Typography>Publish: </Typography>
              <Typography marginLeft={1} fontWeight={"500"}>
                {allBlogs?.filter((item) => item?.status === "publish")?.length}
              </Typography>
            </Stack>
          </Stack> */}
          <Tabs
            value={selectedTab}
            onChange={(a, e) => onChangeCategoery(e)}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            {[
              {
                name: "All",
                id: "all",
              },
            ]
              ?.concat(blogsCategories)
              .map((b) => (
                <Tab
                  label={`${b.name} ${
                    allBlogs &&
                    `(${
                      b.id === "all"
                        ? totalBlogs
                        : allBlogs.filter((obj) =>
                            obj?.categories?.includes(b.id)
                          )?.length
                    })`
                  }`}
                  value={b.id}
                />
              ))}
            {/* <Tab label="Item One" {...a11yProps(0)} />
            <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
          <Stack
            direction={"row"}
            sx={{
              gap: "7px",
              mb: 2,
              maxWidth: `calc(100% - ${sidebarExtraWidth + 100}px)`,
              overflowX: "scroll",
              display: "none",
            }}
          >
            <Button
              variant={"text"}
              style={{ flexDirection: "row" }}
              // startIcon={b.icon}
              sx={{
                padding: "6px 20px",
                backgroundColor:
                  selectedTab === "all" ? "#e6e6e6" : "transparent",
                color: "#000",

                "&:hover": {
                  backgroundColor:
                    selectedTab === "all" ? "#e6e6e6" : "transparent",
                },
              }}
              onClick={() => onChangeCategoery("all")}
            >
              <Typography>All </Typography>
              <Typography marginLeft={1} fontWeight={"500"}>
                {allBlogs && `(${allBlogs?.length})`}
              </Typography>
            </Button>
            {blogsCategories.map((b) => (
              <Button
                variant={"text"}
                // style={{ flexDirection: "row" }}
                // startIcon={b.icon}
                sx={{
                  padding: "6px 20px",
                  backgroundColor:
                    selectedTab === b.id ? "#e6e6e6" : "transparent",
                  color: "#000",

                  "&:hover": {
                    backgroundColor:
                      selectedTab === b.id ? "#e6e6e6" : "transparent",
                  },
                  whiteSpace: "nowrap",
                }}
                onClick={() => onChangeCategoery(b.id)}
              >
                {b.name}{" "}
                {allBlogs &&
                  `(${
                    allBlogs.filter((obj) => obj?.categories?.includes(b.id))
                      ?.length
                  })`}
                {/* <Typography>{b.name} </Typography>
                <Typography marginLeft={1} fontWeight={"500"}>
                  {allBlogs &&
                    `(${
                      allBlogs.filter((obj) => obj?.categories?.includes(b.id))
                        ?.length
                    })`}
                </Typography> */}
              </Button>
            ))}
          </Stack>
          <Divider />
        </Stack>
      )}
      <Grid
        container
        spacing={4}
        sx={{ width: "100%", height: "100%", marginBottom: "60px", mt: 1 }}
      >
        {fetchingReduxBlogs ? (
          <>
            <Stack
              sx={{ height: "100%", width: "100%" }}
              justifyContent="center"
              alignItems={"center"}
            >
              <LinearProgress sx={{ width: "50%" }} />
            </Stack>
          </>
        ) : null}
        {blogs.map((obj) => (
          <Grid item xs={isMobile ? 12 : 6} md={6} lg={4}>
            <BlogCard blog={obj} />
          </Grid>
        ))}
      </Grid>
      {isArrayWithValues(blogs) && (
        <Stack direction="row" justifyContent={"center"} onClick={onLoadMore}>
          <LoadingButton loading={fetchingMore} variant="contained">
            Load more blogs
          </LoadingButton>
        </Stack>
      )}
    </Box>
  );
};

const BlogCard = ({ blog }) => {
  let {
    date,
    link,
    date_gmt,
    modified_gmt,
    title,
    content,
    excerpt,
    featured_media,
    id,
    status,
  } = blog;
  let contentBody = content?.rendered || "";
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  let successColor = theme.palette.success && theme.palette.success.main;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [showBlogDeleteAlert, setShowBlogDeleteAlert] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  useEffect(async () => {
    let img = "";
    console.log(featured_media);
    setLoadingImage(true);
    if (featured_media && blog?.source_url) setImage(blog?.source_url);
    if (featured_media && !blog?.source_url) {
      img = await getMedia(featured_media);
      dispatch(replaceBloginRedux({ ...blog, source_url: img?.source_url }));
      setImage(img?.source_url);
    }
    setLoadingImage(false);
  }, [blog]);

  const confirmDeleteBlog = async () => {
    console.log(id);
    let website = getWebsite();
    let token = getToken();
    try {
      const res = await axios({
        url: `${website}/wp-json/wp/v2/posts/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: {
          force: true,
        },
      });
      if (res.data?.deleted) {
        dispatch(deleteBlogOnRedux(id));
        dispatch(
          setGlobalToast({
            show: true,
            message: "Blog Deleted!",
            severity: "success",
          })
        );
      }
      console.log(res);
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };

  const onStatusChnage = async (e) => {
    let website = getWebsite();
    let token = getToken();
    if (blog?.meta?.footnote) delete blog?.meta?.footnote;
    dispatch(replaceBloginRedux({ ...blog, status: e ? "publish" : "draft" }));
    dispatch(
      setGlobalToast({
        show: true,
        message: "Blog Update successfully!",
        severity: "success",
      })
    );
    let res = await axios({
      url: `${website}/wp-json/wp/v2/posts/${id}`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "POST",
      data: {
        ...blog,
        status: e ? "publish" : "draft",
      },
    });
    console.log(res);
    if (res.data?.id) {
    } else {
      dispatch(
        replaceBloginRedux({ ...blog, status: !e ? "publish" : "draft" })
      );
      dispatch(
        setGlobalToast({
          show: true,
          message: "Blog Update Fail!",
          severity: "success",
        })
      );
    }
  };

  const goToBlog = () => {
    if (link) {
      window.open(link);
    }
  };

  return (
    <Card className="shadow_card">
      <Stack onClick={goToBlog}>
        {loadingImage ? (
          <Stack
            justifyContent={"center"}
            alignItems="center"
            sx={{ height: "250px", width: "100%" }}
          >
            <CircularProgress />
          </Stack>
        ) : isValidImageUrl(image) ? (
          <img
            src={image}
            style={{ height: "250px", objectFit: "cover", width: "100%" }}
          />
        ) : (
          <Stack
            sx={{
              height: "250px",
              width: "100%",
              backgroundColor: "#e8e8e8",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Typography sx={{ marginTop: "7px", color: "#cfcfcf" }}>
            No Image
          </Typography> */}
            <ImageNotSupportedOutlined
              fontSize="large"
              color="#cccccc"
              htmlColor="#cccccc"
            />
          </Stack>
        )}
      </Stack>

      <Stack sx={{ padding: "20px", paddingTop: "10px", paddingBottom: "5px" }}>
        <Typography variant="h6">{title?.rendered}</Typography>
        <Box>
          <Typography sx={{ marginTop: "7px", color: "#808080" }}>
            {contentBody.replace(/<\/?[^>]+(>|$)/g, "").length > 80
              ? `${contentBody.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 80)}...`
              : contentBody.replace(/<\/?[^>]+(>|$)/g, "")}
          </Typography>
        </Box>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Stack>
            <Typography>
              {formatDate(new Date(date_gmt), "relative_date")}
            </Typography>
            <Typography fontSize={8} color={"#8a8a8a"}>
              Created on
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems="center">
            <Typography
              variant="h2"
              style={{
                fontSize: 12,
                marginRight: 4,
                color: "grey",
              }}
            >
              Publish
            </Typography>
            <Switch
              onChange={(e) => onStatusChnage(e)}
              checked={status === "publish"}
              style={
                status === "publish"
                  ? {
                      border: `1px solid ${successColor}`,
                      // border: `1px solid #00ff00`,
                      backgroundColor: successColor,
                      // backgroundColor: "#00ff00",
                    }
                  : {}
              }
            />
            <IconButton>
              <Edit
                onClick={() => {
                  navigate(`/frontend#edit${id}`);
                }}
              />
            </IconButton>
            {/* <IconButton onClick={confirmDeleteBlog}> */}
            <IconButton onClick={() => setShowBlogDeleteAlert(true)}>
              <Delete />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <Dialog
        open={showBlogDeleteAlert}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowBlogDeleteAlert(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{ py: 3 }}
      >
        <DialogTitle>
          <Typography variant="subtitle2">{title?.rendered}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {"Are you sure you want to delete this blog?"}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ mx: 2 }}>
          <Button
            onClick={() => {
              // console.log(showDeleteAlert);
              setShowBlogDeleteAlert(false);
            }}
          >
            Close
          </Button>
          <LoadingButton
            loading={false}
            color="error"
            variant="contained"
            onClick={() => {
              confirmDeleteBlog();
              setShowBlogDeleteAlert(false);
            }}
            // disabled={!productAccess.includes("delete") && !isAdmin}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

const BlogForm = () => {
  const blogStatuses = [
    { label: "Published", value: "publish" },
    { label: "Draft", value: "draft" },
  ];
  const blogsCategories =
    useSelector((state) => state.pages.blogsCategories) || [];
  const allReduxBlogs = useSelector((state) => state.pages.allBlogs) || [];
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let { hash } = location;
  const [allBlogs, setAllBlogs] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [imageObj, setImageObj] = useState({});
  const [mediaLibraryOpen, toggleMediaLibrary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  useEffect(() => {
    if (isArrayWithValues(allReduxBlogs)) setAllBlogs(allReduxBlogs);
  }, [allReduxBlogs]);
  useEffect(() => {
    if (!isArrayWithValues(blogsCategories)) dispatch(getBlogsCategories());
  }, []);
  useEffect(() => {
    if (isArrayWithValues(blogsCategories)) setAllCategories(blogsCategories);
  }, [blogsCategories]);

  const defaultState = {
    title: "",
    content: "",
    categories: [],
    featured_media: "",
    status: "draft",
    seo_title: "",
    seo_description: "",
    date_gmt: "",
    modified_gmt: "",
  };
  const [formData, setFormData] = useState(defaultState);
  const onFormEdit = (payload) => {
    setFormData((pre) => ({ ...pre, ...payload }));
  };
  const setImage = (arr) => {
    console.log(arr);
    setImageObj(arr[0]);
    toggleMediaLibrary(false);
    onFormEdit({ featured_media: arr[0]?.id });
  };
  const onSubmit = async () => {
    let website = getWebsite();
    let token = getToken();
    console.log(formData);

    let data = { ...formData };

    if (!data?.featured_media) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Blog Image Mandatory!",
          severity: "error",
        })
      );
      return;
    }

    delete data?.date_gmt;
    delete data?.modified_gmt;

    // if(formData)
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/wp/v2/posts${data.id ? `/${data.id}` : ""}`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "POST",
      data,
    });
    console.log(res);
    if (res.data?.id) {
      if (data.id) {
        dispatch(
          replaceBloginRedux({ ...res.data, source_url: imageObj?.source_url })
        );
        dispatch(
          setGlobalToast({
            show: true,
            message: "Blog Update successfully!",
            severity: "success",
          })
        );
      } else {
        dispatch(
          addBloginRedux({ ...res.data, source_url: imageObj?.source_url })
        );
        dispatch(
          setGlobalToast({
            show: true,
            message: "Blog created successfully!",
            severity: "success",
          })
        );
      }

      navigate("/frontend");
    }
    setLoading(false);
  };

  const onCheckBoxChange = (id) => {
    let arr = formData.categories;
    if (arr.includes(id)) arr = arr.filter((e) => e !== id);
    else arr.push(id);
    onFormEdit({ categories: arr });
  };
  useEffect(async () => {
    if (hash.includes("edit") && isArrayWithValues(allBlogs)) {
      let idNumber = hash.slice(5);
      console.log(idNumber);
      let obj = allBlogs.find((o) => Number(o.id) === Number(idNumber));
      console.log(obj);
      // let {  id } = obj;
      setFormData({
        title: obj?.title?.rendered || "",
        content: obj?.content?.rendered || "",
        categories: obj?.categories || "",
        featured_media: obj?.featured_media || "",
        id: obj?.id,
        status: obj?.status,
        date_gmt: obj?.date_gmt,
        modified_gmt: obj?.modified_gmt,
      });
      let img = {};
      if (obj?.featured_media) img = await getMedia(obj?.featured_media);
      setImageObj(img);
    }
  }, [hash, allBlogs]);

  console.log("formData", formData);

  return (
    <Box sx={{ padding: "10px 20px" }} overflow={"visible"}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        sx={{ mb: 3 }}
        alignItems="center"
      >
        <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
          <IconButton
            onClick={() => {
              navigate("/frontend");
            }}
          >
            <ArrowBack />{" "}
          </IconButton>
          <Typography variant="h5">
            {formData.id ? "Update" : "Add"} Blog
          </Typography>
        </Stack>
        <LoadingButton loading={loading} onClick={onSubmit} variant="contained">
          {formData.id ? "Update" : "Save"}
        </LoadingButton>
      </Stack>
      <Grid
        container
        spacing={5}
        direction={isIpad ? "column-reverse" : "row"}
        overflow={"visible"}
      >
        <Grid item xs={12} lg={8} overflow={"visible"}>
          <Card
            className="shadow_card"
            sx={{ padding: "20px" }}
            style={{ overflow: "visible" }}
          >
            <Stack overflow={"visible"}>
              <Stack sx={{ mb: 4 }}>
                <Stack
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
                    Title
                  </Typography>
                </Stack>
                <TextField
                  size="small"
                  value={formData?.title}
                  onChange={(e) => onFormEdit({ title: e.target.value })}
                />
              </Stack>
              <Stack overflow={"visible"}>
                <Stack flexDirection={"row"} alignItems={"center"}>
                  <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
                    Description
                  </Typography>
                  <Tooltip title="Select any content to apply editing on it">
                    <InfoOutlinedIcon fontSize="small" sx={{ fontSize: 12 }} />
                  </Tooltip>
                </Stack>
                <QuillWrapper
                  style={{
                    zIndex: 999,
                    border: `1px solid #cecece`,
                    borderRadius: 4,
                  }}
                >
                  <ReactQuill
                    style={{ borderRadius: "8px" }}
                    // theme="snow"
                    theme="bubble"
                    value={formData?.content}
                    onChange={(e) => onFormEdit({ content: e })}
                    placeholder="Type something.."
                    formats={[
                      "header",
                      "font",
                      "size",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "indent",
                      "link",
                      "image",
                      // "video",
                    ]}
                    modules={{
                      toolbar: [
                        [{ header: "1" }, { header: "2" }, { font: [] }],
                        [{ size: [] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        [
                          "link",
                          "image",
                          // "video"
                        ],
                        ["clean"],
                      ],
                      clipboard: {
                        // toggle to add extra line breaks when pasting HTML:
                        matchVisual: false,
                      },
                    }}
                  />
                </QuillWrapper>
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          sx={isIpad ? { display: "flex", gap: "20px" } : {}}
        >
          <Card className="shadow_card" sx={{ padding: "20px" }}>
            <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
              Blog Image
            </Typography>
            {isObjWithValues(imageObj) ? (
              <Box
                sx={{ position: "relative", maxHeight: "160px", width: "100%" }}
              >
                <img
                  src={imageObj?.source_url}
                  style={{
                    maxHeight: "160px",
                    width: "100%",
                    objectFit: "cover",
                    textAlign: "center",
                    borderRadius: "8px",
                    border: "0.5px solid #ccc",
                  }}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    right: "-12px",
                    top: "-12px",
                    zIndex: 2,
                    overflow: "hidden",
                  }}
                  onClick={() => setImageObj({})}
                  aria-label="delete"
                >
                  <Cancel
                    size="small"
                    color="primary"
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                    }}
                  />
                </IconButton>
              </Box>
            ) : (
              <Card
                onClick={() => toggleMediaLibrary(true)}
                sx={{
                  cursor: "pointer",
                  backgroundColor: "rgba(0,0,0,0.1)",
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                  },
                }}
              >
                <CardContent
                  fullWidth
                  id="outlined"
                  style={{
                    height: "160px",
                    alignItems: "center",
                    display: "flex",
                  }}
                  hoverTitle=""
                >
                  <Stack
                    justifyContent={"center"}
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    {loadingImage ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {" "}
                        <FileUpload
                          color="rgba(0,0,0,0.3)"
                          style={{
                            height: "40px",
                            width: "40px",
                            // backgroundColor: "rgba(0,0,0,0.3)",
                            // position: "absolute",
                            // top: "50%",
                            // left: "50%",
                            // transform: "translate(-50%, -50%)",
                          }}
                        />
                        <Typography>Image (1440px X 600px)</Typography>
                        <Typography>(JPEG, PNG, WEBP)</Typography>
                      </>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            )}
          </Card>
          <Card
            className="shadow_card"
            sx={isIpad ? { padding: "20px" } : { padding: "20px", mt: 7 }}
          >
            <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
              Blogs Category
            </Typography>
            {isArrayWithValues(allCategories) ? (
              <>
                <FormGroup>
                  {allCategories.map((obj) => (
                    <FormControlLabel
                      sx={{ "MuiFormControlLabel-label": { width: "100%" } }}
                      control={
                        <Checkbox
                          checked={formData.categories.includes(obj.id)}
                          onChange={() => onCheckBoxChange(obj.id)}
                        />
                      }
                      label={
                        <Stack
                          direction={"row"}
                          justifyContent="space-between"
                          alignItems={"center"}
                          sx={{ width: "100%" }}
                        >
                          <Typography variant="subtitle2">
                            {" "}
                            {obj.name}
                          </Typography>
                          <Typography marginLeft={1} fontWeight={"500"}>
                            (
                            {
                              allBlogs.filter((category) =>
                                category?.categories?.includes(obj.id)
                              )?.length
                            }
                            )
                          </Typography>
                          <IconButton
                            // onClick={() => dispatch(deleteBlogCategory(obj))}
                            onClick={() => setShowDeleteAlert(obj)}
                            size="small"
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </Stack>
                      }
                    />
                  ))}
                </FormGroup>
              </>
            ) : null}
          </Card>
          <Card
            className="shadow_card"
            sx={isIpad ? { padding: "20px" } : { padding: "20px", mt: 7 }}
          >
            <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
              Status
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="status-radio-buttons-group-label"
                value={formData?.status}
                onChange={(e) => onFormEdit({ status: e.target.value })}
              >
                {blogStatuses?.map((val) => (
                  <FormControlLabel
                    value={val?.value}
                    control={
                      <Radio
                        color={
                          val?.label === "Published" ? "success" : "default"
                        }
                      />
                    }
                    label={val?.label}
                    color="#00ff00"
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Card>
          <Card
            className="shadow_card"
            sx={isIpad ? { padding: "20px" } : { padding: "20px", mt: 7 }}
          >
            {/* <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
              SEO
            </Typography> */}
            <Stack sx={{ mb: 4 }}>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
                  SEO Title
                </Typography>
                <Typography variant="subtitle4" sx={{ fontSize: "12px" }}>
                  {SEO_TITLE_LIMIT - (formData?.seo_title?.length || 0)} (Max{" "}
                  {SEO_TITLE_LIMIT} chars)
                </Typography>
              </Stack>
              <TextField
                size="small"
                multiline
                inputProps={{ maxLength: SEO_TITLE_LIMIT }}
                value={formData?.seo_title}
                onChange={(e) => onFormEdit({ seo_title: e.target.value })}
              />
            </Stack>
            <Stack sx={{ mb: 4 }}>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
                  SEO Description
                </Typography>
                <Typography variant="subtitle4" sx={{ fontSize: "12px" }}>
                  {SEO_DESC_LIMIT - (formData?.seo_description?.length || 0)}{" "}
                  (Max {SEO_DESC_LIMIT} chars)
                </Typography>
              </Stack>
              <TextField
                size="small"
                inputProps={{ maxLength: SEO_DESC_LIMIT }}
                value={formData?.seo_description}
                multiline
                onChange={(e) =>
                  onFormEdit({ seo_description: e.target.value })
                }
              />
            </Stack>
          </Card>
          <Card
            className="shadow_card"
            sx={isIpad ? { padding: "20px" } : { padding: "20px", mt: 7 }}
          >
            {/* <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
              SEO
            </Typography> */}
            <Stack sx={{ mb: 4 }}>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="subtitle4" sx={{ fontSize: "14px" }}>
                  Timeline
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={{ mb: 4 }}>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "12px", marginRight: 2 }}>
                  Last Updated:
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {formatDate(
                    new Date(formData?.modified_gmt),
                    "relative_date"
                  )}
                </Typography>
              </Stack>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "12px", marginRight: 2 }}>
                  Created On:{" "}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {formatDate(new Date(formData?.date_gmt), "relative_date")}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
      <ProductMediaLibrary
        open={mediaLibraryOpen}
        onChange={toggleMediaLibrary}
        setParentImages={setImage}
        // id={"mobile_1"}
        multiple={false}
        aspect={{ height: 600, width: 1440 }}
        // selectedImages={images}
      />
      <Dialog
        open={isObjWithValues(showDeleteAlert)}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowDeleteAlert(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{ py: 3 }}
      >
        <DialogTitle>
          <Typography variant="subtitle2">Remove Category?</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            This will also affect other blogs linked with this category
          </Typography>
        </DialogContent>
        <DialogActions sx={{ mx: 2 }}>
          <Button
            onClick={() => {
              setShowDeleteAlert(false);
            }}
          >
            Close
          </Button>
          <LoadingButton
            loading={false}
            color="error"
            variant="contained"
            onClick={() => {
              dispatch(deleteBlogCategory(showDeleteAlert));
              setShowDeleteAlert(false);
            }}
            // disabled={!productAccess.includes("delete") && !isAdmin}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: calc(100vh - 390px);,
    border-radius: 6px;
  }
`;

const CreateCategoryPopup = ({ open, handleClose }) => {
  let website = getWebsite();
  let token = getToken();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    console.log(formData);

    let data = { ...formData };

    delete data?.date_gmt;
    delete data?.modified_gmt;

    // if(formData)
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/wp/v2/categories`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "POST",
      data,
    });
    console.log(res);
    if (res.data?.id) {
      dispatch(addCategoryinRedux(res.data));
      dispatch(
        setGlobalToast({
          show: true,
          message: "Category created successfully!",
          severity: "success",
        })
      );
      handleClose();
    }
    setLoading(false);
  };
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={dialogStyle}
      fullWidth
      maxWidth="sm"
      {...globalPopupStyle}
    >
      <DialogTitle>Add New Category </DialogTitle>
      <DialogContent>
        <Stack sx={{ mb: 3, mt: 3 }}>
          <Typography variant="subtitle2" sx={{ fontSize: "15px" }}>
            Category Name
          </Typography>
          <TextField
            size="small"
            placeholder="Name"
            onChange={(e) =>
              setFormData((pre) => ({ ...pre, name: e.target.value }))
            }
            value={formData.name}
          />
        </Stack>
        <Stack sx={{ mb: 3, mt: 1 }}>
          <Typography variant="subtitle2" sx={{ fontSize: "15px" }}>
            Description
          </Typography>
          <TextField
            size="small"
            multiline
            rows={3}
            placeholder="Description"
            onChange={(e) =>
              setFormData((pre) => ({ ...pre, description: e.target.value }))
            }
            value={formData.description}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <LoadingButton loading={loading} onClick={onSubmit}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
