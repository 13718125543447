import { useState, useEffect, useMemo } from "react";
import {
  Typography,
  Card,
  TextField,
  IconButton,
  InputAdornment,
  Autocomplete,
  Chip,
  Box,
} from "@mui/material";
import SwapCallsSharpIcon from "@mui/icons-material/SwapCallsSharp";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useDispatch, useSelector } from "react-redux";
import { goToStepStyles } from "./styles/goToStepStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import BlockOptions from "./components/BlockOptions";
import { checkTitleExists } from "../helper/checkTitleExists";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchUsers } from "./helper/fetchUsers";

const styles = goToStepStyles;

const SidebarAssignTeam = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [block, setBlock] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [data, setData] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [titleCheckFlag, setTitleCheckFlag] = useState(false);
  const titleErrorMessage =
    title === "" ? "Block title is required" : "Title already exists!";

  const closeSidebar = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { openAssignTeamSidebar: false },
    });
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title == "") {
      setTitleCheckFlag(true);
      setTitleError(true);
    }

    if (title) {
      const submittedUserData = {
        title,
        selected_team: selectedTeam,
      };
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openAssignTeamSidebar: false },
      });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  const handleSelectedOption = (e, newValue) => {
    setSelectedTeam(newValue);
  };

  const handleTitle = (e) => {
    const value = e.target.value;
    setTitle(e.target.value);
    if (checkTitleExists({ nodes, title: value })) {
      setTitleCheckFlag(true);
      return;
    }
    setTitleCheckFlag(false);
  };

  const getUsersData = async () => {
    setLoading(true);

    const res = await fetchUsers();
    setData(res);
    setLoading(false);
  };

  useEffect(() => {
    const currentNode = nodes?.find((it) => it.id === userId);
    setLoading(true);
    getUsersData();

    if (currentNode) {
      const node = currentNode?.fieldData;
      if (node?.title) {
        setTitle(node?.title);
      }
      if (node?.selected_team) {
        setSelectedTeam(node?.selected_team);
      } else {
        setSelectedTeam([]);
      }
      setLoading(false);
    }
  }, [userId]);

  return (
    <Card style={styles.customCard}>
      <form>
        <Box sx={styles.topCard}>
          <Box sx={styles.innerCard}>
            <Box sx={styles.userHeader}>
              <GroupAddIcon />
              <Typography variant="span" style={styles.headerTitle}>
                Assign Team
              </Typography>
            </Box>
            <Box sx={styles.headerIconsMainDiv}>
              <IconButton sx={styles.headerIconsDiv} onClick={handleClose}>
                <CloseIcon sx={styles.headerIcons} />
              </IconButton>
              <IconButton sx={styles.headerIconsDiv} onClick={handleCheck}>
                <CheckIcon sx={styles.headerIcons} />
              </IconButton>
            </Box>
          </Box>

          <TextField
            placeholder="Type block title"
            size="small"
            style={styles.titleTextField}
            onChange={handleTitle}
            error={titleError}
            value={title}
            required
          />
          {titleCheckFlag && (
            <Typography
              sx={{
                fontSize: "14px",
                color: "#E63946",
                fontWeight: 500,
                fontFamily: "Inter",
                position: "relative",
                bottom: "10px",
                left: "3px",
              }}
            >
              {titleErrorMessage}
            </Typography>
          )}
        </Box>
        <Box sx={styles.responseSection}>
          <Box sx={styles.responseDiv}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Typography variant="span" style={styles.labels}>
                  Select Member
                </Typography>
                <Autocomplete
                  multiple
                  placeholder="Select..."
                  id="tags-filled"
                  options={
                    (data &&
                      data.length > 0 &&
                      data.map((option) => ({
                        label: option?.first_name + " " + option?.last_name,
                        value: option?.id,
                      }))) ||
                    []
                  }
                  value={selectedTeam || []}
                  onChange={(e, newValue) => handleSelectedOption(e, newValue)}
                  renderTags={(value, getTagProps) =>
                    value.map((selectedTeam, index) => (
                      <Chip
                        key={index}
                        sx={{ backgroundColor: "#dcdcdc", fontFamily: "Inter" }}
                        variant="outlined"
                        label={selectedTeam?.label}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select..."
                      variant="outlined"
                      sx={{
                        backgroundColor: "#fff",
                      }}
                    />
                  )}
                />
              </>
            )}
          </Box>
        </Box>
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </form>
    </Card>
  );
};

export default SidebarAssignTeam;
