import { formatDateDynamic } from "../../../helper";

function formatLedgerForPDF(ledger, { show_gold }) {
  if (!ledger) {
    return [];
  }

  return ledger.map((entry) => {
    let object = {
      "Order ID": entry.id || "",
      "Date Paid":
        formatDateDynamic(new Date(entry.date_paid), "DD MMM, YYYY hh:ss A") ||
        "",
      Branch: entry?.branch || "",
      // "Payment Mode": entry.mode || "",
      "Payment Mode": {
        title: entry.mode || "",
        subtitle: entry?.transaction_id
          ? `Txn. ID: ${entry?.transaction_id}`
          : "",
      },
      "Amount (Cr.)": entry?.status !== "penalty" ? entry.total : "",
      "Amount (Dr.)": entry?.status === "penalty" ? entry.total : "",
      // Gold: entry.gold || "",
      "Payment Status": entry.status || "",
      // reference: entry.ref || "",
    };
    if (show_gold) object["Gold"] = entry.gold || "";
    return object;
  });
}

function mergeRewardsIntoLedger(rewards, _ledger = []) {
  let ledger = [..._ledger];
  // Reverse the rewards array to insert from the last to keep the order correct after insertion
  rewards
    .slice()
    .reverse()
    .forEach((reward) => {
      // Find the index of the ledger entry after which the reward should be inserted
      const insertAfterIndex = ledger.findIndex(
        (entry) =>
          entry.id == reward.last_order_id ||
          entry.order_id == reward.last_order_id
      );

      // If found, insert the reward or penalty as a new ledger entry after the found index
      if (insertAfterIndex !== -1) {
        const rewardEntry = {
          // id: `reward-${reward.title}`, // Creating a unique ID for the reward/penalty entry
          status: reward.type,
          date_paid: reward?.interval_end || ledger[insertAfterIndex].date_paid, // Using the date of the last payment in the interval
          total: reward.reward_or_penalty_to_pay,
          reward_details: {
            title: reward.title,
            from: reward.from,
            to: reward.to,
            total_paid_by_customer: reward.total_paid_by_customer,
            reward_value: reward.reward_value || reward.penalty_value,
            reward_type: reward.reward_type || reward.penalty_type,
            amount: reward.reward_or_penalty_to_pay,
            type: reward.type,
          },
          gold: `${reward?.gold_weight} grams`,
        };
        // Insert the reward entry into the ledger after the specified index
        ledger.splice(insertAfterIndex + 1, 0, rewardEntry);
      }
    });

  return ledger;
}

const LEDGER_TYPES = {
  reward: "success",
  penalty: "failed",
  payment: "processing",
};

export { formatLedgerForPDF, mergeRewardsIntoLedger, LEDGER_TYPES };
