import { Box } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import {
  PrimaryLabel,
  SecondaryLabelSmall,
} from "../../../../../../helper/utility/Labels";
import RewardTypeSelection from "./RewardTypeSelection";
import CustomIntervalReward from "./CustomIntervalReward";
import FixedPlanReward from "./FixedPlanReward";

const PlanRewards = forwardRef(
  ({ form, editForm, addRewardOpen, setAddRewardOpen }, ref) => {
    const customRef = useRef();
    useImperativeHandle(ref, () => {
      return {
        addPhase() {
          if (form?.reward_type === "custom") {
            customRef?.current?.onAddPhase();
          }
        },
      };
    });
    return (
      <Box>
        {!Boolean(form?.reward_type) && (
          <RewardTypeSelection
            form={form}
            editForm={editForm}
            addRewardOpen={addRewardOpen}
            setAddRewardOpen={setAddRewardOpen}
          />
        )}
        {form?.reward_type === "custom" && (
          <CustomIntervalReward
            ref={customRef}
            form={form}
            editForm={editForm}
            addRewardOpen={addRewardOpen}
            setAddRewardOpen={setAddRewardOpen}
          />
        )}
        {form?.reward_type === "fixed" && (
          <FixedPlanReward
            form={form}
            editForm={editForm}
            addRewardOpen={addRewardOpen}
            setAddRewardOpen={setAddRewardOpen}
          />
        )}
      </Box>
    );
  }
);

export default PlanRewards;
