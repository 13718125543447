import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import SubsectionHeader from "../../../components/SubsectionHeader";
import CustomTable from "../../../../components/CustomTable";
import { Add, Cached, MoreHoriz } from "@mui/icons-material";
import CreateBroadcast from "./components/CreateBroadcast";
import {
  fetchJweroBroadcasts,
  fetchJweroTriggers,
} from "../../../../../redux/actions/marketingActions";
import { useDispatch, useSelector } from "react-redux";
import {
  formatDate,
  isArray,
  isArrayWithValues,
  isObjWithValues,
  validateNumber,
} from "jwero-javascript-utils";
import {
  EmptyState,
  backendApiCall,
  formatServerValue,
} from "../../../../../helper";
import {
  PrimaryLabelExtraSmall,
  SecondaryLabel,
  SecondaryLabelExtraSmall,
  SecondaryLabelSmall,
} from "../../../../../helper/utility/Labels";
import HorizontalStack from "../../../../../helper/utility/HorizontalStack";
import CustomChip from "../../../../components/CustomChip";
import {
  CampaignEmptyStateSvg,
  CampaignIconSvg,
  CampaignLaunchSvg,
  CampaignSvg,
  WhatsappSvg,
} from "../../../../../Svgs";
import { campaignChannelIcon } from "../../../../customers/components/CampaignHistory";
import { CHANNEL_EVENTS } from "../../../constants";
import {
  setGlobalSnackbar,
  setGlobalToast,
} from "../../../../../redux/actions/helperActions";

function Broadcast() {
  const broadcastsList = useSelector((state) => state?.marketing?.broadcasts);
  const fetchingJweroBroadcasts = useSelector(
    (state) => state?.marketing?.fetchingJweroBroadcasts
  );
  const dispatch = useDispatch();
  const [broadcasts, setBroadcasts] = useState([]);

  const triggersList = useSelector((state) => state?.marketing?.triggers);
  const fetchingJweroTriggers = useSelector(
    (state) => state?.marketing?.fetchingJweroTriggers
  );

  useEffect(() => {
    if (!isArrayWithValues(triggersList) && !fetchingJweroTriggers)
      dispatch(fetchJweroTriggers());
  }, []);

  useEffect(() => {
    if (isArray(broadcastsList)) setBroadcasts(broadcastsList);
  }, [broadcastsList]);

  useEffect(() => {
    if (!fetchingJweroBroadcasts && !isArrayWithValues(broadcastsList))
      dispatch(fetchJweroBroadcasts());
  }, []);

  const onResume = async (broadcast) => {
    dispatch(
      setGlobalToast({
        show: true,
        message: `Restarting${
          broadcast?.title ? ` ${broadcast?.title}` : ""
        } broadcast`,
        severity: "info",
      })
    );
    await backendApiCall({
      endpoint: "broadcast/start",
      method: "POST",
      data: {
        broadcast_id: broadcast?.id,
      },
    });
    dispatch(
      fetchJweroBroadcasts({
        onSuccess: () => {
          dispatch(
            setGlobalToast({
              show: true,
              message: `Restarted broadcast${
                broadcast?.title ? ` ${broadcast?.title}` : ""
              } successfully`,
              severity: "success",
            })
          );
        },
        onFailed: () => {
          dispatch(
            setGlobalToast({
              show: true,
              message: `Unable to restart broadcast`,
              severity: "failed",
            })
          );
        },
      })
    );
  };

  return (
    <Box sx={{ mt: 3 }}>
      <SubsectionHeader
        title={"Broadcast List"}
        primaryButtonProps={{
          startIcon: (
            <Cached
              className={fetchingJweroBroadcasts ? "infinite_rotate" : ""}
            />
          ),
          label: "Refresh",
          variant: "outlined",
          disabled: fetchingJweroBroadcasts,
          onClick: () => dispatch(fetchJweroBroadcasts()),
        }}
        right={<CreateBroadcast />}
      />
      <Box sx={{ mt: 4 }}>
        {!isArrayWithValues(broadcasts) && !fetchingJweroBroadcasts && (
          <EmptyState
            icon={
              <CampaignEmptyStateSvg
                style={{ height: "145px", width: "145px" }}
              />
            }
            text={"No Broadcasts created yet"}
          />
        )}
        {(isArrayWithValues(broadcasts) || fetchingJweroBroadcasts) && (
          <CustomTable
            rows={broadcasts}
            tableHeadCells={BroadcastHeads}
            tableRow={CustomTableRow}
            tableRowProps={{
              onResume,
            }}
          />
        )}
      </Box>
    </Box>
  );
}

export default Broadcast;

const CustomTableRow = ({ row, onResume }) => {
  const customerSegments = useSelector((state) => state.chats.customerSegments);
  const broadcast = useSelector((state) => state.chats?.chat_broadcasts);
  const [groups, setGroups] = React.useState([]);

  React.useEffect(() => {
    let groups = [];
    if (row?.meta_data?.segments && isObjWithValues(customerSegments)) {
      let segments = [];
      Object.values(customerSegments)?.forEach((segment) => {
        if (
          row?.meta_data?.segments?.find((i) => i.id + "" === segment.id + "")
        )
          segments.push(segment);
      });
      groups = segments?.map((i) => ({
        value: i.id,
        label: i?.filterForm?.segment_name,
      }));
    }
    if (row.broadcast_info?.title) {
      groups.push({
        label: row.broadcast_info?.title,
        value: row.broadcast_info?.id,
      });
    }
    setGroups(groups);
  }, [row, customerSegments]);
  return (
    <TableRow
      hover
      // onClick={(event) => handleClick(event, row.id)}
      // role="."
      // aria-checked={isItemSelected}
      tabIndex={-1}
      // key={row.id}
      // selected={isItemSelected}
      sx={{ cursor: "pointer" }}
    >
      {/* <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell> */}
      <TableCell
        component="th"
        // id={labelId}
        scope="row"
        // padding="none"
        sx={{ maxWidth: "60px" }}
      >
        {formatServerValue(
          `${row?.template?.name || row?.template?.template_name}`
        )}
      </TableCell>
      <TableCell>
        <SecondaryLabel>
          {groups?.[0]?.label}{" "}
          {groups?.length > 1 ? `+ ${groups?.length - 1}` : ""}
        </SecondaryLabel>
        <SecondaryLabelSmall>
          {row?.total_contacts} Contacts
        </SecondaryLabelSmall>
      </TableCell>
      <TableCell>
        {campaignChannelIcon[row?.channel || "whatsapp"]}
        {/* <WhatsappSvg /> */}
      </TableCell>
      <TableCell align="right">
        <SecondaryLabel sx={{ whiteSpace: "nowrap" }}>
          {formatDate(new Date(row?.created_at), "DD MMM YYYY")}
        </SecondaryLabel>
        <SecondaryLabelSmall>
          {formatDate(new Date(row?.created_at), "hh:mm A")}
        </SecondaryLabelSmall>
      </TableCell>
      <TableCell align="right">
        <CustomChip type={row?.status} label={formatServerValue(row?.status)} />
      </TableCell>
      <TableCell>
        {CHANNEL_EVENTS?.[row?.channel]?.map((i) => {
          let totalContacts = validateNumber(row?.analytics?.[i]);
          let totalContactsPercent =
            Math.round(
              (validateNumber(row?.analytics?.[i]) /
                validateNumber(row?.total_contacts)) *
                100
            ) || 0;
          // if (!totalContacts) return;
          return (
            <HorizontalStack sx={{ marginTop: "4px" }}>
              <PrimaryLabelExtraSmall>
                <strong style={{ color: "grey" }}>{i}</strong>: {totalContacts}{" "}
                Contacts ({totalContactsPercent}%)
              </PrimaryLabelExtraSmall>
            </HorizontalStack>
          );
        })}
      </TableCell>
      {/* <TableCell align="right">
        <SecondaryLabelSmall sx={{ whiteSpace: "nowrap" }}>
          {row?.analytics?.sent} Contacts
        </SecondaryLabelSmall>
        <HorizontalStack spacing={2} justifyContent="flex-end">
          <CircularProgress
            determinate
            variant="determinate"
            value={
              Math.round(
                (validateNumber(row?.analytics?.sent) /
                  validateNumber(row?.total_contacts)) *
                  100
              ) || 0
            }
            style={{ height: 14, width: 14 }}
            classes={{
              colorPrimary: "red",
              barColorPrimary: "green",
              colorSecondary: "blue",
            }}
          />
          <SecondaryLabelSmall>
            {Math.round(
              (validateNumber(row?.analytics?.sent) /
                validateNumber(row?.total_contacts)) *
                100
            ) || 0}
            %
          </SecondaryLabelSmall>
        </HorizontalStack>
      </TableCell>
      <TableCell align="right">
        <SecondaryLabelSmall sx={{ whiteSpace: "nowrap" }}>
          {row?.analytics?.delivered} Contacts
        </SecondaryLabelSmall>
        <HorizontalStack spacing={2} justifyContent="flex-end">
          <CircularProgress
            determinate
            variant="determinate"
            value={Math.round(
              (validateNumber(row?.analytics?.delivered) /
                validateNumber(row?.total_contacts)) *
                100
            )}
            style={{ height: 14, width: 14 }}
            classes={{
              colorPrimary: "red",
              barColorPrimary: "green",
              colorSecondary: "blue",
            }}
          />
          <SecondaryLabelSmall>
            {Math.round(
              (validateNumber(row?.analytics?.delivered) /
                validateNumber(row?.total_contacts)) *
                100
            ) || 0}
            %
          </SecondaryLabelSmall>
        </HorizontalStack>
      </TableCell>
      <TableCell align="right">
        <SecondaryLabelSmall sx={{ whiteSpace: "nowrap" }}>
          {row?.analytics?.read} Contacts
        </SecondaryLabelSmall>
        <HorizontalStack spacing={2} justifyContent="flex-end">
          <CircularProgress
            determinate
            variant="determinate"
            value={
              Math.round(
                (validateNumber(row?.analytics?.read) /
                  validateNumber(row?.total_contacts)) *
                  100
              ) || 0
            }
            style={{ height: 14, width: 14 }}
            classes={{
              colorPrimary: "red",
              barColorPrimary: "green",
              colorSecondary: "blue",
            }}
          />
          <SecondaryLabelSmall>
            {Math.round(
              (validateNumber(row?.analytics?.read) /
                validateNumber(row?.total_contacts)) *
                100
            ) || 0}
            %
          </SecondaryLabelSmall>
        </HorizontalStack>
      </TableCell>
      <TableCell align="right">
        <SecondaryLabelSmall sx={{ whiteSpace: "nowrap" }}>
          {row?.analytics?.failed} Contacts
        </SecondaryLabelSmall>
        <HorizontalStack spacing={2} justifyContent="flex-end">
          <CircularProgress
            determinate
            variant="determinate"
            value={
              validateNumber(
                Math.round(
                  (validateNumber(row?.analytics?.failed) /
                    validateNumber(row?.total_contacts)) *
                    100
                )
              ) || 0
            }
            style={{ height: 14, width: 14 }}
            classes={{
              colorPrimary: "red",
              barColorPrimary: "green",
              colorSecondary: "blue",
            }}
          />
          <SecondaryLabelSmall>
            {validateNumber(
              Math.round(
                (validateNumber(row?.analytics?.failed) /
                  validateNumber(row?.total_contacts)) *
                  100
              )
            ) || 0}
            %
          </SecondaryLabelSmall>
        </HorizontalStack>
      </TableCell> */}
      <TableCell align="right">
        <BroadcastRowActions
          canResume={row?.status === "paused"}
          onResume={() => onResume(row)}
        />
        {/* <IconButton>
          <MoreHoriz />
        </IconButton> */}
      </TableCell>
    </TableRow>
  );
};

function BroadcastRowActions({ onResume, canResume }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditPress = () => {
    onEdit();
    handleClose();
  };
  const onDeletePress = () => {
    onDelete();
    handleClose();
  };

  return (
    <div>
      <IconButton
        // onClick={() => onEditPress(row?.id)}
        size="small"
        // sx={{ backgroundColor: "text.secondary" }}
        onClick={handleClick}
        // onClick={() => onEditPress(row)}
      >
        <MoreHoriz />
      </IconButton>
      {/* <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          disabled={!canResume}
          onClick={() => {
            handleClose();
            onResume();
          }}
        >
          Resume
        </MenuItem>
        {/* <MenuItem onClick={onDeletePress}>Delete</MenuItem> */}
      </Menu>
    </div>
  );
}

const BroadcastHeads = [
  {
    id: "template_name",
    numeric: false,
    disablePadding: true,
    label: "Template Name",
  },
  {
    id: "calories",
    numeric: false,
    disablePadding: true,
    label: "Recipients",
  },
  {
    id: "fat",
    numeric: false,
    disablePadding: true,
    label: "Channel",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  // {
  //   id: "carbs",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Sent By",
  // },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "protein",
    numeric: false,
    disablePadding: true,
    label: "Analytics",
  },
  // {
  //   id: "sent_to",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Sent To",
  // },
  // {
  //   id: "delivered_to",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Delivered To",
  // },
  // {
  //   id: "read_by",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Read By",
  // },
  // {
  //   id: "failed",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Failed",
  // },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];
