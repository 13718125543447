import { useState, useRef } from "react";
import { Paper, Box, Typography } from "@mui/material";
import { useEffect } from "react";
import phoneImg from "../assets/mobile-svg.svg";
import SelectAttributes from "../helper/SelectAttributes";

const TemplatePreview = ({
  currentSelectedTemplate,
  smsText,
  setSmsText,
  initialLoad,
  setInitialLoad,
  attributeField,
  setAttributeField,
  selectAttributeAnchor,
  setSelectAttributeAnchor,
  handleOpenattributeField,
  anchorAttributeRef,
}) => {
  const smsArr = currentSelectedTemplate?.template_data?.data;
  const [currentSms, setCurrentSms] = useState(null);

  const traverseAndFindAttributes = (text) => {
    const pattern = /##.*?##/g;
    const matches = [...text.matchAll(pattern)];

    const resultArray = matches.map((match, i) => ({
      index: i + 1,
      value: "",
    }));
    setAttributeField(resultArray);
  };
  function replaceAttributes(text, attributeObj) {
    const words = text.split(/\s+/);
    let currentIndex = 1;

    for (let i = 0; i < words.length; i++) {
      if (words[i].startsWith("##")) {
        const capturedText = words[i].slice(2);
        const attribute = attributeObj.find(
          (attr) => attr.index === currentIndex
        );

        if (attribute && attribute.value !== "") {
          words[i] =
            `{{${attribute.value}}}` + words[i].slice(capturedText.length + 2);
          currentIndex++;
        }
      }
    }

    return words.join(" ");
  }

  const handleVariable = (text, index) => {
    console.log(text, index, "<<<<<<< text, index");
    if (text?.value) text = text?.value;
    const updatedAttr = attributeField.map((field) =>
      field.index === index ? { ...field, value: text } : field
    );

    setAttributeField(updatedAttr);

    const updatedText = replaceAttributes(smsText?.original, updatedAttr);

    setSmsText((prev) => ({
      ...prev,
      current: updatedText || "",
    }));
  };

  useEffect(() => {
    const filteredSms = smsArr.find((item) => item.active_status === "1");
    const textData =
      filteredSms?.template_data || filteredSms?.template_content || "";
    setCurrentSms(filteredSms);
    if (!smsText && initialLoad) {
      setSmsText({ original: textData, current: textData });
      traverseAndFindAttributes(textData);
    }
    if (!initialLoad) {
      setSmsText({ original: textData, current: textData });
      traverseAndFindAttributes(textData);
    }
    setInitialLoad(false);
  }, [currentSelectedTemplate, smsArr]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "90%",
        height: "600px",
        padding: "5px",
        backgroundImage: `url(${phoneImg})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {currentSms && (
        <Box
          onClick={handleOpenattributeField}
          ref={anchorAttributeRef}
          sx={{
            borderRadius: "15px",
            margin: "10px",
            padding: "10px",
            backgroundColor: "#fff",
            width: "47%",
            "&:hover": {
              cursor: "pointer",
              userSelect: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" sx={{ fontSize: "12px", fontWeight: 600 }}>
              {currentSms?.template_name}
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "10px" }}>
              now
            </Typography>
          </Box>
          <Typography variant="span" sx={{ fontSize: "11px" }}>
            {smsText?.current}
          </Typography>
        </Box>
      )}
      <SelectAttributes
        selectAttributeAnchor={selectAttributeAnchor}
        setSelectAttributeAnchor={setSelectAttributeAnchor}
        attributeField={attributeField}
        setAttributeField={setAttributeField}
        handleVariable={handleVariable}
      />
    </Box>
  );
};

export default TemplatePreview;
