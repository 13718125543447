import axios from "axios";
import { getIp } from "../../App";
import {
  backendApiCall,
  backendApiEndpoints,
  backendDoamin,
  checkCustomizations,
  formatDate,
  getAuthToken,
  getAxiosError,
  getIsBackendMigrated,
  getMainWebsite,
  getRandomString,
  getShippingLocation,
  getShippingMethod,
  getTimezoneTime,
  getToken,
  getWebsite,
  getWooCommerceCustomersHelper,
  isArrayWithValues,
  isObjWithValues,
  nodeWebsite,
  ramdomParams,
  settingsEndpoint,
  sleep,
  updateProductsInGoogle,
  websocketApiCall,
} from "../../helper";
import { EDIT_CHATS } from "./chatActions";
import { SET_CRM_DATA } from "./crmActions";
import { setGlobalSnackbar, setGlobalToast } from "./helperActions";
import { setupPaymentMethods } from "./productActions";
import { backendEndpoints } from "../../pages/chat_new/apiFunctions";
import { getTimeInMilliSeconds } from "jwero-javascript-utils";
import { setUser } from "./userActions";

export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const SET_SIZE_MASTER = "SET_SIZE_MASTER";
export const SET_SIZE_TAB = "SET_SIZE_TAB";
export const SET_PRODUCT_GROUPS = "SET_PRODUCT_GROUPS";

export const editSettings = (payload) => ({ type: EDIT_SETTINGS, payload });

const options = [
  "size_guide",
  "master_pricing",
  "customer_master_pricing",
  "product_defaults",
  "business_settings",
  "diamond_group",
  "size_masters_new",
  "size_masters_info",
  "size_masters_type",
  "team_members",
  "spreadsheet_history",
  "xts-woodmart-options",
  "product_shortcuts",
  "Show_product_customize",
  "breakup_settings",
  "woocommerce_specific_ship_to_countries",
  "meta_integration",
  "google_integration",
  "customer_labels",
  "retailer_invites",
  "crm_grouping_names",
  "jwero_notifications",
  "ornate_integration",
  "google_spreadsheet",
  // "ornate_product_details",
  // "ornate_history",
  "tryb4ubuy_integration",
  "camweara_integration",
  "onesignal_integration",
  "woocommerce_razorpay_settings",
  "woocommerce_cashfree_settings",
  "branch",
  "woocommerce_ccavenue_settings",
  "woocommerce_checkout_page_id",
  "woocommerce_payeasebuzz_settings",
  "woocommerce_payumbolt_settings",
  "whatsapp_botsettings",
  "whatsapp_assigned_templates",
  "smtp_mail",
  "seo_master_settings",
  "catalog_settings",
  "instore_settings",
  "crm_spreadsheet_history",
  "digigold_gifts",
  "digigold_cancelled_plans",
  "digigold_user_rewards",
  "ornate_api_calls",
  "integration_attributes",
  "woocommerce_integration",
  "ebay_integration",
  "amazon_integration",
  "canned_messages",
  "product_groups",
  "crm_invites",
  "broadcast_list",
  "chats_groupings",
  "merge_profiles",
  "chats_spreadsheet_history",
  "shopify_integration",
  "mirrAR_integration",
  "woocommerce_mapping",
  "shopify_mapping",
  "ebay_shop_settings",
  "jemisys_integration",
  "api_calls",
  "exotel_integration",
  "promotion_stepper",
  "sms_integration",
  "paysharp_integration",
  "misc_product_settings",
  "msg91_integration",
  "branding_detail",
  "backend_migrated",
  "unicommerce_integration",
  "jwero_sheet_sync",
  "webchat_integration",
  "mloyal_integration",
  "gupshup_integration",
  "loylty_rewardz_integration",
  "woocommerce_prices_include_tax",
  "jwero_shipping_rates",
];

export const fetchAllSettings = (props) => {
  props = props || {};
  let { setLoading, signout } = props;
  return async (dispatch, getState) => {
    const token = await getToken();
    const website = await getWebsite();

    if (token && website) {
      let ip = await getIp();
      try {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            fetchingAllSettings: true,
          },
        });
        setLoading && setLoading(true);
        let data;
        if (true) {
          // if (!getIsBackendMigrated("chats")) {

          let { data: _data } = await axios({
            url: `${website}/wp-json/store/v1/settings`,
            headers: {
              Authorization: `Basic ${token}`,
            },
            params: { options: options.join() },
          });
          data = _data;
        } else {
          let _data = await backendApiCall({
            // url: `${website}/settings`,
            endpoint: backendApiEndpoints.settings,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: { options: options.join() },
            extractDataFromResponse: true,
          });

          data = _data;
          data = {
            success: true,
            data: _data,
          };
        }

        if (data && data.success && isObjWithValues(data.data)) {
          const state = getState();

          let {
            size_guide,
            master_pricing,
            customer_master_pricing,
            product_defaults,
            business_settings,
            diamond_group,
            size_masters_new,
            size_masters_info,
            size_masters_type,
            team_members,
            spreadsheet_history,
            woocommerce_specific_ship_to_countries,
            breakup_settings,
            Show_product_customize,
            product_shortcuts,
            meta_integration,
            google_integration,
            customer_labels,
            retailer_invites,
            crm_grouping_names,
            jwero_notifications,
            ornate_integration,
            google_spreadsheet,
            // ornate_product_details,
            // ornate_history,
            tryb4ubuy_integration,
            camweara_integration,
            onesignal_integration,
            woocommerce_razorpay_settings,
            woocommerce_cashfree_settings,
            branch,
            woocommerce_ccavenue_settings,
            woocommerce_checkout_page_id,
            woocommerce_payeasebuzz_settings,
            woocommerce_payumbolt_settings,
            whatsapp_botsettings,
            whatsapp_assigned_templates,
            smtp_mail,
            seo_master_settings,
            catalog_settings,
            instore_settings,
            crm_spreadsheet_history,
            digigold_gifts,
            digigold_cancelled_plans,
            digigold_user_rewards,
            ornate_api_calls,
            integration_attributes,
            woocommerce_integration,
            ebay_integration,
            amazon_integration,
            canned_messages,
            product_groups,
            crm_invites,
            broadcast_list,
            chats_groupings,
            merge_profiles,
            chats_spreadsheet_history,
            shopify_integration,
            mirrAR_integration,
            woocommerce_mapping,
            shopify_mapping,
            ebay_shop_settings,
            jemisys_integration,
            api_calls,
            exotel_integration,
            promotion_stepper,
            sms_integration,
            paysharp_integration,
            misc_product_settings,
            msg91_integration,
            branding_detail,
            unicommerce_integration,
            jwero_sheet_sync,
            loylty_rewardz_integration,
            mloyal_integration,
            gupshup_integration,
            woocommerce_prices_include_tax,
            jwero_shipping_rates,
          } = data.data;

          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              ip,
              sizeMasters: size_guide,
              customerMasterPricing: customer_master_pricing,
              masterPricing: master_pricing,
              productDefaults: product_defaults,
              productSettings: business_settings,
              allDiamondGroups: diamond_group,
              size_masters_type,
              sizeMastersNew:
                size_masters_type === "new"
                  ? size_masters_info
                  : size_masters_new,
              teamMembers: team_members || [],
              spreadsheetHistory: spreadsheet_history,
              shipToCountries: woocommerce_specific_ship_to_countries,
              breakupSettings: breakup_settings,
              productCustomize: Show_product_customize,
              allowed_customization: checkCustomizations(
                Show_product_customize
              ),
              productShortcuts: product_shortcuts,
              websiteProductSettings: getIsBackendMigrated("chats")
                ? branding_detail
                : data?.data["xts-woodmart-options"],
              metaIntegration: meta_integration,
              googleIntegration: google_integration,
              notifications: isArrayWithValues(jwero_notifications)
                ? jwero_notifications.reverse()
                : [],
              ornateIntegration: ornate_integration,
              googleSpreadsheet: google_spreadsheet,
              // ornateProducts: ornate_product_details,
              // ornateHistory: ornate_history,
              trybeforeyoubuyIntegration: tryb4ubuy_integration,
              camwearaIntegration: camweara_integration,
              mirrARIntegration: mirrAR_integration,

              onesignalIntegration: onesignal_integration,
              woocommerceRazorpaySettings: woocommerce_razorpay_settings,
              woocommerceCashfree_settings: woocommerce_cashfree_settings,
              branch,
              woocommerceCcavenueSettings: woocommerce_ccavenue_settings,
              woocommerce_checkout_page_id: woocommerce_checkout_page_id,
              woocommercePayeasebuzzSettings: woocommerce_payeasebuzz_settings,
              woocommercePayumboltSettings: woocommerce_payumbolt_settings,
              whatsappBotSettings: whatsapp_botsettings,
              whatsappAssignedTemplates: whatsapp_assigned_templates,
              smtpMail: smtp_mail,
              seoMasterSettings: seo_master_settings,
              catalogSettings: catalog_settings,
              instoreSettings: instore_settings,
              digigoldGifts: digigold_gifts,
              digigoldCancelledPlans: digigold_cancelled_plans,
              digigold_user_rewards: digigold_user_rewards,
              ornateApiCalls: ornate_api_calls,
              integrationAttributes: integration_attributes,

              wooCommerceIntegration: !state.settings.wooCommerceIntegration
                ?.integrated
                ? woocommerce_integration
                : state.settings.wooCommerceIntegration,

              shopifyIntegration: !state.settings.shopifyIntegration?.integrated
                ? shopify_integration
                : state.settings.shopifyIntegration,
              ebayIntegration: ebay_integration,
              amazonIntegration: amazon_integration,
              productGroups: product_groups,
              woocommerceMapping: woocommerce_mapping,
              shopifyMapping: shopify_mapping,
              ebaySettings: ebay_shop_settings,
              jemisysIntegration: jemisys_integration,
              apiCalls: api_calls,
              exotelIntegration: exotel_integration,
              promotionStepper: promotion_stepper,
              smsIntegration: sms_integration,
              paysharpIntegration: paysharp_integration,
              miscProductSettings: misc_product_settings,
              msg91Integration: msg91_integration,
              unicommerce_integration,
              jweroSheetSync: jwero_sheet_sync,
              loyltyRewardzIntegration: loylty_rewardz_integration,
              mloyalIntegration: mloyal_integration,
              gupshupIntegration: gupshup_integration,
              shouldAddProductTax: woocommerce_prices_include_tax === "no",

              shippingRates: jwero_shipping_rates,
            },
          });
          dispatch({
            type: SET_CRM_DATA,
            payload: {
              allCustomerLabels: customer_labels,
              retailer_invites: Object.values(retailer_invites || {}),
              crmGrouping: crm_grouping_names,
              crmSpreadsheetHistory: crm_spreadsheet_history,
              crmInvites: Object.values(crm_invites || {}),
            },
          });
          dispatch({
            type: EDIT_CHATS,
            payload: {
              cannedMessages: canned_messages,
              broadcastList: broadcast_list,
              chatsGroupings: chats_groupings,
              mergeProfiles: merge_profiles,
              chatsSpreadsheetHistory: chats_spreadsheet_history,
            },
          });
        }
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            fetchingAllSettings: false,
            allSettingsFetched: true,
          },
        });

        dispatch(setupPaymentMethods());
        setLoading && setLoading(false);
      } catch (error) {
        signout && signout();
      }
    } else signout && signout();
  };
};

const newDataBaseOptions = [
  "chat_broadcasts",
  "chat_tags",
  "custom_fields",
  "webchat_integration",
];

export const fetchAllNewSettings = (props) => {
  return async (dispatch, getState) => {
    props = props || {};
    let { setLoading, signout } = props;
    let authToken = getAuthToken();
    let chatsSettings = ["chat_broadcasts", "chat_tags"];

    dispatch({
      type: EDIT_SETTINGS,
      payload: { fetchingMainSettings: true },
    });
    let data = await backendApiCall({
      // url: `${website}/settings`,
      endpoint: backendApiEndpoints.settings,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: { options: newDataBaseOptions.join() },
    });

    let chatsObject = {};
    if (data?.success) {
      Object.entries(data?.data || {})?.forEach(([key, value]) => {
        if (chatsSettings?.includes(key)) chatsObject[key] = value;
      });
    }
    let { custom_fields, webchat_integration } = data?.data || {};

    dispatch({
      type: EDIT_CHATS,
      payload: chatsObject,
    });
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        custom_fields,
        webchatIntegration: webchat_integration,
        fetchingMainSettings: false,
      },
    });
  };
};

export const fetchTeamMembers = () => {
  return async (dispatch) => {
    try {
      let res = await backendApiCall({
        endpoint: "sub_user",
      });

      if (res?.success)
        dispatch(
          setUser({
            team_members: res?.data,
          })
        );
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
};

export const fetchProductCategories = () => {
  return async (dispatch) => {
    const token = await getToken();
    const website = await getWebsite();
    if (token && website) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingProductCategories: true,
        },
      });
      try {
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/products/categories`,
          headers: {
            Authorization: `Basic ${token}`,
          },
        });
        if (data && isArrayWithValues(data)) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              productCategories: data,
            },
          });
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingProductCategories: false,
        },
      });
    }
  };
};

export const addProductCategories = ({ payload }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const productCategories = state.settings.productCategories;
    const token = await getToken();
    const website = await getWebsite();
    if (token && website) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          updatingProductCategories: true,
        },
      });
      try {
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/products/categories`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "POST",
          data: payload,
        });
        if (data && isObjWithValues(data)) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              productCategories: [data]?.concat(productCategories),
            },
          });
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          updatingProductCategories: false,
        },
      });
    }
  };
};

export const updateProductCategories = ({ payload, id }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const productCategories = state.settings.productCategories;
    const token = await getToken();
    const website = await getWebsite();
    if (token && website) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          updatingProductCategories: true,
        },
      });
      try {
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/products/categories/${id}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: payload,
        });
        if (data && isObjWithValues(data)) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              productCategories: productCategories?.map((item) => {
                if (item?.id === data?.id) return data;
                else return item;
              }),
            },
          });
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          updatingProductCategories: false,
        },
      });
    }
  };
};

export const deleteProductCategories = ({ id }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const productCategories = state.settings.productCategories;
    const token = await getToken();
    const website = await getWebsite();
    if (token && website) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          deletingProductCategories: true,
        },
      });
      try {
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/products/categories/${id}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "DELETE",
          params: { force: true },
        });
        if (data && isObjWithValues(data)) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              productCategories: productCategories?.filter(
                (item) => item?.id !== data?.id
              ),
            },
          });
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          deletingProductCategories: false,
        },
      });
    }
  };
};

export const getQuickReplies = () => {
  return async (dispatch) => {
    try {
      let res = await backendApiCall({
        endpoint: "quick_replies",
      });

      if (res?.success)
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            quick_replies: res?.data,
            fetched_quick_replies: true,
          },
        });
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
};

export const getJweroSheetSync = () => {
  return async (dispatch) => {
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        loadingJweroSheetSync: true,
      },
    });
    try {
      let res = await settingsEndpoint({
        endpoint: "jwero_sheet_sync",
      });
      if (isObjWithValues(res)) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            jweroSheetSync: res,
          },
        });
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          loadingJweroSheetSync: false,
        },
      });
    }
  };
};

export const deprecated_fetchDashboardDetails = () => {
  return async (dispatch) => {
    const token = await getToken();
    const website = await getWebsite();
    if (token && website) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingDashboardDetails: true,
        },
      });
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/dashboard`,
          headers: {
            Authorization: `Basic ${token}`,
          },
        });
        if (data && data.success && isObjWithValues(data.data)) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              dashboardDetails: data.data,
            },
          });
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingDashboardDetails: false,
        },
      });
    }
  };
};
export const fetchDashboardDetails = () => {
  return async (dispatch) => {
    if (!getIsBackendMigrated("chats") || true)
      return dispatch(deprecated_fetchDashboardDetails());
    const token = await getToken();
    if (token) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingDashboardDetails: true,
        },
      });
      try {
        let { data } = await axios({
          url: `${backendDoamin}/${backendApiEndpoints.dashboard}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (data && data.success && isObjWithValues(data.data)) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              dashboardDetails: data.data,
            },
          });
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingDashboardDetails: false,
        },
      });
    }
  };
};

export const addDigigoldGift = (productId, customerId, obj, { onSuccess }) => {
  return async (dispatch) => {
    const token = await getToken();
    const website = await getWebsite();
    if (token && website) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          addingDigigoldGift: true,
        },
      });
      try {
        console.log({ [`${productId}_${customerId}`]: obj });
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/digigold_gifts`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: {
            type: "digigold_gifts",
            data: { [`${productId}_${customerId}`]: obj },
          },
        });
        console.log(data, "<<<<<");
        if (data && data.success && isObjWithValues(data.data)) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              digigoldGifts: data.data,
            },
          });
          onSuccess && onSuccess();
        }
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            addingDigigoldGift: false,
          },
        });
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };
};
export const addDigigoldPlanCancel = (
  productId,
  customerId,
  obj,
  options = {}
) => {
  let { onSuccess } = options;
  return async (dispatch) => {
    const token = await getToken();
    const website = await getWebsite();
    if (token && website) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          addingDigigoldCancel: true,
        },
      });
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/digigold_cancelled_plans`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: {
            type: "digigold_cancelled_plans",
            data: {
              [obj.id ||
              `${productId}_${customerId}_${getTimeInMilliSeconds()}`]: obj,
            },
          },
        });
        console.log(data, "<<<<<");
        if (data && data.success && isObjWithValues(data.data)) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              digigoldCancelledPlans: data.data,
            },
          });
          onSuccess && onSuccess();
        }
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            addingDigigoldCancel: false,
          },
        });
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };
};

export const fetchOrnateProducts = () => {
  return async (dispatch) => {
    const token = await getToken();
    const website = await getWebsite();
    if (token && website) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingOrnateProducts: true,
        },
      });
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/settings?asdf`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: { options: `ornate_product_details` },
      });
      if (
        data &&
        data.success &&
        isObjWithValues(data?.data?.ornate_product_details)
        // &&
        // !data.data.ornate_product_details
      ) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            ornateProducts: data.data.ornate_product_details,
          },
        });
      }
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingOrnateProducts: false,
          fetchedOrnateProducts: true,
        },
      });
    }
  };
};

export const getOrnateProductsFromTanika = () => {
  return async (dispatch) => {
    try {
      let website = await getWebsite();
      let token = await getToken();
      let array = [];
      let page = 1;
      let per_page = 1000;
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingOrnateProductsInTanika: true,
        },
      });
      // eslint-disable-next-line no-unused-vars
      for await (let _i of Array.from(new Array(100))) {
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/products`,
          headers: { Authorization: `Basic ${token}` },
          params: {
            _fields: "id,sku,date_modified,stock_status",
            per_page,
            page,
            "custom_filter[0][key]": "ornate_unique_id",
            "custom_filter[0][value]": "",
            "custom_filter[0][compare]": "LIKE",
          },
        });
        if (isArrayWithValues(data)) {
          array = array.concat(data);
          if (data.length < per_page) break;
          await sleep(page < 10 ? page * 1000 : 3000);
        } else break;
        page++;
      }
      // return array;
      if (isArrayWithValues(array))
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            ornateProductsInTanika: array,
          },
        });
      else
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            ornateProductsInTanika: [],
          },
        });
    } catch (error) {
      console.log(getAxiosError(error));
    }
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        ornateProductsInTanikaFetched: true,
        fetchingOrnateProductsInTanika: false,
      },
    });
  };
};

export const fetchOrnateHistory = (port) => {
  return async (dispatch) => {
    const token = await getToken();
    const website = await getWebsite();
    if (token && website) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingOrnateHistory: true,
        },
      });
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/settings`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: { options: `ornate_history_${port}` },
      });
      if (data && data.success && isObjWithValues(data.data)) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            ornateHistory: data.data[`ornate_history_${port}`],
          },
        });
      }
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingOrnateHistory: false,
        },
      });
    }
  };
};

// export const getSizeMastsers = () => {
//   return (dispatch) => {
//     const token = getToken();
//     const website = getWebsite();
//     if (token && website) {
//       axios({
//         url: `${website}/wp-json/store/v1/settings/size_guide`,
//         headers: {
//           Authorization: `Basic ${token}`,
//         },
//       }).then(async (res) => {
//         let { data } = res;
//         if (data.success && isObjWithValues(data.data)) {
//           return dispatch({
//             type: SET_SIZE_MASTER,
//             payload: data.data,
//           });
//         } else {
//           let data = await updateDefaultSizeMasters(website, token);
//           if (isObjWithValues(data.data)) {
//             return dispatch({
//               type: SET_SIZE_MASTER,
//               payload: data.data,
//             });
//           }
//         }
//       });
//     }
//   };
// };
export const updateSizeMastsers = (obj, onUpdate) => {
  if (!isObjWithValues(obj)) return;
  return (dispatch, getState) => {
    dispatch({
      type: EDIT_SETTINGS,
      payload: { updatingSizeMasters: true },
    });
    const token = getToken();
    const website = getWebsite();
    if (token && website) {
      axios({
        url: `${website}/wp-json/store/v1/settings/size_guide`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "POST",
        data: { type: "size_guide", data: obj },
      })
        .then((res) => {
          let { data } = res;
          if (isObjWithValues(data.data)) {
            dispatch({
              type: SET_SIZE_MASTER,
              payload: data.data,
            });
            onUpdate && onUpdate();
          }
        })
        .catch((e) => console.log(getAxiosError(e)))
        .finally(() => {
          dispatch({
            type: EDIT_SETTINGS,
            payload: { updatingSizeMasters: false },
          });
        });
    }
  };
};
// export const getSizeTabs = () => {
//   return async (dispatch) => {
//     const token = getToken();
//     const website = getWebsite();
//     if (token && website) {
//       axios({
//         url: `${website}/wp-json/store/v1/settings/setting_size_tab`,
//         headers: {
//           Authorization: `Basic ${token}`,
//         },
//       }).then(async (res) => {
//         let { data } = res;
//         if (isObjWithValues(data.data)) {
//           return dispatch({
//             type: SET_SIZE_TAB,
//             payload: data.data,
//           });
//         }
//         if (!data.data) {
//           let data = await updateSizeTabs(website, token);
//           if (isObjWithValues(data.data)) {
//             return dispatch({
//               type: SET_SIZE_TAB,
//               payload: data.data,
//             });
//           }
//         }
//       });
//     }
//   };
// };

export const updateProductDefaultSettings = (settings, setToast) => {
  return (dispatch) => {
    const token = getToken();
    const website = getWebsite();
    if (token && website) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: { updatingProductDefaultSettings: true },
      });
      axios({
        url: `${website}/wp-json/store/v1/settings/product_defaults`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "POST",
        data: { type: "product_shortcuts", data: settings },
      })
        .then((res) => {
          let { data } = res;
          if (data) {
            setToast &&
              setToast({
                show: true,
                severity: "success",
                message: "Product shortcuts updated.",
              });
            dispatch({
              type: EDIT_SETTINGS,
              payload: { productDefaults: data.data },
            });
          }
        })
        .catch((e) => {
          setToast &&
            setToast({
              show: true,
              severity: "error",
              message: "Couldn't update product shortcuts! try again later.",
            });
          console.log(getAxiosError(e));
        })
        .finally(() => {
          dispatch({
            type: EDIT_SETTINGS,
            payload: { updatingProductDefaultSettings: false },
          });
        });
    }
  };
};
export const getProductDefaultSettings = () => {
  return (dispatch) => {
    const token = getToken();
    const website = getWebsite();

    if (token && website) {
      console.log(Math.floor(Date.now() / 1000));
      dispatch({
        type: EDIT_SETTINGS,
        payload: { fetchingProductDefaultSettings: true },
      });

      axios({
        url: `${website}/wp-json/store/v1/settings/product_defaults`,
        headers: {
          Authorization: `Basic ${token}`,
        },
      })
        .then((res) => {
          let { data } = res;

          if (data && data.type === "success") {
            dispatch({
              type: EDIT_SETTINGS,
              payload: { productDefaults: data.data },
            });
          }
        })
        .catch((e) => {
          console.log(getAxiosError(e));
        })
        .finally(() => {
          dispatch({
            type: EDIT_SETTINGS,
            payload: { fetchingProductDefaultSettings: false },
          });
        });
    }
  };
};
export const getSubscriptionDetails = () => {
  let website = getWebsite();
  let token = getToken();
  if (!website || !token) return false;
  return async (dispatch) => {
    console.log("start");
    try {
      dispatch({
        type: EDIT_SETTINGS,
        payload: { fetchingSubscriptionDetails: true },
      });
      const res = await axios({
        url: `${website}/wp-json/store/v1/subscription`,
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      console.log(res?.data);

      if (res.data?.data) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            subscriptionDetails: res?.data?.data,
            fetchingSubscriptionDetails: false,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: { fetchingSubscriptionDetails: false },
      });
    }
    console.log("end");
  };
};
export const updateProductGroups = (obj, onSuccess) => {
  return (dispatch) => {
    const token = getToken();
    const website = getWebsite();
    if (token && website) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: { updatingProductGroups: true },
      });
      axios({
        url: `${website}/wp-json/store/v1/settings/product_groups`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "POST",
        data: { type: "product_groups", data: obj },
      })
        .then((res) => {
          let { data } = res;
          if (data && data.success) {
            dispatch({
              type: EDIT_SETTINGS,
              payload: { productGroups: data.data },
            });
          }
          onSuccess && onSuccess();
        })
        .catch((e) => {
          console.log(getAxiosError(e));
        })
        .finally(() => {
          dispatch({
            type: EDIT_SETTINGS,
            payload: { updatingProductGroups: false },
          });
        });
    }
  };
};

export const getProductGroups = () => {
  return (dispatch) => {
    const token = getToken();
    const website = getWebsite();
    if (token && website) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: { fetchingProductGroups: true },
      });
      axios({
        url: `${website}/wp-json/store/v1/settings/product_groups`,
        headers: {
          Authorization: `Basic ${token}`,
        },
      })
        .then((res) => {
          let { data } = res;
          if (data && data.success) {
            dispatch({
              type: EDIT_SETTINGS,
              payload: { productGroups: data.data },
            });
          }
        })
        .catch((e) => {
          console.log(getAxiosError(e));
        })
        .finally(() => {
          dispatch({
            type: EDIT_SETTINGS,
            payload: { fetchingProductGroups: false },
          });
        });
    }
  };
};

export const getIntegrations = () => {
  return (dispatch) => {
    const token = getToken();
    const website = getWebsite();
    if (token && website) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: { fetchingIntegrations: true },
      });
      axios({
        url: `${website}/wp-json/store/v1/apps/?apps=gupshup,google`,
        headers: {
          Authorization: `Basic ${token}`,
        },
      })
        .then((res) => {
          let { data } = res;
          console.log(JSON.stringify(data.data, null, 2));
          if (data.success) {
            dispatch({
              type: EDIT_SETTINGS,
              payload: { integrations: data.data },
            });
          }
        })
        .catch((e) => {
          console.log(getAxiosError(e));
        })
        .finally(() => {
          dispatch({
            type: EDIT_SETTINGS,
            payload: { fetchingIntegrations: false },
          });
        });
    }
  };
};

export const updateGoogleAccessTokenInRedux = (access_token) => {
  return (dispatch, getState) => {
    const state = getState();
    const integrations = state.settings.integrations;
    if (isArrayWithValues(integrations)) {
      let google = integrations.find((i) => i.google);
      google = (isObjWithValues(google) && google.google) || {};
      if (isObjWithValues(google) && google.google_access_token) {
        let obj = { google: { ...google, google_access_token: access_token } };
        let updatedIntegrations = integrations.map((i) => (i.google ? obj : i));
        console.log(updatedIntegrations);
        dispatch({
          type: EDIT_SETTINGS,
          payload: { integrations: updatedIntegrations },
        });
      }
    }
  };
};
export const updateGupshupInRedux = (newObj) => {
  return (dispatch, getState) => {
    const state = getState();
    const integrations = state.settings.integrations;
    if (isArrayWithValues(integrations)) {
      let obj = { gupshup: { ...newObj } };
      let updatedIntegrations = integrations.map((i) => (i.gupshup ? obj : i));
      console.log(updatedIntegrations);
      dispatch({
        type: EDIT_SETTINGS,
        payload: { integrations: updatedIntegrations },
      });
    }
  };
};
export const deleteGoogleIntegrationFromRedux = () => {
  return (dispatch, getState) => {
    const state = getState();
    const integrations = state.settings.integrations;
    if (isArrayWithValues(integrations)) {
      let updatedIntegrations = integrations.filter((i) => !i.google);
      dispatch({
        type: EDIT_SETTINGS,
        payload: { integrations: updatedIntegrations },
      });
    }
  };
};

export const deprecated_addTeamMember = (data, closeForm, onSuccess) => {
  return (dispatch, getState) => {
    const website = getWebsite();
    const token = getToken();
    const state = getState();
    const mainSocket = state.socket.main_socket;
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        addingTeamMember: true,
      },
    });
    if (website && token)
      axios({
        url: `${website}/wp-json/store/v1/settings/team_members`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "POST",
        data: { type: "team_members", data },
      })
        .then((res) => {
          console.log(res.data, "<<<<<<<<");
          let { data } = res;
          if (isArrayWithValues(data.data)) {
            dispatch({
              type: EDIT_SETTINGS,
              payload: {
                teamMembers: data.data,
              },
            });
          }
          closeForm && closeForm(false);
          onSuccess && onSuccess();
          mainSocket &&
            mainSocket.emit("update_settings", { website: website });
        })
        .catch((e) => console.log(getAxiosError(e)))
        .finally(() => {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              addingTeamMember: false,
            },
          });
        });
  };
};
export const addTeamMember = (data, closeForm, onSuccess) => {
  return async (dispatch, getState) => {
    const website = getWebsite();
    if (!getIsBackendMigrated("chats")) {
      dispatch(deprecated_addTeamMember(data, closeForm, onSuccess));
      return;
    }
    const state = getState();
    const mainSocket = state.socket.main_socket;
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        addingTeamMember: true,
      },
    });
    try {
      let res = await settingsEndpoint({
        endpoint: "team_members",
        method: "POST",
        data,
      });
      if (res) {
        if (isArrayWithValues(res)) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              teamMembers: res,
            },
          });
        }
        closeForm && closeForm(false);
        onSuccess && onSuccess();
        mainSocket && mainSocket.emit("update_settings", { website: website });
      }
    } catch (error) {
      console.log(getAxiosError(error));
    } finally {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          addingTeamMember: false,
        },
      });
    }
  };
};
// export const getTeamMembers = () => {
//   return (dispatch) => {
//     const website = getWebsite();
//     const token = getToken();
//     dispatch({
//       type: EDIT_SETTINGS,
//       payload: {
//         fetchingTeamMembers: true,
//       },
//     });
//     if (website && token)
//       axios({
//         url: `${website}/wp-json/store/v1/settings/team_members`,
//         headers: {
//           Authorization: `Basic ${token}`,
//         },
//       })
//         .then((res) => {
//           let { data } = res;
//           if (data.success) {
//             dispatch({
//               type: EDIT_SETTINGS,
//               payload: {
//                 teamMembers: data.data,
//               },
//             });
//           }
//         })
//         .finally(() => {
//           dispatch({
//             type: EDIT_SETTINGS,
//             payload: {
//               fetchingTeamMembers: false,
//             },
//           });
//         });
//   };
// };

// export const getSpreadsheetHistory = () => {
//   return (dispatch) => {
//     const website = getWebsite();
//     const token = getToken();
//     dispatch({
//       type: EDIT_SETTINGS,
//       payload: {
//         fetchingSyncHistory: true,
//       },
//     });
//     if (website && token)
//       axios({
//         url: `${website}/wp-json/store/v1/settings/spreadsheet_history`,
//         headers: {
//           Authorization: `Basic ${token}`,
//         },
//       })
//         .then((res) => {
//           let { data } = res;
//           if (
//             data.success &&
//             data.data &&
//             isArrayWithValues(data.data.spreadsheet_history)
//           )
//             dispatch({
//               type: EDIT_SETTINGS,
//               payload: {
//                 spreadsheetHistory: data.data.spreadsheet_history,
//               },
//             });
//         })
//         .catch((e) => console.log(getAxiosError(e)))
//         .finally(() =>
//           dispatch({
//             type: EDIT_SETTINGS,
//             payload: {
//               fetchingSyncHistory: false,
//             },
//           })
//         );
//   };
// };

export const addSpreadsheetHistory = (allHistory) => {
  return (dispatch) => {
    const website = getWebsite();
    const token = getToken();
    if (website && token)
      axios({
        url: `${website}/wp-json/store/v1/settings/`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: { spreadsheet_history: allHistory },

        method: "POST",
      })
        .then((res) => {
          if (res.data && res.data.data)
            dispatch({
              type: EDIT_SETTINGS,
              payload: {
                spreadsheetHistory: res.data.data,
              },
            });
        })
        .catch((e) => {
          console.log(getAxiosError(e));
        });
  };
};
export const addExtraCharge = (
  data,
  setLoading
  //  setToast, callback
) => {
  return (dispatch) => {
    const website = getWebsite();
    const token = getToken();
    // let customerMasterPricing = state.settings.customerMasterPricing;
    setLoading && setLoading(true);
    if (website && token)
      axios({
        url: `${website}/wp-json/store/v1/settings/master_pricing`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: {
          data: { additional_pricing: { ...data } },
        },
        method: "PUT",
      })
        .then((res) => {
          if (res.data && res.data.data) {
            console.log(res);
            dispatch({
              type: EDIT_SETTINGS,
              payload: {
                masterPricing: { ...res.data.data },
              },
            });
            // setToast({
            //   show: true,
            //   message: "Pricing has been updated!",
            //   severity: "success",
            // });
          }
        })
        .catch((e) => {
          console.log(getAxiosError(e));

          // if (setToast)
          //   setToast({
          //     show: true,
          //     message: "Couldn't update Pricing, try again later!",
          //     severity: "error",
          //   });
        })
        .finally(() => {
          setLoading && setLoading(false);
          // callback && callback();
        });
  };
};

export const addMetalPricingHistory = (obj) => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      await axios({
        url: `${website}/wp-json/store/v1/settings/master_pricing_history`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: {
          type: "master_pricing_history",
          data: { [formatDate(getTimezoneTime(), "dd mm yyyy")]: obj },
        },
        method: "PUT",
      });
    }
  };
};

export const updateMarketPlaceProducts = () => {
  return async (dispatch) => {
    const website = getWebsite();
    const token = getToken();

    if (website && token) {
      try {
        let { data } = await websocketApiCall({
          method: "POST",
          headers: { Authorization: "Basic " + token },
          data: {
            website,
          },
        });
        // let { data } = await axios({
        //   url: `${websocket}/update_marketplace_products`,
        //   method: "POST",
        //   data: {
        //     website,
        //   },
        //   headers: { Authorization: "Basic " + token },
        // });
        return data;
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };
};

export const deprecated_updateMasterPricings = ({
  data,
  setLoading,
  setToast,
  callback,
  afterUpdate,
  updateGoogleProducts,
  skipMasterUpdateHook,
}) => {
  return (dispatch, getState) => {
    const state = getState();
    const website = getWebsite();
    const token = getToken();
    let customerMasterPricing = state.settings.customerMasterPricing;
    let reduxMasterPricing = state.settings.masterPrincing;
    console.log(state.settings.googleIntegration, "<<<<");
    setLoading && setLoading(true);
    if (website && token && !skipMasterUpdateHook) {
      axios({
        url: `${website}/wp-json/store/v1/settings/master_pricing`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: {
          type: "master_pricing",
          data,
        },
        method: "PUT",
      })
        .then(async (res) => {
          if (res.data && res.data.data) {
            try {
              await axios({
                url: `${website}/wp-json/store/v1/settings/customer_master_pricing`,
                headers: {
                  Authorization: `Basic ${token}`,
                },
                data: {
                  type: "customer_master_pricing",
                  data: {
                    ...customerMasterPricing,
                    labour_pricing: data.labour_pricing,
                    gemstone_pricing: data.gemstone_pricing,
                    diamond_pricing: data.diamond_pricing,
                    additional_pricing: data.additional_pricing,
                  },
                },
                method: "POST",
              });
            } catch (error) {}
            if (
              updateGoogleProducts &&
              isObjWithValues(state?.settings?.googleIntegration)
            ) {
              let obj = Object.values(state?.settings?.googleIntegration)
                .reverse()
                .find((i) => i.connected.includes("google_shopping"));
              console.log(obj, state?.settings?.googleIntegration, "<<<<");
              if (isArrayWithValues(obj?.google_shopping)) {
                let selectedMerchantObj =
                  obj?.google_shopping.find((i) => i.selected_merchant_id) ||
                  {};
                console.log(selectedMerchantObj, "<<<<");
                if (selectedMerchantObj?.selected_merchant_id) {
                  await updateProductsInGoogle(
                    selectedMerchantObj.selected_merchant_id
                  );
                }
              }
            }
            dispatch({
              type: EDIT_SETTINGS,
              payload: {
                // customerMasterPricing: customerRes.data.data,
                masterPricing: res.data.data,
              },
            });
            dispatch(
              setGlobalToast({
                show: true,
                message: "Pricing has been updated!",
                severity: "success",
              })
            );
            afterUpdate && (await afterUpdate());
          }
        })
        .catch((e) => console.log(getAxiosError(e)))
        .finally(() => {
          setLoading && setLoading(false);
          callback && callback();
        });
    } else if (website && token) {
      // Saving in redux only to send all changes together when click on Apply Changes(skipMasterUpdateHook = false) in master pricing
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          masterPricing: {
            ...(reduxMasterPricing || {}),
            diamond_pricing: data?.diamond_pricing
              ? data?.diamond_pricing
              : reduxMasterPricing?.diamond_pricing || {},
            gemstone_pricing: data?.gemstone_pricing
              ? data?.gemstone_pricing
              : reduxMasterPricing?.gemstone_pricing || {},
            labour_pricing: data?.labour_pricing
              ? data?.labour_pricing
              : reduxMasterPricing?.labour_pricing || {},
            additional_pricing: data?.additional_pricing
              ? data?.additional_pricing
              : reduxMasterPricing?.additional_pricing || {},
            margin_pricing: data?.margin_pricing
              ? data?.margin_pricing
              : reduxMasterPricing?.margin_pricing || {},
          },
        },
      });
      // dispatch(
      //   setGlobalToast({
      //     show: true,
      //     message: "Pricing has been updated!",
      //     severity: "success",
      //   })
      // );
      async () => afterUpdate && (await afterUpdate());
      setLoading && setLoading(false);
      callback && callback();
    }
    // axios({
    //   url: `${website}/wp-json/store/v1/settings/customer_master_pricing`,
    //   headers: {
    //     Authorization: `Basic ${token}`,
    //   },
    //   data: {
    //     type: "customer_master_pricing",
    //     data: {
    //       ...customerMasterPricing,
    //       labour_pricing: data.labour_pricing,
    //       gemstone_pricing: data.gemstone_pricing,
    //       diamond_pricing: data.diamond_pricing,
    //       additional_pricing: data.additional_pricing,
    //     },
    //   },
    //   method: "POST",
    // })
    //   .then(async (customerRes) => {})
    //   .catch((e) => {
    //     console.log(getAxiosError(e));

    //     dispatch(
    //       setGlobalToast({
    //         show: true,
    //         message: "Couldn't update Pricing, try again later!",
    //         severity: "error",
    //       })
    //     );
    //   })
    //   .finally(() => {
    //     setLoading && setLoading(false);
    //     callback && callback();
    //   });
  };
};
export const getUpdateProductHistory = () => {
  return async (dispatch) => {
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        fetchingUpdateHistory: true,
      },
    });
    try {
      let res = await settingsEndpoint({
        endpoint: "product_price_update_history",
      });
      if (isObjWithValues(res)) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            productPriceUpdateHistory: res,
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingUpdateHistory: false,
        },
      });
    }
  };
};
export const getUpdateProductHistoryRef = () => {
  return async (dispatch) => {
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        fetchingUpdateHistoryRef: true,
      },
    });
    try {
      let res = await settingsEndpoint({
        endpoint: "product_price_update_history_ref",
      });
      if (isObjWithValues(res)) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            productPriceUpdateHistoryRef: res,
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingUpdateHistoryRef: false,
        },
      });
    }
  };
};
export const modifyUpdateProductHistoryRef = ({ data }) => {
  return async (dispatch) => {
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        fetchingUpdateHistoryRef: true,
      },
    });
    try {
      let res = await settingsEndpoint({
        method: "PUT",
        endpoint: "product_price_update_history_ref",
        data: data,
      });
      if (isObjWithValues(res)) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            productPriceUpdateHistoryRef: res,
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingUpdateHistoryRef: false,
        },
      });
    }
  };
};
export const updateMasterPricings = ({
  data,
  setLoading,
  setToast,
  callback,
  afterUpdate,
  updateGoogleProducts,
  skipMasterUpdateHook,
}) => {
  return async (dispatch, getState) => {
    if (!getIsBackendMigrated("chats") || true) {
      dispatch(
        deprecated_updateMasterPricings({
          data,
          setLoading,
          setToast,
          callback,
          afterUpdate,
          updateGoogleProducts,
          skipMasterUpdateHook,
        })
      );
      return;
    }
    let state = getState();
    let customerMasterPricing = state?.settings?.customerMasterPricing || {};
    try {
      let res = await settingsEndpoint({
        endpoint: "master_pricing",
        method: "PUT",
        data: data,
      });
      if (res) {
        await settingsEndpoint({
          endpoint: "customer_master_pricing",
          method: "POST",
          data: {
            ...customerMasterPricing,
            labour_pricing: data.labour_pricing,
            gemstone_pricing: data.gemstone_pricing,
            diamond_pricing: data.diamond_pricing,
            additional_pricing: data.additional_pricing,
          },
        });
      }
      if (
        updateGoogleProducts &&
        isObjWithValues(state?.settings?.googleIntegration)
      ) {
        let obj = Object.values(state?.settings?.googleIntegration)
          .reverse()
          .find((i) => i.connected.includes("google_shopping"));
        console.log(obj, state?.settings?.googleIntegration, "<<<<");
        if (isArrayWithValues(obj?.google_shopping)) {
          let selectedMerchantObj = obj?.google_shopping.find(
            (i) => i.selected_merchant_id
          );
          console.log(selectedMerchantObj, "<<<<");
          if (selectedMerchantObj.selected_merchant_id) {
            await updateProductsInGoogle(
              selectedMerchantObj.selected_merchant_id
            );
          }
        }
      }
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          // customerMasterPricing: customerRes.data.data,
          // masterPricing: res.data.data,
          masterPricing: res,
        },
      });
      dispatch(
        setGlobalToast({
          show: true,
          message: "Pricing has been updated!",
          severity: "success",
        })
      );
      afterUpdate && (await afterUpdate());
    } catch (error) {
      console.log(getAxiosError(error));
    }
    setLoading && setLoading(false);
    callback && callback();
  };
};

export const updateCustomerMasterPricings = ({
  data,
  setLoading,
  setToast,
  callback,
}) => {
  return (dispatch) => {
    const website = getWebsite();
    const token = getToken();
    setLoading && setLoading(true);
    if (website && token)
      axios({
        url: `${website}/wp-json/store/v1/settings/customer_master_pricing`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: {
          type: "customer_master_pricing",
          data,
        },
        method: "POST",
      })
        .then((res) => {
          if (res.data && res.data.data)
            dispatch({
              type: EDIT_SETTINGS,
              payload: {
                customerMasterPricing: res.data.data,
              },
            });
        })
        .catch((e) => console.log(getAxiosError(e)))
        .finally(() => {
          setLoading && setLoading(false);
          callback && callback();
        });
  };
};

// export const getMasterPricings = (props) => {
//   let setLoading = props && props.setLoading;
//   return (dispatch) => {
//     const website = getWebsite();
//     const token = getToken();
//     if (setLoading) setLoading(true);
//     dispatch({
//       type: EDIT_SETTINGS,
//       payload: {
//         fetchingMasterPricing: true,
//       },
//     });
//     if (website && token)
//       axios({
//         url: `${website}/wp-json/store/v1/settings/master_pricing`,
//         headers: {
//           Authorization: `Basic ${token}`,
//         },
//         // data: {
//         //   type: "master_pricing",
//         //   data,
//         // },
//         // method: "POST",
//       })
//         .then((res) => {
//           axios({
//             url: `${website}/wp-json/store/v1/settings/customer_master_pricing`,
//             headers: {
//               Authorization: `Basic ${token}`,
//             },
//           })
//             .then((customerRes) => {
//               if (res.data && res.data.data)
//                 dispatch({
//                   type: EDIT_SETTINGS,
//                   payload: {
//                     masterPricing: res.data.data,
//                     customerMasterPricing: customerRes.data.data,
//                   },
//                 });
//             })
//             .catch((e) => {
//               console.log(getAxiosError(e));
//             })
//             .finally(() => {
//               if (setLoading) setLoading(false);
//               dispatch({
//                 type: EDIT_SETTINGS,
//                 payload: {
//                   fetchingMasterPricing: false,
//                 },
//               });
//             });
//         })
//         .catch((e) => console.log(getAxiosError(e)));
//   };
// };

// export const getCustomerMasterPricings = ({ setLoading }) => {
//   return (dispatch) => {
//     const website = getWebsite();
//     const token = getToken();
//     setLoading && setLoading(true);
//     dispatch({
//       type: EDIT_SETTINGS,
//       payload: {
//         fetchingCustomerMasterPricing: true,
//       },
//     });
//     if (website && token)
//       axios({
//         url: `${website}/wp-json/store/v1/settings/customer_master_pricing`,
//         headers: {
//           Authorization: `Basic ${token}`,
//         },
//         // data: {
//         //   type: "master_pricing",
//         //   data,
//         // },
//         // method: "POST",
//       })
//         .then((res) => {
//           if (res.data && res.data.data)
//             dispatch({
//               type: EDIT_SETTINGS,
//               payload: {
//                 customerMasterPricing: res.data.data,
//               },
//             });
//         })
//         .catch((e) => console.log(getAxiosError(e)))
//         .finally(() => {
//           setLoading && setLoading(false);
//           dispatch({
//             type: EDIT_SETTINGS,
//             payload: {
//               fetchingCustomerMasterPricing: false,
//             },
//           });
//         });
//   };
// };

// export const getProductSettings = (params) => {
//   return (dispatch) => {
//     const website = getWebsite();
//     const token = getToken();
//     if (website && token) {
//       const controller = new AbortController();
//       const signal = controller.signal;
//       dispatch({
//         type: EDIT_SETTINGS,
//         payload: { fectchingProductSettings: true },
//       });
//       axios({
//         url: `${website}/wp-json/store/v1/settings/business_settings`,
//         headers: {
//           Authorization: `Basic ${token}`,
//           // signal,
//         },
//       }).then((res) => {
//         let { data } = res;

//         if (data.type === "success" && isObjWithValues(data.data)) {
//           dispatch({
//             type: EDIT_SETTINGS,
//             payload: {
//               productSettings: { ...data.data },
//             },
//           });
//         }
//       });
//       dispatch({
//         type: EDIT_SETTINGS,
//         payload: {
//           fectchingProductSettings: false,
//         },
//       });
//       // if (true) {
//       //   setFetchingSettings(false);
//       //   let { data } = res;
//       //   if (data.success && isObjWithValues(data.data)) {
//       //     setSettingsFromRedux({ ...data.data });
//       //     updateSettingsInRedux({ ...data.data });
//       //   }
//       // }
//     }
//   };
// };

export const getDropdownData = () => {
  return async (dispatch) => {
    dispatch({
      type: EDIT_SETTINGS,
      payload: { fetchingDropdownData: true },
    });
    axios({
      url: "https://gist.githubusercontent.com/akshy78695/54136a0b7075095a9bcd8a3b2b30b0b4/raw/f1cc92a907b9e47665bb1cdfb78ccd7cdac89c6d/dropdownData3.json",
    })
      .then((res) => {
        dispatch({
          type: EDIT_SETTINGS,
          payload: { dropdownData: res.data },
        });
        // setTimeout(() => {
        // }, 5000);
      })
      .finally(() =>
        dispatch({
          type: EDIT_SETTINGS,
          payload: { fetchingDropdownData: false },
        })
      );
  };
};

////////////////////// product settings /////////////////
// export const getWebsiteProductSettings = () => {
//   return (dispatch) => {
//     dispatch({
//       type: EDIT_SETTINGS,
//       payload: {
//         fetchingwebsiteProductSettings: true,
//       },
//     });
//     axios({
//       url: `${website}/wp-json/store/v1/settings/xts-woodmart-options`,
//       headers: {
//         Authorization: `Basic ${token}`,
//       },
//       method: "GET",
//     }).then((res) => {
//       if (isObjWithValues(res.data.data)) {
//         dispatch({
//           type: EDIT_SETTINGS,
//           payload: {
//             websiteProductSettings: res.data.data,
//             fetchingwebsiteProductSettings: false,
//           },
//         });
//       }
//     });
//   };
// };
// export const getProductShortcutsSettings = () => {
//   return (dispatch) => {
//     dispatch({
//       type: EDIT_SETTINGS,
//       payload: {
//         fetchingProductShortcutsSettings: true,
//       },
//     });
//     axios({
//       url: `${website}/wp-json/store/v1/settings/product_shortcuts`,
//       headers: {
//         Authorization: `Basic ${token}`,
//       },
//       method: "GET",
//     }).then((res) => {
//       if (isObjWithValues(res.data.data)) {
//         dispatch({
//           type: EDIT_SETTINGS,
//           payload: {
//             productShortcuts: res.data.data,
//             fetchingProductShortcutsSettings: false,
//           },
//         });
//       }
//     });
//   };
// };
//
// export const getProductCustomizeSettings = () => {
//   return (dispatch) => {
//     dispatch({
//       type: EDIT_SETTINGS,
//       payload: {
//         fetchingCustomizeSettings: true,
//       },
//     });
//     axios({
//       url: `${website}/wp-json/store/v1/settings/Show_product_customize`,
//       headers: {
//         Authorization: `Basic ${token}`,
//       },
//       method: "GET",
//     }).then((res) => {
//       if (isObjWithValues(res.data.data)) {
//         dispatch({
//           type: EDIT_SETTINGS,
//           payload: {
//             productCustomize: res.data.data,
//             fetchingCustomizeSettings: false,
//           },
//         });
//       }
//     });
//   };
// };
// export const getBreakupSettings = () => {
//   return (dispatch) => {
//     dispatch({
//       type: EDIT_SETTINGS,
//       payload: {
//         fetchingBreakupSettings: true,
//       },
//     });
//     axios({
//       url: `${website}/wp-json/store/v1/settings/breakup_settings`,
//       headers: {
//         Authorization: `Basic ${token}`,
//       },
//       method: "GET",
//     }).then((res) => {
//       if (isObjWithValues(res.data.data)) {
//         dispatch({
//           type: EDIT_SETTINGS,
//           payload: {
//             breakupSettings: res.data.data,
//             fetchingBreakupSettings: false,
//           },
//         });
//       }
//     });
//   };
// };
export const updateProductSettingInRedux = (payload) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        ...payload,
      },
    });
  };
};
// export const getSizeMastersNew = () => {
//   return (dispatch) => {
//     dispatch({
//       type: EDIT_SETTINGS,
//       payload: {
//         fetchingSizeMasterNew: true,
//       },
//     });
//     axios({
//       url: `${website}/wp-json/store/v1/settings/size_masters_info`,
//       headers: {
//         Authorization: `Basic ${token}`,
//       },
//       method: "GET",
//     })
//       .then((res) => {
//         // if (isObjWithValues(res.data.data) && res.data.success) {
//         dispatch({
//           type: EDIT_SETTINGS,
//           payload: {
//             sizeMastersNew: res.data.data,
//             fetchingSizeMasterNew: false,
//           },
//         });
//         // }
//       })
//       .catch((e) => {
//         getAxiosError(e);
//       });
//   };
// };
export const createSizeMaster = (data, isUpdate, afterUpdate) => {
  let website = getWebsite();
  let token = getToken();
  if (!website || !token) return false;
  // console.log(data);
  return (dispatch, getState) => {
    console.log(data);
    let state = getState();
    let size_masters_type = state?.settings?.size_masters_type;
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        creatingSizeMaster: true,
      },
    });
    axios({
      url: `${website}/wp-json/store/v1/settings/${
        size_masters_type === "new" ? "size_masters_info" : "size_masters_new"
      }`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "PUT",
      data: { data: data },
    })
      .then((res) => {
        console.log(res);
        // if (isObjWithValues(res.data.data) && res.data.success) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            sizeMastersNew: res.data.data,
            creatingSizeMaster: false,
          },
        });
        dispatch(
          setGlobalToast({
            show: true,
            message: isUpdate
              ? "Product size has been updated!"
              : "New product size has been added!",
            severity: "success",
          })
        );
        // }
        afterUpdate && afterUpdate();
      })
      .catch((e) => {
        getAxiosError(e);
      });
  };
};

export const addShippingsInRedux = (shipping) => {
  return (dispatch, getState) => {
    let state = getState();
    let shippings = state.settings.shippings;
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        shippings: [...shippings, shipping],
        // fetchingShippings: false,
      },
    });
  };
};
export const updateShippingsInRedux = (shipping) => {
  return (dispatch, getState) => {
    let state = getState();
    let shippings = state.settings.shippings || [];
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        shippings: shippings?.map((obj) =>
          obj?.id === shipping?.id ? shipping : obj
        ),
        // fetchingShippings: false,
      },
    });
  };
};
export const DeleteShippingsInRedux = (id) => {
  return (dispatch, getState) => {
    let state = getState();
    let shippings = state.settings.shippings || [];
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        shippings: shippings?.filter((obj) => obj?.id !== id),
        // fetchingShippings: false,
      },
    });
  };
};
export const getShippings = () => {
  let website = getWebsite();
  let token = getToken();
  if (!website || !token) return false;
  return (dispatch, getState) => {
    const state = getState();
    console.log(state);
    let countries = state.persist.countries;
    let states = state.persist.states;
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        fetchingShippings: true,
      },
    });
    axios({
      url: `${website}/wp-json/wc/v3/shipping/zones`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      params: {
        ...ramdomParams,
      },
      method: "GET",
    }).then(async (res) => {
      if (isArrayWithValues(res.data)) {
        let shippingZones = res.data.map((zone) => ({
          id: zone.id,
          name: zone.name,
        }));
        let shippings = [];
        for await (const shippingObj of shippingZones) {
          try {
            const shippingLoaction = await getShippingLocation(shippingObj.id);
            const shippingMedthods = await getShippingMethod(shippingObj.id);
            let obj = {
              ...shippingObj,
              loaction: shippingLoaction
                .map((loaction) => {
                  if (loaction.type === "country") {
                    let country = countries?.find(
                      (item) => item.code === loaction.code
                    );
                    return country?.label;
                  }
                  if (loaction.type === "state") {
                    let stateLabel = states[loaction.code.substring(0, 2)].find(
                      (obj) => obj.code === loaction.code.slice(-2)
                    );
                    return stateLabel?.label;
                  } else {
                    return loaction?.code;
                  }
                })
                ?.filter(Boolean),
              methods: shippingMedthods,
            };
            shippings.push(obj);
          } catch (error) {
            console.log(getAxiosError(error), "<<<<<<<<<<<<<< shipping error");
          }
        }
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            shippings: shippings,
            fetchingShippings: false,
          },
        });
      }
    });
  };
};

const transformShippingRates = (backendResponse) => {
  const result = [];
  const regions = Object.keys(backendResponse.data);

  regions.forEach((region) => {
    const rateCategories = backendResponse.data[region];
    Object.keys(rateCategories).forEach((rateKey) => {
      const [minOrderValue, maxOrderValue] = rateKey.split(":").map(Number);
      const categories = rateCategories[rateKey];

      // Check if a similar entry already exists in the result
      let existingEntry = result.find(
        (entry) =>
          entry.regions.includes(region) &&
          JSON.stringify(entry.rates) ===
            JSON.stringify([
              { minOrderValue, maxOrderValue, rate: maxOrderValue },
            ])
      );

      if (existingEntry) {
        existingEntry.categories = [
          ...new Set([...existingEntry.categories, ...categories]),
        ];
      } else {
        result.push({
          id: result.length + 1, // Generate a unique ID
          regions: [region],
          rates: [{ minOrderValue, maxOrderValue, rate: maxOrderValue }],
          categories: categories,
        });
      }
    });
  });

  return { shippingRates: result };
};

export const getShippingRates = () => {
  let website = getWebsite();
  let token = getToken();
  if (!website || !token) return false;
  return (dispatch, getState) => {
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        fetchingShippingRates: true,
      },
    });
    axios({
      url: `${website}/wp-json/store/v1/settings/jwero_shipping_rates`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "GET",
    }).then(async (res) => {
      if (isObjWithValues(res.data)) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            shippingRates: res.data,
            fetchingShippingRates: false,
          },
        });
      }
    });
  };
};

export const updateShippingRates = ({ newShippingRates }) => {
  let website = getWebsite();
  let token = getToken();
  if (!website || !token) return false;
  return (dispatch, getState) => {
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        updatingShippingRates: true,
      },
    });
    axios({
      url: `${website}/wp-json/store/v1/settings/jwero_shipping_rates`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "PUT",
      data: {
        data: {
          new: newShippingRates,
        },
      },
    }).then(async (res) => {
      console.log({ res: res.data?.data });
      if (isObjWithValues(res.data?.data)) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            shippingRates: res?.data?.data,
            updatingShippingRates: false,
          },
        });
      }
    });
  };
};

export const removeShippingInRedux = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    let shipping = state.settings.shippings;
    let filteredShipping = shipping.filter((shipping) => shipping.id !== id);
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        shippings: filteredShipping,
        // fetchingShippings: false,
      },
    });
  };
};
// export const getShippingCountry = () => {
//   return (dispatch) => {
//     axios({
//       url: `${website}/wp-json/store/v1/settings/woocommerce_specific_ship_to_countries`,
//       headers: {
//         Authorization: `Basic ${token}`,
//       },
//       method: "GET",
//     }).then((res) => {
//       if (isArrayWithValues(res.data.data)) {
//         dispatch({
//           type: EDIT_SETTINGS,
//           payload: {
//             shipToCountries: res.data.data,
//           },
//         });
//       }
//     });
//   };
// };
export const getCoupons = (props = {}) => {
  return async (dispatch, getState) => {
    let { search, page, refreshing } = props;
    try {
      console.log(1);
      let website = getWebsite();
      let token = getToken();
      let state = getState();

      if (!website || !token) return false;
      // if (state.settings?.totalCouponPages)
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingCoupons: true,
        },
      });
      console.log(2);

      let currentPage = state?.settings?.currentCouponPage || 0;
      let totalPages = state?.settings?.totalCouponPages || 1;

      //increasing page here
      if (!refreshing) currentPage++;

      if (page) currentPage = page;
      if (currentPage > totalPages) return false;

      let res = await axios({
        url: `${website}/wp-json/wc/v3/coupons`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        // params: { abc: Math.random() },
        params: { per_page: 100, page: currentPage, search },
        method: "GET",
      });
      if (isArrayWithValues(res.data)) {
        let preCoupons = state?.settings?.coupons || [];
        if (page === 1 || currentPage === 1) preCoupons = [];
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            coupons: refreshing ? res?.data : preCoupons?.concat(res.data),
            totalCouponsCount: res.headers?.["x-wp-total"],
            totalCouponPages: res.headers?.["x-wp-totalpages"],
            currentCouponPage: currentPage,
          },
        });
      }
    } catch (error) {
      console.log(error, "<<<<<<< coupons error");
    } finally {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingCoupons: false,
        },
      });
    }
  };
};

export const addCouponinRedux = (coupon) => {
  return (dispatch, getState) => {
    let state = getState();
    let copouns = state.settings.coupons || [];
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        coupons: [...copouns, coupon],
        fetchingCoupons: false,
      },
    });
  };
};
export const updateCouponInRedux = (coupon) => {
  return (dispatch, getState) => {
    let state = getState();
    let copouns = state.settings.coupons || [];
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        coupons: copouns.map((obj) =>
          `${obj.id}` === `${coupon.id}` ? coupon : obj
        ),
        fetchingCoupons: false,
      },
    });
  };
};

export const deleteCoupon = (coupon, setLoading, onFinished) => {
  let website = getWebsite();
  let token = getToken();
  if (!website || !token) return false;
  return (dispatch, getState) => {
    const state = getState();
    setLoading && setLoading(true);
    axios({
      url: `${website}/wp-json/wc/v3/coupons/${coupon.id}`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      params: { force: true },
      method: "DELETE",
    })
      .then((res) => {
        let coupons = state.settings.coupons;
        let filteredCoupons = coupons.filter((item) => item.id !== coupon.id);
        if (isObjWithValues(res.data)) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              coupons: filteredCoupons,
              // fetchingCoupons: false,
            },
          });
          dispatch(
            setGlobalToast({
              show: true,
              message: "Coupon Deleted Successfully",
              severity: "success",
            })
          );
        }
      })
      .catch((e) => {
        console.log(getAxiosError(e));
      })
      .finally(() => {
        setLoading && setLoading(false);
        onFinished && onFinished();
      });
  };
};

// export const getMetaIntegration = () => {
//   return (dispatch) => {
//     console.log("geting meta");
//     dispatch({
//       type: EDIT_SETTINGS,
//       payload: {
//         fetchingMetaIntegration: true,
//       },
//     });
//     axios({
//       url: `${website}/wp-json/store/v1/settings/meta_integration`,
//       headers: {
//         Authorization: `Basic ${token}`,
//       },
//       method: "GET",
//     })
//       .then((res) => {
//         const { data } = res;
//         if (data.type === "success") {
//           dispatch({
//             type: EDIT_SETTINGS,
//             payload: {
//               metaIntegration: data.data,
//               fetchingMetaIntegration: false,
//             },
//           });
//         }
//       })
//       .catch((e) => {
//         getAxiosError(e);
//       })
//       .finally(() =>
//         dispatch({
//           type: EDIT_SETTINGS,
//           payload: {
//             fetchingMetaIntegration: false,
//           },
//         })
//       );
//   };
// };

export const getAllTaxes = (removeCache) => {
  return async (dispatch) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      if (removeCache)
        dispatch({ type: EDIT_SETTINGS, payload: { allTaxes: [] } });
      dispatch({ type: EDIT_SETTINGS, payload: { fetchingAllTaxes: true } });
      try {
        let res = await axios({
          url: `${website}/wp-json/wc/v3/taxes`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          params: removeCache ? { random_key: Math.random() } : {},
        });
        let { data } = res;
        if (isArrayWithValues(data))
          dispatch({ type: EDIT_SETTINGS, payload: { allTaxes: data } });
      } catch (error) {
        console.log(getAxiosError(error));
      }
      dispatch({ type: EDIT_SETTINGS, payload: { fetchingAllTaxes: false } });
    }
  };
};

export const addTax = (payload, onSuccess, onError) => {
  return async (dispatch, getState) => {
    let website = await getWebsite();
    let token = await getToken();
    let state = getState();
    let allTaxes = state.settings.allTaxes || [];
    if (website && token) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: { addingTax: true },
      });
      try {
        let res = await axios({
          url: `${website}/wp-json/wc/v3/taxes`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "POST",
          data: payload,
        });
        let { data } = res;
        if (data.id) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: { allTaxes: [...allTaxes, data] },
          });
          onSuccess && onSuccess();
        }
      } catch (error) {
        console.log(getAxiosError(error));
        onError && onError();
      }
      dispatch({
        type: EDIT_SETTINGS,
        payload: { addingTax: false },
      });
    }
  };
};
export const updateTax = (payload, onSuccess, onError) => {
  return async (dispatch, getState) => {
    let website = await getWebsite();
    let token = await getToken();
    let state = getState();
    let allTaxes = state.settings.allTaxes || [];
    if (website && token) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: { addingTax: true },
      });
      try {
        let res = await axios({
          url: `${website}/wp-json/wc/v3/taxes/${payload.id}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: payload,
        });
        let { data } = res;
        if (data.id) {
          let updatedArr = allTaxes.map((i) =>
            `${i.id}` === `${data.id}` ? data : i
          );
          dispatch({
            type: EDIT_SETTINGS,
            payload: { allTaxes: updatedArr },
          });
          onSuccess && onSuccess();
        }
      } catch (error) {
        console.log(getAxiosError(error));
        onError && onError();
      }
      dispatch({
        type: EDIT_SETTINGS,
        payload: { addingTax: false },
      });
    }
  };
};

export const deleteTax = (payload, onSuccess, onError) => {
  return async (dispatch, getState) => {
    let website = await getWebsite();
    let token = await getToken();
    let state = getState();
    let allTaxes = state.settings.allTaxes || [];
    if (website && token) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: { deletingTax: true },
      });
      try {
        let res = await axios({
          url: `${website}/wp-json/wc/v3/taxes/${payload?.id}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          params: {
            force: true,
          },

          method: "DELETE",
        });
        let { data } = res;
        if (data.id) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              allTaxes: allTaxes.filter((obj) => obj?.id !== payload?.id),
            },
          });
          onSuccess && onSuccess();
        }
      } catch (error) {
        console.log(getAxiosError(error));
        onError && onError();
      } finally {
        dispatch({
          type: EDIT_SETTINGS,
          payload: { addingTax: false },
        });
      }
    }
  };
};
export const addDiamondGroup = (payload, onSuccess, onError) => {
  return async (dispatch, getState) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        let res = await axios({
          url: `${website}/wp-json/store/v1/settings/diamond_group`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: { type: "diamond_group", data: payload },
        });
        console.log(res.data);
        let { data } = res;
        if (isObjWithValues(data.data)) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: { allDiamondGroups: data.data },
          });
          onSuccess && onSuccess();
        }
      } catch (error) {
        console.log(getAxiosError(error));
        onError && onError();
      }
    }
  };
};
export const deleteDiamondGroup = (id, setLoading, onSuccess, onError) => {
  return async (dispatch) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        setLoading && setLoading(true);
        let res = await axios({
          url: `${website}/wp-json/store/v1/settings/diamond_group`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "DELETE",
          data: { id },
        });
        console.log(res.data);
        let { data } = res;
        if (isObjWithValues(data.data)) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: { allDiamondGroups: data.data },
          });
          onSuccess && onSuccess();
        }
      } catch (error) {
        console.log(getAxiosError(error));
        onError && onError();
      }
      setLoading && setLoading(false);
    }
  };
};

export const addScheduleActivity = (eventDetails) => {
  let website = getWebsite();
  let token = getToken();
  if (!website || !token) return false;
  return async (dispatch, getState) => {
    const id = `${Date.now()}_${getRandomString(8)}`;

    try {
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings/tanika_autopublisher`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "PUT",
        data: {
          type: "tanika_autopublisher",
          data: {
            [id]: {
              ...eventDetails,
            },
          },
        },
      });

      console.log(res);
      if (res.data?.data) {
        console.log("activity tanika_autopublisher");
        // let dataObj = {};
        // dispatch({
        //   type: EDIT_SETTINGS,
        //   payload: {
        //     userActivities: [...userActivities, {...res.data?.data}],
        //   },
        // });
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
};

// export const getDiamondGroups = (payload) => {
//   return async (dispatch) => {
//     let website = await getWebsite();
//     let token = await getToken();
//     if (website && token) {
//       try {
//         let res = await axios({
//           url: `${website}/wp-json/store/v1/settings/diamond_group`,
//           headers: {
//             Authorization: `Basic ${token}`,
//           },
//         });
//         let { data } = res;
//         if (data.type === "success" && isObjWithValues(data.data)) {
//           dispatch({
//             type: EDIT_SETTINGS,
//             payload: { allDiamondGroups: data.data },
//           });
//         }
//       } catch (error) {
//         console.log(getAxiosError(error));
//       }
//     }
//   };
// };
// export const getGoogleIntegration = () => {
//   return (dispatch) => {
//     dispatch({
//       type: EDIT_SETTINGS,
//       payload: {
//         fetchingGoogleIntegration: true,
//       },
//     });
//     axios({
//       url: `${website}/wp-json/store/v1/settings/google_integration`,
//       headers: {
//         Authorization: `Basic ${token}`,
//       },
//       // method: "GET",
//     })
//       .then((res) => {
//         const { data } = res;
//         if (data.type === "success") {
//           dispatch({
//             type: EDIT_SETTINGS,
//             payload: {
//               googleIntegration: data.data,
//               fetchingMetaIntegration: false,
//             },
//           });
//         }
//       })
//       .catch((e) => {
//         getAxiosError(e);
//       })
//       .finally(() =>
//         dispatch({
//           type: EDIT_SETTINGS,
//           payload: {
//             fetchingGoogleIntegration: false,
//           },
//         })
//       );
//   };
// };

export const getWoocommerceCustomers = (params = {}) => {
  return async (dispatch, getState) => {
    let state = getState();
    let wooCommerceIntegration = state.settings.wooCommerceIntegration;
    dispatch({
      type: EDIT_SETTINGS,
      payload: { fetchingWooCommerceCustomer: true },
    });
    let customers = await getWooCommerceCustomersHelper({
      ...wooCommerceIntegration,
      params: { ...params },
    });
    if (isArrayWithValues(customers?.data)) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: { wooCommerceCustomer: customers?.data },
      });
    }
    console.log(customers);
    dispatch({
      type: EDIT_SETTINGS,
      payload: { fetchingWooCommerceCustomer: false },
    });
  };
};

export const updateMiscProductSettings = (payload) => {
  return async (dispatch) => {
    dispatch(
      editSettings({
        updatingMiscProductSettings: true,
      })
    );
    let settings = await settingsEndpoint({
      method: "PUT",
      data: payload,
      endpoint: "misc_product_settings",
    });
    dispatch(
      editSettings({
        updatingMiscProductSettings: false,
      })
    );
    if (settings)
      dispatch(
        setGlobalToast({
          show: true,
          message: "Settings has been updated",
          severity: "success",
        })
      );
    console.log(settings);
  };
};

export const fetchAndSaveSingleSettings = ({ endpoint, key, onSuccess }) => {
  return async (dispatch) => {
    dispatch(editSettings({ [`${key}Fetching`]: true }));
    let data = await settingsEndpoint({
      endpoint,
    });

    dispatch(editSettings({ [key]: data, [`${key}Fetched`]: true }));
    dispatch(editSettings({ [key]: data, [`${key}Fetching`]: false }));
    onSuccess && onSuccess();
  };
};
export const fetchSingleSettings = ({
  loadingKey,
  onSuccess,
  setting_name,
  reduxKey,
}) => {
  return async (dispatch) => {
    try {
      dispatch(editSettings({ [`${loadingKey}`]: true }));
      let data = await backendApiCall({
        endpoint: `${backendEndpoints.settings}/${setting_name}`,
      });

      if (data?.success) {
        dispatch(editSettings({ [reduxKey]: data?.data }));
        onSuccess && onSuccess({ response: data });
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(editSettings({ [`${loadingKey}`]: false }));
    }
  };
};
export const fetchAndUpdateSingleSettings = ({
  loadingKey,
  onSuccess,
  setting_name,
  reduxKey,
  payload,
}) => {
  return async (dispatch) => {
    try {
      if (loadingKey) dispatch(editSettings({ [`${loadingKey}`]: true }));
      let data = await backendApiCall({
        endpoint: `${backendEndpoints.settings}/${setting_name}`,
      });
      console.log(data?.status, "<<<<< data?.status");
      if (data?.status === 200) {
        let updateData = await backendApiCall({
          endpoint: `${backendEndpoints.settings}/${setting_name}`,
          method: "PUT",
          data: { data: payload },
        });
        if (reduxKey) dispatch(editSettings({ [reduxKey]: updateData?.data }));
        onSuccess && onSuccess();
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (loadingKey) dispatch(editSettings({ [`${loadingKey}`]: false }));
    }
  };
};

export const addUpdateNestedSettings = ({
  object,
  setting_key,
  setting_name,
  onSuccess,
  redux_key,
}) => {
  return async (dispatch) => {
    try {
      dispatch(
        editSettings({
          updatingNestedSettings: true,
        })
      );
      let flowSettingsRes = await backendApiCall({
        endpoint: `${backendEndpoints.settings}/${setting_name}`,
      });
      if (!flowSettingsRes?.success) throw new Error();
      let flowSettings = flowSettingsRes?.data;
      let settingsToUpdated = flowSettings?.[setting_key] || [];
      if (typeof settingsToUpdated === "object")
        settingsToUpdated = Object.values(settingsToUpdated);

      if (settingsToUpdated?.find((i) => i.id + "" === object?.id + "")) {
        settingsToUpdated = settingsToUpdated.map((i) => {
          if (i?.id + "" === object?.id + "") {
            return { ...i, ...object };
          }
          return i;
        });
      } else settingsToUpdated = settingsToUpdated.concat(object);
      flowSettings[setting_key] = settingsToUpdated;
      let flowSettingsResponse = await backendApiCall({
        endpoint: `${backendEndpoints.settings}/${setting_name}`,
        method: "PUT",
        data: { data: flowSettings },
      });
      if (!flowSettingsResponse?.success) throw new Error();
      if (flowSettingsResponse?.success) {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Settings updated successfully",
            severity: "success",
          })
        );

        dispatch(editSettings({ [redux_key]: flowSettingsResponse?.data }));
        onSuccess && onSuccess();
      }
      console.log(flowSettingsRes, "<<< added flow settings");
    } catch (error) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Unable to update settings",
          severity: "error",
        })
      );
    } finally {
      dispatch(
        editSettings({
          updatingNestedSettings: false,
        })
      );
    }
  };
};

let globalSettingsOptions = ["size_masters_guide"];

export const fetchGlobalSettings = () => {
  return async (dispatch) => {
    try {
      let res = await settingsEndpoint({
        endpoint: `?options=${globalSettingsOptions?.join()}`,
        website: getMainWebsite(),
        useApiKey: true,
      });
      if (res) {
        dispatch(
          editSettings({
            global_size_masters_guide: res?.size_masters_guide,
          })
        );
      }
    } catch (error) {
      console.log(error, "<<<<<<<<<");
    }
  };
};
