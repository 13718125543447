import {
  Alert,
  Box,
  Button,
  Collapse,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  backendApiCall,
  backendApiEndpoints,
  isEmailValid,
} from "../../helper";
import { LoadingButton } from "@mui/lab";

function ForgotPassword({ onGoToSignIn }) {
  const [form, setForm] = useState({
    email: "",
  });
  const [alertProps, setAlertProps] = useState({});
  const [showOTP, setShowOTP] = useState(false);
  const [showPasswordUpdate, setShowPasswordUpdate] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [animateIn, setAnimateIn] = useState(false);
  const [loading, setLoading] = useState({});

  const editLoading = (payload) =>
    setLoading((state) => ({ ...state, ...payload }));

  const editForm = (payload) => {
    if (alertProps.show) editAlert({ show: false });
    setForm((state) => ({ ...state, ...payload }));
  };
  const editAlert = (payload) =>
    setAlertProps((state) => ({ ...state, ...payload }));

  const onEmailVerify = async () => {
    if (!isEmailValid(form.email))
      return editAlert({
        show: true,
        message: "Please enter a valid Email!",
        severity: "warning",
      });
    try {
      editLoading({ emailVerify: true });
      let emailSentRes = await backendApiCall({
        endpoint: backendApiEndpoints.emailResetOTP,
        data: { email: form?.email },
        method: "POST",
      });
      console.log(emailSentRes, "<<<<< emailSentres");
      if (emailSentRes?.success) {
        editAlert({
          show: true,
          message: "Email has been sent successfully",
          severity: "success",
        });
        setTimeout(() => {
          editAlert({ show: false });
        }, 2000);
        setShowOTP(true);
      } else {
        editAlert({
          show: true,
          message: "Unable to sent email, please try again!",
          severity: "error",
        });
      }
    } catch (error) {
    } finally {
      editLoading({ emailVerify: false });
    }
  };

  const onOTPVerify = async () => {
    try {
      editLoading({ otpVerify: true });
      let otpVerifyRes = await backendApiCall({
        endpoint: backendApiEndpoints.verifyEmailOTP,
        data: { email: form?.email, otp: form?.otp },
        method: "POST",
      });
      if (otpVerifyRes?.success) {
        editAlert({
          show: true,
          message: "OTP has been verified successfully",
          severity: "success",
        });
        setTimeout(() => {
          editAlert({ show: false });
        }, 2000);
        setAnimateOut(true);
        setTimeout(() => {
          setShowPasswordUpdate(true);
          setAnimateIn(true);
          setTimeout(() => {
            setAnimateIn(false);
          }, 350); // Duration of the animation
        }, 350); // Duration of the animation
      } else {
        editAlert({
          show: true,
          message: otpVerifyRes?.data?.message || "OTP did not matched",
          severity: "error",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      editLoading({ otpVerify: false });
    }
  };

  const onEditEmail = () => {
    setShowOTP(false);
  };

  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        Forgot Password
      </Typography>
      <Stack direction="row" alignItems="center" flexWrap={"wrap"} mb={3}>
        <Typography component="h2" variant="body1">
          Kindly enter your email to verify
        </Typography>
      </Stack>
      <Collapse in={alertProps.show}>
        <Alert severity={alertProps.severity}>{alertProps.message}</Alert>
      </Collapse>
      <div className={`_form-container ${animateOut ? "animate-out" : ""}`}>
        {!showPasswordUpdate ? (
          <EmailVerifyForm
            form={form}
            editForm={editForm}
            showOTP={showOTP}
            onEmailVerify={onEmailVerify}
            onOTPVerify={onOTPVerify}
            onEditEmail={onEditEmail}
            loading={loading}
            onGoToSignIn={onGoToSignIn}
          />
        ) : null}
      </div>
      <div className={`_form-container ${animateIn ? "animate-in" : ""}`}>
        {showPasswordUpdate ? (
          <NewPasswordForm form={form} editForm={editForm} loading={loading} />
        ) : null}
      </div>
      <style>
        {`
            ._form-container {
              transition: transform 0.35s ease, opacity 0.35s ease;
            }
            .animate-out {
              transform: translateX(100%);
              opacity: 0;
            }
            .animate-in {
              transform: translateX(-100%);
              opacity: 0;
            }
            .animate-in {
              transform: translateX(0%);
              opacity: 1;
            }
          `}
      </style>
    </Box>
  );
}
// function ForgotPassword() {
//   const [form, setForm] = useState({
//     email: "",
//   });
//   const [alertProps, setAlertProps] = useState({});
//   const [showOTP, setShowOTP] = useState(false);
//   const [showPasswordUpdate, setShowPasswordUpdate] = useState(false);
//   const editForm = (payload) => setForm((state) => ({ ...state, ...payload }));
//   const editAlert = (payload) =>
//     setAlertProps((state) => ({ ...state, ...payload }));

//   const onEmailVerify = () => {
//     editAlert({
//       show: true,
//       message: "Email has been sent successfully",
//       severity: "success",
//     });
//     setTimeout(() => {
//       editAlert({ show: false });
//     }, 2000);
//     setShowOTP(true);
//   };

//   const onOTPVerify = () => {
//     editAlert({
//       show: true,
//       message: "OTP has been verified successfully",
//       severity: "success",
//     });
//     setTimeout(() => {
//       editAlert({ show: false });
//     }, 2000);
//     setShowPasswordUpdate(true);
//   };

//   const onEditEmail = () => {
//     setShowOTP(false);
//   };

//   return (
//     <Box>
//       <>
//         <Typography
//           // component="h1"
//           variant="h2"
//           // align="center"
//           gutterBottom
//         >
//           Forgot Password
//         </Typography>
//         <Stack direction="row" alignItems="center" flexWrap={"wrap"} mb={3}>
//           <Typography component="h2" variant="body1">
//             {"Kindly enter your email to verify"}
//           </Typography>
//           {/* <Button
//             // onClick={() => setfalse(!false)}
//             sx={{
//               whiteSpace: "nowrap",
//               background: "rgba(1, 39, 137, 0.06)",
//             }}
//           >
//             {false ? "Sign In" : "Create an account"}
//           </Button> */}
//         </Stack>
//       </>
//       <Collapse in={alertProps.show}>
//         <Alert severity={alertProps.severity}>{alertProps.message}</Alert>
//       </Collapse>
//       {showPasswordUpdate ? (
//         <NewPasswordForm form={form} editForm={editForm} />
//       ) : (
//         <EmailVerifyForm
//           form={form}
//           editForm={editForm}
//           showOTP={showOTP}
//           onEmailVerify={onEmailVerify}
//           onOTPVerify={onOTPVerify}
//           onEditEmail={onEditEmail}
//         />
//       )}
//     </Box>
//   );
// }

export default ForgotPassword;

const EmailVerifyForm = ({
  form,
  editForm,
  showOTP,
  onEmailVerify,
  onOTPVerify,
  onEditEmail,
  loading,
  onGoToSignIn,
}) => {
  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={4} lg={5}>
          <Typography mt={2} ml={2} variant="h6">
            Email
          </Typography>
        </Grid>
      </Grid>
      <TextField
        type="email"
        name="email"
        // label="Email Address"
        placeholder="john.doe@gmail.com"
        value={form.email}
        // error={Boolean(touched.email && errors.email)}
        fullWidth
        // helperText={touched.email && errors.email}
        // onBlur={handleBlur}
        disabled={showOTP}
        InputProps={{
          endAdornment: showOTP ? (
            <InputAdornment position="start" onClick={onEditEmail}>
              Edit
            </InputAdornment>
          ) : null,
        }}
        onChange={(e) => editForm({ email: e.target.value })}
        sx={{ my: 2 }}
      />
      <Collapse in={showOTP}>
        <>
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={4} lg={5}>
              <Typography mt={1} ml={2} variant="h6">
                OTP
              </Typography>
            </Grid>
          </Grid>
          <TextField
            type="text"
            name="otp"
            // label="Email Address"
            placeholder="Enter OTP"
            value={form.otp}
            // error={Boolean(touched.otp && errors.otp)}
            fullWidth
            // helperText={touched.otp && errors.otp}
            // onBlur={handleBlur}
            onChange={(e) => editForm({ otp: e.target.value })}
            sx={{ my: 2 }}
          />
        </>
      </Collapse>

      {showOTP ? (
        <LoadingButton
          fullWidth
          variant="contained"
          color="primary"
          disabled={!form.otp}
          sx={{ mt: 4 }}
          onClick={onOTPVerify}
          loading={loading?.otpVerify}
        >
          Verify OTP
        </LoadingButton>
      ) : (
        <LoadingButton
          fullWidth
          variant="contained"
          color="primary"
          disabled={!form.email}
          sx={{ mt: 4 }}
          onClick={onEmailVerify}
          loading={loading?.emailVerify}
        >
          Verify Email
        </LoadingButton>
      )}

      <Button
        fullWidth
        variant="text"
        color="primary"
        // disabled={isSubmitting}
        onClick={onGoToSignIn}
        sx={{ mt: 1.5 }}
      >
        Go to Sign In
      </Button>
    </>
  );
};

const NewPasswordForm = ({ form, editForm }) => {
  return (
    <Box>
      <Typography mt={3} ml={2} variant="h6">
        Enter New Password
      </Typography>
      <TextField
        type="email"
        name="email"
        placeholder="Enter new password"
        value={form.password}
        fullWidth
        onChange={(e) => editForm({ password: e.target.value })}
        sx={{ my: 2 }}
      />
      <>
        <Typography mt={2} ml={2} variant="h6">
          Re-Enter New Password
        </Typography>
        <TextField
          type="text"
          name="otp"
          placeholder="Confirm your new password"
          value={form.confirm_password}
          fullWidth
          onChange={(e) => editForm({ confirm_password: e.target.value })}
          sx={{ my: 2 }}
        />
      </>

      <Button
        fullWidth
        variant="contained"
        color="primary"
        // disabled={isSubmitting}
        sx={{ mt: 4 }}
        // onClick={onEmailVerify}
      >
        Update Password
      </Button>
    </Box>
  );
};
