import { useSelector } from "react-redux";
import { Popover, Box, Typography, IconButton } from "@mui/material";
import { optionPopoverStyles } from "../styles/optionPopoverStyles";
import HomeIcon from "@mui/icons-material/Home";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import PersonIcon from "@mui/icons-material/Person";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import TelegramIcon from "@mui/icons-material/Telegram";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import AddTaskIcon from "@mui/icons-material/AddTask";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

const styles = optionPopoverStyles;

const BlockOptions = ({
  setData,
  optionsAnchorEl,
  setOptionsAnchorEl,
  setBlock,
}) => {
  const open = Boolean(optionsAnchorEl);
  const id = open ? "simple-popover" : undefined;
  const nodes = useSelector((state) => state.flow?.nodes);

  const handleClosePopover = () => {
    setOptionsAnchorEl(null);
  };

  const handleOptionClick = (id, type) => {
    setBlock(type);
    setData(id);
    handleClosePopover();
  };

  const defaultTitles = {
    start: {
      name: "Start",
      number: 1,
      icon: HomeIcon,
    },
    bot: {
      name: "Bot",
      number: 1,
      icon: TelegramIcon,
    },
    user: {
      name: "User",
      number: 1,
      icon: PersonIcon,
    },
    question: {
      name: "Question",
      number: 1,
      icon: QuestionMarkIcon,
    },
    filter: {
      name: "Filter",
      number: 1,
      icon: FilterAltOutlinedIcon,
    },
    fallback: {
      name: "Default fallback",
      number: 1,
      icon: ReplyAllIcon,
    },
    "live-agent": {
      name: "Live Agent",
      number: 1,
      icon: SupportAgentIcon,
    },
    "product-catalog": {
      name: "Product Catalog",
      number: 1,
      icon: CategoryIcon,
    },
    "whatsapp-template": {
      name: "Whatsapp Template",
      number: 1,
      icon: WhatsAppIcon,
    },
    "email": {
      name: "Email",
      number: 1,
      icon:EmailIcon
    },
    "push-notification": {
      name: "Push Notification",
      number: 1,
      icon: NotificationsActiveIcon
    },
    "sms": {
      name: "SMS",
      number: 1,
      icon: SmsIcon
    },
    "delay": {
      name: "Delay",
      number: 1,
      icon: HourglassBottomIcon
    },
    "create-task": {
      name: "Create Task",
      number: 1,
      icon: AddTaskIcon
    },
    
  };

  return (
    <Popover
      sx={{ marginTop: "4px" }}
      id={id}
      open={open}
      anchorEl={optionsAnchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box sx={styles.outerBox}>
        {nodes.length > 0 &&
          nodes.map((node) => {
            const typeInfo = defaultTitles[node?.type];
            if (!typeInfo || node?.type === "user") {
              return;
            }
            const title =
              node?.title ||
              node?.fieldData.title ||
              defaultTitles[node?.type].name +
                " " +
                defaultTitles[node.type].number;
            const IconComponent = defaultTitles[node?.type]?.icon;
            defaultTitles[node?.type].number =
              defaultTitles[node?.type].number + 1;
            return (
              <Box
                sx={styles.innerBox}
                key={node?.id}
                onClick={() => handleOptionClick(node?.id, title)}
              >
                <IconButton
                  sx={{
                    height: "24px",
                    width: "24px",
                    backgroundColor: "#dcdcdc",
                    marginRight: "24px",
                  }}
                >
                  <IconComponent sx={{ fontSize: "15px" }} />
                </IconButton>
                <Typography variant="span" sx={styles.blockName}>
                  {title || title + " " + defaultTitles[node?.type].number}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Popover>
  );
};

export default BlockOptions;
