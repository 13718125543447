import { MoreVert, OpenInNew } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Rating,
  Skeleton,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import {
  dialogStyle,
  formatDate,
  getAxiosError,
  getId,
  getStoreEmail,
  getToken,
  getWebsite,
  isArrayWithValues,
} from "../../../helper";
import {
  deleteReview,
  getReplies,
  getReviews,
  updateReview,
} from "../../../redux/actions/pagesActions";
import { QuillWrapper } from "../../products/AddProduct";
import { isObjWithValues, validateNumber } from "jwero-javascript-utils";
// import "../../../App.css"
const tabs = [
  { label: "All", value: "all" },
  { label: "Approved", value: "approved" },
  { label: "Un Approved", value: "hold" },
  { label: "Replied", value: "replied" },
  { label: "Spam", value: "spam" },
];
const Reviews = () => {
  const [selectedTab, setSelectedTab] = useState("all");

  const [allReviews, setAllReviews] = useState([]);
  const [allReplies, setAllReplies] = useState([]);

  const allReduxReviews = useSelector((state) => state.pages.allReviews);
  const allReduxReplies = useSelector((state) => state.pages.allReplies);
  const fetchingReviews = useSelector((state) => state.pages.fetchingReviews);

  const totalPages = useSelector((state) => state.pages.totalReviewsPages);
  const totalReviewsCount = useSelector(
    (state) => state.pages.totalReviewsCount
  );
  const currentReviewsPage = useSelector(
    (state) => state.pages.currentReviewsPage
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isArrayWithValues(allReduxReviews)) dispatch(getReviews());
    if (!isArrayWithValues(allReduxReplies)) dispatch(getReplies());
  }, []);
  useEffect(() => {
    if (isArrayWithValues(allReduxReviews)) setAllReviews(allReduxReviews);
  }, [allReduxReviews]);
  useEffect(() => {
    if (isArrayWithValues(allReduxReplies)) setAllReplies(allReduxReplies);
  }, [allReduxReplies]);

  const onTabChange = (val) => {
    // if (!isArrayWithValues(allReduxReviews)) return
    setSelectedTab(val);
    if (val === "all") setAllReviews(allReduxReviews);
    else if (val === "replied") {
      let filterArr = allReviews.filter((obj) =>
        isArrayWithValues(obj.replies)
      );
      console.log(filterArr, allReviews);
      setAllReviews(filterArr);
    } else {
      let filterArr = allReduxReviews.filter((obj) => obj.status === val);
      setAllReviews(filterArr);
    }
  };

  return (
    <Box sx={{ padding: 0 }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          marginTop: "0",
          padding: "12px",
          backgroundColor: "#fff",
          zIndex: 11,
        }}
      >
        <Typography variant="h3">Reviews</Typography>
        <Stack direction={"row"} sx={{ mt: 5, overflow: "auto" }}>
          {tabs.map((i) => {
            let count = 0;

            if (i.value === "all") count = ` (${totalReviewsCount || 0})`;
            else if (i.value === "replied") {
              let filterArr = allReduxReviews?.filter((review) =>
                allReduxReplies?.some((reply) => reply?.parent == review?.id)
              );
              count = ` (${filterArr?.length || 0})`;
            } else {
              let filterArr = allReduxReviews?.filter(
                (review) => i.value == review.status
              );
              count = ` (${filterArr?.length || 0})`;
            }
            return (
              <Button
                variant={"text"}
                startIcon={i.icon}
                // variant={selectedTab !== i.value ? "outlined" : "contained"}
                sx={{
                  // gap: "15px",
                  padding: "6px 20px",
                  backgroundColor:
                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                  color: "#000",
                  // color: selectedTab === i.value ? "primary.main" : "#000",
                  "&:hover": {
                    backgroundColor:
                      selectedTab === i.value ? "#e6e6e6" : "transparent",
                  },
                }}
                onClick={() => onTabChange(i.value)}
              >
                <Stack
                  direction={"row"}
                  style={{ alignItems: "flex-end", justifyContent: "flex-end" }}
                >
                  <Typography>{i.label}</Typography>
                  <Typography marginLeft={1} fontWeight={"500"} marginRight={6}>
                    {count}
                  </Typography>
                </Stack>
              </Button>
            );
          })}
        </Stack>
        <Divider sx={{ mt: 3 }} />
      </Stack>
      <Box sx={{ padding: "12px", marginBottom: "60px" }}>
        <Grid container spacing={7} rowSpacing={6}>
          {allReduxReviews
            ?.filter((item) =>
              selectedTab === "all"
                ? true
                : selectedTab === "replied"
                ? allReduxReplies?.some((reply) => reply?.parent === item?.id)
                : item?.status === selectedTab
            )
            ?.map((obj) => (
              <Grid item lg={6} xs={12}>
                <ReviewCard obj={obj} setAllReplies={setAllReplies} />
              </Grid>
            ))}
          {/* {allReviews.map((obj) => (
            <Grid item lg={6} xs={12}>
              <ReviewCard obj={obj} setAllReplies={setAllReplies} />
            </Grid>
          ))} */}
        </Grid>

        {fetchingReviews ? (
          <Grid container spacing={5} rowSpacing={4} marginTop={2}>
            {Array(8)
              .fill("")
              .map((i) => (
                <Grid item lg={6} xs={12}>
                  <LoadingCards />
                </Grid>
              ))}
          </Grid>
        ) : selectedTab === "all" ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            marginTop={4}
          >
            <Typography>
              Showing {allReduxReviews?.length} out of {totalReviewsCount}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size={"medium"}
              // onMouseEnter={onAddProductHover}
              // onMouseDown={handleAddProductClose}
              // colo={hello}
              // startIcon={<AddIcon />}
              sx={{ ml: 2 }}
              disabled={
                validateNumber(currentReviewsPage) >= validateNumber(totalPages)
              }
              // onClick={onAddProductHover}
              onClick={() => {
                dispatch(getReviews());
              }}
            >
              {validateNumber(currentReviewsPage) >= validateNumber(totalPages)
                ? "No more reviews"
                : "Load more"}
            </Button>
          </Stack>
        ) : allReviews?.length > 0 ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            marginTop={4}
          >
            <Button
              variant="contained"
              color="primary"
              size={"medium"}
              // onMouseEnter={onAddProductHover}
              // onMouseDown={handleAddProductClose}
              // colo={hello}
              // startIcon={<AddIcon />}
              sx={{ ml: 2 }}
              disabled={
                validateNumber(currentReviewsPage) >= validateNumber(totalPages)
              }
              // onClick={onAddProductHover}
              onClick={() => {
                dispatch(getReviews());
              }}
            >
              {validateNumber(currentReviewsPage) >= validateNumber(totalPages)
                ? "No more reviews"
                : "Load more"}
            </Button>
          </Stack>
        ) : null}
      </Box>
    </Box>
  );
};

export default Reviews;

const LoadingCards = () => {
  return (
    <Card sx={{ padding: "16px" }} className="shadow_card">
      <Stack sx={{ mb: 3 }} justifyContent="space-between" direction={"row"}>
        <Stack direction={"row"} sx={{ gap: "10px" }} alignItems="center">
          <Skeleton variant="circular" width={40} height={40} />
          <Stack>
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", width: "100px" }}
            />
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "60px" }} />
          </Stack>
        </Stack>
      </Stack>
      <Skeleton variant="rounded" width={"90%"} height={30} />
      <Stack direction={"row"} sx={{ gap: "8px" }} alignItems="center">
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "70%" }} />
      </Stack>
      <Stack direction={"row"} sx={{ gap: "8px", mt: 3 }} alignItems="center">
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "30px" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "30px" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "30px" }} />
      </Stack>
    </Card>
  );
};

const ReviewCard = ({ obj, setAllReplies }) => {
  let {
    id,
    date_created,
    reviewer,
    reviewer_email,
    rating,
    product_name,
    status,
    review,
    verified,
    product_permalink,
    reviewer_avatar_urls,
  } = obj;
  const [loading, setLoading] = useState(false);
  let avatarArray = Object.values(reviewer_avatar_urls) || [];
  const [replies, setReplies] = useState([]);
  const allReduxReplies = useSelector((state) => state.pages.allReplies);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isArrayWithValues(allReduxReplies)) {
      let replyArr = allReduxReplies?.filter((reply) => reply.parent === id);

      setReplies(replyArr);
    }
  }, [allReduxReplies, id]);

  const buttonArr = [
    { label: "Approved", value: "approved" },
    { label: "Un Approved", value: "hold" },
    { label: "Reply", value: "replied" },
    { label: "Spam", value: "spam" },
    // { label: "Delete", value: "delete" },
  ];

  const onGoproduct = () => {
    window.open(product_permalink, "_blank");
  };
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [showConfirmationDialog, setshowConfirmationDialog] = useState(false);

  const onUpdateStatus = async (val) => {
    if (val === "replied") {
      setShowReviewForm(obj);
      return;
    }
    setLoading(val);
    await dispatch(updateReview(id, { status: val }));
    setLoading(false);
  };
  const ondelete = async () => {
    setLoading("delete");
    await dispatch(deleteReview(id));
    setLoading(false);
  };

  const onConfirm = async () => {
    if (showConfirmationDialog?.id === "replied") {
      // setShowReviewForm(obj);
      return;
    } else if (showConfirmationDialog?.id === "delete") {
      setLoading("delete");
      setshowConfirmationDialog(false);
      await dispatch(deleteReview(id));
      setLoading(false);
    } else {
      const val = showConfirmationDialog?.id;
      setLoading(val);
      setshowConfirmationDialog(false);
      await dispatch(updateReview(id, { status: val }));
      setLoading(false);
    }
  };

  return (
    <Card sx={{ padding: "25px" }} className="shadow_card">
      <Stack sx={{ mb: 3 }} justifyContent="space-between" direction={"row"}>
        <Stack direction={"row"} sx={{ gap: "10px" }} alignItems="center">
          <Avatar />
          <Stack>
            <Typography>{reviewer}</Typography>
            <Typography>{reviewer_email}</Typography>
          </Stack>
        </Stack>
        <IconButton onClick={onGoproduct}>
          <OpenInNew fontSize="small" />
        </IconButton>
      </Stack>

      <Stack direction={"row"} sx={{ gap: "10px", mb: 3 }} alignItems="center">
        <Rating name="read-only" value={rating} readOnly size="small" />
        <Typography>
          {formatDate(new Date(date_created), "relative_date")}
        </Typography>
      </Stack>
      <div
        dangerouslySetInnerHTML={{ __html: review }}
        style={{ fontSize: "15px", fontWeight: "400" }}
      ></div>
      <Stack direction={"row"} sx={{ gap: "8px" }} alignItems="center">
        <Typography>In Response To </Typography>{" "}
        <Typography variant="subtitle2">{product_name}</Typography>
      </Stack>
      <Stack direction={"row"} sx={{ gap: "8px", mt: 3 }} alignItems="center">
        {buttonArr.map((item) => (
          <LoadingButton
            loading={loading === item.value}
            sx={{ padding: "0px 8px", borderRadius: 4 }}
            variant={status === item.value ? "contained" : "outlined"}
            size="small"
            onClick={() => {
              if (item.value === "replied") {
                setShowReviewForm(obj);
                return;
              }
              setshowConfirmationDialog({
                id: item.value,
                title: `Set as ${item.label}`,
                message: `Are you sure you want to set this review as ${item.label}?`,
              });
              // onUpdateStatus(item.value);
            }}
          >
            {item.label}
          </LoadingButton>
        ))}
        <LoadingButton
          loading={loading === "delete"}
          variant="outlined"
          size="small"
          sx={{ padding: "0px 8px", borderRadius: 4 }}
          color="error"
          onClick={() =>
            setshowConfirmationDialog({
              id: "delete",
              title: "Delete Review",
              message: "Are you sure you want to delete this review?",
            })
          }
        >
          Delete
        </LoadingButton>
      </Stack>
      {isArrayWithValues(replies) ? (
        <Stack sx={{ mt: 1 }}>
          <Typography variant="subtitle2">Replies</Typography>
          {replies.map((reply) => (
            <Stack>
              {" "}
              <Typography variant="subtitle2">
                Author : {reply?.author_name}
              </Typography>{" "}
              <Typography>
                {reply?.content?.rendered.replace(
                  /(<\/?\w+?>)\s*?(<\/?\w+?>)|(<\/?\w+?>)/g,
                  ""
                )}
              </Typography>{" "}
            </Stack>
          ))}
        </Stack>
      ) : null}
      <ReplyRviewDailog
        open={showReviewForm}
        handleClose={() => setShowReviewForm(false)}
        setAllReplies={setAllReplies}
      />
      <Dialog
        open={isObjWithValues(showConfirmationDialog)}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setshowConfirmationDialog(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{ py: 3 }}
      >
        <DialogTitle>
          <Typography variant="subtitle2">
            {showConfirmationDialog?.title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {showConfirmationDialog?.message ||
              "Are you sure you want to delete this banner"}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ mx: 2 }}>
          <Button
            onClick={() => {
              setshowConfirmationDialog(false);
            }}
          >
            Close
          </Button>
          <LoadingButton
            loading={false}
            color={
              showConfirmationDialog?.id === "delete" ? "error" : "primary"
            }
            variant="contained"
            onClick={onConfirm}
            // disabled={!productAccess.includes("delete") && !isAdmin}
          >
            {
              {
                hold: "Un Approve",
                approved: "Approve",
                spam: "Spam",
                delete: "Delete",
              }?.[showConfirmationDialog?.id]
            }
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

const ReplyRviewDailog = ({ open, handleClose, setAllReplies }) => {
  const [reviewContent, setReviewContent] = useState("");
  const [loading, setLoading] = useState(false);
  const id = getId();
  const email = getStoreEmail();

  const onSubmit = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setLoading(true);
    try {
      const res = await axios({
        url: `${website}/wp-json/wp/v2/comments`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "POST",
        data: {
          author: id,
          author_email: email,
          parent: open?.id,
          content: reviewContent,
          post: open?.product_id,
        },
      });
      if (res?.data?.id) {
        setAllReplies((pre) => [...pre, res?.data]);
        handleClose();
      }
      console.log(res);
    } catch (error) {
      console.log(getAxiosError(error));
    }
    setLoading(false);
  };
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={dialogStyle}
      fullWidth
      maxWidth="sm"
      {...globalPopupStyle}
    >
      <DialogTitle>Reply Review ({open?.reviewer})</DialogTitle>
      <DialogContent>
        <QuillWrapper>
          <ReactQuill
            value={reviewContent}
            onChange={(e) => setReviewContent(e)}
          />
        </QuillWrapper>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={loading} onClick={onSubmit}>
            Reply
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
