import { Handle, useNodeId } from "reactflow";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography } from "@mui/material";
import { questionStyles } from "../styles/questionStyles";
import NodeOptions from "../helper/NodeOptions";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ShowChatIndicator from "../helper/ShowChatIndicator";
import * as actions from "../../../../../../redux/actions/flowsActions";
import "../styles/node.css";
import "../styles/node-title.css";
import NodeTop from "../helper/NodeTop";

const styles = questionStyles;

const Question = () => {
  const dispatch = useDispatch();
  const nodeId = useNodeId();
  const nodes = useSelector((state) => state.flow?.nodes);
  const title = nodes.find((node) => node.id === nodeId)?.fieldData?.title;
  const messageNodeIndicatorId = useSelector(
    (state) => state.flow?.messageNodeIndicatorId
  );
  const isMinimized = useSelector((state) => state.flow?.isMinimized);
  const [isHovered, setIsHovered] = useState(false);

  const handleMain = () => {
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
    dispatch({ type: actions.SIDEBAR_QUESTION, payload: true });
  };

  return (
    <Box
      className="nodeContainer"
      sx={styles.mainContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          height: "20px",
          width: "140px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
          position: "relative",
          top: "6px",
        }}
      >
        <NodeTop title={title} isHovered={isHovered} nodeId={nodeId} />
      </Box>
      <Box sx={styles.bottomRow}>
        <Handle type="target" position="left" style={styles.leftHandle} />
        <Box sx={styles.nodeBody} onClick={handleMain}>
          <IconButton sx={{ margin: 0, padding: 0 }}>
            <QuestionMarkIcon sx={styles.nodeIcon} />
          </IconButton>
          <Typography variant="span" style={styles.titleName}>
            Question
          </Typography>
          <Handle type="source" position="right" style={styles.rightHandle} />
        </Box>
        {messageNodeIndicatorId === nodeId && !isMinimized && (
          <ShowChatIndicator />
        )}
      </Box>
    </Box>
  );
};

export default Question;
