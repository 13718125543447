import { checkIflast } from "./checkIfLast";

export const canBeAddedAnywhere = [
  "bot",
  "user",
  "user-attachment",
  "product-catalog",
  "filter",
  "subscribe",
  "unsubscribe",
  "success",
  "failure",
  "delay",
  "metal-rates",
  "create-task",
  "assign",
  "fallback",
];

export const canOnlyBeAddedAtLast = [
  "live-agent",
  "go-to-step",
  "question",
  "whatsapp-template",
  "sms",
  "email",
  "push-notification",
];

export const notAllowedToAdd = [
  "whatsapp-template",
  "email",
  "sms",
  "push-notification",
];

const canBeAllowedPrevAndNext = ["bot", "user", "filter", ...notAllowedToAdd];

function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const checkPrevAndNextNodeFlag = ({ nodes, edges, nodeId, nodeAddedType }) => {
  const edge = edges.find((edge) => edge?.source === nodeId);
  console.log("EDGE", edge);
  if (!edge?.target) {
    console.log("FIRST IF CONDITION");
    return true;
  }
  const nextNode = nodes.find((node) => node?.id === edge?.target);
  console.log(
    "CHECKING",
    nodeAddedType === nextNode?.type,
    !canBeAllowedPrevAndNext.includes(nextNode?.type)
  );

  if (
    nodeAddedType === nextNode?.type &&
    !canBeAllowedPrevAndNext.includes(nextNode?.type)
  ) {
    console.log(
      "SECOND IF CONDITION",
      nodeAddedType === nextNode?.type,
      !canBeAllowedPrevAndNext.includes(nextNode?.type)
    );
    return false;
  }

  return true;
};

const checkIfMultipleAdded = ({
  nodes,
  edges,
  currentNodeType,
  nodeId,
  nodeAddedType,
}) => {
  const checkForTypes = ["filter", "user"];
  const connectedEdges = edges.filter((edge) => edge?.source === nodeId);
  console.log(connectedEdges);
  if (connectedEdges.length === 0) {
    return true;
  }
  console.log(nodeAddedType);
  if (checkForTypes.includes(nodeAddedType)) {
    const checkFor = nodeAddedType === "user" ? "filter" : "user";
    const ifPresentFlag = edges.some((edge) => {
      const tempNode = nodes.find((node) => node?.id === edge.target);
      return tempNode?.type === checkFor;
    });
    return ifPresentFlag ? false : true;
  }

  return true;
};

const checkIfFallbackAllowed = ({ nodes, edges, nodeId }) => {
  const connectedEdges = edges.filter((edge) => edge?.source === nodeId);
  const ifAdded = connectedEdges.some((edge) => {
    const currentNode = nodes?.find((node) => node?.id === edge.target);
    if (currentNode.type === "fallback") return true;
  });
  console.log("ifAdded", ifAdded);
  return ifAdded ? false : true;
};

export const nodeCanBeAdded = ({
  nodes,
  edges,
  currentNode,
  nodeAddedType,
}) => {
  const currentNodeId = currentNode?.id;
  const currentNodeType = currentNode?.type;
  const allowedBackToBack = ["bot"];

  const checkNextAndPrevFlag = checkPrevAndNextNodeFlag({
    nodes,
    edges,
    nodeId: currentNodeId,
    nodeAddedType,
  });

  if (
    (currentNodeType === "user" && nodeAddedType === "filter") ||
    (currentNodeType === "fallback" && nodeAddedType === "filter") ||
    (currentNodeType === "filter" && nodeAddedType === "user") ||
    (currentNodeType === "fallback" && nodeAddedType === "user") ||
    (currentNodeType === "user" && nodeAddedType === "fallback") ||
    (currentNodeType === "filter" && nodeAddedType === "fallback")
  ) {
    return false;
  }

  console.log(currentNodeType, nodeAddedType);
  if (
    currentNodeType === nodeAddedType &&
    !allowedBackToBack.includes(nodeAddedType)
  ) {
    console.log("Error 1");
    return false;
  }

  if (!checkNextAndPrevFlag) {
    console.log("Error 2");
    return false;
  }

  const multipleAddingNodeFlag = checkIfMultipleAdded({
    nodes,
    edges,
    currentNodeType,
    nodeId: currentNodeId,
    nodeAddedType,
  });

  if (!multipleAddingNodeFlag) {
    console.log("Error 3");
    return false;
  }

  if (notAllowedToAdd.includes(currentNode?.type)) {
    console.log("Error 4");
    return false;
  }

  const fallbackFlag = checkIfFallbackAllowed({
    nodes,
    edges,
    nodeId: currentNodeId,
    nodeAddedType,
  });

  if (nodeAddedType === "fallback" && !fallbackFlag) {
    console.log("Error 5");
    return false;
  }

  if (canBeAddedAnywhere.includes(nodeAddedType)) {
    return true;
  }

  if (canOnlyBeAddedAtLast.includes(nodeAddedType)) {
    console.log("Entered Last");
    const flag = checkIflast({ nodes, edges, nodeId: currentNodeId });
    if (flag) {
      return true;
    }
  }

  return false;
};

export const nodeCanBeAddedErrorMessage = ({
  nodes,
  edges,
  currentNode,
  nodeAddedType,
}) => {
  const currentNodeId = currentNode?.id;
  const currentNodeType = currentNode?.type;
  const allowedBackToBack = ["bot"];
  const checkNextAndPrevFlag = checkPrevAndNextNodeFlag({
    nodes,
    edges,
    nodeId: currentNodeId,
    nodeAddedType,
  });
  if (
    (currentNodeType === "user" && nodeAddedType === "filter") ||
    (currentNodeType === "fallback" && nodeAddedType === "filter") ||
    (currentNodeType === "filter" && nodeAddedType === "user") ||
    (currentNodeType === "fallback" && nodeAddedType === "user") ||
    (currentNodeType === "user" && nodeAddedType === "fallback") ||
    (currentNodeType === "filter" && nodeAddedType === "fallback")
  ) {
    console.log("Error MSG 1");
    return `You can't add ${nodeAddedType} after ${currentNodeType}.`;
  }

  if (
    currentNodeType === nodeAddedType &&
    !allowedBackToBack.includes(nodeAddedType)
  ) {
    console.log("ErrorMSG 2");
    return `You can't add ${currentNodeType} after ${nodeAddedType}.`;
  }

  if (!checkNextAndPrevFlag) {
    console.log("ErrorMSG 3");
    return `You can't add ${nodeAddedType} before ${nodeAddedType}.`;
  }

  const multipleAddingNodeFlag = checkIfMultipleAdded({
    nodes,
    edges,
    currentNodeType,
    nodeId: currentNodeId,
    nodeAddedType,
  });
  console.log("checkIfMultipleAdded", multipleAddingNodeFlag);
  if (!multipleAddingNodeFlag) {
    console.log("ErrorMSG 4");
    return `You can't add ${nodeAddedType} here`;
  }

  const fallbackFlag = checkIfFallbackAllowed({
    nodes,
    edges,
    nodeId: currentNodeId,
    nodeAddedType,
  });

  console.log("fallbackFlag", fallbackFlag, nodeAddedType);

  if (nodeAddedType === "fallback" && !fallbackFlag) {
    console.log("Error 5");
    return `Only one fallback can be added to a single node`;
  }

  if (currentNode?.type === notAllowedToAdd) {
    return "Drag and drop at multi-handle nodes not allowed, add by cliking + icon.";
  }

  if (canBeAddedAnywhere.includes(nodeAddedType)) {
    return "allowed";
  }

  if (canOnlyBeAddedAtLast.includes(nodeAddedType)) {
    const flag = checkIflast({ nodes, edges, nodeId: currentNodeId });
    if (flag) {
      return "allowed";
    }
  }

  return `${capitalizeFirstLetter(
    nodeAddedType
  )} node can be added at the end only.`;
};
