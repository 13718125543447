import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  FormControl,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import { useSelector } from "react-redux";
import {
  formatDate,
  formatServerValue,
  getAxiosError,
  getRandomInt,
  getRandomRangeNumbers,
  getToken,
  getUnixFromString,
  getWebsite,
  insertInString,
  isArrayWithValues,
  isObjWithValues,
  mainWebsite,
  sectionLabel,
  validateNumber,
} from "../../../helper";
import { isValidDate } from "../../../utils/dateFunctions";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import {
  CalendarToday,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { minWidth } from "@mui/system";
import CountUp from "react-countup";
import { AnalyticsChip } from ".";
const dateDimensions = ["date"];
const categoryDimensions = ["city"];

function GoogleAnalytics() {
  const [dateRanges, setDateRanges] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(new Date().setDate(new Date().getDate() - 2)),
  });
  const [matrics, setMaxtrics] = useState([
    { label: "New Visitors", value: "newUsers" },
  ]);

  const [dimensions, setdimensions] = useState("city");
  const [series, setSeries] = useState([]);
  const [categorySeries, setCategorySeries] = useState([]);
  const [fetchingAnalytics, setFetchingAnalytics] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(false);
  const [categories, setCategories] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showMore, setShowMore] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const reduxGoogleIntegration = useSelector(
    (state) => state.settings.googleIntegration
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    if (reduxGoogleIntegration && isObjWithValues(reduxGoogleIntegration)) {
      for (let obj of Object.values(reduxGoogleIntegration)) {
        let { connected, google_analytics } = obj;

        if (
          isArrayWithValues(connected) &&
          connected.includes("google_analytics")
        ) {
          if (isArrayWithValues(google_analytics)) {
            let idObj = google_analytics.find((i) => i.selected_analytics_id);
            if (idObj) setSelectedAccount(idObj.selected_analytics_id);
          }
        }
      }
    }
  }, [reduxGoogleIntegration]);

  useEffect(() => {
    if (isArrayWithValues(matrics) && selectedAccount) analyticsChange();
    else setSeries([]);
  }, [matrics, dateRanges, dimensions, selectedAccount]);

  const analyticsChange = async () => {
    setFetchingAnalytics(true);
    let payload = {
      endpoint: `/properties/${selectedAccount}:runReport`,
      payload: {
        dimensions: [
          {
            name: dimensions,
          },
        ],
        metrics: matrics
          .map((i) => ({ name: i.value }))
          ?.filter(
            (obj, index, self) =>
              self.findIndex((t) => t.name === obj.name) === index
          ),
        dateRanges: [
          {
            startDate: formatDate(new Date(dateRanges.startDate), "yyyy-mm-dd"),
            endDate: formatDate(new Date(dateRanges.endDate), "yyyy-mm-dd"),
          },
        ],
      },
    };
    let response = await getAnalytics(payload);
    if (isObjWithValues(response)) {
      let series = {};
      let { rows, metricHeaders } = response;
      if (isArrayWithValues(metricHeaders)) {
        for (let headerObj of metricHeaders) {
          let { name } = headerObj;
          series[name] = [];
        }
      }
      if (isArrayWithValues(rows)) {
        let keys = Object.keys(series);
        let categories = [];
        for (let row of rows.slice(0, 12)) {
          let { dimensionValues, metricValues } = row;
          if (
            !isArrayWithValues(dimensionValues) ||
            !isArrayWithValues(metricValues)
          )
            continue;
          let index = 0;
          if (!dateDimensions.includes(dimensions))
            categories.push(dimensionValues[0].value);
          for (let value of metricValues) {
            if (dateDimensions.includes(dimensions)) {
              series[keys[index]].push([
                getUnixFromString(dimensionValues[0].value),
                validateNumber(Number(value.value).toFixed(1)),
              ]);
            } else {
              series[keys[index]].push(
                validateNumber(Number(value.value).toFixed(1))
              );
            }
            index++;
          }
        }
        if (isArrayWithValues(categories)) setCategories(categories);
        else setCategories([]);
      }
      if (isObjWithValues(series)) {
        let seriesArray = [];
        // let length = dimensions === 'date'? Object.keys(series) : new Array()
        for (let key of Object.keys(series)) {
          let array = [];
          array = series[key].sort((a, b) => a[0] - b[0]);
          seriesArray.push({ name: key, data: array });
          // if (dimensions === "date") {
          // } else {
          //   array = series[key].slice(0, 10);
          //   seriesArray.push({ data: array });
          //   categories.push(key);
          // }
        }
        // chart.updateOptions({
        //   series: seriesArray,
        //   options: {
        //     ...categoryOptions,
        //     xaxis: {
        //       show: !dateDimensions.includes(dimensions),
        //       labels: {
        //         rotate: -45,
        //       },
        //       categories,
        //       tickPlacement: "on",
        //     },
        //   },
        // });
        if (dateDimensions.includes(dimensions)) setSeries(seriesArray);
        else setCategorySeries(seriesArray);
      }
    }
    setFetchingAnalytics(false);
  };

  return (
    <Box>
      <Card sx={{ borderRadius: "15px", height: "100%" }}>
        <CardContent>
          <Typography
            // sx={{ fontSize: "15px", mt: 5, mb: 2, px: 2, fontWeight: "400" }}
            sx={{ ...sectionLabel }}
          >
            {`Google Analytics`}
          </Typography>
          <Box>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent="flex-end"
              spacing={3}
              flexWrap="wrap"
              rowGap={4}
            >
              <CircularProgress
                style={{
                  height: "16px",
                  width: "16px",
                  display: fetchingAnalytics ? "block" : "none",
                }}
              />
              <Autocomplete
                multiple
                id="tags-standard"
                options={allMatrics}
                getOptionLabel={(option) => option.label}
                defaultValue={[allMatrics[2]]}
                value={matrics}
                onChange={(event, newValue) => {
                  setMaxtrics(newValue);
                }}
                ChipProps={{
                  sx: { p: 0, fontSize: "11px", m: 0 },
                  size: "small",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //   variant="standard"
                    sx={{
                      minWidth:
                        formatDate(
                          new Date(dateRanges.startDate),
                          "yyyy-mm-dd"
                        ) !==
                        formatDate(new Date(dateRanges.endDate), "yyyy-mm-dd")
                          ? "270px"
                          : "200px",
                    }}
                    size="small"
                    label="Metrics"
                    //   placeholder="Favorites"
                  />
                )}
              />
              <FormControl size="small" sx={{ minWidth: "200px" }}>
                <InputLabel id="demo-simple-select-label">
                  Dimensions
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dimensions}
                  onChange={(e) => setdimensions(e.target.value)}
                  label="Dimensions"
                >
                  {allDimensions.map((i) => {
                    return <MenuItem value={i.value}>{i.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>

              <Box onClick={handleClick} sx={{ cursor: "pointer" }}>
                <TextField
                  size="small"
                  label="Date"
                  value={
                    formatDate(new Date(dateRanges.startDate), "yyyy-mm-dd") ==
                    formatDate(new Date(dateRanges.endDate), "yyyy-mm-dd")
                      ? `${formatDate(
                          new Date(dateRanges.startDate),
                          "relative_date"
                        )}`
                      : `${formatDate(
                          new Date(dateRanges.startDate),
                          "relative_date"
                        )} - ${formatDate(
                          new Date(dateRanges.endDate),
                          "relative_date"
                        )}`
                  }
                  sx={{
                    cursor: "pointer",
                    pointerEvents: "none",
                    minWidth:
                      formatDate(
                        new Date(dateRanges.startDate),
                        "yyyy-mm-dd"
                      ) ===
                      formatDate(new Date(dateRanges.endDate), "yyyy-mm-dd")
                        ? "200px"
                        : "270px",
                    transition: "all 0.3s",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarToday />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <DatePicker
                  dateRanges={dateRanges}
                  setDateRanges={setDateRanges}
                  onClose={handleClose}
                />
              </Popover>
            </Stack>
          </Box>
          <Box
            sx={{
              display: dateDimensions.includes(dimensions) ? "block" : "none",
            }}
          >
            <ReactApexChart
              options={{
                ...options,
                xaxis: {
                  show: dateDimensions.includes(dimensions),
                  type: "datetime",
                  //   min: isObjWithValues(series)
                  //     ? series[0].data[0]
                  //     : new Date().getTime(),
                  tickAmount: 6,
                },
              }}
              series={dateDimensions.includes(dimensions) ? series : []}
              type="area"
              height={400}
            />
          </Box>
          <Box
            sx={{
              display: !dateDimensions.includes(dimensions) ? "block" : "none",
            }}
          >
            <ReactApexChart
              options={{
                ...categoryOptions,
                yaxis: {
                  title: {
                    text: formatServerValue(dimensions),
                  },
                },
                xaxis: {
                  show: !dateDimensions.includes(dimensions),
                  labels: {
                    rotate: -45,
                  },
                  categories,
                  tickPlacement: "on",
                },
              }}
              series={categorySeries}
              // series={[
              //   {
              //     name: "Servings",
              //     data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 87, 65, 35],
              //   },
              //   {
              //     name: "Servings2",
              //     data: [ 45, 31, 87,44, 55, 41, 67, 22, 43, 21, 33, 65, 35],
              //   },
              // ]}
              type={"bar"}
              // type="line"
              height={400}
            />
          </Box>
        </CardContent>
      </Card>
      <Box sx={{ backgroundColor: "#fff", borderRadius: "15px" }}>
        <AnalyticsCards
          selectedAccount={selectedAccount}
          dateRanges={dateRanges}
        />
        <Collapse in={showMore}>
          <TopCards selectedAccount={selectedAccount} dateRanges={dateRanges} />
        </Collapse>
        <Stack direction={"row"} justifyContent="end">
          <Link
            sx={{
              hover: {
                backgroundColor: "transparent",
              },
              mr: 3,
              cursor: "pointer",
            }}
            endIcon={showMore ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            onClick={() => setShowMore((pre) => !pre)}
          >
            View {showMore ? "less" : "more"}
          </Link>
        </Stack>
      </Box>
    </Box>
  );
}

export default GoogleAnalytics;

const AnalyticsData = [
  { label: "Total Visitors", value: "totalUsers" },
  { label: "New Visitors", value: "newUsers" },
  { label: "Sessions", value: "sessions" },
  { label: "Page Views", value: "screenPageViews" },
  {
    label: "Average Session",
    value: "averageSessionDuration",
    suffix: "Mins",
  },
  { label: "Bounce Rate", value: "bounceRate", suffix: "%" },
];

const AnalyticsCards = ({ selectedAccount, dateRanges }) => {
  const [data, setData] = useState({
    averageSessionDuration: 0,
    bounceRate: 0,
    newUsers: 0,
    screenPageViews: 0,
    sessions: 0,
    totalUsers: 0,
  });
  const [fetchingAnalytics, setFetchingAnalytics] = useState(false);

  useEffect(() => {
    if (selectedAccount) getTopAnalytics();
  }, [dateRanges, selectedAccount]);

  const getTopAnalytics = async () => {
    setFetchingAnalytics(true);
    let payload = {
      endpoint: `/properties/${selectedAccount}:runReport`,
      payload: {
        metrics: [
          {
            name: "averageSessionDuration",
          },
          {
            name: "bounceRate",
          },
          {
            name: "newUsers",
          },
          {
            name: "screenPageViews",
          },
          {
            name: "sessions",
          },
          {
            name: "totalUsers",
          },
        ],
        dateRanges: [
          {
            startDate: formatDate(new Date(dateRanges.startDate), "yyyy-mm-dd"),
            endDate: formatDate(new Date(dateRanges.endDate), "yyyy-mm-dd"),
          },
        ],
        limit: "1",
      },
    };
    let response = await getAnalytics(payload);
    if (!isObjWithValues(response)) return setFetchingAnalytics(false);

    let { metricHeaders, rows } = response;
    let seriesObj = {};
    if (!isArrayWithValues(metricHeaders)) return setFetchingAnalytics(false);
    // let seriesObj = metricHeaders.map((i) => ({ [i.name]: 0 }));
    let keys = metricHeaders.map((i) => i.name);
    if (isArrayWithValues(rows) && isArrayWithValues(rows[0]?.metricValues)) {
      let index = 0;
      for (let key of keys) {
        let value = rows[0]?.metricValues[index]?.value;
        if (key === "averageSessionDuration")
          value = value ? Math.round(Number(value) / 60) : value;
        seriesObj[key] = validateNumber(Number(value).toFixed(2));
        index++;
      }
    }
    setFetchingAnalytics(false);
    setData(seriesObj);
  };
  return (
    <Grid
      container
      sx={{
        mt: 3,
        backgroundColor: "#fff",
        padding: "30px 15px",
        borderRadius: "15px",
        paddingBottom: "10px",
      }}
    >
      {AnalyticsData.map((i, index) => (
        <Grid item key={i.value} xs={6} sm={6} md={3} lg={2}>
          <NewTopCard
            title={i.label}
            value={data[i.value]}
            fetching={fetchingAnalytics}
            suffix={i.suffix}
            last={index === AnalyticsData.length - 1 ? true : false}
          />
        </Grid>
      ))}
    </Grid>
  );
};
const TopCards = ({ selectedAccount, dateRanges }) => {
  const [data, setData] = useState({
    browser: 0,
    city: 0,
    country: 0,
    deviceCategory: 0,
    pageReferrer: 0,
    operatingSystem: 0,
  });
  const [fetchingAnalytics, setFetchingAnalytics] = useState(false);

  useEffect(() => {
    if (selectedAccount) getTopAnalytics();
  }, [dateRanges, selectedAccount]);

  const getTopAnalytics = async () => {
    setFetchingAnalytics(true);
    let payload = {
      endpoint: `/properties/${selectedAccount}:runReport`,
      payload: {
        dimensions: [
          { name: "browser" },
          { name: "city" },
          { name: "country" },
          { name: "deviceCategory" },
          { name: "pageReferrer" },
          { name: "operatingSystem" },
        ],
        metrics: [{ name: "screenPageViews" }],
        dateRanges: [
          {
            startDate: formatDate(new Date(dateRanges.startDate), "yyyy-mm-dd"),
            endDate: formatDate(new Date(dateRanges.endDate), "yyyy-mm-dd"),
          },
        ],
        limit: "1",
        metricAggregations: ["MAXIMUM"],
      },
    };
    let response = await getAnalytics(payload);
    if (!isObjWithValues(response)) return setFetchingAnalytics(false);

    let { dimensionHeaders, rows } = response;
    let seriesObj = {};
    if (!isArrayWithValues(dimensionHeaders))
      return setFetchingAnalytics(false);
    // let seriesObj = dimensionHeaders.map((i) => ({ [i.name]: 0 }));
    let keys = dimensionHeaders.map((i) => i.name);
    if (
      isArrayWithValues(rows) &&
      isArrayWithValues(rows[0]?.dimensionValues)
    ) {
      let index = 0;
      for (let key of keys) {
        let value = rows[0]?.dimensionValues[index]?.value;
        if (key === "pageReferrer")
          value = value.replace(`${mainWebsite}/`, "/");
        seriesObj[key] = value;
        index++;
      }
    }
    setFetchingAnalytics(false);
    setData(seriesObj);
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        backgroundColor: "#fff",
        padding: "30px 15px",
        borderRadius: "15px",
        paddingBottom: "10px",
      }}
    >
      {cardsData.map((i, index) => (
        <Grid item key={i.value} xs={6} sm={6} md={3} lg={2}>
          <NewTopCard
            title={i.label}
            value={data[i.value]}
            fetching={fetchingAnalytics}
            fontSize="24px"
            last={index === cardsData.length - 1}
            downCard={true}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const cardsData = [
  {
    label: "Top Country",
    value: "country",
  },
  { label: "Top City", value: "city" },
  { label: "Top Browser", value: "browser" },
  { label: "Top Device", value: "deviceCategory" },
  { label: "Top Referrer", value: "pageReferrer" },
  { label: "Top OS", value: "operatingSystem" },
];

const numberLabels = [
  "Total Visitors",
  "New Visitors",
  "Sessions",
  "Page Views",
  "Avarage Session",
  "Bounce Rate",
];
const FloatLabels = ["Avarage Session", "Bounce Rate"];

const TopCard = ({ title, value, fetching, fontSize, suffix }) => {
  const [countRendering, setCountRendering] = useState(false);
  return (
    <Card sx={{}}>
      <CardContent>
        <Typography
          sx={{ fontSize: "15px", fontWeight: "500", color: "text.secondary" }}
        >
          {title}
        </Typography>
        <Stack direction={"row"} alignItems="flex-end">
          {numberLabels.includes(title) && value && !fetching ? (
            <CountUp
              end={value}
              duration={2}
              decimals={FloatLabels.includes(title) ? 2 : 0}
              onStart={() => setCountRendering(true)}
              onEnd={() => setCountRendering(false)}
              style={{
                fontSize: fontSize || "34px",
                fontWeight: "500",
                marginTop: "8px",
              }}
            />
          ) : (
            <Typography
              sx={{ fontSize: fontSize || "34px", fontWeight: "500", mt: 2 }}
            >
              {fetching ? "-" : value}
            </Typography>
          )}
          {title === "Top Country" && value
            ? getFlagImage(
                `https://www.countryflags.com/wp-content/uploads/${value.toLowerCase()}-flag-png-large.png`
              )
            : null}
          {title === "Top Browser" && value
            ? getFlagImage(
                `https://cdn1.iconfinder.com/data/icons/logotypes/32/${value.toLowerCase()}-128.png`,
                { style: { height: "25px", width: "25px" } }
              )
            : null}
          <Typography
            sx={{
              fontSize: fontSize || "22px",
              color: "text.secondary",
              mb: 1,
              ml: 2,
            }}
          >
            {suffix && !countRendering ? suffix : ""}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
const NewTopCard = ({
  title,
  value,
  fetching,
  fontSize,
  suffix,
  last,
  downCard,
}) => {
  const [countRendering, setCountRendering] = useState(false);
  return (
    <Box
      sx={{
        borderRight: 1,
        borderColor: last ? "#fff" : "#e8e8e8",
        paddingLeft: "30px",
        my: 2,
      }}
    >
      <Typography sx={{ fontSize: "17px", fontWeight: "500" }}>
        {title}
      </Typography>
      <Stack direction={"row"} alignItems="flex-end" gap={"5px"}>
        {title === "Top Country" && value
          ? getFlagImage(
              `https://www.countryflags.com/wp-content/uploads/${value.toLowerCase()}-flag-png-large.png`
            )
          : null}
        {title === "Top Browser" && value
          ? getFlagImage(
              `https://cdn1.iconfinder.com/data/icons/logotypes/32/${value.toLowerCase()}-128.png`,
              { style: { height: "25px", width: "25px" } }
            )
          : null}
        {numberLabels.includes(title) && value && !fetching ? (
          <CountUp
            end={value}
            duration={2}
            decimals={FloatLabels.includes(title) ? 2 : 0}
            onStart={() => setCountRendering(true)}
            onEnd={() => setCountRendering(false)}
            style={{
              fontSize: fontSize || "25px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          />
        ) : (
          <Typography
            sx={{ fontSize: fontSize || "25px", fontWeight: "500", mt: 2 }}
          >
            {fetching ? "-" : value}
          </Typography>
        )}

        <Typography
          sx={{
            fontSize: fontSize || "22px",
            color: "text.secondary",
            // mb: 1,
            ml: 2,
          }}
        >
          {suffix && !countRendering ? suffix : ""}
        </Typography>
        {/* {!downCard && (
          <AnalyticsChip sx={{ mb: 1 }} label={"10%"} type="success" />
        )} */}
      </Stack>
    </Box>
  );
};

export const getFlagImage = (url, options = {}) => {
  return (
    <img
      style={{
        width: "30px",
        height: "23px",
        borderRadius: "5px",
        marginLeft: "4px",
        marginBottom: "6px",
        ...options.style,
      }}
      onError={(event) => (event.target.style.display = "none")}
      onLoad={(event) => (event.target.style.display = "inline-block")}
      src={url}
      // src={`https://www.countryflags.com/wp-content/uploads/${value.toLowerCase()}-flag-png-large.png`}
    />
  );
};

const DatePicker = ({ dateRanges, setDateRanges, onClose }) => {
  const [tempDateRanges, setTempDateRanges] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(new Date().setDate(new Date().getDate() - 2)),
    // endDate: new Date(),
  });

  useEffect(() => {
    if (isObjWithValues(dateRanges)) setTempDateRanges(dateRanges);
  }, [dateRanges]);
  const onSubmit = () => {
    setDateRanges({
      startDate: new Date(
        tempDateRanges.startDate.setDate(tempDateRanges.startDate.getDate() + 1)
      ),
      endDate: new Date(
        tempDateRanges.endDate.setDate(tempDateRanges.endDate.getDate() + 1)
      ),
    });
    onClose();
  };
  return (
    <Box sx={{ p: 3 }}>
      <DateRangePicker
        ranges={[
          {
            ...tempDateRanges,
            key: "selection",
          },
        ]}
        onChange={({ selection }) => {
          setTempDateRanges((state) => ({
            endDate: selection ? selection.endDate : new Date(),
            startDate: selection ? selection.startDate : new Date(),
          }));
        }}
      />
      <Stack justifyContent={"flex-end"} direction="row" sx={{ mt: 3 }}>
        <Button onClick={onSubmit} sx={{ float: "right" }} variant="contained">
          Submit
        </Button>
      </Stack>
    </Box>
  );
};

const options = {
  chart: {
    // id: "area-datetime",
    type: "area",
    // type: "bar",
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
  },
  colors: ["#012687", "#0012b9", "#616ada", "#f6b11c"],
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },

  tooltip: {
    // x: {
    //   format: "dd MMM yyyy",
    // },
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
};

const categoryOptions = {
  chart: {
    height: 350,
    type: "bar",
    stacked: true,
  },
  colors: ["#012687", "#0012b9", "#616ada", "#f6b11c"],
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: "50%",
      // distributed: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 2,
  },

  grid: {
    row: {
      colors: ["#fff", "#f2f2f2"],
    },
  },

  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "horizontal",
      shadeIntensity: 0.25,
      gradientToColors: undefined,
      inverseColors: true,
      opacityFrom: 0.85,
      opacityTo: 0.85,
      stops: [50, 0, 100],
    },
  },
};
// const categoryOptions = {
//   chart: {
//     type: "bar",
//     height: 350,
//     stacked: true,
//     toolbar: {
//       show: true,
//     },
//     zoom: {
//       enabled: true,
//     },
//   },
//   responsive: [
//     {
//       breakpoint: 480,
//       options: {
//         legend: {
//           position: "bottom",
//           offsetX: -10,
//           offsetY: 0,
//         },
//       },
//     },
//   ],
//   plotOptions: {
//     bar: {
//       horizontal: true,
//       borderRadius: 10,
//       dataLabels: {
//         total: {
//           enabled: true,
//           style: {
//             fontSize: "13px",
//             fontWeight: 900,
//           },
//         },
//       },
//     },
//   },
//   legend: {
//     position: "right",
//     offsetY: 40,
//   },
//   fill: {
//     opacity: 1,
//   },
// };

const allMatrics = [
  { label: "Active Visitors", value: "activeUsers" },
  //   { label: "1 Day Active Visitors", value: "active1DayUser" },
  { label: "Avarage Session Duration", value: "averageSessionDuration" },
  // { label: "Added to Cart", value: "itemsAddedToCart" },
  { label: "Items Checked Out", value: "itemsCheckedOut" },
  { label: "Items Viewed", value: "itemsViewed" },
  { label: "Organic Google Search Clicks", value: "organicGoogleSearchClicks" },
  { label: "User Engegement Duration", value: "userEngagementDuration" },
  { label: "Add to Carts", value: "addToCarts" },
  {
    label: "Bounce Rate",
    value: "bounceRate",
  },
  {
    label: "New Visitors",
    value: "newUsers",
  },
  {
    label: "Checkouts",
    value: "checkouts",
  },
];
const allDimensions = [
  { label: "City", value: "city" },
  { label: "Country", value: "country" },
  { label: "Browser", value: "browser" },
  { label: "Device", value: "mobileDeviceModel" },
  { label: "Device Category", value: "deviceCategory" },
  // { label: "Landing Page", value: "landingPage" },
  // { label: "Page", value: "pageTitle" },
  // { label: "Platform Device Category", value: "platformDeviceCategory" },
  { label: "Source / Medium", value: "sourceMedium" },
  //   { label: "1 Day Active Visitors", value: "active1DayUser" },
  { label: "date", value: "date" },
  // {
  //   label: "Bounce Rate",
  //   value: "bounceRate",
  // },
  // {
  //   label: "New Visitors",
  //   value: "newUsers",
  // },
  // {
  //   label: "Checkouts",
  //   value: "checkouts",
  // },
];

export const getAnalytics = async (body) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/channel/google_analytics`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: body,
        method: "POST",
      });
      return data;
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  }
};
