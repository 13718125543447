// import { MenuItem, Select } from "@mui/material";
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import Select from "react-select";
import {
  containsText,
  getStoreId,
  goldPurities,
  isArrayWithValues,
  isObjWithValues,
} from "../../../../helper";
import { gemstoneShapes } from "../../../settings/MasterSettings";
import {
  diamondCut,
  diamondShapes,
  diamondTypes,
  gemstoneQualities,
  gemstoneTypes,
} from "../../../settings/MasterSettings";
import StaticDropdown from "../utils/static_dropdowns";
import { arePropsEqual } from "../../../../utils/js";
import {
  Autocomplete,
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  Popover,
  TextField,
  Typography,
  Popper,
  ListItem,
  Card,
  CardContent,
  ListItemButton,
  InputAdornment,
  ListSubheader,
  Checkbox,
  ListItemText,
  Tooltip,
} from "@mui/material";
import CustomChip from "../../../components/CustomChip";
import { ArrowDropDown, Search } from "@material-ui/icons";

const chipTypes = {
  stock_status: {
    instock: "completed",
    onbackorder: "pending",
    outofstock: "failed",
  },
  status: {
    publish: "completed",
    draft: "failed",
  },
};

const SelectComponent = (props) => {
  let {
    focus,
    active,
    stopEditing,
    column_key,
    column_name,
    rowData,
    setRowData,
    onValueChange,
    selectedValue,
    is_multiple_dropdown,
    options = [],
    duplicate_dropdown,
    search,
  } = props || {};
  const textinputRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => options?.filter((option) => containsText(option?.value, searchText)),
    [searchText]
  );

  const handleClick = (event) => {
    if (!anchorEl && !isArrayWithValues(options)) return;
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const optionClick = (value) => {
    setRowData(rowData ? `${rowData || ""}, ${value}` : value);
    handleClose();
    textinputRef?.current?.focus();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  // const choices = StaticDropdown?.[column_key]?.map((i) => ({ ...i })) || [];

  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  const handleDropdownOpen = () => {
    setDropdownOpen(true);
  };

  const selectedOption = options?.find(
    (option) => option.value === rowData || option.label === rowData
  )?.label;

  const onDropdownChange = (value) => {
    // setRowData({ ...allRowData, [column_key]: value });
    setRowData(value);
  };

  useEffect(() => {
    if (!focus || !active) {
      textinputRef?.current?.blur();
    }
  }, [focus, active]);

  if (!is_multiple_dropdown)
    return (
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        // multiple
        fullWidth
        value={selectedOption || ""}
        onChange={(e) => {
          setRowData(e.target.value);
        }}
        MenuProps={{ sx: { maxHeight: "300px" } }}
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": {
            border: "none !important",
          },
          pointerEvents: active ? undefined : "none",
        }}
        input={<OutlinedInput id="select-multiple-chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            <CustomChip
              key={selected}
              label={selected}
              size="small"
              type={chipTypes?.[column_key]?.[rowData] || "default"}
            />
            {/* {selected.map((value) => (
          ))} */}
          </Box>
        )}
      >
        {search && (
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {displayedOptions?.map((obj, index) => (
          <MenuItem key={obj.value + index} value={obj.value}>
            <Checkbox
              checked={Boolean(
                (isArrayWithValues(rowData) ? rowData : [rowData])?.includes(
                  obj?.label
                ) ||
                  (isArrayWithValues(rowData) ? rowData : [rowData])?.includes(
                    obj?.value
                  )
              )}
            />
            <ListItemText primary={obj.label} />
          </MenuItem>
        ))}
        {/* {options?.map((obj) => (
          <MenuItem
            key={obj.value}
            value={obj.value}
            // style={getStyles(obj, personName, theme)}
          >
            {obj?.label}
          </MenuItem>
        ))} */}
      </Select>
    );
  else if (duplicate_dropdown)
    return (
      <>
        <TextField
          // ref={textinputRef}
          inputRef={textinputRef}
          onClick={handleClick}
          value={rowData}
          onChange={(e) => setRowData(e.target.value)}
          fullWidth
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": {
              border: "none !important",
            },
            pointerEvents: active ? undefined : "none",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDown />
              </InputAdornment>
            ),
          }}
        />
        <Popper id={id} open={open} anchorEl={anchorEl}>
          <Card
            sx={{
              maxHeight: "300px",
              overflow: "scroll",
              p: 0,
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
              // "0rem 0.25rem 0.125rem -0.125rem #1f212414, 0rem 0.75rem 0.125rem -0.125rem #1f212426",
            }}
          >
            <CardContent sx={{ px: 0 }}>
              {options?.map((obj) => (
                <ListItemButton
                  key={obj.value + index}
                  value={obj.value}
                  onClick={() => optionClick(obj.value)}
                  sx={{ cursor: "pointer", px: 4 }}
                  // style={getStyles(obj, personName, theme)}
                >
                  {obj?.label}
                </ListItemButton>
              ))}
            </CardContent>
          </Card>
        </Popper>
      </>
    );
  // return (
  //   <Autocomplete
  //     id="free-solo-demo"
  //     freeSolo
  //     // value={isArrayWithValues(rowData) ? rowData : []}
  //     options={top100Films.map((option) => option.title)}
  //     renderInput={(params) => <TextField {...params} />}
  //     selectOnFocus
  //     ref={textinputRef}
  //     // inputValue={rowData || ""}
  //     // onInputChange={(e) => {
  //     //   console.log(e.target.value);
  //     // }}
  //     onClose={handleDropdownClose}
  //     onOpen={handleDropdownOpen}
  //     onClick={handleDropdownOpen}
  //     autoSelect
  //     multiple
  //     fullWidth
  //     renderTags={(values) => {
  //       console.log(values, "props");
  //       return values?.map((i) => <div>{i},</div>);
  //     }}
  //     limitTags={2}
  //     sx={{
  //       boxShadow: "none",
  //       ".MuiOutlinedInput-notchedOutline": {
  //         border: "none !important",
  //       },
  //       pointerEvents: active ? undefined : "none",
  //     }}
  //   />
  // );

  return (
    <Tooltip
      title={isArrayWithValues(rowData) && !dropdownOpen ? rowData?.join() : ""}
    >
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        fullWidth
        disabled={props.disabled}
        open={dropdownOpen}
        onOpen={handleDropdownOpen}
        onClose={handleDropdownClose}
        value={isArrayWithValues(rowData) ? rowData : []}
        // value={selectedOption}
        onChange={(e) => {
          let values = e.target.value || [];
          onDropdownChange([...values]);
        }}
        MenuProps={{ sx: { maxHeight: "300px" } }}
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": {
            border: "none !important",
          },
          pointerEvents: active ? undefined : "none",
        }}
        renderValue={(selected) => selected.join(", ")}
        // input={<OutlinedInput id="select-multiple-chip" />}
        // renderValue={(selected) => (
        //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        //     <CustomChip
        //       key={selected}
        //       label={selected}
        //       size="small"
        //       type={chipTypes?.[column_key]?.[rowData] || "default"}
        //     />
        //     {/* {selected.map((value) => (
        //   ))} */}
        //   </Box>
        // )}
      >
        {search && (
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {displayedOptions?.map((obj) => (
          <MenuItem key={obj} value={obj.value}>
            <Checkbox
              checked={Boolean(
                (isArrayWithValues(rowData) ? rowData : [rowData])?.includes(
                  obj?.label
                ) ||
                  (isArrayWithValues(rowData) ? rowData : [rowData])?.includes(
                    obj?.value
                  )
              )}
            />
            <ListItemText primary={obj.label} />
          </MenuItem>
        ))}
        {/* {options?.map((obj, index) => (
          <MenuItem
            key={obj.value + index}
            value={obj.value}
            
          >
            {obj?.label}
          </MenuItem>
        ))} */}
      </Select>
    </Tooltip>
  );

  return (
    <Autocomplete
      fullWidth
      multiple={is_multiple_dropdown}
      ref={ref}
      options={options}
      value={selectedValue}
      isSearchable
      isMulti={is_multiple_dropdown}
      renderInput={(params) => (
        <TextField
          {...params}
          style={{ borderColor: "transparent" }}
          label=""
          variant="standard"
          inputProps={{
            style: {
              height: "38px",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                border: "none",
              },
            },
          }}
        />
      )}
      onChange={(a, e) => {
        // console.log(e, "<<<<");
      }}
      styles={{
        container: (provided) => ({
          ...provided,
          flex: 1, // full width
          alignSelf: "stretch", // full height
        }),
        control: (provided) => ({
          ...provided,
          height: "100%",
          border: "none",
          boxShadow: "none",
          background: "none",
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          opacity: 0,
        }),
      }}
    />
  );
};

const createSpreadSheetData = ({
  customers = [],
  allCustomerGroup,
  states,
  CRMCustomFields,
}) => {
  let storeId = getStoreId();
  let spreadsheetArray = [];
  let sheetHeaders = getSheetHeaders({ store_id: storeId });
  for (let customer of customers) {
    let { meta_data, billing } = customer;
    if (isArrayWithValues(meta_data)) {
      for (let metaObj of meta_data) customer[metaObj.key] = metaObj.value;
    }
    if (customer.error_message) customer = { ...customer };
    else customer = { ...customer, action: "synced" };
    if (isObjWithValues(billing))
      for (let key in billing) if (billing[key]) customer[key] = billing[key];

    if (customer.state && customer.country) {
      let stateObj = states?.[customer.country]?.find(
        (i) => i.code === customer.state
      );
      if (stateObj?.label) customer.state = stateObj?.label;
    }
    if (isArrayWithValues(customer[`group_name_${storeId}`])) {
      let labelNameArray =
        allCustomerGroup?.map((obj) => obj?.group_name) || [];
      customer[`group_name_${storeId}`] = customer[`group_name_${storeId}`]
        ?.filter((str) => labelNameArray?.includes(str))
        .join();
    }
    spreadsheetArray.push(
      customer
      // sheetHeaders.map((i) =>
      //   typeof customer[i.value] === "string" && customer[i.value]
      //     ? customer[i.value]
      //     : ""
      // )
    );
  }
  return spreadsheetArray;
};

const _SelectComponent = React.memo(
  SelectComponent
  // (a, b) =>
  // arePropsEqual(a, b, [])
);
let getSheetHeaders = ({ store_id }) => {
  return [
    { label: "Actions", value: "action" },
    { label: "Errors", value: "error_message" },
    { label: "Registered Date", value: "date_created" },
    { label: "First Name", value: "first_name" },
    { label: "Last Name", value: "last_name" },
    { label: "Contact Type", value: "contact_type" },
    { label: "Email", value: "email" },
    // { label: "Nickname", value: "nickname" },
    { label: "Dial Code 1", value: "dialcode_mobile" },
    { label: "Phone", value: "phone" },
    { label: "Dial Code 2", value: "dialcode_whatsapp" },
    { label: "Whatsapp", value: "whatsapp" },
    { label: "Groupings", value: `group_name_${store_id}` },
    { label: "Address Line 1", value: "address_1" },
    { label: "Address Line 2", value: "address_2" },
    { label: "City", value: "city" },
    { label: "Postcode", value: "postcode" },
    { label: "State", value: "state" },
    { label: "Country", value: "country" },
    { label: "Branch", value: "branch_from" },
    { label: "Customer Since", value: "customer_since" },
    { label: "First Purchase", value: "first_purchase" },
    { label: "Total Purchase", value: "total_purchase" },
    { label: "Visits In Showroom", value: "visits_in_showroom" },
    { label: "Last Visit In Showroom", value: "last_visit_in_showrrom" },
    // { label: "Visits On Website", value: "visits_on_website" },
    // { label: "Last Visit On Website", value: "last_visit_on_website" },
    { label: "Birthday", value: "user_birthday" },
    { label: "Anniversary", value: "user_anniversary" },
    { label: "Profession", value: "profession" },
    { label: "Annual Income", value: "annual_income" },
    { label: "Ethnicity", value: "ethincity" },
    { label: "Language", value: "language" },
    { label: "Religion", value: "religion" },
    { label: "Sub-Sect", value: "Sub-Sect" },
    { label: "Facebook", value: "customer_facebook" },
    { label: "Instagram", value: "customer_instagram" },
    { label: "Twitter", value: "customer_twitter" },
    { label: "Linkedin", value: "customer_linkedin" },
    { label: "YouTube", value: "customer_youtube" },
    { label: "Pinterest", value: "customer_pinterest" },
  ];
};

export { _SelectComponent as SelectComponent, createSpreadSheetData };
