import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { CardMedia, Typography, IconButton, Box } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PropTypes from "prop-types";
import CircleIcon from "@mui/icons-material/Circle";
import { useDispatch } from "react-redux";
import { chatbotHeaderStyles } from "../styles/chatbotHeaderStyles";
import * as actions from "../../../../../../redux/actions/flowsActions";
import "../public/css/popover-options.css";
import TopSectionPopover from "../helper/TopSectionPopover";
import HeaderTop from "../helper/HeaderTop";
import defaultBotIcon from "../assets/default_bot_image.png";

const styles = chatbotHeaderStyles;

const TopSection = ({ setChatbot, sendDataToBackend }) => {
  const dispatch = useDispatch();
  const web_chat_customization = useSelector(
    (state) => state.flow?.chatbotCustomization
  );
  const botName = web_chat_customization?.bot_name;
  const tagline = web_chat_customization?.bot_tagline;
  const headerColor = useSelector(
    (state) => state.flow?.chatbotStyles
  )?.headerColor;
  const botIcon = useSelector((state) => state.flow?.chatbotStyles)?.botIcon;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    dispatch({ type: actions.RESET_TEST_BOT_MESSAGES });
    setAnchorEl(null);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: {
        isMinimized: true,
      },
    });
    // setChatbot(false);
  };

  const handleReset = async () => {
    dispatch({ type: actions.RESET_TEST_BOT_MESSAGES });
    setAnchorEl(null);
    sendDataToBackend();
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOptions = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      <Box sx={styles.mainContainer}>
        <HeaderTop handleClose={handleClose} />
        {/* <Card style={{ ...styles.cardDiv, backgroundColor: headerColor }}> */}
        <Box style={styles.cardDiv}>
          <Box sx={styles.imgDiv}>
            <CircleIcon
              sx={styles.liveAgentIcon}
              style={{ height: "12px", width: "12px" }}
            />
            <CardMedia
              component="img"
              style={styles.botLogo}
              image={defaultBotIcon || brandIcon || defaultBotIcon}
              alt="Live from space album cover"
            />
          </Box>
          <Box style={styles.headerTextField}>
            <Typography variant="span" style={styles.botNameText}>
              {botName || "Bot"}
            </Typography>
            <Typography variant="span" style={styles.botStatusText}>
              {tagline || "Online"}
            </Typography>
          </Box>
          <Box style={styles.headerIconsDiv}>
            <IconButton onClick={handleOptions}>
              <MoreVertIcon style={styles.headerIcon} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <TopSectionPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleReset={handleReset}
        handlePopoverClose={handlePopoverClose}
      />
    </>
  );
};

TopSection.propTypes = {
  setChatbot: PropTypes.func.isRequired,
  sendDataToBackend: PropTypes.func.isRequired,
};

export default TopSection;
