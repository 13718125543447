import { isArrayWithValues } from "jwero-javascript-utils";

const getJweroCustomerPhoneNumber = ({ customer = {} }) => {
  let { meta_data, billing } = customer;
  let obj = {
    phone: billing ? billing.phone : "",
  };
  if (isArrayWithValues(meta_data))
    meta_data.map((i) => {
      if (
        ["whatsapp", "dialcode_whatsapp", "dialcode_mobile"].includes(i.key) &&
        i.value
      )
        obj[i.key] = i.value;
    });
  if (obj.whatsapp && obj.dialcode_whatsapp) {
    return {
      success: true,
      data: `${obj.dialcode_whatsapp || ""}${obj.whatsapp || ""}`
        .replace(/\s/g, "")
        .trim(),
      dialcode: obj.dialcode_whatsapp?.replace(/\s/g, "").trim(),
      phone: obj.whatsapp?.replace(/\s/g, "").trim(),
    };
  } else if (obj.mobile && obj.dialcode_mobile) {
    return {
      success: true,
      data: `${obj.dialcode_mobile || ""}${obj.mobile || ""}`
        .replace(/\s/g, "")
        .trim(),
      dialcode: obj.dialcode_mobile?.replace(/\s/g, "").trim(),
      phone: obj.mobile?.replace(/\s/g, "").trim(),
    };
  } else if (obj.phone && obj.dialcode_mobile) {
    return {
      success: true,
      data: `${obj.dialcode_mobile || ""}${obj.phone || ""}`
        .replace(/\s/g, "")
        .trim(),
      dialcode: obj.dialcode_mobile?.replace(/\s/g, "").trim(),
      phone: obj.phone?.replace(/\s/g, "").trim(),
    };
  } else return { success: false };
};

export { getJweroCustomerPhoneNumber };
