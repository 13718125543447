import axios from "axios";
import {
  apikey,
  fetchProducts,
  formatDate,
  getAxiosError,
  getRelativeTimeDifference,
  getTemplateVariableObjFromCustomer,
  getToken,
  getVisitorsInfo,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  mainSocketEvents,
  productViewsEvent,
  sendSingleTemplate,
  setupDigigoldUsers,
  validateNumber,
} from "../../helper";
import * as qs from "qs";
import { SUPPLIER_EDIT } from "./supplierActions";
import { UAParser } from "ua-parser-js";
import { setGlobalToast } from "./helperActions";
import { EDIT_CHATS, setCountOnSegment } from "./chatActions";

export const SET_CRM_DATA = "SET_CRM_DATA";
export const APPEND_CUSTOMERS = "APPEND_CUSTOMERS";

export const editCRM = (payload) => ({ type: SET_CRM_DATA, payload });

export const fetchCustomers = (params, clear) => {
  return (dispatch) => {
    let website = getWebsite();
    let token = getToken();

    dispatch({
      type: SET_CRM_DATA,
      payload: {
        noCustomersFound: false,
        fetchingCustomers: true,
        // allCustomers: allCustomers,
      },
    });
    if (clear)
      dispatch({
        type: SET_CRM_DATA,
        payload: {
          allCustomers: [],
        },
      });
    axios({
      url: `${website}/wp-json/wc/v3/customers`,
      params,
      headers: {
        Authorization: `Basic ${token}`,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    })
      .then((res) => {
        dispatch({
          type: SET_CRM_DATA,
          payload: {
            totalCustomersFound: res.headers?.["x-wp-total"] || 0,
          },
        });
        if (isArrayWithValues(res.data)) {
          // let customersArray =
          // let customersArray = [
          //   ...new Set([...allCustomers, ...res.data].map((tag) => tag)),
          // ];
          // console.log(customersArray);
          dispatch({
            type: SET_CRM_DATA,
            payload: {
              fetchingCustomers: false,
              allCustomers: [...res.data],
            },
          });
        } else
          dispatch({
            type: SET_CRM_DATA,
            payload: { noCustomersFound: true, allCustomers: [] },
          });
      })
      .catch(() => {
        dispatch({
          type: SET_CRM_DATA,
          payload: {
            totalCustomersFound: 0,
          },
        });
      })
      .finally(() => {
        dispatch({
          type: SET_CRM_DATA,
          payload: { fetchingCustomers: false },
        });
      });
  };
};
export const appendNextCusromer = (params) => {
  let website = getWebsite();
  let token = getToken();
  if (!website || !token) return false;
  console.log(params);
  return (dispatch, getState) => {
    let state = getState();
    let allCustomers = state.customers.allCustomers;
    dispatch({
      type: SET_CRM_DATA,
      payload: {
        noCustomersFound: false,
        appendingCustomers: true,
        // fetchingCustomers: true,
        // allCustomers: allCustomers,
      },
    });
    axios({
      url: `${website}/wp-json/wc/v3/customers`,
      params,
      headers: {
        Authorization: `Basic ${token}`,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    })
      .then((res) => {
        console.log(res);
        if (Array.isArray(res.data)) {
          if (isArrayWithValues(res.data)) {
            dispatch({
              type: SET_CRM_DATA,
              payload: {
                // fetchingCustomers: false,
                allCustomers: [...allCustomers, ...res.data],
              },
            });
          }
          if (params.per_page && res.data.length < params.per_page) {
            dispatch({
              type: SET_CRM_DATA,
              payload: {
                noCustomersFound: true,
              },
            });
          } else
            dispatch({
              type: SET_CRM_DATA,
              payload: {
                noCustomersFound: false,
              },
            });
        }
      })
      .finally(() => {
        dispatch({
          type: SET_CRM_DATA,
          payload: { appendingCustomers: false },
        });
      });
  };
};

// export const fetchCustomers = (params, wipeData, clearCache) => {
//   // if (clearCache)
//   params.random_key = Math.random();
//   return (dispatch) => {
//     let website = getWebsite();
//     let token = getToken();
//     if (wipeData)
//       dispatch({
//         type: SET_CRM_DATA,
//         payload: { allCustomers: [] },
//       });
//     dispatch({
//       type: SET_CRM_DATA,
//       payload: { noCustomersFound: false, fetchingCustomers: true },
//     });
//     if (website && token)
//       axios({
//         url: `${website}/wp-json/wc/v3/customers`,
//         params,
//         headers: {
//           Authorization: `Basic ${token}`,
//         },
//       })
//         .then((res) => {
//           if (Array.isArray(res.data)) {
//             if (isArrayWithValues(res.data)) {
//               dispatch({
//                 type: SET_CRM_DATA,
//                 payload: { allCustomers: res.data },
//               });
//             } else
//               dispatch({
//                 type: SET_CRM_DATA,
//                 payload: { noCustomersFound: true, allCustomers: [] },
//               });
//           }
//         })
//         .finally(() => {
//           dispatch({
//             type: SET_CRM_DATA,
//             payload: { fetchingCustomers: false },
//           });
//         });
//   };
// };

export const fetchChatCustomers = (params, wipeData) => {
  // params.random_key = Math.random();
  // params.sortby = "recent_communication";
  console.log(params);
  return (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (wipeData)
      dispatch({
        type: SET_CRM_DATA,
        payload: { allChatCustomers: [] },
      });
    dispatch({
      type: SET_CRM_DATA,
      payload: { noCustomersFound: false, fetchingCustomers: true },
    });
    console.log(website, token);
    if (website && token)
      axios({
        url: `${website}/wp-json/wc/v3/customers`,
        params,
        headers: {
          Authorization: `Basic ${token}`,
        },
      })
        .then((res) => {
          console.log(res.data);
          if (Array.isArray(res.data)) {
            if (isArrayWithValues(res.data)) {
              dispatch({
                type: SET_CRM_DATA,
                payload: { allChatCustomers: res.data },
              });
            } else
              dispatch({
                type: SET_CRM_DATA,
                payload: { noCustomersFound: true, allChatCustomers: [] },
              });
          }
        })
        .catch((e) => console.log(getAxiosError(e)))
        .finally(() => {
          dispatch({
            type: SET_CRM_DATA,
            payload: { fetchingCustomers: false },
          });
        });
  };
};

export const appendAllCustomers = (params) => {
  console.log(params, "append customers");
  params.random_key = Math.random();
  return (dispatch) => {
    dispatch({
      type: SET_CRM_DATA,
      payload: {
        fetchingCustomers: true,
        noCustomersFound: false,
        allCustomersFetched: false,
      },
    });

    let website = getWebsite();
    let token = getToken();
    if (website && token)
      axios({
        url: `${website}/wp-json/wc/v3/customers`,
        params,
        headers: {
          Authorization: `Basic ${token}`,
        },
      })
        .then((res) => {
          // if (Array.isArray(res.data))
          let { data } = res;
          if (Array.isArray(data)) {
            if (data.length === 0)
              dispatch({
                type: SET_CRM_DATA,
                payload: { noCustomersFound: true },
              });
            if (data.length !== params.per_page)
              dispatch({
                type: SET_CRM_DATA,
                payload: { allCustomersFetched: true },
              });
          }
          if (isArrayWithValues(res.data)) {
            dispatch({
              type: APPEND_CUSTOMERS,
              payload: res.data,
            });
          } else
            dispatch({
              type: SET_CRM_DATA,
              payload:
                Object.keys(params).length > 2
                  ? { noCustomersFound: true, allCustomers: [] }
                  : { noCustomersFound: true },
            });
        })
        .finally(() => {
          dispatch({
            type: SET_CRM_DATA,
            payload: {
              fetchingCustomers: false,
            },
          });
        });
  };
};

export const unshiftCustomer = (customer) => {
  return (dispatch, getState) => {
    let state = getState();
    if (isArrayWithValues(state.customers.allCustomers)) {
      let allCustomers = [...state.customers.allCustomers];
      if (isObjWithValues(customer)) {
        allCustomers.unshift(customer);
        dispatch({
          type: SET_CRM_DATA,
          payload: {
            allCustomers,
          },
        });
      }
    }
  };
};

// export const updateCustomerInRedux = (customer) => {
//   return (dispatch, getState) => {
//     try {
//       if (customer.id) {
//         let state = getState();
//         let allCustomers = state.customers.allCustomers;
//         let allChatCustomers = state.customers.allChatCustomers;

//         if (isArrayWithValues(allChatCustomers)) {
//           allChatCustomers = allChatCustomers.map((i) =>
//             i.id === customer.id ? customer : i
//           );
//           dispatch({
//             type: SET_CRM_DATA,
//             payload: { allChatCustomers },
//           });
//         }
//         if (isArrayWithValues(allCustomers)) {
//           allCustomers = allCustomers.map((i) =>
//             i.id === customer.id ? customer : i
//           );
//           dispatch({
//             type: SET_CRM_DATA,
//             payload: { allCustomers },
//           });
//         }
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   };
// };

// export const getAllCustomerLabels = () => {
//   return async (dispatch) => {
//     let website = await getWebsite();
//     let token = await getToken();

//     axios({
//       url: `${website}/wp-json/store/v1/settings`,
//       headers: {
//         Authorization: `Basic ${token}`,
//       },
//       params: { options: "customer_labels" },
//     }).then((res) => {
//       let { data } = res;
//       console.log(data, "<<<<<<");
//       if (data.success && data.data) {
//         dispatch({
//           type: SET_CRM_DATA,
//           payload: { allCustomerLabels: data.data.customer_labels || [] },
//         });
//       }
//     });
//   };
// };

export const addCustomerLabel = (data, onSuccess, onError) => {
  return async (dispatch) => {
    let website = await getWebsite();
    let token = await getToken();

    axios({
      url: `${website}/wp-json/store/v1/settings`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      // params: { options: "customer_labels" },
      // data: { type: "customer_labels", data },
      data: { customer_labels: [...data] },
      method: "POST",
    })
      .then((res) => {
        let { data } = res;
        console.log(data, "<<<<<<");
        onSuccess && onSuccess();
        if (data.success) {
          dispatch({
            type: SET_CRM_DATA,
            payload: { allCustomerLabels: data.data },
          });
        }
      })
      .catch((e) => {
        console.log(getAxiosError(e));
        onError && onError();
      });
  };
};
// export const getRetailerInvites = () => {
//   return async (dispatch) => {
//     let website = await getWebsite();
//     let token = await getToken();

//     axios({
//       url: `${website}/wp-json/store/v1/settings/retailer_invites`,
//       headers: {
//         Authorization: `Basic ${token}`,
//       },
//     })
//       .then((res) => {
//         let { data } = res;
//         if (data.success) {
//           let invites = data.data || {};
//           dispatch({
//             type: SET_CRM_DATA,
//             payload: { retailer_invites: Object.values(invites) },
//           });
//         }
//       })
//       .catch((e) => {
//         console.log(getAxiosError(e));
//       });
//   };
// };

export const fetchRetailerActivity = (options) => {
  return async (dispatch) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        let res = await axios({
          url: `${website}/wp-json/store/v1/settings`,
          params: { options },
          headers: {
            Authorization: `Basic ${token}`,
          },
        });
        if (res.data.success && isObjWithValues(res.data.data)) {
          let obj = {};
          for (let i in res.data.data) {
            let id = i.replace("retailer_activity_", "");
            if (res.data.data[i]) obj[id] = res.data.data[i];
          }
          dispatch({
            type: SET_CRM_DATA,
            payload: { retailer_activities: obj },
          });
        } else return {};
      } catch (error) {
        console.log(getAxiosError(error));
        // return {};
      }
    }
  };
};
// export const getGroupingData = () => {
//   return async (dispatch) => {
//     try {
//       const res = await axios({
//         url: `${website}/wp-json/store/v1/settings`, headers: {
//           Authorization: `Basic ${token}`,
//         },
//         params: {
//           options: "crm_grouping_names"
//         }
//       })
//       console.log(res.data);
//       if (res.data.success) {
//         dispatch({
//           type: SET_CRM_DATA,
//           payload: { crmGrouping: res.data.data.crm_grouping_names },
//         });
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }
// }
export const addCustomerInRedux = (customer) => {
  console.log(customer);
  return (dispatch, getState) => {
    dispatch({
      type: SET_CRM_DATA,
      payload: {
        noCustomersFound: false,
        // fetchingCustomers: true,
      },
    });
    console.log("append");
    if (customer?.id) {
      let state = getState();
      let allCustomers = state.customers.allCustomers;
      allCustomers.unshift(customer);

      dispatch({
        type: SET_CRM_DATA,
        payload: {
          allCustomers,
          fetchingCustomers: false,
        },
      });
      console.log("customer added");
    }
    // } catch (error) {
    //   console.log("====================================");
    //   console.log(error);
    //   console.log("====================================");
    // }
  };
};

export const updateCustomerInRedux = (customer) => {
  return (dispatch, getState) => {
    try {
      if (customer.id) {
        let state = getState();
        let allCustomers = state.customers.allCustomers;
        if (isArrayWithValues(allCustomers)) {
          if (allCustomers.some((e) => Number(e.id) === Number(customer.id))) {
            let updateCustomers = allCustomers.map((i) =>
              Number(i.id) === Number(customer.id) ? { ...i, ...customer } : i
            );
            dispatch({
              type: SET_CRM_DATA,
              payload: { allCustomers: updateCustomers },
            });
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};
export const deleteCustomerInRedux = (customer_id) => {
  return (dispatch, getState) => {
    try {
      if (customer_id) {
        let state = getState();
        let allCustomers = state.customers.allCustomers;
        if (isArrayWithValues(allCustomers)) {
          let updateCustomers = allCustomers.filter(
            (i) => Number(i.id) !== Number(customer_id)
          );
          dispatch({
            type: SET_CRM_DATA,
            payload: { allCustomers: updateCustomers },
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const addMultipleCustomerInRedux = (customerArray) => {
  return (dispatch, getState) => {
    if (isArrayWithValues(customerArray)) {
      let state = getState();
      let allCustomers = state.customers.allCustomers;
      allCustomers = [...customerArray, ...allCustomers];
      dispatch({
        type: SET_CRM_DATA,
        payload: {
          allCustomers,
          fetchingCustomers: false,
        },
      });
    }
  };
};

export const deleteMultipleCustomerInRedux = (customer_ids, options) => {
  return (dispatch, getState) => {
    let { navigate } = options;
    try {
      if (isArrayWithValues(customer_ids)) {
        let state = getState();
        let allCustomers = state.customers.allCustomers;
        if (isArrayWithValues(allCustomers)) {
          let updateCustomers = allCustomers.filter(
            (i) => !customer_ids.includes(Number(i.id))
          );
          dispatch({
            type: SET_CRM_DATA,
            payload: { allCustomers: updateCustomers },
          });
          navigate && navigate(`/crm/${updateCustomers?.[0]?.id}#info`);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateMultipleCustomersInRedux = (customers) => {
  return (dispatch, getState) => {
    try {
      if (isArrayWithValues(customers)) {
        let state = getState();
        let allCustomers = state.customers.allCustomers;
        if (isArrayWithValues(allCustomers)) {
          let updatedCustomers = [];
          for (let i = 0; i < allCustomers.length; i++) {
            let id = allCustomers[i].id;
            let updatedProduct = customers.find((i) => i.id === id);
            if (updatedProduct) updatedCustomers.push(updatedProduct);
            else updatedCustomers.push(allCustomers[i]);
          }
          dispatch({
            type: SET_CRM_DATA,
            payload: { allCustomers: updatedCustomers },
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getDigigoldUsers = (params, clear) => {
  console.log(params, "fetching digi users");
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    dispatch({
      type: SET_CRM_DATA,
      payload: {
        noDigiCustomersFound: false,
        fetchingDigiCustomers: true,
      },
    });
    if (clear)
      dispatch({
        type: SET_CRM_DATA,
        payload: {
          allDigiCustomers: [],
        },
      });
    let { data } = await axios({
      // url: `${website}/wp-json/store/v1/digigold`,
      url: `${website}/wp-json/store/v1/digigold/new`,
      // url: `${url}/customers`,
      // params,
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    let removedPendingPlansFromCustomer = { ...(data || {}) };
    if (isObjWithValues(data)) {
      for (let customer_id in removedPendingPlansFromCustomer) {
        let { plans } = removedPendingPlansFromCustomer?.[customer_id];
        if (isArrayWithValues(plans?.ledgers)) {
          plans.ledger = plans.ledger?.filter((i) => i?.status !== `pending`);
          removedPendingPlansFromCustomer[customer_id].plans = plans;
        }
      }
      dispatch({
        type: SET_CRM_DATA,
        payload: {
          fetchingDigiCustomers: false,
          allDigiCustomers: setupDigigoldUsers(removedPendingPlansFromCustomer),
        },
      });
    } else
      dispatch({
        type: SET_CRM_DATA,
        payload: { noDigiCustomersFound: true, allDigiCustomers: [] },
      });

    dispatch({
      type: SET_CRM_DATA,
      payload: { fetchingDigiCustomers: false },
    });
  };
};

export const updateDigigoldUserInRedux = ({ user }) => {
  return (dispatch, getState) => {
    let state = getState();
    let updatedDigiCustomers = state.customers.updatedDigiCustomers;
    let allDigiCustomersArray = state?.customers?.allDigiCustomers;
    allDigiCustomersArray = allDigiCustomersArray?.map((i) =>
      i?.id + "" === user?.id + "" ? { ...i, ...user } : i
    );
    let allDigiCustomersObj = {};
    for (let customer of allDigiCustomersArray)
      allDigiCustomersObj[customer?.id] = customer;
    let removedPendingPlansFromCustomer = { ...(allDigiCustomersObj || {}) };
    if (isObjWithValues(allDigiCustomersObj)) {
      for (let customer_id in removedPendingPlansFromCustomer) {
        let { plans } = removedPendingPlansFromCustomer?.[customer_id];
        if (isArrayWithValues(plans?.ledgers)) {
          plans.ledger = plans.ledger?.filter((i) => i?.status !== `pending`);
          removedPendingPlansFromCustomer[customer_id].plans = plans;
        }
      }
      updatedDigiCustomers = updatedDigiCustomers?.map((i) =>
        i?.id + "" === user?.id + "" ? { ...i, ...user } : i
      );

      dispatch({
        type: SET_CRM_DATA,
        payload: {
          allDigiCustomers: setupDigigoldUsers(removedPendingPlansFromCustomer),
          updatedDigiCustomers,
        },
      });
    }
  };
};

export const getDigigoldCustomer = (id) => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      dispatch({
        type: SET_CRM_DATA,
        payload: {
          fetchingDigiCustomer: true,
        },
      });
      try {
        let res = await axios({
          url: `${website}/wp-json/wc/v3/customers/${id}?random_key=${Math.random()}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
        });
        if (isObjWithValues(res.data)) {
          dispatch({
            type: SET_CRM_DATA,
            payload: {
              digigold_user_view: res.data,
            },
          });
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
      dispatch({
        type: SET_CRM_DATA,
        payload: {
          fetchingDigiCustomer: false,
        },
      });
    }
  };
};

export const fetchRecentCustomers = (params, clear) => {
  let website = getWebsite();
  let token = getToken();
  if (!website || !token) return false;
  return (dispatch, getState) => {
    dispatch({
      type: SET_CRM_DATA,
      payload: {
        noCustomersFound: false,
        fetchingRecentCustomers: true,
        // allCustomers: allCustomers,
      },
    });

    axios({
      url: `${website}/wp-json/wc/v3/customers`,
      params: {
        page: 1,
        per_page: 5,
        sortby: "last_active",
      },
      headers: {
        Authorization: `Basic ${token}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        if (isArrayWithValues(res.data)) {
          // let customersArray =
          // let customersArray = [
          //   ...new Set([...allCustomers, ...res.data].map((tag) => tag)),
          // ];
          // console.log(customersArray);
          dispatch({
            type: SET_CRM_DATA,
            payload: {
              fetchingRecentCustomers: false,
              recentCustomers: [...res.data],
            },
          });
        } else
          dispatch({
            type: SET_CRM_DATA,
            payload: { noCustomersFound: true, allCustomers: [] },
          });
      })
      .finally(() => {
        dispatch({
          type: SET_CRM_DATA,
          payload: { fetchingRecentCustomers: false },
        });
      });
  };
};

export const getDigiCustomerReferrer = (id, order_ids) => {
  return async (dispatch, getState) => {
    let website = getWebsite();
    let token = getToken();
    let state = getState();
    let updatedDigiCustomers = state.customers.updatedDigiCustomers;
    if (!website && !token) return false;
    if (!isArrayWithValues(updatedDigiCustomers)) return false;
    let referIds = [];
    for (let customer of updatedDigiCustomers) {
      let { meta_data, fetchedRefer } = customer;
      if (fetchedRefer) continue;
      let referId = meta_data.find((i) => i.key === "digiReferredFrom")?.value;
      if (referId && !referIds.includes(referId)) referIds.push(referId);
    }
    if (!isArrayWithValues(referIds)) return false;

    dispatch({
      type: "SET_CRM_DATA",
      payload: { fetchingDigiReferers: true },
    });
    try {
      const { data } = await axios({
        url: `${website}/wp-json/wc/v3/customers`,
        params: {
          _fields: "id,first_name,last_name,email,date_created",
          include: referIds.join(),
          // include: order_ids.join(","),
          // customer: id,
          // per_page: order_ids.length,
          // page: 1,
        },
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      if (isArrayWithValues(data)) {
        updatedDigiCustomers = updatedDigiCustomers.map((customer) => {
          let { meta_data, fetchedRefer } = customer;
          if (!fetchedRefer) {
            let referId = meta_data.find(
              (i) => i.key === "digiReferredFrom"
            )?.value;

            for (let refCustomer of data) {
              let { id } = refCustomer;
              if (referId && `${referId}` === `${id}`) {
                customer.digi_refer_obj = refCustomer;
                customer.fetchedRefer = true;
              }
            }
          }
          return customer;
        });
      }
      // if (isArrayWithValues(data))
      //   updatedDigiCustomers = updatedDigiCustomers.map((i) => {
      //     if (i.id == id)
      //       return { ...i, : data, ordersFetched: true };
      //     else return i;
      //   });
      dispatch({
        type: "SET_CRM_DATA",
        payload: { updatedDigiCustomers, digiReferFetched: true },
      });
    } catch (error) {}
    dispatch({
      type: "SET_CRM_DATA",
      payload: { fetchingDigiReferers: false },
    });
  };
};

export const updateCRMInviteInRedux = (obj) => {
  return (dispatch, getState) => {
    let state = getState();
    let crmInvites = state.crm.crmInvites;
    if (isArrayWithValues(crmInvites)) {
      crmInvites = crmInvites.map((i) =>
        `${i.uid}` === `${obj.uid}` ? obj : i
      );
    } else crmInvites = [obj];
    dispatch({
      type: SET_CRM_DATA,
      payload: {
        crmInvites: crmInvites,
      },
    });
  };
};

export const sendCRMInvite = ({ payload, setUpdating }) => {
  return async () => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      setUpdating && setUpdating(true);
      try {
        await axios({
          url: `${website}/wp-json/store/v1/invite`,
          headers: { Authorization: `Basic ${token}` },
          method: "POST",
          data: { data: payload },
        });
      } catch (error) {
        console.log(getAxiosError(error), "<<<< send invite error");
      }
      setUpdating && setUpdating(false);
    }
  };
};
export const updateCRMInvite = ({ obj, setUpdating, url, onSuccess }) => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      try {
        setUpdating && setUpdating(true);
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/crm_invites`,
          headers: { Authorization: `Basic ${token}` },
          method: "PUT",
          data: {
            type: "crm_invites",
            data: { [obj.uid]: obj },
          },
        });
        if (data.success)
          dispatch({
            type: SET_CRM_DATA,
            payload: {
              crmInvites: Object.values(data.data),
            },
          });
        if (url)
          try {
            let { data: retailersInvites } = await axios({
              url: `${url}/wp-json/store/v1/settings/crm_invites`,
              headers: { apikey },
              // method: "PUT",
              // data: {
              //   type: "crm_invites",
              //   data: retailerObj,
              // },
            });
            retailersInvites = retailersInvites?.data || {};
            let objToUpdateInRetailer = {};
            if (retailersInvites[obj.uid]) {
              let updateKeys = [
                "access",
                "product_groups",
                "all_product_access",
                "status",
              ];
              let tempObj = retailersInvites[obj.uid];
              let temp = {};
              for (let key in tempObj) {
                if (updateKeys.includes(key)) continue;
                temp[key] = tempObj[key];
              }
              objToUpdateInRetailer = { ...obj, ...temp };
            } else {
              objToUpdateInRetailer = { ...obj, status: "requested" };
              delete objToUpdateInRetailer.store_id;
            }
            // console.log(
            //   objToUpdateInRetailer,
            //   retailersInvites[obj.uid],
            //   "<<< object to update"
            // );
            // console.log(data, "<<<< retailer res");
            let { data: retailerUpdateRes } = await axios({
              url: `${url}/wp-json/store/v1/settings/crm_invites`,
              headers: { apikey },
              method: "PUT",
              data: {
                type: "crm_invites",
                data: { [objToUpdateInRetailer.uid]: objToUpdateInRetailer },
              },
            });
            console.log(retailerUpdateRes, "<<<<");
          } catch (error) {}
        onSuccess && onSuccess();
      } catch (error) {}
      setUpdating && setUpdating(false);
    }
  };
};

export const updateCRMStatus = ({ obj, setUpdating, status }) => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      try {
        setUpdating && setUpdating(true);
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/crm_invites`,
          headers: { Authorization: `Basic ${token}` },
          method: "PUT",
          data: {
            type: "crm_invites",
            data: { [obj.uid]: { ...obj, status } },
          },
        });
        try {
          let { data: supplierInvites } = await axios({
            url: `${obj.url}/wp-json/store/v1/settings/crm_invites`,
            headers: { apikey },
          });
          supplierInvites = supplierInvites?.data;
          if (supplierInvites?.[obj.uid]) {
            let { data: supplierInvitesUpdateRes } = await axios({
              url: `${obj.url}/wp-json/store/v1/settings/crm_invites`,
              headers: { apikey },
              method: "PUT",
              data: {
                type: "crm_invites",
                data: { [obj.uid]: { ...obj, status } },
              },
            });
            console.log(
              supplierInvitesUpdateRes,
              // supplierInvitesUpdateRes,
              obj,
              "<<<<< supplier invites res"
            );
          }
        } catch (error) {}

        dispatch({
          type: SUPPLIER_EDIT,
          payload: {
            allSuppliers: Object.values(data?.data),
          },
        });
      } catch (error) {}
      setUpdating && setUpdating(false);
    }
  };
};

// export const

export const getCustomerAnalytics = (id, setState, setLoading) => {
  return async (dispatch, getState) => {
    let state = getState();
    let allOnlineVisitors = state.visitor.allOnlineVisitors;
    dispatch({
      type: SET_CRM_DATA,
      payload: { loadingCrmAnalytics: true },
    });
    // console.log(allOnlineVisitors);
    setLoading && setLoading(true);
    let analyticsObj = {
      catalog: {
        catalogOpen: 0,
        productView: 0,
      },
      views: 0,
      totalTime: 0,
      totalViewTime: 0,
      productViewsId: [],
      sessions: {
        total: 0,
        website: 0,
        mobile: 0,
        sales_app: 0,
      },
      monthlySession: {},
      websiteArrayGraph: {},
      events: [],
      catalogTimeSpent: {},
    };
    let graphDataObj = {};
    try {
      let CustomerAnalytics = allOnlineVisitors[`user_${id}`];
      if (!isArrayWithValues(CustomerAnalytics)) {
        CustomerAnalytics = (await getVisitorsInfo(id)) || [];
        analyticsObj.events = CustomerAnalytics;
      }

      // let websiteArrayGraph = [];
      for (let index = 0; index < CustomerAnalytics.length; index++) {
        const element = CustomerAnalytics[index];
        let os = new UAParser(element.ua).getOS();
        let device = new UAParser(element.ua).getDevice();
        // analyticsObj.sessions.total = mainSocketEvents.includes(element.type)
        //   ? analyticsObj.sessions.total + 1
        //   : analyticsObj.sessions.total;

        if (mainSocketEvents.includes(element.type)) {
          analyticsObj.sessions.total = analyticsObj.sessions.total + 1;

          analyticsObj.sessions.sales_app =
            element.type === "sales_app"
              ? analyticsObj.sessions.sales_app + 1
              : analyticsObj.sessions.sales_app;
          analyticsObj.sessions.website =
            os.name === "Mac OS" || os.name === "Windows"
              ? analyticsObj.sessions.website + 1
              : analyticsObj.sessions.website;
          analyticsObj.sessions.mobile =
            device.type === "mobile"
              ? analyticsObj.sessions.mobile + 1
              : analyticsObj.sessions.mobile;
        }

        // mainSocketEvents.includes(element.type) && websiteArrayGraph.push([new Date(element.id).getTime(), catalogSentTimes[date]]);

        let key = formatDate(
          new Date(validateNumber(element.time) * 1000),
          "relative_date"
        );
        if (element?.type === "catalog") {
          analyticsObj.catalog.catalogOpen += 1;
          if (element?.time && element?.end_time) {
            if (!analyticsObj.catalogTimeSpent[element?.event_id])
              analyticsObj.catalogTimeSpent[element?.event_id] = 0;
            analyticsObj.catalogTimeSpent[element?.event_id] +=
              validateNumber(element?.end_time) - validateNumber(element?.time);
          }
        }
        if (element?.type === "catalog_product")
          analyticsObj.catalog.productView += 1;
        if (mainSocketEvents.includes(element.type)) {
          if (
            validateNumber(element?.end_time) &&
            validateNumber(element?.time)
          ) {
            let timespent = Number(element?.end_time) - Number(element?.time);
            if (timespent > 0) analyticsObj.totalTime += timespent;
          }
          if (!graphDataObj[element.type]) graphDataObj[element.type] = {};
          let oldDate = graphDataObj[element.type][key] || 0;
          graphDataObj[element.type] = {
            ...graphDataObj[element.type],
            [key]: oldDate + 1,
          };
        }
        if (productViewsEvent.includes(element.type)) {
          if (
            validateNumber(element?.end_time) &&
            validateNumber(element?.time)
          ) {
            let timespent = Number(element?.end_time) - Number(element?.time);
            if (timespent > 0) analyticsObj.totalViewTime += timespent;
          }
        }
        if (
          // analyticsObj.productViewsId.length < 7 &&
          productViewsEvent.includes(element.type)
        ) {
          analyticsObj.productViewsId.push(element?.product_id);
          analyticsObj.views = analyticsObj.views + 1;
        }
      }
      let currentTime =
        Math.floor(new Date().getTime() / 1000) - analyticsObj.totalTime;
      let timeObj = getRelativeTimeDifference(
        new Date(currentTime * 1000),
        new Date()
      );
      let timeStr = "";
      if (timeObj) timeStr = `${timeObj.value} ${timeObj.label}`;
      analyticsObj.totalTime = timeStr;
      ///
      // let currentViewTime =
      //   Math.floor(new Date().getTime() / 1000) - analyticsObj.totalViewTime;
      let viewTimeObj = getRelativeTimeDifference(
        new Date(currentTime * 1000),
        new Date()
      );
      let viewTimeStr = "";
      if (viewTimeObj)
        viewTimeStr = `${viewTimeObj.value} ${viewTimeObj.label}`;
      analyticsObj.totalViewTime = viewTimeStr;
      // analyticsObj.visitorInfo = CustomerAnalytics
      for (let index = 0; index < Object.keys(graphDataObj).length; index++) {
        const mainKey = Object.keys(graphDataObj)[index];
        console.log(graphDataObj, mainKey);
        analyticsObj["websiteArrayGraph"][mainKey] = Object.keys(
          graphDataObj[mainKey] || {}
        ).map((key) => [new Date(key).getTime(), graphDataObj[mainKey][key]]);
      }
      analyticsObj.recentActivity = CustomerAnalytics.slice(1).slice(-5);
      setState && setState(analyticsObj);
      let crmAnalytics = getState().customers.crmAnalytics || {};

      dispatch({
        type: SET_CRM_DATA,
        payload: {
          crmAnalytics: {
            ...crmAnalytics,
            [id]: analyticsObj,
          },
          loadingCrmAnalytics: false,
        },
      });
    } catch (error) {
      console.log(error);
    }
    setLoading && setLoading(false);
  };
};

export const setAanalyticsProduct = (id, obj = {}) => {
  return async (dispatch, getState) => {
    let state = getState();
    let crmAnalytics = state.customers.crmAnalytics || {};
    let analyticsObj = crmAnalytics?.[id] || {};
    // console.log(crmAnalytics?.[id]?.productViewsId?.join(","));
    const setLoadingStatefun = (val) => {
      dispatch({
        type: SET_CRM_DATA,
        payload: {
          fetchingCrmProducts: val,
        },
      });
    };
    const setStateFun = (data) => {
      dispatch({
        type: SET_CRM_DATA,
        payload: {
          crmAnalytics: {
            ...crmAnalytics,
            [id]: { ...analyticsObj, productViewslist: data },
          },
        },
      });
      // dispatch(setProductsInAanalytics(id, obj))
    };
    if (analyticsObj?.productViewsId && !analyticsObj?.productViewslist) {
      let include = analyticsObj?.productViewsId?.join(",") || "";
      if (include) {
        fetchProducts(
          { include: include, per_page: 100 },
          setStateFun,
          setLoadingStatefun
        );
      }
    } else setStateFun([]);
  };
};

export const sendTemplateinReedux = (e, customerInfo) => {
  return async (dispatch, getState) => {
    let customer = { ...customerInfo };
    let payload = {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      // to: customer.number,
      type: "template",
      template: e,
    };
    console.log(`1`);
    let variableObj = getTemplateVariableObjFromCustomer(customer);
    let { meta_data, billing } = customer;
    let obj = {
      phone: billing ? billing.phone : "",
    };
    if (isArrayWithValues(meta_data))
      meta_data?.forEach((i) => {
        if (
          ["whatsapp", "dialcode_whatsapp", "dialcode_mobile"].includes(
            i.key
          ) &&
          i.value
        )
          obj[i.key] = i.value;
      });
    if (obj.whatsapp && obj.dialcode_whatsapp) {
      payload.to = `${obj.dialcode_whatsapp}${obj.whatsapp}`
        .replace(/\s/g, "")
        .trim();
    } else if (obj.mobile && obj.dialcode_mobile) {
      payload.to = `${obj.dialcode_mobile}${obj.mobile}`
        .replace(/\s/g, "")
        .trim();
    } else if (obj.phone && obj.dialcode_mobile) {
      payload.to = `${obj.dialcode_mobile}${obj.phone}`
        .replace(/\s/g, "")
        .trim();
    } else;

    if (isArrayWithValues(payload?.template?.components)) {
      let components = [];
      for (let component of payload?.template.components) {
        if (isArrayWithValues(component.parameters)) {
          let parameters = [];
          for (let parameter of component.parameters) {
            let { text } = parameter || {};
            let variableText = text
              ? text?.includes("{") && text?.includes("}")
                ? variableObj?.[text] || " "
                : text
              : text;
            if (!variableText) variableText = " ";
            parameters.push({ ...parameter, text: variableText });
          }
          components.push({ ...component, parameters });
        }
      }
      if (isArrayWithValues(components))
        payload = {
          ...payload,
          template: { ...payload.template, components },
        };
      payload.name = `${
        customer.first_name || customer?.billing?.first_name || ""
      } ${customer?.last_name || customer?.billing?.last_name || ""}`;
      let res = await sendSingleTemplate(payload);
      console.log(res);
      if (res?.success) {
        // dispatch(addResponseInChat(res.data.data, customer.id));
        dispatch(
          setGlobalToast({
            show: true,
            message: "Message Sent Successfully",
            severity: "success",
          })
        );
      }
    }
  };
};
export const getSegmentList = (arg) => {
  let { loading } = arg || {};
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      loading && loading(true);
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings/customer_segments`,
        headers: {
          Authorization: `Basic ${token}`,
        },
      });

      if (res?.data?.type === "success") {
        loading && loading(false);

        const sortedArray = Object.entries(res?.data?.data || {}).sort(
          (a, b) =>
            validateNumber(b?.id?.split("_")?.[0]) -
            validateNumber(a?.id?.split("_")?.[0])
        );

        const sortedObj = Object.fromEntries(sortedArray);

        dispatch({
          type: EDIT_CHATS,
          payload: {
            customerSegments: sortedObj,
            // res?.data?.data,
          },
        });
        if (sortedObj) {
          for (let key in sortedObj) {
            dispatch(setCountOnSegment(sortedObj?.[key]));
          }
        }
      }
    }
    loading && loading(false);
  };
};
export const addCustomerStoreUsed = () => {
  return (dispatch, getState) => {
    let state = getState();
    let storeUsed = state.user.store_used;
    dispatch({
      type: "SET_USER",
      payload: {
        storeUsed: {
          ...storeUsed,
          users: validateNumber(storeUsed?.users) + 1,
        },
      },
    });
  };
};

export const setCartProducts = (id, productArray, key) => {
  return async (dispatch, getState) => {
    const setLoadingStatefun = (val) => {
      dispatch({
        type: SET_CRM_DATA,
        payload: {
          fetchingCrmProducts: val,
        },
      });
    };
    const setStateFun = (data) => {
      let state = getState();
      let crmAnalytics = state.customers.crmAnalytics || {};
      let analyticsObj = crmAnalytics?.[id] || {};
      dispatch({
        type: SET_CRM_DATA,
        payload: {
          crmAnalytics: {
            ...crmAnalytics,
            [id]: { ...analyticsObj, [key]: data },
          },
        },
      });
    };
    try {
      if (isArrayWithValues(productArray)) {
        fetchProducts(
          { include: productArray?.join(","), per_page: 100 },
          (products) => {
            if (isArrayWithValues(products)) setStateFun(products);
            else {
              fetchProducts(
                {
                  include: productArray?.join(","),
                  per_page: 100,
                  "custom_filter[0][key]": "_visibility",
                  "custom_filter[0][value]": "hidden",
                },
                setStateFun,
                setLoadingStatefun
              );
            }
          },
          setLoadingStatefun
        );
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
};

export const setProductsInAanalytics = (id, obj) => {
  return async (dispatch, getState) => {
    let state = getState();
    let crmAnalytics = state.customers.crmAnalytics || {};
    console.log(crmAnalytics);
    const setLoadingStatefun = (val) => {
      dispatch({
        type: SET_CRM_DATA,
        payload: {
          fetchingCrmProducts: val,
        },
      });
    };
    const setStateFun = (data) => {
      let newProductObj = {
        "Wislist Products": [],
        "Cart Products": [],
      };
      console.log(data);
      for (let index = 0; index < data.length; index++) {
        let Productobj = data[index];
        for (const obj of Productobj?.meta_data) {
          Productobj[obj?.key] = obj?.value;
        }
        for (let k = 0; k < Object.keys(obj).length; k++) {
          const objKey = Object.keys(obj)[k];
          if (obj[objKey]?.includes(Productobj?.id)) {
            // if (!isArrayWithValues(newProductObj[objKey]))
            // newProductObj[objKey] = [];
            newProductObj[objKey].push(Productobj);
          }
        }
      }

      let analyticsObj = crmAnalytics?.[id?.toString()] || {};
      dispatch({
        type: SET_CRM_DATA,
        payload: {
          crmAnalytics: {
            ...crmAnalytics,
            [id]: { ...analyticsObj, ...newProductObj },
          },
        },
      });
    };
    if (isArrayWithValues(Object.values(obj))) {
      console.log(Object.values(obj).flat().join(","));
      fetchProducts(
        { include: Object.values(obj).flat().join(","), per_page: 100 },
        setStateFun,
        setLoadingStatefun
      );
    }
  };
};

export const createMultipleCustomer = (data) => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      dispatch({
        type: SET_CRM_DATA,
        payload: {
          creatingMultipleCustomer: true,
        },
      });
      let res = await axios({
        url: `${website}/wp-json/wc/v3/customers/batch`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "POST",
        data: {
          create: data,
        },
      });
      console.debug(res);
    }
    dispatch({
      type: SET_CRM_DATA,
      payload: {
        creatingMultipleCustomer: false,
      },
    });
  };
};

export const deleteCampaign = ({ obj, setLoading, onSuccess }) => {
  return async (dispatch) => {
    let website = getWebsite();
    let token = getToken();

    if (website && token) {
      // setDeleteId(id);
      setLoading && setLoading(true);
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings/campaign_history`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "DELETE",
        data: {
          id: obj?.id,
        },
      });
      if (res?.data?.success) {
        onSuccess && onSuccess();
        // setDeleteId(false);
        setLoading && setLoading(false);
        dispatch(
          setGlobalToast({
            show: true,
            severity: "success",
            message: "Delete Campaign successfully!",
          })
        );
        dispatch({
          type: EDIT_CHATS,
          payload: {
            campaignHistory: res?.data?.data,
          },
        });
      }
    }
    // setDeleteId(false);
    setLoading && setLoading(false);
  };
};
