import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Popover,
  Box,
  TextField,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { preDefinedAttributesTextField } from "../../sidebar-bot/utils/preDefinedAttribuesTextField";
import { attributeStyles } from "../styles/attributeStyles";
import CustomField from "../../../../../../helper/utility/CustomField";

const styles = attributeStyles;

const containsText = (text, searchText) => {
  return text.toLowerCase().includes(searchText.toLowerCase());
};

const AttributesSelect = ({
  handleAttributesOptions,
  attributeAnchorEl,
  setAttributeAnchorEl,
  onChange,
  value,
  label,
  placeholder,
}) => {
  const open = Boolean(attributeAnchorEl?.openAnchor);
  const id = open ? "simple-popover" : undefined;
  const globalAttributes = useSelector(
    (state) => state?.flow?.globalAttributes
  );
  const all_attributes = useSelector((state) => state?.flow?.all_attributes);
  const [searchText, setSearchText] = useState("");

  const handleClose = () => {
    setAttributeAnchorEl(null);
  };

  const searchLabels = [
    "Pre-defined Attributes",
    "Global Attributes",
    "Custom Field Attributes",
    "No options found",
  ];

  const displayedOptions = useMemo(() => {
    const filteredGlobal = all_attributes?.filter((option) =>
      containsText(option?.label, searchText)
    );

    const options = [];

    if (filteredGlobal.length > 0) {
      options.push(...filteredGlobal);
    }

    if (options.length === 0) {
      options.push("No options found");
    }

    return options;
    // return options?.map((i) => ({ label: i, value: `{{${i}}}` }));
  }, [searchText, globalAttributes]);

  // console.log(displayedOptions, "<<<<< displayedOptions");

  return (
    <CustomField
      type="autocomplete"
      //   options={displayedOptions?.map((i) => ({ label: i, value: i }))}
      options={displayedOptions}
      onChange={onChange}
      multiple={false}
      label={label}
      // placeholder={placeholder}
      value={value}
      textFieldProps={{
        placeholder,
      }}
      autocompleteProps={{
        freeSolo: true,
        inputValue: value || "",
        onInputChange: (event, newInputValue) => {
          onChange({ value: newInputValue });
        },
        // inputValueChange: onChange,
      }}
    />
  );

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={attributeAnchorEl?.openAnchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Box sx={styles.outerBox}>
        <Box
          sx={{
            borderBottom: "1px solid #dcdcdc",
            padding: "5px",
          }}
        >
          <TextField
            size="small"
            autoFocus
            variant="standard"
            placeholder="Type to search..."
            fullWidth
            inputProps={{
              style: {
                fontFamily: "Inter",
              },
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment
                  sx={{
                    position: "relative",
                    left: "4px",
                    bottom: "2px",
                    color: "#366aed",
                  }}
                  position="start"
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
        </Box>
        {displayedOptions?.map((option, i) => (
          <MenuItem
            key={i}
            sx={{
              fontFamily: "Inter",
              fontSize: searchLabels.includes(option) ? "16px" : "15px",
              fontWeight: searchLabels.includes(option) ? "600px" : "400px",
              backgroundColor: searchLabels.includes(option)
                ? "#fff"
                : "inherit",
              color: searchLabels.includes(option) ? "#000" : "inherit",
              margin: searchLabels.includes(option) ? "10px 0px 0px 0px" : "0",
              padding: searchLabels.includes(option)
                ? "5px 7px"
                : "5px 15px 5px 15px",
              cursor: searchLabels.includes(option) ? "default" : "pointer",
              "&:hover": {
                backgroundColor: !searchLabels.includes(option)
                  ? "#f0f0f0"
                  : "#fff",
              },
            }}
            onClick={() => {
              if (!searchLabels.includes(option)) {
                handleAttributesOptions(attributeAnchorEl.id, option);
              }
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Box>
    </Popover>
  );
};

export default AttributesSelect;
