import { useState, useEffect, useMemo, useRef } from "react";
import {
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import SmsIcon from "@mui/icons-material/Sms";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { smsStyles } from "./styles/smsStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import { fetchSmsTemplates } from "./helper/sms-tempaltes";
import CircularProgress from "@mui/material/CircularProgress";
import TemplatePreview from "./components/TemplatePreview";
import { checkTitleExists } from "../helper/checkTitleExists";
import SidebarTopBar from "../components/SidebarTopBar";
import { setConnectedSMSchannels } from "../../../../../redux/actions/marketingActions";
import HorizontalStack from "../../../../../helper/utility/HorizontalStack";
import { formatServerValue } from "../../../../../helper";
import { isArrayWithValues } from "jwero-javascript-utils";

const styles = smsStyles;

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
function replaceMissingAttributes(all_attributes, current) {
  // Regular expression to find placeholders in the form {{anything_here}}
  const regex = /{{(.*?)}}/g;
  let output = current;

  // Find all matches in the current string
  let matches;
  while ((matches = regex.exec(current)) !== null) {
    // Extract the placeholder name without the curly braces
    const attributeName = matches[1];

    // Check if the attribute name is not in the all_attributes array
    if (
      !all_attributes.find(
        (i) => i?.label === attributeName || i?.value === attributeName
      )
    ) {
      // Replace the whole placeholder (including curly braces) with __ __ in the output
      output = output.replace(matches[0], `__${attributeName}__`);
    }
  }

  return output;
}
const SidebarSms = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const all_attributes = useSelector((state) => state.flow?.all_attributes);
  const connected_sms_services = useSelector(
    (state) => state.settings?.connected_sms_services
  );
  const msg91Integration = useSelector(
    (state) => state?.settings?.msg91Integration
  );
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [fetchingTemplates, setFetchingTemplates] = useState(false);
  const [gupshupTemplates, setGupshupTemplates] = useState({});
  const [currentSelectedTemplate, setCurrentSelectedTemplate] = useState(null);
  const [currentTemplateTitle, setCurrentTemplateTitle] = useState("");
  const [titleCheckFlag, setTitleCheckFlag] = useState(false);
  const [attributeField, setAttributeField] = useState(null);
  const [smsText, setSmsText] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [attributeError, setAttributeError] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const anchorAttributeRef = useRef();
  const [selectAttributeAnchor, setSelectAttributeAnchor] = useState(null);
  const [SMSService, setSMSService] = useState(false);
  const titleErrorMessage =
    title === "" ? "Title is required" : "Title already exists!";

  const gupshupIntegration = useSelector(
    (state) => state.settings.gupshupIntegration
  );

  // useEffect(() => {
  //   handleSelectedOption(displayedOptions?.[0]);
  // }, [SMSService]);

  useEffect(() => {
    if (isArrayWithValues(gupshupIntegration?.sms?.dlt_templates_list)) {
      let templates = gupshupIntegration?.sms?.dlt_templates_list;

      setGupshupTemplates(
        templates?.map((i) => ({
          flow_name: i?.template_name,
          template_data: {
            data: [
              {
                ...i,
                active_status: "1",
              },
            ],
          },
        }))
      );
    }
  }, [gupshupIntegration]);

  useEffect(() => {
    setSMSService(connected_sms_services?.[0]?.name);
  }, [connected_sms_services]);

  useEffect(() => {
    dispatch(setConnectedSMSchannels());
  }, [msg91Integration, gupshupIntegration]);

  const handleOpenattributeField = (e) => {
    setSelectAttributeAnchor(anchorAttributeRef.current);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const checkAttributesValid = () =>
    attributeField?.every((attr) => attr.value !== "");

  const closeSidebar = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { openSmsSidebar: false },
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCheck = (e) => {
    e.preventDefault();

    let variableSMSText = replaceMissingAttributes(
      all_attributes || [],
      smsText?.current || ""
    );

    if (title == "" || titleCheckFlag) {
      setTitleCheckFlag(true);
      setTitleError(true);
    }

    if (!currentSelectedTemplate) {
      setTemplateError(true);
    }

    if (!checkAttributesValid()) {
      handleOpenattributeField();
      setAttributeError(true);
    }

    if (
      title &&
      !titleCheckFlag &&
      currentTemplateTitle &&
      checkAttributesValid()
    ) {
      const submittedUserData = {
        title,
        template: {
          service: SMSService || "",
          template_title: currentTemplateTitle,
          template_details: currentSelectedTemplate,
          template_text: { ...smsText, current: variableSMSText },
          template_attribues: attributeField,
        },
      };
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openSmsSidebar: false },
      });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  const setTemplatesInitially = async ({ isSelected }) => {
    setFetchingTemplates(true);
    const templates = await fetchSmsTemplates();
    setTemplates(templates);
    setFetchingTemplates(false);
    return true;
  };

  const handleSelectedOption = (currTemp) => {
    setTemplateError(false);
    setCurrentTemplateTitle(currTemp?.flow_name);
    setCurrentSelectedTemplate(currTemp);
  };

  const handleTitle = (e) => {
    const value = e.target.value;
    setTitle(e.target.value);
    if (checkTitleExists({ nodes, title: value })) {
      setTitleCheckFlag(true);
      return;
    }
    setTitleCheckFlag(false);
  };

  const displayedOptions = useMemo(() => {
    let _templates =
      SMSService === "msg91"
        ? templates
        : SMSService === "gupshup"
        ? gupshupTemplates
        : [];
    if (searchText === "") {
      return _templates;
    } else {
      return _templates?.filter((option) =>
        containsText(option?.flow_name, searchText)
      );
    }
  }, [searchText, templates, gupshupTemplates, SMSService]);

  useEffect(() => {
    const currentNode = nodes?.find((it) => it.id === userId);

    if (currentNode) {
      const node = currentNode?.fieldData;
      if (node?.title) {
        setTitle(node?.title);
      }
      if (node?.template) {
        setCurrentSelectedTemplate(node?.template?.template_details);
        setCurrentTemplateTitle(node?.template?.template_title);
        setSmsText(node?.template?.template_text);
        setAttributeField(node?.template?.template_attribues);
        setTemplatesInitially({ isSelected: true });
      } else {
        setTemplatesInitially({ isSelected: false });
      }
    }
  }, [userId]);

  console.log(displayedOptions, gupshupIntegration, "<<<< displayedOptions");

  return (
    <Box sx={styles.customCard}>
      <form>
        <SidebarTopBar
          onTitleChange={handleTitle}
          titleError={titleErrorMessage}
          title={title}
          showTitleError={titleCheckFlag}
          handleClose={handleClose}
          handleCheck={handleCheck}
          type="sms"
          topBarTitle={"SMS"}
          titleLabel="Trigger title"
        />
        {/* <Box sx={styles.topCard}>
          <Box sx={styles.innerCard}>
            <Box sx={styles.userHeader}>
              <SmsIcon />
              <Typography variant="span" style={styles.headerTitle}>
                Sms
              </Typography>
            </Box>
            <Box sx={styles.headerIconsMainDiv}>
              <IconButton sx={styles.headerIconsDiv} onClick={handleClose}>
                <CloseIcon sx={styles.headerIcons} />
              </IconButton>
              <IconButton sx={styles.headerIconsDiv} onClick={handleCheck}>
                <CheckIcon sx={styles.headerIcons} />
              </IconButton>
            </Box>
          </Box>

          <TextField
            placeholder="Type block title"
            size="small"
            style={styles.titleTextField}
            onChange={handleTitle}
            error={titleError}
            value={title}
            required
          />
          {titleCheckFlag && (
            <Typography
              sx={{
                fontSize: "14px",
                color: "#E63946",
                fontWeight: 600,
                fontFamily: "Inter",
                position: "relative",
                bottom: "10px",
                left: "3px",
              }}
            >
              {titleErrorMessage}
            </Typography>
          )}
        </Box> */}
        <Box sx={styles.responseSection}>
          <Box sx={styles.responseDiv}>
            <HorizontalStack sx={{ justifyContent: "space-between", my: 2 }}>
              <Typography
                variant="span"
                style={{ ...styles.labels, marginBottom: 0 }}
              >
                Select SMS template
              </Typography>
              {/* <InputLabel id="demo-simple-select-label">
                  sms_service
                </InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={SMSService}
                // label="sms_service"
                onChange={(e) => setSMSService(e.target.value)}
                size="small"
                sx={{ backgroundColor: "#fff" }}
              >
                {connected_sms_services?.map((i) => {
                  return (
                    <MenuItem value={i?.name}>
                      {formatServerValue(i?.name)}
                    </MenuItem>
                  );
                })}
              </Select>
            </HorizontalStack>
            {fetchingTemplates ? (
              <CircularProgress />
            ) : (
              <Box sx={{ marginBottom: "20px" }}>
                <FormControl fullWidth>
                  <Select
                    sx={{ backgroundColor: "#fff" }}
                    size="small"
                    MenuProps={{
                      autoFocus: false,
                    }}
                    id="search-select"
                    value={currentTemplateTitle || ""}
                    onChange={(e) => handleSelectedOption(e.target.value)}
                    onClose={() => setSearchText("")}
                    renderValue={() => currentTemplateTitle}
                  >
                    <Box
                      sx={{
                        borderBottom: "1px solid #dcdcdc",
                        padding: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <TextField
                        size="small"
                        autoFocus
                        variant="standard"
                        placeholder="Type to search..."
                        fullWidth
                        inputProps={{
                          style: {
                            fontFamily: "Inter",
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{
                                position: "relative",
                                left: "4px",
                                bottom: "2px",
                                color: "#366aed",
                              }}
                            >
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </Box>
                    {displayedOptions?.map((option, i) => (
                      <MenuItem key={i} value={option}>
                        {option?.flow_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            {templateError && (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#E63946",
                  fontWeight: 600,
                  fontFamily: "Inter",
                  position: "relative",
                  bottom: "10px",
                  left: "3px",
                }}
              >
                Please select template.
              </Typography>
            )}
          </Box>
          {currentTemplateTitle !== "" && (
            <TemplatePreview
              currentSelectedTemplate={currentSelectedTemplate}
              smsText={smsText}
              setSmsText={setSmsText}
              initialLoad={initialLoad}
              setInitialLoad={setInitialLoad}
              attributeField={attributeField}
              setAttributeField={setAttributeField}
              setAttributeError={setAttributeError}
              selectAttributeAnchor={selectAttributeAnchor}
              setSelectAttributeAnchor={setSelectAttributeAnchor}
              handleOpenattributeField={handleOpenattributeField}
              anchorAttributeRef={anchorAttributeRef}
            />
          )}
        </Box>

        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </form>
    </Box>
  );
};

export default SidebarSms;
