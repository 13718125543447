import { unformatServerValue } from "../../../helper";
import { unformatChatUser } from "../../../utils/chat_user";
import { allSizeGuides } from "../../settings_new/product/SizeMasters";

function validateSheetHeaders({ original = [], sheetHeaders = [] }) {
  // if (original?.length !== sheetHeaders?.length) return false;
  // else {
  // }
  // console.log(original, sheetHeaders, "<<<<<<<<<<<<<<, Best");
  for (const key of original) {
    // console.log(
    //   // sheetHeaders,
    //   // original,
    //   sheetHeaders?.find(
    //     (i) => unformatServerValue(i) === unformatServerValue(key)
    //   ),
    //   unformatServerValue(key)
    // );
    if (
      !sheetHeaders?.find((i) => {
        return unformatServerValue(i) === unformatServerValue(key) || i === key;
      })
    ) {
      return false;
    }
    // if (!sheetHeaders?.includes(key)) return false;
  }
  return true;
}

let originalProductsHeader = [
  "SKU",
  "Barcode",
  "Slug",
  "Title",
  "Status",
  "Product Description",
  "Stock Status",
  "Quantity",
  "Collections",
  "Category",
  "Sub Category",
  "Settings/Size",
  "Featured",
  "Backorders allowed",
  "Sold Individually",
  "HUID",
  "Min shipping days",
  "Max shipping days",
  "Metal Type",
  "Metal Color",
  "Gold Purity",
  "Gold Gross",
  "Gold Net",
  "Silver Purity",
  "Silver Gross",
  "Silver Net",
  "Platinum Purity",
  "Platinum Gross",
  "Platinum Net",
  "Diamond From",
  "Diamond Weight",
  "Diamond Pieces",
  "Diamond Type",
  "Diamond Quality",
  "Diamond Shapes",
  "Diamond Cut",
  "Custom Diamond Rate",
  "Diamond Certified",
  "Diamond Lab",
  "Diamond Certificate ID",
  "Gemstone From",
  "Gemstone Quality",
  "Gemstone Shape",
  "Gemstone Type",
  "Gemstone Size",
  "Gemstone Pieces",
  "Gemstone Weight",
  "Gemstone Color",
  //
  "No. of Lines",
  "Length",
  "Closure Type",
  "Pearl Type",
  "Pearl Setting Type",
  "Pearl Size",
  "Pearl Pieces",
  "Pearl Weight",
  //
  "Custom Gemstone Rate",
  "Labour Type",
  "Labour From",
  "Per Gram",
  "Wastage From",
  "Wastage in (%)",
  "Minimum Making",
  "Extra Charge Type",
  "Extra Charge Value",
  "SEO Title",
  "SEO Description",
  "SEO Keywords",
  "Width (in mm)",
  "Height (in mm)",
  "Thickness (in mm)",
  "Tags",
  "Gender",
  "Ocassions",
  "Manual Product Price (MRP)",
  "Upsell",
  "Cross Sell",
  "Video URL",
  "Images",
  ...allSizeGuides.map((i) => i?.label),
];
const originalCustomerHeader = [
  "First Name",
  "Last Name",
  "Email",
  "Contact Type",
  "Dial Code 1",
  "Phone",
  "Dial Code 2",
  "Whatsapp",
  "Tags",
  "Address Line 1",
  "Address Line 2",
  "City",
  "Postcode",
  "State",
  "Country",
  "Gender",
  "Branch",
  "First Purchase",
  "Total Purchase",
  "Visits In Showroom",
  "Last Visit In Showroom",
  "Birthday",
  "Anniversary",
  "Profession",
  "Annual Income",
  "Ethnicity",
  "Language",
  "Religion",
  "Facebook",
  "Instagram",
  "Twitter",
  "Linkedin",
  "YouTube",
  "Pinterest",
  "Company Name",
  "Company Code",
];

const originalChatsHeaders = [
  "First Name",
  "Last Name",
  "Email",
  "Contact Type",
  "Broadcast",
  "Tags",
  "Dial code whatsapp",
  "Whatsapp",
];

export {
  validateSheetHeaders,
  originalProductsHeader,
  originalCustomerHeader,
  originalChatsHeaders,
};
