import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomField from "../../../../../../helper/utility/CustomField";
import { ADD_REWARD_OPTIONS } from "../../../../constants";
import {
  getRandomString,
  isArray,
  isArrayWithValues,
  isObjWithValues,
  validateNumber,
} from "jwero-javascript-utils";
import {
  ErrorLabelExtraSmall,
  PrimaryLabel,
  PrimaryLabelLarge,
  PrimaryLabelSmall,
  SecondaryLabel,
  SecondaryLabelSmall,
} from "../../../../../../helper/utility/Labels";
import HorizontalStack from "../../../../../../helper/utility/HorizontalStack";
import {
  Add,
  ArrowBackIos,
  Delete,
  DeleteOutlineOutlined,
  EditOutlined,
} from "@mui/icons-material";
import PlanSwitchWarning from "./PlanSwitchWarning";
import Switch from "rc-switch";
import { currencySymbols } from "../../../../../pricing/Gold";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { validateCustomRewardForm } from "../../helper/validations";
import { generateAdvancedPenaltySettingsSentence } from "../../utils";

const CustomIntervalReward = forwardRef(
  (
    {
      form,
      editForm,
      currency,
      addRewardOpen: showAdd,
      setAddRewardOpen: setShowAdd,
      showHeader,
      showActions,
    },
    ref
  ) => {
    // const [showAdd, setShowAdd] = useState(false);
    const addRewardRef = useRef();
    useImperativeHandle(ref, () => {
      return {
        onAddPhase() {
          _onAddPhase();
        },
      };
    });

    useEffect(() => {
      if (!showAdd) addRewardRef?.current?.resetForm();
    }, [showAdd]);
    const _onAddPhase = () => {
      if (!addRewardRef?.current?.validateForm()) return;
      let phase = addRewardRef?.current?.getState();
      let phases = form?.reward_breakup || [];
      console.log(phases, "1");

      if (phase?.id)
        phases = phases?.map((i) => (i?.id === phase?.id ? phase : i));
      else {
        phase.id = getRandomString(6);
        phases?.push(phase);
      }
      console.log(phases, "2");
      setShowAdd(false);
      editForm({ reward_breakup: phases });
    };

    const onEditPhase = (obj, index) => {
      obj.title = `Interval ${index + 1}`;
      addRewardRef?.current?.setState(obj);
      setShowAdd(true);
    };
    const onDeletePhase = (phase) => {
      let phases = form?.reward_breakup || [];
      phases = phases?.filter((i) => i?.id !== phase?.id);
      editForm({ reward_breakup: phases });

      // addRewardRef?.current?.setState(obj);
      // setShowAdd(true);
    };
    return (
      <Box>
        <Box sx={{ display: showAdd ? "block" : "none" }}>
          <AddReward ref={addRewardRef} showAdd={showAdd} addForm={form} />
        </Box>
        <Box sx={{ display: !showAdd ? "block" : "none" }}>
          <_RewardsTable
            reward_breakup={form?.reward_breakup}
            setShowAdd={setShowAdd}
            editForm={editForm}
            showHeader={showHeader}
            showActions={showActions}
            onEditPhase={onEditPhase}
            onDeletePhase={onDeletePhase}
          />
        </Box>
      </Box>
    );
  }
);

export default CustomIntervalReward;

const initialState = {
  start_date: "",
  to: "",
  time_period_type: "days",
  reward_value: "",
  reward_type: "amount",
  reward_on: "paid_amount",
  gift: "",
  penalty_value: "",
  penalty_type: "percentage",
  penalty_on: "paid_amount",
  minimum_amount: "",
};

const AddReward = forwardRef(({ addForm, showAdd }, ref) => {
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState({});
  const [editDatePeriod, setEditDatePeriod] = useState(true);
  const editForm = (payload) => setForm((state) => ({ ...state, ...payload }));
  const default_currency = useSelector(
    (state) => state.settings.productSettings
  )?.default_currency;

  useEffect(() => {
    let editDatePeriod = true;

    if (addForm?.reward_breakup?.length === 1 && !form?.id)
      editDatePeriod = false;
    if (addForm?.reward_breakup?.length > 1) editDatePeriod = false;
    if (!editDatePeriod)
      editForm({
        time_period_type: addForm?.reward_breakup?.[0]?.time_period_type,
      });
    setEditDatePeriod(editDatePeriod);
  }, [addForm?.reward_breakup, showAdd, form?.id]);

  useImperativeHandle(ref, () => {
    return {
      getState() {
        return {
          ...form,
          currency: default_currency,
          title: `Interval ${
            validateNumber(addForm?.reward_breakup?.length) + 1
          }`,
        };
      },
      setState(obj) {
        editForm(obj);
      },
      resetForm() {
        setForm(initialState);
      },
      validateForm() {
        let errors = validateCustomRewardForm({
          mainForm: addForm,
          form,
          addForm,
        });
        setErrors(errors);
        return !isObjWithValues(errors);
      },
    };
  });

  const theme = useTheme();
  let mainBackgroundColor = theme.palette.background?.paper;
  const backgroundColor = theme.palette.primary.main;
  return (
    <Box sx={{ py: 4 }}>
      <PrimaryLabel>Add interval rewards & penalties</PrimaryLabel>
      <PrimaryLabelLarge sx={{ mt: 4 }}>
        {form?.title ||
          `Interval ${validateNumber(addForm?.reward_breakup?.length) + 1}`}
      </PrimaryLabelLarge>
      <PrimaryLabelSmall sx={{ mt: 4, mb: 2 }}>
        Interval duration
      </PrimaryLabelSmall>
      <Grid container spacing={2.5} rowSpacing={5}>
        <Grid item xs={12} sm={4}>
          <CustomField
            value={form?.from}
            onChange={(e) => editForm({ from: e })}
            label="Starts from"
            textFieldProps={{
              placeholder: "Enter days",
              helperText: errors?.from,
              error: Boolean(errors?.from),
              type: "number",
              onInput: (e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 3);
              },
            }}
            // type="text_dropdown"
            backgroundColor={mainBackgroundColor}
            // suffixProps={{
            //   value: form.completion_type,
            //   onChange: (e) => editForm({ completion_type: e }),
            //   options: [],
            // }}
            // tooltip={"Specify a name for the field"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomField
            value={form?.to}
            onChange={(e) => editForm({ to: e })}
            label="Ends on"
            textFieldProps={{
              placeholder: "Enter days",
              helperText: errors?.to,
              error: Boolean(errors?.to),
              type: "number",
              onInput: (e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 3);
              },
            }}
            type="text_dropdown"
            suffixProps={{
              value: form.time_period_type,
              onChange: (e) => editForm({ time_period_type: e }),
              otherProps: {
                disabled: !editDatePeriod,
              },
              options: [
                { label: "Days", value: "days" },
                { label: "Months", value: "months" },
              ],
            }}
            backgroundColor={mainBackgroundColor}
            // tooltip={"Specify a name for the field"}
          />
        </Grid>
      </Grid>
      {Boolean(errors?.overlapping) && (
        <ErrorLabelExtraSmall sx={{ my: 2 }}>
          {errors?.overlapping}
        </ErrorLabelExtraSmall>
      )}
      <PrimaryLabelSmall
        sx={{
          mt: 4,
          mb: 2,
        }}
      >
        Set reward
      </PrimaryLabelSmall>
      <Grid
        container
        spacing={2.5}
        rowSpacing={5}
        sx={{ alignItems: "flex-end" }}
      >
        <Grid item xs={12} sm={3}>
          <CustomField
            label={"Minimum amount (Optional)"}
            value={form?.minimum_amount}
            onChange={(e) => editForm({ minimum_amount: e })}
            backgroundColor={mainBackgroundColor}
            textFieldProps={{
              type: "number",
              onInput: (e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 5);
              },
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySymbols[default_currency]}
                  </InputAdornment>
                ),
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <CustomField
            value={form?.reward_type}
            onChange={(e) => editForm({ reward_type: e })}
            label="Reward Type"
            textFieldProps={
              {
                // placeholder: "Enter days",
                // helperText: errors?.reward_type,
                // error: Boolean(errors?.reward_type),
              }
            }
            type="dropdown"
            // suffixProps={{
            //   value: form.reward_type,
            //   onChange: (e) => editForm({ reward_type: e }),
            // }}
            options={[
              { label: "% Percent", value: "percentage" },
              { label: "Amount", value: "amount" },
            ]}
            backgroundColor={mainBackgroundColor}
            // tooltip={"Specify a name for the field"}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CustomField
            value={form?.reward_value}
            onChange={(e) => editForm({ reward_value: e?.slice(0, 7) })}
            label="Add reward"
            textFieldProps={{
              placeholder: "Enter value",
              helperText: errors?.title,
              error: Boolean(errors?.title),
              type: "number",
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    {form?.reward_type === "amount" &&
                      currencySymbols[default_currency]}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    {form?.reward_type === "percentage" && "%"}
                  </InputAdornment>
                ),
              },
              // onInput: (e) => {
              //   e.target.value = Math.max(0, parseInt(e.target.value))
              //     .toString()
              //     .slice(0, form?.reward_type === "percentage" ? 2 : 5);
              // },
            }}
            // type="text_dropdown"
            backgroundColor={mainBackgroundColor}
            // suffixProps={{
            //   value: form.completion_type,
            //   onChange: (e) => editForm({ completion_type: e }),
            //   options: [],
            // }}
            // tooltip={"Specify a name for the field"}
          />
        </Grid>
        {form?.reward_type === "percentage" && (
          <Grid item xs={12} sm={3}>
            <CustomField
              value={form?.reward_on}
              onChange={(e) => editForm({ reward_on: e })}
              type="dropdown"
              options={[
                { label: "On total plan value", value: "total_plan_amount" },
                {
                  label: "On accumulated amount",
                  value: "paid_amount",
                },
                // { label: "On per transection", value: "transection" },
              ]}
              backgroundColor={mainBackgroundColor}
              // tooltip={"Specify a name for the field"}
            />
          </Grid>
        )}
      </Grid>

      {/* <HorizontalStack
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: { sm: "100%", md: "50%" },
          mt: 4,
          mb: 2,
        }}
      >
        <PrimaryLabelSmall sx={{ mt: 4, mb: 2 }}>
          Minimum amount to enable rewards
        </PrimaryLabelSmall>
        <Switch
          onChange={(e) => {
            editForm({ enable_minimum_amount: e });
          }}
          checked={form?.enable_minimum_amount}
          style={{
            border: `1px solid ${
              form?.enable_minimum_amount ? backgroundColor : "#e8e8e8"
            }`,
            backgroundColor: form?.enable_minimum_amount
              ? backgroundColor
              : "#e8e8e8",
          }}
        />
      </HorizontalStack> */}

      {/* {Boolean(form?.enable_minimum_amount) && (
        <Grid container>
          <Grid item xs={6}>
            <CustomField
              label={"Minimum amount to enable rewards"}
              value={form?.minimum_amount}
              onChange={(e) => editForm({ minimum_amount: e })}
              backgroundColor={mainBackgroundColor}
              textFieldProps={{
                type: "number",
                onInput: (e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 5);
                },
              }}
            />
          </Grid>
        </Grid>
      )} */}

      <HorizontalStack
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: { sm: "100%", md: "50%" },
          mt: 4,
          mb: 2,
        }}
      >
        <PrimaryLabelSmall sx={{ mt: 4, mb: 2 }}>Set penalty</PrimaryLabelSmall>
        <Switch
          onChange={(e) => {
            editForm({ has_penalty: e });
          }}
          checked={form?.has_penalty}
          style={{
            border: `1px solid ${
              form?.has_penalty ? backgroundColor : "#e8e8e8"
            }`,
            backgroundColor: form?.has_penalty ? backgroundColor : "#e8e8e8",
          }}
        />
      </HorizontalStack>
      {Boolean(form?.has_penalty) && (
        <>
          <SecondaryLabel sx={{ mt: 2 }}>
            If no payment received in this phase
          </SecondaryLabel>

          <HorizontalStack spacing={2} style={{ flexWrap: "wrap" }}>
            <PrimaryLabelSmall>Then deduct</PrimaryLabelSmall>

            <CustomField
              value={form?.penalty_type}
              onChange={(e) => editForm({ penalty_type: e })}
              type="dropdown"
              options={[
                { label: "% Percent", value: "percentage" },
                { label: "Amount", value: "amount" },
              ]}
              textFieldProps={{ style: { minWidth: "200px" } }}
              backgroundColor={mainBackgroundColor}
            />

            <CustomField
              value={form?.penalty_value}
              onChange={(e) => editForm({ penalty_value: e?.slice(0, 7) })}
              backgroundColor={mainBackgroundColor}
              textFieldProps={{
                style: { minWidth: "200px" },
                type: "number",
                // onInput: (e) => {
                //   e.target.value = Math.max(0, parseInt(e.target.value))
                //     .toString()
                //     .slice(0, form?.penalty_type === "percentage" ? 2 : 5);
                // },
              }}
            />
            <PrimaryLabelSmall>On</PrimaryLabelSmall>
            <CustomField
              value={form?.penalty_on}
              onChange={(e) => editForm({ penalty_on: e })}
              type="dropdown"
              options={[
                { label: "Total plan amount", value: "plan_amount" },
                { label: "Total accumulated amount", value: "paid_amount" },
              ]}
              textFieldProps={{ style: { minWidth: "200px" } }}
              backgroundColor={mainBackgroundColor}
              // tooltip={"Specify a name for the field"}
            />
          </HorizontalStack>
        </>
      )}

      <HorizontalStack
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: { sm: "100%", md: "50%" },
          mt: 4,
          mb: 2,
        }}
      >
        <PrimaryLabelSmall sx={{ mt: 4, mb: 2 }}>
          Add extra gift
        </PrimaryLabelSmall>
        <Switch
          onChange={(e) => {
            editForm({ has_gifts: e });
          }}
          checked={form?.has_gifts}
          style={{
            border: `1px solid ${
              form?.has_gifts ? backgroundColor : "#e8e8e8"
            }`,
            backgroundColor: form?.has_gifts ? backgroundColor : "#e8e8e8",
          }}
        />
      </HorizontalStack>

      {Boolean(form?.has_gifts) && (
        <Grid container>
          <Grid item xs={6}>
            <CustomField
              label={"Gift"}
              value={form?.gift}
              onChange={(e) => editForm({ gift: e })}
              backgroundColor={mainBackgroundColor}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
});

const _RewardsTable = ({
  reward_breakup,
  setShowAdd,
  editForm,
  showHeader = true,
  showActions = true,
  onEditPhase,
  onDeletePhase,
}) => {
  const default_currency = useSelector(
    (state) => state.settings.productSettings
  )?.default_currency;
  const [rows, setRows] = useState([
    // {
    //   id: "23",
    //   from: "1",
    //   to: "20",
    //   time_period_type: "days",
    //   reward_value: "10",
    //   reward_type: "percentage",
    //   reward_on: "paid_amount",
    //   gift: "Bag",
    //   penalty_value: "10",
    //   penalty_type: "percentage",
    //   penalty_on: "paid_amount",
    //   minimum_amount: "10000",
    //   enable_minimum_amount: true,
    //   has_penalty: true,
    //   has_gifts: true,
    //   currency: "INR",
    // },
  ]);
  useEffect(() => {
    if (isArray(reward_breakup)) setRows(reward_breakup);
  }, [reward_breakup]);

  const onRewardTypeChange = () => {
    editForm({ reward_type: "fixed" });
  };

  return (
    <Box>
      {showHeader && (
        <HorizontalStack sx={{ justifyContent: "space-between" }}>
          <PrimaryLabel>Rewards</PrimaryLabel>
          <HorizontalStack spacing={2}>
            <PlanSwitchWarning
              onRewardTypeChange={onRewardTypeChange}
              editForm={editForm}
              currentType="custom"
            />
            <Button
              variant="outlined"
              startIcon={<Add />}
              size="small"
              onClick={() => setShowAdd(true)}
            >
              Add Interval
            </Button>
          </HorizontalStack>
        </HorizontalStack>
      )}
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Interval</TableCell>
              <TableCell>Starting</TableCell>
              <TableCell>Ending</TableCell>
              <TableCell align="right" sx={{ color: "success.main" }}>
                Reward
              </TableCell>
              <TableCell align="right">Gift</TableCell>
              <TableCell align="right">Minimum Amount</TableCell>

              <TableCell align="right" sx={{ color: "error.main" }}>
                Penalty
              </TableCell>
              {showActions && <TableCell align="right">Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  // Target all table cells in a row for the right border
                  "& td, & th": {
                    borderRight: 1,
                    borderColor: "#e8e8e8",
                  },
                  borderLeft: 1,
                  borderColor: "#e8e8e8",
                  // "&:last-child td, &:last-child th": {
                  //   // border: 0,
                  //   borderRight: 1,
                  //   borderColor: "#e8e8e8",
                  // },
                }}
              >
                <TableCell> {index + 1}</TableCell>
                <TableCell component="th" scope="row">
                  {row.from} {row?.time_period_type}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.to} {row?.time_period_type}
                </TableCell>
                <TableCell align="right">
                  {row.reward_value
                    ? `${
                        row?.reward_type === "amount"
                          ? currencySymbols[default_currency]
                          : ""
                      } ${row.reward_value} ${
                        row?.reward_type === "percentage" ? "%" : ""
                      }`?.trim()
                    : "--"}
                </TableCell>
                <TableCell align="right">{row.gift || "--"}</TableCell>
                <TableCell align="right">
                  {row.minimum_amount
                    ? `${currencySymbols[row?.currency || "INR"]} ${
                        row.minimum_amount
                      }`
                    : "--"}
                </TableCell>
                <TableCell align="right">
                  {row.penalty_value
                    ? `${
                        row?.penalty_type === "amount"
                          ? currencySymbols[default_currency]
                          : ""
                      } ${row.penalty_value} ${
                        row?.penalty_type === "percentage" ? "%" : ""
                      }`?.trim()
                    : "--"}
                  {/* {row.penalty_value
                    ? `${row.penalty_value} ${
                        row?.penalty_type === "percentage" && "%"
                      }`
                    : "--"} */}
                </TableCell>
                {showActions && (
                  <TableCell align="right">
                    <HorizontalStack spacing={2} justifyContent="flex-end">
                      <IconButton onClick={() => onEditPhase(row, index)}>
                        <EditOutlined />
                      </IconButton>
                      <DeleteReward onDelete={() => onDeletePhase(row)} />
                      {/* <IconButton>
                        <DeleteOutlineOutlined />
                      </IconButton> */}
                    </HorizontalStack>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

function DeleteReward({ onDelete: _onDelete, item, loading }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onDelete = (e) => {
    handleClose();
    _onDelete(e, item);
  };

  return (
    <div>
      <IconButton
        // loading={loading}
        size="small"
        variant="outlined"
        color="error"
        onClick={handleClick}
        // onClick={(e) => onDelete(e, item)}
      >
        <DeleteOutlineOutlined />
      </IconButton>
      {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
          Open Popover
        </Button> */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this reward?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          <LoadingButton
            // loading={loading}
            size="small"
            variant="outlined"
            color="error"
            startIcon={<Delete />}
            sx={{ minWidth: "81px" }}
            onClick={(e) => onDelete(e)}
          >
            Delete
          </LoadingButton>
          {/* <Button onClick={handleClose} autoFocus>
              Delete
            </Button> */}
        </DialogActions>
        {/* <Typography sx={{ p: 2 }}>Delete this template permanently?</Typography> */}
      </Popover>
    </div>
  );
}

export const AdvancePaneltySettings = ({ form, editForm }) => {
  const theme = useTheme();
  const default_currency = useSelector(
    (state) => state.settings.productSettings
  )?.default_currency;
  const backgroundColor = theme.palette.primary.main;
  let mainBackgroundColor = theme.palette.background?.paper;

  const PhasesOptions = useMemo(
    () =>
      form?.reward_breakup
        ?.map((i, ind) => ({
          label: `Interval ${ind + 1} (${i?.from} - ${i?.to} ${
            i?.time_period_type
          })`,
          value: i?.id,
        }))
        ?.filter(
          (i) =>
            !(form?.penalty_on_phases || [])?.find((j) => j?.value === i?.value)
        ),
    [form?.reward_breakup, form?.penalty_on_phases]
  );

  return (
    <Box
      sx={{
        px: 3,
      }}
    >
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        mt={6}
      >
        <Typography variant="h6" sx={{ fontSize: "14px" }}>
          Advanced penalty settings{" "}
          <SecondaryLabelSmall component="span">(Optional)</SecondaryLabelSmall>
        </Typography>
        <Switch
          onChange={(e) => {
            editForm({ advance_penalty: e });
          }}
          checked={form?.advance_penalty}
          style={{
            border: `1px solid ${
              form?.advance_penalty ? backgroundColor : "#e8e8e8"
            }`,
            backgroundColor: form?.advance_penalty
              ? backgroundColor
              : "#e8e8e8",
          }}
        />
      </Stack>

      <SecondaryLabelSmall sx={{ mt: 1 }}>
        Setup advanced penalties based on conditions
      </SecondaryLabelSmall>
      {Boolean(form?.advance_penalty) && (
        <>
          <HorizontalStack
            spacing={2}
            sx={{ flexWrap: "wrap", rowGap: "10px", mt: 1 }}
          >
            <PrimaryLabelSmall>If</PrimaryLabelSmall>

            <CustomField
              value={form?.advance_penalty_on}
              onChange={(e) => editForm({ advance_penalty_on: e })}
              type="dropdown"
              options={[
                { label: "Consecutively", value: "all" },
                { label: "Any one", value: "one" },
              ]}
              textFieldProps={{ style: { minWidth: "200px" } }}
              backgroundColor={mainBackgroundColor}
            />

            <CustomField
              value={form?.penalty_on_phases}
              // value={[{ label: "Option 1", value: "1" }]}
              onChange={(e) => editForm({ penalty_on_phases: e })}
              backgroundColor={mainBackgroundColor}
              type="checkbox"
              // options={[
              //   { label: "Option 1", value: "1" },
              //   { label: "Option 2", value: "2" },
              // ]}
              options={PhasesOptions}
              // options={form?.reward_breakup?.map((i, ind) => ({
              //   label: `Interval ${ind + 1} (${i?.from} - ${i?.to} ${
              //     i?.time_period_type
              //   })`,
              //   value: i?.id,
              // }))}
              textFieldProps={{
                placeholder: "Select intervals",
                style: { minWidth: "200px" },
              }}
            />
            <PrimaryLabelSmall>Payments are missed then</PrimaryLabelSmall>
            <CustomField
              value={form?.advance_penalty_action}
              onChange={(e) => editForm({ advance_penalty_action: e })}
              type="dropdown"
              options={[
                { label: "No rewards", value: "no_rewards" },
                { label: "Deduct", value: "deduct" },
                { label: "Plan Close", value: "plan_close" },
              ]}
              textFieldProps={{ style: { minWidth: "200px" } }}
              backgroundColor={mainBackgroundColor}
              // tooltip={"Specify a name for the field"}
            />
            {form?.advance_penalty_action === "deduct" && (
              <>
                <CustomField
                  value={form?.advance_penalty_deduction_type}
                  onChange={(e) =>
                    editForm({ advance_penalty_deduction_type: e })
                  }
                  type="dropdown"
                  options={[
                    { label: "Amount", value: "amount" },
                    { label: "Percentage", value: "percentage" },
                  ]}
                  textFieldProps={{ style: { minWidth: "200px" } }}
                  backgroundColor={mainBackgroundColor}
                  // tooltip={"Specify a name for the field"}
                />
                <CustomField
                  value={form?.advance_penalty_deduction_value}
                  onChange={(e) =>
                    editForm({
                      advance_penalty_deduction_value: e?.slice?.(0, 7),
                    })
                  }
                  textFieldProps={{
                    style: { minWidth: "200px" },
                    type: "number",
                    placeholder: `Enter ${form?.advance_penalty_deduction_type}`,
                    // onInput: (e) => {
                    //   e.target.value = Math.max(0, parseInt(e.target.value))
                    //     .toString()
                    //     .slice(0, 9);
                    // },
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          {form?.advance_penalty_deduction_type === "amount" &&
                            currencySymbols[default_currency]}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          {form?.advance_penalty_deduction_type ===
                            "percentage" && "%"}
                        </InputAdornment>
                      ),
                    },
                  }}
                  backgroundColor={mainBackgroundColor}
                  // tooltip={"Specify a name for the field"}
                />
                {form?.advance_penalty_deduction_type === "percentage" && (
                  <>
                    <PrimaryLabelSmall>On</PrimaryLabelSmall>
                    <CustomField
                      value={form?.advance_penalty_deduction_on}
                      onChange={(e) =>
                        editForm({ advance_penalty_deduction_on: e })
                      }
                      type="dropdown"
                      options={[
                        {
                          label: "Total plan value",
                          value: "total_plan_amount",
                        },
                        {
                          label: "Total accumulated value",
                          value: "paid_amount",
                        },
                      ]}
                      textFieldProps={{ style: { minWidth: "200px" } }}
                      backgroundColor={mainBackgroundColor}
                    />
                  </>
                )}
              </>
            )}
          </HorizontalStack>
        </>
      )}
    </Box>
  );
};
