import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PlanTypeSelection from "./components/PlanTypeSelection";
import AddPlanSteps from "./components/Stepper";
import { Alert, Box, IconButton } from "@mui/material";
import PrimaryPlanDetails from "./components/PrimaryPlanDetails";
import PlanContents from "./components/PlanContents";
import PlanRewards from "./components/PlanRewards";
import PlanSettings from "./components/PlanSettings";
import PlanReview from "./components/PlanReview";
import { LoadingButton } from "@mui/lab";
import { getToken, getWebsite, sleep } from "../../../../helper";
import { useDispatch } from "react-redux";
import {
  setGlobalSnackbar,
  setGlobalToast,
} from "../../../../redux/actions/helperActions";
import HorizontalStack from "../../../../helper/utility/HorizontalStack";
import { Cancel, Close } from "@mui/icons-material";
import {
  capitalizeFirstLetter,
  getAxiosError,
  isArrayWithValues,
  isObjWithValues,
} from "jwero-javascript-utils";
import { AdvancePaneltySettings } from "./components/PlanRewards/CustomIntervalReward";
import { validatePlanContentsDetails } from "./helper/validations";
import axios from "axios";
import {
  addDigiPlanInRedux,
  updateDigiPlanInRedux,
} from "../../../../redux/actions/productActions";

let defaultForm = {
  type: "",
  digi_plan_type: "flexi",
  amount: "amount",
  plan_amount_type: "fixed",
  duration_period: "days",
  reward_type: "",
  currency: "INR",
  advance_penalty_on: "all",
  advance_penalty_deduction_type: "amount",
  advance_penalty_deduction_on: "total_plan_amount",
};

const AddDigitalGoldPlan = React.forwardRef((props = {}, ref) => {
  let { onFixedPlanSelection, editData } = props;
  const [form, setForm] = React.useState({
    ...defaultForm,
  });
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(-1);
  const [addRewardOpen, setAddRewardOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const stepRef = React.useRef();
  const rewardsRef = React.useRef();
  const primaryPlanRef = React.useRef();
  const planContentsRef = React.useRef();
  const dispatch = useDispatch();

  React.useImperativeHandle(ref, () => {
    return {
      open: handleClickOpen,
      close: handleClose,
      setStep: setActiveStep,
    };
  });

  React.useEffect(() => {
    setIsEdit(isObjWithValues(editData));
    if (isObjWithValues(editData)) {
      let obj = { ...editData };
      let { meta_data } = editData;
      if (isArrayWithValues(meta_data)) {
        for (let metaObj of meta_data) obj[metaObj.key] = metaObj.value;
      }
      setForm({ ...defaultForm, ...obj });
    }
  }, [editData]);

  const editForm = (payload) => {
    if (isObjWithValues(errors)) setErrors({});
    setForm((state) => ({ ...state, ...payload }));
  };

  React.useEffect(() => {
    console.log(form);
  }, [activeStep]);

  const handleNext = async () => {
    if (activeStep < 0 && form.type === "fixed") {
      onFixedPlanSelection && onFixedPlanSelection();
    }
    if (activeStep === 0) {
      let errors = primaryPlanRef?.current?.validateForm();
      console.log(errors);
      if (isObjWithValues(errors)) {
        setErrors(errors);
        return;
      }
    }
    if (activeStep === 1) {
      let errors = validatePlanContentsDetails({ form: form });
      if (errors) {
        if (errors?.terms_and_conditions) {
          planContentsRef.current?.scrollTo("terms_and_conditions");
        }
      }
      console.log(errors);
      if (isObjWithValues(errors)) {
        setErrors(errors);
        return;
      }
    }
    if (activeStep === 4) {
      setLoading(true);
      try {
        let { name, sku, description, ...otherValues } = form;
        let payload = {
          name,
          sku,
          description,
          catalog_visibility: "hidden",
        };
        let meta_data = [
          {
            key: "_virtual",
            value: "yes",
          },
          {
            key: "_sold_individually",
            value: "yes",
          },
          {
            key: "_visibility",
            value: "hidden",
          },
        ];
        for (let key in otherValues) {
          meta_data.push({ key, value: otherValues[key] });
        }
        payload.meta_data = meta_data;
        console.log(payload);

        let res = await addProduct(payload);
        if (res?.id) {
          handleClose();
          dispatch(
            setGlobalToast({
              show: true,
              message: isEdit
                ? "Plan has been updated"
                : `You have successfully launched ${capitalizeFirstLetter(
                    form?.name || ""
                  )}`,
              severity: "success",
            })
          );
        } else throw new Error();

        // await sleep(3000);
      } catch (error) {
        // dispatch(
        //   setGlobalToast({
        //     show: true,
        //     message: `Unable to create plan`,
        //     severity: "Error",
        //   })
        // );
      } finally {
        setLoading(false);
      }
    } else if (addRewardOpen) rewardsRef?.current?.addPhase();
    else stepRef?.current?.nextStep();
  };

  const addProduct = async (obj) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      // setSubmitting(true);
      try {
        let { data } = await axios({
          url: isEdit
            ? `${website}/wp-json/wc/v3/products/${form.id}`
            : `${website}/wp-json/wc/v3/products/`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: isEdit ? "PUT" : "POST",
          data: obj,
        });
        // console.log(data);
        if (isObjWithValues(data)) {
          if (isEdit) dispatch(updateDigiPlanInRedux(data));
          else dispatch(addDigiPlanInRedux(data));
          // closeForm();
          // dispatch(
          //   setGlobalToast({
          //     show: true,
          //     message: `Digigold plan ${
          //       isEdit ? "updated" : "added"
          //     } successfully`,
          //     severity: "success",
          //   })
          // );
        }
        return data;
      } catch (error) {
        let e = getAxiosError(error);
        if (e.code == "product_invalid_sku") {
          dispatch(
            setGlobalToast({
              show: true,
              message: "Plan code already exist!",
              severity: "error",
            })
          );
        } else {
          dispatch(
            setGlobalToast({
              show: true,
              message: isEdit
                ? `Unable to update plan`
                : `Unable to create plan`,
              severity: "Error",
            })
          );
        }
      }
      // setSubmitting(false);
    }
  };

  const handleBack = () => {
    if (addRewardOpen) {
      setAddRewardOpen(false);
    } else stepRef?.current?.backStep();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log(form, "<<< form");

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            maxHeight: "80vh",
            height: "80vh",
            minHeight: "80vh",
          },
        }}
      >
        <HorizontalStack style={{ justifyContent: "space-between" }}>
          <DialogTitle id="alert-dialog-title">{"Create new plan"}</DialogTitle>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </HorizontalStack>
        <Box
          sx={{
            display: activeStep >= 0 ? "block" : "none",
            mb: 1,
            px: 3,
          }}
        >
          <AddPlanSteps
            ref={stepRef}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </Box>
        <DialogContent>
          {activeStep < 0 && (
            <PlanTypeSelection form={form} editForm={editForm} />
          )}
          <Box sx={{ py: 4 }}>
            {activeStep === 0 && (
              <PrimaryPlanDetails
                errors={errors}
                ref={primaryPlanRef}
                form={form}
                editForm={editForm}
              />
            )}
            {activeStep === 1 && (
              <PlanContents
                errors={errors}
                ref={planContentsRef}
                form={form}
                editForm={editForm}
              />
            )}
            {activeStep === 2 && (
              <PlanRewards
                ref={rewardsRef}
                form={form}
                editForm={editForm}
                addRewardOpen={addRewardOpen}
                setAddRewardOpen={setAddRewardOpen}
              />
            )}
            {activeStep === 3 && (
              <PlanSettings
                form={form}
                editForm={editForm}
                addRewardOpen={addRewardOpen}
                setAddRewardOpen={setAddRewardOpen}
              />
            )}
            {activeStep === 4 && (
              <PlanReview
                form={form}
                editForm={editForm}
                addRewardOpen={addRewardOpen}
                setAddRewardOpen={setAddRewardOpen}
              />
            )}
          </Box>
        </DialogContent>

        {activeStep === 2 &&
          form?.reward_type === "custom" &&
          !addRewardOpen && (
            <AdvancePaneltySettings form={form} editForm={editForm} />
          )}
        {isEdit && (
          <Box sx={{ px: 3, mt: 2 }}>
            <Alert severity="error">
              Editing digigold flexi plan is not available
            </Alert>
          </Box>
        )}
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button onClick={handleBack}>Back</Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleNext}
            autoFocus
            disabled={isEdit && activeStep === 4}
          >
            {activeStep === 4 ? "Launch plan" : "Next"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default AddDigitalGoldPlan;
