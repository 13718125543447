import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Calls from "../../../customers/components/Calls";
import { useDispatch, useSelector } from "react-redux";
import {
  isArrayWithValues,
  settingsEndpoint,
  validateNumber,
} from "../../../../helper";
import { EDIT_CHATS } from "../../../../redux/actions/chatActions";
import { Sync } from "@mui/icons-material";
import SmsHistory from "../../../customers/components/SmsHistory";

const Activities = () => {
  return (
    <Box p={4} sx={{ pt: 0 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">SMS History</Typography>

        {/* <IconButton onClick={() => {
                    getAllLogs()
                }} disabled={fetchingLogsArray}>
                    <Sync />
                </IconButton> */}
        {/* <LoadingButton variant='outlined' onClick={() => {
                    getAllLogs()
                }}>
                    Refresh
                </LoadingButton> */}
      </Stack>
      <Typography sx={{ fontStyle: "italic", mt: 2 }}>
        Here you will be able to view all the latest activity of the recent
        campaigns.
      </Typography>
      <SmsHistory crm={false} />
    </Box>
  );
};

export default Activities;
