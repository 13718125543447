import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { UnicommerceSvg } from "../../../../Svgs";
import { TabPanel } from "../../../../helper";
import Integration from "./Integration";
import { isObjWithValues } from "jwero-javascript-utils";
import Settings from "./settings";
import { useSelector } from "react-redux";
import { ListItem } from "../meta/MetaOverview";

function Unicommerce() {
  const [selectedTab, setSelectedTab] = useState("integration");
  const unicommerceIntegration = useSelector(
    (state) => state.settings.unicommerce_integration
  );
  return (
    <Box sx={{ height: "100%", paddingRight: "15px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {" "}
            <UnicommerceSvg height={25} width={25} /> Unicommerce
          </Typography>
        </Stack>
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          <Tab label="Integration" value="integration" />
          {isObjWithValues(unicommerceIntegration) && (
            <Tab label="Settings" value="settings" />
          )}
          {/* {reduxWooCommerceIntegration?.integrated
        ? [
            <Tab label="Mapping" value="mapping" />,
            <Tab label="Import" value="import" />,
            <Tab label="Activity" value="activity" />,
            <Tab label="Settings" value="settings" />,
          ]
        : null} */}
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"integration"}>
        <Integration />
      </TabPanel>
      <TabPanel value={selectedTab} index={"overview"}>
        <Overview />
      </TabPanel>
      {isObjWithValues(unicommerceIntegration) && (
        <TabPanel value={selectedTab} index={"settings"}>
          <Settings />
        </TabPanel>
      )}
    </Box>
  );
}

export default Unicommerce;

const benefits = [
  "✅ Streamlined Operations:- Choose between manual or automatic order processing, and easily identify orders with customizable prefixes. This flexibility ensures that every order is tracked and identified.",
  "✅ Order Journey Visibility:- Track every order's journey from Jwero to Unicommerce in real-time, allowing for proactive management at every step.",
  "✅ Order Verification:- Opt for processing verified orders from Jwero or set specific criteria for unverified orders in Unicommerce, giving you control over the quality and reliability of your transactions.",
  "✅ Real-Time Inventory Management:- Instant stock updates between Unicommerce and Jwero keep your inventory accurate, helping you avoid stock discrepancies and meet customer demand without delays.",
];

function Overview() {
  return (
    <Box sx={{ padding: "16px" }}>
      <Typography variant="subtitle1" sx={{ fontSize: "15px" }}>
        Unicommerce enables businesses to automate their supply chain management
        within their business and at the same time enables businesses to push
        products and cross sells across different channels such as Myntra,
        Nykaa, Flipkart etc.
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "15px", mt: 3 }}>
        Integrating Unicommerce with Jwero transforms the way jewellers manage
        their operations, offering a blend of flexibility, efficiency, and
        control. This powerful integration brings together the robust
        capabilities of Unicommerce's supply chain management with Jwero's
        specialized platform for jewellers,
      </Typography>
      <Typography variant="h6" sx={{ mt: 2, mb: 2 }} marginTop={4}>
        Here's a concise overview of the key benefits:
      </Typography>
      <ListItem arr={benefits} />
      <Typography variant="subtitle1" sx={{ fontSize: "15px", mt: 3 }}>
        The integration is designed with customization at its core, enabling
        jewellers to fine-tune their operations. Automating key processes not
        only saves time but also allows you to focus on growth and customer
        service.
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "15px", mt: 3 }}>
        In essence, Unicommerce integration with Jwero is not just about
        streamlining operations—it's about empowering jewellers with the tools
        to enhance their service quality, manage inventory smartly, and ensure
        every customer interaction is a success.
      </Typography>
    </Box>
  );
}
