import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HorizontalStack from "../../../helper/utility/HorizontalStack";
import {
  ErrorLabelExtraSmall,
  PrimaryLabel,
  PrimaryLabelSmall,
} from "../../../helper/utility/Labels";
import { Alert, MenuItem, Select } from "@mui/material";
import { allPaymentStatus } from "../Orders";
import { useDispatch, useSelector } from "react-redux";
import { isArrayWithValues } from "jwero-javascript-utils";
import { CustomDateRangeFilter } from "../../digital_gold/Dashboard";
import axios from "axios";
import {
  getAdminName,
  getAuthToken,
  getEmail,
  getId,
  getStoreId,
  getStoreName,
  getTenantId,
  getToken,
  getWebSocketUrl,
  getWebsite,
  isDev,
} from "../../../helper";
import { LoadingButton } from "@mui/lab";
import { getIp } from "../../../App";
import { UAParser } from "ua-parser-js";
import {
  setGlobalSnackbar,
  setGlobalToast,
} from "../../../redux/actions/helperActions";
import { FileDownloadOutlined } from "@mui/icons-material";

export default function ExportAsSheets() {
  const [open, setOpen] = React.useState(false);
  const [form, setForm] = React.useState({
    after: "",
    before: "",
    status: "all",
  });
  const [orderStatuses, setOrderStatuses] = React.useState(
    allPaymentStatus?.map((i) => (i?.value === "" ? { ...i, value: "all" } : i))
  );
  const reduxOrderStatuses = useSelector((state) => state.orders.orderStatuses);
  const [loading, setLoading] = React.useState(false);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const [error, setError] = React.useState("");

  const dispatch = useDispatch();

  const editForm = (payload) => {
    if (Boolean(error)) setError("");
    setForm((state) => ({ ...state, ...payload }));
  };

  React.useEffect(() => {
    try {
      if (isArrayWithValues(reduxOrderStatuses)) {
        let allTotal = 0;

        const reports = reduxOrderStatuses?.map((o) => {
          allTotal += o?.total;
          return {
            label: o?.name,
            value: o?.slug,
            total: o?.total,
          };
        });

        // reports?.push({ label: "All", value: "", total: allTotal });

        setOrderStatuses([
          ...[{ label: "All", value: "all", total: allTotal }],
          ...reports,
        ]);
      }
    } catch (e) {
      console.log(e);
    }
  }, [reduxOrderStatuses]);

  console.log(orderStatuses, "<<<<<< rderStatuses");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function toISOStringLocal(date) {
    date = new Date(date);
    const pad = function (num) {
      return (num < 10 ? "0" : "") + num;
    };

    var year = date.getFullYear(),
      month = pad(date.getMonth() + 1),
      day = pad(date.getDate()),
      hour = pad(date.getHours()),
      minute = pad(date.getMinutes()),
      second = pad(date.getSeconds()),
      millisecond = pad(date.getMilliseconds());

    // Construct an ISO 8601 string in local time
    return `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}`;
  }
  // const onDateSubmit = (params) => {
  //   // if (params.after) params.after = formatDate(params.after, "yyyy-mm-dd");
  //   // if (params.before) params.before = formatDate(params.before, "yyyy-mm-dd");

  //   if (params.after) {
  //     params.after = new Date(params.after);
  //     params.after = toISOStringLocal(params.after);
  //   }
  //   if (params.before) {
  //     params.before = new Date(params.before);
  //     params.before = toISOStringLocal(params.before);
  //   }

  //   setParams({ ...params, ...defaultParams });
  //   dispatch(getAllOrders({ ...params, ...defaultParams }, true));
  // };
  function isMoreThanAYear(dateRange) {
    const afterDate = new Date(dateRange.after);
    const beforeDate = new Date(dateRange.before);

    const oneYearInMillis = 1000 * 60 * 60 * 24 * 365;

    return beforeDate - afterDate > oneYearInMillis;
  }
  const startExport = async () => {
    let error = "";
    if (isMoreThanAYear(form)) {
      error = "Date range cannot be more than 1 year";
    }
    if (!form?.before || !form.after) {
      error = "Please select a date";
    }
    if (error) {
      setError(error);
      return;
    }
    setLoading(true);
    try {
      let { data: res } = await axios({
        url: `${getWebSocketUrl()}/sheets/orders`,
        method: "POST",
        headers: {
          Authorization: `Basic ${getToken()}`,
        },
        data: {
          store_id: getStoreId(),
          website: getWebsite(),
          newAuthToken: getAuthToken(),
          tenantId: getTenantId(),
          email: getEmail(),
          storeName: getStoreName(),
          userName: getAdminName(),
          ip: await getIp(),
          ua: new UAParser().getUA(),
          isAdmin: isAdmin,
          by: getId(),
          is_dev: isDev(),
          filters: form,
        },
      });
      // console.log(res, "<<<<< res");
      if (res?.success) {
        dispatch(
          setGlobalToast({
            show: true,
            message:
              "Sheet exporting process started successfully, you'll receive email in couple of minutes",
            severity: "success",
          })
        );
        handleClose();
      } else {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Unable to export sheet",
            severity: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Unable to export sheet",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        endIcon={<FileDownloadOutlined />}
        sx={{ flex: 1 }}
        onClick={handleClickOpen}
      >
        Export
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          Export orders as sheet
        </DialogTitle>
        <DialogContent>
          <Alert
            // title={``}
            severity="info"
          >
            You will receive exported file on email:{" "}
            <strong>{getEmail()}</strong>
          </Alert>
          <HorizontalStack sx={{ mt: 4 }} justifyContent="space-between">
            <PrimaryLabelSmall>Select Date: </PrimaryLabelSmall>
            <CustomDateRangeFilter
              dateRange={form}
              startDateLabel={"after"}
              endDateLabel={"before"}
              setDateRange={
                (dateRange) => editForm({ ...dateRange })
                // onDateSubmit({ ...defaultParams, ...params, ...dateRange })
              }
              // allowed_filters={[
              //   "this_week",
              //   "this_month",
              //   "this_year",
              //   "custom",
              // ]}
              mobileMode
              // selectedValue={"this_week"}
              custom_only
            />
          </HorizontalStack>
          <HorizontalStack sx={{ mt: 4 }} justifyContent="space-between">
            <PrimaryLabelSmall>Select Status: </PrimaryLabelSmall>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={form.status}
              // label="Age"
              onChange={(e) => editForm({ status: e.target.value })}
              size="small"
            >
              {orderStatuses?.map((i) => (
                <MenuItem value={i?.value}>{i?.label}</MenuItem>
              ))}
              {/* <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </HorizontalStack>
        </DialogContent>
        {Boolean(error) && (
          <ErrorLabelExtraSmall sx={{ textAlign: "center" }}>
            <strong>{error}</strong>
          </ErrorLabelExtraSmall>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            onClick={startExport}
            variant="contained"
          >
            Start Export
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
