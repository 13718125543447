export const traverseAndDeleteNodes = ({ nodes, edges, id, sourceId }) => {
  const nodesToDelete = [id];
  const edgesToDelete = [id];
  const edgesToTraverse = [sourceId, id];
  while (edgesToTraverse.length > 0) {
    const currentEdge = edgesToTraverse.shift();
    const edgesToUpdate = edges.filter((edge) => edge.source === currentEdge);
    const edgesToUpdateTarget = edgesToUpdate.map((edge) => edge.target);
    nodesToDelete.push(...edgesToUpdateTarget);
    edgesToDelete.push(...edgesToUpdateTarget);
    edgesToTraverse.push(...edgesToUpdateTarget);
  }
  const updatedNodes = nodes.filter((node) => !nodesToDelete.includes(node.id));
  const updatedEdges = edges.filter(
    (edge) => !edgesToDelete.includes(edge.target)
  );

  return { finalNodes: updatedNodes, finalEdges: updatedEdges };
};
