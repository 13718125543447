import {
  alpha,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  formatDate,
  getComparator,
  isArrayWithValues,
  isObjWithValues,
  reduceLargeText,
  stableSort,
} from "../../helper";
import { getStatusBackgroundColor, statusFontColor } from "../orders/Orders";
import { currencySymbols } from "../pricing/Gold";
import {
  CustomersTable,
  digigoldGlobalColors,
  SectionLabel,
  StyledTableRow,
  TopCards,
  TotalCard,
} from "./Dashboard";
import { visuallyHidden } from "@mui/utils";
import { useTheme } from "@mui/system";
import { DashboardTotalCard } from "../dashboards/Default";
import { validateNumber } from "jwero-javascript-utils";

function Customers({
  digiCustomers,
  dashboardTotals,
  digiPlans,
  currency,
  setUserView,
  digiCancelledPlans,
  topGraphs,
  setSelectedTab,
}) {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <DashboardTotalCard
                title="New Customers"
                value={dashboardTotals.totalRecentCustomers || 0}
                values={[
                  {
                    title: "In amt",
                    value: dashboardTotals?.totalRecentCustomersInAmount || 0,
                    // prefix: currencySymbols[currency],
                  },
                  {
                    title: "In grams",
                    value: dashboardTotals?.totalRecentCustomersInGram || 0,
                    // suffix: "g",
                  },
                ]}
                // onClick={() => navigate("plans")}
                // breakup={plansBreakup}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <DashboardTotalCard
                title="Total Customers"
                value={digiCustomers?.length || 0}
                values={[
                  {
                    title: "In amt",
                    value: dashboardTotals?.totalCustomersInAmount || 0,
                    // prefix: currencySymbols[currency],
                  },
                  {
                    title: "In grams",
                    value: dashboardTotals?.totalCustomersInGram || 0,
                    // suffix: "g",
                  },
                ]}
                // onClick={() => navigate("users")}
                // breakup={totalUsersBreakup}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <DashboardTotalCard
                title="Closed accounts"
                value={
                  validateNumber(dashboardTotals?.totalCancelledPlansAmount) +
                  validateNumber(dashboardTotals?.totalCancelledPlansGram)
                }
                values={[
                  {
                    title: "In amt",
                    value: dashboardTotals?.totalCancelledPlansAmount || 0,
                    // prefix: currencySymbols[currency],
                  },
                  {
                    title: "In grams",
                    value: dashboardTotals?.totalCancelledPlansGram || 0,
                    // suffix: "g",
                  },
                ]}
                // breakup={totalAmountBreakup}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Chart1 users={digiCustomers} />
        </Grid>
      </Grid>
      {/* <TopCards
        topGraphs={topGraphs}
        dashboardTotals={dashboardTotals}
        setSelectedTab={setSelectedTab}
        currency={currency}
      /> */}
      <CustomersTable
        customers={digiCustomers}
        plans={digiPlans}
        currency={currency}
        setUserView={setUserView}
        digiCancelledPlans={digiCancelledPlans}
      />
      {/* <CustomersTable
        customers={digiCustomers}
        plans={digiPlans}
        currency={currency}
        setUserView={setUserView}
      /> */}
    </Box>
  );
}

export default Customers;

const Chart1 = ({ users }) => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (!isArrayWithValues(users)) return;
    let seriesObj = {};
    let series = [];
    for (let user of users) {
      let { plans } = user;
      if (!isObjWithValues(plans)) continue;
      let allLedgers = [];
      Object.values(plans).map((i) => {
        if (isArrayWithValues(i.ledger))
          allLedgers = allLedgers.concat(i.ledger);
      });
      let sortedLedger = allLedgers.sort(
        (a, b) =>
          new Date(a.date_paid).getTime() - new Date(b.date_paid).getTime()
      );
      if (sortedLedger?.[0]?.date_paid) {
        let paidDate = formatDate(
          new Date(sortedLedger?.[0]?.date_paid),
          "mm-dd-yyyy"
        );
        if (!seriesObj[paidDate]) seriesObj[paidDate] = 0;
        seriesObj[paidDate] += 1;
      }
    }
    for (let date in seriesObj) {
      series.push([new Date(date).getTime(), seriesObj[date]]);
    }
    series = series.sort(
      (a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()
    );
    setSeries(series);
  }, [users]);

  return (
    <>
      <Card sx={{ borderRadius: "15px" }}>
        <CardContent>
          <SectionLabel>Customers Enrollment</SectionLabel>
          <ReactApexChart
            options={chart1Options}
            series={[
              {
                data: series,
                name: "Customers enrolled",
              },
            ]}
            type="area"
            height={350}
          />
        </CardContent>
      </Card>
    </>
    // <Card sx={{ borderRadius: "15px" }}>
    //   <CardContent>
    //     <Stack
    //       direction={"row"}
    //       justifyContent="space-between"
    //       alignItems={"center"}
    //     >
    //       <Typography>Analytics</Typography>
    //       {/* <Button startIcon={<ArrowBackIos />} endIcon={<ArrowForwardIos />}>
    //                     Total
    //                 </Button> */}
    //     </Stack>
    //     <ReactApexChart
    //       options={options}
    //       series={series}
    //       type="bar"
    //       height={350}
    //     />
    //   </CardContent>
    // </Card>
  );
};

const chart1Options = {
  chart: {
    id: "area-datetime",
    type: "area",
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
    toolbar: { show: false },
  },
  colors: digigoldGlobalColors,
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },
  yaxis: [
    {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
  ],
  xaxis: {
    type: "datetime",
    // min: new Date("01 Mar 2012").getTime(),
    tickAmount: 6,
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
};

export const DigiOrdersTable = ({ orders, currency, customers }) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  const theme = useTheme();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";

  useEffect(() => {
    if (!isArrayWithValues(orders)) return;
    let rows = [];
    for (let order of orders) {
      let obj = { ...order };
      let customer = {};
      if (isArrayWithValues(customers)) {
        let customerObj = customers.find((i) => i.id == obj.customer_id);
        if (customerObj) {
          let { digi_refer_obj } = customerObj;
          obj.digi_refer_obj = digi_refer_obj;
        }
      }

      let { meta_data } = order;
      if (isArrayWithValues(meta_data)) {
        for (let metaObj of meta_data) {
          obj[metaObj.key] = metaObj.value;
        }
      }
      rows.push(obj);
    }
    setRows(rows);
    // if (!isArrayWithValues(plans)) return;
    // let rows = [];
    // for (let plan of plans) {
    //   let {
    //     totalUsers,
    //     totalPaid,
    //     totalPayments,
    //     name,
    //     date_created,
    //     meta_data,
    //   } = plan;
    //   let metaObj = {};
    //   if (isArrayWithValues(meta_data)) {
    //     for (let obj of meta_data) {
    //       let { key, value } = obj;
    //       if (value) metaObj[key] = value;
    //     }
    //   }
    //   let obj = {
    //     totalUsers,
    //     totalPaid,
    //     totalPayments,
    //     name,
    //     duration: metaObj.digi_plan_duration,
    //   };
    //   if (date_created)
    //     obj.date_created = formatDate(
    //       new Date(date_created),
    //       "dd:mm:yyyy hh:mm"
    //     );
    //   rows.push(obj);
    // }
    // setRows(rows);
  }, [orders, customers]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(selected.length > 0 && {
                  bgcolor: (theme) =>
                    alpha(
                      theme.palette.primary.main,
                      theme.palette.action.activatedOpacity
                    ),
                }),
              }}
            >
              {selected.length > 0 ? (
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {selected.length} selected
                </Typography>
              ) : (
                <SectionLabel
                  sx={{ flex: "1 1 100%" }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Recent Advance Received
                </SectionLabel>
              )}

              {/* {selected.length > 0 ? (
                <Tooltip title="Delete">
                  <IconButton>
                    <Delete />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Filter list">
                  <IconButton>
                    <FilterList />
                  </IconButton>
                </Tooltip>
              )} */}
            </Toolbar>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <DigiOrdersTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                  {/* {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                  {stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      // console.log(row);
                      return (
                        <StyledTableRow
                          // hover
                          // onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell> */}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            <Typography
                              sx={{ fontWeight: "600", fontWeight: "15px" }}
                            >
                              {/* {row?.billing?.first_name || ""}{" "}
                              {row?.billing?.last_name || ""} */}
                              <Typography
                                sx={{ fontSize: "14px", fontWeight: "500" }}
                              >
                                {row?.billing?.first_name || ""}{" "}
                                {row?.billing?.last_name || ""}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  color: "text.secondary",
                                  mt: 0.5,
                                }}
                              >
                                Customer ID: {row.customer_id}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  color: "text.secondary",
                                  mt: 0.5,
                                }}
                              >
                                Join date:{" "}
                                {row.joining_date
                                  ? formatDate(
                                      new Date(row.joining_date),
                                      "dd-mm-yyyy"
                                    )
                                  : ""}
                              </Typography>
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            {row?.digi_refer_obj ? (
                              <>
                                <Typography
                                  sx={{ fontSize: "14px", fontWeight: "500" }}
                                >
                                  {row?.digi_refer_obj?.first_name || ""}{" "}
                                  {row?.digi_refer_obj?.last_name || ""}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "11px",
                                    color: "text.secondary",
                                    mt: 0.5,
                                  }}
                                >
                                  A/c ID: {row?.digi_refer_obj?.id}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "11px",
                                    color: "text.secondary",
                                    mt: 0.5,
                                  }}
                                >
                                  J. date:{" "}
                                  {row?.digi_refer_obj?.date_created
                                    ? formatDate(
                                        new Date(
                                          row?.digi_refer_obj?.date_created
                                        ),
                                        "dd-mm-yyyy"
                                      )
                                    : ""}
                                </Typography>
                              </>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {row?.billing?.city || ""}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title={row?.line_items?.[0]?.name || ""}>
                              <Stack
                                direction="row"
                                alignItems={"center"}
                                justifyContent="flex-end"
                              >
                                {row?.line_items?.[0]?.name
                                  ? reduceLargeText(
                                      row?.line_items?.[0]?.name,
                                      15
                                    )
                                  : "-"}
                              </Stack>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="right">
                            {row.date_created
                              ? formatDate(
                                  new Date(row.date_created),
                                  "dd-mm-yyyy"
                                )
                              : ""}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ fontSize: "14px", fontWeight: "500" }}
                          >{`${row?.currency_symbol || ""} ${
                            row?.total || ""
                          }`}</TableCell>
                          <TableCell align="right">
                            <Typography
                              sx={{ fontWeight: "600", fontWeight: "15px" }}
                            >
                              {/* {row?.billing?.first_name || ""}{" "}
                              {row?.billing?.last_name || ""} */}
                              <Typography sx={{ fontSize: "13px" }}>
                                {row.manual_payment === "yes"
                                  ? "Manual"
                                  : "Automatic"}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  color: "text.secondary",
                                  mt: 0.5,
                                }}
                              >
                                Ref. No: {row.id}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  color: "text.secondary",
                                  mt: 0.5,
                                }}
                              >
                                {row.payment_method_title}
                              </Typography>
                            </Typography>
                            {/* {currency ? currencySymbols[currency] : ""} {0} */}
                          </TableCell>
                          <TableCell align="right">
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label={row.status}
                              // color="warning"
                              sx={{
                                color: statusFontColor(row.status),
                                border: isDarkTheme
                                  ? "0.7px solid"
                                  : "0px solid",
                                // borderColor: statusFontColor(row.status),
                                backgroundColor: isDarkTheme
                                  ? "transparent"
                                  : getStatusBackgroundColor(row.status),
                              }}
                              variant="outlined"
                            />
                          </TableCell>
                        </StyledTableRow>
                      );
                    }
                  )}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />s */}
          </Paper>
        </Box>
      </CardContent>
    </Card>
  );
};
const DigiOrdersTableHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Customer Name",
  },
  {
    id: "family_name",
    numeric: false,
    disablePadding: false,
    label: "Refer by",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "plan_name",
    numeric: true,
    disablePadding: false,
    label: "Plan Name",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "amt_paid",
    numeric: true,
    disablePadding: false,
    label: "Amt Paid",
  },
  {
    id: "payment_mode",
    numeric: true,
    disablePadding: false,
    label: "Payment mode",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function DigiOrdersTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {DigiOrdersTableHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
