export const checkTitleExists = ({ nodes, title }) => {
  const defaultValues = ["start point", "default fallback"];

  if(title.trim() === ""){
    return false
  }

  if (defaultValues.includes(title.trim())) {
    return true;
  }
  const titleExists = nodes.find(
    (node) => node?.fieldData?.title?.toLowerCase() === title.toLowerCase()
  );
  return titleExists ? true : false;
};
