import { useState, useEffect } from "react";
import {
  Typography,
  Card,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Alert,
} from "@mui/material";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { useDispatch, useSelector } from "react-redux";
import { delayStyles } from "./styles/delayStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import FormatOptions from "./components/FormatOptions";
import { capitalizeFirstLetter } from "jwero-javascript-utils";
import { checkTitleExists } from "../helper/checkTitleExists";
import { SecondaryLabelSmall } from "../../../../../helper/utility/Labels";
import HorizontalStack from "../../../../../helper/utility/HorizontalStack";
import { InfoOutlined } from "@mui/icons-material";
import SidebarTopBar from "../components/SidebarTopBar";
import CustomField from "../../../../../helper/utility/CustomField";

const styles = delayStyles;

const SidebarDelay = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [data, setData] = useState(null);
  const [errorMessage, setErrrorMessage] = useState(false);
  const [titleCheckFlag, setTitleCheckFlag] = useState(false);
  const titleErrorMessage =
    title === "" ? "Title is required" : "Title already exists!";

  const editDate = (e) => setData((a) => ({ ...a, ...e }));

  const handleClickPopover = (e) => {
    setOptionsAnchorEl(e.currentTarget);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const closeSidebar = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { openDelaySidebar: false },
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleTimeChange = (e) => {
    const value = e;
    if (value < 0) {
      setErrrorMessage(true);
      return;
    }
    setErrrorMessage(false);
    setData((prev) => ({
      ...prev,
      time_value: value,
    }));
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title == "" || titleCheckFlag) {
      setTitleCheckFlag(true);
      setTitleError(true);
    }

    if (title && !titleCheckFlag) {
      const submittedUserData = {
        title,
        fieldsData: data,
      };
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { openDelaySidebar: false },
      });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { dialogBox: true },
    });
  };

  const handleBotTitle = (e) => {
    const value = e.target.value;
    setTitle(e.target.value);
    if (checkTitleExists({ nodes, title: value })) {
      setTitleCheckFlag(true);
      return;
    }
    setTitleCheckFlag(false);
  };

  useEffect(() => {
    const currentNode = nodes?.find((it) => it.id === userId);

    if (currentNode) {
      const node = currentNode?.fieldData;
      if (node?.title) {
        setTitle(node?.title);
      }
      if (node?.fieldsData) {
        setData(node?.fieldsData);
      } else {
        setData({
          time_value: 1,
          time_format: "Minutes",
        });
      }
    }
  }, [userId, nodes]);

  return (
    <Card style={styles.customCard}>
      <form>
        <SidebarTopBar
          onTitleChange={handleBotTitle}
          titleError={titleErrorMessage}
          title={title}
          showTitleError={titleCheckFlag}
          handleClose={handleClose}
          handleCheck={handleCheck}
          type="delay"
          topBarTitle={"Delay"}
          titlePlaceholder="For e.g. Wait for XX seconds/minutes"
          titleLabel="Trigger title"
        />
        {/* <Box sx={styles.topCard}>
          <Box sx={styles.innerCard}>
            <Box sx={styles.userHeader}>
              <HourglassBottomIcon />
              <Typography variant="span" style={styles.headerTitle}>
                Delay
              </Typography>
            </Box>
            <Box sx={styles.headerIconsMainDiv}>
              <IconButton sx={styles.headerIconsDiv} onClick={handleClose}>
                <CloseIcon sx={styles.headerIcons} />
              </IconButton>
              <IconButton sx={styles.headerIconsDiv} onClick={handleCheck}>
                <CheckIcon sx={styles.headerIcons} />
              </IconButton>
            </Box>
          </Box>

          <TextField
            placeholder="Type block title"
            size="small"
            style={styles.titleTextField}
            onChange={handleBotTitle}
            error={titleError}
            value={title}
            required
          />
          {titleCheckFlag ? (
            <Typography
              sx={{
                fontSize: "14px",
                color: "#E63946",
                fontWeight: 600,
                fontFamily: "Inter",
                position: "relative",
                bottom: "10px",
                left: "3px",
              }}
            >
              {titleErrorMessage}
            </Typography>
          ) : (
            <SecondaryLabelSmall
              sx={{
                color:
                  // error ? "error.main" :
                  "text.secondary",
              }}
            >
              Specify the duration of delay, in seconds, minutes, or hours,
              before the trigger action is executed
            </SecondaryLabelSmall>
          )}
        </Box> */}
        <Box sx={styles.responseSection}>
          <Box sx={styles.responseDiv}>
            <Typography variant="span" style={styles.labels}>
              Set Delay
            </Typography>
            <CustomField
              value={data?.time_value}
              onChange={handleTimeChange}
              // label="Enter time"
              textFieldProps={{
                placeholder: "Enter days",
                // helperText: errors?.to,
                // error: Boolean(errors?.to),
                type: "number",
                onInput: (e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 4);
                },
              }}
              type="text_dropdown"
              suffixProps={{
                value: data?.time_format,
                onChange: (e) => editDate({ time_format: e }),
                // otherProps: {
                //   disabled: !editDatePeriod,
                // },
                options: [
                  { label: "Seconds", value: "seconds" },
                  { label: "Minutes", value: "minutes" },
                  { label: "Hours", value: "hours" },
                  { label: "Days", value: "days" },
                ],
              }}
              // backgroundColor={mainBackgroundColor}
              // tooltip={"Specify a name for the field"}
            />
            {/* <CustomField type="text_dropdown" /> */}
            {/* <Box sx={styles.setTimeBox}>
              <Box
                sx={{
                  ...styles.textFieldBox,
                  width: "120px",
                  marginRight: "10px",
                }}
              >
                <TextField
                  type="number"
                  value={data?.time_value}
                  sx={{ ...styles.textField, paddingLeft: "10px" }}
                  onChange={handleTimeChange}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="Enter time"
                />
              </Box>
              <Box
                onClick={handleClickPopover}
                sx={{
                  ...styles.textFieldBox,
                  width: "120px",
                  cursor: "pointer",
                }}
              >
                <TextField
                  sx={styles.textField}
                  variant="standard"
                  value={
                    (data?.time_format &&
                      capitalizeFirstLetter(data?.time_format)) ||
                    ""
                  }
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <ArrowDropDownCircleIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box> */}
          </Box>
          {errorMessage && (
            <Alert severity="error" sx={{ marginTop: "25px", color: "red" }}>
              Value Should be Positive only.
            </Alert>
          )}
          <FormatOptions
            setData={setData}
            optionsAnchorEl={optionsAnchorEl}
            setOptionsAnchorEl={setOptionsAnchorEl}
          />
        </Box>
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </form>
    </Card>
  );
};

export default SidebarDelay;
