import { triggerNodes } from "../../actions-sidebar";
import { checkIflast } from "../helper/checkIfLast";

const webchatNodes = [
  "bot",
  "user",
  // "user-attachment",
  "question",
  "filter",
  "fallback",
  "go-to-step",
  "whatsapp-template",
  "live-agent",
  "product-catalog",
  "subscribe",
  "unsubscribe",
  "create-task",
  "metal-rates",
  "assign",
  // "email",
];

const campaignNodes = [
  "filter",
  "fallback",
  "go-to-step",
  "whatsapp-template",
  "email",
  "sms",
  "push-notification",
  "delay",
  "subscribe",
  "unsubscribe",
  "create-task",
  "assign",
];

const canBeAllowedPrevAndNext = [
  "bot",
  "whatsapp-template",
  "push-notification",
  "sms",
  "email",
  "user",
  "filter",
];

const canBeAddedLastOnly = [
  "whatsapp-template",
  "question",
  "email",
  "sms",
  "push-notification",
  "live-agent",
  "go-to-step",
];

// return true if prev and next are not the same nodes
const checkPrevAndNextNodeFlag = ({ nodes, edges, nodeId }) => {
  const edge = edges?.find((edge) => edge?.source === nodeId);
  if (!edge?.target) {
    return null;
  }
  const nextNode = nodes.find((node) => node?.id === edge?.target);
  if (!canBeAllowedPrevAndNext.includes(nextNode?.type)) {
    return nextNode?.type;
  }

  return null;
};

export const getAllowedNodes = ({
  nodes,
  edges,
  currentNodeId,
  currentNodeType,
  type,
}) => {
  const allNodes =
    type === "campaign"
      ? campaignNodes
      : type === "trigger"
      ? triggerNodes
      : webchatNodes;

  const isNodeLast = checkIflast({ nodes, edges, nodeId: currentNodeId });

  const multipleAddedNodes = ["user", "user-attachment", "filter", "fallback"];
  let currentValidNodes = isNodeLast
    ? allNodes
    : allNodes.filter((node) => !canBeAddedLastOnly.includes(node));

  if (!canBeAllowedPrevAndNext.includes(currentNodeType)) {
    currentValidNodes = currentValidNodes.filter(
      (type) => type !== currentNodeType
    );
  }

  const check = checkPrevAndNextNodeFlag({
    nodes,
    edges,
    nodeId: currentNodeId,
  });

  if (check) {
    currentValidNodes = currentValidNodes.filter((type) => type !== check);
  }

  //   console.log(currentNodeId,currentValidNodes)
  // if (checkPrevAndNextNodeFlag({ nodes, edges, nodeId: currentNodeId })) {
  //   console.log("INNNN");
  //   currentValidNodes = currentValidNodes.filter(
  //     (type) => type !== currentNodeType
  //   );
  // }    console.log(currentValidNodes)

  switch (currentNodeType) {
    case "bot":
    case "question":
    case "whatsapp-template":
    case "email":
    case "sms":
    case "push-notification":
    case "delay":
    case "subscribe":
    case "unsubscribe":
    case "create-task":
    case "metal-rates":
    case "assign":
    case "product-catalog": {
      let connectedNodes = [];
      if (checkFilterAllowed({ nodes, edges, id: currentNodeId })) {
        connectedNodes.push("filter");
      }

      if (checkUserAllowed({ nodes, edges, id: currentNodeId })) {
        connectedNodes.push("user");
      }

      if (checkUserAttachmentAllowed({ nodes, edges, id: currentNodeId })) {
        connectedNodes.push("user-attachment");
      }

      if (checkFallbackAllowed({ nodes, edges, id: currentNodeId })) {
        connectedNodes.push("fallback");
      }

      if (connectedNodes.includes("filter")) {
        currentValidNodes = currentValidNodes.filter((node) => node !== "user");
      }

      if (connectedNodes.includes("user")) {
        currentValidNodes = currentValidNodes.filter(
          (node) => node !== "filter"
        );
      }

      if (connectedNodes.includes("user-attachment")) {
        currentValidNodes = currentValidNodes.filter(
          (node) => node !== "filter"
        );
      }

      if (connectedNodes.includes("fallback")) {
        currentValidNodes = currentValidNodes.filter(
          (node) => node !== "fallback"
        );
      }
      return currentValidNodes;
    }
    case "user": {
      const resultNodes = currentValidNodes.filter(
        (node) => !multipleAddedNodes.includes(node)
      );
      return resultNodes;
    }
    case "filter": {
      const resultNodes = currentValidNodes.filter(
        (node) => !multipleAddedNodes.includes(node)
      );
      return resultNodes;
    }
    case "fallback": {
      const resultNodes = currentValidNodes.filter(
        (node) => !multipleAddedNodes.includes(node)
      );
      resultNodes.push("filter");
      return resultNodes;
    }
    default:
      return currentValidNodes;
  }
};

function getMatchingNodes(nodes, edges, id) {
  const checkFilterRef = edges.filter((edge) => edge?.source === id);
  const matchingNodes = checkFilterRef.reduce((acc, link) => {
    const matchingNode = nodes.find((node) => node.id === link.target);
    if (matchingNode) {
      acc.push(matchingNode);
    }

    return acc;
  }, []);

  return matchingNodes;
}

function checkFilterAllowed({ nodes, edges, id }) {
  const matchingNodes = getMatchingNodes(nodes, edges, id);

  const filterExists = matchingNodes.find((node) => node.type === "filter");
  return filterExists ? true : false;
}

function checkUserAllowed({ nodes, edges, id }) {
  const matchingNodes = getMatchingNodes(nodes, edges, id);

  const filterExists = matchingNodes.find((node) => node.type === "user");
  return filterExists ? true : false;
}

function checkFallbackAllowed({ nodes, edges, id }) {
  const matchingNodes = getMatchingNodes(nodes, edges, id);

  const filterExists = matchingNodes.find((node) => node.type === "fallback");
  return filterExists ? true : false;
}

function checkUserAttachmentAllowed({ nodes, edges, id }) {
  const matchingNodes = getMatchingNodes(nodes, edges, id);

  const filterExists = matchingNodes.find(
    (node) => node.type === "user-attachment"
  );
  return filterExists ? true : false;
}

// const allNodes = [
//   "bot",
//   "user",
//   // "user-attachment",
//   "question",
//   "filter",
//   "fallback",
//   "go-to-step",
//   "whatsapp-template",
//   "live-agent",
//   "product-catalog",
//   "email",
//   "sms",
//   "push-notification",
//   "delay",
//   "subscribe",
//   "unsubscribe",
//   "create-task",
//   "metal-rates",
//   "assign",
// ];
