import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useEffect } from "react";
import { TabPanel, a11yProps } from "../../../../../../helper";
import HorizontalStack from "../../../../../../helper/utility/HorizontalStack";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import {
  PrimaryLabelSmall,
  SecondaryLabel,
  SecondaryLabelSmall,
} from "../../../../../../helper/utility/Labels";
import { getOrderStatuses } from "../../../../../../redux/actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { NotFound } from "../../../../../../Svgs";
import { isArrayWithValues } from "jwero-javascript-utils";

function SMSTemplateAssign({ state, onDeleteTemplate, onSelectTemplatePress }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", mt: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Orders" {...a11yProps(0)} />
          <Tab label="Digigold" {...a11yProps(1)} />
          <Tab label="Authentication" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <OrdersAssignTemplates
          state={state}
          onDeleteTemplate={onDeleteTemplate}
          onSelectTemplatePress={onSelectTemplatePress}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DigigoldAssignTemplates
          state={state}
          onDeleteTemplate={onDeleteTemplate}
          onSelectTemplatePress={onSelectTemplatePress}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OTPAssignTemplates
          state={state}
          onDeleteTemplate={onDeleteTemplate}
          onSelectTemplatePress={onSelectTemplatePress}
        />
      </TabPanel>
    </Box>
  );
}

export default SMSTemplateAssign;

const OrdersAssignTemplates = ({
  state,
  onDeleteTemplate,
  onSelectTemplatePress,
}) => {
  const reduxOrderStatuses = useSelector((state) => state.orders.orderStatuses);
  const fetchedOrderStatuses = useSelector(
    (state) => state.orders.fetchedOrderStatuses
  );
  const fetchingOrderStatuses = useSelector(
    (state) => state.orders.fetchingOrderStatuses
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isArrayWithValues(reduxOrderStatuses)) dispatch(getOrderStatuses());
  }, []);

  return (
    <Box>
      {(fetchingOrderStatuses || !isArrayWithValues(reduxOrderStatuses)) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            {fetchingOrderStatuses ? <CircularProgress /> : null}
            {!fetchingOrderStatuses &&
            !isArrayWithValues(reduxOrderStatuses) ? (
              <>
                <NotFound height="300px" width="300px" />
                <SecondaryLabelSmall>No order status found</SecondaryLabelSmall>
              </>
            ) : null}
          </Box>
        </Box>
      )}
      {isArrayWithValues(reduxOrderStatuses) &&
        !fetchingOrderStatuses &&
        reduxOrderStatuses?.map((orderStatus) => {
          return (
            <SMSAssignView
              label={`Order ${orderStatus?.name}`}
              onDeleteTemplate={onDeleteTemplate}
              state={state}
              onSelectTemplatePress={onSelectTemplatePress}
              template_key={`order_${orderStatus?.slug}`}
            />
          );
        })}
      {/* <SMSAssignView
        label={"Order Confirmation"}
        onDeleteTemplate={onDeleteTemplate}
        state={state}
        onSelectTemplatePress={onSelectTemplatePress}
        template_key={"order_confirmation"}
      />
      <SMSAssignView
        label={"Order placed"}
        onDeleteTemplate={onDeleteTemplate}
        state={state}
        onSelectTemplatePress={onSelectTemplatePress}
        template_key={"order_placed"}
      />
      <SMSAssignView
        label={"Order feedback"}
        onDeleteTemplate={onDeleteTemplate}
        state={state}
        onSelectTemplatePress={onSelectTemplatePress}
        template_key={"order_feedback"}
      />
      <SMSAssignView
        label={"Order shipped"}
        onDeleteTemplate={onDeleteTemplate}
        state={state}
        onSelectTemplatePress={onSelectTemplatePress}
        template_key={"order_shipped"}
      />
      <SMSAssignView
        label={"Order delivered"}
        onDeleteTemplate={onDeleteTemplate}
        state={state}
        onSelectTemplatePress={onSelectTemplatePress}
        template_key={"order_delivered"}
      />
      <SMSAssignView
        label={"Order cancelled"}
        onDeleteTemplate={onDeleteTemplate}
        state={state}
        onSelectTemplatePress={onSelectTemplatePress}
        template_key={"order_cancelled"}
      /> */}
    </Box>
  );
};
const DigigoldAssignTemplates = ({
  state,
  onDeleteTemplate,
  onSelectTemplatePress,
}) => {
  return (
    <Box>
      <SMSAssignView
        label={"Digital Gold OTP Template"}
        onDeleteTemplate={onDeleteTemplate}
        state={state}
        onSelectTemplatePress={onSelectTemplatePress}
        template_key={"digital_gold_template"}
      />
    </Box>
  );
};
const OTPAssignTemplates = ({
  state,
  onDeleteTemplate,
  onSelectTemplatePress,
}) => {
  return (
    <Box>
      <SMSAssignView
        label={"OTP Template"}
        onDeleteTemplate={onDeleteTemplate}
        state={state}
        onSelectTemplatePress={onSelectTemplatePress}
        template_key={"otp_template"}
      />
    </Box>
  );
};

const SMSAssignView = ({
  label,
  state,
  onDeleteTemplate,
  onSelectTemplatePress,
  template_key,
}) => {
  return (
    <HorizontalStack justifyContent="space-between" sx={{ my: 3 }}>
      <PrimaryLabelSmall>{label} </PrimaryLabelSmall>
      <TemplateSelection
        template={state?.[template_key]}
        onDeleteTemplate={onDeleteTemplate}
        onSelectTemplatePress={onSelectTemplatePress}
        template_key={template_key}
      />
    </HorizontalStack>
  );
};

const TemplateSelection = ({
  template,
  onSelectTemplatePress,
  onDeleteTemplate,
  template_key,
}) => {
  return (
    <HorizontalStack spacing={0}>
      {template?.template_name ? (
        <>
          <Button
            variant="outlined"
            size="small"
            // onClick={() => onSelectTemplatePress("otp_template")}
            disabled
            sx={{ mr: 2 }}
          >
            {template?.template_name}
          </Button>
          <IconButton
            // onClick={() => onShowTemplate(v?.value)}
            onClick={() => onSelectTemplatePress(template_key)}
            size="small"
            color="primary"
          >
            <EditOutlined style={{ height: "17px", width: "17px" }} />
          </IconButton>
          <IconButton
            onClick={() => onDeleteTemplate(template_key)}
            size="small"
            color="error"
          >
            <DeleteOutline style={{ height: "17px", width: "17px" }} />
          </IconButton>
        </>
      ) : (
        <Button
          variant="outlined"
          size="small"
          onClick={() => onSelectTemplatePress(template_key)}
        >
          Select Template
        </Button>
      )}
    </HorizontalStack>
  );
};
