import {
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import FlowsList from "./components/flows_list";
import { getFlows } from "../../redux/actions/flowsActions";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import HorizontalStack from "../../helper/utility/HorizontalStack";
import { isArrayWithValues } from "jwero-javascript-utils";
import KeywordActions from "./components/flows_list/components/keyword_actions";
import FlowSettings from "./pages/settings";
import { fetchSingleSettings } from "../../redux/actions/settingActions";
import { EmptyState, getItem, setItem } from "../../helper";
import {
  FlowConfiguration,
  FlowsListSvg,
  FlowsSvg,
  KeywordsActionsSvg,
  NotFound,
} from "../../Svgs";
import { SecondaryLabelSmall } from "../../helper/utility/Labels";
import { StackRow } from "../components/helper";
import ButtonTopTabs from "../components/ButtonTopTabs";

const allTabs = [
  { label: "Workflows", value: "flows" },
  { label: "Keyword Actions", value: "keyword_actions" },
  { label: "Configuration", value: "settings" },
];

function Flows() {
  const navigate = useNavigate();
  const flowsList = useSelector((state) => state.flow?.flowsList);
  const flowsListLoading = useSelector((state) => state.flow?.flowsListLoading);
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState("flows");
  const keywordActionsRef = React.useRef();
  const [mount, setMount] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isArrayWithValues(flowsList)) dispatch(getFlows());
    dispatch(
      fetchSingleSettings({
        setting_name: "flow_settings",
        reduxKey: "flowSettings",
        loadingKey: "fetchingFlowKeywords",
      })
    );
  }, []);

  const icons = {
    flows: <FlowsListSvg />,
    keyword_actions: <KeywordsActionsSvg />,
    settings: <FlowConfiguration fill={theme.palette.primary.main} />,
  };

  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        // p: { sm: 2, md: 3 },
        pl: 0,
      }}
    >
      <HorizontalStack sx={{ justifyContent: "space-between" }}>
        {/* <Stack sx={{ px: 3 }}>
          <StackRow justifyContent={"space-between"}>
            <Typography
              variant="h3"
              sx={{ display: "flex", alignItems: "center", gap: 2 }}
            >
              <FlowsSvg color="action" /> Workflows
            </Typography>
          </StackRow>
          <Breadcrumbs aria-label="Breadcrumb" mt={1} px={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography variant="subtitle1" color={"text.secondary"}>
              Marketing Automation
            </Typography>
          </Breadcrumbs>
        </Stack> */}

        {/* <Box>
          <HorizontalStack gutterBottom spacing={3}>
            <Typography variant="h3" display="inline">
              Flows
            </Typography>
            <Box sx={{ border: "1px solid grey", borderRadius: 6, px: 3 }}>
              <SecondaryLabelSmall sx={{ color: "text.secondary" }}>
                Beta
              </SecondaryLabelSmall>
            </Box>
          </HorizontalStack>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Flows</Typography>
          </Breadcrumbs>
        </Box> */}
        {/* <Button
          variant="contained"
          color="primary"
          size={"medium"}
          startIcon={<Add />}
          sx={{ ml: 2 }}
          onClick={() => navigate("/flows/create")}
        >
          Create Flow
        </Button> */}
      </HorizontalStack>
      <ButtonTopTabs
        tabs={allTabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <Box sx={{ mt: 2 }}>
        {selectedTab === "flows" ? <FlowsList /> : null}
        {selectedTab === "keyword_actions" && (
          <KeywordActions ref={keywordActionsRef} />
        )}
        {selectedTab === "settings" && <FlowSettings ref={keywordActionsRef} />}
        {/* <Card>
          <CardContent sx={{ flex: 1 }}>
            <Grid container>
              <Grid
                item
                xs={1.8}
                sx={
                  isIpad
                    ? { display: "none" }
                    : { height: "calc(100vh - 150px)" }
                }
              >
                <Tabs
                  // sx={{ height: "100%" }}
                  orientation="vertical"
                  variant="scrollable"
                  value={selectedTab}
                  onChange={(e, newValue) => {
                    setSelectedTab(newValue);
                  }}
                  aria-label="Vertical tabs example"
                  sx={{
                    borderRight: 1,
                    borderColor: "divider",
                    height: "100%",
                  }}
                >
                  {allTabs.map((tab) => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                      icon={icons?.[tab?.value]}
                      iconPosition="start"
                      style={{ alignSelf: "flex-start", padding: "0px 16px" }}
                      sx={{ py: 0 }}
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid
                item
                // xs={isIpad ? 12 : 10.2}
                xs={12}
                // sx={{ height: "calc(100vh - 150px)" }}
                className="scrollbar-hidden"
                // sx={{ px: { md: 3, sm: 0.5 } }}
              >
                {selectedTab === "flows" ? <FlowsList /> : null}
                {selectedTab === "keyword_actions" && (
                  <KeywordActions ref={keywordActionsRef} />
                )}
                {selectedTab === "settings" && (
                  <FlowSettings ref={keywordActionsRef} />
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card> */}
      </Box>
    </Box>
  );
}

export default Flows;

const AllTabs = [
  { label: "Workflows", value: "flows" },
  { label: "Keyword Actions", value: "keyword_actions" },
  { label: "Configuration", value: "settings" },
];
const TopTabs = ({ selectedTab, setSelectedTab }) => {
  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          // backgroundColor: "Background",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "auto",
          whiteSpace: "nowrap",
        }}
        spacing={4}
      >
        {AllTabs?.length > 1 &&
          AllTabs?.map((i) => {
            return (
              <>
                <Box sx={{ position: "relative" }}>
                  <Button
                    key={i?.label}
                    variant={"text"}
                    sx={{
                      backgroundColor:
                        selectedTab === i.value ? "#e6e6e6" : "transparent",
                      color: "#000",

                      "&:hover": {
                        backgroundColor:
                          selectedTab === i.value ? "#e6e6e6" : "transparent",
                      },
                    }}
                    style={{ minWidth: "auto" }}
                    onClick={() => setSelectedTab(i.value)}
                  >
                    {i.label}
                  </Button>
                  {i?.badge && (
                    <Badge
                      sx={{
                        backgroundColor: "#ea2e2e",
                        borderRadius: "6px",
                        fontSize: "9px",
                        color: "#fff",
                        fontWeight: "bold",
                        padding: "2px 4px",
                        position: "absolute",
                        right: "-20px",
                        top: "-3px",
                        zIndex: 1,
                      }}
                    >
                      {i?.badge}
                    </Badge>
                  )}
                </Box>
              </>
            );
          })}
      </Stack>
    </Box>
  );
};
