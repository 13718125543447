import { Typography } from "@mui/material";
import React from "react";
import { formatWhatsappMessage, reduceLargeText } from "../../../../helper";

function LastMessageTextView({ message, type, seen, isDeleted }) {
  return (
    <div>
      {type === "image" ? (
        "Image"
      ) : type === "video" ? (
        "Video"
      ) : type === "document" ? (
        "Document"
      ) : type === "audio" ? (
        "Audio"
      ) : type === "story_mention" ? (
        "Story mention"
      ) : type === "share" ? (
        "Share"
      ) : (
        <Typography
          sx={{
            color: seen ? "#808080" : "#000",
            fontWeight: seen ? null : "700",
          }}
          noWrap
          dangerouslySetInnerHTML={{
            __html: formatWhatsappMessage(
              reduceLargeText(
                isDeleted
                  ? "This message has been unsent"
                  : typeof message === "string"
                  ? message
                      ?.replace(/\n/g, "")
                      ?.replace(/&lt;br&gt;/g, " ")
                      ?.replaceAll("<br>", " ")
                      ?.trim()
                  : "",
                20
              )
            ),
          }}
        />
      )}
    </div>
  );
}

export default LastMessageTextView;
