let productSubMedias = [
  "-1",
  "-2",
  "-3",
  "-4 ",
  "-5",
  "-6",
  "-7",
  "-8",
  "-9",
  "-01",
  "-02",
  "-03",
  "-04",
  "-05",
  "-06",
  "-07",
  "-08",
  "-09",
  "-a",
  "-b",
  "-c",
  "-d",
  "-e",
  "-f",
  "-g",
  "_1",
  "_2",
  "_3",
  "_4 ",
  "_5",
  "_6",
  "_7",
  "_8",
  "_9",
  "_01",
  "_02",
  "_03",
  "_04",
  "_05",
  "_06",
  "_07",
  "_08",
  "_09",
  "_a",
  "_b",
  "_c",
  "_d",
  "_e",
  "_f",
  "_g",
];

function trimProductSubMediaSuffix(inputString) {
  // Ensure that the "-4 " case with a space is correctly handled by trimming spaces from the input string.
  inputString = inputString.trim();

  for (let i = 0; i < productSubMedias.length; i++) {
    let suffix = productSubMedias[i];
    // Check if the input string ends with the current suffix.
    if (inputString.endsWith(suffix)) {
      // Trim the suffix and return the trimmed string.
      return inputString.substring(0, inputString.length - suffix.length);
    }
  }

  // Return the original string if no matching suffix is found.
  return inputString;
}

function rearrangeImages(images, sku) {
  // Convert SKU to lower case for case-insensitive comparison
  const lowerCaseSku = sku.toLowerCase();

  // Sort images by checking if the name includes the SKU,
  // and then by name alphabetically
  images.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    const includesSkuA = nameA.includes(lowerCaseSku);
    const includesSkuB = nameB.includes(lowerCaseSku);

    // If both or neither image name includes the SKU, sort alphabetically
    if (includesSkuA === includesSkuB) {
      return nameA.localeCompare(nameB);
    }

    // If only one image name includes the SKU, it comes first
    return includesSkuA ? -1 : 1;
  });

  return images;
}

export { productSubMedias, trimProductSubMediaSuffix, rearrangeImages };
