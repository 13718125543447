import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { useState } from "react";
import {
  getAxiosError,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
} from "../../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { googleScopes } from "./Index";
import axios from "axios";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { LoadingButton } from "@mui/lab";
import { googleIntegrationsByProduct, revokeGoogle } from "../../../../App";
import {
  Firebase,
  Google,
  GoogleAnalytics,
  GoogleBusiness,
  GoogleCalendar,
  GoogleSheet,
  GoogleShop,
  GoogleSite,
  GoogleTagManager,
} from "../../../../Svgs";
import { setGlobalToast } from "../../../../redux/actions/helperActions";

const GoogleIntegrations = ({ authUrl, client_id, redirect_url }) => {
  const [googleIntegartion, setGoogleIntegartion] = useState({});
  const [integratedApp, setIntegartedApp] = useState([]);
  const [scope, setScope] = useState("");
  const [swtichControl, setSwtichControl] = useState([]);
  const [revoking, setRevoking] = useState(false);
  const [filterIntegration, setFilterIntegration] = useState([]);

  const theme = useTheme();
  const dispatch = useDispatch();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  const reduxGoogleIntegration = useSelector(
    (state) => state.settings.googleIntegration
  );
  const fetchingAllSettings = useSelector(
    (state) => state.settings.fetchingAllSettings
  );

  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );

  useEffect(() => {
    if (isArrayWithValues(user_products)) {
      let array = [];
      for (let product of user_products) {
        if (googleIntegrationsByProduct[product])
          array = array.concat(googleIntegrationsByProduct[product]);
      }
      // if (user_products.includes("catalog"))
      //   array = array.concat([
      //     "google_sheets",
      //     "google_analytics",
      //     "tag_manager",
      //   ]);
      // if (user_products.includes("chats"))
      //   array = array.concat(["google_calendar", "tag_manager"]);
      setFilterIntegration([...new Set(array)]);
    } else setFilterIntegration([]);
  }, [user_products]);

  // useEffect(() => {
  //     if (!isObjWithValues(reduxGoogleIntegration)) dispatch(getGoogleIntegration())
  // }, [])

  const getEmailOfApp = (app) => {
    for (const key in googleIntegartion) {
      if (googleIntegartion?.[key]?.connected?.includes(app)) {
        return googleIntegartion?.[key]?.info?.email;
      }
    }
  };

  useEffect(() => {
    if (isObjWithValues(reduxGoogleIntegration))
      setGoogleIntegartion(reduxGoogleIntegration);
    if (!isObjWithValues(reduxGoogleIntegration)) {
      setIntegartedApp([]);
      return;
    }
    let integratedApp = [];
    for (const key in reduxGoogleIntegration) {
      let app = reduxGoogleIntegration[key].connected;
      integratedApp = [...integratedApp, ...app];
    }
    setIntegartedApp(integratedApp);
  }, [reduxGoogleIntegration]);

  const googlePress = () => {
    if (!isArrayWithValues(scope)) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Please select channel!",
          severity: "error",
        })
      );
      return;
    }
    console.log(
      `${authUrl}?client_id=${client_id}&redirect_uri=${redirect_url}&response_type=code&scope=${scope.join(
        " "
      )}&state=${swtichControl.join()}&access_type=offline`
    );
    // return;
    // https://accounts.google.com/o/oauth2/v2/auth?client_id=448848454597-rbba0c3q0kbcn0f8l893813l98rgra7p.apps.googleusercontent.com&redirect_uri=http://localhost:3000/integrations&response_type=code&scope=https://www.googleapis.com/auth/spreadsheets
    window.open(
      `${authUrl}?client_id=${client_id}&redirect_uri=${redirect_url}&response_type=code&scope=${scope.join(
        " "
      )}&state=${swtichControl.join()}&access_type=offline`,
      "_self"
    );
  };

  const onRevokeGoogle = async (app) => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setRevoking(true);

    let access_token = "";
    let refresh_token = "";
    let email = "";
    for (const key in googleIntegartion) {
      if (googleIntegartion[key].connected.includes(app)) {
        access_token = googleIntegartion[key].access_token;
        refresh_token = googleIntegartion[key].refresh_token;
        email = googleIntegartion?.[key]?.info?.email;
      }
    }
    let keys = [];
    for (const key in googleIntegartion) {
      if (googleIntegartion[key]?.info?.email === email) keys.push(key);
    }
    console.log(access_token, refresh_token);
    try {
      const revokeRes = await revokeGoogle(access_token, refresh_token);
      console.log(revokeRes);
      for await (const id of keys) {
        let integrationsRes = await axios({
          url: `${website}/wp-json/store/v1/settings/google_integration`,
          method: "DELETE",
          headers: { Authorization: `Basic ${token}` },
          data: { id: id },
        });
        if (integrationsRes.data.success) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              googleIntegration: integrationsRes.data.data,
              // fetchingMetaIntegration: false,
            },
          });
        }
      }
    } catch (error) {
      error = getAxiosError(error);
      if (error?.error === "invalid_token") {
        try {
          for await (const id of keys) {
            let integrationsRes = await axios({
              url: `${website}/wp-json/store/v1/settings/google_integration`,
              method: "DELETE",
              headers: { Authorization: `Basic ${token}` },
              data: { id: id },
            });
            console.log(integrationsRes);
            if (integrationsRes.data.success) {
              dispatch({
                type: EDIT_SETTINGS,
                payload: {
                  googleIntegration: integrationsRes.data.data,
                  // fetchingMetaIntegration: false,
                },
              });
            }
          }
        } catch (error) {
          console.log(getAxiosError(error));
        }
      }
    }

    setRevoking(false);
  };
  const onSwitchChange = (e, val) => {
    if (e) {
      setSwtichControl((pre) => [...pre, val]);
      setScope((pre) => [...pre, googleScopes[val]]);
    } else {
      setSwtichControl((pre) => pre.filter((i) => i !== val));
      setScope((pre) => pre.filter((i) => i !== googleScopes[val]));
    }
  };
  return (
    <Box sx={{ padding: 0 }} className="Google-integrations-list">
      {allAppsData
        .filter((i) =>
          isArrayWithValues(user_products)
            ? filterIntegration.includes(i.value)
            : true
        )
        .map((obj) => (
          <>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
              sx={{ gap: "30px" }}
            >
              <Stack direction="row" sx={{ gap: "20px" }} alignItems="center">
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  {obj?.icon && obj?.icon} {obj.label}
                </Typography>
                <Switch
                  onChange={(e) => {
                    onSwitchChange(e, obj.value);
                  }}
                  checked={
                    swtichControl.includes(obj.value) ||
                    integratedApp.includes(obj.value)
                  }
                  disabled={
                    integratedApp.includes(obj.value) || fetchingAllSettings
                  }
                  style={
                    swtichControl.includes(obj.value)
                      ? {
                          border: `1px solid ${backgroundColor}`,
                          backgroundColor,
                        }
                      : {}
                  }
                />
              </Stack>
              {integratedApp.includes(obj.value) && (
                <LoadingButton
                  loading={revoking}
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => onRevokeGoogle(obj.value)}
                >
                  Revoke
                </LoadingButton>
              )}
            </Stack>
            {integratedApp.includes(obj.value) && getEmailOfApp(obj.value) ? (
              <Typography variant="subtitle2">
                {getEmailOfApp(obj.value)}
              </Typography>
            ) : null}
            <Typography sx={{ marginBottom: "20px" }}>
              {obj.description}
            </Typography>
            <Divider />
            <Box sx={{ height: "15px" }}></Box>
          </>
        ))}

      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        sx={{
          position: "sticky",
          bottom: "12px",
          zIndex: 20,
          // backgroundColor: "#fff",
        }}
      >
        <Button
          startIcon={<Google />}
          onClick={googlePress}
          sx={{
            padding: "12px 16px",
            borderRadius: "29px",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            backgroundColor: "#fff",
            ":hover": {
              backgroundColor: "#fff",
            },
          }}
          className={
            isArrayWithValues(scope)
              ? "Google-integrations-button btn--jump"
              : "Google-integrations-button"
          }
        >
          Continue with Google
        </Button>
      </Stack>
    </Box>
  );
};

export default GoogleIntegrations;

const allAppsData = [
  {
    label: "Google Business",
    value: "google_business",
    icon: <GoogleBusiness height={"32px"} width={"32px"} />,
    description:
      "Jewellers can integrate th eir Google Business Account to connect to your customers on the web for improved digital presence 🔧.",
  },
  {
    label: "Tag Manager",
    value: "tag_manager",
    icon: <GoogleTagManager height={"32px"} width={"32px"} />,
    description:
      "Google Tool that enables you to install and manage Jewellery marketing tags without changing the source code of your website or mobile application 🧰",
  },
  {
    label: "Google Sheets",
    value: "google_sheets",
    icon: <GoogleSheet height={"32px"} width={"32px"} />,
    description:
      "Manage your Jewellery Products Inventory from google’s web base spreadsheet tool 📄",
  },
  {
    label: "Google Shopping",
    value: "google_shopping",
    icon: <GoogleShop height={"32px"} width={"32px"} />,
    description:
      "Show off the amazing collection of your products globally with the help of Google Shopping Ads 🔮",
  },
  {
    label: "Google Calendar",
    value: "google_calendar",
    icon: <GoogleCalendar height={"32px"} width={"32px"} />,
    description:
      "With Google Calendar, you can quickly schedule meetings and events and get reminders about upcoming activities, so you always know what's next.",
  },
  {
    label: "Analytics",
    value: "google_analytics",
    icon: <GoogleAnalytics height={"32px"} width={"32px"} />,
    description:
      "Jewellers can track and analyze their website traffic to understand where their customers are coming from with detailed analytics 📍",
  },
  // {
  //   label: "Firebase",
  //   value: "firebase",
  //   icon: <Firebase height={"32px"} width={"32px"} />,
  //   description:
  //     "Utilizing built-in third-party authentication providers, such as Google, Facebook, and Twitter is quite easy with Firebase 🔍",
  // },
  // {
  //   label: "Verify Site",
  //   value: "google_siteverification",
  //   icon: <GoogleSite height={"32px"} width={"32px"} />,
  //   description:
  //     "Google site verification aims to establish your ownership of the website. For this, you must finish the Google Search Console authentication procedure before managing your websites with Google tools.🔑",
  // },
];
