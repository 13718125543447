import React from "react";
import { StackRow, setItem } from "../../../helper";
import { Breadcrumbs, Button, Link, Stack, Typography } from "@mui/material";
import { FlowsSvg } from "../../../Svgs";
import { NavLink } from "react-router-dom";
import { OpenInNew } from "@mui/icons-material";
import HorizontalStack from "../../../helper/utility/HorizontalStack";
import { setPersistData } from "../../../redux/actions/persistActions";
import { useDispatch } from "react-redux";

function Header() {
  const dispatch = useDispatch();
  const onSwitchToOld = () => {
    dispatch(
      setPersistData({
        marketing_automation_view: "old",
      })
    );
    setItem({
      marketing_automation_view: "old",
    });
  };

  return (
    <Stack sx={{ px: 3 }}>
      <HorizontalStack spacing={3}>
        <Typography
          variant="h3"
          sx={{ display: "flex", alignItems: "center", gap: 2 }}
        >
          <FlowsSvg color="action" /> Marketing Automation (Beta)
        </Typography>
        {/* <Button
          variant="outlined"
          endIcon={<OpenInNew />}
          size="small"
          onClick={onSwitchToOld}
          sx={{ mt: 1.5 }}
        >
          Switch to Old
        </Button> */}
      </HorizontalStack>
      <Breadcrumbs aria-label="Breadcrumb" mt={1} px={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        {/* {breadcrumbs?.map((obj) => {
          if (obj?.link)
            return (
              <Link component={NavLink} to={obj?.link}>
                {obj?.title}
              </Link>
            );
          return <Typography>{obj.title}</Typography>;
        })} */}
        <Typography variant="subtitle1" color={"text.secondary"}>
          Marketing Automation
        </Typography>
      </Breadcrumbs>
    </Stack>
  );
}

export default Header;
