import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import {
  PrimaryLabel,
  PrimaryLabelSmall,
} from "../../../../../helper/utility/Labels";
import Switch from "rc-switch";
import { ADD_PLAN_TOOLTIPS } from "../../../constants";
import CustomTooltip from "../../../../flows/pages/addFlow/components/CustomTooltip";
import { InfoOutlined } from "@mui/icons-material";
import HorizontalStack from "../../../../../helper/utility/HorizontalStack";

function PlanSettings({ form = {}, editForm }) {
  const theme = useTheme();
  const backgroundColor = theme.palette.primary.main;
  return (
    <Box>
      <PrimaryLabel>Basic settings</PrimaryLabel>

      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        mt={4}
      >
        <Typography variant="h6" sx={{ fontSize: "14px" }}>
          Allow admin to update gold rates?
        </Typography>
        <Switch
          onChange={(e) => {
            editForm({ change_gold_rate: e });
          }}
          checked={form?.change_gold_rate}
          style={{
            border: `1px solid ${
              form?.change_gold_rate ? backgroundColor : "#e8e8e8"
            }`,
            backgroundColor: form?.change_gold_rate
              ? backgroundColor
              : "#e8e8e8",
          }}
        />
      </Stack>
      <Typography sx={{ fontSize: "12px", color: "secondary.main" }}>
        While adding any manual payment entry for any customer, allow admin to
        edit the gold rates based on the payment date
      </Typography>
      <PrimaryLabel sx={{ mt: 6 }}>Frontend settings</PrimaryLabel>

      <FormGroup sx={{ mt: 2, display: "flex" }}>
        <FormControlLabel
          checked={form?.amount_on_grams}
          onChange={(e) => editForm({ amount_on_grams: e.target.checked })}
          control={<Checkbox />}
          label={
            <HorizontalStack spacing={2}>
              <PrimaryLabelSmall>
                {"Show accumulated amount to customers"}
              </PrimaryLabelSmall>
              <CustomTooltip
                title={ADD_PLAN_TOOLTIPS.settings_show_accumulated_amount}
              >
                <InfoOutlined style={{ height: "14px", width: "14px" }} />
              </CustomTooltip>
            </HorizontalStack>
          }
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          checked={form?.grams_on_amount}
          onChange={(e) => editForm({ grams_on_amount: e.target.checked })}
          control={<Checkbox />}
          label={
            <HorizontalStack spacing={2}>
              <PrimaryLabelSmall>
                {"Show accumulated gold weight to customers"}
              </PrimaryLabelSmall>
              <CustomTooltip
                title={ADD_PLAN_TOOLTIPS.settings_show_accumulated_weight}
              >
                <InfoOutlined style={{ height: "14px", width: "14px" }} />
              </CustomTooltip>
            </HorizontalStack>
          }
        />
      </FormGroup>
    </Box>
  );
}

export default PlanSettings;
