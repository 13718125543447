import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  Snackbar,
  Slide,
  Typography,
  Select,
  ListSubheader,
  MenuItem,
  Grid,
  Collapse,
} from "@mui/material";
import { spacing } from "@mui/system";

import firebase from "../../firebase";

import useAuth from "../../hooks/useAuth";
import axios from "axios";
import {
  Base64,
  backendApiCall,
  dialCodes,
  dialCodesObj,
  getAxiosError,
  getToken,
  getWebsite,
  isDev,
} from "../../helper";
import { commonDialCodes } from "../../pages/crm/Add";
import { Mixpanel } from "../../mixpanel";
import { useSelector } from "react-redux";
import { getIp } from "../../App";
const auth = firebase.auth();

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn(props) {
  let { onForgotPasswordPress } = props;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { signIn } = useAuth();
  const [toast, setToast] = useState({ show: false });
  const [showPassword, setShowPassword] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [form, setForm] = useState({
    phone: "",
    otp: "",
    dialcode: "India 🇮🇳 (+91)",
  });
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const [isOtpSignin, setIsOtpSignin] = useState(false);
  const [isOtpSent, setisOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const editForm = (payload) => {
    if (toast.show) setToast({ show: false });
    setForm((state) => ({ ...state, ...payload }));
  };
  useEffect(() => {
    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //   "recaptcha-container",
    //   {
    //     size: "invisible",
    //     callback: function (response) {
    //       console.log("Captcha Resolved");
    //     },
    //     defaultCountry: "IN",
    //   }
    // );
  }, []);

  useEffect(() => {
    // if (state.email) {
    //   setToast({
    //     show: true,
    //     message: "Password updated successfully!",
    //     severity: "success",
    //   });
    //   delete state.email;
    // }
  }, []);

  const onEmailChange = (e, a) => {
    console.log(e, a);
  };

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    // return;
    try {
      let res = await signIn(values.email, values.password);
      console.log(res, "login res");
      // if (isDev()) {
      //   setSubmitting(false);
      //   return;
      // }
      if (res?.unauthenticated) {
        setStatus({ success: false });
        setErrors({ submit: res.message });
        setSubmitting(false);
      } else {
        if (res === "UNREGISTERED") {
          setTimeout(() => {
            navigate("/auth/register");
          }, 600);
        } else if (res === "UNMIGRATED") {
          setTimeout(() => {
            navigate("/migration");
          }, 600);
        } else if (res === "DISABLED") {
          setStatus({ success: false });
          setErrors({
            submit:
              "Account is temporary disabled! Kindly contact our customer support at care@jwero.ai",
            // "Account is temporary disabled! please contact our Customer care",
          });
          setSubmitting(false);
        } else {
          return navigate("/dashboard");
        }
      }
    } catch (error) {
      error = getAxiosError(error) || error;
      console.log(error);
      let ip = await getIp();
      Mixpanel.track("login_unsuccessful", {
        code: error?.code,
        message: error?.message,
        ip,
      });

      // let message = error.message || "Something went wrong";
      let message = "";
      if (error && (error.code === "incorrect_password" || error.code == "401"))
        message = "Invalid Credentials";

      if (error?.code === "invalid_email")
        message = "Account with this email does not exists.";
      setStatus({ success: false });
      setErrors({ submit: message || "Internal Server Error" });
      setSubmitting(false);
    }
  };

  const sendOTP = async (e) => {
    setLoading(true);
    let dialCode = dialCodes?.find(
      (item) => item?.label === form.dialcode
    )?.value;
    try {
      let res = await backendApiCall({
        endpoint: "sms/otp/generate",
        method: "POST",
        data: {
          to: `${dialCode || ""}${form?.phone || ""}`,
        },
      });

      console.log(res);

      if (res?.success) {
        setToast({
          show: true,
          message: "OTP sent to your mobile number",
          severity: "success",
        });
        setisOtpSent(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const verifyOTP = async (e) => {
    setLoading(true);
    let dialCode = dialCodes?.find(
      (item) => item?.label === form.dialcode
    )?.value;
    try {
      let res = await backendApiCall({
        endpoint: "sms/otp/verify",
        method: "POST",
        data: {
          to: `${dialCode || ""}${form?.phone || ""}`,
          otp: form.otp,
        },
      });

      console.log(res);

      if (res?.success) {
        setToast({
          show: true,
          message: "OTP verified successfully",
          severity: "success",
        });
        setIsOtpVerified(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
    // e.preventDefault();
    // setLoading(true);
    // try {
    //   let otpRes = await window.confirmationResult.confirm(form.otp);
    //   console.log(otpRes);
    //   setToast({ show: true, message: "OTP Verified", severity: "success" });
    // } catch (error) {
    //   console.log(getAxiosError(error), error.code);
    //   if (error?.code == "auth/code-expired")
    //     setToast({ show: true, message: "Code expired", severity: "error" });
    //   if (error?.code == "auth/invalid-verification-code")
    //     setToast({ show: true, message: "Incorrect code", severity: "error" });
    // }
    // setLoading(false);
  };

  return (
    <>
      {/* <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast({ ...toast, show: false })}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar> */}
      <Collapse in={toast.show}>
        <Alert mt={2} mb={0} severity={toast.severity || "warning"}>
          {toast.message}
        </Alert>
      </Collapse>

      {isOtpSignin ? (
        <form noValidate>
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={4} lg={5}>
              <Typography mt={6} ml={2} variant="h6">
                Phone
              </Typography>
            </Grid>
            <Grid item xs={4} lg={5}>
              <Button
                onClick={() => {
                  setisOtpSent(false);
                  setIsOtpSignin(!isOtpSignin);
                }}
                color="secondary"
                sx={4}
              >
                Use email instead
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={4} lg={3}>
              <Select
                fullWidth
                // error={hasError?.dialcode_mobile}
                onChange={(e) => {
                  // setHasError((pre) => ({ ...pre, dialcode_mobile: false }));

                  editForm({
                    dialcode: e.target.value,
                  });
                }}
                value={form.dialcode || ""}
                MenuProps={{ sx: { maxHeight: "400px" } }}
                // renderValue={(value) => `+${value.value}`}
                renderValue={(e) => `+${dialCodesObj[e]}`}
              >
                <ListSubheader>Common Dial Codes</ListSubheader>
                {commonDialCodes.map((i) => {
                  return (
                    <MenuItem key={i.value} value={i.label}>
                      {i.label}
                    </MenuItem>
                  );
                })}
                <ListSubheader>Dial Codes</ListSubheader>
                {dialCodes.map((obj) => (
                  <MenuItem key={obj.value} value={obj.label}>
                    {obj.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={8} lg={9}>
              <TextField
                type="number"
                name="number"
                // label="Email Address"
                placeholder="for e.g 9876543210"
                value={form.phone}
                // error={Boolean(touched.phone && errors.phone)}
                fullWidth
                // helperText={touched.phone && errors.phone}
                // onBlur={handleBlur}
                onChange={(e) => editForm({ phone: e.target.value })}
              />
            </Grid>
          </Grid>

          {isOtpSent && (
            <>
              <Typography mt={6} ml={2} variant="h6">
                OTP
              </Typography>
              <TextField
                // type={showPassword ? "text" : "password"}
                name="otp"
                // label="otp"
                placeholder="*** ***"
                value={form.otp}
                // error={Boolean(touched.otp && errors.otp)}
                fullWidth
                // helperText={touched.otp && errors.otp}
                // onBlur={handleBlur}
                onChange={(e) => editForm({ otp: e.target.value })}
                my={2}
              />
            </>
          )}
          <Button
            // type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading || isOtpVerified}
            sx={{ mt: 2 }}
            onClick={isOtpSent ? verifyOTP : sendOTP}
          >
            {isOtpSent ? "Verify OTP" : "Send OTP"}
          </Button>
        </form>
      ) : (
        <Formik
          initialValues={{
            // email: "demo@bootlab.io",
            // password: "unsafepassword",

            // email: "tiarabytj@gmail.com",
            // password: "Tiarabytj12#$",
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            password: Yup.string().max(255).required("Password is required"),
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {/* <Alert mt={3} mb={3} severity="info">
    Use <strong>demo@bootlab.io</strong> and{" "}
    <strong>unsafepassword</strong> to sign in
  </Alert> */}
              {errors.submit && (
                <Alert mt={2} mb={3} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                <Grid item xs={4} lg={5}>
                  <Typography mt={6} ml={2} variant="h6">
                    Email
                  </Typography>
                </Grid>
                {/* <Grid item xs={4} lg={5}>
                  <Button
                    onClick={() => setIsOtpSignin(!isOtpSignin)}
                    color="secondary"
                    sx={4}
                  >
                    Use OTP instead
                  </Button>
                </Grid> */}
              </Grid>
              {/* <Typography mt={6} ml={2} variant="h6">
                Email
              </Typography> */}
              <TextField
                type="email"
                name="email"
                // label="Email Address"
                placeholder="example@gmail.com"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
              />
              <Typography mt={6} ml={2} variant="h6">
                Password
              </Typography>
              <TextField
                type={showPassword ? "text" : "password"}
                name="password"
                // label="Password"
                placeholder="**************"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
              />
              <FormControlLabel
                sx={{ mt: 1, mb: 2, marginLeft: "0px", alignItems: "center" }}
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    sx={{ mr: 1 }}
                    checked={showPassword}
                    onChange={(e, value) => setShowPassword(value)}
                  />
                }
                label="Show Password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                sx={{ mt: 2 }}
              >
                Sign in
              </Button>
              {/* <Button
                // component={Link}
                // to="/auth/reset-password"
                onClick={onForgotPasswordPress}
                fullWidth
                color="primary"
                sx={{ marginTop: 2 }}
              >
                Forgot password
              </Button> */}
            </form>
          )}
        </Formik>
      )}
      {/* <Button
              component={Link}
              to="/auth/reset-password"
              fullWidth
              color="primary"
            >
              Forgot password
            </Button> */}
    </>
  );
}

export default SignIn;
