import { Upload, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardContent,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getToken,
  getWebsite,
  isDev,
  isObjWithValues,
} from "../../../../helper";
import axios from "axios";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { useTheme } from "styled-components";
import Switch from "rc-switch";
import ProductMediaLibrary from "../../../components/ProductMediaLibrary";

function Settings() {
  const theme = useTheme();
  const dispatch = useDispatch();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  const [formData, setFormData] = useState({
    points_logo: "",
    points_label: "",
  });
  const [loading, setLoading] = useState(false);
  const [toggleMediaLibrary, setToggleMediaLibrary] = useState(false);

  const mloyalIntegration = useSelector(
    (state) => state.settings.mloyalIntegration
  );
  const globalToast = useSelector((state) => state.helper.globalToast);
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isObjWithValues(mloyalIntegration))
      onFormEdit({ ...mloyalIntegration });
  }, [mloyalIntegration]);

  const onFormEdit = (payload) => {
    setFormData((pre) => ({ ...pre, ...payload }));
  };

  const onUpdateForm = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/mloyal_integration`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "mloyal_integration",
        data: {
          ...(formData || {}),
        },
      },
    });
    if (res.data.success) {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Settings updated successfully",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          mloyalIntegration: res.data.data,
        },
      });
    } else {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Settings Failed! Please try again.",
          severity: "error",
        })
      );
    }
    setLoading(false);
  };

  const compareAspectRatios = (
    baseWidth,
    baseHeight,
    otherWidth,
    otherHeight
  ) => {
    const calculateAspectRatio = (width, height) => {
      return width / height;
    };

    const baseRatio = calculateAspectRatio(baseWidth, baseHeight);
    const otherRatio = calculateAspectRatio(otherWidth, otherHeight);
    // Round the aspect ratios to 2 decimal places for comparison
    return baseRatio.toFixed(2) === otherRatio.toFixed(2);
  };

  const setLogo = (ar, id) => {
    const expectedAspect = { height: 100, width: 100 };
    const isValid = compareAspectRatios(
      expectedAspect?.width,
      expectedAspect?.height,
      ar[0]?.media_details?.width,
      ar[0]?.media_details?.height
    );

    // if (!isValid) {
    //   dispatch(
    //     setGlobalToast({
    //       show: true,
    //       message: "Dimensions doesn't match, it should be 500x500",
    //       severity: "error",
    //     })
    //   );
    //   return;
    // }

    console.log("ar[0]?.source_url", ar[0]?.source_url);

    if (ar[0]?.source_url) {
      onFormEdit({ points_logo: ar[0]?.source_url });
    }

    setToggleMediaLibrary(false);
  };

  return (
    <Box sx={{ paddingLeft: "12px", paddingBottom: "25px" }}>
      <Stack spacing={7} sx={{ width: isPhone ? "100%" : "70%" }}>
        <Stack spacing={7} sx={{ width: isPhone ? "100%" : "70%" }}>
          <Card
            onClick={() => setToggleMediaLibrary(true)}
            sx={{
              cursor: "pointer",
              backgroundColor: "rgba(0,0,0,0.1)",
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.1)",
              },
            }}
          >
            <CardContent
              //   fullWidth
              id="outlined"
              style={{
                // borderWidth: 2,
                // borderStyle: "dashed",
                // borderColor: "#bababa",
                alignItems: "center",
                display: "flex",
              }}
              hoverTitle=""
              sx={{ position: "relative" }}
            >
              <Box
                sx={{
                  height: "120px",
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  // backgroundColor: "primary.main",
                  borderRadius: "50%",
                }}
              >
                {formData?.points_logo ? (
                  <img
                    // src={obj?.bg_image_mobile[0]}
                    src={formData?.points_logo}
                    style={{
                      maxHeight: "160px",
                      width: "100%",
                      objectFit: "contain",
                      textAlign: "center",
                      borderRadius: "8px",
                    }}
                  />
                ) : (
                  <Stack justifyContent={"center"} alignItems="center">
                    {/* <Typography>1440px X 600px</Typography> */}
                    <Upload style={{}} />
                    <Typography>JPEG, PNG, WEBP</Typography>
                    <Typography style={{ fontSize: 12 }}>under 5MB</Typography>
                    <Typography
                      style={{
                        fontSize: 8,
                        // position: "absolute",
                        left: 2,
                        bottom: 2,
                      }}
                    >
                      GIF/Videos not supported
                    </Typography>
                  </Stack>
                )}
              </Box>
            </CardContent>
          </Card>
        </Stack>
        <Stack>
          <FormLabel variant="h6">Currency Label</FormLabel>
          <TextField
            type={"text"}
            value={formData.points_label}
            onChange={(e) => onFormEdit({ points_label: e.target.value })}
            size="small"
            placeholder=""
            helperText=""
          />
        </Stack>
        <LoadingButton
          loading={loading}
          variant={"contained"}
          onClick={onUpdateForm}
          sx={{ mr: 4 }}
        >
          Update
        </LoadingButton>
      </Stack>
      <ProductMediaLibrary
        open={toggleMediaLibrary}
        onChange={() => setToggleMediaLibrary(!toggleMediaLibrary)}
        setParentImages={setLogo}
        multiple={false}
        aspect={{ width: 100, height: 100 }}
        // selectedImages={images}
        // enable_crop={false}
      />
    </Box>
  );
}

export default Settings;
