import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Popover,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import AttributePopover from "./AttributesPopover";
import DataObjectIcon from "@mui/icons-material/DataObject";
import { getRandomString } from "jwero-javascript-utils";

function countPlaceholders(text) {
  const placeholderRegex = /\{\{(\d+)\}\}/g;
  const matches = text.match(placeholderRegex);
  return matches ? matches.length : 0;
}

const TextComponent = ({
  currentTemplateState,
  setCurrentTemplateState,
  component,
  index,
  titleAttributesCount,
  setTitleAttributesCount,
  titleAttributes,
  setTitleAttributes,
  titleAttributesRef,
  titleAnchorEl,
  setTitleAnchorEl,
}) => {
  const globalAttributes = useSelector((state) => state.flow?.globalAttributes);
  const open = Boolean(titleAnchorEl);
  const id = open ? "simple-popover" : undefined;
  const options = globalAttributes;
  const [currentText, setCurrentText] = useState(component.text);
  const [attributeTitleAnchorEl, setAttributeTitleAnchorEl] = useState({
    id: null,
    openAnchor: null,
  });

  const handleTitleAttributePopover = (e, index) => {
    e.stopPropagation();
    setAttributeTitleAnchorEl({
      id: index,
      openAnchor: e.currentTarget,
    });
  };

  // function setDynamicValues(template, dynamicValues) {
  //   let result = template;

  //   if (Array.isArray(dynamicValues)) {
  //     dynamicValues.forEach((value, index) => {
  //       const placeholder = new RegExp(`{{${index + 1}}}`, "g");
  //       result = result.replace(placeholder, value);
  //     });
  //   } else if (typeof dynamicValues === "string") {
  //     const placeholder = "{{1}}"; // for a single string value
  //     result = result.replace(placeholder, dynamicValues);
  //   }

  //   return result;
  // }

  function replaceAtIndex(currentText, index, value) {
    let regex = /\{\{[^}]*\}\}/g;

    let matches = currentText.match(regex);

    if (matches && index < matches.length) {
      let matchIndex = currentText.indexOf(matches[index]);
      currentText =
        currentText.substring(0, matchIndex) +
        `{{${value}}}` +
        currentText.substring(matchIndex + matches[index].length);
    }
    return currentText;
  }

  const handleAttributesTitle = (idx, value) => {
    let updatedOptions;
    setTitleAttributes((prev) => {
      updatedOptions = {
        ...prev,
        [idx]: value,
      };
      const updatedText = replaceAtIndex(currentText, idx, value);
      setCurrentText(updatedText);
      const updatedComponents = [...currentTemplateState.components];
      updatedComponents[index] = {
        ...updatedComponents[index],
        selected_attributes: updatedOptions,
        updated_text: updatedText,
      };

      setCurrentTemplateState((prevData) => ({
        ...prevData,
        components: updatedComponents,
      }));
      return updatedOptions;
    });
    setAttributeTitleAnchorEl(null);
  };

  // const handleVariables = (e, value) => {
  //   const updatedText = setDynamicValues(currentText, value);
  //   setCurrentText(updatedText);
  //   setOptionSelected(value);
  //   const updatedComponents = [...currentTemplatePreview.components];
  //   updatedComponents[index] = {
  //     ...updatedComponents[index],
  //     variables_value: value,
  //   };

  //   setCurrentTemplateState((prev) => ({
  //     ...prev,
  //     components: updatedComponents,
  //   }));
  //   setAttributeAnchorEl(null);
  // };

  const handleClick = (event) => {
    event.stopPropagation();

    if (!options) {
      return;
    }
    setTitleAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setTitleAnchorEl(null);
  };

  // useEffect(() => {
  //   if (component?.variables_value) {
  //     setOptionSelected(component?.variables_value);
  //   }
  // }, [component?.variables_value]);

  useEffect(() => {
    const componentBody = currentTemplateState?.components[index];

    if (componentBody?.selected_attributes) {
      setTitleAttributes(componentBody?.selected_attributes);
      setCurrentText(componentBody?.updated_text);
      const count = countPlaceholders(component?.text);
      setTitleAttributesCount(count);
    } else {
      setCurrentText(component?.text);
      const count = countPlaceholders(component?.text);
      setTitleAttributes({});
      setTitleAttributesCount(count);
    }
  }, [currentTemplateState]);

  return (
    <>
      <Box
        onClick={handleClick}
        ref={titleAttributesRef}
        sx={{
          padding: "10px",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 600,
            fontFamily: "Inter",
          }}
        >
          {currentText}
        </Typography>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={titleAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "200px",
            width: "280px",
            padding: "20px 20px 30px 20px",
            gap: "10px",
            overflowY: "auto",
          }}
        >
          <Typography
            variant="span"
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: 600,
              color: "#22272d",
            }}
          >
            Select Attributes
          </Typography>
          {Array.from({ length: titleAttributesCount }).map((_, index) => (
            <Box
              key={index}
              sx={{
                width: "85%",
                borderRadius: "10px",
                color: "rgb(89, 104, 123)",
                border: "1px solid #000",
                padding: "5px 10px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fff",
              }}
            >
              <TextField
                fullWidth
                key={index}
                placeholder={`Select value for {{${index + 1}}}`}
                value={titleAttributes[index]}
                variant="standard"
                onChange={(e) => handleAttributesTitle(index, e.target.value)}
                inputProps={{
                  maxLength: 30,
                }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => handleTitleAttributePopover(e, index)}
                      >
                        <DataObjectIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          ))}
          <AttributePopover
            handleAttributesOptions={handleAttributesTitle}
            attributeAnchorEl={attributeTitleAnchorEl}
            setAttributeAnchorEl={setAttributeTitleAnchorEl}
          />
        </Box>
      </Popover>
    </>
  );
};

export default TextComponent;
