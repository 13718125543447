import { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Autocomplete,
  Chip,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Radio,
  FormControlLabel,
  Collapse,
} from "@mui/material";
import SegmentIcon from "@mui/icons-material/Segment";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { startStyles } from "./styles/startStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import {
  PrimaryLabel,
  SecondaryLabel,
  SecondaryLabelSmall,
} from "../../../../../helper/utility/Labels";
import CustomField from "../../../../../helper/utility/CustomField";
import {
  ArrowDropDown,
  InfoOutlined,
  RadioButtonChecked,
  RadioButtonCheckedOutlined,
  RadioButtonUnchecked,
  SearchOutlined,
} from "@mui/icons-material";
import HorizontalStack from "../../../../../helper/utility/HorizontalStack";
import { unformatServerValue } from "../../../../../helper";
import {
  getTimeInMilliSeconds,
  isArrayWithValues,
} from "jwero-javascript-utils";
import { ALL_TRIGGERS } from "../../../../marketing_automation/utils";
import CustomTooltip from "../components/CustomTooltip";
// import { fetchCustomerSegments } from "./helper/fetchCustomerSegments";

const styles = startStyles;

const TRIGGER_TYPE_LABELS = {
  order: "Order",
  customer: "Customers",
  website: "Website",
  product: "Products",
  whatsapp: "Whatsapp",
  digital_gold: "Digital Gold",
};

const SidebarStartTrigger = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedSegments, setSelectedSegments] = useState(null);
  const [triggersBySection, setTriggersBySection] = useState({});
  const [selectedTriggers, setSelectedTriggers] = useState([]);
  const [searchTrigger, setSearchTrigger] = useState("");
  const [allTriggers, setAllTriggers] = useState(ALL_TRIGGERS);
  const triggersList = useSelector((state) => state?.marketing?.triggers);
  const flow_details = useSelector((state) => state.flow?.flow_details);
  const [hideCategory, setHideCategory] = useState({});

  useEffect(() => {
    let obj = {};
    Object.keys(TRIGGER_TYPE_LABELS)?.forEach((i, index) => {
      if (index) obj[i] = true;
    });
    setHideCategory(obj);
  }, [TRIGGER_TYPE_LABELS]);

  const editHideCategory = (e) => setHideCategory((s) => ({ ...s, ...e }));

  // console.log(
  //   flow_details,
  //   triggersList?.map((i) => ({ id: i?.id, event_name: i?.event_name })),
  //   "<<<< "
  // );
  useEffect(() => {
    setAllTriggers(
      !searchTrigger
        ? ALL_TRIGGERS
        : ALL_TRIGGERS?.filter((i) =>
            unformatServerValue(i?.label)?.includes(searchTrigger)
          )
    );
  }, [searchTrigger]);

  useEffect(() => {
    setAllTriggers(
      ALL_TRIGGERS?.map((i) => {
        let is_used = Boolean(
          triggersList?.find(
            (j) =>
              j?.event_name === i?.value &&
              (flow_details ? j?.id !== flow_details?.id : true)
          )
        );
        return { ...i, is_used };
      })
    );
  }, [triggersList, flow_details]);

  useEffect(() => {
    let obj = {};
    for (let trigger of allTriggers)
      if (!obj[trigger.type])
        obj[trigger.type] = allTriggers?.filter(
          (i) => i?.type === trigger?.type
        );
    setTriggersBySection(obj);
  }, [allTriggers]);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClose = () => {
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const closeSidebar = () => {
    setOpen(false);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { openStartSidebar: false },
    });
    dispatch({ type: actions.RESET_NODE_ID });
  };

  const handleSelectedOption = (e, newValue) => {
    setSelectedSegments(newValue);
  };

  const handleSave = () => {
    const dataPayload = { selected_triggers: selectedTriggers };
    console.log({ id: userId, data: dataPayload }, "<<<<<<<");
    dispatch({
      type: actions.UPDATE_NODE_DATA,
      payload: { id: userId, data: dataPayload },
    });
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { nodesUpdated: getTimeInMilliSeconds() },
    });

    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { selected_triggers: selectedTriggers, openStartSidebar: false },
    });
  };

  //   const getCustomerSegmemts = async () => {
  //     setLoading(true);
  //     const res = await fetchCustomerSegments();
  //     const responseData = res || {};
  //     const resultArray = Object.values(responseData).map((item) => ({
  //       id: item.id,
  //       segment_name: item.filterForm.segment_name,
  //     }));
  //     setData(resultArray);

  //     setLoading(false);
  //   };

  useEffect(() => {
    const currentNode = nodes?.find((it) => it.id === userId);
    // getCustomerSegmemts();
    const fieldData = currentNode?.fieldData;
    if (fieldData?.selected_triggers) {
      setSelectedTriggers(fieldData?.selected_triggers);
    } else {
      setSelectedTriggers([]);
    }
  }, [userId]);

  const onTriggerPress = (trigger) => {
    if (selectedTriggers?.find((i) => i?.value === trigger))
      setSelectedTriggers([]);
    else setSelectedTriggers([ALL_TRIGGERS?.find((i) => i?.value === trigger)]);
  };

  return (
    <Box sx={styles.customCard}>
      <Box sx={styles.responseSection}>
        <Box
          sx={{
            position: "absolute",
            right: "20px",
            top: "-10px",
            "&:hover": {
              backgroundColor: "#dcdcdc",
            },
            gap: "6px",
          }}
          // onClick={handleClose}
        >
          {/* <CloseIcon
            style={{
              fontSize: "22px",
              backgroundColor: "#fff",
              borderRadius: "5px",
              padding: "4px",
            }}
          /> */}
          {/* <Button size="small" variant="text" sx={{ color: "text.secondary" }}>
            Cancel
          </Button> */}
          <Button
            size="small"
            variant="contained"
            disabled={!isArrayWithValues(selectedTriggers)}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>

        <PrimaryLabel>Select Trigger</PrimaryLabel>
        <SecondaryLabelSmall sx={{ mt: 1 }}>
          Select an event to start this workflow
        </SecondaryLabelSmall>

        <CustomField
          value={searchTrigger}
          onChange={(e) => setSearchTrigger(e)}
          textFieldProps={{
            placeholder: "Search trigger",
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            },
          }}
          sx={{ mt: 3 }}
        />

        <Box sx={{ flex: 1, overflowY: "scroll" }}>
          {Object.keys(triggersBySection || {})?.map((i) => {
            return (
              <Box sx={{ mt: 4 }}>
                <HorizontalStack
                  sx={{ justifyContent: "space-between", cursor: "pointer" }}
                  onClick={() =>
                    editHideCategory({ [i]: !Boolean(hideCategory[i]) })
                  }
                >
                  <SecondaryLabelSmall>
                    {TRIGGER_TYPE_LABELS[i]}
                  </SecondaryLabelSmall>
                  <IconButton
                    size="small"
                    sx={{
                      transform: Boolean(hideCategory[i])
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                      transition: "all 0.3s",
                    }}
                  >
                    <ArrowDropDown />
                  </IconButton>
                </HorizontalStack>
                <Collapse in={Boolean(!hideCategory[i])}>
                  {triggersBySection?.[i]?.map((trigger) => {
                    return (
                      <TriggerListItem
                        title={trigger.label}
                        value={trigger.value}
                        disabled={trigger?.is_used}
                        onClick={onTriggerPress}
                        tooltip={trigger?.tooltip}
                        selected={selectedTriggers?.find(
                          (i) => i?.value === trigger?.value
                        )}
                      />
                    );
                  })}
                </Collapse>
              </Box>
            );
          })}
        </Box>
        {/* <Box sx={styles.topCard}>
          <SegmentIcon />
          <Typography variant="span" style={styles.headerTitle}>
            Select Segment
          </Typography>
        </Box>
        <>
          <Typography variant="span" style={styles.labels}>
            Select Segment
          </Typography>
          <Autocomplete
            multiple
            placeholder="Select..."
            id="tags-filled"
            loading={loading}
            options={
              (data &&
                data.length > 0 &&
                data.map((option) => option?.segment_name)) ||
              []
            }
            value={selectedSegments || []}
            onChange={(e, newValue) => handleSelectedOption(e, newValue)}
            getOptionSelected={(option, value) => option?.id === value?.id}
            renderTags={(value, getTagProps) =>
              value.map((selectedSegments, index) => (
                <Chip
                  key={index}
                  sx={{ backgroundColor: "#dcdcdc", fontFamily: "Inter" }}
                  variant="outlined"
                  label={selectedSegments}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select..."
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                sx={{
                  backgroundColor: "#fff",
                  marginBottom: "30px",
                }}
              />
            )}
          />
          <Button
            variant="contained"
            sx={{ textTransform: "none", marginBottom: "20px" }}
            onClick={handleSave}
          >
            Save
          </Button>
        </> */}
        {/* )} */}
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
        {/* <Box sx={{ flexShrink: 0 }}>
          <Button
            variant="contained"
            sx={{ mt: 3 }}
            disabled={!isArrayWithValues(selectedTriggers)}
            size="large"
            fullWidth
            onClick={handleSave}
          >
            Select Trigger
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
};

const TriggerListItem = ({
  title,
  disabled,
  value,
  onClick,
  selected,
  tooltip,
}) => {
  return (
    <HorizontalStack
      sx={{
        px: 2,
        py: 3,
        border: "0.4px solid grey",
        backgroundColor: "#fff",
        borderRadius: "10px",
        my: 2,
        "&:hover": {
          cursor: disabled ? "not-allowed" : "pointer",
          backgroundColor: "#ececec",
        },
        // gap: 10,
        display: "flex",
        alignItems: "center",
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? "not-allowed" : "default",
        gap: "10px",
      }}
      onClick={() => !disabled && onClick(value)}
    >
      {/* <Radio
        // size="small"
        checked={true}
        value="1"
        defaultChecked
        name="size-radio-button-demo"
        // ={selected}
      /> */}
      <Box sx={{ mx: 2, mt: 1 }}>
        {selected ? (
          <RadioButtonChecked style={{ height: "22px", width: "22px" }} />
        ) : (
          <RadioButtonUnchecked style={{ height: "22px", width: "22px" }} />
        )}
      </Box>
      {/* <Box sx={{ p: 1, borderRadius: "6px", backgroundColor: "lightgrey" }}>
      </Box> */}
      <CustomTooltip title={disabled ? "Trigger already created" : ""}>
        <SecondaryLabel>{title}</SecondaryLabel>
      </CustomTooltip>
      {Boolean(tooltip) && (
        <CustomTooltip title={tooltip}>
          <InfoOutlined style={{ height: "13px", width: "13px", ml: 1 }} />
        </CustomTooltip>
      )}
    </HorizontalStack>
  );
};

export default SidebarStartTrigger;
