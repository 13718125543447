export const startNodeStyles = {
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      fontFamily: "Inter",
    },
    innerBox: {
      position: "relative",
      top: "20px",
      left: "10px",
      color: "#797b8c",
      textAlign: "center",
      paddingRight: "35px",
    },
    bottomRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "row",
      "&:hover": {
        cursor: 'pointer',
      },
    },
    nodeBody: {
      margin: 0,
      padding: 0,
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      height: "30px",
      minWidth: "110px",
      fontSize: "16px",
      color: "#ffffff",
      backgroundColor: '#566e8a',
      borderRadius: "20px",
      marginBottom: "30px",
    },
    titleName: {
      margin: 0,
      padding: 0,
      fontSize: "12px",
      fontFamily: "Inter",
      fontWeight: 550,
    },
    iconButton: {
      marginLeft: '2px',
    },
    icon: {
      fontSize: "18px", color: "#ffffff" 
    },
    addIcon: {
      fontSize: "14px",
      height: '20px',
      width: '20px',    color: "black",
      borderRadius: "50%",
      backgroundColor: "white",
      marginLeft: "4px",
      transition: "visibility 0s, color 0.2s",
      position: 'relative',
      bottom: '13px',    
    },
    rightHandle: {
      background: "#566d89",
      opacity: "0",
      top: "4.5px",
      // left: "9px",
      position: "relative",
    }
  };
  