import {
  Alert,
  Avatar,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Slide,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  fetchCustomers,
  appendAllCustomers,
  addCustomerLabel,
  updateCustomerInRedux,
} from "../../redux/actions/crmActions";
import * as qs from "qs";
import {
  batchUpdateCustomer,
  formatDate,
  getAllCRMFields,
  getAxiosError,
  getCustomers,
  getLightenColor,
  getRandomDarkColor,
  getRandomInt,
  getRandomString,
  getRelativeTimeDifference,
  getRemainingTime,
  getStoreId,
  getToken,
  getUserProduct,
  getWebsite,
  isArrayWithValues,
  isNumber,
  isObjWithValues,
  secondaryLabel,
} from "../../helper";
import { CheckCircle, CornerDownLeft } from "react-feather";
import {
  Add as AddIcon,
  AddCircleOutline,
  Cached,
  Close,
  Delete,
  LocalOffer,
  Search,
} from "@mui/icons-material";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import { LoadingButton } from "@mui/lab";
import { styled, useTheme } from "@mui/system";
import axios from "axios";
import { isValidDate } from "../../utils/dateFunctions";
import { Whatsapp } from "../../Svgs";
import { fetchCatalogs } from "../../redux/actions/productActions";
import { updateCustomer } from "./View";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { updateChatObject } from "../../redux/actions/chatActions";

const SearchTextField = styled(TextField)`
  border-radius: 10px;
`;

const defaultParams = { page: 1, per_page: 20 };

const filters = [
  { label: "Recent Updated", value: "recent_updated" },
  { label: "Recent Active", value: "last_active" },
  { label: "DigiGold", value: "digigold" },
  { label: "Paid Customer", value: "paid_customer" },
  { label: "Recent Communication", value: "recent_communication" },
  { label: "Alphabetically A Z", value: "a_z" },
  { label: "Alphabetically Z A", value: "z_a" },
];

const fakeAvtars = [
  "https://avatars.dicebear.com/v2/avataaars/3da881d307702a994699a7e8cccf12de.svg",
  "https://avatars.dicebear.com/v2/avataaars/d8bb2edb896f5c583fc6af8c9e57dc8d.svg",
  "https://avatars.dicebear.com/v2/avataaars/e4bae82962359ec9c2120fb02c7737a3.svg",
  "https://avatars.dicebear.com/v2/avataaars/20e775b7c1e8a09f06617011454f65eb.svg",
  "https://avatars.dicebear.com/v2/avataaars/0fafdda675b8bbc8d67cf4c51183ce45.svg",
  "https://avatars.dicebear.com/v2/avataaars/09cb17c8ee34f3ddfc2d8049ebceb2d3.svg",
  "https://avatars.dicebear.com/v2/avataaars/3da7347012ea2a05229098dd50e13728.svg",
  "https://avatars.dicebear.com/v2/avataaars/6ec37ff59b2f57b8b5c874c6ae5b83c8.svg",
  "https://avatars.dicebear.com/v2/avataaars/b55346fe6f6b1be076cfc7e5c0ea3dd8.svg",
  "https://avatars.dicebear.com/v2/avataaars/e679851b38e5d2ec115a40d057ac99a0.svg",
  "https://avatars.dicebear.com/v2/avataaars/820a1a30772f51f6e84d0ab525bbb538.svg",
  "https://avatars.dicebear.com/v2/avataaars/5ffc3f1bb3bd641bc90b425df47befb2.svg",
];

function CRM() {
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [fetchingLocation, setFetchingLocation] = useState(false);
  const [crmAccess, setCrmAccess] = useState([]);
  const [defaultParams, setDefaultParams] = useState({ page: 1, per_page: 20 });
  const [params, setParams] = useState(defaultParams);
  const [addUserForm, setAddUserForm] = useState({
    fullName: "",
    email: "",
    phone: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postcode: "",
  });

  const [allCustomers, setAllCustomers] = useState([]);
  const [reduxCustomers, setReduxCustomers] = useState([]);

  const access = useSelector((state) => state.user.access);
  const assignedCustomers = useSelector(
    (state) => state.user.assigned_customers
  );
  const customerIncluded = useSelector((state) => state.user.customerIncluded);
  const allReduxCustomers = useSelector(
    (state) => state.customers.allCustomers
  );
  const allCatalogs = useSelector((state) => state.product.allCatalogs);
  const allReduxCustomerLabels = useSelector(
    (state) => state.customers.allCustomerLabels
  );

  let changeCityTimeout;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!isArrayWithValues(allReduxCustomerLabels))
  //     dispatch(getAllCustomerLabels());
  // }, []);

  useEffect(() => {
    if (typeof customerIncluded === "boolean") {
      if (isArrayWithValues(assignedCustomers)) {
        if (customerIncluded)
          setDefaultParams({
            ...defaultParams,
            include: assignedCustomers.map((i) => i.value),
          });
        else
          setDefaultParams({
            ...defaultParams,
            exclude: assignedCustomers.map((i) => i.value),
          });
      } else {
        if (!isArrayWithValues(allReduxCustomers))
          dispatch(appendAllCustomers(defaultParams));
      }
    }
  }, [customerIncluded, assignedCustomers]);

  useEffect(() => {
    if (defaultParams.include || defaultParams.exclude)
      if (!isArrayWithValues(allReduxCustomers))
        dispatch(appendAllCustomers(defaultParams));
  }, [defaultParams]);

  useEffect(() => {
    if (isArrayWithValues(access.crm)) setCrmAccess(access.crm);
  }, [access]);

  useEffect(() => {
    if (isArrayWithValues(allReduxCustomers))
      setReduxCustomers(allReduxCustomers);
    // else dispatch(appendAllCustomers(defaultParams));
  }, []);

  useEffect(() => {
    if (Array.isArray(allReduxCustomers)) setReduxCustomers(allReduxCustomers);
    if (
      isArrayWithValues(allReduxCustomers) &&
      !isArrayWithValues(allCatalogs)
    ) {
      dispatch(fetchCatalogs());
    }
  }, [allReduxCustomers]);

  useEffect(() => {
    if (Array.isArray(reduxCustomers)) setAllCustomers(reduxCustomers);
  }, [reduxCustomers]);

  const appendCustomers = () => {
    setParams({ ...params, page: params.page + 1 });
    dispatch(appendAllCustomers({ ...params, page: params.page + 1 }));
  };

  const changeAddForm = (payload) =>
    setAddUserForm({ ...addUserForm, ...payload });

  const postcodeChange = (e) => {
    changeAddForm({ postcode: e.target.value });
    onPostcodeChange(e.target.value, changeAddForm, setFetchingLocation);
  };

  const onCityChange = (e) => {
    changeAddForm({ city: e.target.value });
    if (changeCityTimeout) clearTimeout(changeCityTimeout);
    changeCityTimeout = setTimeout(() => {
      cityChange(e.target.value, changeAddForm, setFetchingLocation);
    }, 500);
  };

  const onAddUser = () => {
    let { email, phone, postcode, city, state, address_1, address_2 } =
      addUserForm;
    let obj = {
      email,
      billing: {
        city,
        state,
        address_1,
        address_2,
        postcode,
        phone,
      },
    };
    if (!email) delete obj.email;
    let fullName = addUserForm.fullName.split(" ");
    if (fullName[0]) obj.first_name = fullName[0];
    if (fullName[1]) obj.last_name = fullName[1];
    console.log(JSON.stringify(obj, null, 2));
  };
  return (
    <React.Fragment>
      <Helmet title="CRM" />

      <Grid justifyContent="space-between" container spacing={2} p={6} py={3}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Customer Relationship Management
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            {/* <Link component={NavLink} to="/">
              Products
            </Link> */}
            <Typography>CRM</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item sx={{ marginLeft: "auto" }}>
          <Stack direction="row" justifyContent={"flex-end"}>
            <Button
              // onClick={() => navigate("products/add")}
              // disabled={!crmAccess.includes("add")}
              startIcon={<Cached />}
              // onClick={() => navigate("/customers/add")}
              onClick={() =>
                dispatch(fetchCustomers(defaultParams, true, true))
              }
            >
              Refresh
            </Button>
            <Button
              variant="contained"
              color="primary"
              // onClick={() => navigate("products/add")}
              disabled={!crmAccess.includes("add")}
              startIcon={<AddIcon />}
              onClick={() => navigate("/customers/add")}
            >
              Add User
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Grid container mt={3}>
        <Grid item xs={12}>
          <EnhancedTable
            allCustomers={allCustomers}
            appendCustomers={appendCustomers}
            params={params}
            setParams={setParams}
          />
        </Grid>
      </Grid>
      {/* <Dialog
        open={showAddUserForm}
        onClose={() => setShowAddUserForm(false)}
        scroll={"paper"}
        maxWidth="sm"
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Add user</DialogTitle>
        <DialogContent dividers={"paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Full Name</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={addUserForm.fullName}
                  onChange={(e) => changeAddForm({ fullName: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Email</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={addUserForm.email}
                  onChange={(e) => changeAddForm({ email: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Phone</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={addUserForm.phone}
                  onChange={(e) => changeAddForm({ phone: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Address line 1</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={addUserForm.address_1}
                  onChange={(e) => changeAddForm({ address_1: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Address line 2</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={addUserForm.address_2}
                  onChange={(e) => changeAddForm({ address_2: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Pin code</Typography>
                <TextField
                  variant="outlined"
                  value={addUserForm.postcode}
                  onBlur={(e) =>
                    onPostcodeChange(
                      e.target.value,
                      changeAddForm,
                      setFetchingLocation
                    )
                  }
                  onChange={(e) => changeAddForm({ postcode: e.target.value })}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">City</Typography>
                <TextField
                  variant="outlined"
                  value={addUserForm.city}
                  onBlur={(e) =>
                    cityChange(
                      e.target.value,
                      changeAddForm,
                      setFetchingLocation
                    )
                  }
                  onChange={(e) => changeAddForm({ city: e.target.value })}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">State</Typography>
                <TextField
                  variant="outlined"
                  value={addUserForm.state}
                  onChange={(e) => changeAddForm({ state: e.target.value })}
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddUserForm(false)}>Cancel</Button>
          <LoadingButton onClick={onAddUser} variant="outlined">
            Add user
          </LoadingButton>
        </DialogActions>
      </Dialog> */}
    </React.Fragment>
  );
}

export default CRM;

const onPostcodeChange = (value, setState, setLoading) => {
  if (value.length >= 6) {
    setLoading(true);
    axios({
      method: "GET",
      url: `https://api.postalpincode.in/pincode/${value}`,
    })
      .then((res) => {
        let { data } = res;
        if (Array.isArray(data)) {
          let obj = data[0];
          if (obj.Status === "Success") {
            let { PostOffice } = obj;
            if (
              PostOffice &&
              Array.isArray(PostOffice) &&
              PostOffice.length > 0
            ) {
              let info = PostOffice[0];
              let countryObj, stateObj;
              // if (
              //   dropdownData.countries &&
              //   Array.isArray(dropdownData.countries)
              // ) {
              //   countryObj = dropdownData.countries.find(
              //     (i) => i.label === info.Country
              //   );
              // }
              // if (isObjWithValues(info))

              setState({
                city: info.Name ? info.Name : "",
                state: info.State ? info.State : "",
              });
              // this.setState(
              //   {
              //     billing: {
              //       ...this.state.billing,
              //       city: `${info.Name}, ${info.District}`,
              //     },
              //   },
              //   () => {
              //     if (countryObj) {
              //       this.onCountryValuePress(countryObj);
              //       setTimeout(() => {
              //         stateObj = this.state.selectedBillingStates.find(
              //           i => i.label === info.State,
              //         );
              //         if (stateObj)
              //           this.setState({selectedBillingState: stateObj});
              //       }, 100);
              //     }
              //   },
              // );
            }
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
};

const cityChange = (value, setState, setLoading) => {
  value = value.replace(" ", "_");
  axios({
    method: "GET",
    url: `https://api.postalpincode.in/postoffice/${value}`,
  }).then((res) => {
    let { data } = res;
    if (Array.isArray(data)) {
      let obj = data[0];
      if (obj.Status === "Success") {
        let { PostOffice } = obj;
        if (PostOffice && Array.isArray(PostOffice) && PostOffice.length > 0) {
          let info = PostOffice[0];
          setState({
            city: info.Name ? info.Name : "",
            postcode: info.Pincode ? info.Pincode : "",
            state: info.State ? info.State : "",
          });
        }
      }
    }
  });
};

const customerPlaceholder = () => (
  <Stack spacing={1}>
    <div style={{ display: "flex", padding: "10px 0px 10px 20px" }}>
      <Skeleton variant="circular" width={50} height={50} />
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginLeft: 12 }}>
          {/* <Skeleton variant="text" style={{ width: "40vw" }} />
            <Skeleton variant="text" style={{ width: "45vw" }} /> */}
          <Skeleton
            variant="rectangle"
            style={{ width: "64vw", height: 28, borderRadius: 8 }}
          />
        </div>
        {/* <div style={{ marginLeft: 15 }}>
            <Skeleton variant="circular" style={{ width: 20, height: 20 }} />
          </div>
          <div style={{ marginLeft: 35 }}>
            <Skeleton
              variant="rectangle"
              style={{ width: 40, height: 20, borderRadius: 8 }}
            />
          </div> */}
        {/* <div style={{ marginLeft: 12 }}>
            <Skeleton variant="text" style={{ width: "3vw" }} />
          </div> */}
      </div>
    </div>
    {/* <Skeleton variant="rectangular" width={210} height={118} /> */}
  </Stack>
);

function EnhancedTable({ allCustomers, appendCustomers, params, setParams }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [customerLabelId, setCustomerLabelId] = React.useState(0);
  const [rows, setRows] = useState([]);
  const allCustomersFetched = useSelector(
    (state) => state.customers.allCustomersFetched
  );
  const fetchingCustomers = useSelector(
    (state) => state.customers.fetchingCustomers
  );
  const reduxCatalogs = useSelector((state) => state.product.allCatalogs);
  const [labourViewId, setLabourViewId] = useState("");
  useEffect(() => {
    if (Array.isArray(allCustomers)) setRows((state) => [...allCustomers]);
  }, [JSON.stringify(allCustomers)]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (Array.isArray(rows) && rows.length > 0) {
      let currentPage = newPage + 1;
      if (rows.length / rowsPerPage === currentPage && !allCustomersFetched) {
        appendCustomers();
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          params={params}
          setParams={setParams}
        />
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let { first_name, last_name, email, id } = row;
                  return (
                    <CustomerRow
                      key={id}
                      row={row}
                      labelId={labelId}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      setCustomerLabelId={setCustomerLabelId}
                    />
                  );
                })}
            </TableBody>
          </Table>
          {emptyRows > 0 && !allCustomersFetched && fetchingCustomers && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              {new Array(emptyRows).fill(1).map((i, index) => {
                return (
                  <div key={index} style={{ alignItems: "center" }}>
                    {customerPlaceholder()}
                  </div>
                );
              })}
            </TableRow>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {fetchingCustomers && !allCustomersFetched ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size="13px" />
              <Typography variant="body1" ml={2}>
                Fetching more customers.
              </Typography>
            </div>

            <div style={{ height: "10px" }} />
          </>
        ) : null}
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      <Dialog
        maxWidth="xs"
        fullWidth
        open={customerLabelId}
        onClose={() => setCustomerLabelId(false)}
        // {...globalPopupStyle}
      >
        <CustomerLabelView
          id={customerLabelId}
          onClose={() => setCustomerLabelId(false)}
        />
      </Dialog>
    </Box>
  );
}

export const CustomerLabelView = ({
  id,
  onClose,
  setCustomer,
  broadcast,
  customer,
  chatDetail,
  label,
  whatsappCustomerArray,
  multiple,
  hideDelete,
}) => {
  console.log(label);
  const allCustomers = useSelector((state) => state.customers.allCustomers);
  const [allCustomerGroup, setAllCustomerGroup] = useState([]);
  const allReduxCustomerGroup = useSelector(
    (state) => state.customers.crmGrouping
  );
  let limit = 30;
  const [selectedTags, setSelectedTags] = useState([]);
  const [addLabelColor, setAddLabelColor] = useState(
    getRandomDarkColor()
    // Math.floor(Math.random() * 16777215).toString(16)
  );
  const [deleteId, setDeleteId] = useState(false);

  const [labelArray, setLabelArray] = useState([]);
  const [toast, setToast] = useState({ show: false });
  const [error, setError] = useState("");
  const [updating, setUpdating] = useState(false);
  const [customLabel, setCustomLabel] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (isArrayWithValues(allReduxCustomerGroup))
      setAllCustomerGroup(
        [...allReduxCustomerGroup].filter(
          (i) => !selectedTags.includes(i.group_name)
        )
      );
  }, [allReduxCustomerGroup, selectedTags]);

  useEffect(() => {
    if (id && isArrayWithValues(allCustomers)) {
      let customer = allCustomers.find((i) => i.id === id);
      if (customer) {
        let { meta_data } = customer;
        let storeid = getStoreId();
        if (storeid) {
          let labelObj = meta_data.find(
            (i) => i.key === `group_name_${storeid}`
          );
          if (labelObj) {
            setSelectedTags(labelObj.value);
          } else setSelectedTags([]);
        } else setSelectedTags([]);
      } else setSelectedTags([]);
    } else setSelectedTags([]);
  }, [allCustomers, id]);

  // useEffect(() => {
  //   if (!isArrayWithValues(allReduxCustomerGroup))
  //     dispatch(getGroupingData());
  // }, []);
  console.log(allReduxCustomerGroup, "<allReduxCustomerGroup");
  const onAdd = async (obj) => {
    if (!id) return;

    if (!obj?.color) {
      if (allCustomerGroup.find((i) => i.group_name === customLabel)) {
        setUpdating(false);
        // return dispatch(
        //   setGlobalToast({
        //     show: true,
        //     message: "Label deleted successfully!",
        //     severity: "success",
        //   })
        // );
        return;

        // setToast({
        //   show: true,
        //   message: "Label is already used, Please use another",
        //   severity: "error",
        // });
      }
      obj = {
        id: getRandomString(5),
        color: addLabelColor,
        group_name: customLabel?.trim(),
        group_description: "",
      };
      let tempArray = [...(allReduxCustomerGroup || []), obj];
      let array = [...(allReduxCustomerGroup || [])];
      if (isArrayWithValues(array)) {
        array.push({ ...obj });
      } else array = [{ ...obj }];
      // console.log(
      //   array,
      //   allReduxCustomerGroup,
      //   "allCustomerGroup>>array",
      //   [...(allCustomerGroup || []), obj],
      //   tempArray
      // );
      // return setUpdating(false);
      if (allReduxCustomerGroup.length >= limit) {
        return setError("Maximum limit reached");
      }
      try {
        let res = await addCustomerLabel(array);
        console.log(res);
        if (res.success && res.data && res.data.crm_grouping_names)
          dispatch({
            type: "SET_CRM_DATA",
            payload: { crmGrouping: res.data.crm_grouping_names },
          });
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
    //&& !isDev() && !isDev()
    if (allReduxCustomerGroup.length >= limit) {
      return setError("Maximum limit reached");
    }
    let group_namesObj =
      customer?.meta_data?.find(
        (o) => o.key === `group_name_${getStoreId()}`
      ) || {};
    let groups = group_namesObj?.value || [];
    let meta_data = [
      {
        key: `group_name_${getStoreId()}`,
        value: [...groups, obj.group_name],
      },
    ];
    // return;
    setUpdating(true);
    try {
      let res = await updateCustomer(id, { meta_data });
      setCustomer && setCustomer(res);
      dispatch(updateCustomerInRedux(res));
      // setUpdating(false);
      dispatch(setGlobalToast({ show: true, message: "Tag has been added!" }));
      onClose && onClose();
    } catch (error) {
      console.log(getAxiosError(error));
    } finally {
      setUpdating(false);
    }
    // }
    setUpdating(false);
  };

  const addCustomerLabel = async (data) => {
    let website = await getWebsite();
    let token = await getToken();

    let res = await axios({
      url: `${website}/wp-json/store/v1/settings`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      // params: { options: "customer_labels" },
      data: { crm_grouping_names: data },
      method: "POST",
    });
    return res.data;
  };

  return (
    <React.Fragment>
      {/* <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast({ ...toast, show: false })}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar> */}
      {deleteId ? (
        <DeleteCrmLabel
          setDeleteId={setDeleteId}
          deleteId={deleteId}
          allReduxCustomerGroup={allCustomerGroup}
        />
      ) : (
        <>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            pr={3}
          >
            {!broadcast && (
              <>
                {" "}
                <DialogTitle>Customer Grouping</DialogTitle>
                <IconButton onClick={() => onClose && onClose()}>
                  <Close />
                </IconButton>
              </>
            )}
          </Stack>
          <DialogContent>
            {isArrayWithValues(allCustomerGroup) &&
              allCustomerGroup.map((i) => {
                return (
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Stack
                      direction={"row"}
                      alignItem={"center"}
                      sx={{
                        px: 3,
                        py: 2,
                        "&:hover": {
                          backgroundColor: "rgba(0,0,0,0.02)",
                        },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        !multiple && onAdd(i);
                      }}
                    >
                      {multiple && <Checkbox />}
                      <LocalOffer
                        style={{
                          height: "16px",
                          width: "16px",
                          color: i.color,
                        }}
                      />
                      <Typography variant="h6" ml={3}>
                        {i.group_name}
                      </Typography>
                    </Stack>
                    {true && !hideDelete && (
                      <IconButton onClick={() => setDeleteId(i)}>
                        <Delete color="error" fontSize="small" />
                      </IconButton>
                    )}
                  </Stack>
                );
              })}
          </DialogContent>
          {!broadcast && (
            <>
              {" "}
              <Box sx={{ px: 3 }}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Custom Label"
                  fullWidth
                  variant="standard"
                  sx={{ mt: 2 }}
                  value={customLabel}
                  onChange={(e) => setCustomLabel(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ p: 2 }}
                          onClick={() =>
                            setAddLabelColor(
                              getRandomDarkColor()
                              // `#${Math.floor(Math.random() * 16777215).toString(16)}`
                            )
                          }
                        >
                          <LocalOffer
                            style={{
                              height: "16px",
                              width: "16px",
                              color: addLabelColor,
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Alert
                variant="outlined"
                severity="info"
                sx={{
                  border: "0px solid",
                  fontSize: "12px",
                  alignItems: "center",
                }}
              >
                Tip: Click on tag to change color while adding Custom Tag!
              </Alert>
              <Collapse in={Boolean(error)}>
                <Typography
                  sx={{
                    ...secondaryLabel,
                    color: "error.main",
                    textAlign: "center",
                  }}
                >
                  {error}
                </Typography>
              </Collapse>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <LoadingButton loading={updating} onClick={() => onAdd()}>
                  Update
                </LoadingButton>
              </DialogActions>
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export const DeleteCrmLabel = ({
  allReduxCustomerGroup,
  deleteId,
  setDeleteId,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const store_id = useSelector((state) => state.user.store_id);

  const deleteLabel = async (data) => {
    let website = await getWebsite();
    let token = await getToken();
    // console.log(tag);
    // return;
    setLoading(true);

    let allCustomersWithLabel = await getAllCRMFields({
      fields: `group_name_${store_id}`,
    });
    let batchUpdateArray = [];
    if (isObjWithValues(allCustomersWithLabel)) {
      for (let id in allCustomersWithLabel) {
        let data = allCustomersWithLabel[id];
        let labels = data?.[`group_name_${store_id}`];
        if (isArrayWithValues(labels) && labels?.includes(deleteId?.group_name))
          batchUpdateArray.push({
            id,
            meta_data: [
              {
                key: `group_name_${store_id}`,
                value: labels?.filter((i) => i !== deleteId?.group_name),
              },
            ],
          });
      }
    }

    console.log(batchUpdateArray);

    if (isArrayWithValues(batchUpdateArray)) {
      let per_page = 100;
      let batchLength = parseInt(batchUpdateArray?.length / per_page) || 1;
      let batchUpdatePayload = batchUpdateArray;
      for await (let i of Array.from(new Array(batchLength).fill(1))) {
        let { data } = await batchUpdateCustomer(
          batchUpdatePayload?.splice(0, per_page)
        );
        console.log(batchUpdatePayload, data);
      }
    }
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/crm_grouping_names`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      // params: { options: "customer_labels" },
      data: {
        data: [...(allReduxCustomerGroup || [])].filter(
          (obj) => obj?.id !== deleteId?.id
        ),
      },

      // {
      //   setting: "crm_grouping_names",
      //   data:
      // },
      method: "POST",
    });
    console.log(res.data);
    if (res?.data?.type === "success") {
      setDeleteId(false);
      dispatch(
        setGlobalToast({
          show: true,
          message: "Customer Label deleted successfully!",
        })
      );
      dispatch({
        type: "SET_CRM_DATA",
        payload: { crmGrouping: res?.data?.data },
      });
    }
    setLoading(false);
    return;

    let customersArray = [];
    let batchLenght = 1;
    let page = 1;
    for await (const iterator of Array.from(new Array(batchLenght).fill(1))) {
      let customersRes = await getCustomers({
        params: {
          per_page: 100,
          _fields: "id,meta_data",
          page: page,

          // "custom_filter": [{ "key": "group_name_27", "value": deleteId?.group_name, "compare": "LIKE" }]
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      if (isArrayWithValues(customersRes?.data)) {
        customersArray = [...customersArray, ...(customersRes?.data || [])];
      }
      batchLenght = customersRes?.headers?.["x-wp-total"];
      console.log(batchLenght);
      page = page + 1;
    }
    console.log(customersArray);

    let updateRes = await batchUpdateCustomer(
      customersArray?.map((obj) => {
        let newObj = {
          id: obj?.id,
        };
        let groupObj = obj?.meta_data?.find(
          (o) => o.key === `group_name_${getStoreId()}`
        );
        newObj.meta_data = {
          key: `group_name_${getStoreId()}`,
          value: groupObj?.value?.filter((str) => str !== deleteId?.group_name),
        };
        return newObj;
      })
    );
    console.log(updateRes);
    return;
    setLoading(true);
  };
  return (
    <>
      <DialogTitle>Are you Sure you want to delete CRM Label</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Label will also will deleted from customers
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteId && setDeleteId(false)}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          loading={loading}
          onClick={deleteLabel}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </>
  );
};

const CustomerRow = ({
  row,
  labelId,
  isItemSelected,
  handleClick,
  setCustomerLabelId,
}) => {
  const [customer, setCustomer] = useState({});
  const [lastCatalogVisit, setLastCatalogVisit] = useState({});
  const [showCustomerLabels, setShowCustomerLabels] = useState(false);
  const allStatus = ["Banned", "Active"];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const reduxCatalogs = useSelector((state) => state.product.allCatalogs);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    let {
      first_name,
      last_name,
      email,
      meta_data,
      billing,
      whatsapp_chat,
      id,
    } = row;
    let obj = {
      first_name,
      last_name,
      whatsapp_chat,
      id,
      email,
    };
    const metaKeys = ["whatsapp", "profile_url", "last_active", "label"];
    if (isArrayWithValues(meta_data)) {
      for (let i = 0; i < meta_data.length; i++) {
        let metaObj = meta_data[i];
        if (metaKeys.includes(metaObj.key)) {
          obj[metaObj.key] = metaObj.value;
        }
      }
    }
    setCustomer(obj);
  }, [row]);

  useEffect(() => {
    if (
      isArrayWithValues(reduxCatalogs) &&
      customer.email &&
      !isObjWithValues(lastCatalogVisit)
    ) {
      let lastVisitObj = {};
      for (let i = 0; i < reduxCatalogs.length; i++) {
        let obj = reduxCatalogs[i];
        if (isArrayWithValues(obj.catalogRequests)) {
          let { catalogRequests } = obj;
          if (isArrayWithValues(catalogRequests))
            for (let j = 0; j < catalogRequests.length; j++) {
              let request = catalogRequests[j];
              // console.log(request);
              let currentTime = request.analytics && request.analytics.end;
              let lastTime =
                lastVisitObj.analytics && lastVisitObj.analytics.end;

              if (
                request &&
                request.email === customer.email &&
                (currentTime > lastTime || !lastTime)
                // (lastVisitObj.end < request.end || !lastVisitObj.end)
              )
                lastVisitObj = request;
            }
        }
      }
      if (
        isObjWithValues(lastVisitObj) &&
        lastVisitObj.analytics &&
        lastVisitObj.analytics.end
      )
        setLastCatalogVisit(
          getRelativeTimeDifference(Number(lastVisitObj.analytics.end) * 1000)
        );
    }
  }, [reduxCatalogs, customer]);

  let {
    id,
    first_name,
    last_name,
    email,
    meta_data,
    billing,
    whatsapp,
    whatsapp_chat,
    profile_url,
    last_active,
    label,
    avatar_url,
  } = customer;
  let lastChatTime = 0;
  if (isArrayWithValues(whatsapp_chat)) {
    for (let i = 0; i < whatsapp_chat.length; i++) {
      let obj = whatsapp_chat[i];
      if (obj.sender) {
        lastChatTime = obj.time;
        break;
      }
    }
  }
  let lastTimeObj = lastChatTime && getRelativeTimeDifference(lastChatTime);
  let profileURL = avatar_url || fakeAvtars[getRandomInt(12)];
  const onViewPress = (obj) => {
    dispatch({ type: "SET_CRM_DATA", payload: { customerView: obj } });
    navigate(`/customers/${obj.id}`);
  };

  const isDarkTheme = theme.palette && theme.palette.mode === "dark";
  let status = allStatus[getRandomInt(2)];
  return (
    <TableRow
      hover
      onClick={(event) => onViewPress(row)}
      // onClick={(event) => handleClick(event, row.name)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
      sx={{ cursor: "pointer" }}
    >
      {/* <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell> */}
      <TableCell
        component="th"
        id={labelId}
        // style={{ width: "100%" }}
        scope="row"
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            src={profileURL}
            sx={{
              width: 40,
              height: 40,
              fontSize: 13,
              backgroundColor: "GrayText",
            }}
            // srcSet={`${small} 100w, ${medium} 68w, ${large} 1280w, ${xlarge} 3200w`}
          >
            {/* {`${first_name && first_name[0]} ${last_name && last_name[0]}`} */}
          </Avatar>
          <Box ml={4} sx={{ width: "100%" }}>
            <Stack
              direction={"row"}
              justifyContent="flex-start"
              alignItems={"center"}
            >
              <Typography
                // variant="body2"
                // fontWeight={"bold"}
                variant="h6"
                // component={Button}
                disableRipple
                disableFocusRipple
                disableElevation
                // onClick={(event) => onViewPress(row)}
                sx={{ justifyContent: "flex-start" }}
                style={{ paddingLeft: "0px", fontSize: "14px" }}
                fontStyle={{ textAlign: "left", fontSize: "16px" }}
              >
                {`${first_name} ${last_name}`}
              </Typography>

              {/* {getRandomInt(3) % 2 === 0 ? (
                <Typography
                  variant="caption"
                  color="success.main"
                  fontWeight={"bold"}
                >
                  Verified
                </Typography>
              ) : (
                <Typography
                  variant="caption"
                  color="error.main"
                  fontWeight={"bold"}
                >
                  Not verified
                </Typography>
              )} */}
              {isObjWithValues(label) && label.label ? (
                <Chip
                  icon={
                    <LocalOffer
                      style={{
                        height: "10px",
                        width: "10px",
                        // color: getLightenColor(label.color, -100),
                      }}
                    />
                  }
                  sx={{
                    ml: 2,
                    fontSize: "11px",
                    // color: getLightenColor(label.color, -100),
                    // backgroundColor: getLightenColor(label.color, 140),
                  }}
                  // variant="out"
                  label={label.label || ""}
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCustomerLabelId(id);
                  }}
                />
              ) : (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCustomerLabelId(id);
                  }}
                >
                  <AddCircleOutline />
                </IconButton>
              )}
            </Stack>
            {isIpad ? (
              <>
                {email && (
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 11 }}
                  >{`Email: ${email}`}</Typography>
                )}
                {(whatsapp || (billing && billing.phone)) && (
                  <Typography variant="body2" sx={{ fontSize: 11 }}>{`Phone: ${
                    whatsapp || (billing && billing.phone)
                  }`}</Typography>
                )}
              </>
            ) : null}
          </Box>
        </div>
      </TableCell>
      {!isIpad && (
        <>
          <TableCell align="left">{email || "-"}</TableCell>
          <TableCell align="left">
            {whatsapp || (billing && billing.phone) || "-"}
          </TableCell>
          <TableCell align="left">
            {isObjWithValues(lastTimeObj) ? (
              <Tooltip title="Last customer message time">
                <Stack direction="row" alignItems={"center"}>
                  <Whatsapp height={"16px"} width="16px" />
                  <Typography ml={2} fontSize="12px">
                    {lastTimeObj.value} {lastTimeObj.label}
                  </Typography>
                </Stack>
              </Tooltip>
            ) : (
              ""
            )}
            {isObjWithValues(lastCatalogVisit) ? (
              <Tooltip title="Last customer catalog visit">
                <Stack direction="row" alignItems={"center"} mt={1}>
                  <AutoStoriesOutlinedIcon
                    style={{ height: "16px", width: "16px" }}
                  />
                  <Typography ml={2} fontSize="12px">
                    {lastCatalogVisit.value} {lastCatalogVisit.label}
                  </Typography>
                </Stack>
              </Tooltip>
            ) : (
              ""
            )}
            {/* <CheckCircle color="#54D62C" size="16px" /> */}
          </TableCell>
          {/* <TableCell align="left">
            {status === "Active" ? (
              <Chip
                label={status}
                variant="outlined"
                color={"success"}
                size="small"
                sx={{
                  border: isDarkTheme ? "0.7px solid" : "0px",
                  backgroundColor: isDarkTheme ? "transparent" : "#E6F8E0",
                }}
              />
            ) : (
              <Chip
                label={status}
                variant="outlined"
                color={"error"}
                size="small"
                sx={{
                  border: isDarkTheme ? "0.7px solid" : "0px",
                  backgroundColor: isDarkTheme ? "transparent" : "#FFE3E1",
                }}
              />
            )}
          </TableCell> */}
        </>
      )}

      {/* <TableCell align="right">{row.fat}</TableCell>
          <TableCell align="right">{row.carbs}</TableCell>
          <TableCell align="right">{row.protein}</TableCell> */}
    </TableRow>
  );
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const theme = useTheme();

  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  let headCellsArr = isIpad ? headCellsMobile : headCells;
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCellsArr.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ textTransform: "capitalize", fontWeight: "bold" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, params, setParams } = props;
  const [search, setSearch] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("a_z");
  const dispatch = useDispatch();

  const onFilterChange = (filter) => {
    setSelectedFilter(filter);
    // let { selectedFilter } = this.state;
    // if (filter.value !== selectedFilter.value)
    //   this.setState({ selectedFilter: filter }, () => {
    //     // console.log(filter);
    let paramsObj = { ...params, ...defaultParams };
    switch (filter) {
      case "":
        delete paramsObj.sortby;
        delete paramsObj.order;
        delete paramsObj.orderBy;
        break;
      case "a_z":
        delete paramsObj.sortby;
        paramsObj = { ...paramsObj, order: "asc", orderBy: "name" };
        break;
      case "z_a":
        delete paramsObj.sortby;
        paramsObj = {
          ...paramsObj,
          order: "desc",
          orderBy: "name",
        };
        break;
      case "recent_updated":
        delete paramsObj.order;
        delete paramsObj.orderBy;
        paramsObj = {
          ...paramsObj,
          // order: 'desc',
          sortby: "recent_updated",
          // orderBy: 'meta_value_num',
          // meta_query: {key: 'last_update'},
        };
        break;
      case "last_active":
        delete paramsObj.order;
        delete paramsObj.orderBy;
        paramsObj = {
          ...paramsObj,
          // order: 'desc',
          sortby: "last_active",
          // orderBy: 'meta_value_num',
          // meta_query: {key: 'last_active'},
        };
      case "digigold":
        delete paramsObj.order;
        delete paramsObj.orderBy;
        paramsObj = {
          ...paramsObj,
          // order: 'DESC',
          sortby: "digigold",

          // [`meta_query[key]`]: 'digigold',
          // meta_query: {key: 'digigold'},
        };
        break;
      case "paid_customer":
        delete paramsObj.order;
        delete paramsObj.orderBy;
        paramsObj = {
          ...paramsObj,
          // order: 'desc',
          sortby: "paying_customer",
          // meta_query: {key: 'paying_customer', value: 1},
        };
        break;
      case "recent_communication":
        delete paramsObj.order;
        delete paramsObj.orderBy;
        paramsObj = {
          ...paramsObj,
          // order: 'asc',
          sortby: "recent_communication",
          // meta_query: {key: 'paying_customer', value: 1},
        };
        break;

      default:
        break;
    }

    // console.log(paramsObj);
    setParams(paramsObj);
    dispatch(fetchCustomers(paramsObj, true));
    //     if (filter.value === "a_z") {
    //     }
    //     if (filter.value === "z_a") {
    //     }
    //     if (filter.value === "recent_updated") {
    //     }
    //     if (filter.value === "last_active") {
    //     }
    //     if (filter.value === "digigold") {
    //     }
    //     if (filter.value === "paid_customer") {
    //     }
    //     if (filter.value === "recent_communication") {
    //     }
    //   });
    // else {
    //   this.setState({ selectedFilter: {} }, () => {
    //     let params = { ...this.state.params };
    //     delete params.order;
    //     delete params.orderBy;
    //     delete params.meta_query;
    //     this.setState({ params }, () =>
    //       this.props.getCustomerList(this.state.params, true)
    //     );
    //   });
    // }
    // this.setState({ filterOpen: false });
  };

  const onSearch = () => {
    setParams({ ...params, search });
    dispatch(fetchCustomers({ ...params, search }, true));
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") onSearch();
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        <Stack direction={"column"} width="100%" pt={3}>
          <Stack direction="row">
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Customers
              </Typography>
            )}
          </Stack>
          <Grid
            container
            spacing={3}
            sx={{ paddingRight: "6px" }}
            my={2}
            px={2}
          >
            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" size="small" fullWidth>
                {/* <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel> */}
                <NoBorderSelect
                  id="demo-simple-select"
                  // multiple
                  // input={<OutlinedInput label={label} />}
                  // renderValue={(selected) => selected}
                  value={selectedFilter}
                  onChange={(e) => onFilterChange(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  style={{
                    // margin: "4px 6px",
                    borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                  }}
                  size="small"
                  sx={{
                    border: "0px solid transparent",
                    borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                    "&:focus": {
                      backgroundColor: "transparent",
                    },
                    borderRadius: "10px",
                  }}
                >
                  {/* <MenuItem value="" key="placeholder">
              Sort By: none
            </MenuItem> */}
                  {filters.map((obj) => {
                    return (
                      <MenuItem
                        key={obj.value}
                        value={obj.value}
                        sx={{
                          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
                        }}
                      >
                        {/* <Checkbox checked={sortBy.indexOf(obj.value) > -1} /> */}
                        <ListItemText primary={obj.label} />
                      </MenuItem>
                    );
                  })}
                </NoBorderSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                variant="outlined"
                placeholder="Search User"
                InputProps={{
                  sx: { borderRadius: "10px" },

                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <CornerDownLeft size="18px" />
                    </InputAdornment>
                  ),
                }}
                onKeyDown={onKeyDown}
                inputProps={{
                  sx: {
                    padding: "12.5px 14px",
                    paddingLeft: 0,
                  },
                }}
                inputMode="search"
                fullWidth
              />
            </Grid>
          </Grid>
        </Stack>
      </Toolbar>
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Customer name",
    align: "left",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    align: "left",
  },
  {
    id: "contact",
    numeric: true,
    disablePadding: false,
    label: "Contacts",
    align: "left",
  },
  {
    id: "last_seen",
    numeric: true,
    disablePadding: false,
    label: "Last seen",
    align: "left",
  },
  // {
  //   id: "status",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Status",
  //   align: "left",
  // },
];
const headCellsMobile = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Customers Details",
    align: "left",
  },
];

const NoBorderSelect = styled(Select)`
  &:before {
    // whatever you want with normal CSS syntax. Here, a custom orange line as example
    border-bottom: 0px solid
  },
  &:after {
    // whatever you want with normal CSS syntax. Here, a custom orange line as example
    border-bottom: 0px solid
  },
`;
