import { useSelector } from "react-redux";
import { Box, Chip, Typography, Alert } from "@mui/material";
import CatalogBox from "../helper/CatalogBox";
import { productCatalogStyles } from "../styles/productCatalogStyles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { capitalizeFirstLetter } from "jwero-javascript-utils";

const styles = productCatalogStyles;

const MainSection = ({
  catalogs,
  selectedCatalog,
  setSelectedCatalog,
  handleCatalog,
}) => {
  const productCatalogIntegrated = useSelector(
    (state) => state.flow?.integration
  ).productCatalogIntegrated;

  const handleTextChange = (e, catalog) => {
    const value = e.target.value;

    const catalogIndex = selectedCatalog.findIndex(
      (item) => item.label === catalog.label
    );

    if (catalogIndex !== -1) {
      setSelectedCatalog((prev) => {
        const updatedCatalogs = [...prev];
        updatedCatalogs[catalogIndex] = {
          ...updatedCatalogs[catalogIndex],
          title: value,
        };
        return updatedCatalogs;
      });
    }
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(selectedCatalog);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelectedCatalog(items);
  }

  return (
    <Box sx={styles.responseSection}>
      <Box sx={styles.responseDiv}>
        <Typography variant="span" style={styles.labels}>
          Select Filters
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {catalogs &&
            Object.keys(catalogs).length > 0 &&
            Object.keys(catalogs).map((catalog, index) => (
              <Chip
                key={index}
                size="md"
                variant="solid"
                label={capitalizeFirstLetter(catalog)}
                sx={{
                  marginRight: "7px",
                  fontFamily: "Inter",
                  backgroundColor: selectedCatalog.some(
                    (item) => item.label === catalog
                  )
                    ? "#4c8afc"
                    : "white",
                  color: selectedCatalog.some((item) => item.label === catalog)
                    ? "white"
                    : "#4c8afc",
                  "&:hover": {
                    backgroundColor: selectedCatalog.some(
                      (item) => item.label === catalog
                    )
                      ? "#4c8afc"
                      : "white",
                    color: selectedCatalog.some(
                      (item) => item.label === catalog
                    )
                      ? "white"
                      : "#4c8afc",
                    cursor: "pointer",
                  },
                }}
                onClick={() => handleCatalog(catalog)}
              />
            ))}
        </Box>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="catalogs">
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{
                  listStyle: "none",
                  padding: 0,
                }}
              >
                {catalogs &&
                  Object.keys(catalogs).length > 0 &&
                  selectedCatalog.map((catalog, index) => (
                    <Draggable
                      key={catalog?.label}
                      draggableId={catalog?.label}
                      index={index}
                    >
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <CatalogBox
                            catalogs={catalogs}
                            catalog={catalog}
                            selectedCatalog={selectedCatalog}
                            setSelectedCatalog={setSelectedCatalog}
                            handleTextChange={handleTextChange}
                          />
                        </li>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box>
        {!productCatalogIntegrated && (
          <Alert severity="error" sx={{ marginTop: "25px", color: "red" }}>
            E-commerce not integrated !!
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default MainSection;
