/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import {
  ConfimPopup,
  EmptyState,
  formatDate,
  getAxiosError,
  getId,
  getTimeInMilliSeconds,
  isArrayWithValues,
  isObjWithValues,
  settingsEndpoint,
} from "../../../../helper";
import { Label } from "../../../customers/components/Info";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { useEffect } from "react";
import { getAllTemplates } from "../../../../redux/actions/chatActions";
import CustomChip from "../../../components/CustomChip";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { Sync } from "@mui/icons-material";
import { smsChannels } from "../../../customers/components/SendSms";
import { setConnectedSMSchannels } from "../../../../redux/actions/marketingActions";
import HorizontalStack from "../../../../helper/utility/HorizontalStack";

const Templates = () => {
  const smsIntegration = useSelector((state) => state.settings.smsIntegration);
  const fetchingSmsTempletes = useSelector(
    (state) => state.chats.fetchingSmsTempletes
  );
  const dispatch = useDispatch();
  const [openPopup, setOpenPopup] = useState(false);
  let templateLength =
    smsIntegration?.[smsIntegration?.selected_channel]?.template_ids?.length;

  return (
    <Box p={4} sx={{ pt: 0 }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h6">
          Templates {templateLength ? `(${templateLength})` : null}
        </Typography>

        <Stack direction={"row"} gap={3} alignItems={"center"}>
          <IconButton
            onClick={() => {
              dispatch(
                getAllTemplates(
                  smsIntegration?.[smsIntegration?.selected_channel]
                    ?.template_ids || [],
                  smsIntegration?.[smsIntegration?.selected_channel]?.auth_key
                )
              );
            }}
            disabled={fetchingSmsTempletes}
          >
            <Sync />
          </IconButton>

          {/* <LoadingButton
                        loading={fetchingSmsTempletes}
                        onClick={() => {
                            dispatch(
                                getAllTemplates(
                                    smsIntegration?.[smsIntegration?.selected_channel]
                                        ?.template_ids || [],
                                    smsIntegration?.[smsIntegration?.selected_channel]?.auth_key
                                )
                            );
                        }}
                    >
                        Refresh
                    </LoadingButton> */}
          {smsIntegration?.selected_channel === "exotel" && (
            <Button variant="contained" onClick={() => setOpenPopup(true)}>
              Add Template
            </Button>
          )}
        </Stack>
      </Stack>
      <Typography sx={{ fontStyle: "italic" }}>
        Templates can be created via DLT services directly. Once Created it must
        be added to your Preferred SMS Service provider and sync here in Jwero
        to utilize all the templates.
      </Typography>
      <SmsTemplateList smsIntegration={smsIntegration} showChannelSelection />
      {openPopup && (
        <AddSmsTemplate
          smsIntegration={smsIntegration}
          open={openPopup}
          handleClose={() => setOpenPopup(false)}
        />
      )}
    </Box>
  );
};

export default Templates;

const felids = [
  {
    label: "Template Name",
    value: "template_name",
    girdProps: {
      sx: 12,
      md: 6,
    },
  },
  {
    label: "DLT template Id",
    value: "dtl_template_id",
    girdProps: {
      sx: 12,
      md: 6,
    },
  },
  {
    label: "Sms Template",
    value: "body",
    girdProps: {
      sx: 12,
      md: 12,
    },
    props: {
      multiline: true,
      maxRows: 3,
    },
  },
];
const AddSmsTemplate = ({ smsIntegration, handleClose, open }) => {
  const [values, setValues] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const onChangeHandler = (payload) => {
    setValues((pre) => ({ ...pre, ...payload }));
    setError(false);
  };
  const allTemplates =
    useSelector((state) => state.settings.smsIntegration)?.["exotel"]
      ?.templates || [];
  const dispatch = useDispatch();

  async function onSubmit() {
    if (!values.template_name || !values.dtl_template_id || !values.body) {
      setError((pre) => ({
        ...pre,
        errorMessage: "Name,Dtl Template ID , Body required",
      }));
      return;
    }

    if (
      allTemplates?.find(
        (template) => template.template_name === values.template_name
      )
    ) {
      setError((pre) => ({
        ...pre,
        template_name: "Template name already exists",
      }));
      return;
    }
    if (
      allTemplates?.find(
        (template) => template.dtl_template_id === values.dtl_template_id
      )
    ) {
      setError((pre) => ({
        ...pre,
        template_name: "Template ID already exists",
      }));
      return;
    }
    if (allTemplates?.find((template) => template.body === values.body)) {
      setError((pre) => ({ ...pre, body: "Template ID already exists" }));
      return;
    }
    setLoading(true);
    let res = await settingsEndpoint({
      endpoint: "sms_integration",
      method: "PUT",
      data: {
        exotel: {
          ...(smsIntegration?.exotel || {}),
          templates: [
            ...allTemplates,

            {
              ...values,
              time: getTimeInMilliSeconds(),
              isAdmin,
              created_by: getId(),
            },
          ],
        },
      },
    });

    if (res) {
      await dispatch({
        type: EDIT_SETTINGS,
        payload: {
          exotelIntegration: res,
        },
      });
      dispatch(
        setGlobalToast({
          show: true,
          message: "Template Added Sucessfully",
          severity: "success",
        })
      );
    }
    setLoading(false);

    handleClose();
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Add Template</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} rowSpacing={4}>
          {felids?.map((felid) => {
            return (
              <Grid
                item
                xs={12}
                {...(felid?.girdProps || {})}
                key={felid.value}
              >
                <Label>{felid.label}</Label>
                <TextField
                  size="small"
                  fullWidth
                  value={values[felid.value]}
                  error={error?.[felid.value]}
                  onChange={(e) =>
                    onChangeHandler({ [felid.value]: e.target.value })
                  }
                  {...(isObjWithValues(felid?.props) ? felid?.props : {})}
                  helperText={error?.[felid.value] || ""}
                />
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Fade in={error?.errorMessage}>
              <Typography color={"error"}>{error?.errorMessage}</Typography>
            </Fade>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          onClick={onSubmit}
          variant="contained"
          autoFocus
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

// const AddTemplate = ({ smsIntegration, openPopup, setOpenPopup }) => {x
//     const dispatch = useDispatch();
//     const [inputForm, setInputForm] = useState({});
//     const [error, setError] = useState({});
//     const [adding, setAdding] = useState(false);
//     const onEditForm = (payload) => {
//         setError({});
//         setInputForm((pre) => ({ ...pre, ...payload }));
//     };

//     const addTemplate = async () => {
//         let idsArray =
//             smsIntegration?.[smsIntegration?.selected_channel]?.template_ids || [];
//         let template_ids = [...new Set([...idsArray, inputForm?.template_id])];

//         if (idsArray?.includes(inputForm?.template_id)) {
//             setError((pre) => ({ ...pre, template_id: "Template id Alredy Exists" }));
//             return;
//         }
//         // if (isObjWithValues(error)) return
//         setAdding(true);

//         try {
//             let res = await settingsEndpoint({
//                 endpoint: "sms_integration",
//                 method: "PUT",
//                 data: {
//                     [smsIntegration?.selected_channel]: {
//                         ...(smsIntegration?.[smsIntegration?.selected_channel] || {}),
//                         template_ids: template_ids,
//                     },
//                 },
//             });
//             if (res) {
//                 dispatch({
//                     type: EDIT_SETTINGS,
//                     payload: {
//                         smsIntegration: res,
//                     },
//                 });
//                 dispatch(
//                     setGlobalToast({
//                         show: true,
//                         message: `Template Added successfully`,
//                         severity: "success",
//                     })
//                 );
//                 dispatch(
//                     getAllTemplates(
//                         smsIntegration?.[smsIntegration?.selected_channel]?.template_ids ||
//                         [],
//                         smsIntegration?.[smsIntegration?.selected_channel]?.auth_key
//                     )
//                 );
//             }
//         } catch (error) {
//             console.log(getAxiosError(error));
//         }
//         setAdding(false);
//         setOpenPopup(false);
//     };
//     return (
//         <ConfimPopup
//             button={
//                 <LoadingButton
//                     variant="contained"
//                     loading={adding}
//                     onClick={addTemplate}
//                 >
//                     Add Template
//                 </LoadingButton>
//             }
//             content={
//                 <Stack>
//                     <Label>Template Id</Label>
//                     <TextField
//                         size="small"
//                         value={inputForm?.template_id}
//                         onChange={(e) => onEditForm({ template_id: e.target.value })}
//                         error={error?.template_id}
//                         helperText={error?.template_id}
//                     />
//                 </Stack>
//             }
//             heading={"Add MSG91 Template ID"}
//             handleClose={() => setOpenPopup(false)}
//             open={openPopup}
//         />
//     );
// };
function getValue(obj, path) {
  try {
    if (path.includes(".")) {
      // Split the path into object and property name
      const [objName, propertyName] = path.split(".");

      // Get the object using the name
      let currentObj = obj[objName];

      // Iterate through nested properties
      const nestedProperties = propertyName.split(".");
      for (let prop of nestedProperties) {
        if (currentObj && prop in currentObj) {
          currentObj = currentObj[prop];
        } else {
          return "";
        }
      }

      return currentObj;
    } else if (path in obj) {
      // If the path is a single property name
      return obj[path];
    } else {
      return "";
    }
  } catch (error) {
    // Handle any errors, such as if the path is invalid
    console.error("Error getting value:", error);
    return "";
  }
}

export const SmsTemplateList = ({
  onSelectTemplate,
  selectedChannel: _selectedChannel,
  showChannelSelection,
}) => {
  const dispatch = useDispatch();
  const smsIntegration = useSelector((state) => state.settings.smsIntegration);
  const smsTemplates = useSelector((state) => state.chats.smsTemplates);
  const fetchingSmsTempletes = useSelector(
    (state) => state.chats.fetchingSmsTempletes
  );
  const gupshupTemplates = useSelector(
    (state) => state.settings.gupshupIntegration
  )?.sms?.dlt_templates_list;
  let exotelSmsTemplate = useSelector(
    (state) => state.settings.smsIntegration
  )?.exotel?.templates?.map((o) => ({
    ...o,
    time: formatDate(new Date(o?.time), "dd mm yyyy"),
  }));
  const [selectedChannel, setSelectedChannel] = useState("msg91");
  const connected_sms_services = useSelector(
    (state) => state.settings?.connected_sms_services
  );
  const msg91Integration = useSelector(
    (state) => state?.settings?.msg91Integration
  );
  const gupshupIntegration = useSelector(
    (state) => state.settings.gupshupIntegration
  );
  const smsIntegrations = useSelector((state) => state.settings.smsIntegration);

  useEffect(() => {
    setSelectedChannel(_selectedChannel);
  }, [_selectedChannel]);

  useEffect(() => {
    if (connected_sms_services?.[0]?.name)
      setSelectedChannel(connected_sms_services?.[0]?.name);
  }, [connected_sms_services]);

  useEffect(() => {
    dispatch(setConnectedSMSchannels());
  }, [msg91Integration, gupshupIntegration, smsIntegrations]);

  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (!isArrayWithValues(smsTemplates) && selectedChannel === "msg91")
      dispatch(
        getAllTemplates(
          smsIntegration?.[selectedChannel]?.template_ids || [],
          smsIntegration?.[selectedChannel]?.auth_key
        )
      );
  }, [smsIntegration]);

  useEffect(() => {
    setRows(
      selectedChannel === "msg91"
        ? smsTemplates || []
        : selectedChannel === "gupshup"
        ? gupshupTemplates || []
        : exotelSmsTemplate || []
    );
  }, [smsTemplates, selectedChannel, _selectedChannel]);

  let tableHead = {
    msg91: [
      { label: "Template", path: "template_name" },
      { label: "Content", path: "template_data" },
      { label: "Template ID", path: "template_id" },
      { label: "Sender ID", path: "sender_id" },
      { label: onSelectTemplate ? null : "Actions" },
    ],
    exotel: [
      // { label: "Sr.", path:"" },
      { label: "Template Name", path: "template_name" },
      { label: "Template ID", path: "dtl_template_id" },
      {
        label: "Sms Template",
        path: "body",
        wrapperComponent: Typography,
        wrapperProps: {
          sx: { maxWidth: "200px" },
          noWrap: true,
        },
      },
      // { label: "Status", path: "status" },
      { label: "Date", path: "time" },
    ],
    gupshup: [
      { label: "Template ID", path: "template_id_urn" },
      { label: "Template Name", path: "template_name" },
      { label: "Template Content", path: "template_content" },
      { label: "Mask", path: "mast" },
      { label: "DLT Message Type", path: "dlt_message_type" },
      { label: "MSG Type", path: "msg_type" },
      { label: "Business Category", path: "business_category" },
    ],
  };

  return (
    <Box>
      {Boolean(showChannelSelection) && (
        <HorizontalStack sx={{ justifyContent: "flex-end" }}>
          <TextField
            select
            sx={{
              minWidth: "150px",
              ".MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                gap: 1,
              },
            }}
            style={{ marginRight: 12, marginTop: 8 }}
            size="small"
            value={selectedChannel}
            onChange={(e) => setSelectedChannel(e.target.value)}
          >
            {smsChannels
              ?.filter((i) =>
                connected_sms_services?.find((j) => j?.name === i?.value)
              )
              ?.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{ display: "flex", alignItems: "center", gap: 3 }}
                >
                  {option?.icon} {option.label}
                </MenuItem>
              ))}
          </TextField>
        </HorizontalStack>
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHead?.[selectedChannel]?.map((obj) => {
                if (obj?.label)
                  return <TableCell key={obj?.label}>{obj?.label}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchingSmsTempletes ? (
              new Array(5)?.fill("")?.map((s, i) => (
                <TableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      cursor: onSelectTemplate && "pointer",
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  </TableCell>
                  {onSelectTemplate ? null : <TableCell></TableCell>}
                </TableRow>
              ))
            ) : isArrayWithValues(rows) ? (
              rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                    cursor: onSelectTemplate ? "pointer" : "initial",
                  }}
                  onClick={() => onSelectTemplate && onSelectTemplate(row)}
                >
                  {tableHead?.[selectedChannel]?.map((obj) => {
                    // if (obj?.label)
                    let Wrapper = obj?.wrapperComponent || Fragment;
                    let props = obj?.wrapperProps || {};
                    if (obj?.label)
                      return (
                        <TableCell key={obj?.label}>
                          <Wrapper {...props}>
                            {getValue(row, obj?.path)}
                          </Wrapper>
                        </TableCell>
                      );
                  })}

                  {/* <TableCell component="th" scope="row">
                                        <Typography sx={{ mb: 1.5 }}>
                                            {row.template_name}
                                        </Typography>
                                        <CustomChip type={"completed"} status={"Verified by DLT"} />
                                    </TableCell>
                                    <TableCell sx={{ maxWidth: "250px" }}>
                                        {row.template_data}
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle2">
                                            {row.template_id}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle2">{row.sender_id}</Typography>
                                    </TableCell>
                                    {onSelectTemplate ? null : <TableCell></TableCell>} */}
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell colSpan={5}>
                  {/* <Typography>No Sms Template found!</Typography> */}
                  <EmptyState text={"No Sms Template found!"} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
