export const getAxis = ({ x, y, handleNum, totalHandles, currentNode }) => {
  const distance = 70;
  console.log(currentNode);
  const bigHeightNodes = [
    "whatsapp-template",
    "sms",
    "email",
    "push-notification",
  ];

  if (totalHandles % 2 !== 0) {
    if (handleNum === Math.ceil(totalHandles / 2)) {
      console.log("INNNNNNNN for oddd");
      const multiNodeAxis = {
        x: x + 215,
        y: y - 1.5,
      };
      const otherNodeAxis = {
        x: x + 250,
        y: y + 13,
      };
      const axis = bigHeightNodes.includes(currentNode)
        ? multiNodeAxis
        : otherNodeAxis;
      return axis;
    }
  }

  if (handleNum <= totalHandles / 2) {
    const multiNodeAxis = {
      x: x + 215,
      y: y - distance * (totalHandles / 2 - handleNum + 1) + 15,
    };
    const otherNodeAxis = {
      x: x + 250,
      y: y - distance * (totalHandles / 2 - handleNum + 1) + 15,
    };
    const axis = bigHeightNodes.includes(currentNode)
      ? multiNodeAxis
      : otherNodeAxis;
    return axis;
  } else {
    if (bigHeightNodes.includes(currentNode)) {
      return {
        x: x + 215,
        y: y + distance * (handleNum - totalHandles / 2) - 15,
      };
    } else {
      return {
        x: x + 250,
        y: y + distance * (handleNum - totalHandles / 2) - 15,
      };
    }
  }
};
