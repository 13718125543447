import { EDIT_MARKETING } from "../actions/marketingActions";

const initialState = {};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EDIT_MARKETING:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default reducer;
