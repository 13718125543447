import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Stack,
  Tabs,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Label } from "../../../customers/components/Info";
import { useDispatch, useSelector } from "react-redux";
import { isObjWithValues, settingsEndpoint } from "../../../../helper";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { LoadingButton } from "@mui/lab";
import HorizontalStack from "../../../../helper/utility/HorizontalStack";
import SendSms from "../../../customers/components/SendSms";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import SMSTemplateAssign from "./msg91/components/SMSTemplateAssign";
import { PrimaryLabel } from "../../../../helper/utility/Labels";

const Settings = () => {
  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const smsIntegration = useSelector((state) => state.settings.smsIntegration);

  const [settingState, setSettingState] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState({});
  const [showSMSTemplates, setShowSMSTemplates] = useState(false);
  const [selectedTemplatedValue, setSelectedTemplatedValue] = useState(false);

  const onEditForm = (payload) => {
    setSettingState((pre) => ({ ...pre, ...payload }));
    setHasError({});
  };
  useEffect(() => {
    setSettingState(smsIntegration?.[smsIntegration?.selected_channel] || {});
    return () => {};
  }, [smsIntegration]);

  const onUpdate = async () => {
    // let isValid = false;
    // if (isObjWithValues(hasError) || !settingState?.otp_template_id)
    //   isValid = false;
    // else isValid = true;
    // if (!settingState?.otp_template_id)
    //   setHasError((pre) => ({ ...pre, otp_template_id: true }));
    // if (!isValid) return;
    console.log(settingState, "<<<<< settingState");
    setLoading(true);
    let res = await settingsEndpoint({
      endpoint: "sms_integration",
      method: "PUT",
      data: {
        [smsIntegration?.selected_channel]: {
          ...(smsIntegration?.[smsIntegration?.selected_channel] || {}),
          ...settingState,
          otp_template_id: settingState?.otp_template_id || "",
          otp_template: settingState?.otp_template || "",
          digital_gold_template: settingState?.digital_gold_template || "",
        },
      },
    });
    if (res) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Settings has been saved",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          smsIntegration: res,
        },
      });
    }
    setLoading(false);
  };

  const onSelectTemplatePress = (value) => {
    console.log(value, "<<<<< value");
    setSelectedTemplatedValue(value);
    setShowSMSTemplates(true);
  };

  const onTemplateSelect = (payload) => {
    onEditForm({ [selectedTemplatedValue]: payload });
  };

  const onDeleteTemplate = (value) => {
    onEditForm({ [value]: "" });
  };

  return (
    <Box sx={{ p: 2, px: 4 }}>
      <HorizontalStack sx={{ justifyContent: "space-between" }}>
        <PrimaryLabel>Assign SMS templates</PrimaryLabel>
        <LoadingButton
          size="small"
          loading={loading}
          onClick={onUpdate}
          variant="contained"
        >
          Update Settings
        </LoadingButton>
      </HorizontalStack>
      <SMSTemplateAssign
        state={settingState}
        onDeleteTemplate={onDeleteTemplate}
        onSelectTemplatePress={onSelectTemplatePress}
      />

      <SendSms
        handleClose={() => setShowSMSTemplates(false)}
        // selected={selected}
        // sendWithCampagin={true}
        onEditForm={onTemplateSelect}
        returnPayload
        open={showSMSTemplates}
      />
    </Box>
  );

  return (
    <Box p={"12px 26px"}>
      <Stack sx={{ width: isIpad ? "100%" : "50%" }}>
        <HorizontalStack justifyContent="space-between" sx={{ my: 3 }}>
          <Label>OTP Template </Label>
          <HorizontalStack spacing={3}>
            {settingState.otp_template?.template_name ? (
              <>
                <IconButton
                  // onClick={() => onShowTemplate(v?.value)}
                  onClick={() => onSelectTemplatePress("otp_template")}
                  size="small"
                  color="primary"
                >
                  <EditOutlined />
                </IconButton>
                <IconButton
                  onClick={() => onDeleteTemplate("otp_template")}
                  size="small"
                  color="error"
                >
                  <DeleteOutline />
                </IconButton>
                <Button
                  variant="outlined"
                  size="small"
                  // onClick={() => onSelectTemplatePress("otp_template")}
                  disabled
                >
                  {settingState.otp_template?.template_name}
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                size="small"
                onClick={() => onSelectTemplatePress("otp_template")}
              >
                Select Template
              </Button>
            )}
          </HorizontalStack>
        </HorizontalStack>
        <HorizontalStack justifyContent="space-between" sx={{ my: 3 }}>
          <Label>Digital Gold OTP Template </Label>
          <TemplateSelection
            template={settingState.digital_gold_template}
            onDeleteTemplate={onDeleteTemplate}
            onSelectTemplatePress={onSelectTemplatePress}
            template_key={"digital_gold_template"}
          />
        </HorizontalStack>
        <HorizontalStack justifyContent="space-between" sx={{ my: 3 }}>
          <Label>Order Confirmation </Label>
          <TemplateSelection
            template={settingState.order_confirmation}
            onDeleteTemplate={onDeleteTemplate}
            onSelectTemplatePress={onSelectTemplatePress}
            template_key={"order_confirmation"}
          />
        </HorizontalStack>
        <HorizontalStack justifyContent="space-between" sx={{ my: 3 }}>
          <Label>Order Placed</Label>
          <TemplateSelection
            template={settingState.order_placed}
            onDeleteTemplate={onDeleteTemplate}
            onSelectTemplatePress={onSelectTemplatePress}
            template_key={"order_placed"}
          />
        </HorizontalStack>
        <HorizontalStack justifyContent="space-between" sx={{ my: 3 }}>
          <Label>Order feedback</Label>
          <TemplateSelection
            template={settingState.order_feedback}
            onDeleteTemplate={onDeleteTemplate}
            onSelectTemplatePress={onSelectTemplatePress}
            template_key={"order_feedback"}
          />
        </HorizontalStack>
        <HorizontalStack justifyContent="space-between" sx={{ my: 3 }}>
          <Label>Order shipped</Label>
          <TemplateSelection
            template={settingState.order_shipped}
            onDeleteTemplate={onDeleteTemplate}
            onSelectTemplatePress={onSelectTemplatePress}
            template_key={"order_shipped"}
          />
        </HorizontalStack>
        <HorizontalStack justifyContent="space-between" sx={{ my: 3 }}>
          <Label>Order delivered</Label>
          <TemplateSelection
            template={settingState.order_delivered}
            onDeleteTemplate={onDeleteTemplate}
            onSelectTemplatePress={onSelectTemplatePress}
            template_key={"order_delivered"}
          />
        </HorizontalStack>
        <HorizontalStack justifyContent="space-between" sx={{ my: 3 }}>
          <Label>Order cancelled</Label>
          <TemplateSelection
            template={settingState.order_cancelled}
            onDeleteTemplate={onDeleteTemplate}
            onSelectTemplatePress={onSelectTemplatePress}
            template_key={"order_cancelled"}
          />
        </HorizontalStack>
        {/* <HorizontalStack spacing={3}>
            {settingState.digital_gold_template?.template_name ? (
              <>
                <IconButton
                  // onClick={() => onShowTemplate(v?.value)}
                  onClick={() => onSelectTemplatePress("digital_gold_template")}
                  size="small"
                  color="primary"
                >
                  <EditOutlined />
                </IconButton>
                <IconButton
                  onClick={() => onDeleteTemplate("digital_gold_template")}
                  size="small"
                  color="error"
                >
                  <DeleteOutline />
                </IconButton>
                <Button
                  variant="outlined"
                  size="small"
                  // onClick={() => onSelectTemplatePress("otp_template")}
                  disabled
                >
                  {settingState.digital_gold_template?.template_name}
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                size="small"
                onClick={() => onSelectTemplatePress("digital_gold_template")}
              >
                Select Template
              </Button>
            )}
          </HorizontalStack> */}
        {/* <Label>OTP Template ID</Label>
        <Stack direction={"row"} alignItems={"center"} gap={3}>
          <TextField
            size="small"
            fullWidth
            value={settingState?.otp_template_id}
            onChange={(e) => onEditForm({ otp_template_id: e.target.value })}
            error={hasError?.otp_template_id}
            helperText={
              hasError?.otp_template_id ? "PLease Enter Template Id" : null
            }
          />
          {hasError?.otp_template_id && (
            <FormHelperText color={"error"}>
              PLease Enter Template Id{" "}
            </FormHelperText>
          )}
        </Stack>
        <Label sx={{ mt: 2 }}>Digital Gold OTP Template ID</Label>
        <Stack direction={"row"} alignItems={"center"} gap={3}>
          <TextField
            size="small"
            fullWidth
            value={settingState?.digigold_otp_template_id}
            onChange={(e) =>
              onEditForm({ digigold_otp_template_id: e.target.value })
            }
            error={hasError?.digigold_otp_template_id}
            helperText={
              hasError?.digigold_otp_template_id
                ? "PLease Enter Template Id"
                : null
            }
          />
          {hasError?.otp_template_id && (
            <FormHelperText color={"error"}>
              PLease Enter Template Id{" "}
            </FormHelperText>
          )}
        </Stack> */}

        <HorizontalStack justifyContent="flex-end" sx={{ mt: 2 }}>
          <LoadingButton
            loading={loading}
            onClick={onUpdate}
            variant="contained"
          >
            Update
          </LoadingButton>
        </HorizontalStack>
      </Stack>
      <SendSms
        handleClose={() => setShowSMSTemplates(false)}
        // selected={selected}
        // sendWithCampagin={true}
        onEditForm={onTemplateSelect}
        returnPayload
        open={showSMSTemplates}
      />
    </Box>
  );
};

export default Settings;

const TemplateSelection = ({
  template,
  onSelectTemplatePress,
  onDeleteTemplate,
  template_key,
}) => {
  return (
    <HorizontalStack spacing={3}>
      {template?.template_name ? (
        <>
          <IconButton
            // onClick={() => onShowTemplate(v?.value)}
            onClick={() => onSelectTemplatePress(template_key)}
            size="small"
            color="primary"
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            onClick={() => onDeleteTemplate(template_key)}
            size="small"
            color="error"
          >
            <DeleteOutline />
          </IconButton>
          <Button
            variant="outlined"
            size="small"
            // onClick={() => onSelectTemplatePress("otp_template")}
            disabled
          >
            {template?.template_name}
          </Button>
        </>
      ) : (
        <Button
          variant="outlined"
          size="small"
          onClick={() => onSelectTemplatePress(template_key)}
        >
          Select Template
        </Button>
      )}
    </HorizontalStack>
  );
};
