import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const DialogBox = ({ open, setOpen, handleCloseDialog, closeSidebar }) => {

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            fontWeight: 600,
            fontFamily: "Inter",
            borderBottom: "1px solid #dbe1e8",
            textAlign: "center",
            fontSize: "18px",
          }}
          id="alert-dialog-title"
        >
          Unsaved Changes
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              fontFamily: "Inter",
              color: "#516173",
              marginTop: "20px"
            }}
          >
            Are you sure you want to leave without saving the changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ marginBottom: '15px'}}>
          <Button
            sx={{
              fontFamily: "Inter",
              backgroundColor: "#fff",
              border: "1px solid #dbe1e8",
              textTransform: "none",
              color: "#516173",
              '&:hover': {
                  border: "1px solid #006cff",
                  backgroundColor: "#fff",
                  transition: "all .3s",
              }
            }}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            sx={{
              fontFamily: "Inter",
              backgroundColor: "#006cff",
              border: "1px solid #006cff",
              textTransform: "none",
              color: "#fff",
              '&:hover': {
                border: "1px #006cff",
                backgroundColor: "#6295f5",
                transition: "all .3s",
            }
            }}
            onClick={closeSidebar}
            autoFocus
          >
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogBox;
