import { getAxiosError } from "jwero-javascript-utils";
import { backendApiCall } from "../../../helper";
import { backendEndpoints } from "../apiFunctions";

const assignTeamMember = async ({
  sub_users = [],
  customers = [],
  replace,
  params = {},
}) => {
  try {
    let requestObj = {
      endpoint: `${backendEndpoints?.sub_user}/assign_customer`,
      method: "POST",
      data: { sub_users, customers, channel: "chats", replace },
      params,
    };
    // console.log({ ...data, channel: "chats" });
    return await backendApiCall(requestObj);
  } catch (error) {
    console.log(getAxiosError(error), "update chat user Error");
  }
};

export { assignTeamMember };
