import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { InputAdornment, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { addCannedMessage } from "../../../redux/actions/chatActions";
import { getRandomString } from "../../../helper";
import ChatUserVariableSelect from "../../components/ChatUserVariableSelect";

const AddCannedMessages = React.forwardRef(({}, ref) => {
  const userInfo = useSelector((state) => state?.user);
  const [open, setOpen] = React.useState(false);
  let [title, setTitle] = React.useState("");
  let [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  React.useImperativeHandle(ref, () => {
    return {
      openAddCannedMessages: () => setOpen(true),
    };
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onAdd = () => {
    let obj = {
      title,
      content: { text: message },
      created_by: {
        id: userInfo?.id,
        isAdmin: userInfo?.isAdmin,
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
      },
      // time: new Date().getTime(),
      // message,
    };
    dispatch(addCannedMessage(obj, setLoading, () => handleClose()));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">Add new quick reply</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          placeholder="e.g. User greet!"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          multiline
          rows={2}
          fullWidth
          placeholder="e.g. Hi! How can I help you?"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={{ marginTop: "8px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <ChatUserVariableSelect
                  onSelect={(e) => setMessage((state) => `${state} ${e}`)}
                  buttonComponent={({ open }) => (
                    <Button color="secondary" onClick={open}>
                      {"{}"}
                    </Button>
                  )}
                />
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <LoadingButton loading={loading} onClick={onAdd}>
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});
export default AddCannedMessages;
