import { createContext, useEffect, useReducer } from "react";
import {
  Base64,
  backendDoamin,
  capitalizeFirstLetter,
  checkAdvanceAccess,
  checkBetweenTime,
  formatDate,
  formatServerValue,
  getItem,
  getTimezoneTime,
  isArrayWithValues,
  isDev,
  isIPInRange,
  mainWebsite,
  removeItem,
  setItem,
  unformatServerValue,
} from "../helper";

import axios from "axios";
import axiosInstance from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import { sign, verify } from "jsonwebtoken";
import { useDispatch } from "react-redux";
import { daysArray } from "../pages/settings/roles/RolesList";
import { getIp } from "../App";
import { Mixpanel } from "../mixpanel";
import { appendIntegrations } from "../redux/actions/userActions";
import { SET_HELPER_DATA } from "../redux/actions/helperActions";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const JS = "super-secret-key";
const JWT_EXPIRES_IN = "7 days";

export const defaultAccessObj = {
  access: {
    product: [
      "edit",
      "add",
      "delete",
      "bulk_edit",
      "create_catalog",
      "create_quotation",
    ],
    crm: [
      "add",
      "edit",
      "chat",
      "catalogs",
      "quotations",
      "analytics",
      "notes",
      "pricing",
      "contacts",
    ],
  },
  settingsAccess: false,
  integrationsAccess: false,
  catalogsAccess: false,
  assigned_customers: [],
  customerExcluded: false,
  customerIncluded: false,
  advanced_access: {
    ip: "",
    start_login_time: "",
    end_login_time: "",
    start_day: "",
    end_day: "",
  },
};
// const defaultAccessObj = {
//   // id: 1,
//   // first_name: "Rahul",
//   // last_name: "more",
//   // email: "rahulmore@gmail.com",
//   access: {
//     product: ["add", "edit", "delete", "bulk_edit"],
//     crm: ["add", "edit", "chat"],
//   },
//   settingsAccess: true,
//   integrationsAccess: true,
//   assigned_customers: [
//     // {
//     //   label: "akshay kumar",
//     //   value: 6041,
//     // },
//   ],
//   // created: 1651920800000,
//   status: "Pending",
//   customerExcluded: false,
//   customerIncluded: false,
// };
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const reduxDispatch = useDispatch();
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = getItem("accessToken");
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await getUserResponse(
            axiosInstance.defaults.headers.common
          );
          // const response = await axios.get("/api/auth/my-account");
          let user = response[1] && response[1].user;
          // let parsedUser = {};
          // if (user) {
          //   parsedUser = JSON.parse(user);
          //   console.log(parsedUser);
          // }

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
          reduxDispatch({
            type: "SET_USER",
            payload: {
              ...user,
              ...defaultAccessObj,
              ...user?.access,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });

          reduxDispatch({
            type: "SET_USER",
            payload: null,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email, password, token) => {
    // const response = await axiosInstance.post("/api/auth/sign-in", {
    //   email,
    //   password,
    // });
    // const response = await axios.post(
    //   "https://retailer.tanika.tech/wp-json/store/v1/details",
    //   null,
    //   {
    //     headers: {
    //       Authorization: `Basic ${Base64.btoa(`${email}:${password}`)}`,
    //     },
    //   }
    // );
    // let product = new URLSearchParams(window.location.href).get("product");

    // return product;
    if ((!email || !password) && token) {
      let decodedToken = Base64.atob(token);
      if (decodedToken) {
        decodedToken = decodedToken.split(":");
        email = decodedToken[0];
        password = decodedToken[1];
      }
    }
    if (!token) token = `${Base64.btoa(`${email}:${password}`)}`;
    let response;
    if (email?.includes("__")) {
      email = email.replace("__", "");
      response = await axios({
        url: `${backendDoamin}/api/auth/login`,
        method: "POST",
        // url: "https://app.tanika.tech/wp-json/store/v1/signin",
        data: { email, password },
      });
      token = response.data?.token;
    } else
      response = await axios({
        url: `${mainWebsite}/wp-json/store/v1/details`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        // url: "https://app.tanika.tech/wp-json/store/v1/signin",
        // data: { email, password },
        // method: "POST",
      });
    let { data: resData } = response;
    let data = resData?.data || resData;
    console.log(data, "<<<< data");
    if (data?.code === "incorrect_password") {
      return { unauthenticated: true, message: "Invalid credentials" };
    }
    if (data?.ID || data?.cap_key || data?.caps) {
      reduxDispatch({
        type: SET_HELPER_DATA,
        payload: {
          unregistered_user: {
            user_id: data.ID,
            user_email: email,
            token,
            // whatsapp_verified: data.whatsapp_verified,
          },
        },
      });
      return "UNREGISTERED";
    }
    if (!data.store_plan) data.store_plan = {};
    // if (isDev()) data.store_plan.user_products = ["catalog"];
    // if (isDev()) data.store_plan.user_products = ["chats"];
    if (window.location.href.includes("?product=e_commerce"))
      data.store_plan.user_products = ["e-commerce"];
    if (window.location.href.includes("?product=catalog"))
      data.store_plan.user_products = ["catalog"];
    if (window.location.href.includes("?product=chats"))
      data.store_plan.user_products = ["chats"];
    if (window.location.href.includes("?product=crm"))
      data.store_plan.user_products = ["crm"];
    // if (isDev()) data.store_plan.user_products = ["chats", "catalog"];

    // if (data?.access?.email?.includes("tanikatech")) data.isAdmin = true;

    reduxDispatch(appendIntegrations(data?.store_plan?.add_ons?.integrations));

    if (
      !isArrayWithValues(data.store_plan.user_products) &&
      !data.store_plan.all_products_access
    )
      data.store_plan.all_products_access = true;
    if (data?.store_plan?.disabled && !isDev()) return "DISABLED";
    console.log(2);

    const user = response.data.success ? response.data?.data : response.data;
    user.token = token;
    const accessToken = sign(
      { userId: user.id, time: new Date().getTime() },
      JS,
      {
        expiresIn: JWT_EXPIRES_IN,
      }
    );
    // window.localStorage.setItem("user", JSON.stringify(user));
    if (!user.user_type) user.user_type = "retailer";

    setItem({
      user: JSON.stringify({
        ...user,
        ...user.access,
        user_type: user.user_type,
        has_quotation_access: user.user_type == "supplier",
        _access: user.access,
      }),
    });
    setSession(accessToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        user: {
          ...user,
          ...user.access,
          user_type: user.user_type,
          has_quotation_access: user.user_type == "supplier",
          _access: user.access,
        },
      },
    });
    console.log(3);

    reduxDispatch({
      type: "EDIT_SETTINGS",
      payload: {
        allSettingsFetched: false,
      },
    });
    reduxDispatch({
      type: "SET_USER",
      payload: {
        ...user,
        ...user.access,
        user_type: user.user_type,
        has_quotation_access: user.user_type == "supplier",
        _access: user.access,
        // ...defaultAccessObj,
      },
    });
    let migration_status = data.migration_status || {};
    // if (
    //   !migration_status.business_settings ||
    //   !migration_status.master_pricing ||
    //   !migration_status.products ||
    //   !migration_status.final
    // )
    //   return "UNMIGRATED";

    // let advanced_access = defaultAccessObj?.advanced_access;
    console.log(4);
    let advanced_access = user.access?.advanced_access;
    let unauthObj = await checkAdvanceAccess({
      advanced_access,
      ...(user.access || {}),
    });

    if (unauthObj.unauthenticated && !isDev()) return unauthObj;

    Mixpanel.identify(
      `${`${unformatServerValue(user?.store_details?.store_name || "")}`}-${
        user.store_id
      }-${user.id}`
    );
    Mixpanel.people.set({
      user_id: user.id,
      store_id: user.store_id,
      name: user.username,
      store_name: user?.store_details?.store_name,
      $email: user?.store_details?.store_email,
      user_type: user?.user_type,
      admin: user.isAdmin,
    });
    console.log(4);
    Mixpanel.track("login_successful");
    return isDev()
      ? true
      : data.store_details && data.store_details.store_email;
  };

  const signInWithoutApi = (user) => {
    const accessToken = sign(
      { userId: user.id, time: new Date().getTime() },
      JS,
      {
        expiresIn: JWT_EXPIRES_IN,
      }
    );
    // window.localStorage.setItem("user", JSON.stringify(user));
    if (!user.user_type) user.user_type = "retailer";

    setItem({
      user: JSON.stringify({
        ...user,
        ...user.access,
        user_type: user.user_type,
        has_quotation_access: user.user_type == "supplier",
        _access: user.access,
      }),
    });
    setSession(accessToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        user: {
          ...user,
          ...user.access,
          user_type: user.user_type,
          has_quotation_access: user.user_type == "supplier",
          _access: user.access,
        },
      },
    });
    reduxDispatch({
      type: "EDIT_SETTINGS",
      payload: {
        allSettingsFetched: false,
      },
    });
    reduxDispatch({
      type: "SET_USER",
      payload: {
        ...user,
        ...user?.access,
        user_type: user.user_type,
        has_quotation_access: user.user_type == "supplier",
        _access: user?.access,
        // ...defaultAccessObj,
      },
    });
  };

  const signOut = async () => {
    setSession(null);
    removeItem("user");
    window.localStorage.removeItem("user");
    dispatch({ type: SIGN_OUT });

    reduxDispatch({
      type: "USER_LOGOUT",
      // payload: null,
    });
    // reduxDispatch({
    //   type: "SET_USER",
    //   payload: null,
    // });
  };

  const signUp = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    // window.localStorage.setItem("accessToken", accessToken);
    // window.localStorage.setItem("user", JSON.stringify(user));
    setItem({ accessToken: accessToken, user: JSON.stringify(user) });
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signInWithoutApi,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

const getUserResponse = (config) => {
  try {
    const { Authorization } = config;

    if (!Authorization) {
      return [401, { message: "Authorization token missing" }];
    }

    const accessToken = Authorization.split(" ")[1];
    const data = verify(accessToken, JS);
    const userId = typeof data === "object" ? data?.userId : "";
    const user = getItem("user");
    // const user = users.find((_user) => _user.id === userId);

    if (!user) {
      return [401, { message: "Invalid authorization token" }];
    }

    return [200, { user }];
  } catch (error) {
    console.error(error);
    return [500, { message: "Internal server error" }];
  }
};
