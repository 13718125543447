import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Grid,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import {
  ErrorLabelExtraSmall,
  PrimaryLabelSmall,
  SecondaryLabelSmall,
} from "./Labels";
import HorizontalStack from "./HorizontalStack";
import {
  DesktopDatePicker,
  DesktopTimePicker,
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  InfoOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { isObjWithValues } from "jwero-javascript-utils";
import CustomTooltip from "../../pages/flows/pages/addFlow/components/CustomTooltip";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

let arrayTypes = ["checkbox"];

const CustomButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`, // Apply border to the entire group
  borderRadius: "8px", // Use the theme's border radius

  "& .MuiButtonGroup-grouped": {
    border: "none", // Remove individual button borders
    "&:not(:last-of-type)": {
      borderRight: `1px solid ${theme.palette.divider}`, // Add the divider between buttons
    },
  },
  "& .MuiButtonGroup-grouped:first-of-type": {
    borderTopLeftRadius: "8px", // Apply border radius to the first button
    borderBottomLeftRadius: "8px",
  },
  "& .MuiButtonGroup-grouped:last-of-type": {
    borderTopRightRadius: "8px", // Apply border radius to the last button
    borderBottomRightRadius: "8px",
  },
}));

const CustomField = ({
  label,
  onChange = () => {},
  options = [],
  value: _value = "",
  textFieldProps,
  type = "text",
  labelRight,
  sx = {},
  error,
  helperText,
  backgroundColor,
  tooltip,
  suffixProps = {},
  prefixProps = {},
  multiple = true,
  placeholder,
  autocompleteProps,
}) => {
  let mainBackgroundColor = backgroundColor || "#f7f7f7";
  let value = _value;

  let containerStyle = {
    backgroundColor: mainBackgroundColor,
    borderRadius: "8px",
  };
  // || (arrayTypes?.includes(type) ? [] : "");
  return (
    <Box sx={sx}>
      <HorizontalStack justifyContent="space-between">
        <HorizontalStack justifyContent="space-between" sx={{ gap: 2 }}>
          <SecondaryLabelSmall>{label}</SecondaryLabelSmall>
          {tooltip && (
            <CustomTooltip title={tooltip}>
              <InfoOutlined style={{ height: "14px", width: "14px" }} />
            </CustomTooltip>
          )}
        </HorizontalStack>

        {labelRight && labelRight}
      </HorizontalStack>
      {/* <HorizontalStack justifyContent="space-between" sx={{ gap: 2 }}> */}
      {type === "text" && (
        <TextField
          value={value}
          onChange={(e) => onChange(e.target.value)}
          size="small"
          sx={{ mt: 1 }}
          fullWidth
          error={error}
          {...textFieldProps}
          InputProps={{
            style: { ...containerStyle, ...(textFieldProps?.style || {}) },
            ...textFieldProps?.InputProps,
          }}
          // helperText={helperText || textFieldProps?.helperText}
        />
      )}
      {type === "text_dropdown" && (
        // <HorizontalStack justifyContent="space-between" sx={{ gap: 2 }}>
        <Grid container spacing={2}>
          {isObjWithValues(prefixProps) && (
            <Grid item xs={4}>
              <Select
                value={prefixProps?.value || ""}
                size="small"
                onChange={(e) => prefixProps?.onChange?.(e.target.value)}
                sx={{ mt: 1 }}
                fullWidth
                SelectDisplayProps={{
                  style: containerStyle,
                }}
                // placeholder="hello"
                {...(prefixProps?.textFieldProps || {})}
                style={{ borderRadius: "10px" }}
              >
                {prefixProps?.options?.map((i) => {
                  return <MenuItem value={i.value}>{i.label}</MenuItem>;
                })}
              </Select>
            </Grid>
          )}
          <Grid item xs={8}>
            <TextField
              value={value}
              onChange={(e) => onChange(e.target.value)}
              size="small"
              sx={{ mt: 1 }}
              // InputProps={{ }}
              fullWidth
              InputProps={{
                style: containerStyle,
              }}
              error={error}
              {...textFieldProps}
              // helperText={helperText || textFieldProps?.helperText}
            />
          </Grid>
          {isObjWithValues(suffixProps) && (
            <Grid item xs={4}>
              <Select
                value={suffixProps?.value || ""}
                size="small"
                onChange={(e) => suffixProps?.onChange?.(e.target.value)}
                sx={{ mt: 1 }}
                fullWidth
                SelectDisplayProps={{
                  style: containerStyle,
                }}
                label={suffixProps?.label}
                // placeholder="hello"
                {...suffixProps?.otherProps}
                style={{ borderRadius: "10px" }}
              >
                {suffixProps?.options?.map((i) => {
                  return <MenuItem value={i.value}>{i.label}</MenuItem>;
                })}
              </Select>
            </Grid>
          )}
        </Grid>
      )}
      {type === "number" && (
        <TextField
          // {...textFieldProps}
          size="small"
          onChange={(e) => onChange(e.target.value)}
          sx={{ mt: 1 }}
          fullWidth
          InputProps={{ style: { ...containerStyle } }}
          value={value}
          type="number"
          {...textFieldProps}
          // helperText={helperText || textFieldProps?.helperText}
        />
      )}
      {type === "dropdown" && (
        <Select
          value={value || ""}
          size="small"
          onChange={(e) => onChange(e.target.value)}
          sx={{ mt: 1 }}
          fullWidth
          // SelectDisplayProps={{
          //   style: { ...containerStyle },
          // }}
          SelectDisplayProps={{
            style: containerStyle,
          }}
          placeholder="hello"
          {...textFieldProps}
          style={{ borderRadius: "10px", ...(textFieldProps?.style || {}) }}
        >
          {options?.map((i) => {
            return <MenuItem value={i.value}>{i.label}</MenuItem>;
          })}
        </Select>
      )}
      {type === "date" && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            // disableFuture
            // label="First Purchase"
            {...textFieldProps}
            value={value}
            onChange={(newValue) => onChange(newValue)}
            renderInput={(params) => (
              <TextField
                {...textFieldProps}
                fullWidth
                {...params}
                sx={{ mt: 1 }}
                error={false}
              />
            )}
            inputFormat="dd/MM/yyyy"
          />
        </LocalizationProvider>
      )}
      {type === "time" && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopTimePicker
            // disableFuture
            // label="First Purchase"
            value={value}
            onChange={(newValue) => onChange(newValue)}
            renderInput={(params) => (
              <TextField
                {...textFieldProps}
                fullWidth
                {...params}
                sx={{ mt: 1 }}
                error={false}
              />
            )}
            inputFormat=""
          />
        </LocalizationProvider>
      )}
      {type === "datetime" && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDateTimePicker
            // disableFuture
            // label="First Purchase"
            value={value}
            onChange={(newValue) => onChange(newValue)}
            renderInput={(params) => (
              <TextField
                {...textFieldProps}
                fullWidth
                {...params}
                sx={{ mt: 1 }}
                error={false}
              />
            )}
            inputFormat=""
          />
        </LocalizationProvider>
      )}
      {type === "checkbox" && (
        <Autocomplete
          multiple
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          value={value || []}
          onChange={(a, e) => onChange(e)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          {...textFieldProps}
          // style={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ mt: 1 }}
              size="small"
              InputProps={{
                ...params?.InputProps,
                style: {
                  ...params?.InputProps?.style,
                  ...containerStyle,
                },
              }}
              {...textFieldProps}
            />
          )}
        />
      )}
      {type === "radio" && (
        <Select
          size="small"
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          sx={{ mt: 1 }}
          fullWidth
          SelectDisplayProps={{
            style: { ...containerStyle },
          }}
          placeholder="hello"
          renderValue={(selected) => selected}
          {...textFieldProps}
        >
          {options?.map((i) => {
            return (
              <MenuItem key={i.value} value={i.value}>
                <Radio
                  checked={i.value === value}
                  onChange={(e) => onChange(e.target.value)}
                  value="a"
                />
                <ListItemText primary={i.label} />
              </MenuItem>
            );
          })}
        </Select>
      )}
      {type === "button_group" && (
        <CustomButtonGroup
          variant="outlined"
          sx={{ mt: 1 }}
          aria-label="outlined primary button group"
        >
          {options?.map((i) => {
            return (
              <Button
                key={i.value}
                variant={value === i?.value ? "contained" : undefined}
                onClick={(e) => onChange(i?.value)}
              >
                {i.label}
              </Button>
            );
          })}
        </CustomButtonGroup>
      )}
      {type === "text_range" && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              // {...textFieldProps}
              label={prefixProps?.label}
              size="small"
              value={prefixProps?.value}
              onChange={(e) => prefixProps?.onChange?.(e.target.value)}
              sx={{ mt: 1 }}
              fullWidth
              InputProps={{ style: { ...containerStyle } }}
              {...prefixProps?.textFieldProps}
              // helperText={helperText || textFieldProps?.helperText}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              // {...textFieldProps}
              size="small"
              label={suffixProps?.label}
              value={suffixProps?.value}
              onChange={(e) => suffixProps?.onChange?.(e.target.value)}
              sx={{ mt: 1 }}
              fullWidth
              InputProps={{ style: { ...containerStyle } }}
              {...suffixProps?.textFieldProps}
              // helperText={helperText || textFieldProps?.helperText}
            />
          </Grid>
        </Grid>
      )}
      {type === "autocomplete" && (
        <Autocomplete
          multiple={multiple}
          value={value || []}
          onChange={(event, newValue) => {
            onChange(newValue);
          }}
          // inputValue={inputValue}
          // onInputChange={(event, newInputValue) => {
          //   setInputValue(newInputValue);
          // }}
          id="controllable-states-demo"
          options={options}
          // sx={{ width: 300 }}
          {...autocompleteProps}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params?.InputProps,
                style: {
                  ...params?.InputProps?.style,
                  ...containerStyle,
                },
              }}
              size="small"
              sx={{ mt: 1 }}
              {...textFieldProps}
            />
          )}
        />
        // <Autocomplete
        //   multiple
        //   value={value || []}
        //   onChange={(a, e) => onChange(e)}
        //   id="tags-outlined"
        //   options={options || []}
        //   getOptionLabel={(option) => option.label}
        //   renderTags={(tagValue, getTagProps) =>
        //     tagValue.map((option, index) => (
        //       <Chip
        //         label={option.label}
        //         size="small"
        //         {...getTagProps({ index })}
        //       />
        //     ))
        //   }
        //   filterSelectedOptions
        //   renderInput={(params) => (
        //     <TextField
        //       {...params}
        //       InputProps={{
        //         ...params?.InputProps,
        //         style: {
        //           ...params?.InputProps?.style,
        //           ...containerStyle,
        //         },
        //       }}
        //       size="small"
        //       sx={{ mt: 1 }}
        //       {...textFieldProps}
        //     />
        //   )}
        // />
      )}
      {/* <Select
          size="small"
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          sx={{ mt: 1 }}
          fullWidth
          SelectDisplayProps={{
            style: { ...containerStyle },
          }}
          placeholder="hello"
          renderValue={(selected) => selected}
          {...textFieldProps}
        >
          {options?.map((i) => {
            return (
              <MenuItem key={i.value} value={i.value}>
                <Radio
                  checked={i.value === value}
                  onChange={(e) => onChange(e.target.value)}
                  value="a"
                />
                <ListItemText primary={i.label} />
              </MenuItem>
            );
          })}
        </Select> */}
      {/* </HorizontalStack> */}
      {helperText && (
        <SecondaryLabelSmall
          sx={{ color: error ? "error.main" : "text.secondary" }}
        >
          {helperText}
        </SecondaryLabelSmall>
      )}
    </Box>
  );
};

export default CustomField;
