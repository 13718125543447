import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_SETTINGS } from "../../../../../redux/actions/settingActions";
import { getToken, getWebsite } from "../../../../../helper";
import { InputAdornment, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { setGlobalToast } from "../../../../../redux/actions/helperActions";
import axios from "axios";
import { Lock } from "@mui/icons-material";

export default function Configure() {
  const dispatch = useDispatch();
  const [brandName, setBrandName] = React.useState("");

  const reduxAmazonIntegration = useSelector(
    (state) => state.settings.amazonIntegration
  );
  const integratingAmazon = useSelector(
    (state) => state.settings.integratingAmazon
  );

  React.useEffect(() => {
    const brandName = reduxAmazonIntegration?.brand_name;
    setBrandName(brandName || "");
  }, []);

  const onSaveConfiguration = async () => {
    let website = getWebsite();
    let token = getToken();
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        integratingAmazon: true,
      },
    });

    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/amazon_integration`,
          method: "PUT",
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            setting: "amazon_integration",
            data: { brand_name: brandName, integrated: true },
          },
        });
        if (data.type === "success") {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              amazonIntegration: data.data,
            },
          });
          dispatch(
            setGlobalToast({
              show: true,
              message: "Configuration updated successfully",
              severity: "success",
            })
          );
        } else throw new Error();
      } catch (err) {
        console.log("Amazon revoke", err);

        dispatch(
          setGlobalToast({
            show: true,
            message: "Coudn't update configuration! Please try again.",
            severity: "error",
          })
        );
      }
    }
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        integratingAmazon: false,
      },
    });
  };

  return (
    <Box sx={{ padding: "12px 18px" }}>
      <Stack direction={"row"} alignItems={"center"} sx={{ gap: "30px" }}>
        <Typography
          variant="h6"
          // fontSize={"13px"}
          // mt={3}
        >
          Brand name
        </Typography>
      </Stack>
      <Stack direction={"row"} alignItems="center" sx={{ gap: "20px", mt: 2 }}>
        <TextField
          value={brandName}
          onChange={(e) => setBrandName(e.target.value)}
          // variant="outlined"
          size="small"
          sx={{ width: "350px" }}
          // fullWidth
          // sx={{ mt: 2 }}
          disabled={integratingAmazon}
          InputProps={
            integratingAmazon
              ? {
                  endAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }
              : null
          }
        />
        <LoadingButton
          size="small"
          loading={integratingAmazon}
          // disabled={isCamweraIntegrated}
          disabled={integratingAmazon || !brandName}
          variant="contained"
          onClick={onSaveConfiguration}
        >
          Save
        </LoadingButton>
      </Stack>
      <Stack alignItems={"flex-start"} sx={{ gap: "5px" }} marginTop={6}>
        <Typography
          variant="h6"
          fontSize={"13px"}
          // mt={3}
        >
          Your official registered brand name on Amazon.
        </Typography>
        <Typography
          // variant="h8"
          fontSize={"13px"}
          // mt={3}
        >
          Please make sure it is exactly the same as one from your Seller
          Central Dashboard, any differentiation in spacing, capitalization may
          leads to failure syncing products to Amazon.
        </Typography>
      </Stack>
    </Box>
  );
}
