import { Add } from "@material-ui/icons";
import {
  ArrowBack,
  Close,
  ContentCopy,
  Delete,
  Edit,
  Search,
  Sync,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import React, { memo, useEffect, useState } from "react";
import { useRef } from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalizeFirstLetter,
  dialogStyle,
  EmptyState,
  formatServerValue,
  getIntegrationObj,
  // getStoreName,
  isArrayWithValues,
  isObjWithValues,
  settingsEndpoint,
} from "../../helper";
// getWebsite
import {
  deleteTemplate,
  EDIT_CHATS,
  getTempletes,
} from "../../redux/actions/chatActions";

import CreateTemplete from "./CreateTemplete";
import CustomSwitch from "./CustomeSwitch";

import ProductMediaLibrary from "./ProductMediaLibrary";
import { useNavigate } from "react-router-dom";
import { WhatsappSvg } from "../../Svgs";
import NewMediaLibrary from "../../mediaLibrary/NewMediaLibrary";
import MessageTypeIcon from "../chat_new/components/message/MessageTypeIcon";
import { getFileType } from "../../utils/js";
import { setGlobalToast } from "../../redux/actions/helperActions";
import {
  SecondaryLabelExtraSmall,
  SecondaryLabelSmall,
} from "../../helper/utility/Labels";
// import { getWebsite } from "../../helper";

export const variables = [
  { label: "{First Name}", value: "first_name" },
  { label: "{Last Name}", value: "last_name" },
  { label: "{Fullname}", value: "fullname" },
  { label: "{City}", value: "city" },
  { label: "{Address}", value: "address" },
  { label: "{OTP}", value: "otp" },
  // { label: "Address", value: "address" },
  //   { label: "Whatsapp", value: "whatsapp" },
  //   { label: "Mobile", value: "mobile" },
  //   { label: "Profession", value: "profession" },
  //   { label: "Language", value: "language" },
  //   { label: "Ethinicity", value: "ethinicity" },
  //   { label: "Religion", value: "religion" },
];
export const template_variables = [
  { label: "{First Name}", value: "first_name" },
  { label: "{Last Name}", value: "last_name" },
  { label: "{Fullname}", value: "fullname" },
  { label: "{City}", value: "city" },
  { label: "{Address}", value: "address" },
  { label: "{Billing City}", value: "billing_city" },
  { label: "{Title}", value: "title" },
  { label: "{Due Date}", value: "due_date" },
  { label: "{Expiry}", value: "expiry" },
  { label: "{Pieces}", value: "pieces" },
  { label: "{Order Id}", value: "order_id" },
  { label: "{Order Note}", value: "order_note" },
  { label: "{Payment Method}", value: "payment_method" },
  { label: "{Amount}", value: "amount" },
  { label: "{Reference No.}", value: "reference_no" },

  // { label: "Address", value: "address" },
  //   { label: "Whatsapp", value: "whatsapp" },
  //   { label: "Mobile", value: "mobile" },
  //   { label: "Profession", value: "profession" },
  //   { label: "Language", value: "language" },
  //   { label: "Ethinicity", value: "ethinicity" },
  //   { label: "Religion", value: "religion" },
];
const filter = [
  { label: "ALL", value: "ALL" },
  // { label: "TRANSACTIONAL", value: "TRANSACTIONAL" },
  { label: "UTILITY", value: "UTILITY" },
  { label: "MARKETING", value: "MARKETING" },
  // { label: 'APPOINTMENT_UPDATE', value: 'APPOINTMENT_UPDATE' },
  // { label: 'SHIPPING_UPDATE', value: 'SHIPPING_UPDATE' },
  // { label: 'ACCOUNT_UPDATE', value: 'ACCOUNT_UPDATE' },
  // { label: 'TICKET_UPDATE', value: 'TICKET_UPDATE' },
  // { lable: 'ISSUE_RESOLUTION', value: 'ISSUE_RESOLUTION' }
];
const statusFilter = [
  { label: "Approved", value: "APPROVED" },
  { label: "Rejected", value: "REJECTED" },
  { label: "All", value: "all" },
];
const images = ["jpg", "gif", "png"];
const videos = ["mp4", "3gp", "ogg"];

const TempleteList = forwardRef(
  (
    {
      open,
      handleClose,
      setParentTemplate,
      hideRejected,
      viewOnly,
      asBox,
      setShowTemplateButton,
      hideBackButton,
      hideClose,
      label,
      newMediaLibrary,
      showAllVariables,
      hideTemplateActions,
      selectionMode,
    },
    ref
  ) => {
    const theme = useTheme();
    const isIpad = useMediaQuery(theme.breakpoints.down("md"));
    const [showCreateTemplate, setShowCreateTemplate] = useState(false);
    const [templateVariables, setTemplateVariables] = useState([]);
    const templateRef = useRef();
    useImperativeHandle(ref, () => ({
      onSendTemplete() {
        // console.log("hi");
        templateRef.current && templateRef.current.onSendTemplete();
      },
    }));
    useEffect(() => {
      setTemplateVariables(showAllVariables ? template_variables : variables);
    }, [showAllVariables]);

    if (asBox)
      return (
        <TempleteListContent
          open={open}
          handleClose={handleClose}
          setParentTemplate={setParentTemplate}
          hideRejected={hideRejected}
          viewOnly={viewOnly}
          asBox
          ref={templateRef}
          hideBackButton={hideBackButton}
          setShowTemplateButton={setShowTemplateButton}
          hideClose={hideClose}
          setShowCreateTemplete={setShowCreateTemplate}
          showCreateTemplete={showCreateTemplate}
          label={label}
          newMediaLibrary={newMediaLibrary}
          templateVariables={templateVariables}
          hideTemplateActions={hideTemplateActions}
          selectionMode={selectionMode}
        />
      );

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={Boolean(open)}
        onClose={() => {
          handleClose();
          // setSelectedTemplete(false);
        }}
        fullScreen={isIpad}
        hideBackdrop={asBox}
        sx={
          isIpad
            ? {}
            : {
                ...dialogStyle,
                height: "100%",
                visibility: showCreateTemplate ? "hidden" : "visible",
                transition: "all 0.3s",
              }
        }
      >
        <TempleteListContent
          open={open}
          handleClose={handleClose}
          setParentTemplate={setParentTemplate}
          hideRejected={hideRejected}
          viewOnly={viewOnly}
          asBox
          setShowCreateTemplete={setShowCreateTemplate}
          showCreateTemplete={showCreateTemplate}
          ref={templateRef}
          setShowTemplateButton={setShowTemplateButton}
          hideBackButton={hideBackButton}
          hideClose={hideClose}
          label={label}
          newMediaLibrary={newMediaLibrary}
          templateVariables={templateVariables}
          hideTemplateActions={hideTemplateActions}
          selectionMode={selectionMode}
        />
      </Dialog>
    );
  }
);

const TempleteListContent = memo(
  forwardRef(
    (
      {
        open,
        handleClose,
        setParentTemplate,
        hideRejected,
        viewOnly,
        asBox,
        setShowTemplateButton,
        showCreateTemplete,
        setShowCreateTemplete,
        hideBackButton,
        hideClose,
        label,
        newMediaLibrary,
        templateVariables,
        hideTemplateActions,
        selectionMode,
      },

      ref
    ) => {
      const [whatsappTempletes, setWhatsappTempletes] = useState([]);
      const [selectedTemplete, setSelectedTemplete] = useState(false);
      const [searchTerm, setSearchTerm] = useState("");
      const [headerVariable, setHeaderVariable] = useState("");

      //   const [headerInputVariable, setHeaderInputVariable] = useState("");
      const [bodyVariable, setBodyVaribale] = useState([]);
      const [filterValue, setFilterValue] = useState("ALL");
      const [status, setStatus] = useState("all");
      const reduxWhatsappTemplete = useSelector(
        (state) => state.chats.whatsappTempletes
      );
      const draftWhatsappTemplates = useSelector(
        (state) => state.chats.draftWhatsappTemplates
      );
      const reduxFetchingTemplete = useSelector(
        (state) => state.chats.fetchingTempletes
      );
      const metaIntegration = useSelector(
        (state) => state.settings.metaIntegration
      );
      const theme = useTheme();
      const isIpad = useMediaQuery(theme.breakpoints.down("md"));

      const dispatch = useDispatch();
      const navigate = useNavigate();

      useEffect(() => {
        // let reduxWhatsappTemplete = reduxWhatsappTemplete ||[]
        // if (isArrayWithValues(reduxWhatsappTemplete)) {
        let predefined = {
          ...preDefinedWhatsappTemplates,
          ...(draftWhatsappTemplates || {}),
        };
        let names = reduxWhatsappTemplete?.map((obj) => obj.name);
        if (isArrayWithValues(names))
          for (const key of names) {
            if (Object.keys(predefined).includes(key)) {
              delete predefined[key];
            }
          }

        // let arr = Object.values(preDefinedWhatsappTemplates).
        let arr = reduxWhatsappTemplete?.filter((obj) =>
          Object.keys(preDefinedWhatsappTemplates).includes(obj.name)
        );
        if (hideRejected)
          setWhatsappTempletes(
            reduxWhatsappTemplete?.filter((i) => i.status === "APPROVED")
          );
        else
          setWhatsappTempletes([
            ...(reduxWhatsappTemplete || []),
            ...Object.values(predefined || {})?.map((obj) => ({
              ...obj,
              draft: true,
            })),
            // ...preDefinedWhatsappTemplates,
          ]);
        // }
      }, [
        reduxWhatsappTemplete?.length,
        Object.values(draftWhatsappTemplates || {})?.length,
      ]);

      useEffect(() => {
        if (!isArrayWithValues(reduxWhatsappTemplete)) {
          dispatch(getTempletes());
        }
      }, []);
      useEffect(async () => {
        if (!isObjWithValues(draftWhatsappTemplates)) {
          let templates = await settingsEndpoint({
            endpoint: "draft_whatsapp_templates",
          });
          dispatch({
            type: EDIT_CHATS,
            payload: { draftWhatsappTemplates: templates },
          });
        }
      }, []);
      useEffect(() => {
        if (!isArrayWithValues(reduxWhatsappTemplete)) {
          dispatch(getTempletes());
          console.log("call");
        }
      }, []);
      // const [anchorEl, setAnchorEl] = React.useState(null);
      // const filterOpen = Boolean(anchorEl);
      // const FilterClose = () => {
      //   setAnchorEl(null);
      // };

      // const FilterClick = (event) => {
      //   setAnchorEl(event.currentTarget);
      // };
      const onSearch = (e) => {
        setSearchTerm(e.target.value);

        // console.log();
        if (e.target.value)
          setWhatsappTempletes(
            whatsappTempletes.filter((obj) =>
              JSON.stringify(obj)
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
            )
          );
        else {
          setWhatsappTempletes(reduxWhatsappTemplete);
        }
      };
      const [previewHeader, setPreviewHeader] = useState("");
      const [previewBody, setPreviewBody] = useState("");
      const [previewFooter, sePreviewFooter] = useState("");
      const [quickReplyButton, setQuickReplyButton] = useState([]);
      const [headerStr, setHeaderStr] = useState("");
      const [bodyStr, setBodyStr] = useState("");
      const [bodyVariableLenght, setBodyVariableLenght] = useState(0);
      const [linkVariable, setLinkVariable] = useState(null);
      const [linkButton, setButtonLink] = useState([]);
      const [deleting, setDeleting] = useState(false);
      const [urlStr, setUrlStr] = useState("");
      // const [showCreateTemplete, setShowCreateTemplete] = useState(false);
      const [headerError, setHeaderError] = useState(false);
      const [bodyError, setBodyError] = useState(false);
      const [buttonError, setButtonError] = useState(false);
      // const [headerMedia, setHeaderMedia] = useState("")
      const [hasError, setHasError] = useState({});
      const [headerImage, setHeaderImage] = useState("");
      const [showMedia, setShowMedia] = useState(false);
      const inistalSchedule = {
        enable: false,
        time: new Date(),
        scheduleTitle: "",
      };
      const [schedule, setSchedule] = useState(inistalSchedule);

      useEffect(() => {
        if (isObjWithValues(open)) {
          setSelectedTemplete(open);
          setShowTemplateButton && setShowTemplateButton(true);
        }
      }, [open]);

      useEffect(() => {
        setShowMedia(false);
        if (isObjWithValues(selectedTemplete)) {
          let predefinedValues = templateVariables.map((obj) => obj.label);
          // console.log(selectedTemplete);
          let body = selectedTemplete.components.find((i) => i.type === "BODY");
          setPreviewBody(body.text);

          // if (body?.example?.body_text?.[0]) {
          //   let arr = body?.example?.body_text?.[0]
          //   for (let index = 0; index < arr.length; index++) {
          //     const element = arr[index];
          //     setPreviewBody(pre => (replaceVariable(element, `{{${index + 1}}}`, pre)))
          //   }
          // }
          setBodyStr(body.text);

          setBodyVariableLenght(body?.text?.match(/{{/gi)?.length);
          setBodyVaribale(Array(body?.text?.match(/{{/gi)?.length)?.fill(""));

          let header = selectedTemplete?.components?.find(
            (i) => i.type === "HEADER"
          );

          if (header) {
            if (header.text) {
              setPreviewHeader(header.text);
              // setPreviewHeader(header.text);
              setHeaderStr(header.text);
            }
            if (header?.format && header?.format !== "TEXT") {
              setShowMedia(true);
            } else {
              setShowMedia(false);
            }
          } else {
            setPreviewHeader("");
            setHeaderStr("");
            setHeaderVariable("");
          }
          let footer = selectedTemplete.components.find(
            (i) => i.type === "FOOTER"
          );
          if (footer) {
            sePreviewFooter(footer.text);
          }
          let button = selectedTemplete.components.find(
            (i) => i.type === "BUTTONS"
          );
          if (button && button.buttons[0].type === "QUICK_REPLY") {
            setQuickReplyButton(button.buttons.map((i) => i.text));
          } else {
            setQuickReplyButton([]);
          }
          if (
            button &&
            button.buttons.filter(
              (b) => b.type === "URL" || b.type === "PHONE_NUMBER"
            )
          ) {
            setButtonLink(
              button.buttons
                .filter((b) => b.type === "URL" || b.type === "PHONE_NUMBER")
                .map((o) => o.text)
            );
          } else {
            setButtonLink([]);
          }
          if (
            button &&
            button.buttons?.find((v) => v?.url?.includes("{{1}}"))
          ) {
            let obj = button?.buttons?.find((v) => v?.url?.includes("{{1}}"));
            // console.log(obj);
            setUrlStr(obj.url.slice(0, -5));
            setLinkVariable([""]);
          } else {
            setLinkVariable([]);
          }
        }
      }, [selectedTemplete]);

      const replaceVariable = (currentText, replace, whole_text) => {
        let firstIndex = whole_text.indexOf(replace);
        let firstString = whole_text.substring(0, firstIndex);
        whole_text = whole_text.replace(replace, "");
        let lastString = whole_text.substring(firstIndex, whole_text.length);
        return `${firstString} ${currentText} ${lastString}`;
      };
      const onheadingchange = (e, a) => {
        // console.log(e.target.value,a );
        // return;

        setHeaderVariable(a);
        setPreviewHeader(replaceVariable(a, "{{1}}", headerStr));
        setHeaderError(false);
      };
      //   const onheadingInputchange = (e, a) => {
      //     // console.log(e.target.value,a );
      //     // return;
      //     setHeaderVariable("");
      //     setHeaderInputVariable(a);
      //     setPreviewHeader(replaceVariable(a, "{{1}}", headerStr));
      //     setHeaderError(false);
      //   };
      const onBodyChnage = (e, value, index) => {
        let newValue = bodyVariable.map((val, i) =>
          index === i ? value : val
        );
        setBodyError(false);
        setBodyVaribale(newValue);
        // console.log(`<<<${replaceVariable(value || `{{${index + 1}}}`, `{{${index + 1}}}`, bodyStr)}`);
        // return
        setPreviewBody(replaceVariable(value, `{{${index + 1}}}`, bodyStr));
        // console.log();
        // return
      };
      const onSendTemplete = async () => {
        let template = {
          name: selectedTemplete.name,
          language: { code: selectedTemplete.language },
          components: [],
        };
        let body =
          isArrayWithValues(selectedTemplete.components) &&
          selectedTemplete.components.find((i) => i.type === "BODY");
        let header =
          isArrayWithValues(selectedTemplete.components) &&
          selectedTemplete.components.find((i) => i.type === "HEADER");
        let button =
          isArrayWithValues(selectedTemplete.components) &&
          selectedTemplete.components.find((i) => i.type === "BUTTONS");
        if (header?.text?.includes("{{1}}")) {
          if (!headerVariable) {
            setHeaderError(true);
            return;
          }
          let headerObj = {
            type: "HEADER",
            parameters: [{ type: "text", text: headerVariable }],
          };
          template.components.push(headerObj);
        }
        if (header?.format && header?.format?.toLowerCase() !== "text") {
          if (!headerImage) {
            setHeaderError(true);
            return;
          }
          let headerObj = {
            type: "HEADER",
            parameters: [
              {
                type: header?.format?.toLowerCase(),
                [header?.format?.toLowerCase()]: { link: headerImage },
              },
            ],
          };
          template.components.push(headerObj);
        }
        if (body?.text?.includes("{{")) {
          if (bodyVariable.includes("")) {
            setBodyError(true);
            return;
          }
          let bodyObj = {
            type: "BODY",
            parameters: bodyVariable.map((v) => ({ type: "text", text: v })),
          };
          template.components.push(bodyObj);
        }
        if (button?.buttons?.find((v) => v?.url?.includes("{{1}}"))) {
          if (!isArrayWithValues(linkVariable)) {
            setButtonError(true);
            return;
          }
          let index = button.buttons.findIndex((v) =>
            v?.url?.includes("{{1}}")
          );
          // console.log("buttonIndex", index);
          let buttonObj = {
            type: "BUTTON",
            sub_type: "url",
            parameters: [{ type: "text", text: linkVariable[0] }],
          };
          buttonObj = { ...buttonObj, index };
          template.components.push(buttonObj);
        }
        // console.log(template.components);
        // return
        if (schedule?.enable) {
          if (!schedule?.scheduleTitle) {
            setHasError((pre) => ({ ...pre, scheduleTitle: true }));
            return;
          }
          if (new Date(schedule?.time).getTime() < new Date().getTime()) {
            return;
          }
        }

        setParentTemplate(template, { ...schedule }, { ...selectedTemplete });
        setSchedule(inistalSchedule);
        setSelectedTemplete(false);
        setShowTemplateButton && setShowTemplateButton(false);
        setButtonLink([]);
        setLinkVariable(null);
        setHeaderImage("");
        setBodyError(false);
        setHeaderError(false);
        setButtonError(false);
        handleClose();
      };
      const onDeleteTemplate = (e, obj) => {
        e.stopPropagation();
        // console.log("hi");
        dispatch(deleteTemplate(obj, setDeleting));
      };
      const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);
      const [mediaLibraryImage, setMediaLibraryImage] = useState([]);
      const setImages = (arr) => {
        // console.log(arr);
        if (isArrayWithValues(arr)) setMediaLibraryImage(arr);
        setHeaderImage(arr[0]?.source_url);
        setMediaLibraryOpen(false);
      };
      const onStatusChange = (e) => {
        setStatus(e.target.value);
        if (e.target.value === "all") {
          setWhatsappTempletes(reduxWhatsappTemplete);
        } else {
          setWhatsappTempletes(
            reduxWhatsappTemplete.filter((o) => o.status === e.target.value)
          );
        }
      };
      const onFilterChange = (e) => {
        setFilterValue(e.target.value);
        if (isArrayWithValues(reduxWhatsappTemplete))
          if (e.target.value === "ALL") {
            setWhatsappTempletes(reduxWhatsappTemplete);
          } else {
            setWhatsappTempletes(
              reduxWhatsappTemplete.filter((o) => o.category === e.target.value)
            );
          }
      };

      useImperativeHandle(ref, () => ({
        onSendTemplete() {
          // console.log("hi");
          onSendTemplete();
        },
      }));

      const onTemplateMediaSelected = (media) => {
        console.log(
          media,
          selectedTemplete?.components?.find((i) => i.type === "HEADER")
        );
        let formatRequired = selectedTemplete?.components
          ?.find((i) => i.type === "HEADER")
          ?.format?.toLowerCase();
        let extension = media?.[0]?.file_type;
        let formatGot = getFileType(extension);
        if (formatRequired !== formatGot)
          return dispatch(
            setGlobalToast({
              show: true,
              // message: `Please select ${capitalizeFirstLetter(formatRequired)}`,
              message: `Invalid file format selected`,
              severity: "error",
            })
          );
        setImages(media);
      };
      const onCopyToClipboard = (link) => {
        // setCopyTooltip("Copied!");
        navigator.clipboard.writeText(link);
        dispatch(
          setGlobalToast({
            show: true,
            message: "Copied to clipboard",
            severity: "success",
          })
        );
        // setTimeout(() => setCopyTooltip("Copy"), 900);
      };

      return (
        <>
          {/* <Dialog
        fullWidth
        maxWidth="md"
        open={Boolean(open)}
        onClose={() => {
          handleClose();
          setSelectedTemplete(false);
        }}
        fullScreen={isIpad}
        hideBackdrop={asBox}
        sx={
          isIpad
            ? {}
            : {
              ...dialogStyle,
              height: "100%",
              visibility: showCreateTemplete ? "hidden" : "visible",
              transition: "all 0.3s",
            }
        }
      > */}
          {isObjWithValues(
            getIntegrationObj(metaIntegration, "whatsapp")?.obj
          ) &&
          getIntegrationObj(metaIntegration, "whatsapp")?.obj
            ?.selected_whatsapp_phone_id ? (
            <>
              <DialogTitle>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={isIpad ? { width: "-webkit-fill-available" } : {}}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      {true ? (
                        <>
                          {selectedTemplete && (
                            <>
                              {!hideBackButton ? (
                                <IconButton
                                  onClick={() => {
                                    setShowTemplateButton &&
                                      setShowTemplateButton(false);
                                    setSelectedTemplete(false);
                                  }}
                                >
                                  <ArrowBack />
                                </IconButton>
                              ) : null}
                            </>
                          )}
                        </>
                      ) : null}

                      <Typography variant="h6">
                        {selectionMode
                          ? "Select whatsapp template"
                          : "WhatsApp Templates"}
                      </Typography>
                    </Box>
                    <Box>
                      {!selectedTemplete && isIpad && (
                        <IconButton onClick={() => dispatch(getTempletes())}>
                          <Sync />
                        </IconButton>
                      )}
                      {!selectedTemplete && isIpad && (
                        <Button
                          onClick={() => {
                            setShowCreateTemplete(true);
                          }}
                          startIcon={<Add />}
                          size="small"
                          variant="contained"
                        >
                          Create
                        </Button>
                      )}
                    </Box>
                  </Stack>

                  {/* <Button onClick={() => setSelectedTemplete(false)}>Back</Button> */}
                  {!isIpad ? (
                    <Stack direction={"row"} sx={{ gap: "10px" }}>
                      {!selectedTemplete && (
                        <LoadingButton
                          startIcon={<Sync />}
                          size="small"
                          variant="outlined"
                          loading={reduxFetchingTemplete}
                          onClick={() => dispatch(getTempletes())}
                        >
                          Refresh
                        </LoadingButton>
                      )}
                      {!selectedTemplete && (
                        <Button
                          onClick={() => {
                            setShowCreateTemplete(true);
                          }}
                          startIcon={<Add />}
                          size="small"
                          variant="contained"
                        >
                          Create Template
                        </Button>
                      )}
                    </Stack>
                  ) : null}
                  {selectedTemplete ? (
                    !hideClose ? null : (
                      <Button onClick={onSendTemplete} variant="contained">
                        {!hideClose ? "" : "Send"}
                      </Button>
                    )
                  ) : null}
                </Stack>
              </DialogTitle>
              <DialogContent>
                {showCreateTemplete && (
                  <CreateTemplete
                    open={showCreateTemplete}
                    close={() => setShowCreateTemplete(false)}
                  />
                )}
                {!selectedTemplete && (
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"space-between"}
                    sx={{ gap: "20px", mb: 4 }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="Search"
                      value={searchTerm}
                      onChange={onSearch}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Select
                      size="small"
                      value={filterValue}
                      onChange={onFilterChange}
                    >
                      {filter.map((i) => (
                        <MenuItem key={i.value} value={i.value}>
                          {i.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {!hideRejected && (
                      <Select
                        size="small"
                        value={status}
                        onChange={onStatusChange}
                      >
                        {statusFilter.map((i) => (
                          <MenuItem value={i.value}>{i.label}</MenuItem>
                        ))}
                      </Select>
                    )}
                  </Stack>
                )}
                {!selectedTemplete && (
                  <>
                    {reduxFetchingTemplete ? (
                      <Stack
                        sx={{ rowGap: "10px" }}
                        className="scrollbar-hidden"
                      >
                        {Array(8)
                          .fill("")
                          .map((i) => (
                            <Card
                              sx={{
                                padding: 3,
                                ":hover": !viewOnly
                                  ? {
                                      backgroundColor: "#f1f1f1",
                                      cursor: "pointer",
                                    }
                                  : {},
                              }}
                            >
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem", width: "15vw" }}
                              />
                              <Stack
                                direction={"row"}
                                justifyContent="space-between"
                              >
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem", width: "10vw" }}
                                />
                                <Stack direction={"row"} sx={{ gap: "10px" }}>
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", width: "40px" }}
                                  />{" "}
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "1rem", width: "40px" }}
                                  />
                                </Stack>
                              </Stack>
                            </Card>
                          ))}
                      </Stack>
                    ) : null}
                  </>
                )}
                {!selectedTemplete && !reduxFetchingTemplete && (
                  <>
                    <Stack sx={{ rowGap: "10px" }} className="scrollbar-hidden">
                      {isArrayWithValues(whatsappTempletes) && (
                        <>
                          {whatsappTempletes.map((item) => {
                            let body = "";
                            let components = item?.components || [];
                            let headerObj = components.find(
                              (o) => o.type === "HEADER"
                            );
                            let bodyObj = components.find(
                              (o) => o.type === "BODY"
                            );
                            if (bodyObj) {
                              body = bodyObj.text;
                            }
                            if (headerObj && headerObj.type === "text") {
                              body = headerObj.text;
                            }

                            return (
                              <Card
                                sx={{
                                  padding: 3,
                                  ":hover": !viewOnly
                                    ? {
                                        backgroundColor: "#f1f1f1",
                                        cursor: "pointer",
                                      }
                                    : {},
                                }}
                                onClick={() => {
                                  // item?.status &&
                                  !viewOnly &&
                                    item?.status === "APPROVED" &&
                                    setSelectedTemplete(item);
                                  setShowTemplateButton &&
                                    setShowTemplateButton(true);
                                }}
                              >
                                <Stack
                                  direction={"row"}
                                  justifyContent="space-between"
                                >
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={
                                      isIpad ? "space-between" : ""
                                    }
                                    spacing={2}
                                  >
                                    <Typography
                                      variant="h6"
                                      sx={{ fontSize: "14px" }}
                                    >
                                      {formatServerValue(item.name)}
                                    </Typography>
                                    <Stack direction={"rows"} gap={"10px"}>
                                      {!item?.status && (
                                        <Chip
                                          size="small"
                                          ml={1}
                                          // mb={1}
                                          label={"Draft"}
                                          // color="warning"
                                          sx={{
                                            color:
                                              item?.status === "REJECTED"
                                                ? "#910905"
                                                : "#1aae6f",
                                            // border: isDarkTheme ? "0.7px solid" : "0px solid",
                                            // borderColor: statusFontColor(row.status),
                                            backgroundColor:
                                              //  isDarkTheme? "transparent":
                                              item?.status === "REJECTED"
                                                ? "#f2e5e8"
                                                : "#e5eae8",
                                            fontSize: "11px",
                                            height: "auto",
                                            py: 0.3,
                                          }}
                                        />
                                      )}
                                      {item?.status && (
                                        <Chip
                                          size="small"
                                          ml={1}
                                          // mb={1}
                                          label={capitalizeFirstLetter(
                                            item?.status
                                          )}
                                          // color="warning"
                                          sx={{
                                            color:
                                              item?.status === "REJECTED"
                                                ? "#910905"
                                                : "#1aae6f",
                                            // border: isDarkTheme ? "0.7px solid" : "0px solid",
                                            // borderColor: statusFontColor(row.status),
                                            backgroundColor:
                                              //  isDarkTheme? "transparent":
                                              item?.status === "REJECTED"
                                                ? "#f2e5e8"
                                                : "#e5eae8",
                                            fontSize: "11px",
                                            height: "auto",
                                            py: 0.3,
                                          }}
                                        />
                                      )}
                                      <Chip
                                        size="small"
                                        ml={1}
                                        // mb={1}
                                        label={capitalizeFirstLetter(
                                          item?.category
                                        )}
                                        // color="warning"
                                        sx={{
                                          color:
                                            item.category === "TRANSACTIONAL"
                                              ? "#5e66e5"
                                              : "#1aae6f",
                                          // border: isDarkTheme ? "0.7px solid" : "0px solid",
                                          // borderColor: statusFontColor(row.status),
                                          backgroundColor:
                                            //  isDarkTheme? "transparent":
                                            item.category === "TRANSACTIONAL"
                                              ? "#edf0fa"
                                              : "#e5eae8",
                                          fontSize: "11px",
                                          height: "auto",
                                          py: 0.3,
                                        }}
                                      />
                                    </Stack>
                                  </Stack>

                                  {/* <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                        {item.category
                          ? capitalizeFirstLetter(item.category)
                          : ""}
                      </Typography> */}
                                </Stack>

                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                  mt={2}
                                >
                                  <SecondaryLabelExtraSmall
                                    variant="h6"
                                    sx={{ fontSize: "14px" }}
                                  >
                                    {item.name}
                                  </SecondaryLabelExtraSmall>
                                  <ContentCopy
                                    onClick={() => onCopyToClipboard(item.name)}
                                    style={{
                                      height: "14px",
                                      width: "14px",
                                      cursor: "pointer",
                                      color: theme.palette.text.secondary,
                                    }}
                                  />
                                </Stack>
                                <Stack
                                  direction={isIpad ? "column" : "row"}
                                  justifyContent="space-between"
                                  alignItems={isIpad ? "flex-start" : "center"}
                                  sx={{ gap: "10px" }}
                                >
                                  <Typography
                                    sx={{
                                      mt: 2,
                                      fontSize: "12px",
                                      color: "text.secondary",
                                      maxWidth: "700px",
                                    }}
                                  >
                                    {body}
                                  </Typography>
                                  {!Boolean(hideTemplateActions) && (
                                    <>
                                      <Stack
                                        direction={"row"}
                                        sx={
                                          isIpad
                                            ? {
                                                gap: "10px",
                                                width: "-webkit-fill-available",
                                              }
                                            : { gap: "10px" }
                                        }
                                        alignItems={"center"}
                                        justifyContent="flex-end"
                                      >
                                        <IconButton
                                          onClick={() =>
                                            setShowCreateTemplete(item)
                                          }
                                        >
                                          <Edit />
                                        </IconButton>
                                        {/* <LoadingButton
                                      loading={deleting === item.id}
                                      size="small"
                                      variant="outlined"
                                      color="error"
                                      startIcon={<Delete />}
                                      sx={{ minWidth: "81px" }}
                                      onClick={(e) => onDeleteTemplate(e, item)}
                                    >
                                      Delete
                                    </LoadingButton> */}
                                        <DeletePopover
                                          onDeleteTemplate={onDeleteTemplate}
                                          loading={deleting === item.id}
                                          item={item}
                                        />
                                      </Stack>
                                    </>
                                  )}
                                </Stack>
                              </Card>
                            );
                          })}
                        </>
                      )}
                      {!isArrayWithValues(whatsappTempletes) && (
                        <Stack>
                          <EmptyState
                            heading={"No Template Found"}
                            // text={"Whatsapp is Not Integrated!"}
                            Box
                            icon={<WhatsappSvg />}
                            button={
                              <Button
                                variant="contained"
                                onClick={() => {
                                  setShowCreateTemplete(true);
                                }}
                              >
                                Create Template
                              </Button>
                            }
                          />
                        </Stack>
                      )}
                    </Stack>
                  </>
                )}
                {selectedTemplete && (
                  <Grid container>
                    <Grid item xs={12} md={8} lg={8} sx={{ padding: "20px" }}>
                      {showMedia && (
                        <>
                          <Typography variant="h6">
                            Header{" "}
                            {selectedTemplete?.components?.find(
                              (i) => i.type === "HEADER"
                            )?.format &&
                              `(${capitalizeFirstLetter(
                                selectedTemplete?.components?.find(
                                  (i) => i.type === "HEADER"
                                )?.format || ""
                              )})`}
                          </Typography>
                          <Button
                            onClick={() => setMediaLibraryOpen(true)}
                            variant="contained"
                            sx={{ mt: 1, mb: 2 }}
                            endIcon={
                              <MessageTypeIcon
                                type={selectedTemplete?.components
                                  ?.find((i) => i.type === "HEADER")
                                  ?.format?.toLowerCase()}
                              />
                            }
                          >
                            Select{" "}
                            {`${capitalizeFirstLetter(
                              selectedTemplete?.components?.find(
                                (i) => i.type === "HEADER"
                              )?.format || ""
                            )}`}
                          </Button>
                        </>
                      )}
                      {headerStr?.includes("{{1}}") ? (
                        <Stack sx={{ rowGap: "5px", mb: 2 }}>
                          <Typography variant="h6" sx={{ mb: 1 }}>
                            Header
                          </Typography>
                          <Autocomplete
                            freeSolo
                            size="small"
                            // value={headerVariable}
                            // onChange={(e, a) => onheadingchange(e, a)}
                            inputValue={headerVariable}
                            onInputChange={(e, a) => onheadingchange(e, a)}
                            options={templateVariables?.map(
                              (option) => option.label
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label="freeSolo"

                                placeholder="Enter content for {{1}}"
                                error={headerError}
                              />
                            )}
                          />
                          {/* <TextField
                    size="small"
                    placeholder="Enter content for {{1}}"
                    error={headerError}
                    value={headerVariable}
                    onChange={onheadingchange}
                  /> */}
                        </Stack>
                      ) : null}
                      {bodyVariableLenght && (
                        <>
                          <Typography variant="h6" sx={{ mb: 1 }}>
                            Body
                          </Typography>
                          <Grid container spacing={2} rowSpacing={2}>
                            {Array(bodyVariableLenght)
                              .fill(bodyVariableLenght)
                              .map((v, index) => (
                                <Grid item xs={12} sm={6}>
                                  <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    size="small"
                                    // value={headerVariable}
                                    // onChange={(e, a) => onheadingchange(e, a)}
                                    inputValue={bodyVariable[index]}
                                    onInputChange={(e, value) => {
                                      // console.log(value, index);

                                      onBodyChnage(e, value, index);
                                    }}
                                    onBlur={(e) => {
                                      e.target.value && setBodyStr(previewBody);
                                    }}
                                    options={templateVariables.map(
                                      (option) => option.label
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        // label="freeSolo"
                                        placeholder={`Enter content for {{${
                                          index + 1
                                        }}}`}
                                        error={bodyError}
                                      />
                                    )}
                                  />
                                  {/* <TextField
                            fullWidth
                            size="small"
                            error={bodyError}
                            placeholder={`Enter content for {{${++index}}}`}
                            onChange={(e) => {
                              onBodyChnage(e, index);
                            }}
                            onBlur={(e) => {
                              e.target.value && setBodyStr(previewBody);
                            }}
                          />{" "} */}
                                </Grid>
                              ))}
                          </Grid>
                        </>
                      )}
                      {isArrayWithValues(linkVariable) && (
                        <>
                          <Typography variant="h6" sx={{ mb: 1, mt: 2 }}>
                            Link Button
                          </Typography>
                          <TextField
                            size="small"
                            error={buttonError}
                            placeholder="Enter content for dynamic link"
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {urlStr}
                                </InputAdornment>
                              ),
                            }}
                            value={linkVariable[0]}
                            onChange={(e) => setLinkVariable([e.target.value])}
                          />
                        </>
                      )}

                      {/* <Stack sx={{ mt: 3, mb: 2 }}>
                <Stack direction={"row"} gap={"30px"}>
                  <FormLabel>Schedule</FormLabel>{" "}
                  <CustomSwitch
                    checked={schedule?.enable}
                    onChange={(e) => {
                      setSchedule((pre) => ({ ...pre, enable: e }));
                    }}
                  />
                </Stack>
                <Collapse in={schedule?.enable}>
                  <Box sx={{}}>
                    <Stack sx={{ mt: 1, mb: 3 }}>
                      <FormLabel sx={{ marginTop: "14px" }}>Schedule Title</FormLabel>
                      <TextField
                        // fullWidth
                        size="small"
                        placeholder="Schedule Title"
                        // variant="standard"
                        error={hasError?.scheduleTitle}
                        helperText={hasError?.scheduleTitle ? "Schedule title is required* " : ""}
                        value={schedule?.scheduleTitle}
                        onChange={(e) => {
                          setSchedule(pre => ({ ...pre, scheduleTitle: e.target.value }));
                          setHasError((pre) => ({ ...pre, scheduleTitle: false }));
                        }}
                      />
                    </Stack>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDateTimePicker
                        error={hasError?.time}
                        value={schedule?.time ? schedule?.time : null}
                        onChange={(newValue) => {
                          setHasError((pre) => ({ ...pre, time: new Date(newValue).getTime() < new Date().getTime() }));
                          setSchedule(pre => ({ ...pre, time: newValue }));
                        }}

                        renderInput={(params) => (
                          <TextField {...params} size="small"
                            helperText={hasError?.time ? "Time must be of future" : ""} error={hasError?.time}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Collapse>
              </Stack> */}
                    </Grid>
                    <Grid item md={4} lg={4} xs={12}>
                      <Box
                        sx={{
                          backgroundImage:
                            "url(/static/img/whatsappBackground.png)",
                          height: "100%",
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        <Typography
                          sx={{ color: "#808080", fontWeight: "600" }}
                          variant="subtitle1"
                        >
                          Preview
                        </Typography>
                        <Box sx={{ height: "10px" }}></Box>
                        <Card sx={{ width: "90%", padding: "7px" }}>
                          {/* {previewHeader && (
                                        <Box
                                            sx={{
                                                backgroundColor: "#CCD0D5",
                                                borderRadius: "3px",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yL/r/NztUdBQHuX5.png" />
                                        </Box>
                                    )} */}
                          {headerImage &&
                            (images.includes(
                              new URL(headerImage).pathname.split(".")[1]
                            ) ? (
                              <img
                                alt={"example"}
                                src={headerImage}
                                style={{ width: "100%", borderRadius: 2 }}
                              />
                            ) : videos.includes(
                                new URL(headerImage).pathname.split(".")[1]
                              ) ? (
                              <video width="100%" controls>
                                <source src={headerImage} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            ) : null)}
                          {previewHeader && (
                            <Typography variant="h6">
                              {previewHeader}
                            </Typography>
                          )}
                          <div
                            dangerouslySetInnerHTML={{ __html: previewBody }}
                          ></div>
                          {previewFooter && (
                            <Typography
                              variant="caption"
                              sx={{ color: "#A9A9A9" }}
                            >
                              {previewFooter}
                            </Typography>
                          )}
                        </Card>
                        {isArrayWithValues(quickReplyButton) && (
                          <Grid
                            container
                            sx={{ width: "90%", mt: 1 }}
                            spacing={quickReplyButton.length === 1 ? 0 : 2}
                          >
                            {quickReplyButton.map((b, index) => (
                              <Grid
                                item
                                xs={
                                  quickReplyButton.length === 1
                                    ? 12
                                    : quickReplyButton.length === 2
                                    ? 6
                                    : quickReplyButton.length === 3
                                    ? b.index !== 3
                                      ? 6
                                      : 12
                                    : null
                                }
                              >
                                <Card sx={{ padding: 1 }}>
                                  <Typography sx={{ textAlign: "center" }}>
                                    {b}
                                  </Typography>
                                </Card>
                              </Grid>
                            ))}
                          </Grid>
                        )}

                        {/* {isArrayWithValues(linkButton) ? <Box>hi</Box> : null} */}
                        {isArrayWithValues(linkButton) && (
                          <Grid
                            container
                            sx={{ width: "90%", mt: 1 }}
                            spacing={linkButton.length === 1 ? 0 : 2}
                          >
                            {linkButton.map((b, index) => (
                              <Grid
                                item
                                xs={
                                  linkButton.length === 1
                                    ? 12
                                    : linkButton.length === 2
                                    ? 6
                                    : linkButton.length === 3
                                    ? b.index !== 3
                                      ? 6
                                      : 12
                                    : null
                                }
                              >
                                <Card sx={{ padding: 1 }}>
                                  <Typography sx={{ textAlign: "center" }}>
                                    {b}
                                  </Typography>
                                </Card>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {newMediaLibrary ? (
                  <NewMediaLibrary
                    open={mediaLibraryOpen}
                    onChange={() => {
                      setMediaLibraryOpen(false);
                    }}
                    handleClose={() => {
                      setMediaLibraryOpen(false);
                    }}
                    setParentImages={onTemplateMediaSelected}
                    multiple={false}
                    enable_crop={false}
                  />
                ) : (
                  <ProductMediaLibrary
                    open={mediaLibraryOpen}
                    onChange={() => {
                      setMediaLibraryOpen(false);
                    }}
                    handleClose={() => {
                      setMediaLibraryOpen(false);
                    }}
                    setParentImages={setImages}
                    multiple={false}
                    enable_crop={false}
                  />
                )}
              </DialogContent>
              {!setShowTemplateButton && (
                <DialogActions>
                  <Button
                    onClick={() => {
                      handleClose();
                      setSelectedTemplete(false);
                      setShowTemplateButton && setShowTemplateButton(false);
                    }}
                  >
                    {hideClose ? "" : "Cancel"}
                  </Button>
                  {!viewOnly && isObjWithValues(selectedTemplete) && (
                    <Button onClick={onSendTemplete}>
                      {hideClose ? "" : label || "Send"}
                    </Button>
                  )}
                </DialogActions>
              )}
            </>
          ) : (
            <>
              <DialogTitle
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton onClick={() => handleClose()}>
                  <Close />
                </IconButton>
              </DialogTitle>
              <EmptyState
                heading={"WhatsApp Not Integrated!"}
                // text={"Whatsapp is Not Integrated!"}
                Box
                icon={<WhatsappSvg height={50} width={50} />}
                button={
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate(`/integration#meta`);
                    }}
                  >
                    Integrate Meta
                  </Button>
                }
              />
            </>
          )}
          {/* </Dialog> */}
        </>
      );
    }
  )
);

export default TempleteList;

function DeletePopover({ onDeleteTemplate, item, loading }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onDelete = (e) => {
    handleClose();
    onDeleteTemplate(e, item);
  };

  return (
    <div>
      <LoadingButton
        loading={loading}
        size="small"
        variant="outlined"
        color="error"
        startIcon={<Delete />}
        sx={{ minWidth: "81px" }}
        onClick={handleClick}
        // onClick={(e) => onDeleteTemplate(e, item)}
      >
        Delete
      </LoadingButton>
      {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this template permanently?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          <LoadingButton
            // loading={loading}
            size="small"
            variant="outlined"
            color="error"
            startIcon={<Delete />}
            sx={{ minWidth: "81px" }}
            onClick={(e) => onDelete(e)}
          >
            Delete
          </LoadingButton>
          {/* <Button onClick={handleClose} autoFocus>
            Delete
          </Button> */}
        </DialogActions>
        {/* <Typography sx={{ p: 2 }}>Delete this template permanently?</Typography> */}
      </Popover>
    </div>
  );
}

let blogname = "tanika";
// let blogname = getStoreName()

const preDefinedWhatsappTemplates = {
  anniversary: {
    name: "anniversary",
    category: "MARKETING",
    language: "en",
    components: [
      {
        type: "BODY",
        text: `We're delighted you chose us to celebrate this beautiful union.\n Team ${blogname} extends their heartfelt congratulations. 🎉\n\nEnjoy your lovely anniversary with us and get a special discount personalised just for you. 😍`,
      },
      {
        type: "HEADER",
        format: "TEXT",
        example: {
          header_text: ["user"],
        },
        text: "Happy Anniversary {{1}} ",
      },
      {
        type: "BUTTONS",
        buttons: [
          {
            type: "URL",
            text: "Explore Products",
            url: `${"abc"}{{1}}`,
            example: ["/product"],
          },
        ],
      },
    ],
  },
  create_account: {
    name: "account_testing",
    category: "MARKETING",
    language: "en",
    components: [
      {
        type: "BODY",
        text: `Congratulations *{{1}}* on your Successful Registration.\nExplore wide range of jewellery from *${blogname}*.\nWe hope you have a wonderful journey with us.\n\nFor any assistance reach out to us via [settings/whatsAppNum] or reply to this message.`,
        example: {
          body_text: ["blogname"],
        },
      },
      {
        type: "HEADER",
        format: "TEXT",
        example: {
          header_text: ["user"],
        },
        text: "Welcome to {{1}}",
      },
      {
        type: "BUTTONS",
        buttons: [
          {
            type: "URL",
            text: "Explore Products",
            url: "https://tiarabytj.com{{1}}",
            example: ["/product"],
          },
        ],
      },
    ],
  },
  digital_gold_subscription_remainder: {
    name: "digital_gold_subscription_remainder",
    category: "MARKETING",
    language: "en",
    components: [
      {
        type: "BODY",
        text: "Your payment for {{1}} plan is due within {{2}} days. agdam \nTotal Due : {{3}}\nDue to be paid by : {{4}} \nFor any assistance reach out to us via [settings/whatsAppNum] or reply to this message.",
        example: {
          body_text: ["blogname", "ws", "wqs", "dd"],
        },
      },
      {
        type: "HEADER",
        format: "TEXT",
        example: {
          header_text: ["user"],
        },
        text: "Hello {{1}}",
      },
      {
        type: "BUTTONS",
        buttons: [
          {
            type: "QUICK_REPLY",
            text: "buy now",
          },
        ],
      },
    ],
  },
  digigold_monthly_ledger: {
    name: "digigold_monthly_ledger",
    category: "MARKETING",
    language: "en",
    components: [
      {
        type: "BODY",
        text: "Hi {{1}},\nPlease find *{{2}}* ledger for the month of {{3}}\n\nFor any assistance reach out to us via [settings / whatsAppNum] or reply to this message.",
        example: {
          body_text: ["blogname", "ws", "wqs", "dd"],
        },
      },
      {
        type: "HEADER",
        format: "TEXT",
        example: {
          header_text: ["user"],
        },
        text: `Powered by ${blogname}`,
      },
      {
        type: "BUTTONS",
        buttons: [
          {
            type: "URL",
            text: "Go to Ledger",
            url: "https://tiarabytj.com{{1}}",
            example: ["/product"],
          },
        ],
      },
    ],
  },
  birthday: {
    name: "birthday",
    category: "MARKETING",
    language: "en",
    components: [
      {
        type: "BODY",
        text: `We have a special discount just for you 😍\nIt’s your day to shine bright like a diamond.\n\n Enjoy this wonderful occasion with ${blogname}.\n Celebrate with shimmer and shine. ✨`,
        example: {
          body_text: ["blogname", "ws", "wqs", "dd"],
        },
      },
      {
        type: "HEADER",
        format: "TEXT",
        example: {
          header_text: ["user"],
        },
        text: `Happy Birthday {{1}}!`,
      },
      {
        type: "BUTTONS",
        buttons: [
          {
            type: "URL",
            text: "Explore Products",
            url: "https://tiarabytj.com{{1}}",
            example: ["/product"],
          },
        ],
      },
    ],
  },
  new_order_received: {
    name: "new_order_received",
    category: "MARKETING",
    language: "en",
    components: [
      {
        type: "BODY",
        text: `Woohoo🤩🎉\n
Your order *[Order No.]{{1}} of *[Product name]{{2}}* for *[Amount]{{3}}* has been confirmed & will reach you shortly! 🛳\n
Thanks for shopping with us. 😇
`,
        example: {
          body_text: ["blogname", "ws", "wqs", "dd"],
        },
      },
      {
        type: "BUTTONS",
        buttons: [
          {
            type: "URL",
            text: "View Order Details",
            url: "https://tiarabytj.com{{1}}",
            example: ["/product"],
          },
        ],
      },
    ],
  },
  "order_note ": {
    name: "new_order_received",
    category: "MARKETING",
    language: "en",
    components: [
      {
        type: "BODY",
        text: `Take a look at the update on your order number #123.\n\nNote/Information : 
*{{1}}*\n\nFor any assistance reach out to us via [settings/whatsAppNum] or reply to this message.`,
        example: {
          body_text: ["blogname"],
        },
      },
      {
        type: "HEADER",
        format: "TEXT",
        example: {
          header_text: ["user"],
        },
        text: `Hi {{1}},`,
      },
      {
        type: "BUTTONS",
        buttons: [
          {
            type: "URL",
            text: "View Order Details",
            url: "https://tiarabytj.com{{1}}",
            example: ["/product"],
          },
        ],
      },
    ],
  },
};
