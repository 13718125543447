import { Add, CollectionsOutlined, FolderOpen } from "@mui/icons-material";
import {
  Button,
  CardContent,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { createContext, useEffect, useRef, useState } from "react";
import {
  TabPanel,
  backendApiCall,
  dialogStyle,
  getAxiosError,
  isArray,
  isArrayWithValues,
  isObjWithValues,
} from "../helper";
import ImageDetails from "./compnents/ImageDetails";
import ImagesSection from "./compnents/ImagesSection";
import { useDispatch, useSelector } from "react-redux";
import { getMedia } from "../redux/actions/helperActions";
import { getMediaLibrary } from "../redux/actions/productActions";
import { Label } from "../pages/customers/components/Info";
import { LoadingButton } from "@mui/lab";
const tabDefault = [
  {
    label: "All images",
    value: "all_images",
  },
  //   {
  //     label: "Products",
  //     value: "products",
  //   },
  //   {
  //     label: "Whatsapp",
  //     value: "whatsapp",
  //   },
];
const ITEMS_PER_PAGE = 30;
export const MediaLibraryContext = createContext();
const NewMediaLibrary = ({
  open,
  handleClose,
  platform,
  setParentImages,
  multiple,
  enable_crop,
  hideUploadInfo,
  aspect,
}) => {
  const dispatch = useDispatch();

  const reduxMedia = useSelector((state) => state?.helper?.media);
  const fetchingMedia = useSelector((state) => state?.helper?.fetchingMedia);
  const noMoreMedia = useSelector((state) => state?.helper?.noMoreMedia);
  // const [tabLibraries, setTabLibraries] = useState(tabDefault);
  const [selectedLibrary, setSelectedLibrary] = useState("all_images");
  const [viewImage, setViewImage] = useState({});
  const [selected, setSelected] = useState([]);
  const [renderedData, setRenderedData] = useState([]);
  const [folders, setFolders] = useState(tabDefault);
  // const [medias, setMedias] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCreateTextFelid, setShowCreateTextFelid] = useState(false);
  const [creatingButton, setCreatingButton] = useState(false);

  const [uiPage, setUiPage] = useState("images");
  const [params, setParams] = useState({ per_page: 100, page: 1 });
  const [selectedImagesSectionView, setSelectedImagesSectionView] =
    useState("grid");
  // const [currentPage, setCurrentPage] = useState(1);
  const containerRef = useRef(null); // Ref for the container element

  const getFolders = async () => {
    try {
      let res = await backendApiCall({
        endpoint: "media/get-folders",
        method: "GET",
      });
      console.log(res);
      return res?.data;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
  useEffect(() => {
    let setUpFloders = async () => {
      let folderRes = await getFolders();
      if (isArray(folderRes)) {
        setFolders((pre) => [
          ...pre,
          ...(folderRes?.map((str) => ({
            label: str?.name,
            value: str?.name,
          })) || []),
        ]);
      }
    };
    setUpFloders();
  }, []);
  useEffect(() => {
    let _params = { ...params, page: 1 };
    if (selectedLibrary !== "all_images") {
      _params.path = selectedLibrary;
    }
    if (!fetchingMedia) dispatch(getMedia({ params: _params }));
    return () => {};
  }, [selectedLibrary]);

  useEffect(() => {
    if (!isArrayWithValues(reduxMedia)) dispatch(getMedia({ params }));
    return () => {};
  }, []);
  useEffect(() => {
    if (Array.isArray(reduxMedia)) {
      // setMedias(reduxMedia);
      setRenderedData(reduxMedia);
    }
  }, [reduxMedia]);

  useEffect(() => {
    if (isObjWithValues(viewImage)) setUiPage("imageDetails");
    else setUiPage("images");

    return () => {};
  }, [viewImage]);
  const onCreateFolder = () => {};

  // useEffect(() => {
  //     if (!isArrayWithValues(reduxMedia)) dispatch(getMediaLibrary(params));
  //     // if (isArrayWithValues(alreadySelectedImages))
  //     //     setSelectedImages(alreadySelectedImages);
  //     // if (!isArrayWithValues(reduxAllSku)) dispatch(getAllSku());
  // }, []);

  useEffect(() => {
    if (Array.isArray(reduxMedia)) {
      // setMedias(reduxMedia);
      setRenderedData(reduxMedia);
    }
  }, [reduxMedia]);

  const handleScroll = () => {
    // console.log("scroll");
    const container = containerRef.current;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    if (
      scrollTop + clientHeight >= scrollHeight - 50 &&
      reduxMedia?.length >= 100 &&
      !fetchingMedia
    ) {
      // When the user reaches the bottom of the page, load the next page of items
      dispatch(getMedia({ params: { ...params, page: params.page + 1 } }));
      setParams((pre) => ({ ...pre, page: ++pre.page }));
      // setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  // useEffect(() => {
  //     if (!isArrayWithValues(medias)) return
  //     const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  //     const endIndex = startIndex + ITEMS_PER_PAGE;
  //     const newItems = medias.slice(startIndex, endIndex);

  //     if (isArrayWithValues(newItems)) {
  //         setLoading(true);
  //         // Simulate loading for 1 second
  //         setTimeout(() => {
  //             setRenderedData((prevData) => prevData.concat(newItems));
  //             setLoading(false);
  //         }, 1000);
  //     } else {
  //         setParams(pre => ({ ...pre, page: ++pre.page }))
  //         dispatch(getMedia({ params: { ...params, page: params.page + 1 } }))
  //     }
  //     // setRenderedData((prevData) => prevData.concat(newItems));
  // }, [currentPage, medias]);
  // useEffect(() => {
  //     if (renderedData.length > ITEMS_PER_PAGE * 2) {
  //         setRenderedData((prevData) => prevData.slice(ITEMS_PER_PAGE));
  //     }
  // }, [renderedData]);
  const onSelect = (option) => {
    if (multiple)
      setSelected((prevOptions) =>
        prevOptions.some((item) => item.id === option.id)
          ? prevOptions.filter((item) => item.id !== option.id)
          : [...prevOptions, option]
      );
    else setSelected([option]);
  };

  const onSubmit = () => {
    setParentImages && setParentImages(selected);
    handleClose();
  };

  let contextValue = {
    platform,
    setParentImages,
    multiple,
    enable_crop,
    hideUploadInfo,
    aspect,
    onSelect,
    selected,
    renderedData,
    selectedLibrary,
  };

  return (
    <>
      <MediaLibraryContext.Provider value={contextValue}>
        <Dialog
          // fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"lg"}
          sx={{ ...dialogStyle }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                color: "text.secondary",
              }}
            >
              <CollectionsOutlined /> Media library
            </Typography>
            {/* <Button
              onClick={() => {
                setShowCreateTextFelid(true);
              }}
              startIcon={<Add />}
              variant="outlined"
            >
              New library
            </Button> */}
          </DialogTitle>
          <DialogContent sx={{ padding: "20px 0", height: "70vh" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={2.5}
                sx={{ borderRight: 0.5, borderRightColor: "divider" }}
              >
                {/* <Card> */}
                <CardContent sx={{ paddingRight: "inherit" }}>
                  <Typography variant="h6">Libraries</Typography>

                  <Tabs
                    orientation="vertical"
                    value={selectedLibrary}
                    onChange={(e, newValue) => setSelectedLibrary(newValue)}
                  >
                    {folders?.map((obj) => (
                      <Tab
                        key={obj?.value}
                        {...obj}
                        // icon={<FolderOpen />}
                        // iconPosition="start"
                        sx={{
                          alignItems: "baseline",
                          // flexDirection: "row",
                          // gap: "4px",
                        }}
                      />
                    ))}
                  </Tabs>

                  <Collapse in={showCreateTextFelid}>
                    {showCreateTextFelid && (
                      <Stack gap={1}>
                        <Label>Folder Name</Label>
                        <TextField size={"small"} fullWidth />{" "}
                        <Stack
                          direction={"row"}
                          alignContent={"center"}
                          justifyContent={"end"}
                        >
                          <Button
                            onClick={() => {
                              setShowCreateTextFelid(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <LoadingButton
                            loading={creatingButton}
                            onClick={onCreateFolder}
                            variant="contained"
                          >
                            Create
                          </LoadingButton>
                        </Stack>
                      </Stack>
                    )}
                  </Collapse>
                </CardContent>
                {/* </Card> */}
              </Grid>
              <Grid
                item
                xs={12}
                md={9.5}
                sx={{ height: `calc(70vh - 25px)`, overflow: "auto" }}
                ref={containerRef}
                onScroll={handleScroll}
              >
                <TabPanel value={"images"} index={uiPage}>
                  <ImagesSection
                    selectedLibrary={selectedLibrary}
                    setViewImage={setViewImage}
                    setSelectedImagesSectionView={setSelectedImagesSectionView}
                  />
                </TabPanel>
                <TabPanel value={"imageDetails"} index={uiPage}>
                  <ImageDetails
                    data={viewImage}
                    onClose={() => setViewImage({})}
                  />
                </TabPanel>
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    visibility:
                      loading || fetchingMedia ? "inherit" : "collapse",
                  }}
                >
                  {loading || fetchingMedia ? (
                    <>
                      <CircularProgress />
                      <Typography>Loading</Typography>
                    </>
                  ) : null}
                </Stack>
                {/* <div hidden={isObjWithValues(viewImage)}></div>
            <div hidden={!isObjWithValues(viewImage)}>
              <ImageDetails data={viewImage} onClose={() => setViewImage({})} />
            </div> */}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
            {selectedImagesSectionView !== "upload" && (
              <Button
                variant="contained"
                disabled={!isArrayWithValues(selected)}
                onClick={onSubmit}
                autoFocus
              >
                Select
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </MediaLibraryContext.Provider>
    </>
  );
};

export default NewMediaLibrary;
