import { createBotNode } from "./add-nodes/createBotNode";
import { createUserNode } from "./add-nodes/createUserNode";
import { createFallbackNode } from "./add-nodes/createFallbackNode";
import { createUserAttachmentNode } from "./add-nodes/createUserAttachment";
import { createWhatsappTemplateNode } from "./add-nodes/createWhatsappTemplateNode";
import { createProductCatalogNode } from "./add-nodes/createProductCatalogNode";
import { createQuestionNode } from "./add-nodes/createQuestionNode";
import { createFilterNode } from "./add-nodes/createFilterNode";
import { createGoToStepNode } from "./add-nodes/createGoToStepNode";
import { createLiveAgentNode } from "./add-nodes/createLiveAgentNode";
import { createSubscribeNode } from "./add-nodes/createSubscribeNode";
import { createUnsubscribeNode } from "./add-nodes/createUnsubscribeNode";
import { createEmailNode } from "./add-nodes/createEmailNode";
import { createSmsNode } from "./add-nodes/createSmsNode";
import { createPushNotification } from "./add-nodes/createPushNotification";
import { createDelayNode } from "./add-nodes/createDelayNode";
import { createMetalRatesNode } from "./add-nodes/createMetalRatesNode";
import { createAssignNode } from "./add-nodes/createAssignNode";
import { createTaskNode } from "./add-nodes/createTaskNode";

const nodeCreationFunctions = {
  bot: createBotNode,
  user: createUserNode,
  fallback: createFallbackNode,
  "user-attachment": createUserAttachmentNode,
  "whatsapp-template": createWhatsappTemplateNode,
  "product-catalog": createProductCatalogNode,
  question: createQuestionNode,
  filter: createFilterNode,
  "go-to-step": createGoToStepNode,
  "live-agent": createLiveAgentNode,
  subscribe: createSubscribeNode,
  unsubscribe: createUnsubscribeNode,
  email: createEmailNode,
  sms: createSmsNode,
  "push-notification": createPushNotification,
  delay: createDelayNode,
  "create-task": createTaskNode,
  "metal-rates": createMetalRatesNode,
  assign: createAssignNode,
};

export const addNode = ({ dispatch, nodes, currentNode, addNodeType }) => {
  const id = currentNode?.id;

  const createNodeFunction = nodeCreationFunctions[addNodeType];
  createNodeFunction({ dispatch, nodes, nodeId: id });
};
