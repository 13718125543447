const FLOW_TYPE_LABELS = {
  webchat: "Flows",
  trigger: "Trigger",
};
const FLOW_TYPE_NAVIGATION = {
  webchat: "flows",
  trigger: "trigger",
};

export { FLOW_TYPE_LABELS, FLOW_TYPE_NAVIGATION };
