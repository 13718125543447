import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  dialogStyle,
  isArrayWithValues,
  isObjWithValues,
  primaryLabel,
} from "../../helper";
import {
  AmazonSvg,
  EbaySvg,
  GoogleShop,
  MetaSvg,
  ShopifySvg,
  WooCommerceSvg,
} from "../../Svgs";
import DataNotFound from "../components/DataNotFound";
import { StorefrontOutlined } from "@material-ui/icons";
import { CheckCircle } from "@mui/icons-material";

const allMarketPlaces = [
  {
    label: "Amazon",
    Logo: AmazonSvg,
    custom_value: "add_to_amazon",
    custom_remove_value: "remove_from_amazon",
    value: "amazon",
  },
  {
    label: "Shopify",
    Logo: ShopifySvg,
    custom_value: "add_to_shopify",
    custom_remove_value: "remove_from_shopify",
    value: "shopify",
  },
  {
    label: "Ebay",
    Logo: EbaySvg,
    custom_value: "add_to_ebay",
    custom_remove_value: "remove_from_ebay",
    value: "ebay",
  },
  {
    label: "WooCommerce",
    Logo: WooCommerceSvg,
    custom_value: "add_to_woocommerce",
    custom_remove_value: "remove_from_woocommerce",
    value: "woocommerce",
  },
  {
    label: "Google Shopping",
    Logo: GoogleShop,
    custom_value: "add_to_google_shop",
    custom_remove_value: "delete_from_google_shop",
    value: "google_shopping",
  },
  {
    label: "Facebook Shopping",
    Logo: MetaSvg,
    custom_value: "add_to_facebook_shop",
    custom_remove_value: "delete_from_facebook_shop",
    value: "facebook_shopping",
  },
];

const AddToMarketplaceDialog = forwardRef(
  ({ value, onChange, onAdd, loading, isRemove, onClose }, ref) => {
    const [open, setOpen] = React.useState(false);
    const [integratedMarketplaces, setIntegratedMarketplaces] = useState([]);

    const reduxWooCommerceIntegration = useSelector(
      (state) => state.settings.wooCommerceIntegration
    );
    const reduxShopifyIntegration = useSelector(
      (state) => state.settings.shopifyIntegration
    );
    const reduxEbayIntegration = useSelector(
      (state) => state.settings.ebayIntegration
    );
    const reduxAmazonIntegration = useSelector(
      (state) => state.settings.amazonIntegration
    );
    const reduxGoogleIntegration = useSelector(
      (state) => state.settings.googleIntegration
    );
    const reduxMetaIntegrations = useSelector(
      (state) => state.settings.metaIntegration
    );

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      onClose && onClose();
    };

    useImperativeHandle(ref, () => {
      return {
        open() {
          handleClickOpen();
        },
        close() {
          handleClose();
        },
      };
    });
    useEffect(() => {
      checkMarketplaceIntegration(reduxGoogleIntegration, "google_shop");
    }, [reduxGoogleIntegration]);
    useEffect(() => {
      checkMarketplaceIntegration(reduxMetaIntegrations, "facebook_shopping");
    }, [reduxMetaIntegrations]);
    useEffect(() => {
      checkMarketplaceIntegration(reduxWooCommerceIntegration, "woocommerce");
    }, [reduxWooCommerceIntegration]);
    useEffect(() => {
      checkMarketplaceIntegration(reduxShopifyIntegration, "shopify");
    }, [reduxShopifyIntegration]);
    useEffect(() => {
      checkMarketplaceIntegration(reduxEbayIntegration, "ebay");
    }, [reduxEbayIntegration]);
    useEffect(() => {
      checkMarketplaceIntegration(reduxAmazonIntegration, "amazon");
    }, [reduxAmazonIntegration]);

    useEffect(() => {
      if (!isObjWithValues(reduxGoogleIntegration)) return;
      let googleIntegration = { ...reduxGoogleIntegration };
      // if (!isObjWithValues(reduxGoogleIntegration)) {
      //   setIntegartedApp([]);
      //   return;
      // }
      let integratedApp = [];
      for (const key in googleIntegration) {
        if (!isArrayWithValues(reduxGoogleIntegration[key].connected)) return;
        let app = googleIntegration[key].connected;
        integratedApp = [...integratedApp, ...app];
      }
      setIntegratedMarketplaces((prevArray) => [
        ...prevArray,
        ...integratedApp,
      ]);
      // setIntegartedApp(integratedApp);
      // let integratedAppArray = [];
      // for (const key in reduxGoogleIntegration) {
      //   if (!isArrayWithValues(reduxGoogleIntegration[key].connected)) return;
      //   let app = reduxGoogleIntegration[key].connected;
      //   integratedAppArray = [...integratedAppArray, ...app];
      // }
      // setIntegartedApp(integratedAppArray);
    }, [reduxGoogleIntegration]);

    // Checking marketplace integration status
    const checkMarketplaceIntegration = (mRedux, mName) => {
      if (
        (isObjWithValues(mRedux) && mRedux.integrated) ||
        (mName === "facebook_shopping" &&
          Object.values(mRedux || {})?.find((i) =>
            i?.connected?.includes("commerce")
          ))
      ) {
        if (!integratedMarketplaces.includes(mName)) {
          setIntegratedMarketplaces((prevArray) => [...prevArray, mName]);
        }
      } else {
        setIntegratedMarketplaces((prevArray) =>
          prevArray.filter((str) => str !== mName)
        );
      }
    };
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <DialogTitle id="alert-dialog-title">
          {isRemove
            ? "Remove selected products from channel"
            : "Add selected products to a channel"}
        </DialogTitle>
        <DialogContent>
          {!isArrayWithValues(
            allMarketPlaces?.filter((i) =>
              integratedMarketplaces?.includes(i.value)
            )
          ) ? (
            <DataNotFound
              illustration={
                <StorefrontOutlined
                  style={{ height: "200px", width: "200px", color: "#d7d7d7" }}
                />
              }
              subtitle={"No marketplace integrated"}
            />
          ) : null}
          <Stack
            direction="row"
            alignItems={"center"}
            sx={{ flexWrap: "wrap" }}
            spacing={3}
            py={3}
          >
            {allMarketPlaces
              ?.filter((i) => integratedMarketplaces?.includes(i.value))
              .map((i) => {
                let isSelected = isRemove
                  ? i.custom_remove_value === value
                  : i.custom_value === value;
                let Logo = i.Logo;
                return (
                  // <Grid item xs={6} sm={4} md={3} lg={2.4}>
                  // </Grid>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    spacing={2}
                    justifyContent={"center"}
                    sx={{
                      textAlign: "center",
                      border: isSelected
                        ? "1px solid #d7d7d7"
                        : "1px solid #e8e8e8",
                      padding: "13px 10px",
                      borderRadius: "10px",
                      // boxShadow: isSelected
                      //   ? "0px 10px 15px -3px rgba(0,0,0,0.1)"
                      //   : "none",
                      cursor: "pointer",
                      position: "relative",
                      // px: 4,
                    }}
                    onClick={() =>
                      onChange(
                        isRemove ? i.custom_remove_value : i.custom_value
                      )
                    }
                  >
                    <Logo style={{ height: "30px", width: "30px" }} />
                    {isSelected && (
                      <CheckCircle
                        style={{
                          position: "absolute",
                          top: -5,
                          right: -5,
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    )}
                    <Typography sx={{ mt: 2, ...primaryLabel }}>
                      {i.label}
                    </Typography>
                  </Stack>
                );
              })}
          </Stack>
          <Grid container mt={1} spacing={3}></Grid>
          {/* <DialogContentText id="alert-dialog-description">
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText> */}
        </DialogContent>
        <DialogActions sx={{ mr: 2 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            variant="outlined"
            onClick={() => onAdd()}
            autoFocus
            color={isRemove ? "error" : "primary"}
          >
            {isRemove ? "Remove" : "Add"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
);

export default AddToMarketplaceDialog;
