import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FilterLabel, likeFilters } from "../Customers";
import { Clear } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  ethincityArray,
  incomeArray,
  languageArray,
  religionArray,
} from "./Info";
import {
  formatServerValue,
  getStoreId,
  isObjWithValues,
} from "../../../helper";
import { isArrayWithValues } from "jwero-javascript-utils";

const Filters = forwardRef(
  (
    {
      //   editFilterForm,
      //   filterForm,
      stateArray,
      setStateArray,
    },
    ref
  ) => {
    // const [stateArray, setStateArray] = useState([{ key: "", value: "" }]);
    const states = useSelector((state) => state.persist.states);
    const occupationsArray = useSelector((state) => state.persist.occupations);
    const branches = useSelector((state) => state.settings.branch);
    const allCustomerGroup = useSelector(
      (state) => state.customers.crmGrouping
    );
    const countriesArray = useSelector((state) => state.persist.countries);
    const custom_fields = useSelector(
      (state) => state?.settings?.custom_fields
    );

    const allFilterOptions = {
      first_name: {
        type: "text",
      },
      last_name: {
        type: "text",
      },
      billing_city: { type: "text" },
      billing_state: {
        type: "autoComplete",
        options: Object.values(states || {})
          ?.flat()
          ?.map((obj) => {
            return {
              label: obj?.label,
              value: obj?.code,
            };
          }),
      },
      billing_country: {
        type: "autoComplete",
        options: countriesArray?.map((o) => ({
          label: o?.label,
          value: o?.code,
        })),
      },
      whatsapp: { type: "text" },
      mobile: { type: "text" },
      profession: { type: "autoComplete", options: occupationsArray },
      language: { type: "autoComplete", options: languageArray },
      ethinicity: { type: "autoComplete", options: ethincityArray },
      annual_income: { type: "autoComplete", options: incomeArray },
      gender: {
        type: "autoComplete",
        options: [
          { label: "Male", value: "male" },
          { label: "Female", value: "female" },
          { label: "Other", value: "other" },
        ],
      },
      [`group_name_${getStoreId()}`]: {
        type: "autoComplete",
        options: isArrayWithValues(allCustomerGroup)
          ? allCustomerGroup?.map((obj) => {
              return {
                label: formatServerValue(obj?.group_name),
                value: obj?.group_name,
              };
            })
          : [],
      },
      religion: {
        type: "autoComplete",
        options: religionArray,
      },
      branch_from: {
        type: "autoComplete",
        options: Object.values(branches || {})
          .map((i) => ({
            label: i.branchName,
            value: i.branchName,
          }))
          .filter((i) => i.label),
      },
      lead_source: {
        type: "text",
      },
    };

    console.log(allCustomerGroup, "<<<<< allCustomerGroup");

    const [filters, setFilters] = useState(likeFilters);
    const [likeFiltersOptions, setLikeFilterOptions] =
      useState(allFilterOptions);

    useEffect(() => {
      let country = stateArray?.find(
        (o) => o?.key === "billing_country"
      )?.value;
      if (country?.value)
        setLikeFilterOptions((pre) => ({
          ...pre,
          billing_state: {
            ...(pre?.billing_state || {}),
            options: Object.values(states[country?.value] || {})
              ?.flat()
              ?.map((obj) => {
                return {
                  label: obj?.label,
                  value: obj?.code,
                };
              }),
          },
        }));
      else
        setLikeFilterOptions((pre) => ({
          ...pre,
          billing_state: {
            ...(pre?.billing_state || {}),
            options: Object.values(states || {})
              ?.flat()
              ?.map((obj) => {
                return {
                  label: obj?.label,
                  value: obj?.code,
                };
              }),
          },
        }));
      return () => {};
    }, [stateArray?.find((o) => o?.key === "billing_country")?.value]);

    useImperativeHandle(ref, () => ({
      getState() {
        return stateArray;
      },
    }));
    useEffect(() => {
      let filters = [
        // { label: `Tags`, value: `group_name_${getStoreId()}` },
        { label: `Religion`, value: `religion` },
        // { label: `Ethinicity`, value: `ethincity` },
        { label: `Branch`, value: `branch_from` },
        { label: "Lead Source", value: "lead_source" },
      ];

      if (isObjWithValues(custom_fields?.crm)) {
        let filterOptions = {};
        let array = Object.values(custom_fields?.crm)
          ?.map((i) => {
            return i.type !== "date" && i.type !== "time"
              ? { label: i.title, value: i.value }
              : null;
          })
          .filter(Boolean);
        filters = filters.concat(array);
        for (let field of Object.values(custom_fields?.crm)) {
          let { type, value, options } = field;
          if (["checkbox", "radio", "dropdown"]?.includes(type)) {
            filterOptions[value] = {
              type: "autoComplete",
              options,
            };
          }
        }
        setLikeFilterOptions((state) => ({ ...state, ...filterOptions }));
      }

      setFilters(likeFilters.concat(filters));

      return () => {};
    }, [custom_fields]);

    const onEditForm = ({ index, payload }) => {
      let pre = [...stateArray];
      let preObj = pre?.[index];
      preObj = { ...preObj, ...payload };
      pre[index] = preObj;

      setStateArray(pre);
      // setStateArray((pre) => {
      //   let preObj = pre?.[index];
      //   preObj = { ...preObj, ...payload };
      //   pre[index] = preObj;
      //   return pre;
      // });
    };

    return (
      <>
        <Stack mt={4} mb={6}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={3}
            justifyContent={"space-between"}
          >
            <FilterLabel variant="h6">Filter by Value</FilterLabel>
            <Button
              onClick={() =>
                setStateArray((pre) => [...pre, { key: "", value: "" }])
              }
            >
              Add
            </Button>
          </Stack>
          <Grid container spacing={2}>
            {stateArray?.map((obj, index) => {
              let { key, value } = obj;

              return (
                <Fragment>
                  <Grid item xs={6}>
                    <Select
                      value={key || ""}
                      onChange={(e) => {
                        onEditForm({
                          index,
                          payload: { key: e.target.value, value: "" },
                        });
                      }}
                      fullWidth
                      size="small"
                      endAdornment={
                        <IconButton
                          sx={{
                            display: key ? "block" : "none",
                            mr: 1.8,
                          }}
                          onClick={() => {
                            setStateArray((pre) =>
                              pre?.filter((o) => o?.key !== key)
                            );
                            // onEditForm({
                            //   index,
                            //   payload: { key: "", value: "" },
                            // });
                          }}
                          size={"small"}
                        >
                          <Clear style={{ height: "18px", width: "18px" }} />
                        </IconButton>
                      }
                    >
                      {filters.map((i) => {
                        return <MenuItem value={i.value}>{i.label}</MenuItem>;
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    {likeFiltersOptions?.[key]?.type === "autoComplete" ? (
                      <Autocomplete
                        size="small"
                        id="combo-box-demo"
                        options={likeFiltersOptions?.[key]?.options || []}
                        value={value || ""}
                        onChange={(event, newInputValue) => {
                          onEditForm({
                            index,
                            payload: {
                              value: newInputValue,
                            },
                          });
                          // editFilterForm({ ethincity: newInputValue });
                        }}
                        fullWidth
                        renderInput={(params) => <TextField {...params} />}
                      />
                    ) : (
                      <TextField
                        size="small"
                        fullWidth
                        value={value}
                        onChange={
                          (e) => {
                            onEditForm({
                              index,
                              payload: { value: e.target.value },
                            });
                          }
                          // editFilterForm({ filterValue: e.target.value })
                        }
                      />
                    )}
                  </Grid>
                </Fragment>
              );
            })}
            {/* <Grid item xs={6}>
            <Select
              value={filterForm.filterValueLabel}
              onChange={(e) =>
                editFilterForm({
                  filterValueLabel:
                    filterForm.filterValueLabel === e.target.value
                      ? ""
                      : e.target.value,
                })
              }
              fullWidth
              size="small"
              endAdornment={
                <IconButton
                  sx={{
                    display: filterForm.filterValueLabel ? "block" : "none",
                    mr: 1.8,
                  }}
                  onClick={() =>
                    editFilterForm({
                      filterValueLabel: "",
                    })
                  }
                  size={"small"}
                >
                  <Clear style={{ height: "18px", width: "18px" }} />
                </IconButton>
              }
            >
              {likeFilters.map((i) => {
                return <MenuItem value={i.value}>{i.label}</MenuItem>;
              })}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              value={filterForm.filterValue}
              onChange={(e) => editFilterForm({ filterValue: e.target.value })}
            />
          </Grid> */}
          </Grid>
        </Stack>
      </>
    );
  }
);

export default Filters;
