import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import { textBoxStyles } from "../styles/textBoxStyles";

const styles = textBoxStyles;

const TextBox = ({
  value,
  onChangeFunction,
  placeholderValue,
  fieldType,
  maxLengthValue,
  rows = 1,
  inputAdornment,
  handleClick,
  inputRef,
}) => {
  return (
    <Box sx={styles.mainContainer}>
      <TextField
        multiline
        rows={rows}
        fullWidth
        variant="standard"
        value={value}
        placeholder={placeholderValue}
        onChange={(e) => onChangeFunction({ e })}
        name={fieldType}
        ref={inputRef}
        inputProps={{
          maxLength: maxLengthValue,
        }}
        InputProps={{
          disableUnderline: true,
          endAdornment: inputAdornment ? (
            <InputAdornment
              position="end"
              onClick={(e) => handleClick({ e, fieldType })}
              sx={styles.inputAdornment}
            >
              <IconButton>{inputAdornment}</IconButton>
            </InputAdornment>
          ) : undefined,
        }}
      />
    </Box>
  );
};

export default TextBox;
