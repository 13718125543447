import React, { forwardRef, useImperativeHandle, useState } from "react";
import ProductMediaLibrary from "../../../../components/ProductMediaLibrary";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Switch from "rc-switch";
import {
  Cancel,
  FileUploadOutlined,
  InfoOutlined,
  Upload,
} from "@mui/icons-material";
import {
  ErrorLabelExtraSmall,
  PrimaryLabel,
  PrimaryLabelSmall,
  SecondaryLabel,
  SecondaryLabelSmall,
} from "../../../../../helper/utility/Labels";
import { QuillWrapper } from "../../../../products/AddProduct";
import ReactQuill from "react-quill";
import { isArrayWithValues, isObjWithValues } from "jwero-javascript-utils";
import HorizontalStack from "../../../../../helper/utility/HorizontalStack";
import CustomTooltip from "../../../../flows/pages/addFlow/components/CustomTooltip";
import { ADD_PLAN_TOOLTIPS } from "../../../constants";
import { useDispatch } from "react-redux";
import { setGlobalToast } from "../../../../../redux/actions/helperActions";
import { validatePlanContentsDetails } from "../helper/validations";

const PlanContents = forwardRef(({ errors, form: addForm, editForm }, ref) => {
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  let backgroundColor = theme.palette?.primary?.main;
  let secondaryLight = theme.palette.secondary?.light;

  useImperativeHandle(ref, () => {
    return {
      validateForm(form) {
        let errors = validatePlanContentsDetails({ form: addForm });
        return errors;
      },
      scrollTo(id) {
        const element = document?.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
      },
    };
  });

  const setImage = (array) => {
    if (array?.[0]?.media_details?.width !== array?.[0]?.media_details?.height)
      return dispatch(
        setGlobalToast({
          show: true,
          message: "Invalid image dimensions",
          severity: "error",
        })
      );
    if (array?.[0]?.media_details?.filesize > 5242880)
      return dispatch(
        setGlobalToast({
          show: true,
          message: "File size exceeded!",
          severity: "error",
        })
      );
    if (isArrayWithValues(array)) {
      editForm({ image: array[0] });
      setMediaLibraryOpen(false);
    }
  };

  return (
    <>
      <PrimaryLabel>
        Plan Content{" "}
        {/* <SecondaryLabel component="span">(Optional)</SecondaryLabel> */}
      </PrimaryLabel>
      <Grid container spacing={5} sx={{ mt: 1 }}>
        {/* <Grid item xs={6}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="h6" sx={{ fontSize: "14px" }}>
              Add Image?
            </Typography>
            <Switch
              onChange={(e) => {
                editForm({ show_image: e });
              }}
              checked={addForm.show_image}
              style={{
                border: `1px solid ${
                  addForm.show_image ? backgroundColor : "#e8e8e8"
                }`,
                backgroundColor: addForm.show_image
                  ? backgroundColor
                  : "#e8e8e8",
              }}
            />
          </Stack>
        </Grid> */}
        <Grid item xs={4}>
          <HorizontalStack spacing={2}>
            <PrimaryLabelSmall>Thumbnail</PrimaryLabelSmall>
            <CustomTooltip title={ADD_PLAN_TOOLTIPS.plan_thumbnail}>
              <InfoOutlined style={{ height: "14px", width: "14px" }} />
            </CustomTooltip>
          </HorizontalStack>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {isObjWithValues(addForm.image) ? (
                  <Box sx={{ position: "relative" }}>
                    <img
                      src={addForm?.image?.source_url}
                      style={{
                        maxHeight: "160px",
                        width: "100%",
                        objectFit: "contain",
                        textAlign: "center",
                        borderRadius: "7px",
                      }}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: "-12px",
                        top: "-12px",
                        zIndex: 2,
                        overflow: "hidden",
                      }}
                      onClick={() => editForm({ image: null })}
                      aria-label="delete"
                    >
                      <Cancel
                        size="small"
                        color="primary"
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "50%",
                        }}
                      />
                    </IconButton>
                  </Box>
                ) : (
                  <Card
                    onClick={() => setMediaLibraryOpen(true)}
                    sx={{
                      cursor: "pointer",
                      // backgroundColor: "rgba(0,0,0,0.1)",
                      backgroundColor: secondaryLight,
                      py: 4,
                      // "&:hover": {
                      //   backgroundColor: "rgba(0,0,0,0.1)",
                      // },

                      borderWidth: 2,
                      borderStyle: "dashed",
                      borderColor: theme.palette.secondary?.main,
                    }}
                  >
                    <CardContent
                      fullWidth
                      id="outlined"
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                      hoverTitle=""
                    >
                      <div style={{ marginLeft: 5, textAlign: "center" }}>
                        <FileUploadOutlined
                          color={theme.palette.secondary?.main}
                          style={{
                            height: "40px",
                            width: "40px",
                            color: theme.palette.secondary?.main,
                            // backgroundColor: "rgba(0,0,0,0.3)",
                            // position: "absolute",
                            // top: "50%",
                            // left: "50%",
                            // transform: "translate(-50%, -50%)",
                          }}
                        />
                        <h3 style={{ color: theme.palette.secondary?.main }}>
                          Add Thumbnail
                        </h3>
                        <SecondaryLabelSmall style={{ mt: 1 }}>
                          JPG , PNG up to 5mb
                        </SecondaryLabelSmall>
                        <SecondaryLabelSmall style={{ mt: 1 }}>
                          Size limit : 500 x 500
                        </SecondaryLabelSmall>
                      </div>
                    </CardContent>
                  </Card>
                )}
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                    <Typography fontSize={"16px"} fontWeight="600">
                      Instructions
                    </Typography>
                    <Box sx={{ ml: 1 }}>
                      <BulletText text={"Only PNG Images allowed"} />
                      <BulletText text={"Upload Images below 2 MB"} />
                      <BulletText
                        text={"Recommend Images Dimensions below 3000px"}
                      />
                      <BulletText
                        text={"Add Product Measurement in product details*"}
                      />
                    </Box>
                  </Grid> */}
            </Grid>
          </Box>
          {/* <Collapse in={addForm.show_image}>
          </Collapse> */}
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <PrimaryLabelSmall>Plan Description</PrimaryLabelSmall>
          {/* <TextField
              // label="Plan Description"
              value={addForm.description}
              onChange={(e) => editForm({ description: e.target.value })}
              fullWidth
              multiline
              rows={2}
            //   helperText="Title entered here will be displayed to customers"
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <Tooltip title="Title entered here will be displayed to customers">
            //         <Info />
            //       </Tooltip>
            //     </InputAdornment>
            //   ),
            // }}
            /> */}
          <Box>
            <QuillWrapper style={{ marginTop: "10px" }}>
              <ReactQuill
                theme="snow"
                value={addForm.description}
                onChange={(e) => editForm({ description: e })}

                // placeholder="Type something.."
              />
            </QuillWrapper>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <PrimaryLabelSmall sx={{ mt: 2 }}>
            Plan Terms and Conditions *
          </PrimaryLabelSmall>
          <div>
            <QuillWrapper style={{ marginTop: "10px" }}>
              <ReactQuill
                theme="snow"
                value={addForm.terms_and_conditions}
                onChange={(e) => editForm({ terms_and_conditions: e })}

                // placeholder="Type something.."
              />
            </QuillWrapper>
          </div>

          {errors?.terms_and_conditions && (
            <ErrorLabelExtraSmall sx={{ mt: 1 }}>
              {errors?.terms_and_conditions}
            </ErrorLabelExtraSmall>
          )}
          <div id="terms_and_conditions" style={{ marginTop: 40 }} />
          {/* <TextField
              // label="Plan Terms and Conditions"
              value={addForm.terms_and_conditions}
              onChange={(e) => editForm({ terms_and_conditions: e.target.value })}
              fullWidth
              multiline
              rows={3}
            //   helperText="Title entered here will be displayed to customers"
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <Tooltip title="Title entered here will be displayed to customers">
            //         <Info />
            //       </Tooltip>
            //     </InputAdornment>
            //   ),
            // }}
            /> */}
        </Grid>
      </Grid>

      <ProductMediaLibrary
        open={mediaLibraryOpen}
        onChange={setMediaLibraryOpen}
        setParentImages={setImage}
        selectedImages={addForm.image ? [addForm.image] : []}
        multiple={false}
      />
    </>
  );
});

export default PlanContents;
