import { useState } from "react";
import { Handle, useNodeId } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography, Tooltip, Fade } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NodePopover from "../helper/NodePopover";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import NodeOptions from "../helper/NodeOptions";
import NodeTop from "../helper/NodeTop";
import ShowChatIndicator from "../helper/ShowChatIndicator";
import { nodeCanBeAddedErrorMessage } from "../helper/nodeCanBeAdded";
import * as actions from "../../../../../../redux/actions/flowsActions";
import { fallbackNodeStyles } from "../styles/fallbackStyles";
import "../styles/node.css";
import AddNodesButton from "../helper/AddNodesButton";
import BounceLoader from "react-spinners/BounceLoader";

const styles = fallbackNodeStyles;

const CustomDefaultFallback = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const nodeId = useNodeId();
  const title = nodes.find((node) => node.id === nodeId)?.title;
  const isDragging = useSelector((state) => state.flow?.isDragging);
  const dragginNodeType = useSelector((state) => state.flow?.dragginNodeType);
  const messageNodeIndicatorId = useSelector(
    (state) => state.flow?.messageNodeIndicatorId
  );
  const isMinimized = useSelector((state) => state.flow?.isMinimized);
  const [isHovered, setIsHovered] = useState(false);
  const [openNodePopover, setOpenNodePopover] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const bubbleColor = errorMessage ? "#e63946" : "#3185FC";

  const handleClick = (event) => {
    setOpenNodePopover(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenNodePopover(false);
    setAnchorEl(null);
  };

  const handleMain = () => {
    setOpenTooltip(!openTooltip);
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { nodeDraggedToId: nodeId },
    });

    if (isDragging) {
      const currentNode = nodes?.find((node) => node.id === nodeId);
      const flag = nodeCanBeAddedErrorMessage({
        nodes,
        edges,
        currentNode,
        nodeAddedType: dragginNodeType,
      });

      if (flag !== "allowed") {
        setErrorMessage(flag);
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setOpenTooltip(false);
  };

  return (
    <Box
      className="nodeContainer"
      sx={styles.mainContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        sx={{
          height: "20px",
          width: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
          marginRight: "10px",
          position: "relative",
          top: "6px",
        }}
      >
        <NodeTop
          title={title}
          isHovered={isHovered}
          isDragging={isDragging}
          nodeId={nodeId}
          setIsHovered={setIsHovered}
        />
      </Box>

      <Box sx={styles.bottomRow}>
        <Handle
          type="target"
          position="left"
          style={{
            background: "#566d89",
            position: "relative",
            opacity: "0",
            left: "10px",
            bottom: "11px",
          }}
        />
        {nodeId == "0002" ? (
          <Tooltip
            arrow
            title="Default fallback can't be edited."
            open={openTooltip}
            placement="left"
            componentsProps={{
              tooltip: {
                sx: {
                  width: "180px",
                  backgroundColor: "#797b8c",
                  color: "#fff",
                  fontSize: "16px",
                  fontFamily: "Inter",
                  padding: "8px",
                  fontWeight: 550,
                },
              },
              arrow: {
                sx: {
                  color: "#797b8c",
                },
              },
            }}
          >
            <Box sx={styles.nodeBody} onClick={handleMain}>
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                  "&:hover": {
                    backgroundColor: "#797b8c",
                  },
                }}
              >
                <ReplyAllIcon
                  style={{
                    fontSize: "18px",
                    marginLeft: "5px",
                    color: "#ffffff",
                    marginRight: "5px",
                  }}
                />
              </IconButton>
              <Typography variant="span" style={styles.titleName}>
                Fallback
              </Typography>
            </Box>
          </Tooltip>
        ) : isHovered &&
          isDragging &&
          errorMessage !== null &&
          nodeId === "0002" ? (
          <Tooltip
            arrow
            title={errorMessage}
            placement="top"
            open={true}
            componentsProps={{
              tooltip: {
                sx: {
                  color: "#fff",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  padding: "10px",
                },
              },
            }}
          >
            <Box sx={styles.nodeBody} onClick={handleMain}>
              <IconButton
                sx={{
                  margin: 0,
                  padding: 0,
                  "&:hover": {
                    backgroundColor: "#797b8c",
                  },
                }}
              >
                <ReplyAllIcon
                  style={{
                    fontSize: "18px",
                    marginLeft: "5px",
                    color: "#ffffff",
                    marginRight: "5px",
                  }}
                />
              </IconButton>
              <Typography variant="span" style={styles.titleName}>
                Fallback
              </Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box sx={styles.nodeBody} onClick={handleMain}>
            <IconButton
              sx={{
                margin: 0,
                padding: 0,
                "&:hover": {
                  backgroundColor: "#797b8c",
                },
              }}
            >
              <ReplyAllIcon
                style={{
                  fontSize: "18px",
                  marginLeft: "5px",
                  color: "#ffffff",
                  marginRight: "5px",
                }}
              />
            </IconButton>
            <Typography variant="span" style={styles.titleName}>
              fallback
            </Typography>
          </Box>
        )}
        {messageNodeIndicatorId === nodeId && !isMinimized && (
          <ShowChatIndicator />
        )}
        <Fade in={openNodePopover} timeout={3000}>
          <Box>
            <NodePopover
              nodeId={nodeId}
              handleClick={handleClick}
              handleClose={handleClose}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
          </Box>
        </Fade>

        <span onClick={handleClick} className="addButton">
          <AddIcon sx={styles.addIcon} />
        </span>
        <div
          style={{
            position: "absolute",
            bottom: "13px",
            left: "70px",
            visibility: isHovered && isDragging ? "visible" : "hidden",
          }}
        >
          <BounceLoader color={bubbleColor} speedMultiplier="0.8" size="80px" />
        </div>
        {/* <AddNodesButton
          handleClick={handleClick}
          isHovered={isHovered}
          nodeId={nodeId}
          errorMessage={errorMessage}
        /> */}
      </Box>
      <Handle
        type="source"
        position="right"
        style={{
          background: "#566d89",
          opacity: 0,
          position: "relative",
          right: "-30px",
          top: "-45px",
        }}
      />
    </Box>
  );
};

export default CustomDefaultFallback;
