import { onAddFallback } from "../../utils/FallbackNode";
import * as actions from "../../../../../../../redux/actions/flowsActions";
import { getAxis } from "../getAxis";
import { calculateAxis } from "../calculateAxis";

export const createFallbackNode = async ({
  dispatch,
  nodes,
  nodeId,
  btnsObj,
  currentAddHandle,
  handlesObj,
}) => {
  const currNode = nodes.find((node) => node.id === nodeId);
  let currCount = currNode?.userConnected;
  const type = currNode?.type;
  const currentType = "fallback";

  let axis = {
    x: currNode?.position.x,
    y: currNode?.position.y,
  };

  if (handlesObj) {
    const index = handlesObj.findIndex(
      (item) => item?.value == currentAddHandle
    );
    axis = getAxis({
      x: axis.x,
      y: axis.y,
      handleNum: index + 1,
      totalHandles: handlesObj.length,
    });
  } else {
    axis = calculateAxis({ axis, currType: currentType, prevType: type });
    axis.x = currNode.position.x + 240;
    if (currCount === 0) {
      axis.y = currNode.position.y;
    } else if (currCount === 1) {
      axis.y = currNode?.position?.y + 75;
    } else if (currCount === 2) {
      axis.y = currNode?.position?.y - 80;
    } else {
      axis.y = currNode?.position?.y - 75 * (currCount - 1);
    }
  }

  onAddFallback({ dispatch, axis, nodeId, currentAddHandle });

  dispatch({
    type: actions.UPDATE_USER_CONNECTED,
    payload: { id: nodeId, count: currCount + 1 },
  });
};
