import { useRef } from "react";
import { Box, Typography, CardContent, CardMedia } from "@mui/material";
import ShowDocuments from "../../helper/ShowDocuments";
import ReactPlayer from "react-player";
import EditIcon from "@mui/icons-material/Edit";

const FileDisplayCard = ({
  componentType,
  component,
  index,
  handleFileSelect,
  handleIconClick,
  fileInputRef,
}) => {
  const supportedTypes = {
    IMAGE: {
      display_name: "Image",
      accept: "image/jpeg, image/jpg, image/png",
      value: "image"
    },
    VIDEO: {
      display_name: "Video",
      accept: "video/mp4, video/3gp",
      value: "video"
    },
    DOCUMENT: {
      display_name: "Document",
      accept: ".doc,.,.pdf,.csv,.docx,.xls,.xlsx",
      value: "document"
    },
  };
  const fileURL = component?.file_url?.url;
  const viewDocument = (url) => {
    window.open(url, "_blank");
  };

  return (
    <CardContent
      style={{
        padding: 0,
        height: "200px",
        borderRadius: "20px 20px 0 0",
        position: "relative",
        cursor: "pointer"
      }}
      onClick={handleIconClick}
    >
        <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          fontFamily: "Source Sans Pro",
          gap: "3px",
          position: "absolute",
          margin: "4px",
          right: "4px",
          cursor: "pointer",
          padding: "3px 5px",
          borderRadius: "5px",
          "&:hover": {
            backgroundColor: "#f6f6f6",
          },
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>Edit</Typography>
        <EditIcon sx={{ fontSize: "18px" }} />
      </Box>
      {componentType === "IMAGE" && (
        <CardMedia
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
          component="img"
          sx={{ height: 200 }}
          image={fileURL}
          alt="Selected Image"
        />
      )}

      {componentType === "VIDEO" && (
        <ReactPlayer url={fileURL} width="640" height="560" controls />
      )}

      {componentType === "DOCUMENT" && (
        <Box sx={{ height: "200px", marginTop: "15px", overflow: "hidden" }}>
          <iframe
            src={`https://docs.google.com/gview?url=${encodeURIComponent(
              fileURL
            )}&embedded=true`} // src={fileURL}
            type="application/pdf, application/doc, application/docx, application/xml, application/csv"
            style={{ border: "none", width: "300px" }}
          />
          {/* <ShowDocuments fileURL={fileURL} /> */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              fontFamily: "Inter",
              color: "#008aff",
              fontSize: "16px",
              textDecoration: "underline",
              cursor: "pointer",
              transition: "0.3s ease"
            }}
          >
            <Typography variant="span" onClick={() => viewDocument(fileURL)}>
              View document
            </Typography>
            <Typography variant="span">Upload Documents</Typography>
          </Box>
        </Box>
      )}
      <input
        type="file"
        accept={supportedTypes[componentType].accept}
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e) => handleFileSelect(e, index, supportedTypes[componentType].accept)}
      />
    </CardContent>
  );
};

export default FileDisplayCard;
