import {
  Add,
  Close,
  Delete,
  Done,
  Groups,
  PeopleAltOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { Fragment } from "react";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EmptyState,
  checkSamePlatform,
  dialogStyle,
  getNameFromPlatform,
  getRandomString,
  getToken,
  getWebsite,
  isArray,
  isArrayWithValues,
  isObjWithValues,
  sleep,
} from "../../../helper";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import {
  EDIT_CHATS,
  assignBroadcast,
  setUpConversations,
  updateChatObject,
} from "../../../redux/actions/chatActions";
import ChatsCustomer from "./ChatsCustomer";
import { LoadingButton } from "@mui/lab";
import { updateBroadcastContacts } from "../apiFunctions";
import { InstagramSvg, Messenger, WhatsappSvg } from "../../../Svgs";

const BroadcastList = ({
  open,
  handleClose,
  selectedCustomers = [],
  hasBoradCast,
  setHasBoradCast,
  hideView,
  hideDelete,
  clearBulk,
}) => {
  const chat_broadcasts = useSelector((state) => state.chats.chat_broadcasts);

  const broadcastList = useSelector((state) => state.chats.broadcastList);
  // const allReduxConversations = useSelector(
  //   (state) => state.chats.conversations
  // );
  const [broadcastObject, setBroadcastObject] = useState({});
  // const [groups, setGroups] = useState({});
  const [viewGroup, setViewGroup] = useState({});

  const dispatch = useDispatch();
  const [showTextFeild, setShowTextFeild] = useState(false);

  const [channel, setChannel] = useState(null);
  const [deletingId, setDeletingId] = useState(false);
  const [error, setError] = useState({});
  const [viewCustomer, setViewCustomer] = useState([]);
  const [labelValue, setLabelValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteObj, setDeleteObj] = useState({});

  useEffect(() => {
    if (isObjWithValues(chat_broadcasts)) {
      let broadcastObj = {};
      Object?.entries(chat_broadcasts)?.forEach(([key, value]) => {
        if (channel === "all") {
          broadcastObj[key] = value;
        } else if (value?.platform === channel) broadcastObj[key] = value;
      });
      setBroadcastObject(broadcastObj);
    }
    return () => {};
  }, [chat_broadcasts, channel]);
  useEffect(() => {
    setChannel(checkSamePlatform(selectedCustomers || []));
    return () => {};
  }, [selectedCustomers]);

  const onAssign = async (broadcast) => {
    if (!isArrayWithValues(selectedCustomers)) return;
    dispatch(
      assignBroadcast({
        usersMetaData: selectedCustomers?.map((obj) => {
          let updatePayload = { id: obj?.id };
          updatePayload.meta_data = {
            broadcast: [
              ...new Set([
                ...(isArrayWithValues(obj?.meta_data?.broadcast)
                  ? obj?.meta_data?.broadcast
                  : typeof obj?.meta_data?.broadcast === "string"
                  ? [obj?.meta_data?.broadcast]
                  : []),
                broadcast?.id,
              ]),
            ],
          };
          return updatePayload;
        }),
      })
    );
    dispatch(
      setGlobalToast({
        show: true,
        message: `Broadcast Assigned Successfully!`,
        severity: "success",
      })
    );
    await sleep(1000);
    handleClose && handleClose();
    clearBulk && clearBulk();
    setLoading(false);
    // console.log(data);
  };

  const onAdd = async () => {
    let alreadyThere = false;
    Object.values(chat_broadcasts || {})?.forEach((obj) => {
      if (
        obj?.title?.replaceAll(" ", "")?.toLowerCase() ===
        labelValue?.replaceAll(" ", "")?.toLowerCase()
      )
        alreadyThere = true;
    });
    if (alreadyThere) {
      setError({
        name: "Broadcast Already Exist!",
      });
      return;
    }
    let id = getRandomString(10);
    let newBroadCast = {
      id,
      created_on: new Date().getTime(),
      platform: channel,
      contact_ids: selectedCustomers?.map((obj) => obj?.id),
      title: labelValue,
    };
    let usersMetaData = selectedCustomers?.map((obj) => {
      let updatePayload = { id: obj?.id };
      updatePayload.meta_data = {
        broadcast: [
          ...new Set([
            ...(isArrayWithValues(obj?.meta_data?.broadcast)
              ? obj?.meta_data?.broadcast
              : typeof obj?.meta_data?.broadcast === "string"
              ? [obj?.meta_data?.broadcast]
              : []),
            id,
          ]),
        ],
      };
      return updatePayload;
    });
    console.log(newBroadCast);
    dispatch(
      assignBroadcast({
        usersMetaData,
        newBroadcast: {
          [id]: newBroadCast,
        },
      })
    );
    dispatch(
      setGlobalToast({
        show: true,
        message: `Broadcast Created Successfully!`,
        severity: "success",
      })
    );
    await sleep(1000);
    clearBulk && clearBulk();
    // dispatch(
    //   setGlobalToast({
    //     show: true,
    //     message: `Broadcast Created Successfully!`,
    //     severity: "success",
    //   })
    // );

    handleClose && handleClose();

    setLoading(false);
  };

  return (
    <>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2,
        }}
      >
        <PeopleAltOutlined />
        Broadcast List{" "}
        {isObjWithValues(broadcastList)
          ? `(${Object.keys(broadcastObject)?.length})`
          : null}
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        {channel ? (
          <List>
            {isArrayWithValues(selectedCustomers) && (
              <>
                {showTextFeild ? (
                  <ListItem>
                    <TextField
                      placeholder="Broadcast List Name"
                      size="small"
                      fullWidth
                      variant="standard"
                      focused
                      error={error?.name}
                      helperText={error?.name}
                      // type={"submit"}
                      // onSubmit={onAdd}
                      value={labelValue}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onAdd();
                        }
                      }}
                      onChange={(e) => {
                        setLabelValue(e.target.value);
                        setError({});
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Add />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            sx={{ cursor: "pointer" }}
                            position="start"
                            onClick={() => setShowTextFeild(false)}
                          >
                            {loading ? (
                              <CircularProgress
                                style={{ height: "20px", width: "20px" }}
                              />
                            ) : (
                              <Close />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </ListItem>
                ) : (
                  <>
                    <ListItemButton
                      onClick={() => {
                        setShowTextFeild(true);
                        setHasBoradCast && setHasBoradCast(false);
                      }}
                      className={hasBoradCast ? "btn--jump" : ""}

                      // selected={selectedIndex === 0}
                      // onClick={(event) => handleListItemClick(event, 0)}
                    >
                      <ListItemIcon>
                        <Add />
                      </ListItemIcon>
                      <ListItemText primary={"Add new broadcast list"} />
                    </ListItemButton>
                  </>
                )}
              </>
            )}

            {Object.values(
              isObjWithValues(broadcastObject) ? broadcastObject : {}
            ).map((obj) => {
              let alreadyAssign = false;
              if (
                selectedCustomers?.length === 1 &&
                isArrayWithValues(selectedCustomers?.[0]?.label) &&
                selectedCustomers?.[0]?.label?.includes(obj?.id)
              ) {
                alreadyAssign = true;
              }
              let icons = {
                whatsapp: <WhatsappSvg height={10} width={10} />,
                instagram: <InstagramSvg height={10} width={10} />,
                facebook: <Messenger height={10} width={10} />,
              };
              return (
                <ListItem
                  onClick={() => onAssign(obj)}
                  key={obj?.id}
                  sx={{ paddingRight: "8px" }}
                  secondaryAction={
                    <>
                      {loading === obj?.id || deletingId === obj?.id ? (
                        <CircularProgress
                          style={{ height: "15px", width: "15px" }}
                        />
                      ) : hideDelete || true ? null : (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => setDeleteObj(obj)}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </>
                  }
                >
                  <ListItemButton
                    role={undefined}
                    selected={deletingId === obj?.id}
                    // onClick={() =>
                    // !alreadyAssign &&
                    // onAssgin(obj, groups?.[obj?.id]?.customers || [])
                    // }
                    sx={{
                      paddingRight: "4px",
                      ".MuiListItemButton-gutters": {
                        paddingRight: "4px",
                      },
                      // ".bkanzN > .MuiListItemButton-root": {
                      //   paddingRight: "4px",
                      // },
                    }}
                    disableGutters={true}
                    dense
                  >
                    <ListItemIcon>
                      <Badge badgeContent={icons?.[obj?.platform]}>
                        <PeopleAltOutlined />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Stack direction={"row"} alignItems="center">
                          <Typography variant="h6">{obj?.title} </Typography>
                          <Typography variant="h6" sx={{ ml: 4 }}>
                            {obj?.contact_ids?.length
                              ? `(${obj?.contact_ids?.length})`
                              : null}
                          </Typography>
                          {alreadyAssign && (
                            <Tooltip title={"Already Assign"}>
                              <Avatar
                                height={"20px"}
                                width={"20px"}
                                sx={{ ml: 3, height: "20px", width: "20px" }}
                                src={selectedCustomers?.[0]?.profile_pic || ""}
                              />
                            </Tooltip>
                          )}
                        </Stack>
                      }
                    />

                    {hideView ? null : (
                      <Button
                        variant="outlined"
                        onClick={(e) => {
                          e.stopPropagation();
                          setViewCustomer(obj);
                          // setViewGroup(groups?.[obj?.id]);
                          // setViewCustomer(groups?.[obj?.id]?.customers || []);
                        }}
                      >
                        View
                      </Button>
                    )}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <EmptyState
            // heading={"Selected chats users are not from same platform"}
            heading={"Cross-Platform Audience Isolation"}
            text={"Broadcast Limitation: Users Can't Span Channels"}
          />
        )}
      </DialogContent>

      <Dialog
        open={isObjWithValues(viewCustomer)}
        onClose={() => {
          setViewGroup({});
          setViewCustomer([]);
        }}
        sx={{ ...dialogStyle }}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            justifyContent: "space-between",
          }}
        >
          {viewGroup?.group_detail?.label}{" "}
          <Typography variant="h6" fontSize={"20px"}>
            {viewGroup?.customers?.length
              ? `(${viewGroup?.customers?.length})`
              : ""}{" "}
            Contacts
          </Typography>
        </DialogTitle>
        <DialogContent>
          {/* <Typography variant="h6"></Typography> */}
          <ChatsCustomer
            params={{ includes: viewCustomer?.contact_ids?.join(",") }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              // setViewGroup({});
              setViewCustomer({});
            }}
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isObjWithValues(deleteObj)}
        onClose={() => setDeleteObj({})}
        sx={{ ...dialogStyle }}
      >
        <DialogTitle>
          Are you sure You want to Delete BroadcastList {deleteObj?.label}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteObj({})}>Cancel</Button>
          <LoadingButton
            loading={loading}
            // onClick={() => onDelete(deleteObj?.id)}
            variant="contained"
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BroadcastList;
