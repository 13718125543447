import { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Autocomplete,
  Chip,
  Box,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import SegmentIcon from "@mui/icons-material/Segment";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { startStyles } from "./styles/startStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";
import { fetchCustomerSegments } from "./helper/fetchCustomerSegments";

const styles = startStyles;

const SidebarStart = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedSegments, setSelectedSegments] = useState(null);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClose = () => {
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const closeSidebar = () => {
    setOpen(false);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { openStartSidebar: false },
    });
    dispatch({ type: actions.RESET_NODE_ID });
  };

  const handleSelectedOption = (e, newValue) => {
    setSelectedSegments(newValue);
  };

  const handleSave = () => {
    const segmentsIds = selectedSegments
      .map((segment) => {
        const matchingItem = data.find((item) => item.segment_name === segment);
        return matchingItem ? { id: matchingItem.id } : null;
      })
      .filter(Boolean);

    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { segments: segmentsIds, openStartSidebar: false },
    });
    const dataPayload = { selected_segments: selectedSegments };
    dispatch({
      type: actions.UPDATE_NODE_DATA,
      payload: { id: userId, data: dataPayload },
    });
  };

  const getCustomerSegmemts = async () => {
    setLoading(true);
    const res = await fetchCustomerSegments();
    const responseData = res || {};
    const resultArray = Object.values(responseData).map((item) => ({
      id: item.id,
      segment_name: item.filterForm.segment_name,
    }));
    setData(resultArray);

    setLoading(false);
  };

  useEffect(() => {
    const currentNode = nodes?.find((it) => it.id === userId);
    getCustomerSegmemts();
    const fieldData = currentNode?.fieldData;
    if (fieldData?.selected_segments) {
      setSelectedSegments(fieldData?.selected_segments);
    } else {
      setSelectedSegments([]);
    }
  }, [userId]);

  return (
    <Box sx={styles.customCard}>
      <Box sx={styles.responseSection}>
        <IconButton
          sx={{
            position: "absolute",
            right: "20px",
            top: "-10px",
            "&:hover": {
              backgroundColor: "#dcdcdc",
            },
          }}
          onClick={handleClose}
        >
          <CloseIcon
            style={{
              fontSize: "22px",
              backgroundColor: "#fff",
              borderRadius: "5px",
              padding: "4px",
            }}
          />
        </IconButton>
        <Box sx={styles.topCard}>
          <SegmentIcon />
          <Typography variant="span" style={styles.headerTitle}>
            Select Segment
          </Typography>
        </Box>
        {/* {loading ? (
          <CircularProgress />
        ) : ( */}
        <>
          <Typography variant="span" style={styles.labels}>
            Select Segment
          </Typography>
          <Autocomplete
            multiple
            placeholder="Select..."
            id="tags-filled"
            loading={loading}
            options={
              (data &&
                data.length > 0 &&
                data.map((option) => option?.segment_name)) ||
              []
            }
            value={selectedSegments || []}
            onChange={(e, newValue) => handleSelectedOption(e, newValue)}
            getOptionSelected={(option, value) => option?.id === value?.id}
            renderTags={(value, getTagProps) =>
              value.map((selectedSegments, index) => (
                <Chip
                  key={index}
                  sx={{ backgroundColor: "#dcdcdc", fontFamily: "Inter" }}
                  variant="outlined"
                  label={selectedSegments}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select..."
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                sx={{
                  backgroundColor: "#fff",
                  marginBottom: "30px",
                }}
              />
            )}
          />
          <Button
            variant="contained"
            sx={{ textTransform: "none", marginBottom: "20px" }}
            onClick={handleSave}
          >
            Save
          </Button>
        </>
        {/* )} */}
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </Box>
    </Box>
  );
};

export default SidebarStart;
