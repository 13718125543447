import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { EmptyState } from "../../../../../helper";
import { toSnakeCase } from "../helper";

export const GupshupSmsTemplates = ({ onSelectTemplate }) => {
  const gupshupIntegration = useSelector(
    (state) => state.settings.gupshupIntegration
  );
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Directly use the dlt_templates_list from gupshupIntegration
    const templates = gupshupIntegration?.sms?.dlt_templates_list;
    if (templates) {
      setRows(templates);
    }
    setLoading(false);
  }, [gupshupIntegration]);

  const tableHead = [
    { label: "Template ID", path: "TEMPLATE ID (URN)" },
    { label: "Template Name", path: "TEMPLATE NAME" },
    { label: "Template Content", path: "TEMPLATE CONTENT" },
    { label: "Mask", path: "MASK" },
    { label: "DLT Message Type", path: "DLT MESSAGE TYPE" },
    { label: "MSG Type", path: "MSG TYPE" },
    { label: "Business Category", path: "BUSINESS CATEGORY" },
  ];

  const getValue = (row, path) => row[path] || "";

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHead.map((obj) => (
                <TableCell key={obj.label}>{obj.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              new Array(5).fill("").map((_, i) => (
                <TableRow key={i}>
                  {tableHead.map((obj) => (
                    <TableCell key={obj.label}>
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : rows.length ? (
              rows.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => onSelectTemplate && onSelectTemplate(row)}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                    cursor: onSelectTemplate ? "pointer" : "initial",
                  }}
                >
                  {tableHead.map((obj) => (
                    <TableCell key={obj.label}>
                      {getValue(row, toSnakeCase(obj.path))}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={tableHead.length}>
                  <EmptyState text={"No Sms Template found!"} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
