import { useState, useCallback, useEffect, memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Popover,
  Box,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import { createBotNode } from "./add-nodes/createBotNode";
import { createUserNode } from "./add-nodes/createUserNode";
import { createUserAttachmentNode } from "./add-nodes/createUserAttachment";
import { createQuestionNode } from "./add-nodes/createQuestionNode";
import { createFilterNode } from "./add-nodes/createFilterNode";
import { createSubscribeNode } from "./add-nodes/createSubscribeNode";
import { createUnsubscribeNode } from "./add-nodes/createUnsubscribeNode";
import { createGoToStepNode } from "./add-nodes/createGoToStepNode";
import { createWhatsappTemplateNode } from "./add-nodes/createWhatsappTemplateNode";
import { createLiveAgentNode } from "./add-nodes/createLiveAgentNode";
import { createProductCatalogNode } from "./add-nodes/createProductCatalogNode";
import { createTaskNode } from "./add-nodes/createTaskNode";
import { createMetalRatesNode } from "./add-nodes/createMetalRatesNode";
import { createAssignNode } from "./add-nodes/createAssignNode";
import PersonIcon from "@mui/icons-material/Person";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SwapCallsSharpIcon from "@mui/icons-material/SwapCallsSharp";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CategoryIcon from "@mui/icons-material/Category";
import EmailIcon from "@mui/icons-material/Email";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SmsIcon from "@mui/icons-material/Sms";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import AddTaskIcon from "@mui/icons-material/AddTask";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import TollIcon from "@mui/icons-material/Toll";
import SearchIcon from "@mui/icons-material/Search";
import * as actions from "../../../../../../redux/actions/flowsActions";
import { nodePopoverStyles } from "../styles/nodePopoverStyles";
import { checkIflast } from "./checkIfLast";
import { createEmailNode } from "./add-nodes/createEmailNode";
import { createPushNotification } from "./add-nodes/createPushNotification";
import { createSmsNode } from "./add-nodes/createSmsNode";
import { createDelayNode } from "./add-nodes/createDelayNode";
import { createFallbackNode } from "./add-nodes/createFallbackNode";
import { getAllowedNodes } from "../utils/getAllowedNodes";
import { SecondaryLabelSmall } from "../../../../../../helper/utility/Labels";
import HorizontalStack from "../../../../../../helper/utility/HorizontalStack";
import { InfoOutlined } from "@mui/icons-material";

const popoverStyles = nodePopoverStyles;

const containsText = (text, searchText) => {
  return text.toLowerCase().includes(searchText.toLowerCase());
};

const allowedNodesMapping = {
  bot: {
    func: createBotNode,
    label: "Bot Response",
    icon: <TelegramIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      "&:hover": {
        backgroundColor: "#e3e3e3",
      },
    },
  },
  user: {
    func: createUserNode,
    label: "User Input",
    icon: <PersonIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#446DF6",
      color: "#fff",
      fontSize: "10px",
      "&:hover": {
        backgroundColor: "#446DF6",
      },
    },
  },
  "user-attachment": {
    func: createUserAttachmentNode,
    label: "User Attachment",
    icon: <AttachFileIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      "&:hover": {
        backgroundColor: "#e3e3e3",
      },
      color: "#000",
    },
  },
  question: {
    func: createQuestionNode,
    label: "Questions",
    icon: <QuestionMarkOutlinedIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#ffad5c",
      color: "#22304a",
      "&:hover": {
        backgroundColor: "#ffad5c",
      },
    },
  },
  fallback: {
    func: createFallbackNode,
    label: "Fallback",
    icon: <ReplyAllIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      "&:hover": {
        backgroundColor: "#e3e3e3",
      },
    },
  },
  filter: {
    func: createFilterNode,
    label: "Filter",
    icon: <FilterAltOutlinedIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#686ad3",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#686ad3",
      },
    },
  },
  subscribe: {
    func: createSubscribeNode,
    label: "Subscribe",
    icon: <BookmarkAddIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#9dca80",
      "&:hover": {
        backgroundColor: "#9dca80",
      },
      color: "#000",
    },
  },
  unsubscribe: {
    func: createUnsubscribeNode,
    label: "Unsubscribe",
    icon: <BookmarkRemoveIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#FB4B54",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#FB4B54",
      },
    },
  },
  "go-to-step": {
    func: createGoToStepNode,
    label: "Go to step",
    icon: <SwapCallsSharpIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#ffdc66",
      transform: "rotate(90deg)",
      "&:hover": {
        backgroundColor: "#ffdc66",
      },
    },
  },
  "whatsapp-template": {
    func: createWhatsappTemplateNode,
    label: "Whatsapp",
    icon: <WhatsAppIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#25D366",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#25D366",
      },
    },
  },
  "live-agent": {
    func: createLiveAgentNode,
    label: "Live Agent",
    icon: <SupportAgentIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#008aff",
      "&:hover": {
        backgroundColor: "#008aff",
      },
      color: "#ffffff",
    },
  },
  "product-catalog": {
    func: createProductCatalogNode,
    label: "Product Catalog",
    icon: <CategoryIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#00b3fa",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#00b3fa",
      },
    },
  },
  email: {
    func: createEmailNode,
    label: "Email",
    icon: <EmailIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#20A4F3",
      "&:hover": {
        backgroundColor: "#20A4F3",
      },
      color: "#ffffff",
    },
  },
  sms: {
    func: createSmsNode,
    label: "SMS",
    icon: <SmsIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#98E4FF",
      "&:hover": {
        backgroundColor: "#98E4FF",
      },
    },
  },
  "push-notification": {
    func: createPushNotification,
    label: "Push Notification",
    icon: <NotificationsActiveIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#86CB92",
      "&:hover": {
        backgroundColor: "#86CB92",
      },
    },
  },
  delay: {
    func: createDelayNode,
    label: "Delay",
    icon: <HourglassBottomIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#bae8e8",
      "&:hover": {
        backgroundColor: "#bae8e8",
      },
    },
  },
  "create-task": {
    func: createTaskNode,
    label: "Create task",
    icon: <AddTaskIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#8884FF",
      "&:hover": {
        backgroundColor: "#8884FF",
      },
    },
  },
  "metal-rates": {
    func: createMetalRatesNode,
    label: "Metal rates",
    icon: <TollIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#FFD700",
      "&:hover": {
        backgroundColor: "#FFD700",
      },
    },
  },
  assign: {
    func: createAssignNode,
    label: "Assign Team",
    icon: <GroupAddIcon sx={popoverStyles.icon} />,
    buttonStyles: {
      ...popoverStyles.iconButton,
      backgroundColor: "#BBE1C3",
      "&:hover": {
        backgroundColor: "#BBE1C3",
      },
      color: "#000",
    },
  },
};

const NodePopover = memo(
  ({
    handleClick,
    handleClose,
    anchorEl,
    nodeId,
    currentAddHandle,
    handlesObj,
  }) => {
    const dispatch = useDispatch();
    const nodes = useSelector((state) => state.flow?.nodes);
    const edges = useSelector((state) => state.flow?.edges);
    const chatbotType = useSelector((state) => state.flow?.chatbotType);
    const nodeType = nodes?.find((node) => node.id === nodeId)?.type;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
      if (!open) {
        setTimeout(() => setSearchText(""), [1000]);
      }
    }, [open]);

    const allowedNodes = getAllowedNodes({
      nodes,
      edges,
      currentNodeId: nodeId,
      currentNodeType: nodeType,
      type: chatbotType?.type,
    });

    const displayedOptions = useMemo(
      () => allowedNodes?.filter((option) => containsText(option, searchText)),
      [searchText, allowedNodes]
    );

    const handleNode = useCallback(
      (event, node) => {
        event.stopPropagation();
        const handler = allowedNodesMapping[node];
        const addNodeFunction = handler.func;
        addNodeFunction({
          dispatch,
          nodes,
          nodeId,
          currentAddHandle,
          handlesObj,
        });
        handleClose();
      },
      [dispatch, nodes, nodeId, handleClose]
    );

    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            ...popoverStyles.mainBox,
            // minHeight: chatbotType?.type === "trigger" ? "300px" : "520px",
          }}
        >
          <Box
            sx={{
              borderBottom: "1px solid #dcdcdc",
              padding: "5px",
              marginBottom: "5px",
            }}
          >
            <HorizontalStack spacing={1.5}>
              <InfoOutlined style={{ height: "14px", width: "14px" }} />
              <SecondaryLabelSmall>
                Select a Communication channel
              </SecondaryLabelSmall>
            </HorizontalStack>
            {/* <TextField
              size="small"
              autoFocus
              variant="standard"
              placeholder="Type to searchasdfasdf..."
              fullWidth
              inputProps={{
                style: {
                  fontFamily: "Inter",
                  fontSize: "15px",
                  fontWeight: 500,
                },
              }}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      position: "relative",
                      left: "4px",
                      bottom: "2px",
                      color: "#366aed",
                    }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            /> */}
          </Box>
          {displayedOptions &&
            displayedOptions.length > 0 &&
            displayedOptions.map((node, index) => (
              <Box
                key={index}
                onClick={(event) => handleNode(event, node)}
                sx={popoverStyles.innerBox}
              >
                <IconButton sx={allowedNodesMapping[node]?.buttonStyles}>
                  {allowedNodesMapping[node]?.icon}
                </IconButton>
                <Typography sx={popoverStyles.textField}>
                  {allowedNodesMapping[node]?.label}
                </Typography>
              </Box>
            ))}
          {displayedOptions && displayedOptions.length === 0 && (
            <Box
              sx={{
                ...popoverStyles.innerBox,
                marginTop: "10px",
                "&:hover": {
                  backgroundColor: "#fff",
                  cursor: "auto",
                  color: "#000",
                },
              }}
            >
              <Typography
                sx={{
                  ...popoverStyles.textField,
                  fontSize: "16px",
                  fontWeight: 600,
                  "&:hover": {
                    color: "#000",
                  },
                }}
              >
                No options found!!
              </Typography>
            </Box>
          )}
        </Box>
      </Popover>
    );
  }
);

NodePopover.displayName = "NodePopover";

export default NodePopover;
