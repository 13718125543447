export const questionStyles = {
  customCard: {
    position: "absolute",
    minWidth: "480px",
    maxWidth: "500px",
    maxHeight: "1000px",
    margin: "0 auto",
    borderRadius: "5px",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    top: "20px",
    right: "20px",
    pointerEvents: "all",
  },
  topCard: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: "10px"
  },
  innerCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "35px",
  },
  userHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
    fontWeight: 600,
    color: "#59687b",
    justifyContent: "center",
  },
  headerTitle: {
    fontSize: "15px",
    fontWeight: "530",
    marginLeft: "7px",
  },
  headerIconsMainDiv: {
    display: "flex",
    alignContents: "flex-end",
    position: "relative",
    left: "15px",
    bottom: "5px",
  },
  headerIconsDiv: {
    backgroundColor: "#dcdcdc",
    marginRight: "10px",
    borderRadius: "4px",
    height: "30px",
    width: "30px",
  },
  headerIcons: {
    color: "#475e7a",
    fontSize: "20px",
    fontWeight: "600",
  },
  titleTextField: {
    marginTop: "15px",
    marginBottom: "15px",
    borderRadius: "8px",
    fontSize: "50px",
  },
  responseSection: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#dcdcdc",
    paddingLeft: "30px",
    paddingRight: "30px",
    maxHeight: "530px",
    overflowY: "auto",
    paddingBottom: "30px",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
  },
  responseDiv: {
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
    padding: "0px"
  },
  iconsButton: {
    color: "black",
    fontWeight: 600,
    position: "relative",
    top: "1px",
  },
  icons: {
    fontSize: "20px",
  },
  innerBox: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "10px",
    marginBottom: "15px",
  },
  labelBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  labelIcon: {
    height: '22px',
    width: '22px',
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
    fontSize: 16,
    opacity: 0.8,
    color:"#fff",
    marginRight: '4px',
    boxShadow: "none",
    "&:hover": {
      boxShadow: "0 2px 6px 0 rgba(0,0,0,.14)",
      opacity: 0.8,   
      backgroundColor: "#fff",
      color: "black",
    }
  },
  variantDelete: {
    backgroundColor: "#fff",
    boxShadow: "0 2px 6px 0 rgba(0,0,0,.14)",
    opacity: 0.8,  
    position: "relative",
    left: "301px",
    bottom: "58px",
    "&:hover": {
      backgroundColor: "#008aff",
      color: "#fff"
    } 
  },
  labels: {
    fontSize: "16px",
    fontWeight: 550,
  },
  textFieldBox: {
    borderRadius: "7px",
    backgroundColor: "#dbe1e8",
    margin: "10px",
    height: '50px',
    paddingLeft: '10px',
    paddingTop: '10px',
    paddingRight: '10px',
  },
  questionTextfield: {
    border: "none"
  },
  optionsInput: {
    marginTop: "10px",
  },
  footerTextBox: {
    fontSize: "15px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    position: "relative",
    "&:hover": {
      cursor: "pointer"
    },
  },
  footerIcon: {
    borderRadius: "50%",
    fontSize: "22px",
    marginRight: "8px",
    color: "#008aff",
    backgroundColor: "#fff",
    position: "relative",
    transition: "0.3 ease",
    top: "6px",
    "&:hover": {
      backgroundColor: "#008aff",
      color: "#fff"
    }
  },
  footerText: {
    backgroundColor: "#dcdcdc",
    position: "relative",
    top: "8px",
    padding: "0 10px",
    color: "#475e7a",
    fontSize: "14px",
    fontWeight: 600,
  }
};
