import React, { useState, useEffect, useMemo } from "react";
import { Handle, useNodeId, useUpdateNodeInternals } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import NodePopover from "../helper/NodePopover";
import * as actions from "../../../../../../redux/actions/flowsActions";
import { whatsappTemplateStyles } from "../styles/whatsappTemplateStyles";
import "../styles/node.css";
import "../styles/node-title.css";
import { fetchWhatsappTemplates } from "../../sidebar-whatsapp-template/helper/fetchWhatsappTemplates";
import { nodeCanBeAddedErrorMessage } from "../helper/nodeCanBeAdded";
import AddIcon from "@mui/icons-material/Add";
import ShowChatIndicator from "../helper/ShowChatIndicator";
import NodeTop from "../helper/NodeTop";
import { nodeAddedOnHandle } from "../utils/nodeAddedOnHandle";
import CustomTooltip from "../../components/CustomTooltip";

const styles = whatsappTemplateStyles;

function toSnakeCase(inputText) {
  const snakeCaseText = inputText.replace(/[\W\s]+/g, "_");
  return snakeCaseText.toLowerCase();
}

const WhatsappTemplate = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const nodeId = useNodeId();
  const updateNodeInternals = useUpdateNodeInternals();
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const dragginNodeType = useSelector((state) => state.flow?.dragginNodeType);
  const isDragging = useSelector((state) => state.flow?.isDragging);
  const title = nodes.find((node) => node.id === nodeId)?.fieldData?.title;
  const messageNodeIndicatorId = useSelector(
    (state) => state.flow?.messageNodeIndicatorId
  );
  const isMinimized = useSelector((state) => state.flow?.isMinimized);
  const [isHovered, setIsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentAddHandle, setCurrentAddhandle] = useState(null);
  const [showAddIcons, setShowAddIcons] = useState(false);
  const currNode = nodes.find((node) => node?.id == nodeId);
  const [prevSavedState, setPrevSavedState] = useState(null);
  // const [handlesObj, setHandlesObj] = useState(null);

  const handleClick = (e, handlePosition) => {
    console.log(handlePosition);
    e.stopPropagation();
    const flag = nodeAddedOnHandle({
      edges: edges,
      currentNodeId: nodeId,
      handleId: handlePosition,
    });
    if (flag) {
      console.log("GOING AHEAD");
      return;
    }

    setCurrentAddhandle(handlePosition);
    setAnchorEl(e.currentTarget);
    setIsHovered(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMain = () => {
    dispatch({ type: actions.CURRENT_NODE_ID, payload: { nodeId: nodeId } });
    dispatch({ type: actions.SIDEBAR_WHATSAPP_TEMPLATE, payload: true });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { nodeDraggedToId: nodeId },
    });
    if (isDragging) {
      const currentNode = nodes?.find((node) => node.id === nodeId);
      const flag = nodeCanBeAddedErrorMessage({
        nodes,
        edges,
        currentNode,
        nodeAddedType: dragginNodeType,
      });

      if (flag !== "allowed") {
        setErrorMessage(flag);
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setErrorMessage(null);
  };

  useEffect(() => {
    fetchWhatsappTemplates(dispatch);
  }, [dispatch]);

  const handlesObj = useMemo(() => {
    const data = currNode?.fieldData;
    const staticHandles = [
      { label: "Sent", value: "whatsapp_sent_handle" },
      { label: "Delivered", value: "whatsapp_delivered_handle" },
      { label: "Read", value: "whatsapp_read_handle" },
      { label: "Failed", value: "whatsapp_failed_handle" },
    ];

    if (data) {
      const currentSavedState = data?.currentSavedState;

      if (currentSavedState) {
        const components = currentSavedState?.components;
        const traverseButtonComponent = components.find((comp) => {
          if (comp?.type === "BUTTONS") {
            return comp;
          }
        });
        if (!traverseButtonComponent) {
          return [...staticHandles];
        }
        const buttonComponent = traverseButtonComponent?.buttons;
        const newButtonsHandles = (buttonComponent ?? [])
          .map((btnComp) => {
            const text = btnComp?.text;
            if (btnComp?.type === "QUICK_REPLY")
              return {
                label: text,
                value: `whatsapp_${toSnakeCase(text)}_handle`,
              };
          })
          ?.filter(Boolean);
        updateNodeInternals(nodeId);
        // const updatedHandles = [...staticHandles, ...newButtonsHandles];
        return [...staticHandles, ...newButtonsHandles];
      }
    }

    return null;
  }, [currNode?.fieldData]);

  // useEffect(() => {
  //   console.log("Effect is running with nodeId:", nodeId);
  //   const data = currNode?.fieldData;

  //   if (data) {
  //     const currentSavedState = data?.currentSavedState;

  //     if (prevSavedState == currentSavedState) {
  //       console.log("SAME STATE");
  //       return;
  //     }

  //     setPrevSavedState(currentSavedState);
  //     if (currentSavedState) {
  //       console.log(currentSavedState);
  //       const components = currentSavedState?.components;
  //       console.log("components", components);
  //       const traverseButtonComponent = components.find((comp) => {
  //         if (comp?.type === "BUTTONS") {
  //           return comp;
  //         }
  //       });
  //       const buttonComponent = traverseButtonComponent?.buttons;
  //       const newButtonsHandles = (buttonComponent ?? []).map((btnComp) => {
  //         const text = btnComp?.text;
  //         return {
  //           label: text,
  //           value: `whatsapp_${toSnakeCase(text)}_handle`,
  //         };
  //       });

  //       const updatedHandles = [...staticHandles, ...newButtonsHandles];

  //       setHandlesObj((prev) => [...staticHandles, ...newButtonsHandles]);
  //       // setShowAddIcons(true);
  //     }
  //   }
  // }, [currNode?.fieldData, dispatch]);

  console.log(handlesObj, "<<<< handlesObj");

  return (
    <Box
      className="nodeContainer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{ paddingBottom: "30px", paddingLeft: "30px" }}
    >
      <Box
        sx={{
          height: "20px",
          width: title !== undefined ? "120px" : "98px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
          fontFamily: "Inter",
          position: "relative",
          top: "6px",
        }}
      >
        <NodeTop
          title={title}
          isHovered={isHovered}
          isDragging={isDragging}
          nodeId={nodeId}
        />
      </Box>
      <Box sx={styles.nodeBox}>
        <Handle type="target" position="left" style={styles.leftHandle} />

        {!title ? (
          <Box
            sx={{ ...styles.nodeBody, width: "80px", justifyContent: "center" }}
            onClick={handleMain}
          >
            <Box sx={{ ...styles.nodeInnerBox, marginRight: 0 }}>
              <WhatsAppIcon sx={styles.nodeIcon} />
              <Typography variant="body2" sx={styles.titleName}>
                Whatsapp
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              ...styles.nodeBody,
              height: `${handlesObj?.length * 10 + 25}px`,
            }}
            onClick={handleMain}
          >
            <Box sx={styles.nodeInnerBox}>
              <WhatsAppIcon sx={styles.nodeIcon} />
              <Typography variant="body2" sx={styles.titleName}>
                Whatsapp
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box
                sx={{
                  ...styles.handleBox,
                  height: `${handlesObj?.length * 10 + 15}px`,
                }}
              >
                {handlesObj &&
                  handlesObj.length > 0 &&
                  handlesObj.map((handle, index) => {
                    return (
                      <Box key={index} sx={styles.handleInnerBox}>
                        <CustomTooltip title={handle?.label}>
                          <Typography variant="body2" sx={styles.handleText}>
                            {handle?.label}
                          </Typography>
                        </CustomTooltip>
                        <AddIcon
                          style={{ height: "10px", width: "10px" }}
                          sx={styles.addIcon}
                          onClick={(e) => handleClick(e, handle?.value)}
                        />
                        <Handle
                          type="source"
                          position="right"
                          id={handle?.value}
                          style={styles.rightHandle}
                        />
                        {/* <div
                          style={{
                            position: "absolute",
                            bottom: "-3px",
                            left: "27px",
                            visibility:
                              isHovered && isDragging ? "visible" : "hidden",
                          }}
                        >
                          <BounceLoader
                            color="#3185FC"
                            speedMultiplier="0.8"
                            size="20px"
                          />
                        </div> */}
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </Box>
        )}
        {messageNodeIndicatorId === nodeId && !isMinimized && (
          <ShowChatIndicator />
        )}

        <NodePopover
          nodeId={nodeId}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          currentAddHandle={currentAddHandle}
          handlesObj={handlesObj}
        />
      </Box>
    </Box>
  );
};

export default React.memo(WhatsappTemplate);
