export const buttonFieldStyles = {
  mainContainer: {
    marginBottom: "10px",
    display: "flex",
    zIndex: 99999,
  },

  innerContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "20px",
  },
  textFieldContainer: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "10px",
  },
  textField: {
    backgroundColor: "white",
    borderRadius: "10px",
    fontFamily: "Source Sans Pro, Roboto, sans-serif",
    color: "#fff",
    fontSize: "10px",
    minHeight: "100px",
    border: "none",
    "& fieldset": { border: "none" },
  },
  icon: {
    fontSize: "19px",
    fontWeight: 600,
  },
  iconButton: {
    height: "30px",
    width: "30px",
    backgroundColor: "#fff",
    color: "#647283",
    border: "0.3px solid #dcdcdc",
    marginRight: "6px",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#008aff",
    },
  },
  lineBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  line: {
    marginTop: "2px",
    height: "4px",
    backgroundColor: "#c5c7c6",
    width: "2px",
  },
};
