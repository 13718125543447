import { backendApiCall, backendApiEndpoints } from "../../helper";
import { backendEndpoints } from "../../pages/chat_new/apiFunctions";
import { EDIT_SETTINGS } from "./settingActions";

const EDIT_MARKETING = "EDIT_MARKETING";

const editMarketingState = (payload) => ({ type: EDIT_MARKETING, payload });

const fetchJweroBroadcasts = (props) => {
  let { onSuccess, onFailed } = props || {};
  return async (dispatch) => {
    try {
      dispatch(
        editMarketingState({
          fetchingJweroBroadcasts: true,
        })
      );
      let broadcastRes = await backendApiCall({
        endpoint: `${backendApiEndpoints.broadcasts}`,
      });
      if (broadcastRes?.success) {
        dispatch(
          editMarketingState({
            broadcasts: broadcastRes?.data,
          })
        );
        onSuccess && onSuccess();
      }
      console.log(broadcastRes, "<<<<<broadcast/templates");
    } catch (error) {
      onFailed && onFailed();
    } finally {
      dispatch(
        editMarketingState({
          fetchingJweroBroadcasts: false,
        })
      );
    }
  };
};
const fetchJweroCampaigns = () => {
  return async (dispatch) => {
    try {
      dispatch(
        editMarketingState({
          fetchingJweroCampaigns: true,
        })
      );
      let campaingsRes = await backendApiCall({
        endpoint: `${backendApiEndpoints.campaigns}`,
      });
      if (campaingsRes?.success) {
        dispatch(
          editMarketingState({
            broadcasts: campaingsRes?.data,
          })
        );
      }
      console.log(campaingsRes, "<<<<<broadcast/templates");
    } catch (error) {
    } finally {
      dispatch(
        editMarketingState({
          fetchingJweroCampaigns: false,
        })
      );
    }
  };
};
const fetchJweroTriggers = (props = {}) => {
  return async (dispatch) => {
    let { onSuccess } = props;
    try {
      dispatch(
        editMarketingState({
          fetchingJweroTriggers: true,
        })
      );
      let triggersRes = await backendApiCall({
        endpoint: "flow",
        params: { type: "trigger" },
      });
      if (triggersRes?.success) {
        dispatch(
          editMarketingState({
            triggers: triggersRes?.data,
          })
        );
        onSuccess && onSuccess();
      }
    } catch (error) {
    } finally {
      dispatch(
        editMarketingState({
          fetchingJweroTriggers: false,
        })
      );
    }
  };
};

const setConnectedSMSchannels = () => {
  return (dispatch, getState) => {
    let state = getState();
    let connected = [];
    let msg91Integration = state?.settings?.msg91Integration;
    const gupshupIntegration = state.settings.gupshupIntegration;
    const smsIntegration = state.settings?.smsIntegration;
    if (msg91Integration?.authkey || smsIntegration?.msg91?.auth_key)
      connected.push({ name: "msg91" });
    if (gupshupIntegration?.enabled) connected.push({ name: "gupshup" });

    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        connected_sms_services: connected,
      },
    });
    // let msg91Integration = state?.settings?.msg91Integration;
  };
};

export {
  editMarketingState,
  fetchJweroBroadcasts,
  fetchJweroCampaigns,
  EDIT_MARKETING,
  fetchJweroTriggers,
  setConnectedSMSchannels,
};
