import { useState, useEffect } from "react";
import {
  Typography,
  Card,
  TextField,
  Autocomplete,
  IconButton,
} from "@mui/material";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { userAttachmentStyles } from "./styles/userAttachmentStyles";
import * as actions from "../../../../../redux/actions/flowsActions";
import DialogBox from "../components/DialogBox";

const styles = userAttachmentStyles;

const SidebarUserAttachment = () => {
  const nodes = useSelector((state) => state.flow?.nodes);
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [titleCheckFlag, setTitleCheckFlag] = useState(false);

  const documentOptions = [
    "Image attachment",
    "Video attachment",
    "Document attachment",
  ];
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCheck = (e) => {
    e.preventDefault();
    if (title == "" || titleCheckFlag) {
      setTitleCheckFlag(true);
      setTitleError(true);
    }

    if (title) {
      const submittedUserData = {
        title,
        fieldsData: selectedValues,
      };
      dispatch({ type: actions.SIDEBAR_USER_ATTACHMENT, payload: false });
      dispatch({
        type: actions.UPDATE_NODE_DATA,
        payload: { id: userId, data: submittedUserData },
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    handleClickOpen();
    dispatch({ type: actions.SET_DIALOG_BOX, payload: true });
  };

  const closeSidebar = () => {
    dispatch({ type: actions.SIDEBAR_USER_ATTACHMENT, payload: false });
  };

  useEffect(() => {
    const currentNode = nodes?.find((it) => it.id === userId);
    const node = currentNode?.fieldData;
    if (node?.title) {
      setTitle(node?.title);
      setSelectedValues(node?.fieldsData?.selectedValues);
    }
  }, [userId, nodes]);

  return (
    <Card style={styles.customCard}>
      <form>
        <div style={styles.topCard}>
          <div style={styles.innerCard}>
            <div style={styles.userHeader}>
              <AttachFileOutlinedIcon />
              <Typography variant="span" style={styles.headerTitle}>
                USER ATTACHMENT
              </Typography>
            </div>
            <div style={styles.headerIconsMainDiv}>
              <IconButton style={styles.headerIconsDiv} onClick={handleClose}>
                <CloseIcon style={styles.headerIcons} />
              </IconButton>
              <IconButton style={styles.headerIconsDiv} onClick={handleCheck}>
                <CheckIcon style={styles.headerIcons} />
              </IconButton>
            </div>
          </div>

          <TextField
            placeholder="Type block title"
            size="small"
            style={styles.titleTextField}
            onChange={(e) => setTitle(e.target.value)}
            error={titleError}
            value={title}
            required
          ></TextField>
        </div>
        <div style={styles.responseSection}>
          <div style={styles.responseDiv}>
            <Typography variant="span" style={styles.labels}>
              Trigger only if the user sends
            </Typography>
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              options={documentOptions}
              sx={styles.optionsInput}
              value={documentOptions[0]}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div style={styles.responseDiv}>
            <Typography variant="span" style={styles.labels}>
              Save the file URL to the attribute
            </Typography>
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              options={documentOptions}
              sx={styles.optionsInput}
              value={documentOptions[0]}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </div>
        <DialogBox
          setOpen={setOpen}
          open={open}
          handleCloseDialog={handleCloseDialog}
          closeSidebar={closeSidebar}
        />
      </form>
    </Card>
  );
};

export default SidebarUserAttachment;
