import { Add, Delete, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getShippingRates,
  updateShippingRates,
} from "../../redux/actions/settingActions";
import {
  dialogStyle,
  getRandomId,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";
import { fetchTaxonomies } from "../../redux/actions/productActions";

const initialForm = {
  id: getRandomId(), // Generate unique ID for each form entry
  regions: [],
  rate: {
    maxProductValue: 0,
    rate: 0,
    additionalRate: 0,
    hasAdditionalRate: false,
  },
  categories: [],
  collections: [],
};

const ShippingRates = () => {
  const dispatch = useDispatch();
  const [shippings, setShippings] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addForm, setAddForm] = useState(initialForm);
  const [deleting, setDeleting] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [maxProductValueError, setMaxProductValueError] = useState(false);

  const reduxShippingRates = useSelector(
    (state) => state.settings?.shippingRates
  );
  const fetchingRates = useSelector((state) => state.settings.fetchingRates);
  const taxonomies = useSelector((state) => state.product.taxonomies);

  useEffect(() => {
    if (!isObjWithValues(taxonomies)) dispatch(fetchTaxonomies());
  }, []);

  const collections = taxonomies?.collections || [];
  const categories = taxonomies?.categories || [];

  useEffect(() => {
    if (isArrayWithValues(reduxShippingRates?.new)) {
      setShippings(reduxShippingRates?.new);
    } else {
      dispatch(getShippingRates());
    }
  }, [reduxShippingRates]);

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  const handleDeleteRate = (index) => {
    setDeleting(index);
    setDeleteDialogOpen(true);
  };

  const confirmDeleteRate = () => {
    const updatedShippings = shippings.filter((_, i) => i !== deleting);
    setShippings(updatedShippings);
    dispatch(updateShippingRates({ newShippingRates: updatedShippings }));
    setDeleteDialogOpen(false);
    setDeleting(false);
  };

  const onSubmitForm = () => {
    setLoading(true);

    // Convert categories and collections to array of strings (values)
    const transformedForm = {
      ...addForm,
      categories: addForm.categories.map((category) => category.value),
      collections: addForm.collections.map((collection) => collection.value),
    };

    const updatedShippings = [...(shippings || [])];
    if (editIndex !== null) {
      updatedShippings[editIndex] = transformedForm;
    } else {
      updatedShippings.push({ ...transformedForm, id: getRandomId() }); // Generate unique ID for new entries
    }

    dispatch(updateShippingRates({ newShippingRates: updatedShippings }));
    setShowForm(false);
    setAddForm(initialForm);
    setEditIndex(null);
    setLoading(false);
  };

  const handleRateChange = (field, value) => {
    const updatedRate = { ...addForm.rate, [field]: value };

    // Check for duplicate maxProductValue in the form only if adding a new rate
    if (editIndex === null) {
      const maxProductValues = shippings.map(
        (shipping) => shipping.rate.maxProductValue
      );
      const hasDuplicateMaxProductValue = maxProductValues.some(
        (value) => value === updatedRate.maxProductValue
      );

      if (hasDuplicateMaxProductValue) {
        setMaxProductValueError(true);
      } else {
        setMaxProductValueError(false);
      }
    } else {
      setMaxProductValueError(false);
    }

    setAddForm((prev) => ({ ...prev, rate: updatedRate }));
  };

  const handleToggleAdditionalRate = (value) => {
    const updatedRate = { ...addForm.rate, hasAdditionalRate: value };
    setAddForm((prev) => ({ ...prev, rate: updatedRate }));
  };

  const openEditForm = (index) => {
    const editedForm = shippings[index];

    // Convert stored string arrays back to objects for display in Autocomplete
    setAddForm({
      ...editedForm,
      categories: editedForm.categories.map((categoryValue) =>
        categories.find((category) => category.value === categoryValue)
      ),
      collections: editedForm.collections.map((collectionValue) =>
        collections.find((collection) => collection.value === collectionValue)
      ),
    });
    setShowForm(true);
    setEditIndex(index);
  };

  return (
    <Box>
      <Stack alignItems={"center"} justifyContent="flex-end" direction="row">
        <Button
          size="small"
          onClick={() => {
            setAddForm(initialForm);
            setShowForm(true);
            setEditIndex(null);
          }}
          startIcon={<Add />}
          sx={{ mr: 2 }}
          variant="contained"
        >
          Add New Shipping Rate
        </Button>
      </Stack>
      <Card sx={{ mt: 3, padding: "20px" }} className="shadow_card">
        <TableContainer
          sx={{
            mt: 3,
            borderRadius: "10px",
            boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>SR. No.</TableCell>
                <TableCell align="left">Collection/Categories</TableCell>
                <TableCell align="left">Rates</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fetchingRates && (
                <>
                  {Array(8)
                    .fill(null)
                    .map((_, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <CircularProgress />
                        </TableCell>
                        <TableCell align="left">
                          <CircularProgress />
                        </TableCell>
                        <TableCell align="left">
                          <CircularProgress />
                        </TableCell>
                        <TableCell align="left">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )}
              {isArrayWithValues(shippings) &&
                shippings.map((row, index) => (
                  <TableRow key={index} hover sx={{ cursor: "pointer" }}>
                    <TableCell align="left">
                      <Typography>{index + 1}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Stack>
                        {isArrayWithValues(row.categories) && (
                          <Typography>{row?.categories?.join(", ")}</Typography>
                        )}
                        {isArrayWithValues(row.collections) && (
                          <Typography>
                            {row?.collections?.join(", ")}
                          </Typography>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                      <Stack my={1} direction="row" alignItems={"center"}>
                        <Typography>{`Max Product Value: ${
                          row.rate.maxProductValue || "No limit"
                        }, Rate: ${row.rate.rate}`}</Typography>
                        {row.rate.hasAdditionalRate && (
                          <Typography>{`, Additional Rate: ${row.rate.additionalRate} per additional quantity`}</Typography>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                      <IconButton
                        size="small"
                        onClick={() => handleDeleteRate(index)}
                      >
                        <Delete />
                      </IconButton>
                      <IconButton onClick={() => openEditForm(index)}>
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={Boolean(showForm)}
        onClose={() => {
          setShowForm(false);
          setAddForm(initialForm);
          setEditIndex(null);
        }}
        sx={dialogStyle}
      >
        <DialogTitle>
          {editIndex !== null ? "Update" : "Add"} Shipping Rate
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mt={2} gap={2}>
            <Grid item xs={12}>
              <TextField
                label="Max Product Value"
                type="number"
                fullWidth
                value={addForm.rate.maxProductValue}
                onChange={(e) =>
                  handleRateChange("maxProductValue", parseInt(e.target.value))
                }
                error={maxProductValueError}
                helperText={
                  maxProductValueError
                    ? "Max Product Value must be unique."
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Rate"
                type="number"
                fullWidth
                value={addForm.rate.rate}
                onChange={(e) =>
                  handleRateChange("rate", parseInt(e.target.value))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center">
                <Switch
                  checked={addForm.rate.hasAdditionalRate}
                  onChange={(e) => handleToggleAdditionalRate(e.target.checked)}
                />
                <Typography>Enable Additional Rate</Typography>
              </Stack>
            </Grid>
            {addForm.rate.hasAdditionalRate && (
              <Grid item xs={12}>
                <TextField
                  label="Additional Rate per Extra Quantity"
                  type="number"
                  fullWidth
                  value={addForm.rate.additionalRate}
                  onChange={(e) =>
                    handleRateChange("additionalRate", parseInt(e.target.value))
                  }
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={["domestic", "international"]}
                value={addForm.regions}
                onChange={(e, newValue) =>
                  setAddForm((prev) => ({ ...prev, regions: newValue }))
                }
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="Regions" />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                openOnFocus
                value={addForm.collections}
                getOptionLabel={(option) => option?.label || ""}
                options={collections.filter(
                  (col) =>
                    !shippings.some((ship) =>
                      ship.collections.some(
                        (selectedCol) => selectedCol === col.value
                      )
                    )
                )}
                onChange={(e, newValue) =>
                  setAddForm((prev) => ({ ...prev, collections: newValue }))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Collections"
                    variant="standard"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                openOnFocus
                value={addForm.categories}
                getOptionLabel={(option) => option?.label || ""}
                options={categories.filter(
                  (cat) =>
                    !shippings.some((ship) =>
                      ship.categories.some(
                        (selectedCat) => selectedCat === cat.value
                      )
                    )
                )}
                onChange={(e, newValue) =>
                  setAddForm((prev) => ({ ...prev, categories: newValue }))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Categories"
                    variant="standard"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowForm(false);
              setAddForm(initialForm);
              setEditIndex(null);
            }}
          >
            Cancel
          </Button>
          <LoadingButton loading={loading} onClick={onSubmitForm}>
            {editIndex !== null ? "Update" : "Add"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this rate?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDeleteRate} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ShippingRates;
