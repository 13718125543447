import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Outlet, useLocation } from "react-router-dom";

import {
  AppBar,
  Box,
  CssBaseline,
  IconButton,
  Menu,
  Paper as MuiPaper,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import dashboardItems, {
  retailerPageSection,
  supplierPageSection,
} from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import {
  getWebsite,
  hasAccessInPlan,
  isArrayWithValues,
  isDev,
} from "../helper";
import { useSelector } from "react-redux";
import { planTabs } from "../App";
import axios from "axios";

// const drawerWidth = 258;
// const drawerWidth = 150;
export const drawerWidth = 110;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${110}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);
// `
//  box-shadow: none;
// `;

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;
// .MuiPaper-root .MuiPaper-root {
//   box-shadow: none;
// }
const Dashboard = ({ children, hideSideBar, noPadding }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [navItems, setNavItems] = useState([]);
  const user_type = useSelector((state) => state.user.user_type);
  const store_plan = useSelector((state) => state.user.store_plan);
  let [betaUsers, setBetaUsers] = useState([]);
  let website = getWebsite();

  // const setupBetaUsers = async () => {
  //   let { data } = await axios({
  //     url: "https://tiarabytj.com/wp-json/store/v1/settings/beta_customers",
  //     headers: {
  //       apikey: "eca2c452-753f-4d18-ad23-c76ceec5f3a6",
  //     },
  //   });
  //   if (isArrayWithValues(data?.data)) setBetaUsers(data?.data);
  // };
  // useEffect(() => {
  //   setupBetaUsers();
  // }, []);

  useEffect(() => {
    if (!isArrayWithValues(dashboardItems)) return;
    let sections = [...dashboardItems];
    let deniedModueles = !isDev() ? store_plan?.denied_access?.modules : [];
    if (user_type || !isArrayWithValues(store_plan?.user_products)) {
      let section = sections.find((i) => i.title === "Pages");
      let { pages } = section;
      if (user_type === "supplier") {
        if (isArrayWithValues(pages)) pages = supplierPageSection;
      } else if (isArrayWithValues(pages)) pages = retailerPageSection;

      section.pages = pages;
      sections = sections.map((i) => (i.title === "Pages" ? section : i));
    }
    if (isArrayWithValues(store_plan?.user_products)) {
      let section = sections.find((i) => i.title === "Pages");
      let { pages } = section;
      let array = [];
      for (let index = 0; index < store_plan?.user_products.length; index++) {
        const element = store_plan?.user_products[index];
        array = array.concat(planTabs[element]);
      }
      array = [...new Set(array)];
      pages = retailerPageSection.filter((obj) => array?.includes(obj.title));
      section.pages = pages;
      sections = sections.map((i) => (i.title === "Pages" ? section : i));
    }
    if (
      website &&
      !website?.includes("tiara")
      // && !isDev()
    ) {
      let section = sections.find((i) => i.title === "Pages");
      let { pages } = section;
      pages = pages?.filter((obj) => obj?.title !== "Design Bank");
      pages = pages?.filter((obj) => obj?.title !== "Activities");
      // if (!betaUsers?.includes(website))
      if (deniedModueles?.includes("Marketing Automation"))
        pages = pages?.filter((obj) => obj?.title !== "Marketing Automation");
      // pages = pages?.filter((obj) => obj?.title !== "Campaign");
      section.pages = pages;
      sections = sections.map((i) => (i.title === "Pages" ? section : i));
    }
    if (!hasAccessInPlan({ access: "campaign" })) {
      let section = sections.find((i) => i.title === "Pages");
      let { pages } = section;
      pages = pages?.filter((obj) => obj?.title !== "Campaign");
      // pages = pages?.filter((obj) => obj?.title !== "Campaign");
      section.pages = pages;
      sections = sections.map((i) => (i.title === "Pages" ? section : i));
    }
    setNavItems(sections);
  }, [dashboardItems, user_type, store_plan, betaUsers, website]);

  const handleDrawerToggle = () => {
    console.log("nav click");
    setMobileOpen((pre) => !pre);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const location = useLocation();
  let { hash, pathname } = location;
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const showNavBar = () => {
    if (isIpad) {
      if (hash?.includes("Communication"))
        return !hash?.includes("Communication");
      if (pathname?.includes("chats")) {
        console.log(pathname.substring(7));
        if (pathname.substring(7)) return false;
        else return true;
      } else return true;
    } else {
      return true;
    }
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppBar open={true}>
        {showNavBar() ? <Navbar onDrawerToggle={handleDrawerToggle} /> : null}

        {/* <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              marginRight: 5,
              // ...(false && { display: "none" }),
            }}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography>
        </Toolbar> */}
      </AppBar>

      {/* <Box
        sx={{
          display: { xs: "block", lg: "none" },
        }}
      > */}
      {/* {true ? null : ( */}
      <Sidebar
        PaperProps={{
          style:
            // { width: drawerWidth }
            { width: 258 },
        }}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        items={navItems}
      />
      {/* )} */}
      {/* </Box> */}
      {hideSideBar ? null : (
        <Drawer
        // open={mobileOpen}
        // onClose={handleDrawerToggle}
        >
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Sidebar
              PaperProps={{ style: { width: isIpad ? 135 : drawerWidth } }}
              items={navItems}
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Box>
        </Drawer>
      )}
      <AppContent>
        {/* {showNavBar() ? <Navbar onDrawerToggle={handleDrawerToggle} /> : null} */}
        {showNavBar() ? (
          <DrawerHeader onDrawerToggle={handleDrawerToggle} />
        ) : null}
        <MainContent p={noPadding ? 0 : isIpad ? "6px" : isLgUp ? 3 : 5}>
          {children}
          <Outlet />
        </MainContent>
        {/* <Footer /> */}
      </AppContent>
      {/* <Settings /> */}
    </Root>
  );
};

export default Dashboard;
